/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Icon, Input, Menu } from 'semantic-ui-react';
import classNames from 'classnames';
import ToggleButton from '../ToggleButton/ToggleButton';
import PopupWrapper from '../PopupWrapper/PopupWrapper';
import './FilterSettings.styles.less';
import * as constants from './filterSettings.constants';
import withAuthorization from '../../helpers/authorization';
import * as appConstants from '../../../features/App/app.constants';
import * as businessCenterConstants from '../../../features/BusinessCenter/businessCenter.constants';
import * as activityConstants from '../../../features/Activity/activity.constants';
import Tooltip from '../../../common/components/Tooltip/Tooltip';
import ActivityTags from '../../../features/Activity/views/activityTags/activityTags';

const AUTOMATION_NAME = 'filter-settings';

class FilterSettings extends React.PureComponent {

  static propTypes = {
    messagesNamespace: PropTypes.string,
    onFilterUpdate: PropTypes.func.isRequired,
    filters: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.array.isRequired
    ]),
    tags: PropTypes.array,
    isFiltered: PropTypes.bool.isRequired,
    filterType: PropTypes.string,
    filterKeysToExclude: PropTypes.array,
    isDisabled: PropTypes.bool,
    hasFullPermission: PropTypes.func,
  };

  static defaultProps = {
    isDisabled: false
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      isPopupOpen: false, shouldFilterHeaderBeHighlighted: false
    };
    this.handleActivityTagChange = this.handleActivityTagChange.bind(this);
    this.handleFilterActivityChange = this.handleFilterActivityChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isDisabled) {
      this.setState({ shouldFilterHeaderBeHighlighted: false });
    }
  }

  handleOnPopupOpen = () => {
    this.setState({
      isPopupOpen: true
    });
  };

  handleOnPopupClose = () => {
    this.setState({
      isPopupOpen: false
    });
  };

  getFilterType(filterType) {
    return !filterType ? 'view' : filterType;
  }

  //When the component is loaded for the first time,
  //we need to make sure that this filter gets highlighted if user is coming
  //from a different page and the filter state is altered
  componentWillMount() {
    this.updateHighlightedState(this.props.filters, '');
  }

  handleOnFilterUpdate = (groupKey, criteria, status) => {
    if (!this.props.isDisabled) {
      this.updateHighlightedStateWithCriteria(groupKey, criteria, status, false);
      this.props.onFilterUpdate(groupKey, criteria, status, false);
    }
  };

  getTransformedFilterList(groupKey, criteria, status, turnOffOtherFilters) {
    let tempFilters = this.props.filters;
    if (turnOffOtherFilters) {
      tempFilters.forEach((value, key) => {
        const thisObj = tempFilters.get(key);
        if (key === groupKey) {
          thisObj.forEach((filterVal, filterKey) => {
            const modifiedFilter = tempFilters.get(groupKey).set(filterKey, filterKey == criteria);
            tempFilters = tempFilters.set(groupKey, modifiedFilter);
          });
        }
      });
    } else {
      const modifiedFilter = tempFilters.get(groupKey).set(criteria, status);
      tempFilters = tempFilters.set(groupKey, modifiedFilter);
    }
    return tempFilters;
  }

  updateHighlightedState = (filters, criteria) => {
    let shouldHighlight = false;
    const filtersToExcludeList = this.props.filterKeysToExclude != undefined ? this.props.filterKeysToExclude : [];

    if (!Array.isArray(filters)) {
      filters.forEach((value, key) => {
        const thisFilterObj = filters.get(key);
        thisFilterObj.forEach((filterVal, filterKey) => {
          if (((!thisFilterObj.get(filterKey) && (!filtersToExcludeList.some((filter) => filterKey.toLowerCase().includes(filter)))) || (filtersToExcludeList.some((filter) => filterKey.toLowerCase().includes(filter)) && thisFilterObj.get(filterKey))) && (criteria != activityConstants.RESET_ALL_FILTERS || criteria === '')) {
            shouldHighlight = true;
          }
        });
      });
    }
    this.setState({ shouldFilterHeaderBeHighlighted: shouldHighlight });
  };


  updateHighlightedStateWithCriteria = (groupKey, criteria, status, turnOffOtherFilters) => {
    const transformedList = this.getTransformedFilterList(groupKey, criteria, status, turnOffOtherFilters);
    this.updateHighlightedState(transformedList, criteria);
  };

  handleOnlyOneClick = (e, groupKey, criteria) => {
    e.preventDefault();
    e.stopPropagation();
    this.updateHighlightedStateWithCriteria(groupKey, criteria, true, true);
    for (const key in this.props.filters.toJS()[groupKey]) {
      if (key !== 'criteria') {
        this.props.onFilterUpdate(groupKey, key, false, false);
      }
    }
    this.props.onFilterUpdate(groupKey, criteria, true, true);
  };
  handleActivityTagChange = (event, target) => {
    this.props.onFilterUpdate('tags', target.value, true, true);
  };
  handleFilterActivityChange = (e) => {
    this.props.onFilterUpdate(activityConstants.FILTER_BY_ACTIVITY, activityConstants.FILTERS_ACTIVITY_SEARCH_BY_TEXT, e.target.value, true);
  };

  render() {
    const { filters, isFiltered, messagesNamespace, hasFullPermission, filterType, isDisabled } = this.props;
    const { isPopupOpen, shouldFilterHeaderBeHighlighted } = this.state;
    const { formatMessage } = this.props.intl; // eslint-disable-line

    if (filterType === activityConstants.TAG) {
      return (
        <ActivityTags
          activityTags={this.props.tags}
          autoCompleteOptions={this.props.filters}
          handleActivityTagChange={this.handleActivityTagChange}
          shouldActivityTagBeShown={this.props.hasFullPermission(appConstants.FEATURE_ACTIVITY_TAGS)}
        />
      );
    }

    const maxGroupsIndex = filters.size - 1;
    const filtersJS = filters.toJS();

    if (filterType === activityConstants.FILTER_BY_ACTIVITY) {
      return (
        <Input
          icon="search"
          iconPosition="left"
          name="filterByActivity"
          value={filtersJS[activityConstants.FILTER_BY_ACTIVITY][activityConstants.FILTERS_ACTIVITY_SEARCH_BY_TEXT] || ''}
          placeholder={formatMessage({ id: 'activity.actions.search' })}
          onChange={this.handleFilterActivityChange}
          data-automation-id={`${messagesNamespace}.activity.search`}
          className={'highlighted'}
        />
      );
    }

    delete filtersJS[activityConstants.FILTER_BY_ACTIVITY];

    if (!hasFullPermission(appConstants.FEATURE_NAME_CAMPAIGN_CENTER) && hasFullPermission(appConstants.FEATURE_ACTIONS_ON_BULK_OF_MEMBER)) {
      for (const prop in filtersJS[businessCenterConstants.FILTERS_GROUP_NAME_ACTIVITY_BY_TYPE]) {
        if (prop !== activityConstants.FILTERS_ACTIVITY_ONE_TIME) {
          delete filtersJS[businessCenterConstants.FILTERS_GROUP_NAME_ACTIVITY_BY_TYPE][prop];
        }
      }
    }
    return (
      <PopupWrapper
        className="filter-settings"
        position="bottom left"
        hideTrigger={false}
        flowing
        on="click"
        open={isPopupOpen}
        onOpen={this.handleOnPopupOpen}
        onClose={this.handleOnPopupClose}
        automationId={AUTOMATION_NAME}
        basic
        trigger={
          <Menu.Item
            active={shouldFilterHeaderBeHighlighted || isPopupOpen}
            data-automation-id={`popup.${AUTOMATION_NAME}.trigger`}
          >
            <Icon className={isFiltered ? 'como-ic-filter-half-full' : 'como-ic-filter-empty'}/>
            <span>
              {formatMessage({ id: `${messagesNamespace}.${this.getFilterType(filterType)}` })}
            </span>
          </Menu.Item>
        }
      >
        <ul>
          {Object.entries(filtersJS)
            .map(([groupKey, val], groupIndex) => {
              const maxValIndex = Object.keys(val).length - 1;
              const displayGroup = [constants.FILTER_GROUP_KEY_ACTIVITY_BY_TYPE, constants.FILTER_GROUP_KEY_ACTIVITY_BY_STATUS]
                .includes(groupKey) ? constants.FILTER_GROUP_KEY_ACTIVITY : groupKey;
              return Object.entries(val).map(([criteria, status], index) => (/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
                <li
                  key={index.toString()}
                  className={classNames({
                    'with-border-bottom': index === maxValIndex && maxGroupsIndex !== groupIndex
                  })}
                  onClick={() => this.handleOnFilterUpdate(groupKey, criteria, !status)}
                >
                  {(!isDisabled) ? criteria !== activityConstants.RESET_ALL_FILTERS && <ToggleButton
                    automationId={AUTOMATION_NAME}
                    id={`${displayGroup}.${criteria}`}
                    enabled={status}
                  /> : null}
                  {formatMessage({ id: `${messagesNamespace}.filter-settings.${displayGroup}.${criteria}` })}
                  {(!isDisabled) ? !(displayGroup === 'campaign') ? criteria !== activityConstants.RESET_ALL_FILTERS &&
                      criteria !== activityConstants.FILTERS_ACTIVITY_PINNED &&
                    <button
                      onClick={(e) => this.handleOnlyOneClick(e, groupKey, criteria)}
                      className="filters-only-one"
                    >
                      {formatMessage({ id: 'filter-settings.button.only' })}
                    </button> : displayGroup === 'campaign' && criteria === 'isAutoArchived' &&
                    <div className="hint-tip-label">
                      <Tooltip
                        wide="very"
                        trigger={(<Icon className="como-ic-help"/>)}
                        content={<div>
                          <p>{formatMessage({ id: 'autoArchived.activity.explained.general' })}</p>
                          <p>{formatMessage({ id: 'autoArchived.activity.explained.rules' })}</p>
                          <p>{formatMessage({ id: 'autoArchived.activity.explained.onetime' })}</p>
                        </div>}
                      />
                    </div> : null}

                </li>));
            })}
        </ul>
      </PopupWrapper>
    );
  }
}

export default withAuthorization(injectIntl(FilterSettings));
