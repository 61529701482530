import { fromJS, List } from 'immutable';
import * as defaults from '../activity.reducer.defaults';
import * as schemaConstants from '../../activitySchema/activitySchema.constants';
import * as constants from '../../activity.constants';

export function updateMiddlePunchConditions(requiredPunches, activity) {
  const middlePunch = getMiddleNumber(requiredPunches);
  return activity
    .getIn([constants.CONDITIONS, constants.CONDITIONS_LIST])
    .map((condition) => {
      if (condition.get(constants.CONDITION_KEY) === schemaConstants.CONTEXT_TOTAL_PUNCHES_AFTER_OPERATION) {
        return condition.set(constants.CONDITION_VALUE, middlePunch);
      }
      return condition;
    });
}

export function addRuleToDeletionList(rulesList, ruleId) {
  if (rulesList) {
    return rulesList.push(ruleId);
  }
  return List([ruleId]);
}

export function getMiddleNumber(num) {
  return Math.ceil(num / 2);
}

export function setActionDelay(actions, actionType, delayValue, delayUnit) {
  return actions.map((action) => {
    if (action.get(constants.ACTION_TYPE) === actionType) {
      if (delayValue) {
        const delay = fromJS(defaults.defaultDelay)
          .set(constants.DELAY_UNITS, delayUnit)
          .set(constants.DELAY_NUMBER, delayValue);
        return action.set(constants.DELAY, delay);
      }
      return action.set(constants.DELAY, null);
    }
    return action;
  });
}
