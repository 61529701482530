import { createSelector } from 'reselect';
import { fromJS, List, OrderedMap } from 'immutable';
import moment from 'moment/moment';
import * as constants from './businessCenter.constants';
import * as activityConstants from '../Activity/activity.constants';
import { sortArrayByDate } from '../../common/utils/dateTimeUtils';
import * as activitySelectorsUtils from '../Activity/activity.selectors.utils';
import * as reducerConstants from '../../constants/reducer.constants';

/**
 * Direct selector to the business center state domain
 */

const getBusinessCenterData = (state) => state.get(reducerConstants.BUSINESS_CENTER_BRANCH);
export const getToastState = createSelector([getBusinessCenterData], (bcd) => bcd.get(constants.IS_TOAST_OPEN));
export const getToastMessage = createSelector([getBusinessCenterData], (bcd) => bcd.get(constants.TOAST_MESSAGE));
export const getCampaigns = createSelector([getBusinessCenterData], (bcd) => bcd.get(constants.CAMPAIGNS));
export const getFilters = createSelector([getBusinessCenterData], (bcd) => bcd.get(constants.FILTERS));
export const getTriggers = createSelector([getBusinessCenterData], (bcd) => bcd.get(constants.TRIGGERS));
export const getActions = createSelector([getBusinessCenterData], (bcd) => bcd.get(constants.ACTIONS));
export const getOrder = createSelector([getBusinessCenterData], (bcd) => bcd.getIn([constants.SORT, constants.ORDER]));
export const getFocusedCampaignId = createSelector([getBusinessCenterData], (bcd) => bcd.get(constants.FOCUSED_CAMPAIGN_ID));
export const getCampaignsLoadedFlag = createSelector([getBusinessCenterData], (bcd) => bcd.get(constants.CAMPAIGNS_LOADED));
export const getHighlightedActivityId = createSelector([getBusinessCenterData], (bcd) => bcd.get(activityConstants.HIGHLIGHTED_ACTIVITY_ID));
export const getFetchedAutoArchiveActivities = createSelector([getBusinessCenterData], (bcd) => bcd.get(constants.FETCHED_ACTIVITIES_WITH_ARCHIVE));
export const getScrollYPosition = createSelector([getBusinessCenterData], (bcd) => bcd.get(constants.SCROLL_Y_POSITION));
export const getFiltersToDisable = createSelector([getBusinessCenterData], (bcd) => bcd.get(constants.FILTERS_TO_DISABLE_MAP));
export const isRuleFilterEnabled = createSelector([getBusinessCenterData], (bcd) => bcd.get(constants.IS_RULE_FILTER_ENABLED));
export const isActionFilterEnabled = createSelector([getBusinessCenterData], (bcd) => bcd.get(constants.IS_ACTION_FILTER_ENABLED));
export const isAutoArchivedFilterEnabled = createSelector([getBusinessCenterData], (bcd) => bcd.get(constants.IS_AUTO_ARCHIVED_FILTER_ENABLED));
export const isArchivedCampaignFilterEnabled = createSelector([getBusinessCenterData], (bcd) => bcd.get(constants.IS_ARCHIVE_CAMPAIGN_FILTER_ENABLED));
export const filterGroupingMap = createSelector([getBusinessCenterData], (bcd) => bcd.get(constants.FILTER_GROUPING_MAP));
export const isActionFilterUpdated = createSelector([getBusinessCenterData], (bcd) => bcd.get(constants.IS_ACTION_FILTER_UPDATED));
export const getAllActivityTags = createSelector([getBusinessCenterData], (bcd) => bcd.get(constants.ALL_BUSINESS_CENTER_ACTIVITY_TAGS));
export const getActivityTagsFilter = createSelector([getBusinessCenterData], (bcd) => bcd.get(constants.ACTIVITY_TAGS_FILTER));

/**
 * Other specific selectors
 */

/**
 * Default selector used by business center
 */

const getFilteredActivities = (activities, filters, _isRuleFilterEnabled, _isActionFilterEnabled, _filterGroupingMap) => {
  if (!activities) {
    return null;
  }

  return activities
    .filter((activity) => {
      if (!activity) {
        return false;
      }

      const viewStatus = activitySelectorsUtils.calculateActivityViewStatus(activity);
      const type = activity.get(activityConstants.TYPE);
      const actions = activity.get(activityConstants.ACTIONS);
      const cases = activity.get(activityConstants.CASES);
      const searchBY = filters.get(activityConstants.FILTER_SEARCH_BY_ACTIVITY_TEXT);
      const pinned = filters.get(activityConstants.FILTERS_ACTIVITY_PINNED);

      if (type === activityConstants.SERVER_TYPE_RULE) {
        const triggerType = getValidTrigger(activity.get(activityConstants.TRIGGER));

        if (!filters.get(triggerType) && filters.has(triggerType)) {
          return false;
        }

        if (!filters.get(activityConstants.FILTERS_ACTIVITY_RULE)) {
          return false;
        }

        if (!shouldActivityBeShownWithActionFilters(actions, cases, filters, _filterGroupingMap)) {
          return false;
        }
      }

      if (type === activityConstants.SERVER_TYPE_DEAL &&
        (!filters.get(activityConstants.FILTERS_ACTIVITY_DEAL) || _isRuleFilterEnabled || _isActionFilterEnabled)) {
        return false;
      }

      if (type === activityConstants.SERVER_TYPE_ONE_TIME_ACTION) {
        if (_isRuleFilterEnabled) {
          return false;
        }
        if (!filters.get(activityConstants.FILTERS_ACTIVITY_ONE_TIME)) {
          return false;
        }
        if (!shouldActivityBeShownWithActionFilters(actions, cases, filters, _filterGroupingMap)) {
          return false;
        }
      }

      if (type === activityConstants.SERVER_TYPE_PUNCH_CARD &&
        (!filters.get(activityConstants.FILTERS_ACTIVITY_PUNCH_CARD) || _isRuleFilterEnabled || _isActionFilterEnabled)) {
        return false;
      }

      if (viewStatus === activityConstants.ACTIVITY_STATUS_ACTIVE && !filters.get(activityConstants.FILTERS_ACTIVITY_ACTIVE)) {
        return false;
      }

      if (viewStatus === activityConstants.ACTIVITY_STATUS_STOPPED && !filters.get(activityConstants.FILTERS_ACTIVITY_STOPPED)) {
        return false;
      }

      if (viewStatus === activityConstants.ACTIVITY_STATUS_DRAFT && !filters.get(activityConstants.FILTERS_ACTIVITY_DRAFT)) {
        return false;
      }

      if (viewStatus === activityConstants.ACTIVITY_STATUS_FUTURE && !filters.get(activityConstants.FILTERS_ACTIVITY_FUTURE)) {
        return false;
      }

      if (viewStatus === activityConstants.ACTIVITY_STATUS_COMPLETED && !filters.get(activityConstants.FILTERS_ACTIVITY_COMPLETED)) {
        return false;
      }

      if (searchBY) {
        const activeName = activity.get(activityConstants.NAME);
        const activeDescription = activity.get(activityConstants.DESCRIPTION);

        if (!((activeName && activeName.toLowerCase().includes(searchBY.toLowerCase())) ||
              (activeDescription && activeDescription.toLowerCase().includes(searchBY.toLowerCase())))) {
          return false;
        }
      }

      if (pinned && activity.get(constants.ACTIVITY_PINNING_DATE) === null) {
        return false;
      }

      return !(filters.get(activityConstants.ACTIVITY_STATUS_AUTO_ARCHIVED) === false && activitySelectorsUtils.isAutoArchived(activity));
    });
};

const getFilteredCampaigns = (campaigns, filters, triggers, actions, _isRuleFilterEnabled, _isActionFilterEnabled, _filterGroupingMap, _activityTagsFilter) => campaigns
  // filter campaigns
  // filter campaigns' activities
  .map((campaign) => {
    const activities = campaign.get(constants.ACTIVITIES);
    const activitiesToFilter = filters.get(constants.FILTERS_GROUP_NAME_ACTIVITY_BY_TYPE)
      .merge(filters.get(constants.FILTERS_GROUP_NAME_ACTIVITY_BY_STATUS))
      .merge(filters.get(constants.FILTERS_GROUP_NAME_ACTIVITY_EXTRA))
      .merge(filters.get(constants.FILTERS_GROUP_NAME_CAMPAIGN))
      .merge(filters.get(activityConstants.FILTER_BY_ACTIVITY));
    const triggersToFilter = triggers.get(constants.FILTERS_GROUP_NAME_ALL_TRIGGER);
    const actionsToFilter = actions.get(constants.FILTERS_GROUP_NAME_ALL_ACTION);
    const combinedFilters = activitiesToFilter.merge(triggersToFilter).merge(actionsToFilter);
    const filteredActivities = getFilteredActivities(activities, combinedFilters, _isRuleFilterEnabled, _isActionFilterEnabled, _filterGroupingMap);

    if (_activityTagsFilter && _activityTagsFilter.length > 0) {
      const taggedFilteredActivities = filteredActivities.filter((a) => a.get('activityTags') && a.get('activityTags').some((at) => _activityTagsFilter.some((atf) => atf === at)));
      return campaign.set(constants.ACTIVITIES, taggedFilteredActivities);
    }

    return campaign.set(constants.ACTIVITIES, filteredActivities);
  })
  .filter((campaign) => {
    const status = campaign.get(constants.CAMPAIGN_STATUS);
    const campaignFilters = filters.get(constants.FILTERS_GROUP_NAME_CAMPAIGN);
    const pinnedActivities = filters.get(constants.FILTERS_GROUP_NAME_ACTIVITY_EXTRA).get(activityConstants.FILTERS_ACTIVITY_PINNED);

    const allActivities = campaign.get(constants.ACTIVITIES);
    const campaignStatusFilters = filters.get(constants.FILTERS_GROUP_NAME_CAMPAIGN);

    const now = moment.utc();
    const createdOn = campaign.get('createdOn');

    const autoArchivedActivities = allActivities.filter((a) => activitySelectorsUtils.isAutoArchived(a));
    const isExpired = moment(createdOn).add(8, 'days') < now;
    const showPinnedActivities = pinnedActivities && allActivities.size === 0;
    const showHidden = !((allActivities.size === 0 || allActivities.size === autoArchivedActivities.size) && isExpired) && status !== constants.CAMPAIGN_STATUS_ARCHIVE && !showPinnedActivities;
    const showArchive = status === constants.CAMPAIGN_STATUS_ARCHIVE && campaignFilters.get(constants.FILTERS_CAMPAIGN_ARCHIVED);
    const showAutoArchived = campaignStatusFilters.get(activityConstants.ACTIVITY_STATUS_AUTO_ARCHIVED) && autoArchivedActivities.size > 0 && allActivities.size > 0;

    return showArchive || showHidden || showAutoArchived;
  });

/**
 * orders campaigns list and their activities
 * @param campaigns list
 * @param order const value descent or ascend
 */
const getOrderedCampaigns = (campaigns, order) => {
  const orderFactor = order === constants.ORDER_ASCEND ? 1 : -1;
  const orderedCampaigns = sortArrayByDate(campaigns, [constants.CAMPAIGN_CREATED_ON], orderFactor);
  return orderedCampaigns.map((campaign) => campaign.set(constants.ACTIVITIES,
    sortArrayByDate(campaign.get(constants.ACTIVITIES), [activityConstants.ACTIVITY_CREATE_DATE], orderFactor)));
};

export const getFilteredAndSortedCampaigns = createSelector(
  [getCampaigns, getFilters, getTriggers, getActions, getOrder, isRuleFilterEnabled, isActionFilterEnabled, filterGroupingMap, getActivityTagsFilter],
  (campaigns, filters, triggers, actions, order, isRuleModeEnabled, isActionModeEnabled, _filterGroupingMap, _activityTagsFilter) => {
    let newCampaigns;
    try {
      newCampaigns = getFilteredCampaigns(campaigns, filters, triggers, actions, isRuleModeEnabled, isActionModeEnabled, _filterGroupingMap, _activityTagsFilter);
      // order campaigns by dates
      newCampaigns = getOrderedCampaigns(newCampaigns, order);
    } catch (err) {
      console.error(err);
    }
    return newCampaigns;
  }
);

/**
 * trigger filters appear based on the rules which are present in active campaigns
 * @param campaigns triggers list
 */
export const getActiveTriggers = createSelector(
  [getCampaigns, isArchivedCampaignFilterEnabled, isAutoArchivedFilterEnabled, isActionFilterEnabled, getActions, filterGroupingMap],
  (campaigns, isArchiveCampaignEnabled, isAutoArchivedActivitiesFiltered, _isActionFilterEnabled, actions, _filterGroupingMap) => {

    let activeTriggers = OrderedMap();
    try {
      campaigns.forEach((value, key) => {
        const thisCampaign = campaigns.get(key);
        if ((thisCampaign.get(constants.CAMPAIGN_STATUS) === constants.CAMPAIGN_STATUS_ACTIVE) || (isArchiveCampaignEnabled && thisCampaign.get(constants.CAMPAIGN_STATUS) === constants.CAMPAIGN_STATUS_ARCHIVE)) {
          thisCampaign.get(constants.ACTIVITIES).forEach((activityValue, activityKey) => {
            const thisActivity = thisCampaign.get(constants.ACTIVITIES).get(activityKey);
            if (thisActivity.get(activityConstants.TYPE) === activityConstants.SERVER_TYPE_RULE) {
              if (((isAutoArchivedActivitiesFiltered && activitySelectorsUtils.isAutoArchived(thisActivity))
                  || (!activitySelectorsUtils.isAutoArchived(thisActivity)))
                && ((_isActionFilterEnabled && shouldTriggerBeShownWithActionFilters(thisActivity, actions, _filterGroupingMap)) || (!_isActionFilterEnabled))) {
                activeTriggers = activeTriggers.set(getValidTrigger(thisActivity.get(activityConstants.TRIGGER)), true);
              }
            }
          });
        }
      });
    } catch (err) {
      console.error(err);
    }
    return activeTriggers;
  }
);

/**
 * action filters appear based on the actions and rules which are present in active campaigns
 * @param campaigns triggers list
 */
export const getActiveActions = createSelector(
  [getCampaigns, isArchivedCampaignFilterEnabled, isAutoArchivedFilterEnabled, filterGroupingMap],
  (campaigns, isArchiveCampaignEnabled, isAutoArchivedActivitiesFiltered, _filterGroupingMap) => {
    let activeActions = OrderedMap();
    let noAction = false;
    try {
      campaigns.forEach((value, key) => {
        const thisCampaign = campaigns.get(key);
        if ((thisCampaign.get(constants.CAMPAIGN_STATUS) === constants.CAMPAIGN_STATUS_ACTIVE) || (isArchiveCampaignEnabled && thisCampaign.get(constants.CAMPAIGN_STATUS) === constants.CAMPAIGN_STATUS_ARCHIVE)) {
          thisCampaign.get(constants.ACTIVITIES).forEach((activityValue, activityKey) => {
            const thisActivity = thisCampaign.get(constants.ACTIVITIES).get(activityKey);
            if (thisActivity.get(activityConstants.TYPE) === activityConstants.SERVER_TYPE_RULE || thisActivity.get(activityConstants.TYPE) === activityConstants.SERVER_TYPE_ONE_TIME_ACTION) {
              if ((isAutoArchivedActivitiesFiltered && activitySelectorsUtils.isAutoArchived(thisActivity))
                || (!activitySelectorsUtils.isAutoArchived(thisActivity))) {
                if (thisActivity.get(constants.ACTIONS)) {
                  const actions = thisActivity.get(constants.ACTIONS);
                  activeActions = setActiveActionFilterNames(actions, activeActions, _filterGroupingMap);
                } else {
                  noAction = true;
                }
                if (thisActivity.get(activityConstants.CASES)) {
                  const cases = thisActivity.get(activityConstants.CASES);
                  cases.forEach((caseValue, caseKey) => {
                    const actions = (cases.get(caseKey).get(constants.ACTIONS));
                    if (actions) {
                      activeActions = setActiveActionFilterNames(actions, activeActions, _filterGroupingMap);
                    } else {
                      noAction = true;
                    }
                  });
                } else {
                  noAction = true;
                }
              }
            }
          });
        }
      });
      if (noAction) {
        activeActions = activeActions.set(constants.NO_ACTION, true);
      }
    } catch (err) {
      console.error(err);
    }
    return activeActions;
  }
);


export const getSortedReducedCampaignsData = createSelector(
  [getFilteredAndSortedCampaigns],
  (campaigns) => campaigns ? campaigns.map((option, index) =>
    (fromJS({ key: index, value: option.get(constants.ID), text: option.get(constants.CAMPAIGN_NAME) }))
  ) : List()
);

export const isCampaignsFiltered = createSelector([getCampaigns, getFilters, getTriggers, getActions, isRuleFilterEnabled, isActionFilterEnabled, filterGroupingMap], (campaigns, filters, triggers, actions, isRuleModeEnabled, isActionModeEnabled, _filterGroupingMap) => {
  const filteredCampaigns = getFilteredCampaigns(campaigns, filters, triggers, actions, isRuleModeEnabled, isActionModeEnabled, _filterGroupingMap);
  if (campaigns.size !== filteredCampaigns.size) {
    return true;
  }
  return campaigns.some((campaign, index) =>
    (campaign.get(constants.ACTIVITIES).size !== filteredCampaigns.getIn([index, constants.ACTIVITIES]).size));
});

export function getContextMenuItemsVisibilityMap(activity) {
  const isActive = activity.get(activityConstants.STATUS) === activityConstants.ACTIVITY_STATUS_ACTIVE;
  const activityType = activity.get(activityConstants.TYPE);
  const viewStatus = activitySelectorsUtils.calculateActivityViewStatus(activity);
  const isDraft = viewStatus === activityConstants.ACTIVITY_STATUS_DRAFT;
  const shouldOneTimeAnalysisBeShown = viewStatus === activityConstants.ACTIVITY_STATUS_COMPLETED;
  const showStatsMenuItem = ([activityConstants.SERVER_TYPE_RULE, activityConstants.SERVER_TYPE_DEAL, activityConstants.SERVER_TYPE_PUNCH_CARD,
      shouldOneTimeAnalysisBeShown ? activityConstants.SERVER_TYPE_ONE_TIME_ACTION : null]
      .includes(activityType) && !isDraft)
    || (activityType === activityConstants.SERVER_TYPE_ONE_TIME_ACTION && activity.get(activityConstants.AB_TEST_MODE) && !isDraft && viewStatus === activityConstants.ACTIVITY_STATUS_COMPLETED);
  const showMoveToMenuItem = activityType !== activityConstants.SERVER_TYPE_ONE_TIME_ACTION ||
    (activityType === activityConstants.SERVER_TYPE_ONE_TIME_ACTION
      && (isDraft || viewStatus === activityConstants.ACTIVITY_STATUS_FUTURE));
  const showDeleteMenuItem = isDraft;
  const showChangeStatusMenuItem = !isDraft &&
    (activityType !== activityConstants.SERVER_TYPE_ONE_TIME_ACTION ||
      (viewStatus === activityConstants.ACTIVITY_STATUS_STOPPED
        || viewStatus === activityConstants.ACTIVITY_STATUS_FUTURE));
  const showActivateMenuItem = showChangeStatusMenuItem && !isActive;
  const showDeactivateMenuItem = showChangeStatusMenuItem && isActive;
  return {
    [activityConstants.CONTEXT_MENU_OPTION_OPEN_IN_NEW_TAB]: true,
    [activityConstants.CONTEXT_MENU_OPTION_DETAILS]: true,
    [activityConstants.CONTEXT_MENU_OPTION_STATS]: showStatsMenuItem,
    [activityConstants.CONTEXT_MENU_OPTION_MOVE_TO]: showMoveToMenuItem,
    [activityConstants.CONTEXT_MENU_OPTION_DELETE]: showDeleteMenuItem,
    [activityConstants.CONTEXT_MENU_OPTION_ACTIVATE]: showActivateMenuItem,
    [activityConstants.CONTEXT_MENU_OPTION_DEACTIVATE]: showDeactivateMenuItem
  };
}

function shouldTriggerBeShownWithActionFilters(activity, actionFilters, _filterGroupingMap) {
  const actions = activity.get(constants.ACTIONS);
  const cases = activity.get(activityConstants.CASES);
  const actionsToFilter = actionFilters.get(constants.FILTERS_GROUP_NAME_ALL_ACTION);
  return shouldActivityBeShownWithActionFilters(actions, cases, actionsToFilter, _filterGroupingMap);
}

function getActionVisibilityStatusTransformedBooleanArray(actions, cases, filters, _filterGroupingMap) {
  let statusList = [];
  // Evaluate actions
  if (actions) {
    const actionJs = actions.toJS();
    actionJs.forEach((x) => {
      Object.values(x.actionsList).filter((action) => {
        statusList.push(filters.get(getTransformedFilterNameByGroup(action.actionType, _filterGroupingMap)));
      });
    });
  }
  // Evaluate cases and actions
  if (cases) {
    const casesJs = cases.toJS();
    casesJs.forEach((x) => {
      if (x.actions) {
        Object.values(x.actions).forEach((action) => {
          Object.values(action.actionsList).filter((innerAction) => {
            statusList.push(filters.get(getTransformedFilterNameByGroup(innerAction.actionType, _filterGroupingMap)));
          });
        });
      }
    });
  }
  return statusList;
}


function shouldActivityBeShownWithActionFilters(actions, cases, filters, _filterGroupingMap) {
  const statusList = getActionVisibilityStatusTransformedBooleanArray(actions, cases, filters, _filterGroupingMap);
  const atLeastOneActionFilterSelected = statusList.includes(true);
  const noActionsAndCasesPresentInActivity = areCasesAndActionsNotPresent(actions, cases) && filters.get(constants.NO_ACTION);
  return (atLeastOneActionFilterSelected || noActionsAndCasesPresentInActivity);
}

function areCasesAndActionsNotPresent(actions, cases) {
  let counter = 0;
  if (actions) {
    const actionJs = actions.toJS();
    actionJs.forEach((x) => {
      Object.values(x.actionsList).forEach(() => {
        counter += 1;
      });
    });
  }
  // Evaluate cases and actions
  if (cases) {
    const casesJs = cases.toJS();
    casesJs.forEach((x) => {
      if (x.actions) {
        Object.values(x.actions).forEach((action) => {
          Object.values(action.actionsList).forEach(() => {
              counter += 1;
            }
          );
        });
      }
    });
  }
  return counter === 0;
}

function setActiveActionFilterNames(actions, actionsMap, _filterGroupingMap) {
  actions.forEach((value, key) => {
    const actionsList = (actions.get(key).get(activityConstants.ACTIONS_LIST));
    actionsList.forEach((actionValue, actionKey) => {
      const filterName = getTransformedFilterNameByGroup(actionsList.get(actionKey).get(activityConstants.ACTION_TYPE), _filterGroupingMap);
      // eslint-disable-next-line no-param-reassign
      actionsMap = actionsMap.set(filterName, true);
    });
  });
  return actionsMap;
}

function getTransformedFilterNameByGroup(filter, _filterGroupingMap) {
  const filterGroupName = _filterGroupingMap.get(filter);
  return filterGroupName || filter;
}

function getValidTrigger(trigger) {
  return trigger === null ? 'null' : trigger;
}

