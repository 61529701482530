import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import DisabledItem from './DisabledItem';


class DisabledItemPosRestriction extends Component {
  render() {
    const { children, overrideToolTipMessage } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line

    return (
      <DisabledItem tooltipMessage={formatMessage({ id: overrideToolTipMessage || 'activity.conditions.this.option.is.unavailable' })} automationId="pos">
        {children}
      </DisabledItem>
    );
  }
}

DisabledItemPosRestriction.propTypes = {
  children: PropTypes.node,
  overrideToolTipMessage: PropTypes.string
};

export default injectIntl(DisabledItemPosRestriction);
/*
*

* */
