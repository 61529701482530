/**
 *
 * SendTcConsent
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import * as constants from '../../../../activity.constants';

export class UnsubscribeEmailOrSms extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string,
    onActionFieldUpdate: PropTypes.func.isRequired,
    action: PropTypes.object,
    index: PropTypes.number,
  };

  componentWillMount() {
    if (!this.props.action.get(constants.UNSUBSCRIBE_TYPE)) {
      this.props.onActionFieldUpdate(constants.UNSUBSCRIBE_TYPE, constants.UNSUBSCRIBE_SMS, this.props.index);
    }
  }

  onUpdate = (e, data) => {
    this.props.onActionFieldUpdate(constants.UNSUBSCRIBE_TYPE, data.value, this.props.index);
  };

  render() {
    const { action} = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    return (
          <div data-automation-id={`${this.props.automationIdPrefix}.type`} className={'unsubscribeMethod'}>
            <Radio
              label={formatMessage({ id: `activity.action.unsubscribe.type.${constants.UNSUBSCRIBE_SMS}` })}
              value={constants.UNSUBSCRIBE_SMS}
              onClick={this.onUpdate}
              checked={action.get(constants.UNSUBSCRIBE_TYPE) === constants.UNSUBSCRIBE_SMS}
            />
            &nbsp; &nbsp;
            <Radio
              label={formatMessage({ id: `activity.action.unsubscribe.type.${constants.UNSUBSCRIBE_EMAIL}` })}
              value={constants.UNSUBSCRIBE_EMAIL}
              onClick={this.onUpdate}
              checked={action.get(constants.UNSUBSCRIBE_TYPE) === constants.UNSUBSCRIBE_EMAIL}
            />
          </div>
    );
  }
}
export default injectIntl(UnsubscribeEmailOrSms);

