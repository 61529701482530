/**
*
* TemplatesGuidanceHeader
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import ImageTextCard from '../../../../common/components/Cards/ImageTextCard';
import brickImage from '../../../../images/brick.png';
import tracker from '../../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../../common/utils/tracking/tracking.consts';

class TemplatesGuidanceHeader extends React.PureComponent {

  static propTypes = {
    createPath: PropTypes.string.isRequired,
    intl: PropTypes.object,
    createEntity: PropTypes.func.isRequired
  };

  render() {
    const { createPath, intl } = this.props;
    return (
      <div className="activityTemplatesGuidanceHeader">
        <Grid columns={3} className="guidance-container">
          <Grid.Row>
            <Grid.Column className="guidance-card-column">
              <Link
                to={{ pathname: createPath, search: location.search }}
                onClick={tracker.wrapButtonAction(this.props.createEntity,
                  trackerConstants.BUTTON_TYPE_TEMPLATE_SELECTED,
                  { type: trackerConstants.TEMPLATE_START_FROM_SCRATCH })}
              >
                <ImageTextCard
                  glowOnHover
                  image={brickImage}
                  text={intl.formatMessage({ id: 'templates.text.start' })}
                  automationId={'template-library.start-from-scratch'}
                />
              </Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <h1>
          <span className="or-select-template">
            {intl.formatMessage({ id: 'templates.text.or-select-template' })}
          </span>
        </h1>
      </div>
    );
  }
}

export default injectIntl(TemplatesGuidanceHeader);

