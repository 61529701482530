/**
 *
 * DownloadCsvFromInput
 *
 */
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import _ from 'lodash';
import './DownloadCsvFromInput.styles.less';
import PasteInputField from '../PasteToClipboardInputField/PasteInputField';
import Toast from '../../Toast/Toast';

class DownloadCsvFromInput extends React.PureComponent {

  static propTypes = {
    clearValue: PropTypes.func,
    error: PropTypes.string,
    inputValue: PropTypes.string,
    isPasting: PropTypes.bool,
    goingToPasteImportMembersData: PropTypes.func,
    filename: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    inputType: PropTypes.string,
    prefix: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.clearValue = this.clearValue.bind(this);
    this.resetToast = this.resetToast.bind(this);
    this.pasteValueToInput = this.pasteValueToInput.bind(this);
    this.state = {
      toastIsOpen: false,
      toastMessageKey: ''
    };
  }

  componentWillReceiveProps(nextProps) {
    if (_.isEmpty(this.props.inputValue) && !_.isEmpty(nextProps.inputValue)) {
      this.openToast('activity.globalConditions.members.radioBtn.import.members.list.uploaded');
    }
  }

  clearValue() {
    this.resetToast();
    this.props.clearValue();
  }

  pasteValueToInput(value) {
    this.props.onChange(value, null, this.props.inputType);
  }

  openToast(messageKey) {
    this.setState({
      toastIsOpen: true,
      toastMessageKey: messageKey
    });
  }

  resetToast() {
    this.setState({
      toastIsOpen: false,
      toastMessageKey: ''
    });
  }

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const isInputValueNotEmpty = this.props.inputValue.length > 0;

    return (
      <div className="download-input-to-csv-container">
        <PasteInputField
          clearValue={this.clearValue}
          error={this.props.error}
          inputValue={this.props.inputValue}
          goingToPasteImportMembersData={this.props.goingToPasteImportMembersData}
          name={this.props.name}
          onPaste={this.pasteValueToInput}
          prefix={this.props.prefix}
          showLoader={this.props.isPasting}
        />
        { isInputValueNotEmpty &&
          <CSVLink
            className="download-to-csv"
            data={this.props.inputValue}
            filename={this.props.filename}
          >
            {formatMessage({ id: 'activity.globalConditions.members.radioBtn.import.members.download.as.csv' })}
          </CSVLink>
        }
        <Toast
          className="paste-input-toast"
          isOpen={this.state.toastIsOpen}
          messageKey={this.state.toastMessageKey}
          onClose={this.resetToast}
        />
      </div>
    );
  }
}

export default injectIntl(DownloadCsvFromInput);
