import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import Modal from '../../../../common/components/CustomModal/CustomModal';
import Dropdown from '../../../../common/components/DropdownWrapper/DropdownWrapper';
import * as constants from '../../businessCenter.constants';
import ConfirmationModal from '../../../../common/components/CustomModal/ConfirmationModal/ConfirmationModal';
import * as activityConstants from '../../../Activity/activity.constants';

import './ActivityMoveTo.styles.less';

const AUTOMATION_NAME = 'activity-move-to';

class ActivityMoveTo extends React.PureComponent {

  static propTypes = {
    options: PropTypes.object.isRequired,
    trigger: PropTypes.node,
    content: PropTypes.node,
    children: PropTypes.node,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    activityStatus: PropTypes.string
  };

  constructor(props) {
    super(props);

    const generatedOptions = this.generateDropDownOptions(props.options);
    this.state = {
      isModalOpen: props.open || false,
      options: generatedOptions,
      value: generatedOptions[0] ? generatedOptions[0].value : null
    };
  }

  componentWillReceiveProps(nextProps) {
    const { open, options } = this.props;
    if (open !== nextProps.open) {
      this.setState({ isModalOpen: nextProps.open });
    }

    if (options !== nextProps.options) {
      const generatedOptions = this.generateDropDownOptions(nextProps.options);
      this.setState({ options: generatedOptions, value: generatedOptions[0] ? generatedOptions[0].value : null });
    }
  }

  handleOnMove = () => {
    const { options, activityStatus } = this.props;
    const option = options.find((opt) => opt[constants.ID] === this.state.value);

    if (option[constants.CAMPAIGN_STATUS] !== constants.CAMPAIGN_STATUS_ACTIVE && (
      activityStatus === activityConstants.ACTIVITY_STATUS_FUTURE ||
      activityStatus === activityConstants.ACTIVITY_STATUS_ACTIVE)) {
      return this.setState({ isMoveToArchivedCampaignOpen: true });
    }

    return this.handleOnConfirm();
  };

  handleOnConfirm = () => {
    const { onConfirm } = this.props;
    if (onConfirm) {
      onConfirm(this.state.value);
    }

    this.handleModalClose();
  };

  handleOnClose = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }

    this.handleModalClose();
  };

  handleModalOpen = () => {
    this.setState({
      isModalOpen: true
    });
  };

  handleModalClose = () => {
    this.setState({
      isModalOpen: false,
      isMoveToArchivedCampaignOpen: false
    });
  };

  modalActionsHtml() {
    const { formatMessage } = this.props.intl; // eslint-disable-line
    return [
      <Button
        key="move"
        data-automation-id={`${AUTOMATION_NAME}.btn.move`}
        onClick={this.handleOnMove}
      >
        {formatMessage({ id: 'general.button.move' })}
      </Button>
    ];
  }

  generateDropDownOptions(options) {
    return options.map(
      (option, index) => ({ key: index, value: option[constants.ID], text: option[constants.CAMPAIGN_NAME] })
    ).toJS();
  }

  handleOnSelect = (e, { value }) => {
    this.setState({ value });
  };

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line
    const { trigger } = this.props;
    const { options, value, isMoveToArchivedCampaignOpen } = this.state;

    if (isMoveToArchivedCampaignOpen) {
      return (
        <ConfirmationModal
          open={this.state.isMoveToArchivedCampaignOpen}
          onConfirm={this.handleOnConfirm}
          onCancel={this.handleOnClose}
        >
          {formatMessage({ id: 'business-center.campaign.activity.content.move-to.archived-campaign' })}
        </ConfirmationModal>
      );
    }

    return (
      <Modal
        className="activity-move-to--modal como-dialog"
        header={formatMessage({ id: 'business-center.campaign.activity.header.move-to' })}
        trigger={trigger}
        onOpen={this.handleModalOpen}
        onClose={this.handleOnClose}
        content={
          <Dropdown
            onSelectOption={this.handleOnSelect}
            placeholder={formatMessage({ id: 'business-center.campaign.activity.placeholder.move-to.dropdown' })}
            search
            selection
            options={options}
            prefix={AUTOMATION_NAME}
            value={value}
          />
        }
        open={this.state.isModalOpen}
        actions={this.modalActionsHtml()}
        automationIdPrefix={AUTOMATION_NAME}
        size="mini"
        closeOnDimmerClick
      />
    );
  }
}

export default injectIntl(ActivityMoveTo);
