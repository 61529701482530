export const COMPONENT_TYPE_TEXT = 'span';
export const COMPONENT_TYPE_PARAGRAPH = 'p';
export const COMPONENT_TYPE_LINK = 'a';
export const ENTITY_NAME = 'name';
export const ENTITY_DESCRIPTION = 'description';
export const BACK_BUTTON_NAME = 'back';
export const CANCEL_BUTTON_NAME = 'cancel';

export const SET_MENTION_COMPONENT_COPIED_VALUE = 'setMentionComponentCopiedValue';
export const MENTION_COPIED_VALUE = 'mentionComponentCopiedValue';
