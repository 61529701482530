import { fromJS } from 'immutable';
import * as constants from './gifts.constants';
import * as activityConstants from '../Activity/activity.constants';
import * as utils from './utils/gifts.reducer.utils';

const initialState = fromJS({
  [constants.FILTERS]: {
    [constants.GIFTS]: {
      [activityConstants.FILTERS_ACTIVITY_ACTIVE]: true,
      [activityConstants.FILTERS_ACTIVITY_DRAFT]: true,
      [activityConstants.FILTERS_ACTIVITY_COMPLETED]: false,
      [activityConstants.FILTERS_ACTIVITY_STOPPED]: true,
      [activityConstants.FILTERS_ACTIVITY_FUTURE]: true
    },
  },
  [constants.SORT]: {
    [constants.ORDER]: constants.ORDER_DESCENDING
  },
  [constants.GIFTS]: [],
  [constants.GIFTS_ACTIVITY_TAGS]: [],
  [constants.GIFTS_LOADED]: false
});

export default function giftsReducer(state = initialState, action) {

  switch (action.type) {

    case constants.GET_GIFTS: {
      return state.set(constants.GIFTS_LOADED, false);
    }

    case constants.GET_GIFTS_SUCCESS: {
      return state
        .set(constants.GIFTS, fromJS(action.data))
        .set(constants.GIFTS_ACTIVITY_TAGS, utils.getGiftsActivityTags(action.data))
        .set(constants.GIFTS_LOADED, true);
    }

    case constants.SET_GIFTS_LOADED: {
      return state.set(constants.GIFTS_LOADED, action.isLoaded);
    }

    case constants.UPDATE_FILTERS: {
      const { status, key, groupKey } = action;
      return state.setIn([constants.FILTERS, groupKey, key], status);
    }

    case constants.UPDATE_ORDER: {
      return state
        .setIn([constants.SORT, constants.ORDER],
          state.getIn([constants.SORT, constants.ORDER]) === constants.ORDER_DESCENDING ?
            constants.ORDER_ASCENDING : constants.ORDER_DESCENDING
        );
    }

    case constants.HIGHLIGHT_ACTIVITY: {
      if (action[activityConstants.HIGHLIGHTED_ACTIVITY_ID]) {
        return state.set(activityConstants.HIGHLIGHTED_ACTIVITY_ID, action[activityConstants.HIGHLIGHTED_ACTIVITY_ID]);
      }
      return state.delete(activityConstants.HIGHLIGHTED_ACTIVITY_ID);
    }

    case constants.SEARCH_GIFTS: {
      return state.set(constants.SEARCH_TERM, action[constants.SEARCH_TERM]);
    }

    case constants.UPDATE_GIFT_SUCCESS: {
      const newGift = action.data;
      const newGiftIndex = state.get(constants.GIFTS).findIndex((gift) =>
        gift.get(activityConstants.HUB_ID) === newGift[activityConstants.HUB_ID]);
      return state.setIn([constants.GIFTS, newGiftIndex], fromJS(newGift));
    }

    case constants.CLEAN_GIFT_STATS: {
      return state.delete(constants.GIFT_STATS);
    }

    case constants.GET_GIFT_STATS_SUCCESS: {
      const stats = action.data;
      return state.set(constants.GIFT_STATS, fromJS(stats));
    }

    case constants.DELETE_GIFT_SUCCESS: {
      const filteredGiftsList = utils.removeGiftFromList(state.get(constants.GIFTS), action[activityConstants.HUB_ID]);
      return state.set(constants.GIFTS, filteredGiftsList);
    }


    case constants.CLEAN_GIFT_RELATED_ACTIVITIES_DATA: {
      return state.delete(constants.GIFT_USAGES);
    }

    case constants.GET_GIFT_RELATED_ACTIVITIES_SUCCESS: {
      const usages = action.data;
      return state.set(constants.GIFT_USAGES, fromJS(usages));
    }

    default:
      return state;
  }

}
