import { fromJS } from 'immutable';
import * as constantsTypes from './constants/types';
import * as constants from './constants/constants';
import * as goalsConstants from '../goals.constants';

const Tool = {
  [goalsConstants.GOAL_TOOL_TEMPLATES_RULES_POINTERS]: [],
  [goalsConstants.GOAL_TOOL_TEMPLATES_DEALS_POINTERS]: [],
  [goalsConstants.GOAL_TOOL_TEMPLATES_ONETIME_POINTERS]: []
};

const goal = {
  [goalsConstants.GOAL_NAME]: '',
  [goalsConstants.GOAL_ICON]: '',
  [goalsConstants.GOAL_TOOLS]: [Tool]
};

export const initState = fromJS({
  [constants.RULES_PRODUCT_TEMPLATES]: [],
  [constants.DEALS_PRODUCT_TEMPLATES]: [],
  [constants.ONE_TIME_ACTIONS_PRODUCT_TEMPLATES]: [],
  [constants.GOAL]: goal,
  [constants.GOALS]: []
});

const goalsAdmin = (state = initState, action) => {
  const { type, data, payload } = action;
  let newState;

  switch (type) {
    case constantsTypes.GET_DEALS_PRODUCT_TEMPLATES_SUCCESS:
      newState = state.setIn([constants.DEALS_PRODUCT_TEMPLATES], fromJS(data));
      break;
    case constantsTypes.GET_ONE_TIME_ACTIONS_PRODUCT_TEMPLATES_SUCCESS:
      newState = state.setIn([constants.ONE_TIME_ACTIONS_PRODUCT_TEMPLATES], fromJS(data));
      break;
    case constantsTypes.GET_RULES_PRODUCT_TEMPLATES_SUCCESS:
      newState = state.setIn([constants.RULES_PRODUCT_TEMPLATES], fromJS(data));
      break;
    case constantsTypes.UPDATE_TOOL:
      newState = state.setIn([constants.GOAL, goalsConstants.GOAL_TOOLS, `${payload.index}`, `${payload.key}`], fromJS(payload.value));
      break;
    case constantsTypes.ADD_TOOL:
      newState = state.setIn([constants.GOAL, goalsConstants.GOAL_TOOLS], state.getIn([constants.GOAL, goalsConstants.GOAL_TOOLS]).push(fromJS(Tool)));
      break;
    case constantsTypes.CHANGE_GOAL_NAME:
      newState = state.setIn([constants.GOAL, goalsConstants.GOAL_NAME], fromJS(payload));
      break;
    case constantsTypes.CHANGE_GOAL_ICON:
      newState = state.setIn([constants.GOAL, goalsConstants.GOAL_ICON], fromJS(payload));
      break;
    case constantsTypes.CREATE_GOAL_SUCCESS:
      newState = state.setIn([constants.GOAL], fromJS(goal));
      break;
    case constantsTypes.GET_GOALS_SUCCESS:
      newState = state.setIn([constants.GOALS], fromJS(data));
      break;
    case constantsTypes.SELECT_GOAL:
      const selectedGoal = state.getIn([constants.GOALS]).find((g) => g.get(goalsConstants.GOAL_DB_ID) === payload);
      newState = state.setIn([constants.GOAL], fromJS(selectedGoal));
      break;
    case constantsTypes.UPDATE_GOAL_SUCCESS:
      newState = state.setIn([constants.GOAL], fromJS(goal));
      const goalIndex = newState.getIn([constants.GOALS]).findIndex((g) => g.get(goalsConstants.GOAL_DB_ID) === data[goalsConstants.GOAL_DB_ID]);
      newState = newState.setIn([constants.GOALS, `${goalIndex}`], data);
      break;
    default:
      newState = state;
  }

  return newState;
};

export default goalsAdmin;
