import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import * as constants from '../../activity.constants';
import PopupWrapper from '../../../../common/components/PopupWrapper/PopupWrapper';
import PopupTrigger from '../../../../common/components/PopupTrigger/PopupTrigger';

import withAuthorization from '../../../../common/helpers/authorization';
import DependentOnPosOrAddOnItem from '../../../../common/components/DisabledItem/DependentOnPosOrAddOnItem';
import Tooltip from '../../../../common/components/Tooltip/Tooltip';

class ConditionKeyListPopup extends React.PureComponent {

  static propTypes = {
    disabled: PropTypes.bool,
    options: PropTypes.array,
    onSelectOption: PropTypes.func,
    value: PropTypes.string,
    index: PropTypes.number,
    intl: PropTypes.object,
    error: PropTypes.string,
    getRestrictions: PropTypes.func,
    getBusinessBundlePlan: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false,
      openedTooltip: null };
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onOptionSelect = this.onOptionSelect.bind(this);
    this.getValueToDisplay = this.getValueToDisplay.bind(this);

  }

  onOpen(tooltipId) {
    setTimeout(() => this.setState({ openedTooltip: tooltipId }), 1500);
  }

  onClose() {
    this.setState({ openedTooltip: null });
  }

  onOptionSelect(name, value) {
    this.props.onSelectOption(null, { name, value });
    this.handleOnPopupClose();
  }

  getValueToDisplay() {
    const { formatMessage } = this.props.intl;
    const returnObj = {};
    returnObj.errorMsg = '';
    returnObj.isOk = true;
    const valueObj = this.props.options.find((option) => option[constants.OPTION_KEY] === this.props.value, this);
    // this might happen if the condition previously saved in the rule was after that removed from the registration form
    if (!valueObj) {
      returnObj.isOk = false;
      returnObj.errorMsg = formatMessage({ id: 'activity.globalConditions.missingField' });
      returnObj.text = this.props.value;
    } else {
      returnObj.text = valueObj[constants.TEXT];
    }
    return returnObj;
  }

  getConditionNode = (condition) => {
    const { formatMessage } = this.props.intl;
    const businessPlan = this.props.getBusinessBundlePlan();
    const restrictions = this.props.getRestrictions(condition[constants.FEATURES], businessPlan);
    const hide = restrictions && (
      restrictions.get(constants.HIDE_BY_BUSINESS_PLAN)
    );
    if (hide) {
      return null;
    }
    const disabled = restrictions && (
      restrictions.get(constants.RESTRICTED_BY_POS_FEATURES)
      || restrictions.get(constants.RESTRICTED_BY_ADD_ON_FEATURES)
    );
    const className = classNames({
      selected: condition[constants.VALUE] === this.props.value,
      disabled
    });
    return (<li
      key={`global_members_${condition[constants.KEY]}`}
      onClick={() => !disabled && this.onOptionSelect(constants.CONDITION_KEY, condition[constants.OPTION_KEY])}
      data-automation-id={`globalConditions.members.${this.props.index}.${constants.CONDITION_KEY}.${condition[constants.KEY]}`}
      value={condition[constants.OPTION_KEY]}
      className={className}
    >
      <DependentOnPosOrAddOnItem restrictions={restrictions} conditionFeatures={condition[constants.FEATURES]}>
        {condition[constants.TEXT]}
      </DependentOnPosOrAddOnItem>
      { condition.isAutoTag &&
      <Tooltip
        open={this.state.openedTooltip === condition[constants.KEY]}
        onOpen={() => this.onOpen(condition[constants.KEY])}
        onClose={this.onClose}
        content={<p>{formatMessage({ id: 'activity.condition.auto.tag.tooltip' })} <a target="_blank" href={'https://knowledge.comosense.com/hc/en-us/articles/360004224314-Auto-Customer-Segmentation'}>{formatMessage({ id: 'activity.condition.auto.tag.tooltip.more.link' })}</a></p>}
        trigger={<span className="auto-tag" > {formatMessage({ id: 'auto.tag.condition.label' })}</span>}
        position="bottom left"
      />

    }
    </li>);
  };

  handleOnPopupOpen = () => {
    this.setState({
      isPopupOpen: true
    });

  };

  handleOnPopupClose = () => {
    this.setState({
      isPopupOpen: false
    });
  };

  render() {
    const { formatMessage } = this.props.intl;
    const valueToDisplay = this.props.value
      ? this.getValueToDisplay()
      : { isOk: true, text: formatMessage({ id: 'activity.globalConditions.identifyMemberBy' }) };
    return (
      <PopupWrapper
        open={this.state.isPopupOpen}
        className="condition-key-popup"
        disabled={this.props.disabled}
        position="bottom left"
        fixed
        hideTrigger={false}
        on="click"
        onOpen={this.handleOnPopupOpen}
        onClose={this.handleOnPopupClose}
        size="large"
        error={this.props.error}
        automationId={`globalConditions.members.condition.${this.props.index}.fields.select.popup`}
        trigger={
          <div className="inline-div">
            <PopupTrigger
              className={`ui fluid selection dropdown hideTrigger ${this.props.value ? '' : 'placeholder'} ${valueToDisplay.isOk ? '' : 'error'}`}
              message={valueToDisplay.text}
              automationId={`globalConditions.members.condition.${this.props.index}.fields.select`}
            />
          </div>
        }
      >
        <ul className="three-columned selectable-options">
          {
            this.props.options.map((option) => this.getConditionNode(option))
          }
        </ul>
      </PopupWrapper>);
  }
}

export default withAuthorization(injectIntl(ConditionKeyListPopup));
