/*
 *
 * ComilliaHint constants
 *
 */

// ACTIONS CONSTANTS

export const DEFAULT_ACTION = 'app/ComilliaHint/DEFAULT_ACTION';
export const HANDLE_HINT_APPEARANCE = 'app/ComilliaHint/HANDLE_HINT_APPEARANCE';

// STORE CONSTANTS

export const TOUCHED_COUNT = 'touchedCount';
export const DATA_LOADING = 'loading';
export const SHOW_HINT = 'show';
export const FILTERED_COUNT = 'filteredCount';

// todo: return 500 and 0.05
export const TOUCHED_BY_COMMUNICATION_MIN_NUMBER = 500;
export const TOUCHED_BY_COMMUNICATION_MIN_PERCENT = 0.05;
