import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Dimmer, Loader, Table } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import Tooltip from '../../../common/components/Tooltip/Tooltip';
import Modal from '../../../common/components/CustomModal/CustomModal';
import * as activityConstants from '../../Activity/activity.constants';
import injectSaga from '../../../utils/injectSaga';
import * as emailsActions from '../emails.actions';
import emailsSaga from '../emails.saga';
import * as selectors from '../emails.selectors';
import * as activityApi from '../../Activity/activity.api';
import * as businessCenterActions from '../../BusinessCenter/businessCenter.actions';
import ActivityType from '../../../common/components/ActivityType/ActivityType';
import ActivityStatus from '../../Activity/views/ActivityStatus';
import { calculateActivityViewStatus } from '../../Activity/activity.selectors.utils';
import businessSaga from '../../BusinessCenter/businessCenter.saga';
import * as reducerConstants from '../../../constants/reducer.constants';

const NAME_LETTERS_MAX = 25;

class EmailUsages extends React.PureComponent {
  static propTypes = {
    actions: PropTypes.object,
    email: PropTypes.object.isRequired,
    onConfirm: PropTypes.func,
    open: PropTypes.bool,
    usages: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = { isTooltipOpen: false };
    this.onTooltipOpen = this.onTooltipOpen.bind(this);
    this.onTooltipClose = this.onTooltipClose.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open) {
      this.props.actions.getEmailUsages(this.props.email.get(activityConstants.HUB_ID));
    }
  }

  onTooltipOpen() {
    setTimeout(() => this.setState({ isTooltipOpen: true }), 2000);
  }

  onTooltipClose() {
    this.setState({ isTooltipOpen: false });
  }

  onClose() {
    this.props.actions.cleanEmailUsagesData();
    this.props.onConfirm();
  }

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line
    const { open, email, usages } = this.props;
    const name = email.get(activityConstants.NAME);
    const title = <p>{formatMessage({ id: 'email.related.activities.title' }, { name })}</p>;
    return (
      <Modal
        open={open}
        className="modal-email-related-activities"
        onClose={this.onClose}
        automationIdPrefix="email.related.activities"
        header={
          <div className="type-and-name">
            {name.length > NAME_LETTERS_MAX ?
              <Tooltip
                content={title}
                trigger={title}
                position="bottom right"
              /> : title
            }
          </div>
        }

        content={
          <div className="email-related-activities-modal-content">
            <Dimmer inverted active={!usages}>
              <Loader active />
            </Dimmer>
            {
              (usages && usages.size > 0) &&
              <Table className="email-related-activities-table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell className="type-column">
                      {formatMessage({ id: 'email.related.activities.activity.type' })}
                    </Table.HeaderCell>
                    <Table.HeaderCell className="name-column">
                      {formatMessage({ id: 'email.related.activities.activity.name' })}
                    </Table.HeaderCell>
                    <Table.HeaderCell className="status-column">
                      {formatMessage({ id: 'email.related.activities.activity.status' })}
                    </Table.HeaderCell>
                    <Table.HeaderCell className="link-column">
                      {formatMessage({ id: 'email.related.activities.activity.link' })}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {usages.map((activity, index) => {
                    const type = activityApi.transformServerTypeToActivityType(activity.get(activityConstants.TYPE));
                    return (<Table.Row key={`related_activity_${index.toString()}`}>
                      <Table.Cell>
                        <ActivityType type={activity.get(activityConstants.TYPE)} />
                      </Table.Cell>
                      <Table.Cell>
                        {activity.get('name')}
                      </Table.Cell>
                      <Table.Cell>
                        <ActivityStatus
                          status={calculateActivityViewStatus(activity)}
                          messagesNamespace="business-center"
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <a
                          role="button"
                          key="open"
                          onClick={() => this.props.actions.goToActivity(type, activity.get(activityConstants.HUB_ID))}
                        >
                          {formatMessage({ id: 'email.related.activities.activity.link.button' })}
                        </a>
                      </Table.Cell>
                    </Table.Row>);
                  })
                  }
                </Table.Body>
              </Table>
            }
            {
              (!usages || usages.size === 0) &&
              <div className="no-activities-message">
                {formatMessage({ id: 'email.related.activities.no.related.activities' })}
              </div>
            }
          </div>
        }

        actions={[
          <a role="button" key="gotIt" onClick={this.onClose}>{formatMessage({ id: 'general.button.got-it' })}</a>
        ]}
        closeOnDimmerClick
      />
    );
  }
}

const mapStateToProps = (state) => ({
  usages: selectors.getEmailUsages(state)
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...emailsActions, goToActivity: businessCenterActions.goToActivity }, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.EMAILS_BRANCH, saga: emailsSaga });
const withBusinessSaga = injectSaga({ key: reducerConstants.BUSINESS_CENTER_BRANCH, saga: businessSaga });

export default compose(
  withConnect,
  withSaga,
  withBusinessSaga,
  injectIntl
)(EmailUsages);
