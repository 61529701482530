import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Radio } from 'semantic-ui-react';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import * as activityConstants from '../../../../activity.constants';
import DateTimePicker from '../../../../../../common/components/CustomDatePicker/DateTimePicker';
import * as constants from './scheduleCondition.constants';
import './scheduleCondition.styles.less';

class ScheduleGlobalConditions extends React.PureComponent {

  static propTypes = {
    schedulingData: PropTypes.object,
    onRadioChange: PropTypes.func.isRequired,
    errors: PropTypes.object,
    updateDate: PropTypes.func.isRequired,
    businessTimezone: PropTypes.string,
    datePickerInsideModal: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.updateDateToNow = this.updateDateToNow.bind(this);
  }

  updateDateToNow() {
    this.props.updateDate(moment().tz(this.props.businessTimezone));
  }

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const schedulingDate = this.props.schedulingData ? moment.utc(this.props.schedulingData.get(activityConstants.SCHEDULE_DATE)).tz(this.props.businessTimezone) : moment.tz(this.props.businessTimezone);
    const schedulingType = this.props.schedulingData ? this.props.schedulingData.get(activityConstants.TYPE) : null;
    const topButtonsData = [{
      'id': 'now',
      'messageKey': 'datePicker.selectedStart.now',
      'callback': this.updateDateToNow
    }];

    return (
      <div className="choose-schedule-group">
        <p className="dateTime-rule-title">{formatMessage({ id: 'activity.globalConditions.schedule.subTitle' })}</p>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <Radio
                label={formatMessage({ id: 'activity.globalConditions.schedule.radioBtn.immediate' })}
                name="scheduleGroup"
                value={activityConstants.SCHEDULE_IMMEDIATE}
                checked={schedulingType === activityConstants.SCHEDULE_IMMEDIATE}
                onChange={this.props.onRadioChange}
                data-automation-id="globalConditions.schedule.radioBtn.immediate"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <Radio
                label={formatMessage({ id: 'activity.globalConditions.schedule.radioBtn.scheduled' })}
                name="scheduleGroup"
                value={activityConstants.SCHEDULE_SCHEDULED}
                checked={schedulingType === activityConstants.SCHEDULE_SCHEDULED}
                onChange={this.props.onRadioChange}
                data-automation-id="globalConditions.schedule.radioBtn.scheduled"
              />
            </Grid.Column>
            <Grid.Column width={12}>
              {
          schedulingType === activityConstants.SCHEDULE_SCHEDULED &&
            <div>
              <DateTimePicker
                selectedDate={schedulingDate}
                updateDate={this.props.updateDate}
                error={this.props.errors ? this.props.errors.get(activityConstants.SCHEDULE_SCHEDULED) : null}
                dataAutomationId="globalConditions.schedule.date"
                minDate={moment().tz(this.props.businessTimezone)}
                maxDate={moment().tz(this.props.businessTimezone).add(constants.MAX_SCHEDULED_RANGE_MONTHS, 'months')}
                topButtonsData={topButtonsData}
                datePickerInsideModal={this.props.datePickerInsideModal}
              />
              <span className="hint after-date-picker">{formatMessage({ id: 'globalConditions.schedule.date.maximum.hint' })}</span>
            </div>
        }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default injectIntl(ScheduleGlobalConditions);
