import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import withAuthorization from '../../../common/helpers/authorization';

const AdminRoute = ({ component: Component, hasFullPermission, redirectTo, featureName, ...rest }) => (
  <Route
    {...rest}
    render={(props) => hasFullPermission(featureName)
      ? <Component {...props} />
      : <Redirect
        to={{
          pathname: redirectTo,
          search: props.location.search
        }}
      />
    }
  />
);

AdminRoute.propTypes = {
  hasFullPermission: PropTypes.func.isRequired,
  featureName: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  redirectTo: PropTypes.string.isRequired
};

export default withAuthorization(AdminRoute);
