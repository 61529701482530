// ACTIONS CONSTANTS

export const GET_ITEM_GROUPS = 'app/ItemGroups/GET_ITEM_GROUPS';
export const GET_ITEM_GROUPS_SUCCESS = 'app/ItemGroups/GET_ITEM_GROUPS_SUCCESS';

export const CREATE_ITEMS_GROUP = 'app/ItemGroups/CREATE_ITEMS_GROUP';
export const CREATE_ITEMS_GROUP_SUCCESS = 'app/ItemGroups/CREATE_ITEMS_GROUP_SUCCESS';
export const CREATE_ITEMS_GROUP_ERROR = 'app/ItemGroups/CREATE_ITEMS_GROUP_ERROR';

export const UPDATE_ITEMS_GROUP = 'app/ItemGroups/UPDATE_ITEMS_GROUP';

export const DEACTIVATE_ITEMS_GROUP = 'app/ItemGroups/DEACTIVATE_ITEMS_GROUP';
export const DEACTIVATE_ITEMS_GROUP_SUCCESS = 'app/ItemGroups/DEACTIVATE_ITEMS_GROUP_SUCCESS';
export const DEACTIVATE_ITEMS_GROUP_ERROR = 'app/ItemGroups/DEACTIVATE_ITEMS_GROUP_ERROR';

// STORE CONSTANTS
export const ITEM_GROUPS = 'itemGroups';
export const LIST = 'list';
export const ITEM_GROUPS_LOADED = 'itemGroupsLoaded';

export const HUB_ID = 'hubId';
export const NAME = 'name';
export const SERVER_ID = 'serverId';
export const ID = 'id';

export const DEACTIVATE_IN_PROGRESS = 'itemsGroupDeactivateInProgress';
