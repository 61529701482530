import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import './BackLink.styles.less';

const AUTOMATION_NAME = 'link.back';

const BackLink = ({ pathname, border, intl, className, ...rest }) =>  //eslint-disable-line
  (<Link
    to={{ pathname, search: location.search }}
    className={classNames(className, { [`${className}--border`]: border })}
    data-automation-id={AUTOMATION_NAME}
    {...rest}
  >
    <Icon className="como-ic-angle-left" />
    <span>{intl.formatMessage({ id: 'general.link.back' })}</span>
  </Link>
);

BackLink.propTypes = {
  pathname: PropTypes.string.isRequired,
  border: PropTypes.bool,
  className: PropTypes.string
};

BackLink.defaultProps = {
  border: false,
  className: 'link-back-default'
};

export default injectIntl(BackLink);
