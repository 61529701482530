import axios from 'axios';

export function getGifts() {
  return axios.get('/api/Gifts').then((res) => res.data);
}

export function getGift(giftId) {
  return axios.get(`/api/Gifts/${giftId}`).then((res) => res.data);
}

export function getGiftRelatedActivities(giftId) {
  return axios.get(`/api/Gifts/relatedActivities/${giftId}`).then((res) => res.data);
}

export function saveGift(gift) {
  return axios.post('/api/Gifts', gift).then((res) => res.data);
}

export function getGiftStats(giftId) {
  return axios.get(`/api/Gifts/GetStats?hubGiftId=${giftId}`).then((res) => res.data);
}

export function deleteGift(giftId) {
  return axios.delete(`/api/Gifts/${giftId}`).then((res) => res.data);
}

export function getMigratedGiftData(data) {
  return axios.get(`/api/Gifts/GetMigratedGift?migrationId=${data.activityId}`).then((res) => res.data);
}

export function neutralizeHub1Gift(giftId) {
  return axios.get(`/api/Gifts/NeutralizeHub1Gift?giftId=${giftId}`).then((res) => res.data);
}
