import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import classNames from 'classnames';
import * as activityConstants from '../../../activity.constants';
import Tooltip from '../../../../../common/components/Tooltip/Tooltip';
import {
  getDaysConditions,
  getStartDate,
  buildFormattedDateString,
  getEndDate
} from '../../../reducers/utils/activity.datetime.reducer.utils';
import * as dateConstants from '../../../../../common/utils/dateTime.constants';
import ValidationErrorMessage from '../../../../../common/components/ValidationErrorMessage/ValidationErrorMessage';
import * as commonComponentsConstants from '../../../../../common/components/common.components.constants';

class DateTimeToolTip extends React.PureComponent {

  // todo: after we decide intl props included or not in contract
  static propTypes = {
    openModal: PropTypes.func,
    conditionType: PropTypes.string,
    conditionData: PropTypes.object,
    locale: PropTypes.string,
    localeDateFormat: PropTypes.string,
    localeTimeFormat: PropTypes.string,
    intl: PropTypes.object,
    validationErrors: PropTypes.object,
    textPrefix: PropTypes.string,
    businessTimezone: PropTypes.string
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      isPopupOpen: false
    };
    this.openToolTip = this.openToolTip.bind(this);
    this.closeToolTip = this.closeToolTip.bind(this);
    this.getTriggerMessage = this.getTriggerMessage.bind(this);
    this.getStartsMsg = this.getStartsMsg.bind(this);
    this.getExpiresMsg = this.getExpiresMsg.bind(this);
    this.getContent = this.getContent.bind(this);
  }

  /**
   * If starts date is later then now "Starts on ..." msg is added to the trigger message
   * @returns {string}
   */
  getStartsMsg() {
    if (this.props.conditionType === activityConstants.DATETIME_ANY || !this.props.conditionData) {
      return '';
    }
    const { formatMessage } = this.props.intl;

    const conditionData = this.props.conditionData.has(activityConstants.DATES_RANGE_CONDITION) ? this.props.conditionData.get(activityConstants.DATES_RANGE_CONDITION) : this.props.conditionData;
    const date = getStartDate(conditionData);
    const dateObject = moment.utc(date).tz(this.props.businessTimezone);
    return dateObject.isAfter(moment.tz(this.props.businessTimezone))
      ? `${formatMessage({ id: `${this.textPrefix}dateTimeGlobalConditions.startsOn` })} ${dateObject.format(this.props.localeDateFormat)}`
      : '';
  }

  /**
   * If end date exists add Expires on date message
   * @returns {string}
   */
  getExpiresMsg() {
    const { formatMessage } = this.props.intl;
    let dateMsg = formatMessage({ id: 'dateTimeGlobalConditions.neverExpires' });
    if (this.props.conditionData.getIn([activityConstants.DATES_RANGE_CONDITION, activityConstants.OPERATOR_KEY]) !== dateConstants.DATE_TIME_IS_AFTER) {
      dateMsg = `${formatMessage({ id: `${this.textPrefix}dateTimeGlobalConditions.expiresOn` })} ${moment.utc(this.props.conditionData.getIn([activityConstants.DATES_RANGE_CONDITION, activityConstants.CONDITION_VALUE, dateConstants.DATE_TO])).tz(this.props.businessTimezone).format(this.props.localeDateFormat)}`;
    }
    return dateMsg;
  }

  getTriggerMessage() {

    const { formatMessage } = this.props.intl;
    let msg = '';
    if (this.props.conditionType === activityConstants.DATETIME_ANY || !this.props.conditionData) {
      msg = formatMessage({ id: 'dateTimeGlobalConditions.atAnyDateAndTime' });
    } else {
      const startsMsg = this.getStartsMsg();
      const expiresMsg = this.getExpiresMsg();
      msg = `${startsMsg} ${startsMsg && expiresMsg ? ', ' : ''} ${expiresMsg}`;

      // days msg
      const daysConditions = getDaysConditions(this.props.conditionData, this.props.intl, this.props.locale, this.props.localeTimeFormat);
      if (daysConditions && daysConditions.length !== 0) {
        msg = `${msg}${formatMessage({ id: 'dateTimeGlobalConditions.trigger.conditionsSeparator' })} `;
      }
      daysConditions.map((condition, index) => (
        msg = index !== 0 ? `${msg}, ${condition}` : `${msg} ${condition}`
      ));
    }
    return msg;
  }


  getDateTimeRangeConditionValueAsString = (conditionData) => {
    const { formatMessage } = this.props.intl;
    const startDate = getStartDate(conditionData);
    let formattedStartDate;
    const defaultStartDateTextKey = 'datePicker.selectedStart.onOrBefore';
    if (startDate) {
      formattedStartDate = moment.utc(startDate).tz(this.props.businessTimezone).format(this.props.localeDateFormat);
    } else {
      formattedStartDate = formatMessage({ id: defaultStartDateTextKey });
    }
    const endDate = getEndDate(conditionData);
    let formattedEndDate;
    if (endDate) {
      formattedEndDate = moment.utc(endDate).tz(this.props.businessTimezone).format(this.props.localeDateFormat);
    } else {
      formattedEndDate = formatMessage({ id: 'datePicker.selectedEnd.forever' });
    }
    const hasStartDate = !!startDate;
    return buildFormattedDateString(formattedStartDate, formattedEndDate, true, hasStartDate, this.props.intl);
  };


  getContent() {
    if (this.props.conditionType === activityConstants.DATETIME_ANY || !this.props.conditionData) {
      return null;
    }
    const conditionData = this.props.conditionData.has(activityConstants.DATES_RANGE_CONDITION) ? this.props.conditionData.get(activityConstants.DATES_RANGE_CONDITION) : this.props.conditionData;
    const dateTimeCondition = this.getDateTimeRangeConditionValueAsString(conditionData);
    const daysConditions = getDaysConditions(this.props.conditionData, this.props.intl, this.props.locale, this.props.localeTimeFormat);
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <div className="tooltip-dates-conditions tooltip-conditions-part">
              <p>{dateTimeCondition}</p>
            </div>
            <div className="tooltip-days-conditions tooltip-conditions-part">
              {
              daysConditions.map((condition, index) => (
                <p key={`dayCondition_${index}`}>{condition}</p>
              ))
            }
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  get textPrefix() {
    return this.props.textPrefix ? `${this.props.textPrefix}.` : '';
  }

  openToolTip() {
    // if popup was closed now and type is not ANY
    if (!this.state.isPopupOpen && this.props.conditionType !== activityConstants.DATETIME_ANY) {
      this.setState({ isPopupOpen: true });
    }
  }

  closeToolTip() {
    this.setState({ isPopupOpen: false });
  }

  render() {
    const { formatMessage } = this.props.intl;
    const triggerMessage = this.getTriggerMessage();
    const triggerClassNames = classNames('condition-link modal-trigger-link tooltip-trigger', { 'errors-inside-global-conditions-highlight': this.props.validationErrors });
    const content = this.getContent();
    return (
      <Tooltip
        trigger={
          <div className="modal-trigger">
            <a
              role="button"
              className={triggerClassNames}
              onClick={this.props.openModal}
            >
              <Icon className="como-ic-date" data-automation-id="globalConditions.dateTime" />
              <span> {triggerMessage} </span>
            </a>
            {this.props.validationErrors ?
              <ValidationErrorMessage
                errorLayoutType={commonComponentsConstants.COMPONENT_TYPE_PARAGRAPH}
                errorMessage={formatMessage({ id: 'activity.validation.error.popup.outside.message' })}
                dataAutomationId={'globalConditions.dateTime.error.message'}
              />
              : null
            }
          </div>
        }
        content={content}
        position="bottom left"
        className="dateTime-tooltip"
        open={this.state.isPopupOpen}
        flowing
        onOpen={this.openToolTip}
        onClose={this.closeToolTip}
      />

    );
  }
}

export default injectIntl(DateTimeToolTip);

