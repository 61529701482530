import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import classNames from 'classnames';

import Modal from '../../../../../../common/components/CustomModal/CustomModal';
import OccurrencesLimitCondition from './limitPerMemberGlobalCondition';
import * as activityConstants from '../../../../activity.constants';
import * as activityActions from '../../../../activity.actions';
import * as globalConditionsActions from '../globalConditions.actions';
import ValidationErrorMessage from '../../../../../../common/components/ValidationErrorMessage/ValidationErrorMessage';
import { getBrowserLocale } from '../../../../../App/selectors';
import {
  getLimitOccurrencesGlobalConditionsTempValidationErrors,
  getLimitOccurrencesGlobalConditionsValidationErrors,
  getLimitOccurrencesPerMemberGlobalConditionsModel,
  getLimitOccurrencesPerMemberGlobalConditionsTempModel,
  getMembershipGlobalConditionType
} from '../../../../activity.selectors';
import * as commonComponentsConstants from '../../../../../../common/components/common.components.constants';


export const AUTOMATION_PREFIX_NAME = 'globalConditions.applySeveralTimes';

class LimitPerMemberGlobalConditionWrapper extends React.PureComponent {
  static propTypes = {
    intl: PropTypes.object,
    tempModel: PropTypes.object,
    actualModel: PropTypes.object,
    validationErrors: PropTypes.object,
    modalValidationErrors: PropTypes.object,
    actions: PropTypes.object,
    disabled: PropTypes.bool,
    isMembersFilter: PropTypes.bool,
    currentConditionType: PropTypes.string
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      isModalOpen: false
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.cancelChanges = this.cancelChanges.bind(this);
    this.getTriggerText = this.getTriggerText.bind(this);
    this.getTriggerIcon = this.getTriggerIcon.bind(this);
  }

  getTriggerText() {
    const { formatMessage } = this.props.intl;
    const conditionType = this.props.actualModel ?
      this.props.actualModel.get(activityConstants.LIMIT_OCCURRENCES_PER_MEMBER_CONDITION_TYPE) :
      '';
    switch (conditionType) {
      case activityConstants.LIMITED_PER_MEMBER: {
        let totalLimit = this.props.actualModel.get(activityConstants.TOTAL_OCCURRENCES_CONDITION_ENABLED);
        if (totalLimit) {
          totalLimit = this.props.actualModel.getIn(
            [activityConstants.TOTAL_OCCURRENCES_CONDITION, activityConstants.CONDITION_VALUE]);
        }
        let periodicLimit = this.props.actualModel.get(activityConstants.BY_PERIOD_CONDITION_ENABLED);
        if (periodicLimit) {
          periodicLimit = this.props.actualModel.getIn(
            [activityConstants.BY_PERIOD_CONDITION, activityConstants.CONDITION_VALUE]);
        }
        let msg = '';
        if (totalLimit) {
          msg += formatMessage({
            id: 'activity.conditions.upToTimes'
          }, {
            times: totalLimit
          });
        }
        if (periodicLimit) {
          if (msg !== '') {
            const conditionCombineMsg = formatMessage({
              id: 'activity.conditionsRelation.and'
            });
            msg += ` ${conditionCombineMsg} `;
          }
          msg += this.getByPeriodMessage(periodicLimit);
        }
        if (msg !== '') {
          return msg;
        }
        break;
      }
      default: {
        break;
      }
    }
    return formatMessage({
      id: 'activity.conditions.infinite.number.of.purchases.per.member'
    });
  }

  getByPeriodMessage(periodicLimit) {
    const { formatMessage } = this.props.intl;
    const count = periodicLimit.get(activityConstants.BY_PERIOD_CONDITION_COUNT);
    const period = periodicLimit.get(activityConstants.BY_PERIOD_CONDITION_PERIOD);
    const isRollingPeriod = periodicLimit.get(activityConstants.IS_ROLLING_PERIOD);
    let multiplier = isRollingPeriod ? periodicLimit.get(activityConstants.BY_PERIOD_PERIOD_MULTIPLIER) : 1;
    if (multiplier === null) {
      multiplier = '';
    }
    const periodTranslation = formatMessage({
      id: `activity.conditions.occurrences.limit.unit.${period}${multiplier > 1 ? '.plural' : ''}`
    });
    const periodMsg = `${multiplier} ${periodTranslation}`;
    return formatMessage({ id: 'activity.conditions.upToTimesWithin' }, {
      times: count,
      period: periodMsg
    });
  }

  getTriggerIcon() {
    return 'como-ic-infinity';
  }

  /**
   * opens modal
   */
  openModal() {
    if (this.props.disabled) {
      return;
    }
    this.props.actions.copyGlobalOccurrencesLimitConditionsToTempModel();
    this.setState({
      isModalOpen: true
    });
  }

  cancelChanges() {
    this.props.actions.removeLimitOccurrencesTempModel();
    this.closeModal();
  }

  saveChanges() {
    this.props.actions.applyLimitOccurrencesModel();
    this.closeModal();
  }

  closeModal() {
    this.setState({
      isModalOpen: false
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentConditionType !== this.props.currentConditionType && (this.props.currentConditionType === activityConstants.NON_MEMBERS || this.props.currentConditionType === activityConstants.ALL_COSTUMERS)) { // if conditiontype is for non-member default it to infinite
      this.props.actions.updateTempLimitPerMemberGlobalConditionType(activityConstants.INFINITE);
      this.props.actions.applyLimitOccurrencesModel();
    }
  }

  render() {
    const { formatMessage } = this.props.intl;
    const triggerClassNames = classNames('condition-link modal-trigger-link', {
      'errors-inside-global-conditions-highlight': this.props.validationErrors,
      'disabled': this.props.disabled
    });
    const cancelButton = (
      <Button
        key="applySeveralTimesConditionCancelBtn"
        onClick={this.cancelChanges}
        basic
        className="link modal-button"
        data-automation-id={`${AUTOMATION_PREFIX_NAME}.btnCancel`}
      >
        {formatMessage({ id: 'general.button.cancel' })}
      </Button>);
    const saveButton = (<Button
      key="applySeveralTimesConditionSaveBtn"
      onClick={this.saveChanges}
      className="modal-button"
      data-automation-id={`${AUTOMATION_PREFIX_NAME}.btnSave`}
    >
      {formatMessage({ id: 'general.button.save' })}
    </Button>);
    const triggerButton = (
      <div className="modal-trigger">
        <a role="button" className={triggerClassNames} onClick={this.openModal}>
          <Icon
            className={this.getTriggerIcon()}
            data-automation-id={`${AUTOMATION_PREFIX_NAME}.trigger`}
          />
          {this.getTriggerText()}
        </a>
        {this.props.validationErrors ?
          <ValidationErrorMessage
            errorLayoutType={commonComponentsConstants.COMPONENT_TYPE_PARAGRAPH}
            errorMessage={formatMessage({ id: 'activity.validation.error.popup.outside.message' })}
            dataAutomationId={`${AUTOMATION_PREFIX_NAME}.error.message`}
          />
          : null}
      </div>
    );
    const header = formatMessage({
      id: 'activity.globalConditions.applySeveralTimes'
    });
    return (
      <Modal
        className="occurrences-limit-condition-modal"
        automationIdPrefix="globalConditions.applySeveralTimes"
        header={header}
        actions={[cancelButton, saveButton]}
        trigger={triggerButton}
        content={<OccurrencesLimitCondition
          data={this.props.tempModel}
          updateConditionType={this.props.actions.updateTempLimitPerMemberGlobalConditionType}
          updateTotalLimitEnable={this.props.actions.updateTempLimitPerMemberGlobalConditionTotalLimitEnable}
          updateByPeriodLimitEnable={this.props.actions.updateTempLimitPerMemberGlobalConditionByPeriodLimitEnable}
          updatePeriodUnit={this.props.actions.updateTempLimitPerMemberGlobalConditionByPeriodUnitChange}
          updatePeriodMultiplier={this.props.actions.updateTempLimitPerMemberGlobalConditionByPeriodPeriodMultiplier}
          updateByPeriodPeriodCount={this.props.actions.updateTempLimitPerMemberGlobalConditionByPeriodPeriodCount}
          updateTotalOccurrencesCount={this.props.actions.updateTempLimitPerMemberGlobalConditionTotalOccurrencesCount}
          validationErrors={this.props.modalValidationErrors}
          currentConditionType={this.props.currentConditionType}
        />}
        size={960}
        closeIcon={<Icon className="como-ic-close" name="close" onClick={this.cancelChanges} />}
        open={this.state.isModalOpen}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  locale: getBrowserLocale(state),
  tempModel: getLimitOccurrencesPerMemberGlobalConditionsTempModel(state),
  actualModel: getLimitOccurrencesPerMemberGlobalConditionsModel(state),
  modalValidationErrors: getLimitOccurrencesGlobalConditionsTempValidationErrors(state),
  validationErrors: getLimitOccurrencesGlobalConditionsValidationErrors(state),
  currentConditionType: getMembershipGlobalConditionType(state, props.isMembersFilter)
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...globalConditionsActions, ...activityActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LimitPerMemberGlobalConditionWrapper));
