import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Tab } from 'semantic-ui-react';
import classNames from 'classnames';
import _ from 'lodash';
import DisabledItem from '../DisabledItem/DisabledItem';
import './index.styles.less';


class PaneContentList extends React.Component {

  static propTypes = {
    automationId: PropTypes.string,
    selectedOption: PropTypes.string,
    values: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    onSelect: PropTypes.func,
  };

  static defaultProps = {
    automationId: ''
  };

  render() {

    const { automationId, selectedOption, values, onSelect, ...restProps } = this.props;

    return (
      <Tab.Pane attached {...restProps}>
        <ul>
          {
          values.map(({ key, text, disable, icon } = {}) => {

            const tabOptionClass = classNames({
              'active-tab-option': key === selectedOption
            });
            if (disable && disable.hide) {
              return null;
            }
            if (disable && disable.disabled) {
              return (
                <div key={key} className="disabled__container">
                  <DisabledItem tooltipMessage={disable.message} withUnAvailableIcon={!disable.icon}>
                    {text}
                    {
                      disable.icon &&
                      <span className="inside-sentence">
                        <Icon className={disable.icon} />
                      </span>
                    }
                  </DisabledItem>
                </div>
              );
            }

            return (
              <li
                className={tabOptionClass}
                data-automation-id={`${automationId}.${key}`}
                key={key}
                onClick={(e) => _.isFunction(onSelect) && onSelect(e, key)}
              >
                {text}
                {icon && <span className="inside-sentence">
                  <Icon className={icon} />
                </span>}
              </li>
            );
          }
          )
        }
        </ul>
      </Tab.Pane>
    );
  }
}

export default PaneContentList;
