import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Form } from 'formsy-semantic-ui-react';
import { bindActionCreators, compose } from 'redux';
import * as routeConstants from '../../../constants/route.contants';
import * as constants from './constants/constants';
import Tool from './view/tool';
import injectSaga from '../../../utils/injectSaga';
import saga from './admin.saga';
import * as goalsAdminActions from './admin.actions';
import * as selectors from './admin.selectors';
import './admin.styles.less';
import * as goalsConstants from '../goals.constants';
import GoalMetaData from './view/goalMetaData';
import BackLink from '../../../common/components/NavigationLink/BackLink';

class GoalsAdmin extends React.PureComponent {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    rulesProductTemplates: PropTypes.object.isRequired,
    dealsProductTemplates: PropTypes.object.isRequired,
    oneTimeActionsProductTemplates: PropTypes.object.isRequired,
    goal: PropTypes.object.isRequired,
    goals: PropTypes.object
  };
  constructor(props) {
    super(props);
    const { actions } = props;
    actions.getActivitiesProductTemplates();
    actions.getGoals();
    this.state = { isNewGoal: true };
  }

  handleOnSelectGoal = (e, { value }) => {
    e.preventDefault();
    this.setState({ isNewGoal: false });
    this.props.actions.selectGoal(value);
  };
  handleAddTool = () => {
    this.props.actions.addTool();
  };
  handleSubmit = () => {
    const { actions } = this.props;
    if (this.state.isNewGoal) {
      actions.createGoal();
      return;
    }

    actions.updateGoal();
  };
  generateGoalsOptions(data) {
    return data.map((goal, index) => ({ key: index, text: goal[goalsConstants.GOAL_NAME], value: goal[goalsConstants.GOAL_DB_ID] }));
  }
  renderTools() {
    const { rulesProductTemplates, dealsProductTemplates, oneTimeActionsProductTemplates, goal, actions } = this.props;

    const data = [];
    const tools = goal.get(goalsConstants.GOAL_TOOLS);
    for (let i = 0; i < tools.size; i++) {
      data.push(
        <Tool
          key={i}
          index={i}
          rulesProductTemplates={rulesProductTemplates}
          dealsProductTemplates={dealsProductTemplates}
          oneTimeActionsProductTemplates={oneTimeActionsProductTemplates}
          onChange={actions.updateTool}
          tool={tools.get(`${i}`)}
        />
      );
    }
    return data;
  }
  render() {
    const { goal, actions, goals } = this.props;
    const goalsOptions = this.generateGoalsOptions(goals.toJS());

    return (
      <div className="goals-admin__container">
        <BackLink pathname={routeConstants.GOALS_ROUTE} />
        <Form
          className="goal-selection"
        >
          <Form.Dropdown
            name="updateGoal"
            label="Update Goal"
            data-automation-id="goals.admin.form.select.goal"
            placeholder="Choose Goal"
            search
            selection
            options={goalsOptions}
            onChange={this.handleOnSelectGoal}
            value={goal.get(goalsConstants.GOAL_DB_ID)}
          />
        </Form>
        <Form
          data-automation-id="goals.admin.form"
          onValidSubmit={this.handleSubmit}
          className="goals-admin-create__container"
        >
          <GoalMetaData
            name={goal.get(goalsConstants.GOAL_NAME)}
            icon={goal.get(goalsConstants.GOAL_ICON)}
            onChangeName={actions.changeGoalName}
            onChangeIcon={actions.changeGoalIcon}
          />
          {this.renderTools()}
          <div className="actions">
            <Button
              onClick={this.handleAddTool}
              data-automation-id="goals.admin.form.actions.add.tool"
            >
              Add Tool
            </Button>
            <Form.Button
              content="Save"
              data-automation-id="goals.admin.form.actions.submit"
            />
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rulesProductTemplates: selectors.getRulesProductTemplates(state),
  dealsProductTemplates: selectors.getDealsProductTemplates(state),
  oneTimeActionsProductTemplates: selectors.getOneTimeActionsProductTemplates(state),
  goal: selectors.getGoal(state),
  goals: selectors.getGoals(state)
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(goalsAdminActions, dispatch)
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: constants.SAGA_KEY_GOALS_ADMIN, saga });

export default compose(
  withConnect,
  withSaga,
  injectIntl,
)(GoalsAdmin);
