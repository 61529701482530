import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import ConditionValue from './ConditionValue';
import ItemsPopulation from '../itemsPopulation/itemsPopulation.container';
import * as constants from './../../activity.constants';
import * as schemaConstants from '../../activitySchema/activitySchema.constants';
import tracker from '../../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../../common/utils/tracking/tracking.consts';
import ConditionOperator from './ConditionOperator';
import ConditionKey from './ConditionKey';
import * as actions from './globalConditions/filters/referralCodeCondition/referralCode.actions';
import * as appSelectors from '../../../App/selectors';
import * as appConstants from '../../../App/app.constants';
import * as utils from '../../reducers/utils/activity.reducer.utils';

class Condition extends React.PureComponent {

  static propTypes = {
    addDaysTimesCondition: PropTypes.func,
    automationIdPrefix: PropTypes.string,
    className: PropTypes.string,
    condition: PropTypes.object,
    conditionKeyColumnMaxWidthClass: PropTypes.string,
    conditionsList: PropTypes.object,
    conditionsRelations: PropTypes.string,
    deleteCondition: PropTypes.func,
    deleteDaysTimesCondition: PropTypes.func,
    disabled: PropTypes.bool,
    filteredConditionsList: PropTypes.object,
    features: PropTypes.object,
    hideConditionKey: PropTypes.bool,
    index: PropTypes.number,
    intl: PropTypes.object,
    isCaseCondition: PropTypes.bool,
    isFixedConditionKey: PropTypes.bool,
    isInsideModal: PropTypes.bool,
    isMembersFilter: PropTypes.bool,
    isDeletable: PropTypes.bool,
    isLastCondition: PropTypes.bool,
    isPastDateRange: PropTypes.bool,
    isInfiniteDateRange: PropTypes.bool,
    mainConditions: PropTypes.bool,
    trigger: PropTypes.string,
    updateConditionField: PropTypes.func,
    updateDateRangeDate: PropTypes.func,
    validationErrors: PropTypes.object,
    referralTempCondition: PropTypes.object,
    createReferralCodeTempModel: PropTypes.func,
    deleteReferralCodeTempModel: PropTypes.func,
    getBusinessBundlePlan: PropTypes.func.isRequired,
    saveReferralCodeTempModelToGlobalConditions: PropTypes.func,
    referralTempError: PropTypes.string,
    updateDaysTimes: PropTypes.func,
    conditionKeyWidth: PropTypes.number,
    conditionOperatorWidth: PropTypes.number,
    conditionValueWidth: PropTypes.number,
    conditionItemsPopulationWidth: PropTypes.number,
    userActionIndex: PropTypes.number,
    abTestMode: PropTypes.bool,
    datePickerInsideModal: PropTypes.bool,
    isAdvancedRuleConditions: PropTypes.bool,
    autoCompleteOptions: PropTypes.object,
    isInsideUserAction: PropTypes.bool,
    isOneTimeActivityDisabled: PropTypes.bool
  };

  static defaultProps = {
    isDeletable: true
  };

  /**
   * component constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.onConditionUpdate = this.onConditionUpdate.bind(this);
    this.canUseOnce = this.canUseOnce.bind(this);
  }

  /**
   * handler for changes in one of the condition properties
   * @param {object} e - event
   * @param {object} data - trigger properties
   */
  onConditionUpdate = (e, data) => {
    const conditionFieldName = data.name || constants.CONDITION_VALUE;
    if (conditionFieldName === constants.CONDITION_KEY) {
      let context = (this.props.isCaseCondition)
        ? trackerConstants.CONTEXT_TYPE_ACTIVITY_CASES
        : trackerConstants.CONTEXT_TYPE_ACTIVITY;
      if (this.props.isInsideModal) {
        context = schemaConstants.MEMBERSHIP_GLOBAL_CONDITION;
      }
      tracker.onEvent(trackerConstants.EVENT_TYPE_CONDITION_SELECTED, {
        [trackerConstants.CONDITION_SELECTED_ARGS_CONDITION_KEY]: data.value,
        [trackerConstants.CONTEXT]: context
      });
    }

    this.props.updateConditionField(conditionFieldName, data.value, this.props.index, this.props.abTestMode);
  };

  getConditionKeyWidth = (type, originalWidth) => {
    if (this.props.abTestMode) {
      return 10;
    }
    if (this.props.conditionKeyWidth) {
      return this.props.conditionKeyWidth;
    }
    let conditionKeyWidth = originalWidth;
    if ([schemaConstants.VALUE_TYPE_CURRENCY, schemaConstants.VALUE_TYPE_NUMBER].includes(type)) {
      conditionKeyWidth = originalWidth === 4 ? 2 : 4;
    }
    if ([schemaConstants.VALUE_TYPE_ARRAY].includes(type)) {
      conditionKeyWidth = originalWidth === 4 ? 6 : 4;
    }
    return conditionKeyWidth;
  };

  /**
   * todo: this function is temp until all interested components will pass conditionValueWidth, conditionOperatorWidth, conditionKeyWidth
   * now I have to support all previous logic, too
   * @returns {*}
   */
  getFieldWidth(fieldWidthName, selectedConditionSchema) {
    if (this.props.isAdvancedRuleConditions) {
      return 5;
    }
    let width;
    const defaultFieldWidth = this.props.mainConditions ? 4 : 5;
    if (!selectedConditionSchema) {
      return defaultFieldWidth;
    }
    if (fieldWidthName === constants.CONDITION_VALUE_WIDTH) {
      // todo: this logic will become redundant when all interested components will pass conditionValueWidth
      width = this.props.trigger === schemaConstants.TRIGGER_UPDATE_MEMBERSHIP &&
        ![schemaConstants.CODE_TAG, schemaConstants.OPERATION].includes(this.props.condition.get(constants.CONDITION_KEY)) &&
        (!this.props.isCaseCondition || !selectedConditionSchema.get(constants.SCHEMA_CONDITION_GROUP))
        ? 10 : this.getConditionKeyWidth(selectedConditionSchema.get(constants.VALUE_TYPE), defaultFieldWidth);
    } else {
      return defaultFieldWidth;
    }

    return width;
  }

  canUseOnce(conditionKey) {
    return this.props.conditionsList.some(
      (condition) => condition.get(constants.SCHEMA_CONDITION_KEY) === conditionKey &&
        condition.get(constants.CONDITION_USED_ONCE_PER_USER_ACTION));
  }

  /**
   * fix conditionsList previously filtered by "used once" flag
   * @returns {Array|*}
   */
  get computedConditionsList() {
    const { conditionsList, filteredConditionsList, condition } = this.props;
    if (!filteredConditionsList) {
      return conditionsList;
    }
    const conditionKey = condition.get(constants.CONDITION_KEY);
    const selectedConditionFoundInFilteredList =
      filteredConditionsList.some((item) => item.get(constants.SCHEMA_CONDITION_KEY) === conditionKey);
    if (!selectedConditionFoundInFilteredList) {
      const selectedConditionInConditionsList =
        conditionsList.find((item) => item.get(constants.SCHEMA_CONDITION_KEY) === conditionKey);
      if (selectedConditionInConditionsList && this.canUseOnce(conditionKey)) {
        // conditions with the 'canUseOnce' flag aren't appear in the filtered conditions list
        return filteredConditionsList.push(selectedConditionInConditionsList);
      }
    }
    return filteredConditionsList;
  }

  shouldDisplayOperatorKey = (conditionKey, valueType, trigger, conditionGroup, isCaseCondition) => (
    valueType &&
    conditionKey &&
    conditionKey !== schemaConstants.CONTEXT_TIMESTAMP &&
    conditionKey !== schemaConstants.CONTEXT_TIMESTAMP_DAYS &&
    conditionKey !== schemaConstants.CONTEXT_REFERRAL_DATA &&
    valueType !== schemaConstants.VALUE_TYPE_BOOLEAN &&
    valueType !== schemaConstants.VALUE_TYPE_DEFAULT_FIXED_HIDDEN_VALUE &&
    (trigger !== schemaConstants.TRIGGER_UPDATE_MEMBERSHIP || conditionKey === schemaConstants.CODE_TAG || (isCaseCondition && conditionGroup)) &&
    !this.props.abTestMode
  );

  shouldDisplayConditionValue = (condition, valueType) => (
    condition.get(constants.CONDITION_KEY) &&
    (condition.get(constants.OPERATOR_KEY) !== schemaConstants.OPERATOR_KEY_CONTAINS_NONE) &&
    (valueType !== schemaConstants.VALUE_TYPE_DEFAULT_FIXED_HIDDEN_VALUE)
  );

  get parsedAutoCompleteOptions() {
    let options = [];
    if (this.props.autoCompleteOptions && this.props.autoCompleteOptions.size > 0) {
      options = this.props.autoCompleteOptions.toJS().map((option) => ({ key: option, text: option, value: option }));
    }
    if (this.props.condition.get(constants.CONDITION_VALUE) && this.props.condition.get(constants.CONDITION_VALUE).size > 0) {
      this.props.condition.get(constants.CONDITION_VALUE).map((value) => options.push({ key: value, text: value, value }));
    }
    return options;
  }

  render() {
    const { formatMessage } = this.props.intl;
    const condition = this.props.condition;
    const isInsideModal = this.props.isInsideModal ? this.props.isInsideModal : false;
    const conditionKey = condition.get(constants.CONDITION_KEY);
    const selectedConditionSchema = this.props.conditionsList ? this.props.conditionsList.find((c) => c.get(constants.KEY) === conditionKey) : List();
    const operatorsList = selectedConditionSchema ? selectedConditionSchema.get(constants.SCHEMA_CONDITION_OPERATORS) : List();
    const automationIdPrefix = this.props.automationIdPrefix || '';
    const classNames = ['condition-row attribute-condition-row'];
    const conditionKeyWidth = this.props.conditionKeyWidth ||
      this.getFieldWidth(constants.CONDITION_KEY_WIDTH, selectedConditionSchema);
    const conditionOperatorWidth = this.props.conditionOperatorWidth ||
      this.getFieldWidth(constants.CONDITION_OPERATOR_WIDTH, selectedConditionSchema);
    const conditionValueWidth = this.props.conditionValueWidth ||
      this.getFieldWidth(constants.CONDITION_VALUE_WIDTH, selectedConditionSchema);
    const conditionItemsPopulationWidth = this.props.conditionItemsPopulationWidth ||
      this.getFieldWidth(constants.CONDITION_ITEMS_POPULATION_WITH, selectedConditionSchema);
    if (this.props.className) {
      classNames.push(this.props.className);
    }
    if (this.props.isLastCondition) {
      classNames.push('last-condition');
    }
    const selectedConditionSchemaValueType = selectedConditionSchema
      ? selectedConditionSchema.get(constants.VALUE_TYPE)
      : null;
    const selectedConditionSchemaGroup = selectedConditionSchema
      ? selectedConditionSchema.get(constants.SCHEMA_CONDITION_GROUP)
      : null;
    const displayOperatorDropDownWithSingleOption = condition.get(constants.CONDITION_KEY) !== schemaConstants.PROBABILITY;
    const hideConditionKey = this.props.hideConditionKey || this.props.abTestMode;

    return (
      <Grid className={classNames.join(' ')}>
        <Grid.Row>
          {!hideConditionKey &&
          <Grid.Column width={conditionKeyWidth} className={this.props.conditionKeyColumnMaxWidthClass}>
            <ConditionKey
              conditionKey={conditionKey}
              conditionsList={this.computedConditionsList}
              disabled={this.props.disabled || this.props.isOneTimeActivityDisabled}
              automationIdPrefix={automationIdPrefix}
              isCaseCondition={this.props.isCaseCondition}
              isInsideModal={isInsideModal}
              validationErrors={this.props.validationErrors}
              index={this.props.index}
              isFixedConditionKey={this.props.isFixedConditionKey}
              onConditionUpdate={this.onConditionUpdate}
              selectedConditionSchema={selectedConditionSchema}
              getBusinessBundlePlan={this.props.getBusinessBundlePlan}
            />
          </Grid.Column>
          }
          {
            this.shouldDisplayOperatorKey(condition.get(constants.CONDITION_KEY), selectedConditionSchemaValueType,
              this.props.trigger, selectedConditionSchemaGroup, this.props.isCaseCondition)
              ?
                <Grid.Column width={conditionOperatorWidth}>
                  <ConditionOperator
                    isInsideModal={isInsideModal}
                    automationIdPrefix={automationIdPrefix}
                    disabled={this.props.disabled || this.props.isOneTimeActivityDisabled}
                    options={operatorsList}
                    value={condition.get(constants.OPERATOR_KEY)}
                    index={this.props.index}
                    onConditionUpdate={this.onConditionUpdate}
                    displayDropDownWithSingleOption={displayOperatorDropDownWithSingleOption}
                    condition={this.props.condition}
                  />
                </Grid.Column>

              : null
          }
          {
            this.shouldDisplayConditionValue(condition, selectedConditionSchemaValueType)
              // todo: conditionFieldWidth will become redundant when all components will pass width for conditionKeyWidth, conditionOperatorWidth, and conditionValueWidth
              ? (
                <Grid.Column
                  className="condition-value"
                  width={conditionValueWidth}
                >
                  <ConditionValue
                    addDaysTimesCondition={this.props.addDaysTimesCondition}
                    condition={condition}
                    disabled={this.props.disabled || this.props.isOneTimeActivityDisabled}
                    deleteDaysTimesCondition={this.props.deleteDaysTimesCondition}
                    index={this.props.index}
                    isPastDateRange={this.props.isPastDateRange}
                    isInfiniteDateRange={this.props.isInfiniteDateRange}
                    isInsideUserAction={this.props.isInsideUserAction}
                    onConditionUpdate={this.onConditionUpdate}
                    prefix={this.props.automationIdPrefix}
                    selectedConditionSchema={selectedConditionSchema}
                    trigger={this.props.trigger}
                    updateDateRangeDate={this.props.updateDateRangeDate}
                    updateDaysTimes={this.props.updateDaysTimes}
                    validationErrors={this.props.validationErrors}
                    userActionIndex={this.props.userActionIndex}
                    abTestMode={this.props.abTestMode}
                    referralTempCondition={this.props.referralTempCondition}
                    referraltempError={this.props.referralTempError}
                    createReferralCodeTempModel={this.props.createReferralCodeTempModel}
                    deleteReferralCodeTempModel={this.props.deleteReferralCodeTempModel}
                    saveReferralCodeTempModelToGlobalConditions={this.props.saveReferralCodeTempModelToGlobalConditions}
                    datePickerInsideModal={this.props.datePickerInsideModal}
                    hasAutoComplete={selectedConditionSchema && selectedConditionSchema.has(schemaConstants.AUTO_COMPLETE_OPTIONS)}
                    autoCompleteOptions={this.parsedAutoCompleteOptions}
                  />
                </Grid.Column>
              )
              : null
          }
          {
            (condition.get(constants.ITEMS_POPULATION)) &&
            <Grid.Column width={conditionItemsPopulationWidth}>
              <ItemsPopulation
                className={this.props.className}
                automationIdPrefix={automationIdPrefix}
                position="bottom right"
                index={this.props.index}
                isMembersFilter={this.props.isMembersFilter}
                data={condition.get(constants.ITEMS_POPULATION).toJS()}
                onUpdate={(index, data) => this.props.updateConditionField(constants.ITEMS_POPULATION, data, this.props.index)}
                itemsPopulationValidationErrors={this.props.validationErrors ? this.props.validationErrors.get(constants.ITEMS_POPULATION) : null}
                features={this.props.features}
                posFeature={selectedConditionSchema.getIn([appConstants.FEATURES, 0, constants.NAME])}
                disableAny={this.props.isOneTimeActivityDisabled}
              />
            </Grid.Column>
          }
          {
            this.props.isDeletable && (!isInsideModal || (isInsideModal && condition.get(constants.CONDITION_KEY))) && !this.props.abTestMode && !this.props.isOneTimeActivityDisabled
              ? (
                <Grid.Column width={1}>
                  <Icon
                    className="como-ic-delete"
                    onClick={() => this.props.deleteCondition(this.props.index)}
                    data-automation-id={`rule.${automationIdPrefix}.delete`}
                  />
                </Grid.Column>
              )
              : null
          }
        </Grid.Row>
        {
          // TODO - this should be a part of the parent logic
          this.props.conditionsRelations && !this.props.isLastCondition
            ? (
              <Grid.Row className="conditionRelations">
                <p>
                  {formatMessage({ id: `activity.conditionsRelation.${this.props.conditionsRelations}` })}
                </p>
              </Grid.Row>
            )
            : null
        }
      </Grid>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const schemaCondition = ownProps.conditionsList.find((condition) => condition.get(constants.KEY) === ownProps.condition.get(constants.CONDITION_KEY));
  if (schemaCondition && schemaCondition.get(schemaConstants.AUTO_COMPLETE_OPTIONS) && schemaCondition.get(schemaConstants.AUTO_COMPLETE_OPTIONS) === appConstants.BUSINESS_TAGS) {
    if (utils.isTriggerTagsRelated(ownProps.trigger)) {
      return {
        autoCompleteOptions: appSelectors.getBusinessTags(state),
      };
    }
    return {
      autoCompleteOptions: appSelectors.getBusinessTagsWithoutRfm(state)
    };
  }
  return {
    autoCompleteOptions: null
  };
}

function mapDispatchToProps(dispatch) {
  const creators = {
    ...actions
  };
  return {
    actions: bindActionCreators(creators, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(Condition);
