import { call, put, takeLatest, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { actions as toastrActions } from '../../common/components/ReduxToast';

import * as constants from './templates.constants';
import * as api from './templates.api';
import { convertActions, prepareActivityDataForBackend } from '../Activity/activity.api';
import { getTranslationSelector } from '../App/selectors';
import * as errorConstant from '../ErrorMessage/errorMessage.constants';
import { setLoadingState } from '../App/app.actions';
import * as emailsConstants from '../Emails/emails.constants';
import * as utils from '../Activity/activity.saga.utils';

function* getBusinessTemplates({ entityType }) {
  try {
    yield put(setLoadingState(true));
    const data = yield call(api.getBusinessTemplates, entityType);
    yield put({ type: constants.GET_BUSINESS_TEMPLATES_SUCCESS, data, entityType });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getBusinessTemplates.Fail'] });
  } finally {
    yield put(setLoadingState(false));
  }
}

function* getActivityProductTemplates({ entityType }) {
  try {
    yield put(setLoadingState(true));
    const data = yield call(api.getProductTemplates, entityType);
    yield put({ type: constants.GET_PRODUCT_TEMPLATES_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getProductTemplates.Fail'] });
  } finally {
    yield put(setLoadingState(false));
  }
}

function* getProductCategories({ entityType }) {
  try {
    yield delay(50);
    yield put(setLoadingState(true));
    const data = yield call(api.getProductTemplatesCategories, entityType);
    yield put({ type: constants.GET_PRODUCT_CATEGORIES_SUCCESS, payload: data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getProductCategories.Fail'] });
  } finally {
    yield put(setLoadingState(false));
  }
}

function* getBusinessesVerticals() {
  try {
    yield delay(50);
    yield put(setLoadingState(true));
    const data = yield call(api.getBusinessesVerticals);
    yield put({ type: constants.GET_BUSINESSES_VERTICALS_SUCCESS, payload: data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getBusinessesVerticals.Fail'] });
  } finally {
    yield put(setLoadingState(false));
  }
}

function* addSuccessMessage() {
  const textSelector = getTranslationSelector('activity.save-as-template.success');
  const text = yield select(textSelector);

  const notificationAction = toastrActions.add({
    message: text,
    options: {
      showCloseButton: true,
      className: 'simple-hub2-toastr-notification',
      removeOnHover: true
    }
  });
  yield put(notificationAction);
}

function* saveAsBusinessTemplate({ payload: template, entityType }) {
  try {
    yield put({ type: constants.SET_SAVE_TEMPLATE_IN_PROGRESS, [constants.SAVE_TEMPLATE_IN_PROGRESS]: true });
    if (entityType !== emailsConstants.EMAIL) {
      const thisActivity = prepareActivityDataForBackend(convertActions(template.entity));
      template.activity = utils.processActivityForSavingAsTemplate(thisActivity);
      // We don't want to save landing page data in a template
      delete template.activity.landingPageData;
    }
    delete template.entity;
    yield call(api.createBusinessTemplate, template, entityType);
    yield put({ type: constants.SAVE_AS_TEMPLATE_SUCCESS });
    yield addSuccessMessage();
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['createBusinessTemplate.Fail'] });
  } finally {
    yield put({ type: constants.MARK_SAVE_AS_TEMPLATE_IN_PROGRESS_FALSE });
  }
}

function* saveAsProductTemplate({ payload: template, entityType }) {
  try {
    yield put({ type: constants.SET_SAVE_TEMPLATE_IN_PROGRESS, [constants.SAVE_TEMPLATE_IN_PROGRESS]: true });
    const thisActivity = prepareActivityDataForBackend(convertActions(template.entity));
    template.activity = utils.processActivityForSavingAsTemplate(thisActivity);
    // We don't want to save landing page data in a template
    delete template.activity.landingPageData;
    delete template.entity;
    yield call(api.saveProductTemplate, template, entityType);
    yield put({ type: constants.SAVE_AS_TEMPLATE_SUCCESS });
    yield addSuccessMessage();
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['saveProductTemplate.Fail'] });
  }
}

function* deleteBusinessTemplate({ payload: { templateId }, entityType }) {
  try {
    yield put(setLoadingState(true));
    yield call(api.deleteBusinessTemplate, templateId, entityType);
    yield put({ type: constants.DELETE_BUSINESS_TEMPLATE_SUCCESS, payload: templateId });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['deleteBusinessTemplate.Fail'] });
  } finally {
    yield put(setLoadingState(false));
  }
}

function* updateBusinessTemplate({ payload: template, entityType }) {
  try {
    yield put({ type: constants.SET_SAVE_TEMPLATE_IN_PROGRESS, [constants.SAVE_TEMPLATE_IN_PROGRESS]: true });
    if (entityType !== emailsConstants.EMAIL) {
      const thisActivity = prepareActivityDataForBackend(convertActions(template.entity));
      template.activity = utils.processActivityForSavingAsTemplate(thisActivity);
    }
    delete template.entity;

    yield call(api.updateBusinessTemplate, template, entityType);
    yield put({
      type: constants.UPDATE_BUSINESS_TEMPLATE_SUCCESS,
      payload: { dbId: template.dbId, name: template.templateName, description: template.templateDescription }
    });
    yield addSuccessMessage();
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['updateBusinessTemplate.Fail'] });
  }
}

function* updateBusinessTemplateMetadata({ payload: template, entityType }) {
  try {
    yield put({ type: constants.SET_SAVE_TEMPLATE_IN_PROGRESS, [constants.SAVE_TEMPLATE_IN_PROGRESS]: true });
    if (entityType !== emailsConstants.EMAIL) {
      template.activity = template.entity;
    } else {
      template.email = template.entity;
    }
    delete template.entity;
    yield call(api.updateBusinessTemplate, template, entityType);
    yield put({
      type: constants.UPDATE_BUSINESS_TEMPLATE_SUCCESS,
      payload: { dbId: template.dbId, name: template.templateName, description: template.templateDescription }
    });
    yield addSuccessMessage();
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['updateBusinessTemplate.Fail'] });
  }
}

function* updateProductTemplate({ payload: template, entityType }) {
  try {
    yield put({ type: constants.SET_SAVE_TEMPLATE_IN_PROGRESS, [constants.SAVE_TEMPLATE_IN_PROGRESS]: true });
    template.activity = prepareActivityDataForBackend(convertActions(template.entity));
    delete template.entity;
    yield call(api.updateProductTemplate, template, entityType);
    yield put({
      type: constants.UPDATE_PRODUCT_TEMPLATE_SUCCESS,
      payload: template
    });
    yield addSuccessMessage();
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['updateProductTemplate.Fail'] });
  }
}

function* updateProductTemplateMetadata({ payload: template, entityType }) {
  try {
    yield put({ type: constants.SET_SAVE_TEMPLATE_IN_PROGRESS, [constants.SAVE_TEMPLATE_IN_PROGRESS]: true });
    template.activity = prepareActivityDataForBackend(convertActions(template.entity));
    delete template.entity;
    yield call(api.updateProductTemplate, template, entityType);
    yield put({
      type: constants.UPDATE_PRODUCT_TEMPLATE_SUCCESS,
      payload: template
    });
    yield addSuccessMessage();
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['updateProductTemplate.Fail'] });
  }
}

function* deleteProductTemplate({ payload, entityType }) {
  try {
    yield put(setLoadingState(true));
    yield call(api.deleteProductTemplate, payload.templateId, entityType);
    yield put({ type: constants.DELETE_PRODUCT_TEMPLATE_SUCCESS, payload });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['deleteProductTemplate.Fail'] });
  } finally {
    yield put(setLoadingState(false));
  }
}

function* templatesSaga() {
  yield [
    takeLatest(constants.GET_BUSINESS_TEMPLATES, getBusinessTemplates),
    takeLatest(constants.GET_PRODUCT_TEMPLATES, getActivityProductTemplates),
    takeLatest(constants.GET_PRODUCT_CATEGORIES, getProductCategories),
    takeLatest(constants.SAVE_AS_BUSINESS_TEMPLATE, saveAsBusinessTemplate),
    takeLatest(constants.SAVE_AS_PRODUCT_TEMPLATE, saveAsProductTemplate),
    takeLatest(constants.DELETE_BUSINESS_TEMPLATE, deleteBusinessTemplate),
    takeLatest(constants.UPDATE_BUSINESS_TEMPLATE, updateBusinessTemplate),
    takeLatest(constants.UPDATE_PRODUCT_TEMPLATE, updateProductTemplate),
    takeLatest(constants.DELETE_PRODUCT_TEMPLATE, deleteProductTemplate),
    takeLatest(constants.UPDATE_BUSINESS_TEMPLATE_METADATA, updateBusinessTemplateMetadata),
    takeLatest(constants.UPDATE_PRODUCT_TEMPLATE_METADATA, updateProductTemplateMetadata),
    takeLatest(constants.GET_BUSINESSES_VERTICALS, getBusinessesVerticals)
  ];
}


export default templatesSaga;
