import * as appConstants from './app.constants';
import * as routeConstants from '../../constants/route.contants';

export function getIdentifierType(val) {
  if (/^\d+$/.test(val)) {
    return appConstants.CUSTOMER_IDENTIFIER_PHONE;
  }
  if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val)) {
    return appConstants.CUSTOMER_IDENTIFIER_EMAIL;
  }
  return appConstants.CUSTOMER_IDENTIFIER_KEY;
}

export function GetFeaturesByPage(pageName) {
  let pageToCheck = pageName;
  const featureNames = [];
  const explicitPageNameParts = ['/rules/', '/deals/', '/punchCards/', '/gifts/', '/emails/', '/promoCodes/', '/onetimes/'];
  if (explicitPageNameParts.includes(pageName)) {
    pageToCheck = `/${pageName.split('/')[1]}`;
  }
  switch (pageToCheck) {
    case routeConstants.BUSINESS_CENTER_ROUTE:
    case routeConstants.GIFTS_ROUTE:
    case routeConstants.PROMO_CODES_ROUTE:
    case routeConstants.EMAILS_ROUTE:
    case routeConstants.RULES_ROUTE:
    case routeConstants.DEALS_ROUTE:
    case routeConstants.PUNCH_CARDS_ROUTE:
    case '/onetimes':
      featureNames.push(appConstants.FEATURE_NAME_CAMPAIGN_CENTER);
      break;
    case routeConstants.DATA_BI_ROUTE:
    case routeConstants.DATA_BI_LOOKER_ANALYTICS:
      featureNames.push(appConstants.FEATURE_NAME_VIEW_DATA);
      break;
    case routeConstants.DATA_BI_LOOKER_ROUTE:
    case routeConstants.DATA_BI_LOOKER_DASHBOARDS_ROUTE:
      featureNames.push(appConstants.FEATURE_NAME_DATA_REPORTS);
      break;
    case routeConstants.FILTER_MEMBERS_ROUTE:
    case routeConstants.BASIC_FILTER_MEMBERS_ROUTE:
      featureNames.push(appConstants.FEATURE_NAME_FILTER);
      break;
    case routeConstants.DATA_EXPORT_ROUTE:
      featureNames.push(appConstants.FEATURE_NAME_DATA_EXPORT);
      break;
    case routeConstants.BUSINESS_PROFILE:
      featureNames.push(appConstants.FEATURE_NAME_BUSINESS_SETTINGS);
      break;
    case routeConstants.CUSTOMER_ROUTE:
      featureNames.push(appConstants.FEATURE_NAME_FIND_CUSTOMER);
      break;
    default:
      break;
  }
  return featureNames;
}
