import { call, put, takeLatest } from 'redux-saga/effects';
import * as api from './ai.api';
import * as constants from './ai.constants';
import * as errorConstant from '../ErrorMessage/errorMessage.constants';

function* getPromptData({ payload: promptData }) {
  try {
    yield put({ type: constants.SET_LOADING_STATE, [constants.IS_LOADING]: true });
    const data = yield call(api.getPromptText, promptData);
    yield put({ type: constants.GET_PROMPT_SUCCESS, data });
  } catch (err) {
    yield put({ type: constants.GET_PROMPT_FAIL });
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getAiPrompt.Fail'] });
  } finally {
    yield put({ type: constants.SET_LOADING_STATE, [constants.IS_LOADING]: false });
  }
}

// sagas listen to dispatched actionsList too (same as reducers)
function* aiSaga() {
  yield [
    takeLatest(constants.GET_PROMPT, getPromptData),
  ];
}

export default aiSaga;
