/*
 *
 * Benefits actions
 *
 */

import * as constants from './benefits.constants';

export function getBenefits() {
  return {
    type: constants.GET_BENEFITS
  };
}
