import React, { useEffect, useMemo } from 'react';
import { Radio } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';

import * as dateConstants from '../../../common/utils/dateTime.constants';
import * as constants from '../../Activity/activity.constants';

const Files = (props) => {
  const { files, reportKey, intl, selectedValue, onSelect } = props;

  const { formatMessage } = intl;

  const firstFileName = useMemo(() => files[0].name, [files]);

  useEffect(() => {
    onSelect(firstFileName);
  }, [reportKey, firstFileName]);

  return (
    <React.Fragment>
      {files.map((file) => {
        const fileName = file.name;
        const isChecked = fileName === selectedValue;

        return (
          <Radio
            radio
            key={`listing-option-${reportKey}-${fileName}`}
            id={fileName}
            label={(
              <label htmlFor={fileName}>
                <h4>{file.name.split('/')[1]}</h4>
                <div className={'data-export-file-name'}>
                  <div>{moment(file.timeCreated).format(dateConstants.STANDARD_TIME_FORMAT)}</div>
                  <span>{file.daysToDeletion} {formatMessage({ id: 'data-and-bi.export.report.daysLeft', defaultMessage: 'days left' })}</span>
                </div>
              </label>
            )}
            name={`data-export.${reportKey}.group`}
            value={constants.ACTIONS_SIMPLE_MODE}
            checked={isChecked}
            onClick={() => onSelect(fileName)}
          />
        );
      })}
    </React.Fragment>
  );
};

Files.propTypes = {
  intl: PropTypes.object,
  files: PropTypes.array.isRequired,
  reportKey: PropTypes.string.isRequired,
  selectedValue: PropTypes.string,
  onSelect: PropTypes.func
};

export default injectIntl(Files);
