/**
 *
 * Points
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { INTEGER_TYPE } from '../inputConstants';
import PercentageInputField from '../PercentageInputField/PercentageInputField';
import { getDecimalPrecision } from '../../../../features/App/selectors';

class PointsPercentageInputField extends React.PureComponent {

  static propTypes = {
    allowOnlyPositive: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    fluid: PropTypes.bool,
    name: PropTypes.string,
    prefix: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func,
    precision: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number
  };

  static defaultProps = {
    fluid: false,
    allowOnlyPositive: true
  };

  constructor(props) {
    super(props);
    this.state = { displayValue: this.GetDisplayValueByPrecision(props.value) };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ displayValue: this.GetDisplayValueByPrecision(nextProps.value) });
  }

  onKeyDown = (event) => {
    if (this.props.allowOnlyPositive && event.key === '-') {
      event.preventDefault();
      return false;
    }
    return true;
  };

  onChange = (e, data) => {
    this.setState({ displayValue: parseFloat(data.value) });

    let formattedValue;
    if (this.props.precision === 0) {
      formattedValue = parseFloat(data.value ? data.value : null) / 100;
    } else {
      formattedValue = parseFloat(data.value ? data.value : null);
    }
    const newData = { name: data.name, value: formattedValue };

    this.props.onChange(e, newData);
  };

  GetDisplayValueByPrecision(value) {
    if (this.props.precision === 0) {
      return value === null ? null : (value * 100);
    }
    return value === null ? null : value;
  }

  render() {

    const { disabled, error, fluid, name, prefix, min, max } = this.props;
    const className = fluid ? this.props.className : `${this.props.className} points-percentage-field`;

    return (<PercentageInputField
      className={className}
      disabled={disabled}
      error={error}
      fluid={fluid}
      name={name}
      min={min}
      max={max}
      onChange={this.onChange}
      onKeyDown={this.onKeyDown}
      prefix={prefix}
      type={INTEGER_TYPE}
      value={this.state.displayValue}
    />
    );
  }
}

const mapStateToProps = (state) => ({
  precision: getDecimalPrecision(state)
});

const withConnect = connect(mapStateToProps);

export default compose(
  withConnect,
  injectIntl,
)(PointsPercentageInputField);
