import React from 'react';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Image } from 'semantic-ui-react';
import _ from 'lodash';
import Tooltip from '../../../common/components/Tooltip/Tooltip';
import Modal from '../../../common/components/CustomModal/CustomModal';
import * as appSelectors from '../../App/selectors';
import * as imagesConstants from '../../Images/images.constants';


const NAME_LETTERS_MAX = 25;

class GiftHistoryLog extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    historyLog: PropTypes.object.isRequired,
    onConfirm: PropTypes.func,
    open: PropTypes.bool,
    localeDateFormat: PropTypes.string.isRequired,
    localeTimeFormat: PropTypes.string.isRequired,
    thumbImage: PropTypes.object,
    migrated: PropTypes.bool
  };

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line
    const { name, historyLog, onConfirm, open, thumbImage } = this.props;

    return (
      <Modal
        open={open}
        className="gift-history-log-modal"
        onClose={onConfirm}
        automationIdPrefix="gift.details"
        header={
          <div className="type-and-name">
            {thumbImage && <Image
              className="gift-mini-thumbnail"
              src={thumbImage ? thumbImage.get(imagesConstants.URL) : ''}
            />}
            {name.length > NAME_LETTERS_MAX ?
              <Tooltip
                content={name}
                trigger={
                  <p>{name}</p>
                }
                position="bottom right"
              /> : <p>{name}</p>
            }
          </div>
        }
        content={
          <div>
            <ul>{
            Object.entries(historyLog.toJS())
              .map(([key, value]) => {
                if (!value) {
                  return null;
                }
                const { firstName, lastName } = value;
                const userName = `${!_.isNil(firstName) ? firstName : ''} ${!_.isNil(lastName) ? lastName : ''}`.trim();
                const date = `${moment(value.date).format(this.props.localeDateFormat)} ${moment(value.date).format(this.props.localeTimeFormat)}`;
                return (
                  <li key={key}>
                    <span
                      className="log--title"
                    >{formatMessage({ id: `business-center.campaign.activity.historyLog.${key}` })}</span>
                    <div
                      className="log--content"
                      title={userName.length > 0
                          ? formatMessage({ id: 'business-center.campaign.activity.historyLog.content.with.name' }, { date, userName })
                          : formatMessage({ id: 'business-center.campaign.activity.historyLog.content' }, { date })
                        }
                    >
                      {userName.length > 0
                          ? formatMessage({ id: 'business-center.campaign.activity.historyLog.content.with.name' }, { date, userName })
                          : formatMessage({ id: 'business-center.campaign.activity.historyLog.content' }, { date })
                      }
                    </div>
                  </li>
                );
              })}
            </ul>
            {this.props.migrated &&
            <span className="hint-tip-label stick-to-bottom">
              {formatMessage({ id: 'gift.historyLog.migrated.note' })}
            </span>
            }
          </div>}
        actions={[
          <a role="button" key="gotIt" onClick={onConfirm}>{formatMessage({ id: 'general.button.got-it' })}</a>
        ]}
        closeOnDimmerClick
      />
    );
  }
}

const mapStateToProps = (state) => ({
  localeDateFormat: appSelectors.getBrowserLocaleDateFormat(state),
  localeTimeFormat: appSelectors.getBrowserLocaleTimeFormat(state)
});

export default connect(mapStateToProps)(injectIntl(GiftHistoryLog));
