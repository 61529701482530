import * as constants from '../../../activity.constants';
import * as errorConstant from '../../../../ErrorMessage/errorMessage.constants';

export function updateGlobalDateRangeDate(dateObject, isStart, localeTimeFormat, businessTimezone) {
  return {
    type: constants.UPDATE_DATE_RANGE_DATE,
    dateObject,
    isStart,
    localeTimeFormat,
    conditionType: constants.GLOBAL_CONDITIONS,
    businessTimezone
  };
}

export function updateUserActionFilterDateRange(dateObject, isStart, localeTimeFormat, allowInfinite, userActionIndex, businessTimezone) {
  return {
    type: constants.UPDATE_DATE_RANGE_DATE,
    dateObject,
    isStart,
    localeTimeFormat,
    allowInfinite,
    conditionParent: constants.FILTERS_USER_ACTION,
    userActionIndex,
    businessTimezone
  };
}

export function updateGlobalDaysTimes(data, index, isMilitaryTime, businessTimezone) {
  return {
    type: constants.UPDATE_DAYS_TIMES_CONDITION,
    daysTimes: data,
    index,
    isMilitaryTime,
    conditionType: constants.GLOBAL_CONDITIONS,
    businessTimezone
  };
}

export function createReferralCodeTempModel(userActionIndex, conditionIndex) {
  return {
    type: constants.CREATE_REFERRAL_CODE_TEMP_MODEL,
    userActionIndex,
    conditionIndex
  };
}

export function deleteReferralCodeTempModel() {
  return {
    type: constants.DELETE_REFERRAL_CODE_TEMP_MODEL
  };
}

export function saveReferralCodeTempModelToGlobalConditions(userActionIndex, conditionIndex) {
  return {
    type: constants.SAVE_REFERRAL_CODE_TEMP_MODEL_TO_CONDITIONS,
    userActionIndex,
    conditionIndex
  };
}

export function addGlobalDaysTimesCondition() {
  return {
    type: constants.ADD_DAYS_TIMES_CONDITION,
    conditionType: constants.GLOBAL_CONDITIONS
  };
}

export function deleteGlobalDaysTimesCondition(index) {
  return {
    type: constants.DELETE_DAYS_TIMES_CONDITION,
    index,
    conditionType: constants.GLOBAL_CONDITIONS
  };
}

export function saveGlobalDateTimeChanges() {
  return {
    type: constants.SAVE_GLOBAL_DATE_TIME_CHANGES
  };
}

export function createTempGlobalDateTimeConditions() {
  return {
    type: constants.CREATE_TEMP_GLOBAL_DATE_TIME_CONDITIONS
  };
}

export function resetGlobalDateTimeConditions(conditionType) {
  return {
    type: constants.RESET_GLOBAL_DATE_TIME_CONDITIONS,
    conditionType
  };
}

export function updateGlobalDateTimeConditionType(conditionType) {
  return {
    type: constants.UPDATE_GLOBAL_DATETIME_CONDITION_TYPE,
    conditionType
  };
}

export function clearTempGlobalDateTime() {
  return {
    type: constants.CLEAR_TEMP_GLOBAL_DATETIME
  };
}

export function filterMembersAndApplyFromTempModel() {
  return {
    type: constants.FILTER_MEMBERS,
  };
}

export function openGlobalMembershipConditionsModal(isFilteredPopulation) {
  return {
    type: constants.OPEN_GLOBAL_MEMBERSHIP_CONDITIONS_MODAL,
    isFilteredPopulation,
  };
}

export function closeGlobalMembershipConditionsModal(isFilteredPopulation, shouldUpdateModel) {
  return {
    type: constants.CLOSE_GLOBAL_MEMBERSHIP_CONDITIONS_MODAL,
    isFilteredPopulation,
    shouldUpdateModel,
  };
}

export function exportOneTimePopulation(filteredPopulation) {
  return {
    type: constants.EXPORT_FILTERED_POPULATION,
    filteredPopulation
  };
}

export function openFilterModalAndApplyFilter() {
  return {
    type: constants.OPEN_FILTER_MODAL_AND_APPLY_FILTER,
  };
}

export function updateGlobalConditionsType(conditionType) {
  return {
    type: constants.UPDATE_GLOBAL_MEMBERSHIP_CONDITION_TYPE,
    conditionType
  };
}

export function openFilterModalAndApplyCommunicationActionFilter() {
  return {
    type: constants.OPEN_FILTER_MODAL_AND_APPLY_COMMUNICATION_ACTION_FILTER,
  };
}

export function updateGlobalMembershipConditionField(name, value, index) {
  return {
    type: constants.UPDATE_GLOBAL_MEMBERSHIP_CONDITION_FIELD,
    name,
    value,
    conditionIndex: index
  };
}

export function updateFilterUserActionAttribute(attributeName, attributeValue, conditionIndex, userActionIndex, conditionParent) {
  return {
    type: constants.UPDATE_GLOBAL_MEMBERSHIP_CONDITION_FIELD,
    conditionParent,
    name: attributeName,
    value: attributeValue,
    conditionIndex,
    userActionIndex,
  };
}

export function updateGlobalMembershipStatusCondition(value) {
  return {
    type: constants.UPDATE_GLOBAL_MEMBERSHIP_STATUS_CONDITION,
    value
  };
}

export function updateGlobalImportMembershipCondition(value, importMode, conditionType) {
  return {
    type: constants.UPDATE_GLOBAL_IMPORT_MEMBERSHIP_CONDITION,
    importMode,
    value,
    conditionType
  };
}

export function clearGlobalImportMembershipCondition() {
  return {
    type: constants.CLEAR_GLOBAL_IMPORT_MEMBERSHIP_COPY_PASTE_CONDITION,
  };
}

export function goingToPasteImportMembersData() {
  return {
    type: constants.GOING_TO_PASTE_IMPORT_MEMBERS_DATA,
  };
}

export function addGlobalMembershipCondition() {
  return {
    type: constants.ADD_GLOBAL_MEMBERSHIP_CONDITION
  };
}

export function updateGlobalMembershipConditionsOperator(value) {
  return {
    type: constants.UPDATE_GLOBAL_MEMBERSHIP_CONDITION_MEMBER_ATTRIBUTES_OPERATOR,
    value
  };
}

export function updateGlobalMembershipConditionsActionOperator(value) {
  return {
    type: constants.UPDATE_GLOBAL_MEMBERSHIP_CONDITION_MEMBER_ACTIONS_OPERATOR,
    value
  };
}

export function deleteGlobalMembershipCondition(index) {
  return {
    type: constants.DELETE_GLOBAL_MEMBERSHIP_CONDITION,
    index
  };
}

export function resetMembershipStatusCondition() {
  return {
    type: constants.COPY_MEMBERSHIP_STATUS_CONDITION_TO_TEMP_DATA
  };
}

export function deleteTempMembershipStatusCondition() {
  return {
    type: constants.DELETE_TEMP_MEMBERSHIP_STATUS_CONDITION
  };
}

export function saveMembershipStatusCondition() {
  return {
    type: constants.SAVE_MEMBERSHIP_STATUS_CONDITION
  };
}

export function copyGlobalOccurrencesLimitConditionsToTempModel() {
  return {
    type: constants.COPY_GLOBAL_OCCURRENCES_LIMIT_CONDITION_TO_TEMP_MODEL
  };
}

export function applyLimitOccurrencesModel() {
  return {
    type: constants.APPLY_GLOBAL_OCCURRENCES_LIMIT_CONDITION_FROM_TEMP_MODEL
  };
}

export function removeLimitOccurrencesTempModel() {
  return {
    type: constants.REMOVE_GLOBAL_OCCURRENCES_LIMIT_CONDITION_TEMP_MODEL
  };
}

export function updateTempLimitPerMemberGlobalConditionType(value) {
  return {
    type: constants.UPDATE_LIMIT_PER_MEMBER_GLOBAL_CONDITION_TYPE,
    value
  };
}

export function updateTempLimitPerMemberGlobalConditionTotalLimitEnable(value) {
  return {
    type: constants.UPDATE_LIMIT_PER_MEMBER_GLOBAL_CONDITION_TOTAL_LIMIT_ENABLE,
    value
  };
}

export function updateTempLimitPerMemberGlobalConditionByPeriodLimitEnable(value) {
  return {
    type: constants.UPDATE_LIMIT_PER_MEMBER_GLOBAL_CONDITION_BY_PERIOD_LIMIT_ENABLE,
    value
  };
}

export function updateTempLimitPerMemberGlobalConditionByPeriodUnitChange(value) {
  return {
    type: constants.UPDATE_LIMIT_PER_MEMBER_GLOBAL_CONDITION_BY_PERIOD_UNIT_CHANGE,
    value
  };
}

export function updateTempLimitPerMemberGlobalConditionByPeriodPeriodMultiplier(value) {
  return {
    type: constants.UPDATE_LIMIT_PER_MEMBER_GLOBAL_CONDITION_BY_PERIOD_PERIOD_MULTIPLIER,
    value
  };
}

export function updateTempLimitPerMemberGlobalConditionByPeriodPeriodCount(value) {
  return {
    type: constants.UPDATE_LIMIT_PER_MEMBER_GLOBAL_CONDITION_BY_PERIOD_PERIOD_COUNT,
    value
  };
}

export function updateTempLimitPerMemberGlobalConditionTotalOccurrencesCount(value) {
  return {
    type: constants.UPDATE_LIMIT_PER_MEMBER_GLOBAL_CONDITION_TOTAL_OCCURRENCES_COUNT,
    value
  };
}

export function updateGlobalSchedulingType(schedulingType) {
  return {
    type: constants.UPDATE_SCHEDULING_TYPE,
    schedulingType
  };
}

export function updateGlobalSchedulingDate(dateObject) {
  return {
    type: constants.UPDATE_SCHEDULING_DATE,
    dateObject
  };
}

export function saveSchedulingChanges() {
  return {
    type: constants.SAVE_SCHEDULING_CHANGES
  };
}

export function createTempSchedulingData() {
  return {
    type: constants.CREATE_TEMP_SCHEDULING_DATA
  };
}

export function resetSchedulingData() {
  return {
    type: constants.RESET_SCHEDULING_DATA
  };
}

export function updateFilterUserActionType(userActionType, userActionIndex, businessTimezone) {
  return {
    type: constants.UPDATE_FILTER_USER_ACTION_TYPE,
    userActionType,
    userActionIndex,
    businessTimezone
  };
}

export function addUserActionCriteriaToFilter(userActionIndex) {
  return {
    type: constants.ADD_USER_ACTION_CRITERIA_TO_FILTER,
    userActionIndex
  };
}

export function deleteUserActionCriteriaFromFilter(userActionIndex, conditionIndex) {
  return {
    type: constants.DELETE_GLOBAL_MEMBERSHIP_CONDITION,
    conditionParent: constants.FILTERS_USER_ACTION,
    userActionIndex,
    conditionIndex
  };
}

export function clearFilteredPopulation() {
  return {
    type: constants.CLEAR_FILTERED_POPULATION
  };
}

export function deleteUserActionFromFilter(userActionIndex) {
  return {
    type: constants.DELETE_USER_ACTION_FROM_FILTER,
    userActionIndex
  };
}

export function updateFilterUserActionAggregationAttribute(attributeName, attributeValue, isActive, attributeIndex, userActionIndex) {
  return {
    type: constants.UPDATE_USER_ACTION_AGGREGATION_CRITERIA,
    name: attributeName,
    value: attributeValue,
    isActive,
    attributeIndex,
    userActionIndex,
  };
}


export function uploadImportMembersFile(fileHandle, fileType) {
  return {
    type: constants.IMPORT_MEMBERS_FILE_UPLOAD,
    fileHandle,
    fileType
  };
}

export function displayUploadMembersFileError() {
  return {
    type: errorConstant.HANDLE_ERROR,
    [errorConstant.MESSAGES_KEYS]: ['uploadMembersFile.rejected.Fail']
  };
}
