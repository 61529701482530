import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { injectIntl } from 'react-intl';
import { NavBarItem, VerticalNavBar } from '../../common/components/VerticalNavBar/VerticalNavBar';
import * as constants from './sideNavBar.constants';
import * as sideNavBarActions from './sideNavBar.actions';
import './sideNavBar.styles.less';

class SideNavBar extends React.PureComponent {

  static propTypes = {
    actions: PropTypes.object.isRequired,
    collapseMap: PropTypes.object,
    intl: PropTypes.object,
    sideNavBarItems: PropTypes.array
  };

  state = {
    isSideBarOpen: localStorage.getItem('isSideBarOpen') === 'true'
  };

  toggleSideBar = () => {
    this.setState({ isSideBarOpen: !this.state.isSideBarOpen });
    localStorage.setItem('isSideBarOpen', !this.state.isSideBarOpen);
  };

  render() {
    const formatMessage = this.props.intl.formatMessage;

    return (
      <div className={`side-nav-bar-container ${this.state.isSideBarOpen ? '' : 'collapsed'}`}>

        <VerticalNavBar
          onToggleCollapse={this.props.actions.toggleCollapse}
          collapseMap={this.props.collapseMap}
          className="side-nav-bar"
        >
          {this.props.sideNavBarItems.map((item, i) => {
            return (
              <NavBarItem
                key={i.toString()}
                icon={item.icon}
                text={item.text}
                target={item.target}
                automationId={item.automationId}
                parentId={item.parentId}
                isContainerCollapsed={!this.state.isSideBarOpen}
              />
            );
          })}

        </VerticalNavBar>

        <div className="side-nav-bar-separator" />

        <div className="side-nav-bar-toggle-btn" onClick={this.toggleSideBar}>
          <i className={'icon como-svg-side-nav-icon-toggle-toolbar'} />
          <span>{formatMessage({ id: 'side-navbar.toggle-toolbar' })}</span>
        </div>
      </div>

    );
  }
}

const mapStateToProps = (state) => ({
  collapseMap: state.getIn([constants.SIDE_NAVBAR, constants.COLLAPSE_MAP])
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sideNavBarActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, injectIntl)(SideNavBar);
