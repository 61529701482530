import * as constants from '../gifts.constants';
import * as activityConstants from '../../Activity/activity.constants';
import { sortArrayByDate } from '../../../common/utils/dateTimeUtils';
import { calculateActivityViewStatus } from '../../Activity/activity.selectors.utils';

export function getFilteredAndSortedGiftsImpl(gifts, filters, order, searchTerm) {
  const filtered = getFilteredGifts(gifts, filters, searchTerm);
  const orderFactor = order === constants.ORDER_ASCENDING ? 1 : -1;
  return sortArrayByDate(filtered, [activityConstants.CREATE_DATE], orderFactor);
}

export function isGiftsFilteredImpl(gifts, filteredGifts) {
  return gifts.size !== filteredGifts.size;
}

export function getContextMenuItemsVisibilityMap(status) {
  const isActive = status === activityConstants.ACTIVITY_STATUS_ACTIVE;
  const isDraft = status === activityConstants.ACTIVITY_STATUS_DRAFT;
  return {
    [activityConstants.CONTEXT_MENU_OPTION_DETAILS]: true,
    [activityConstants.CONTEXT_MENU_OPTION_ACTIVATE]: !isActive && !isDraft,
    [activityConstants.CONTEXT_MENU_OPTION_DEACTIVATE]: isActive,
    [activityConstants.CONTEXT_MENU_OPTION_STATS]: !isDraft,
    [activityConstants.CONTEXT_MENU_OPTION_USAGES]: !isDraft,
    [activityConstants.CONTEXT_MENU_OPTION_DELETE]: isDraft,
    [activityConstants.CONTEXT_MENU_OPTION_OPEN_IN_NEW_TAB]: true
  };
}

function getFilteredGifts(gifts, filters, searchTerm) {
  const searchTermLower = searchTerm && searchTerm.toLowerCase();
  const filterFlags = filters.get(constants.GIFTS);
  return gifts.filter((gift) => {

    const viewStatus = calculateActivityViewStatus(gift);
    if ((viewStatus === activityConstants.ACTIVITY_STATUS_ACTIVE && !filterFlags.get(activityConstants.FILTERS_ACTIVITY_ACTIVE)) ||
        (viewStatus === activityConstants.ACTIVITY_STATUS_STOPPED && !filterFlags.get(activityConstants.FILTERS_ACTIVITY_STOPPED)) ||
        (viewStatus === activityConstants.ACTIVITY_STATUS_FUTURE && !filterFlags.get(activityConstants.FILTERS_ACTIVITY_FUTURE)) ||
        (viewStatus === activityConstants.ACTIVITY_STATUS_COMPLETED && !filterFlags.get(activityConstants.FILTERS_ACTIVITY_COMPLETED)) ||
        (viewStatus === activityConstants.ACTIVITY_STATUS_DRAFT && !filterFlags.get(activityConstants.FILTERS_ACTIVITY_DRAFT))) {
      return false;
    }
    return !searchTerm || gift.get(activityConstants.NAME).toLowerCase().includes(searchTermLower);
  });
}
