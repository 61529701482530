import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import BenefitsList from '../../../../../Benefits/views/benefitsList/benefitsList';
import * as constants from '../../../../activity.constants';
import { getSelectedBenefit } from '../../../../../Benefits/benefits.selector';
import { BENEFIT_ACTIVE, BENEFIT_TITLE } from '../../../../../Benefits/benefits.constants';
import './punchExceeded.styles.less';

class PunchExceeded extends React.PureComponent {

  static propTypes = {
    action: PropTypes.object,
    automationIdPrefix: PropTypes.string,
    index: PropTypes.number,
    selectedBenefit: PropTypes.object,
    onActionFieldUpdate: PropTypes.func.isRequired,
    validationErrors: PropTypes.object
  };

  onBenefitSelect = (e, data) => {
    this.props.onActionFieldUpdate(data.name, data.value, this.props.index);
  };

  render() {

    const { action, index, selectedBenefit, validationErrors, automationIdPrefix } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    return (
      <Grid>
        <Grid.Row className="action assign-asset-action" columns={2}>
          <Grid.Column width={16}>
            <BenefitsList
              index={index}
              onChange={this.onBenefitSelect}
              value={action.get(constants.PUNCH_CARD_KEY)}
              name={constants.PUNCH_CARD_KEY}
              action={action}
              benefitType={constants.BENEFIT_PUNCH_CARD}
              prefix={`${automationIdPrefix}.punchCardKey`}
              error={validationErrors ? validationErrors.get(constants.PUNCH_CARD_KEY) : null}
            />
            <span className="label added-text">{formatMessage({
              id: 'activity.action.exceededPunchNumber',
            })}</span>
          </Grid.Column>
        </Grid.Row>
        {
          selectedBenefit && !selectedBenefit.get(BENEFIT_ACTIVE) &&
            (
              <Grid.Row>
                <Grid.Column>
                  <p className="hint">
                    {formatMessage({ id: 'benefits.inactive.hint' }, { benefitName: selectedBenefit.get(BENEFIT_TITLE) })}
                  </p>
                </Grid.Column>
              </Grid.Row>
            )
        }
      </Grid>
    );
  }
}

const mapStateToProps = (state, props) => ({
  selectedBenefit: getSelectedBenefit(state, props.action.get(constants.PUNCH_CARD_KEY))
});

const withConnect = connect(mapStateToProps, {});

export default compose(withConnect, injectIntl)(PunchExceeded);
