/*
 *
 * Members constants
 *
 */

// ACTIONS CONSTANTS

export const GO_TO_CUSTOMER = 'app/Members/GO_TO_CUSTOMER';
export const GET_MEMBERS_FILTERED_BY_COMMUNICATION_ACTIONS = 'app/Members/GET_MEMBERS_FILTERED_BY_COMMUNICATION_ACTIONS';
export const GET_MEMBERS_FILTERED_BY_COMMUNICATION_ACTIONS_SUCCESS = 'app/Members/GET_MEMBERS_FILTERED_BY_COMMUNICATION_ACTIONS_SUCCESS';
export const GET_MEMBERS_FILTERED_BY_COMMUNICATION_ACTIONS_DONE = 'app/Members/GET_MEMBERS_FILTERED_BY_COMMUNICATION_ACTIONS_DONE';
export const FIND_MEMBERS = 'app/Members/FIND_MEMBERS';
export const SHOW_MULTIPLE_MEMBERS_POPUP = 'app/Members/SHOW_MULTIPLE_MEMBERS_POPUP';
export const HANDLE_MEMBER_POPUP_VISIBILITY = 'app/Members/ANDLE_MEMBER_POPUP_VISIBILITY';
export const CLEAR_SEARCH_QUERY = 'app/Members/CLEAR_SEARCH_QUERY';

// STORE CONSTANTS

export const CUSTOMER_SEARCH = 'customerSearch';
export const MEMBERS_FILTERED_BY_COMMUNICATION_ACTIONS = 'membersFilteredByCommunicationActions';
export const MEMBERS_TOUCHED_LOADED = 'membersTouchedLoaded';
export const FILTERED_MEMBERS = 'filteredMembers';
export const TOUCHED_MEMBERS = 'touchedMembers';
export const MEMBERS_POPUP_STATUS = 'memberPopupStatus';
export const MEMBERS_POPUP_DATA = 'memberPopupData';
export const MEMBERS_POPUP_TOTAL = 'memberPopupTotal';
export const MEMBERS_SEARCH_QUERY = 'membersSearchQuery';
