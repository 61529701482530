/**
*
* SingleImage
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { injectIntl } from 'react-intl';

import * as constants from '../../images.constants';
import ConfirmationDialog from '../../../../common/components/ConfirmationDialog/ConfirmationDialog';
import { MODAL_LOADING_ICON } from '../../../../common/components/CustomModal/customModal.constants';


class SingleImage extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string,
    deleteInProgress: PropTypes.number,
    image: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    localeDateFormat: PropTypes.string.isRequired,
    onDelete: PropTypes.func,
    onSelect: PropTypes.func,
    onDoubleClick: PropTypes.func,
    selectedId: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.selectedImage = React.createRef();
    this.onSelect = this.onSelect.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onDoubleClick = this.onDoubleClick.bind(this);
  }

  componentDidMount() {
    if (this.selectedImage.current) { // todo: maybe put ternary here
      this.selectedImage.current.scrollIntoView();
    }
  }

  onSelect(e) {
    this.props.onSelect(e, this.props.image);
  }

  onDelete(e) {
    this.props.onDelete(e, this.props.image);
  }

  onDoubleClick(e) {
    this.props.onDoubleClick(e, this.props.image);
  }

  get imageCreateDate() {
    return this.props.image.get(constants.CREATE_TIME);
  }

  get isImageSelected() {
    return this.props.selectedId === this.props.image.get(constants.ID);
  }

  get isImageDefault() {
    return this.props.image.get(constants.SOURCE) === constants.IMAGE_SOURCE_DEFAULT;
  }

  get isBeingDeleted() {
    return this.props.deleteInProgress && this.props.deleteInProgress === this.props.image.get(constants.ID);
  }

  render() {
    const { automationIdPrefix, image, intl } = this.props;
    const imageUrlEncoded = encodeURI(image.get(constants.URL));
    const imageName = image.get(constants.DISPLAY_NAME);

    return (
      <div
        className="single-image"
        ref={this.isImageSelected ? this.selectedImage : null}
      >
        <div
          className={this.isImageSelected ? 'selected' : ''}
          data-automation-id={`${automationIdPrefix}`}
          onClick={this.onSelect}
          onDoubleClick={this.onDoubleClick}
          role="button"
          style={{ backgroundImage: `url(${imageUrlEncoded})` }}
        />

        <div className="single-image-footer">
          <span className="single-image-name" title={imageName}>{imageName}</span>
          {
            this.imageCreateDate &&
            <span className="single-image-date">{moment(this.imageCreateDate).format(this.props.localeDateFormat)}</span>
          }
          {!this.isImageSelected && (!this.props.deleteInProgress || this.isBeingDeleted) && (
            <ConfirmationDialog
              automationIdPrefix={`${automationIdPrefix}.delete`}
              confirmButtonKey="general.button.delete"
              tooltipMessageKey="imageGallery.delete.tooltip"
              contentMessageKey="imageGallery.delete.confirmationMessage"
              confirmAction={this.onDelete}
              className={`delete-image circular ${this.isBeingDeleted ? 'delete-in-progress' : ''}`}
              icon="como-ic-delete"
              loading={this.isBeingDeleted}
              isDefaultImage={this.isImageDefault}
              showLoadingElement={MODAL_LOADING_ICON}
            />
          )}
        </div>

      </div>
    );
  }
}

export default injectIntl(SingleImage);

