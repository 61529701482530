import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import ActivitiesType from './ActivityType';
import * as activityConstants from '../../Activity/activity.constants';
import * as routeConstants from '../../../constants/route.contants';
import tracker from '../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../common/utils/tracking/tracking.consts';
import * as promoCodeConstants from '../promoCodes.constants';

class AddActivitySegment extends React.PureComponent {

  static propTypes = {
    activityType: PropTypes.string,
    campaignId: PropTypes.string,
    campaignIndex: PropTypes.number,
    //locationId: PropTypes.number.isRequired,
    segmentContent: PropTypes.string,
    createActivity: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.segmentStyle = classNames('activity-segment', {
      fix: props.activityType === promoCodeConstants.PROMO_CODE_TYPE,
      generated: props.activityType === promoCodeConstants.PROMO_CODE_GENERATED,
      imported: props.activityType === promoCodeConstants.PROMO_CODE_IMPORTED
    });
  }

  render() {
    const { activityType, campaignIndex, segmentContent } = this.props;
    // todo: remove this ternary when punch-cards have templates
    // const createPath = activityType === activityConstants.ACTIVITY_TYPE_PUNCH_CARD ? routeConstants.PUNCH_CARDS_CREATE_ROUTE : routeConstants.TEMPLATE_LIBRARY_ROUTE;
    return (
      // <Link to={{ pathname: createPath, search: location.search, type: this.props.activityType }}>
        <Segment
          className={this.segmentStyle}
          data-automation-id={`business-center.campaign.${campaignIndex}.add-activity.${activityType}`}
          onClick={this.props.createActivity}
        >
          <ActivitiesType type={activityType} />
          <p className="segment-content">{segmentContent}</p>
        </Segment>
      // </Link>
    );
  }
}

export default AddActivitySegment;
