import {createSelector} from 'reselect';
import {getTransformedGlobalMembershipConditions} from '../Activity/activitySchema/activitySchema.selectors';
import * as constants from './filterMembers.constants';
import hatGlasses from '../../../app/images/icons/memberImages/hatGlasses.svg';
import glasses from '../../../app/images/icons/memberImages/glasses.svg';
import hair from '../../../app/images/icons/memberImages/hair.svg';
import headphones from '../../../app/images/icons/memberImages/headphones.svg';
import mustache from '../../../app/images/icons/memberImages/mustache.svg';
import mustacheGlasses from '../../../app/images/icons/memberImages/mustacheGlasses.svg';
import ninja from '../../../app/images/icons/memberImages/ninja.svg';
import superWomen from '../../../app/images/icons/memberImages/superWomen.svg';
import workOut from '../../../app/images/icons/memberImages/workOut.svg';

const getGlobalMembershipConditionsFromSchema = createSelector(
  [(state) => state], getTransformedGlobalMembershipConditions);
export const getFilterGlobalMembershipConditionsFromSchema = (state) => {
  const arr = getGlobalMembershipConditionsFromSchema(state);
  return arr;
};
export const getMemberImages = () => [hatGlasses, glasses, hair, headphones, mustache, mustacheGlasses, ninja, superWomen, workOut];
export const getModel = (state) => state.getIn([constants.FILTER_MEMBERS_TEMP_MODEL, constants.FILTER_TEMP_MODEL]);
export const getTempModel = (state) => state.getIn([constants.FILTER_MEMBERS_TEMP_MODEL, constants.FILTER_TEMP_MODEL, constants.CONDITIONS, constants.CONDITIONS_LIST]);
export const getTempListOperator = (state) => state.getIn([constants.FILTER_MEMBERS_TEMP_MODEL, constants.FILTER_TEMP_MODEL, constants.CONDITIONS, constants.CONDITION_LIST_OPERATOR]);
export const getFetchedFormFields = (state) => state.getIn([constants.FILTER_MEMBERS_TEMP_MODEL, constants.FETCHED_FORM_FIELDS]);
export const getFiltersInProgressFlag = (state) => state.getIn([constants.FILTER_MEMBERS_TEMP_MODEL, constants.FILTERS_MEMBERS_IN_PROGRESS]);
export const getFilterExportInProgressFlag = (state) => state.getIn([constants.FILTER_MEMBERS_TEMP_MODEL, constants.EXPORT_IN_PROGRESS]);
export const getFiltersMembersConditionsValidationErrors = (state) => state.getIn([constants.FILTER_MEMBERS_TEMP_MODEL, constants.TEMP_FILTER_ERRORS]);
export const getFilterMembersNumberOfMembersValidationErrors = (state) => state.getIn([constants.FILTER_MEMBERS_TEMP_MODEL, constants.DATA, constants.FILTERED_POPULATION, constants.FILTER_EMPTY_STATE]);
export const getFilterMembersPopulation = (state) => state.getIn([constants.FILTER_MEMBERS_TEMP_MODEL, constants.DATA, constants.FILTERED_POPULATION]);
export const getFilteredPopulationSize = (state) => state.getIn([constants.FILTER_MEMBERS_TEMP_MODEL, constants.DATA, constants.FILTERED_POPULATION, constants.FILTERED_NUM_OF_MEMBERS]);
export const getFilteredPopulationSamples = (state) => state.getIn([constants.FILTER_MEMBERS_TEMP_MODEL, constants.DATA, constants.FILTERED_POPULATION, constants.FILTERED_MEMBERS_SAMPLES]);
export const getReferralCodeTempModelCondition = (state) => state.getIn([constants.FILTER_MEMBERS_TEMP_MODEL, constants.TEMP_REFERRAL_CODE_MODEL]);
export const getReferralCodeTempError = (state) => state.getIn([constants.FILTER_MEMBERS_TEMP_MODEL, constants.TEMP_FILTERS_ERRORS, constants.TEMP_REFERRAL_CODE_MODEL, constants.CONDITION_VALUE]);
export const getFranchisesLocations = (state) => state.getIn(['app', 'franchisesData', 'franchises', 'userBusinessLocations', 'allowedLocations']);
export const getActivitiesList = (state) => state.getIn([constants.FILTER_MEMBERS_TEMP_MODEL, constants.ACTIVITIES, constants.DATA]);
export const getActivitiesListFetchedFlag = (state) => state.getIn([constants.FILTER_MEMBERS_TEMP_MODEL, constants.ACTIVITIES, constants.ACTIVITIES_FETCHED]);

