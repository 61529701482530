/**
 *
 * FileUploader
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Dimmer, Icon, Loader } from 'semantic-ui-react';
import Dropzone from 'react-dropzone';

import './fileUploader.styles.less';

class FileUploader extends React.PureComponent {

  static propTypes = {
    allowMultiple: PropTypes.bool,
    automationIdPrefix: PropTypes.string,
    classNames: PropTypes.string,
    displayUploadError: PropTypes.func,
    fileNameClassName: PropTypes.string,
    fileUploadInstructionsKey: PropTypes.string,
    fileUploadLabelKey: PropTypes.string,
    fileUploadName: PropTypes.string,
    iconClassName: PropTypes.string,
    maxFileSize: PropTypes.number,
    onClick: PropTypes.func,
    uploadInProgress: PropTypes.bool,
    uploadFiles: PropTypes.func,
    validTypes: PropTypes.string,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    allowMultiple: true,
    iconClassName: 'como-svg-icon-upload-images'
  };

  /**
   * handler for trying to upload files
   * @param accepted
   * @param rejected
   */
  onDrop = (accepted, rejected) => {
    if (rejected.length) {
      this.props.displayUploadError();
    } else if (accepted.length) {
      this.props.uploadFiles(accepted);
    }
  };

  render() {

    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    return (
      <Dropzone
        accept={this.props.validTypes}
        maxSize={this.props.maxFileSize}
        data-automation-id={`${this.props.automationIdPrefix}.dropzone`}
        className={`ignore file-upload ${this.props.classNames || ''}`}
        activeClassName="file-upload-active"
        rejectClassName="file-upload-reject"
        multiple={this.props.allowMultiple}
        onClick={this.props.onClick}
        onDrop={this.onDrop}
        disabled={this.props.disabled}
      >
        <Dimmer active={this.props.uploadInProgress} inverted>
          <Loader />
        </Dimmer>
        <Icon className={this.props.iconClassName} />
        {this.props.fileUploadLabelKey &&
          <p className="file-upload-label">{formatMessage({ id: this.props.fileUploadLabelKey })}</p>
        }
        {this.props.fileUploadInstructionsKey ?
          <p className="file-upload-instructions">
            {formatMessage({ id: this.props.fileUploadInstructionsKey })}
          </p>
          : this.props.fileUploadName &&
          <p className={`file-upload-instructions ${this.props.fileNameClassName || ''}`}>
            {this.props.fileUploadName}
          </p>
        }
      </Dropzone>
    );
  }
}

export default injectIntl(FileUploader);
