import { delay } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import * as constants from './gifts.constants';
import * as api from './gifts.api';
import * as errorConstant from '../ErrorMessage/errorMessage.constants';
import * as routeConstants from '../../constants/route.contants';
import * as activityActions from '../Activity/activity.actions';
import * as activityConstants from '../Activity/activity.constants';
import { getLocationId } from '../App/selectors';
import * as appActions from '../App/app.actions';
import * as queryStringUtils from '../../utils/queryStringUtils';

function* getGifts() {
  try {
    // debounce by 50ms
    yield delay(50);
    const data = yield call(api.getGifts);
    yield put({ type: constants.GET_GIFTS_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getGifts.Fail'] });
  }
}

function* getGift(action) {
  try {
    // debounce by 50ms
    yield delay(50);
    const data = yield call(api.getGift, action[activityConstants.HUB_ID]);
    yield put({ type: constants.GET_GIFTS_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getGift.Fail'] });
  }
}

function* goToGift(action) {
  yield put({ type: activityConstants.RESET_ACTIVITY_DATA });
  yield put(push({
    pathname: `/gifts/${action[activityConstants.HUB_ID]}`,
    search: location.search,
  }));
}

function* createGift() {
  const locationId = yield select(getLocationId);
  yield put(activityActions.createActivity(locationId, null, null, null, activityConstants.ACTIVITY_TYPE_GIFT));
  yield put(push({
    pathname: routeConstants.TEMPLATE_LIBRARY_ROUTE,
    search: location.search,
    type: 'gift'
  }));
}

function* updateGift({ gift }) {
  try {
    const data = yield call(api.saveGift, gift.toJS());
    yield put({ type: constants.UPDATE_GIFT_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['updateGift.Fail'] });
  }
}

function* goToGiftsList() {
  const locationSearch = queryStringUtils.removeParameter(location.search, activityConstants.MIGRATION_ID);
  yield put(push({
    pathname: routeConstants.GIFTS_ROUTE,
    search: locationSearch,
  }));
}

function* getGiftStats(action) {
  try {
    yield put({ type: constants.CLEAN_GIFT_STATS, data });
    const data = yield call(api.getGiftStats, action[activityConstants.HUB_ID]);
    yield put({ type: constants.GET_GIFT_STATS_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getGiftStats.Fail'] });
  }
}

function* deleteGift(action) {
  try {
    yield call(api.deleteGift, action[activityConstants.HUB_ID]);
    yield put({ type: constants.DELETE_GIFT_SUCCESS, [activityConstants.HUB_ID]: action[activityConstants.HUB_ID] });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['deleteGift.Fail'] });
  }
}

function* getGiftRelatedActivities(action) {
  try {
    yield put({ type: constants.CLEAN_GIFT_RELATED_ACTIVITIES_DATA, data });
    const data = yield call(api.getGiftRelatedActivities, action[activityConstants.HUB_ID]);
    yield put({ type: constants.GET_GIFT_RELATED_ACTIVITIES_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getGiftRelatedActivities.Fail'] });
  }
}


function* getMigratedActivityData(action) {
  if (action.activityType === activityConstants.ACTIVITY_TYPE_GIFT) {
    try {
      const data = yield call(api.getMigratedGiftData, action);
      yield put({ type: activityConstants.GET_MIGRATED_ACTIVITY_DATA_SUCCESS, data, activityType: action.activityType, activityId: action.activityId });
    } catch (err) {
      if (!err.response || (err.response && err.response.status && err.response.status !== 403)) {
        yield put(appActions.setLoadingState(false));
        yield put({
          type: errorConstant.HANDLE_ERROR,
          [errorConstant.MESSAGES_KEYS]: ['getMigratedGiftData.Fail'],
          [errorConstant.LEAVE_ON_ERROR_CLOSE]: activityConstants.PATH_GIFTS
        });
      }
    }
  }
}


function* handleErrorMessageClose(action) {
  if (action.shouldGoBack === activityConstants.PATH_GIFTS) {
    yield put({ type: constants.GO_TO_GIFTS_LIST });
    yield put({ type: errorConstant.RESET_ERROR_LEAVE_PATH });
  }
}

// sagas listen to dispatched actionsList too (same as reducers)
export default function* GiftsSaga() {
  yield [
    takeLatest(constants.GET_GIFT, getGift),
    takeLatest(constants.GET_GIFTS, getGifts),
    takeLatest(constants.GO_TO_GIFT, goToGift),
    takeLatest(constants.CREATE_GIFT, createGift),
    takeLatest(constants.UPDATE_GIFT, updateGift),
    takeLatest(constants.GO_TO_GIFTS_LIST, goToGiftsList),
    takeLatest(constants.GET_GIFT_STATS, getGiftStats),
    takeLatest(constants.GET_GIFT_RELATED_ACTIVITIES, getGiftRelatedActivities),
    takeLatest(constants.DELETE_GIFT, deleteGift),
    takeLatest(activityConstants.GET_MIGRATED_ACTIVITY_DATA, getMigratedActivityData),
    takeLatest(errorConstant.HANDLE_ERROR_MESSAGE_CLOSE, handleErrorMessageClose)

  ];
}
