import React from 'react';
import {Grid} from 'semantic-ui-react';
import {injectIntl} from 'react-intl';
import PropTypes from "prop-types";
import * as activityConstants from '../../Activity/activity.constants';
import Tooltip from '../../../common/components/Tooltip/Tooltip';

const NAME_LETTERS_MAX = 35;
class PointShopAnalysisRow extends React.PureComponent {
  static propTypes = {
    item: PropTypes.object,
    rowIndex: PropTypes.number,
    barMaxValue: PropTypes.number,
  };

  render() {
    const {item, rowIndex} = this.props;
    const pointShopItemName = item.getIn([activityConstants.ACTIVITY_TYPE_POINT_SHOP_ITEM, activityConstants.NAME]);
    const appAnalysis = item.get(activityConstants.POINT_SHOP_STATS_COUNT_APP_SOURCE) ? item.get(activityConstants.POINT_SHOP_STATS_COUNT_APP_SOURCE) : 0;
    const posAnalysis = item.get(activityConstants.POINT_SHOP_STATS_COUNT_POS_SOURCE) ? item.get(activityConstants.POINT_SHOP_STATS_COUNT_POS_SOURCE) : 0;
    return (

      <Grid.Row className={"listcontent"}>
        <Grid.Column width={6}>
          <div className={'index'}>{rowIndex + 1} </div>
          { pointShopItemName.length > NAME_LETTERS_MAX ?
            <Tooltip
              content={<div className={"point-shop-name"}> {pointShopItemName}</div>}
              trigger={
                <div className={"point-shop-name"}> {('.   ' + pointShopItemName).substr(0, 30) + '...'}</div>
              }
              position="bottom center"
              wide="very"
            /> : <div className={"point-shop-name"}> {'.   ' + pointShopItemName}</div>
          }
        </Grid.Column>
        <Grid.Column width={4}>
          <div className="members-bar">
            <div style={{width: (((appAnalysis + posAnalysis) / this.props.barMaxValue) * 100) + '%'}}></div>
          </div>
        </Grid.Column>
        <Grid.Column textAlign={"center"} width={2}>{appAnalysis.toLocaleString()}</Grid.Column>
        <Grid.Column textAlign={"center"} width={2}>{posAnalysis.toLocaleString()}</Grid.Column>
        <Grid.Column textAlign={"center"} width={2}>{(posAnalysis + appAnalysis).toLocaleString()}</Grid.Column>
      </Grid.Row>
    );
  }
}

export default injectIntl(PointShopAnalysisRow);
