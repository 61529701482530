import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Dimmer, Grid, Icon, Loader } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import * as moment from 'moment';
import Tooltip from '../../../common/components/Tooltip/Tooltip';
import Modal from '../../../common/components/CustomModal/CustomModal';
import * as activityConstants from '../../Activity/activity.constants';
import injectSaga from '../../../utils/injectSaga';
import * as activityActions from '../../Activity/activity.actions';
import activitySaga from '../../Activity/activity.saga';
import * as selectors from '../../Activity/activity.selectors';
import * as reducerConstants from '../../../constants/reducer.constants';
import * as constants from '../businessCenter.constants';
import * as appSelectors from '../../App/selectors';

const NAME_LETTERS_MAX = 25;

class OneTimeStats extends React.PureComponent {
  static propTypes = {
    activity: PropTypes.object,
    open: PropTypes.bool,
    onConfirm: PropTypes.func,
    abStats: PropTypes.object,
    memberStats: PropTypes.object,
    actions: PropTypes.object,
    currencySymbol: PropTypes.string,
    businessTimeZone: PropTypes.string,
    localeDateFormat: PropTypes.string,
    localeTimeFormat: PropTypes.string,
    statsLoaded: PropTypes.bool,
    activityName: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      isTooltipOpen: false,
      excludedCategories: [constants.AB_TEST_CATEGORY_NUM_APPLIED]
    };
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
    this.getCombinedCategories = this.getCombinedCategories.bind(this);
  }

  getTransformedActivityType(type) {
    if (type === activityConstants.ACTIVITY_TYPE_ONE_TIME) {
      return activityConstants.SERVER_TYPE_ONE_TIME_ACTION;
    }
    return type;
  }

  componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open) {
      if (this.props.activity.get(activityConstants.AB_TEST_MODE)) {
        this.props.actions.getAbTestStats(this.props.activity.get(activityConstants.SERVER_ID));
      }
      this.props.actions.getActivityStats(this.props.activity.get(activityConstants.HUB_ID), this.getTransformedActivityType(this.props.activity.get(activityConstants.TYPE)));
    }
  }

  get casesMetadata() {
    const { formatMessage } = this.props.intl; // eslint-disable-line
    const case1Probability = this.props.activity.getIn([activityConstants.CASES, 0, activityConstants.CONDITIONS, activityConstants.CONDITIONS_LIST, 0, activityConstants.CONDITION_VALUE]);
    const case2Probability = 100 - case1Probability;
    const case1Population = Math.floor(this.props.activity.getIn([activityConstants.ONE_TIME_ACTION_FILTERED_POPULATION, activityConstants.FILTERED_NUM_OF_MEMBERS]) * (case1Probability / 100));
    const case2Population = this.props.activity.getIn([activityConstants.ONE_TIME_ACTION_FILTERED_POPULATION, activityConstants.FILTERED_NUM_OF_MEMBERS]) - case1Population;
    const case1Description = this.props.activity.getIn([activityConstants.CASES, 0, activityConstants.DESCRIPTION]) ? this.props.activity.getIn([activityConstants.CASES, 0, activityConstants.DESCRIPTION]) : formatMessage({ id: 'abTest.case1.default.description' });
    const case2Description = this.props.activity.getIn([activityConstants.CASES, 1, activityConstants.DESCRIPTION]) ? this.props.activity.getIn([activityConstants.CASES, 1, activityConstants.DESCRIPTION]) : formatMessage({ id: 'abTest.case2.default.description' });
    return {
      case1: {
        probability: case1Probability,
        population: case1Population,
        description: case1Description
      },
      case2: {
        probability: case2Probability,
        population: case2Population,
        description: case2Description
      }
    };
  }

  circleCase1 = (node) => {
    if (node && this.props.activity && this.props.activity.get(activityConstants.AB_TEST_MODE)) {
      const percentage = this.props.activity.getIn([activityConstants.CASES, 0, activityConstants.CONDITIONS, activityConstants.CONDITIONS_LIST, 0, activityConstants.CONDITION_VALUE]);
      setTimeout(() => {
        node.setAttribute('data-progress', percentage);
      });
    }
  };

  circleCase2 = (node) => {
    if (node && this.props.activity && this.props.activity.get(activityConstants.AB_TEST_MODE)) {
      const percentage = 100 - this.props.activity.getIn([activityConstants.CASES, 0, activityConstants.CONDITIONS, activityConstants.CONDITIONS_LIST, 0, activityConstants.CONDITION_VALUE]);
      setTimeout(() => {
        node.setAttribute('data-progress', percentage);
      });
    }
  };

  roundToDigits(number, numOfDigits) {
    const multiplier = 10 ** numOfDigits;
    const rounded = Math.round(number * multiplier) / multiplier;
    return rounded.toFixed(numOfDigits);
  }

  roundToInt(number) {
    return Math.round(number);
  }

// Math.round( number * 10 ) / 10;
  formatByValue(value) {
    const val = parseFloat(value);
    if ((val > 0 && val < 10) || (val < 0 && val > -10)) {

      return this.roundToDigits(val, 1);
    } else if ((val >= 10 && val < 1000) || (val <= -10 && val > -1000)) {
      return this.roundToInt(val);
    } else if ((val >= 1000 && val < 10000) || (val <= -1000 && val > -10000)) {
      return `${this.roundToDigits(val / 1000, 1)}K`;
    } else if ((val >= 10000 && val < 1000000) || (val <= -10000 && val > -1000000)) {
      return `${this.roundToInt(val / 1000)}K`;
    } else if ((val >= 1000000 && val < 10000000) || (val <= -1000000 && val > -10000000)) {
      return `${this.roundToDigits(val / 1000000, 1)}M`;
    } else if (val > 10000000 || val < -10000000) {
      return `${this.roundToInt(val / 1000)}M`;
    }
    return value;
  }

  getFormattedValue = (value, type) => {
    switch (type) {
      case constants.AB_TEST_TYPE_FLOAT:
        return this.formatByValue(value);
      case constants.AB_TEST_TYPE_INTEGER:
        return this.roundToInt(parseFloat(value));
      default:
        return value;
    }
  };

  onOpen() {
    setTimeout(() => this.setState({ isTooltipOpen: true }), 2000);
  }

  onClose() {
    this.setState({ isTooltipOpen: false });
  }

  getFormattedValueForTitle = (value, type) => {
    switch (type) {
      case constants.AB_TEST_TYPE_FLOAT:
        return this.roundToDigits(parseFloat(value), 2);
      case constants.AB_TEST_TYPE_INTEGER:
        return this.roundToInt(parseFloat(value));
      default:
        return value;
    }
  };

  getUnitsDetailsByFormat = (format) => {
    switch (format) {
      case constants.AB_TEST_FORMAT_CURRENCY:
      case constants.AB_TEST_FORMAT_CURRENCY_PER_SPEND:
        return ` (${this.props.currencySymbol})`;
      case constants.AB_TEST_FORMAT_PERCENT:
        return ' (%)';
      default:
        return '';
    }
  };

  getCombinedCategories(categories) {
    const { formatMessage } = this.props.intl; // eslint-disable-line
    const categoriesArr = categories.split(',');
    const translatedCategoriesArr = [];
    categoriesArr.forEach((category) => {
      translatedCategoriesArr.push(formatMessage({ id: `abTest.category.${category}` }));
    });
    return translatedCategoriesArr.join('/');
  }

  shouldDimmerBeShown(){
    return (!this.props.abStats && !this.props.statsLoaded && this.props.activity.get(activityConstants.AB_TEST_MODE)) ||
        (!this.props.activity.get(activityConstants.AB_TEST_MODE) && !this.props.memberStats)
  }

  getModalPopupCss(){
    return this.props.activity.get(activityConstants.AB_TEST_MODE) ? "modal__activity__log-details ab-test" : "modal__activity__log-details";
  }

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line
    const { open, onConfirm, abStats, activityName, memberStats } = this.props;
    const casesMetadata = this.casesMetadata;
    let date = abStats ? moment.utc(abStats.getIn([0, constants.AB_TEST_DATA, 0, constants.AB_TEST_TIME_UPDATED])).tz(this.props.businessTimeZone) : null;
    let dateUpdated = date ? `${date.format(this.props.localeDateFormat)} ${date.format(this.props.localeTimeFormat)} ` : '';
    if (!abStats && this.props.statsLoaded ) {
      date = moment().utc().tz(this.props.businessTimeZone);
      dateUpdated = date ? `${date.format(this.props.localeDateFormat)} ${date.format(this.props.localeTimeFormat)}` : '';
    }
    const hint = formatMessage({ id: 'OneTime-stats.hint' });
    return (
      <Modal
        open={open}
        className={this.getModalPopupCss()}
        size={800}
        onClose={onConfirm}
        automationIdPrefix={`${this.props.activity.get(activityConstants.TYPE)}.analysis`}
        header={
          <div className="type-and-name">
            { activityName.length > NAME_LETTERS_MAX ?
              <Tooltip
                content={`${activityName} analysis`}
                trigger={
                  <p>{`${activityName} analysis`}</p>
                }
                position="bottom right"
              /> : <p>{`${activityName} analysis`}</p>
            }
          </div>
        }
        content={
          <div className="stats-modal-content">
            <Dimmer inverted active={ this.shouldDimmerBeShown()}>
              <Loader active />
            </Dimmer>
            <Tooltip
              open={this.state.isTooltipOpen}
              onOpen={this.onOpen}
              onClose={this.onClose}
              className="tooltip.ui.popup"
              content={formatMessage({ id: 'onetime-stats.tooltip' }, { 'total': '0', 'distinct': memberStats ? memberStats.get('distinctMembers') : '?' })}
              trigger={
                <ul>
                  <li>
                    <span
                      className="log--title stats "
                      data-automation-id="rule-stats.distinct.key"
                    >{formatMessage({ id: 'rule-stats.distinct' })}</span>
                    <span
                      className="log--content"
                      data-automation-id="rule-stats.distinct.value"
                    >{ memberStats ? memberStats.get('distinctMembers') : '?' }</span>
                  </li>
                </ul>
              }
              position="bottom left"
            />
            {this.props.activity.get(activityConstants.AB_TEST_MODE)
             ?
              <span>
                <Grid>
                  <Grid.Row className="header-row">
                    <Grid.Column width={5}>
                    </Grid.Column>
                    <Grid.Column width={4} className="abtest-case-1">
                      <div className="radial-progress" data-progress="0" ref={this.circleCase1}><div className="circle"><div className="mask full"><div className="fill"></div></div><div className="mask half"><div className="fill"></div><div className="fill fix"></div></div></div>
                        <div className="inset">A</div>
                      </div>
                      <div className="probability">{casesMetadata.case1.probability}%</div>
                      <div className="explained">
                        <Icon className="como-svg-tilda" />
                        <span>{`${casesMetadata.case1.population} ${formatMessage({ id: 'abTest.population.members' })}`}</span>
                      </div>
                      <div className="explained explained-description">{casesMetadata.case1.description}</div>
                    </Grid.Column>
                    <Grid.Column width={4} className="abtest-case-2">
                      <div className="radial-progress" data-progress="0" ref={this.circleCase2}><div className="circle"><div className="mask full"><div className="fill"></div></div><div className="mask half"><div className="fill"></div><div className="fill fix"></div></div></div>
                        <div className="inset">B</div>
                      </div>
                      <div className="probability">{casesMetadata.case2.probability}%</div>
                      <div className="explained">
                        <Icon className="como-svg-tilda" />
                        <span>{`${casesMetadata.case2.population} ${formatMessage({ id: 'abTest.population.members' })}`}</span>
                      </div>
                      <div className="explained explained-description">{casesMetadata.case2.description}</div>
                    </Grid.Column>
                    <Grid.Column width={3}>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Grid className={`table ${!abStats && this.props.statsLoaded ? 'empty' : ''}`}>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      {abStats ? abStats.map((statsRow, statsIndex) => {
                        const categories = this.getCombinedCategories(statsRow.get('categories'));
                        const isSingleRow = statsRow.get('isGeneral');
                        if (this.state.excludedCategories.includes(statsRow.get('categories'))) {
                          return null;
                        }
                        return (
                          <Grid key={`${statsRow.get(constants.AB_TEST_CATEGORY)}_${statsIndex.toString()}`}>
                            { !isSingleRow &&
                              <Grid.Row className="main-row">
                                <Grid.Column width={16}> {categories}</Grid.Column>
                              </Grid.Row>
                            }
                            {
                              statsRow.get(constants.AB_TEST_DATA).map((dataRow, dataIndex) => (
                                <Grid.Row className={isSingleRow ? 'main-row' : 'child-row'} key={`${dataRow.get(constants.AB_TEST_KEY)}_${dataIndex.toString()}`}>
                                  <Grid.Column width={5} className={!isSingleRow ? 'child-key' : ''}>
                                    {isSingleRow ? `${categories} ${this.getUnitsDetailsByFormat(dataRow.get(constants.AB_TEST_FORMAT))}` : `${formatMessage({ id: `abTest.key.${dataRow.get(constants.AB_TEST_KEY)}` })} ${this.getUnitsDetailsByFormat(dataRow.get(constants.AB_TEST_FORMAT))}`}
                                  </Grid.Column>
                                  <Grid.Column width={4} className="values-column">
                                    {dataRow.get(constants.AB_TEST_CASE1_VALUE) &&
                                    <span
                                      className={dataRow.get('betterCase') === constants.CASE_A ? 'better' : ''}
                                      title={this.getFormattedValueForTitle(dataRow.get(constants.AB_TEST_CASE1_VALUE), dataRow.get(constants.AB_TEST_TYPE))}
                                    >
                                      {this.getFormattedValue(dataRow.get(constants.AB_TEST_CASE1_VALUE), dataRow.get(constants.AB_TEST_TYPE))}
                                    </span>}
                                  </Grid.Column>
                                  <Grid.Column width={4} className="values-column">
                                    {dataRow.get(constants.AB_TEST_CASE2_VALUE) &&
                                    <span
                                      className={dataRow.get('betterCase') === constants.CASE_B ? 'better' : ''}
                                      title={this.getFormattedValueForTitle(dataRow.get(constants.AB_TEST_CASE2_VALUE), dataRow.get(constants.AB_TEST_TYPE))}
                                    >
                                      {this.getFormattedValue(dataRow.get(constants.AB_TEST_CASE2_VALUE), dataRow.get(constants.AB_TEST_TYPE))}
                                    </span>}
                                  </Grid.Column>
                                  <Grid.Column width={3} className="values-column align-left">
                                    {dataRow.get(constants.AB_TEST_DIFF) &&
                                    <span
                                      title={`${this.getFormattedValueForTitle(dataRow.get(constants.AB_TEST_DIFF), constants.AB_TEST_TYPE_FLOAT)} (${this.getFormattedValueForTitle(dataRow.get('percentageDiff'), constants.AB_TEST_TYPE_FLOAT)}%)`}
                                    >
                                      {`${this.getFormattedValue(dataRow.get(constants.AB_TEST_DIFF), constants.AB_TEST_TYPE_FLOAT)} (${this.getFormattedValue(dataRow.get('percentageDiff'), constants.AB_TEST_TYPE_FLOAT)}%)`}
                                      </span>
                                    }
                                  </Grid.Column>
                                </Grid.Row>
                                ))
                          }
                          </Grid>
                        );
                      }) : null
                      }
                      {
                        !abStats && this.props.statsLoaded &&
                        <div className="empty-abtest-stats">{formatMessage({ id: 'abTest.no.stats.yet' })}</div>
                      }
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <span className="hint-tip-label">{formatMessage({ id: 'abTest.last.updated' }, { 'date': dateUpdated })} </span>
              </span>
              :
              null
            }
            <span className="hint-tip-label stick-to-bottom">
              {hint}
            </span>

          </div>
        }
        actions={[
          <a data-automation-id="rule-stats.got.it.button" role="button" key="gotIt" onClick={onConfirm}>{formatMessage({ id: 'general.button.got-it' })}</a>
        ]}
        closeOnDimmerClick
      />
    );
  }
}

const mapStateToProps = (state) => ({
  abStats: selectors.getActivityABTestStats(state),
  memberStats: selectors.getActivityStats(state),
  currencySymbol: appSelectors.getLocationCurrencySymbol(state),
  businessTimeZone: appSelectors.getBusinessTimeZone(state),
  localeDateFormat: appSelectors.getBrowserLocaleDateFormat(state),
  localeTimeFormat: appSelectors.getBrowserLocaleTimeFormat(state),
  statsLoaded: selectors.getActivityStatsLoadedStatus(state)
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(activityActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.ACTIVITY_BRANCH, saga: activitySaga });

export default compose(
  withConnect,
  withSaga,
  injectIntl
)(OneTimeStats);

