/*
 *
 * Members actions
 *
 */

import * as constants from './members.constants';

export const goToCustomer = (param) => ({ type: constants.GO_TO_CUSTOMER, param });

export const findMembers = (searchQuery) => ({ type: constants.FIND_MEMBERS, searchQuery });

export const handleMemberPopupVisibility = (show = true) => ({ type: constants.HANDLE_MEMBER_POPUP_VISIBILITY, show });

export const clearSearchQuery = () => ({ type: constants.CLEAR_SEARCH_QUERY });

export function getMembersFilteredByCommunicationActions(filterConditions, locationId) {
  return {
    type: constants.GET_MEMBERS_FILTERED_BY_COMMUNICATION_ACTIONS,
    filterConditions,
    locationId
  };
}
