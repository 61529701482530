/*
 *
 * Benefits constants
 *
 */

// ACTIONS CONSTANTS

export const GET_BENEFITS = 'app/Benefits/GET_BENEFITS';
export const GET_BENEFITS_SUCCESS = 'app/Benefits/GET_BENEFITS_SUCCESS';
export const GET_BENEFITS_ERROR = 'app/Benefits/GET_BENEFITS_ERROR';

// STORE CONSTANTS
export const BENEFITS = 'benefits';
export const BENEFITS_LIST = 'list';
export const BENEFITS_LIST_LOADED = 'benefitsListLoaded';
export const GIFT = 'gift';
export const GIFTS = 'gifts';
export const PUNCH_CARD = 'punchCard';
export const PUNCH_CARD_OLD = 'punch_card';
export const PUNCH_CARDS = 'punchCards';

export const FILTER_SEARCH = 'search';
export const FILTER_ALL = 'all';
export const FILTER_TRUE = 'true';
export const FILTER_FALSE = 'false';
export const FILTER_STATUS_ALL = 'allStatuses';
export const FILTER_STATUS_ACTIVE = 'active';
export const FILTER_STATUS_INACTIVE = 'inactive';
export const FILTER_TYPE_ALL = 'allTypes';

export const BENEFIT_ACTIVE = 'active';
export const BENEFIT_SERVER_ENTITY_KEY = 'serverEntityKey';
export const BENEFIT_DESCRIPTION = 'description';
export const BENEFIT_IMAGE_URL = 'imageUrl';
export const BENEFIT_SERVER_ID = 'serverId';
export const BENEFIT_TITLE = 'title';
export const BENEFIT_TYPE = 'type';
export const BENEFIT_VALID_FROM = 'validFrom';
export const BENEFIT_VALID_UNTIL = 'validUntil';
export const BENEFIT_EXPIRED = 'expired';
export const BENEFIT_INACTIVE = 'inactive';

// COMMON CONSTANTS
export const APP = 'app';
export const BUSINESS = 'business';
export const LOCATION_ID = 'locationId';

