import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Grid, Icon, Radio } from 'semantic-ui-react';

import CustomCheckbox from '../../../../../../common/components/FormInputFields/CheckboxWrapper/CheckboxWrapper';
import IntegerInputField from '../../../../../../common/components/FormInputFields/IntegerInputField/IntegerInputField';
import DropDown, { Divider } from '../../../../../../common/components/DropdownWrapper/DropdownWrapper';
import Tooltip from '../../../../../../common/components/Tooltip/Tooltip';
import * as constants from '../../../../activity.constants';

import './limitPerMemberGlobalCondition.styles.less';

import { AUTOMATION_PREFIX_NAME } from './limitPerMemberGlobalConditionWrapper';
import ValidationErrorMessage from '../../../../../../common/components/ValidationErrorMessage/ValidationErrorMessage';
import * as commonComponentsConstants from '../../../../../../common/components/common.components.constants';
import * as dateConstants from '../../../../../../common/utils/dateTime.constants';

class LimitPerMemberGlobalCondition extends React.PureComponent {

  static propTypes = {
    intl: PropTypes.object,
    data: PropTypes.object.isRequired,
    validationErrors: PropTypes.object,
    updateConditionType: PropTypes.func.isRequired,
    updateTotalLimitEnable: PropTypes.func.isRequired,
    updateByPeriodLimitEnable: PropTypes.func.isRequired,
    updatePeriodUnit: PropTypes.func.isRequired,
    updatePeriodMultiplier: PropTypes.func.isRequired,
    updateByPeriodPeriodCount: PropTypes.func.isRequired,
    updateTotalOccurrencesCount: PropTypes.func.isRequired,
    currentConditionType: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.handleConditionTypeChange = this.handleConditionTypeChange.bind(this);
    this.handleByPeriodPeriodCountChange = this.handleByPeriodPeriodCountChange.bind(this);
    this.handleByPeriodLimitEnabledChange = this.handleByPeriodLimitEnabledChange.bind(this);
    this.handleTotalOccurrencesCountChange = this.handleTotalOccurrencesCountChange.bind(this);
    this.handlePeriodUnitChange = this.handlePeriodUnitChange.bind(this);
    this.handleTotalLimitEnabledChange = this.handleTotalLimitEnabledChange.bind(this);
    this.handlePeriodMultiplierChange = this.handlePeriodMultiplierChange.bind(this);
  }

  // CONDITION TYPE //

  getFieldErrors() {
    return this.props.validationErrors ? {
      totalOccurrencesCountError: this.props.validationErrors.getIn([constants.TOTAL_OCCURRENCES_CONDITION, constants.CONDITION_VALUE]),
      byPeriodPeriodCountError: this.props.validationErrors.getIn([constants.BY_PERIOD_CONDITION, constants.CONDITION_VALUE, constants.BY_PERIOD_CONDITION_COUNT]),
      byPeriodPeriodMultiplierError: this.props.validationErrors.getIn([constants.BY_PERIOD_CONDITION, constants.CONDITION_VALUE, constants.BY_PERIOD_PERIOD_MULTIPLIER]),
      selectOneLimitError: this.props.validationErrors.getIn([constants.LIMITED_PER_MEMBER_NONE_SELECTED_ERROR]),
    } : {};
  }

  handleConditionTypeChange(e, { value }) {
    this.props.updateConditionType(value);
  }

  get conditionType() {
    return this.props.data.get(constants.LIMIT_OCCURRENCES_PER_MEMBER_CONDITION_TYPE);
  }

  // TOTAL OCCURRENCES LIMIT //

  get totalOccurrencesConditionEnabled() {
    return this.props.data.get(constants.TOTAL_OCCURRENCES_CONDITION_ENABLED);
  }

  get totalOccurrencesCount() {
    return this.props.data.getIn([constants.TOTAL_OCCURRENCES_CONDITION, constants.CONDITION_VALUE]);
  }

  handleTotalLimitEnabledChange(e, { checked }) {
    this.props.updateTotalLimitEnable(checked);
  }

  handleTotalOccurrencesCountChange(e, { value }) {
    this.props.updateTotalOccurrencesCount(value);
  }

  // BY PERIOD LIMIT //

  get byPeriodConditionEnabled() {
    return this.props.data.get(constants.BY_PERIOD_CONDITION_ENABLED);
  }

  get isRollingPeriod() {
    return this.props.data.getIn([
      constants.BY_PERIOD_CONDITION, constants.CONDITION_VALUE, constants.IS_ROLLING_PERIOD
    ]);
  }

  get byPeriodPeriodCount() {
    return this.props.data.getIn([
      constants.BY_PERIOD_CONDITION, constants.CONDITION_VALUE, constants.BY_PERIOD_CONDITION_COUNT
    ]);
  }

  get byPeriodPeriodMultiplier() {
    return this.props.data.getIn([
      constants.BY_PERIOD_CONDITION, constants.CONDITION_VALUE, constants.BY_PERIOD_PERIOD_MULTIPLIER
    ]);
  }

  get periodUnitCalculated() {
    if (this.isRollingPeriod) {
      return constants.ROLLING_PERIOD;
    }
    return this.periodUnit;
  }

  get periodUnit() {
    return this.props.data.getIn([
      constants.BY_PERIOD_CONDITION, constants.CONDITION_VALUE, constants.BY_PERIOD_CONDITION_PERIOD
    ]);
  }

  handleByPeriodLimitEnabledChange(e, { checked }) {
    this.props.updateByPeriodLimitEnable(checked);
  }

  handleByPeriodPeriodCountChange(e, { value }) {
    this.props.updateByPeriodPeriodCount(value);
  }

  handlePeriodUnitChange(e, { value }) {
    this.props.updatePeriodUnit(value);
  }

  handlePeriodMultiplierChange(e, { value }) {
    this.props.updatePeriodMultiplier(value);
  }

  get rollingPeriodTooltip() {
    const { formatMessage } = this.props.intl;
    const dayItalicWord = (
      <span className="rolling-period-tooltip-day">
        {formatMessage({ id: 'activity.conditions.occurrences.limit.unit.day' })}
      </span>
    );
    return (<Tooltip
      className="rolling-period-tooltip"
      header={formatMessage({ id: 'activity.conditions.occurrences.limit.unit.rollingPeriod.tooltip.header' })}
      content={(<FormattedMessage
        id="activity.conditions.occurrences.limit.unit.rollingPeriod.tooltip.body"
        values={{ 'day': dayItalicWord }}
      />)}
      trigger={<Icon className="como-ic-help float-to-right" />}
      position="top left"
    />);
  }

  get periodDropDown() {
    const { formatMessage } = this.props.intl;
    return (<DropDown
      options={[
        {
          value: dateConstants.HOUR,
          text: formatMessage({ id: 'activity.conditions.occurrences.limit.unit.hour' })
        },
        {
          value: dateConstants.DAY,
          text: formatMessage({ id: 'activity.conditions.occurrences.limit.unit.day' })
        },
        {
          value: dateConstants.WEEK,
          text: formatMessage({ id: 'activity.conditions.occurrences.limit.unit.week' })
        },
        {
          value: dateConstants.MONTH,
          text: formatMessage({ id: 'activity.conditions.occurrences.limit.unit.month' })
        },
        {
          value: dateConstants.YEAR,
          text: formatMessage({ id: 'activity.conditions.occurrences.limit.unit.year' })
        },
        <Divider />,
        {
          value: constants.ROLLING_PERIOD,
          text: formatMessage({ id: 'activity.conditions.occurrences.limit.unit.rollingPeriod' }),
          content: (
            <span>
              {formatMessage({ id: 'activity.conditions.occurrences.limit.unit.rollingPeriod' })}
              {this.rollingPeriodTooltip}
            </span>
          )
        }
      ]}
      prefix={`${AUTOMATION_PREFIX_NAME}.limitedPerMember.byPeriod.timesPer`}
      name="value"
      onSelectOption={this.handlePeriodUnitChange}
      selection
      value={this.periodUnitCalculated}
      disabled={!this.byPeriodConditionEnabled}
      className="period-drop-down"
    />);
  }

  get rollingPeriodDropDown() {
    const { formatMessage } = this.props.intl;
    return (<DropDown
      options={[
        {
          value: dateConstants.HOUR,
          text: formatMessage({ id: 'activity.conditions.occurrences.limit.unit.hour.plural' })
        }
      ]}
      prefix={`${AUTOMATION_PREFIX_NAME}.limitedPerMember.byPeriod.rollingPeriodPer`}
      name="value"
      onSelectOption={() => { }}
      selection
      value={this.periodUnit}
      disabled={!this.byPeriodConditionEnabled}
    />);
  }

  render() {
    const { formatMessage } = this.props.intl;
    const errors = this.getFieldErrors();
    if (!this.props.data) {
      return null;
    }
    return (
      <div className="occurrences-limit-condition">
        <Grid>
          <Grid.Row>
            <p> {formatMessage({ id: 'activity.conditions.this.activity.applies' })} </p>
          </Grid.Row>
          <Grid.Row>
            <Radio
              label={formatMessage({ id: 'activity.conditions.infinite.number.of.purchases.per.member' })}
              value={constants.INFINITE}
              checked={this.conditionType === constants.INFINITE}
              onChange={this.handleConditionTypeChange}
              data-automation-id={`${AUTOMATION_PREFIX_NAME}.infinite`}
            />
          </Grid.Row>
          <Grid.Row>
            <Radio
              label={formatMessage({
                id: 'activity.conditions.limited.number.of.purchases.per.member'
              })}
              disabled={this.props.currentConditionType === constants.NON_MEMBERS || this.props.currentConditionType === constants.ALL_COSTUMERS}
              value={constants.LIMITED_PER_MEMBER}
              checked={this.conditionType === constants.LIMITED_PER_MEMBER}
              onChange={this.handleConditionTypeChange}
              data-automation-id={`${AUTOMATION_PREFIX_NAME}.limitedPerMember`}
            />
          </Grid.Row>
        </Grid>
        <Grid className="gray-panel limited-times-per-member-section">
          {this.conditionType === constants.LIMITED_PER_MEMBER ?
            <Grid.Row>
              <Grid.Column width={16}>
                <CustomCheckbox
                  label={formatMessage({
                    id: 'activity.conditions.occurrences.limit.up.to'
                  })}
                  onChange={this.handleTotalLimitEnabledChange}
                  checked={this.totalOccurrencesConditionEnabled}
                  data-automation-id={`${AUTOMATION_PREFIX_NAME}.limitedPerMember.totalLimit.enable`}
                />
                <IntegerInputField
                  value={this.totalOccurrencesCount}
                  onChange={this.handleTotalOccurrencesCountChange}
                  disabled={!this.totalOccurrencesConditionEnabled}
                  prefix={`${AUTOMATION_PREFIX_NAME}.limitedPerMember.totalLimit.count`}
                  error={errors.totalOccurrencesCountError}
                >
                </IntegerInputField>
                <span className={this.totalOccurrencesConditionEnabled ? 'checked' : 'unchecked'}>
                  {formatMessage({ id: 'activity.conditions.occurrences.limit.times.in.total' })}
                </span>
              </Grid.Column>
            </Grid.Row>
            : null}
          {this.conditionType === constants.LIMITED_PER_MEMBER ?
            <Grid.Row>
              <Grid.Column width={16}>
                <CustomCheckbox
                  label={formatMessage({ id: 'activity.conditions.occurrences.limit.up.to' })}
                  onChange={this.handleByPeriodLimitEnabledChange}
                  checked={this.byPeriodConditionEnabled}
                  data-automation-id={`${AUTOMATION_PREFIX_NAME}.limitedPerMember.byPeriod.enable`}
                />
                <IntegerInputField
                  value={this.byPeriodPeriodCount}
                  onChange={this.handleByPeriodPeriodCountChange}
                  disabled={!this.byPeriodConditionEnabled}
                  prefix={`${AUTOMATION_PREFIX_NAME}.limitedPerMember.byPeriod.count`}
                  error={errors.byPeriodPeriodCountError}
                >
                </IntegerInputField>
                <span className={`${this.byPeriodConditionEnabled ? 'checked' : 'unchecked'} head-of-sentence align-baseline`}>
                  {formatMessage({ id: 'activity.conditions.occurrences.limit.times.per' })}
                </span>
                <span className="period-props-wrapper">
                  {this.periodDropDown}
                  {this.isRollingPeriod ?
                    <div className="rolling-period-props">
                      <IntegerInputField
                        className="rolling-period-multiplier"
                        value={this.byPeriodPeriodMultiplier}
                        onChange={this.handlePeriodMultiplierChange}
                        disabled={!this.byPeriodConditionEnabled}
                        prefix={`${AUTOMATION_PREFIX_NAME}.limitedPerMember.byPeriod.multiplier`}
                        error={errors.byPeriodPeriodMultiplierError}
                      >
                      </IntegerInputField>
                      <span className="rolling-period-drop-down">
                        {this.rollingPeriodDropDown}
                      </span>
                    </div>
              : null}
                </span>
              </Grid.Column>
            </Grid.Row> : null }
        </Grid>
        {errors.selectOneLimitError ?
          <ValidationErrorMessage
            className="select-one-error"
            errorLayoutType={commonComponentsConstants.COMPONENT_TYPE_PARAGRAPH}
            errorMessage={formatMessage({ id: errors.selectOneLimitError })}
            dataAutomationId={`${AUTOMATION_PREFIX_NAME}.error.message`}
          />
          : null}
      </div>
    );
  }
}

export default injectIntl(LimitPerMemberGlobalCondition);
