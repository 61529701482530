/**
 * radio button for itemCodes/departmentCodes list
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'semantic-ui-react';
import DisabledItemPosRestriction from '../../../../common/components/DisabledItem/DisabledItemPosRestriction';

class ItemPopRadio extends React.PureComponent {

  static propTypes = {
    checked: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    name: PropTypes.string,
    note: PropTypes.string,
    value: PropTypes.string,
    overrideToolTipMessage: PropTypes.string,
    disabled: PropTypes.bool
  };

  render() {
    const { label, disabled, checked, name, value, note, overrideToolTipMessage } = this.props;
    return (
      <div>
        {disabled ?
          (<DisabledItemPosRestriction overrideToolTipMessage={overrideToolTipMessage}>
            <Radio
              label={label}
              disabled
            />
          </DisabledItemPosRestriction>)
          :
          (<Radio
            checked={checked}
            label={label}
            name={name}
            value={value}
          />)
        }
        {
          note &&
          <span className="note">
            {note}
          </span>
        }

      </div>
    );
  }
}

export default ItemPopRadio;
