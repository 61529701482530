import { fromJS, List } from 'immutable';
import moment from 'moment/moment';
import _ from 'lodash';
import * as constants from '../activity.constants';
import * as conditionReducerUtils from './utils/condition.reducer.utils';
import * as defaults from './activity.reducer.defaults';
import * as globalConditionReducerUtils from './utils/globalCondition.reducer.utils';
import * as membershipChangedConstants from '../views/conditions/updateMembershipCondition/updateMembership.constants';
import * as activitySchemaSelectors from '../activitySchema/activitySchema.selectors';
import * as dateTimeReducerUtils from './utils/activity.datetime.reducer.utils';
import * as activityConditionDefaults from './activity.condition.defaults';
import * as schemaConstants from '../activitySchema/activitySchema.constants';
import { isImportMembersLimitExceeded } from './utils/validations/global.conditions.validations.utils';


export function activityGlobalConditionsReducer(state, action) {
  const globalMembershipConditionsPath = [constants.DATA, constants.MEMBERSHIP_GLOBAL_CONDITIONS];
  const filterPopulationConditionsPath = [constants.DATA, constants.ONE_TIME_ACTION_FILTERED_POPULATION, constants.FILTERED_POPULATION_CONDITIONS];

  switch (action.type) {

    case constants.CREATE_TEMP_GLOBAL_DATE_TIME_CONDITIONS: {
      if (state.getIn([constants.DATA, constants.DATETIME_GLOBAL_CONDITIONS])) {
        return state
          .set(constants.TEMP_DATETIME_GLOBAL_CONDITIONS, state.getIn([constants.DATA, constants.DATETIME_GLOBAL_CONDITIONS]))
          .setIn([constants.TEMP_DATETIME_GLOBAL_CONDITIONS, constants.TYPE], constants.DATETIME_SPECIFIC);
      }
      return state
        .set(constants.TEMP_DATETIME_GLOBAL_CONDITIONS, conditionReducerUtils.createInitialDateTimeCondition(activitySchemaSelectors.getSchema(state)))
        .setIn([constants.TEMP_DATETIME_GLOBAL_CONDITIONS, constants.TYPE], constants.DATETIME_ANY);
    }

    case constants.RESET_GLOBAL_DATE_TIME_CONDITIONS: {
      return state.delete(constants.TEMP_DATETIME_GLOBAL_CONDITIONS);
    }

    case constants.SAVE_GLOBAL_DATE_TIME_CHANGES: {
      const tempState = state.get(constants.TEMP_DATETIME_GLOBAL_CONDITIONS);
      const newState = state.delete(constants.TEMP_DATETIME_GLOBAL_CONDITIONS);
      const condition = conditionReducerUtils.transformDateTimeGlobalConditionTempModel(tempState);
      if (!condition) {
        return newState.deleteIn([constants.DATA, constants.DATETIME_GLOBAL_CONDITIONS]);
      }
      return newState.setIn([constants.DATA, constants.DATETIME_GLOBAL_CONDITIONS], tempState);
    }

    case constants.UPDATE_GLOBAL_DATETIME_CONDITION_TYPE: {
      return state.setIn([constants.TEMP_DATETIME_GLOBAL_CONDITIONS, constants.TYPE], action.conditionType);
    }

    case constants.CLEAR_TEMP_GLOBAL_DATETIME: {
      return state.delete(constants.TEMP_DATETIME_GLOBAL_CONDITIONS);
    }

    case constants.COPY_GLOBAL_OCCURRENCES_LIMIT_CONDITION_TO_TEMP_MODEL: {
      const currentStatePath = [constants.DATA, constants.LIMIT_OCCURRENCES_PER_MEMBER_GLOBAL_CONDITIONS];
      const currentState = state.getIn(currentStatePath);
      return state.setIn([constants.DATA, constants.LIMIT_OCCURRENCES_PER_MEMBER_GLOBAL_CONDITIONS_TEMP_MODEL],
        currentState || fromJS(defaults.defaultGlobalOccurrencesLimitCondition));
    }

    case constants.APPLY_GLOBAL_OCCURRENCES_LIMIT_CONDITION_FROM_TEMP_MODEL: {
      const tempConditionModel = state.getIn([constants.DATA, constants.LIMIT_OCCURRENCES_PER_MEMBER_GLOBAL_CONDITIONS_TEMP_MODEL]);
      const conditionPath = [constants.DATA, constants.LIMIT_OCCURRENCES_PER_MEMBER_GLOBAL_CONDITIONS];
      const conditionModel = globalConditionReducerUtils.normalizeGlobalOccurrencesLimitConditionTempModel(tempConditionModel);
      const newState = state.setIn(conditionPath, conditionModel);
      return newState.deleteIn([constants.DATA, constants.LIMIT_OCCURRENCES_PER_MEMBER_GLOBAL_CONDITIONS_TEMP_MODEL]);
    }

    case constants.REMOVE_GLOBAL_OCCURRENCES_LIMIT_CONDITION_TEMP_MODEL: {
      return state.deleteIn([constants.DATA, constants.LIMIT_OCCURRENCES_PER_MEMBER_GLOBAL_CONDITIONS_TEMP_MODEL]);
    }

    case constants.OPEN_GLOBAL_MEMBERSHIP_CONDITIONS_MODAL: {
      // verify that not already opened
      if (state.get(constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL)) {
        return state;
      }

      const path = action.isFilteredPopulation ? filterPopulationConditionsPath : globalMembershipConditionsPath;
      const currentStatusCondition = state.getIn([...path, constants.MEMBERSHIP_STATUS_CONDITION]);
      let newState = state
        .set(constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, state.getIn(path) || fromJS(defaults.registeredMembersGlobalMembershipCondition))
        .set(constants.TEMP_MEMBERSHIP_STATUS_CONDITION, currentStatusCondition || fromJS(defaults.defaultMembershipStatusCondition));
      if (!newState.getIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.CONDITIONS])) {
        newState = newState
          .setIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.CONDITIONS], fromJS(defaults.emptyGlobalMembershipConditionsConditions));
      }
      if(newState.getIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.TEMP_VALIDATION_ERRORS])) {
        newState = newState.setIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.TEMP_VALIDATION_ERRORS], '');
      }
      if (!newState.getIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.MEMBERSHIP_STATUS_CONDITION])) {
        newState = newState
          .setIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.MEMBERSHIP_STATUS_CONDITION], fromJS(defaults.defaultMembershipStatusCondition));
      }
      if (action.isFilteredPopulation) {
        if (!newState.getIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS]) ||
          !newState.getIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS, constants.USER_ACTIONS_CONDITIONS_LIST])) {
          newState = newState.setIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS], fromJS(defaults.userActionsEmptyFilters));
        }
        if (!newState.getIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER])) {
          newState = newState.setIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER], fromJS(defaults.defaultMembershipImportConditionsWrapper));
        }
        if (action.isCommunicationActionReFiltered) {
          // Apply reFiltering here if any of the action is either email/SMS/Push
          if (state.getIn([constants.DATA, constants.ACTIONS])) {
            let existingConditionsList = state.getIn([...path, constants.CONDITIONS, constants.CONDITIONS_LIST]) || fromJS([]);
            let conditionIndex = existingConditionsList.size;
            const actionsList = state.getIn([constants.DATA, constants.ACTIONS, 0, constants.ACTIONS_LIST]);
            const reFilteringModel = globalConditionReducerUtils.getOneTimeReFilteringCommunicationModel(actionsList, existingConditionsList);

            if (reFilteringModel.shouldEmailAllowedGlobalConditionBeApplied) {
              existingConditionsList = existingConditionsList.set(conditionIndex++, activityConditionDefaults.allowEmailCondition);
            }

            if (reFilteringModel.shouldSMSAllowedGlobalConditionBeApplied) {
              existingConditionsList = existingConditionsList.set(conditionIndex++, activityConditionDefaults.allowSMSCondition);
            }

            if (reFilteringModel.shouldPushNotificationGlobalConditionBeApplied) {
              existingConditionsList = existingConditionsList.set(conditionIndex++, activityConditionDefaults.allowPushCondition);
            }

            if (reFilteringModel.shouldConsentGlobalConditionBeApplied) {
              existingConditionsList = existingConditionsList.set(conditionIndex++, activityConditionDefaults.allowConsentCondition);
            }

            //Rectify the email/SMS condition
            const emailActionPresent = actionsList.filter((action) => action.get(constants.ACTION_TYPE) === schemaConstants.SEND_MEMBER_EMAIL_ACTION).size > 0;
            const smsActionPresent = actionsList.filter((action) => action.get(constants.ACTION_TYPE) === schemaConstants.SEND_MEMBER_SMS_ACTION).size > 0;
            const pushNotificationPreset = actionsList.filter((action) => action.get(constants.ACTION_TYPE) === schemaConstants.SEND_PERSONAL_PUSH_ACTION).size > 0;

            let index = 0;
            existingConditionsList.forEach((condition) => {
              if ((condition.get(constants.CONDITION_KEY) === schemaConstants.CONTEXT_MEMBERSHIP_ALLOW_EMAIL) &&
                  condition.get(constants.CONDITION_VALUE) === schemaConstants.FALSE && emailActionPresent) {
                    condition = condition.set(constants.CONDITION_VALUE, schemaConstants.TRUE);
                    existingConditionsList = existingConditionsList.set(index, condition);
              }
              if (condition.get(constants.CONDITION_KEY) === schemaConstants.CONTEXT_MEMBERSHIP_ALLOW_SMS &&
                  condition.get(constants.CONDITION_VALUE) === schemaConstants.FALSE && smsActionPresent) {
                    condition = condition.set(constants.CONDITION_VALUE, schemaConstants.TRUE);
                    existingConditionsList = existingConditionsList.set(index, condition);
              }
              if ((condition.get(constants.CONDITION_KEY) === schemaConstants.CONTEXT_MEMBERSHIP_PUSH_NOTIFICATION_ENABLED &&
                  condition.get(constants.CONDITION_VALUE) === schemaConstants.FALSE) && pushNotificationPreset) {
                    condition = condition.set(constants.CONDITION_VALUE, schemaConstants.TRUE);
                    existingConditionsList = existingConditionsList.set(index, condition);
              }
              ++index;
            });

            if (existingConditionsList.size > 0) {
              newState = newState.setIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.TYPE], constants.MEMBERS_SPECIFIC);
              newState = newState
                .setIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.CONDITIONS, constants.CONDITIONS_LIST], existingConditionsList);
            }
          }
        }
      }

      return newState.set(constants.MEMBERSHIP_GLOBAL_CONDITIONS_MODAL_IS_OPEN, true);
    }

    case constants.CLOSE_GLOBAL_MEMBERSHIP_CONDITIONS_MODAL: {
      const filterType = state.getIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.TYPE]);
      const conditionObj = state.getIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.CONDITIONS, constants.CONDITIONS_LIST]).toJS();
      const userActionConditionObj = state.getIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS, constants.USER_ACTIONS_CONDITIONS_LIST]) ?
        state.getIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS, constants.USER_ACTIONS_CONDITIONS_LIST]).toJS() : null;

      if (filterType === constants.MEMBERS_SPECIFIC && action.shouldUpdateModel) {

        if (!conditionObj && !userActionConditionObj) {
          return state.setIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.TEMP_VALIDATION_ERRORS], 'activity.validation.error.condition.value.required');
        }

        if (!userActionConditionObj) {
          if (conditionObj.length === 1 && conditionObj[0].conditionKey === '') {
            return state.setIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.TEMP_VALIDATION_ERRORS], 'activity.validation.error.condition.value.required');
          }
        } else {
          if (conditionObj.length === 1 && conditionObj[0].conditionKey === '' && userActionConditionObj && userActionConditionObj.length === 1 && userActionConditionObj[0].userActionKey === '') {
            return state.setIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.TEMP_VALIDATION_ERRORS], 'activity.validation.error.condition.value.required');
          }
        }

        let conditionError = '';
        conditionObj.forEach((condition) => {
          if ((condition.conditionValue === '' || condition.conditionValue === null || condition.conditionValue.length === 0) && condition.conditionKey !== '') {
            conditionError = `activity.validation.error.condition.${!condition.conditionKey ? 'key' : 'value'}.required`;
          }
        });

        if (conditionError) {
          return state.setIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.TEMP_VALIDATION_ERRORS], conditionError);
        }
      }

      // verify that not already closed
      if (!state.get(constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL)) {
        console.warn('Trying to close global membership modal which is already closed');
        return state;
      }
      const path = action.isFilteredPopulation ? filterPopulationConditionsPath : globalMembershipConditionsPath;
      let newState = state;
      if (action.shouldUpdateModel) {
        const tempModel = newState.get(constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL);
        const updatedModel = globalConditionReducerUtils.normalizeMembershipGlobalConditionTempModel(tempModel);
        newState = newState.setIn(path, updatedModel);
      }
      newState = newState.set(constants.MEMBERSHIP_GLOBAL_CONDITIONS_MODAL_IS_OPEN, false);
      return newState
        .delete(constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL)
        .delete(constants.TEMP_MEMBERSHIP_STATUS_CONDITION);
    }

    case constants.UPDATE_GLOBAL_MEMBERSHIP_CONDITION_TYPE: {
      let newState = state;
      const path = [constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER];

      if(newState.getIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.TEMP_VALIDATION_ERRORS])) {
        newState = newState.setIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.TEMP_VALIDATION_ERRORS], '');
      }

      //Setting condition key here..
      const copyPasteConditionKey = action.conditionType === constants.MEMBERS_PHONENUMBER_IMPORT ? schemaConstants.CONTEXT_MEMBERSHIP_PHONE_NUMBER
          : schemaConstants.CONTEXT_MEMBERSHIP_KEY_STR;
      const importFileConditionKey = action.conditionType === constants.MEMBERS_PHONENUMBER_IMPORT ? schemaConstants.MEMBERSHIP_PHONENUMBER_KEYS_FILE
        : schemaConstants.MEMBERSHIP_KEYS_FILE;
      if (action.conditionType == constants.NON_MEMBERS) {
        newState = newState.setIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.MEMBERSHIP_STATUS_CONDITION, constants.CONDITION_VALUE], [schemaConstants.MEMBERSHIP_STATUS_VALUE_UNREGISTERED]);
      }
      else {
        newState = newState.setIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.MEMBERSHIP_STATUS_CONDITION, constants.CONDITION_VALUE], [schemaConstants.MEMBERSHIP_STATUS_VALUE_REGISTERED]);
      }
      return newState.setIn([...path, constants.IMPORT_MEMBERS_COPY_PASTE_MODE, constants.CONDITION_KEY], copyPasteConditionKey)
        .setIn([...path, constants.IMPORT_MEMBERS_UPLOAD_FILE_MODE, constants.CONDITION_KEY], importFileConditionKey)
        .setIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.TYPE], action.conditionType);
    }

    case constants.UPDATE_GLOBAL_MEMBERSHIP_CONDITION_FIELD: {
      const newState = state;
      const path = globalConditionReducerUtils.getGlobalConditionPath(action.conditionParent, action.conditionIndex, action.userActionIndex);
      const condition = newState.getIn(path);
      const isUserActionFilter = action.conditionParent === constants.FILTERS_USER_ACTION;
      const trigger = (isUserActionFilter
        ? newState.getIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS,
          constants.USER_ACTIONS_CONDITIONS_LIST, action.userActionIndex, constants.USER_ACTION_KEY])
        : newState.getIn([constants.DATA, constants.TRIGGER])) || '';
      const updatedCondition = conditionReducerUtils.updateConditionField(
        condition, trigger, action.name, action.value, activitySchemaSelectors.getSchema(newState), isUserActionFilter);
      return newState.setIn(path, updatedCondition)
        .deleteIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.TEMP_VALIDATION_ERRORS]);
    }

    case constants.UPDATE_GLOBAL_MEMBERSHIP_STATUS_CONDITION: {
      if (!action.value.length) {
        return state;
      }
      const path = [constants.TEMP_MEMBERSHIP_STATUS_CONDITION, constants.CONDITION_VALUE];
      const newValue = globalConditionReducerUtils.mergeMembershipStatusValues(state.getIn(path), action.value);
      return state.setIn(path, newValue);
    }

    case constants.UPDATE_GLOBAL_IMPORT_MEMBERSHIP_CONDITION: {
      let newState = state;
      const path = [constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER];
      if (action.importMode) {
        return newState.setIn([...path, constants.IMPORT_MEMBERS_MODE], action.importMode);

      }
      if (action.value) {
        let membershipImportArray = transformCsvOrExcelDataToArray(action.value);
        const isDataInvalid = isImportMembersLimitExceeded(membershipImportArray.length);
        newState = newState.setIn([...path, constants.IMPORT_MEMBERS_COPY_PASTE_MODE, constants.PASTING_IMPORT_MEMBERS], false);
        if (isDataInvalid) {
          membershipImportArray = null;
          return newState.setIn([...path, constants.IMPORT_MEMBERS_COPY_PASTE_MODE, constants.IMPORT_MEMBERS_LIMIT_EXCEEDED], true);
        }
        newState = newState.setIn([...path, constants.IMPORT_MEMBERS_COPY_PASTE_MODE, constants.IMPORT_MEMBERS_LIMIT_EXCEEDED], false);
        let condition = newState.getIn([...path, constants.IMPORT_MEMBERS_COPY_PASTE_MODE]);
        condition = condition.set(constants.CONDITION_VALUE, fromJS(membershipImportArray));
        if (action.conditionType) {
          newState = newState.setIn([...path, constants.CONDITION_TYPE_TO_COPY_PASTE_VALUE, action.conditionType], fromJS(membershipImportArray));
        }
        newState = newState.setIn([...path, constants.IMPORT_MEMBERS_COPY_PASTE_MODE], condition);
      }
      return newState;
    }

    case constants.IMPORT_MEMBERS_FILE_UPLOAD: {
      return state.setIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER,
        constants.IMPORT_MEMBERS_UPLOAD_FILE_MODE, constants.UPLOAD_MEMBERS_FILE_IN_PROGRESS], true);
    }

    case constants.IMPORT_MEMBERS_FILE_UPLOAD_SUCCESS: {
      const path = [constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER];
      let newState = state.setIn([...path, constants.IMPORT_MEMBERS_UPLOAD_FILE_MODE, constants.UPLOAD_MEMBERS_FILE_IN_PROGRESS], false)
        .setIn([...path, constants.IMPORT_MEMBERS_UPLOAD_FILE_MODE, constants.CONDITION_VALUE, constants.UPLOADED_FILE_NAME], action[constants.UPLOADED_FILE_NAME])
        .setIn([...path, constants.IMPORT_MEMBERS_UPLOAD_FILE_MODE, constants.CONDITION_VALUE, constants.UPLOADED_BUCKET_NAME], action[constants.UPLOADED_BUCKET_NAME])
        .setIn([...path, constants.IMPORT_MEMBERS_UPLOAD_FILE_MODE, constants.CONDITION_VALUE, constants.MEDIA_LINK], action[constants.MEDIA_LINK])
        .setIn([...path, constants.IMPORT_MEMBERS_UPLOAD_FILE_MODE, constants.CONDITION_VALUE, constants.FILTERED_POPULATION_ADJUSTED_BY_COMILLIA_HINT], false);

      const conditionType = newState.getIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.TYPE]);
      return newState
        .setIn([...path, constants.CONDITION_TYPE_TO_IMPORT_FILE_VALUE, conditionType, constants.CONDITION_VALUE, constants.UPLOADED_FILE_NAME], action[constants.UPLOADED_FILE_NAME])
        .setIn([...path, constants.CONDITION_TYPE_TO_IMPORT_FILE_VALUE, conditionType, constants.CONDITION_VALUE, constants.UPLOADED_BUCKET_NAME], action[constants.UPLOADED_BUCKET_NAME])
        .setIn([...path, constants.CONDITION_TYPE_TO_IMPORT_FILE_VALUE, conditionType, constants.CONDITION_VALUE, constants.MEDIA_LINK], action[constants.MEDIA_LINK])
        .setIn([...path, constants.CONDITION_TYPE_TO_IMPORT_FILE_VALUE, conditionType, constants.CONDITION_VALUE, constants.FILTERED_POPULATION_ADJUSTED_BY_COMILLIA_HINT], false);
    }

    case constants.IMPORT_MEMBERS_FILE_UPLOAD_ERROR: {
      return state.setIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER,
        constants.IMPORT_MEMBERS_UPLOAD_FILE_MODE, constants.UPLOAD_MEMBERS_FILE_IN_PROGRESS], false);
    }

    case constants.CLEAR_GLOBAL_IMPORT_MEMBERSHIP_COPY_PASTE_CONDITION: {
      let newState = state;
      const path = [constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER];
      newState = newState.setIn([...path, constants.IMPORT_MEMBERS_COPY_PASTE_MODE], fromJS(defaults.defaultMembershipImportCopyPasteCondition));
      if (newState.getIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.TYPE]) === constants.MEMBERS_PHONENUMBER_IMPORT) {
        return newState
          .setIn([...path, constants.IMPORT_MEMBERS_COPY_PASTE_MODE, constants.CONDITION_KEY], schemaConstants.CONTEXT_MEMBERSHIP_PHONE_NUMBER)
          .setIn([...path, constants.IMPORT_MEMBERS_UPLOAD_FILE_MODE, constants.CONDITION_KEY], schemaConstants.MEMBERSHIP_PHONENUMBER_KEYS_FILE)
          .setIn([...path, constants.CONDITION_TYPE_TO_COPY_PASTE_VALUE, constants.MEMBERS_PHONENUMBER_IMPORT], '');
      }
      else {
        return newState
          .setIn([...path, constants.IMPORT_MEMBERS_COPY_PASTE_MODE, constants.CONDITION_KEY], schemaConstants.CONTEXT_MEMBERSHIP_KEY_STR)
          .setIn([...path, constants.IMPORT_MEMBERS_UPLOAD_FILE_MODE, constants.CONDITION_KEY], schemaConstants.MEMBERSHIP_KEYS_FILE)
          .setIn([...path, constants.CONDITION_TYPE_TO_COPY_PASTE_VALUE, constants.MEMBERS_IMPORT], '');
      }
    }

    case constants.GOING_TO_PASTE_IMPORT_MEMBERS_DATA: {
      return state.setIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER,
        constants.IMPORT_MEMBERS_COPY_PASTE_MODE, constants.PASTING_IMPORT_MEMBERS], true);
    }

    case constants.ADD_GLOBAL_MEMBERSHIP_CONDITION: {
      const path = [constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.CONDITIONS, constants.CONDITIONS_LIST];
      const updatedConditionsList = globalConditionReducerUtils.addGlobalMembershipCondition(state.getIn(path) || List());
      return state.setIn(path, updatedConditionsList);
    }

    case constants.UPDATE_GLOBAL_MEMBERSHIP_CONDITION_MEMBER_ATTRIBUTES_OPERATOR: {
      const path = [constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.CONDITIONS, constants.CONDITION_LIST_OPERATOR];
      return state.setIn(path, action.value);
    }

    case constants.DELETE_GLOBAL_MEMBERSHIP_CONDITION: {
      if (action.conditionParent === constants.FILTERS_USER_ACTION) {
        const path = getFilterUserActionConditionPath(action.userActionIndex, action.conditionIndex);
        return state.deleteIn(path);
      }
      const path = [constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.CONDITIONS, constants.CONDITIONS_LIST];
      const updatedConditionsList = globalConditionReducerUtils.removeGlobalMembershipCondition(state.getIn(path), action.index);
      return state.setIn(path, updatedConditionsList);
    }

    case constants.COPY_MEMBERSHIP_STATUS_CONDITION_TO_TEMP_DATA: {
      const tempMembershipStatusCondition = globalConditionReducerUtils.getStatusConditionForTempData(state.getIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.MEMBERSHIP_STATUS_CONDITION]));
      return state.set(constants.TEMP_MEMBERSHIP_STATUS_CONDITION, tempMembershipStatusCondition);
    }

    case constants.DELETE_TEMP_MEMBERSHIP_STATUS_CONDITION: {
      return state.delete(constants.TEMP_MEMBERSHIP_STATUS_CONDITION);
    }

    case constants.SAVE_MEMBERSHIP_STATUS_CONDITION: {
      return state.setIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.MEMBERSHIP_STATUS_CONDITION],
        state.get(constants.TEMP_MEMBERSHIP_STATUS_CONDITION));
    }

    case constants.UPDATE_LIMIT_PER_MEMBER_GLOBAL_CONDITION_TYPE: {
      const path = [constants.DATA,
        constants.LIMIT_OCCURRENCES_PER_MEMBER_GLOBAL_CONDITIONS_TEMP_MODEL,
        constants.LIMIT_OCCURRENCES_PER_MEMBER_CONDITION_TYPE];
      return state.setIn(path, action.value);
    }

    case constants.UPDATE_LIMIT_PER_MEMBER_GLOBAL_CONDITION_TOTAL_LIMIT_ENABLE: {
      const globalConditionPath = [constants.DATA, constants.LIMIT_OCCURRENCES_PER_MEMBER_GLOBAL_CONDITIONS_TEMP_MODEL];
      const globalCondition = state.getIn(globalConditionPath);
      const activityType = state.getIn([constants.DATA, constants.TYPE]);
      const updatedGlobalCondition = globalConditionReducerUtils.updateLimitPerMemberGlobalConditionTotalLimitEnabled(globalCondition, action.value, activityType);
      return state.setIn(globalConditionPath, updatedGlobalCondition);
    }

    case constants.UPDATE_LIMIT_PER_MEMBER_GLOBAL_CONDITION_BY_PERIOD_LIMIT_ENABLE: {
      const globalConditionPath = [constants.DATA, constants.LIMIT_OCCURRENCES_PER_MEMBER_GLOBAL_CONDITIONS_TEMP_MODEL];
      const globalCondition = state.getIn(globalConditionPath);
      const activityType = state.getIn([constants.DATA, constants.TYPE]);
      const updatedGlobalCondition = globalConditionReducerUtils.updateLimitPerMemberGlobalConditionByPeriodLimitEnable(globalCondition, action.value, activityType);
      return state.setIn(globalConditionPath, updatedGlobalCondition);
    }

    case constants.UPDATE_LIMIT_PER_MEMBER_GLOBAL_CONDITION_BY_PERIOD_UNIT_CHANGE: {
      const globalConditionPath = [constants.DATA, constants.LIMIT_OCCURRENCES_PER_MEMBER_GLOBAL_CONDITIONS_TEMP_MODEL];
      const globalCondition = state.getIn(globalConditionPath);
      const updatedGlobalCondition = globalConditionReducerUtils.updateLimitPerMemberGlobalConditionByPeriodUnitChange(globalCondition, action.value);
      return state.setIn(globalConditionPath, updatedGlobalCondition);
    }

    case constants.UPDATE_LIMIT_PER_MEMBER_GLOBAL_CONDITION_BY_PERIOD_PERIOD_COUNT: {
      const path = [constants.DATA,
        constants.LIMIT_OCCURRENCES_PER_MEMBER_GLOBAL_CONDITIONS_TEMP_MODEL,
        constants.BY_PERIOD_CONDITION,
        constants.CONDITION_VALUE,
        constants.BY_PERIOD_CONDITION_COUNT];
      return state.setIn(path, action.value);
    }

    case constants.UPDATE_LIMIT_PER_MEMBER_GLOBAL_CONDITION_BY_PERIOD_PERIOD_MULTIPLIER: {
      const path = [constants.DATA,
        constants.LIMIT_OCCURRENCES_PER_MEMBER_GLOBAL_CONDITIONS_TEMP_MODEL,
        constants.BY_PERIOD_CONDITION,
        constants.CONDITION_VALUE,
        constants.BY_PERIOD_PERIOD_MULTIPLIER];
      return state.setIn(path, action.value);
    }

    case constants.UPDATE_LIMIT_PER_MEMBER_GLOBAL_CONDITION_TOTAL_OCCURRENCES_COUNT: {
      const path = [constants.DATA,
        constants.LIMIT_OCCURRENCES_PER_MEMBER_GLOBAL_CONDITIONS_TEMP_MODEL,
        constants.TOTAL_OCCURRENCES_CONDITION,
        constants.CONDITION_VALUE];
      return state.setIn(path, action.value);
    }

    case membershipChangedConstants.UPDATE_MEMBERSHIP_COPY_DATA_TO_TEMP: {
      let newUpdateMembershipConditionValue;
      if (action.value) {
        newUpdateMembershipConditionValue = fromJS(action.value);
      } else {
        newUpdateMembershipConditionValue = conditionReducerUtils.initializeUpdateMembershipCondition(action.conditionSchema.get(constants.KEY));
      }
      return state.set(membershipChangedConstants.UPDATE_MEMBERSHIP_TEMP_DATA, newUpdateMembershipConditionValue);
    }

    case membershipChangedConstants.UPDATE_MEMBERSHIP_RESET_TEMP_DATA: {
      const newUpdateMembershipConditionValue = conditionReducerUtils.initializeUpdateMembershipCondition(action.conditionSchema.get(constants.KEY));
      return state.set(membershipChangedConstants.UPDATE_MEMBERSHIP_TEMP_DATA, newUpdateMembershipConditionValue);
    }

    case membershipChangedConstants.UPDATE_MEMBERSHIP_UPDATE_TEMP_DATA: {
      const tempDataPath = [membershipChangedConstants.UPDATE_MEMBERSHIP_TEMP_DATA, action.origin];
      const tempData = state.getIn(tempDataPath);
      const updatedTempData = globalConditionReducerUtils.updateMembershipTempDataField(tempData, action[constants.KEY], action.value);
      return state.setIn(tempDataPath, updatedTempData);
    }

    case membershipChangedConstants.UPDATE_MEMBERSHIP_CLEAR_TEMP_DATA: {
      return state.delete(membershipChangedConstants.UPDATE_MEMBERSHIP_TEMP_DATA);
    }

    case constants.RESET_GIFT_INSTANCE_EXPIRATION_GLOBAL_CONDITION_TEMP_MODEL: {
      const currentModel = state.getIn([constants.DATA, constants.INSTANCE_EXPIRATION_GLOBAL_CONDITION]);
      const currentModelWithPristineFlag = currentModel.set(constants.PRISTINE_FLAG, true);
      return state
        .set(constants.INSTANCE_EXPIRATION_GLOBAL_CONDITION_TEMP_MODEL, currentModelWithPristineFlag);
    }

    case constants.UPDATE_GIFT_INSTANCE_EXPIRATION_GLOBAL_CONDITION_TEMP_MODEL: {
      const sectionPath = [constants.INSTANCE_EXPIRATION_GLOBAL_CONDITION_TEMP_MODEL, action.section];
      return state
        .setIn([constants.INSTANCE_EXPIRATION_GLOBAL_CONDITION_TEMP_MODEL, constants.PRISTINE_FLAG], false)
        .setIn(sectionPath.concat(action.key), action.value);
    }

    case constants.APPLY_GIFT_INSTANCE_EXPIRATION_GLOBAL_CONDITION_TEMP_MODEL: {
      return state.setIn([constants.DATA, constants.INSTANCE_EXPIRATION_GLOBAL_CONDITION],
        state.get(constants.INSTANCE_EXPIRATION_GLOBAL_CONDITION_TEMP_MODEL)
          .delete(constants.PRISTINE_FLAG));
    }

    case constants.SET_BIRTHDAY_OR_ANNIVERSARY_EVENT_SCOPE: {
      return state.setIn([constants.DATA, constants.BIRTHDAY_OR_ANNIVERSARY_PARAMETERS, constants.EVENT_SCOPE], action.value);
    }

    case constants.SET_CLUB_MEMBER_ANNIVERSARY_EVENT_SCOPE: {
      return state.setIn([constants.DATA, constants.CLUB_MEMBER_ANNIVERSARY_PARAMETERS, constants.EVENT_SCOPE], action.value);
    }

    case constants.UPDATE_TRIGGER: {
      if ([schemaConstants.TRIGGER_MEMBER_HAS_ANNIVERSARY, schemaConstants.TRIGGER_MEMBER_HAS_BIRTHDAY, schemaConstants.TRIGGER_CLUB_MEMBER_ANNIVERSARY]
        .includes(action.trigger)) {
        return state.setIn([constants.DATA, constants.LIMIT_OCCURRENCES_PER_MEMBER_GLOBAL_CONDITIONS],
          fromJS(defaults.defaultBirthdayOrAnniversaryOrClubMemberAnniversaryGlobalOccurrencesLimitCondition));
      }
      if (action.trigger === schemaConstants.TRIGGER_JOINED_APP) {
        const membershipGlobalCondition = state.getIn([constants.DATA, constants.MEMBERSHIP_GLOBAL_CONDITIONS]);
        const updatedMembershipGlobalCondition =
          globalConditionReducerUtils.alterMembershipGlobalConditionToIncludeUnregisteredStatus(membershipGlobalCondition);
        return state.setIn([constants.DATA, constants.MEMBERSHIP_GLOBAL_CONDITIONS], updatedMembershipGlobalCondition);
      }
      return state;
    }

    case constants.UPDATE_FILTER_USER_ACTION_TYPE: {
      const path = [constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS];
      const dateAttributeModel = globalConditionReducerUtils.prepareDateAttributeModel(activitySchemaSelectors.getDateRangeCondition(state));
      const now = moment();
      const dateRangeCondition = dateTimeReducerUtils.updateDateRangeConditionDate(dateAttributeModel, false, now);
      const schemaAggregatedConditionsPerUserAction = activitySchemaSelectors.getSchema(state)
        .getIn([constants.CONDITIONS_PER_USER_ACTION, action.userActionType, schemaConstants.AGGREGATED_CONDITIONS_PER_ACTION]);
      const updatedUserActions = globalConditionReducerUtils.initUserActionsConditions(state.getIn(path),
        action.userActionType, action.userActionIndex, dateRangeCondition, schemaAggregatedConditionsPerUserAction);
      return state.setIn(path, updatedUserActions);
    }

    case constants.UPDATE_USER_ACTION_AGGREGATION_CRITERIA: {
      let newState = state;
      const pathPrefix = [constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS, constants.USER_ACTIONS_CONDITIONS_LIST,
        action.userActionIndex, constants.USER_ACTION_AGGREGATION_CONDITIONS, action.attributeIndex];
      const conditionPath = [...pathPrefix, constants.CONDITION];
      const isConditionActivePath = [...pathPrefix, constants.IS_USER_ACTION_AGGREGATED_CONDITIONS_ACTIVE];
      if (action.name != null) {
        const condition = newState.getIn(conditionPath);
        const userActionFilter = newState.getIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS,
          constants.USER_ACTIONS_CONDITIONS_LIST, action.userActionIndex, constants.USER_ACTION_KEY]);
        const updatedCondition = conditionReducerUtils.updateConditionField(
          condition, userActionFilter, action.name, action.value, activitySchemaSelectors.getSchema(newState), true);
        newState = newState.setIn(conditionPath, updatedCondition);
      }
      return newState.setIn(isConditionActivePath, action.isActive);
    }

    case constants.UPDATE_GLOBAL_MEMBERSHIP_CONDITION_MEMBER_ACTIONS_OPERATOR: {
      const path = [constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS, constants.CONDITION_LIST_OPERATOR];
      return state.setIn(path, action.value);
    }

    case constants.ADD_USER_ACTION_CRITERIA_TO_FILTER: {
      const path = [constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS,
        constants.USER_ACTIONS_CONDITIONS_LIST, action.userActionIndex, constants.CONDITIONS, constants.CONDITIONS_LIST];
      const updatedConditionsList = globalConditionReducerUtils.addGlobalMembershipCondition(state.getIn(path) || List());
      return state.setIn(path, updatedConditionsList);
    }

    case constants.CLEAR_FILTERED_POPULATION: {
      return state
        .deleteIn([constants.DATA, constants.ONE_TIME_ACTION_FILTERED_POPULATION, constants.FILTERED_POPULATION_COUNT])
        .deleteIn([constants.DATA, constants.ONE_TIME_ACTION_FILTERED_POPULATION, constants.FILTERED_POPULATION_FILE_NAME])
        .deleteIn([constants.DATA, constants.ONE_TIME_ACTION_FILTERED_POPULATION, constants.FILTERED_POPULATION_BUCKET_NAME]);
    }

    case constants.CREATE_REFERRAL_CODE_TEMP_MODEL: {
      const condition = state
        .getIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS, constants.USER_ACTIONS_CONDITIONS_LIST, action.userActionIndex, constants.CONDITIONS, constants.CONDITIONS_LIST, action.conditionIndex]);
      return state
        .set(constants.TEMP_REFERRAL_CODE_MODEL, condition);
    }

    case constants.DELETE_REFERRAL_CODE_TEMP_MODEL: {
      return state.delete(constants.TEMP_REFERRAL_CODE_MODEL);
    }

    case constants.SAVE_REFERRAL_CODE_TEMP_MODEL_TO_CONDITIONS: {
      const condition = globalConditionReducerUtils.prepareReferralCodeTempConditionToSave(state.get(constants.TEMP_REFERRAL_CODE_MODEL));
      return state
        .setIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS, constants.USER_ACTIONS_CONDITIONS_LIST,
          action.userActionIndex, constants.CONDITIONS, constants.CONDITIONS_LIST, action.conditionIndex], condition);
    }

    case constants.DELETE_USER_ACTION_FROM_FILTER: {
      const userActionsListPath = [constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS, constants.USER_ACTIONS_CONDITIONS_LIST];
      if (state.getIn(userActionsListPath).size <= 1) {
        return state.setIn([...userActionsListPath, 0], fromJS(defaults.userActionEmptyConditions));
      }
      return state.deleteIn([...userActionsListPath, action.userActionIndex]);
    }

    default:
      return state;
  }
}

function getFilterUserActionConditionPath(userActionIndex, conditionIndex) {
  return [constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS,
    constants.USER_ACTIONS_CONDITIONS_LIST, userActionIndex, constants.CONDITIONS,
    constants.CONDITIONS_LIST, conditionIndex];
}

function transformCsvOrExcelDataToArray(value) {
  let membershipImportArray = [];
  if (value.includes(String.fromCharCode(13))) {
    membershipImportArray = value.split(String.fromCharCode(13)).map((item) => item.trim());
  } else if (value.includes(',')) {
    membershipImportArray = value.split(',').map((item) => item.trim());
  } else if (value.includes(String.fromCharCode(32))) {
    membershipImportArray = value.split(String.fromCharCode(32)).map((item) => item.trim());
  } else if (value.includes('\n')) {
    membershipImportArray = value.split('\n').map((item) => item.trim());
  } else if (value.includes(String.fromCharCode(9))) {
    membershipImportArray = value.split(String.fromCharCode(9)).map((item) => item.trim());
  } else {
    membershipImportArray.push(value.trim());
  }
  return membershipImportArray.filter((key) => !_.isEmpty(key));
}




