import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import PromoCodesSingleCodeTab from './promoCodes/PromoCodesSingleCodeTab';
import PromoCodesGeneratedTab from './promoCodes/PromoCodesGeneratedTab';

const PROMO_CODES_TYPES = {
  singleCode: 'singleCode',
  generated: 'generated',
  imported: 'imported'
};

class PromoCodesCodeTab extends React.PureComponent {

  static propTypes = {
    location: PropTypes.object,
    promoCodeType: PropTypes.string
  };

  render() {
    const { location, promoCodeType } = this.props;
    let type = !location.state ? promoCodeType : location.state.type;

    switch (type) {
      case PROMO_CODES_TYPES.singleCode:
        return <PromoCodesSingleCodeTab />;
      case PROMO_CODES_TYPES.generated:
        return <PromoCodesGeneratedTab />;
      default:
        return <div>promo code type not found</div>;
    }
  }
}

export default compose(
  injectIntl
)(PromoCodesCodeTab);
