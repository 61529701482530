import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import '../membershipConditions/MembershipGlobalConditions.styles.less';
import * as constants from '../../../../activity.constants';
import * as schemaConstants from '../../../../activitySchema/activitySchema.constants';
import Condition from '../../condition';
import './filters.styles.less';
import CheckBox from '../../../../../../common/components/FormInputFields/CheckboxWrapper/CheckboxWrapper';
import SingleLineAccordion from '../../../../../../common/components/SingleLineAccordion/singleLineAccordion';

class UserActionConditions extends React.PureComponent {

  static propTypes = {
    addUserActionCriteriaToFilter: PropTypes.func,
    dataAutomationPrefix: PropTypes.string,
    deleteUserActionCriteriaFromFilter: PropTypes.func,
    features: PropTypes.object,
    schemaConditionsPerUserAction: PropTypes.object,
    updateFilterUserActionAggregationAttribute: PropTypes.func,
    updateFilterUserActionAttribute: PropTypes.func,
    userAction: PropTypes.object,
    userActionIndex: PropTypes.number,
    validationErrors: PropTypes.object,
    referralTempCondition: PropTypes.object,
    referralTempError: PropTypes.string,
    createReferralCodeTempModel: PropTypes.func,
    deleteReferralCodeTempModel: PropTypes.func,
    saveReferralCodeTempModelToGlobalConditions: PropTypes.func,
    getBusinessBundlePlan: PropTypes.func.isRequired,
  };

  getFilteredSchemaConditions(schemaConditions, conditions) {
    if (!schemaConditions) {
      return null;
    }
    return schemaConditions.filter((conditionSchema) => !(conditionSchema.get(constants.CONDITION_USED_ONCE_PER_USER_ACTION)
      && conditions.find((condition) => condition.get(constants.CONDITION_KEY) === conditionSchema.get(constants.SCHEMA_CONDITION_KEY))));
  }

  getUserActionConditionsThatAppearInSchema(conditions, schemaConditions, includeEmptyConditions) {
    return conditions.filter((condition) => {
      if (includeEmptyConditions && condition.get(constants.CONDITION_KEY) === '') {
        return true;
      }
      return schemaConditions && schemaConditions.find((schemaCond) =>
        condition.get(constants.CONDITION_KEY) === schemaCond.get(constants.SCHEMA_CONDITION_KEY));
    });
  }

  isAnyAggregationConditionSelected(userActionAggregationConditions) {
    return userActionAggregationConditions && !!userActionAggregationConditions.find((conditionWrapper) =>
      conditionWrapper.get(constants.IS_USER_ACTION_AGGREGATED_CONDITIONS_ACTIVE));
  }

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const actionType = this.props.userAction.get(constants.USER_ACTION_KEY);
    if (!!actionType === false) {
      return null;
    }
    const userActionAllConditionsList = this.props.userAction.getIn([constants.CONDITIONS, constants.CONDITIONS_LIST]);
    const schemaConditionsForUserAction = this.props.schemaConditionsPerUserAction.get(actionType);

    // Non aggregation conditions
    const schemaNonAggregationConditionsForUserAction = schemaConditionsForUserAction.get(schemaConstants.CONDITIONS_PER_ACTION);
    const filteredSchemaNonAggregationConditionsForUserAction = this.getFilteredSchemaConditions(schemaNonAggregationConditionsForUserAction, userActionAllConditionsList);

    // Aggregation conditions
    const userActionAggregationConditions = this.props.userAction.get(constants.USER_ACTION_AGGREGATION_CONDITIONS);

    return (
      <div className="filter-user-action-tabbed-conditions">
        {userActionAllConditionsList.map((condition, conditionIndex) => (
          <Condition
            automationIdPrefix={`${this.props.dataAutomationPrefix}.condition.${conditionIndex}`}
            className={'filter-user-action-tabbed-condition'}
            condition={condition}
            conditionsList={schemaNonAggregationConditionsForUserAction}
            filteredConditionsList={filteredSchemaNonAggregationConditionsForUserAction}
            deleteCondition={() => this.props.deleteUserActionCriteriaFromFilter(conditionIndex)}
            features={this.props.features}
            key={conditionIndex.toString()}
            index={conditionIndex}
            isLastCondition={conditionIndex === userActionAllConditionsList.size - 1}
            isMembersFilter
            isPastDateRange
            mainConditions
            trigger={actionType}
            updateConditionField={(name, value, index) => this.props.updateFilterUserActionAttribute(name, value, index, condition.get(constants.CONDITION_KEY))}
            validationErrors={this.props.validationErrors ? this.props.validationErrors.getIn([constants.CONDITIONS, conditionIndex.toString()]) : null}
            userActionIndex={this.props.userActionIndex}
            conditionKeyWidth={5}
            conditionKeyColumnMaxWidthClass="filter-user-criteria-key-max-width"
            conditionValueWidth={condition.get(constants.ITEMS_POPULATION) ? 2 : null}
            referralTempCondition={this.props.referralTempCondition}
            referralTempError={this.props.referralTempError}
            createReferralCodeTempModel={this.props.createReferralCodeTempModel}
            deleteReferralCodeTempModel={this.props.deleteReferralCodeTempModel}
            saveReferralCodeTempModelToGlobalConditions={this.props.saveReferralCodeTempModelToGlobalConditions}
            getBusinessBundlePlan={this.props.getBusinessBundlePlan}
          />
        ), this)}
        { schemaConditionsForUserAction.get('conditionsPerAction') && schemaConditionsForUserAction.get('conditionsPerAction').size > 0 &&
        <a
          role="button"
          className="condition-link add-condition"
          onClick={this.props.addUserActionCriteriaToFilter}
          data-automation-id={`${this.props.dataAutomationPrefix}.add-criteria`}
        >
          <Icon className="como-ic-plus-in-circle" />
          {formatMessage({ id: 'activity.filters.actions.addCriteria' })}
        </a>
        }
        {userActionAggregationConditions && userActionAggregationConditions.size > 0 &&
          <SingleLineAccordion
            automationIdPrefix={`${this.props.dataAutomationPrefix}.aggregations.section`}
            classNames="filter-user-action-aggregation-conditions-container"
            title={formatMessage({ id: `activity.filters.user.actions.aggregations.${actionType}` })}
            initialCollapseState={!this.isAnyAggregationConditionSelected(userActionAggregationConditions)}
            validationErrors={this.props.validationErrors ? this.props.validationErrors.get(constants.USER_ACTION_AGGREGATION_CONDITIONS) : null}
          >
            <Grid className="filter-user-action-aggregation-conditions-content">
              {userActionAggregationConditions.map((conditionWrapper, conditionIndex) => {
                const condition = conditionWrapper.get(constants.CONDITION);
                const schemaAggregationConditionsPerUserAction = schemaConditionsForUserAction.get(schemaConstants.AGGREGATED_CONDITIONS_PER_ACTION)
                  .filter((conditionSchema) => conditionSchema.get(constants.SCHEMA_CONDITION_KEY) === condition.get(constants.CONDITION_KEY));
                const isConditionActive = conditionWrapper.get(constants.IS_USER_ACTION_AGGREGATED_CONDITIONS_ACTIVE);
                return (
                  <Grid.Row key={conditionIndex.toString()} className="filter-user-action-aggregation-conditions-row">
                    <Grid.Column width={5}>
                      <CheckBox
                        className="filter-user-action-aggregation-conditions-row-checkbox"
                        checked={isConditionActive}
                        label={formatMessage({ id: `condition.${condition.get(constants.CONDITION_KEY)}` })}
                        data-automation-id={`${this.props.dataAutomationPrefix}.aggregation.checkbox.${conditionIndex}`}
                        onChange={(e, { checked }) => this.props.updateFilterUserActionAggregationAttribute(null, null, checked, conditionIndex)}
                      />
                    </Grid.Column>
                    <Grid.Column width={9}>
                      <Condition
                        automationIdPrefix={`${this.props.dataAutomationPrefix}.aggregation.condition.${conditionIndex}`}
                        condition={condition}
                        conditionsList={schemaAggregationConditionsPerUserAction}
                        disabled={!isConditionActive}
                        features={this.props.features}
                        key={(conditionIndex).toString()}
                        index={conditionIndex}
                        isDeletable={false}
                        hideConditionKey
                        trigger={actionType}
                        updateConditionField={(name, value, index) => this.props.updateFilterUserActionAggregationAttribute(name, value, isConditionActive, index)}
                        validationErrors={this.props.validationErrors ? this.props.validationErrors.getIn([constants.USER_ACTION_AGGREGATION_CONDITIONS, conditionIndex]) : null}
                        userActionIndex={this.props.userActionIndex}
                        conditionKeyWidth={0}
                        conditionOperatorWidth={9}
                        conditionValueWidth={7}
                        referralTempCondition={this.props.referralTempCondition}
                        referralTempError={this.props.referralTempError}
                        createReferralCodeTempModel={this.props.createReferralCodeTempModel}
                        deleteReferralCodeTempModel={this.props.deleteReferralCodeTempModel}
                        saveReferralCodeTempModelToGlobalConditions={this.props.saveReferralCodeTempModelToGlobalConditions}
                        getBusinessBundlePlan={this.props.getBusinessBundlePlan}
                      />
                    </Grid.Column>
                  </Grid.Row>
                );
              }, this)
              }
            </Grid>
          </SingleLineAccordion>
        }
      </div>
    );
  }
}

export default injectIntl(UserActionConditions);

