import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';
import Modal from '../CustomModal';

const AUTOMATION_NAME = 'information';

class InformationModal extends React.PureComponent {

  static propTypes = {
    onConfirm: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    content: PropTypes.node,
    children: PropTypes.node,
  };

  modalActionsHtml() {
    const { formatMessage } = this.props.intl; // eslint-disable-line

    return [
      <Button
        key="save"
        data-automation-id={`${AUTOMATION_NAME}.btn.got-it`}
        onClick={this.props.onConfirm}
      >
        {formatMessage({ id: 'general.button.got-it' })}
      </Button>
    ];
  }

  render() {

    const { formatMessage } = this.props.intl; // eslint-disable-line
    const { content, children, open, onConfirm } = this.props;

    return (
      <Modal
        className="como-dialog"
        header={formatMessage({ id: 'global.information.header' })}
        onClose={onConfirm}
        content={children || content}
        open={open}
        actions={this.modalActionsHtml()}
        automationIdPrefix={AUTOMATION_NAME}
        size="mini"
        closeOnDimmerClick
      />
    );
  }
}

export default injectIntl(InformationModal);
