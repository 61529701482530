import { createSelector } from 'reselect';
import { List, Map } from 'immutable';
import * as schemaConstants from './activitySchema.constants';
import * as appConstants from '../../App/app.constants';
import * as constants from '../activity.constants';
import { getSchema } from './activitySchema.selectors';
import { getTranslations } from '../../App/selectors';
import { getRegistrationFormFields } from '../../Forms/forms.selectors';
import { getSelectedTrigger } from '../activity.selectors';

// export const getEmailMentionsTransformed = createSelector([getTranslations, getSchema], getEmailMentionsTransformedImpl);

const getMentionsForSelectedTrigger = createSelector([getSelectedTrigger, getSchema],
  (trigger, schema) => schema.getIn([constants.MENTIONS_PER_TRIGGERS, trigger]));

const getMentionsForMicroCampaigns = createSelector([getSchema], (schema) => schema.getIn([constants.MENTIONS_PER_TRIGGERS, schemaConstants.TRIGGER_MICRO_CAMPAIGN]));

const getMentionsForEmailActions = createSelector([getSchema], (schema) => schema.get(constants.EMAIL_ACTION_MENTIONS));

export const getAllMentionsUnsortedTransformed = createSelector(
  [getTranslations, getRegistrationFormFields, getSchema], getAllMentionsUnsortedTransformedImpl);

export const getTransformedMentions = createSelector(
  [getMentionsForSelectedTrigger, getTranslations, getRegistrationFormFields, getSchema], getTransformedMentionsImpl);

export const getTransformedMentionsForMicroCampaigns = createSelector(
  [getMentionsForMicroCampaigns, getTranslations, getRegistrationFormFields, getSchema], getTransformedMentionsImpl);

export const getEmailMentionsTransformed = createSelector(
  [getMentionsForEmailActions, getTranslations, getRegistrationFormFields, getSchema], getTransformedMentionsImpl);

/**
 * Reduce the mentions list according to the registration fields.
 * For the reduced list, put the form field title as the mention display name
 * @param membershipMentions
 * @param registrationFormFields
 * @returns {*}
 */
function filterAndEnrichMembershipMentionsByRegFields(membershipMentions, registrationFormFields) {
  return membershipMentions.filter((mention) => findFormFieldMatchingMention(registrationFormFields, mention) !== undefined).map((mention) => {
    const foundFormField = findFormFieldMatchingMention(registrationFormFields, mention);
    return foundFormField ? mention.set(schemaConstants.MENTION_DISPLAY, foundFormField.get(appConstants.REGISTRATION_FORM_FIELD_TITLE)) : mention;
  });
}

function findFormFieldMatchingMention(registrationFormFields, mention) {
  return registrationFormFields.find((formField) => {
    const formFieldName = formField.get(appConstants.REGISTRATION_FORM_FIELD_NAME);
    const regexPattern = `${formFieldName}(\\[0\\]\\.OldValue|)$`;
    return mention.get(schemaConstants.MENTION_ID).match(regexPattern) !== null;
  });
}

function translateMentionDisplayName(mention, translations) {
  const mentionCategoryKey = `mention.Category.${mention.get(schemaConstants.MENTION_CATEGORY)}`;
  const mentionDisplayKey = `mention.${mention.get(schemaConstants.MENTION_ID)}`;
  // Get category name from translations. If it's missing, use the key
  const translatedCategory = translations[mentionCategoryKey] ? translations[mentionCategoryKey] : mention.get(schemaConstants.MENTION_CATEGORY);
  // For the mention name, if it is already present (from registration form) use it
  // If not, get the name from translations. If missing from there, use the key
  let translatedName = mention.get(schemaConstants.MENTION_DISPLAY);
  if (!translatedName) {
    if (translations[mentionDisplayKey]) {
      translatedName = translations[mentionDisplayKey];
    } else {
      const mentionIdTokens = mention.get(schemaConstants.MENTION_ID).split('context.');
      translatedName = mentionIdTokens[1];
    }
  }
  // Construct the mention name with Category: mention
  const mentionTransformedDisplay = `${translatedCategory}: ${translatedName}`;
  return mention.set(schemaConstants.MENTION_DISPLAY, mentionTransformedDisplay);
}

function translateMentionsDisplayNames(mentionsByCategory, translations) {
  return mentionsByCategory.reduce((result, categoryValues, categoryName) => result.set(categoryName,
    categoryValues.map((mention) => translateMentionDisplayName(mention, translations))), Map());
}

function sortMentionsInsideCategories(mentionsByCategory) {
  return mentionsByCategory.reduce((result, categoryValues, categoryName) => result.set(categoryName,
    categoryValues.sort(sortMentionsInsideCategory)), Map());
}

function sortMentionsInsideCategory(mention1, mention2) {
  // sort inside category
  if (mention1.get(schemaConstants.MENTION_DISPLAY) < mention2.get(schemaConstants.MENTION_DISPLAY)) {
    return -1;
  }
  if (mention1.get(schemaConstants.MENTION_DISPLAY) > mention2.get(schemaConstants.MENTION_DISPLAY)) {
    return 1;
  }
  return 0;
}

function sortByOrderMap(item1, item2, orderMap) {
  if (orderMap.get(item1) < orderMap.get(item2)) {
    return -1;
  }
  if (orderMap.get(item1) > orderMap.get(item2)) {
    return 1;
  }
  return 0;
}

function mergeAllMembershipMentionsToOneCategory(mentionsByCategory, schema) {
  const mentionsMembershipSubCategories = schema.get(schemaConstants.MENTIONS_MEMBERSHIP_SUB_CATEGORIES);
  const isMembershipMentionsCategory = (categoryName) => mentionsMembershipSubCategories.includes(categoryName);

  return mentionsByCategory.reduce((accumulator, categoryValues, categoryName) => {
    if (isMembershipMentionsCategory(categoryName)) {
      return accumulator.set(schemaConstants.MENTION_MEMBERSHIP_CATEGORY,
        accumulator.get(schemaConstants.MENTION_MEMBERSHIP_CATEGORY).concat(categoryValues));
    }
    return accumulator.set(categoryName, categoryValues);
  }, Map().set(schemaConstants.MENTION_MEMBERSHIP_CATEGORY, List()));
}

function sortAndMergeMentionsByCategories(transformedMentionsByCategory, schema) {
  const orderMap = schema.get(schemaConstants.MENTIONS_CATEGORIES_ORDER);
  const sortedCategoryNames = transformedMentionsByCategory.keySeq().sort(
    (item1, item2) => sortByOrderMap(item1, item2, orderMap));
  return sortedCategoryNames.reduce(
    (accumulator, categoryName) => accumulator.concat(transformedMentionsByCategory.get(categoryName)),
    List());
}

/**
 * Implementation of the getTransformedMentions selector
 * @param mentionsForSelectedTrigger
 * @param translations
 * @param registrationFormFields
 * @param schema
 * @returns {*}
 */
function getTransformedMentionsImpl(mentionsForSelectedTrigger, translations, registrationFormFields, schema) {
  if (translations && mentionsForSelectedTrigger && registrationFormFields) {
    let transformedMentionsByCategory = mentionsForSelectedTrigger;

    // Filter out membership mentions (except for must-show mentions) that are not part of the registration form fields
    transformedMentionsByCategory = filterMembershipMentionsByRegFormFields(transformedMentionsByCategory, registrationFormFields);

    // Merge all membership mentions before sort
    transformedMentionsByCategory = mergeAllMembershipMentionsToOneCategory(transformedMentionsByCategory, schema);

    // For each mention, translate the category & id to create the display field
    transformedMentionsByCategory = translateMentionsDisplayNames(transformedMentionsByCategory, translations);

    // Sort mentions
    transformedMentionsByCategory = sortMentionsInsideCategories(transformedMentionsByCategory);

    // Merge all mentions to 1 sorted-by-category array
    return sortAndMergeMentionsByCategories(transformedMentionsByCategory, schema);
  }

  return null;
}

function getAllMentionsUnsortedTransformedImpl(translations, registrationFormFields, schema) {
  if (translations && registrationFormFields) {
    const mentionsPerTrigger = schema.get(constants.MENTIONS_PER_TRIGGERS);
    const mentions = mentionsPerTrigger.reduce((accumulator, byTrigger) => {
      let newAccumulator = accumulator;
      byTrigger.forEach((mentionsSource) => {
        mentionsSource.toList().forEach((mention) => {
          if (!newAccumulator.get(mention.get(schemaConstants.MENTION_ID))) {
            const translatedMention = translateMentionDisplayName(mention, translations);
            newAccumulator = newAccumulator.set(mention.get(schemaConstants.MENTION_ID), translatedMention);
          }
        });
      });
      return newAccumulator;
    }, Map());

    return mentions.toList();
  }
  return null;
}

function getEmailMentionsTransformedImpl(translations, schema) {
  return schema.get(constants.EMAIL_ACTION_MENTIONS).map((
    mention) => translateMentionDisplayName(mention, translations));
}

function filterMembershipMentionsCategoryByRegFormFields(mentionsByCategory, registrationFormFields, categoryName) {
  // Use only mentions that match the registration fields
  let mentionsList = mentionsByCategory.get(categoryName);
  if (!mentionsList) {
    return mentionsByCategory;
  }
  mentionsList = filterAndEnrichMembershipMentionsByRegFields(mentionsList, registrationFormFields);
  return mentionsByCategory.set(categoryName, mentionsList);
}

function filterMembershipMentionsByRegFormFields(mentionsByCategory, registrationFormFields) {
  let result = filterMembershipMentionsCategoryByRegFormFields(mentionsByCategory, registrationFormFields, schemaConstants.MENTION_MEMBERSHIP_BY_REG_FORM_CATEGORY);
  result = filterMembershipMentionsCategoryByRegFormFields(result, registrationFormFields, schemaConstants.MENTION_MEMBERSHIP_ADDRESS_CATEGORY);
  result = filterMembershipMentionsCategoryByRegFormFields(result, registrationFormFields, schemaConstants.MENTION_UPDATE_MEMBERSHIP_CATEGORY);
  return result;
}
