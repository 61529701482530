/**
 *
 * StringArray is a component that knows to receive an array of strings and display it in a text input field exploded
 * by a delimiter
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import InputWrapper from '../InputWrapper/InputWrapper';
import { STRING_TYPE } from '../inputConstants';

export const DELIMITER = ',';

class StringArrayInputField extends React.PureComponent {

  static propTypes = {
    allowOnlyPositive: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    fluid: PropTypes.bool,
    name: PropTypes.string,
    prefix: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func
  };

  static defaultProps = {
    fluid: false,
    allowOnlyPositive: true
  };

  static stringToArray(str) {
    return str ? str.split(DELIMITER).map((item) => item.trim()) : [];
  }

  static arrayToString(str) {
    return str ? str.join(DELIMITER) : '';
  }

  constructor(props) {
    super(props);
    this.state = { displayValue: StringArrayInputField.arrayToString(props.value) };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ displayValue: StringArrayInputField.arrayToString(nextProps.value) });
  }

  onChange = (e, data) => {
    this.setState({ displayValue: data.value });
    this.props.onChange(e, StringArrayInputField.stringToArray(data.value));
  };

  render() {
    const { disabled, error, fluid, name, prefix } = this.props;
    const className = fluid ? this.props.className : `${this.props.className} string-array-input-field`;

    return (<InputWrapper
      className={className}
      disabled={disabled}
      error={error}
      fluid={fluid}
      name={name}
      onChange={this.onChange}
      prefix={prefix}
      type={STRING_TYPE}
      value={this.state.displayValue}
    />
    );
  }
}

export default injectIntl(StringArrayInputField);
