import { fromJS } from 'immutable';
import * as _ from 'lodash';
import * as constants from './mailTemplates.constants';

const initialState = fromJS({
  [constants.MAIL_TEMPLATES_MAP]: {},
  [constants.MAIL_TEMPLATES_LOADING]: false,
  [constants.MAIL_SENDER_NAME]: '',
  [constants.MAIL_SENDER_NAME_LOADING]: false,
});

function mailTemplatesReducer(state = initialState, action) {
  switch (action.type) {
    case constants.GET_MAIL_TEMPLATES: {
      return state.set(constants.MAIL_TEMPLATES_LOADING, true);
    }

    case constants.GET_MAIL_TEMPLATES_SUCCESS: {
      const emails = action.data.map((value, key) => ({ ...value, sort: key }));
      return state.set(constants.MAIL_TEMPLATES_MAP, fromJS(_.keyBy(emails, constants.EXTERNAL_ID)))
                  .set(constants.MAIL_TEMPLATES_LOADING, false);
    }

    case constants.GET_MAIL_TEMPLATES_ERROR: {
      console.error(action.message);
      return state.set(constants.MAIL_TEMPLATES_LOADING, false);
    }

    case constants.GET_MAIL_SENDER_NAME: {
      return state.set(constants.MAIL_SENDER_NAME_LOADING, true);
    }

    case constants.GET_MAIL_SENDER_NAME_SUCCESS: {
      return state.set(constants.MAIL_SENDER_NAME, fromJS(action.data))
                  .set(constants.MAIL_SENDER_NAME_LOADING, false);
    }

    case constants.GET_MAIL_SENDER_NAME_ERROR: {
      console.error(action.message);
      return state.set(constants.MAIL_SENDER_NAME_LOADING, false);
    }

    default:
      return state;
  }
}

export default mailTemplatesReducer;
