import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

const Instructions = (props) => {
  const { intl, instructions, setInstructions } = props;

  const { formatMessage } = intl;

  return (
    <div className={`tw-flex tw-flex-col tw-mb-2`}>
      <span className={'tw-text-sm'}>
        {formatMessage({ id: 'vertex.ai.instructions' })}
        <span className={'tw-italic tw-text-gray-300'}>
          {formatMessage({ id: 'vertex.ai.instructions.optional' })}
        </span>
      </span>
      <input
        type={'text'}
        onChange={(e) => setInstructions(e.target.value)}
        name={'Instructions'}
        value={instructions}
        placeholder={formatMessage({ id: 'vertex.ai.instructions.placeholder' })}
        className={'tw-input tw-my-2 tw-input-bordered tw-w-full tw-max-w-xs'}
      />
    </div>
  );
};

Instructions.prototype = {
  intl: PropTypes.object,
  instructions: PropTypes.string,
  setInstructions: PropTypes.func
};

export default injectIntl(Instructions);
