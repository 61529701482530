import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import * as constants from './businessCenter.constants';
import * as errorConstant from '../ErrorMessage/errorMessage.constants';
import * as activityConstants from '../Activity/activity.constants';
import * as api from './businessCenter.api';
import * as routeConstants from '../../constants/route.contants';
import * as queryStringUtils from '../../utils/queryStringUtils';
import * as appSelectors from '../App/selectors';


function* getCampaigns() {
  try {
    const data = yield call(api.getCampaigns, false);
    yield put({ type: constants.GET_CAMPAIGNS_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getCampaigns.Fail'] });
  }
}

function* getCampaignsWithArchiveActivities() {
  try {
    const data = yield call(api.getCampaigns, true);
    yield put({ type: constants.FETCHED_ACTIVITIES_WITH_ARCHIVE_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getCampaigns.Fail'] });
  }
}

function* createCampaign({ name, status }) {
  try {
    const campaign = { [constants.CAMPAIGN_NAME]: name, [constants.CAMPAIGN_STATUS]: status };
    const data = yield call(api.createCampaign, campaign);
    yield put({ type: constants.CREATE_CAMPAIGN_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['createCampaign.Fail'] });
  }
}

function* deleteCampaign({ campaignId }) {
  try {
    yield call(api.deleteCampaign, campaignId);
    yield put({ type: constants.DELETE_CAMPAIGN_SUCCESS, [constants.ID]: campaignId });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['deleteCampaign.Fail'] });
  }
}

export function* deleteActivity({ activityId, campaignId, activityType }) {
  try {
    yield call(api.deleteActivity, activityType, activityId);
    yield put({ type: constants.DELETE_ACTIVITY_SUCCESS, activityId, campaignId });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['deleteActivity.Fail'] });
  }
}

export function* updateActivity({ activity }) {
  try {
    const activityObj = activity.toJS();
    activityObj.IsStatusChangeRequest = true; // tells the server to bring the data

    const data = yield call(api.saveActivity, activityObj);
    yield put({ type: constants.UPDATE_ACTIVITY_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['saveActivity.Fail'] });
  }
}

export function* UpdateActivityPin({ campaignId, activityId, activityType, pinningDate }){
  try {
    yield call(api.updateActivityPin, activityId, activityType, pinningDate);
    yield put({ type: constants.UPDATE_ACTIVITY_PINNED_DATE, campaignId, activityId, pinningDate });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['saveActivity.Fail'] });
  }
}

export function* updateCampaign({ campaign }) {
  try {
    // noinspection JSUnusedLocalSymbols
    const cleanCampaign = campaign.delete(constants.ACTIVITIES).toJS();
    const data = yield call(api.updateCampaign, cleanCampaign);
    yield put({ type: constants.UPDATE_CAMPAIGN_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['updateCampaign.Fail'] });
  }
}

export function* goToActivity({ activityType, activityHubId }) {
  yield put({ type: activityConstants.RESET_ACTIVITY_DATA });
  const hasBasicPlanPermissions = yield select(appSelectors.hasBasicPlanPermissions);

  let activityRoute = '';
  if (hasBasicPlanPermissions) {
    activityRoute = 'b/';
  }
  if (activityType === activityConstants.ACTIVITY_TYPE_RULE) {
    activityRoute += activityConstants.PATH_RULES;
  } else if (activityType === activityConstants.ACTIVITY_TYPE_DEAL) {
    activityRoute += activityConstants.PATH_DEALS;
  } else if (activityType === activityConstants.ACTIVITY_TYPE_ONE_TIME) {
    activityRoute += activityConstants.PATH_ONE_TIME;
  } else if (activityType === activityConstants.ACTIVITY_TYPE_PUNCH_CARD) {
    activityRoute += activityConstants.PATH_PUNCH_CARDS;
  } else if (activityType === activityConstants.ACTIVITY_TYPE_MICRO_CAMPAIGN) {
    activityRoute += activityConstants.PATH_MICRO_CAMPAIGNS;
  }
  yield put(push({
    pathname: `/${activityRoute}/${activityHubId}`,
    search: location.search,
  }));
}

export function* goToBusinessCenter() {
  const locationSearch = queryStringUtils.removeParameter(location.search, activityConstants.MIGRATION_ID);
  const hasBasicPlanPermissions = yield select(appSelectors.hasBasicPlanPermissions);
  yield put(push({
    pathname: hasBasicPlanPermissions ? routeConstants.BASIC_BUSINESS_CENTER_ROUTE : routeConstants.BUSINESS_CENTER_ROUTE,
    search: locationSearch,
  }));
}

export function* goToUpgradePage({path}) {
  yield put(push({
    pathname: routeConstants.UPGRADE_ROUTE.replace(':id', path),
    search: location.search,
  }));
}

export function* activityMoveToCampaign({ activity, campaignId }) {
  try {
    const updatedActivity = activity.set(activityConstants.CAMPAIGN_KEY, campaignId).toJS();
    updatedActivity.IsStatusChangeRequest = true; // tells the server to bring the data

    const data = yield call(api.saveActivity, updatedActivity);
    yield put({
      type: constants.ACTIVITY_MOVE_TO_CAMPAIGN_SUCCESS,
      data,
      oldCampaignId: activity.get(activityConstants.CAMPAIGN_KEY)
    });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['saveActivity.Fail'] });
  }
}

function* updateScrollYPosition(yPos) {
  yield put({ type: constants.SCROLL_Y_POSITION_UPDATED, yPos });
}

function* businessCenterSaga() {
  yield [
    takeLatest(constants.GET_CAMPAIGNS, getCampaigns),
    takeLatest(constants.FETCH_CAMPAIGNS_WITH_ARCHIVE_ACTIVITIES, getCampaignsWithArchiveActivities),
    takeEvery(constants.CREATE_CAMPAIGN, createCampaign),
    takeEvery(constants.DELETE_CAMPAIGN, deleteCampaign),
    takeEvery(constants.GO_TO_ACTIVITY, goToActivity),
    takeEvery(constants.DELETE_ACTIVITY, deleteActivity),
    takeEvery(constants.UPDATE_CAMPAIGN, updateCampaign),
    takeEvery(constants.UPDATE_ACTIVITY, updateActivity),
    takeEvery(constants.UPDATE_ACTIVITY_PIN, UpdateActivityPin),
    takeEvery(constants.ACTIVITY_MOVE_TO_CAMPAIGN, activityMoveToCampaign),
    takeEvery(constants.GO_TO_BUSINESS_CENTER, goToBusinessCenter),
    takeEvery(constants.GO_TO_UPGRADE_PAGE, goToUpgradePage),
    takeLatest(constants.UPDATE_SCROLL_POSITION, updateScrollYPosition)
  ];
}

export default businessCenterSaga;
