import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {Dimmer, Loader, Grid} from "semantic-ui-react";
import injectSaga from '../../../utils/injectSaga';
import pointShopSaga from '../pointShop.saga';
import * as reducerConstants from '../../../constants/reducer.constants';
import * as pointShopActions from '../pointShop.actions';
import * as pointShopItemActions from '../../PointShopItem/pointShopItem.actions';
import withAuthorization from '../../../common/helpers/authorization';
import '../pointShop.styles.less';
import Modal from "../../../common/components/CustomModal/CustomModal";
import PointShopAnalysisRow from "./PointShopAnalysisRow";
import * as selectors from "../pointShop.selectors";
import * as activityConstants from '../../Activity/activity.constants';


class PointShopAnalysis extends React.PureComponent {

  static propTypes = {
    actions: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    pointShopItems: PropTypes.object,
    pointShopItemsAnalysis: PropTypes.object,
    getAnalysisInProgress: PropTypes.bool,
  };

  componentDidMount() {
    if (this.props.pointShopItems.size > 0) {
      this.props.actions.getPointShopAnalysis();
    }
  }

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line
    const { onClose, open } = this.props;
    const barMaxValue = this.props.pointShopItemsAnalysis ? Math.max(...(this.props.pointShopItemsAnalysis.toJSON().map((p) => p.appSourceCount + p.posSourceCount))) : 0;
    return (
      <Modal
        open={open}
        className="point-shop-analysis-modal"
        onClose={onClose}
        automationIdPrefix="point.shop.analysis"
        header={
          <div id={'point-shop-analysis-title'} className="type-and-name">
            <div>{formatMessage({ id: 'point-shop-analysis-title' })}</div>
          </div>
        }
        content={
          <Grid className="point-shop-analysis-grid">
            <Grid.Row className={"list-column-name"}>
              <Grid.Column width={5}>{formatMessage({ id: 'point-shop-analysis-page.item.name' })}</Grid.Column>
              <Grid.Column width={5}></Grid.Column>
              <Grid.Column width={2}>{formatMessage({ id: 'point-shop-analysis-page.app' })}</Grid.Column>
              <Grid.Column width={2}>{formatMessage({ id: 'point-shop-analysis-page.pos' })}</Grid.Column>
              <Grid.Column width={2}>{formatMessage({ id: 'point-shop-analysis-page.total' })}</Grid.Column>
            </Grid.Row>
            {!this.props.getAnalysisInProgress && this.props.pointShopItemsAnalysis.size > 0 && this.props.pointShopItemsAnalysis.sort((item1, item2) => {
              const item1Total = item1.get(activityConstants.POINT_SHOP_STATS_COUNT_TOTAL_SOURCE) ? item1.get(activityConstants.POINT_SHOP_STATS_COUNT_TOTAL_SOURCE) : 0;
              const item2Total = item2.get(activityConstants.POINT_SHOP_STATS_COUNT_TOTAL_SOURCE) ? item2.get(activityConstants.POINT_SHOP_STATS_COUNT_TOTAL_SOURCE) : 0;
              return item2Total - item1Total;
            }).map((item, index) => {
              return (
                <PointShopAnalysisRow barMaxValue={barMaxValue} key={index} item={item} rowIndex={index}/>
              );
            }, this)}
            <Dimmer inverted active={this.props.getAnalysisInProgress}>
              <Loader active/>
            </Dimmer>
          </Grid>}
        actions={[
          <div>
            <div style={{float: "left", fontStyle: "italic", color: "#A3A6B7"}}>*{formatMessage({id: 'punch.card.stats.hint'})}</div>
            <a role="button" key="gotIt" onClick={onClose}>{formatMessage({ id: 'general.button.got-it' })}</a>
          </div>
        ]}
        closeOnDimmerClick
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...pointShopActions, ...pointShopItemActions }, dispatch)
  };
}

const mapStateToProps = (state) => ({
  pointShopItemsAnalysis: selectors.getPointShopItemsAnalysis(state),
  getAnalysisInProgress: selectors.getPointShopAnalysisSuccess(state)
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.POINT_SHOP_BRANCH, saga: pointShopSaga });

export default compose(
  withConnect,
  withSaga,
  injectIntl,
  withAuthorization
)(PointShopAnalysis);
