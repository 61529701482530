import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

class ActivityStatistic extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currencySymbol: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isPercentage: PropTypes.bool,
  };

  parseValue(val) {
    if (typeof (val) === 'string') {
      return parseFloat(parseFloat(val).toFixed(1)).toLocaleString();
    }
    return parseFloat(val.toFixed(1)).toLocaleString();
  }

  renderValue(isNumber, defaultValue) {
    const { value, currencySymbol, isPercentage } = this.props;
    if (isPercentage) {
      if (!isNumber) {
        return defaultValue;
      }
      return this.parseValue(value * 100) + '%';
    }
    if (currencySymbol) {
      if (!isNumber) {
        return defaultValue;
      }
      return currencySymbol + this.parseValue(value);
    }
    if (isNumber) {
      return this.parseValue(value);
    }
    return defaultValue;
  }

  render() {
    const { label, value, defaultValue } = this.props;
    const isNumber = !isNaN(parseFloat(value));
    const defaultVal = defaultValue || 0;

    return (
      <Grid.Row>
        <Grid.Column width={10} className="statsTitle">
          <div
            data-automation-id="activity-stats.total.key"
          >{label}</div>
        </Grid.Column>
        <Grid.Column width={6}>
          <div
            data-automation-id="activity-stats.total.value"
          >{this.renderValue(isNumber, defaultVal)}</div>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

export default ActivityStatistic;

