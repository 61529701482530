import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import * as activityConstants from '../../Activity/activity.constants';
import ContextMenu from '../../../common/components/ContextMenu/ContextMenu';
import * as selectors from '../businessCenter.selectors';

class ActivityContextMenu extends React.PureComponent {
  static propTypes = {
    onChangeActivityStatus: PropTypes.func.isRequired,
    onDeleteActivity: PropTypes.func.isRequired,
    onMoveActivityTo: PropTypes.func.isRequired,
    onDetailsOpen: PropTypes.func.isRequired,
    onAnalysisOpen: PropTypes.func.isRequired,
    onOpenInNewTab: PropTypes.func.isRequired,
    activity: PropTypes.object.isRequired,
    campaignId: PropTypes.string.isRequired,
    hidden: PropTypes.bool,
    automationId: PropTypes.string
  };

  getMenuOptions() {
    const visibleContextMenuItems = selectors.getContextMenuItemsVisibilityMap(this.props.activity);
    const { formatMessage } = this.props.intl; //eslint-disable-line
    const options = [];

    if (visibleContextMenuItems[activityConstants.CONTEXT_MENU_OPTION_DETAILS]) {
      options.push({
        key: 'activity-details',
        iconName: 'como-ic-info',
        text: formatMessage({ id: 'business-center.campaign.activity.menu.activity-details' }),
        cb: this.props.onDetailsOpen
      });
    }

    if (visibleContextMenuItems[activityConstants.CONTEXT_MENU_OPTION_STATS]) {
      const activityType = this.props.activity.get(activityConstants.TYPE);
      options.push({
        key: `${this.props.activity.get(activityConstants.TYPE)}-stats`,
        iconName: 'como-ic-activity-analysis',
        text: formatMessage({ id: `${this.props.activity.get(activityConstants.TYPE)}-page.activity.menu.analysis` }),
        cb: this.props.onAnalysisOpen
      });
    }

    if (visibleContextMenuItems[activityConstants.CONTEXT_MENU_OPTION_MOVE_TO]) {
      options.push({
        key: 'move-to',
        iconName: 'como-ic-arrow-right',
        text: formatMessage({ id: 'business-center.campaign.activity.menu.move-to' }),
        cb: () => this.props.onMoveActivityTo(this.props.activity)
      });
    }

    if (visibleContextMenuItems[activityConstants.CONTEXT_MENU_OPTION_OPEN_IN_NEW_TAB]) {
      options.push({
        key: 'openInNewTab',
        className: 'benefit-action-list-item-set-up-key',
        iconName: 'como-ic-arrow-right',
        text: formatMessage({ id: 'business-center.campaign.activity.menu.openInNewTab' }),
        cb: () => this.props.onOpenInNewTab(this.props.activity)
      });
    }
    if (visibleContextMenuItems[activityConstants.CONTEXT_MENU_OPTION_ACTIVATE]) {
      options.push({
        key: 'activate',
        iconName: 'como-ic-ok',
        text: formatMessage({ id: 'business-center.campaign.activity.menu.activate' }),
        cb: () => this.props.onChangeActivityStatus(
          this.props.activity.set(activityConstants.STATUS, activityConstants.ACTIVITY_STATUS_ACTIVE))
      });
    }

    if (visibleContextMenuItems[activityConstants.CONTEXT_MENU_OPTION_DEACTIVATE]) {
      options.push({
        key: 'deactivate',
        iconName: 'como-ic-deactivate',
        text: formatMessage({ id: 'business-center.campaign.activity.menu.stop' }),
        cb: () => this.props.onChangeActivityStatus(
          this.props.activity.set(activityConstants.STATUS, activityConstants.ACTIVITY_STATUS_INACTIVE))
      });
    }

    if (visibleContextMenuItems[activityConstants.CONTEXT_MENU_OPTION_DELETE]) {
      options.push({
        key: 'delete',
        iconName: 'como-ic-delete',
        text: formatMessage({ id: 'business-center.campaign.activity.menu.delete' }),
        cb: () => this.props.onDeleteActivity(
          this.props.campaignId,
          this.props.activity.get(activityConstants.TYPE),
          this.props.activity.get(activityConstants.HUB_ID)
        )
      });
    }

    return options;
  }

  render() {
    return (<ContextMenu
      options={this.getMenuOptions()}
      automationId={this.props.automationId}
      hidden={this.props.hidden}
    />);
  }
}

export default injectIntl(ActivityContextMenu);
