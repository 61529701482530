import React from 'react';
import PropTypes from 'prop-types';
import {Button, Dimmer, Loader} from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import Modal from '../CustomModal/CustomModal';
import Dropdown from '../DropdownWrapper/DropdownWrapper';

class ChooseOption extends React.PureComponent {

  static propTypes = {
    options: PropTypes.object,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    isOpen: PropTypes.bool,
    optionType: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: props.isOpen || false,
      value: props.options ? props.options.getIn([0, 'value']) : null
    };
  }

  componentWillReceiveProps(nextProps) {
    const { isOpen, options } = this.props;
    if (isOpen !== nextProps.isOpen) {
      this.setState({ isModalOpen: nextProps.isOpen });
    }
    if (options !== nextProps.options) {
      this.setState({ value: nextProps.options ? nextProps.options.getIn([0, 'value']) : null });
    }
  }

  handleOnConfirm = () => {
    if (this.props.onConfirm) {
      this.props.onConfirm(this.state.value);
    }
    this.handleModalClose();
  };

  handleOnClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
    this.handleModalClose();
  };

  handleModalClose = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  handleOnSelect = (e, { value }) => {
    this.setState({ value });
  };

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line
    const { value } = this.state;
    const AUTOMATION_NAME = `choose-${this.props.optionType}`;

    return (
      <Modal
        className="activity-move-to--modal"
        header={formatMessage({ id: `activities.choose.${this.props.optionType}.header` })}
        onOpen={() => this.setState({ isModalOpen: true })}
        onClose={this.handleOnClose}
        content={
          <div>
            <Dimmer inverted active={!value}>
              <Loader active />
            </Dimmer>
            <Dropdown
              onSelectOption={this.handleOnSelect}
              placeholder={formatMessage({ id: `activities.choose.${this.props.optionType}.placeholder` })}
              selection
              options={this.props.options.toJS()}
              prefix={AUTOMATION_NAME}
              value={value}
              search={false}
            />
          </div>

        }
        open={this.state.isModalOpen}
        actions={[<Button key="choose" data-automation-id={`${AUTOMATION_NAME}.btn.choose`} onClick={this.handleOnConfirm}>
          {formatMessage({ id: 'general.button.ok' })}
        </Button>]}
        automationIdPrefix={AUTOMATION_NAME}
        size="tiny"
        closeOnDimmerClick
      />
    );
  }
}
export default injectIntl(ChooseOption);
