import React from 'react';
import { Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './Tooltip.styles.less';

class TooltipWrapper extends React.PureComponent {

  static propTypes = {
    className: PropTypes.string
  };

  render() {
    const { className, ...tooltipProps } = this.props;
    return (
      <Popup
        className={`tooltip ${className}`}
        basic
        hoverable
        {...tooltipProps}
      />
    );
  }
}

export default TooltipWrapper;
