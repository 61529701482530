/*
 *
 * App reducer
 *
 */

import { fromJS } from 'immutable';
import moment from 'moment';

import * as constants from './app.constants';

// this is used to build urls to hub_1 in a correct environment

const initialState = fromJS({
  [constants.INITIAL_APP_DATA_LOADED]: false,
  [constants.LIVE_HTTP_REQUESTS_COUNT]: 0,
  [constants.IS_LOADING]: false,

});

function appReducer(state = initialState, action) {

  let newState;

  switch (action.type) {

    case constants.USER_LOGOUT_SUCCESS: {
      window.location.replace(`${action.hub1Path}/dashboard/#/login`); // currently login is available only via hub1
      newState = initialState;
      break;
    }

    case constants.SET_HUB_1_PATH: {
      newState = state.set(constants.HUB_1_PATH, action.payload);
      break;
    }

    case constants.CHANGE_LOCALE_SUCCESS: {
      newState = state.set(constants.TRANSLATIONS, action.data).set(constants.SSR_TRANSLATIONS, action.ssrData).set(constants.LEGAL_SETTINGS, action.legalSettings).set(constants.LOCALE, action.locale);
      break;
    }

    case constants.SET_BROWSER_LOCALE_SETTINGS: {
      newState = state.set(constants.BROWSER_LOCALE_SETTINGS, fromJS(action.browserLocaleData));
      break;
    }

    case constants.CHANGE_LOCALE_ERROR: {
      newState = state;
      break;
    }

    case constants.GET_BUSINESS_SUCCESS: {
      newState = state.set(constants.BUSINESS, fromJS(action.data));
      break;
    }

    case constants.GET_BUSINESSES_SUCCESS: {
      newState = state
        .set(constants.BUSINESSES, action.data.businesses);
      break;
    }

    case constants.CONTACT_SUPPORT_SUCCESS: {
      newState = state
        .set(constants.CONTACT_CSM_SUPPORT, action.data);
      break;
    }

    case constants.CONTACT_SUPPORT_FAIL: {
      newState = state.set(constants.CONTACT_SUPPORT_ERROR_CODE, fromJS({
        status: 'failed',
        error: action.error
      }));
      break;
    }

    case constants.GET_BUSINESS_ERROR: {
      newState = state;
      break;
    }

    case constants.GET_BUSINESS_TIME_INFO_SUCCESS: {
      newState = state.set(constants.BUSINESS_DATE_TIME, action.response.businessDateTime)
                      .setIn([constants.BUSINESS, constants.TIME_ZONE], action.response.businessTimeZone);
      break;
    }

    case constants.GET_APPLICATION_DATA_SUCCESS: {
      newState = state
        .set(constants.LOCATION_REGION_INFO, fromJS(action.data.locationRegionInfo))
        .set(constants.UNSUBSCRIBE_MESSAGE, fromJS(action.data.unsubscribeMessage))
        .set(constants.DEFAULT_CONSENT_CONTENT, fromJS(action.data.consentContent))
        .set(constants.LANGUAGES, fromJS(action.data.languages))
        .set(constants.DATE_FORMATS, fromJS(action.data.dateFormats))
        .set(constants.USER, fromJS(action.data.user))
        .set(constants.IS_COMO_USER, fromJS(action.data.user.isComoUser))
        .set(constants.USER_EMAIL, fromJS(action.data.user.email))
        .set(constants.USER_TOTP_OTP_PATH, fromJS(action.data.user.userTotpOtpPath))
        .set(constants.USER_LANG, fromJS(action.data.user.lang))
        .set(constants.BUSINESS, fromJS(action.data.business))
        .set(constants.BUSINESS_CSMs, fromJS(action.data.businessCsms))
        .set(constants.BUSINESS_SUPPORT_EMAILS, fromJS(action.data.supportEmails))
        .set(constants.DOMAINS, fromJS(action.data.domains))
        .set(constants.INITIAL_APP_DATA_LOADED, true)
        .set(constants.EXTERNAL_SERVICES_URLS, fromJS(action.data.externalServicesUrls))
        .set(constants.DEAL_SETTINGS, fromJS(action.data.dealSettings))
        .setIn([constants.BROWSER_LOCALE_SETTINGS, constants.DATE_FORMAT], action.data.dateFormats.filter((x) => x.key === action.data.user.dateFormatId)[0].dateFormat);

      break;
    }

    case constants.GET_FRANCHISES_DATA_SUCCESS: {
      newState = state
        .setIn([constants.FRANCHISES_DATA, constants.FRANCHISES], fromJS(action.data));
      break;
    }

    case constants.GET_MONTHLY_COMMUNICATIONS_LIMITATION_SUCCESS: {
      newState = state
        .setIn([constants.MONTHLY_SMS_MESSAGES_LIMITATION], action.data.smsMonthlyLimitations)
        .setIn([constants.MONTHLY_EMAIL_MESSAGES_LIMITATION], action.data.emailMonthlyLimitations);
      break;
    }

    case constants.GET_APPLICATION_DATA_ERROR: {
      newState = state;
      break;
    }

    case constants.GET_APPLICATION_DATA_FAIL: {
      newState = state.set(constants.GET_APPLICATION_DATA_ERROR_CODE, 500);
      break;
    }

    case constants.DEFAULT_ACTION: {
      newState = state;
      break;
    }

    case constants.INCREASE_AJAX_REQUESTS_COUNTER: {
      newState = state.set(constants.LIVE_HTTP_REQUESTS_COUNT, state.get(constants.LIVE_HTTP_REQUESTS_COUNT) + 1);
      break;
    }

    case constants.DECREASE_AJAX_REQUESTS_COUNTER: {
      newState = state.set(constants.LIVE_HTTP_REQUESTS_COUNT, state.get(constants.LIVE_HTTP_REQUESTS_COUNT) - 1);
      break;
    }

    case constants.SET_LOADING_STATE: {
      newState = (state.get(constants.IS_LOADING) === action[constants.IS_LOADING]) ? state : state.set(constants.IS_LOADING, fromJS(action[constants.IS_LOADING]));
      break;
    }

    case constants.GET_LOCATIONS_SUCCESS: {
      newState = state.set(constants.LOCATIONS, fromJS(action.data.locations)).set(constants.LOCATIONS_GROUPS, fromJS(action.data.locationsGroups));
      break;
    }

    case constants.GET_FEATURES_SUCCESS: {
      newState = state.set(constants.FEATURES, fromJS(action.data));
      break;
    }

    case constants.SET_CUSTOMER_MEMBERSHIP_KEY: {
      newState = state.setIn([constants.CUSTOMER, constants.MEMBERSHIP_KEY], action.data);
      break;
    }

    case constants.SET_CUSTOMER_DATA_LOADING: {
      newState = state.setIn([constants.CUSTOMER, constants.CUSTOMER_DATA_LOCADING], action.data);
      break;
    }

    case constants.USER_UPDATE_SUCCESS: {
      newState = state.set(constants.USER, fromJS(action.data));
      break;
    }

    case constants.SET_DEMO_MODE_SUCCESS: {
      newState = state.set(constants.USER, fromJS(action.data));
      break;
    }

    case constants.USER_UPDATE_EMAIL: {
      newState = state.set(constants.USER_EMAIL, fromJS(action.data));
      break;
    }

    case constants.USER_UPDATE_LANG: {
      newState = state.set(constants.USER_LANG, fromJS(action.data));
      break;
    }

    case constants.GET_VERIFICATION_CODE_SUCCESS: {
      newState = state.set(constants.VERIFICATION_CODE, fromJS(action.data));
      break;
    }

    case constants.GET_APP_CLIENT_ID_SUCCESS: {
      newState = state.set(constants.VERIFICATION_CODE, fromJS(action.data));
      break;
    }

    case constants.CHANGE_PASSWORD_SUCCESS: {
      newState = state.set(constants.CHANGE_USER_PASSWORD, fromJS(action.result));
      break;
    }

    case constants.CHANGE_PASSWORD_ERROR: {
      newState = state.set(constants.CHANGE_USER_PASSWORD, fromJS(action.result));
      break;
    }

    case constants.GET_REGISTRATION_FIELDS_SUCCESS: {
      newState = state.set(constants.REGISTRATION_FIELDS, fromJS(action.data));
      break;
    }

    case constants.CREATE_MEMBER_SUCCESS: {
      newState = state.set(constants.NEW_MEMBER, fromJS(action.data));
      break;
    }

    case constants.CANCEL_3RD_PARTY_CODE_SUCCESS: {
      newState = state.set(constants.CANCEL_3RD_PARTY_CODE_STATUS, fromJS({
        data: action.data,
        status: 'success',
        error: null
      }));
      break;
    }

    case constants.CANCEL_3RD_PARTY_CODE_FAIL: {
      newState = state.set(constants.CANCEL_3RD_PARTY_CODE_STATUS, fromJS({
        data: null,
        status: 'failed',
        error: action.err
      }));
      break;
    }

    case constants.CANCEL_3RD_PARTY_CLEAR_STATUS: {
      newState = state.set(constants.CANCEL_3RD_PARTY_CODE_STATUS, fromJS({
        data: null,
        status: 'initial',
        error: null
      }));
      break;
    }

    case constants.GET_CURRENT_SMS_PROVIDERS_SUCCESS: {
      newState = state
        .set(constants.CURRENT_SMS_OPERATIONAL_PROVIDER, action.data.operational)
        .set(constants.CURRENT_SMS_MARKETING_PROVIDER, action.data.marketing);
      break;
    }

    case constants.GET_TAGS_SUCCESS: {
      newState = state;
      if (action.data.length > 0) {
        newState = newState
          .setIn([constants.BUSINESS_TAGS, constants.TAGS], fromJS(action.data));
      }
      newState = newState
        .setIn([constants.BUSINESS_TAGS, constants.LAST_TAGS_RETRIEVAL_TIME], fromJS(moment().format()));
      break;
    }

    case constants.UPDATE_BUSINESS_DATE_FORMAT: {
      newState = state
        .setIn([constants.BROWSER_LOCALE_SETTINGS, constants.DATE_FORMAT], action.data);
      break;
    }

    default:
      newState = state;
  }
  return newState;
}

export default appReducer;
