import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import * as schemaConstants from '../../activitySchema/activitySchema.constants';
import ConditionListOfValues from '../conditions/conditionListOfValues';

class ActivityTags extends React.Component {

  static propTypes = {
    activityTags: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array
    ]),
    autoCompleteOptions: PropTypes.array,
    shouldActivityTagBeShown: PropTypes.bool,
    handleActivityTagChange: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    intl: PropTypes.object
  };

  get computedActivityTagsValue() {
    const activityTags = this.props.activityTags;
    if (activityTags && typeof activityTags.toJS === 'function') {
      return [...new Set(activityTags.toJS())];
    }
    return [...new Set(activityTags)];
  }

  get parsedAutoCompleteOptions() {
    const options = [];
    if (this.props.autoCompleteOptions && this.props.autoCompleteOptions.length > 0) {
      this.props.autoCompleteOptions.map((option) => {
        const thisOption = option.trim();
        if (options.filter((o) => o.key === thisOption).length === 0) {
          options.push({ key: thisOption, text: thisOption, value: thisOption });
        }
      });
    }

    const tagValue = this.computedActivityTagsValue;

    if (tagValue.length > 0) {
      tagValue.map((value) => options.push({ key: value, text: value, value }));
    }

    return options;
  }

  render() {
    const { formatMessage } = this.props.intl;

    return (
      <React.Fragment>
        {this.props.shouldActivityTagBeShown && (
          <div className="activity-tags activity-link">
            <ConditionListOfValues
              type={schemaConstants.VALUE_TYPE_AUTO_COMPLETE}
              value={this.computedActivityTagsValue}
              multiple
              allowAdditions
              dynamicOptions={this.parsedAutoCompleteOptions}
              className="auto-complete-wrapper"
              prefix={'activityTags'}
              onChange={(e, target) => this.props.handleActivityTagChange(e, target)}
              placeholder={formatMessage({ id: 'businessProfile.locations.field.external.field.tags' })}
              onOpen={this.props.onOpen}
              onClose={this.props.onClose}
            />
          </div>
        )}
      </React.Fragment>
    );
  }

}

export default injectIntl(ActivityTags);
