/**
 *
 * ImageGallery
 * Displays the business' images, allows selecting one
 * If allowAdditions flag is turned on, it will include the FileUpload component
 *
*/

import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';
import _ from 'lodash';
import './imageGallery.styles.less';
import CustomModal from '../../../../common/components/CustomModal/CustomModal';
import ImageGalleryContent from './ImageGalleryContent';
import injectSaga from '../../../../utils/injectSaga';
import saga from '../../images.saga';
import * as constants from '../../images.constants';
import * as imagesActions from '../../images.actions';
import { getBrowserLocaleDateFormat } from '../../../App/selectors';
import * as selectors from '../../images.selectors';
import * as reducerConstants from '../../../../constants/reducer.constants';

class ImageGallery extends React.PureComponent {

  static propTypes = {
    actions: PropTypes.object.isRequired,
    allowAdditions: PropTypes.bool,
    className: PropTypes.string,
    deleteInProgress: PropTypes.number,
    images: PropTypes.object,
    imageUploadInstructionsKey: PropTypes.string,
    intl: PropTypes.object,
    onApply: PropTypes.func,
    selectedId: PropTypes.number,
    triggerNode: PropTypes.node.isRequired,
    uploadInProgress: PropTypes.bool,
    defaultImage: PropTypes.object,
    defaultIfNull: PropTypes.bool
  };

  static defaultProps = {
    allowAdditions: true,
    defaultIfNull: false
  };

  state = {
    isModalOpen: false,
    selectedId: null
  };

  componentWillMount() {
    if (!this.props.images) {
      this.props.actions.getImagesBySize(constants.IMAGE_SIZE_THUMBNAIL);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.uploadInProgress && nextProps.uploadInProgress !== this.props.uploadInProgress) {
      this.props.actions.getImagesBySize(constants.IMAGE_SIZE_THUMBNAIL);
    }
    if (!nextProps.deleteInProgress && nextProps.deleteInProgress !== this.props.deleteInProgress) {
      this.props.actions.getImagesBySize(constants.IMAGE_SIZE_THUMBNAIL);
    }
  }

  onApply = (e) => {
    this.props.onApply(e, { selectedId: this.state.selectedId });
    this.closeModal();
  };

  onCancel = () => {
    if (this.props.deleteInProgress || this.props.uploadInProgress) {
      return;
    }
    this.closeModal();
  };

  onDelete = (e, image) => {
    this.props.actions.deleteImage(image);
  };

  onSelect = (e, image) => {
    this.setState({ selectedId: image.get(constants.ID) });
  };

  onDoubleClick = (e, image) => {
    this.onSelect(e, image);
    this.onApply(e);
  };

  openModal = () => {
    this.setState({
      isModalOpen: true,
      selectedId: this.props.selectedId,
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
      selectedId: null,
    });
  };

  render() {

    const { className, intl, selectedId, triggerNode, ...restProps } = this.props;
    const { formatMessage } = intl;
    const automationIdPrefix = 'imageGallery';

    return (
      <CustomModal
        actions={[
          <a
            key="cancel"
            role="button"
            data-automation-id={`${automationIdPrefix}.cancel`}
            onClick={this.onCancel}
            tabIndex={0}
            className={`modal-cancel-link ${this.props.deleteInProgress || this.props.uploadInProgress ? 'disabled' : ''}`}
          >
            {formatMessage({ id: 'general.button.cancel', defaultMessage: 'Cancel' })}
          </a>,
          <Button
            key="save"
            data-automation-id={`${automationIdPrefix}.save`}
            onClick={this.onApply}
            disabled={this.props.uploadInProgress || !_.isNil(this.props.deleteInProgress)}
          >
            {formatMessage({ id: 'general.button.save' })}
          </Button>
        ]}
        automationIdPrefix={automationIdPrefix}
        className={`image-gallery-modal ${className || ''}`}
        content={(
          <ImageGalleryContent
            automationIdPrefix={automationIdPrefix}
            imageUploadInstructionsKey={this.props.imageUploadInstructionsKey}
            onSelect={this.onSelect}
            onDelete={this.onDelete}
            onDoubleClick={this.onDoubleClick}
            selectedId={this.state.selectedId}
            {...restProps}
          />)}
        header={formatMessage({ id: 'imageGallery.header' })}
        onClose={this.closeModal}
        onOpen={this.openModal}
        open={this.state.isModalOpen}
        trigger={triggerNode}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  images: selectors.getAllActiveImageThumbnails(state),
  localeDateFormat: getBrowserLocaleDateFormat(state),
  uploadInProgress: selectors.uploadInProgressSelector(state),
  deleteInProgress: selectors.deleteInProgressSelector(state),
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(imagesActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.IMAGES_BRANCH, saga });

export default compose(
  withConnect,
  withSaga,
  injectIntl
)(ImageGallery);
