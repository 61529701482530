import 'moment-timezone';
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { fromJS } from 'immutable';
import { Grid, Header } from 'semantic-ui-react';

import * as activityActions from '../../../activity.actions';
import * as activitySelectors from '../../../activity.selectors';
import PromoCodeSingleCodeBulk from '../../promoCodeBulks/PromoCodeSingleCodeBulk';
import ImportCodesBulkFile from '../../promoCodeBulks/PromoCodesImportCodesBulkFile';
import * as activityConstants from '../../../activity.constants';

class PromoCodesSingleCodeTab extends React.PureComponent {

  static propTypes = {
    singleBulks: PropTypes.object,
    fileUploadedBulks: PropTypes.object,
    errorMessageSingleBulk: PropTypes.string,
    errorMessageUploadedFileBulk: PropTypes.object,
    actions: PropTypes.object,
    importMembershipGlobalCondition: PropTypes.object,
    fileUploadInProgress: PropTypes.bool,
    bulkMode: PropTypes.string,
    codeChangeDisabled: PropTypes.bool
  };


  get isUploadInProgress() {
    return this.props.importMembershipGlobalCondition
      ? this.props.importMembershipGlobalCondition.getIn([activityConstants.IMPORT_MEMBERS_UPLOAD_FILE_MODE, activityConstants.UPLOAD_MEMBERS_FILE_IN_PROGRESS])
      : false;
  }

  render() {
    const { formatMessage } = this.props.intl;
    if (!this.props.singleBulks && !this.props.fileUploadedBulks) {
      return null;
    }
    const firstSingleBulk = this.props.singleBulks.get(0) || fromJS({});
    const firstUploadedFileBulk = this.props.fileUploadedBulks.get(0) || fromJS({});
    return (
      <Grid className="promoCode-bulks">
        <Grid.Row>
          <Grid.Column width={14} className="left-label">
            <Header>{formatMessage({ id: 'promoCode.displayTab.fixCode.title' })}</Header>
            <p className="hint-tip-label">
              {formatMessage({ id: 'promoCode.displayTab.explained' })}
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={14}>
            <div className="promoCode-bulks-card">
              <PromoCodeSingleCodeBulk
                bulk={firstSingleBulk}
                errorMessage={this.props.errorMessageSingleBulk ? this.props.errorMessageSingleBulk.get('0') : null}
                onUpdate={(value) => this.props.actions.updatePromoCodeSingleCodeValue(value, 0)}
                automationIdPrefix={'promo-code-bulks.0.'}
                selected={this.props.bulkMode === activityConstants.PROMO_CODE_MODE_SINGLE_CODE}
                onModeCLick={() => this.props.actions.updatePromoCodesMode(activityConstants.PROMO_CODE_MODE_SINGLE_CODE)}
                disabled={this.props.codeChangeDisabled}
                initializeSingleCode={this.props.actions.setInitialPromoCodeSingleBulkValue}
                checkCodeIsAvailable={this.props.actions.checkCodeIsAvailable}
                isTakenFlag={this.props.isTakenFlag}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}


const mapStateToProps = (state) => ({
  singleBulks: activitySelectors.getPromoCodeBulksSingle(state),
  fileUploadedBulks: activitySelectors.getPromoCodeBulksFileUploaded(state),
  errorMessageSingleBulk: activitySelectors.getPromoCodeBulksSingleCodeValidationErrors(state),
  errorMessageUploadedFileBulk: activitySelectors.getPromoCodeBulksFileUploadedValidationErrors(state),
  fileUploadInProgress: activitySelectors.getPromoCodesFileUploadProgress(state),
  bulkMode: activitySelectors.getPromoCodeBulkMode(state),
  isTakenFlag: activitySelectors.getPromoCodeTakenFlag(state)
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(activityActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(injectIntl(PromoCodesSingleCodeTab));
