import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';

import MentionTextArea from '../../../../../../common/components/MentionableTextArea/MentionTextArea';
import * as constants from '../../../../activity.constants';
import GenericAction from '../../../../../GenericAction/views/genericAction';
import CheckBox from '../../../../../../common/components/FormInputFields/CheckboxWrapper/CheckboxWrapper';
import IntegerInputField from '../../../../../../common/components/FormInputFields/IntegerInputField/IntegerInputField';
import ValidationErrorMessage from '../../../../../../common/components/ValidationErrorMessage/ValidationErrorMessage';
import './promptInAppMessage.styles.less';

class PromptInAppMessage extends React.PureComponent {

  static propTypes = {
    action: PropTypes.object,
    assetContext: PropTypes.string,
    automationIdPrefix: PropTypes.string,
    index: PropTypes.number,
    intl: PropTypes.object.isRequired,
    mentionsList: PropTypes.object,
    onActionFieldUpdate: PropTypes.func.isRequired,
    validationErrors: PropTypes.object,
  };

  get triggerCheckboxes() {
    const { action, onActionFieldUpdate, automationIdPrefix, index } = this.props;
    const { formatMessage } = this.props.intl;
    const triggersAndTranslations = {
      [constants.MESSAGE_TRIGGER_BY_OPENING_THE_APP]: 'activity.in.app.message.trigger.opening.the.app',
      [constants.MESSAGE_TRIGGER_BY_NAVIGATION_TO_HOME_SCREEN]: 'activity.in.app.message.trigger.navigation.to.home.screen',
      [constants.MESSAGE_TRIGGER_BY_AFTER_REDEEM]: 'activity.in.app.message.trigger.after.redeem',
      [constants.MESSAGE_TRIGGER_BY_AFTER_PUNCH]: 'activity.in.app.message.trigger.after.punch',
      [constants.MESSAGE_TRIGGER_BY_AFTER_COUPON_CODE_ACTION]: 'activity.in.app.message.trigger.after.coupon.code.action'
    };
    return (
      <ul className="activity-in-app-message-trigger-options">
        {
          Object.keys(triggersAndTranslations).map((triggerName) =>
            (
              <li>
                <CheckBox
                  checked={action.get(triggerName)}
                  data-automation-id={`${automationIdPrefix}.${triggerName}`}
                  key={triggerName}
                  label={formatMessage({ id: triggersAndTranslations[triggerName] })}
                  name={triggerName}
                  onChange={(e, data) => onActionFieldUpdate(triggerName, data.checked, index)}
                />
              </li>
            )
          )
        }
      </ul>
    );
  }

  render() {
    const { action, assetContext, automationIdPrefix, index, mentionsList, onActionFieldUpdate, validationErrors } = this.props;
    const triggerNoneSelectedError = validationErrors && validationErrors.get(constants.MESSAGE_TRIGGER_BY);
    const { formatMessage } = this.props.intl;
    return (
      <Grid className="activity-communication-action">
        <Grid.Row>
          <Grid.Column
            className="activity-in-app-message-triggers"
            width={10}
          >
            {formatMessage({ id: 'activity.in.app.message.trigger' })}
            {triggerNoneSelectedError && (
              <ValidationErrorMessage
                errorMessage={formatMessage({ id: triggerNoneSelectedError })}
                dataAutomationId={`${this.props.automationIdPrefix}.error.message`}
              />)}
            {this.triggerCheckboxes}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            {
              mentionsList
                ? (
                  <div>
                    <p className="action-title">
                      {formatMessage({ id: 'activity.generic.action.messageContent' })}
                    </p>
                    <MentionTextArea
                      automationIdPrefix={`${automationIdPrefix}.${constants.POPUP_SUBJECT}`}
                      mentionOptions={mentionsList || []}
                      message={action.get(constants.POPUP_SUBJECT)}
                      placeholder={formatMessage({ id: 'activity.in.app.message.subject.placeholder' })}
                      onTextUpdate={(text) => onActionFieldUpdate(constants.POPUP_SUBJECT, text, index)}
                      index={index}
                      error={validationErrors ? validationErrors.get(constants.POPUP_SUBJECT) : null}
                      singleLine
                      hideTips
                    />
                    <MentionTextArea
                      automationIdPrefix={`${automationIdPrefix}.${constants.POPUP_MESSAGE}`}
                      mentionOptions={mentionsList || []}
                      message={action.get(constants.POPUP_MESSAGE)}
                      onTextUpdate={(text) => onActionFieldUpdate(constants.POPUP_MESSAGE, text, index)}
                      index={index}
                      placeholder={formatMessage({ id: 'activity.in.app.message.body.placeholder' })}
                      error={validationErrors ? validationErrors.get(constants.POPUP_MESSAGE) : null}
                    />
                  </div>
                )
                : null
            }
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <p className="action-title">
              {formatMessage({ id: 'activity.push.action.button.config.label' })}
            </p>
            <GenericAction
              actionData={action.get(constants.GENERIC_ACTION_ARGS)}
              assetContext={assetContext}
              automationPrefix={`${automationIdPrefix}.${constants.GENERIC_ACTION_ARGS}`}
              doNothingLabelStringId="activity.generic.action.button.actions.confirmation.only"
              index={index}
              onUpdate={(newValue) => onActionFieldUpdate(constants.GENERIC_ACTION_ARGS, newValue, index)}
              validationErrors={validationErrors ? validationErrors.get(constants.GENERIC_ACTION_ARGS) : null}
            >
            </GenericAction>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <p className="action-title">
              {formatMessage({ id: 'activity.generic.action.generalSettings' })}
            </p>
            <p>
              <span>{formatMessage({ id: 'activity.in.app.message.will.not.be.presented.after' })}</span>
              <span className="message-max-age-in-days">
                <IntegerInputField
                  allowOnlyPositive
                  id={constants.MESSAGE_MAX_AGE_IN_DAYS}
                  name={constants.MESSAGE_MAX_AGE_IN_DAYS}
                  onChange={(e, data) => onActionFieldUpdate(constants.MESSAGE_MAX_AGE_IN_DAYS, data.value, index)}
                  value={action.get(constants.MESSAGE_MAX_AGE_IN_DAYS)}
                  prefix={`${automationIdPrefix}.${constants.MESSAGE_MAX_AGE_IN_DAYS}`}
                  error={validationErrors ? validationErrors.get(constants.MESSAGE_MAX_AGE_IN_DAYS) : null}
                  fluid
                />
              </span>
              <span>{formatMessage({ id: 'global.time.duration.days.lowercase' })}</span>
            </p>
            <CheckBox
              name={constants.SAVE_MESSAGE_TO_INBOX}
              checked={action.get(constants.SAVE_MESSAGE_TO_INBOX)}
              data-automation-id={`${automationIdPrefix}.${constants.SAVE_MESSAGE_TO_INBOX}`}
              onChange={(e, data) => onActionFieldUpdate(constants.SAVE_MESSAGE_TO_INBOX, data.checked, index)}
              label={formatMessage({ id: 'activity.save.message.to.inbox.checkbox' })}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default injectIntl(PromptInAppMessage);
