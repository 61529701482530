import { fromJS } from 'immutable';
import * as constants from './promoCodes.constants';
import * as activityConstants from '../Activity/activity.constants';
import * as utils from './utils/promoCodes.reducer.utils';

const initialState = fromJS({
  [constants.FILTERS]: {
    [constants.PROMO_CODES]: {
      [activityConstants.FILTERS_ACTIVITY_ACTIVE]: true,
      [activityConstants.FILTERS_ACTIVITY_COMPLETED]: false,
      [activityConstants.FILTERS_ACTIVITY_PAUSED]: true,
      [activityConstants.FILTERS_ACTIVITY_STOPPED]: false,
      [activityConstants.FILTERS_ACTIVITY_FUTURE]: true,
      [activityConstants.FILTERS_ACTIVITY_DRAFT]: true
    },
  },
  [constants.SORT]: {
    [constants.ORDER]: constants.ORDER_DESCENDING
  },
  [constants.PROMO_CODES]: [],
  [constants.BULK_CODES_LIST]: [],
  [constants.BULK_EXPORT_LOADING_STATUSES]: [],
  [constants.PROMO_CODES_LOADED]: false
});

export default function promoCodesReducer(state = initialState, action) {

  switch (action.type) {

    case constants.GET_PROMO_CODES: {
      return state.set(constants.PROMO_CODES_LOADED, false);
    }

    case constants.GET_PROMO_CODES_SUCCESS: {
      return state
        .set(constants.PROMO_CODES, fromJS(action.data))
        .set(constants.PROMO_CODES_LOADED, true);
    }

    case constants.SET_PROMO_CODES_LOADED: {
      return state.set(constants.PROMO_CODES_LOADED, action.isLoaded);
    }

    case constants.UPDATE_FILTERS: {
      const { status, key, groupKey } = action;
      return state.setIn([constants.FILTERS, groupKey, key], status);
    }

    case constants.UPDATE_ORDER: {
      return state
        .setIn([constants.SORT, constants.ORDER],
          state.getIn([constants.SORT, constants.ORDER]) === constants.ORDER_DESCENDING ?
            constants.ORDER_ASCENDING : constants.ORDER_DESCENDING
        );
    }

    case constants.HIGHLIGHT_ACTIVITY: {
      if (action[activityConstants.HIGHLIGHTED_ACTIVITY_ID]) {
        return state.set(activityConstants.HIGHLIGHTED_ACTIVITY_ID, action[activityConstants.HIGHLIGHTED_ACTIVITY_ID]);
      }
      return state.delete(activityConstants.HIGHLIGHTED_ACTIVITY_ID);
    }

    case constants.SEARCH_PROMO_CODES: {
      return state.set(constants.SEARCH_TERM, action[constants.SEARCH_TERM]);
    }

    case constants.UPDATE_PROMO_CODE_SUCCESS: {
      const newPromoCode = action.data;
      const newPromoCodeIndex = state.get(constants.PROMO_CODES).findIndex((promoCode) =>
        promoCode.get(activityConstants.HUB_ID) === newPromoCode[activityConstants.HUB_ID]);
      return state.setIn([constants.PROMO_CODES, newPromoCodeIndex], fromJS(newPromoCode));
    }

    case constants.DELETE_PROMO_CODE_SUCCESS: {
      const filteredPromoCodesList = utils.removePromoCodeFromList(state.get(constants.PROMO_CODES), action[activityConstants.HUB_ID]);
      return state.set(constants.PROMO_CODES, filteredPromoCodesList);
    }

    case constants.GET_PROMO_CODE_STATS_SUCCESS: {
      const stats = action.data;
      return state.set(constants.PROMO_CODES_STATS, fromJS(stats));
    }

    case constants.CLEAN_PROMO_CODE_STATS: {
      return state.delete(constants.PROMO_CODES_STATS);
    }

    case constants.GET_BULK_CODES_LIST_SUCCESS: {
      const data = action.data;
      return state
        .set(constants.BULK_CODES_LIST, fromJS(data))
        .set(constants.BULK_CODES_LIST_MODAL_VISIBILITY_STATUS, true);
    }

    case constants.HANDLE_BULK_LIST_MODAL_VISIBILITY: {
      return state.set(constants.BULK_CODES_LIST_MODAL_VISIBILITY_STATUS, action.show);
    }

    case constants.CLOSE_BULK_LIST_MODAL: {
      return state
        .set(constants.BULK_CODES_LIST_MODAL_VISIBILITY_STATUS, false)
        .set(constants.BULK_CODES_LIST, [])
        .set(constants.BULK_EXPORT_LOADING_STATUSES, []);
    }

    default:
      return state;
  }

}
