import { takeEvery, takeLatest, call, put, select } from 'redux-saga/effects';
import { actions as toastrActions } from '../../../common/components/ReduxToast';
import * as api from '../goals.api';
import * as constantsTypes from './constants/types';
import * as appActions from '../../App/app.actions';
import * as activityTemplatesApi from '../../Templates/templates.api';
import * as adminSelectors from './admin.selectors';
import * as errorConstant from '../../ErrorMessage/errorMessage.constants';
import * as goalConstants from '../goals.constants';

function* getDealsTemplates() {
  try {
    yield put(appActions.setLoadingState(true));
    const data = yield call(activityTemplatesApi.getAllDealsProductTemplates);
    yield put({ type: constantsTypes.GET_DEALS_PRODUCT_TEMPLATES_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getProductTemplates.Fail'] });
  } finally {
    yield put(appActions.setLoadingState(false));
  }
}

function* getRulesTemplates() {
  try {
    yield put(appActions.setLoadingState(true));
    const data = yield call(activityTemplatesApi.getAllRulesProductTemplates);
    yield put({ type: constantsTypes.GET_RULES_PRODUCT_TEMPLATES_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getProductTemplates.Fail'] });
  } finally {
    yield put(appActions.setLoadingState(false));
  }
}

function* getOneTimeActionsTemplates() {
  try {
    yield put(appActions.setLoadingState(true));
    const data = yield call(activityTemplatesApi.getAllOneTimeActionsProductTemplates);
    yield put({ type: constantsTypes.GET_ONE_TIME_ACTIONS_PRODUCT_TEMPLATES_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getProductTemplates.Fail'] });
  } finally {
    yield put(appActions.setLoadingState(false));
  }
}

function* getPunchCardsTemplates() {
  try {
    yield put(appActions.setLoadingState(true));
    const data = yield call(activityTemplatesApi.getAllPunchCardsProductTemplates);
    yield put({ type: constantsTypes.GET_PUNCH_CARDS_PRODUCT_TEMPLATES_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getProductTemplates.Fail'] });
  } finally {
    yield put(appActions.setLoadingState(false));
  }
}

function successToaster(message) {
  return toastrActions.add({
    message,
    options: {
      showCloseButton: true,
      className: 'simple-hub2-toastr-notification',
      removeOnHover: true
    }
  });
}

function* createGoal() {
  try {
    yield put(appActions.setLoadingState(true));
    const newGoal = yield select(adminSelectors.getNewGoalNotImmutable);
    const goal = newGoal;
    goal.tools = enrichTools(goal.tools);

    const data = yield call(api.createGoal, goal);
    yield put({ type: constantsTypes.CREATE_GOAL_SUCCESS });
    yield put(successToaster('Goal has been successfully created'));
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['createGoalByAdmin.Fail'] });
  } finally {
    yield put(appActions.setLoadingState(false));
  }
}

function enrichTools(tools) {
  return tools.map((tool) => {
    const { name, description, templatesDealsPointers, templatesRulesPointers, templatesOneTimeActionsPointers } = tool;
    const params = { name, description };

    if (Array.isArray(templatesDealsPointers) && templatesDealsPointers.length > 0) {
      params.templatesDealsPointers = templatesDealsPointers;
    }

    if (Array.isArray(templatesRulesPointers) && templatesRulesPointers.length > 0) {
      params.templatesRulesPointers = templatesRulesPointers;
    }

    if (Array.isArray(templatesOneTimeActionsPointers) && templatesOneTimeActionsPointers.length > 0) {
      params.templatesOneTimeActionsPointers = templatesOneTimeActionsPointers;
    }
    return params;

  });
}

function* getGoals() {
  try {
    yield put(appActions.setLoadingState(true));

    const data = yield call(api.getGoals);
    const enrichedData = data.map((item) => {
      const tools = item[goalConstants.GOAL_TOOLS];
      item[goalConstants.GOAL_TOOLS] = tools
        .map((tool) => {
          tool[goalConstants.GOAL_TOOL_TEMPLATES_RULES_POINTERS] = tool[goalConstants.GOAL_TOOL_TEMPLATES_RULES_POINTERS] || [];
          tool[goalConstants.GOAL_TOOL_TEMPLATES_DEALS_POINTERS] = tool[goalConstants.GOAL_TOOL_TEMPLATES_DEALS_POINTERS] || [];
          tool[goalConstants.GOAL_TOOL_TEMPLATES_ONETIME_POINTERS] = tool[goalConstants.GOAL_TOOL_TEMPLATES_ONETIME_POINTERS] || [];
          return tool;
        });

      return item;
    });

    yield put({ type: constantsTypes.GET_GOALS_SUCCESS, data: enrichedData });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getGoalsByAdmin.Fail'] });
  } finally {
    yield put(appActions.setLoadingState(false));
  }
}

function* updateGoal() {
  try {
    yield put(appActions.setLoadingState(true));

    const newGoal = yield select(adminSelectors.getNewGoalNotImmutable);
    const goal = newGoal;
    goal.tools = enrichTools(goal.tools);
    const data = yield call(api.updateGoal, goal);
    yield put({ type: constantsTypes.UPDATE_GOAL_SUCCESS, data });
    yield put(successToaster('Goal has been successfully updated'));
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['updateGoalByAdmin.Fail'] });
  } finally {
    yield put(appActions.setLoadingState(false));
  }
}

function* goalsSaga() {
  yield [
    takeLatest(constantsTypes.GET_DEALS_PRODUCT_TEMPLATES, getDealsTemplates),
    takeLatest(constantsTypes.GET_RULES_PRODUCT_TEMPLATES, getRulesTemplates),
    takeLatest(constantsTypes.GET_ONE_TIME_ACTIONS_PRODUCT_TEMPLATES, getOneTimeActionsTemplates),
    takeLatest(constantsTypes.GET_PUNCH_CARDS_PRODUCT_TEMPLATES, getPunchCardsTemplates),
    takeLatest(constantsTypes.GET_GOALS, getGoals),
    takeEvery(constantsTypes.UPDATE_GOAL, updateGoal),
    takeEvery(constantsTypes.CREATE_GOAL, createGoal)
  ];
}

export default goalsSaga;
