import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BeePlugin from 'bee-plugin';
import { injectIntl } from 'react-intl';
import { bindActionCreators, compose } from 'redux';
import { Grid, Loader } from 'semantic-ui-react';
import classNames from 'classnames';

import * as emailActions from './email.actions';
import '../Activity/activity.styles.less';
import './email.styles.less';
import * as mentionsSelectors from '../Activity/activitySchema/mentions.selectos';
import InputWrapper from '../../common/components/FormInputFields/InputWrapper/InputWrapper';
import * as constants from './email.constants';
import EntityHeader from '../../common/components/EntityHeader/EntityHeader';
import * as selectors from './email.selectors';
import injectSaga from '../../utils/injectSaga';
import saga from './email.saga';
import * as appSelectors from '../App/selectors';
import * as featuresConstants from '../features.constants';
import * as reducerConstants from '../../constants/reducer.constants';
import mailTemplatesSaga from '../MailTemplates/mailTemplates.saga';
import * as mailTemplatesActions from '../MailTemplates/mailTemplates.actions';
import MentionTextArea from '../../common/components/MentionableTextArea/MentionTextArea';
import TemplatesMenu from '../Templates/templatesMenu/TemplatesMenu';
import * as activityConstants from '../Activity/activity.constants';
import * as templatesActions from '../Templates/templates.actions';
import HeaderNotification from '../Activity/views/activityHeader/HeaderNotification';
import * as activitySelectors from '../Activity/activity.selectors';
import * as activityActions from '../Activity/activity.actions';
import businessSaga from '../BusinessCenter/businessCenter.saga';
import withAuthorization from '../../common/helpers/authorization';
import Settings from './Settings';
import * as formsSelectors from '../Forms/forms.selectors';
import formsSaga from '../Forms/forms.saga';
import * as formsActions from '../Forms/forms.actions';

export class HubEmail extends React.PureComponent {
  static propTypes = {
    mentions: PropTypes.object,
    email: PropTypes.object,
    validationErrors: PropTypes.object,
    actions: PropTypes.object,
    match: PropTypes.object.isRequired,
    token: PropTypes.string,
    checkingEmailNameAvailability: PropTypes.bool,
    isDirty: PropTypes.bool,
    publishInProgress: PropTypes.bool,
    uid: PropTypes.string,
    mailSenderName: PropTypes.string,
    mailTemplatesActions: PropTypes.object,
    templatesActions: PropTypes.object,
    activityActions: PropTypes.object,
    template: PropTypes.object,
    transformedMentions: PropTypes.object,
    tempOriginActivity: PropTypes.object,
    businessBundlePlan: PropTypes.string.isRequired,
    hasBasicPlanPermissions: PropTypes.bool.isRequired,
    registrationFormFields: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isHeaderShadowed: false,
      emailEditor: null,
      emailEditorStarted: true,
      loading: true,
      tokenInitiallyReceived: false,
      template: null,
      isNewTemplate: true
    };
  }

  componentWillMount() {
    this.setState({
      loading: true,
      tokenInitiallyReceived: false
    });

    if (!this.props.email.get(featuresConstants.DATA)) {
      if (this.props.match.params.id) {
        this.props.actions.getEmail(this.props.match.params.id);
      } else {
        this.props.actions.createEmail();
      }
    }

    if (!this.props.token) {
      this.props.actions.getBusinessEmailEditorCredentials();
    }

    if (!this.props.mailSenderName) {
      this.props.mailTemplatesActions.getMailSenderName();
    }

    if (!this.props.registrationFormFields) {
      this.props.actions.getRegistrationFormFields();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.token && nextProps.token) {
      this.setState({
        tokenInitiallyReceived: true
      });
    }
    setTimeout(() => {
      if (this.state.tokenInitiallyReceived && this.props.email && this.props.email.get(featuresConstants.DATA)) {
        this.startEditor(nextProps.token, nextProps.uid, this.props.email.get(featuresConstants.DATA));
      }
    });
    if (nextProps.validationErrors) {
      this.setState({
        loading: false
      });
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScrollView);
  }

  componentWillUnmount() {
    this.props.actions.clearEmailData();
    window.removeEventListener('scroll', this.handleScrollView);
  }

  startEditor = (token, uid, email) => {
    if (!token) {
      return;
    }
    const editor = new BeePlugin(JSON.parse(token));
    editor.start(this.getInitConfiguration(uid), JSON.parse(email.get(constants.EMAIL_CONTENT_JSON)));
    this.setState({
      emailEditor: editor,
      emailEditorStarted: true,
      tokenInitiallyReceived: false,
    });
  };

  get mentions() {
    return (this.props.mentions || []).map((mention) => ({
      name: mention.get('display'),
      value: `{{${mention.get('id')}}}`
    }));
  }

  cleanMentionsInString(str = '') {
    return str.replace(/{{@/g, '{{').replace(/@}}/g, '}}');
  }

  getInitConfiguration(uid) {
    return {
      uid, // needed for identify resources of the that user and billing stuff
      container: 'bee-plugin-container', // Identifies the id of div element that contains BEE Plugin
      // todo: check if languages support needed, bee has fallback to
      language: 'en-US',
      mergeTags: this.mentions,
      trackChanges: true,
      onSave: (jsonFile, htmlFile) => {
        this.saveEmail(this.cleanMentionsInString(jsonFile), this.cleanMentionsInString(htmlFile));
      },
      onChange: this.props.actions.markAsDirty,
      onSaveAsTemplate: (jsonFile) => {
        this.saveEmailAsTemplate(this.cleanMentionsInString(jsonFile));
      },
      onSend: (htmlFile) => {
        // console.log('onSend', htmlFile);
      },
      onError: (errorMessage) => {
        // console.log('onError ', errorMessage);
      },
      onLoad: () => {
        this.setState({ loading: false });
      }
    };
  }

  saveEmail = (jsonData, htmlData) => {
    const emailToSave = this.props.email
      .setIn([featuresConstants.DATA, constants.EMAIL_CONTENT_HTML], htmlData)
      .setIn([featuresConstants.DATA, constants.EMAIL_CONTENT_JSON], jsonData);
    this.props.actions.onEmailSave(emailToSave);
    this.setState({
      loading: false
    });
  };

  handleScrollView = () => {
    this.setState({
      isHeaderShadowed: window.scrollY > 0 && !this.props.validationErrors
    });
  };

  handleNameChange = (event) => {
    const name = event.target.value.trim();
    this.props.actions.updateEmailMetadata(constants.EMAIL_NAME, name);
    if (name && this.props.validationErrors) {
      this.props.actions.checkingEmailNameAvailability(
        this.props.email.getIn([featuresConstants.DATA, featuresConstants.HUB_ID]),
        name
      );
    }
  };

  saveEmailAsTemplate = (jsonData) => {
    const emailToSave = this.props.email.setIn([featuresConstants.DATA, constants.EMAIL_CONTENT_JSON], jsonData);
    const template = this.state.template || {};
    template.email = emailToSave.get(featuresConstants.DATA).toJS();
    delete template.entity;
    if (this.state.isNewTemplate) {
      this.props.templatesActions.saveAsBusinessTemplate(template, constants.EMAIL);
    } else {
      this.props.templatesActions.updateBusinessTemplate(template, constants.EMAIL);
    }
  };

  onEmailSaveAsTemplate(template, saveAsNew) {
    this.setState({
      template,
      isNewTemplate: saveAsNew
    }, this.state.emailEditor.saveAsTemplate());
  }

  get isCreatingEmailFromActivity() {
    return this.props.tempOriginActivity;
  }

  handleCancelOrBack = () => {
    if (this.props.tempOriginActivity) {
      this.props.activityActions.cancelCreateEmailFromActivity();
    } else {
      this.props.actions.goToEmailList(this.props.isDirty);
    }
  };

  render() {
    const email = this.props.email.get(featuresConstants.DATA);

    if (!email) {
      return null;
    }

    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const headerClassNames = classNames({ shadowed: this.state.isHeaderShadowed });
    const emailNameError = this.props.validationErrors ? this.props.validationErrors.getIn([constants.EMAIL_HEADER, constants.EMAIL_NAME]) : null;
    const nameClassNames = classNames({ 'inputFieldError': !!emailNameError });
    const senderClassName = classNames('email-input-field', !email.get(constants.FROM_HEADER) ? 'placeholder' : '');
    const subjectClassName = classNames('ui input email-input-field', !email.get(constants.SUBJECT) ? 'placeholder' : '');

    return (
      <div className="activity email form">
        {
          this.isCreatingEmailFromActivity &&
          <HeaderNotification
            activityName={this.props.tempOriginActivity.getIn([activityConstants.DATA, activityConstants.NAME])}
            activityType={activityConstants.RULE_PREFIX}
            automationIdPrefix={'email.createdFromActivity'}
            createdEntityType={this.props.tempOriginActivity.get(activityConstants.TYPE)}
          />
        }
        <EntityHeader
          headerClassName={headerClassNames}
          nameClassName={nameClassNames}
          namePlaceholder={formatMessage({ id: 'email.title.name.placeholder' })}
          isLoading={this.props.checkingEmailNameAvailability}
          name={email.get(constants.EMAIL_NAME)}
          nameValidationError={emailNameError}
          promptBeforeLeave={this.props.isDirty && !this.props.publishInProgress}
          canSaveAsDraft={false}
          publishButtonDisabled={this.props.publishInProgress || this.state.loading}
          publishButtonText={formatMessage({ id: 'email.header.publish' })}
          onSaveEntityClick={() => this.setState({ loading: true }, this.state.emailEditor.save())}
          hasTabs={false}
          handleNameChange={this.handleNameChange}
          onCancelOrBackClick={this.handleCancelOrBack}
          prefix={'activity'}
          showPreview
          onShowPreviewClick={() => this.state.emailEditor.preview()}
          isActivateAddon={false}
          businessBundlePlan={this.props.businessBundlePlan}
        />
        <div className="form activity-body">
          <TemplatesMenu
            permissionLevel={activityConstants.FEATURE_LEVEL.FULL}
            prefix={'email'}
            prefixNode={<Settings emailData={email} onChange={this.props.actions.onEmailFieldUpdate} />}
            template={this.props.template}
            showMenu
            entityType={constants.EMAIL}
            onEmailSaveAsTemplate={(template, saveAsNew) => this.onEmailSaveAsTemplate(template, saveAsNew)}
            hasBasicPlanPermissions={this.props.hasBasicPlanPermissions}
          />
          <Grid className="email form activity-body">
            <Grid.Row>
              <Grid.Column width={10}>
                <p className="action-label">{formatMessage({ id: 'email.from.input.label' })}</p>
                <InputWrapper
                  disabled
                  className={senderClassName}
                  name={constants.FROM_HEADER}
                  onChange={(e, data) => this.props.actions.onEmailFieldUpdate(data.name, data.value.trim())}
                  value={this.props.mailSenderName}
                  prefix={'email.from.header.value'}
                  error={this.props.validationErrors ? this.props.validationErrors.get(constants.FROM_HEADER) : null}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={10} className="input-wrapper">
                <p className="action-label">{formatMessage({ id: 'email.subject.input.label' })}</p>
                <MentionTextArea
                  mentionOptions={this.props.transformedMentions}
                  onTextUpdate={(data) => this.props.actions.onEmailFieldUpdate(constants.SUBJECT, this.cleanMentionsInString(data.trim()))}
                  hideTips
                  singleLine
                  className={subjectClassName}
                  message={email.get(constants.SUBJECT)}
                  placeholder={formatMessage({ id: 'email.subject.placeholder' })}
                  automationIdPrefix="email.subject.value"
                  error={this.props.validationErrors ? this.props.validationErrors.get(constants.SUBJECT) : null}
                />

              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <p>{formatMessage({ id: 'create.your.email.here' })}</p>
                <Loader active={this.state.loading} />
                <div id="bee-plugin-container" style={{ visibility: this.state.loading ? 'hidden' : 'visible' }}>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <input data-automation-id="server.id" value={this.props.email.getIn([featuresConstants.DATA, featuresConstants.SERVER_ID])} hidden readOnly />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mentions: mentionsSelectors.getEmailMentionsTransformed(state),
  transformedMentions: mentionsSelectors.getEmailMentionsTransformed(state),
  email: selectors.getEmail(state),
  locationId: appSelectors.getLocationId(state),
  token: selectors.getEmailEditorToken(state),
  uid: selectors.getEmailEditorUid(state),
  validationErrors: selectors.getEmailValidationErrors(state),
  checkingEmailNameAvailability: selectors.getCheckingEMailNameAvailabilityFlag(state),
  isDirty: selectors.isDirty(state),
  publishInProgress: selectors.getPublishInProgressFlag(state),
  mailSenderName: appSelectors.getMailSenderName(state),
  template: selectors.getEmailTemplate(state),
  tempOriginActivity: activitySelectors.getTempOriginActivity(state),
  businessBundlePlan: appSelectors.getBusinessBundlePlan(state),
  hasBasicPlanPermissions: appSelectors.hasBasicPlanPermissions(state),
  registrationFormFields: formsSelectors.getRegistrationFormFields(state),
});

function mapDispatchToProps(dispatch) {
  const creators = Object.assign({},
    emailActions,
    formsActions,
  );

  return {
    actions: bindActionCreators(creators, dispatch),
    mailTemplatesActions: bindActionCreators(mailTemplatesActions, dispatch),
    templatesActions: bindActionCreators(templatesActions, dispatch),
    activityActions: bindActionCreators(activityActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: 'email', saga });
const withMailTemplatesSaga = injectSaga({ key: reducerConstants.MAIL_TEMPLATES_BRANCH, saga: mailTemplatesSaga });
const withBusinessSaga = injectSaga({ key: reducerConstants.BUSINESS_CENTER_BRANCH, saga: businessSaga });
const withFormsSaga = injectSaga({ key: reducerConstants.FORMS_BRANCH, saga: formsSaga });

export default compose(
  withSaga,
  withMailTemplatesSaga,
  withBusinessSaga,
  withFormsSaga,
  withConnect,
  withAuthorization)(injectIntl(HubEmail));
