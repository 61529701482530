import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import ActivitiesType from './ActivityType';
import * as activityConstants from '../../Activity/activity.constants';
import * as routeConstants from '../../../constants/route.contants';
import tracker from '../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../common/utils/tracking/tracking.consts';

class AddActivitySegment extends React.PureComponent {

  static propTypes = {
    activityType: PropTypes.string,
    campaignId: PropTypes.string,
    campaignIndex: PropTypes.number,
    locationId: PropTypes.number.isRequired,
    segmentContent: PropTypes.string,
    createActivity: PropTypes.func.isRequired,
    hasBasicPlanPermissions: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.segmentStyle = classNames('activity-segment', {
      rule: props.activityType === activityConstants.ACTIVITY_TYPE_RULE,
      deal: props.activityType === activityConstants.ACTIVITY_TYPE_DEAL,
      oneTime: props.activityType === activityConstants.ACTIVITY_TYPE_ONE_TIME,
      punchCard: props.activityType === activityConstants.ACTIVITY_TYPE_PUNCH_CARD
    });
  }

  render() {
    const { activityType, campaignIndex, segmentContent } = this.props;
    // todo: remove this ternary when punch-cards have templates
    // eslint-disable-next-line no-nested-ternary
    const createPath = activityType === activityConstants.ACTIVITY_TYPE_PUNCH_CARD ?
        routeConstants.PUNCH_CARDS_CREATE_ROUTE : this.props.hasBasicPlanPermissions ?
        routeConstants.BASIC_TEMPLATE_LIBRARY_ROUTE : routeConstants.TEMPLATE_LIBRARY_ROUTE;
    return (
      <Link to={{ pathname: createPath, search: location.search, type: this.props.activityType }}>
        <Segment
          className={this.segmentStyle}
          data-automation-id={`business-center.campaign.${campaignIndex}.add-activity.${activityType}`}
          onClick={() => tracker.wrapButtonAction(this.props.createActivity(this.props.locationId, this.props.campaignId,
          null /* templateKey */, null /* goalkey */, activityType), trackerConstants.BUTTON_TYPE_CREATE_ONE_TIME_ACTIVITY)}
        >
          <ActivitiesType type={activityType} />
          <p className="segment-content">{segmentContent}</p>
        </Segment>
      </Link>
    );
  }
}

export default AddActivitySegment;
