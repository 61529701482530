import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import EmailRow from './EmailRow';

class EmailsTable extends React.PureComponent {
  static propTypes = {
    emails: PropTypes.object.isRequired,
    changeActivityStatus: PropTypes.func.isRequired,
    goToEmail: PropTypes.func.isRequired,
    highlightedEmailId: PropTypes.string,
    openEmailInNewTab: PropTypes.func.isRequired,
    transformedMentions: PropTypes.object,
    intl: PropTypes.object
  };

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <Table className="ui single line fixed selectable very basic table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              className="list-column-name email-name-column"
            >{formatMessage({ id: 'emails-page.email.name' })}</Table.HeaderCell>
            <Table.HeaderCell
              className="list-column-name email-subject-column"
            >{formatMessage({ id: 'emails-page.email.subject' })}</Table.HeaderCell>
            <Table.HeaderCell
              className="list-column-name email-status-column"
            >{formatMessage({ id: 'emails-page.email.status' })}</Table.HeaderCell>
            <Table.HeaderCell className="email-context-menu-column" />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.emails.map((email, index) => (
            <EmailRow
              changeActivityStatus={this.props.changeActivityStatus}
              goToEmail={this.props.goToEmail}
              highlightedActivityId={this.props.highlightedEmailId}
              key={index.toString()}
              email={email}
              rowIndex={index}
              openEmailInNewTab={this.props.openEmailInNewTab}
              transformedMentions={this.props.transformedMentions}
            />
          ))}
        </Table.Body>
      </Table>
    );
  }
}

export default injectIntl(EmailsTable);
