import { fromJS } from 'immutable';
import * as constantsTypes from './constants/types';
import * as constants from './constants/constants';

export const initTemplateAdmin = fromJS({
  [constants.CATEGORY_NAME]: '',
  [constants.CATEGORY_TYPE]: ''
});

const templateAdminReducer = (state = initTemplateAdmin, action) => {

  let newState;

  const { type, payload } = action;

  switch (type) {
    case constantsTypes.CREATE_TEMPLATE_CATEGORY_SUCCESS:
      newState = initTemplateAdmin;
      break;
    case constantsTypes.UPDATE_TEMPLATE_CATEGORY:
      newState = state.set(payload.key, payload.value);
      break;
    default:
      newState = state;
  }

  return newState;
};

export default templateAdminReducer;
