import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import tracker from '../../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../../common/utils/tracking/tracking.consts';
import ConfirmationDialog from '../../../../common/components/ConfirmationDialog/ConfirmationDialog';
import Tooltip from '../../../../common/components/Tooltip/Tooltip';

const AUTOMATION_PREFIX_NAME = 'toggle-cases-mode';

class ToggleCasesMode extends React.PureComponent {

  static propTypes = {
    casesMode: PropTypes.bool.isRequired,
    automationIdPrefix: PropTypes.string.isRequired,
    cancelSplit: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    splitToCases: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    abTestMode: PropTypes.bool,
    tooltipMessage: PropTypes.string
  };

  confirmCancelSplit = () => {
    this.props.cancelSplit();
    tracker.onButtonClick(trackerConstants.BUTTON_TYPE_CASES_CANCEL_SPLIT);
  };

  handleSplitToCases = () => {
    if (!this.props.disabled) {
      this.props.splitToCases();
      tracker.onButtonClick(trackerConstants.BUTTON_TYPE_CASES_SPLIT_TO_CASES);
    }
  };

  render() {
    const { formatMessage } = this.props.intl;
    const splitButtonClasses = classNames('split-to-cases', 'activity-link', { 'disabled': this.props.disabled });
    return this.props.casesMode
      ? (
        !this.props.abTestMode && <ConfirmationDialog
          automationIdPrefix={`${this.props.automationIdPrefix}.actions.${AUTOMATION_PREFIX_NAME}`}
          confirmButtonKey="activity.action.cancelSplitToCases.confirmationMessage.confirm"
          cancelButtonKey="activity.action.cancelSplitToCases.confirmationMessage.cancel"
          triggerClassName="cancel-split-to-cases"
          triggerLabelKey="activity.action.cancelSplitToCases"
          triggerAutomationId={`${this.props.automationIdPrefix}.actions.cancel-split`}
          contentMessageKey="activity.action.cancelSplitToCases.confirmationMessage"
          titleKey="activity.action.cancelSplitToCases.confirmationHeader"
          confirmAction={this.confirmCancelSplit}
          icon="como-ic-close"
        />
      )
      : (
        <>
          {this.props.tooltipMessage ? <Tooltip
            content={this.props.tooltipMessage}
            trigger={
              <a role="button" className={splitButtonClasses} onClick={this.handleSplitToCases}>
                <Icon className="como-ic-split" size="large" data-automation-id={`${this.props.automationIdPrefix}.actions.split-to-cases`} />
                {formatMessage({ id: 'activity.action.splitToSeveralCases' })}
              </a>
            }
          /> :
             <a role="button" className={splitButtonClasses} onClick={this.handleSplitToCases}>
                <Icon className="como-ic-split" size="large" data-automation-id={`${this.props.automationIdPrefix}.actions.split-to-cases`} />
                {formatMessage({ id: 'activity.action.splitToSeveralCases' })}
             </a>
          }
          </>
      );
  }
}

export default injectIntl(ToggleCasesMode);
