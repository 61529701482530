import React from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Loader } from 'semantic-ui-react';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import Modal from '../../../common/components/CustomModal/CustomModal';
import * as activityConstants from '../../Activity/activity.constants';
import injectSaga from '../../../utils/injectSaga';
import * as emailsActions from '../emails.actions';
import emailsSaga from '../emails.saga';
import * as selectors from '../emails.selectors';
import * as reducerConstants from '../../../constants/reducer.constants';
import * as appSelectors from '../../App/selectors';
import Tooltip from '../../../common/components/Tooltip/Tooltip';
import * as emailConstants from '../../Email/email.constants';

const NAME_LETTERS_MAX = 25;

class EmailHistoryLog extends React.PureComponent {
  static propTypes = {
    email: PropTypes.object.isRequired,
    onConfirm: PropTypes.func,
    open: PropTypes.bool,
    localeDateFormat: PropTypes.string.isRequired,
    localeTimeFormat: PropTypes.string.isRequired,
    lastSent: PropTypes.object,
    businessTimezone: PropTypes.string,
    actions: PropTypes.object,
    emailStatsLoading: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open) {
      this.props.actions.getEmailsStats(this.props.email.get(activityConstants.HUB_ID));
    }
  }

  onClose = () => {
    this.props.actions.cleanEmailStats();
    this.props.onConfirm();
  };

  formatDate = (date) => `${moment(date).tz(this.props.businessTimezone).format(this.props.localeDateFormat)} ${moment(date).format(this.props.localeTimeFormat)}`;

  getSendFromText = () => {
    const { formatMessage } = this.props.intl; // eslint-disable-line
    if (this.props.lastSent.get(activityConstants.ACTIVITY_NAME)) {
      return `${formatMessage({ id: 'email.details.sent.from' })} ${this.props.lastSent.get('count')}` > 0 ? formatMessage({ id: 'business-center.filter-settings.activity.onetime' }) : formatMessage({ id: 'business-center.filter-settings.activity.rule' });
    }
    return '';
  };

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line
    const { email, onConfirm, open } = this.props;
    const name = email.get(emailConstants.EMAIL_NAME);

    return (
      <Modal
        open={open}
        className="email-history-log-modal"
        onClose={onConfirm}
        automationIdPrefix="email.details"
        header={
          <div className="type-and-name">
            {name.length > NAME_LETTERS_MAX ?
              <Tooltip
                content={name}
                trigger={
                  <p>{name}</p>
                }
                position="bottom right"
              /> : <p>{name}</p>
            }
          </div>
        }
        content={
          <div>
            <Dimmer inverted active={this.props.emailStatsLoading}>
              <Loader active />
            </Dimmer>
            <ul>
              {[emailConstants.EMAIL_OPEN_TRACKING, emailConstants.EMAIL_CLICK_TRACKING, emailConstants.EMAIL_2FA_TEMPLATE, emailConstants.EMAIL_CONSENT_TEMPLATE].map((fieldName, index) => (
                <li key={`email-${index.toString()}`}>
                  <span className="log--title">
                    {formatMessage({ id: `emails-page.settings.email.${fieldName}` })}
                  </span>
                  <div className="log--content">
                    {email.get(fieldName) === 'true' ? formatMessage({ id: 'boolean.true' }) : formatMessage({ id: 'boolean.false' })}
                  </div>
                </li>
              ))}
              {[emailConstants.CREATED, emailConstants.UPDATED].map((fieldName, index) => {
                const {
                   [emailConstants.HISTORY_LOG_FIRST_NAME]: firstName,
                   [emailConstants.HISTORY_LOG_LAST_NAME]: lastName,
                   [emailConstants.HISTORY_LOG_DATE]: date
                 } = email.get(fieldName).toJS();

                const userName = `${firstName || ''} ${lastName || ''}`.trim();
                const dateFormatted = `${moment(date).format(this.props.localeDateFormat)} ${moment(date).format(this.props.localeTimeFormat)}`;
                const content = userName.length > 0
                   ? formatMessage({ id: 'email.details.content.with.name' }, { date: dateFormatted, userName })
                   : formatMessage({ id: 'email.details.content' }, { date: dateFormatted });

                return (
                  <li key={`cu-${index.toString()}`}>
                    <span className="log--title">
                      {formatMessage({ id: `email.details.${fieldName}` })}
                    </span>
                    <div className="log--content" title={content} >
                      {content}
                    </div>
                  </li>
                );
              })}
              {this.props.lastSent &&
                <li
                  key="last-sent"
                >
                  <span className="log--title">
                    {formatMessage({ id: 'emails.details.lastSent' })}
                  </span>
                  <div className="log--content">
                    {`${this.formatDate(this.props.lastSent.get('date'))} ${this.getSendFromText()}`}
                  </div>

                </li>
              }
            </ul>
          </div>
        }
        actions={[
          <a tabIndex={-1} role="button" key="gotIt" onClick={onConfirm}>
            {formatMessage({ id: 'general.button.got-it' })}
          </a>
        ]}
        closeOnDimmerClick
      />
    );
  }
}

const mapStateToProps = (state) => ({
  localeDateFormat: appSelectors.getBrowserLocaleDateFormat(state),
  localeTimeFormat: appSelectors.getBrowserLocaleTimeFormat(state),
  businessTimezone: appSelectors.getBusinessTimeZone(state),
  lastSent: selectors.getLastSent(state),
  emailStatsLoading: selectors.getEmailStatsLoading(state)
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(emailsActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.EMAILS_BRANCH, saga: emailsSaga });

export default compose(
  withConnect,
  withSaga,
  injectIntl
)(EmailHistoryLog);
