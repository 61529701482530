import axios from 'axios';

export function getPointShopItems() {
  return axios.get('/api/pointShopItems')
    .then((res) => res.data);
}
export function getPointShopAnalysis() {
  return axios.get('/api/pointShopItems/stats')
    .then((res) => res.data);
}

export function createPointShopItem(data) {
  return axios.post('/api/pointShop/create', data)
    .then((res) => res.data);
}

export function getPointShopItem(pointShopId) {
  return axios.get(`/api/pointShopItems/${pointShopId}`)
    .then((res) => res.data);
}
