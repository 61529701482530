import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import './PopupWrapper.styles.less';
import ValidationErrorMessage from '../ValidationErrorMessage/ValidationErrorMessage';
import { PopupWithFixedPosition } from './PopupWithFixedPosition';
import { isChromeBrowser } from '../../utils/browserUtils';


/**
 * Enhances Semantic-UI Popup component with additional functionality
 */
class PopupWrapper extends React.PureComponent {

  /*
   WARNING - IF THE POPUP IS OPENED IN THE WRONG LOCATION:
   Please make sure that you aren't focusing an element inside the popup,
   Semantic-UI Popup component calculates the popup position only after it is open (drawn):
   the calculation assume that the scroll offset hasn't changed since the mouse click on the trigger,
   so focusing element upon it's show (e.g. componentDidMount) breaks the positioning calculation.

   If you have to focus an element inside the popup,
   Please make sure to run the focus action outside of the current cycle (with setTimeout),
   So that the positioning of the popup will be done before the focus happens.
  */

  static propTypes = {
    intl: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    fixed: PropTypes.bool,
    hideTrigger: PropTypes.bool,
    error: PropTypes.string,
    automationId: PropTypes.string,
    scrollToViewIfNeeded: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    children: PropTypes.node,
    content: PropTypes.any
  };

  static defaultProps = {
    scrollToViewIfNeeded: true,
    fixed: true
  };

  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this.handleOnOpen = this.handleOnOpen.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
  }

  handleOnOpen(e, props) {
    if (this.props.onOpen) {
      this.props.onOpen(e, props);
    } else {
      this.setState({ isOpen: true });
    }
  }

  handleOnClose(e, props) {
    if (this.props.onClose) {
      this.props.onClose(e, props);
    } else {
      this.setState({ isOpen: false });
    }
  }

  handleRef = (node) => {
    if (node && this.props.scrollToViewIfNeeded && isChromeBrowser()) {
      setTimeout(() => {
        const rect = node.getBoundingClientRect();
        const innerHeight = window.innerHeight;
        if (rect.bottom > innerHeight) {
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          window.scrollTo({ top: ((rect.top + rect.height + scrollTop) - innerHeight) + 23, behavior: 'smooth' });
        }
      });
    }
  };

  render() {
    // noinspection JSUnusedLocalSymbols - we need to exclude the 'fixed' & 'onOpen' props from popupProps
    const { fixed, onOpen, hideTrigger, error, intl, automationId, scrollToViewIfNeeded, children, content, disabled, ...popupProps } = this.props;
    const { formatMessage } = intl;
    const errorMessage = error ? formatMessage({ id: error }) : '';
    const popupStyle = { marginTop: hideTrigger ? '-3em' : '0px' }; // Popup component ignore the className attribute :(
    return (
      <span className="popup-wrapper" data-automation-id={automationId}>
        <PopupWithFixedPosition
          basic
          fixed={fixed}
          style={popupStyle}
          open={this.state.isOpen}
          onOpen={this.handleOnOpen}
          onClose={this.handleOnClose}
          {...popupProps}
          disabled={disabled}
        >
          <div className="popup-content-wrapper" ref={this.handleRef}>
            {children || content}
          </div>
        </PopupWithFixedPosition>
        {error ? (<ValidationErrorMessage
          errorMessage={errorMessage}
          dataAutomationId={`${automationId}.error.message`}
        />) : null}
      </span>
    );
  }
}

export default injectIntl(PopupWrapper);
