import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { Ref } from '@stardust-ui/react-component-ref';
import classNames from 'classnames';

import * as activitySelectorsUtils from '../../Activity/activity.selectors.utils';
import tracker from '../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../common/utils/tracking/tracking.consts';
import * as activityConstants from '../../Activity/activity.constants';
import * as emailConstants from '../../Email/email.constants';
import EmailHistoryLog from './EmailHistoryLog';
import EmailContextMenu from './EmailContextMenu';
import EmailStats from './EmailStats';
import EmailUsages from './EmailUsages';
import ActivityStatus from '../../Activity/views/ActivityStatus';
import MentionTextArea from '../../../common/components/MentionableTextArea/MentionTextArea';

class EmailRow extends React.PureComponent {

  static propTypes = {
    changeActivityStatus: PropTypes.func.isRequired,
    goToEmail: PropTypes.func.isRequired,
    highlightedEmailId: PropTypes.string,
    email: PropTypes.object.isRequired,
    openEmailInNewTab: PropTypes.func.isRequired,
    rowIndex: PropTypes.number.isRequired,
    transformedMentions: PropTypes.object,
  };

  state = {
    isMouseEnter: false,
    isDetailsModalOpen: false,
    isAnalysisModalOpen: false,
    isUsagesModalOpen: false
  };

  handleOnMouseEnter = () => {
    this.setState({
      isMouseEnter: true
    });
  };
  handleOnMouseLeave = () => {
    this.setState({
      isMouseEnter: false
    });
  };
  handleDetailsModalOpen = () => {
    this.setState({
      isDetailsModalOpen: true
    });
  };
  handleDetailsModalClose = () => {
    this.setState({
      isDetailsModalOpen: false
    });
  };

  handleAnalysisModalOpen = () => {
    this.setState({
      isAnalysisModalOpen: true
    });
  };

  handleAnalysisModalClose = () => {
    this.setState({
      isAnalysisModalOpen: false
    });
  };

  handleUsagesModalOpen = () => {
    this.setState({
      isUsagesModalOpen: true
    });
  };

  handleUsagesModalClose = () => {
    this.setState({
      isUsagesModalOpen: false
    });
  };

  handleRef = (node) => {
    const { highlightedEmailId, email } = this.props;
    if (highlightedEmailId && highlightedEmailId === email.get(activityConstants.HUB_ID)) {
      window.scrollTo({
        top: node ? node.getBoundingClientRect().top : 0,
        behavior: 'smooth'
      });
    }
  };

  render() {
    const { email, rowIndex, highlightedEmailId, openEmailInNewTab } = this.props;
    const { isMouseEnter, isDetailsModalOpen, isAnalysisModalOpen, isUsagesModalOpen } = this.state;
    const emailName = email.get(emailConstants.EMAIL_NAME);
    const viewStatus = activitySelectorsUtils.calculateActivityViewStatus(email);
    const subjectClassNames = classNames('subjectText');
    const rowClass = classNames('email-row', {
      'disable-pointer-events': viewStatus === activityConstants.ACTIVITY_STATUS_INVALID,
      'clickable': viewStatus !== activityConstants.ACTIVITY_STATUS_INVALID,
      'highlighted': highlightedEmailId && email.get(activityConstants.HUB_ID) === highlightedEmailId,
    });

    const detailsForTracker = {
      [trackerConstants.ACTIVITY_HUB_ID]: email.get(activityConstants.HUB_ID),
      [trackerConstants.ACTIVITY_SERVER_ID]: email.get(activityConstants.SERVER_ID),
      [trackerConstants.ACTIVITY_STATUS]: email.get(activityConstants.STATUS),
      [trackerConstants.ACTIVITY_NAME]: email.get(activityConstants.NAME),
      [trackerConstants.ACTIVITY_TYPE]: email.get(activityConstants.ACTIVITY_TYPE_EMAIL),
    };

    const rowViewStatus = email.get(activityConstants.STATUS);

    return (
      <Ref innerRef={this.handleRef}>
        <Table.Row
          data-automation-id={`emails-page.campaign.row.${rowIndex}`}
          className={rowClass}
          onMouseEnter={this.handleOnMouseEnter}
          onMouseLeave={this.handleOnMouseLeave}
        >
          <EmailHistoryLog
            email={email}
            open={isDetailsModalOpen}
            onConfirm={this.handleDetailsModalClose}
          />
          <EmailStats
            email={email}
            onConfirm={this.handleAnalysisModalClose}
            open={isAnalysisModalOpen}
          />
          <EmailUsages
            email={email}
            open={isUsagesModalOpen}
            onConfirm={this.handleUsagesModalClose}
          />
          <Table.Cell
            onClick={tracker.wrapButtonAction(() => this.props.goToEmail(email.get(activityConstants.HUB_ID)),
              trackerConstants.BUTTON_TYPE_EMAIL_LOADED,
              detailsForTracker)
            }
            className="listcontent"
            title={emailName}
          >{emailName}</Table.Cell>
          <Table.Cell
            className="listcontent"
            onClick={tracker.wrapButtonAction(() => this.props.goToEmail(email.get(activityConstants.HUB_ID)),
              trackerConstants.BUTTON_TYPE_EMAIL_LOADED,
              detailsForTracker)
            }
          >
            <MentionTextArea
              disabled
              mentionOptions={this.props.transformedMentions}
              hideTips
              singleLine
              message={email.get(emailConstants.SUBJECT)}
              className={subjectClassNames}
              automationIdPrefix="email.subject.value"
            />
          </Table.Cell>
          <Table.Cell
            onClick={tracker.wrapButtonAction(() => this.props.goToEmail(email.get(activityConstants.HUB_ID)),
              trackerConstants.BUTTON_TYPE_EMAIL_LOADED,
              detailsForTracker)
            }
          >
            <ActivityStatus status={rowViewStatus} messagesNamespace="email" />
          </Table.Cell>
          <Table.Cell className="listcontent" textAlign="right">
            <EmailContextMenu
              detailsForTracker={detailsForTracker}
              hidden={!isMouseEnter}
              onChangeEmailStatus={this.props.changeActivityStatus}
              onDetailsOpen={this.handleDetailsModalOpen}
              onAnalysisOpen={this.handleAnalysisModalOpen}
              onUsagesOpen={this.handleUsagesModalOpen}
              email={email}
              onOpenInNewTab={
                tracker.wrapButtonAction(() => openEmailInNewTab(email), trackerConstants.BUTTON_TYPE_OPEN_IN_NEW_TAB, detailsForTracker)
              }
              emailViewStatus={viewStatus}
              automationId={`emails-page.email.${rowIndex}.email-context-menu`}
            />
          </Table.Cell>
        </Table.Row>
      </Ref>
    );
  }
}

export default EmailRow;
