import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from '../../../../../../common/components/CustomModal/CustomModal';
import DateTimeGlobalConditions from './DateTimeGlobalConditions';
import * as globalConditionsActions from '../globalConditions.actions';
import DateTimeToolTip from '../../dateTimeConditions/DateTimeToolTip';
import * as timeConstants from '../../../../../../common/utils/dateTime.constants';
import * as activityConstants from '../../../../activity.constants';
import * as appSelectors from '../../../../../App/selectors';
import * as selectors from '../../../../activity.selectors';

class DateTimeGlobalConditionsWrapper extends React.PureComponent {

  static propTypes = {
    intl: PropTypes.object,
    locale: PropTypes.string,
    localeDateFormat: PropTypes.string,
    localeTimeFormat: PropTypes.string,
    dateTimeGlobalConditions: PropTypes.object,
    tempDatesRangeCondition: PropTypes.object,
    tempDaysTimesCondition: PropTypes.object,
    conditionType: PropTypes.string,
    actions: PropTypes.object,
    validationErrors: PropTypes.object,
    tempValidationErrors: PropTypes.object,
    activityType: PropTypes.string,
    businessTimezone: PropTypes.string
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      isModalOpen: false
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.toggleDateTimeCondition = this.toggleDateTimeCondition.bind(this);
    this.cancelChanges = this.cancelChanges.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
  }

  saveChanges() {
    this.props.actions.saveGlobalDateTimeChanges();
    this.closeModal();
  }

  cancelChanges() {
    this.props.actions.resetGlobalDateTimeConditions(this.props.conditionType);
    this.closeModal();
  }

  /**
   * opens modal
   */
  openModal = () => {
    this.setState({
      isModalOpen: true,
    });
    this.props.actions.createTempGlobalDateTimeConditions();
  };

  /**
   * close modal
   */
  closeModal = () => {
    this.setState({
      isModalOpen: false
    });
  };

  toggleDateTimeCondition(event, elem) {
    this.props.actions.updateGlobalDateTimeConditionType(elem.value);
  }
  hasErrors(conditions){
    let valid = true;
    conditions.forEach((c) => {
      if(c.get(timeConstants.TIME_RANGE)){
        valid = !(c.getIn([timeConstants.TIME_RANGE, timeConstants.TIME_ERRORS]) && c.getIn([timeConstants.TIME_RANGE, timeConstants.TIME_ERRORS]).size > 0);
      }else if(c.get(timeConstants.DAYS)){
        valid = !(c.getIn([timeConstants.DAYS, timeConstants.TIME_ERRORS]) && c.getIn([timeConstants.DAYS, timeConstants.TIME_ERRORS]).size > 0);
      }
      return true;
    });
    return !valid;
  }
  render() {
    const { formatMessage } = this.props.intl;
    const conditionData = this.props.dateTimeGlobalConditions;

    let hasErrors = false;

    if (this.props.tempDaysTimesCondition && this.props.tempDaysTimesCondition.get(activityConstants.CONDITION_VALUE)){
      hasErrors = this.hasErrors(this.props.tempDaysTimesCondition.get(activityConstants.CONDITION_VALUE));
    }

    return (
      <Modal
        className={`dateTime-global-modal from-${this.props.activityType}`}
        header={formatMessage({ id: 'activity.globalConditions.dateTime' })}
        automationIdPrefix="globalConditions.dateTime"
        actions={[
          <Button
            key="globalDateTimeConditionCancelBtn"
            onClick={this.cancelChanges}
            basic
            className="link modal-button"
            data-automation-id="globalConditions.dateTime.btnCancel"
          >
            {formatMessage({ id: 'general.button.cancel' })}
          </Button>,
          <Button
            className="modal-button"
            key="globalDateTimeConditionSaveBtn"
            onClick={this.saveChanges}
            data-automation-id="globalConditions.dateTime.btnSave"
            disabled={hasErrors}
          >
            {formatMessage({ id: 'general.button.save' })}
          </Button>
        ]}
        trigger={
          <DateTimeToolTip
            openModal={this.openModal}
            conditionType={this.props.conditionType}
            conditionData={conditionData}
            locale={this.props.locale}
            localeDateFormat={this.props.localeDateFormat}
            localeTimeFormat={this.props.localeTimeFormat}
            validationErrors={this.props.validationErrors}
            textPrefix={this.props.activityType}
            businessTimezone={this.props.businessTimezone}
          />
        }
        content={
          <DateTimeGlobalConditions
            actions={this.props.actions}
            dateTimeGlobalConditions={this.props.dateTimeGlobalConditions}
            datesRangeCondition={this.props.tempDatesRangeCondition}
            daysTimesCondition={this.props.tempDaysTimesCondition}
            conditionType={this.props.conditionType}
            onRadioChange={this.toggleDateTimeCondition}
            tempValidationErrors={this.props.tempValidationErrors}
            activityType={this.props.activityType}
            datePickerInsideModal
          />
        }
        size={960}
        closeIcon={<Icon className="como-ic-close" name="close" onClick={this.cancelChanges} data-automation-id="globalConditions.dateTime.closeBtn" />}
        open={this.state.isModalOpen}
      />

    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(globalConditionsActions, dispatch)
  };
}

const mapStateToProps = (state) => ({
  locale: appSelectors.getBrowserLocale(state),
  localeDateFormat: appSelectors.getBrowserLocaleDateFormat(state),
  localeTimeFormat: appSelectors.getBrowserLocaleTimeFormat(state),
  tempDatesRangeCondition: selectors.getTempDateTimeGlobalConditionsDatesRangeCondition(state),
  tempDaysTimesCondition: selectors.getTempDateTimeGlobalConditionsTimeRangeInDaysOfWeekCondition(state),
  conditionType: selectors.getDateTimeGlobalConditionType(state),
  validationErrors: selectors.getDatetimeGlobalConditionsValidationErrors(state),
  tempValidationErrors: selectors.getDatetimeGlobalConditionsTempValidationErrors(state),
  activityType: selectors.getActivityType(state),
  businessTimezone: appSelectors.getBusinessTimeZone(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DateTimeGlobalConditionsWrapper));

