import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import * as activityConstants from '../../activity.constants';
import * as selectors from '../../activity.selectors';

class ChildActivitiesIds extends React.PureComponent {

  static propTypes = {
    childActivities: PropTypes.object,
  };

  get communicationRulesIds() {
    return this.communicationRules
      .filter((rule) => rule.get(activityConstants.SERVER_ID))
      .map((rule) => rule.get(activityConstants.SERVER_ID))
      .join(',');
  }

  get communicationRules() {
    return this.props.childActivities.get(activityConstants.PUNCH_CARD_COMMUNICATION_RULES);
  }

  get punchOverflowRule() {
    return this.props.childActivities.get(activityConstants.PUNCH_CARD_RULE_PUNCH_OVERFLOW);
  }

  get punchRule() {
    return this.props.childActivities.get(activityConstants.PUNCH_CARD_RULE_PUNCH);
  }

  get sendOnExpirationRule() {
    return this.props.childActivities.get(activityConstants.PUNCH_CARD_RULE_SEND_ON_EXPIRATION);
  }

  get sendOnLastPunchRule() {
    return this.props.childActivities.get(activityConstants.PUNCH_CARD_RULE_SEND_ON_LAST_PUNCH);
  }

  get sendOnRedeemRule() {
    return this.props.childActivities.get(activityConstants.PUNCH_CARD_RULE_SEND_ON_REDEEM);
  }

  render() {
    return this.props.childActivities
    ? (
      <div>
        {
          this.communicationRules && this.communicationRules.size > 0 &&
          <input
            type="text"
            hidden
            name={activityConstants.PUNCH_CARD_COMMUNICATION_RULES}
            defaultValue={this.communicationRulesIds}
            data-automation-id={activityConstants.PUNCH_CARD_COMMUNICATION_RULES}
          />
        }
        {
          this.punchOverflowRule &&
          <input
            type="text"
            hidden
            name={activityConstants.PUNCH_CARD_RULE_PUNCH_OVERFLOW}
            defaultValue={this.punchOverflowRule.get(activityConstants.SERVER_ID) || ''}
            data-automation-id={activityConstants.PUNCH_CARD_RULE_PUNCH_OVERFLOW}
          />
        }
        {
          this.punchRule &&
          <input
            type="text"
            hidden
            name={activityConstants.PUNCH_CARD_RULE_PUNCH}
            defaultValue={this.punchRule.get(activityConstants.SERVER_ID) || ''}
            data-automation-id={activityConstants.PUNCH_CARD_RULE_PUNCH}
          />
        }
        {
          this.sendOnExpirationRule &&
          <input
            type="text"
            hidden
            name={activityConstants.PUNCH_CARD_RULE_SEND_ON_EXPIRATION}
            defaultValue={this.sendOnExpirationRule.get(activityConstants.SERVER_ID) || ''}
            data-automation-id={activityConstants.PUNCH_CARD_RULE_SEND_ON_EXPIRATION}
          />
        }
        {
          this.sendOnLastPunchRule &&
          <input
            type="text"
            hidden
            name={activityConstants.PUNCH_CARD_RULE_SEND_ON_LAST_PUNCH}
            defaultValue={this.sendOnLastPunchRule.get(activityConstants.SERVER_ID) || ''}
            data-automation-id={activityConstants.PUNCH_CARD_RULE_SEND_ON_LAST_PUNCH}
          />
        }
        {
          this.sendOnRedeemRule &&
          <input
            type="text"
            hidden
            name={activityConstants.PUNCH_CARD_RULE_SEND_ON_REDEEM}
            defaultValue={this.sendOnRedeemRule.get(activityConstants.SERVER_ID) || ''}
            data-automation-id={activityConstants.PUNCH_CARD_RULE_SEND_ON_REDEEM}
          />
        }
      </div>
    )
      : null;
  }
}

const mapStateToProps = (state) => ({
  childActivities: selectors.getChildActivities(state),
});

const withConnect = connect(mapStateToProps, null);

export default compose(
  withConnect,
  injectIntl,
)(ChildActivitiesIds);
