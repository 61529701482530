/*
 *
 * BusinessCenter actions
 *
 */

import * as constants from './businessCenter.constants';
import * as errorConstant from '../ErrorMessage/errorMessage.constants';

export function getCampaigns() {
  return { type: constants.GET_CAMPAIGNS };
}


export function openGoals() {
  return { type: constants.OPEN_GOALS };
}

// activitiesBoxes actions

export function createCampaign({ name, status }) {
  return { type: constants.CREATE_CAMPAIGN, name, status };
}

export function defocusCampaign() {
  return { type: constants.DEFOCUS_CAMPAIGN };
}

export function focusCampaign(campaignId) {
  return { type: constants.FOCUS_CAMPAIGN, campaignId };
}

export function deleteCampaign(campaignId) {
  return { type: constants.DELETE_CAMPAIGN, campaignId };
}

export function onSortUpdate() {
  return { type: constants.UPDATE_ORDER };
}

// activities actions

export function addActivity() {
  return { type: constants.ADD_ACTIVITY };
}

export function sortActivities() {
  return { type: constants.SORT_ACTIVITIES };
}

export function goToActivity(activityType, activityHubId) {
  return {
    type: constants.GO_TO_ACTIVITY,
    activityType,
    activityHubId
  };
}

export function deleteActivity(campaignId, activityType, activityId) {
  return {
    type: constants.DELETE_ACTIVITY,
    activityType,
    activityId,
    campaignId
  };
}

// filters

export function onFilterUpdate(groupKey, key, status) {
  return {
    type: constants.UPDATE_FILTERS,
    groupKey,
    key,
    status
  };
}

export function onTriggerFilterUpdate(groupKey, key, status) {
  return {
    type: constants.UPDATE_TRIGGER_FILTERS,
    groupKey,
    key,
    status
  };
}

export function onTagFilterUpdate(groupKey, key, status) {
  return {
    type: constants.UPDATE_TAGS_FILTERS,
    groupKey,
    key,
    status
  };
}

export function updateActionFilterStatus(status) {
  return {
    type: constants.UPDATE_IS_ACTION_FILTER_UPDATED,
    status
  };
}

export function setRuleFilterMode(isEnabled) {
  return {
    type: constants.UPDATE_RULE_FILTER_MODE,
    isEnabled,
  };
}

export function setActionFilterMode(isEnabled) {
  return {
    type: constants.UPDATE_ACTION_FILTER_MODE,
    isEnabled,
  };
}

export function updateActivityPin(campaignId, activityId, activityType, pinningDate) {
  return {
    type: constants.UPDATE_ACTIVITY_PIN,
    campaignId,
    activityId,
    activityType,
    pinningDate
  }
}

export function updateTriggerFilterList(triggerList, isPageLoadedFirstTime) {
  return {
    type: constants.UPDATE_TRIGGER_FILTERS_SHOW_LIST,
    triggerList,
    isPageLoadedFirstTime
  };
}

export function updateActionsFilterList(actionsList, isPageLoadedFirstTime) {
  return {
    type: constants.UPDATE_ACTION_FILTERS_SHOW_LIST,
    actionsList,
    isPageLoadedFirstTime
  };
}

export function onActionFilterUpdate(groupKey, key, status) {
  return {
    type: constants.UPDATE_ACTION_FILTERS,
    groupKey,
    key,
    status
  };
}

export function fetchActivitiesWithArchives() {
  return {
    type: constants.FETCH_CAMPAIGNS_WITH_ARCHIVE_ACTIVITIES,
  };
}

export function updateCampaign(campaign) {
  return {
    type: constants.UPDATE_CAMPAIGN,
    campaign
  };
}

export function activityMoveToCampaign(activity, campaignId) {
  return {
    type: constants.ACTIVITY_MOVE_TO_CAMPAIGN,
    activity,
    campaignId
  };
}

export function updateActivity(activity) {
  return {
    type: constants.UPDATE_ACTIVITY,
    activity
  };
}

export function setCampaignsLoaded(isLoaded) {
  return {
    type: constants.SET_CAMPAIGNS_LOADED,
    isLoaded
  };
}

export function goToBusinessCenter() {
  return {
    type: constants.GO_TO_BUSINESS_CENTER,
  };
}

export function openActivityInNewTab(activity) {
  return {
    type: constants.OPEN_ACTIVITY_IN_NEW_TAB,
    activity: activity
  };
}

export function activityActivateError(errorMessage) {
  return {
    type: errorConstant.HANDLE_ERROR,
    [errorConstant.MESSAGES_KEYS]: [errorMessage]
  };
}
export function goToUpgradePage(path) {
  return {
    type: constants.GO_TO_UPGRADE_PAGE,
    path
  };
}

export function updateScrollPosition(position) {
  return {type: constants.UPDATE_SCROLL_POSITION, [constants.SCROLL_Y_POSITION]: position };
}

export const openToast = (message) => ({
  type: constants.OPEN_TOAST,
  message,
});

export const closeToast = () => ({
  type: constants.CLOSE_TOAST,
});
