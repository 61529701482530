/*
 *
 * FilterMembers constants
 *
 */

// ACTIONS CONSTANTS

export const UPDATE_FILTER_GLOBAL_MEMBERSHIP_CONDITION_FIELD = 'filter/globalConditions/UPDATE_GLOBAL_MEMBERSHIP_CONDITION_FIELD';
export const CLEAR_FILTER_CONDITION_FIELDS = 'filter/CLEAR_FILTER_CONDITION_FIELDS';
export const FILTER_ALL_MEMBERS = 'filter/FILTER_ALL_MEMBERS';
export const GO_TO_FILTER_MEMBERS = 'filter/GO_TO_FILTER_MEMBERS';
export const ADD_FILTER_GLOBAL_MEMBERSHIP_CONDITION = 'filter/globalConditions/ADD_FILTER_GLOBAL_MEMBERSHIP_CONDITION_FIELD';
export const UPDATE_FILTER_GLOBAL_MEMBERSHIP_CONDITION_ATTRIBUTE_OPERATOR = 'filter/globalConditions/UPDATE_FILTER_GLOBAL_MEMBERSHIP_CONDITION_ATTRIBUTE_OPERATOR_FIELD';
export const UPDATE_FILTER_GLOBAL_MEMBERSHIP_CONDITION_ACTION_OPERATOR = 'filter/globalConditions/UPDATE_FILTER_GLOBAL_MEMBERSHIP_CONDITION_ACTION_OPERATOR_FIELD';
export const ACTIVITIES = 'activities';
export const ACTIVITIES_FETCHED = 'activitiesFetched';

export const FILTER_MEMBERS_TEMP_MODEL = 'filterMembers';
export const FILTERS_USER_ACTION = 'filtersUserAction';
export const USER_ACTIONS_CONDITIONS = 'userActionsConditions';
export const USER_ACTIONS_CONDITIONS_LIST = 'userActionsConditionsList';
export const CONDITIONS = 'conditions';
export const CONDITION_LIST_OPERATOR = 'conditionsOperator';
export const USER_ACTION_AGGREGATION_CONDITIONS = 'aggregationConditions';
export const IS_USER_ACTION_AGGREGATED_CONDITIONS_ACTIVE = 'isActive';
export const CONDITION = 'condition';
export const FILTER_TEMP_MODEL = 'tempModel';
export const FILTER_SOURCE = 'filter';
export const FETCHED_FORM_FIELDS = 'fetchedFormFields';
export const USER_ACTION_KEY = 'userActionKey';
export const CONDITIONS_LIST = 'conditionsList';
export const TEMP_DATETIME_GLOBAL_CONDITIONS = 'tempDateTimeGlobalConditions';
export const TEMP_FILTERS_ERRORS = 'tempFiltersErrors';
export const CONDITION_VALUE = 'conditionValue';
export const DATES_RANGE_CONDITION = 'datesRangeCondition';
export const USER_ACTION_DATE_RANGE_CONDITION = 'dateRangeCondition';
export const SET_FILTER_MEMBERS_IN_PROGRESS = 'app/FilterMembers/SET_FILTERS_IN_PROGRESS';
export const FILTER_MEMBER_FAIL = 'filterMembers.Fail';
export const FILTER_MEMBERS_FILTER_SUCCESS = 'FilterMembers/FILTER_MEMBERS_SUCCESS';
export const FILTERS_MEMBERS_IN_PROGRESS = 'filterMemberInProgress';
export const FILTER_MEMBERS = 'filterMember';
export const FILTER_MEMBERS_BY_ATTRIBUTES_AND_ACTIONS = 'filterMembers/FILTER_MEMBERS';
export const ADD_TEMP_FILTERS_ERRORS = 'app/activity/ADD_TEMP_FILTERS_ERRORS';
export const FILTER_MEMBERS_SUCCESS = 'globalConditions/FILTER_MEMBERS_SUCCESS';
export const CLOSE_GLOBAL_MEMBERSHIP_CONDITIONS_MODAL = 'globalConditions/CLOSE_GLOBAL_MEMBERSHIP_CONDITIONS_MODAL';
export const GET_ACTIVITIES = 'filter/GET_ACTIVITIES';
export const ACTIVITIES_FETCHED_SUCCESS = 'filter/GET_ACTIVITIES_SUCCESS';
export const ACTIVITIES_FETCHED_FAIL = 'filter/GET_ACTIVITIES_SUCCESS';
export const FILTER_MEMBERS_UPDATE_FILTER_USER_ACTION_TYPE = 'filter/UPDATE_FILTER_USER_ACTION_TYPE';
export const ADD_FILTER_MEMBERS_TEMP_FILTERS_ERRORS = 'filter/ADD_TEMP_FILTERS_ERRORS';
export const TEMP_FILTER_MEMBERS_ERRORS = 'tempFilterMembersErrors';
export const TEMP_FILTER_ERRORS = 'tempFiltersErrors';
export const FILTERED_POPULATION = 'filteredPopulation';
export const ADD_FILTER_TEMP_FILTERS_ERRORS = 'app/FilterMembers/ADD_TEMP_FILTERS_ERRORS';
export const NO_MEMBERS_FILTERED_ERROR = 'noMembersFilteredError';
export const FILTER_EMPTY_STATE = 'filterEmptyState';
export const ADD_FILTER_MEMBERS_RESULTS_STATE = 'filterMembersResultsState';
export const EXPORT_IN_PROGRESS = 'exportInProgress';
export const DATA = 'data';
export const FILTERED_NUM_OF_MEMBERS = 'count';
export const FILTERED_MEMBERS_SAMPLES = 'samples';
export const DELETE_USER_ACTION_FROM_FILTER = 'filterMembers/DELETE_USER_ACTION_FROM_FILTER';
export const EXPORT_FILTERED_POPULATION = 'filterMembers/exportFilteredPopulation';
export const EXPORT_FILTERED_POPULATION_SUCCESS = 'filterMembers/exportFilteredPopulationSuccess';
export const EXPORT_FILTERED_POPULATION_IN_PROGRESS = 'filterMembers/exportFilteredPopulationInProgress';
export const ADD_USER_ACTION_CRITERIA_TO_FILTER_MEMBERS = 'globalConditions/ADD_USER_ACTION_CRITERIA_TO_FILTER_MEMBERS';
export const CREATE_FILTER_REFERRAL_CODE_TEMP_MODEL = 'filterMembers/CREATE_REFERRAL_CODE_TEMP_MODEL';
export const DELETE_FILTER_REFERRAL_CODE_TEMP_MODEL = 'filterMembers/DELETE_REFERRAL_CODE_TEMP_MODEL';
export const SAVE_FILTER_REFERRAL_CODE_TEMP_MODEL_TO_CONDITIONS = 'filterMembers/SAVE_FILTER_REFERRAL_CODE_TEMP_MODEL_TO_CONDITIONS';
export const DELETE_FILTER_MEMBERSHIP_CONDITION = 'filterMembers/DELETE_FILTER_MEMBERSHIP_CONDITION';
export const CREATE_ONE_TIME_ACTION_ON_FILTERED_POPULATION = 'filterMembers/CREATE_ONE_TIME_ACTION_ON_FILTERED_POPULATION';
export const UPDATE_USER_ACTION_AGGREGATION_CRITERIA = 'filterMembers/UPDATE_USER_ACTION_AGGREGATION_CRITERIA';
export const UPDATE_DATE_RANGE_DATE = 'filterMembers/dateTime/UPDATE_DATE_RANGE_DATE';
export const TEMP_REFERRAL_CODE_MODEL = 'tempReferralCode';
export const CREATE_REFERRAL_CODE_TEMP_MODEL = 'filterMembers/CREATE_REFERRAL_CODE_TEMP_MODEL';


// STORE CONSTANTS
