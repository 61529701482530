import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import AutoDirectionProvider from 'react-with-direction/dist/AutoDirectionProvider';
import * as activityConstants from '../../../../activity.constants';
import * as dateTimeUtils from '../../../../reducers/utils/activity.datetime.reducer.utils';
import * as appSelectors from '../../../../../App/selectors';
import * as appConstants from '../../../../../App/app.constants';

class FilterUserActionSummary extends React.PureComponent {

  static propTypes = {
    userAction: PropTypes.object,
    intl: PropTypes.object,
    localeSettings: PropTypes.object
  };


  get dateRangeConditionText() {
    const dateRangeCondition = this.props.userAction.get(activityConstants.USER_ACTION_DATE_RANGE_CONDITION);
    return dateTimeUtils.getDateTimeRangeConditionValueAsString(dateRangeCondition, this.props.intl, this.props.localeSettings.get(appConstants.DATE_FORMAT), true);
  }

  get userActionText() {
    const { formatMessage } = this.props.intl;
    const userActionNameTextKey = this.props.userAction.get(activityConstants.USER_ACTION_KEY) ? `activity.filters.actions.${this.props.userAction.get(activityConstants.USER_ACTION_KEY)}` : null;
    const hasAggregationConditions = this.props.userAction.get(activityConstants.USER_ACTION_AGGREGATION_CONDITIONS)
      ? this.props.userAction.get(activityConstants.USER_ACTION_AGGREGATION_CONDITIONS).find((condition) =>
          condition.get(activityConstants.IS_USER_ACTION_AGGREGATED_CONDITIONS_ACTIVE)) !== undefined
      : false;
    const hasConditions = this.props.userAction.getIn([activityConstants.CONDITIONS, activityConstants.CONDITIONS_LIST]).size > 0
      || hasAggregationConditions;
    const specificConditionsText = hasConditions
      ? formatMessage({ id: 'userAction.specificCondition.seeMore' }) : '';
    if (userActionNameTextKey) {
      return `${formatMessage({ id: 'userAction.prefix.member' })} ${formatMessage({ id: userActionNameTextKey })} ${this.dateRangeConditionText} ${specificConditionsText}`;
    }
    return null;
  }

  render() {
    return (
      this.userActionText &&
      <AutoDirectionProvider text={this.userActionText}>
        <span>
          {this.userActionText}
        </span>
      </AutoDirectionProvider>);

  }
}

const mapStateToProps = (state) => ({
  localeSettings: appSelectors.getBrowserLocaleSettings(state)
});


export default connect(mapStateToProps)(injectIntl(FilterUserActionSummary));
