/*
 *
 * Templates actions
 *
 */

import * as constants from './templates.constants';
import * as emailsConstants from '../Emails/emails.constants';

export function defaultAction() {
  return {
    type: constants.DEFAULT_ACTION,
  };
}

export function getTemplates(entityType, shouldGetProductTemplates) {
  if (shouldGetProductTemplates) {
    return [
      getBusinessTemplates(entityType),
      getProductTemplates(entityType),
      getProductCategories(entityType)
    ];
  }
  return getBusinessTemplates(entityType);
  
}

export function getBusinessTemplates(entityType) {
  return {
    type: constants.GET_BUSINESS_TEMPLATES,
    entityType
  };
}

export function getProductTemplates(entityType) {
  return {
    type: constants.GET_PRODUCT_TEMPLATES,
    entityType
  };
}

export function getProductCategories(entityType) {
  return {
    type: constants.GET_PRODUCT_CATEGORIES,
    entityType
  };
}

export function saveAsBusinessTemplate(data, entityType) {
  return {
    type: constants.SAVE_AS_BUSINESS_TEMPLATE,
    payload: data,
    entityType
  };
}

export function saveAsProductTemplate(data, entityType) {
  return {
    type: constants.SAVE_AS_PRODUCT_TEMPLATE,
    payload: data,
    entityType
  };
}

export function updateBusinessTemplate(data, entityType) {
  return {
    type: constants.UPDATE_BUSINESS_TEMPLATE,
    payload: data,
    entityType
  };
}

export function updateProductTemplate(data, entityType) {
  return {
    type: constants.UPDATE_PRODUCT_TEMPLATE,
    payload: data,
    entityType
  };
}

export function updateBusinessTemplateMetadata(data, entityType) {
  return {
    type: constants.UPDATE_BUSINESS_TEMPLATE_METADATA,
    payload: data,
    entityType
  };
}

export function updateProductTemplateMetadata(data, entityType) {
  return {
    type: constants.UPDATE_PRODUCT_TEMPLATE_METADATA,
    payload: data,
    entityType
  };
}


export function deleteBusinessTemplate(data, entityType) {
  return {
    type: constants.DELETE_BUSINESS_TEMPLATE,
    payload: data,
    entityType
  };
}

export function deleteProductTemplate(data, entityType) {
  return {
    type: constants.DELETE_PRODUCT_TEMPLATE,
    payload: data,
    entityType
  };
}

export function clearTemplates() {
  return {
    type: constants.CLEAR_TEMPLATES
  };
}

export function getBusinessesVerticals() {
  return {
    type: constants.GET_BUSINESSES_VERTICALS
  };
}


export function loadTemplateDataIntoEntity(data, entityType) {
  if (entityType !== emailsConstants.EMAIL) {
    return {
      type: constants.LOAD_TEMPLATE_DATA_INTO_ACTIVITY,
      payload: data
    };
  }
  return {
    type: constants.LOAD_TEMPLATE_DATA_INTO_EMAIL,
    payload: data
  };
}
