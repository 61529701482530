import { createSelector } from 'reselect';
import * as constants from './pointShop.constants';
import * as reducerConstants from '../../constants/reducer.constants';

export const getPointShopItems = (state) => state.getIn([reducerConstants.POINT_SHOP_BRANCH, constants.POINT_SHOP_ITEMS]);
export const getPointShopItem = (state) => state.getIn([reducerConstants.POINT_SHOP_BRANCH, constants.POINT_SHOP_ITEM]);
export const getPointShopItemsAnalysis = (state) => state.getIn([reducerConstants.POINT_SHOP_BRANCH, constants.POINT_SHOP_ITEMS_ANALYSIS]);
export const getPointShopAnalysisSuccess = (state) => state.getIn([reducerConstants.POINT_SHOP_BRANCH, constants.POINT_SHOP_ITEMS_ANALYSIS_IN_PROGRESS]);
export const getPointShopItemCreated = (state) => state.getIn([reducerConstants.POINT_SHOP_BRANCH, constants.POINT_SHOP_ITEMS_CREATED]);
export const getPointShopItemError = (state) => state.getIn([reducerConstants.POINT_SHOP_BRANCH, constants.POINT_SHOP_ITEMS_ERROR]);
export const getPointShopItemImageError = (state) => state.getIn([reducerConstants.POINT_SHOP_BRANCH, constants.POINT_SHOP_ITEM_IMAGE_ERROR]);
