/* eslint-disable no-underscore-dangle */
import './mixpanel';
import { trackerBase } from '../trackerBase';

export class MixpanelTracker extends trackerBase {

  _trackImpl(event, trackArgs) {
    mixpanel.track(event, trackArgs);
  }

  /**
   * adjust metadata map to fit mixpanel conventions/requirements
   */
  _getMetadata() {
    const metadata = super._getMetadata();
    delete metadata.userFeatures;
    delete metadata.email;
    delete metadata.isComoUser;
    return metadata;
  }

  initialize() {
    super.initialize();
    // identify the user by email:
    this.initialized
      .then(() => this._onInitialized())
      .catch(() => {}); // suppress error messages
  }

  _onInitialized() {
    const { email: $email, firstName: $first_name, lastName: $last_name, isComoUser } = this.user;
    if (!this.trackerConfig.apiKey) {
      console.warn('mixpanel API key not set, mixpanel won\'t work...');
    } else {
      mixpanel.init(this.trackerConfig.apiKey);
    }
    window.mixpanel.identify($email);
    const userData = {
      $email,
      $first_name,
      $last_name,
      isComoUser,
      'Browser Language': window.navigator.language
    };
    this.userFeatures.forEach((feature) => {
      userData[`Feature: ${feature.name}`] = feature.level;
    });
    mixpanel.people.set(userData);
  }
}
