import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import * as activityConstants from '../../Activity/activity.constants';
import ContextMenu from '../../../common/components/ContextMenu/ContextMenu';
import * as selectors from '../gifts.selectors';

class GiftContextMenu extends React.PureComponent {
  static propTypes = {
    onChangeActivityStatus: PropTypes.func.isRequired,
    onDeleteGift: PropTypes.func.isRequired,
    onDetailsOpen: PropTypes.func.isRequired,
    onAnalysisOpen: PropTypes.func.isRequired,
    onUsagesOpen: PropTypes.func.isRequired,
    onOpenInNewTab: PropTypes.func.isRequired,
    gift: PropTypes.object.isRequired,
    hidden: PropTypes.bool,
    automationId: PropTypes.string,
    intl: PropTypes.object
  };

  getMenuOptions() {
    const activityStatus = this.props.gift.get(activityConstants.STATUS);
    const visibleContextMenuItems = selectors.getContextMenuItemsVisibilityMap(activityStatus);
    const { formatMessage } = this.props.intl;
    const options = [];

    if (visibleContextMenuItems[activityConstants.CONTEXT_MENU_OPTION_DETAILS]) {
      options.push({
        key: 'activity-details',
        iconName: 'como-ic-info',
        text: formatMessage({ id: 'gifts-page.activity.menu.activity-details' }),
        cb: this.props.onDetailsOpen
      });
    }

    if (visibleContextMenuItems[activityConstants.CONTEXT_MENU_OPTION_STATS]) {
      options.push({
        key: 'gift-stats',
        iconName: 'como-ic-activity-analysis',
        text: formatMessage({ id: 'gifts-page.activity.menu.analysis' }),
        cb: this.props.onAnalysisOpen
      });
    }

    if (visibleContextMenuItems[activityConstants.CONTEXT_MENU_OPTION_USAGES]) {
      options.push({
        key: 'gift-usage',
        iconName: 'como-ic-related-activities',
        text: formatMessage({ id: 'gifts-page.activity.menu.related.activities' }),
        cb: this.props.onUsagesOpen
      });
    }

    if (visibleContextMenuItems[activityConstants.CONTEXT_MENU_OPTION_ACTIVATE]) {
      options.push({
        key: 'activate',
        iconName: 'como-ic-ok',
        text: formatMessage({ id: 'gifts-page.activity.menu.activate' }),
        cb: () => this.props.onChangeActivityStatus(
          this.props.gift.set(activityConstants.STATUS, activityConstants.ACTIVITY_STATUS_ACTIVE))
      });
    }
    if (visibleContextMenuItems[activityConstants.CONTEXT_MENU_OPTION_OPEN_IN_NEW_TAB]) {
      options.push({
        key: 'openInNewTab',
        className: 'benefit-action-list-item-set-up-key',
        iconName: 'como-ic-arrow-right',
        text: formatMessage({ id: 'business-center.campaign.activity.menu.openInNewTab' }),
        cb: () => this.props.onOpenInNewTab(this.props.gift)
      });
    }
    if (visibleContextMenuItems[activityConstants.CONTEXT_MENU_OPTION_DEACTIVATE]) {
      options.push({
        key: 'deactivate',
        iconName: 'como-ic-deactivate',
        text: formatMessage({ id: 'gifts-page.activity.menu.stop' }),
        cb: () => this.props.onChangeActivityStatus(
          this.props.gift.set(activityConstants.STATUS, activityConstants.ACTIVITY_STATUS_INACTIVE))
      });
    }

    if (visibleContextMenuItems[activityConstants.CONTEXT_MENU_OPTION_DELETE]) {
      options.push({
        key: 'delete',
        iconName: 'como-ic-delete',
        text: formatMessage({ id: 'business-center.campaign.activity.menu.delete' }),
        cb: () => this.props.onDeleteGift(this.props.gift.get(activityConstants.HUB_ID))
      });
    }

    return options;
  }

  render() {
    return (<ContextMenu
      options={this.getMenuOptions()}
      automationId={this.props.automationId}
      hidden={this.props.hidden}
    />);
  }
}

export default injectIntl(GiftContextMenu);
