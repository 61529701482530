/**
 *
 * PasteInputField
 *
 */
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Icon, Loader } from 'semantic-ui-react';
import InputWrapper from './../InputWrapper/InputWrapper';
import './PasteInputField.styles.less';

class PasteInputField extends React.PureComponent {

  static propTypes = {
    clearValue: PropTypes.func,
    error: PropTypes.string,
    inputValue: PropTypes.string,
    goingToPasteImportMembersData: PropTypes.func,
    name: PropTypes.string,
    onPaste: PropTypes.func,
    prefix: PropTypes.string,
    showLoader: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.onPaste = this.onPaste.bind(this);
  }

  onPaste(e, data) {
    this.props.goingToPasteImportMembersData();
    const pastedData = e.clipboardData.getData('text/plain');
    e.preventDefault();
    setTimeout(() => this.props.onPaste(pastedData));
  }

  render() {

    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const { name, prefix, error } = this.props;
    return (
      <div>
        <div className="paste-input-line">
          <InputWrapper
            allowTyping={false}
            className="paste-input"
            disabled={!!this.props.inputValue}
            error={error}
            name={name}
            onPaste={this.onPaste}
            prefix={prefix}
            value={this.props.inputValue}
            placeholder={formatMessage({ id: 'activity.globalConditions.members.radioBtn.import.members.paste.placeholder' })}
          />
          {this.props.showLoader &&
            <Loader active size="mini" className="paste-loader" />
          }
          {this.props.inputValue.length > 0 &&
            <Icon
              className="como-ic-delete"
              onClick={this.props.clearValue}
            />
          }
        </div>
      </div>
    );
  }
}

export default injectIntl(PasteInputField);
