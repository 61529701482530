import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import * as constants from '../emails.constants';
import * as featuresConstants from '../../features.constants';
import EmailStatsDetailed from './EmailStatsDetailed';

class EmailStatsRule extends React.PureComponent {
  static propTypes = {
    ruleStats: PropTypes.object,
    statisticsPerRule: PropTypes.object,
    getDetailedStatsPerRow: PropTypes.func,
    showCampaignStats: PropTypes.bool,
    statsTypePrefix: PropTypes.string
  };

  render() {
    const {formatMessage} = this.props.intl; // eslint-disable-line
    const idName = this.props.statsTypePrefix === 'microCampaign' ? constants.MICRO_CAMPAIGN_ID : constants.RULE_ID;
    return (
      <Grid.Row>
        <Grid.Column width={16}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                {formatMessage({ id: `email-stats.sentFromRule.${this.props.statsTypePrefix}` })}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="stats-title">
              <Grid.Column width={16}>
                <span
                  className="log--title stats rule"
                  data-automation-id="email-stats.date.key"
                >{formatMessage({ id: 'email-stats.name.title' })}</span>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className={`stats-data ${this.props.ruleStats && this.props.ruleStats.size ? '' : 'no-border'}`}>
              <Grid.Column width={16}>
                {
                  this.props.ruleStats && this.props.ruleStats.map((statsElem, index) => (
                    <Grid key={`statsElem_${index.toString()}`} className="stats-elem-wrap">
                      <Grid.Row className="email-statistics-row link" onClick={() => this.props.getDetailedStatsPerRow(statsElem.get(constants.EMAIL_STATS_ACTIVITY_ID), this.props.statsTypePrefix)}>
                        <Grid.Column width={14}>{statsElem.get(constants.EMAIL_STATS_ACTIVITY_NAME)}</Grid.Column>
                        <Grid.Column width={2} className="toggle-campaign-stats">
                          <Icon className={`${this.props.statisticsPerRule && this.props.statisticsPerRule.get(featuresConstants.DATA) && this.props.statisticsPerRule.get(idName) === statsElem.get(constants.EMAIL_STATS_ACTIVITY_ID) && this.props.showCampaignStats ? 'como-ic-angle-up' : 'como-ic-angle-down'}`}></Icon>
                        </Grid.Column>
                      </Grid.Row>
                      { this.props.statisticsPerRule && this.props.statisticsPerRule.get(featuresConstants.DATA) && this.props.statisticsPerRule.get(idName) === statsElem.get(constants.EMAIL_STATS_ACTIVITY_ID) &&
                      <Grid.Row className={`campaign-stats ${this.props.showCampaignStats ? 'show' : 'hide'}`}>
                        <Grid.Column width={16}>
                          <div>
                            {
                              this.props.statisticsPerRule && this.props.statisticsPerRule.get(featuresConstants.DATA) && this.props.statisticsPerRule.get(featuresConstants.DATA).map((statsCampaignElem, campIndex) => (
                                <EmailStatsDetailed
                                  key={`stats_${campIndex}`}
                                  statsCampaignElem={statsCampaignElem}
                                  campIndex={campIndex}
                                />
                              ))
                            }
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                      }

                    </Grid>))
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    );
  }
}
export default injectIntl(EmailStatsRule);
