/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { List } from 'immutable';
import { connect } from 'react-redux';
import ConditionListOfValues from './conditionListOfValues';
import * as schemaConstants from '../../activitySchema/activitySchema.constants';
import * as appSelectors from '../../../App/selectors';
import * as constants from '../../activity.constants';

class LocationsGroupsListCondition extends React.PureComponent {

  static propTypes = {
    disabled: PropTypes.bool,
    index: PropTypes.number,
    value: PropTypes.array,
    onChange: PropTypes.func,
    prefix: PropTypes.string,
    error: PropTypes.string,
    isSearchable: PropTypes.bool,
    locationsGroups: PropTypes.array,
    conditionType: PropTypes.string
  };

  getTag() {
    return this.props.conditionType === schemaConstants.CONTEXT_MEMBERSHIP_PREFERRED_LOCATION
      ? schemaConstants.LOCATIONS_TAG_PREFERRED
      : schemaConstants.LOCATIONS_TAG_SECOND_PREFERRED;
  }

  get locationsGroupsOptions() {
    const locationsGroups = this.props.locationsGroups || List();
    const result = locationsGroups.map((locationsGroup) => {
      const locationName = locationsGroup.get(constants.LOCATIONS_GROUP_NAME);
      const value = locationsGroup.get(constants.LOCATIONS_GROUP_Id);
      return {
        value,
        label: locationName
      };
    });
    return result.toJS();
  }

  render() {

    return (<ConditionListOfValues
      {...this.props}
      type={schemaConstants.VALUE_TYPE_LOCATIONS}
      dynamicOptions={this.locationsGroupsOptions}
      multiple
    />);
  }
}

const mapStateToProps = (state) => ({
  locationsGroups: appSelectors.getLocationsGroups(state)
});

const withConnect = connect(mapStateToProps, null);

export default compose(
  withConnect,
)(LocationsGroupsListCondition);
