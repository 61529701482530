import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import '../templates.styles.less';
import BasicCard from '../../../common/components/Cards/BasicCard/BasicCard';
import SaveTemplate from './SaveTemplate/SaveTemplate';
import * as activityTemplatesConstants from '../templates.constants';
import { FEATURE_LEVEL } from '../../Activity/activity.constants';
import withAuthorization from '../../../common/helpers/authorization';
import ConfirmationDialog from '../../../common/components/ConfirmationDialog/ConfirmationDialog';
import * as commonConstants from '../../../common/components/common.components.constants';
import * as emailsConstants from '../../Emails/emails.constants';

const AUTOMATION_PREFIX_NAME = 'template-card';

class TemplateCard extends React.PureComponent {
  static propTypes = {
    template: PropTypes.object.isRequired,
    templateType: PropTypes.string.isRequired,
    prefix: PropTypes.string,
    categories: PropTypes.object,
    onClick: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    entityType: PropTypes.string.isRequired,
    getPermissionLevel: PropTypes.func.isRequired,
    intl: PropTypes.object
  };

  handleChildClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopPropagation();
  };

  handleEdit = (e) => {
    e.preventDefault();
  };

  handleDelete = () => {
    const { template, onDelete, entityType } = this.props;
    onDelete(template.get(activityTemplatesConstants.DB_ID), entityType);
  };

  render() {
    const { intl, onClick, onEdit, template, categories, prefix, getPermissionLevel, templateType, entityType } = this.props;
    const templateId = template.get(activityTemplatesConstants.DB_ID);
    const templateName = template.get(activityTemplatesConstants.TEMPLATE_NAME);
    const templateDescription = template.get(activityTemplatesConstants.TEMPLATE_DESCRIPTION);
    const level = getPermissionLevel('Product Templates');
    const hasContextMenu = level === FEATURE_LEVEL.FULL;
    const entity = entityType !== emailsConstants.EMAIL ? template.get(activityTemplatesConstants.ACTIVITY) : template.get(emailsConstants.EMAIL);
    return (
      <BasicCard id={templateId} onClick={onClick} automationId={`${prefix}.${AUTOMATION_PREFIX_NAME}`}>
        <div className="card-header">
          <div className="card-title">{templateName}</div>
          {(!template.get('categoryDbId') || hasContextMenu) && // Checking if it's not a business template (category is unique to product templates)
            <Dropdown
              className="float-right dropdown-container"
              data-automation-id={`${prefix}.${AUTOMATION_PREFIX_NAME}.dropdown`}
              icon={
                <Icon className="como-ic-more" size="small" onClick={this.handleChildClick} />
            }
              onClick={this.handleChildClick}
            >
              <Dropdown.Menu>
                <Dropdown.Item>
                  <SaveTemplate
                    name={templateName}
                    description={templateDescription}
                    id={templateId}
                    saveAsTemplate={onEdit}
                    entity={entity}
                    entityType={entityType}
                    templateType={templateType}
                    trigger={
                      <Dropdown.Item
                        text={intl.formatMessage({ id: 'general.menu.edit' })}
                        onClick={this.handleEdit}
                        data-automation-id={`${prefix}.${AUTOMATION_PREFIX_NAME}.menu.edit.trigger`}
                      />
                }
                    categoryDbId={template.get(activityTemplatesConstants.CATEGORY_DB_ID)}
                    templateVerticals={template.get(activityTemplatesConstants.TEMPLATE_VERTICALS)}
                    categories={categories}
                  />
                </Dropdown.Item>
                <Dropdown.Item>
                  <ConfirmationDialog
                    automationIdPrefix={`${prefix}.${AUTOMATION_PREFIX_NAME}.menu.delete.trigger`}
                    confirmButtonKey="general.menu.delete"
                    triggerLabelKey="general.menu.delete"
                    contentMessageKey="template.delete.phrases.are-you-sure"
                    titleKey="template.delete.are-you-sure.header"
                    confirmAction={this.handleDelete}
                    triggerType={commonConstants.COMPONENT_TYPE_TEXT}
                  />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          }
        </div>
        <div
          className="card-description"
          data-automation-id={`${prefix}.${AUTOMATION_PREFIX_NAME}.description`}
        >
          {templateDescription}
        </div>

      </BasicCard>
    );
  }
}

export default withAuthorization(injectIntl(TemplateCard));
