import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formsy-semantic-ui-react';

const GoalMetaData = ({ name, icon, onChangeName, onChangeIcon }) => (
  <Form.Group widths="equal">
    <Form.Input
      name="goalName"
      data-automation-id="goals.admin.form.name"
      onChange={(e) => {
        const { value = '' } = e.target;
        return onChangeName(value.trim());
      }}
      fluid
      label="Goal Name"
      placeholder="name"
      value={name}
      required
    />
    <Form.Input
      name="goalIcon"
      data-automation-id="goals.admin.form.icon"
      onChange={(e) => {
        const { value = '' } = e.target;
        return onChangeIcon(value.trim());
      }}
      fluid
      label="Goal Icon"
      placeholder="wake"
      value={icon}
      required
    />
  </Form.Group>);

GoalMetaData.propTypes = {
  onChangeName: PropTypes.func.isRequired,
  onChangeIcon: PropTypes.func.isRequired,
  name: PropTypes.string,
  icon: PropTypes.string
};

export default GoalMetaData;
