/**
 * Example:
 <DaysAndTimes
 timeTo={{hour: 2, minute: 22, format: "am"}}
 timeFrom={{hour: 1, minute: 12, format: "pm"}}
 daysActive={[1, 3]}
 onChange={this.handlerDaysAndTime} isMilitaryTime={true}
 allDayName={"all"}
 timesName={{from: "aa", to: "To"}}
 content={{days: {contentWidth: 12}, times: {contentWidth: 4}}}
 days={[
    {id: 1, name: 'Sun'},
    {id: 2, name: 'Mon'},
    {id: 3, name: 'Tue'},
    {id: 4, name: 'Wed'},
    {id: 5, name: 'Thu'},
    {id: 6, name: 'Fri'},
    {id: 7, name: 'Sat'}
  ]}
 startIndex={1}
 />

 Props:

 mandatory:

 onChange = function,
 days = [{id=number, name=string},...] => default []
 index = number => default 0

 optional:

 daysActive = [id = number, id,...] => default []: activated days
 timeFrom = {hour=integer, minute=integer, format=string} => see DEFAULT_TIME_IS_MILITARY object
 timeTo = {hour=integer, minute=integer, format=string} => see DEFAULT_TIME_IS_MILITARY object
 isMilitaryTime = boolean => default see IS_MILITARY_TIME object: if equals to true 24 hour format otherwise 12
 allDayName = string: label of all day field that field choose all day see ALL_DAY_IS_MILITARY object if doesn't exist will be null.
 timesName = {from=string, to=string}: labels for timeFrom and timeTo
 startIndex = number: started point of days array.
 content = {days=object, times=object}: can be defined a width of days and times and their labels

 */

import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import PopupWrapper from '../PopupWrapper/PopupWrapper';
import SelectableDaysOfWeek from '../SelectableDaysOfWeek/SelectableDaysOfWeek';
import HourAndMinute from '../HourAndMinute/HourAndMinute';
import './DaysAndTimes.styles.less';
import PopupTrigger from '../PopupTrigger/PopupTrigger';
import { representTimeFromTo } from '../../utils/dateTimeUtils';
import ValidationErrorMessage from '../ValidationErrorMessage/ValidationErrorMessage';

const IS_MILITARY_TIME = false;

const DEFAULT_TIME_IS_MILITARY = {
  [false]: genTimes(genTime(12, 0), genTime(11, 59)),
  [true]: genTimes(genTime(0, 0), genTime(23, 59))
};

const ALL_DAY_IS_MILITARY = {
  [false]: genTimes(genTime(12, 0, 'am'), genTime(11, 59, 'pm')),
  [true]: genTimes(genTime(0, 0), genTime(23, 59))
};

function genTime(hour, minute, format) {
  return {
    hour,
    minute,
    format
  };
}

function genTimes(timeFrom, timeTo) {
  return { timeFrom, timeTo };
}

function propTimeValidation() {
  return PropTypes.shape({ hour: PropTypes.number, minute: PropTypes.number, format: PropTypes.string });
}

function propContentParamValidation() {
  return PropTypes.shape({
    label: PropTypes.shape({ name: PropTypes.string, width: PropTypes.number }),
    contentWidth: PropTypes.number
  });
}

class DaysAndTimes extends React.Component {

  static propTypes = {
    automationIdPrefix: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    intl: PropTypes.object,
    daysActive: PropTypes.arrayOf(PropTypes.number),
    timeFrom: propTimeValidation(),
    timeTo: propTimeValidation(),
    isMilitaryTime: PropTypes.bool,
    allDayName: PropTypes.string,
    timesName: PropTypes.shape({ from: PropTypes.string, to: PropTypes.string }),
    startIndex: PropTypes.number,
    errors: PropTypes.array,
    content: PropTypes.shape({
      days: propContentParamValidation(),
      times: propContentParamValidation()
    })
  };

  static defaultProps =  {
    timeFrom: DEFAULT_TIME_IS_MILITARY[IS_MILITARY_TIME].timeFrom,
    timeTo: DEFAULT_TIME_IS_MILITARY[IS_MILITARY_TIME].timeTo,
    daysActive: [],
    isMilitaryTime: IS_MILITARY_TIME,
    timesName: {},
    index: 0,
    content: {
      days: {
        label: {
          width: 0
        },
        contentWidth: 10
      },
      times: {
        label: {
          width: 0
        },
        contentWidth: 5
      }
    }
  };

  state = {
    isPopupOpen: false
  };

  getTriggerMessage(timeFrom, timeTo) {
    if ([timeFrom.hour, timeFrom.minute, timeTo.hour, timeTo.minute].some(isNaN)) {
      return null;
    }
    return representTimeFromTo(timeFrom, timeTo);
  }

  handlerPopupOpen = () => {
    this.setState({ isPopupOpen: true });
  };
  handlerPopupClose = () => {
    this.setState({ isPopupOpen: false });
  };
  handlerHourAndMinuteFrom = (timeFrom) => {
    const { onChange, timeTo, daysActive, index } = this.props;
    return onChange({ data: { daysActive, timeFrom, timeTo }, index });
  };
  handlerHourAndMinuteTo = (timeTo) => {
    const { onChange, timeFrom, daysActive, index } = this.props;
    return onChange({ data: { daysActive, timeFrom, timeTo }, index });
  };
  handlerAllDay = () => {
    const { onChange, daysActive, isMilitaryTime, index } = this.props;

    onChange({ data: {
      daysActive,
      timeFrom: ALL_DAY_IS_MILITARY[isMilitaryTime].timeFrom,
      timeTo: ALL_DAY_IS_MILITARY[isMilitaryTime].timeTo },
      index
    });

    this.handlerPopupClose();
  };
  handlerDays = (daysActive) => {
    const { onChange, timeFrom, timeTo, index } = this.props;
    onChange({ data: { daysActive, timeFrom, timeTo }, index });
  };

  renderPopupTrigger() {
    const { timeFrom, timeTo, index, automationIdPrefix, errors } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className="inline-div">
        <PopupTrigger
          className="without--margin selected-option selection dropdown ui"
          automationId={`${automationIdPrefix}.${index}.represent_time_from_to`}
          message={this.getTriggerMessage(timeFrom, timeTo)}
        />
        {
          errors && errors.get(0) ?
            <ValidationErrorMessage
              errorMessage={formatMessage({ id: `${this.props.automationIdPrefix}.0.error.${errors.get(0)}` })}
              dataAutomationId={`${this.props.automationIdPrefix}.0.error.message`}
            />
            : null
        }
      </div>
    );
  }

  renderTimeContainer(label, { hour, minute, format } = {}, callback, i) {
    const { isMilitaryTime, index, automationIdPrefix } = this.props;
    return (
      <div className="container-days-and-times--select-time-between__time">
        <label>{label}</label>
        <HourAndMinute
          isMilitary={isMilitaryTime}
          format={format}
          hour={hour}
          minute={minute}
          onChange={callback}
          index={i}
          prefix={`${automationIdPrefix}.${index}`}
        />
      </div>
    );
  }

  renderAllDay() {
    const { allDayName, index, automationIdPrefix } = this.props;
    if (!_.isString(allDayName) || allDayName.length < 1) { return null; }
    return (
      <p
        className="container-days-and-times--select-all"
        data-automation-id={`${automationIdPrefix}.${index}.all_day`}
        onClick={this.handlerAllDay}
      >
        {allDayName}
      </p>
    );
  }

  renderTimes() {
    const { timesName, timeFrom, timeTo, isMilitaryTime } = this.props;

    return (
      <PopupWrapper
        on="click"
        className="container-days-and-times-popup without--padding"
        basic
        fixed
        position={'bottom right'}
        size="large"
        flowing
        trigger={this.renderPopupTrigger()}
        onClose={this.handlerPopupClose}
        onOpen={this.handlerPopupOpen}
        open={this.state.isPopupOpen}
        automationId={`${this.props.automationIdPrefix}.popup`}
      >
        <div
          className={isMilitaryTime ? 'container-days-and-times-content-military' : 'container-days-and-times-content'}
        >
          {this.renderAllDay()}
          <div className="container-days-and-times--select-time-between">
            {this.renderTimeContainer(timesName.from, timeFrom, this.handlerHourAndMinuteFrom, 0)}
            {this.renderTimeContainer(timesName.to, timeTo, this.handlerHourAndMinuteTo, 1)}
          </div>
        </div>
      </PopupWrapper>
    );
  }

  renderGridColumn(width, content) {

    if (!width) {
      return null;
    }

    return (<Grid.Column width={width}>{content}</Grid.Column>);
  }

  renderDays() {
    const { startIndex, daysActive, index, automationIdPrefix } = this.props;
    return (
      <SelectableDaysOfWeek
        selected={daysActive}
        prefix={`${automationIdPrefix}.${index}.days`}
        startIndex={startIndex}
        onChange={this.handlerDays}
      />
    );
  }

  render() {
    const { days: contentDays = {}, times: contentTimes = {} } = this.props.content;
    const { label: labelDays = {}, contentWidth: contentWidthDays } = contentDays;
    const { label: labelTimes = {}, contentWidth: contentWidthTimes } = contentTimes;

    return (
      <Grid className="container-days-and-times">
        <Grid.Row verticalAlign="middle">
          {this.renderGridColumn(labelDays.width, labelDays.name)}
          {this.renderGridColumn(contentWidthDays, this.renderDays())}
          {this.renderGridColumn(labelTimes.width, labelTimes.name)}
          <Grid.Column width={1} className="preposition">at</Grid.Column>
          {this.renderGridColumn(contentWidthTimes, this.renderTimes())}
        </Grid.Row>
      </Grid>
    );
  }
}

export default injectIntl(DaysAndTimes);
