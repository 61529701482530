import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Button, Icon, Radio } from 'semantic-ui-react';
import _ from 'lodash';
import IntegerInputField from '../../../../../common/components/FormInputFields/IntegerInputField/IntegerInputField';
import Modal from '../../../../../common/components/CustomModal/CustomModal';
import DropDown from '../../../../../common/components/DropdownWrapper/DropdownWrapper';
import * as constants from '../../../activity.constants';
import './DelayContent.styles.less';
import * as dateConstants from '../../../../../common/utils/dateTime.constants';

const AUTOMATION_PREFIX = 'delay';
const VALUE_DAY_MAX = 30;
const DAY_IN_SECONDS = 86400;
const DAY_IN_MINUTES = 1440;
const DAY_IN_HOURS = 24;
const DAYS_IN_A_WEEK = 7;
const DEFAULT_VALUE_MINIMUM = 1;
const DEFAULT_UNITS = dateConstants.HOUR;
const OPTIONS_TYPES = [dateConstants.SECOND, dateConstants.MINUTE, dateConstants.HOUR, dateConstants.DAY, dateConstants.WEEK];

function genOptionName(value, text) {
  return { value, text };
}

function isTypeValid(props) {

  const { units } = props;

  if (!_.isUndefined(units) && !OPTIONS_TYPES.includes(units)) {
    return new Error('units have an invalid format');
  }

  return null;
}

class DelayContent extends React.PureComponent {
  static propTypes = {
    automationIdPrefix: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    onActionFieldUpdate: PropTypes.func,
    units: isTypeValid,
    value: PropTypes.number,
    actionsWrapperIndex: PropTypes.number
  };

  constructor(props) {
    super(props);
    const units = props.units || DEFAULT_UNITS;
    const value = props.value || DEFAULT_VALUE_MINIMUM;
    const isImmediate = !props.units || !props.value;
    const validationError = this.validateDelay(units, value, isImmediate);
    this.state = {
      isModalOpen: false,
      isImmediate,
      units,
      value,
      validationError
    };
  }

  componentWillReceiveProps(nextProps) {
    const { units, value } = this.props;

    const newState = {};

    if (units !== nextProps.units || !nextProps.units) {
      newState.units = nextProps.units || DEFAULT_UNITS;
    }

    if (value !== nextProps.value || !nextProps.value) {
      newState.value = nextProps.value ? nextProps.value : DEFAULT_VALUE_MINIMUM;
    }

    this.setState({ ...newState, isImmediate: !nextProps.value || !nextProps.units });
  }

  isImmediateProp() {
    const { value, units } = this.props;
    return !units || !value;
  }

  handlerRadioGroup = () => {
    const validationError = this.validateDelay(this.state.units, this.state.value, !this.state.isImmediate);
    this.setState({
      isImmediate: !this.state.isImmediate,
      validationError
    });
  };

  handlerCounterChange = (e, value = {}) => {
    const validationError = this.validateDelay(this.state.units, value.value, this.state.isImmediate);
    this.setState({
      value: value.value,
      validationError
    });
  };

  validateDelayPeriod(units, value) {
    let days;
    switch (units) {
      case dateConstants.SECOND:
        days = value / DAY_IN_SECONDS;
        break;
      case dateConstants.MINUTE:
        days = value / DAY_IN_MINUTES;
        break;
      case dateConstants.HOUR:
        days = value / DAY_IN_HOURS;
        break;
      case dateConstants.DAY:
        days = value;
        break;
      case dateConstants.WEEK:
        days = value * DAYS_IN_A_WEEK;
        break;
      default:
        return false;
    }
    return days <= VALUE_DAY_MAX;
  }

  validateDelay(units, value, isImmediate) {
    let validationError = null;
    if (isImmediate) {
      return null;
    } else if (_.isNil(value)) {
      validationError = 'activity.validation.error.action.delay.required';
    } else if (value === 0) {
      validationError = 'activity.validation.error.action.delay.value.must.be.positive';
    } else if (!this.validateDelayPeriod(units, value)) {
      validationError = 'activity.validation.error.action.delay.too.big';
    }
    return validationError;
  }

  handlerSelectionOption = (e, data) => {
    const validationError = this.validateDelay(data.value, this.state.value, this.state.isImmediate);
    this.setState({
      units: data.value,
      validationError
    });
  };
  handlerModalOpen = () => {
    this.setState({
      isModalOpen: true
    });
  };
  handlerModalClose = () => {
    this.setState({
      isModalOpen: false
    });
  };
  handlerSave = (e) => {
    const { units, value, isImmediate } = this.state;
    const { index, onActionFieldUpdate } = this.props;

    if (isImmediate) {
      onActionFieldUpdate(constants.DELAY, null, index, this.props.actionsWrapperIndex);
      this.reset();
      this.handlerModalClose();
      return;
    }

    const validationError = this.validateDelay(units, value, isImmediate);
    this.setState({ validationError });
    if (validationError) {
      return;
    }
    onActionFieldUpdate(constants.DELAY, { units, number: value }, index, this.props.actionsWrapperIndex);
    this.handlerModalClose();
  };

  reset() {
    const { units, value } = this.props;

    this.setState({
      units: units || DEFAULT_UNITS,
      value: value || DEFAULT_VALUE_MINIMUM,
      validationError: null,
      isImmediate: this.isImmediateProp()
    });
  }

  handlerCancel = () => {
    this.reset();

    this.handlerModalClose();
  };

  modalTriggerHtml() {
    const { intl, value, units, automationIdPrefix } = this.props;
    const { formatMessage } = intl;
    const TYPES_TRIGGER = {
      [dateConstants.SECOND]: 'activity.action.delay.options.value.trigger.seconds',
      [dateConstants.MINUTE]: 'activity.action.delay.options.value.trigger.minutes',
      [dateConstants.HOUR]: 'activity.action.delay.options.value.trigger.hours',
      [dateConstants.DAY]: 'activity.action.delay.options.value.trigger.days',
      [dateConstants.WEEK]: 'activity.action.delay.options.value.trigger.weeks'
    };
    const className = !this.isImmediateProp() ? "delayed_modal" : "immediate_modal";
    return (
      <span
        role="button"
        className={className + " action-link "}
        data-automation-id={`${automationIdPrefix}.${AUTOMATION_PREFIX}.open.modal`}
      >
        <Icon className="como-ic-alarm-clock" />
        <span>{!this.isImmediateProp() ? formatMessage({ id: TYPES_TRIGGER[units] }, { value }) : formatMessage({ id: 'activity.action.delay.value.trigger.immediate' })}</span>
      </span>
    );
  }

  modalContentHtml() {
    const CLASS_NAME = 'container-delay-content';
    const { intl, automationIdPrefix } = this.props;
    const { formatMessage } = intl;
    const { units, value, isImmediate } = this.state;

    return (
      <div className={CLASS_NAME}>
        <div>
          <p>{formatMessage({ id: 'activity.action.delay.title' })}:</p>
          <Radio
            label={formatMessage({
              id: 'activity.action.delay.immediately'
            })}
            data-automation-id={`${automationIdPrefix}.${AUTOMATION_PREFIX}.radio.immediately`}
            name="delayGroup"
            checked={isImmediate}
            onChange={this.handlerRadioGroup}
          />
          <div className={`${CLASS_NAME}__after`}>
            <Radio
              label={formatMessage({
                id: 'activity.action.delay.after'
              })}
              name="delayGroup"
              data-automation-id={`${automationIdPrefix}.${AUTOMATION_PREFIX}.radio.after`}
              checked={!isImmediate}
              onChange={this.handlerRadioGroup}
            />
            <div className={`${CLASS_NAME}__after__content`}>
              <IntegerInputField
                className="numerical-input"
                value={value}
                onChange={this.handlerCounterChange}
                prefix={`${automationIdPrefix}.${AUTOMATION_PREFIX}.input.after.value`}
                name="counter"
                disabled={isImmediate}
                error={this.state.validationError}
              />
              <DropDown
                options={[
                  genOptionName(dateConstants.SECOND, formatMessage({ id: 'activity.action.delay.options.value.seconds' })),
                  genOptionName(dateConstants.MINUTE, formatMessage({ id: 'activity.action.delay.options.value.minutes' })),
                  genOptionName(dateConstants.HOUR, formatMessage({ id: 'activity.action.delay.options.value.hours' })),
                  genOptionName(dateConstants.DAY, formatMessage({ id: 'activity.action.delay.options.value.days' })),
                  genOptionName(dateConstants.WEEK, formatMessage({ id: 'activity.action.delay.options.value.weeks' }))
                ]}
                name="value"
                prefix={`${automationIdPrefix}.${AUTOMATION_PREFIX}.dropdown`}
                hideTrigger
                onSelectOption={this.handlerSelectionOption}
                selection
                value={units}
                disabled={isImmediate}
                error={this.state.validationError ? '' : null}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  modalContentActions() {
    const { formatMessage } = this.props.intl;
    const { automationIdPrefix } = this.props;
    return [
      <Button
        key={0}
        basic
        data-automation-id={`${automationIdPrefix}.${AUTOMATION_PREFIX}.btn.cancel`}
        onClick={this.handlerCancel}
      >
        {formatMessage({ id: 'activity.action.delay.cancel' })}
      </Button>,
      <Button
        key={1}
        data-automation-id={`${automationIdPrefix}.${AUTOMATION_PREFIX}.btn.save`}
        onClick={this.handlerSave}
      >
        {formatMessage({ id: 'activity.action.delay.save' })}
      </Button>
    ];
  }

  render() {
    const { intl, automationIdPrefix } = this.props;
    const { formatMessage } = intl;
    return (
      <Modal
        className="modal__actions-delay"
        header={formatMessage({ id: 'activity.action.delay' })}
        content={this.modalContentHtml()}
        trigger={this.modalTriggerHtml()}
        onOpen={this.handlerModalOpen}
        onClose={this.handlerCancel}
        open={this.state.isModalOpen}
        actions={this.modalContentActions()}
        automationIdPrefix={`${automationIdPrefix}.${AUTOMATION_PREFIX}.delay`}
      >
      </Modal>
    );
  }
}

export default injectIntl(DelayContent);
