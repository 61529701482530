import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Icon, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import * as selectors from './../promoCodes.selectors';
import * as promoCodesActions from './../promoCodes.actions';
import * as errorActions from './../../ErrorMessage/errorMessage.actions';
import * as api from './../promoCodes.api';
import * as activityConstants from "../../Activity/activity.constants";

class PromoCodeBulkListItem extends Component {

  state = {
    isLoading: false
  };

  getBulkExport = () => {
    const { bulk, serverId } = this.props;

    this.setState({ isLoading: true }, () => {
      api.getExportBulk(bulk.get('key'), serverId).then((data) => {

        this.setState({ isLoading: false }, () => {
          if (data.error) {
            let errorMessage = 'promoCodes.bulk.export.fail';
            const error = data.error;
            if (error && error.response && error.response.data && error.response.data.errorMessage) {
              errorMessage = error.response.data.errorMessage;
            }
            this.props.errorActions.handleError([errorMessage], 'promoCodes.bulk.export.fail.header');
          } else {
            window.location.href = data.url;
          }
        });
      }).catch((error) => {
        this.setState({ isLoading: false }, () => {
          let errorMessage = 'promoCodes.bulk.export.fail';
          if (error && error.response && error.response.data && error.response.data.errorMessage) {
            errorMessage = error.response.data.errorMessage;
          }
          this.props.errorActions.handleError([errorMessage], 'promoCodes.bulk.export.fail.header');
        });
      });
    });

  };


  render() {
    const { bulk, index, serverId, intl, status } = this.props;
    const isTest = bulk.get('isTest');
    const bulkName = isTest ? intl.formatMessage({ id: 'promoCode.testBulk.name' }) : bulk.get('name');

    return (
      <li className="promo-bulk-data">
        <div className="bulk-name">{`${index + 1}. ${bulkName}`}</div>
        <div className="bulk-size">{bulk.get('size')}</div>
        <div className="bulk-controls">
          <div className="svg-icon-container">

            { isTest ?
              <Icon className="como-ic-view no-padding" onClick={() => { this.props.actions.getBulkList(bulk.get('key'), serverId); }} />
              :
              (this.state.isLoading ? <Loader active inline size="small" /> : <Icon className="como-ic-export no-padding" onClick={this.getBulkExport} />)
            }

          </div>
        </div>
        <div className="bulk-status">{status === activityConstants.ACTIVITY_STATUS_STOPPED || status === activityConstants.ACTIVITY_STATUS_INACTIVE ? intl.formatMessage({ id: 'activity.actions.benefits.inactive' }) : ''}</div>
      </li>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(promoCodesActions, dispatch),
  errorActions: bindActionCreators(errorActions, dispatch)
});

const withConnect = connect(null, mapDispatchToProps);

PromoCodeBulkListItem.propTypes = {
  bulk: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  actions: PropTypes.object,
  errorActions: PropTypes.object,
  serverId: PropTypes.string,
  status: PropTypes.string,
  intl: PropTypes.object
};

export default compose(withConnect, injectIntl)(PromoCodeBulkListItem);
