import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import GlobalConditions from './globalConditions/GlobalConditions';
import Conditions from './conditions';
import * as activityConstants from '../../activity.constants';
import * as schemaConstants from '../../activitySchema/activitySchema.constants';
import InstanceExpirationCondition from './globalConditions/instanceExpirationCondition/InstanceExpirationCondition';
import BirthdayOrAnniversaryEventTypeCondition
  from './globalConditions/birthdayOrAnniversaryEventTypeCondition/BirthdayOrAnniversaryEventTypeCondition';
import ClubMemberAnniversaryEventTypeCondition
  from './globalConditions/joinTheClubPeriodEventTypeCondition/ClubMemberAnniversaryEventTypeCondition';

class ConditionsContainer extends React.PureComponent {

  // eslint-disable-next-line no-undef
  static propTypes = {
    activityConditions: PropTypes.object.isRequired,
    conditionsList: PropTypes.object,
    label: PropTypes.bool,
    mainConditions: PropTypes.bool,
    trigger: PropTypes.string,
    addCondition: PropTypes.func.isRequired,
    deleteCondition: PropTypes.func.isRequired,
    updateConditionField: PropTypes.func.isRequired,
    schemaMembershipConditions: PropTypes.object,
    dateTimeGlobalConditions: PropTypes.object,
    conditionsValidationErrors: PropTypes.object,
    globalConditionsValidationErrors: PropTypes.object,
    disabledGlobalConditions: PropTypes.object,
    activityType: PropTypes.string,
    className: PropTypes.string,
    abTestMode: PropTypes.bool,
    isABTestingAllowed: PropTypes.bool,
    getBusinessBundlePlan: PropTypes.func.isRequired
  };

  render() {
    const conditionsSectionClasses = classNames(
      'conditions-section', {
        'no-add-condition': [activityConstants.ACTIVITY_TYPE_PUNCH_CARD, activityConstants.ACTIVITY_TYPE_GIFT].includes(this.props.activityType),
        [this.props.className]: this.props.className
      });

    const birthdayOrAnniversaryTriggerSelected = [
      schemaConstants.TRIGGER_MEMBER_HAS_ANNIVERSARY,
      schemaConstants.TRIGGER_MEMBER_HAS_BIRTHDAY,
    ].includes(this.props.trigger);
    const isLimitPerMemberGlobalConditionDisabled = [
      schemaConstants.TRIGGER_MEMBER_HAS_ANNIVERSARY,
      schemaConstants.TRIGGER_MEMBER_HAS_BIRTHDAY,
      schemaConstants.TRIGGER_CLUB_MEMBER_ANNIVERSARY,
    ].includes(this.props.trigger);
    return (
      <Grid>
        <Grid.Row className={conditionsSectionClasses}>
          <Grid.Column width={16}>
            <GlobalConditions
              activityTrigger={this.props.trigger}
              disabledGlobalConditions={this.props.disabledGlobalConditions}
              dateTimeGlobalConditions={this.props.dateTimeGlobalConditions}
              schemaMembershipConditions={this.props.schemaMembershipConditions}
              validationErrors={this.props.globalConditionsValidationErrors}
              limitPerMemberGlobalConditionDisabled={isLimitPerMemberGlobalConditionDisabled}
              abTestMode={this.props.abTestMode}
              isABTestingAllowed={this.props.isABTestingAllowed}
            />
            <Conditions
              activityConditions={this.props.activityConditions}
              addCondition={this.props.addCondition}
              automationIdPrefix="activity"
              conditionsList={this.props.conditionsList}
              deleteCondition={this.props.deleteCondition}
              label={this.props.label}
              mainConditions={this.props.mainConditions}
              schemaMembershipConditions={this.props.schemaMembershipConditions}
              trigger={this.props.trigger}
              updateConditionField={this.props.updateConditionField}
              validationErrors={this.props.conditionsValidationErrors}
              activityType={this.props.activityType}
              getBusinessBundlePlan={this.props.getBusinessBundlePlan}
            >
              {[activityConstants.ACTIVITY_TYPE_PUNCH_CARD, activityConstants.ACTIVITY_TYPE_GIFT].includes(this.props.activityType) &&
              <InstanceExpirationCondition
                automationIdPrefix="globalConditions.giftActivationDeactivation"
                isLastCondition={this.props.activityConditions.size === 0}
                showActivateDelayCondition={this.props.activityType !== activityConstants.ACTIVITY_TYPE_PUNCH_CARD}
              />
              }
            </Conditions>
            {birthdayOrAnniversaryTriggerSelected &&
            <BirthdayOrAnniversaryEventTypeCondition />
            }
            {this.props.trigger === schemaConstants.TRIGGER_CLUB_MEMBER_ANNIVERSARY &&
            <ClubMemberAnniversaryEventTypeCondition />
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default injectIntl(ConditionsContainer);

