import * as schemaConstants from './activitySchema.constants';

export const beaconSignalTypes = [
  { value: 'Enter', label: 'condition.context.beaconSignalTypes.enter', formatted: true },
  { value: 'Exit', label: 'condition.context.beaconSignalTypes.exit', formatted: true }
];

export const catalogItems = [
  { value: 'catalogItem1', label: 'condition.context.paymentFlow.catalogItem1', formatted: true },
  { value: 'catalogItem2', label: 'condition.context.paymentFlow.catalogItem2', formatted: true }
];

export const paymentFlows = [
  { value: 'inApp', label: 'condition.context.paymentFlow.inApp', formatted: true },
  { value: 'inStore', label: 'condition.context.paymentFlow.inStore', formatted: true },
];

export const filterPaymentFlows = [
  { value: 'PAY_IN_APP', label: 'condition.context.paymentFlow.inApp', formatted: true },
  { value: 'TOP_UP', label: 'condition.context.paymentFlow.topUp', formatted: true },
];

export const paymentFlowsInApp = [
  { value: 'PAY_IN_APP', label: 'condition.context.paymentFlow.inApp', formatted: true },
  { value: 'TOP_UP', label: 'condition.context.paymentFlow.topUp', formatted: true },
];

export const assetSourceList = [
  { value: 'PointsShop', label: 'condition.context.assetSource.pointShop', formatted: true },
  { value: 'Automation', label: 'condition.context.assetSource.rule', formatted: true },
  { value: 'Hub', label: 'condition.context.assetSource.findFilter', formatted: true },
];

export const membershipStatuses = [
  {
    value: schemaConstants.MEMBERSHIP_STATUS_VALUE_REGISTERED,
    label: 'activity.globalConditions.memberStatus.registered',
    formatted: true
  },
  {
    value: schemaConstants.MEMBERSHIP_STATUS_VALUE_PENDING,
    label: 'activity.globalConditions.memberStatus.pending',
    formatted: true
  },
  {
    value: schemaConstants.MEMBERSHIP_STATUS_VALUE_UNREGISTERED,
    label: 'activity.globalConditions.memberStatus.not_registered',
    formatted: true
  }
];

export const membershipConsentValues = [
  {
    value: schemaConstants.MEMBERSHIP_CONSENT_VALUE_YES,
    label: 'activity.globalConditions.consentValue.yes',
    formatted: true
  },
  {
    value: schemaConstants.MEMBERSHIP_CONSENT_VALUE_NO,
    label: 'activity.globalConditions.consentValue.no',
    formatted: true
  },
  {
    value: schemaConstants.MEMBERSHIP_CONSENT_VALUE_BUSINESS_APPROVAL,
    label: 'activity.globalConditions.consentValue.previous',
    formatted: true
  },
];

export const months =
  Object.keys(schemaConstants.MONTHS).map((monthName) => ({
    value: schemaConstants.MONTHS[monthName],
    label: `activity.conditions.months.${monthName.toLowerCase()}`,
    formatted: true
  }));
export const daysByMonths = schemaConstants.DAYS_BY_MONTH;

export const membershipRfmValues =
  Object.keys(schemaConstants.RFM_TAGS).map((rfmTag) => ({
    value: schemaConstants.RFM_TAGS[rfmTag],
    label: `activity.conditions.rfm.${rfmTag.toLowerCase()}`,
    formatted: true
  }));

export const membershipLastVisitValues =
  Object.keys(schemaConstants.TIME_SINCE_LAST_VISIT_TAGS).map((LastVisitTag) => ({
    value: schemaConstants.TIME_SINCE_LAST_VISIT_TAGS[LastVisitTag],
    label: `activity.conditions.LastVisitTag.${LastVisitTag.toLowerCase()}`,
    formatted: true
  }));

export const consentSourceOptions = [
  {
    value: schemaConstants.GAVE_CONSENT_FROM_APP,
    label: 'activity.conditions.source.app',
    formatted: true
  },
  {
    value: schemaConstants.GAVE_CONSENT_FROM_CUSTOMER_PORTAL,
    label: 'activity.conditions.source.customerPortal',
    formatted: true
  },
  {
    value: schemaConstants.GAVE_CONSENT_FROM_LANDING_PAGE,
    label: 'activity.conditions.source.landingPage',
    formatted: true
  },
  {
    value: schemaConstants.GAVE_CONSENT_FROM_HUB,
    label: 'activity.conditions.source.hub',
    formatted: true
  }
];

export const joinClubSourceOptions = [
  {
    value: schemaConstants.JOIN_CLUB_FROM_POS,
    label: 'activity.conditions.joining.source.pos',
    formatted: true
  },
  {

    value: schemaConstants.JOIN_CLUB_FROM_HUB,
    label: 'activity.conditions.joining.source.hub',
    formatted: true
  },
  {
    value: schemaConstants.JOIN_CLUB_FROM_APP,
    label: 'activity.conditions.joining.source.app',
    formatted: true
  },
  {
    value: schemaConstants.JOIN_CLUB_FROM_IMPORT,
    label: 'activity.conditions.joining.source.import',
    formatted: true
  },
  {
    value: schemaConstants.JOIN_CLUB_FROM_QUICK_REGISTER,
    label: 'activity.conditions.joining.source.quickRegister',
    formatted: true
  },
  {
    value: schemaConstants.JOIN_CLUB_FROM_CUSTOMER_PORTAL,
    label: 'activity.conditions.joining.source.customerPortal',
    formatted: true
  }
];

export const smsTypes = [
  {
    value: schemaConstants.COMMUNICATION_MARKETING_SMS,
    label: 'activity.conditions.communication.sms.marketing',
    formatted: true
  },
  {
    value: schemaConstants.COMMUNICATION_OPERATIVE_SMS,
    label: 'activity.conditions.communication.sms.operative',
    formatted: true
  },
];
export const smsSteps = [
  {
    value: schemaConstants.COMMUNICATION_SMS_SERVER_STEP,
    label: 'activity.conditions.communication.sms.step.server',
    formatted: true
  },
  /* {
    value: schemaConstants.COMMUNICATION_SMS_SMS_GW_STEP,
    label: 'activity.conditions.communication.sms.step.smsGw',
    formatted: true
  },*/
  {
    value: schemaConstants.COMMUNICATION_SMS_PROVIDER_STEP,
    label: 'activity.conditions.communication.sms.step.provider',
    formatted: true
  },
  {
    value: schemaConstants.COMMUNICATION_SMS_CARRIER_STEP,
    label: 'activity.conditions.communication.sms.step.carrier',
    formatted: true
  },
];
export const communicationSourceOptions = [
  {
    value: schemaConstants.COMMUNICATION_FROM_POS,
    label: 'activity.conditions.communication.source.pos',
    formatted: true
  },
  {
    value: schemaConstants.COMMUNICATION_FROM_AUTOMATION,
    label: 'activity.conditions.communication.source.automation',
    formatted: true
  },
  {

    value: schemaConstants.COMMUNICATION_FROM_HUB,
    label: 'activity.conditions.communication.source.hub',
    formatted: true
  },
  {
    value: schemaConstants.COMMUNICATION_FROM_CLIENT,
    label: 'activity.conditions.communication.source.client',
    formatted: true
  },
  {
    value: schemaConstants.COMMUNICATION_FROM_ADMIN,
    label: 'activity.conditions.communication.source.admin',
    formatted: true
  },
  {
    value: schemaConstants.COMMUNICATION_FROM_SERVER,
    label: 'activity.conditions.communication.source.server',
    formatted: true
  },
];

export const conditionValueTypes = [
  schemaConstants.VALUE_TYPE_BEACON_SIGNALS_LIST,
  schemaConstants.VALUE_TYPE_CATALOG_ITEMS_LIST,
  schemaConstants.VALUE_TYPE_PAYMENT_TYPE,
  schemaConstants.VALUE_TYPE_PROVIDERS_LIST,
  schemaConstants.VALUE_TYPE_ASSET_SOURCE_LIST,
  schemaConstants.VALUE_TYPE_PAYMENT_FLOW,
  schemaConstants.VALUE_TYPE_PAYMENT_FLOW_IN_APP,
  schemaConstants.FILTER_VALUE_TYPE_PAYMENT_FLOW_IN_APP,
  schemaConstants.VALUE_TYPE_MEMBERSHIP_STATUS_LIST,
  schemaConstants.VALUE_TYPE_MEMBERSHIP_CONSENT_LIST,
  schemaConstants.VALUE_TYPE_MONTHS_LIST,
  schemaConstants.VALUE_TYPE_DYNAMIC,
  schemaConstants.VALUE_TYPE_GAVE_CONSENT_SOURCE,
  schemaConstants.VALUE_TYPE_MEMBERSHIP_RFM_LIST,
  schemaConstants.VALUE_TYPE_MEMBERSHIP_LAST_VISIT_LIST,
  schemaConstants.VALUE_TYPE_JOINING_SOURCE,
  schemaConstants.VALUE_TYPE_LOCATIONS,
  schemaConstants.VALUE_TYPE_SENT_FROM,
  schemaConstants.VALUE_TYPE_EMAIL_TEMPLATE,
  schemaConstants.VALUE_TYPE_AUTO_COMPLETE
];
