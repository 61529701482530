import * as activityConstants from '../../Activity/activity.constants';

export function getGiftsActivityTags(gifts) {
  const allActivityTags = [];
  gifts.forEach((gift) => {
    if (gift.activityTags && gift.activityTags.length > 0) {
      allActivityTags.push(...gift.activityTags);
    }
  });
  return allActivityTags;
}

export function removeGiftFromList(gifts, giftHubId) {
  return gifts.filter((gift) => gift.get(activityConstants.HUB_ID) !== giftHubId);
}
