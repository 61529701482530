const GSM_STANDARD_CHARS = [
  '@', '0', '¡', 'P', '¿',
  'p', '£', '_', '!', '1',
  'A', 'Q', 'a', 'q', '$',
  '"', '2', 'B', 'R', 'b',
  'r', '¥', '?', '#', '3',
  'C', 'S', 'c', 's', 'è',
  '?', '4', 'D', 'T', 'd',
  't', 'é', '?', '%', '5',
  'E', 'U', 'e', 'u', 'ù',
  '6', 'F', 'V', 'f', 'v',
  'ì', '?',
  "'", '7', 'G',
  'W', 'g', 'w', 'ò', '(',
  '8', 'H', 'X', 'h', 'x',
  'Ç', ')', '9', 'I', 'Y',
  'i', 'y', '*', ':', 'J',
  'Z', 'j', 'z', 'Ø', '+',
  ';', 'K', 'Ä', 'k', 'ä',
  'Æ', ',', '<', 'L', 'l',
  'ö', 'æ', '-', '=', 'M',
  'Ñ', 'm', 'ñ', 'Å', 'ß',
  '.', '>', 'N', 'Ü', 'n',
  'ü', 'å', 'É', '/', 'O',
  '§', 'o', 'à', 'Δ', 'Φ',
  'Γ', 'Λ', 'Ω', 'Π', 'Ψ',
  'Σ', 'Θ', 'Ξ', 'Ö', 'Ä',
  'Ü', 'ø', ' ', '\r', '\n',
  '¤', '&'
];

const GSM_EXTENDED_CHARS = [
  '|', '^', '€', '{', '}', '[', ']', '~', '\\'
];

const BYTE_SIZE = 8;
const CHARACTER_SIZE = 7;
const GSM_METADATA_LENGTH = 7;
const GSM_METADATA_SIZE = GSM_METADATA_LENGTH * CHARACTER_SIZE;
const GSM_DEFAULT_MAX_SIZE = 140 * BYTE_SIZE; // default GSM max size for all providers except unicell and Shamir


const PROVIDERS_LIMIT_OPTIONS_LIST = [
  { name: 'unicell', GSMSize: 335, UnicodeSize: 335 },
  { name: 'shamir', GSMSize: 268, UnicodeSize: 268 }
];

const DEFAULT_OPTIONS = { name: 'default', GSMSize: GSM_DEFAULT_MAX_SIZE, UnicodeSize: GSM_DEFAULT_MAX_SIZE };

class SMSCharacterCounter {

  static getInfo(message, unsubscribeMessage, providerName) {
    const text = message + (unsubscribeMessage ? unsubscribeMessage + ' ' : '');
    const isUniCodeRequired = this.isUnicodeRequired(text);
    const providerSettings = this.getProviderSettings(providerName);
    const maxMessageSize = this.getMaxMessageSize(providerSettings, isUniCodeRequired);

    return this.getMessageDetails(text, providerSettings.name, isUniCodeRequired, maxMessageSize);
  }

  static getMaxMessageSize(providerOptions, isUniCodeRequired) {
    return isUniCodeRequired ? providerOptions.UnicodeSize : providerOptions.GSMSize;
  }

  static getProviderSettings(providerName) {
    const options = PROVIDERS_LIMIT_OPTIONS_LIST.find((index) => index.name === providerName);
    return options || DEFAULT_OPTIONS;
  }

  static isUnicodeRequired(text) {
    const remainder = this.difference([...text
    ],
      [...GSM_STANDARD_CHARS, ...GSM_EXTENDED_CHARS
      ]);
    return remainder.length !== 0;
  }

  static getMessageDetails(text, providerName, isUniCodeRequired, maxMessageSize) {
    const messages = [];
    const messagesLength = this.calculateMessagesCount(text, providerName, isUniCodeRequired, maxMessageSize, messages);
    return {
      messagesCount: messagesLength,
      unicodeRequired: isUniCodeRequired,
      charactersCount: messages.reduce((sum, sms) => sum + sms.length, 1)
    };
  }

  static calculateMessagesCount(text, providerName, isUniCodeRequired, maxMessageSize, messages) {
    let messagesCount = 0;
    if (providerName != 'default') {
      messages.push(text);
      messagesCount = (text.length <= maxMessageSize) ? 1 : Math.ceil((text.length / maxMessageSize));
    } else {
      const splitMessages = this.splitIntoSMS(text, isUniCodeRequired, maxMessageSize);
      messages.push(...splitMessages);
      messagesCount = messages.length;
    }
    return messagesCount;
  }

  static splitIntoSMS(originalText, isUniCodeRequired, maxSize) {
    let index = 0;
    const messages = [];
    let text = originalText;
    let split = this.getTextSize(text, isUniCodeRequired) > maxSize;
    while (index <= originalText.length) {
      const sms = this.getSMS(text, isUniCodeRequired, maxSize, split);
      messages.push(sms);
      text = text.slice(sms.length);
      index += sms.length + 1;
      split = this.getTextSize(text, isUniCodeRequired) + GSM_METADATA_SIZE > maxSize;
    }
    return messages;
  }

  static getSMS(text, isUniCodeRequired, maxSize, lastSplit) {
    let index = 0,
      currentSize = 0;
    const maxSizeWithMeta = maxSize - GSM_METADATA_SIZE;

    while (currentSize + this.getCharSize(text, isUniCodeRequired, text[index
      ]) <= maxSizeWithMeta && index < text.length) {
      currentSize += this.getCharSize(text, isUniCodeRequired, text[index
        ]);
      index += 1;
    }
    if (!lastSplit) {
      index = text.length;
    }
    return text.slice(0, index);
  }

  static getTextSize(text, isUniCodeRequired) {
    return [...text
    ].map((x) => this.getCharSize(text, isUniCodeRequired, x)).reduce((sum, c) => sum + c,
      0);
  }

  static getSizeInBytes(text, isUniCodeRequired) {
    return Math.ceil(this.getTextSize(text, isUniCodeRequired) / BYTE_SIZE);
  }

  static getCharSize(text, isUnicodeRequired, character) {
    if (!character) {
      return 0;
    }
    if (isUnicodeRequired) {
      return character.length * BYTE_SIZE * 2;
    } else if (GSM_EXTENDED_CHARS.includes(character)) {
      return CHARACTER_SIZE * 2;
    }
    return CHARACTER_SIZE;
  }

  static difference(array, values) {
    return array.filter((x) => !values.includes(x));
  }

}

export default SMSCharacterCounter;
