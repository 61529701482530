/*
 *
 * Forms constants
 *
 */

// ACTIONS CONSTANTS

export const GET_FORMS = 'app/Benefits/GET_FORMS';
export const GET_FORMS_SUCCESS = 'app/Benefits/GET_FORMS_SUCCESS';
export const GET_REGISTRATION_FORM_FIELDS = 'app/activity/GET_REGISTRATION_FORM_FIELDS';
export const GET_REGISTRATION_FORM_FIELDS_SUCCESS = 'app/activity/GET_REGISTRATION_FORM_FIELDS_SUCCESS';

// STORE CONSTANTS
export const FORMS = 'forms';
export const FORMS_LIST = 'list';
export const FORMS_LIST_LOADED = 'listLoaded';
export const FORM_TITLE = 'title';
export const FORM_KEY = 'key';
export const REGISTRATION_FORM_FIELDS = 'registrationFormFields';
export const REGISTRATION_FORM_FIELDS_FETCHED = 'registrationFormFieldsFetched';
