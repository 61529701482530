import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as constants from '../../activity.constants';
import ActionsList from './actionsList';
import * as appConstants from '../../../App/app.constants';
import withAuthorization from '../../../../common/helpers/authorization';
import Bundle from './bundle/Bundle';

class BenefitActionsWrapper extends React.PureComponent {

  static propTypes = {
    actionsList: PropTypes.object.isRequired,
    actionsMode: PropTypes.string,
    actionsPopupContent: PropTypes.object,
    activityType: PropTypes.string,
    automationIdPrefix: PropTypes.string.isRequired,
    getBusinessBundlePlan: PropTypes.func.isRequired,
    bundle: PropTypes.object,
    businessStrategy: PropTypes.string,
    createTempApplyOnModel: PropTypes.func,
    saveApplyOnData: PropTypes.func,
    updateActionField: PropTypes.func.isRequired,
    updateActionType: PropTypes.func.isRequired,
    validationErrors: PropTypes.object,
    actionsWrapperIndex: PropTypes.number,
    addBundleLine: PropTypes.func,
    deleteBundleLine: PropTypes.func,
    onBundleLineFieldUpdate: PropTypes.func,
    onUpdateBundle: PropTypes.func,
    activityTrigger: PropTypes.string,
    actionsWrappersSize: PropTypes.number,
    deleteActionsWrapper: PropTypes.func,
    caseIndex: PropTypes.number,
    isSimpleMode: PropTypes.bool,
    features: PropTypes.object,
  };

  getApplyOnData() {
    if (!this.props.bundle || !this.props.bundle.getIn(constants.VALUE, constants.CONDITIONS_LIST).size) {
      return null;
    }
    const applyOn = [];
    this.props.bundle.getIn(constants.VALUE, constants.CONDITIONS_LIST).forEach((condition) => {
      const applyOnObj = {};
      applyOnObj[constants.BUNDLE_CONDITION_ID] = condition.get(constants.CONDITION_ID);
      if (condition.get(constants.CONDITION_KEY) === constants.QUANTITY) {
        applyOnObj[constants.BUNDLE_CONDITION_ID] = constants.BUNDLE_APPLY_ON_LIMIT;
      }
      applyOn.push(applyOnObj);
    });
    return applyOn;
  }

  deleteActionsWrapper = () => {
    this.props.deleteActionsWrapper(this.props.actionsWrapperIndex);
  };

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    const applyOnData = this.getApplyOnData();
    if (this.props.actionsMode !== constants.SIMPLE_ENTIRE_TICKET_DISCOUNT
    && this.props.actionsMode !== constants.SIMPLE_SPECIFIC_ITEMS_DISCOUNT
    && this.props.actionsMode !== constants.ADVANCED_DISCOUNT) {
      return null;
    }
    let andDoMessageKey = this.props.actionsMode === constants.ADVANCED_DISCOUNT || this.props.actionsList.getIn([0, constants.ACTION_TYPE]) === '' ? 'activity.action.and' : 'activity.action.andDo';
    if (this.props.actionsWrapperIndex === 0 && !this.props.bundle) {
      andDoMessageKey = 'activity.action.do';
    }
    const showNumbersOnMultipleActions = this.props.actionsWrappersSize > 2 && this.props.actionsWrapperIndex + 1 !== this.props.actionsWrappersSize;
    return (
      <div className="benefit-actions-wrapper">
        <div className="actions-wrapper-elem">
          <div className="actions-wrapper-elem-number">
            {showNumbersOnMultipleActions ? `${this.props.actionsWrapperIndex + 1})` : ''}
          </div>
          <div className={`section-title ${showNumbersOnMultipleActions ? 'showNumbersOnMultipleActions' : ''}`}>
            {this.props.actionsWrapperIndex === 0 && this.props.bundle ? '' : formatMessage({ id: andDoMessageKey })}
          </div>
          <div className="actions-mode deal-actions-mode">
            { this.props.bundle && <Bundle
              activityType={this.props.activityType}
              addBundleLine={this.props.addBundleLine}
              automationIdPrefix={this.props.automationIdPrefix}
              bundle={this.props.bundle}
              deleteBundleLine={this.props.deleteBundleLine}
              onBundleLineFieldUpdate={this.props.onBundleLineFieldUpdate}
              posFeature={appConstants.FEATURES_SHOPPING_CART_SUBMIT_PURCHASE}
              validationErrors={this.props.validationErrors ? this.props.validationErrors.get(constants.BUNDLE) : null}
              actionsWrapperIndex={this.props.actionsWrapperIndex}
              deleteActionsWrapper={this.props.deleteActionsWrapper}
              showNumbersOnMultipleActions={showNumbersOnMultipleActions}
              showSimpleFeature={this.props.isSimpleMode}
              onUpdateBundle={this.props.onUpdateBundle}
            />}
            <ActionsList
              features={this.props.features}
              actionsPopupContent={this.props.actionsPopupContent}
              actionPlaceholder={formatMessage({ id: 'discount.actions.placeholder' })}
              actionsList={this.props.actionsList}
              automationIdPrefix={this.props.automationIdPrefix}
              actionsMode={this.props.actionsMode}
              applyOnData={applyOnData}
              validationErrors={this.props.validationErrors ? this.props.validationErrors.get(constants.ACTIONS_LIST) : null}
              deleteAction={this.deleteActionsWrapper}
              updateActionField={this.props.updateActionField}
              updateActionType={this.props.updateActionType}
              activityType={this.props.activityType}
              bundle={this.props.bundle}
              saveApplyOnData={this.props.saveApplyOnData}
              createTempApplyOnModel={this.props.createTempApplyOnModel}
              limit
              businessStrategy={this.props.businessStrategy}
              actionsWrapperIndex={this.props.actionsWrapperIndex}
              activityTrigger={this.props.activityTrigger}
              isDiscountAction
              caseIndex={this.props.caseIndex}
              getBusinessBundlePlan={this.props.getBusinessBundlePlan}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  businessStrategy: state.getIn([appConstants.APP, appConstants.DEAL_SETTINGS, appConstants.DEFAULT_DISCOUNT_STRATEGY]),
  isSimpleMode: state.getIn([constants.ACTIVITY, constants.DATA, constants.GIFT_SIMPLE_MODE])
});

export default connect(mapStateToProps)(withAuthorization(injectIntl(BenefitActionsWrapper)));
