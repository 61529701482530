/*
 *
 * MicroCampaign actions
 *
 */

import * as constants from './microCampaign.constants';
import * as microCampaignsConstants from '../MicroCampaigns/microCampaigns.constants';

export const updateMicroCampaignMetadata = (key, value) =>
  ({
    type: constants.UPDATE_MICRO_CAMPAIGN_METADATA,
    key,
    value
  });

export const goToMicroCampaigns = () => ({
  type: microCampaignsConstants.GO_TO_MICRO_CAMPAIGNS
});

export const getMicroCampaign = (hubId) => ({
  type: constants.GET_MICRO_CAMPAIGN,
  hubId
});

export const createMicroCampaign = () => ({
  type: constants.CREATE_MICRO_CAMPAIGN
});

export const saveMicroCampaign = (data, isDraft, locationId) => ({
  type: constants.SAVE_MICRO_CAMPAIGN,
  data,
  isDraft,
  locationId
});

export const checkMicroCampaignNameAvailability = (microCampaignId, name) => ({
  type: constants.CHECK_MICRO_CAMPAIGN_NAME_AVAILABILITY,
  hubId: microCampaignId,
  name
});

export const updateMicroCampaignField = (data) => ({
  type: constants.UPDATE_MICRO_CAMPAIGN_FIELD,
  data
});

export const updateCommunicationActionType = (actionName, index, defaultText, assetSetIndex) => ({
  type: constants.UPDATE_COMMUNICATION_ACTION_TYPE,
  actionName,
  index,
  defaultText,
  assetSetIndex
});
export function submitMicroCampaignActionToCustomer(user, customerSearchField, actionsWrapperIndex, actionIndex, actionType) {
  return {
    type: constants.SUBMIT_COMMUNICATION_ACTION_TO_CUSTOMER,
    user,
    actionsWrapperIndex,
    actionIndex,
    customerSearchField,
    actionType
  };
}
export const clearMicroCampaignData = () => ({
  type: constants.CLEAR_MICRO_CAMPAIGN_DATA
});

export const addBenefit = () => ({
  type: constants.ADD_BENEFIT_TO_MICRO_CAMPAIGN
});

export const deleteAssetTemplate = (index) => ({
  type: constants.DELETE_ASSET_TEMPLATE,
  index
});

export const clearAssetSetData = (index) => ({
  type: constants.CLEAR_ASSET_TEMPLATE,
  index
});

export const toggleAssetSetDisplay = (isCollapsed, index) => ({
  type: constants.TOGGLE_ASSET_SET_DISPLAY,
  isCollapsed,
  index
});

export const setAssetSetsExpandCollapseMode = (isCollapsed) => ({
  type: constants.SET_ALL_EXPAND_COLLAPSE_MODE,
  isCollapsed
});
