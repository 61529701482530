import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import PopupWrapper from '../PopupWrapper/PopupWrapper';
import CustomMenu from '../CustomMenu/CustomMenu';

export default class ContextMenu extends React.PureComponent {
  static propTypes = {
    automationId: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    hidden: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      options: this.wrapOptionsCallbackWithCloseMenuAction(props.options)
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.options) {
      const options = this.wrapOptionsCallbackWithCloseMenuAction(nextProps.options);
      this.setState({ options });
    }
  }

  wrapOptionsCallbackWithCloseMenuAction = (options) =>
    options.map((opt) => ({ ...opt,
      cb: () => {
        opt.cb();
        this.handleOnClose();
      } }));

  handleOnClose = () => {
    this.setState({
      open: false
    });
  };

  handleOnOpen = () => {
    this.setState({
      open: true
    });
  };

  render() {
    const className = (!this.state.open && this.props.hidden) ? 'visibility-hidden' : '';
    return (
      <div className={className}>
        <PopupWrapper
          automationId={this.props.automationId}
          className="no-padding no-border"
          on="click"
          onOpen={this.handleOnOpen}
          onClose={this.handleOnClose}
          open={this.state.open}
          position="bottom right"
          fixed
          trigger={
            <Icon
              className={this.state.open ? 'como-ic-more menuIconOpen' : 'como-ic-more menuIcon'}
              size="large"
              onClick={(e) => e.stopPropagation()}
            />
          }
        >
          <CustomMenu
            AutomationIdPrefix={`${this.props.automationId}.menu`}
            data={this.state.options}
          />
        </PopupWrapper>
      </div>);
  }
}
