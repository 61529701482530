import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import * as appConstants from '../../../../App/app.constants';
import PopupWrapper from '../../../../../common/components/PopupWrapper/PopupWrapper';
import DaysTimesRange from './DaysTimesCondition';
import PopupTrigger from '../../../../../common/components/PopupTrigger/PopupTrigger';
import { getDaysConditions } from '../../../reducers/utils/activity.datetime.reducer.utils';
import * as appSelectors from '../../../../App/selectors';

class DaysTimesField extends React.PureComponent {

  static propTypes = {
    condition: PropTypes.object,
    index: PropTypes.number,
    intl: PropTypes.object,
    localeSettings: PropTypes.object,
    prefix: PropTypes.string,
    addDaysTimesCondition: PropTypes.func,
    deleteDaysTimesCondition: PropTypes.func,
    updateDaysTimes: PropTypes.func,
    businessTimezone: PropTypes.string
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      isPopupOpen: false
    };
  }

  onPopupOpen = () => {
    this.setState({
      isPopupOpen: true
    });
  };

  /**
   * close popup when clicking outside it but not on one of the nodes of the hour components
   * @param {object} e
   */
  onPopupClose = (e) => {
    if (
      e.target.className.indexOf('como-ic-triangle-down') < 0 &&
      e.target.className.indexOf('como-ic-triangle-up') < 0 &&
      e.target.className.indexOf('container-days-and-times--select-all') < 0 &&
      e.target.className.indexOf('como-ic-delete') < 0 &&
      e.target.className.indexOf('text') < 0 &&
      e.target.localName !== ('input')
    ) {
      this.setState({
        isPopupOpen: false
      });
    }
  };

  /**
   * parse daysTimes condition as string
   * if default values did not change show 'always' message
   * @returns {string}
   */
  getDaysValueAsString() {
    const { condition, intl, localeSettings } = this.props;
    const daysConditions = getDaysConditions(condition, intl, localeSettings.get(appConstants.BROWSER_LOCALE), localeSettings.get(appConstants.TIME_FORMAT));
    return daysConditions.length
      ? daysConditions.join(', ')
      : intl.formatMessage({ id: 'dateTimeGlobalConditions.atAnyDateAndTime' });
  }

   // use local functions to inject condition index to callback functions
  addDaysTimesCondition = () => {
    this.props.addDaysTimesCondition(this.props.index);
  };
  deleteDaysTimesCondition = (daysTimesLineIndex) => {
    this.props.deleteDaysTimesCondition(daysTimesLineIndex, this.props.index);
  };
  updateDaysTimes = (data, daysTimesLineIndex, isMilitaryTime) => {
    this.props.updateDaysTimes(data, daysTimesLineIndex, isMilitaryTime, this.props.index, this.props.businessTimezone);
  };

  render() {

    const { condition, intl, localeSettings, prefix } = this.props;
    const valueAsString = this.getDaysValueAsString();

    return (
      <PopupWrapper
        trigger={
          <div className="inline-div">
            <PopupTrigger
              className="popup-trigger"
              automationId={prefix}
              message={valueAsString}
              title={valueAsString}
            />
          </div>
        }
        on="click"
        position="bottom left"
        flowing
        className="days-times-conditions-popup"
        open={this.state.isPopupOpen}
        onOpen={this.onPopupOpen}
        onClose={this.onPopupClose}
      >
        <DaysTimesRange
          automationIdPrefix={`${prefix}.daysAndTimes`}
          condition={condition}
          intl={intl}
          locale={localeSettings.get(appConstants.BROWSER_LOCALE)}
          localeTimeFormat={localeSettings.get(appConstants.TIME_FORMAT)}
          addDaysTimesCondition={this.addDaysTimesCondition}
          deleteDaysTimesCondition={this.deleteDaysTimesCondition}
          updateDaysTimes={this.updateDaysTimes}
        />
      </PopupWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  localeSettings: appSelectors.getBrowserLocaleSettings(state),
  businessTimezone: appSelectors.getBusinessTimeZone(state)
});

const withConnect = connect(mapStateToProps, null);

export default compose(
  withConnect,
  injectIntl
)(injectIntl(DaysTimesField));
