import axios from 'axios';

export const getCustomScreensData = () =>
  axios.get('/api/CustomScreenLayouts').then((response) => response.data);

export const getCustomWebViewsData = () =>
  axios.get('/api/CustomWebViews').then((response) => response.data);

export const getCatalogs = () =>
  axios.get('/api/Catalogs').then((response) => response.data);

