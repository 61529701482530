/**
*
* UpdateMembershipOperation
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import * as schemaConstants from '../../../activitySchema/activitySchema.constants';
import Boolean from '../../../../../common/components/FormInputFields/Boolean/Boolean';

class UpdateMembershipOperation extends React.Component {

  static propTypes = {
    disabled: PropTypes.bool,
    index: PropTypes.number,
    prefix: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    name: PropTypes.string
  };

  onUpdate = (e, data) => {
    data.value = data.value === 'true' ? schemaConstants.OPERATION_VALUE_JOIN_CLUB : schemaConstants.OPERATION_VALUE_UPDATE_MEMBERSHIP;
    this.props.onChange(e, data);
  };

  render() {

    const { disabled, index, prefix, value, name } = this.props;
    const boolValue = (value === schemaConstants.OPERATION_VALUE_JOIN_CLUB).toString();

    return (
      <Boolean
        disabled={disabled}
        index={index}
        prefix={prefix}
        value={boolValue}
        onChange={this.onUpdate}
        name={name}
      />
    );
  }
}

export default UpdateMembershipOperation;
