import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import InputWrapper from '../../../../../../common/components/FormInputFields/InputWrapper/InputWrapper';
import * as schemaConstants from '../../../../activitySchema/activitySchema.constants';
import MentionTextArea from '../../../../../../common/components/MentionableTextArea/MentionTextArea';

class customFieldWithMention extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      key: '',
      value: ''
    };
  }

  static propTypes = {
    automationIdPrefix: PropTypes.string,
    keyLabel: PropTypes.string,
    valueLabel: PropTypes.string,
    onActionFieldUpdate: PropTypes.func.isRequired,
    customBodyField: PropTypes.object,
    index: PropTypes.number,
    validationErrors: PropTypes.object,
    hideKey: PropTypes.bool,
    mentionsList: PropTypes.object,
    removeItem: PropTypes.func.isRequired,
    showDeleteButton: PropTypes.bool,
    parentIndex: PropTypes.number
  };

  render() {
    const { index, onActionFieldUpdate, validationErrors, automationIdPrefix, customBodyField, hideKey, keyLabel, valueLabel, mentionsList, removeItem, showDeleteButton, parentIndex } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    return (
      <React.Fragment>
        {index !== 0 && <hr className="action-custom-body-divider" />}
        <Grid.Row columns={1} style={{ marginTop: 30, marginBlock: 20 }}>
          {!hideKey && <Grid.Column>
            {showDeleteButton && <Icon
              style={{ cursor: 'pointer', float: 'right' }}
              className="icon como-ic-delete"
              onClick={() => { removeItem(index, parentIndex); }}
            />}
            <p className="action-label">{keyLabel || formatMessage({ id: 'activity.action.body.key' })}</p>
            <InputWrapper
              noSpaces
              fluid
              onChange={(e, data) => onActionFieldUpdate(index, schemaConstants.EXPORT_EVENT_BODY_KEY_FIELD, data.value)}
              name={`${schemaConstants.EXPORT_EVENT_CUSTOM_BODY_FIELDS}.${schemaConstants.EXPORT_EVENT_BODY_KEY_FIELD}`}
              value={customBodyField ? customBodyField.get(schemaConstants.EXPORT_EVENT_BODY_KEY_FIELD) : ''}
              prefix={`${automationIdPrefix}.${schemaConstants.EXPORT_EVENT_CUSTOM_BODY_FIELDS}.${schemaConstants.EXPORT_EVENT_BODY_KEY_FIELD}`}
              error={validationErrors ? validationErrors.get(`${schemaConstants.EXPORT_EVENT_CUSTOM_BODY_FIELDS}.${index}.${schemaConstants.EXPORT_EVENT_BODY_KEY_FIELD}`) : null}
            />
          </Grid.Column>
          }
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <p className="action-label">{valueLabel || formatMessage({ id: 'activity.action.body.value' })}</p>
            {mentionsList ? <MentionTextArea
              automationIdPrefix={`${automationIdPrefix}.${schemaConstants.EXPORT_EVENT_CUSTOM_BODY_FIELDS}.${schemaConstants.EXPORT_EVENT_BODY_VALUE_FIELD}`}
              mentionOptions={mentionsList || []}
              message={customBodyField ? customBodyField.get(schemaConstants.EXPORT_EVENT_BODY_VALUE_FIELD) : ''}
              onTextUpdate={(val) => onActionFieldUpdate(index, schemaConstants.EXPORT_EVENT_BODY_VALUE_FIELD, val)}
              index={index}
              error={validationErrors ? validationErrors.get(`${schemaConstants.EXPORT_EVENT_CUSTOM_BODY_FIELDS}.${index}.${schemaConstants.EXPORT_EVENT_BODY_VALUE_FIELD}`) : null}
            /> : null}
          </Grid.Column>
        </Grid.Row>
      </React.Fragment>
    );
  }
}

export default injectIntl(customFieldWithMention);
