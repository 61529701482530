import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Icon, Label, List } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { HUB_ID, NAME, SERVER_ID } from '../../../ItemGroups/itemGroups.constants';

import EditItemsGroup from './editItemsGroup';
import ConfirmationDialog from '../../../../common/components/ConfirmationDialog/ConfirmationDialog';
import { MODAL_LOADING_CONFIRMATION_BUTTON } from '../../../../common/components/CustomModal/customModal.constants';

class GroupItem extends React.PureComponent {

  static propTypes = {
    advancedRulesSchemaConditions: PropTypes.object,
    automationIdPrefix: PropTypes.string.isRequired,
    group: PropTypes.object.isRequired,
    groupsCount: PropTypes.number.isRequired,
    isChecked: PropTypes.bool.isRequired,
    isDeleted: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onDeleteGroup: PropTypes.func,
    onUpdateItemsGroup: PropTypes.func,
    posFeature: PropTypes.string.isRequired,
    deleteInProgress: PropTypes.string

  };

  onDelete = () => {
    this.props.onDeleteGroup(this.props.group.get(HUB_ID), this.props.group.get(SERVER_ID));
  };


  get isBeingDeleted() {
    return this.props.deleteInProgress && this.props.deleteInProgress === this.props.group.get(HUB_ID);
  }

  render() {

    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const { advancedRulesSchemaConditions, automationIdPrefix, group, isChecked, isDeleted, onUpdateItemsGroup } = this.props;

    return (
      <List.Item>
        <Checkbox
          label={group.get(NAME)}
          checked={isChecked}
          onChange={(e, data) => this.props.onChange(group.get(SERVER_ID), data.checked)}
          disabled={isDeleted && !isChecked}
          data-automation-id={`${automationIdPrefix}.checkbox`}
        />
        {
          isDeleted
          ? (
            <Label as="span" className="deactivated-indication">
              <Icon name="ban" />
              {formatMessage({ id: 'general.status.deleted', defaultMessage: 'Deleted' })}
            </Label>
          )
          : (
            <span>
              <ConfirmationDialog
                confirmAction={this.onDelete}
                loading={this.isBeingDeleted}
                showLoadingElement={MODAL_LOADING_CONFIRMATION_BUTTON}
                automationIdPrefix={`${automationIdPrefix}.deleteGroup`}
                confirmButtonKey="general.button.delete"
                contentMessageKey="activity.itemsPopulation.itemGroups.deleteGroup.message"
                titleKey="activity.itemsPopulation.itemGroups.deleteGroup.header"
                icon="como-ic-delete"
              />
              <EditItemsGroup
                advancedRulesSchemaConditions={advancedRulesSchemaConditions}
                automationIdPrefix={automationIdPrefix}
                group={group}
                groupsCount={this.props.groupsCount}
                onUpdateItemsGroup={onUpdateItemsGroup}
                posFeature={this.props.posFeature}
              />
            </span>
          )
        }
      </List.Item>
    );
  }
}

export default injectIntl(GroupItem);

