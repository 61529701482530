import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { bindActionCreators, compose } from 'redux';
import {Form, Grid, Header, Icon, Image, Input} from 'semantic-ui-react';
import classNames from 'classnames';
import { fromJS } from 'immutable';
import moment from 'moment';
// import _ from 'lodash';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import * as actions from './pointShopItem.actions';
import * as activityActions from '../Activity/activity.actions';
import * as benefitsActions from '../Benefits/benefits.actions';
import * as formsActions from '../Forms/forms.actions';
import * as pointShopActions from './../PointShop/pointShop.actions';
// import * as businessActions from '../BusinessCenter/businessCenter.actions';

import * as constants from './pointShopItem.constants';
import * as benefitsConstants from '../Benefits/benefits.constants';
import * as activityConstants from '../Activity/activity.constants';
import * as appConstants from '../App/app.constants';
import * as imagesConstants from '../Images/images.constants';
import * as reducerConstants from '../../constants/reducer.constants';
import * as routeConstants from '../../constants/route.contants';

import injectSaga from '../../utils/injectSaga';
import saga from '../PointShop/pointShop.saga';
import businessSaga from '../BusinessCenter/businessCenter.saga';
import benefitsSaga from '../Benefits/benefits.saga';
import formsSaga from '../Forms/forms.saga';
import imagesSaga from '../Images/images.saga';


import * as appSelectors from '../App/selectors';
import * as imageSelectors from '../Images/images.selectors';
import * as activitySelectors from '../Activity/activity.selectors';
import * as schemaSelectors from '../Activity/activitySchema/activitySchema.selectors';
import * as selectors from '../PointShop/pointShop.selectors';
// import * as businessCenterSelectors from '../BusinessCenter/businessCenter.selectors';

import EntityHeader from '../../common/components/EntityHeader/EntityHeader';
import ItemsPopulation from '../Activity/views/itemsPopulation/itemsPopulation.container';
import Gallery from '../Images/views/ImageGallery/ImageGallery.container';
import IntegerInputField from '../../common/components/FormInputFields/IntegerInputField/IntegerInputField';
import BenefitsList from '../Benefits/views/benefitsList/benefitsList';
import MembershipTagCondition from './views/MembershipTagCondition';
import DatesCondition from './views/DatesCondition';
// import ActivityTags from '../Activity/views/activityTags/activityTags';

import '../Activity/activity.styles.less';
import './pointShopItem.styles.less';

import { validatePointShopItem } from './utils/pointShop.validation.utils';
import {
  dateTimeGlobalConditionsStructure,
  getDefaultDates,
  getDefaultGiftDataStructure,
  membershipGlobalConditionsStructure
} from './utils/pointShop.data.utils';
import withAuthorization from '../../common/helpers/authorization';
import ToggleButton from '../../common/components/ToggleButton/ToggleButton';


class PointShopItem extends React.PureComponent {

  state = {
    name: '',
    description: '',
    tags: [],
    // activityTags: [],
    price: null,
    validFrom: getDefaultDates().dateFrom,
    validUntil: getDefaultDates().dateTo,
    imageId: null,
    status: null,

    // for custom
    itemGroupsData: {
      conditions: {
        conditionsList: [],
        conditionsOperator: 'or'
      },
      type: 'itemCodes'
    },
    assetTemplate: null,

    // for classic
    gift: getDefaultGiftDataStructure(this.props.locationId),

    isHeaderShadowed: false,
    isTagsConditionModalOpen: false,
    isDatesConditionModalOpen: false,
    pointShopItemMode: constants.POINT_SHOP_ITEM_MODE_CLASSIC,
    validationErrors: {},
    isLoading: false,
    isImageSelected: false,
    pointShopItemImageError: false,
    simpleMode: false,
    simpleDescription: '',
  };

  static propTypes = {
    pointShopItem: PropTypes.object,
    getBusinessBundlePlan: PropTypes.func.isRequired,
    businessDisplayName: PropTypes.string,
    hasFullPermission: PropTypes.func,
    /* allOtherActivityTags: PropTypes.array,
    campaigns: PropTypes.object, */
  };

  componentWillMount() {
    if (!this.props.features) {
      // noinspection JSUnresolvedFunction
      this.props.actions.getFeatures();
    }
    if (!this.props.benefitsList) {
      // noinspection JSUnresolvedFunction
      this.props.benefitsActions.getBenefits();
    }
    if(!this.props.location.state && this.props.match.params.id) {
        this.props.pointShopActions.getPointShopItem(this.props.match.params.id);
    }
    /* if (_.isNil(this.props.campaigns) || this.props.campaigns.isEmpty()) {
      this.props.businessActions.getCampaigns();
    } */
  }

  componentDidMount() {
    const { location } = this.props;

    // proceed existing item data
    if (location.state && location.state.item) {
      this.initPointShopState(location.state.item);
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.pointShopItem) {

      const pointShopItem = nextProps.pointShopItem;
      this.initPointShopState(pointShopItem);
    }

    if (nextProps.isPointShopItemCreated) {
      this.props.history.push({ search: this.props.location.search, pathname: routeConstants.POINT_SHOP_ROUTE });
    }
    if (nextProps.ImageError) {
      this.setState({
        pointShopItemImageError: nextProps.ImageError,
        validationErrors: { Image: 'activity.validation.error.activity.general.500' },
        isLoading: false
      });
    }
    if (nextProps.isPointShopItemError) {
      if (nextProps.isPointShopItemError.indexOf('400') !== -1) {
        this.setState({
          validationErrors: { name: 'activity.validation.error.activity.name.already.used' },
          isLoading: false
        });
      } else {
        this.setState({
          validationErrors: { general: 'activity.validation.error.activity.general.500' },
          isLoading: false
        });
      }
    } else {
      this.setState({
        validationErrors: {}
      });
    }
  }

   initPointShopState = (pointShopItem) => {
    this.setState({
      ...pointShopItem,
      selectedImage: pointShopItem.imageId ? fromJS({
        imageId: pointShopItem.imageId,
        url: pointShopItem.imageUrl
      }) : null,
      /* activityTags: pointShopItem.gift === null ? [] : pointShopItem.gift.activityTags, */
      pointShopItemMode: pointShopItem.gift === null ? constants.POINT_SHOP_ITEM_MODE_CUSTOM : constants.POINT_SHOP_ITEM_MODE_CLASSIC,
      itemGroupsData: pointShopItem.gift !== null && pointShopItem.gift.actions && pointShopItem.gift.actions.length ?
        pointShopItem.gift.actions[0].actionsList[0].discountOn : {
          conditions: {
            conditionsList: [],
            conditionsOperator: 'or'
          },
          type: 'itemCodes'
        },
      price: pointShopItem.price !== null ? (pointShopItem.price / 100) : null,
      gift: pointShopItem.gift === null ? getDefaultGiftDataStructure(this.props.locationId) : pointShopItem.gift,
      simpleDescription: (pointShopItem.gift !== null && pointShopItem.gift.actions && pointShopItem.gift.actions.length) ? pointShopItem.gift.actions[0].actionsList[0].simpleDescription : '',
      simpleMode: pointShopItem.simpleMode,
    });
  }

  handleModeChange = () => {
    this.setState({ pointShopItemMode: this.state.pointShopItemMode === constants.POINT_SHOP_ITEM_MODE_CLASSIC ? constants.POINT_SHOP_ITEM_MODE_CUSTOM : constants.POINT_SHOP_ITEM_MODE_CLASSIC });
  };

  handleTriggerTagsConditionModal = () => {
    this.setState({ isTagsConditionModalOpen: !this.state.isTagsConditionModalOpen });
  };

  handleTriggerDatesConditionModal = () => {
    this.setState({ isDatesConditionModalOpen: !this.state.isDatesConditionModalOpen });
  };

  handleTagsApply = (tags) => {
    let membershipGlobalConditions = null;
    if (Array.isArray(tags) && tags.length) {
      membershipGlobalConditions = membershipGlobalConditionsStructure(tags);
    }
    this.setState({
      tags,
      gift: {
        ...this.state.gift,
        membershipGlobalConditions: membershipGlobalConditions
      }
    });
  };

  handleDatesApply = (dates, condition) => {
    this.setState({
      validFrom: moment(dates.dateFrom).format(),
      validUntil: dates.dateTo ? moment(dates.dateTo).format() : null,
      gift: {
        ...this.state.gift,
        ...dateTimeGlobalConditionsStructure(condition)
      }
    }, this.proceedValidationOnChange);
  };

  handleNameChange = (event) => {
    const name = event.target.value.trim();
    this.setState({
      name,
      gift: {
        ...this.state.gift,
        name
      }
    }, this.proceedValidationOnChange);
  };

  handleDescriptionChange = (event) => {
    const description = event.target.value.trim();
    this.setState({
      description,
      gift: {
        ...this.state.gift,
        description
      }
    }, this.proceedValidationOnChange);
  };

  handlePriceChange = (event, price) => {
    this.setState({ price }, this.proceedValidationOnChange);
  };

  onUpdatePurchaseItems = (e, data) => {
    const t = {
      actions: [
        {
          actionsList: [
            {
              actionType: 'discountFreeItems',
              discountValue: null,
              limit: { value: 1, active: true },
              discountOn: data.toJS(),
            }
          ],
          mode: 'simpleSpecificItemsDiscount'
        }
      ]
    };
    this.setState({
      itemGroupsData: data.toJS(),
      gift: {
        ...this.state.gift,
        ...t
      }
    }, this.proceedValidationOnChange);
  };
  onUpdateSimpleDescription = (e, value) => {
    this.setState({
      simpleDescription: value,
    }, this.proceedValidationOnChange);
  };

  onBenefitChange = (e, data) => {
    this.setState({ assetTemplate: data.value, selectedBenefit: data }, this.proceedValidationOnChange);
  };
 /* handleActivityTagChange = (e, data) => {
    this.setState({ activityTags: data.value, gift: { ...this.state.gift, activityTags: data.value } }, this.proceedValidationOnChange);
  }; */
  handleCancelOrBack = () => {
    this.props.history.push({ search: this.props.location.search, pathname: routeConstants.POINT_SHOP_ROUTE });
  };

  getDefaultImagePropByType(imageType) {
    switch (imageType) {
      case activityConstants.IMAGE_TYPE_STAMP:
        return this.props.defaultStampImage;
      default:
        return this.props.defaultPreviewImage;
    }
  }

  getImageUploadInstructionsKey(imageType) {
    switch (imageType) {
      case activityConstants.IMAGE_TYPE_PREVIEW:
        return 'imageGallery.upload.preview.image.instruction';
      case activityConstants.IMAGE_TYPE_BACKGROUND:
        return 'imageGallery.upload.background.image.instruction';
      case activityConstants.IMAGE_TYPE_STAMP:
        return 'imageGallery.upload.stamp.image.instruction';
      default:
        return null;
    }
  }

  onImageSelected = (type, selectedId) => {
    let fieldName = '';
    switch (type) {
      case activityConstants.IMAGE_TYPE_BACKGROUND:
        fieldName = activityConstants.BACKGROUND_IMAGE_ID;
        break;
      case activityConstants.IMAGE_TYPE_STAMP:
        fieldName = activityConstants.STAMP_IMAGE_ID;
        break;
      default:
        fieldName = activityConstants.PREVIEW_IMAGE_ID;
    }

    this.props.activityActions.updateDisplayField([{
      [activityConstants.DISPLAY_TAB_FIELD_NAME]: fieldName,
      [activityConstants.DISPLAY_TAB_FIELD_VALUE]: selectedId
    }]);
    this.props.pointShopActions.ClearPointShopItemImageError();
    this.setState({
      imageId: selectedId,
      gift: {
        ...this.state.gift,
        imageId: selectedId,
      },
      isImageSelected: true,
      pointShopItemImageError: false
    });
  };

  getImageSelector = (imageType, selectedImage) => {
    const { formatMessage } = this.props.intl;
    const defaultImageUrl = this.getDefaultImagePropByType(imageType);
    const imageWrapperClassNames = classNames('image-wrapper', {
      'no-image-selected': !selectedImage,
      'image-Error': this.state.pointShopItemImageError
    });
    const changeImageButtonText = formatMessage({
      id: selectedImage
        ? 'activity.displayTab.change.image'
        : 'activity.displayTab.select.image'
    });
    let triggerImageUrl = defaultImageUrl ? defaultImageUrl.get(imagesConstants.URL) : '';
    if (selectedImage) {
      triggerImageUrl = selectedImage.get(imagesConstants.URL);
    }

    return (
      <Grid.Column width={4}>
        <p className="action-label">{formatMessage({ id: `activity.displayTab.${imageType}.image` })}</p>
        <Gallery
          onApply={(e, data) => this.onImageSelected(imageType, data.selectedId)}
          selectedId={this.state.imageId ? this.state.imageId : null}
          images={this.props.images}
          imageUploadInstructionsKey={this.getImageUploadInstructionsKey(imageType)}
          defaultImage={this.props.defaultImage}
          defaultIfNull
          triggerNode={
            <div
              className={`image-trigger ${imageType}-image-trigger`}
              data-automation-id={`select.${imageType}.image.trigger`}
            >
              {this.state.pointShopItemImageError && <span className={'validation-error-message'}> { formatMessage({ id: 'global.image.size.error' }) }</span> }
              <div className={imageWrapperClassNames}>
                <Image
                  data-automation-id={`select.${imageType}.image`}
                  className="gallery-image"
                  style={{ backgroundImage: `url(${encodeURI(triggerImageUrl)})` }}
                />

              </div>
              <div className="change-image-button" data-automation-id={`select.${imageType}.image.trigger.button`}>
                <Icon className="como-ic-pencil" />{changeImageButtonText}
              </div>
            </div>
          }
        />
      </Grid.Column>
    );
  };

  handleSaveAsDraftButtonClick = () => {
    this.proceedSaveWithValidation(constants.POINT_SHOP_ITEM_STATUS_DRAFT);
  };

  handleSave = () => {
    this.setState({ isLoading: true });
    this.proceedSaveWithValidation(constants.POINT_SHOP_ITEM_STATUS_ACTIVE);
  };

  proceedSaveWithValidation = (status) => {
    const item = { ...this.state };
    if (this.state.pointShopItemMode === constants.POINT_SHOP_ITEM_MODE_CUSTOM) {
      item.gift = null;
    } else if (item.gift.actions && item.gift.actions.length) {
      item.gift.actions[0].actionsList[0].simpleDescription = this.state.simpleDescription;
    }
    item.status = status;
    this.setState({ status });
    const validationErrors = validatePointShopItem(item, status === constants.POINT_SHOP_ITEM_STATUS_DRAFT);

    if (Object.keys(validationErrors).length !== 0) {
      this.setState({ validationErrors, isLoading: false });
      return;
    }
    if (item.price !== null) item.price = item.price * 100;
    this.props.actions.createPointShopItem(item);
  };

  proceedValidationOnChange = () => {
    if (Object.keys(this.state.validationErrors).length) {
      this.setState({ validationErrors: validatePointShopItem(this.state, this.state.status === constants.POINT_SHOP_ITEM_STATUS_DRAFT) });
    }
  };

  componentWillUnmount() {
    this.props.pointShopActions.clearPointShopItemData();
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { selectedBenefit } = this.state;
    const headerClassNames = classNames({ shadowed: this.state.isHeaderShadowed });
    const nameClassNames = classNames({ 'inputFieldError': !!this.state.validationErrors.name });
    const automationIdPrefix = 'pointShop';

    const selectedBenefitIsExpired = selectedBenefit &&
      moment.utc(selectedBenefit[benefitsConstants.BENEFIT_VALID_UNTIL]).isBefore(moment.utc());
    /* const shouldActivityTagBeShown = this.props.hasFullPermission(appConstants.FEATURE_ACTIVITY_TAGS) && this.state.pointShopItemMode !== constants.POINT_SHOP_ITEM_MODE_CUSTOM; */
    const showSimpleModeToggle = this.props.hasFullPermission(activityConstants.GIFT_SIMPLE_MODE_FEATURE) && this.state.pointShopItemMode !== constants.POINT_SHOP_ITEM_MODE_CUSTOM;
    const showSimpleModeCustomText = this.state.simpleMode && this.state.pointShopItemMode !== constants.POINT_SHOP_ITEM_MODE_CUSTOM;
    return (
      <div className="activity point-shop-item form">
        <Helmet>
          <title>Point Shop{this.props.businessDisplayName ? ` - ${this.props.businessDisplayName}` : null}</title>
        </Helmet>
        <EntityHeader
          headerClassName={headerClassNames}
          descriptionClassName={this.state.validationErrors.description && 'error'}
          nameClassName={nameClassNames}
          namePlaceholder={formatMessage({ id: 'microCampaign.title.name.placeholder' })}
          name={this.state.name}
          nameValidationError={this.state.validationErrors.name}
          promptBeforeLeave={this.props.isDirty && !this.props.publishInProgress}
          canSaveAsDraft={!this.state.id}
          saveAsDraftInProgress={this.props.saveAsDraftInProgress}
          onSaveAsDraftClick={this.handleSaveAsDraftButtonClick}
          publishButtonText={formatMessage({ id: 'microCampaign.header.publish' })}
          onSaveEntityClick={this.handleSave}
          hasTabs={false}
          handleNameChange={this.handleNameChange}
          handleDescriptionChange={this.handleDescriptionChange}
          onCancelOrBackClick={this.handleCancelOrBack}
          prefix={'pointShopItem'}
          showPreview={false}
          descriptionPlaceholder={formatMessage({ id: 'activity.title.description.placeholder' })}
          description={this.state.description}
          publishOrSaveInProgress={this.state.isLoading}
          publishButtonDisabled={this.state.isLoading}
          isActivateAddon={false}
          getBusinessBundlePlan={this.props.getBusinessBundlePlan}
        />

        <Form className="form activity-body">
          {!!Object.keys(this.state.validationErrors).length &&
          <div className="toolbar-error-panel">
            <span className="toolbar-error-text">{formatMessage({ id: 'pointShopItem.validation.error.general' })}</span>
          </div>
          }
          <Grid>
            <Grid.Row className={'title-section'}>
              <Grid.Column width={8}>
                <Header>{formatMessage({ id: 'pointShop.definition.title' })}</Header>
              </Grid.Column>
              <Grid.Column width={5}>
                {showSimpleModeToggle && <div style={{ float: 'right' }}>
                  <ToggleButton
                    name="simpleMode"
                    data-automation-id={'gift.simpleMode'}
                    automationId={'gift.simpleMode'}
                    id={'simpleMode'}
                    enabled={this.state.simpleMode}
                    onChange={() => this.setState({ simpleMode: !this.state.simpleMode })}
                    disabled={this.state.pointShopItemMode === constants.POINT_SHOP_ITEM_MODE_CUSTOM}
                  />
                  <p style={{ display: 'inline', margin: '0 30px 0 15px' }}>{formatMessage({ id: 'gift.simpleMode' })}</p>
                </div>}
              </Grid.Column>
              <Grid.Column width={3}>
                {!this.state.id ?
                  <p className={'pointShopItem-mode-switch'}>
                    <a
                      role={'button'}
                      tabIndex={-1}
                      onClick={this.handleModeChange}
                      data-automation-id="puchCard.rewardMode.switch"
                    >
                      {formatMessage({ id: `pointShopItem.mode.go${this.state.pointShopItemMode !== constants.POINT_SHOP_ITEM_MODE_CUSTOM ? 'Custom' : 'Classic'}` })}
                    </a>
                  </p>
                  : null
                }
              </Grid.Column>
             {/* <Grid.Column width={4} className={'activity-tags-wrapper'}>
                <ActivityTags
                  activityTags={this.state.activityTags}
                  autoCompleteOptions={this.props.allOtherActivityTags}
                  handleActivityTagChange={this.handleActivityTagChange}
                  shouldActivityTagBeShown={shouldActivityTagBeShown}
                />
              </Grid.Column>*/}
            </Grid.Row>
            <Grid.Row className={'conditions-section'}>
              <Grid.Column width={16}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column className="condition-left-column point-shop-apply-it" width={2}>
                      <p>{this.props.intl.formatMessage({ id: 'activity.globalConditions.applyIt' })}</p>
                    </Grid.Column>
                    <Grid.Column width={14}>
                      <div>
                        <span>
                          <MembershipTagCondition
                            tags={this.state.tags}
                            triggerModal={this.handleTriggerTagsConditionModal}
                            isModalOpen={this.state.isTagsConditionModalOpen}
                            handleTagsApply={this.handleTagsApply}
                          />
                          <DatesCondition
                            error={this.state.validationErrors.dateTimeRange || null}
                            validFrom={this.state.validFrom}
                            validUntil={this.state.validUntil}
                            triggerModal={this.handleTriggerDatesConditionModal}
                            isModalOpen={this.state.isDatesConditionModalOpen}
                            handleDatesApply={this.handleDatesApply}
                            businessTimeZone={this.props.businessTimeZone}
                            localeDateFormat={this.props.localeDateFormat}
                            dateFormat={this.props.dateFormat}
                            locale={this.props.locale}
                          />
                        </span>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Grid className="actions-section">
            <Grid.Row className="set-up pointShopItem-container-part">
              {this.state.pointShopItemMode === constants.POINT_SHOP_ITEM_MODE_CLASSIC ?
                <React.Fragment>
                  <div>
                    {this.props.intl.formatMessage({ id: 'pointShopItem.label.Purchase' })}
                  </div>
                  <div>
                    <ItemsPopulation
                      automationIdPrefix={'id'}
                      data={this.state.itemGroupsData}
                      disableAny
                      features={this.props.features}
                      index={0}
                      onUpdate={(e, data) => this.onUpdatePurchaseItems(activityConstants.ITEMS_POPULATION, data)}
                      posFeature={appConstants.FEATURES_SHOPPING_CART_SUBMIT_PURCHASE}
                      itemsPopulationValidationErrors={this.state.validationErrors.itemGroupsData || null}
                      position="bottom left"
                      isSimpleMode={showSimpleModeCustomText}
                    />
                  </div>
                  <div>
                    {this.props.intl.formatMessage({ id: 'pointShopItem.label.for' })}
                  </div>
                  <div>
                    <IntegerInputField
                      name="itemPrice"
                      value={this.state.price}
                      onChange={(event, elem) => this.handlePriceChange(elem.name, elem.value)}
                      prefix={`${automationIdPrefix}.${activityConstants.ITEMS_POPULATION}.${activityConstants.CONDITION_VALUE}`}
                      error={this.state.validationErrors.price || null}
                    />
                  </div>
                  <div>
                    {this.props.intl.formatMessage({ id: 'pointShopItem.label.points' })}
                  </div>
                  <div className="point-shop-item-hint">
                    {this.props.intl.formatMessage({ id: 'pointShopItem.label.hint' })}
                  </div>
                </React.Fragment>
                : null
              }
              {this.state.pointShopItemMode === constants.POINT_SHOP_ITEM_MODE_CUSTOM ?
                <React.Fragment>
                  <div>
                    {this.props.intl.formatMessage({ id: 'pointShopItem.label.priceInpoints' })}
                  </div>
                  <div>
                    <IntegerInputField
                      name="itemPrice"
                      value={this.state.price}
                      onChange={(event, elem) => this.handlePriceChange(elem.name, elem.value)}
                      prefix={`${automationIdPrefix}.${activityConstants.ITEMS_POPULATION}.${activityConstants.CONDITION_VALUE}`}
                      error={this.state.validationErrors.price || null}
                    />
                  </div>
                </React.Fragment>
                : null
              }
            </Grid.Row>

            <Grid.Row className="point-shop-item-img-picker-row">
              {this.getImageSelector(activityConstants.IMAGE_TYPE_PREVIEW, (this.props.selectedPreviewImage && this.state.isImageSelected) ? this.props.selectedPreviewImage : this.state.selectedImage)}
              <Grid.Column width={8}>
                {showSimpleModeCustomText && <div>
                  <span style={{ paddingLeft: '70px', paddingRight: '15px' }}>{formatMessage({ id: 'simple.instructions' })}</span>
                  <Input
                    name={activityConstants.SIMPLE_DESCRIPTION}
                    onChange={(e, elem) => this.onUpdateSimpleDescription(activityConstants.SIMPLE_DESCRIPTION, elem.value)}
                    value={this.state.simpleDescription}
                    prefix={`${automationIdPrefix}.${activityConstants.SIMPLE_DESCRIPTION}`}
                    disabled={false}
                    placeholder={'Add Simple Description'}
                    data-automation-id={`${automationIdPrefix}.simpleDescription`}
                  />
                </div>}
              </Grid.Column>
            </Grid.Row>

            {this.state.pointShopItemMode === constants.POINT_SHOP_ITEM_MODE_CUSTOM ?
              <Grid.Row>
                <div className="benefitSelect-section">
                  <Header>
                    {this.props.intl.formatMessage({ id: 'pointShopItem.label.sendBenifit' })}
                  </Header>

                  <div className="benefitSelect-panel">
                    <BenefitsList
                      benefitType={activityConstants.BENEFIT_GIFT}
                      allowCreateGift={false}
                      error={this.state.validationErrors.assetTemplate || null}
                      index={0}
                      name={'benifit'}
                      onChange={this.onBenefitChange}
                      prefix={`${automationIdPrefix}.benefitKey`}
                      value={this.state.assetTemplate}
                      createGiftFromActivity={this.props.activityActions.createGiftFromActivity}
                    />
                    {
                      (selectedBenefitIsExpired) &&
                      (
                        <Grid.Row>
                          <Grid.Column>
                            <p className="hint">
                              {formatMessage(
                                { id: 'benefits.expired.hint' },
                                { benefitName: selectedBenefit.title }
                              )}
                            </p>
                          </Grid.Column>
                        </Grid.Row>
                      )
                    }
                  </div>
                </div>

              </Grid.Row>
              : null
            }
          </Grid>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  features: appSelectors.getFeatures(state),
  locationId: appSelectors.getLocationId(state),
  defaultPreviewImage: imageSelectors.getDefaultImageThumbnail(state),
  defaultStampImage: imageSelectors.getDefaultStampImageThumbnail(state),
  images: imageSelectors.getAllActiveImageThumbnails(state),
  selectedBackgroundImage: activitySelectors.getSelectedBackgroundImage(state),
  selectedStampImage: activitySelectors.getSelectedStampImage(state),
  schemaMembershipConditions: schemaSelectors.getTransformedGlobalMembershipConditions(state),
  activity: activitySelectors.getActivityModel(state),
  selectedPreviewImage: activitySelectors.getSelectedPreviewImage(state),
  isPointShopItemCreated: selectors.getPointShopItemCreated(state),
  isPointShopItemError: selectors.getPointShopItemError(state),
  dateFormat: appSelectors.getBrowserLocaleDateFormat(state),
  businessTimeZone: appSelectors.getBusinessTimeZone(state),
  localeDateFormat: appSelectors.getBrowserLocaleDateFormat(state),
  locale: appSelectors.getBrowserLocale(state),
  pointShopItem: selectors.getPointShopItem(state),
  businessDisplayName: appSelectors.getBusinessDisplayName(state),
  ImageError: selectors.getPointShopItemImageError(state),
  /* allOtherActivityTags: businessCenterSelectors.getAllActivityTags(state),
  campaigns: businessCenterSelectors.getSortedReducedCampaignsData(state), */
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    activityActions: bindActionCreators(activityActions, dispatch),
    formsActions: bindActionCreators(formsActions, dispatch),
    benefitsActions: bindActionCreators(benefitsActions, dispatch),
    pointShopActions: bindActionCreators(pointShopActions, dispatch),
    /* businessActions: bindActionCreators(businessActions, dispatch) */
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.POINT_SHOP_BRANCH, saga });
const withBusinessSaga = injectSaga({ key: reducerConstants.BUSINESS_CENTER_BRANCH, saga: businessSaga });
const withBenefitsSaga = injectSaga({ key: benefitsConstants.BENEFITS, saga: benefitsSaga });
const withFormsSaga = injectSaga({ key: reducerConstants.FORMS_BRANCH, saga: formsSaga });
const withImages = injectSaga({ key: reducerConstants.IMAGES_BRANCH, saga: imagesSaga });

export default compose(
  withSaga,
  withBusinessSaga,
  withBenefitsSaga,
  withFormsSaga,
  withImages,
  withConnect,
  withAuthorization
)(injectIntl(PointShopItem));
