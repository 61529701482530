import { fromJS, List } from 'immutable';
import * as constants from '../activity.constants';
import * as defaults from './activity.reducer.defaults';

import {
  addCase,
  createCaseFromActionsWrapper,
  createEmptyCase,
  swapCases,
  toggleCasesCollapse
} from './utils/cases.reducer.utils';
import { getDefaultActionWrapperForActivity } from './utils/action.reducer.utils';
import { getSchema } from '../activitySchema/activitySchema.selectors';
import * as activityUtils from './utils/activity.reducer.utils';
import * as schemaConditions from '../activitySchema/activitySchema.conditions';


export function activityCasesReducer(state, action) {
  switch (action.type) {

    case constants.SPLIT_TO_CASES: {
      const actionsWrappers = state.getIn([constants.DATA, constants.ACTIONS]);
      const newEmptyCase = createCaseFromActionsWrapper(actionsWrappers);
      const casesList = List().push(newEmptyCase);
      let newState = state
        .setIn([constants.DATA, constants.CASES], casesList)
        .deleteIn([constants.DATA, constants.ACTIONS]);
      if (activityUtils.isTriggerTagsRelated(state.getIn([constants.DATA, constants.TRIGGER]))) {
        const tagCondition = schemaConditions.tag;
        const conditionPath = [constants.DATA, constants.CASES, 0, constants.CONDITIONS, constants.CONDITIONS_LIST];
        newState = newState
          .setIn(conditionPath, fromJS([defaults.emptyCondition]))
          .setIn([...conditionPath, 0, constants.CONDITION_KEY], tagCondition.key)
          .setIn([...conditionPath, 0, constants.CONDITION_VALUE_DATA_TYPE], tagCondition.dataValueType)
          .setIn([...conditionPath, 0, constants.OPERATOR_KEY], constants.IS_ONE_OF);
      }
      return newState;
    }

    case constants.CANCEL_SPLIT: {
      const activityType = state.getIn([constants.DATA, constants.TYPE]);
      let actionsWrappers = new List();
      actionsWrappers = actionsWrappers.push(getDefaultActionWrapperForActivity(activityType));
      return state
        .setIn([constants.DATA, constants.ACTIONS], actionsWrappers)
        .deleteIn([constants.DATA, constants.CASES]);
    }

    case constants.SET_CASES_EXPAND_COLLAPSE_MODE: {
      const newCases = toggleCasesCollapse(state.getIn([constants.DATA, constants.CASES]), action.caseIndex, action.collapse);
      return state.setIn([constants.DATA, constants.CASES], newCases);
    }

    case constants.UPDATE_CASE_DESCRIPTION: {
      return state.setIn([constants.DATA, constants.CASES, action.caseIndex, constants.DESCRIPTION], fromJS(action.description));
    }

    case constants.ADD_CASE: {
      const trigger = state.getIn([constants.DATA, constants.TRIGGER]);
      const cases = state.getIn([constants.DATA, constants.CASES]);
      const newCases = addCase(cases, trigger, getSchema(state));
      return state.setIn([constants.DATA, constants.CASES], newCases);
    }

    case constants.DELETE_CASE: {
      return state.deleteIn([constants.DATA, constants.CASES, action.caseIndex]);
    }

    case constants.CLEAR_CASE_DATA: {
      const activityType = state.getIn([constants.DATA, constants.TYPE]);
      return state.setIn([constants.DATA, constants.CASES, action.caseIndex], createEmptyCase(activityType));
    }

    case constants.UPDATE_CASE_ORDER: {
      const casesList = state.getIn([constants.DATA, constants.CASES]);
      const casesSwapped = swapCases(casesList, action.caseIndex, action.targetIndex);
      return state.setIn([constants.DATA, constants.CASES], casesSwapped);
    }

    case constants.START_AB_TESTING: {
      let newState = state.setIn([constants.DATA, constants.AB_TEST_MODE], fromJS(true));
      const actionsWrappers = state.getIn([constants.DATA, constants.ACTIONS]);
      const probabilityCondition = fromJS(defaults.defaultABTestCondition);
      let newEmptyCase = createCaseFromActionsWrapper(actionsWrappers);
      newEmptyCase = newEmptyCase.setIn([constants.CONDITIONS, constants.CONDITIONS_LIST, 0], fromJS(probabilityCondition))
                                 .setIn([constants.AB_TESTING_FILTERED_POPULATION], probabilityCondition.get(constants.CONDITION_VALUE));
      const casesList = List().push(newEmptyCase).push(newEmptyCase);
      newState = newState
        .setIn([constants.DATA, constants.CASES], casesList)
        .deleteIn([constants.DATA, constants.ACTIONS]);
      return newState;
    }

    case constants.CANCEL_AB_TESTING: {
      const activityType = state.getIn([constants.DATA, constants.TYPE]);
      let actionsWrappers = new List();
      actionsWrappers = actionsWrappers.push(getDefaultActionWrapperForActivity(activityType));
      return state
        .setIn([constants.DATA, constants.ACTIONS], actionsWrappers)
        .deleteIn([constants.DATA, constants.CASES])
        .setIn([constants.DATA, constants.AB_TEST_MODE], fromJS(false));
    }

    default:
      return state;
  }
}
