import React from 'react';
import { injectIntl } from 'react-intl';
import { Card, Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './filterMembers.styles.less';
import * as queryStringUtils from '../../utils/queryStringUtils';
import * as filterMembersUtils from './filterMembers.utils';


export class SingleFilteredMember extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  static propTypes = {
    member: PropTypes.object,
    getCustomer: PropTypes.func,
  };

  getCustomer(event, membershipKey) {
    event.preventDefault();
    this.props.getCustomer(membershipKey);
  }

  render() {
    const locationId = queryStringUtils.getLocationId(location.search);
    const firstName = this.props.member.firstName && this.props.member.firstName != null ? this.props.member.firstName.length < 18 ? this.props.member.firstName : `${this.props.member.firstName.substr(0, 17)}...` : '';
    const lastName = this.props.member.lastName && this.props.member.lastName != null ? this.props.member.lastName.length < 18 ? this.props.member.lastName : `${this.props.member.lastName.substr(0, 17)}...` : '';
    const fullName = `${firstName} ${lastName}`;
    const lastNameInNewRow = fullName.length > 17;
    const fullNameElement = lastNameInNewRow ? (<React.Fragment>{firstName}<br />{lastName}</React.Fragment>) : fullName;
    return (
      <Card
        onClick={(e) => this.getCustomer(e, this.props.member.membershipKey)}
        className="filteredMember"
        id={'member-result'}
        link
        href={`../customer?location_id=${locationId}&search=${this.props.member.membershipKey}`}
      >
        <Image id={'member-image'} src={this.props.member.image || filterMembersUtils.getRandomMemberImage()} />
        <Card.Content id={'member-body'}>
          <Card.Description>
            {fullNameElement}
          </Card.Description>
        </Card.Content>
      </Card>
    );
  }
}

export default injectIntl(SingleFilteredMember);
