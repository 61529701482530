import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import CustomModal from '../CustomModal/CustomModal';
import * as commonConstants from '../common.components.constants';
import * as modalConstants from '../CustomModal/customModal.constants';

class ConfirmationDialog extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string.isRequired,
    confirmButtonKey: PropTypes.string,
    cancelButtonKey: PropTypes.string,
    triggerLabelKey: PropTypes.string,
    tooltipMessageKey: PropTypes.string,
    cancelAction: PropTypes.func,
    contentMessageKey: PropTypes.string.isRequired,
    confirmAction: PropTypes.func.isRequired,
    triggerAutomationId: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.string,
    loading: PropTypes.bool,
    isDefaultImage: PropTypes.bool,
    open: PropTypes.bool,
    triggerType: PropTypes.string,
    titleKey: PropTypes.string,
    size: PropTypes.oneOf([
      modalConstants.MODAL_SIZE_MINI,
      modalConstants.MODAL_SIZE_TINY,
      modalConstants.MODAL_SIZE_SMALL,
      modalConstants.MODAL_SIZE_LARGE,
      modalConstants.MODAL_SIZE_FULLSCREEN
    ]),
    showLoadingElement: PropTypes.oneOf([
      modalConstants.MODAL_LOADING_CONFIRMATION_BUTTON,
      modalConstants.MODAL_LOADING_ICON
    ])
  };

  static defaultProps = {
    triggerType: commonConstants.COMPONENT_TYPE_LINK,
    size: modalConstants.MODAL_SIZE_MINI,
    confirmButtonKey: modalConstants.GENERAL_CONFIRM_BUTTON,
    cancelButtonKey: modalConstants.GENERAL_CANCEL_BUTTON
  };

  constructor(props, context) {
    super(props, context);
    this.state = { isModalOpen: props.open || false };
    this.getTrigger = this.getTrigger.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.open !== nextProps.open) {
      this.setState({ isModalOpen: nextProps.open });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      this.closeModal();
    }
  }

  getTrigger() {
    let trigger;
    const triggerClassNames = classNames(this.props.className, 'activity-link');
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const triggerHasMessage = !!this.props.triggerLabelKey;
    const iconClassNames = classNames(this.props.className, this.props.icon);
    const triggerAutomationId = this.props.triggerAutomationId ? `${this.props.triggerAutomationId}.trigger` : `${this.props.automationIdPrefix}.trigger`;

    if (this.props.isDefaultImage) {
      return (<></>);
    }

    if (!triggerHasMessage && this.props.triggerType !== commonConstants.COMPONENT_TYPE_LINK) {
      return (
        <Icon
          className={iconClassNames}
          title={this.props.tooltipMessageKey && formatMessage({ id: this.props.tooltipMessageKey })}
          data-automation-id={triggerAutomationId}
          loading={this.props.showLoadingElement === modalConstants.MODAL_LOADING_ICON && this.props.loading}
        />
      );
    }

    switch (this.props.triggerType) {
      case commonConstants.COMPONENT_TYPE_TEXT:
        trigger = (
          <span className={triggerClassNames} data-automation-id={triggerAutomationId}>
            { this.props.icon && <Icon
              className={this.props.icon}
            /> }
            {formatMessage({ id: this.props.triggerLabelKey })}
          </span>
        );
        break;
      default:
        trigger = (
          <a className={triggerClassNames} data-automation-id={triggerAutomationId}>
            {this.props.icon && (
              <Icon
                className={this.props.icon}
              />
            )}
            {this.props.triggerLabelKey && formatMessage({ id: this.props.triggerLabelKey })}
          </a>
        );
    }
    return trigger;
  }

  /**
   * handle modal open
   */
  openModal = () => {
    this.setState({
      isModalOpen: true
    });
  };

  /**
   * handle modal close
   */
  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  cancelAction = () => {
    if (this.props.cancelAction) {
      this.props.cancelAction();
    }
    this.closeModal();
  };

  confirmAction = (e) => {
    e.preventDefault();
    this.props.confirmAction();
    if (this.props.showLoadingElement !== modalConstants.MODAL_LOADING_CONFIRMATION_BUTTON) {
      this.closeModal();
    }
  };

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const { confirmClassName } = this.props; // eslint-disable-line react/prop-types
    const dialoguePrefix = `${this.props.automationIdPrefix}.confirmation-dialogue`;
    const trigger = this.getTrigger();
    return (
      <CustomModal
        size={this.props.size}
        open={this.state.isModalOpen}
        onClose={this.cancelAction}
        onOpen={this.openModal}
        automationIdPrefix={dialoguePrefix}
        className="como-dialog"
        actions={[
          <Button
            basic
            className="link"
            key="cancel"
            role="button"
            data-automation-id={`${dialoguePrefix}.cancel`}
            onClick={this.cancelAction}
          >
            {formatMessage({ id: this.props.cancelButtonKey })}
          </Button>,
          <Button key="confirm" data-automation-id={`${dialoguePrefix}.confirm`} onClick={this.confirmAction} loading={this.props.showLoadingElement === modalConstants.MODAL_LOADING_CONFIRMATION_BUTTON && this.props.loading}>
            {formatMessage({ id: this.props.confirmButtonKey })}
          </Button>
        ]}
        trigger={trigger}
        content={formatMessage({ id: this.props.contentMessageKey })}
        header={this.props.titleKey ? formatMessage({ id: this.props.titleKey }) : ''}
        closeIcon={false}
      />
    );
  }
}

export default injectIntl(ConfirmationDialog);
