import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import * as moment from 'moment';
import {connect} from "react-redux";
import * as constants from '../microCampaigns.constants';
import '../microCampaigns.styles.less';
import '../../Activity/views/cases/cases.styles.less';
import * as statsUtils from '../utils/microCampaigns.stats.utils';
import BarChartCustom from './BarChartCustom';
import StatisticsDetailsSection from './StatisticsDetailsSection';
import * as defaults from '../microCampaigns.defaults';
import * as appSelectors from "../../App/selectors";


export class SingleMicroCampaignStatistics extends React.PureComponent {

  static propTypes = {
    intl: PropTypes.object,
    automationIdPrefix: PropTypes.string,
    weeklyStats: PropTypes.object,
    localeDateFormat: PropTypes.string,
    totalStats: PropTypes.object,
    currencySymbol: PropTypes.string,
    dateFormat: PropTypes.string,
    businessTimeZone: PropTypes.string
  };

  getOverallGraphWidth = () => this.props.weeklyStats.size >= 8 ? defaults.GRAPH_PER_WEEK_WIDTH * this.props.weeklyStats.size : defaults.GRAPH_PER_WEEK_WIDTH * 8;

  getMembersBarWidth = (recentBatchMembers, totalMembers) => totalMembers > 0 ? Math.max((defaults.TOTAL_MEMBERS_BAR_WIDTH * recentBatchMembers) / totalMembers, 1) : 0;

  getUpdatedDateByBZTimeAndLocaleFormat(dateTimeString) {
    return moment.utc(dateTimeString).tz(this.props.businessTimeZone).format(this.props.dateFormat);
  }

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const weeklyRevenuesData = statsUtils.getWeeklyRevenuesDataForChart(this.props.weeklyStats, constants.START_CAMPAIGN_DATE);

    const recentWeekObject = this.props.weeklyStats.max((w) => w.get(constants.WEEK)).get(constants.START_CAMPAIGN_DATE);
    const recentWeek = moment(recentWeekObject).format(this.props.localeDateFormat);
    const graphWidth = this.getOverallGraphWidth();
    const totalMembers = this.props.totalStats.get(constants.MEMBERS);
    const recentBatchData = statsUtils.getRecentBatchData(this.props.weeklyStats);
    const recentBatchMembers = recentBatchData.get(constants.MEMBERS);
    // todo: remove:
    // const recentBatchMembers = 1000;
    const recentBatchMembersBarWidth = this.getMembersBarWidth(recentBatchMembers, totalMembers);
    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} className="overview" >
              <p className="subtitle">{formatMessage({ id: 'microCampaigns.statistics.revenue.perWeek' })}</p>
              <BarChartCustom
                data={weeklyRevenuesData.data}
                chartWidth={graphWidth}
                chartHeightDomain={weeklyRevenuesData.heightDomain}
                currencySymbol={this.props.currencySymbol}
                localeDateFormat={this.props.localeDateFormat}
              />
            </Grid.Column>
            <Grid.Column width={8} className="details">
              <StatisticsDetailsSection
                titleKey={formatMessage({id: "microCampaigns.statistics.recent.week"}, {date: recentWeek})}
                formatTitleKey={false}
                membersBarWidth={recentBatchMembersBarWidth}
                membersNumber={recentBatchMembers}
                revenue={recentBatchData.get('Revenue') || 0}
                redeemRate={recentBatchData.get('RedeemRate') || 0}
                revenuePerMember={recentBatchData.get('RevPerMember') || 0}
                currencySymbol={this.props.currencySymbol}
                automationIdPrefix={this.props.automationIdPrefix}
              />
              <StatisticsDetailsSection
                titleKey="microCampaigns.statistics.total"
                formatTitleKey
                membersBarWidth={defaults.TOTAL_MEMBERS_BAR_WIDTH}
                membersNumber={totalMembers}
                revenue={this.props.totalStats.get('Revenue') || 0}
                redeemRate={this.props.totalStats.get('RedeemRate') || 0}
                revenuePerMember={this.props.totalStats.get('RevPerMember') || 0}
                currencySymbol={this.props.currencySymbol}
                automationIdPrefix={this.props.automationIdPrefix}
              />
              <div className="last-updated-note">{`${formatMessage({ id: 'microCampaigns.statistics.lastUpdated' })} ${this.getUpdatedDateByBZTimeAndLocaleFormat(this.props.totalStats.get('timeUpdated'))}`}</div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  localeDateFormat: appSelectors.getBrowserLocaleDateFormat(state),
});

export default (connect)(mapStateToProps)(injectIntl(SingleMicroCampaignStatistics));

