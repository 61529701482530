import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Grid } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import * as schemaConstants from '../../../../activitySchema/activitySchema.constants';
import BenefitsList from '../../../../../Benefits/views/benefitsList/benefitsList';
import IntegerInputField from '../../../../../../common/components/FormInputFields/IntegerInputField/IntegerInputField';
import * as activitySelectors from '../../../../activity.selectors';
import * as benefitSelectors from '../../../../../Benefits/benefits.selector';
import * as activityActions from '../../../../activity.actions';
import './removeAsset.styles.less';
import * as constants from '../../../../../Benefits/benefits.constants';


class removeAsset extends React.PureComponent {

  static propTypes = {
    action: PropTypes.object,
    actions: PropTypes.object,
    automationIdPrefix: PropTypes.string,
    index: PropTypes.number,
    onActionFieldUpdate: PropTypes.func.isRequired,
    selectedBenefit: PropTypes.object,
    validationErrors: PropTypes.object
  };

  onBenefitSelect = (e, data) => {
    this.props.onActionFieldUpdate(data.name, data.value, this.props.index);
  };

  render() {

    const { action, automationIdPrefix, index, selectedBenefit, validationErrors } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const selectedBenefitIsExpired = selectedBenefit &&
      moment.utc(selectedBenefit.get(constants.BENEFIT_VALID_UNTIL)).isBefore(moment.utc());
    const selectedBenefitIsInactive = selectedBenefit && !selectedBenefit.get(constants.BENEFIT_ACTIVE);

    return (
      <Grid className="action assign-asset-action">
        <Grid.Row>
          <Grid.Column width={16}>
            <div>
              <BenefitsList
                action={action}
                className={"benefits-list"}
                error={validationErrors ? validationErrors.get(schemaConstants.ASSIGN_ASSET_BENEFIT_KEY_FIELD) : null}
                benefitType={constants.GIFT}
                index={index}
                name={schemaConstants.ASSIGN_ASSET_BENEFIT_KEY_FIELD}
                onChange={this.onBenefitSelect}
                prefix={`${automationIdPrefix}.benefitKey`}
                value={action.get(schemaConstants.ASSIGN_ASSET_BENEFIT_KEY_FIELD)}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state, props) => ({
  selectedBenefit: benefitSelectors.getSelectedBenefit(state, props.action.get(schemaConstants.ASSIGN_ASSET_BENEFIT_KEY_FIELD))
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(activityActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl
)(removeAsset);
