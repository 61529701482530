import * as constants from '../activity.constants';
import * as utils from './utils/condition.reducer.utils';
import { getSchema } from '../activitySchema/activitySchema.selectors';

export function activityConditionsReducer(state, action) {

  switch (action.type) {

    case constants.ADD_CONDITION: {
      const trigger = state.getIn([constants.DATA, constants.TRIGGER]);
      const isConditionInCases = action.conditionParent === constants.CASES;
      const newCondition = utils.createDefaultCondition(trigger, getSchema(state), isConditionInCases);
      const path = isConditionInCases
        ? [constants.DATA, constants.CASES, action.caseIndex, constants.CONDITIONS, constants.CONDITIONS_LIST]
        : [constants.DATA, constants.CONDITIONS, constants.CONDITIONS_LIST];
      const newConditionsList = state.getIn(path).push(newCondition);
      return state.setIn(path, newConditionsList);
    }

    case constants.UPDATE_CONDITION_FIELD: {
      // update single condition inside conditions List
      const pathPrefix = action.conditionParent === constants.CASES
        ? [constants.DATA, constants.CASES, action.caseIndex, constants.CONDITIONS, constants.CONDITIONS_LIST]
        : [constants.DATA, constants.CONDITIONS, constants.CONDITIONS_LIST];
      const conditionPath = pathPrefix.concat(action.conditionIndex);
      const condition = state.getIn(conditionPath);
      const trigger = state.getIn([constants.DATA, constants.TRIGGER]);
      const newCondition = utils.updateConditionField(condition, trigger, action.fieldName, action.fieldValue, getSchema(state));
      let newState = state.setIn(conditionPath, newCondition);
      // in ab testing condition of the second case is (100 - condition of the first case) (percents)
      if (action.abTestMode && action.caseIndex === 0) {
        const passiveConditionPath = [constants.DATA, constants.CASES, 1, constants.CONDITIONS, constants.CONDITIONS_LIST, 0];
        const passiveCondition = state.getIn(passiveConditionPath);
        const newPasiveCondition = utils.updateConditionField(passiveCondition, trigger, action.fieldName, 100 - parseInt(action.fieldValue, 10), getSchema(state));
        newState = newState.setIn(passiveConditionPath, newPasiveCondition);
      }
      return newState;
    }

    case constants.DELETE_CONDITION: {
      // delete single condition by index from conditions List
      const path = action.conditionParent === constants.CASES
        ? [constants.DATA, constants.CASES, action.caseIndex, constants.CONDITIONS, constants.CONDITIONS_LIST, action.index]
        : [constants.DATA, constants.CONDITIONS, constants.CONDITIONS_LIST, action.index];
      return state.deleteIn(path);
    }

    default:
      return state;
  }
}
