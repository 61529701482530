import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import { Mention, MentionsInput } from 'react-mentions';
import './mentionTextArea.styles.less';
import * as selectors from '../../../features/Activity/activity.selectors';
import * as actions from './mentions.actions';


class MentionTextArea extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string,
    mentionOptions: PropTypes.object.isRequired,
    message: PropTypes.string,
    autoAddedMessage: PropTypes.string,
    placeholder: PropTypes.string,
    onTextUpdate: PropTypes.func.isRequired,
    singleLine: PropTypes.bool,
    error: PropTypes.string,
    intl: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    hideTips: PropTypes.bool,
    className: PropTypes.string,
    wrapperStyle: PropTypes.object,
    inputStyle: PropTypes.object,
  };

  static getMentionDisplayTemplate(mention) {
    return `[${mention.get('display')}]`;
  }

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.handleMentionPaste = this.handleMentionPaste.bind(this);

    this.state = {
      messageForDisplay: ''
    };
    this.inputEl = React.createRef();
  }

  componentWillMount() {
    this.setMessageState(this.props.message);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.message !== this.props.message) {
      this.setMessageState(nextProps.message);
    }
  }


  insertOnCursorPosition(currentMessage, pastedMessage, cursorPosition) {
    const currentMessageSplitted = currentMessage.split('');
    currentMessageSplitted.splice(cursorPosition, 0, pastedMessage);
    return currentMessageSplitted.join('');
  }

  /**
   * Check if there are mentions in pasted data and put mention id instead of plain text
   * @param event
   */
  handleMentionPaste = (event) => {
    const targetElemMessage = event.target.value;
    const targetElemCursorPosition = event.target.selectionStart;

    // get data from clipboard
    const messageToPaste = event.clipboardData.getData('text/plain');

    // remove possible formatting
    const tempDiv = document.createElement('DIV');
    tempDiv.innerHTML = messageToPaste;
    const messageToPasteWithoutFormatting = tempDiv.innerText;

    // insert to the relevant place (where cursor is)
    const combinedPlainText = this.insertOnCursorPosition(targetElemMessage, messageToPasteWithoutFormatting, targetElemCursorPosition);
    let combinedTextWitMentions = combinedPlainText;
    const usedMentions = [];

    // relate to text's mentions
    if (this.props.mentionOptions && this.props.mentionOptions.size > 0) {
      this.props.mentionOptions.forEach((mention) => {
        const mentionDisplay = mention.get('display');
        const mentionIdTemplate = `{{${mention.get('id')}}}`;
        const mentionDisplayTemplate = MentionTextArea.getMentionDisplayTemplate(mention);
        if (combinedPlainText.indexOf(mentionDisplay) > -1) {
          combinedTextWitMentions = combinedTextWitMentions.split(mentionDisplay).join(`${mentionDisplayTemplate + mentionIdTemplate}`);
          usedMentions.push(mention);
        }
      });
    }


    this.onChange(event, combinedTextWitMentions, combinedPlainText, usedMentions);

  };

  /**
   *
   * @param e - The event object
   * @param newTextValue - The text in the format Hi [Member: First name]{{context.Membership.FirstName}}
   * @param newPlainTextValue - The plain text shown: Hi Member: First name
   * @param mentions the mentions used for this message
   */
  onChange(e, newTextValue, newPlainTextValue, mentions) {
    this.setState({ messageForDisplay: newTextValue });
    let messageForServer = newTextValue;
    if (mentions && mentions.length > 0) {
      this.props.mentionOptions.forEach((mention) => {
        messageForServer = messageForServer.split(MentionTextArea.getMentionDisplayTemplate(mention)).join('');
      });
    } else {
      messageForServer = newPlainTextValue;
    }
    this.props.onTextUpdate(messageForServer);
  }

  /**
   * set message for display
   * @param message
   */
  setMessageState(message) {
    if (message) {
      let messageForDisplay = message;
      if (this.props.mentionOptions && this.props.mentionOptions.size > 0) {
        this.props.mentionOptions.forEach((mention) => {
          const mentionDisplayTemplate = MentionTextArea.getMentionDisplayTemplate(mention);
          const mentionIdTemplate = `{{${mention.get('id')}}}`;
          messageForDisplay = messageForDisplay.split(mentionIdTemplate).join(mentionDisplayTemplate + mentionIdTemplate);
        });
      }
      this.setState({ messageForDisplay });
    } else {
      this.setState({ messageForDisplay: '' });
    }
  }


  get placeholder() {
    if (this.props.error) {
      const errorMsg = this.props.intl.formatMessage({ id: this.props.error });
      if (this.props.placeholder) {
        return `${this.props.placeholder} - ${errorMsg}`;
      }
      return errorMsg;
    }
    return this.props.placeholder;
  }

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const clsName = classNames(this.props.className, 'mentions mention-rtl', { 'inputFieldError': this.props.error });

    const options = this.props.mentionOptions ? this.props.mentionOptions.toJS() : {};

    return (
      <div style={this.props.wrapperStyle}>
        <MentionsInput
          style={this.props.inputStyle}
          disabled={this.props.disabled}
          value={this.state.messageForDisplay || ''}
          onChange={this.onChange}
          className={clsName}
          markup="[__display__]{{__id__}}"
          allowSpaceInQuery
          placeholder={this.placeholder}
          singleLine={this.props.singleLine}
          data-automation-id={`${this.props.automationIdPrefix}.mentionTextArea`}
          onPaste={this.handleMentionPaste}
          ref={this.inputEl}
        >
          <Mention
            trigger="@"
            data={options}
          />
        </MentionsInput>
        {
          (this.props.autoAddedMessage && this.props.autoAddedMessage.length) ?
            (<p className="action-label action-note-label">
              {formatMessage({ id: 'activity.action.mentions.autoAddedMessage' }, {message: this.props.autoAddedMessage})}
            </p>)
            : null
        }
        {
          (!this.props.hideTips && !this.props.mentionOptions.isEmpty()) ?
            (<p className="action-label action-note-label">
              {formatMessage({ id: 'activity.action.mentions.tip' })}
            </p>)
            : null
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  copiedValue: selectors.getMentionFieldCopiedValue(state)
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(injectIntl(MentionTextArea));
