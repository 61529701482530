import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import PropTypes from 'prop-types';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import CustomModal from '../../../../common/components/CustomModal/CustomModal';
import ItemsPopulationContent from './itemsPopulationContent';

import * as activityConstants from '../../activity.constants';
import * as itemGroupsConstants from '../../../ItemGroups/itemGroups.constants';
import * as itemsPopulationConstants from './itemsPopulation.constants';

import * as itemsPopulationActions from './itemsPopulation.actions';
import InputWrapper from '../../../../common/components/FormInputFields/InputWrapper/InputWrapper';
import withAuthorization from '../../../../common/helpers/authorization';

const IS_MODAL_OPEN = 'isModalOpen';
const IS_PRISTINE = 'isPristine';
const LOADING = 'loading';

class EditItemsGroup extends React.PureComponent {

  static propTypes = {
    actions: PropTypes.object.isRequired,
    advancedRulesSchemaConditions: PropTypes.object.isRequired,
    automationIdPrefix: PropTypes.string.isRequired,
    group: PropTypes.object.isRequired,
    tempItemsGroup: PropTypes.object,
    onUpdateItemsGroup: PropTypes.func.isRequired,
    getPermissionLevel: PropTypes.func.isRequired,
    posFeature: PropTypes.string.isRequired,
    getBusinessBundlePlan: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      [IS_MODAL_OPEN]: false,
      [IS_PRISTINE]: true,
      [LOADING]: false,
    };
    this.onSave = this.onSave.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.tempItemsGroup && this.props.tempItemsGroup.size && nextProps.tempItemsGroup !== this.props.tempItemsGroup) {
      this.setState({
        [IS_PRISTINE]: false
      });
    }
    if (this.state[LOADING]) {
      this.closeModal();
    }
  }

  /**
   * handle saving edit changes
   */
  onSave = () => {
    this.setState({
      [LOADING]: true,
    }, () => {
      const type = this.props.tempItemsGroup.get(activityConstants.TYPE);
      const name = this.props.tempItemsGroup.get(itemGroupsConstants.NAME);
      const conditions = this.props.tempItemsGroup.getIn([
        activityConstants.ITEMS_POPULATION, type, activityConstants.CONDITIONS
      ]);
      const updatedItemsGroup = this.props.group
        .set(activityConstants.TYPE, type)
        .set(itemGroupsConstants.NAME, name)
        .set(activityConstants.CONDITIONS, conditions);
      this.props.onUpdateItemsGroup(updatedItemsGroup);
    });
  };

  /**
   * handle modal open
   */
  openModal = () => {
    this.props.actions.copyActivityDataToTempData(this.props.group, true);
    this.setState({
      [IS_MODAL_OPEN]: true,
    });
  };

  /**
   * handle modal close
   */
  closeModal = () => {
    this.setState({
      [IS_MODAL_OPEN]: false,
      [LOADING]: false,
    }, () => {
      this.props.actions.clearTempData(true);
    });
  };

  render() {

    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const automationIdPrefix = `${this.props.automationIdPrefix}.editGroup`;
    const itemsPopulationType = this.props.tempItemsGroup ? this.props.tempItemsGroup.get(activityConstants.TYPE) : '';
    const itemsPopulationConditions = this.props.tempItemsGroup && this.props.tempItemsGroup.getIn([activityConstants.ITEMS_POPULATION,
      this.props.tempItemsGroup.get(activityConstants.TYPE), activityConstants.CONDITIONS, activityConstants.CONDITIONS_LIST])
      ? this.props.tempItemsGroup.getIn([activityConstants.ITEMS_POPULATION, this.props.tempItemsGroup.get(activityConstants.TYPE), activityConstants.CONDITIONS, activityConstants.CONDITIONS_LIST]).toJS()
      : [];
    const conditionsRelations = this.props.tempItemsGroup && this.props.tempItemsGroup.getIn([activityConstants.ITEMS_POPULATION,
      this.props.tempItemsGroup.get(activityConstants.TYPE), activityConstants.CONDITIONS,
      activityConstants.CONDITION_LIST_OPERATOR])
      ? this.props.tempItemsGroup.getIn([activityConstants.ITEMS_POPULATION,
        this.props.tempItemsGroup.get(activityConstants.TYPE), activityConstants.CONDITIONS, activityConstants.CONDITION_LIST_OPERATOR]) : '';

    return (
      <CustomModal
        automationIdPrefix={automationIdPrefix}
        size="large"
        className="edit-group-modal"
        open={this.state[IS_MODAL_OPEN]}
        onClose={this.closeModal}
        onOpen={this.openModal}
        header={formatMessage({
          id: 'activity.itemsPopulation.itemGroups.editGroup.title'
        })}
        actions={[
          <Button
            basic
            className="link"
            key="cancel"
            role="button"
            data-automation-id={`${automationIdPrefix}.cancel`}
            onClick={(e) => this.closeModal(e, true)}
          >
            {formatMessage({ id: 'general.button.cancel', defaultMessage: 'Cancel' })}
          </Button>,
          <Button key="save" data-automation-id={`${automationIdPrefix}.save`} onClick={this.onSave} loading={this.state.loading}>
            {formatMessage({ id: 'general.button.save' })}
          </Button>
        ]}
        trigger={(
          <Icon
            className="como-ic-pencil"
            data-automation-id={automationIdPrefix}
          />
        )}
        content={(
          <Grid>
            <Grid.Row>
              <Grid.Column width={2}>
                <p className="edit-group-label">
                  {formatMessage({ id: 'activity.itemsPopulation.itemGroups.newGroup.name', defaultMessage: 'Name' })}
                </p>
              </Grid.Column>
              <Grid.Column width={14}>
                <InputWrapper
                  name="groupName"
                  onChange={(e, data) => this.props.actions.updateGroupName(data.value)}
                  value={this.props.tempItemsGroup ? this.props.tempItemsGroup.get(itemGroupsConstants.NAME) : ''}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={2}>
                <p className="edit-group-label">
                  {formatMessage({ id: 'activity.itemsPopulation.itemGroups.editGroup.items', defaultMessage: 'Items' })}
                </p>
              </Grid.Column>
              <Grid.Column width={14}>
                <ItemsPopulationContent
                  automationIdPrefix={automationIdPrefix}
                  isEditGroupMode
                  itemsPopulationType={itemsPopulationType}
                  itemsPopulationConditions={itemsPopulationConditions}
                  onAddCondition={(type) => this.props.actions.addCondition(type, true)}
                  onClearAllInType={(type) => this.props.actions.clearAllInType(type, true)}
                  onDeleteCondition={(type, index) => this.props.actions.deleteCondition(type, index, true)}
                  updateConditionField={(type, index, key, value) => this.props.actions.updateCondition(type, index, key, value, true)}
                  updateWholeCondition={(...args) => this.props.actions.updateWholeCondition(...args, true)}
                  onUpdateType={(type) => this.props.actions.updateType(type, true)}
                  advancedRulesSchemaConditions={this.props.advancedRulesSchemaConditions}
                  conditionsRelations={conditionsRelations}
                  updateConditionsRelations={(type, relation) => this.props.actions.updateConditionsRelations(type, relation, true)}
                  posFeature={this.props.posFeature}
                  getPermissionLevel={this.props.getPermissionLevel}
                  getBusinessBundlePlan={this.props.getBusinessBundlePlan}
                />
              </Grid.Column>
            </Grid.Row>
            {
              this.state[IS_PRISTINE]
                ? null
                : <p className="edit-group-alert">{formatMessage({ id: 'activity.itemsPopulation.itemGroups.editGroup.alert', defaultMessage: 'Editing will affect other activities using this group' })}</p>
            }
          </Grid>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  tempItemsGroup: state.getIn([activityConstants.ACTIVITY, itemsPopulationConstants.TEMP_ITEMS_GROUP])
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(itemsPopulationActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, injectIntl, withAuthorization)(EditItemsGroup);
