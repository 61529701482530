/*
 *
 * Templates constants
 *
 */

// ACTIONS CONSTANTS

export const DEFAULT_ACTION = 'app/Templates/DEFAULT_ACTION';
export const GET_BUSINESS_TEMPLATES = 'app/Templates/GET_BUSINESS_TEMPLATES';
export const GET_BUSINESS_TEMPLATES_SUCCESS = 'app/Templates/GET_BUSINESS_TEMPLATES_SUCCESS';
export const GET_BUSINESS_TEMPLATES_ERROR = 'app/Templates/GET_BUSINESS_TEMPLATES_ERROR';

export const CREATE_BUSINESS_TEMPLATE = 'app/Templates/CREATE_BUSINESS_TEMPLATE';
export const CREATE_BUSINESS_TEMPLATE_SUCCESS = 'app/Templates/CREATE_BUSINESS_TEMPLATE_SUCCESS';
export const CREATE_BUSINESS_TEMPLATE_ERROR = 'app/Templates/CREATE_BUSINESS_TEMPLATE_ERROR';

export const GET_PRODUCT_TEMPLATES = 'app/Templates/GET_PRODUCT_TEMPLATES';
export const GET_PRODUCT_TEMPLATES_SUCCESS = 'app/Templates/GET_PRODUCT_TEMPLATES_SUCCESS';
export const GET_PRODUCT_TEMPLATES_ERROR = 'app/Templates/GET_PRODUCT_TEMPLATES_ERROR';

export const SAVE_AS_BUSINESS_TEMPLATE = 'app/Templates/SAVE_AS_BUSINESS_TEMPLATE';
export const SAVE_AS_PRODUCT_TEMPLATE = 'app/Templates/SAVE_AS_PRODUCT_TEMPLATE';
// export const SAVE_DEAL_AS_TEMPLATE = 'app/Templates/SAVE_DEAL_AS_TEMPLATE';
export const SAVE_AS_TEMPLATE_SUCCESS = 'app/Templates/SAVE_AS_TEMPLATE_SUCCESS';
export const SAVE_AS_TEMPLATE_ERROR = 'app/Templates/SAVE_AS_TEMPLATE_ERROR';
export const MARK_SAVE_AS_TEMPLATE_IN_PROGRESS_FALSE = 'app/Templates/MARK_SAVE_AS_TEMPLATE_IN_PROGRESS_FALSE';

export const DELETE_BUSINESS_TEMPLATE = 'app/Templates/DELETE_BUSINESS_TEMPLATE';
export const DELETE_BUSINESS_TEMPLATE_SUCCESS = 'app/Templates/DELETE_BUSINESS_TEMPLATE_SUCCESS';
export const DELETE_BUSINESS_TEMPLATE_ERROR = 'app/Templates/DELETE_BUSINESS_TEMPLATE_ERROR';

export const DELETE_PRODUCT_TEMPLATE = 'app/Templates/DELETE_PRODUCT_TEMPLATE';
export const DELETE_PRODUCT_TEMPLATE_SUCCESS = 'app/Templates/DELETE_PRODUCT_TEMPLATE_SUCCESS';
export const DELETE_PRODUCT_TEMPLATE_ERROR = 'app/Templates/DELETE_PRODUCT_TEMPLATE_ERROR';

export const UPDATE_BUSINESS_TEMPLATE = 'app/Templates/UPDATE_BUSINESS_TEMPLATE';
export const UPDATE_BUSINESS_TEMPLATE_METADATA = 'app/Templates/UPDATE_BUSINESS_TEMPLATE_METADATA';
export const UPDATE_BUSINESS_TEMPLATE_SUCCESS = 'app/Templates/UPDATE_BUSINESS_TEMPLATE_SUCCESS';
export const UPDATE_DEAL_BUSINESS_TEMPLATE_ERROR = 'app/Templates/UPDATE_DEAL_BUSINESS_TEMPLATE_ERROR';


export const UPDATE_PRODUCT_TEMPLATE = 'app/Templates/UPDATE_PRODUCT_TEMPLATE';
export const UPDATE_PRODUCT_TEMPLATE_METADATA = 'app/Templates/UPDATE_PRODUCT_TEMPLATE_METADATA';
export const UPDATE_PRODUCT_TEMPLATE_SUCCESS = 'app/Templates/UPDATE_PRODUCT_TEMPLATE_SUCCESS';
export const UPDATE_PRODUCT_TEMPLATE_ERROR = 'app/Templates/UPDATE_PRODUCT_TEMPLATE_ERROR';

export const GET_PRODUCT_CATEGORIES = 'app/Templates/GET_PRODUCT_CATEGORIES';
export const GET_PRODUCT_CATEGORIES_SUCCESS = 'app/Templates/GET_PRODUCT_CATEGORIES_SUCCESS';
export const GET_PRODUCT_CATEGORIES_ERROR = 'app/Templates/GET_PRODUCT_CATEGORIES_ERROR';

export const GET_BUSINESSES_VERTICALS = 'app/Templates/GET_BUSINESSES_VERTICALS';
export const GET_BUSINESSES_VERTICALS_SUCCESS = 'app/Templates/GET_BUSINESSES_VERTICALS_SUCCESS';
export const GET_BUSINESSES_VERTICALS_ERROR = 'app/Templates/GET_BUSINESSES_VERTICALS_ERROR';
export const SET_SAVE_TEMPLATE_IN_PROGRESS = 'app/Templates/SET_SAVE_TEMPLATE_IN_PROGRESS';
export const CLEAR_TEMPLATES = 'app/Templates/CLEAR_TEMPLATES';

export const LOAD_TEMPLATE_DATA_INTO_ACTIVITY = 'app/activity/LOAD_TEMPLATE_DATA_INTO_ACTIVITY';
export const LOAD_TEMPLATE_DATA_INTO_EMAIL = 'app/activity/LOAD_TEMPLATE_DATA_INTO_EMAIL';


// STORE CONSTANTS
export const BUSINESS_TEMPLATES = 'businessTemplates';
export const PRODUCT_TEMPLATES = 'productTemplates';
export const PRODUCT_TEMPLATES_CATEGORIES = 'productTemplatesCategories';
export const BUSINESSES_VERTICALS = 'businessesVerticals';
export const RULE_BUSINESS_TEMPLATES = 'ruleBusinessTemplates';
export const RULE_PRODUCT_TEMPLATES = 'ruleProductTemplates';
export const RULE_PRODUCT_CATEGORIES = 'ruleProductCategories';
export const DEAL_BUSINESS_TEMPLATES = 'dealBusinessTemplates';
export const DEAL_PRODUCT_TEMPLATES = 'dealProductTemplates';
export const TEMPLATES_ENTITY_TYPE = 'templatesEntityType';

export const DB_ID = 'dbId';
export const ACTIVITY = 'activity';
export const TEMPLATE_NAME = 'templateName';
export const TEMPLATE_DESCRIPTION = 'templateDescription';
export const TEMPLATE_CATEGORY_NONE = 'None';
export const DEFAULT_CATEGORY = TEMPLATE_CATEGORY_NONE;

export const NAME = 'name';
export const LOCATION_ID = 'locationId';
export const CAMPAIGN_KEY = 'campaignKey';
export const CATEGORY_DB_ID = 'categoryDbId';
export const TEMPLATE_VERTICALS = 'verticals';
export const SAVE_TEMPLATE_IN_PROGRESS = 'saveTemplateInProgress';

export const TEMPLATE_TYPE_PRODUCT = 'product';
export const TEMPLATE_TYPE_BUSINESS = 'business';

export const TEMPLATE_ADMIN = 'templatesAdmin';
