import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';
import Modal from '../CustomModal';

const AUTOMATION_NAME = 'confirmation';

class Confirmation extends React.PureComponent {

  static propTypes = {
    trigger: PropTypes.node,
    cancelButtonKey: PropTypes.string,
    content: PropTypes.node,
    children: PropTypes.node,
    confirmButtonKey: PropTypes.string,
    header: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    open: PropTypes.bool,
    className: PropTypes.string,
    size: PropTypes.string
  };

  static defaultProps = {
    size: 'tiny'
  };

  constructor(props) {
    super(props);
    this.state = { isModalOpen: props.open || false };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.open !== nextProps.open) {
      this.setState({ isModalOpen: nextProps.open });
    }
  }

  handleOnConfirm = () => {
    const { onConfirm } = this.props;
    if (onConfirm) {
      onConfirm();
    }
    this.handleModalClose();
  };

  handleOnCancel = () => {
    const { onCancel } = this.props;
    if (onCancel) {
      onCancel();
    }
    this.handleModalClose();
  };

  handleModalOpen = () => {
    this.setState({
      isModalOpen: true
    });
  };

  handleModalClose = () => {
    this.setState({
      isModalOpen: false
    });
  };

  modalActionsHtml() {
    const { formatMessage } = this.props.intl; // eslint-disable-line

    return [
      <Button
        key="cancel"
        basic
        data-automation-id={`${AUTOMATION_NAME}.btn.cancel`}
        onClick={this.handleOnCancel}
        className="link"
      >
        {this.props.cancelButtonKey ? formatMessage({ id: this.props.cancelButtonKey }) : formatMessage({ id: 'general.button.cancel' })}
      </Button>,
      <Button
        key="confirm"
        data-automation-id={`${AUTOMATION_NAME}.btn.confirm`}
        onClick={this.handleOnConfirm}
      >
        {this.props.confirmButtonKey ? formatMessage({ id: this.props.confirmButtonKey }) : formatMessage({ id: 'general.button.ok' })}
      </Button>
    ];
  }

  render() {

    const { formatMessage } = this.props.intl; // eslint-disable-line
    const { trigger, content, children } = this.props;

    return (
      <Modal
        className={this.props.className}
        header={this.props.header || formatMessage({ id: 'global.confirmation.header' })}
        trigger={trigger}
        onOpen={this.handleModalOpen}
        onClose={this.handleOnCancel}
        content={children || content}
        open={this.state.isModalOpen}
        actions={this.modalActionsHtml()}
        automationIdPrefix={AUTOMATION_NAME}
        size={this.props.size}
      />
    );
  }
}

export default injectIntl(Confirmation);
