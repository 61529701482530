export function getScrollParent(element, excludeStaticParent = false) {
  let style = getComputedStyle(element);
  const overflowRegex = /(auto|scroll)/;
  if (style.position === 'fixed') {
    return document.body;
  }
  // eslint-disable-next-line
  for (let parent = element; (parent = parent.parentElement);) {
    style = getComputedStyle(parent);
    if (excludeStaticParent && style.position === 'static') {
      // eslint-disable-next-line
      continue;
    }
    if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
      return parent;
    }
  }
  return document.body;
}

export function findMatchingParentOrSelf(node, predicate) {
  let currentNode = node;
  while (currentNode) {
    if (predicate(currentNode)) {
      return currentNode;
    }
    currentNode = currentNode.parentElement;
  }
  return null;
}
