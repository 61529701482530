import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Input, List } from 'semantic-ui-react';
import moment from "moment";
import * as constants from '../../benefits.constants';
import Benefits from './benefits';
import DropdownWrapper from '../../../../common/components/DropdownWrapper/DropdownWrapper';

class BenefitsListContent extends React.PureComponent {

  static propTypes = {
    allowCreateGift: PropTypes.bool,
    automationIdPrefix: PropTypes.string,
    benefits: PropTypes.object.isRequired,
    benefitType: PropTypes.string,
    index: PropTypes.number,
    onCreateGift: PropTypes.func,
    onSelectOption: PropTypes.func.isRequired,
    selectedBenefit: PropTypes.object,
    value: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      typesList: [],
      statusesList: [],
      [constants.FILTER_SEARCH]: '',
      [constants.BENEFIT_TYPE]: constants.FILTER_ALL,
      [constants.BENEFIT_ACTIVE]: constants.FILTER_TRUE,
    };
  }

  componentWillMount() {
    // show all assets - active and inactive if selected benefit is inactive
    // otherwise show only active by default
    if (this.props.selectedBenefit) {
      if (!this.props.selectedBenefit.get(constants.FILTER_STATUS_ACTIVE)) {
        this.setState({
          [constants.BENEFIT_ACTIVE]: constants.FILTER_ALL
        });
      }
    }
  }

  /**
   * update filter params to the state
   * @param name
   * @param value
   */
  onFilter = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  /**
   * create a unique list of available benefit types for filtering purposes
   * @returns {Array}
   */
  getBenefitsTypeList = () => {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    return [
      {
        key: constants.FILTER_TYPE_ALL,
        value: constants.FILTER_ALL,
        text: formatMessage({ id: 'activity.actions.benefits.all', defaultMessage: 'All' })
      },
      {
        key: constants.GIFT,
        value: constants.GIFT,
        text: formatMessage({ id: 'activity.actions.benefits.gift', defaultMessage: 'Gift' })
      },
      {
        key: constants.PUNCH_CARD,
        value: constants.PUNCH_CARD,
        text: formatMessage({ id: 'activity.actions.benefits.punchCard', defaultMessage: 'Punch Card' })
      }
    ];
  };

  /**
   * create a unique list of available benefit types for filtering purposes
   * @returns {Array}
   */
  getBenefitsStatusList = () => {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    return [
      {
        key: constants.FILTER_STATUS_ALL,
        value: constants.FILTER_ALL,
        text: formatMessage({ id: 'activity.actions.benefits.all', defaultMessage: 'All' })
      },
      {
        key: constants.FILTER_STATUS_ACTIVE,
        value: constants.FILTER_TRUE,
        text: formatMessage({ id: 'activity.actions.benefits.active', defaultMessage: 'Active' })
      },
      {
        key: constants.FILTER_STATUS_INACTIVE,
        value: constants.FILTER_FALSE,
        text: formatMessage({ id: 'activity.actions.benefits.inactive', defaultMessage: 'Inactive' })
      },
      {
        key: constants.BENEFIT_EXPIRED,
        value: constants.BENEFIT_EXPIRED,
        text: formatMessage({ id: 'activity.actions.benefits.expired', defaultMessage: 'Expired' })
      }
    ];
  };
  isExpired = (benefit) => moment(benefit[constants.BENEFIT_VALID_UNTIL]).isBefore(moment()) && benefit.active;
  filterByTitle = (searchText, benefit) => searchText.length === 0 || benefit.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  /**
   * filter benefits list according to filter params / search query
   * @param {List} benefits
   * @param {Map} filters
   */
  filteredBenefitsList = (benefits, filters) => benefits.filter((benefit) => {
    switch (filters.type) {
      case constants.FILTER_ALL:
        switch (filters.active) {
          case constants.FILTER_ALL:
            return this.filterByTitle(filters.search, benefit);
          case constants.BENEFIT_EXPIRED:
            return this.isExpired(benefit) && this.filterByTitle(filters.search, benefit);
          default:
            return benefit.active === (filters.active === constants.FILTER_TRUE) && !this.isExpired(benefit) && this.filterByTitle(filters.search, benefit);
        }
      default:
        switch (filters.active) {
          case constants.FILTER_ALL:
            return benefit.type === filters.type && this.filterByTitle(filters.search, benefit);
          case constants.BENEFIT_EXPIRED:
            return this.isExpired(benefit) && benefit.type === filters.type && this.filterByTitle(filters.search, benefit);
          default:
            return benefit.active === (filters.active === constants.FILTER_TRUE) &&
              benefit.type === filters.type && this.filterByTitle(filters.search, benefit);
        }
    }
  });

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    const benefits = this.filteredBenefitsList(this.props.benefits.toJS(), this.state);
    const gifts = benefits.filter((benefit) => benefit.type === constants.GIFT);
    const punchCards = benefits.filter((benefit) => benefit.type === constants.PUNCH_CARD);

    const searchPlaceholder = this.props.benefitType
      ? `${formatMessage({ id: `activity.actions.search.${this.props.benefitType.toLowerCase()}s` }) }`
      : `${formatMessage({ id: 'activity.actions.search' })}`;

    return (
      <div>
        <div className="filters">
          <Input
            icon="search"
            placeholder={searchPlaceholder}
            fluid
            onChange={(e, data) => { this.onFilter(constants.FILTER_SEARCH, data.value); }}
            prefix={`benefitsList.${this.props.index}.searchBox`}
          />
          {
            // hide filter-by-type dropdown if component shows one type of benefit
            this.props.benefitType
            ? null
            : (
              <span>
                <span className="filter-header">{formatMessage({ id: 'activity.actions.type' })}</span>
                <DropdownWrapper
                  fluid={false}
                  index={this.props.index}
                  inline
                  name={'filterByType'}
                  onSelectOption={(e, data) => this.onFilter(constants.BENEFIT_TYPE, data.value)}
                  options={this.getBenefitsTypeList()}
                  prefix={`benefitsList.${this.props.index}`}
                  selection={false}
                  value={this.state.type}
                />
              </span>
              )
          }
          <span>
            <span className="filter-header">{formatMessage({ id: 'activity.actions.status' })}</span>
            <DropdownWrapper
              fluid={false}
              index={this.props.index}
              inline
              name={'filterByStatus'}
              onSelectOption={(e, data) => this.onFilter(constants.BENEFIT_ACTIVE, data.value)}
              options={this.getBenefitsStatusList()}
              prefix={`benefitsList.${this.props.index}`}
              selection={false}
              value={this.state.active}
            />
          </span>
        </div>
        <div className="benefits">
          {
            // display gifts if component shows all benefit types or gifts only
            !this.props.benefitType || this.props.benefitType === constants.GIFT
              ? <Benefits
                allowCreateGift={this.props.allowCreateGift}
                automationIdPrefix={`${this.props.automationIdPrefix}.${this.props.benefitType}`}
                index={this.props.index}
                list={gifts}
                onChange={this.props.onSelectOption}
                onCreateGift={this.props.onCreateGift}
                type={constants.GIFTS}
                value={this.props.value}
              />
              : null
          }
          {
            // display punch cards if component shows all benefit types or punch cards only
            !this.props.benefitType || this.props.benefitType === constants.PUNCH_CARD
              ? <Benefits
                automationIdPrefix={`${this.props.automationIdPrefix}.${this.props.benefitType}`}
                index={this.props.index}
                list={punchCards}
                onChange={this.props.onSelectOption}
                type={constants.PUNCH_CARDS}
                value={this.props.value}
              />
              : null
          }

        </div>
      </div>
    );
  }
}

export default injectIntl(BenefitsListContent);
