/**
 *
 * DynamicTagMembership
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import './tagMembership.styles.less';
import DropdownWrapper from '../../../../../../common/components/DropdownWrapper/DropdownWrapper';
import * as appSelectors from '../../../../../App/selectors';
import * as activityConstants from '../../../../activity.constants';
import * as constants from '../../../../activitySchema/activitySchema.constants';

class DynamicTagMembership extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string,
    onActionFieldUpdate: PropTypes.func.isRequired,
    action: PropTypes.object,
    index: PropTypes.number,
    validationErrors: PropTypes.object,
    autoCompleteOptions: PropTypes.object
  };

  componentWillMount() {
    if (!this.props.action.get(activityConstants.TAGS_TYPE)) {
      this.props.onActionFieldUpdate(activityConstants.TAGS_TYPE, activityConstants.ACCUMULATE_POINTS_TYPE_FIXED, this.props.index);
    }
  }
  get autoCompleteOptions() {
    let options = [];
    if (this.props.autoCompleteOptions && this.props.autoCompleteOptions.size > 0) {
      options = this.props.autoCompleteOptions.toJS().map((option) => ({ text: option, value: option }));
    }
    if (this.props.action && this.props.action.get(constants.TAG_MEMBERSHIP_TAG_FIELD)) {
      const value = this.props.action.get(constants.TAG_MEMBERSHIP_TAG_FIELD);
      options.push({ text: value, value });
    }
    return options;
  }

  render() {
    const { index, action, onActionFieldUpdate, validationErrors, automationIdPrefix } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const tagsTypeOptions = [
      {
        value: activityConstants.TAGS_TYPE_FIXED,
        text: formatMessage({ id: 'activity.action.tag.type.fixed' })
      },
      {
        value: activityConstants.TAGS_TYPE_DYNAMIC,
        text: formatMessage({ id: 'activity.action.tag.type.dynamic' })
      }
    ];
    const isDynamicTagsType = this.props.action.get(activityConstants.TAGS_TYPE) === activityConstants.TAGS_TYPE_DYNAMIC;
    const dynamicTagsValues = [
      {
        value: constants.CONTEXT_DATA_DATA_STRING_VALUE_1,
        text: formatMessage({ id: 'condition.context.Data.Data.StringValue1' })
      },
      {
        value: constants.CONTEXT_DATA_DATA_STRING_VALUE_2,
        text: formatMessage({ id: 'condition.context.Data.Data.StringValue2' })
      },
      {
        value: constants.CONTEXT_DATA_DATA_STRING_VALUE_3,
        text: formatMessage({ id: 'condition.context.Data.Data.StringValue3' })
      },
      {
        value: constants.CONTEXT_DATA_DATA_STRING_VALUE_4,
        text: formatMessage({ id: 'condition.context.Data.Data.StringValue4' })
      },
      {
        value: constants.CONTEXT_DATA_DATA_STRING_VALUE_5,
        text: formatMessage({ id: 'condition.context.Data.Data.StringValue5' })
      },
      {
        value: constants.CONTEXT_DATA_DATA_STRING_VALUE_6,
        text: formatMessage({ id: 'condition.context.Data.Data.StringValue6' })
      },
      {
        value: constants.CONTEXT_DATA_DATA_STRING_VALUE_7,
        text: formatMessage({ id: 'condition.context.Data.Data.StringValue7' })
      },
      {
        value: constants.CONTEXT_DATA_DATA_STRING_VALUE_8,
        text: formatMessage({ id: 'condition.context.Data.Data.StringValue8' })
      },
      {
        value: constants.CONTEXT_DATA_DATA_STRING_VALUE_9,
        text: formatMessage({ id: 'condition.context.Data.Data.StringValue9' })
      },
      {
        value: constants.CONTEXT_DATA_DATA_STRING_VALUE_10,
        text: formatMessage({ id: 'condition.context.Data.Data.StringValue10' })
      }
    ];

    const tagTypeValue = this.props.action.get(activityConstants.TAGS_TYPE) || activityConstants.TAGS_TYPE_FIXED;

    return (
      <Grid className="action tag-membership-action">
        <Grid.Row>
          <Grid.Column width={16}>
            <DropdownWrapper
              options={tagsTypeOptions}
              onSelectOption={(e, data) => onActionFieldUpdate(data.name, data.value, index)}
              name={activityConstants.TAGS_TYPE}
              value={tagTypeValue}
              prefix={`${automationIdPrefix}.accumulate.tags.types`}
              className="deduct-tags-type"
              error={validationErrors ? validationErrors.get(activityConstants.TAGS_TYPE) : null}
            />
            <div className={"dynamic-tag-wrapper"}>
              {!isDynamicTagsType ?
                <DropdownWrapper
                  className="auto-complete-wrapper"
                  options={this.autoCompleteOptions}
                  onSelectOption={(e, data) => onActionFieldUpdate(data.name, data.value.trim(), index)}
                  value={action.get(constants.TAG_MEMBERSHIP_TAG_FIELD)}
                  disabled={false}
                  index={this.props.index}
                  prefix={`${automationIdPrefix}.value`}
                  error={validationErrors ? validationErrors.get(constants.TAG_MEMBERSHIP_TAG_FIELD) : null}
                  search
                  allowAdditions
                  name={constants.TAG_MEMBERSHIP_TAG_FIELD}
                  placeholder={formatMessage({ id: 'dropdown.placeholder.select.or.add' })}
                  selection
                  additionLabel={`(${formatMessage({ id: 'adding.new.tag' })})`}
                /> : <DropdownWrapper
                  options={dynamicTagsValues}
                  onSelectOption={(e, data) => onActionFieldUpdate(data.name, data.value, index)}
                  name={activityConstants.DYNAMIC_TAGS_VALUE}
                  value={action.get(activityConstants.DYNAMIC_TAGS_VALUE)}
                  prefix={`${automationIdPrefix}.accumulate.tags.dynamic.value`}
                  className="deduct-tags-dynamic-value"
                  error={validationErrors ? validationErrors.get(activityConstants.DYNAMIC_TAGS_VALUE) : null}
                />}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    autoCompleteOptions: appSelectors.getBusinessTagsWithoutRfm(state),
  };
}

const withConnect = connect(mapStateToProps, null);

export default compose(withConnect, injectIntl)(DynamicTagMembership);
