import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import InputWrapper from '../../../../../../common/components/FormInputFields/InputWrapper/InputWrapper';
import * as schemaConstants from '../../../../activitySchema/activitySchema.constants';
import MentionTextArea from '../../../../../../common/components/MentionableTextArea/MentionTextArea';

class CustomHeader extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      key: '',
      value: ''
    };
  }

  static propTypes = {
    automationIdPrefix: PropTypes.string,
    keyLabel: PropTypes.string,
    valueLabel: PropTypes.string,
    onActionFieldUpdate: PropTypes.func.isRequired,
    customHeader: PropTypes.object,
    index: PropTypes.number,
    validationErrors: PropTypes.object,
    hideKey: PropTypes.bool,
    mentionsList: PropTypes.object,
    removeItem: PropTypes.func.isRequired,
    showDeleteButton: PropTypes.bool,
    parentIndex: PropTypes.number
  };

  render() {
    const { index, onActionFieldUpdate, validationErrors, automationIdPrefix, customHeader, hideKey, keyLabel, valueLabel, mentionsList, removeItem, showDeleteButton, parentIndex } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    return (
      <Grid.Row columns={2}>
        {!hideKey && <Grid.Column width={8}>
          <p className="action-label">{keyLabel || formatMessage({ id: 'activity.action.header.key' })}</p>
          <InputWrapper
            noSpaces
            fluid
            onChange={(e, data) => onActionFieldUpdate(index, schemaConstants.EXPORT_EVENT_HEADER_KEY_FIELD, data.value)}
            name={`${schemaConstants.EXPORT_EVENT_CUSTOM_HEADERS}.${schemaConstants.EXPORT_EVENT_HEADER_KEY_FIELD}`}
            value={customHeader ? customHeader.get(schemaConstants.EXPORT_EVENT_HEADER_KEY_FIELD) : ''}
            prefix={`${automationIdPrefix}.${schemaConstants.EXPORT_EVENT_CUSTOM_HEADERS}.${schemaConstants.EXPORT_EVENT_HEADER_KEY_FIELD}`}
            error={validationErrors ? validationErrors.get(`${schemaConstants.EXPORT_EVENT_CUSTOM_HEADERS}.${index}.${schemaConstants.EXPORT_EVENT_HEADER_KEY_FIELD}`) : null}
          />
        </Grid.Column>}
        <Grid.Column width={8}>
          {showDeleteButton && <Icon
            style={{ cursor: 'pointer', float: 'right' }}
            className="icon como-ic-delete"
            onClick={() => { removeItem(index, parentIndex); }}
          />}
          <p className="action-label">{valueLabel || formatMessage({ id: 'activity.action.header.value' })}</p>
          {mentionsList ? <MentionTextArea
            singleLine
            automationIdPrefix={`${automationIdPrefix}.${schemaConstants.EXPORT_EVENT_CUSTOM_HEADERS}.${schemaConstants.EXPORT_EVENT_HEADER_VALUE_FIELD}`}
            mentionOptions={mentionsList}
            message={customHeader ? customHeader.get(schemaConstants.EXPORT_EVENT_HEADER_VALUE_FIELD) : ''}
            onTextUpdate={(val) => onActionFieldUpdate(index, schemaConstants.EXPORT_EVENT_HEADER_VALUE_FIELD, val)}
            index={index}
            error={validationErrors ? validationErrors.get(`${schemaConstants.EXPORT_EVENT_CUSTOM_HEADERS}.${index}.${schemaConstants.EXPORT_EVENT_HEADER_VALUE_FIELD}`) : null}
            wrapperStyle={{ padding: '0 13px 0 0' }}
            inputStyle={{ border: 'unset', borderBottom: '1px solid rgba(163, 166, 183, 0.65)' }}
          /> : null }
        </Grid.Column>
      </Grid.Row>
    );
  }
}

export default injectIntl(CustomHeader);
