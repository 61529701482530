import * as constants from './promoCodes.constants';
import * as activityConstants from '../Activity/activity.constants';

export const onFilterUpdate = (groupKey, key, status) => ({ type: constants.UPDATE_FILTERS, groupKey, key, status });
export const onSortUpdate = () => ({ type: constants.UPDATE_ORDER });
export const getPromoCodes = () => ({ type: constants.GET_PROMO_CODES });
export const goToPromoCode = (promoCodeId, promoCodeType) => ({ type: constants.GO_TO_PROMO_CODE, [activityConstants.HUB_ID]: promoCodeId, promoCodeType });
export const search = (term) => ({ type: constants.SEARCH_PROMO_CODES, [constants.SEARCH_TERM]: term });
export const createPromoCode = (promoCodeType) => ({ type: constants.CREATE_PROMO_CODE, promoCodeType });
export const updatePromoCode = (promoCode) => ({ type: constants.UPDATE_PROMO_CODE, [constants.PROMO_CODE]: promoCode });
export const extendPromoCodeStatus = (promoCode) => ({ type: constants.EXTEND_PROMO_CODE, [constants.PROMO_CODE]: promoCode.get(activityConstants.HUB_ID) });
export const changePromoCodeStatus = (promoCode) => ({ type: constants.CHANGE_PROMO_CODE_STATUS, [constants.PROMO_CODE]: promoCode });
export const goToPromoCodesList = () => ({ type: constants.GO_TO_PROMO_CODES_LIST });
export const getPromoCodeStats = (promoCodeId, type) => ({ type: constants.GET_PROMO_CODE_STATS, [activityConstants.HUB_ID]: promoCodeId, [activityConstants.PROMO_CODE_BULKS_TYPE]: type });
export const cleanPromoCodeStats = () => ({ type: constants.CLEAN_PROMO_CODE_STATS });
export const deletePromoCode = (promoCodeId) => ({ type: constants.DELETE_PROMO_CODE, [activityConstants.HUB_ID]: promoCodeId });
export const getPromoCodeUsages = (promoCodeId) => ({ type: constants.GET_PROMO_CODE_RELATED_ACTIVITIES, [activityConstants.HUB_ID]: promoCodeId });
export const cleanPromoCodeRelatedActivitiesData = () => ({ type: constants.CLEAN_PROMO_CODE_RELATED_ACTIVITIES_DATA });
export const getBulkList = (bulkKey, serverId) => ({ type: constants.GET_BULK_CODES_LIST, [constants.BULK_KEY]: bulkKey, [activityConstants.SERVER_ID]: serverId });
export const handleBulkListModalVisibility = (show) => ({ type: constants.HANDLE_BULK_LIST_MODAL_VISIBILITY, show });
export const getBulkCodesExport = (bulkKey, serverId) => ({ type: constants.GET_BULKS_EXPORT, [constants.BULK_KEY]: bulkKey, [activityConstants.SERVER_ID]: serverId });
