import React, { Component } from 'react';
import { Select, Form, Input, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { injectIntl } from 'react-intl';
import BackLink from '../../../common/components/NavigationLink/BackLink/index';
import * as routeConstants from '../../../constants/route.contants';
import * as constants from './constants/constants';
import './admin.styles.less';
import injectSaga from '../../../utils/injectSaga';
import saga from './admin.saga';
import * as templatesAdmin from '../Admin/admin.actions';
import * as selectors from './admin.selectors';

const categoryTypes = [
  { text: 'Rule', value: constants.TEMPLATE_RULE_CATEGORY },
  { text: 'Deal', value: constants.TEMPLATE_DEAL_CATEGORY },
  { text: 'One time action', value: constants.TEMPLATE_ONE_TIME_ACTION_CATEGORY }
];

class TemplatesAdmin extends Component {
  handleOnSubmit = (e) => {
    e.preventDefault();
    this.props.actions.createTemplateCategory();
  }
  handleOnChangeName = (event) => {
    const { value } = event.target;
    this.props.actions.updateTemplateCategory(constants.CATEGORY_NAME, value);
  }
  handleOnSelectType = (event, { value }) => {
    this.props.actions.updateTemplateCategory(constants.CATEGORY_TYPE, value);
  }
  render() {
    const { categoryName, categoryType } = this.props;

    return (
      <div className="templates-admin__container">
        <BackLink pathname={routeConstants.BUSINESS_CENTER_ROUTE} />
        <Form onSubmit={this.handleOnSubmit}>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Category Type</label>
              <Select placeholder="Select category type" options={categoryTypes} value={categoryType} onChange={this.handleOnSelectType} />
            </Form.Field>
            <Form.Field>
              <label>Name</label>
              <Input required fluid onChange={this.handleOnChangeName} value={categoryName} />
            </Form.Field>
          </Form.Group>
          <Button type="submit">Create</Button>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  categoryName: selectors.getCategoryName(state),
  categoryType: selectors.getCategoryType(state)
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(templatesAdmin, dispatch)
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: constants.SAGA_KEY_TEMPLATES_ADMIN, saga });

export default compose(
  withConnect,
  withSaga,
  injectIntl,
)(TemplatesAdmin);

