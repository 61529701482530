import { fromJS } from 'immutable/dist/immutable';
import * as constants from './email.constants';
import * as appConstants from '../App/app.constants';
import initialEmailTemplate from './email.initialTemplate.json';

export const initialEmail = fromJS({
  [constants.EMAIL_NAME]: '',
  [constants.SUBJECT]: '',
  [constants.EMAIL_OPEN_TRACKING]: 'true',
  [constants.EMAIL_CLICK_TRACKING]: 'true',
  [constants.EMAIL_2FA_TEMPLATE]: 'false',
  [constants.EMAIL_CONSENT_TEMPLATE]: 'false',
  [constants.EMAIL_CONTENT_JSON]: JSON.stringify(initialEmailTemplate),
  [constants.EMAIL_CONTENT_HTML]: '',
  [appConstants.LOCATION_ID]: '',
});
