import React from 'react';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import 'moment-timezone';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as datesConstants from '../../../../../common/utils/dateTime.constants';
import * as activityConstants from '../../../activity.constants';
import './dateTimeRangeAndDaysHoursCondition.style.less';
import { updateLocale } from '../../../../../common/utils/dateTimeUtils';
import ValidationErrorMessage from '../../../../../common/components/ValidationErrorMessage/ValidationErrorMessage';
import DateTimePicker from '../../../../../common/components/CustomDatePicker/DateTimePicker';
import * as appSelectors from '../../../../App/selectors';


class DateTimeRange extends React.PureComponent {

  static propTypes = {
    condition: PropTypes.object,
    errors: PropTypes.object,
    intl: PropTypes.object,
    isPastDateRange: PropTypes.bool,
    isInfiniteDateRange: PropTypes.bool,
    locale: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    localeTimeFormat: PropTypes.string.isRequired,
    updateDateRangeDate: PropTypes.func,
    allowForever: PropTypes.bool,
    allowNow: PropTypes.bool,
    businessTimezone: PropTypes.string,
    datePickerInsideModal: PropTypes.bool,
    isInsideUserAction: PropTypes.bool
  };

  static defaultProps = {
    isPastDateRange: false,
    isInfiniteDateRange: false,
    isInsideUserAction: false
  };

  constructor(props) {
    super(props);
    this.updateDateRangeDataToNow = this.updateDateRangeDataToNow.bind(this);
    this.updateDateRangeDataToForever = this.updateDateRangeDataToForever.bind(this);
    this.updateDateRangeDataToSinceEver = this.updateDateRangeDataToSinceEver.bind(this);
  }

  componentWillMount() {
    updateLocale(this.props.intl, this.props.locale);
  }

  getSelectedEndObject = () => {
    let selectedEnd;
    if (this.props.condition && this.props.condition.size > 0) {
      const operator = this.props.condition.get(activityConstants.OPERATOR_KEY);
      if (operator === datesConstants.DATE_TIME_IS_INFINITE || operator === datesConstants.DATE_TIME_IS_AFTER) { return undefined; }
      switch (operator) {
        case datesConstants.DATE_TIME_IS_BETWEEN:
          selectedEnd = moment.utc(this.props.condition.getIn([activityConstants.CONDITION_VALUE, datesConstants.DATE_TO])).tz(this.props.businessTimezone);
          break;
        case datesConstants.DATE_TIME_IS_BEFORE_OR_EQUAL:
          selectedEnd = moment.utc(this.props.condition.get(activityConstants.CONDITION_VALUE)).tz(this.props.businessTimezone);
          break;
        default:
          break;
      }
    }
    return selectedEnd;
  };

  getSelectedStartObject = () => {
    let selectStart = moment().tz(this.props.businessTimezone);
    if (this.props.condition && this.props.condition.size > 0) {
      const operator = this.props.condition.get(activityConstants.OPERATOR_KEY);
      if (operator === datesConstants.DATE_TIME_IS_BEFORE_OR_EQUAL || operator === datesConstants.DATE_TIME_IS_INFINITE) { return undefined; }


      selectStart = this.props.condition.getIn([activityConstants.CONDITION_VALUE, datesConstants.DATE_FROM])
        ? moment.utc(this.props.condition.getIn([activityConstants.CONDITION_VALUE, datesConstants.DATE_FROM])).tz(this.props.businessTimezone)
        : moment.utc(this.props.condition.get(activityConstants.CONDITION_VALUE)).tz(this.props.businessTimezone);
    }
    return selectStart;
  };

  updateDateRangeDataToNow(isStart) {
    this.props.updateDateRangeDate(moment().tz(this.props.businessTimezone).utc(), isStart, this.props.localeTimeFormat, this.props.businessTimezone);
  }

  updateDateRangeDataToForever() {
    // todo: if this props condition start date sinceEver -> do the infinite, otherwise do the dateIsAfter
    this.props.updateDateRangeDate(null, false, this.props.localeTimeFormat, this.props.businessTimezone, this.props.isInfiniteDateRange);
  }

  updateDateRangeDataToSinceEver() {
    // todo: if this props condition end forever -> do the infinite, otherwise do the dateIsBefore
    this.props.updateDateRangeDate(null, true, this.props.localeTimeFormat, this.props.businessTimezone);
  }

  getForeverMessage = (selectedEnd) => {
    if (selectedEnd || !this.props.condition || this.props.condition.size === 0) {
      return null;
    }
    if (this.props.condition.get(activityConstants.OPERATOR_KEY) === datesConstants.DATE_TIME_IS_INFINITE || (this.props.condition.get(activityConstants.OPERATOR_KEY) === datesConstants.DATE_TIME_IS_AFTER && this.props.isInfiniteDateRange)) {
      return 'datePicker.selectedStart.now';
    }
    return 'datePicker.selectedEnd.forever';
  };

  render() {
    const { formatMessage } = this.props.intl;
    const selectedStart = this.getSelectedStartObject();
    const selectedEnd = this.getSelectedEndObject();
    const error = this.props.errors ? this.props.errors.get(activityConstants.CONDITION_VALUE) : null;
    const className = classNames('date-picker-range-wrapper', { 'errors-inside-global-conditions-highlight': error });
    const topNowButtonData = (isStart = true) =>
      [{
        'id': 'now',
        'messageKey': 'datePicker.selectedStart.now',
        'callback': () => this.updateDateRangeDataToNow(isStart)
      }];

    const topForeverButtonData = () => {
      const messageKey = this.props.isInfiniteDateRange ? 'datePicker.selectedStart.now' : 'datePicker.selectedEnd.activeForever';
      return this.props.allowForever
      ? [{
        'id': 'forever',
        'messageKey': messageKey,
        'callback': this.updateDateRangeDataToForever
      }]
      : null;
    };

    const topSinceEverButtonData = [{
      'id': 'sinceEver',
      'messageKey': 'datePicker.selectedStart.sinceEver',
      'callback': this.updateDateRangeDataToSinceEver
    }];

    const foreverMessage = this.getForeverMessage(selectedEnd);
    const sinceEverMessage = selectedStart ? null : 'datePicker.selectedStart.sinceEver';
    return (
      <div className={className}>
        <div className="date-picker-start">
          <DateTimePicker
            dataAutomationId="globalConditions.dateTime.datesRange.start"
            selectedDate={selectedStart}
            updateDate={(dateData) => this.props.updateDateRangeDate(dateData, true, this.props.localeTimeFormat, this.props.businessTimezone, this.props.isInfiniteDateRange)}
            topButtonsData={this.props.isPastDateRange || this.props.isInfiniteDateRange ? topSinceEverButtonData : topNowButtonData(true)}
            minDate={this.props.isPastDateRange || this.props.isInfiniteDateRange ? undefined : moment().tz(this.props.businessTimezone)}
              /* maxDate must be undefined in order for the DatePicker to recognize as no-prop: */
            maxDate={selectedEnd || undefined}
            triggerMessageKey={sinceEverMessage}
            datePickerInsideModal
          />

        </div>
        <div className="date-pickers-divider">-</div>
        <div className="date-picker-end">
          <DateTimePicker
            dataAutomationId="globalConditions.dateTime.datesRangeCondition.end"
            selectsEnd
            selectedDate={selectedEnd}
            updateDate={(dateData) => this.props.updateDateRangeDate(dateData, false, this.props.localeTimeFormat, this.props.businessTimezone)}
            topButtonsData={this.props.isPastDateRange || (!this.props.isInfiniteDateRange && !this.props.allowForever) ? (this.props.allowNow ? topNowButtonData(false) : null) : topForeverButtonData()}
            minDate={selectedStart}
            /* maxDate must be undefined in order for the DatePicker to recognize as no-prop: */
            maxDate={this.props.isPastDateRange || (!this.props.isInfiniteDateRange && this.props.isInsideUserAction) ? moment().tz(this.props.businessTimezone) : undefined}
            triggerMessageKey={foreverMessage}
            datePickerInsideModal
          />

        </div>
        {
          error ?
            <ValidationErrorMessage
              dataAutomationId={'globalConditions.dateTime.error.message'}
              errorMessage={formatMessage({ id: error })}
            />
            : null
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  businessTimezone: appSelectors.getBusinessTimeZone(state),
  locale: appSelectors.getBrowserLocale(state),
  localeTimeFormat: appSelectors.getBrowserLocaleTimeFormat(state),
});

const withConnect = connect(mapStateToProps, null);

export default compose(
  withConnect,
  injectIntl
)(injectIntl(DateTimeRange));

