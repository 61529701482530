import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import PointShopRow from './PointShopRow';
import * as activityConstants from '../../Activity/activity.constants';
import * as imagesSelectors from '../../Images/images.selectors';

class PointShopTable extends React.PureComponent {
  static propTypes = {
    items: PropTypes.object.isRequired,
    changeActivityStatus: PropTypes.func,
    openPointShopInNewTab: PropTypes.func.isRequired,
    onDeleteGift: PropTypes.func,
    goToGift: PropTypes.func,
    highlightedGiftId: PropTypes.string,
    thumbImages: PropTypes.object
  };

  goToItem = (item) => {
   this.props.history.push({ search: this.props.location.search, pathname: `/pointShop/${item.hubId}`, state: { item } });
  };

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    return (
      <Table className="ui single line fixed selectable very basic table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="list-column-name point-shop-picture-column" />
            <Table.HeaderCell
              className="list-column-name point-shop-name-column"
            >{formatMessage({ id: 'point-shop-page.item.name' })}</Table.HeaderCell>
            <Table.HeaderCell
              className="list-column-name point-shop-price-column"
            >{formatMessage({ id: 'point-shop-page.item.price' })}</Table.HeaderCell>
            <Table.HeaderCell
              className="list-column-name point-shop-status-column"
            >{formatMessage({ id: 'point-shop-page.item.status' })}</Table.HeaderCell>
            <Table.HeaderCell className="point-shop-context-menu-column" />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.items.map((item, index) => {
            return (
              <PointShopRow
                key={index}
                delete={this.props.delete}
                deactivate={this.props.deactivate}
                goToItem={this.goToItem} item={item}
                rowIndex={index}
                openPointShopInNewTab={this.props.openPointShopInNewTab}
              />
            );
          }, this)}
        </Table.Body>
      </Table>
    );
  }
}

export default injectIntl(PointShopTable);
