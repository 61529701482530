/*
 *
 * Cases actions
 *
 */

import * as constants from '../../activity.constants';

// CASE METADATA

/**
 * split to cases
 * @returns {{type}}
 */
export function splitToCases() {
  return {
    type: constants.SPLIT_TO_CASES
  };
}

/**
 * cancel split to cases
 * @returns {{type}}
 */
export function cancelSplit() {
  return {
    type: constants.CANCEL_SPLIT
  };
}

/**
 * toggle collapse/expand display mode for single/all cases
 * @param {boolean} collapse
 * @param {int} caseIndex
 * @returns {{type, collapse: *, caseIndex: *}}
 */
export function setCasesExpandCollapseMode(collapse, caseIndex) {
  return {
    type: constants.SET_CASES_EXPAND_COLLAPSE_MODE,
    collapse,
    caseIndex
  };
}

/**
 * update case description
 * @param {int} caseIndex
 * @param {string} description
 * @returns {{type, caseIndex: *, description: *}}
 */
export function updateCaseDescription(caseIndex, description) {
  return {
    type: constants.UPDATE_CASE_DESCRIPTION,
    caseIndex,
    description
  };
}

/**
 * add a case at the end of cases array
 * @returns {{type}}
 */
export function addCase() {
  return {
    type: constants.ADD_CASE,
  };
}

/**
 * delete case by caseIndex
 * @param {int} caseIndex
 * @returns {{type, caseIndex: *}}
 */
export function deleteCase(caseIndex) {
  return {
    type: constants.DELETE_CASE,
    caseIndex,
  };
}

/**
 * clear case data
 * @param {int} caseIndex
 * @returns {{type, caseIndex: *}}
 */
export function clearCaseData(caseIndex) {
  return {
    type: constants.CLEAR_CASE_DATA,
    caseIndex,
  };
}

/**
 * move case priority up or down
 * @param {int} caseIndex
 * @param {int} targetIndex
 * @returns {{type, caseIndex: *, up: *}}
 */
export function updateCaseOrder(caseIndex, targetIndex) {
  return {
    type: constants.UPDATE_CASE_ORDER,
    caseIndex,
    targetIndex,
  };
}


// CONDITIONS

/**
 * add condition to case
 * @param {int} caseIndex
 * @returns {{type, conditionParent, caseIndex: *}}
 */
export function addCondition(caseIndex) {
  return {
    type: constants.ADD_CONDITION,
    conditionParent: constants.CASES,
    caseIndex,
  };
}

/**
 * update condition in case
 * @param {string} fieldName
 * @param {string} fieldValue
 * @param {int} conditionIndex
 * @param {int} caseIndex
 * @param {boolean} abTestMode
 * @returns {{type, conditionParent, fieldName: *, fieldValue: *, conditionIndex: *, caseIndex: *}}
 */
export function updateConditionField(fieldName, fieldValue, conditionIndex, caseIndex, abTestMode) {
  return {
    type: constants.UPDATE_CONDITION_FIELD,
    conditionParent: constants.CASES,
    fieldName,
    fieldValue,
    conditionIndex,
    caseIndex,
    abTestMode
  };
}

/**
 * delete condition from case
 * @param {int} index
 * @param {int} caseIndex
 * @returns {{type, conditionParent, index: *, caseIndex: *}}
 */
export function deleteCondition(index, caseIndex) {
  return {
    type: constants.DELETE_CONDITION,
    conditionParent: constants.CASES,
    index,
    caseIndex,
  };
}

/**
 * update the date in the dateTime component
 * @param dateObject
 * @param isStart
 * @param localeTimeFormat
 * @param conditionIndex
 * @param caseIndex
 * @param businessTimezone
 * @param allowInfinite
 * @returns {{type, dateObject: *, isStart: *, localeTimeFormat: *, conditionParent, conditionIndex: *, caseIndex: *}}
 */
export function updateDateRangeDate(dateObject, isStart, localeTimeFormat, conditionIndex, caseIndex, businessTimezone, allowInfinite = false) {
  return {
    type: constants.UPDATE_DATE_RANGE_DATE,
    dateObject,
    isStart,
    localeTimeFormat,
    conditionParent: constants.CASES,
    conditionIndex,
    caseIndex,
    businessTimezone,
    allowInfinite
  };
}

/**
 * add daysTimes line
 * @param conditionIndex
 * @param caseIndex
 * @returns {{type, conditionParent, conditionIndex: *, caseIndex: *}}
 */
export function addDaysTimesCondition(conditionIndex, caseIndex) {
  return {
    type: constants.ADD_DAYS_TIMES_CONDITION,
    conditionParent: constants.CASES,
    conditionIndex,
    caseIndex,
  };
}

/**
 * delete daysTimes line
 * @param index
 * @param conditionIndex
 * @param caseIndex
 * @returns {{type, index: *, conditionParent, conditionIndex: *, caseIndex: *}}
 */
export function deleteDaysTimesCondition(index, conditionIndex, caseIndex) {
  return {
    type: constants.DELETE_DAYS_TIMES_CONDITION,
    index,
    conditionParent: constants.CASES,
    conditionIndex,
    caseIndex,
  };
}

/**
 * update daysTimes condition
 * @param data
 * @param index
 * @param isMilitaryTime
 * @param conditionIndex
 * @param caseIndex
 * @param businessTimezone
 * @returns {{type, daysTimes: *, index: *, isMilitaryTime: *, conditionParent, conditionIndex: *, caseIndex: *}}
 */
export function updateDaysTimes(data, index, isMilitaryTime, conditionIndex, caseIndex, businessTimezone) {
  return {
    type: constants.UPDATE_DAYS_TIMES_CONDITION,
    daysTimes: data,
    index,
    isMilitaryTime,
    conditionParent: constants.CASES,
    conditionIndex,
    caseIndex,
    businessTimezone
  };
}


// ACTIONS

/**
 * add bundle line
 * @param {int} actionsWrapperIndex
 * @param {int} caseIndex
 * @returns {{type, index: *, caseIndex: *}}
 */
export function addBundleLine(actionsWrapperIndex, caseIndex) {
  return {
    type: constants.ADD_BUNDLE_LINE,
    actionParent: constants.CASES,
    actionsWrapperIndex,
    caseIndex
  };
}

/**
 * on bundle line field update
 * @param fieldName
 * @param fieldValue
 * @param bundleConditionIndex
 * @param actionsWrapperIndex
 * @param caseIndex
 * @returns {{type, fieldName: *, fieldValue: *, bundleConditionIndex: *, caseIndex: *}}
 */
export function onBundleLineFieldUpdate(fieldName, fieldValue, bundleConditionIndex, actionsWrapperIndex, caseIndex) {
  return {
    type: constants.UPDATE_BUNDLE_LINE_FIELD,
    actionParent: constants.CASES,
    fieldName,
    fieldValue,
    bundleConditionIndex,
    actionsWrapperIndex,
    caseIndex
  };
}

/**
 * delete action
 * @param {int} index
 * @param {int} actionsWrapperIndex
 * @param {int} caseIndex
 * @returns {{type, index: *, caseIndex: *}}
 */
export function deleteAction(index, actionsWrapperIndex, caseIndex) {
  return {
    type: constants.DELETE_ACTION,
    actionParent: constants.CASES,
    index,
    actionsWrapperIndex,
    caseIndex
  };
}

/**
 * delete bundle line
 * @param {int} index
 * @param {int} actionsWrapperIndex
 * @param {int} caseIndex
 * @returns {{type, index: *, caseIndex: *}}
 */
export function deleteBundleLine(index, actionsWrapperIndex, caseIndex) {
  return {
    type: constants.DELETE_BUNDLE_LINE,
    actionParent: constants.CASES,
    index,
    actionsWrapperIndex,
    caseIndex
  };
}

/**
 * reset Rule ActionsWrapper
 * @param mode
 * @param caseIndex
 * @returns {{type, mode: *, caseIndex: *}}
 */
export function resetActivityActionsWrapper(mode, caseIndex) {
  return {
    type: constants.CHANGE_ACTION_MODE,
    actionParent: constants.CASES,
    mode,
    caseIndex
  };
}

export function changeActivityActionMode(mode, actionsWrapperIndex, caseIndex) {
  return {
    type: constants.CHANGE_ACTION_MODE,
    actionParent: constants.CASES,
    mode,
    actionsWrapperIndex,
    caseIndex
  };
}

/**
 * update action field
 * @param {string} fieldName
 * @param {string} fieldValue
 * @param {int} index
 * @param {int} actionsWrapperIndex
 * @param {int} caseIndex
 * @returns {{type, fieldName: *, fieldValue: *, index: *, caseIndex: *}}
 */
export function updateActionField(fieldName, fieldValue, index, actionsWrapperIndex, caseIndex) {
  return {
    type: constants.UPDATE_ACTION_FIELD,
    actionParent: constants.CASES,
    fieldName,
    fieldValue,
    index,
    actionsWrapperIndex,
    caseIndex
  };
}

/**
 * update action type
 * @param {string} actionType
 * @param {int} index
 * @param {int} actionsWrapperIndex
 * @param {string} mode
 * @param {string} trigger
 * @param {int} caseIndex
 * @returns {{type, actionType: *, index: *, caseIndex: *}}
 */
export function updateActionType(actionType, index, actionsWrapperIndex, caseIndex, mode, trigger) {
  return {
    type: constants.UPDATE_ACTION_TYPE,
    actionParent: constants.CASES,
    actionType,
    index,
    actionsWrapperIndex,
    mode,
    trigger,
    caseIndex
  };
}

/**
 * Is responsible for mutating the addPoints action to addPoints rate
 * @param addPointsType
 * @param index
 * @param actionsWrapperIndex
 * @param caseIndex
 * @returns {{type, actionParent, addPointsType: *, index: *, caseIndex: *}}
 */
export function addPointsOnChangeType(addPointsType, index, actionsWrapperIndex, caseIndex) {
  return {
    type: constants.ADD_POINTS_TYPE_CHANGE,
    actionParent: constants.CASES,
    addPointsType,
    index,
    actionsWrapperIndex,
    caseIndex
  };
}

/**
 * Is responsible for save of temp applyOnData (discount advanced actions)
 * @param actionIndex
 * @param actionsWrapperIndex
 * @param caseIndex
 * @returns {{type, actionIndex: *}}
 */
export function saveApplyOnData(actionIndex, actionsWrapperIndex, caseIndex) {
  return {
    type: constants.SAVE_APPLY_ON_DATA,
    actionIndex,
    actionsWrapperIndex,
    caseIndex
  };
}

export function createTempApplyOnModel(actionIndex, actionsWrapperIndex, caseIndex) {
  return {
    type: constants.CREATE_TEMP_APPLY_ON_MODEL,
    actionIndex,
    actionsWrapperIndex,
    caseIndex
  };
}

export function createGiftFromActivity(actionIndex, actionsWrapperIndex, caseIndex) {
  return {
    type: constants.CREATE_GIFT_FROM_ACTIVITY,
    actionParent: constants.CASES,
    actionIndex,
    actionsWrapperIndex,
    caseIndex,
  };
}

export function createEmailFromActivity(actionIndex, actionsWrapperIndex, caseIndex) {
  return {
    type: constants.CREATE_EMAIL_FROM_ACTIVITY,
    actionParent: constants.CASES,
    actionIndex,
    actionsWrapperIndex,
    caseIndex,
  };
}

// AB test
/**
 * Start AB test split case
 * @returns {{type: *}}
 */
export function startABTest() {
  return {
    type: constants.START_AB_TESTING
  };
}

export function cancelABTest() {
  return {
    type: constants.CANCEL_AB_TESTING
  };
}

export function deleteActionsWrapper(actionsWrapperIndex, caseIndex) {
  return {
    type: constants.DELETE_ACTIONS_WRAPPER,
    actionsWrapperIndex,
    caseIndex,
    actionParent: constants.CASES
  };
}

export function submitActionToCustomer(user, customerSearchField, caseIndex, actionsWrapperIndex, actionIndex) {
  return {
    type: constants.SUBMIT_ACTION_TO_CUSTOMER,
    user,
    caseIndex,
    actionsWrapperIndex,
    actionIndex,
    customerSearchField,
    actionParent: constants.CASES,
  };
}
