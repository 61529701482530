import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import _ from 'lodash';

import * as constants from './members.constants';
import * as appConstants from './../App/app.constants';
import * as routerConstants from '../../constants/route.contants';
import * as queryStringUtils from '../../utils/queryStringUtils';
import * as api from './members.api';
import * as errorConstant from '../ErrorMessage/errorMessage.constants';


function* goToCustomer({ param }) {
  const locationSearch = queryStringUtils.parse(location.search);
  locationSearch.search = param;
  yield put({ type: appConstants.SET_CUSTOMER_MEMBERSHIP_KEY, data: param });
  yield put({ type: constants.CLEAR_SEARCH_QUERY });
  yield put(push({ pathname: routerConstants.CUSTOMER_ROUTE, search: queryStringUtils.stringify(locationSearch) }));
}

function* findMembers({ searchQuery }) {
  try {
    yield put({ type: appConstants.SET_CUSTOMER_DATA_LOADING, data: true });
    const data = yield call(api.findMembers, searchQuery);

    switch (data.total) {
      case 1:
        yield put({ type: constants.GO_TO_CUSTOMER, param: data.results[0].membershipKey });
        break;
      case 0:
      case -1:
        yield put({
          type: errorConstant.HANDLE_ERROR,
          [errorConstant.MESSAGES_KEYS]: ['getCustomer.IllegalInput.notFound', 'getCustomer.IllegalInput.notFound.subtext'],
          [errorConstant.ERROR_TYPE]: errorConstant.ERROR_TYPE_CUSTOMER
        });
        break;
      default:
        if (data.total > 50) {
          yield put({
            type: errorConstant.HANDLE_TO_MANY_MEMBERS_POPUP_VISIBILITY,
            show: true
          });
        } else {
          yield put({ type: constants.SHOW_MULTIPLE_MEMBERS_POPUP, data, searchQuery });
        }
        break;
    }

  } catch (error) {
    yield put({
      type: errorConstant.HANDLE_ERROR,
      [errorConstant.MESSAGES_KEYS]: ['getCustomer.IllegalInput.notFound', 'getCustomer.IllegalInput.notFound.subtext'],
      [errorConstant.ERROR_TYPE]: errorConstant.ERROR_TYPE_CUSTOMER
    });
  }
  yield put({ type: appConstants.SET_CUSTOMER_DATA_LOADING, data: false });
}

function* getMembersFilteredByCommunicationActions({ filterConditions, locationId }) {
  try {

    const data = yield call(api.getMembersFilteredByCommunicationActions, filterConditions, locationId);
    yield put({
      type: constants.GET_MEMBERS_FILTERED_BY_COMMUNICATION_ACTIONS_SUCCESS,
      touchedMembers: data
    });
  } catch (err) {
    console.log(_.get(err));
  } finally {
    yield put({
      type: constants.GET_MEMBERS_FILTERED_BY_COMMUNICATION_ACTIONS_DONE
    });
  }
}

// sagas listen to dispatched actions too (same as reducers)
function* membersSaga() {
  yield [
    takeLatest(constants.GO_TO_CUSTOMER, goToCustomer),
    takeLatest(constants.FIND_MEMBERS, findMembers),
    takeLatest(constants.GET_MEMBERS_FILTERED_BY_COMMUNICATION_ACTIONS, getMembersFilteredByCommunicationActions)
  ];
}

export default membersSaga;
