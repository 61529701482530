import React from 'react';
import { injectIntl } from 'react-intl';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import * as appSelectors from '../App/selectors';
import * as appActions from '../App/app.actions';
import './welcome.styles.less';
import { Clip } from '../Clip/Clip';
import * as appConstant from '../App/app.constants';


class WelcomePage extends React.PureComponent {

  static propTypes = {
    defaultPage: PropTypes.string,
    bundlePlan: PropTypes.string,
    user: PropTypes.object,
    actions: PropTypes.object
  };

  componentWillMount() {
    this.props.actions.setLoadingState(true);
    if (this.props.defaultPage) {
      this.props.actions.goToPage(this.props.defaultPage);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.defaultPage) {
      this.props.actions.goToPage(nextProps.defaultPage);
      this.props.actions.setLoadingState(false);
    }
  }

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const className = this.props.bundlePlan === appConstant.TRIAL && this.props.user.get(appConstant.LOGIN_COUNTER) > appConstant.MAX_LOGIN_COUNT_FOR_TUTORIAL ? 'welcome-page welcome-image' : 'welcome-page';
    return (
      <div className={className}>
        {this.props.bundlePlan === appConstant.TRIAL && <Grid className={'welcome-page-tutorial'}>
          <Grid.Row>
            <Grid.Column style={{ width: '80%', margin: '90px auto auto'}}>
              <Clip style={{margin: '50px, auto auto'}} url={'https://static-mk-aws.prod.bcomo.com/St/Basic-Overview.MP4'} width={'100%'}></Clip>
            </Grid.Column>
          </Grid.Row>
        </Grid>}
      </div>
    );
  }


}

function mapStateToProps(state) {
  return {
    defaultPage: appSelectors.getDefaultPage(state),
    bundlePlan: appSelectors.getBusinessBundlePlan(state),
    user: appSelectors.getUser(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(appActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect)(injectIntl(WelcomePage));
