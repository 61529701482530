/*
 *
 * MicroCampaigns reducer
 *
 */

import { fromJS, List } from 'immutable';
import * as constants from './microCampaigns.constants';
import * as activityConstants from '../Activity/activity.constants';

const initialState = fromJS({});

function microCampaignsReducer(state = initialState, action) {

  let newState = state;

  switch (action.type) {

    case constants.GET_MICRO_CAMPAIGNS: {
      newState = newState
        .set(constants.MICRO_CAMPAIGNS_FETCHED, false)
        .set(constants.MICRO_CAMPAIGN_UPDATE_STATUS_IN_PROGRESS, true)
        .set(constants.MICRO_CAMPAIGN_STATS_FETCHED, false);
      break;
    }


    case constants.GET_MICRO_CAMPAIGNS_SUCCESS: {
      newState = newState
        .set(constants.MICRO_CAMPAIGNS, fromJS(action.data))
        .set(constants.MICRO_CAMPAIGNS_FETCHED, true)
        .set(constants.MICRO_CAMPAIGN_STATS_FETCHED, false)
        .set(constants.MICRO_CAMPAIGN_UPDATE_STATUS_IN_PROGRESS, false);
      if (action.data.length > 0) {
        newState = newState.setIn([constants.MICRO_CAMPAIGNS, 0, constants.IS_OPEN], true);
      }
      break;
    }

    case constants.UPDATE_MICRO_CAMPAIGN_STATUS: {
      newState = newState.set(constants.MICRO_CAMPAIGN_UPDATE_STATUS_IN_PROGRESS, true);
      break;
    }

    case constants.UPDATE_MICRO_CAMPAIGN_STATUS_DONE: {
      newState = newState.set(constants.MICRO_CAMPAIGN_UPDATE_STATUS_IN_PROGRESS, false);
      const campaigns = newState.get(constants.MICRO_CAMPAIGNS);
      const updatedCampaignIndex = campaigns.findIndex((campaign) => campaign.get(activityConstants.HUB_ID) === action.updatedMicroCampaign.get(activityConstants.HUB_ID));
      newState = newState.setIn([constants.MICRO_CAMPAIGNS, updatedCampaignIndex], action.updatedMicroCampaign);
      break;
    }

    case constants.UPDATE_MICRO_CAMPAIGN_STATUS_IN_PROGRESS: {
      newState = newState.set(constants.MICRO_CAMPAIGN_UPDATE_STATUS_IN_PROGRESS, true);
      break;
    }

    case constants.CLEAN_MICRO_CAMPAIGNS: {
      newState = newState.set(constants.MICRO_CAMPAIGNS, new List());
      break;
    }

    case constants.GET_MICRO_CAMPAIGN_STATS_SUCCESS: {
      newState = newState
        .set(constants.MICRO_CAMPAIGN_STATS, fromJS(action.data))
        .set(constants.MICRO_CAMPAIGN_STATS_FETCHED, true);
      break;
    }

    case constants.GO_TO_MICRO_CAMPAIGN: {
      newState = newState.set(constants.MICRO_CAMPAIGN_STATS_FETCHED, false);
      break;
    }

    case constants.TOGGLE_COLLAPSED_STATE: {
      const microCampaigns = newState.get(constants.MICRO_CAMPAIGNS);
      const isCurrentElemOpen = microCampaigns.getIn([action.index, constants.IS_OPEN]);
      newState = newState.setIn([constants.MICRO_CAMPAIGNS, action.index, constants.IS_OPEN], !isCurrentElemOpen);
      if (!isCurrentElemOpen) {
        const currentlyOpenElemIndex = microCampaigns.findIndex((microCampaign) => microCampaign.get(constants.IS_OPEN) === false);
        if (currentlyOpenElemIndex && currentlyOpenElemIndex !== action.index) {
          newState = newState.setIn([constants.MICRO_CAMPAIGNS, currentlyOpenElemIndex, constants.IS_OPEN], false);
        }
      }
      break;
    }

    default: {
      newState = state;
    }

  }

  return newState;

}

export default microCampaignsReducer;
