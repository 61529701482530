import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Grid, Segment } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';

import '../../Activity/activity.styles.less';
import '../microCampaign.styles.less';
import * as constants from '../microCampaign.constants';
import * as activityConstants from '../../Activity/activity.constants';
import * as schemaConstants from '../../Activity/activitySchema/activitySchema.constants';
import BenefitsList from '../../Benefits/views/benefitsList/benefitsList';
import ActionsList from '../../Activity/views/actions/actionsList';
import * as benefitsConstants from '../../Benefits/benefits.constants';
import MicroCampaignAssetSetHeader from '../views/miccroCampaignSingleAssetSetHeader';


export class MicroCampaignSingleAssetSet extends React.PureComponent {

  // eslint-disable-next-line no-undef
  static propTypes = {
    index: PropTypes.number,
    validationErrors: PropTypes.object,
    automationIdPrefix: PropTypes.string,
    deleteAssetTemplate: PropTypes.func,
    selectedBenefitStatus: PropTypes.string,
    selectedBenefit: PropTypes.object,
    actionsList: PropTypes.object,
    schemaActionsList: PropTypes.object,
    mentions: PropTypes.object,
    assetSet: PropTypes.object,
    deleteCommunicationAction: PropTypes.func,
    updateCommunicationActionField: PropTypes.func,
    updateCommunicationActionType: PropTypes.func,
    getBusinessBundlePlan: PropTypes.func.isRequired,
    assetSetsCount: PropTypes.number,
    benefitsListValue: PropTypes.string,
    trigger: PropTypes.string,
    onBenefitChange: PropTypes.func,
    clearAssetSetData: PropTypes.func,
    toggleAssetSetDisplay: PropTypes.func,
    submitActionToCustomer: PropTypes.func,
  };

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const isCollapsed = this.props.assetSet.has(activityConstants.IS_COLLAPSED) ? this.props.assetSet.get(activityConstants.IS_COLLAPSED) : this.props.index !== 0 && !this.props.validationErrors;

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Segment className="select-benefit-comp single-case single-micro-campaign-case" key={this.props.index.toString()}>
              <Accordion>
                <Accordion.Title active={!isCollapsed}>
                  <MicroCampaignAssetSetHeader
                    automationIdPrefix={this.props.automationIdPrefix}
                    assetSetIndex={this.props.index}
                    assetSetsCount={this.props.assetSetsCount}
                    assetSet={this.props.assetSet}
                    case={this.props.assetSet}
                    selectedBenefit={this.props.selectedBenefit ? this.props.selectedBenefit.get(benefitsConstants.BENEFIT_TITLE) : null}
                    index={this.props.index}
                    deleteAssetSet={this.props.deleteAssetTemplate}
                    clearAssetSetData={this.props.clearAssetSetData}
                    toggleAssetSetDisplay={this.props.toggleAssetSetDisplay}
                    hasValidationErrors={!!this.props.validationErrors}
                  />
                </Accordion.Title>
                <Accordion.Content active={!isCollapsed}>
                  <Grid className="case-conditions micro-campaign-case-conditions">
                    <Grid.Row>
                      <Grid.Column width={1}></Grid.Column>
                      <Grid.Column width={15}>
                        <p className="case-conditions-label micro-campaigns-case-conditions-label">{formatMessage({ id: 'micro.campaign.single.case.select.benefit' })}</p>
                        <BenefitsList
                          allowCreateGift={false}
                          error={this.props.validationErrors ? this.props.validationErrors.get(constants.MICRO_CAMPAIGN_ASSET_TEMPLATE) : null}
                          index={this.props.index}
                          name={schemaConstants.ASSIGN_ASSET_BENEFIT_KEY_FIELD}
                          onChange={(e, data) => this.props.onBenefitChange(e, data, this.props.index)}
                          prefix={`${this.props.automationIdPrefix}.${this.props.index}.benefitKey`}
                          value={this.props.benefitsListValue}
                        />

                        {this.props.selectedBenefitStatus && this.props.selectedBenefitStatus !== benefitsConstants.BENEFIT_ACTIVE &&
                        <p className="hint">
                          {formatMessage(
                          { id: `benefits.${this.props.selectedBenefitStatus}.hint` },
                          { benefitName: this.props.selectedBenefit.get(benefitsConstants.BENEFIT_TITLE) }
                          )}
                        </p>
                    }
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <div className="communication micro-container-part case-actions micro-campaigns-case-actions">
                    <p>{formatMessage({ id: 'microCampaigns.header.communication' })}</p>
                    <ActionsList
                      actionPlaceholder={formatMessage({ id: 'microCampaign.actions.dropdown.default.first' })}
                      actionsList={this.props.actionsList}
                      actionsPopupContent={this.props.schemaActionsList}
                      activityTrigger={this.props.trigger}
                      activityType={this.props.trigger}
                      automationIdPrefix={this.props.automationIdPrefix}
                      deleteAction={this.props.deleteCommunicationAction}
                      mentionsList={this.props.mentions}
                      updateActionField={(name, value, index) => this.props.updateCommunicationActionField(name, value, index, this.props.index)}
                      updateActionType={(actionName, index) => this.props.updateCommunicationActionType(actionName, index, this.props.index)}
                      validationErrors={this.props.validationErrors ? this.props.validationErrors.get(activityConstants.ACTIONS) : null}
                      allowCreateEmail={false}
                      showSimpleDropdown
                      actionsWrapperIndex={0}
                      noSectionTitle
                      submitActionToCustomer={this.props.submitActionToCustomer}
                      getBusinessBundlePlan={this.props.getBusinessBundlePlan}
                    />
                  </div>
                </Accordion.Content>
              </Accordion>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}


export default injectIntl(MicroCampaignSingleAssetSet);

