import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Dimmer, Grid, Loader } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import Modal from '../../../common/components/CustomModal/CustomModal';
import * as activityConstants from '../../Activity/activity.constants';
import injectSaga from '../../../utils/injectSaga';
import * as emailsActions from '../emails.actions';
import emailsSaga from '../emails.saga';
import * as selectors from '../emails.selectors';
import * as reducerConstants from '../../../constants/reducer.constants';
import * as appSelectors from '../../App/selectors';
import * as constants from '../emails.constants';
import EmailStatsOnetime from './EmailStatsOnetime';
import EmailStatsRule from './EmailStatsRule';


class EmailStats extends React.PureComponent {
  static propTypes = {
    actions: PropTypes.object,
    email: PropTypes.object.isRequired,
    onConfirm: PropTypes.func,
    open: PropTypes.bool,
    onetimeStats: PropTypes.object,
    ruleStats: PropTypes.object,
    localeDateFormat: PropTypes.string,
    localeTimeFormat: PropTypes.string,
    businessTimezone: PropTypes.string,
    campaignStatistics: PropTypes.object,
    statisticsPerRule: PropTypes.object,
    microCampaignStats: PropTypes.object,
    statisticsPerMicroCampaign: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      campaignsStatsLoading: false,
      showCampaignStats: false
    };
    this.onClose = this.onClose.bind(this);

  }

  componentWillMount() {
    this.setState({
      campaignsStatsLoading: false
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.campaignStatistics || nextProps.statisticsPerRule || nextProps.microCampaignStats) {
      this.setState({
        campaignsStatsLoading: false,
        showCampaignStats: true
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open) {
      this.props.actions.getEmailsStats(this.props.email.get(activityConstants.HUB_ID));
    }
  }

  onClose = () => {
    this.setState({
      campaignsStatsLoading: false
    });
    this.props.actions.cleanEmailStats();
    this.props.actions.cleanEmailCampaignStats();
    this.props.onConfirm();
  };

  formatDate = (date) => `${moment(date).tz(this.props.businessTimezone).format(this.props.localeDateFormat)} ${moment(date).format(this.props.localeTimeFormat)}`;

  getDetailedStatsPerRow = (rowId, entityType) => {
    // toggle data

    let detailedStats = '';
    let rowName = '';
    switch (entityType) {
      case 'oneTime': {
        detailedStats = this.props.campaignStatistics;
        rowName = constants.EMAIL_CAMPAIGN_ID;
        break;
      }
      case 'rule': {
        detailedStats = this.props.ruleStats;
        rowName = constants.RULE_ID;
        break;
      }
      case 'microCampaign': {
        detailedStats = this.props.microCampaignStats;
        rowName = constants.MICRO_CAMPAIGN_ID;
        break;
      }
      default:
        break;
    }


    // if statistics open
    if (this.state.showCampaignStats) {

      // if the same campaign clicked just close it and clean the data
      if (detailedStats && detailedStats.get(rowName) === rowId) {
        this.setState({
          showCampaignStats: false,
          campaignsStatsLoading: false
        });
        this.props.actions.cleanEmailCampaignStats();

        // if another campaign clicked go get data
      } else if ((detailedStats && detailedStats.get(rowName) !== rowId) || !detailedStats) {
        this.setState({
          showCampaignStats: true,
          campaignsStatsLoading: true
        });
        this.getStatsByType(entityType, rowId);
      }

      // if statistics closed
    } else if (!this.state.showCampaignStats) {

      // statistics exist in props
      if (detailedStats) {

        // if the same campaign id clicked just open to show stats
        if (detailedStats.get(rowName) === rowId) {
          this.setState({
            showCampaignStats: true,
            campaignsStatsLoading: false
          });

          // if another campaign id clicked get stats
        } else if (detailedStats.get(rowName) !== rowId) {
          this.setState({
            showCampaignStats: true,
            campaignsStatsLoading: true
          });
          this.getStatsByType(entityType, rowId);
        }

        // if statistics not exist in props just go get it
      } else if (!detailedStats) {

        this.setState({
          showCampaignStats: true,
          campaignsStatsLoading: true
        });
        this.getStatsByType(entityType, rowId);
      }
    }
  };

  getStatsByType = (entityType, rowId) => {
    if (entityType === 'oneTime') {
      this.props.actions.getDetailedStatsPerOnetimeCampaign(this.props.email.get(activityConstants.HUB_ID), rowId);
    } else if (entityType === 'rule') {
      this.getDetailedStatsPerRule(this.props.email.get(activityConstants.HUB_ID), rowId);
    } else if (entityType === 'microCampaign') {
      this.getDetailedStatsPerMicroCampaign(this.props.email.get(activityConstants.HUB_ID), rowId);
    }
  };

  getDetailedStatsPerRule = (emailHubId, activityId) => {
    const activity = this.props.ruleStats.find((rule) => rule.get(constants.EMAIL_STATS_ACTIVITY_ID) === activityId);
    if (activity) {
      this.props.actions.getDetailedStatsPerRule(emailHubId, activity.get(constants.EMAIL_STATS_CAMPAIGNS).toJS(), activityId);
    }
  };

  getDetailedStatsPerMicroCampaign = (emailHubId, activityId) => {
    const microCampaign = this.props.microCampaignStats.find((campaign) => campaign.get(constants.EMAIL_STATS_ACTIVITY_ID) === activityId);
    if (microCampaign) {
      this.props.actions.getDetailedStatsPerMicroCampaign(emailHubId, microCampaign.get(constants.EMAIL_STATS_CAMPAIGNS).toJS(), activityId);
    }
  };

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line
    const { open, email } = this.props;
    const name = email.get(activityConstants.NAME);
    const title = <p>{formatMessage({ id: 'email-stats.title' }, { name })}</p>;
    return (
      <Modal
        size="large"
        open={open}
        className="email-stats-modal"
        onClose={this.onClose}
        automationIdPrefix="email.analysis"
        header={
          <div className="type-and-name">
            {title}
          </div>
        }
        content={
          <div className="email-stats-modal-content">
            <Dimmer inverted active={!this.props.onetimeStats && !this.props.ruleStats}>
              <Loader active />
            </Dimmer>

            <div>
              <Dimmer inverted active={this.state.campaignsStatsLoading}><Loader active={this.state.campaignsStatsLoading} /></Dimmer>
              <Grid>
                <EmailStatsOnetime
                  onetimeStats={this.props.onetimeStats}
                  campaignStatistics={this.props.campaignStatistics}
                  getDetailedStatsPerRow={this.getDetailedStatsPerRow}
                  showCampaignStats={this.state.showCampaignStats}
                  formatDate={this.formatDate}
                />
                <Grid.Row />
                <EmailStatsRule
                  ruleStats={this.props.ruleStats}
                  showCampaignStats={this.state.showCampaignStats}
                  statisticsPerRule={this.props.statisticsPerRule}
                  getDetailedStatsPerRow={this.getDetailedStatsPerRow}
                  statsTypePrefix="rule"
                />
                <Grid.Row />
                <EmailStatsRule
                  ruleStats={this.props.microCampaignStats}
                  showCampaignStats={this.state.showCampaignStats}
                  statisticsPerRule={this.props.statisticsPerMicroCampaign}
                  getDetailedStatsPerRow={this.getDetailedStatsPerRow}
                  statsTypePrefix="microCampaign"
                />
              </Grid>
            </div>

          </div>
          }
        actions={[
          <a data-automation-id="email-stats.got.it.button" role="button" key="gotIt" onClick={this.onClose}>{formatMessage({ id: 'general.button.got-it' })}</a>
        ]}
        closeOnDimmerClick
      />
    );
  }
}

const mapStateToProps = (state) => ({
  onetimeStats: selectors.getEmailOneTimeStats(state),
  ruleStats: selectors.getEmailRuleStats(state),
  microCampaignStats: selectors.getEmailMicroCampaignStats(state),
  localeDateFormat: appSelectors.getBrowserLocaleDateFormat(state),
  localeTimeFormat: appSelectors.getBrowserLocaleTimeFormat(state),
  businessTimezone: appSelectors.getBusinessTimeZone(state),
  campaignStatistics: selectors.getCampaignStatistics(state),
  statisticsPerRule: selectors.getStatisticsPerRule(state),
  statisticsPerMicroCampaign: selectors.getStatisticsPerMicroCampaign(state)
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(emailsActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.EMAILS_BRANCH, saga: emailsSaga });

export default compose(
  withConnect,
  withSaga,
  injectIntl
)(EmailStats);
