export const BUTTON_ACTION_VALUE = 'buttonActionValue';
export const CUSTOM_WEB_VIEWS = 'customWebViews';
export const CUSTOM_WEB_VIEW_CONSTANT_ID = 'constantId';
export const CATALOGS = 'catalogs';
export const CATALOG_TITLE = 'title';
export const CATALOG_CONSTANT_ID = 'constantId';
export const CATALOG_MODE = 'catalogMode';
export const CATALOG_MODE_GALLERY = 'gallery';
export const CATALOG_MODE_LIST = 'list';
export const CUSTOM_APP_SCREENS = 'customAppScreens';
export const CUSTOM_APP_SCREEN_ID = 'id';
export const ASSET_TYPE = 'assetType';
export const ASSET_KEY = 'assetKey';
export const DO_NOTHING = 'doNothing';
export const NAVIGATE_TO_TARGET_TC = 'navigateToTargetTC';
export const RATE_APP = 'rateApp';
export const OPEN_WEB_VIEW = 'openWebView';
export const OPEN_WEB_PAGE = 'openWebPage';
export const SHARE_BUDGET = 'shareBudget';
export const SHARE_BUDGET_TYPE = 'shareBudgetType';
export const SHARE_BUDGET_VALUE = 'shareBudgetValue';
export const SHARE_BUDGET_TYPE_FIXED = 'fixed';
export const SHARE_BUDGET_TYPE_CUSTOM = 'custom';
export const OPEN_SPECIFIC_ASSET_SCREEN = 'openSpecificAssetScreen';
export const OPEN_THIS_ASSET_SCREEN = 'openThisAssetScreen';
export const OPEN_CATALOG_PAGE = 'openCatalogPage';
export const BUTTON_ACTION_TYPE = 'buttonActionType';
export const GENERIC_ACTION_BUTTON_TEXT = 'buttonText';
export const APP_SCREEN = 'appScreen';
export const WEB_VIEW = 'webView';
export const CATALOG = 'catalog';
export const WEB_PAGE_URL = 'webPageUrl';
export const TARGET_ASSET_ID = 'targetAssetId';
export const GENERIC_ACTION = 'genericAction';

// COMMON CONSTANTS
export const NAME = 'name';
export const APP = 'app';
export const BUSINESS = 'business';
export const LOCATION_ID = 'locationId';

// ACTIONS CONSTANTS
export const GET_CUSTOM_APP_SCREENS_DATA = 'app/genericActions/GET_CUSTOM_APP_SCREENS_DATA';
export const GET_CUSTOM_APP_SCREENS_DATA_SUCCESS = 'app/genericActions/GET_CUSTOM_APP_SCREENS_DATA_SUCCESS';

export const GET_CUSTOM_WEB_VIEWS_DATA = 'app/genericActions/GET_CUSTOM_WEB_VIEWS_DATA';
export const GET_CUSTOM_WEB_VIEWS_DATA_SUCCESS = 'app/genericActions/GET_CUSTOM_WEB_VIEWS_DATA_SUCCESS';

export const GET_CATALOGS = 'app/genericActions/GET_CATALOGS';
export const GET_CATALOGS_SUCCESS = 'app/genericActions/GET_CATALOGS_SUCCESS';
