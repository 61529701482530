import * as constants from '../activity.constants';

export function activityPromoCodeReducer(state, action) {
  if (state.getIn([constants.DATA, constants.TYPE]) !== constants.ACTIVITY_TYPE_PROMO_CODE) {
    return state;
  }
  switch (action.type) {

    case constants.UPDATE_PROMO_CODE_SINGLE_CODE_VALUE: {
      return state.setIn([
        constants.DATA, constants.PROMO_CODE_BULKS, constants.PROMO_CODE_MODE_SINGLE_CODE, action.bulkIndex, constants.PROMO_CODE_SINGLE_CODE_BULK_VALUE
      ], action.value)
        .setIn([constants.DATA, constants.VALIDATION_PROMO_CODE_TAKEN_FLAG], false);
    }
    case constants.UPDATE_PROMO_CODE_BULK_IS_AVAILABILITY: {
      if (!action.value) {
        state = state.setIn([constants.VALIDATION_ERRORS, constants.PROMO_CODE_BULKS, constants.PROMO_CODE_MODE_SINGLE_CODE, '0'],
          'activity.validation.error.promo.code.bulk.single.code.in.use');
      }
      return state.setIn([constants.DATA, constants.VALIDATION_PROMO_CODE_TAKEN_FLAG], !action.value);
    }
    case constants.UPDATE_PROMO_CODE_BULKS_VALUE: {
      return state.setIn([
        constants.DATA, constants.PROMO_CODE_BULKS
      ], action.value)
        .setIn([constants.DATA, constants.VALIDATION_PROMO_CODE_TAKEN_FLAG], false);
    }
    case constants.GET_FREE_PROMO_CODES_NUMBER_SUCCESS: {
      return state.setIn([constants.DATA, constants.FREE_PROMO_CODES_NUMBER], action.value);
    }

    case constants.UPDATE_PROMO_CODE_BULK_TYPE: {
      return state.setIn([
        constants.DATA, constants.PROMO_CODE_BULKS_TYPE
      ], action.value)
        .setIn([constants.DATA, constants.VALIDATION_PROMO_CODE_TAKEN_FLAG], false);
    }

    case constants.IMPORT_CODES_BULK_UPLOAD: {
      return state.set(constants.PROMO_CODE_FILE_UPLOAD_IN_PROGRESS, true);
    }

    case constants.IMPORT_CODES_BULK_UPLOAD_SUCCESS: {
      return state
        .setIn([constants.DATA, constants.PROMO_CODE_BULKS, constants.PROMO_CODE_MODE_FILE_UPLOADED, action.bulkIndex, constants.PROMO_CODE_FILE_UPLOADED_BULK_FILENAME], action.fileName)
        .setIn([constants.DATA, constants.PROMO_CODE_BULKS, constants.PROMO_CODE_MODE_FILE_UPLOADED, action.bulkIndex, constants.PROMO_CODE_FILE_UPLOADED_BULK_BUCKETNAME], action.bucketName)
        .setIn([constants.DATA, constants.PROMO_CODE_BULKS, constants.PROMO_CODE_MODE_FILE_UPLOADED, action.bulkIndex, constants.PROMO_CODE_FILE_UPLOADED_BULK_MEDIALINK], action.mediaLink)
        .setIn([constants.DATA, constants.PROMO_CODE_BULKS, constants.PROMO_CODE_MODE], constants.PROMO_CODE_MODE_FILE_UPLOADED)
        .setIn([constants.PROMO_CODE_FILE_UPLOAD_IN_PROGRESS], false)
        .setIn([constants.DATA, constants.VALIDATION_PROMO_CODE_TAKEN_FLAG], false)
        .delete(constants.VALIDATION_ERRORS)
        .deleteIn([constants.DATA, constants.VALIDATION_PROMO_CODE_ERROR]);
    }

    case constants.UPDATE_PROMO_CODES_MODE: {
      let newState = state;
      if (action.mode !== state.getIn([constants.DATA, constants.PROMO_CODE_BULKS, constants.PROMO_CODE_MODE])) {
        newState = newState
          .delete(constants.VALIDATION_ERRORS)
          .deleteIn([constants.DATA, constants.VALIDATION_PROMO_CODE_ERROR]);
      }
      newState = newState
        .setIn([constants.DATA, constants.PROMO_CODE_BULKS, constants.PROMO_CODE_MODE], action.mode);
      return newState;
    }

    default:
      return state;
  }
}
