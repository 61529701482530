import { injectIntl } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon } from 'semantic-ui-react';
import * as constants from '../../../activity.constants';
import Tooltip from '../../../../../common/components/Tooltip/Tooltip';
import DropdownWrapper from '../../../../../common/components/DropdownWrapper/DropdownWrapper';
import Capsules from '../../../../../common/components/Capsules/Capsules';

class SendCodesToPos extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string,
    onActionFieldUpdate: PropTypes.func.isRequired,
    action: PropTypes.object,
    index: PropTypes.number,
    validationErrors: PropTypes.object
  };

  onActionFieldUpdate(data) {
    this.props.onActionFieldUpdate(data.name, data.value, this.props.index);
  }

  render() {
    const { index, action, onActionFieldUpdate, validationErrors, automationIdPrefix } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const codesValue = action.has(constants.CODES) ? action.get(constants.CODES).toJS() : [];

    return (
      <Grid className={`deal-action ${this.props.action.get(constants.ACTION_TYPE)}`}>
        <Grid.Row>
          <Grid.Column width={16}>
            <div className="deal-fields">
              <div className="row__container">
                <span className="head-of-sentence text-aligned-to-input-wrapper">{formatMessage({ id: 'deals.send' })}</span>
                <DropdownWrapper
                  options={[
                    {
                      value: constants.ITEM_CODE,
                      text: formatMessage({ id: 'activity.action.itemCode' })
                    },
                    {
                      value: constants.DEAL_CODE,
                      text: formatMessage({ id: 'activity.action.dealCode' })
                    }
                  ]}
                  name={constants.POS_CODE_TYPE}
                  prefix={`${automationIdPrefix}.${constants.POS_CODE_TYPE}`}
                  onSelectOption={(e, data) => onActionFieldUpdate(constants.POS_CODE_TYPE, data.value, index)}
                  value={action.get(constants.POS_CODE_TYPE)}
                >
                </DropdownWrapper>
                <span className="inside-sentence" />
                <Capsules
                  error={validationErrors ? validationErrors.get(constants.CODES) : null}
                  name={constants.CODES}
                  onChange={(e, data) => this.onActionFieldUpdate(data)}
                  prefix={`${automationIdPrefix}.value`}
                  type={constants.CODE}
                  value={codesValue}
                />
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <p className="hint">
              {formatMessage({ id: 'activity.action.sendCodesToPos.hint' })}
              <Tooltip
                className="tooltip.ui.popup"
                content={formatMessage({ id: 'activity.action.sendCodesToPos.tooltip' })}
                trigger={<Icon className="como-ic-help" />}
                position="top left"
              />
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default injectIntl(SendCodesToPos);
