import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import './UpAndDownCaret.styles.less';

const AUTOMATION_PREFIX_NAME = 'carets';

export default class UpAndDownCaret extends React.Component {
  static propTypes = {
    onIncrement: PropTypes.func.isRequired,
    onDecrement: PropTypes.func.isRequired,
    prefix: PropTypes.string,
    disabled: PropTypes.bool
  };

  render() {
    const { prefix, disabled } = this.props;
    return (
      <div className={`container__caret ${disabled ? 'disabled' : ''}`}>
        <Icon
          className="como-ic-triangle-up"
          size="small"
          data-automation-id={`${prefix}.${AUTOMATION_PREFIX_NAME}.up`}
          onClick={disabled ? null : this.props.onIncrement}
        />
        <Icon
          className="como-ic-triangle-down"
          size="small"
          data-automation-id={`${prefix}.${AUTOMATION_PREFIX_NAME}.down`}
          onClick={disabled ? null : this.props.onDecrement}
        />
      </div>
    );
  }
}
