import { call, put, takeLatest } from 'redux-saga/effects';
import * as constants from './genericAction.contants';
import * as api from './genericAction.api';
import * as errorConstant from '../ErrorMessage/errorMessage.constants';

function* getCustomAppScreensData() {
  try {
    const customScreens = yield call(api.getCustomScreensData);
    yield put({ type: constants.GET_CUSTOM_APP_SCREENS_DATA_SUCCESS, data: customScreens });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getCustomScreensData.Fail'] });
  }
}

function* getCatalogs() {
  try {
    const catalogs = yield call(api.getCatalogs);
    yield put({ type: constants.GET_CATALOGS_SUCCESS, data: catalogs });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getCatalogs.Fail'] });
  }
}

function* getCustomWebViewsData() {
  try {
    const customWebViews = yield call(api.getCustomWebViewsData);
    yield put({ type: constants.GET_CUSTOM_WEB_VIEWS_DATA_SUCCESS, data: customWebViews });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getCustomWebViewsData.Fail'] });
  }
}

// sagas listen to dispatched actions too (same as reducers)
function* genericActionSaga() {
  yield [
    takeLatest(constants.GET_CUSTOM_APP_SCREENS_DATA, getCustomAppScreensData),
    takeLatest(constants.GET_CUSTOM_WEB_VIEWS_DATA, getCustomWebViewsData),
    takeLatest(constants.GET_CATALOGS, getCatalogs)
  ];
}

export default genericActionSaga;
