import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import * as activityConstants from '../../Activity/activity.constants';
import * as constants from '../goals.constants';
import * as templatesConstants from '../../Templates/templates.constants';
import * as trackerConstants from '../../../common/utils/tracking/tracking.consts';
import tracker from '../../../common/utils/tracking/tracker';
import Tooltip from '../../../common/components/Tooltip/Tooltip';
import ColorBorderCard from '../../../common/components/Cards/ColorBorderCard/ColorBorderCard';
import ActivityType from '../../../common/components/ActivityType/ActivityType';


const TEMPLATE_DESCRIPTION_LENGTH_MAX = 210;
const AUTOMATION_NAME = 'goal';

const Tool = ({ index, active, intl, tool, onCardSelect }) => {
  const { formatMessage } = intl;
  const description = tool.get(constants.GOAL_TOOL_DESCRIPTION);
  const name = tool.get(constants.GOAL_TOOL_NAME);
  const templatesRules = tool.get(constants.GOAL_TEMPLATES_RULES);
  const templatesDeals = tool.get(constants.GOAL_TEMPLATES_DEALS);
  const templatesOneTimeActions = tool.get(constants.GOAL_TEMPLATES_ONE_TIME_ACTIONS);

  let allTemplates = [];

  if (!_.isNil(templatesRules) && templatesRules.size > 0) {
    allTemplates = templatesRules.toJS();
  }

  if (!_.isNil(templatesDeals) && templatesDeals.size > 0) {
    allTemplates = [...allTemplates, ...templatesDeals.toJS()];
  }

  if (!_.isNil(templatesOneTimeActions) && templatesOneTimeActions.size > 0) {
    allTemplates = [...allTemplates, ...templatesOneTimeActions.toJS()];
  }

  return (<ColorBorderCard
    id={index}
    active={active}
    automationId={AUTOMATION_NAME}
    onClick={tracker.wrapButtonAction(
      () => onCardSelect(index),
      trackerConstants.BUTTON_TYPE_SELECT_GOAL_CAMPAIGN,
      { [trackerConstants.CAMPAIGN_NAME]: name }
    )}
  >
    <div className="name-and-description">
      <p className="name">{name}</p>
      {description && description.length > TEMPLATE_DESCRIPTION_LENGTH_MAX ?
        <Tooltip
          wide
          content={description}
          trigger={
            <p className="description">{description}</p>
          }
          position="bottom left"
        /> :
        <p className="description">{description}</p>
      }
    </div>
    <div className="will-create">
      <p className="title">{formatMessage({ id: 'goals.goal.content.templates.template.will-create' })}</p>
      <div className="activities">
        {allTemplates.map((template, i) => {
          const activity = template[templatesConstants.ACTIVITY];
          return (
            <div
              key={i}
              className="activity"
            >
              <ActivityType type={activity[activityConstants.TYPE]} />
              <span>{activity[activityConstants.NAME]}</span>
            </div>
          );
        })
        }
      </div>
    </div>
  </ColorBorderCard>);
};

Tool.defaultProps = {
  active: false
};

Tool.propTypes = {
  onCardSelect: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
  tool: PropTypes.object.isRequired,
  active: PropTypes.bool,
};

export default injectIntl(Tool);
