import { List, Map } from 'immutable';

import * as schemaConstants from '../../../activitySchema/activitySchema.constants';
import * as constants from '../../../activity.constants';
import { emptyItemsPopulationImmutable } from '../../activity.reducer.defaults';

export function changeAddPointsActionType(pointsAction, addPointsType) {
  let result = pointsAction
  // set the correct action type
    .set(constants.ACTION_TYPE, pointsAction.get(constants.ACTION_TYPE))
    // set the correct type
    .set(constants.TYPE, addPointsType)
    // set points to null
    .set(constants.POINTS, null);

  // set the state according to the action type
  switch (addPointsType) {
    case constants.ACCUMULATE_POINTS_TYPE_PERCENTAGE:
      result = result.remove(constants.POINTS_PER_RATE);
      break;

    case constants.ACCUMULATE_POINTS_TYPE_RATE:
      result = result.set(constants.POINTS_PER_RATE, null);
      break;

    case constants.ACCUMULATE_POINTS_TYPE_FIXED:
    default:
      result = result
        .remove(constants.EXCLUDED_MEANS_OF_PAYMENT)
        .remove(constants.ITEMS_POPULATION)
        .remove(constants.EXCLUDE_ITEMS_POPULATION)
        .remove(constants.POINTS_PER_RATE);
  }

  if (addPointsType === constants.ACCUMULATE_POINTS_TYPE_RATE || addPointsType === constants.ACCUMULATE_POINTS_TYPE_PERCENTAGE) {
    result = result
      .set(constants.ITEMS_POPULATION, emptyItemsPopulationImmutable)
      .set(constants.EXCLUDE_ITEMS_POPULATION, emptyItemsPopulationImmutable)
      .set(constants.EXCLUDE_ITEMS_POPULATION_STATUS, false)
      .set(constants.EXCLUDED_MEANS_OF_PAYMENT, List())
      .set(constants.EXCLUDED_MEANS_OF_PAYMENT_STATUS, false);
  }
  return result;
}


export function initializeAccumulatePointsAction(actionsMode, trigger, duplicateFromAction, actionType) {
  let actionObject = Map();
  actionObject = actionObject.set(constants.ACTION_TYPE, actionType);

  if (actionsMode === constants.ACTIONS_SIMPLE_MODE && trigger === schemaConstants.TRIGGER_PURCHASE_ANALYZED) {
    actionObject = actionObject
      .set(constants.TYPE, duplicateFromAction
        ? duplicateFromAction.get(constants.TYPE)
        : constants.ACCUMULATE_POINTS_TYPE_PERCENTAGE)
      .set(constants.ITEMS_POPULATION, emptyItemsPopulationImmutable)
      .set(constants.EXCLUDE_ITEMS_POPULATION, emptyItemsPopulationImmutable)
      .set(constants.EXCLUDED_MEANS_OF_PAYMENT, List());
  } else {
    actionObject = actionObject.set(constants.TYPE, constants.ACCUMULATE_POINTS_TYPE_FIXED);
  }
  actionObject = actionObject
    .set(constants.POINTS, null)
    .set(constants.BUDGET_TYPE, null);
  return actionObject;
}

export function initializeAddPointsAction() {
  return Map()
    .set(constants.ACTION_TYPE, schemaConstants.ADD_POINTS_ACTION)
    .set(constants.POINTS, null)
    .set(constants.BUDGET_TYPE, null);
}
