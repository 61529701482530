import React from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { Grid, Header } from 'semantic-ui-react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import DropdownWrapper from '../../../../common/components/DropdownWrapper/DropdownWrapper';
import ItemsPopulation from '../itemsPopulation/itemsPopulation.container';
import Limit from '../actions/Limit/Limit';
import ActionsContainer from '../ActionsContainer';

import * as constants from '../../activity.constants';
import * as appConstants from '../../../App/app.constants';
import * as itemsPopulationConstants from '../itemsPopulation/itemsPopulation.constants';

import * as actionsObj from '../../activity.actions';
import * as punchCardActions from './punchCard.actions';
import * as casesActionsObj from '../../views/cases/cases.actions';

import * as selectors from '../../activity.selectors';
import * as schemaSelectors from '../../activitySchema/activitySchema.selectors';
import * as mentionsSelectors from '../../activitySchema/mentions.selectos';

class PunchCardDefinitionTab extends React.PureComponent {

  static propTypes = {
    actionsPerTrigger: PropTypes.object,
    actions: PropTypes.object,
    actionsMode: PropTypes.string,
    activityActionsWrappers: PropTypes.object,
    activityPrefix: PropTypes.string,
    activityServerId: PropTypes.string,
    activityTrigger: PropTypes.string,
    activityType: PropTypes.string,
    bundle: PropTypes.object,
    cases: PropTypes.object,
    casesActions: PropTypes.object,
    conditionsPerTrigger: PropTypes.object,
    features: PropTypes.object,
    intl: PropTypes.object,
    mentions: PropTypes.object,
    punchCardRewardMode: PropTypes.string,
    punchRule: PropTypes.object,
    requiredPunches: PropTypes.number,
    requiredPunchesOptions: PropTypes.array,
    schemaDateTimeGlobalConditions: PropTypes.object,
    schemaMembershipConditions: PropTypes.object,
    validationErrors: PropTypes.object,
    deleteActionsWrapper: PropTypes.func,
    getBusinessBundlePlan: PropTypes.func.isRequired
  };

  static defaultProps = {
    punchCardRewardMode: constants.PUNCH_CARD_REWARD_MODE_SIMPLE,
  };

  componentWillMount() {
    if (!this.props.cases && !this.props.activityActionsWrappers && !this.props.activityActionsWrappers.getIn([0, constants.ACTIONS_LIST, 0, constants.DISCOUNT_ON, constants.TYPE])) {
      this.props.actions.setDefaultActionsType();
    }
  }

  onChangeRewardMode = () => {
    this.props.actions.onChangeRewardMode(this.props.punchCardRewardMode);
  };

  onUpdateRequiredPunches = (e, data) => {
    this.props.actions.updateRequiredPunches(data.value);
  };

  onUpdatePurchaseItems = (e, data) => {
    // update itemsPopulation in punch card redeem action
    // if discount on action not yet chosen or it is of type customDefault update it's items population conditions according to the data:
    if (this.props.activityActionsWrappers && this.props.activityActionsWrappers.getIn([0, constants.ACTIONS_LIST, 0, constants.DISCOUNT_ON, constants.TYPE]) === itemsPopulationConstants.ITEMS_POPULATION_TYPE_PUNCH_CARD_DEFAULT_DISCOUNT) {
      const customData = data.set(constants.TYPE, itemsPopulationConstants.ITEMS_POPULATION_TYPE_PUNCH_CARD_DEFAULT_DISCOUNT);
      this.props.actions.updateActionField(constants.DISCOUNT_ON, customData, 0, 0);
    }
    // also update itemsPopulation in child punch rule's condition
    this.props.actions.updatePunchCardShoppingCartItems(data);
  };

  onUpdateDiscountedItems = (e, data) => {
    // update itemsPopulation in punch card redeem action
    this.props.actions.updateActionField(constants.DISCOUNT_ON, data, 0, 0);
  };

  onUpdateLimit = (fieldName, fieldValue) => {
    this.props.actions.updatePunchRuleLimit(fieldValue);
  };

  get punchRewardText() {
    const { formatMessage } = this.props.intl;
    const ordinalIndicators = [
      'ordinalIndicators.th',
      'ordinalIndicators.st',
      'ordinalIndicators.nd',
      'ordinalIndicators.rd'
    ];
    const indicator = (this.props.requiredPunches + 1) % 100;
    const ordinalIndicator = formatMessage({ id: ordinalIndicators[(indicator - 20) % 10] || ordinalIndicators[indicator] || ordinalIndicators[0] });
    const reward = <span className="number-span">{this.props.requiredPunches + 1}<sup>{ordinalIndicator}</sup></span>;
    return (
      <label>
        <FormattedMessage
          id="punchCard.definition.get.numOfPunch"
          values={{ reward }}
        />
      </label>
    );
  }

  get requiredPunchesOptions() {
    return this.props.requiredPunchesOptions.map((num) => ({
      key: num,
      value: num,
      text: num,
    }));
  }

  render() {

    const { actions, activityActionsWrappers, actionsPerTrigger, activityPrefix, activityServerId, activityTrigger, activityType, cases, casesActions, conditionsPerTrigger, features, intl, mentions, punchRule, requiredPunches, schemaDateTimeGlobalConditions, schemaMembershipConditions, validationErrors } = this.props;
    const { formatMessage } = intl;
    const automationIdPrefix = `${activityPrefix}.${constants.ACTIVITY_TAB_DEFINITION}`;
    const purchaseItems = punchRule.getIn([constants.ACTIONS, 0, constants.BUNDLE, constants.VALUE, constants.CONDITIONS_LIST, 0, constants.ITEMS_POPULATION]);
    const punchCardRewardMode = this.props.punchCardRewardMode === null ? constants.PUNCH_CARD_REWARD_MODE_SIMPLE : this.props.punchCardRewardMode;

    return (
      <Grid className={'definition'}>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header>{formatMessage({ id: `activity.type.${activityType}` })} {formatMessage({ id: 'gift.tab.definition' })}</Header>
          </Grid.Column>
          <Grid.Column width={8}>
            <p className={'definition-reward-mode-switch'}>
              <a role={'button'} tabIndex={-1} onClick={this.onChangeRewardMode} data-automation-id="puchCard.rewardMode.switch">
                {formatMessage({ id: `punchCard.rewardMode.go${punchCardRewardMode === constants.PUNCH_CARD_REWARD_MODE_SIMPLE ? 'Custom' : 'Simple'}` })}
              </a>
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16} className={'action-template-wrapper'}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16} className={'punch-card-buy-section'}>
                  <span>{formatMessage({ id: 'punchCard.definition.buy' })}</span>
                  <DropdownWrapper
                    disabled={Boolean(activityServerId)}
                    index={0}
                    onSelectOption={this.onUpdateRequiredPunches}
                    options={this.requiredPunchesOptions}
                    prefix={`${automationIdPrefix}.${constants.REQUIRED_PUNCHES}`}
                    value={requiredPunches}
                  />
                  <ItemsPopulation
                    automationIdPrefix={`${automationIdPrefix}.${constants.ITEMS_POPULATION}`}
                    data={purchaseItems.toJS()}
                    disableAny
                    features={features}
                    index={0}
                    itemsPopulationValidationErrors={validationErrors ? validationErrors.getIn([constants.PUNCH_CARD_CHILD_ACTIVITIES, constants.PUNCH_CARD_RULE_PUNCH, constants.ITEMS_POPULATION, purchaseItems.get(constants.TYPE)]) : null}
                    onUpdate={this.onUpdatePurchaseItems}
                    posFeature={appConstants.FEATURES_SHOPPING_CART_SUBMIT_PURCHASE}
                    position="bottom left"
                  />
                </Grid.Column>
              </Grid.Row>
              {
                punchCardRewardMode === constants.PUNCH_CARD_REWARD_MODE_SIMPLE &&
                (
                  <Grid.Row>
                    <Grid.Column width={16}>
                      {formatMessage({ id: 'punchCard.definition.get.messageStart' })}
                      <ItemsPopulation
                        automationIdPrefix={`${automationIdPrefix}.choiceOfDiscountedItem.${constants.ITEMS_POPULATION}`}
                        data={activityActionsWrappers.getIn([0, constants.ACTIONS_LIST, 0, constants.DISCOUNT_ON]).toJS()}
                        disableAny
                        features={features}
                        index={0}
                        itemsPopulationValidationErrors={validationErrors ? validationErrors.getIn([constants.ACTIVITY_ACTIONS, constants.ACTIONS, 0, constants.ACTIONS_LIST, '0']) : null}
                        onUpdate={this.onUpdateDiscountedItems}
                        posFeature={appConstants.FEATURES_SHOPPING_CART_SUBMIT_PURCHASE}
                        position="bottom left"
                        punchCardDefaultOptionLabel={this.punchRewardText}
                      />
                      <span
                        className="inside-sentence"
                      >{formatMessage({ id: 'punchCard.definition.get.messageEnd' })}</span>
                    </Grid.Column>
                  </Grid.Row>
                )
              }
              <Grid.Row>
                <Grid.Column>
                  <Limit
                    activityType={activityType}
                    automationIdPrefix={`${automationIdPrefix}`}
                    disabled={false}
                    errors={validationErrors ? fromJS({
                      [constants.LIMIT]: validationErrors ? validationErrors.getIn([constants.PUNCH_CARD_CHILD_ACTIVITIES, constants.PUNCH_CARD_RULE_PUNCH, constants.LIMIT]) : null,
                      [constants.AMOUNT_LIMIT]: validationErrors.get(constants.AMOUNT_LIMIT)
                    }) : null}
                    index={0}
                    limit={punchRule.getIn([constants.ACTIONS, 0, constants.ACTIONS_LIST, 0, constants.LIMIT])}
                    onActionFieldUpdate={this.onUpdateLimit}
                    showLimits={{ times: true, amount: false }}
                    limitActions={false}
                  />
                </Grid.Column>
              </Grid.Row>
              {
                punchCardRewardMode === constants.PUNCH_CARD_REWARD_MODE_CUSTOM &&
                (
                  <Grid.Row className={'actions-container-row'}>
                    <Grid.Column width={16}>
                      <ActionsContainer
                        actionsWrappers={activityActionsWrappers}
                        actionsPopupContent={actionsPerTrigger}
                        activityTrigger={activityTrigger}
                        activityType={activityType}
                        addBundleLine={actions.addBundleLine}
                        addPointsOnChangeType={actions.addPointsOnChangeType}
                        allowOccurrences
                        cases={cases}
                        casesActions={casesActions}
                        casesMode={Boolean(cases)}
                        changeActivityActionMode={actions.changeActivityActionMode}
                        conditionsPerTrigger={conditionsPerTrigger}
                        createGiftFromActivity={actions.createGiftFromActivity}
                        createTempApplyOnModel={actions.createTempApplyOnModel}
                        dateTimeConditions={schemaDateTimeGlobalConditions}
                        deleteAction={actions.deleteAction}
                        deleteBundleLine={actions.deleteBundleLine}
                        features={features}
                        hideAdvancedDealActions
                        membershipConditions={schemaMembershipConditions}
                        mentionsList={mentions}
                        onBundleLineFieldUpdate={actions.onBundleLineFieldUpdate}
                        saveApplyOnData={actions.saveApplyOnData}
                        trigger={activityTrigger}
                        updateActionField={actions.updateActionField}
                        updateActionType={actions.updateActionType}
                        validationErrors={validationErrors ? validationErrors.get(constants.ACTIVITY_ACTIONS) : null}
                        deleteActionsWrapper={this.props.actions.deleteActionsWrapper}
                        getBusinessBundlePlan={this.props.getBusinessBundlePlan}
                      />
                    </Grid.Column>
                  </Grid.Row>
                )
              }
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

}

function mapDispatchToProps(dispatch) {
  const creators = {
    ...actionsObj,
    ...punchCardActions,
  };
  return {
    actions: bindActionCreators(creators, dispatch),
    casesActions: bindActionCreators(casesActionsObj, dispatch)
  };
}

const mapStateToProps = (state) => ({
  actionsPerTrigger: selectors.getActionsForSelectedTrigger(state),
  activityServerId: selectors.getServerId(state),
  activityTrigger: selectors.getSelectedTrigger(state),
 // actionsMode: selectors.getActionsMode(state),
 // bundle: selectors.getActivityBundle(state),
  cases: selectors.getActivityCases(state),
  conditionsPerTrigger: selectors.getConditionsForSelectedTrigger(state),
  mentions: mentionsSelectors.getTransformedMentions(state),
  punchCardRewardMode: selectors.getPunchCardRewardMode(state),
  punchRule: selectors.getRelatedPunchRule(state),
  requiredPunches: selectors.getRequiredPunches(state),
  requiredPunchesOptions: schemaSelectors.punchCardRequiredPunches(state),
  schemaDateTimeGlobalConditions: selectors.getDateTimeGlobalConditionSchema(state),
  schemaMembershipConditions: schemaSelectors.getTransformedGlobalMembershipConditions(state),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  injectIntl,
  withConnect,
)(PunchCardDefinitionTab);

