/*
 *
 * Images actions
 *
 */

import * as constants from './images.constants';
import * as errorConstant from '../ErrorMessage/errorMessage.constants';

export function getImagesBySize(size) {
  return {
    type: constants.GET_IMAGES_BY_SIZE,
    size,
  };
}

export function deleteImage(image) {
  return {
    type: constants.DELETE_IMAGE,
    image,
  };
}

export function uploadImages(accepted) {
  return {
    type: constants.UPLOAD_IMAGES,
    accepted,
  };
}

export function displayUploadError() {
  return {
    type: errorConstant.HANDLE_ERROR,
    [errorConstant.MESSAGES_KEYS]: ['uploadImages.rejected.Fail']
  };
}

