import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Grid, Input } from 'semantic-ui-react';

import injectSaga from '../../../../../../utils/injectSaga';
import saga from '../../../../../MailTemplates/mailTemplates.saga';
import * as mailTemplatesActions from '../../../../../MailTemplates/mailTemplates.actions';

import MentionTextArea from '../../../../../../common/components/MentionableTextArea/MentionTextArea';
import './sendEmail.style.less';
import * as schemaConstants from '../../../../activitySchema/activitySchema.constants';
import * as constants from '../../../../../../features/MailTemplates/mailTemplates.constants';
import * as mentionsSelectors from '../../../../activitySchema/mentions.selectos';
import * as appSelectors from '../../../../../App/selectors';
import * as activitySelectors from '../../../../activity.selectors';
import EmailsList from './EmailsList';
import * as mailTemplatesSelectors from '../../../../../MailTemplates/mailTemplates.selectors';
import TestToMyself from '../testToMyself/TestToMyself.container';

export class SendEmail extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string,
    onActionFieldUpdate: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    action: PropTypes.object,
    index: PropTypes.number,
    emailsList: PropTypes.object,
    mailSenderName: PropTypes.string,
    validationErrors: PropTypes.object,
    mentionsList: PropTypes.object,
    loadingMailTemplates: PropTypes.bool,
    loadingMailSenderName: PropTypes.bool,
    intl: PropTypes.object,
    membersCount: PropTypes.number,
    selectedEmail: PropTypes.object,
    createEmailFromActivity: PropTypes.func,
    newlyCreatedEmail: PropTypes.object,
    actionsWrapperIndex: PropTypes.number,
    submitActionToCustomer: PropTypes.func

  };

  static transformHub1Mentions(mentionsText) {
    return mentionsText.replace(/@(context[\w\\.]+)/g, '{{$1}}');
  }

  constructor(props) {
    super(props);
    this.createEmailFromActivityOriginAction = React.createRef();
  }

  componentWillMount() {
    this.props.actions.getMailTemplates();
    this.props.actions.getMailSenderName();
    // action from rule will be always with 0, from one time with actual filtered members (always more than o)
    const membersCount = this.props.membersCount ? this.props.membersCount : 0;
    this.props.onActionFieldUpdate(schemaConstants.SEND_MEMBER_EMAIL_SELECTED_MEMBERS_COUNT, membersCount, this.props.index);
    this.props.onActionFieldUpdate(schemaConstants.SEND_MEMBER_EMAIL_RULE_NAME, membersCount, this.props.index);
  }

  componentDidUpdate() {
    if (this.createEmailFromActivityOriginAction && this.createEmailFromActivityOriginAction.current) {
      this.createEmailFromActivityOriginAction.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  get isSelectedEmailNewlyCreated() {
    const { newlyCreatedEmail, selectedEmail } = this.props;
    return newlyCreatedEmail && selectedEmail && newlyCreatedEmail.get(constants.EXTERNAL_ID) === selectedEmail.get(constants.EXTERNAL_ID);
  }

  createEmailFromActivity = (index) => {
    this.props.createEmailFromActivity(index, this.props.actionsWrapperIndex);
  };

  render() {
    const { emailsList, onActionFieldUpdate, index, action, mailSenderName, validationErrors,
      automationIdPrefix, mentionsList, loadingMailTemplates, loadingMailSenderName } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    const selectedEmail = this.props.selectedEmail;
    return (
      <div ref={this.isSelectedEmailNewlyCreated ? this.createEmailFromActivityOriginAction : null}>
        <div>
          <Grid className="action sendEmail">
            <Grid.Row>
              <Grid.Column width={7}>
                <p className="action-label">{formatMessage({ id: 'activity.action.email.chooseTemplate' })}</p>
                <EmailsList
                  loadingData={loadingMailTemplates}
                  emailsList={emailsList.filter((mail) => mail.get(constants.STATUS) === constants.ACTIVE)}
                  onActionFieldUpdate={onActionFieldUpdate}
                  action={action}
                  index={index}
                  automationIdPrefix={automationIdPrefix}
                  error={validationErrors ? validationErrors.get(constants.EXTERNAL_ID) : null}
                  selectedEmail={selectedEmail}
                  createEmailFromActivity={this.createEmailFromActivity}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={7}>
                <p className="action-label">{formatMessage({ id: 'activity.action.email.subject' })}</p>
                <MentionTextArea
                  disabled
                  hideTips
                  singleLine
                  automationIdPrefix={automationIdPrefix}
                  mentionOptions={mentionsList}
                  message={selectedEmail ? selectedEmail.get(constants.SUBJECT) : ''}
                  onTextUpdate={(newMessage) => {}}
                  index={index}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={7}>
                <p className="action-label">{formatMessage({ id: 'activity.action.email.from' })}</p>
                <Input disabled fluid value={loadingMailSenderName ? '' : mailSenderName} />
              </Grid.Column>
            </Grid.Row>
            <p className="email-note action-label">
              {formatMessage({ id: 'activity.action.email.additional_charges_warning' })}
            </p>
          </Grid>
        </div>
        <br />
        <TestToMyself
          actionType={schemaConstants.SEND_MEMBER_EMAIL_ACTION}
          actionIndex={this.props.index}
          actionsWrapperIndex={this.props.actionsWrapperIndex}
          submitActionToCustomer={this.props.submitActionToCustomer}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  emailsList: appSelectors.getMailTemplatesMap(state),
  loadingMailTemplates: appSelectors.getMailTemplatesLoadingFlag(state),
  mailSenderName: appSelectors.getMailSenderName(state),
  loadingMailSenderName: appSelectors.getMailSenderNameLoadingFlag(state),
  mentionsList: mentionsSelectors.getEmailMentionsTransformed(state),
  membersCount: activitySelectors.getFilteredPopulationSize(state, true),
  newlyCreatedEmail: mailTemplatesSelectors.getNewlyCreatedEmail(state),
  selectedEmail: mailTemplatesSelectors.getSelectedEmail(state, props.action.get(constants.EXTERNAL_ID)),

});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(mailTemplatesActions, dispatch)
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: constants.MAIL_TEMPLATES, saga });

export default compose(withConnect, withSaga, injectIntl)(SendEmail);
