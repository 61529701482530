export const typesMapping = {
  singleCode: {
    name: 'fix'
  },
  generated: {
    name: 'generated'
  },
  imported: {
    name: 'imported'
  }
};

export const calculateCodesTotal = (bulks) => {
  let counter = 0;
  bulks.forEach((item) => {counter = counter + item.get('size')});

  return counter;
};
