import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Transition } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import * as activityConstants from '../../activity.constants';
import * as constants from './itemsPopulation.constants';
import { ITEM_CODE, ITEM_DEPARTMENT_CODE } from '../../activitySchema/activitySchema.constants';

import PopupFooter from '../../../../common/components/popupFooter';
import ItemList from '../../../../common/components/ItemList/itemList';
import GroupsList from './groupsList';
import AdvancedRules from './advancedRules';
import ItemsPopRadio from './itemsPopRadio';
import SaveAsGroup from './saveAsGroup';
import * as utils from './itemsPopulation.utils';

class ItemsPopulationContent extends React.PureComponent {

  static propTypes = {
    advancedRulesSchemaConditions: PropTypes.object.isRequired,
    animate: PropTypes.bool,
    automationIdPrefix: PropTypes.string.isRequired,
    className: PropTypes.string,
    conditionsRelations: PropTypes.string.isRequired,
    disableAny: PropTypes.bool,
    isEditGroupMode: PropTypes.bool.isRequired,
    initialSelectedGroups: PropTypes.array,
    itemGroups: PropTypes.object,
    itemsPopulationType: PropTypes.string.isRequired,
    itemsPopulationConditions: PropTypes.array.isRequired,
    onAddCondition: PropTypes.func.isRequired,
    onApply: PropTypes.func,
    onCancel: PropTypes.func,
    onClearAllInType: PropTypes.func.isRequired,
    onDeleteCondition: PropTypes.func.isRequired,
    onDeleteGroup: PropTypes.func,
    onSaveAsGroup: PropTypes.func,
    updateConditionField: PropTypes.func.isRequired,
    updateWholeCondition: PropTypes.func.isRequired,
    updateConditionsRelations: PropTypes.func.isRequired,
    onUpdateItemsGroup: PropTypes.func,
    onUpdateType: PropTypes.func.isRequired,
    title: PropTypes.string,
    errors: PropTypes.object,
    intl: PropTypes.object.isRequired,
    getPermissionLevel: PropTypes.func.isRequired,
    posFeature: PropTypes.string.isRequired,
    punchCardDefaultOptionLabel: PropTypes.object,
    getBusinessBundlePlan: PropTypes.func.isRequired,
    isSimpleMode: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.getItemsTemplate = this.getItemsTemplate.bind(this);
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const options = utils.getItemsPopulationContentOptions(
        formatMessage, this.props.posFeature, this.props.disableAny, this.props.punchCardDefaultOptionLabel);
    this.basicItemsPopulationOptions = options.basicItemsPopulationOptions;
    this.allItemsPopulationOptions = options.allItemsPopulationOptions;
  }


  /**
   * return the relevant component template according to the selected radio button
   * @param {string} type
   * @param {array} itemsPopulationConditions
   * @returns {null}
   */
  getItemsTemplate = (type, itemsPopulationConditions) => {
    let itemsTemplate = null;
    switch (type) {
      case constants.ITEMS_POPULATION_TYPE_ITEM_CODES:
        itemsTemplate = (<ItemList
          items={itemsPopulationConditions.length ? itemsPopulationConditions[0][activityConstants.CONDITION_VALUE] : ['']}
          onChange={(data) => this.props.updateWholeCondition(
            type, 0, ITEM_CODE, activityConstants.IS_ONE_OF_CONDITION_OPERATOR, data
          )}
        />);
        break;
      case constants.ITEMS_POPULATION_TYPE_DEPARTMENT_CODES:
        itemsTemplate = (<ItemList
          items={itemsPopulationConditions.length ? itemsPopulationConditions[0][activityConstants.CONDITION_VALUE] : ['']}
          onChange={(data) => this.props.updateWholeCondition(
            type, 0, ITEM_DEPARTMENT_CODE, activityConstants.IS_ONE_OF_CONDITION_OPERATOR, data
          )}
        />);
        break;
      case constants.ITEMS_POPULATION_TYPE_ITEM_GROUPS:
        itemsTemplate = (<GroupsList
          advancedRulesSchemaConditions={this.props.advancedRulesSchemaConditions}
          allItemGroups={this.props.itemGroups}
          selectedItemGroups={itemsPopulationConditions.length ? itemsPopulationConditions[0][activityConstants.CONDITION_VALUE] : []}
          initialSelectedItemGroups={this.props.initialSelectedGroups}
          onChange={(data) => this.props.updateWholeCondition(
            type, 0, constants.ITEMS_POPULATION_TYPE_ITEM_GROUPS, activityConstants.IS_ONE_OF_CONDITION_OPERATOR, data
          )}
          onDeleteGroup={this.props.onDeleteGroup}
          onUpdateItemsGroup={this.props.onUpdateItemsGroup}
          posFeature={this.props.posFeature}
        />);
        break;
      case constants.ITEMS_POPULATION_TYPE_ADVANCED_RULES:
        itemsTemplate = (<AdvancedRules
          conditions={itemsPopulationConditions}
          conditionsRelations={this.props.conditionsRelations}
          advancedRulesSchemaConditions={this.props.advancedRulesSchemaConditions}
          updateConditionsRelations={this.props.updateConditionsRelations}
          onAddCondition={this.props.onAddCondition}
          onDeleteCondition={this.props.onDeleteCondition}
          updateConditionField={(key, value, index) => this.props.updateConditionField(type, index, key, value)}
          getBusinessBundlePlan={this.props.getBusinessBundlePlan}
        />);
        break;
      default:
        break;
    }
    return itemsTemplate
      ? React.cloneElement(itemsTemplate, {
        automationIdPrefix: `${this.props.automationIdPrefix}.${type}`,
        type,
        errors: this.props.errors ? this.props.errors.get(type) : null
      })
      : null;
  };

  getRadioValue(opt) {
    if (opt.type === constants.ITEMS_POPULATION_TYPE_PUNCH_CARD_DEFAULT_DISCOUNT) {
      return opt.type;
    }
    return opt.label;
  }

  /**
   * return if the user has permission to a list of permissions
   * @param features
   * @returns {boolean}
   */
  hasFullPermissionOfAllFeatures = (features) => features.every((feature) =>
    this.props.getPermissionLevel(feature.name) === activityConstants.FEATURE_LEVEL.FULL);


  render() {

    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const { itemsPopulationType, itemsPopulationConditions } = this.props;
    const itemsPopulationOptions = this.props.isEditGroupMode ? this.basicItemsPopulationOptions : this.allItemsPopulationOptions;
    const accordionClass = classNames(this.props.className, {
      'edit-group-accordion': this.props.isEditGroupMode,
      'modal-with-title': this.props.title
    });

    return (
      <Transition
        animation="shake"
        duration={200}
        visible={this.props.animate}
        transitionOnMount={false}
        mountOnShow={false}
      >
        <div>
          {this.props.title && (<div className="edit-group-modal-header">{this.props.title}</div>)}
          <Accordion className={accordionClass}>
            {
              itemsPopulationOptions.map((opt, index) => {
                const hasFeature = opt.features ? this.hasFullPermissionOfAllFeatures(opt.features) : true;
                const disableOption = this.props.isSimpleMode && ['itemsGroup', 'advancedRules'].includes(opt.type);
                const isActive = itemsPopulationType === opt.type && hasFeature;
                const radioValue = this.getRadioValue(opt);
                return (
                  <div key={opt.label}>
                    <Accordion.Title
                      active={isActive}
                      index={index}
                      onClick={() => {
                        if (!hasFeature && opt.type !== constants.ITEMS_POPULATION_TYPE_ANY) {
                          return null;
                        }
                        return this.props.onUpdateType(opt.type);
                      }}
                      data-automation-id={`${this.props.automationIdPrefix}.${opt.type}`}
                    >
                      <ItemsPopRadio
                        intl={this.props.intl}
                        label={opt.label}
                        name="itemsPopulationOptions"
                        value={radioValue}
                        note={opt.note}
                        checked={isActive}
                        disabled={!hasFeature || disableOption}
                        overrideToolTipMessage={disableOption ? 'simpleMode.conflict' : undefined}
                      />
                      {
                        (opt.type === constants.ITEMS_POPULATION_TYPE_ITEM_CODES || opt.type === constants.ITEMS_POPULATION_TYPE_DEPARTMENT_CODES || opt.type === constants.ITEMS_POPULATION_TYPE_ADVANCED_RULES) && isActive
                          ? (
                            <div className="accordion-buttons">
                              {
                                !this.props.isEditGroupMode
                                  ? (
                                    <SaveAsGroup
                                      automationIdPrefix={`${this.props.automationIdPrefix}`}
                                      groupsCount={this.props.itemGroups ? this.props.itemGroups.size : 0}
                                      type={this.props.itemsPopulationType}
                                      onSaveAsGroup={this.props.onSaveAsGroup}
                                    />
                                  )
                                  : null
                              }
                              <a
                                role="button"
                                className="accordion-button"
                                onClick={() => this.props.onClearAllInType(opt.type)}
                                data-automation-id={`${this.props.automationIdPrefix}.${opt.type}.clearAll`}
                              >
                                {formatMessage({ id: 'activity.itemsPopulation.clear', defaultMessage: 'Clear' })}
                              </a>
                            </div>
                          )
                          : null
                      }
                    </Accordion.Title>
                    {
                      opt.type !== '' && opt.type !== constants.ITEMS_POPULATION_TYPE_ANY && isActive
                        ? (
                          <Accordion.Content active={isActive}>
                            {this.getItemsTemplate(opt.type, itemsPopulationConditions)}
                          </Accordion.Content>
                        )
                        : null
                    }
                  </div>
                );
              })
            }
          </Accordion>
          {
            !this.props.isEditGroupMode
              ? (
                <PopupFooter
                  automationIdPrefix={this.props.automationIdPrefix}
                  onApply={this.props.onApply}
                  onCancel={this.props.onCancel}
                />
              )
              : null
          }
        </div>
      </Transition>
    );
  }
}

export default injectIntl(ItemsPopulationContent);
