import moment from 'moment';
import {List} from 'immutable';
import * as _ from 'lodash';
import * as itemPopulationConstants from '../../../views/itemsPopulation/itemsPopulation.constants';
import * as constants from '../../../activity.constants';
import * as schemaConstants from '../../../activitySchema/activitySchema.constants';
import * as datesConstants from '../../../../../common/utils/dateTime.constants';
import * as updateMembershipConstants
  from '../../../views/conditions/updateMembershipCondition/updateMembership.constants';
import * as formsSelectors from '../../../../Forms/forms.selectors';

export function validateItemsPopulationData(itemsPopulation) {
  if (!itemsPopulation) {
    return null;
  }
  const populationType = itemsPopulation.get(itemPopulationConstants.ITEMS_POPULATION_TYPE);
  const itemPopulationConditions = itemsPopulation.getIn([constants.CONDITIONS, constants.CONDITIONS_LIST]);
  const validationErrors = validateItemPopulationConditions(itemPopulationConditions, populationType);
  if (Object.keys(validationErrors).length > 0) {
    return {[populationType]: validationErrors};
  }
  return null;
}

export function validateItemPopulationConditions(itemPopulationConditions, populationType) {
  const conditionValue = itemPopulationConditions.getIn([0, constants.CONDITION_VALUE]);
  if (itemPopulationConditions.isEmpty() &&
    populationType !== itemPopulationConstants.ITEMS_POPULATION_TYPE_ADVANCED_RULES &&
    populationType !== itemPopulationConstants.ITEMS_POPULATION_TYPE_ANY) {
    return `activity.validation.error.item.population.no.${populationType}`;
  } else if (conditionValue && typeof conditionValue === 'object' && [constants.IS_ONE_OF, constants.IS_NOT_ONE_OF].includes(itemPopulationConditions.getIn([0, constants.OPERATOR_KEY]))) {
    const hasEmptyItems = conditionValue.filter((item) => item !== '' && item.split(' ').join('') === '');
    if (hasEmptyItems.size || hasEmptyItems.length) {
      return 'activity.validation.error.condition.value.no.empty.items';
    }
  }
  return validateConditionsList(itemPopulationConditions, true);
}

export function validateUpdateMembershipCondition(updateMembershipCondition) {
  const validationErrors = {};
  if (![schemaConstants.OPERATORS_IS_ANY_VALUE, schemaConstants.OPERATORS_IS_EMPTY].includes(updateMembershipCondition.getIn([updateMembershipConstants.UPDATE_MEMBERSHIP_FROM, constants.OPERATOR_KEY]))) {
    const innerConditionValue = updateMembershipCondition.getIn([updateMembershipConstants.UPDATE_MEMBERSHIP_FROM, constants.CONDITION_VALUE]);
    if (innerConditionValue === null || typeof innerConditionValue === 'undefined' || (List.isList(innerConditionValue) && !innerConditionValue.size)) {
      validationErrors[updateMembershipConstants.UPDATE_MEMBERSHIP_FROM] = {[constants.CONDITION_VALUE]: 'activity.validation.error.condition.value.required'};
    }
  }
  if (![schemaConstants.OPERATORS_IS_ANY_VALUE, schemaConstants.OPERATORS_IS_EMPTY].includes(updateMembershipCondition.getIn([updateMembershipConstants.UPDATE_MEMBERSHIP_TO, constants.OPERATOR_KEY]))) {
    const innerConditionValue = updateMembershipCondition.getIn([updateMembershipConstants.UPDATE_MEMBERSHIP_TO, constants.CONDITION_VALUE]);
    if (innerConditionValue === null || typeof innerConditionValue === 'undefined' || (List.isList(innerConditionValue) && !innerConditionValue.size)) {
      validationErrors[updateMembershipConstants.UPDATE_MEMBERSHIP_TO] = {[constants.CONDITION_VALUE]: 'activity.validation.error.condition.value.required'};
    }
  }
  return validationErrors;
}

export function validateConditionsList(conditionsList, shouldValidateConditionKey) {
  const conditionsErrors = {};
  if (!conditionsList) {
    return conditionsErrors;
  }
  conditionsList.forEach((condition, index) => {
    const conditionErrors = validateCondition(condition, shouldValidateConditionKey);
    if (Object.keys(conditionErrors).length > 0) {
      conditionsErrors[index] = conditionErrors;
    }
  });
  return conditionsErrors;
}

function conditionValueIsRequired(condition) {
  return condition.get(constants.OPERATOR_KEY) !== schemaConstants.OPERATOR_KEY_CONTAINS_NONE;
}

export function validateCondition(condition, shouldValidateConditionKey) {
  const conditionErrors = {};
  if (_.isNil(condition.get(constants.CONDITION_KEY)) || condition.get(constants.CONDITION_KEY) === '') {
    if (shouldValidateConditionKey) {
      conditionErrors[constants.CONDITION_KEY] = 'activity.validation.error.condition.key.required';
    }
    return conditionErrors;
  }
  const conditionValue = condition.get(constants.CONDITION_VALUE);

  if (condition.get(constants.CONDITION_KEY) === schemaConstants.CONTEXT_REFERRAL_DATA) {
    if (!condition.getIn([constants.CONDITION_VALUE, constants.CONDITION_VALUE_REFERRAL_DATA_TYPE])) {
      conditionErrors[constants.CONDITION_VALUE] = 'activity.validation.error.condition.value.required';
    }
  } else if (conditionValueIsRequired(condition)) {

    const conditionValueIsEmpty =
      _.isNil(conditionValue)
      || conditionValue === ''
      || (Array.isArray(conditionValue) && conditionValue.length === 0)
      || (List.isList(conditionValue) && conditionValue.size === 0);

    const isListCondition =
      condition.get(constants.OPERATOR_KEY) === constants.IS_ONE_OF
      || condition.get(constants.OPERATOR_KEY) === constants.IS_NOT_ONE_OF;

    const isNumericCondition =
      condition.get(constants.VALUE_TYPE) === schemaConstants.VALUE_TYPE_NUMBER
      || condition.get(constants.CONDITION_VALUE_DATA_TYPE) === schemaConstants.DATA_VALUE_TYPE_INTEGER;

    const isListValue = Array.isArray(conditionValue) || List.isList(conditionValue);

    const isInvalidListValue = isListCondition && !isListValue;

    const isPercentageIntegerCondition = condition.get(constants.CONDITION_KEY) === schemaConstants.PROBABILITY;

    const numericConditionValueIsInvalid =
      isNumericCondition &&
      ((!isListCondition && isNaN(conditionValue))
        || (isListCondition && _.isFunction(conditionValue.some) && conditionValue.some((value) => isNaN(value))));

    const percentageConditionIsInvalid = isPercentageIntegerCondition && (isNaN(conditionValue) || conditionValue < 0 || conditionValue > 100);
    const invalidConditionValue = conditionValueIsEmpty || numericConditionValueIsInvalid || isInvalidListValue;

    if (percentageConditionIsInvalid) {
      conditionErrors[constants.CONDITION_VALUE] = 'activity.validation.error.condition.value.percentage.range';
    }
    if (invalidConditionValue) {
      conditionErrors[constants.CONDITION_VALUE] = 'activity.validation.error.condition.value.required';
    } else if (
      (condition.get(constants.CONDITION_KEY) === schemaConstants.CONTEXT_SHOPPING_CART_TOTAL_QUANTITY ||
        condition.get(constants.CONDITION_KEY) === constants.QUANTITY ||
        condition.get(constants.CONDITION_KEY) === constants.SPEND) && conditionValue < 1) {
      conditionErrors[constants.CONDITION_VALUE] = 'activity.validation.error.condition.value.must.be.positive';
    }
  } else if (condition.get(constants.CONDITION_KEY) === schemaConstants.CONTEXT_TIMESTAMP &&
    condition.get(constants.OPERATOR_KEY) === datesConstants.DATE_TIME_IS_BETWEEN) {
    const dateFromString = condition.getIn([constants.CONDITION_VALUE, datesConstants.DATE_FROM]);
    const dateFrom = moment(dateFromString);
    const dateToString = condition.getIn([constants.CONDITION_VALUE, datesConstants.DATE_TO]);
    const dateTo = moment(dateToString);
    if (!dateFrom.isValid() || !dateTo.isValid() || !dateFrom.isBefore(dateTo)) {
      conditionErrors[constants.CONDITION_VALUE] = 'activity.validation.error.condition.value.dateTime.endHourAfterStart';
    }
  } else if (condition.get(constants.CONDITION_KEY) === schemaConstants.CONTEXT_TIMESTAMP_DAYS) {
    const errors = {};
    conditionValue.forEach((daysCondition, index) => {
      if (daysCondition.get(datesConstants.DAYS).size === 0) {
        errors[String(index)] = 'activity.validation.error.condition.value.daysTimes.daysNotEmpty';
      } else {
        const timeFromString = daysCondition.getIn([datesConstants.TIME_RANGE, datesConstants.TIME_FROM]);
        const timeFrom = moment(timeFromString, datesConstants.HH_MM_SS_FORMAT);
        const timeToString = daysCondition.getIn([datesConstants.TIME_RANGE, datesConstants.TIME_TO]);
        const timeTo = moment(timeToString, datesConstants.HH_MM_SS_FORMAT);
        if (!timeFrom.isValid() || !timeTo.isValid() || !timeFrom.isBefore(timeTo)) {
          errors[String(index)] = 'activity.validation.error.condition.value.dateTime.endHourAfterStart';
        }
      }
    });
    if (Object.keys(errors).length > 0) {
      conditionErrors[constants.CONDITION_VALUE] = errors;
    }
  } else if (condition.get(constants.OPERATOR_KEY) === constants.IS_ONE_OF_CONDITION_OPERATOR && _.isEmpty(conditionValue)) {
    conditionErrors[constants.CONDITION_VALUE] = 'activity.validation.error.condition.value.required';
  } else if (condition.get(constants.OPERATOR_KEY) === schemaConstants.OPERATORS_UPDATE_MEMBERSHIP) {
    // update membership validation
    const errors = {};
    if (![schemaConstants.OPERATORS_IS_ANY_VALUE, schemaConstants.OPERATORS_IS_EMPTY].includes(condition.getIn([constants.CONDITION_VALUE, updateMembershipConstants.UPDATE_MEMBERSHIP_FROM, constants.OPERATOR_KEY]))) {
      const innerConditionValue = condition.getIn([constants.CONDITION_VALUE, updateMembershipConstants.UPDATE_MEMBERSHIP_FROM, constants.CONDITION_VALUE]);
      if (innerConditionValue === null || typeof innerConditionValue === 'undefined' || (List.isList(innerConditionValue) && !innerConditionValue.size)) {
        errors[updateMembershipConstants.UPDATE_MEMBERSHIP_FROM] = {[constants.CONDITION_VALUE]: 'activity.validation.error.condition.value.required'};
      }
    }
    if (![schemaConstants.OPERATORS_IS_ANY_VALUE, schemaConstants.OPERATORS_IS_EMPTY].includes(condition.getIn([constants.CONDITION_VALUE, updateMembershipConstants.UPDATE_MEMBERSHIP_TO, constants.OPERATOR_KEY]))) {
      const innerConditionValue = condition.getIn([constants.CONDITION_VALUE, updateMembershipConstants.UPDATE_MEMBERSHIP_TO, constants.CONDITION_VALUE]);
      if (innerConditionValue === null || typeof innerConditionValue === 'undefined' || (List.isList(innerConditionValue) && !innerConditionValue.size)) {
        errors[updateMembershipConstants.UPDATE_MEMBERSHIP_TO] = {[constants.CONDITION_VALUE]: 'activity.validation.error.condition.value.required'};
      }
    }
    if (Object.keys(errors).length > 0) {
      conditionErrors[constants.CONDITION_VALUE] = errors;
    }
  }

  // For shopping cart conditions, validate the item population data
  const itemsPopulations = condition.get(constants.ITEMS_POPULATION);
  if (itemsPopulations) {
    const itemsPopulationErrors = validateItemsPopulationData(itemsPopulations);
    if (!_.isEmpty(itemsPopulationErrors)) {
      conditionErrors[constants.ITEMS_POPULATION] = itemsPopulationErrors;
    }
  }
  return conditionErrors;
}

export function validateRegistrationFormFieldsInConditions(conditionsList, rootState, schema, trigger) {
  const errors = {};
  const regFormFields = formsSelectors.getRegistrationFormFields(rootState);
  const regFormFieldNames = regFormFields && regFormFields.map((field) => field.get(constants.NAME));
  if (regFormFieldNames) {
    const allRegistrationFormFieldNames = schema
      .getIn([constants.GLOBAL_CONDITIONS, constants.MEMBERSHIP_GLOBAL_CONDITIONS, trigger, constants.CONDITIONS_MEMBERSHIP_BY_REG_FORM_CATEGORY])
      .map((condition) => condition.get(constants.GLOBAL_CONDITION_KEY));
    const possiblyRemovedFromRegistrationForm1 = conditionsList
      .map((condition) => condition.get(constants.CONDITION_KEY));
    const possiblyRemovedFromRegistrationForm = possiblyRemovedFromRegistrationForm1.filter((conditionKey) => conditionKey !== schemaConstants.CONTEXT_MEMBERSHIP_STATUS &&
      allRegistrationFormFieldNames.includes(conditionKey));
    possiblyRemovedFromRegistrationForm.forEach((conditionKey, index) => {
      const conditionName = conditionKey.split(constants.MEMBERSHIP_GLOBAL_CONDITION_PREFIX)[1];
      if (conditionKey === schemaConstants.CONTEXT_MEMBERSHIP_ANNIVERSARY_MONTH) {
        const originalConditionName = schemaConstants.CONTEXT_MEMBERSHIP_ANNIVERSARY.split(constants.MEMBERSHIP_GLOBAL_CONDITION_PREFIX)[1];
        if (!regFormFieldNames.includes(originalConditionName)) {
          errors[index.toString()] = {[constants.CONDITION_KEY]: 'activity.globalConditions.membership.attribute.deleted'};
        }
      } else if (conditionKey === schemaConstants.CONTEXT_MEMBERSHIP_BIRTHDAY_MONTH || conditionKey === schemaConstants.CONTEXT_MEMBERSHIP_BIRTHDAY_MONTH_AND_DAY) {
        const originalConditionName = schemaConstants.CONTEXT_MEMBERSHIP_BIRTHDAY.split(constants.MEMBERSHIP_GLOBAL_CONDITION_PREFIX)[1];
        if (!regFormFieldNames.includes(originalConditionName)) {
          errors[index.toString()] = {[constants.CONDITION_KEY]: 'activity.globalConditions.membership.attribute.deleted'};
        }
      } else if (!regFormFieldNames.includes(conditionName)) {
        errors[index.toString()] = {[constants.CONDITION_KEY]: 'activity.globalConditions.membership.attribute.deleted'};
      }
    });
  }
  return errors;
}

export function validateSchemaFormFieldsInConditions(conditionsList, schema, trigger) {
  const errors = {};
  const schemaConditionKeys = schema
    .getIn([constants.GLOBAL_CONDITIONS, constants.MEMBERSHIP_GLOBAL_CONDITIONS, trigger, constants.CONDITIONS_MEMBERSHIP_ALWAYS_SHOW])
    .concat(schema.getIn([constants.GLOBAL_CONDITIONS, constants.MEMBERSHIP_GLOBAL_CONDITIONS, trigger, constants.CONDITIONS_MEMBERSHIP_BY_REG_FORM_CATEGORY]))
    .map((conditionSchema) => conditionSchema.get(constants.GLOBAL_CONDITION_KEY));
  const possiblyRemovedFromSchemaConditions1 = conditionsList
    .map((condition) => condition.get(constants.CONDITION_KEY));
  const possiblyRemovedFromSchemaConditions = possiblyRemovedFromSchemaConditions1.filter((conditionKey) => {
    if (conditionKey) {
      const ignoredConditions = [schemaConstants.CONTEXT_MEMBERSHIP_FIRST_NAME_CI, schemaConstants.CONTEXT_MEMBERSHIP_LAST_NAME_CI,
        schemaConstants.CONTEXT_MEMBERSHIP_KEY_STR, schemaConstants.MEMBERSHIP_KEYS_FILE];
      if (ignoredConditions.includes(conditionKey)) {
        return false;
      }
      return !schemaConditionKeys.includes(conditionKey);
    }
    return true;
  });
  possiblyRemovedFromSchemaConditions.forEach((conditionKey, index) => {
    if (conditionKey) {
      errors[index.toString()] = {[constants.CONDITION_KEY]: 'activity.globalConditions.membership.attribute.unsupported'};
    }
  });
  return errors;
}
