import { List, Map } from 'immutable';
import moment from 'moment';
import * as activityConstants from '../../Activity/activity.constants';
import * as dateTimeUtils from '../../../common/utils/dateTimeUtils';
import * as featuresConstants from '../../features.constants';
import * as constants from '../emails.constants';
import {calculateActivityViewStatus} from "../../Activity/activity.selectors.utils";

export function getFilteredAndSortedEmailsImpl(emails, filters, order, searchTerm) {

  const filtered = getFilteredEmails(emails, filters, searchTerm);
  const orderFactor = order === featuresConstants.ORDER_ASCENDING ? 1 : -1;
  return dateTimeUtils.sortArrayByDate(filtered, ['created', 'date'], orderFactor);
}

export function isEmailsFilteredImpl(emails, filteredEmails) {
  return emails.size !== filteredEmails.size;
}

export function getContextMenuItemsVisibilityMap(viewStatus) {
  const isActive = viewStatus === activityConstants.ACTIVITY_STATUS_ACTIVE
    || viewStatus === activityConstants.ACTIVITY_STATUS_FUTURE;
  const isPaused = viewStatus === activityConstants.ACTIVITY_STATUS_PAUSED;
  return {
    [activityConstants.CONTEXT_MENU_OPTION_DETAILS]: true,
    [activityConstants.CONTEXT_MENU_OPTION_ACTIVATE]: isPaused,
    [activityConstants.CONTEXT_MENU_OPTION_PAUSE]: isActive,
    [activityConstants.CONTEXT_MENU_OPTION_DEACTIVATE]: isPaused || isActive,
  };
}

function getFilteredEmails(emails, filters, searchTerm) {
  const searchTermLower = searchTerm && searchTerm.toLowerCase();
  const filterFlags = filters.get(constants.EMAILS);

  return emails.filter((email) => {

    const viewStatus = calculateActivityViewStatus(email);
    if ((viewStatus === activityConstants.ACTIVITY_STATUS_ACTIVE && !filterFlags.get(activityConstants.FILTERS_ACTIVITY_ACTIVE)) ||
      (viewStatus === activityConstants.ACTIVITY_STATUS_STOPPED && !filterFlags.get(activityConstants.FILTERS_ACTIVITY_STOPPED))) {
      return false;
    }
    return !searchTerm || email.get(activityConstants.NAME).toLowerCase().includes(searchTermLower);
  });
}

export function getEmailOneTimeStatsImpl(stats) {
  return stats ? stats.filter((statsElem) => statsElem.get(constants.EMAIL_STATS_TYPE) === constants.EMAIL_STATS_TYPE_ONE_TIME && statsElem.get(constants.EMAIL_STATS_ACTIVITY_NAME)) : null;
}

export function getEmailRuleStatsImpl(stats) {
  if (stats) {
    const ruleStats = stats.filter((statsElem) => statsElem.get(constants.EMAIL_STATS_TYPE) === constants.EMAIL_STATS_TYPE_RULE && statsElem.get(constants.EMAIL_STATS_ACTIVITY_NAME));
    if (ruleStats) {
      return groupByActivityId(ruleStats);
    }
  }
  return null;
}

export function getEmailMicroCampaignStatsImpl(stats) {
  if (stats) {
    const ruleStats = stats.filter((statsElem) => statsElem.get(constants.EMAIL_STATS_TYPE) === constants.EMAIL_STATS_TYPE_MICROCAMPAIGN && statsElem.get(constants.EMAIL_STATS_ACTIVITY_NAME));
    if (ruleStats) {
      return groupByActivityId(ruleStats);
    }
  }
  return null;
}

export function groupByActivityId(ruleStats) {
  const activityIds = [];
  let stats = new List();
  ruleStats.forEach((item) => {
    if (!activityIds.includes(item.get(constants.EMAIL_STATS_ACTIVITY_ID))) {
      activityIds.push(item.get(constants.EMAIL_STATS_ACTIVITY_ID));
      let stat = Map();
      let campaigns = new List();
      campaigns = campaigns.push(item.get(constants.EMAIL_CAMPAIGN_ID));
      stat = stat
        .set(constants.EMAIL_STATS_ACTIVITY_ID, item.get(constants.EMAIL_STATS_ACTIVITY_ID))
        .set(constants.EMAIL_STATS_ACTIVITY_NAME, item.get(constants.EMAIL_STATS_ACTIVITY_NAME))
        .set(constants.EMAIL_STATS_CAMPAIGNS, campaigns);
      stats = stats.push(stat);
    } else {
      const statsIndex = stats.findIndex((statsElem) => statsElem.get(constants.EMAIL_STATS_ACTIVITY_ID) === item.get(constants.EMAIL_STATS_ACTIVITY_ID));
      let stat = stats.find((statsElem) => statsElem.get(constants.EMAIL_STATS_ACTIVITY_ID) === item.get(constants.EMAIL_STATS_ACTIVITY_ID));
      stat = stat.update(constants.EMAIL_STATS_CAMPAIGNS, (arr) => arr.push(item.get(constants.EMAIL_CAMPAIGN_ID)));
      stats = stats.set(statsIndex, stat);
    }
  });
  return stats;
}

export function getLastSentImpl(stats) {
  if (stats && stats.size > 0) {
    const dates = [];
    stats.forEach((item) => {
      dates.push(item.get(constants.EMAIL_STATS_DATE));
    });
    const maxDate = dateTimeUtils.getMaxDate(dates);
    return stats.find((elem) => moment.utc(elem.get(constants.EMAIL_STATS_DATE)).isSame(maxDate));
  }
  return null;
}
