 /*
 *
 * DataAndBi actions
 *
 */

import * as constants from './dataAndBi.constants';

export function getConfig() {
  return {
    type: constants.GET_CONFIG,
  };
}

export function getFiles(queryName) {
  return {
    type: constants.GET_FILES_DATA,
    payload: {
      queryName
    }
  };
}

export function getSignedUrl(objectName) {
  return {
    type: constants.GET_SIGNED_URL,
    payload: {
      objectName
    }
  };
}

export function activateExport() {
  return {
    type: constants.ACTIVATE,
  };
}
