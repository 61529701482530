/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { List } from 'immutable';
import { connect } from 'react-redux';
import ConditionListOfValues from './conditionListOfValues';
import * as schemaConstants from '../../activitySchema/activitySchema.constants';
import * as appSelectors from '../../../App/selectors';
import * as textUtils from '../../../../utils/textFormat';

const LOCATION_NAME = 'location_name';

class LocationsList extends React.PureComponent {

  static propTypes = {
    disabled: PropTypes.bool,
    index: PropTypes.number,
    value: PropTypes.array,
    onChange: PropTypes.func,
    prefix: PropTypes.string,
    error: PropTypes.string,
    isSearchable: PropTypes.bool,
    locations: PropTypes.object,
    conditionType: PropTypes.string
  };

  getTag() {
    return this.props.conditionType === schemaConstants.CONTEXT_MEMBERSHIP_PREFERRED_LOCATION
      ? schemaConstants.LOCATIONS_TAG_PREFERRED
      : schemaConstants.LOCATIONS_TAG_SECOND_PREFERRED;
  }

  get locationsOptions() {

    const locations = this.props.locations || List();
    const result = locations.map((location) => {
      const locationName = location.get(LOCATION_NAME);
      const value = schemaConstants.LOCATIONS_TAG_BORDER
        + this.getTag() + textUtils.removeDataUnsupportedCharacters(locationName)
        + schemaConstants.LOCATIONS_TAG_BORDER;
      return {
        value,
        label: locationName
      };
    });
    return result.toJS();
  }

  render() {

    return (<ConditionListOfValues
      {...this.props}
      type={schemaConstants.VALUE_TYPE_LOCATIONS}
      dynamicOptions={this.locationsOptions}
      multiple
    />);
  }
}

const mapStateToProps = (state) => ({
  locations: appSelectors.getLocations(state)
});

const withConnect = connect(mapStateToProps, null);

export default compose(
  withConnect,
)(LocationsList);
