/**
 *
 * ItemsPopulation
 * popup with itemsPopulation conditions
 * divided to types - any, itemCodes, departmentCodes, itemGroups and advancedRules
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { fromJS, Map } from 'immutable';

import injectSaga from '../../../../utils/injectSaga';
import * as itemGroupsActions from '../../../ItemGroups/itemGroups.actions';
import * as itemsPopulationActions from './itemsPopulation.actions';
import saga from '../../../ItemGroups/itemGroups.saga';
import * as itemGroupsConstants from '../../../ItemGroups/itemGroups.constants';
import * as appConstants from '../../../App/app.constants';
import * as activityConstants from '../../activity.constants';
import * as itemsPopulationConstants from './itemsPopulation.constants';
import './itemsPopulation.styles.less';
import PopupWrapper from '../../../../common/components/PopupWrapper/PopupWrapper';
import ItemsPopulationContent from './itemsPopulationContent';
import withAuthorization from '../../../../common/helpers/authorization';
import PopupTrigger from '../../../../common/components/PopupTrigger/PopupTrigger';
import * as activitySelectors from '../../activity.selectors';
import * as appSelectors from '../../../App/selectors';
import * as itemGroupsSelectors from '../../../ItemGroups/itemGroups.selectors';
import * as itemsPopulationSelectors from './itemsPopulation.selectors';
import * as utils from './itemsPopulation.utils';
import * as defaults from './itemsPopulation.defaults';
import { removeNewLineCharacters } from '../../../../utils/textFormat';

export class ItemsPopulation extends React.PureComponent {

  static propTypes = {
    actions: PropTypes.object.isRequired,
    advancedRulesSchemaConditions: PropTypes.object.isRequired,
    automationIdPrefix: PropTypes.string,
    className: PropTypes.string,
    data: PropTypes.object.isRequired,
    disableAny: PropTypes.bool,
    index: PropTypes.number.isRequired,
    itemGroups: PropTypes.object,
    itemGroupsFetched: PropTypes.bool,
    // eslint-disable-next-line react/no-unused-prop-types
    isMembersFilter: PropTypes.bool, // used in mapStateToProps
    locationId: PropTypes.number.isRequired,
    position: PropTypes.string,
    tempItemsPopulation: PropTypes.object,
    onUpdate: PropTypes.func.isRequired,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    tempItemsPopulationValidationErrors: PropTypes.object,
    itemsPopulationValidationErrors: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    features: PropTypes.object.isRequired,
    intl: PropTypes.object,
    getPermissionLevel: PropTypes.func.isRequired,
    posFeature: PropTypes.string.isRequired,
    punchCardDefaultOptionLabel: PropTypes.object,
    getBusinessBundlePlan: PropTypes.func.isRequired,
    isSimpleMode: PropTypes.func.bool,
  };

  static defaultProps = {
    disabled: false,
    title: null
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      [itemsPopulationConstants.IS_POPUP_OPEN]: false,
      [itemsPopulationConstants.IS_PRISTINE]: true,
      [itemsPopulationConstants.TOGGLE_ANIMATION]: true,
      [itemsPopulationConstants.STATE_VALUE]: Map.isMap(props.data) ? props.data : fromJS(props.data),
    };
    this.onApply = this.onApply.bind(this);
    this.onSaveAsGroup = this.onSaveAsGroup.bind(this);
    this.onPopupOpen = this.onPopupOpen.bind(this);
    this.onPopupClose = this.onPopupClose.bind(this);
    this.setBackgroundClicks = this.setBackgroundClicks.bind(this);
  }

  componentWillMount() {
    if (!this.props.itemGroupsFetched) {
      this.props.actions.getItemGroups();
    }
  }

  componentWillReceiveProps(nextProps) {
    // set pristine state to false when store data has changed
    const newState = {};
    if (this.props.tempItemsPopulation && nextProps.tempItemsPopulation
        && this.props.tempItemsPopulation !== nextProps.tempItemsPopulation) {
      newState[itemsPopulationConstants.IS_PRISTINE] = false;
    }
    if (this.props.data !== nextProps.data) {
      newState[itemsPopulationConstants.STATE_VALUE] = Map.isMap(nextProps.data) ? nextProps.data : fromJS(nextProps.data);
    }
    this.setState(newState);
  }

  componentWillUnmount() {
    this.setBackgroundClicks(true);
  }

  /**
   * call callback function to save itemsPopulation data in store
   */
  onApply = () => {
    const itemsPopulationType = this.props.tempItemsPopulation.get(activityConstants.TYPE);
    const itemsPopulationValue = this.props.tempItemsPopulation.getIn([activityConstants.ITEMS_POPULATION, itemsPopulationType]);


    if (itemsPopulationType === itemsPopulationConstants.ITEMS_POPULATION_TYPE_DEPARTMENT_CODES) {
      const newConditionsList = itemsPopulationValue.getIn([activityConstants.CONDITIONS, activityConstants.CONDITIONS_LIST]).map((condition) => {
        const newConditionValue = condition.get(activityConstants.CONDITION_VALUE).map((value) => removeNewLineCharacters(value));
        return condition.set(activityConstants.CONDITION_VALUE, newConditionValue);
      });

      const newItemsPopulationValue = itemsPopulationValue.setIn([activityConstants.CONDITIONS, activityConstants.CONDITIONS_LIST], newConditionsList);
      this.props.onUpdate(this.props.index, newItemsPopulationValue);
    } else {
      this.props.onUpdate(this.props.index, this.props.tempItemsPopulation.getIn([activityConstants.ITEMS_POPULATION, itemsPopulationType]));
    }

    this.onPopupClose();
  };

  /**
   * handler for clicking the cancel button
   * closes the itemsPopulation popup window
   */
  onCancel = () => {
    this.onPopupClose();
  };

  /**
   * handler for saving selected itemcodes/departmentCodes/advancedRules as a group
   */
  onSaveAsGroup = (groupName) => {
    const groupType = this.props.tempItemsPopulation.get(activityConstants.TYPE);
    const itemsGroup = defaults.emptyItemsGroup
      .set(appConstants.LOCATION_ID, this.props.locationId)
      .set(activityConstants.NAME, groupName)
      .set(activityConstants.TYPE, groupType)
      .set(activityConstants.STATUS, activityConstants.ACTIVITY_STATUS_ACTIVE)
      .set(activityConstants.CONDITIONS,
        this.props.tempItemsPopulation.getIn([activityConstants.ITEMS_POPULATION, groupType, activityConstants.CONDITIONS]));
    this.props.actions.createItemsGroup(itemsGroup);
  };

  /**
   * callback function for opening the popup window
   */
  onPopupOpen = () => {
    if (this.props.disabled) {
      return;
    }
    let value = this.state[itemsPopulationConstants.STATE_VALUE];
    this.setState({
      [itemsPopulationConstants.IS_POPUP_OPEN]: true,
    }, () => {
      this.setBackgroundClicks(false);
      if (value && !value.get(itemsPopulationConstants.ITEMS_POPULATION_TYPE)) {
        const hasPermissionToPosFeature = this.props.getPermissionLevel(this.props.posFeature);
        if (hasPermissionToPosFeature === activityConstants.FEATURE_LEVEL.FULL) {
          value = value.set(itemsPopulationConstants.ITEMS_POPULATION_TYPE, itemsPopulationConstants.ITEMS_POPULATION_TYPE_ITEM_CODES);
        } else {
          value = value.set(itemsPopulationConstants.ITEMS_POPULATION_TYPE, itemsPopulationConstants.ITEMS_POPULATION_TYPE_ANY);
        }
      }
      this.props.actions.copyActivityDataToTempData(value);
    });
  };

  /**
   * callback function for closing the popup window
   * fire animation event only of clicking the background
   * @param e
   */
  onPopupClose = (e) => {
    if (!e || (this.state[itemsPopulationConstants.IS_PRISTINE] && ['html', 'body'].includes(e.srcElement.tagName.toLowerCase()))) {
      this.setState({
        [itemsPopulationConstants.IS_POPUP_OPEN]: false,
        [itemsPopulationConstants.IS_PRISTINE]: true,
      }, () => {
        this.setBackgroundClicks(true);
        this.props.actions.clearTempData();
      });
    } else if (['html', 'body'].includes(e.srcElement.tagName.toLowerCase())) {
      this.setState({ [itemsPopulationConstants.TOGGLE_ANIMATION]: !this.state[itemsPopulationConstants.TOGGLE_ANIMATION] });
    }
  };

  /**
   * disable background clicks when items population popup is open,
   * return to normal when it's closed
   * @param enable
   */
  setBackgroundClicks = (enable) => {
    // todo: property on popupWrapper
    if (enable) {
      document.getElementById('app').classList.remove('disable-pointer-events');
    } else {
      document.getElementById('app').classList.add('disable-pointer-events');
    }
  };

  /**
   * renders the component's view
   * @returns {*}
   */
  render() {
    const { itemGroups, intl } = this.props;
    const data = this.props.data;
    const displayValue = utils.getItemsPopulationDisplayList(
      data, itemGroups, intl.formatMessage, this.props.punchCardDefaultOptionLabel);
    const placeholderClassNames = classNames({
      placeholder: data[activityConstants.TYPE] === '' || (
        data[activityConstants.TYPE] !== itemsPopulationConstants.ITEMS_POPULATION_TYPE_ANY &&
        data[activityConstants.CONDITIONS] &&
        data[activityConstants.CONDITIONS][activityConstants.CONDITIONS_LIST] &&
        data[activityConstants.CONDITIONS][activityConstants.CONDITIONS_LIST].length === 0)
    });
    const automationIdPrefix = this.props.automationIdPrefix ? `${this.props.automationIdPrefix}.${activityConstants.ITEMS_POPULATION}` : activityConstants.ITEMS_POPULATION;
    const initialSelectedGroups = this.props.tempItemsPopulation && this.props.tempItemsPopulation.get(itemsPopulationConstants.INITIAL_GROUPS) ? this.props.tempItemsPopulation.get(itemsPopulationConstants.INITIAL_GROUPS).toJS() : [];
    const itemsPopulationType = this.props.tempItemsPopulation ? this.props.tempItemsPopulation.get(activityConstants.TYPE) : '';
    const itemsPopulationConditions = this.props.tempItemsPopulation && this.props.tempItemsPopulation.getIn([activityConstants.ITEMS_POPULATION]) ? this.props.tempItemsPopulation.getIn([activityConstants.ITEMS_POPULATION, itemsPopulationType, activityConstants.CONDITIONS, activityConstants.CONDITIONS_LIST]).toJS() : [];
    const conditionsRelations = this.props.tempItemsPopulation && this.props.tempItemsPopulation.getIn([activityConstants.ITEMS_POPULATION]) ? this.props.tempItemsPopulation.getIn([activityConstants.ITEMS_POPULATION, itemsPopulationType, activityConstants.CONDITIONS, activityConstants.CONDITION_LIST_OPERATOR]) : '';
    const clsName = classNames('popup-trigger conditions-dropdown items-population-field',
      { 'popup-trigger-error': !!this.props.itemsPopulationValidationErrors,
        'items-population-field-disabled': this.props.disabled,
        'items-population-field-enabled': !this.props.disabled
      });

    return (
      <PopupWrapper
        position={this.props.position}
        fixed
        className="item-sets-popup"
        onOpen={this.onPopupOpen}
        onClose={this.onPopupClose}
        open={this.state[itemsPopulationConstants.IS_POPUP_OPEN]}
        automationId={`${automationIdPrefix}.popup`}
        trigger={(
          <div className="inline-div">
            <PopupTrigger
              className={`${clsName} ${placeholderClassNames}`}
              message={displayValue}
              automationId={`${automationIdPrefix}.trigger`}
              title={typeof displayValue === 'string' ? displayValue : ''}
            />
          </div>
          )}
        on="click"
        size="large"
        scrollToViewIfNeeded
        error={this.props.itemsPopulationValidationErrors ? 'activity.validation.error.popup.outside.message' : null}
      >
        <ItemsPopulationContent
          advancedRulesSchemaConditions={this.props.advancedRulesSchemaConditions}
          animate={this.state[itemsPopulationConstants.TOGGLE_ANIMATION]}
          automationIdPrefix={automationIdPrefix}
          className={this.props.className}
          conditionsRelations={conditionsRelations}
          disableAny={this.props.disableAny}
          errors={this.props.tempItemsPopulationValidationErrors}
          features={this.props.features}
          initialSelectedGroups={initialSelectedGroups}
          isEditGroupMode={false}
          itemGroups={this.props.itemGroups}
          itemsPopulationConditions={itemsPopulationConditions}
          itemsPopulationType={itemsPopulationType}
          onAddCondition={(type) => this.props.actions.addCondition(type)}
          onApply={this.onApply}
          onCancel={this.onCancel}
          onClearAllInType={(type) => this.props.actions.clearAllInType(type)}
          onDeleteCondition={(type, index) => this.props.actions.deleteCondition(type, index)}
          onDeleteGroup={(hubId, serverId) => this.props.actions.deactivateItemsGroup(hubId, serverId)}
          onSaveAsGroup={this.onSaveAsGroup}
          updateConditionField={this.props.actions.updateCondition}
          updateWholeCondition={this.props.actions.updateWholeCondition}
          onUpdateItemsGroup={(group) => this.props.actions.updateItemsGroup(group)}
          title={this.props.title}
          onUpdateType={(type) => this.props.actions.updateType(type)}
          updateConditionsRelations={(type, relation) => this.props.actions.updateConditionsRelations(type, relation)}
          getPermissionLevel={this.props.getPermissionLevel}
          posFeature={this.props.posFeature}
          punchCardDefaultOptionLabel={this.props.punchCardDefaultOptionLabel}
          getBusinessBundlePlan={this.props.getBusinessBundlePlan}
          isSimpleMode={this.props.isSimpleMode}
        />
      </PopupWrapper>
    );
  }
}

const mapStateToProps = (state, props) => ({
  advancedRulesSchemaConditions: activitySelectors.getConditionsForAdvancedRule(state, activityConstants.SHOPPING_CART),
  itemGroups: itemGroupsSelectors.getItemGroupsList(state),
  itemGroupsFetched: itemGroupsSelectors.getItemGroupsListFetchedFlag(state),
  locationId: appSelectors.getLocationId(state),
  tempItemsPopulation: itemsPopulationSelectors.getTempItemsPopulation(state),
  tempItemsPopulationValidationErrors: itemsPopulationSelectors.getTempItemsPopulationValidationErrors(state, props.isMembersFilter)
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...itemGroupsActions, ...itemsPopulationActions }, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: itemGroupsConstants.ITEM_GROUPS, saga });

export default compose(
  withSaga,
  withConnect,
  injectIntl,
  withAuthorization
)(ItemsPopulation);

