import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'semantic-ui-react';
import classNames from 'classnames';
import PopupWrapper from '../../../../../common/components/PopupWrapper/PopupWrapper';
import UpdateMembershipPopupContent from './UpdateMembershipPopupContent';
import UpdateMembershipPopupActions from './UpdateMembershipPopupActions';
import PopupTrigger from '../../../../../common/components/PopupTrigger/PopupTrigger';

class UpdateMembershipPopup extends React.PureComponent {

  static propTypes = {
    data: PropTypes.object,
    error: PropTypes.object,
    isPopupOpen: PropTypes.bool,
    onApply: PropTypes.func,
    onCancel: PropTypes.func,
    onPopupClose: PropTypes.func,
    onPopupOpen: PropTypes.func,
    onUpdate: PropTypes.func,
    popupTrigger: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    prefix: PropTypes.string,
    conditionSchema: PropTypes.object,
    tempError: PropTypes.object,
    toggleAnimation: PropTypes.bool,
    triggerAutomationId: PropTypes.string,
  };

  render() {

    const { data, error, isPopupOpen, onPopupClose, onPopupOpen, onApply, onCancel, onUpdate, popupTrigger, prefix,
      conditionSchema, tempError, toggleAnimation, triggerAutomationId } = this.props;
    const triggerClasses = classNames('popup-trigger', {
      'popup-trigger-error': error
    });

    return (
      <PopupWrapper
        className="condition-membership-changed-popup"
        on="click"
        onClose={onPopupClose}
        onOpen={onPopupOpen}
        open={isPopupOpen}
        size={'large'}
        position="bottom left"
        automationId={`${triggerAutomationId}.popup`}
        trigger={(
          <div className="inline-div">
            <PopupTrigger
              className={triggerClasses}
              message={popupTrigger}
              automationId={triggerAutomationId}
            />
          </div>
        )}
      >
        <Transition
          animation="shake"
          duration={200}
          visible={toggleAnimation}
          transitionOnMount={false}
          mountOnShow={false}
        >
          <div>
            <UpdateMembershipPopupContent
              data={data}
              error={tempError}
              onUpdate={onUpdate}
              prefix={prefix}
              conditionSchema={conditionSchema}
            />
            <UpdateMembershipPopupActions
              onApply={onApply}
              onCancel={onCancel}
              prefix={prefix}
            />
          </div>
        </Transition>
      </PopupWrapper>
    );
  }
}

export default UpdateMembershipPopup;

