export const MILITARY_HOUR = 24;
export const REGULAR_HOUR = 12;
export const HOUR = 'hour';
export const MINUTE = 'minute';
export const AM = 'am';
export const PM = 'pm';

export const FORMAT_HOUR = {
  [MILITARY_HOUR]: {
    min: 0,
    max: 24
  },
  [REGULAR_HOUR]: {
    min: 1,
    max: 12
  }
};

export const FORMAT_MINUTE = {
  min: 0,
  max: 59
};
