/*
 *
 * MicroCampaign api
 *
 */

import axios from 'axios';

const microCampaignUri = '/api/microCampaign';

export function getMicroCampaign(hubId) {
  return axios.get(`${microCampaignUri}/${hubId}`)
  .then((res) => res.data)
  .catch((err) => err.message);
}

export function saveMicroCampaign(microCampaign) {
  return axios.post(microCampaignUri, microCampaign)
    .then((res) => res.data);
}

export function checkMicroCampaignNameAvailability(hubId, name) {
  const urlPrefix = `${microCampaignUri}/isMicroCampaignNameAvailable`;
  const microCampaignIdParam = hubId ? `&hubId=${hubId}` : '';
  const url = `${urlPrefix}?name=${encodeURIComponent(name)}${microCampaignIdParam}`;
  return axios.get(url).then((response) => response.data);
}

