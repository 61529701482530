import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import './TimeInputField.styles.less';
import { INTEGER_TYPE } from '../inputConstants';

class TimeInputField extends React.PureComponent {

  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    fluid: PropTypes.bool,
    value: PropTypes.number,
    name: PropTypes.string,
    onChange: PropTypes.func,
    prefix: PropTypes.string,
    error: PropTypes.string,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    digits: PropTypes.number
  };

  static defaultProps = {
    fluid: false,
    digits: 0
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ value: parseInt(nextProps.value, 10) });
  }

  componentWillUnmount() {
    if (this.state.value !== this.props.value) {
      this.onBlur();
    }
  }

  onKeyDown = (e) => {
    e.preventDefault();

    const { keyCode, key } = e;
    const { digits } = this.props;
    const { value } = this.state;

    switch (keyCode) {
      case 8: {
        // backspace
        let newVal = String(this.state.value);
        if (newVal.length <= 1) {
          return this.setState({ value: this.handleOverflows(0, 0) });
        }
        newVal = newVal.slice(0, newVal.length - 1);
        return this.setState({ value: this.handleOverflows(newVal, 0) });
      }
      case 13: {
        // enter
        return this.onBlur();
      }
      case 38: {
        // up arrow
        return this.onUpdate(parseInt(value, 10) + 1);
      }
      case 40: {
        // down arrow
        return this.onUpdate(parseInt(value, 10) - 1);
      }
      default: {
        const pressedValue = parseInt(key, 10);
        if (isNaN(pressedValue)) {
          return null;
        }
        let newVal = `${String(this.state.value).padStart(digits, '0')}${pressedValue}`;
        if (newVal.length > digits) {
          newVal = newVal.substr(digits - 1);
        }
        return this.setState({ value: this.handleOverflows(newVal, pressedValue) });
      }
    }
  };

  handleOverflows(value, latestKey) {
    const { maxValue, minValue } = this.props;
    let newVal = parseInt(value, 10);
    if (newVal < minValue) {
      newVal = minValue;
    } else if (newVal > maxValue) {
      newVal = latestKey;
    }
    return newVal;
  }

  onBlur = () => {
    this.onUpdate(this.state.value);
  };

  onUpdate = (value) => {
    const { name, onChange } = this.props;
    if (isNaN(value)) {
      return;
    }
    const newData = { name, value };
    onChange(null, newData);
  };

  render() {
    const { className, fluid, disabled, name, prefix, error, digits } = this.props;
    const clsName = `${className}`;
    const showValue = this.state.value === null ? '' : String(this.state.value).padStart(digits, '0');

    return (
      <Input
        className={clsName}
        type={INTEGER_TYPE}
        step={1}
        fluid={fluid}
        value={showValue}
        onKeyDown={this.onKeyDown}
        onBlur={this.onBlur}
        disabled={disabled}
        prefix={prefix}
        name={name}
        error={error}
        data-automation-id={prefix}
      />
    );
  }
}

export default TimeInputField;
