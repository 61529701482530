import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {bindActionCreators, compose} from 'redux';

import PercentageIntegerInputField
  from '../../../../common/components/FormInputFields/PercentageIntegerInputField/PercentageIntegerInputField';
import * as constants from '../../activity.constants';
import * as selectors from '../../activity.selectors';
import * as activityActions from "../../activity.actions";


class ABTestCondition extends React.PureComponent {

  static propTypes = {
    className: PropTypes.string,
    error: PropTypes.string,
    name: PropTypes.string,
    prefix: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func,
    intl: PropTypes.object,
    filteredPopulation: PropTypes.object,
    cases: PropTypes.object
  };

  getCaseIndex = (props) => {
    const prefixParts = props.prefix.split('cases.');
    const caseIndex = prefixParts[1].split('.')[0];
    return caseIndex;
  }

  isABTestPassive = (props) => {
    return this.getCaseIndex(props) == '1';
  }

  getPopulationSize = (props) => {
    return props.cases.get(this.getCaseIndex(props)).get(constants.AB_TESTING_FILTERED_POPULATION);
  }

  updateTestABPopulationSize = (props) => {
    const caseIndex = this.getCaseIndex(props);
    props.actions.updateTestABPopulationSize(caseIndex, props.value, props.filteredPopulation);
  }

  isPopulationPercentageWithInBounds = (percentage) => {
    return percentage && percentage <= 100;
  }

  keepPopulationPercentageWithInBounds = (props) => {
    if (!this.isPopulationPercentageWithInBounds(props.value) && !this.isABTestPassive(props)) { //Keep percentage in bounds
      props.onChange(_, {name: '', value: 100});
    }
  }

  componentWillReceiveProps = (nextProps) => {
    this.updateTestABPopulationSize(nextProps);
    this.keepPopulationPercentageWithInBounds(nextProps);
  }

  componentDidMount =() => {
    this.updateTestABPopulationSize(this.props);
  }

  render() {
    const className = classNames(
      this.props.className, 'ab-test-condition-value'
    );
    const { formatMessage } = this.props.intl;
    return (
      <span className="ab-test-condition-value">
        <PercentageIntegerInputField
          className={className}
          disabled={this.isABTestPassive(this.props)}
          error={this.props.error}
          name={this.props.name}
          placeholder=""
          prefix={this.props.prefix}
          value={this.props.value}
          onChange={this.props.onChange}
        />
        <span>{formatMessage({ id: 'abTest.Population.Case.Size' }, { size: this.getPopulationSize(this.props) })}</span>
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  filteredPopulation: selectors.getOneTimeActionFilteredPopulation(state),
  cases: selectors.getActivityCases(state)
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(activityActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(injectIntl(ABTestCondition));
