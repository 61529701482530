import { withRouter } from 'react-router-dom';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';

import App from './features/App';
// axios default settings override
import initRequester from './config/axios_config';
import tracker from './common/utils/tracking/tracker';

import {
  decreaseAjaxRequestsCounter,
  increaseAjaxRequestsCounter,
  setBrowserLocale,
  setHub1Path
} from './features/App/app.actions';

class AppConfigure extends React.PureComponent {
  static propTypes = {
    browserLocaleData: PropTypes.object,
    hotReload: PropTypes.bool,
    actions: PropTypes.object
  };

  constructor(props) {
    super(props);

    if (props.hotReload) {
      return;
    }

    let hub1Path;
    const path = process.env.HUB_1_PATH;

    if (path) { hub1Path = path; } else {
     // we want to strip sub (or sub.sub) domain to get hub1 from hub2
      const hostSplit = location.hostname.split('.');
      hostSplit.shift();
      hub1Path = `https://${hostSplit.join('.')}`;
    }

    this.props.actions.setHub1Path(hub1Path);
    this.props.actions.setBrowserLocale(this.props.browserLocaleData);
    initRequester(hub1Path, this.props.actions.increaseAjaxRequestsCounter, this.props.actions.decreaseAjaxRequestsCounter, this.props.actions.push);
    setTimeout(tracker.initialize.bind(tracker), 5000);
  }

  render() {
    return (<App hotReload={this.props.hotReload} />);
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ setHub1Path, setBrowserLocale, decreaseAjaxRequestsCounter, increaseAjaxRequestsCounter, push }, dispatch)
  };
}
const WithRouter = withRouter(connect(null, mapDispatchToProps)(AppConfigure));

export default WithRouter;
