import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import GiftRow from './GiftRow';
import * as activityConstants from '../../Activity/activity.constants';
import * as imagesSelectors from '../../Images/images.selectors';

class GiftsTable extends React.PureComponent {
  static propTypes = {
    gifts: PropTypes.object.isRequired,
    changeActivityStatus: PropTypes.func.isRequired,
    onDeleteGift: PropTypes.func.isRequired,
    goToGift: PropTypes.func.isRequired,
    openGiftInNewTab: PropTypes.func.isRequired,
    highlightedGiftId: PropTypes.string,
    thumbImages: PropTypes.object
  };

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    return (
      <Table className="ui single line fixed selectable very basic table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="list-column-name gift-picture-column" />
            <Table.HeaderCell
              className="list-column-name gift-name-column"
            >{formatMessage({ id: 'gifts-page.gift.name' })}</Table.HeaderCell>
            <Table.HeaderCell
              className="list-column-name gift-actions-column"
            >{formatMessage({ id: 'gifts-page.gift.actions' })}</Table.HeaderCell>
            <Table.HeaderCell
              className="list-column-name gift-status-column"
            >{formatMessage({ id: 'gifts-page.gift.status' })}</Table.HeaderCell>
            <Table.HeaderCell className="gift-context-menu-column" />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.gifts.map((gift, index) => {
            const imageId = gift.get(activityConstants.PREVIEW_IMAGE_ID);
            const thumbImage = imageId
              ? imagesSelectors.getImageById(this.props.thumbImages, imageId)
              : imagesSelectors.getDefaultImage(this.props.thumbImages);
            return (
              <GiftRow
                changeActivityStatus={this.props.changeActivityStatus}
                onDeleteGift={this.props.onDeleteGift}
                goToGift={this.props.goToGift}
                highlightedActivityId={this.props.highlightedGiftId}
                key={index.toString()}
                gift={gift}
                rowIndex={index}
                thumbImage={thumbImage}
                openGiftInNewTab={this.props.openGiftInNewTab}
              />
            );
          }, this)}
        </Table.Body>
      </Table>
    );
  }
}

export default injectIntl(GiftsTable);
