/*
 *
 * Activities api functions
 * handle models and prepare them before sending them to the backend
 *
 */

import axios from 'axios';
import * as activityConstants from './activity.constants';
import {
  adjustUserActionsConditions,
  mergeAllFilterConditions,
  normalizeMembershipGlobalConditionTempModel
} from './reducers/utils/globalCondition.reducer.utils';
import { LOCATION_ID } from '../App/app.constants';
import * as utils from './activity.api.utils';
import { adjustConditions } from './reducers/utils/condition.reducer.utils';

export function getActivity(action) {
  const idParam = action.id ? action.id : '';
  const activityApiPath = getActivityApiPath(action.activityType);
  const url = `/api/${activityApiPath}/${idParam}`;
  return axios.get(url)
    .then((res) => res.data);
}

function postActivityToServer(activityType, requestData) {
  const activityApiPath = getActivityApiPath(activityType);
  const url = `/api/${activityApiPath}`;
  return axios.post(url, requestData)
    .then((res) => res.data)
    .catch((err) => {
      if (err && err.response && err.response.data && err.response.data && err.response.data.errorMessage &&
        err.response.data.errorMessage.includes('Error creating image resource')) {
        return err;
      }
      return err.response.data;
    });
}

export function saveActivity(activity) {
  // this is a temp solution before backend hub models updated to support bundle and action wrappers
  let requestData = convertActions(activity);
  requestData = prepareActivityDataForBackend(requestData);
  return postActivityToServer(activity.type, requestData);
}

export function saveOneTimeAction(activity, filteredPopulation, schedulingSData, abTestMode) {
  let activityData = activity;
  if (abTestMode) {
    activityData = prepareABTestData(activityData);
  }
  activityData = convertActions(activityData);
  activityData = prepareActivityDataForBackend(activityData);

  activityData[activityConstants.ONE_TIME_ACTION_SCHEDULING] = schedulingSData;
  activityData[activityConstants.ONE_TIME_ACTION_FILTERED_POPULATION] = filteredPopulation;
  return postActivityToServer(activity.type, activityData);
}
export function getBusinessMonthlySmsMessagesCount(population) {
  const url = '/api/comnSettings';
  return axios.get(`${url}/${activityConstants.GET_BUSINESS_MONTHLY_SMS_MESSAGES_COUNT}?population=${population}`).then((res) => res.data);
}

export function getBusinessMonthlyEmailMessagesCount(population) {
  const url = '/api/comnSettings';
  return axios.get(`${url}/${activityConstants.GET_BUSINESS_MONTHLY_EMAIL_MESSAGES_COUNT}?population=${population}`).then((res) => res.data);
}

export function checkActivityNameAvailability(locationId, activityId, name, activityType) {
  let urlPrefix = null;
  if (activityType === activityConstants.ACTIVITY_TYPE_RULE) {
    urlPrefix = '/api/Rules/IsRuleNameAvailable';
  } else if (activityType === activityConstants.ACTIVITY_TYPE_DEAL) {
    urlPrefix = '/api/Deals/IsDealNameAvailable';
  } else if (activityType === activityConstants.ACTIVITY_TYPE_ONE_TIME) {
    urlPrefix = '/api/OneTime/IsOneTimeActionNameAvailable';
  } else if (activityType === activityConstants.ACTIVITY_TYPE_PUNCH_CARD) {
    urlPrefix = '/api/PunchCards/IsPunchCardNameAvailable';
  } else if (activityType === activityConstants.ACTIVITY_TYPE_GIFT) {
    urlPrefix = '/api/Gifts/IsGiftNameAvailable';
  } else if (activityType === activityConstants.ACTIVITY_TYPE_PROMO_CODE) {
    urlPrefix = '/api/PromoCodes/IsPromoCodeNameAvailable';
  } else {
    throw new Error('Unknown activity type!');
  }
  const activityIdParam = activityId ? `&activityId=${activityId}` : '';
  const url = `${urlPrefix}?locationId=${locationId}&name=${encodeURIComponent(name)}${activityIdParam}`;
  return axios.get(url).then((response) => response.data);
}


export function getLookerQuery(queryFileName, bucketName) {
  return axios.get(`/api/FilterPopulation/getLookerQuery?queryFileName=${queryFileName}&bucketName=${bucketName}`)
    .then((response) => response.data);
}


// TODO: move to features utils

/**
 * this is a temp solution before backend supports data
 * @param data
 */
export function convertActions(data) {
  // convert actionsWrapper object to array actions with one object inside

  if (data.actions) {
    // remove empty actions
    data.actions.forEach((actionsWrapper, index) => {
      data[activityConstants.ACTIONS][index][activityConstants.ACTIONS_LIST] = data[activityConstants.ACTIONS][index][activityConstants.ACTIONS_LIST].filter((action) => action[activityConstants.ACTION_TYPE] !== '');
      utils.nullifyEmptyExcludedItemsPopulation(data.actions[index]);
      // remove actionsWrapper with empty list of actions
      if (data[activityConstants.ACTIONS][index][activityConstants.ACTIONS_LIST].length === 0) {
        data.actions.splice(index, 1);
      }
    });
  }

  if (data.cases) {
    data.cases.map((singleCase) => {
    // remove empty actions
      singleCase.actions.forEach((actionsWrapper, index) => {
        singleCase[activityConstants.ACTIONS][index][activityConstants.ACTIONS_LIST] = singleCase[activityConstants.ACTIONS][index][activityConstants.ACTIONS_LIST].filter((action) => action[activityConstants.ACTION_TYPE] !== '');
        utils.nullifyEmptyExcludedItemsPopulation(singleCase.actions[index]);
      });
      // remove empty conditions
      singleCase[activityConstants.CONDITIONS][activityConstants.CONDITIONS_LIST] = adjustConditions(singleCase[activityConstants.CONDITIONS][activityConstants.CONDITIONS_LIST]);
      return singleCase;
    });
  }

  return data;
}

function prepareABTestData(data) {
  delete data[activityConstants.CASES][1][activityConstants.CONDITIONS][activityConstants.CONDITIONS_LIST][0];
  data[activityConstants.CASES][0][activityConstants.ACTIONS][0][activityConstants.ACTIONS_LIST].forEach((action) => {
    if (action[activityConstants.ACTION_TYPE]) {
      action[activityConstants.ACTION_TAGS] = [activityConstants.AB_TESTING_CASE_A];
    }
  });
  data[activityConstants.CASES][1][activityConstants.ACTIONS][0][activityConstants.ACTIONS_LIST].forEach((action) => {
    if (action[activityConstants.ACTION_TYPE]) {
      action[activityConstants.ACTION_TAGS] = [activityConstants.AB_TESTING_CASE_B];
    }
  });
  return data;
}

export function prepareActivityDataForBackend(activity) {
  // remove empty properties
  const cleanActivityData = cleanEmptyKeysFromModel(activity);
  // adjust conditions
  cleanActivityData[activityConstants.CONDITIONS][activityConstants.CONDITIONS_LIST] = adjustConditions(cleanActivityData[activityConstants.CONDITIONS][activityConstants.CONDITIONS_LIST]);
  if (cleanActivityData[activityConstants.CONDITIONS][activityConstants.CONDITIONS_LIST].length === 0) {
    delete cleanActivityData[activityConstants.CONDITIONS];
  }
  if (cleanActivityData[activityConstants.ONE_TIME_ACTION_FILTERED_POPULATION]) {
    if (cleanActivityData[activityConstants.ONE_TIME_ACTION_FILTERED_POPULATION][activityConstants.FILTERED_POPULATION_CONDITIONS]) {
      if (cleanActivityData[activityConstants.ONE_TIME_ACTION_FILTERED_POPULATION][activityConstants.FILTERED_POPULATION_CONDITIONS][activityConstants.CONDITIONS] &&
        cleanActivityData[activityConstants.ONE_TIME_ACTION_FILTERED_POPULATION][activityConstants.FILTERED_POPULATION_CONDITIONS][activityConstants.CONDITIONS][activityConstants.CONDITIONS_LIST]) {
        cleanActivityData[activityConstants.ONE_TIME_ACTION_FILTERED_POPULATION][activityConstants.FILTERED_POPULATION_CONDITIONS][activityConstants.CONDITIONS][activityConstants.CONDITIONS_LIST]
          = adjustConditions(cleanActivityData[activityConstants.ONE_TIME_ACTION_FILTERED_POPULATION][activityConstants.FILTERED_POPULATION_CONDITIONS][activityConstants.CONDITIONS][activityConstants.CONDITIONS_LIST]);
      }
      if (cleanActivityData[activityConstants.ONE_TIME_ACTION_FILTERED_POPULATION][activityConstants.FILTERED_POPULATION_CONDITIONS][activityConstants.USER_ACTIONS_CONDITIONS]) {
        cleanActivityData[activityConstants.ONE_TIME_ACTION_FILTERED_POPULATION][activityConstants.FILTERED_POPULATION_CONDITIONS][activityConstants.USER_ACTIONS_CONDITIONS]
          = adjustUserActionsConditions(cleanActivityData[activityConstants.ONE_TIME_ACTION_FILTERED_POPULATION][activityConstants.FILTERED_POPULATION_CONDITIONS]);
      }
      if (cleanActivityData[activityConstants.ONE_TIME_ACTION_FILTERED_POPULATION][activityConstants.FILTERED_POPULATION_CONDITIONS][activityConstants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER]) {
        if (cleanActivityData[activityConstants.ONE_TIME_ACTION_FILTERED_POPULATION][activityConstants.FILTERED_POPULATION_CONDITIONS][activityConstants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER][activityConstants.IMPORT_MEMBERS_MODE]
          === activityConstants.IMPORT_MEMBERS_COPY_PASTE_MODE) {
          cleanActivityData[activityConstants.ONE_TIME_ACTION_FILTERED_POPULATION][activityConstants.FILTERED_POPULATION_CONDITIONS][activityConstants.MEMBERSHIP_IMPORT_CONDITION] =
            cleanActivityData[activityConstants.ONE_TIME_ACTION_FILTERED_POPULATION][activityConstants.FILTERED_POPULATION_CONDITIONS][activityConstants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER][activityConstants.IMPORT_MEMBERS_COPY_PASTE_MODE];
        } else {
          cleanActivityData[activityConstants.ONE_TIME_ACTION_FILTERED_POPULATION][activityConstants.FILTERED_POPULATION_CONDITIONS][activityConstants.MEMBERSHIP_IMPORT_FROM_FILE_CONDITION] =
            cleanActivityData[activityConstants.ONE_TIME_ACTION_FILTERED_POPULATION][activityConstants.FILTERED_POPULATION_CONDITIONS][activityConstants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER][activityConstants.IMPORT_MEMBERS_UPLOAD_FILE_MODE];
        }
        delete cleanActivityData[activityConstants.ONE_TIME_ACTION_FILTERED_POPULATION][activityConstants.FILTERED_POPULATION_CONDITIONS][activityConstants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER];
      }
    }
  }
  if (cleanActivityData[activityConstants.TYPE] === activityConstants.ACTIVITY_TYPE_GIFT && cleanActivityData[activityConstants.GIFT_INTERACTIVE_TYPE] === activityConstants.GIFT_INTERACTIVE_TYPE_NONE) {
    delete cleanActivityData[activityConstants.GIFT_INTERACTIVE_TYPE];
  }

  if (cleanActivityData[activityConstants.TYPE] === activityConstants.ACTIVITY_TYPE_PROMO_CODE) {
    if (cleanActivityData[activityConstants.PROMO_CODE_BULKS][activityConstants.PROMO_CODE_MODE] === activityConstants.PROMO_CODE_MODE_SINGLE_CODE) {
      cleanActivityData[activityConstants.PROMO_CODE_BULKS] = cleanActivityData[activityConstants.PROMO_CODE_BULKS][activityConstants.PROMO_CODE_MODE_SINGLE_CODE];
      delete cleanActivityData[activityConstants.PROMO_CODE_BULKS][activityConstants.PROMO_CODE_MODE_FILE_UPLOADED];
    } else if (cleanActivityData[activityConstants.PROMO_CODE_BULKS][activityConstants.PROMO_CODE_MODE] === activityConstants.PROMO_CODE_MODE_FILE_UPLOADED) {
      cleanActivityData[activityConstants.PROMO_CODE_BULKS] = cleanActivityData[activityConstants.PROMO_CODE_BULKS][activityConstants.PROMO_CODE_MODE_FILE_UPLOADED];
      delete cleanActivityData[activityConstants.PROMO_CODE_BULKS][activityConstants.PROMO_CODE_MODE_SINGLE_CODE];
    }
    delete cleanActivityData[activityConstants.PROMO_CODE_BULKS][activityConstants.PROMO_CODE_MODE];
  }
  // activate activity if it's the first save
  // todo - when we have save as draft this will change, take status from button type
  cleanActivityData[activityConstants.STATUS] = activity[activityConstants.STATUS] ? activity[activityConstants.STATUS] : activityConstants.ACTIVITY_STATUS_ACTIVE;

  if (cleanActivityData[activityConstants.TYPE] === activityConstants.ACTIVITY_TYPE_PUNCH_CARD) {
    cleanActivityData[activityConstants.PUNCH_CARD_CHILD_ACTIVITIES] = utils.handlePunchCardChildActivities(cleanActivityData);
  }

  return cleanActivityData;
}

/**
 * remove empty properties
 * @param {object} model
 */
function cleanEmptyKeysFromModel(model) {
  const cleanModel = {};
  Object.keys(model).forEach((key) => {
    if (model[key] && model[key] !== '') {
      cleanModel[key] = model[key];
    }
  });
  return cleanModel;
}


// todo: when implement errors in application do this here too (in case path is null)
function getActivityApiPath(activityType) {
  let activityApiPath = null;
  if (activityType === activityConstants.ACTIVITY_TYPE_RULE) {
    activityApiPath = activityConstants.API_PATH_RULES;
  } else if (activityType === activityConstants.ACTIVITY_TYPE_DEAL) {
    activityApiPath = activityConstants.API_PATH_DEALS;
  } else if (activityType === activityConstants.ACTIVITY_TYPE_ONE_TIME) {
    activityApiPath = activityConstants.API_PATH_ONE_TIME;
  } else if (activityType === activityConstants.ACTIVITY_TYPE_PUNCH_CARD) {
    activityApiPath = activityConstants.API_PATH_PUNCH_CARDS;
  } else if (activityType === activityConstants.ACTIVITY_TYPE_GIFT) {
    activityApiPath = activityConstants.API_PATH_GIFTS;
  } else if (activityType === activityConstants.ACTIVITY_TYPE_PROMO_CODE) {
    activityApiPath = activityConstants.API_PATH_PROMO_CODES;
  }
  // TODO handle unknown type
  return activityApiPath;
}

export function transformServerTypeToActivityType(serverActivityType) {
  let activityType = null;
  if (serverActivityType === activityConstants.SERVER_TYPE_RULE) {
    activityType = activityConstants.ACTIVITY_TYPE_RULE;
  } else if (serverActivityType === activityConstants.SERVER_TYPE_DEAL) {
    activityType = activityConstants.ACTIVITY_TYPE_DEAL;
  } else if (serverActivityType === activityConstants.SERVER_TYPE_ONE_TIME_ACTION) {
    activityType = activityConstants.ACTIVITY_TYPE_ONE_TIME;
  } else if (serverActivityType === activityConstants.SERVER_TYPE_PUNCH_CARD) {
    activityType = activityConstants.ACTIVITY_TYPE_PUNCH_CARD;
  } else if (serverActivityType === activityConstants.SERVER_TYPE_GIFT) {
    activityType = activityConstants.ACTIVITY_TYPE_GIFT;
  } else if (serverActivityType === activityConstants.SERVER_TYPE_PROMO_CODE) {
    activityType = activityConstants.ACTIVITY_TYPE_PROMO_CODE;
  } else if (serverActivityType === 'microCampaign') {
    activityType = serverActivityType;
  }
  return activityType;
}

export function filterMembers(globalMemberConditions, locationId) {
  const filterConditions = normalizeMembershipGlobalConditionTempModel(globalMemberConditions).toJS();
  const mergedFilterConditions = mergeAllFilterConditions(filterConditions);
  // Returns only the user action filters
  const userActionFilters = adjustUserActionsConditions(filterConditions);
  const url = '/api/FilterPopulation';
  const requestData = {
    [activityConstants.FILTERED_POPULATION_CONDITIONS]: mergedFilterConditions,
    [activityConstants.FILTERED_POPULATION_USER_ACTIONS_CONDITIONS]: userActionFilters,
    [LOCATION_ID]: locationId,
  };
  return axios.post(url, requestData).then((res) => res.data);
}

export function getActivityStats(activityId, activityType) {
  const type = transformServerTypeToActivityType(activityType);
  const activityApiPath = getActivityApiPath(type);
  return axios.get(`/api/${activityApiPath}/Stats/${activityId}`).then((res) => res.data);
}

export function getAbTestStats(activityServerId) {
  return axios.get(`/api/${activityConstants.API_PATH_ONE_TIME}/abTestStats/${activityServerId}`)
    .then((res) => res.data);
}

export function getMigratedActivityData(data) {
  const activityApiPath = getActivityApiPath(data.activityType);
  return axios.get(`/api/${activityApiPath}/GetMigratedActivity?migrationId=${data.activityId}`).then((res) => res.data);
}

export function archiveHub1Activity(activityId, activityType, relatedActivitiesHub1, relatedActivitiesHub2) {
  const type = transformServerTypeToActivityType(activityType);
  const activityApiPath = getActivityApiPath(type);
  const relatedActivityString = type === activityConstants.ACTIVITY_TYPE_PUNCH_CARD ? `&relatedActivitiesHub1=${relatedActivitiesHub1}&relatedActivitiesHub2=${relatedActivitiesHub2}` : '';
  return axios.get(`/api/${activityApiPath}/ArchiveHub1Activity?activityId=${activityId}${relatedActivityString}`).then((res) => res.data);

}

export function exportFilteredPopulation(fileName, bucketName, locationId) {
  const url = '/api/FilterPopulation/exportPopulationFromFile';
  const requestData = {
    [activityConstants.FILTERED_POPULATION_FILE_NAME]: fileName,
    [activityConstants.FILTERED_POPULATION_BUCKET_NAME]: bucketName,
    [LOCATION_ID]: locationId,
  };
  return axios.post(url, requestData).then((res) => res.data);
}

export function uploadImportMembersFile(file, fileType) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('membershipFileType', fileType);
  return axios.post('/api/ImportMembersFile/', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
    .then((res) => res.data);
}

export function uploadImportCodesBulkFile(file) {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post('/api/PromoCodes/ImportCodesFile/', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
    .then((res) => res.data);
}

export function requestToEnableGiftLandingPageFeature(data) {
  return axios.post('/api/tools/requestToEnableGiftLandingPageFeature', data)
    .then((res) => res.data).catch((error) => error.response.data);
}

export function requestToEnablePushNotificationWithImageFeature(data) {
  return axios.post('/api/tools/requestToEnablePushNotificationWithImageFeature', data)
    .then((res) => res.data).catch((error) => error.response.data);
}

export function sendAction(data) {
  return axios.post('/api/Customer/action', { hubAction: data.hubAction, membershipKey: data.membershipKey, hubUser: data.hubUser }).then((response) => response.data);
}
