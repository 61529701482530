import { split } from 'split-sms';
import _ from 'lodash';
import * as mentionsList from '../../../../activitySchema/mentions';
import * as constants from '../../../../activitySchema/activitySchema.constants';
import { EMOJIS, GSM_CHARS } from './sendSMS.view.constants';

const PROVIDERS_LIMIT_OPTIONS_LIST = [
  { name: 'unicell', GSM: 335, Unicode: 335, calculateSpecialChars: false },
  { name: 'nexmo', GSM: 153, Unicode: 67, calculateSpecialChars: true },
  { name: 'twillio', GSM: 153, Unicode: 67, calculateSpecialChars: true },
  { name: 'plivo', GSM: 153, Unicode: 67, calculateSpecialChars: true },
  { name: 'shamir', GSM: 268, Unicode: 268, calculateSpecialChars: false },
  { name: 'custom', GSM: 0, Unicode: 0, calculateSpecialChars: false }
];

const DEFAULT_OPTIONS = { name: 'default', GSM: 153, Unicode: 67, calculateSpecialChars: true };

function stripEmojis(str, stripNewLine = false) {
  const finalStr = str
    .replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF]|\u32)/g,
      ''
    );

  if (stripNewLine) {
    return finalStr.replace(/\s+/g, ' ').trim();
  }

  return finalStr.trim();
}

function getProviderSettings(providerName) {
  const options = PROVIDERS_LIMIT_OPTIONS_LIST.find((index) => index.name === providerName);

  return options || DEFAULT_OPTIONS;
}

function calculateMessages(messageLength, charactersLimit) {
  if (charactersLimit === 0) {
    return 0;
  }

  return (messageLength <= charactersLimit) ? 1 : Math.ceil((messageLength / charactersLimit));
}

export function getSpecialChars(unsubscribeMessage, message, providerName) {
  const provider = PROVIDERS_LIMIT_OPTIONS_LIST.find((index) => index.name === providerName) || DEFAULT_OPTIONS;
  const specialCharsList = new Set();

  if (provider.calculateSpecialChars) {
    const cleanedMessage = stripEmojis(message);
    const fullMessage = `${unsubscribeMessage} ${cleanedMessage}`;

    [...fullMessage].forEach((ch, index) => {
      if (!GSM_CHARS[ch] && String.fromCharCode(65039) !== ch) {
        specialCharsList.add(ch);
      }
    });

    EMOJIS.forEach((emoji) => {
      if (message.includes(emoji)) {
        specialCharsList.add(emoji);
      }
    });
  }

  return new Array(...specialCharsList).join(', ');
}

export function getMessagesCount(newMessage, unsubscribeMessage = '', provider = 'default') {
  const settings = getProviderSettings(provider);

  if (newMessage && newMessage.length > 0) {
    const message = `${newMessage}${unsubscribeMessage}`;
    const contentData = split(message);
    const messageContentLength = getMessageContentLength(newMessage, unsubscribeMessage);
    let charsLimit = settings[contentData.characterSet];

    if (provider === 'nexmo') {
      const blob = new Blob([message]);
      const bytes = blob.size;
      const length = message.length;

      charsLimit = bytes > length ? settings.Unicode : settings.GSM;
    }

    return calculateMessages(messageContentLength, charsLimit);
  }

  return 1;
}

export function getMessageContentLength(newMessage, unsubscribeMessage = null) {
  const calculatedMessage = calculateMessageLength(newMessage);
  return calculatedMessage.noMentionsMessageLength + calculatedMessage.mentionsLength + ((unsubscribeMessage) ? unsubscribeMessage.length + 1 : 0);
}

function calculateMessageLength(message) {
  if (message) {
    let messageWithNoMentions = message;
    let mentionsLength = 0;
    const mentions = message.match(/{{[a-zA-Z\.]+}}/g);
    if (mentions && mentions.length > 0) {
      mentions.forEach((mention) => {
        let tempMention = mention.replace('{{', '');
        tempMention = tempMention.replace('}}', '');
        const res = _.find(mentionsList, { [constants.MENTION_ID]: tempMention });
        if (res) {
          messageWithNoMentions = messageWithNoMentions.replace(mention, '');
          mentionsLength += res[constants.MENTION_DEFAULT_SMS_LENGTH];
        }
      });
      return { noMentionsMessageLength: messageWithNoMentions.length, mentionsLength };
    }
    return { noMentionsMessageLength: message.length, mentionsLength: 0 };
  }
  return { noMentionsMessageLength: 0, mentionsLength: 0 };
}
