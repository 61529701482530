import { createSelector } from 'reselect';
import * as constants from './emails.constants';
import * as reducerConstants from '../../constants/reducer.constants';
import * as activityConstants from '../Activity/activity.constants';
import * as utils from './utils/emails.selectors.utils';

export const getEmails = (state) => state.getIn([reducerConstants.EMAILS_BRANCH, constants.EMAILS]);
export const getEmailsLoadedFlag = (state) => state.getIn([reducerConstants.EMAILS_BRANCH, constants.EMAILS_LOADED]);
export const getOrder = (state) => state.getIn([reducerConstants.EMAILS_BRANCH, constants.SORT, constants.ORDER]);
export const getFilters = (state) => state.getIn([reducerConstants.EMAILS_BRANCH, constants.FILTERS]);
export const getSearchTerm = (state) => state.getIn([reducerConstants.EMAILS_BRANCH, constants.SEARCH_TERM]);
export const getFilteredAndSortedEmails = createSelector([getEmails, getFilters, getOrder, getSearchTerm], utils.getFilteredAndSortedEmailsImpl);
export const isEmailsFiltered = createSelector([getEmails, getFilteredAndSortedEmails], utils.isEmailsFilteredImpl);
export const getHighlightedEmailId = (state) => state.getIn([reducerConstants.EMAILS_BRANCH, activityConstants.HIGHLIGHTED_ACTIVITY_ID]);
export const getEmailStats = (state) => state.getIn([reducerConstants.EMAILS_BRANCH, constants.EMAIL_STATS]);
export const getEmailOneTimeStats = createSelector([getEmailStats], utils.getEmailOneTimeStatsImpl);
export const getEmailRuleStats = createSelector([getEmailStats], utils.getEmailRuleStatsImpl);
export const getEmailMicroCampaignStats = createSelector([getEmailStats], utils.getEmailMicroCampaignStatsImpl);
export const getCampaignStatistics = (state) => state.getIn([reducerConstants.EMAILS_BRANCH, constants.EMAIL_STATS_PER_CAMPAIGN]);
export const getStatisticsPerRule = (state) => state.getIn([reducerConstants.EMAILS_BRANCH, constants.EMAIL_STATS_PER_RULE]);
export const getStatisticsPerMicroCampaign = (state) => state.getIn([reducerConstants.EMAILS_BRANCH, constants.EMAIL_STATS_PER_MICRO_CAMPAIGN]);
export const getLastSent = createSelector([getEmailStats], utils.getLastSentImpl);
export const getEmailStatsLoading = (state) => state.getIn([reducerConstants.EMAILS_BRANCH, constants.EMAIL_STATS_LOADING]);
export const getEmailUsages = (state) => state.getIn([reducerConstants.EMAILS_BRANCH, constants.EMAIL_USAGES]);
