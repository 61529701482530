import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Grid, Radio } from 'semantic-ui-react';
import ActionsList from './actionsList';
import Bundle from './bundle/Bundle';
import DisabledItemPosRestriction from '../../../../common/components/DisabledItem/DisabledItemPosRestriction';
import * as constants from '../../activity.constants';
import withAuthorization from '../../../../common/helpers/authorization';
import * as appConstants from '../../../App/app.constants';
import tracker from '../../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../../common/utils/tracking/tracking.consts';


class ActionsWrapper extends React.PureComponent {

  static propTypes = {
    actionsMode: PropTypes.string,
    actionPlaceholder: PropTypes.string.isRequired,
    actionsList: PropTypes.object,
    actionsPopupContent: PropTypes.object.isRequired,
    activityTrigger: PropTypes.string.isRequired,
    activityType: PropTypes.string,
    addBundleLine: PropTypes.func.isRequired,
    addPointsOnChangeType: PropTypes.func,
    automationIdPrefix: PropTypes.string,
    bundle: PropTypes.object,
    changeActivityActionMode: PropTypes.func.isRequired,
    createGiftFromActivity: PropTypes.func,
    createEmailFromActivity: PropTypes.func,
    deleteAction: PropTypes.func.isRequired,
    deleteBundleLine: PropTypes.func,
    getPermissionLevel: PropTypes.func.isRequired,
    features: PropTypes.object,
    intl: PropTypes.object,
    mentionsList: PropTypes.object,
    onBundleLineFieldUpdate: PropTypes.func.isRequired,
    getBusinessBundlePlan: PropTypes.func.isRequired,
    uniqueId: PropTypes.string.isRequired,
    updateActionField: PropTypes.func.isRequired,
    updateActionType: PropTypes.func.isRequired,
    validationErrors: PropTypes.object,
    actionsWrapperIndex: PropTypes.number,
    submitActionToCustomer: PropTypes.func,
    caseIndex: PropTypes.number
  };

  render() {
    const { getPermissionLevel, actionsMode } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const hasFeature = getPermissionLevel(appConstants.FEATURES_SHOPPING_CART_SUBMIT_PURCHASE) !== constants.FEATURE_LEVEL.NONE;
    const radioText = formatMessage({ id: 'activity.action.perOccurrenceMode' });
    const isBundleAdvancedMode = actionsMode === constants.ACTIONS_OCCURRENCES_MODE;

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column className="actions-mode">
            {/*  SIMPLE MODE */}
            <Radio
              label={formatMessage({ id: 'activity.actions.simpleMode' })}
              name={`${this.props.uniqueId}.actionModeGroup`}
              value={constants.ACTIONS_SIMPLE_MODE}
              checked={!isBundleAdvancedMode}
              onChange={(event, elem) => {
                this.props.changeActivityActionMode(elem.value, this.props.actionsWrapperIndex);
                tracker.onEvent(trackerConstants.EVENT_TYPE_SIMPLE_MODE_SELECTED);
              }}
              data-automation-id={`${this.props.automationIdPrefix}.button.simpleMode`}
            />
            {
              !isBundleAdvancedMode ?
                <div className="actions-box">
                  <ActionsList
                    actionsPopupContent={this.props.actionsPopupContent}
                    actionPlaceholder={this.props.actionPlaceholder}
                    actionsList={this.props.actionsList}
                    activityTrigger={this.props.activityTrigger}
                    addPointsOnChangeType={this.props.addPointsOnChangeType}
                    automationIdPrefix={this.props.automationIdPrefix}
                    actionsMode={this.props.actionsMode}
                    createGiftFromActivity={this.props.createGiftFromActivity}
                    createEmailFromActivity={this.props.createEmailFromActivity}
                    mentionsList={this.props.mentionsList}
                    validationErrors={this.props.validationErrors ? this.props.validationErrors.get(constants.ACTIONS_LIST) : null}
                    deleteAction={this.props.deleteAction}
                    updateActionField={this.props.updateActionField}
                    updateActionType={this.props.updateActionType}
                    features={this.props.features}
                    activityType={this.props.activityType}
                    actionsWrapperIndex={this.props.actionsWrapperIndex}
                    submitActionToCustomer={this.props.submitActionToCustomer}
                    caseIndex={this.props.caseIndex}
                    getBusinessBundlePlan={this.props.getBusinessBundlePlan}
                  />
                </div>
                : null
            }
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className="actions-mode">
            {/*  REPEAT MODE */}
            {hasFeature ?
              <Radio
                label={radioText}
                name={`${this.props.uniqueId}.actionModeGroup`}
                value={constants.ACTIONS_OCCURRENCES_MODE}
                checked={isBundleAdvancedMode}
                onChange={(event, elem) => {
                  this.props.changeActivityActionMode(elem.value, this.props.actionsWrapperIndex);
                  tracker.onEvent(trackerConstants.EVENT_TYPE_REPEAT_MODE_SELECTED);
                }}
                data-automation-id={`${this.props.automationIdPrefix}.button.bundleMode`}
              />
              :
              <DisabledItemPosRestriction>
                <Radio
                  label={radioText}
                  data-automation-id={`${this.props.automationIdPrefix}.button.bundleMode`}
                  disabled
                />
              </DisabledItemPosRestriction>
            }
            <p className="note line-break repeat-mode-note">
              <FormattedMessage id="activity.actions.occurrences.description" />
            </p>
            <div className="actions-box">
              {
                isBundleAdvancedMode ?
                  <div className={constants.ACTIONS_OCCURRENCES_MODE}>
                    <Bundle
                      activityType={this.props.activityType}
                      addBundleLine={this.props.addBundleLine}
                      automationIdPrefix={this.props.automationIdPrefix}
                      bundle={this.props.bundle}
                      deleteBundleLine={this.props.deleteBundleLine}
                      onBundleLineFieldUpdate={this.props.onBundleLineFieldUpdate}
                      posFeature={appConstants.FEATURES_SHOPPING_CART_SUBMIT_PURCHASE}
                      validationErrors={this.props.validationErrors ? this.props.validationErrors.get(constants.BUNDLE) : null}
                      actionsWrapperIndex={this.props.actionsWrapperIndex}
                    />
                    <ActionsList
                      actionsPopupContent={this.props.actionsPopupContent}
                      actionPlaceholder={this.props.actionPlaceholder}
                      actionsList={this.props.actionsList}
                      actionsMode={this.props.actionsMode}
                      activityTrigger={this.props.activityTrigger}
                      activityType={this.props.activityType}
                      addPointsOnChangeType={this.props.addPointsOnChangeType}
                      automationIdPrefix={this.props.automationIdPrefix}
                      bundle={this.props.bundle}
                      createGiftFromActivity={this.props.createGiftFromActivity}
                      deleteAction={this.props.deleteAction}
                      limit
                      mentionsList={this.props.mentionsList}
                      updateActionField={this.props.updateActionField}
                      updateActionType={this.props.updateActionType}
                      validationErrors={this.props.validationErrors ? this.props.validationErrors.get(constants.ACTIONS_LIST) : null}
                      actionsWrapperIndex={this.props.actionsWrapperIndex}
                      submitActionToCustomer={this.props.submitActionToCustomer}
                      getBusinessBundlePlan={this.props.getBusinessBundlePlan}
                    />
                  </div>
                  : null
              }
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default withAuthorization(injectIntl(ActionsWrapper));
