import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import _ from 'lodash';
import Tooltip from '../../../common/components/Tooltip/Tooltip';
import Modal from '../../../common/components/CustomModal/CustomModal';
import ActivityType from './ActivityType';
import * as appSelectors from '../../App/selectors';


const NAME_LETTERS_MAX = 25;

class ActivityHistoryLog extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    historyLog: PropTypes.object.isRequired,
    onConfirm: PropTypes.func,
    open: PropTypes.bool,
    localeDateFormat: PropTypes.string.isRequired,
    localeTimeFormat: PropTypes.string.isRequired,
    migrated: PropTypes.bool
  };

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line
    const { type, name, historyLog, onConfirm, open } = this.props;

    return (
      <Modal
        open={open}
        automationIdPrefix="activity.details"
        className="modal__activity__log-details"
        onClose={onConfirm}
        header={
          <div className="type-and-name">
            <ActivityType type={type} />
            { name.length > NAME_LETTERS_MAX ?
              <Tooltip
                content={name}
                trigger={
                  <p>{name}</p>
                }
                position="bottom right"
              /> : <p>{name}</p>
            }
          </div>
      }
        content={
          <React.Fragment>
            <ul>{
          Object.entries(historyLog.toJS())
            .map(([key, value]) => {
              if (!value) {
                return null;
              }
              const { firstName, lastName } = value;
              const userName = `${!_.isNil(firstName) ? firstName : ''} ${!_.isNil(lastName) ? lastName : ''}`.trim();
              const date = `${moment(value.date).format(this.props.localeDateFormat)} ${moment(value.date).format(this.props.localeTimeFormat)}`;
              return (
                <li key={key}>
                  <span className="log--title">{formatMessage({ id: `business-center.campaign.activity.historyLog.${key}` })}</span>
                  <div
                    className="log--content"
                    title={userName.length > 0
                      ? formatMessage({ id: 'business-center.campaign.activity.historyLog.content.with.name' }, { date, userName })
                      : formatMessage({ id: 'business-center.campaign.activity.historyLog.content' }, { date })
                    }
                  >
                    {userName.length > 0
                      ? formatMessage({ id: 'business-center.campaign.activity.historyLog.content.with.name' }, { date, userName })
                      : formatMessage({ id: 'business-center.campaign.activity.historyLog.content' }, { date })
                    }
                  </div>
                </li>
              );
            })}
            </ul>
            {this.props.migrated &&
              <span className="hint-tip-label stick-to-bottom">
                <FormattedMessage
                  id="activity.historyLog.migrated.note"
                  values={{
                    activityType: <span className="text-lowercase">
                      {formatMessage({ id: `activity.type.${type}` })}
                    </span>
                  }}
                />
              </span>
            }
          </React.Fragment>
        }
        actions={[
          <a role="button" key="gotIt" onClick={onConfirm}>{formatMessage({ id: 'general.button.got-it' })}</a>
        ]}
        closeOnDimmerClick
      />
    );
  }
}


const mapStateToProps = (state) => ({
  localeDateFormat: appSelectors.getBrowserLocaleDateFormat(state),
  localeTimeFormat: appSelectors.getBrowserLocaleTimeFormat(state)
});

export default connect(mapStateToProps)(injectIntl(ActivityHistoryLog));
