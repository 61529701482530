export function combineStats(statsList) {

  const combinedStatistics = initializeStatistics();

  const uniqueClicksRate = [];
  const uniqueOpensRate = [];

  statsList.forEach((statsElem) => {
    const statsElemData = JSON.parse(statsElem);

    combinedStatistics.sentValue += Number(statsElemData.find((obj) => obj.name === 'Sent' || obj.name === 'sent').value || 0);
    combinedStatistics.deliveredValue += Number(statsElemData.find((obj) => obj.name === 'Delivered' || obj.name === 'delivered').value || 0);
    combinedStatistics.spamComplaintValue += Number(statsElemData.find((obj) => obj.name === 'Spam Complaint' || obj.name === 'spam_complaint').value || 0);
    combinedStatistics.clickValue += Number(statsElemData.find((obj) => obj.name === 'Click' || obj.name === 'click').value || 0);
    combinedStatistics.openValue += Number(statsElemData.find((obj) => obj.name === 'Open' || obj.name === 'open').value || 0);
    combinedStatistics.unsubscribeValue += Number(statsElemData.find((obj) => obj.name === 'Unsubscribe' || obj.name === 'email_unsubscribe').value || 0);
    combinedStatistics.bounceValue += Number(statsElemData.find((obj) => obj.name === 'Bounce' || obj.name === 'bounce').value || 0);
    combinedStatistics.rejectedByProviderValue += Number(statsElemData.find((obj) => obj.name === 'Rejected by email provider' || obj.name === 'rejected_by_email_provider').value || 0);

    uniqueClicksRate.push(parseInt(statsElemData.find((obj) => obj.name === 'Click' || obj.name === 'click').rate, 10));
    uniqueOpensRate.push(parseInt(statsElemData.find((obj) => obj.name === 'Open' || obj.name === 'open').rate, 10));
  });
  combinedStatistics.deliveredRate = combinedStatistics.sentValue > 0 ? `${combinedStatistics.sentValue === 0 ? 0 : Math.round((combinedStatistics.deliveredValue / combinedStatistics.sentValue) * 10000) / 100}%` : '0%';
  combinedStatistics.clickRate = uniqueClicksRate.reduce(getSum) > 0 ? `${Math.round(uniqueClicksRate.reduce(getSum) / statsList.length)}%` : '0%';
  combinedStatistics.openRate = uniqueOpensRate.reduce(getSum) > 0 ? `${Math.round(uniqueOpensRate.reduce(getSum) / statsList.length)}%` : '0%';

  return [
    { name: 'Sent', value: combinedStatistics.sentValue },
    { name: 'Delivered', value: combinedStatistics.deliveredValue, rate: combinedStatistics.deliveredRate },
    { name: 'Spam Complaint', value: combinedStatistics.spamComplaintValue },
    { name: 'Click', value: combinedStatistics.clickValue, rate: combinedStatistics.clickRate },
    { name: 'Open', value: combinedStatistics.openValue, rate: combinedStatistics.openRate },
    { name: 'Unsubscribe', value: combinedStatistics.unsubscribeValue },
    { name: 'Bounce', value: combinedStatistics.bounceValue },
    { name: 'Rejected by email provider', value: combinedStatistics.rejectedByProviderValue },
  ];
}

function getSum(total, num) {
  return total + num;
}

function initializeStatistics() {
  return {
    sentValue: 0,
    deliveredValue: 0,
    deliveredRate: 0,
    spamComplaintValue: 0,
    clickValue: 0,
    clickRate: 0,
    openValue: 0,
    openRate: 0,
    unsubscribeValue: 0,
    bounceValue: 0,
    rejectedByProviderValue: 0
  };
}
