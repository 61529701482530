import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Grid, Header, Loader } from 'semantic-ui-react';
import classNames from 'classnames';
import InputWrapper from '../../../../../common/components/FormInputFields/InputWrapper/InputWrapper';
import CheckBoxWrapper from '../../../../../common/components/FormInputFields/CheckboxWrapper/CheckboxWrapper';
import ConfirmationDialog from '../../../../../common/components/ConfirmationDialog/ConfirmationDialog';

import { Tooltip } from '@como/web-hub-components';

import * as activityActions from '../../../activity.actions';
import * as activitySelectors from '../../../activity.selectors';

import './promoCodesGenerated.styles.less';
import * as constants from '../../../activity.constants';
import DropdownWrapper from '../../../../../common/components/DropdownWrapper/DropdownWrapper';

const DEFAULT_CODE_LENGTH = 6;

const getDefaultBulk = (index, codeLength) => ({
  name: `Bulk ${index + 1}`,
  size: 100,
  new: true,
  status: 'active',
  type: 'generated',
  codeLength
});

class PromoCodesGeneratedTab extends React.PureComponent {

  static propTypes = {
    intl: PropTypes.object,
  };

  state = {
    codeBulks: [
      {
        name: 'Test codes',
        isTest: true,
        size: 10,
        status: 'active',
        type: 'generated',
        new: true,
        codeLength: DEFAULT_CODE_LENGTH
      },
      getDefaultBulk(0, DEFAULT_CODE_LENGTH)
    ],
    isLoadingFreeCodes: true,
    testBulkStatus: 'active',
    testBulkSize: 10,
    showConfirmDialog: false,
    codeLength: DEFAULT_CODE_LENGTH
  };

  componentDidMount() {

    this.props.actions.updatePromoBulkType('generated');
    if (this.props.promoCodeBulks) {
      const promoCodeBulks = !Array.isArray(this.props.promoCodeBulks) ? this.props.promoCodeBulks.toJS() : this.props.promoCodeBulks;
      if (Array.isArray(promoCodeBulks)) {
        this.setState({
          // eslint-disable-next-line no-nested-ternary
          codeBulks: promoCodeBulks.sort((a, b) => (a.isTest === b.isTest)? 0 : a.isTest? -1 : 1),
          ...this.getTestCodesInfo(promoCodeBulks),
          codeLength: promoCodeBulks[0] && promoCodeBulks[0].codeLength ? promoCodeBulks[0].codeLength : DEFAULT_CODE_LENGTH,
          isEdit: true
        });
        this.props.actions.getFreeCodesNumber(promoCodeBulks[0] && promoCodeBulks[0].codeLength ? promoCodeBulks[0].codeLength : DEFAULT_CODE_LENGTH);
      } else {
        const codeBulks = [
            {
              name: 'Test codes',
              isTest: true,
              size: 10,
              status: 'active',
              type: 'generated',
              new: true,
              codeLength: DEFAULT_CODE_LENGTH
            },
            getDefaultBulk(0, this.state.codeLength)
          ];
        this.setState({
          codeBulks,
          ...this.getTestCodesInfo(codeBulks)
        });
        this.props.actions.updatePromoCodeBulks(codeBulks);
        this.props.actions.getFreeCodesNumber(this.state.codeLength);
      }
    }
  }

  getTestCodesInfo = (bulks) => {
    const testBulk = bulks.find((bulk) => bulk.isTest);
    return {
      testBulkSize: testBulk.size,
      testBulkStatus: testBulk.status
    };
  };

  addBulk = () => {
    const codeBulks = this.state.codeBulks.slice(0);
    const bulk = getDefaultBulk(codeBulks.length - 1, this.state.codeLength);

    codeBulks.push(bulk);
    this.setState({ codeBulks });
    this.props.actions.updatePromoCodeBulks(codeBulks);
  };

  onBulkNameChange = (value, index) => {
    const codeBulks = this.state.codeBulks.slice(0);
    codeBulks[index].name = value;
    this.setState({ codeBulks });
    this.props.actions.updatePromoCodeBulks(codeBulks);
  };

  onBulkCodesCountChange = (value, index) => {
    const codeBulks = this.state.codeBulks.slice(0);
    codeBulks[index].size = value;
    this.setState({ codeBulks });
    this.props.actions.updatePromoCodeBulks(codeBulks);
  };

  deactivateBulk = (index) => {
    const codeBulks = this.state.codeBulks.slice(0);
    codeBulks[index].status = 'inactive';
    this.props.actions.updatePromoCodeBulks(codeBulks);
    this.setState({ showConfirmDialog: false, confirmationBulkIndex: null });
  };

  onWithTestBulksChange = (value) => {
    let testBulkStatus;
    const codeBulks = this.state.codeBulks.slice(0);
    for (let i = 0; i < codeBulks.length; i++) {
      if (codeBulks[i].isTest) {
        testBulkStatus = value ? 'active' : 'paused';
        codeBulks[i].status = testBulkStatus;
      }
    }
    this.setState({ testBulkStatus });
    this.props.actions.updatePromoCodeBulks(codeBulks);
  };

  onTestBulkSizeChange = (value) => {
    const codeBulks = this.state.codeBulks.slice(0);
    for (let i = 0; i < codeBulks.length; i++) {
      if (codeBulks[i].isTest) {
        codeBulks[i].size = parseInt(value);
      }
    }
    this.setState({ testBulkSize: value });
    this.props.actions.updatePromoCodeBulks(codeBulks);
  };

  onConfirmationModalOpen = (index) => {
    this.setState({ showConfirmDialog: true, confirmationBulkIndex: index });
  };

  deleteBulk = (index) => {
    const codeBulks = this.state.codeBulks.slice(0);
    codeBulks.splice(index, 1);
    this.setState({ codeBulks });
    this.props.actions.updatePromoCodeBulks(codeBulks);
  };

  onLengthUpdate = (codeLength) => {
    const codeBulks = this.state.codeBulks.slice(0);
    for (let i = 0; i < codeBulks.length; i++) {
      codeBulks[i].codeLength = codeLength;
    }
    this.setState({ codeLength, codeBulks, isLoadingFreeCodes: true, freePromoCodesNumber: this.props.freePromoCodesNumber.count });
    this.props.actions.getFreeCodesNumber(codeLength);
  };

  static getDerivedStateFromProps(props, state) {
    if (props.freePromoCodesNumber && props.freePromoCodesNumber.uid !== state.uid) {
      return {
        ...state,
        freePromoCodesNumber: props.freePromoCodesNumber.count,
        isLoadingFreeCodes: false,
        uid: props.freePromoCodesNumber.uid
      };
    }
    return null;
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { codeBulks } = this.state;
    const withTestBulks = this.state.testBulkStatus === 'active';
    const errors = this.props.activityValidationErrors && this.props.activityValidationErrors.get('codeBulks') ? this.props.activityValidationErrors.get('codeBulks').toJS() : {};

    return (
      <Grid className="promoCode-generated">
        <Grid.Row>
          <Grid.Column width={14} className="left-label">
            <Header>{formatMessage({ id: 'promoCode.code.generated.title' })}</Header>
            <p className="hint-tip-label">
              {formatMessage({ id: 'promoCode.code.generated.subtitle' })}
            </p>

            <div className="promocode-generated-header-row">
              <div className="promocode-generated-length-row">
                <span className="hint-tip-label">
                  {formatMessage({ id: 'promoCode.code.generated.codeLength.label' })}
                </span>
                <DropdownWrapper
                  options={(() => {
                    const options = [];
                    for (let i = 5; i < 10; i++) {
                      options.push({
                        value: i,
                        text: i
                      });
                    }
                    return options;
                  })()}
                  name={'promoCode.codeLength'}
                  prefix={'promoCode.codeLength'}
                  onSelectOption={(e, data) => this.onLengthUpdate(data.value)}
                  value={this.state.codeLength}
                  disabled={codeBulks[0].key || this.state.isLoadingFreeCodes}
                />
              </div>
              <p className="hint-tip-label">
                {this.state.isLoadingFreeCodes ? (<Loader active inline size='mini' />) : this.state.freePromoCodesNumber} {formatMessage({ id: 'promoCode.code.generated.subtitle.available.codes' })}
              </p>
              {errors['availableCodesCount'] ?
                <div className="promoCode-generated-test-error validation-error-message"
                     title="1activity.validation.error.promo.code.bulk.single.code.length.is.too.sho1rt"
                     data-automation-id="promoCode.generated.row.test.count.error.message">
                  {formatMessage({ id: errors['availableCodesCount'] })}
                </div>
                : null
              }

            </div>

          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <div className="promoCode-generated-content">
            {codeBulks.map((bulk, index) => (
              <div className={classNames({
                'promoCode-generated-row': true,
                'deactivated': bulk.status === 'inactive',
                'hidden': bulk.isTest
              })}>
                <div className="promoCode-generated-index">{index}.</div>
                <div className="promoCode-generated-input">
                  <InputWrapper
                    value={bulk.name}
                    type="text"
                    onChange={(e, data) => this.onBulkNameChange(data.value, index)}
                    resetValueOnOverflow={false}
                    disabled={bulk.status === 'inactive' || this.state.isLoadingFreeCodes}
                    prefix={`promoCode.generated.row${index}.name`}
                    error={errors[index.toString()] ? errors[index.toString()].name : null}
                  />
                </div>
                <div className="promoCode-generated-label">
                  {formatMessage({ id: 'promoCode.code.generated.row.numberOfCodes' })}
                </div>
                <div className={classNames({
                  'promoCode-generated-input': true,
                  'promoCode-number-of-codes-input': bulk.new
                })}>
                  <InputWrapper
                    value={bulk.size}
                    type="text"
                    onChange={(e, data) => this.onBulkCodesCountChange(data.value, index)}
                    resetValueOnOverflow={false}
                    disabled={bulk.key || this.state.isLoadingFreeCodes}
                    prefix={`promoCode.generated.row${index}.count`}
                    className={'promoCode-number-of-codes-input-wrapper'}
                    error={errors[index.toString()] ? errors[index.toString()].size : null}
                    withSeparator
                  />
                </div>
                {
                  bulk.key ?
                    <div data-automation-id={`promoCode.generated.row${index}.deactivate.btn`}
                         onClick={() => this.onConfirmationModalOpen(index)}
                         className="promoCode-generated-deactivate">
                      <i aria-hidden="true" className="icon como-ic-deactivate" />
                      <span>{formatMessage({ id: 'promoCode.code.generated.row.deactivate' })}</span>
                    </div>
                    : null
                }
                {
                  !bulk.key && codeBulks.length > 2 ?
                    <div data-automation-id={`promoCode.generated.row${index}.delete.btn`}
                         onClick={() => this.deleteBulk(index)}
                         className="promoCode-generated-deactivate">
                      <i aria-hidden="true" className="icon como-ic-delete" />
                      <span>{formatMessage({ id: 'promoCode.code.generated.row.delete' })}</span>
                    </div>
                    : null
                }
                <div className="promoCode-generated-deactivated-line" />
              </div>
            ))}
            <div className="promoCode-generated-add-bulk-button" onClick={this.addBulk}>
              <i aria-hidden="true" className="icon como-ic-plus-in-circle" />
              <span>{formatMessage({ id: 'promoCode.code.generated.row.addBulk' })}</span>
            </div>
            <div className={classNames({
              'promoCode-generated-create-test-row': true,
              'disabled': !withTestBulks
            })}>
              <div className="promoCode-generated-test-checkbox">
                <CheckBoxWrapper
                  name={'generated-create-test'}
                  checked={withTestBulks}
                  disabled={this.state.isLoadingFreeCodes}
                  data-automation-id={'promoCode.generated.createTest'}
                  onChange={(e, data) => this.onWithTestBulksChange(data.checked)}
                  label={withTestBulks ?
                    formatMessage({ id: 'promoCode.code.generated.test.create.label' })
                    :
                    formatMessage({ id: 'promoCode.code.generated.test.create.label.withCount' }, { testBulkSize: this.state.testBulkSize })
                  }
                />
              </div>
              {
                withTestBulks ?
                  <React.Fragment>
                    <div className="promoCode-generated-test-count">
                      <InputWrapper
                        value={this.state.testBulkSize}
                        type="number"
                        onChange={(e, data) => this.onTestBulkSizeChange(data.value)}
                        resetValueOnOverflow={false}
                        prefix={'promoCode.generated.row.test.count'}
                        disabled={this.state.isLoadingFreeCodes}
                      />
                    </div>
                    <div className="promoCode-generated-test-label"> {formatMessage({ id: 'promoCode.code.generated.test.codes.label' })}</div>
                  </React.Fragment>
                  : null
              }
              <Tooltip
                content={formatMessage({ id: 'promoCode.code.generated.test.create.tooltip' })}
                trigger={
                  <div className="promoCode-generated-test-info">
                    <i aria-hidden="true" className="icon como-ic-info" />
                  </div>
                }
                position="top center"
              />
            </div>
            {withTestBulks && errors['0'] ?
              <div className="promoCode-generated-test-error validation-error-message"
                   title="1activity.validation.error.promo.code.bulk.single.code.length.is.too.sho1rt"
                   data-automation-id="promoCode.generated.row.test.count.error.message">
                {formatMessage({ id: errors['0'].size })}
              </div>
              : null
            }
          </div>

        </Grid.Row>

        <ConfirmationDialog
          className="apply-confirm-dialog-btn"
          automationIdPrefix="promoCode.generated.deactivate.bulk.confirmation-dialog"
          triggerLabelKey="general.button.apply"
          confirmButtonKey="general.button.apply"
          contentMessageKey="promoCode.generated.deactivate.bulk.confirmation-dialog.message"
          confirmAction={() => this.deactivateBulk(this.state.confirmationBulkIndex)}
          open={this.state.showConfirmDialog}
          cancelAction={() => { this.setState({ showConfirmDialog: false, confirmationBulkIndex: null }); }}
          titleKey="promoCode.generated.deactivate.bulk.confirmation-dialog.title"
          key="promoCode.generated.deactivate.bulk.confirmation-dialog"
        />

      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  promoCodeBulks: activitySelectors.getPromoCodeBulks(state),
  freePromoCodesNumber: activitySelectors.getFreePromoCodesNumber(state),
  activityValidationErrors: activitySelectors.getActivityValidationErrors(state)
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(activityActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  injectIntl,
  withConnect
)(PromoCodesGeneratedTab);
