import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import InputWrapper from '../../../../common/components/FormInputFields/InputWrapper/InputWrapper';
import CaseSummary from '../summary/CaseSummary';
import * as activityConstants from '../../activity.constants';
import tracker from '../../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../../common/utils/tracking/tracking.consts';
import ConfirmationDialog from '../../../../common/components/ConfirmationDialog/ConfirmationDialog';


class CaseHeader extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string,
    caseIndex: PropTypes.number,
    casesActions: PropTypes.object,
    casesCount: PropTypes.number,
    intl: PropTypes.object,
    conditionsList: PropTypes.object,
    case: PropTypes.object.isRequired,
    membershipConditions: PropTypes.object,
    abTestMode: PropTypes.bool,
    isOneTimeActivityDisabled: PropTypes.bool
  };

  toggleCaseDisplay = () => {
    this.props.casesActions.setCasesExpandCollapseMode(!this.isCollapsed, this.props.caseIndex);
  };

  deleteCase = () => {
    this.props.casesActions.deleteCase(this.props.caseIndex);
    tracker.onButtonClick(trackerConstants.BUTTON_TYPE_CASE_DELETE);
  };

  clearCaseData = () => {
    this.props.casesActions.clearCaseData(this.props.caseIndex);
    tracker.onButtonClick(trackerConstants.BUTTON_TYPE_CASE_CLEAR);
  };

  get isCollapsed() {
    return this.props.case.has(activityConstants.IS_COLLAPSED) ? this.props.case.get(activityConstants.IS_COLLAPSED) : true;
  }

  render() {
    const isCollapsed = this.isCollapsed;
    const { formatMessage } = this.props.intl;
    const expandCollapseIconClass = classNames('expand-collapse-toggle', {
      'como-ic-angle-right': isCollapsed,
      'como-ic-angle-down': !isCollapsed
    });
    const headerClassName = classNames('case-header', { 'collapsed': isCollapsed });
    const expandCollapseTooltip = isCollapsed ?
      formatMessage({ id: 'activity.cases.expand.tooltip' }) :
      formatMessage({ id: 'activity.cases.collapse.tooltip' });
    const abTestHeader = this.props.caseIndex === 0 ? 'activity.cases.caseHeader.abTestMode.testA' : 'activity.cases.caseHeader.abTestMode.testB';
    return (
      <Grid className={headerClassName}>
        <Grid.Row>
          <Grid.Column width={1}>
            <Icon
              className={expandCollapseIconClass}
              onClick={this.toggleCaseDisplay}
              size="big"
              data-automation-id={`${this.props.automationIdPrefix}.expand-collapse-handler`}
              title={expandCollapseTooltip}
            />
          </Grid.Column>
          <Grid.Column width={15}>
            { this.props.abTestMode
              ?
                <h1 className="case-title" data-automation-id={`${this.props.automationIdPrefix}.title`} onDoubleClick={this.toggleCaseDisplay}>
                  {formatMessage({ id: abTestHeader })}
                </h1>
              :
                <h1 className="case-title" data-automation-id={`${this.props.automationIdPrefix}.title`} onDoubleClick={this.toggleCaseDisplay}>
                  {formatMessage({ id: 'activity.cases.caseHeader' }, {
                    caseIndex: this.props.caseIndex + 1,
                    casesCount: this.props.casesCount
                  })}
                </h1>
            }

            {
              isCollapsed &&
              <CaseSummary
                case={this.props.case}
                conditionsList={this.props.conditionsList}
                caseIndex={this.props.caseIndex}
                membershipConditions={this.props.membershipConditions}
                abTestMode={this.props.abTestMode}
              />
            }
            <InputWrapper
              placeholder={formatMessage({ id: 'activity.cases.description.placeholder' })}
              value={this.props.case.get(activityConstants.DESCRIPTION)}
              onChange={(e, data) => this.props.casesActions.updateCaseDescription(this.props.caseIndex, data.value)}
              name="description"
              className="case-description"
              prefix={`${this.props.automationIdPrefix}.description`}
              disabled={this.props.isOneTimeActivityDisabled}
            />
            <div className="case-controlls">
              {
                this.props.casesCount > 1 && !this.props.abTestMode && !this.props.isOneTimeActivityDisabled &&
                <ConfirmationDialog
                  automationIdPrefix={`${this.props.automationIdPrefix}.delete-case`}
                  confirmButtonKey="general.button.delete"
                  tooltipMessageKey="activity.cases.delete.tooltip"
                  contentMessageKey="activity.cases.delete.confirmationMessage"
                  confirmAction={this.deleteCase}
                  className="clear case"
                  icon="como-ic-delete "
                />
              }
              {
                !isCollapsed && !this.props.isOneTimeActivityDisabled &&
                <ConfirmationDialog
                  automationIdPrefix={`${this.props.automationIdPrefix}.clear-case`}
                  confirmButtonKey="general.button.clear"
                  tooltipMessageKey="activity.cases.clear.tooltip"
                  contentMessageKey="activity.cases.clear.confirmationMessage"
                  confirmAction={this.clearCaseData}
                  className="erase-case"
                  icon="como-ic-erase"
                />
              }
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default injectIntl(CaseHeader);
