import { fromJS } from 'immutable';
import * as activityConstants from '../../activity.constants';
import * as appConstants from '../../../App/app.constants';

export const emptyItemsGroup = fromJS({
  [activityConstants.NAME]: null,
  [appConstants.LOCATION_ID]: null,
  [activityConstants.TYPE]: null,
  [activityConstants.CONDITIONS]: {
    [activityConstants.CONDITION_LIST_OPERATOR]: activityConstants.CONDITION_OR,
    [activityConstants.CONDITIONS_LIST]: []
  }
});
