import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import {typesMapping} from '../utils/promoCodes.utils';

const PromoCodeType = (props) => {
  const {bulkType, intl} = props;
  const type = typesMapping[bulkType];
  const classes = classNames('promoCodes-page-row-type', type ? type.name : 'fix');
  return (
    <div className={classes}>
      {intl.formatMessage({ id: `activity.type.${type ? type.name : 'fix'}` })}
    </div>
  );
};

PromoCodeType.propTypes = {
  bulkType: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired
};

export default injectIntl(PromoCodeType);
