import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Card, Image, Button, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import PropTypes from 'prop-types';
import './filterMembers.styles.less';
import SingleFilteredMember from './SingleFilteredMember';
import * as actions from './filterMembers.actions';
import * as selectors from './filterMembers.selectors';
import glasses from '../../images/icons/memberImages/glasses.svg';
import emptyStateImage from '../../images/icons/memberImages/emptyState.svg';
import * as appActions from '../App/app.actions';
import * as numbersFormatUtils from '../../utils/numbersFormat';
import * as queryStringUtils from '../../utils/queryStringUtils';
import * as constants from '../Activity/activity.constants';
import * as appConstants from '../App/app.constants';
import { getFilterPermLevel } from '../DataAndBI/dataAndBi.utils';

const ExportMembersFeature = 'Export Members';
export class FilterMembersResults extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

  static propTypes = {
    getCustomer: PropTypes.func,
    createOneTimeAction: PropTypes.func,
    exportFilteredPopulation: PropTypes.func,
    filteredPopulationSize: PropTypes.number,
    filteredPopulation: PropTypes.object,
    filteredPopulationSamples: PropTypes.array,
    membersImages: PropTypes.array,
    noMembers: PropTypes.bool,
    exportInProgress: PropTypes.bool,
    getPermissionLevel: PropTypes.func.isRequired,
    filterTempGlobalCondition: PropTypes.object,
  };

  componentDidMount() {
    if (this.props.filteredPopulationSamples && this.props.filteredPopulationSamples.length > 0) {
      this.handleScroll();
    }
  }

  componentWillMount() {
    if (this.props.filteredPopulationSamples && this.props.filteredPopulationSamples.length > 0) {
      this.props.filteredPopulationSamples.forEach((f) => {
        f.image = this.getRandomMemberImage();
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filteredPopulationSamples !== this.props.filteredPopulationSamples) {
      if (nextProps.filteredPopulationSamples && nextProps.filteredPopulationSamples.length > 0) {
        nextProps.filteredPopulationSamples.forEach((f) => {
          f.image = this.getRandomMemberImage();
        });
        this.handleScroll();
      }
    }
  }

  getRandomMemberImage() {
    if (this.props.membersImages && this.props.membersImages.length > 0) {
      const i = Math.floor(Math.random() * (this.props.membersImages.length - 1));
      return this.props.membersImages[i];
    }
    return glasses;

  }

  handleScroll() {
    setTimeout(() => {
      if (this.scrollArea) {
        this.scrollArea.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }

  render() {
    const locationId = queryStringUtils.getLocationId(location.search);
    const perLevel = getFilterPermLevel(this.props.getPermissionLevel, appConstants.FEATURE_NAME_FILTER);
    const hasExportPermissionLevel = this.props.getPermissionLevel(ExportMembersFeature) === constants.FEATURE_LEVEL.FULL;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    return (
      <div
        className={'filter-members-results-wrapper'}
        ref={(ref) => {
          this.scrollArea = ref;
        }}
      >
        <div
          className={'result-actions'}
        >
          {perLevel === 'Full' && <div className={'result-actions-wrap'}>
            <Button
              circular
              className="one-time-btn stick-to-right"
              onClick={() => this.props.createOneTimeAction(this.props.filteredPopulation, this.props.filterTempGlobalCondition)}
              disabled={this.props.noMembers}
            >
              <i className="icon como-ic-plus" />
              <div className={'one-time-btn-text'}>{formatMessage({ id: 'activity.type.onetime' })}</div>
            </Button>

            { hasExportPermissionLevel && <div className={'single-action stick-to-right divider'}>

              <Button
                className={'link export-filter unset-padding'}
                basic
                id={'export-filter'}
                disabled={this.props.exportInProgress || this.props.noMembers}
                onClick={this.props.exportFilteredPopulation}
                loading={this.props.exportInProgress}
              >{formatMessage({ id: 'filter.export' })}
                {!this.props.exportInProgress && <Icon className="como-ic-export unset-padding" />}
              </Button>
            </div> }
          </div>}
        </div>
        {(this.props.filteredPopulationSize && this.props.filteredPopulationSize > 0 && !this.props.noMembers) ?
          <div>
            <div className={'filter-results-header'}>
              <FormattedMessage
                id="filter-result"
                values={{
                  'count': <h1
                    className={'blue-text'}
                  >{numbersFormatUtils.displayNumberWithCommas(this.props.filteredPopulationSize)}</h1>
                }}
              />
            </div>
            <Card.Group className={'filter-results-group'}>
              {this.props.filteredPopulationSamples.map((s) => (<SingleFilteredMember
                getCustomer={this.props.getCustomer}
                key={s.membershipKey}
                member={s}
              />))}
            </Card.Group>
          </div>
          : null}
        {this.props.noMembers &&
        <div className={'empty-state'}>
          <div className={'empty-filter-result-title'}>{formatMessage({ id: 'empty_filter_result_title' })}</div>
          <div
            className={'empty-filter-result-description'}
          >{formatMessage({ id: 'empty_filter_result_description' })}</div>
          <Image className={'empty-state-image'} src={emptyStateImage} />
        </div>}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  const creators = {
    ...actions,
    ...appActions
  };
  return { actions: bindActionCreators(creators, dispatch) };
};
const mapStateToProps = (state) => ({
  membersImages: selectors.getMemberImages(),
  exportInProgress: selectors.getFilterExportInProgressFlag(state),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(FilterMembersResults);
