import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Radio } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as schemaConstants from '../../../../activitySchema/activitySchema.constants';
import './updateMembershipSensitiveData.styles.less';
import DropdownWrapper from '../../../../../../common/components/DropdownWrapper/DropdownWrapper';
import * as constants from '../../../../../../common/components/FormInputFields/Boolean/Boolean.constants';

class UpdateMembershipSensitiveData extends React.PureComponent {
  constructor(props) {
    super(props);
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    this.state = {
      updateOptions: [
        {
          text: formatMessage({ id: 'activity.action.updateMembershipSensitiveData.allowSms' }),
          value: 'sms'
        },
        {
          text: formatMessage({ id: 'activity.action.updateMembershipSensitiveData.allowEmail' }),
          value: 'email'
        },
        { text: formatMessage({ id: 'activity.action.updateMembershipSensitiveData.giveConsent' }), value: 'consent' }
      ],

    };
  }

  static propTypes = {
    automationIdPrefix: PropTypes.string,
    onActionFieldUpdate: PropTypes.func.isRequired,
    action: PropTypes.object,
    index: PropTypes.number,
    validationErrors: PropTypes.object,
  };

  render() {
    const { action, index, onActionFieldUpdate, validationErrors, automationIdPrefix } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    return (
      <Grid>
        <Grid.Row className="action allow-sms-update-membership">
          <Grid.Column width={16}>
            <div>
              <p className="action-label">{formatMessage({ id: 'activity.action.choose.action' })}</p>
              <DropdownWrapper
                className="auto-complete-wrapper"
                options={this.state.updateOptions}
                onSelectOption={(e, data) => onActionFieldUpdate(data.name, data.value.trim(), index)}
                value={action.get(schemaConstants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_FIELD_TYPE)}
                disabled={false}
                index={this.props.index}
                prefix={`${automationIdPrefix}.value`}
                error={validationErrors ? validationErrors.get(schemaConstants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_FIELD_TYPE) : null}
                name={schemaConstants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_FIELD_TYPE}
                placeholder={formatMessage({ id: 'dropdown.placeholder.select.or.add' })}
                selection
                allowAdditions
              />
            </div>
            <p style={{ color: 'red' }} className="action-hint italic left-note left">
              {formatMessage({ id: 'activity.action.update_sensitive_data.warning' })}
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="action sensitive-selection">
          <Grid.Column width={16}>
            <div>
              {this.props.action.get(schemaConstants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_FIELD_TYPE) === 'sms' && <div>
                <Radio
                  label={constants.YES}
                  value={constants.TRUE}
                  onChange={() => onActionFieldUpdate(schemaConstants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_ALLOW_SMS_TYPE_FIELD, constants.TRUE, index)}
                  checked={this.props.action.get(schemaConstants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_ALLOW_SMS_TYPE_FIELD).toString() == constants.TRUE}
                />
                &nbsp; &nbsp;
                <Radio
                  label={constants.NO}
                  value={constants.FALSE}
                  onChange={() => onActionFieldUpdate(schemaConstants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_ALLOW_SMS_TYPE_FIELD, constants.FALSE, index)}
                  checked={this.props.action.get(schemaConstants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_ALLOW_SMS_TYPE_FIELD).toString() == constants.FALSE}
                />
              </div>}
              {this.props.action.get(schemaConstants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_FIELD_TYPE) === 'email' && <div>
                <Radio
                  label={constants.YES}
                  value={constants.TRUE}
                  onChange={() => onActionFieldUpdate(schemaConstants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_ALLOW_EMAIL_TYPE_FIELD, constants.TRUE, index)}
                  checked={this.props.action.get(schemaConstants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_ALLOW_EMAIL_TYPE_FIELD).toString() == constants.TRUE}
                />
                &nbsp; &nbsp;
                <Radio
                  label={constants.NO}
                  value={constants.FALSE}
                  onChange={() => onActionFieldUpdate(schemaConstants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_ALLOW_EMAIL_TYPE_FIELD, constants.FALSE, index)}
                  name={schemaConstants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_ALLOW_EMAIL_TYPE_FIELD}
                  checked={this.props.action.get(schemaConstants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_ALLOW_EMAIL_TYPE_FIELD).toString() == constants.FALSE}
                />
              </div>}
            </div>
          </Grid.Column>
        </Grid.Row>

      </Grid>
    );
  }
}

export default injectIntl(UpdateMembershipSensitiveData);
