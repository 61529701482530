import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import DropDown from '../DropdownWrapper/DropdownWrapper';
import TimeInputFieldWithUpDownButtons from '../FormInputFields/TimeInputField/TimeInputFieldWithUpDownButtons';
import './HourAndMinute.styles.less';
import * as constants from './HourAndMinute.constants';
import * as utils from './HourAndMinute.utils';

const DATA_AUTOMATION_ID_PREFIX = 'hour_and_minute';

class HourAndMinute extends React.PureComponent {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    showAs: PropTypes.func,
    isMilitary: PropTypes.bool,
    hour: utils.isHourValid,
    minute: utils.isMinuteValid,
    format: utils.isFormatValid,
    prefix: PropTypes.string,
    index: PropTypes.number,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    index: 0
  };

  handlerHourChange = (hour) => {
    const { onChange, minute } = this.props;
    const params = { minute };

    if (!_.isUndefined(hour)) {
      params.hour = hour;
    }

    if (!this.props.isMilitary) {
      params.format = this.props.format;
    }

    return onChange(params);
  };

  handlerMinuteChange = (minute) => {
    const { onChange, hour } = this.props;
    const params = { hour };

    if (!_.isUndefined(minute)) {
      params.minute = minute;
    }

    if (!this.props.isMilitary) {
      params.format = this.props.format;
    }

    return onChange(params);
  };

  handlerSelectionOption = (e, data) => {
    const { hour, minute, onChange } = this.props;

    if (this.props.isMilitary) {
      return onChange({ hour, minute });
    }

    return onChange({ hour, minute, format: data.value });
  };

  render() {
    const { isMilitary, hour, minute, index, showAs, disabled, prefix, intl, format } = this.props;
    const { formatMessage } = intl;
    return (
      <div className="container-hour-and-minute">
        <TimeInputFieldWithUpDownButtons
          min={isMilitary
            ? constants.FORMAT_HOUR[constants.MILITARY_HOUR].min
            : constants.FORMAT_HOUR[constants.REGULAR_HOUR].min}
          initValue={hour}
          max={isMilitary
            ? constants.FORMAT_HOUR[constants.MILITARY_HOUR].max
            : constants.FORMAT_HOUR[constants.REGULAR_HOUR].max}
          isIncludeMax={!isMilitary}
          isIncludeMin
          onChange={this.handlerHourChange}
          prefix={`${prefix}.${DATA_AUTOMATION_ID_PREFIX}.${index}.hour`}
          value={hour}
          showAs={showAs}
          disabled={disabled}
        />
        <span className="nikudataim-divider">:</span>
        <TimeInputFieldWithUpDownButtons
          min={constants.FORMAT_MINUTE.min}
          max={constants.FORMAT_MINUTE.max}
          isIncludeMin
          onChange={this.handlerMinuteChange}
          isIncludeMax
          prefix={`${prefix}.${DATA_AUTOMATION_ID_PREFIX}.${index}.minute`}
          value={minute}
          showAs={showAs}
          disabled={disabled}
        />
        <span>&nbsp;</span>
        {!isMilitary &&
        <DropDown
          prefix={`${prefix}.ampm` || ''}
          options={[
            { value: constants.AM, text: formatMessage({ id: 'activity.action.hour.am' }) },
            { value: constants.PM, text: formatMessage({ id: 'activity.action.hour.pm' }) }
          ]}
          onSelectOption={this.handlerSelectionOption}
          value={format}
          disabled={disabled}
          className="time-ampm"
        />
        }
      </div>
    );
  }
}

export default injectIntl(HourAndMinute);
