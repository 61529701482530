/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Load the favicon, the manifest.json file and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import 'file-loader?name=[name].[ext]!./.htaccess';
/* eslint-enable import/no-unresolved, import/extensions */

// Needed for redux-saga es6 generator support
import 'babel-polyfill';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';

import 'sanitize.css/sanitize.css';

import history from './configureHistory';

// Import configuration for a root app, it renders the root app component
import AppConfigure from './appConfigure';
import configureStore from './configureStore';

// imposer semantic-ui-less styles
import './styles/semantic/semantic.less';
import './styles/common.styles.less';
import './styles/common.external.scss';

import * as appIntl from './app.intl';

// import vendors
// commented out until sales force will fix the cors
// import './vendors/sf/sfchat';
// import './vendors/sf/sfchat.css';

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');
appIntl.configure();

const render = (hotReload) => {
  ReactDOM.render(<Provider store={store}>
    <ConnectedRouter history={history}>
      <AppConfigure browserLocaleData={appIntl.getBrowserLocaleData()} hotReload={hotReload} />
    </ConnectedRouter>
  </Provider>, MOUNT_NODE);
};
render(false);
if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept('./appConfigure', () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(true);

  });
}

// Install ServiceWorker and AppCache in the end since
// it's not the most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  // offline plugin was here
}
