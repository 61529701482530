import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Input, List } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';

import * as schemaConstants from '../../../../activitySchema/activitySchema.constants';
import * as constants from '../../../../../MailTemplates/mailTemplates.constants';


export class EmailsListContent extends React.PureComponent {

  static propTypes = {
    emailsList: PropTypes.object,
    onActionFieldUpdate: PropTypes.func,
    action: PropTypes.object,
    index: PropTypes.number,
    automationIdPrefix: PropTypes.string,
    allowCreateEmail: PropTypes.bool,
    handlePopupClose: PropTypes.func,
    onCreateEmail: PropTypes.func

  };

  constructor(props) {
    super(props);

    this.state = {
      [constants.FILTER_SEARCH]: ''
    };
  }

  onFilter = (value) => {
    this.setState({
      [constants.FILTER_SEARCH]: value
    });
  };

  getFiltered = (options) => options.filter((option) => option.get(constants.TEMPLATE_NAME).includes(this.state[constants.FILTER_SEARCH]));

  onChange(emailId, templateName, index) {
    this.props.handlePopupClose();
    this.props.onActionFieldUpdate(schemaConstants.SEND_MEMBER_EMAIL_KEY_FIELD, emailId, index);
    this.props.onActionFieldUpdate(schemaConstants.SEND_MEMBER_EMAIL_TEMPLATE_NAME, templateName, index);
  }

  renderEmailsList = (emailList) => {
    const listItems = [];
    emailList.forEach((email) => {
      listItems.push((<List.Item
        key={`${email.get(constants.EXTERNAL_ID)}`}
        className={this.props.action.get(schemaConstants.SEND_MEMBER_EMAIL_KEY_FIELD) === email.get(constants.EXTERNAL_ID) ? 'selected' : ''}
        onClick={() => this.onChange(email.get(constants.EXTERNAL_ID), email.get(constants.TEMPLATE_NAME), this.props.index)}
        data-automation-id={`${this.props.automationIdPrefix}.externalTemplateId.${email.get(constants.EXTERNAL_ID)}`}
      >
        <Icon
          className={`como-ic-checkmark ${email.get(constants.EXTERNAL_ID)}_${email.get(constants.TEMPLATE_NAME)}`}/>
        <span
          className="email-name"
          title={email.get(constants.TEMPLATE_NAME)}
        >{email.get(constants.TEMPLATE_NAME)}</span>
      </List.Item>));
    });
    return listItems;
  };

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const filteredEmailList = this.getFiltered(this.props.emailsList);
    return (
      <div>
        <div className="filters">
          <Input
            icon="search"
            placeholder={formatMessage({ id: 'activity.actions.search' })}
            fluid
            onChange={(e, data) => { this.onFilter(data.value); }}
            prefix={`emailsList.${this.props.index}.searchBox`}
          />
        </div>
        <div className="emails">
          <div className="emails-group">
            {
              filteredEmailList.size > 0
                ? (
                  <List size="large" verticalAlign="middle">
                    {
                      this.renderEmailsList(filteredEmailList)
                    }
                  </List>
                )
                : <p>{formatMessage({ id: 'activity.actions.emails.no' })}</p>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(EmailsListContent);
