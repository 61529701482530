import axios from 'axios';

export function getEmail(hubId) {
  return axios.get(`/api/emails/${hubId}`)
  .then((res) => res.data);
}

export function saveEmail(data) {
  const url = '/api/emails';
  return axios.post(url, data)
    .then((res) => res.data);
}

export function getEmailEditorBusinessCredentials() {
  const url = '/api/emails/getEmailEditorBusinessCredentials';
  return axios.post(url)
    .then((res) => res.data);
}

export function checkEmailNameAvailability(emailHubId, name) {
  const urlPrefix = '/api/emails/isEmailNameAvailable';
  const emailIdParam = emailHubId ? `&emailId=${emailHubId}` : '';
  const url = `${urlPrefix}?name=${encodeURIComponent(name)}${emailIdParam}`;
  return axios.get(url).then((response) => response.data);
}
