import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import * as commonConstants from '../common.components.constants';

class ValidationErrorMessage extends React.PureComponent {

  static propTypes = {
    errorMessage: PropTypes.string,
    className: PropTypes.string,
    errorLayoutType: PropTypes.string,
    dataAutomationId: PropTypes.string.isRequired
  };

  getLayoutElem() {
    switch (this.props.errorLayoutType) {
      case commonConstants.COMPONENT_TYPE_TEXT:
        return (
          <span
            className={classNames('validation-error-message', this.props.className)}
            title={this.props.errorMessage}
            data-automation-id={this.props.dataAutomationId}
          >
            {this.props.errorMessage}
          </span>
        );
      case commonConstants.COMPONENT_TYPE_PARAGRAPH:
        return (
          <p
            className={classNames('validation-error-message', this.props.className)}
            title={this.props.errorMessage}
            data-automation-id={this.props.dataAutomationId}
          >
            {this.props.errorMessage}
          </p>
        );
      default:
        return (
          <div
            className={classNames('validation-error-message', this.props.className)}
            title={this.props.errorMessage}
            data-automation-id={this.props.dataAutomationId}
          >
            {this.props.errorMessage}
          </div>
        );
    }
  }

  render() {
    return this.getLayoutElem();
  }
}

export default injectIntl(ValidationErrorMessage);

