import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Icon, Image } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as imageSelectors from '../../images.selectors';
import injectSaga from '../../../../utils/injectSaga';
import * as reducerConstants from '../../../../constants/reducer.constants';
import imagesSaga from '../../images.saga';
import * as activityActions from '../../../Activity/activity.actions';
import Gallery from '../ImageGallery/ImageGallery.container';
import * as activityConstants from '../../../Activity/activity.constants';
import * as imagesConstants from '../../images.constants';
import './pushNotification.styles.less';

class PushNotificationGallery extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      imageId: null
    };
  }
  static propTypes = {
    imageId: PropTypes.number,
    defaultImage: PropTypes.object,
    activityActions: PropTypes.object,
    onImageSelected: PropTypes.func,
    intl: PropTypes.object.isRequired,
    imageUrl: PropTypes.string,
    isImageSelected: PropTypes.bool,
    selectedPreviewImage: PropTypes.object,
    images: PropTypes.object

  };
  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.imageId && nextProps.imageId !== this.state.imageId) {
      this.setState({ imageId: nextProps.imageId });
    }
  }

  onImageSelected = (type, selectedId) => {
    let fieldName = '';
    switch (type) {
      case activityConstants.IMAGE_TYPE_BACKGROUND:
        fieldName = activityConstants.BACKGROUND_IMAGE_ID;
        break;
      default:
        fieldName = activityConstants.PREVIEW_IMAGE_ID;
    }

    this.props.activityActions.updateDisplayField([{
      [activityConstants.DISPLAY_TAB_FIELD_NAME]: fieldName,
      [activityConstants.DISPLAY_TAB_FIELD_VALUE]: selectedId
    }]);
    this.setState({
      imageId: selectedId,
    });

    if (this.props.onImageSelected) {
      this.props.onImageSelected(selectedId);
    }
  };

  render() {
    const { formatMessage } = this.props.intl;
    const { imageUrl, isImageSelected, selectedPreviewImage, defaultImage, images } = this.props;
    const selectedImage = this.props.selectedPreviewImage;
    const imageType = activityConstants.IMAGE_TYPE_PREVIEW;
    const imageWrapperClassNames = classNames('image-wrapper', {
      'no-image-selected': !selectedImage
    });

    // const defaultImageUrl = this.props.defaultStampImage;
    let triggerImageUrl = null;

    if (imageUrl) {
      triggerImageUrl = imageUrl;
    }

    if (selectedPreviewImage && isImageSelected) {
      triggerImageUrl = selectedPreviewImage.get(imagesConstants.URL);
    }

    const changeImageButtonText = formatMessage({
      id: selectedImage
        ? 'activity.displayTab.change.image'
        : 'activity.displayTab.select.image'
    });

    return (
      <div className="push-gallery">
        <p className="action-label">{formatMessage({ id: `activity.displayTab.${imageType}.image` })}</p>
        <Gallery
          onApply={(e, data) => this.onImageSelected(imageType, data.selectedId)}
          selectedId={this.state.imageId ? this.state.imageId : null}
          images={images}
          imageUploadInstructionsKey={'imageGallery.upload.preview.image.instruction'}
          defaultImage={defaultImage}
          defaultIfNull
          triggerNode={
            <div
              className={`image-trigger ${imageType}-image-trigger`}
              data-automation-id={`select.${imageType}.image.trigger`}
            >
              {selectedImage && triggerImageUrl ?
                <div className={imageWrapperClassNames}>
                  <Image
                    data-automation-id={`select.${imageType}.image`}
                    className="gallery-image"
                    style={{ backgroundImage: `url(${encodeURI(triggerImageUrl)})` }}
                  />
                </div>
                :
                <div className="push-gallery-default-image-container">
                  <div className="push-gallery-default-image">
                    <i className="como-svg-push-gallery-default-image"/>
                    <div className="push-gallery-default-image-overlay">
                      <i className="como-svg-push-gallery-choose-image"/>
                      <p>{formatMessage({ id: 'push.gallery.choose.image' })}</p>
                      <span>{formatMessage({ id: 'push.gallery.choose.image.description' })}</span>
                    </div>
                  </div>
                </div>
              }
              <div className="change-image-button" data-automation-id={`select.${imageType}.image.trigger.button`}>
                <Icon className="como-ic-pencil"/>{changeImageButtonText}
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  images: imageSelectors.getAllActiveImageThumbnails(state),
});

function mapDispatchToProps(dispatch) {
  return {
    activityActions: bindActionCreators(activityActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withImages = injectSaga({ key: reducerConstants.IMAGES_BRANCH, saga: imagesSaga });

export default compose(
  withImages,
  withConnect
)(injectIntl(PushNotificationGallery));
