/**
 *
 * Percentage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import InputWrapper from '../InputWrapper/InputWrapper';
import { INTEGER_TYPE } from '../inputConstants';

export const calculateDisplayValue = (value) => value === null ? '' : value.toFixed(2);

export class PercentageInputField extends React.PureComponent {

  static propTypes = {
    allowOnlyPositive: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    fluid: PropTypes.bool,
    name: PropTypes.string,
    prefix: PropTypes.string,
    value: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    allowOnlyPositive: true
  };

  constructor(props) {
    super(props);
    this.state = { displayValue: calculateDisplayValue(props.value) };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ displayValue: calculateDisplayValue(nextProps.value) });
  }

  onKeyDown = (event) => {
    if (this.props.allowOnlyPositive && event.key === '-') {
      event.preventDefault();
      return false;
    }
    return true;
  };

  onChange = (e, data) => {
    const value = !isNaN(data.value) ? data.value : null;
    this.setState({ displayValue: data.value ? calculateDisplayValue(parseFloat(data.value)) : '' });

    this.props.onChange(e, { name: data.name, value });
  };

  render() {

    const className = this.props.fluid ? this.props.className : `${this.props.className} numeric-field`;

    return (
      <InputWrapper
        className={className}
        error={this.props.error}
        disabled={this.props.disabled}
        fluid={this.props.fluid}
        icon="percent"
        name={this.props.name}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
        prefix={this.props.prefix}
        step={1}
        min={this.props.min}
        max={this.props.max}
        type={INTEGER_TYPE}
        value={this.state.displayValue}
      />
    );
  }
}

export default PercentageInputField;
