import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Grid } from 'semantic-ui-react';

import * as activityConstants from '../../../../activity.constants';
import BusinessWallets from './businessWallets/businessWallets';
import PointsInputField from '../../../../../../common/components/FormInputFields/PointsInputField/PointsInputField';

class AccumulatePointsFixed extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
    onActionFieldUpdate: PropTypes.func.isRequired,
    action: PropTypes.object,
    index: PropTypes.number,
    validationErrors: PropTypes.object,
    isNegative: PropTypes.bool
  };

  static defaultProps = {
    limit: false,
    action: {
      [activityConstants.TYPE]: activityConstants.ACCUMULATE_POINTS_TYPE_FIXED
    }
  };

  render() {
    const { intl, index, action, onActionFieldUpdate, validationErrors, automationIdPrefix, isNegative } = this.props;
    const { formatMessage } = intl;

    return (
      <Grid.Row>
        <Grid.Column width={16}>
          <div>
            <span>{formatMessage({ id: isNegative ? 'activity.action.redeemPoints.points' : 'activity.action.addPoints.points' })}</span>
            <PointsInputField
              name={activityConstants.POINTS}
              onChange={(e, data) => onActionFieldUpdate(data.name, data.value, index)}
              value={action.get(activityConstants.POINTS)}
              prefix={`${automationIdPrefix}.points`}
              error={validationErrors ? validationErrors.get(activityConstants.POINTS) : null}
              min={0}
            />
            <span>{formatMessage({ id: 'activity.action.addPoints.budgetType' })}</span>
            <BusinessWallets
              onActionFieldUpdate={(e, data) => onActionFieldUpdate(data.name, data.value, index)}
              name={activityConstants.BUDGET_TYPE}
              index={index}
              value={action.get(activityConstants.BUDGET_TYPE)}
              automationIdPrefix={`${automationIdPrefix}.budgetType`}
              error={validationErrors ? validationErrors.get(activityConstants.BUDGET_TYPE) : null}
            />
          </div>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

export default injectIntl(AccumulatePointsFixed);
