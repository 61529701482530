import { fromJS } from 'immutable';
import * as constants from './itemGroups.constants';

const initialState = fromJS({
  [constants.LIST]: null
});

function itemGroupsReducer(state = initialState, action) {
  switch (action.type) {

    case constants.GET_ITEM_GROUPS_SUCCESS: {
      return state
        .set(constants.LIST, fromJS(action.groups))
        .set(constants.ITEM_GROUPS_LOADED, true);
    }

    case constants.DEACTIVATE_ITEMS_GROUP: {
      return state.set(constants.DEACTIVATE_IN_PROGRESS, action.id);
    }

    case constants.DEACTIVATE_ITEMS_GROUP_SUCCESS:
    case constants.DEACTIVATE_ITEMS_GROUP_ERROR: {
      return state.delete(constants.DEACTIVATE_IN_PROGRESS);
    }

    default: {
      return state;
    }
  }
}

export default itemGroupsReducer;
