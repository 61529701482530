import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import * as activityConstants from '../../Activity/activity.constants';
import tracker from '../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../common/utils/tracking/tracking.consts';
import Confirmation from '../../../common/components/CustomModal/ConfirmationModal/ConfirmationModal';
import * as selectors from '../promoCodes.selectors';
import ContextMenu from '../../../common/components/ContextMenu/ContextMenu';

class PromoCodeContextMenu extends React.PureComponent {
  static propTypes = {
    onChangeActivityStatus: PropTypes.func.isRequired,
    onExtendPromoCodeStatus: PropTypes.func.isRequired,
    onDeletePromoCode: PropTypes.func.isRequired,
    onDetailsOpen: PropTypes.func.isRequired,
    promoCode: PropTypes.object.isRequired,
    promoCodeViewStatus: PropTypes.string.isRequired,
    hidden: PropTypes.bool,
    automationId: PropTypes.string,
    intl: PropTypes.object,
    detailsForTracker: PropTypes.object.isRequired,
    onStatsOpen: PropTypes.func,
    onOpenInNewTab: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showDeactivateConfirmationDialog: false
    };
  }

  getMenuOptions() {
    const { formatMessage } = this.props.intl;
    const visibleContextMenuItems = selectors.getContextMenuItemsVisibilityMap(this.props.promoCodeViewStatus);
    const options = [];
    if (visibleContextMenuItems[activityConstants.CONTEXT_MENU_OPTION_DETAILS]) {
      options.push({
        key: activityConstants.CONTEXT_MENU_OPTION_DETAILS,
        iconName: 'como-ic-info',
        text: formatMessage({ id: 'promoCodes-page.activity.menu.activity-details' }),
        cb: this.handleShowDetailsClick
      });
    }

    if (visibleContextMenuItems[activityConstants.CONTEXT_MENU_OPTION_EXTEND]) {
      options.push({
        key: activityConstants.CONTEXT_MENU_OPTION_EXTEND,
        iconName: 'como-ic-ok',
        text: formatMessage({ id: 'promoCodes-page.activity.menu.activity-extend' }),
        cb: this.handleExtendClick
      });
    }

    if (visibleContextMenuItems[activityConstants.CONTEXT_MENU_OPTION_ACTIVATE]) {
      options.push({
        key: activityConstants.CONTEXT_MENU_OPTION_ACTIVATE,
        iconName: 'como-ic-ok',
        text: formatMessage({ id: 'promoCodes-page.activity.menu.activate' }),
        cb: this.handleActivateClick
      });
    }
    if (visibleContextMenuItems[activityConstants.CONTEXT_MENU_OPTION_PAUSE]) {
      options.push({
        key: activityConstants.CONTEXT_MENU_OPTION_ACTIVATE,
        iconName: 'como-ic-deactivate',
        text: formatMessage({ id: 'promoCodes-page.activity.menu.pause' }),
        cb: this.handlePauseClick
      });
    }
    if (visibleContextMenuItems[activityConstants.CONTEXT_MENU_OPTION_DEACTIVATE]) {
      options.push({
        key: activityConstants.CONTEXT_MENU_OPTION_ACTIVATE,
        iconName: 'como-ic-expired-1',
        text: formatMessage({ id: 'promoCodes-page.activity.menu.deactivate' }),
        cb: this.handleDeactivateClick
      });
    }

    if (visibleContextMenuItems[activityConstants.CONTEXT_MENU_OPTION_DELETE]) {
      options.push({
        key: activityConstants.CONTEXT_MENU_OPTION_ACTIVATE,
        iconName: 'como-ic-delete',
        text: formatMessage({ id: 'business-center.campaign.activity.menu.delete' }),
        cb: this.handleDeleteClick
      });
    }
    if (visibleContextMenuItems[activityConstants.CONTEXT_MENU_OPTION_RESTART] && this.props.promoCode.get(activityConstants.PROMO_CODE_BULKS_TYPE) === activityConstants.PROMO_CODE_BULK_TYPE_SINGLE_CODE) {
      options.push({
        key: activityConstants.CONTEXT_MENU_OPTION_ACTIVATE,
        iconName: 'como-ic-ok',
        text: formatMessage({ id: 'business-center.campaign.activity.menu.restart' }),
        cb: this.handleRestartClick
      });
    }

    if (visibleContextMenuItems[activityConstants.CONTEXT_MENU_OPTION_STATS]) {
      options.push({
        key: activityConstants.CONTEXT_MENU_OPTION_STATS,
        iconName: 'como-ic-activity-analysis',
        text: formatMessage({ id: 'promoCodes-page.activity.menu.analysis' }),
        cb: this.handleShowStatsClick
      });
    }
    options.push({
      key: 'openInNewTab',
      className: 'benefit-action-list-item-set-up-key',
      iconName: 'como-ic-arrow-right',
      text: formatMessage({ id: 'business-center.campaign.activity.menu.openInNewTab' }),
      cb: () => this.props.onOpenInNewTab(this.props.promoCode)
    });

    return options;
  }

  handleShowDetailsClick = () => {
    tracker.onButtonClick(trackerConstants.BUTTON_TYPE_SHOW_ACTIVITY_DETAILS, this.props.detailsForTracker);
    this.props.onDetailsOpen();
  };

  handleActivateClick = () => {
    tracker.onButtonClick(trackerConstants.BUTTON_TYPE_RE_ACTIVATE_ACTIVITY, this.props.detailsForTracker);
    const updatedActivity = this.props.promoCode.set(activityConstants.STATUS, activityConstants.ACTIVITY_STATUS_ACTIVE);
    this.props.onChangeActivityStatus(updatedActivity);
  };

  handleExtendClick = () => {
    tracker.onButtonClick(trackerConstants.BUTTON_TYPE_EXTEND_ACTIVITY, this.props.detailsForTracker);
    this.props.onExtendPromoCodeStatus(this.props.promoCode);
  };

  handlePauseClick = () => {
    tracker.onButtonClick(trackerConstants.BUTTON_TYPE_PAUSE_ACTIVITY, this.props.detailsForTracker);
    const updatedActivity = this.props.promoCode.set(activityConstants.STATUS, activityConstants.ACTIVITY_STATUS_PAUSED);
    this.props.onChangeActivityStatus(updatedActivity);
  };

  handleOnDeactivateConfirmed = () => {
    tracker.onButtonClick(trackerConstants.BUTTON_TYPE_STOP_ACTIVITY, this.props.detailsForTracker);
    const updatedActivity = this.props.promoCode.set(activityConstants.STATUS, activityConstants.ACTIVITY_STATUS_INACTIVE);
    this.props.onChangeActivityStatus(updatedActivity);
    this.setState({ showDeactivateConfirmationDialog: false });
  };

  handleDeactivateClick = () => {
    this.setState({ showDeactivateConfirmationDialog: true });
  };

  handleDeleteClick = () => {
    tracker.onButtonClick(trackerConstants.BUTTON_TYPE_DELETE_ACTIVITY, this.props.detailsForTracker);
    this.props.onDeletePromoCode(this.props.promoCode.get(activityConstants.HUB_ID));
  };

  handleShowStatsClick = () => {
    tracker.onButtonClick(trackerConstants.BUTTON_TYPE_DELETE_ACTIVITY, this.props.detailsForTracker);
    this.props.onStatsOpen(this.props.promoCode.get(activityConstants.HUB_ID));
  };

  handleRestartClick = () => {
    tracker.onButtonClick(trackerConstants.BUTTON_TYPE_RESTART_ACTIVITY, this.props.detailsForTracker);
    const updatedActivity = this.props.promoCode.set(activityConstants.STATUS, activityConstants.ACTIVITY_STATUS_ACTIVE);
    this.props.onChangeActivityStatus(updatedActivity);
  }

  render() {
    return (
      <React.Fragment>
        <Confirmation
          open={this.state.showDeactivateConfirmationDialog}
          onConfirm={this.handleOnDeactivateConfirmed}
          onCancel={() => this.setState({ showDeactivateConfirmationDialog: false })}
          size="small"
          className="promo-codes-como-dialog"
        >
          <div>
            {this.props.intl.formatMessage({ id: 'promo.code.deactivate.confirmation.dialog.body' })}
          </div>
        </Confirmation>
        <ContextMenu
          options={this.getMenuOptions()}
          automationId={this.props.automationId}
          hidden={this.props.hidden}
        />
      </React.Fragment>);
  }
}

export default injectIntl(PromoCodeContextMenu);
