import { delay } from 'redux-saga';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as constants from './itemGroups.constants';
import * as api from './itemGroups.api';
import * as errorConstant from '../ErrorMessage/errorMessage.constants';

function* getItemGroups() {
  // debounce by 50ms
  yield delay(50);
  try {
    const groups = yield call(api.getItemGroups);
    yield put({ type: constants.GET_ITEM_GROUPS_SUCCESS, groups });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getItemGroups.Fail'] });
  }
}

/**
 * save new itemsGroup
 * if successful, get itemGroups again
 * @param action
 */
function* createItemGroups({ itemsGroup }) {
  try {
    const group = yield call(api.createItemsGroup, itemsGroup.toJS());
    if (group.hubId) {
      yield getItemGroups();
      yield put({ type: constants.CREATE_ITEMS_GROUP_SUCCESS, group });
    } else {
      yield put({ type: constants.CREATE_ITEMS_GROUP_ERROR });
    }
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['createItemsGroup.Fail'] });
  }
}

/**
 * update existing itemsGroup
 * @param action
 */
function* updateItemGroups({ itemsGroup }) {
  try {
    yield call(api.updateItemsGroup, itemsGroup.toJS());
    yield getItemGroups();
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['updateItemsGroup.Fail'] });
  }
}

function* deactivateItemGroups({ itemsGroupId, serverId }) {
  try {
    yield call(api.deactivateItemGroups, itemsGroupId);
    yield getItemGroups();
    yield put({ type: constants.DEACTIVATE_ITEMS_GROUP_SUCCESS, serverId });
    yield put({ type: constants.DEACTIVATE_ITEMS_GROUP_ERROR });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['deactivateItemGroups.Fail'] });
  }
}

// sagas listen to dispatched actions too (same as reducers)
function* itemGroupsSaga() {
  yield [
    takeLatest(constants.GET_ITEM_GROUPS, getItemGroups),
    takeEvery(constants.CREATE_ITEMS_GROUP, createItemGroups),
    takeEvery(constants.UPDATE_ITEMS_GROUP, updateItemGroups),
    takeEvery(constants.DEACTIVATE_ITEMS_GROUP, deactivateItemGroups),
  ];
}

export default itemGroupsSaga;
