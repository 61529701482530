import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Header } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import * as constants from '../../activity.constants';
import DisabledItemPosRestriction from '../../../../common/components/DisabledItem/DisabledItemPosRestriction';
import * as appConstants from '../../../App/app.constants';
import DisabledItem from '../../../../common/components/DisabledItem/DisabledItem';


class ActionsPopupContent extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string.isRequired,
    onSelectOption: PropTypes.func.isRequired,
    options: PropTypes.object,
    showSimpleDropdown: PropTypes.bool,
    getBusinessBundlePlan: PropTypes.func.isRequired,
    hasFullPermission: PropTypes.func,
    activityType: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (option) => {

    if (!this.hasFeature(option)) {
      return;
    }

    this.props.onSelectOption(option.key);
  };

  hasFeature = (option) => {
    const features = option[constants.FEATURES];

    if (features) {
      return !features.some((feature) => feature[constants.FIELD_LEVEL_KEY] === constants.FEATURE_LEVEL.NONE ||
                feature[constants.FIELD_LEVEL_KEY] === constants.FEATURE_LEVEL.READ);
    }

    return true;
  };

  getDisallowedFeatureCategory = (option) => {
    const features = option[constants.FEATURES];

    if (features) {
      const disallowedFeatures = features.filter((feature) => feature[constants.FIELD_LEVEL_KEY] === constants.FEATURE_LEVEL.NONE || feature[constants.FIELD_LEVEL_KEY] === constants.FEATURE_LEVEL.READ);
      // this is temp solution for pos tooltip only
      if (disallowedFeatures && disallowedFeatures.some((feature) => feature.category === appConstants.FEATURES_CATEGORY_POS)) {
        return appConstants.FEATURES_CATEGORY_POS;
      } else if (disallowedFeatures && disallowedFeatures.some((feature) => feature.category === appConstants.FEATURES_CATEGORY_NO_POS_INTEGRATION)) {
        return appConstants.FEATURES_CATEGORY_NO_POS_INTEGRATION;
      } else if (disallowedFeatures && disallowedFeatures.some((feature) => feature.category === appConstants.FEATURES_CATEGORY_ADD_ON)) {
        return appConstants.FEATURES_CATEGORY_ADD_ON;
      }
    }
    return null;
  };


  getOption = (action, hasFeature, featureCategory) => {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const optionText = formatMessage({ id: `activity.action.${action.key}` });
    const bundlePlan = this.props.getBusinessBundlePlan();
    if (hasFeature) {
      return optionText;
    }
    switch (featureCategory) {
      case appConstants.FEATURES_CATEGORY_POS:
        return (
          <DisabledItemPosRestriction>
            {optionText}
          </DisabledItemPosRestriction>
        );
      case appConstants.FEATURES_CATEGORY_NO_POS_INTEGRATION:
        return (
          <DisabledItem tooltipMessage={formatMessage({ id: 'tooltip.no-pos.integration' })} automationId="pos">
            {optionText}
          </DisabledItem>
        );
      case appConstants.FEATURES_CATEGORY_ADD_ON:
        return (
          <DisabledItem
            tooltipMessage={bundlePlan === appConstants.REVEL ? formatMessage({id: 'revel.activity.triggers.add-on.is.not.available'}) : formatMessage({id: 'activity.triggers.add-on.is.not.available'})}
            automationId="pos"
            icon="como-ic-add-on"
          >
            {optionText}
          </DisabledItem>
        );
      default:
        return optionText;
    }
  };

  render() {

    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const options = Object.keys(this.props.options).length === 0 && this.props.options.constructor === Object ? [] : this.props.options.toJS();
    return (
      <ul className="two-columned grouped">
        {
          options.map((optionsGroup) => {
            const showGroup = optionsGroup.actionFields.some((action) => this.hasFeature(action));
            return showGroup && (
              <li key={optionsGroup.key} data-automation-id={`action.group.${optionsGroup.key}`}>
                {!this.props.showSimpleDropdown &&
                  <Header>
                    <i className={`como-svg-icon-${optionsGroup.key} trigger-icon`} />
                    {formatMessage({ id: `activity.action.group.${optionsGroup.key}` })}
                  </Header>
                }
                <ul className="selectable-options">
                  {optionsGroup.actionFields.map((action) => {
                    // if oneTime and essentials plan and Revel Bundle and has no feature then hide
                    // else if has no feature then disable
                    // else show
                    const isEssentials = this.props.activityType === constants.ACTIVITY_TYPE_ONE_TIME && !this.props.hasFullPermission(appConstants.FEATURE_NAME_ESSENTIALS_OFF);
                    const hasActionFeature = this.hasFeature(action);
                    const plan = this.props.getBusinessBundlePlan();
                    const hide = (isEssentials && plan === appConstants.REVEL && !hasActionFeature);
                    let overrideHasFeature = false;
                    if ((action.key === 'sendPersonalPush') || (action.key === 'sendMessage')) {
                      if (plan !== appConstants.REVEL) {
                        const features = action[constants.FEATURES];
                        const appFeature = features.find((feature) => feature[constants.NAME] === 'App');
                        overrideHasFeature = appFeature && appFeature[constants.FIELD_LEVEL_KEY] === constants.FEATURE_LEVEL.FULL;
                      }
                    }
                    const hasFeature = overrideHasFeature || (!(action.key === 'sendPersonalPush' && this.props.activityType === constants.ACTIVITY_TYPE_ONE_TIME && !this.props.hasFullPermission('PushForOneTime')) && this.hasFeature(action));
                    const actionStyle = hasFeature
                      ? classNames({separated: action.key === 'ItemDealCode'})
                      : 'disabled';
                    let featureCategory = '';
                    if (!hasFeature) {
                      featureCategory = this.getDisallowedFeatureCategory(action);
                    }
                    return !hide && (
                      <li
                        key={action.key}
                        className={actionStyle}
                        onClick={() => !hasFeature ? null : this.handleClick(action)}
                        data-automation-id={`${this.props.automationIdPrefix}.${action.automationId ? action.automationId : action.key}`}
                      >
                        {
                          this.getOption(action, hasFeature, featureCategory)
                        }

                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })
        }
      </ul>
    );

  }
}

export default injectIntl(ActionsPopupContent);
