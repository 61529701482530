import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import PopupWrapper from '../../../../../../common/components/PopupWrapper/PopupWrapper';
import PopupTrigger from '../../../../../../common/components/PopupTrigger/PopupTrigger';
import EmailsListContent from './EmailsListContent';


export class EmailsList extends React.PureComponent {

  static propTypes = {
    loadingData: PropTypes.bool,
    emailsList: PropTypes.object,
    onActionFieldUpdate: PropTypes.func,
    action: PropTypes.object,
    index: PropTypes.number,
    automationIdPrefix: PropTypes.string,
    error: PropTypes.object,
    selectedEmail: PropTypes.object,
    createEmailFromActivity: PropTypes.func,
    allowCreateEmail: PropTypes.bool

  };

  constructor(props) {
    super(props);

    this.state = {
      isPopupOpen: false
    };
  }

  getEmailListTriggerValue = () => {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const selectedEmail = this.props.selectedEmail;
    if (selectedEmail) {
      return selectedEmail.get('name');
    }
    return formatMessage({ id: 'activity.action.select.externalTemplateId' });
  };


  handlePopupOpen = () => {
    this.setState({
      isPopupOpen: !this.props.loadingData
    });
  };

  handlePopupClose = () => {
    this.setState({
      isPopupOpen: false
    });
  };

  onCreateEmail = () => {
    this.props.createEmailFromActivity(this.props.index);
  };

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const benefitsListTriggerClassName = classNames('ui fluid selection dropdown complex-list-trigger', {
      'inputFieldError': this.props.error,
      'placeholder': !this.props.selectedEmail
    });
    return (
      <PopupWrapper
        className="complex-list"
        disabled={this.props.loadingData}
        hideTrigger
        on="click"
        onOpen={this.handlePopupOpen}
        onClose={this.handlePopupClose}
        open={this.state.isPopupOpen}
        position="bottom left"
        automationId={`${this.props.automationIdPrefix}.popup`}
        trigger={(
          <div className="inline-div">
            <PopupTrigger
              automationId={`${this.props.automationIdPrefix}.externalTemplateId`}
              className={benefitsListTriggerClassName}
              message={this.getEmailListTriggerValue()}
              validationErrorMessage={this.props.error ? formatMessage({ id: this.props.error }) : null}
              validationErrorClassName={this.props.error ? 'complex-list-error' : ''}
            />
          </div>
        )}
      >
        <EmailsListContent
          loadingData={this.props.loadingData}
          emailsList={this.props.emailsList}
          onActionFieldUpdate={this.props.onActionFieldUpdate}
          action={this.props.action}
          index={this.props.index}
          automationIdPrefix={this.props.automationIdPrefix}
          allowCreateEmail={this.props.allowCreateEmail}
          handlePopupClose={this.handlePopupClose}
          onCreateEmail={this.onCreateEmail}
        />
      </PopupWrapper>
    );
  }
}

export default injectIntl(EmailsList);
