import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Icon } from 'semantic-ui-react';

export default class SingleLineAccordion extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string.isRequired,
    classNames: PropTypes.string,
    initialCollapseState: PropTypes.bool,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    validationErrors: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      collapsed: props.initialCollapseState
    };
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.validationErrors && !this.props.validationErrors) {
      this.setState({ collapsed: false });
    }
  }

  toggleCollapse() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  render() {
    return (
      <Accordion data-automation-id={`${this.props.automationIdPrefix}.limits`} className={`single ${this.props.classNames}`}>
        <Accordion.Title active={!this.state.collapsed} onClick={this.toggleCollapse}>
          <Icon className={`como-ic-angle-double-${this.state.collapsed ? 'right' : 'down'}`} />
          {this.props.title}
        </Accordion.Title>
        <Accordion.Content active={!this.state.collapsed}>
          {this.props.children}
        </Accordion.Content>
      </Accordion>
    );
  }
}
