import * as membershipChangedConstants from './updateMembership.constants';

export function copyDataToTemp(value, conditionSchema) {
  return {
    type: membershipChangedConstants.UPDATE_MEMBERSHIP_COPY_DATA_TO_TEMP,
    value,
    conditionSchema,
  };
}

export function updateTempData(origin, key, value) {
  return {
    type: membershipChangedConstants.UPDATE_MEMBERSHIP_UPDATE_TEMP_DATA,
    origin,
    key,
    value,
  };
}

export function resetTempData(conditionSchema) {
  return {
    type: membershipChangedConstants.UPDATE_MEMBERSHIP_RESET_TEMP_DATA,
    conditionSchema
  };
}

export function clearTempData() {
  return {
    type: membershipChangedConstants.UPDATE_MEMBERSHIP_CLEAR_TEMP_DATA,
  };
}
