import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import * as constants from './../../activity.constants';
import * as schemaConstants from '../../activitySchema/activitySchema.constants';
import BenefitsList from '../../../Benefits/views/benefitsList/benefitsList';
import Boolean from '../../../../common/components/FormInputFields/Boolean/Boolean';
import CaseCapsules from '../../../../common/components/Capsules/CaseCapsules';
import ConditionListOfValues from './conditionListOfValues';
import ConditionMonthAndDay from './conditionMonthAndDay';
import CurrencyInputField from '../../../../common/components/FormInputFields/CurrencyInputField/CurrencyInputField';
import Date from '../../../../common/components/FormInputFields/DateInputField/DateInputField';
import DateTimeField from './dateTimeConditions/DateTimeField';
import DaysTimesField from './dateTimeConditions/DaysTimesField';
import FormsList from '../../../../common/components/FormsList/FormsList';
import InputWrapper from '../../../../common/components/FormInputFields/InputWrapper/InputWrapper';
import IntegerInputField from '../../../../common/components/FormInputFields/IntegerInputField/IntegerInputField';
import MembershipChangedContainer from './updateMembershipCondition/UpdateMembership.container';
import PointsInputField from '../../../../common/components/FormInputFields/PointsInputField/PointsInputField';
import UpdateMembershipOperation from './updateMembershipCondition/UpdateMembershipOperation';
import ReferralCodeCondition from './globalConditions/filters/referralCodeCondition/ReferralCodeCondition';
import LocationsListCondition from './locationsListCondition';
import LocationsGroupsListCondition from './locationsGroupsListCondition';
import EmailTemplateList from './emailTemplateList';
import PercentageIntegerInputField from '../../../../common/components/FormInputFields/PercentageIntegerInputField/PercentageIntegerInputField';
import ABTestCondition from './ABTestCondition';
import ActivitiesList from '../../../../common/components/ActivitiesList/activitiesList';

class ConditionValue extends React.PureComponent {

  static propTypes = {
    addDaysTimesCondition: PropTypes.func,
    condition: PropTypes.object,
    deleteDaysTimesCondition: PropTypes.func,
    disabled: PropTypes.bool,
    index: PropTypes.number,
    intl: PropTypes.object,
    isPastDateRange: PropTypes.bool,
    isInfiniteDateRange: PropTypes.bool,
    onConditionUpdate: PropTypes.func,
    prefix: PropTypes.string,
    selectedConditionSchema: PropTypes.object,
    trigger: PropTypes.string,
    updateDateRangeDate: PropTypes.func,
    updateDaysTimes: PropTypes.func,
    validationErrors: PropTypes.object,
    userActionIndex: PropTypes.number,
    abTestMode: PropTypes.bool,
    referralTempCondition: PropTypes.object,
    createReferralCodeTempModel: PropTypes.func,
    deleteReferralCodeTempModel: PropTypes.func,
    saveReferralCodeTempModelToGlobalConditions: PropTypes.func,
    referralTempError: PropTypes.string,
    datePickerInsideModal: PropTypes.bool,
    hasAutoComplete: PropTypes.bool,
    autoCompleteOptions: PropTypes.array,
    isInsideUserAction: PropTypes.bool
  };

  getPlaceholderValues = (values) => values
    ? values.reduce((variables, val, key) => {
      variables[key] = !isNaN(parseFloat(val)) ? val : this.props.intl.formatMessage({ id: val });
      return variables;
    }, {})
    : {};

  get computedConditionValue() {
    const conditionValue = this.props.condition.get(constants.CONDITION_VALUE);
    if (conditionValue && typeof conditionValue.toJS === 'function') {
      return conditionValue.toJS();
    }
    return conditionValue;
  }

  render() {
    const { addDaysTimesCondition, condition, deleteDaysTimesCondition, index, intl, onConditionUpdate, prefix,
      selectedConditionSchema, trigger, updateDateRangeDate, updateDaysTimes, validationErrors } = this.props;
    if (!selectedConditionSchema) {
      return null;
    }
    const automationIdPrefix = prefix || '';
    let valueTypeComponent = null;
    let conditionValue = this.computedConditionValue;
    const conditionValueType = selectedConditionSchema.get(constants.VALUE_TYPE);
    switch (conditionValueType) {
      case schemaConstants.VALUE_TYPE_BEACON_SIGNALS_LIST:
        valueTypeComponent = <ConditionListOfValues type={schemaConstants.VALUE_TYPE_BEACON_SIGNALS_LIST} />;
        break;
      case schemaConstants.VALUE_TYPE_BENEFITS_LIST:
        if ([schemaConstants.TRIGGER_PUNCH, schemaConstants.TRIGGER_EXCEEDED_PUNCH].indexOf(trigger) > -1) {
          valueTypeComponent = <BenefitsList benefitType={constants.BENEFIT_PUNCH_CARD} />;
        } else if ([schemaConstants.TRIGGER_PURCHASED_ASSET].indexOf(trigger) > -1) {
          valueTypeComponent = <BenefitsList benefitType={constants.BENEFIT_GIFT} />;
        } else {
          valueTypeComponent = <BenefitsList />;
        }
        break;
      case schemaConstants.VALUE_TYPE_BOOLEAN:
        valueTypeComponent = <Boolean />;
        break;
      case schemaConstants.VALUE_TYPE_CATALOG_ITEMS_LIST:
        valueTypeComponent = <ConditionListOfValues type={schemaConstants.VALUE_TYPE_CATALOG_ITEMS_LIST} />;
        break;
      case schemaConstants.VALUE_TYPE_CURRENCY:
        valueTypeComponent = <CurrencyInputField fluid />;
        break;
      case schemaConstants.VALUE_TYPE_PERCENTAGE_INTEGER:
        if (this.props.abTestMode) {
          valueTypeComponent = <ABTestCondition />;
        } else {
          valueTypeComponent = <PercentageIntegerInputField />;
        }
        break;
      case schemaConstants.VALUE_TYPE_POINTS:
        valueTypeComponent = <PointsInputField fluid />;
        conditionValue = conditionValue || 0;
        break;
      case schemaConstants.VALUE_TYPE_DATE:
        valueTypeComponent = <Date />;
        break;
      case schemaConstants.VALUE_TYPE_FORMS_LIST:
        valueTypeComponent = <FormsList />;
        break;
      case schemaConstants.VALUE_TYPE_NUMBER:
        valueTypeComponent = <IntegerInputField fluid />;
        break;
      case schemaConstants.VALUE_TYPE_PAYMENT_FLOW:
        valueTypeComponent = <ConditionListOfValues type={schemaConstants.VALUE_TYPE_PAYMENT_FLOW} />;
        break;
      case schemaConstants.VALUE_TYPE_PAYMENT_FLOW_IN_APP:
        valueTypeComponent = <ConditionListOfValues type={schemaConstants.VALUE_TYPE_PAYMENT_FLOW_IN_APP} />;
        break;
      case schemaConstants.FILTER_VALUE_TYPE_PAYMENT_FLOW_IN_APP:
        valueTypeComponent = <ConditionListOfValues type={schemaConstants.FILTER_VALUE_TYPE_PAYMENT_FLOW_IN_APP} multiple />;
        break;
      case schemaConstants.VALUE_TYPE_MEMBERSHIP_STATUS_LIST:
        valueTypeComponent = <ConditionListOfValues type={schemaConstants.VALUE_TYPE_MEMBERSHIP_STATUS_LIST} multiple />;
        break;
      case schemaConstants.VALUE_TYPE_MEMBERSHIP_CONSENT_LIST:
        valueTypeComponent = <ConditionListOfValues type={schemaConstants.VALUE_TYPE_MEMBERSHIP_CONSENT_LIST} multiple />;
        break;

      case schemaConstants.VALUE_TYPE_JOINING_SOURCE:
        valueTypeComponent = <ConditionListOfValues type={schemaConstants.VALUE_TYPE_JOINING_SOURCE} multiple />;
        break;
      case schemaConstants.VALUE_TYPE_ASSET_SOURCE_LIST:
        valueTypeComponent = <ConditionListOfValues type={schemaConstants.VALUE_TYPE_ASSET_SOURCE_LIST} multiple />;
        break;
      case schemaConstants.VALUE_TYPE_UPDATE_MEMBERSHIP_OPERATION:
        valueTypeComponent = <UpdateMembershipOperation />;
        break;
      case schemaConstants.VALUE_TYPE_STRING:
        if ([constants.IS_ONE_OF, constants.IS_NOT_ONE_OF].indexOf(condition.get(constants.OPERATOR_KEY)) >= 0) {
          if (!conditionValue) {
            conditionValue = [];
          } else if (!Array.isArray(conditionValue)) {
            conditionValue = conditionValue.split(',');
          }
          valueTypeComponent = <CaseCapsules fluid type={condition.get(constants.CONDITION_KEY)} />;
        } else {
          conditionValue = Array.isArray(conditionValue) ? conditionValue.join(', ') : conditionValue || '';
          valueTypeComponent = <InputWrapper fluid />;
        }
        break;
      case schemaConstants.VALUE_TYPE_ARRAY:
        conditionValue = conditionValue || [];
        if (this.props.hasAutoComplete && this.props.autoCompleteOptions) {
          valueTypeComponent = (<ConditionListOfValues
            type={schemaConstants.VALUE_TYPE_AUTO_COMPLETE}
            dynamicOptions={this.props.autoCompleteOptions}
            multiple
            allowAdditions
            className="auto-complete-wrapper"
          />);
        } else {
          valueTypeComponent = <CaseCapsules fluid type={condition.get(constants.CONDITION_KEY)} />;
        }
        break;
      case schemaConstants.VALUE_TYPE_DATE_TIME:
        valueTypeComponent =
          (<DateTimeField
            condition={condition}
            isPastDateRange={this.props.isPastDateRange}
            isInfiniteDateRange={this.props.isInfiniteDateRange}
            isInsideUserAction={this.props.isInsideUserAction}
            updateDateRangeDate={updateDateRangeDate}
            insideModal={this.props.datePickerInsideModal}

          />);
        break;
      case schemaConstants.VALUE_TYPE_DAYS_TIMES:
        valueTypeComponent =
          (<DaysTimesField
            condition={condition}
            addDaysTimesCondition={addDaysTimesCondition}
            deleteDaysTimesCondition={deleteDaysTimesCondition}
            updateDaysTimes={updateDaysTimes}
          />);
        break;
      case schemaConstants.VALUE_TYPE_MONTHS_LIST:
        valueTypeComponent = <ConditionListOfValues type={schemaConstants.VALUE_TYPE_MONTHS_LIST} multiple />;
        break;
      case schemaConstants.VALUE_TYPE_MONTHS_AND_DAYS_LIST:
        valueTypeComponent = <ConditionMonthAndDay type={schemaConstants.VALUE_TYPE_MONTHS_LIST} />;
        break;
      case schemaConstants.VALUE_TYPE_MEMBERSHIP_RFM_LIST:
        valueTypeComponent = <ConditionListOfValues type={schemaConstants.VALUE_TYPE_MEMBERSHIP_RFM_LIST} multiple />;
        break;
      case schemaConstants.VALUE_TYPE_MEMBERSHIP_LAST_VISIT_LIST:
        valueTypeComponent = <ConditionListOfValues type={schemaConstants.VALUE_TYPE_MEMBERSHIP_LAST_VISIT_LIST} multiple />;
        break;
      case schemaConstants.CONTEXT_FAIL_SMS_TYPE:
        valueTypeComponent = <ConditionListOfValues type={schemaConstants.CONTEXT_FAIL_SMS_TYPE} multiple />;
        break;
      case schemaConstants.CONTEXT_FAIL_SMS_STEP:
        valueTypeComponent = <ConditionListOfValues type={schemaConstants.CONTEXT_FAIL_SMS_STEP} multiple />;
        break;
      case schemaConstants.VALUE_TYPE_ACTIVITIES:
        valueTypeComponent = <ActivitiesList />;
        break;
      case schemaConstants.VALUE_TYPE_DYNAMIC:
        valueTypeComponent = (
          <ConditionListOfValues
            type={schemaConstants.VALUE_TYPE_DYNAMIC}
            dynamicOptions={selectedConditionSchema.get(schemaConstants.OPTIONS)}
            multiple
          />);
        break;
      case schemaConstants.VALUE_TYPE_GAVE_CONSENT_SOURCE:
        valueTypeComponent = <ConditionListOfValues type={schemaConstants.VALUE_TYPE_GAVE_CONSENT_SOURCE} multiple />;
        break;
      case schemaConstants.VALUE_TYPE_REFERRAL_DATA:
        valueTypeComponent = (
          <ReferralCodeCondition
            userActionIndex={this.props.userActionIndex}
            operatorKey={this.props.condition.get(constants.OPERATOR_KEY)}
            tempCondition={this.props.referralTempCondition}
            tempError={this.props.referralTempError}
            createReferralCodeTempModel={this.props.createReferralCodeTempModel}
            deleteReferralCodeTempModel={this.props.deleteReferralCodeTempModel}
            saveReferralCodeTempModelToGlobalConditions={this.props.saveReferralCodeTempModelToGlobalConditions}
          />);
        break;
      case schemaConstants.VALUE_TYPE_LOCATIONS:
        valueTypeComponent = <LocationsListCondition conditionType={condition.get(constants.CONDITION_KEY)} />;
        break;
      case schemaConstants.DATA_VALUE_TYPE_LOCATIONS_GROUPS:
        valueTypeComponent = <LocationsGroupsListCondition conditionType={condition.get(constants.CONDITION_KEY)} />;
        break;
      case schemaConstants.VALUE_TYPE_SENT_FROM:
        valueTypeComponent = <ConditionListOfValues type={schemaConstants.VALUE_TYPE_SENT_FROM} multiple />;
        break;
      case schemaConstants.VALUE_TYPE_EMAIL_TEMPLATE:
        valueTypeComponent = <EmailTemplateList multiple />;
        break;
      default:
        valueTypeComponent = <InputWrapper fluid />;
    }
    if (
      trigger === schemaConstants.TRIGGER_UPDATE_MEMBERSHIP &&
      !selectedConditionSchema.has(constants.SCHEMA_CONDITION_GROUP) &&
      ![schemaConstants.CODE_TAG, schemaConstants.OPERATION, schemaConstants.PROBABILITY].includes(condition.get(constants.CONDITION_KEY))
    ) {
      valueTypeComponent = <MembershipChangedContainer conditionSchema={selectedConditionSchema} />;
    }
    return React.cloneElement(valueTypeComponent, {
      value: conditionValue,
      onChange: onConditionUpdate,
      disabled: this.props.disabled,
      index,
      hasAutoComplete: this.props.hasAutoComplete,
      autoCompleteOptions: this.props.autoCompleteOptions,
      error: validationErrors ? validationErrors.get(constants.CONDITION_VALUE) : null,
      prefix: `${automationIdPrefix}.${constants.CONDITION_VALUE}`,
      placeholder: selectedConditionSchema.get(constants.PLACEHOLDER)
        ? intl.formatMessage({ id: selectedConditionSchema.getIn([constants.PLACEHOLDER, constants.KEY]) },
                this.getPlaceholderValues(selectedConditionSchema.getIn([constants.PLACEHOLDER, constants.TRANSLATION_ARGS])))
        : ''
    });
  }
}

export default injectIntl(ConditionValue);
