import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { BUSINESS_CENTER_ROUTE } from '../../../../constants/route.contants';

import './CloseLink.styles.less';

const AUTOMATION_NAME = 'link.close';

const CloseLink = (props) =>
  (<Link
    to={{ pathname: `${BUSINESS_CENTER_ROUTE}`, search: location.search }}
    className="close"
    data-automation-id={AUTOMATION_NAME}
    {...props}
  >
    <Icon className="como-ic-close" />
  </Link>
  );

export default CloseLink;
