import moment from 'moment';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Accordion, Button, Radio, Transition } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import * as activityConstants from '../../../../activity.constants';
import IntegerInputField from '../../../../../../common/components/FormInputFields/IntegerInputField/IntegerInputField';
import PopupWrapper from '../../../../../../common/components/PopupWrapper/PopupWrapper';
import PopupTrigger from '../../../../../../common/components/PopupTrigger/PopupTrigger';
import DropdownWrapper from '../../../../../../common/components/DropdownWrapper/DropdownWrapper';
import * as dateConstants from '../../../../../../common/utils/dateTime.constants';

class InstanceExpirationConditionPopup extends React.PureComponent {

  static propTypes = {
    actualValue: PropTypes.object,
    tempValue: PropTypes.object,
    error: PropTypes.string,
    tempError: PropTypes.string,
    deactivationMode: PropTypes.bool,
    intl: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    isPristine: PropTypes.bool,
    automationIdPrefix: PropTypes.string,
    datetimeGlobalCondition: PropTypes.object,
    localeDateFormat: PropTypes.string,
    businessTimezone: PropTypes.string,
    translationsNamespace: PropTypes.string,
    activityType: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = { isOpen: false, animate: false };
  }

  get popupTriggerText() {
    const { actualValue, intl, deactivationMode } = this.props;
    if (actualValue.get(activityConstants.ACTIVATION_MODE) === activityConstants.ACTIVATION_MODE_DEFAULT) {
      if (deactivationMode) {
        return this.expirationDate.format(this.props.localeDateFormat);
      }
      if (this.activationDate.isSameOrBefore(moment.tz(this.props.businessTimezone))) {
        return intl.formatMessage({ id: 'activation.condition.activation.immediately' });
      }
      return intl.formatMessage({ id: 'activation.condition.activation.trigger.future' },
        { date: this.activationDate.format(this.props.localeDateFormat) });
    }
    const timeValue = actualValue.get(activityConstants.ACTIVATION_SPECIFIC_TIME_VALUE);
    const timeUnit = actualValue.get(activityConstants.ACTIVATION_SPECIFIC_TIME_UNIT);
    const timespan = intl.formatMessage({ id: `activation.condition.trigger.text.time.unit.${timeUnit}` }, { value: timeValue });
    if (deactivationMode) {
      return intl.formatMessage({ id: 'activation.condition.expiration.after.receiving.it.trigger' }, { timespan });
    }
    return intl.formatMessage({ id: 'activation.condition.activation.date.after' }, { timespan });
  }

  get dropDownOptions() {
    const { formatMessage } = this.props.intl;
    const timeValue = this.props.tempValue.get(activityConstants.ACTIVATION_SPECIFIC_TIME_VALUE);
    const intervals = this.props.activityType === activityConstants.ACTIVITY_TYPE_PUNCH_CARD ? [dateConstants.DAY, dateConstants.WEEK] : [dateConstants.MINUTE, dateConstants.HOUR, dateConstants.DAY, dateConstants.WEEK];
    return intervals
      .map((value) => ({
        value,
        text: formatMessage({ id: `activation.condition.time.unit.${value}` }, { value: timeValue })
      }));
  }

  get expirationDate() {
    return moment.utc(this.props.datetimeGlobalCondition.getIn(
      [activityConstants.DATES_RANGE_CONDITION, activityConstants.CONDITION_VALUE, dateConstants.DATE_TO])).tz(this.props.businessTimezone);
  }

  get activationDate() {
    return moment.utc(this.props.datetimeGlobalCondition.getIn(
      [activityConstants.DATES_RANGE_CONDITION, activityConstants.CONDITION_VALUE, dateConstants.DATE_FROM])).tz(this.props.businessTimezone);
  }

  get firstRadioButtonLabel() {
    const { translationsNamespace } = this.props;
    if (this.props.deactivationMode) {
      return this.props.intl.formatMessage({ id: `${translationsNamespace}.activation.condition.expiration.date` });
    }
    return this.props.intl.formatMessage({
      id: this.activationDate.isSameOrBefore(moment.tz(this.props.businessTimezone))
        ? 'activation.condition.activation.immediately'
        : 'activation.condition.activation.date'
    });
  }

  get popupContent() {
    const { tempValue, tempError } = this.props;
    if (!tempValue) {
      return null;
    }
    const { formatMessage } = this.props.intl;
    const isDefaultModeSelected = tempValue.get(activityConstants.ACTIVATION_MODE) === activityConstants.ACTIVATION_MODE_DEFAULT;
    const timeUnit = tempValue.get(activityConstants.ACTIVATION_SPECIFIC_TIME_UNIT);

    return (
      <Fragment>
        <Accordion className="activity-instance-activation-popup-accordion">
          <Accordion.Title
            active={isDefaultModeSelected}
            data-automation-id="options.ontime"
          >
            <Radio
              checked={isDefaultModeSelected}
              label={this.firstRadioButtonLabel}
              name={activityConstants.ACTIVATION_MODE}
              onClick={(e) => this.handleChangeMode(e, activityConstants.ACTIVATION_MODE_DEFAULT)}
              className="head-of-sentence"
            />
          </Accordion.Title>
          <Accordion.Title
            data-automation-id="options.time"
            className="options-time"
          >
            <Radio
              checked={!isDefaultModeSelected}
              name={activityConstants.ACTIVATION_MODE}
              onClick={(e) => this.handleChangeMode(e, activityConstants.ACTIVATION_MODE_ON_SPECIFIC_TIME)}
              className="head-of-sentence"
            />
            <span className="inside-sentence">
              <IntegerInputField
                value={tempValue.get(activityConstants.ACTIVATION_SPECIFIC_TIME_VALUE)}
                onChange={this.handleTimeValueChange}
                error={tempError}
                prefix="time.value.input"
                disabled={isDefaultModeSelected}
                allowOnlyPositive
              />
            </span>
            <span className="inside-sentence">
              <DropdownWrapper
                prefix="time.unit.dropdown"
                className="time-unit-dropdown"
                value={timeUnit}
                disabled={isDefaultModeSelected}
                options={this.dropDownOptions}
                onSelectOption={this.handleTimeUnitChange}
              />
            </span>
            <span className="inside-sentence">
              {formatMessage({ id: 'activation.condition.after.receiving.it' })}
            </span>
          </Accordion.Title>
        </Accordion>
        <div className="buttons-section">
          <Button data-automation-id="cancel.button" basic className="link modal-button" onClick={this.handleCancelWithButton}>
            {formatMessage({ id: 'general.button.cancel' })}
          </Button>
          <Button data-automation-id="save.button" className="modal-button" onClick={this.handleSave}>
            {formatMessage({ id: 'general.button.save' })}
          </Button>
        </div>
      </Fragment>);
  }

  handleChangeMode = (e, newMode) => {
    this.handleChange(activityConstants.ACTIVATION_MODE, newMode);
  };

  handleOnOpen = () => {
    if (this.props.onOpen()) {
      this.setState({ isOpen: true, animate: false });
    }
  };

  handleCancelWithoutButton = () => {
    if (!this.props.isPristine) {
      this.setState({ isOpen: true, animate: !this.state.animate });
    } else {
      this.closePopup();
    }
  };

  handleCancelWithButton = () => {
    this.closePopup();
  };

  closePopup = () => {
    this.props.onClose();
    this.setState({ isOpen: false, animate: false });
  };

  handleChange =(key, value) => {
    this.props.onChange(key, value);
    this.setState({ isOpen: this.state.isOpen, animate: this.state.animate });
  };

  handleSave = () => {
    this.props.onSave();
    this.closePopup();
  };

  handleTimeValueChange = (e, data) => {
    this.handleChange(activityConstants.ACTIVATION_SPECIFIC_TIME_VALUE, data.value);
  };

  handleTimeUnitChange = (e, data) => {
    this.handleChange(activityConstants.ACTIVATION_SPECIFIC_TIME_UNIT, data.value);
  };

  render() {
    const triggerClasses = classNames(['popup-trigger', 'activity-instance-activation-popup-trigger'], {
      'popup-trigger-error': this.props.error
    });
    return (<PopupWrapper
      className="activity-instance-activation-popup"
      onOpen={this.handleOnOpen}
      onClose={this.handleCancelWithoutButton}
      open={this.state.isOpen}
      on="click"
      fixed
      position="bottom left"
      error={this.props.error}
      automationId={`${this.props.automationIdPrefix}.popup`}
      trigger={(
        <div className="inline-div">
          <PopupTrigger
            className={triggerClasses}
            message={this.popupTriggerText}
            automationId={`${this.props.automationIdPrefix}.trigger`}
          />
        </div>
      )}
    >
      <Transition
        animation="shake"
        duration={200}
        visible={this.state.animate}
        transitionOnMount={false}
        mountOnShow={false}
      >
        <div>
          {this.popupContent}
        </div>
      </Transition>
    </PopupWrapper>);
  }
}

export default injectIntl(InstanceExpirationConditionPopup);
