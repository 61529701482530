import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { bindActionCreators, compose } from 'redux';
import { injectIntl } from 'react-intl';
import { Button, Dimmer, Grid, Header, Icon, Loader, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import * as selectors from './microCampaigns.selectors';
import injectSaga from '../../utils/injectSaga';
import saga from './microCampaigns.saga';
import * as actions from './microCampaigns.actions';
import tracker from '../../common/utils/tracking/tracker';
import './microCampaigns.styles.less';
import * as trackerConstants from '../../common/utils/tracking/tracking.consts';
import SideNavBar from '../SideNavBar/SideNavBar';
import * as reducerConstants from '../../constants/reducer.constants';
import { getSideNavbarSettings } from '../BusinessCenter/businessCenter.utils';
import withAuthorization from '../../common/helpers/authorization';
import SingleMicroCampaign from './views/SingleMicroCampaign';
import * as activityConstants from '../Activity/activity.constants';
import * as benefitsSelectors from '../Benefits/benefits.selector';
import * as microCampaignConstants from '../MicroCampaign/microCampaign.constants';
import * as benefitsActions from '../Benefits/benefits.actions';
import * as benefitConstants from '../Benefits/benefits.constants';
import benefitsSaga from '../Benefits/benefits.saga';
import microCampaignSaga from '../MicroCampaign/microCampaign.saga';
import businessCenterSaga from '../BusinessCenter/businessCenter.saga';
import * as routeConstants from '../../constants/route.contants';
import * as microCampaignActions from '../MicroCampaign/microCampaign.actions';
import * as appSelectors from '../App/selectors';
import * as appConstants from '../App/app.constants';
import * as constants from './microCampaigns.constants';
import * as businessCenterAction from '../BusinessCenter/businessCenter.actions';


export class MicroCampaigns extends React.PureComponent {

  static propTypes = {
    actions: PropTypes.object,
    intl: PropTypes.object,
    microCampaigns: PropTypes.object,
    benefits: PropTypes.object,
    benefitsFetched: PropTypes.bool,
    microCampaignsFetched: PropTypes.bool,
    updateStatusInProgress: PropTypes.bool,
    hasFullPermission: PropTypes.func.isRequired,
    getPermissionLevel: PropTypes.func.isRequired,
    locationId: PropTypes.number.isRequired,
    microCampaignStats: PropTypes.object,
    microCampaignStatsFetched: PropTypes.bool,
    currencySymbol: PropTypes.string,
    dateFormat: PropTypes.string,
    businessTimeZone: PropTypes.string,
  };

  componentWillMount() {
    if (!this.props.hasFullPermission(appConstants.FEATURE_NAME_AI_CAMPAIGN)) {
      this.props.actions.goToUpgradePage("microCampaigns");
    }
    else {
      this.props.actions.getMicroCampaigns();
      if (!this.props.benefitsFetched) {
        this.props.actions.getBenefits();
      }
    }
  }

  componentWillUnmount() {
    this.props.actions.cleanMicroCampaigns();

  }

  componentDidMount() {
    tracker.onPageLoaded(trackerConstants.PAGE_TYPE_MICRO_CAMPAIGNS);
  }

  onStatusChange = (microCampaign, isActive) => {
    if (microCampaign.get(activityConstants.STATUS) !== activityConstants.ACTIVITY_STATUS_DRAFT && !this.props.updateStatusInProgress) {
      this.props.actions.onStatusChange(microCampaign, isActive);
    }
  };

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const sideNavBarItems = getSideNavbarSettings(this.props.intl.formatMessage, location, this.props.hasFullPermission, this.props.getPermissionLevel);

    if (!this.props.microCampaignsFetched || !this.props.benefitsFetched) {
      return null;
    }
    const benefits = this.props.benefits;
    const hasCampaigns = this.props.microCampaigns && this.props.microCampaigns.size > 0;
    return (
      <div>
        <Dimmer active={this.props.updateStatusInProgress} inverted>
          <Loader />
        </Dimmer>
        <Helmet>
          <title>Comillia AI Campaigns</title>
          <meta name="description" content="Description of microCampaigns" />
        </Helmet>
        <SideNavBar sideNavBarItems={sideNavBarItems} />
        <Grid className={`microCampaigns ${hasCampaigns ? 'has-campaigns' : ''}`}>

          {hasCampaigns &&
          <Grid.Row>
            <Grid.Column width={16}>
              <Header>{formatMessage({ id: 'microCampaigns.title' })}</Header>
            </Grid.Column>
          </Grid.Row>
          }
          {hasCampaigns &&
          <Grid.Row className="microCampaign-title-row">
            <Grid.Column width={16} className="microCampaign-title">
              <Segment>
                <Grid>
                  <Grid.Row className="titles">
                    <Grid.Column width={5}>
                    NAME
                  </Grid.Column>
                    <Grid.Column width={5}>
                    DESCRIPTION
                  </Grid.Column>
                    <Grid.Column width={4}>
                    MEMBERS
                  </Grid.Column>
                    <Grid.Column width={2} className="micro-campaign-context-menu">
                    STATUS
                  </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          }
          { hasCampaigns
           ? (this.props.microCampaigns.map((microCampaign, index) => (
             <Grid.Row key={`microCampaign.${index.toString()}`} className={`single-campaign-elem ${microCampaign.get(activityConstants.HUB_ID)}`}>
               <Grid.Column width={16} className="single-micro-campaign-wrap">
                 <SingleMicroCampaign
                   singleMicroCampaign={microCampaign}
                   benefit={benefitsSelectors.getSelectedBenefitFromList(benefits, microCampaign.getIn([microCampaignConstants.MICRO_CAMPAIGN_ASSET_TEMPLATES, 0, microCampaignConstants.MICRO_CAMPAIGN_ASSET_TEMPLATE]))}
                   index={index}
                   automationIdPrefix="microCampaigns."
                   onStatusChange={(isActive) => this.onStatusChange(microCampaign, isActive)}
                   goToMicroCampaign={(microCampaignId) => this.props.actions.goToMicroCampaign(microCampaignId)}
                   getMicroCampaignStats={() => this.props.actions.getMicroCampaignStats(microCampaign.get(activityConstants.HUB_ID), this.props.locationId)}
                   microCampaignStats={this.props.microCampaignStats}
                   microCampaignStatsFetched={this.props.microCampaignStatsFetched}
                   currencySymbol={this.props.currencySymbol}
                   dateFormat={this.props.dateFormat}
                   businessTimeZone={this.props.businessTimeZone}
                   isOpen={microCampaign.get(constants.IS_OPEN)}
                   toggleCollapsed={() => this.props.actions.toggleCollapsed(index)}
                 />
               </Grid.Column>
             </Grid.Row>
                )
              )
            )
            : (
              <Grid.Row>
                <Grid.Column width={16}>
                  <div className="add-microCampaign">
                    <div className="add-button-wrap">
                      <p className="quote">
                        <q>{formatMessage({ id: 'microCampaigns-page.quote' })}</q>
                        <span className="author">
                          ({formatMessage({ id: 'microCampaigns-page.author' })})</span>
                      </p>
                      <p className="button-title">{formatMessage({ id: 'microCampaigns-page.create' })}</p>

                      <div className="button-wrap"><div><div>
                        <Link to={{ pathname: routeConstants.MICRO_CAMPAIGN_CREATE_ROUTE, search: location.search, type: microCampaignConstants.MICRO_CAMPAIGN }}>
                          <Button
                            className="create-microCampaign-button"
                            data-automation-id="microCampaigns-page.add_microCampaign"
                            onClick={tracker.wrapButtonAction(this.props.actions.createMicroCampaign, trackerConstants.BUTTON_TYPE_EMAIL_CREATE)}
                          >
                            <Icon className="como-ic-plus" />
                            <span>{formatMessage({ id: 'microCampaigns-page.add_microCampaign' })}</span>
                          </Button>
                        </Link>
                      </div></div></div>
                      <a className="learn-more" href="https://knowledge.comosense.com/hc/en-us/articles/360009063720-Comillia-AI-Campaigns" target="_blank">{formatMessage({ id: 'microCampaigns.learn.more' })}</a>


                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            )
          }
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  microCampaigns: selectors.getSortedMicroCampaigns(state),
  microCampaignsFetched: selectors.getMicroCampaignsFetchedFlag(state),
  benefits: benefitsSelectors.getBenefitsList(state),
  benefitsFetched: benefitsSelectors.getBenefitsListFetchedFlag(state),
  updateStatusInProgress: selectors.getMicroCampaignUpdateStatus(state),
  locationId: appSelectors.getLocationId(state),
  microCampaignStats: selectors.getMicroCampaignStats(state),
  microCampaignStatsFetched: selectors.getMicroCampaignStatsFetchedFlag(state),
  currencySymbol: appSelectors.getLocationCurrencySymbol(state),
  dateFormat: appSelectors.getBrowserLocaleDateFormat(state),
  businessTimeZone: appSelectors.getBusinessTimeZone(state)
});


const mapDispatchToProps = (dispatch) => {
  const creators = {
    ...benefitsActions,
    ...microCampaignActions,
    ...businessCenterAction,
    ...actions
  };
  return {
    actions: bindActionCreators(creators, dispatch),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.MICRO_CAMPAIGNS_BRANCH, saga });
const withBenefitsSaga = injectSaga({ key: benefitConstants.BENEFITS, saga: benefitsSaga });
const withMicroCampaignSaga = injectSaga({ key: reducerConstants.MICRO_CAMPAIGN_BRANCH, saga: microCampaignSaga });
const withBusinessCenterSaga = injectSaga({ key: reducerConstants.BUSINESS_CENTER_BRANCH, saga: businessCenterSaga });


export default compose(withConnect, withSaga, withMicroCampaignSaga, withBenefitsSaga, withBusinessCenterSaga, withAuthorization, injectIntl)(MicroCampaigns);
