import * as constants from './email.constants';

export const onEmailFieldUpdate = (fieldName, fieldValue) => ({
  type: constants.UPDATE_EMAIL_FIELD,
  fieldName,
  fieldValue
});
export const onEmailSave = (email) => ({ type: constants.SAVE_EMAIL, email });
export const createEmail = () => ({ type: constants.CREATE_EMAIL });
export const getEmail = (hubId) => ({ type: constants.GET_EMAIL, hubId });
export const clearEmailData = () => ({ type: constants.CLEAR_EMAIL_DATA });
export const getBusinessEmailEditorCredentials = () => ({ type: constants.GET_BUSINESS_EMAIL_EDITOR_CREDENTIALS });
export const goToEmailList = () => ({ type: constants.GO_TO_EMAILS });
export const markAsDirty = () => ({ type: constants.MARK_AS_DIRTY });
export const updateEmailMetadata = (key, value) =>
  // this action is in charge of updating the root properties of the rule/deal (name, description, status...)
   ({
     type: constants.UPDATE_EMAIL_METADATA,
     key,
     value
   });
export const checkingEmailNameAvailability = (emailId, name) => ({
  type: constants.CHECK_EMAIL_NAME_AVAILABILITY,
  emailId,
  name
});

