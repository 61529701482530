import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import Action from './action';
import * as constants from '../../activity.constants';

class ActionsList extends React.PureComponent {

  static propTypes = {
    actionPlaceholder: PropTypes.string.isRequired,
    actionsList: PropTypes.object.isRequired,
    actionsMode: PropTypes.string,
    actionsPopupContent: PropTypes.object,
    activityTrigger: PropTypes.string,
    activityType: PropTypes.string,
    addPointsOnChangeType: PropTypes.func,
    getBusinessBundlePlan: PropTypes.func.isRequired,
    applyOnData: PropTypes.object,
    automationIdPrefix: PropTypes.string.isRequired,
    businessStrategy: PropTypes.string,
    bundle: PropTypes.object,
    createGiftFromActivity: PropTypes.func,
    createEmailFromActivity: PropTypes.func,
    createTempApplyOnModel: PropTypes.func,
    deleteAction: PropTypes.func.isRequired,
    features: PropTypes.object,
    limit: PropTypes.bool,
    mentionsList: PropTypes.object,
    saveApplyOnData: PropTypes.func,
    updateActionField: PropTypes.func.isRequired,
    updateActionType: PropTypes.func.isRequired,
    validationErrors: PropTypes.object,
    allowCreateEmail: PropTypes.bool,
    showSimpleDropdown: PropTypes.bool,
    actionsWrapperIndex: PropTypes.number,
    isDiscountAction: PropTypes.bool,
    showNumbersOnMultipleActions: PropTypes.bool,
    submitActionToCustomer: PropTypes.func,
    hasFullPermission: PropTypes.func,
    caseIndex: PropTypes.number,
    noSectionTitle: PropTypes.bool,
    isOneTimeActivityDisabled: PropTypes.bool
  };


  static defaultProps = {
    allowCreateEmail: true,
    showSimpleDropdown: false,
    noSectionTitle: false
  };


  render() {
    const { actionPlaceholder, actionsList, actionsPopupContent, activityTrigger, addPointsOnChangeType,
      saveApplyOnData, createTempApplyOnModel, actionsMode, bundle, limit, mentionsList, validationErrors,
      deleteAction, updateActionField, updateActionType, activityType, applyOnData, businessStrategy, getBusinessBundlePlan } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    if (!actionsList || !actionsList.size) {
      return null;
    }

    return (
      <div className="actions-list-block">
        {
            actionsList.map((action, index) =>
              (<Grid className="action-block" key={`action_${index.toString()}`}>
                <Grid.Row className="action-container">
                  <Grid.Column width={16} className="action-wrapper">
                    {!(this.props.isDiscountAction && this.props.actionsMode !== constants.ADVANCED_DISCOUNT) && !this.props.noSectionTitle && <div className="section-title">
                      {index === 0 ? formatMessage({ id: 'activity.action.do' }) : formatMessage({ id: 'activity.action.andDo' })}
                    </div>}
                    <Action
                      action={action}
                      actionPlaceholder={actionPlaceholder}
                      getBusinessBundlePlan={getBusinessBundlePlan}
                      actionsMode={actionsMode}
                      actionsPopupContent={actionsPopupContent}
                      activityType={activityType}
                      addPointsOnChangeType={addPointsOnChangeType}
                      applyOnData={applyOnData}
                      automationIdPrefix={`${this.props.automationIdPrefix}`}
                      bundle={bundle}
                      businessStrategy={businessStrategy}
                      createGiftFromActivity={this.props.createGiftFromActivity}
                      createEmailFromActivity={this.props.createEmailFromActivity}
                      createTempApplyOnModel={createTempApplyOnModel}
                      deleteAction={deleteAction}
                      features={this.props.features}
                      index={index}
                      key={`action-${index.toString()}`}
                      limit={limit}
                      mentionsList={mentionsList}
                      saveApplyOnData={saveApplyOnData}
                      trigger={activityTrigger}
                      updateActionField={updateActionField}
                      updateActionType={updateActionType}
                      validationErrors={validationErrors ? validationErrors.get(index.toString()) : null}
                      allowCreateEmail={this.props.allowCreateEmail}
                      showSimpleDropdown={this.props.showSimpleDropdown}
                      actionsWrapperIndex={this.props.actionsWrapperIndex}
                      submitActionToCustomer={this.props.submitActionToCustomer}
                      caseIndex={this.props.caseIndex}
                      isOneTimeActivityDisabled={this.props.isOneTimeActivityDisabled}
                      hasFullPermission={this.props.hasFullPermission}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              ))
        }
      </div>
    );
  }
}

export default injectIntl(ActionsList);
