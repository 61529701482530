/*
 *
 * ComilliaHint reducer
 *
 */

import { fromJS } from 'immutable';
import * as constants from './comilliaHint.constants';
import * as filterMembersConstants from '../FilterMembers/filterMembers.constants';
import * as customerConstants from '../Members/members.constants';
import * as activityConstants from '../Activity/activity.constants';

const initialState = fromJS({});

function comilliaHintReducer(state = initialState.set(constants.SHOW_HINT, true), action) {

  let newState;

  switch (action.type) {

    case constants.HANDLE_HINT_APPEARANCE: {
      newState = state.set(constants.SHOW_HINT, action.show);
      break;
    }

    case filterMembersConstants.FILTER_MEMBERS: {
      newState = state
        .set(constants.SHOW_HINT, false);
      break;
    }

    case filterMembersConstants.FILTER_MEMBERS_SUCCESS:
    case filterMembersConstants.FILTER_MEMBERS_FILTER_SUCCESS: {
      newState = state
        .set(constants.FILTERED_COUNT, action.data[activityConstants.FILTERED_NUM_OF_MEMBERS]);
      break;
    }

    case activityConstants.FILTER_MEMBERS_SUCCESS: {
      newState = state
          .set(constants.SHOW_HINT, true)
          .set(constants.TOUCHED_COUNT, parseInt(action.data.filterByConditions.count, 10) - parseInt(action.data.filterByConditionsAndCommunication.count, 10));
      break;
    }

    case filterMembersConstants.CREATE_ONE_TIME_ACTION_ON_FILTERED_POPULATION: {
      newState = state
        .set(constants.FILTERED_COUNT, action.filteredPopulation.get(activityConstants.FILTERED_NUM_OF_MEMBERS));
      break;
    }

    case activityConstants.SET_FILTERED_POPULATION_DATA: {
      newState = state
        .set(constants.FILTERED_COUNT, action[activityConstants.FILTERED_NUM_OF_MEMBERS]);
      break;
    }

    case customerConstants.GET_MEMBERS_FILTERED_BY_COMMUNICATION_ACTIONS_SUCCESS: {
      const filteredCount = state.get(constants.FILTERED_COUNT);
      if (filteredCount) {
        newState = state
          .set(constants.SHOW_HINT, true)
          .set(constants.TOUCHED_COUNT, parseInt(filteredCount, 10) - parseInt(action.touchedMembers.count, 10));
      }
      break;
    }

    case activityConstants.CLEAR_DATA_ON_UNMOUNT: {
      newState = state
        .delete(constants.SHOW_HINT)
        .delete(constants.FILTERED_COUNT)
        .delete(constants.TOUCHED_COUNT);
      break;
    }

    default: {
      newState = state;
    }

  }

  return newState;

}

export default comilliaHintReducer;
