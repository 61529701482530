import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Radio } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import * as activityConstants from '../../../activity.constants';
import * as schemaConstants from '../../../activitySchema/activitySchema.constants';
import DropdownWrapper from './../../../../../common/components/DropdownWrapper/DropdownWrapper';
import ConditionValue from '../ConditionValue';

class UpdateMembershipPopupContent extends React.PureComponent {

  static propTypes = {
    data: PropTypes.object,
    error: PropTypes.object,
    intl: PropTypes.object,
    onUpdate: PropTypes.func.isRequired,
    prefix: PropTypes.string,
    conditionSchema: PropTypes.object,
  };

  onTypeChange = (operator) => {
    const { onUpdate } = this.props;
    if ([schemaConstants.OPERATORS_IS_ANY_VALUE, schemaConstants.OPERATORS_IS_EMPTY].includes(operator)) {
      onUpdate(activityConstants.OPERATOR_KEY, operator);
      onUpdate(activityConstants.SCHEMA_CONDITION_VALUE_DATA_TYPE, operator);
    } else {
      const defaultOperator = this.props.conditionSchema.getIn([activityConstants.SCHEMA_CONDITION_OPERATORS, 0, activityConstants.VALUE]);
      onUpdate(activityConstants.OPERATOR_KEY, defaultOperator);
      onUpdate(activityConstants.SCHEMA_CONDITION_VALUE_DATA_TYPE, this.props.conditionSchema.get(activityConstants.SCHEMA_CONDITION_VALUE_DATA_TYPE));
    }
  };

  getAccordionOptions = () => {
    const options = [
      schemaConstants.OPERATORS_IS_ANY_VALUE,
      schemaConstants.OPERATORS_IS_EMPTY,
    ];
    if (![schemaConstants.VALUE_TYPE_DATE, schemaConstants.VALUE_TYPE_DATE_TIME].includes(this.props.conditionSchema.get(activityConstants.VALUE_TYPE))) {
      options.push(schemaConstants.OPERATORS_SPECIFIC_VALUE);
    }
    return options;
  };

  get operatorsAsOptions() {
    const { formatMessage } = this.props.intl;
    const options = this.props.conditionSchema.get(activityConstants.SCHEMA_CONDITION_OPERATORS).map((opt) => ({
      key: opt.get(activityConstants.KEY),
      text: formatMessage({ id: `operator.${opt.get(activityConstants.KEY)}` }),
      value: opt.get(activityConstants.VALUE)
    }));
    return options.toJS();
  }

  render() {

    const { data, error, onUpdate, prefix, conditionSchema } = this.props;
    const { formatMessage } = this.props.intl;
    const accordionOptions = this.getAccordionOptions();

    return (
      <Accordion>
        {
          accordionOptions.map((opt) => {
            const automationIdPrefix = `${prefix}.${opt}`;
            const isActive = data.get(activityConstants.OPERATOR_KEY) === opt || (![schemaConstants.OPERATORS_IS_ANY_VALUE, schemaConstants.OPERATORS_IS_EMPTY].includes(data.get(activityConstants.OPERATOR_KEY)));
            return (
              <div key={opt}>
                <Accordion.Title
                  active={isActive}
                  data-automation-id={automationIdPrefix}
                  onClick={() => this.onTypeChange(opt)}
                >
                  <Radio
                    checked={isActive}
                    label={formatMessage({ id: `operator.memberFieldChanged.${opt}` })}
                    name={activityConstants.OPERATOR_KEY}
                  />
                </Accordion.Title>
                {
                  opt === schemaConstants.OPERATORS_SPECIFIC_VALUE &&
                  (
                    <Accordion.Content active={isActive}>
                      {
                        data.get(activityConstants.SCHEMA_CONDITION_VALUE_DATA_TYPE) !== schemaConstants.VALUE_TYPE_BOOLEAN &&
                        <DropdownWrapper
                          name={activityConstants.OPERATOR_KEY}
                          prefix={`${automationIdPrefix}.${activityConstants.OPERATOR_KEY}`}
                          onSelectOption={(e, { value }) => onUpdate(activityConstants.OPERATOR_KEY, value)}
                          options={this.operatorsAsOptions}
                          value={data.get(activityConstants.OPERATOR_KEY)}
                        />
                      }
                      <ConditionValue
                        condition={data}
                        onConditionUpdate={(e, { value }) => onUpdate(activityConstants.CONDITION_VALUE, value)}
                        prefix={`${automationIdPrefix}`}
                        selectedConditionSchema={conditionSchema}
                        validationErrors={error}
                      />
                    </Accordion.Content>
                  )
                }
              </div>
            );
          })
        }
      </Accordion>
    );
  }
}

export default injectIntl(UpdateMembershipPopupContent);
