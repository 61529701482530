/**
 *
 * Tabs
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Menu } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../activity.actions';
import * as selectors from '../../activity.selectors';
import * as constant from '../../activity.constants';

class Tabs extends React.PureComponent {

  static propTypes = {
    actions: PropTypes.object,
    activeTab: PropTypes.string,
    tabs: PropTypes.array,
    tabsErrors: PropTypes.object,
  };

  handleTabClick = (e, { name }) => this.props.actions.selectActivityTab(name);

  get tabMenuItems() {
    const { tabs, tabsErrors } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    return tabs.map((tab) => (
      <Menu.Item
        key={tab}
        name={tab}
        // content={formatMessage({ id: `gift.tab.${tab}` })}
        active={this.props.activeTab === tab}
        onClick={this.handleTabClick}
        data-automation-id={`gift-tab.${tab}`}
        className={tabsErrors.get(tab) ? 'error' : ''}
      >
        {formatMessage({ id: `gift.tab.${tab}` })}
        {tab == constant.ACTIVITY_TAB_LANDING_PAGE
          && < i style={{paddingLeft: 6}} className="share alternate icon"></i>
        }
      </Menu.Item>
    ));
  }

  render() {
    return (
      <Menu pointing secondary className="gifts-tabbed-menu">
        {
          this.tabMenuItems
        }
      </Menu>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}
function mapStateToProps(state) {
  return {
    activeTab: selectors.getActiveTab(state)
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Tabs));
