import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Checkbox } from 'semantic-ui-react';
import './SelectableDaysOfWeek.styles.less';
import * as dateUtils from '../../utils/dateTimeUtils';
import * as appSelectors from '../../../features/App/selectors';

class SelectableDaysOfWeek extends React.Component {

  static propTypes = {
    onChange: PropTypes.func,
    selected: PropTypes.arrayOf(PropTypes.number),
    startIndex: PropTypes.number,
    prefix: PropTypes.string,
    intl: PropTypes.object,
    locale: PropTypes.string
  };

  static defaultProps = {
    selected: [],
    startIndex: 0
  };

  onCheckboxChange = (id, checked) => {
    const { onChange, selected } = this.props;
    if (checked) {
      onChange([...selected, id]);
    } else {
      onChange(selected.filter((valueId) => valueId !== id));
    }
  };

  renderDay = ({ id, name } = {}, index) => {
    const { selected, prefix } = this.props;
    return (
      <Checkbox
        checked={selected.includes(id)}
        data-automation-id={`${prefix}.${index}`}
        key={`${prefix}.${index}.${name}`}
        label={name}
        name={name}
        onChange={(e, { checked }) => this.onCheckboxChange(id, checked)}
      />
    );
  };

  render() {
    const weekDays = dateUtils.prepareWeekDays(this.props.intl, this.props.locale);
    const days = [];
    for (let i = this.props.startIndex; i < this.props.startIndex + weekDays.length; i++) {
      days.push(this.renderDay(weekDays[i % weekDays.length], i));
    }
    return (
      <div className="container-activation-and-deactivate-rectangle">
        {days}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: appSelectors.getBrowserLocale(state)
});

export default (connect)(mapStateToProps)(injectIntl(SelectableDaysOfWeek));

