import React, { useEffect, useRef } from 'react';

export function Clip({ url, width, height }) {
  const videoWidth = width || 640;
  const videoHeight = height || 360;
  const videoRef = useRef();
  const previousUrl = useRef(url);

  useEffect(() => {
    if (previousUrl.current === url) {
      delayPlay();
      return;
    }

    if (videoRef.current) {
      videoRef.current.load();
    }

    previousUrl.current = url;
    delayPlay();
  }, [url]);
  const delayPlay = () => {
    setTimeout(() => {
      videoRef.current.play();
    }, 3000);
  };
  return (
    <video autoPlay width={videoWidth} height={videoHeight} controls ref={videoRef}>
      <source src={url}/>
      <track kind="captions"/>
    </video>
  );
}
