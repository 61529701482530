import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import * as constants from './pointShop.constants';
import * as api from './pointShop.api';
import * as pointShopItemApi from '../PointShopItem/pointShopItem.api';
import * as errorConstant from '../ErrorMessage/errorMessage.constants';

function* getPointShopItems() {
  try {
    const data = yield call(api.getPointShopItems);
    yield put({ type: constants.GET_POINT_SHOP_ITEMS_SUCCESS, data });
  } catch (err) {
    yield put({ type: constants.GET_POINT_SHOP_ITEMS_ERROR, message: err.message });
  }
}

function* getPointShopItem(item) {
  try {
    const data = yield call(api.getPointShopItem, item.hubId);
    yield put({ type: constants.GET_POINT_SHOP_ITEM_SUCCESS, data });
  } catch (err) {
    yield put({ type: constants.GET_POINT_SHOP_ITEMS_ERROR, message: err.message });
  }
}

function* getPointShopAnalysis() {
  try {
    yield put({ type: constants.GET_POINT_SHOP_ITEMS_ANALYSIS_IN_PROGRESS });
    const data = yield call(api.getPointShopAnalysis);
    yield put({ type: constants.GET_POINT_SHOP_ITEMS_ANALYSIS_SUCCESS, data });
  } catch (err) {
    yield put({ type: constants.GET_POINT_SHOP_ITEMS_ANALYSIS_ERROR, message: err.message });
  }
}

function* createPointShopItem(item) {
  try {
    yield put({ type: constants.GET_POINT_SHOP_ITEMS_ERROR, message: '' });
    const data = yield call(pointShopItemApi.createPointShopItem, item.data);
    yield put({ type: constants.CREATE_POINT_SHOP_ITEM_SUCCESS, data });
  } catch (err) {
    if (err.response.data && err.response.data.errorMessage && err.response.data.errorMessage.includes('Error creating image resource')) {
      yield put({ type: constants.CREATE_POINT_SHOP_ITEM_ERROR, message: err.message });
    }
    yield put({ type: constants.GET_POINT_SHOP_ITEMS_ERROR, message: err.message });
  }
}

function* updatePointShopItemStatus(item) {
  try {
    const data = yield call(pointShopItemApi.createPointShopItem, item.data);
    yield put({ type: constants.UPDATE_POINT_SHOP_ITEM_STATUS_SUCCESS, data });
  } catch (err) {
    if (err.response.data && err.response.data.errorMessage && err.response.data.errorMessage.includes('Error creating image resource')) {
      yield put({ type: constants.CREATE_POINT_SHOP_ITEM_ERROR, message: err.message });
    }
    yield put({ type: errorConstant.HANDLE_ERROR, message: err.message });
  }
}

function* deletePointShopItem(item) {
  try {
    const data = yield call(pointShopItemApi.deletePointShopItem, item.id);
    yield put({ type: constants.DELETE_POINT_SHOP_ITEM_SUCCESS, id: item.id });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, message: err.message });
  }
}

function* pointShopSaga() {
  yield [
    takeEvery(constants.GET_POINT_SHOP_ITEMS, getPointShopItems),
    takeEvery(constants.GET_POINT_SHOP_ITEMS_ANALYSIS, getPointShopAnalysis),
    takeEvery(constants.CREATE_POINT_SHOP_ITEM, createPointShopItem),
    takeEvery(constants.DELETE_POINT_SHOP_ITEM, deletePointShopItem),
    takeEvery(constants.UPDATE_POINT_SHOP_ITEM_STATUS, updatePointShopItemStatus),
    takeEvery(constants.GET_POINT_SHOP_ITEM, getPointShopItem)
  ];
}


export default pointShopSaga;
