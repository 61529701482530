/*
 *
 * MicroCampaigns constants
 *
 */

// ACTIONS CONSTANTS

export const DEFAULT_ACTION = 'app/MicroCampaigns/DEFAULT_ACTION';
export const GET_MICRO_CAMPAIGNS_SUCCESS = 'app/MicroCampaigns/GET_MICROCAMPAIGNS_SUCCESS';
export const GET_MICRO_CAMPAIGNS_ERROR = 'app/MicroCampaigns/GET_MICROCAMPAIGNS_ERROR';
export const GET_MICRO_CAMPAIGNS = 'app/MicroCampaigns/GET_MICROCAMPAIGNS';
export const GO_TO_MICRO_CAMPAIGNS = 'app/MicroCampaigns/GO_TO_MICRO_CAMPAIGNS';
export const UPDATE_MICRO_CAMPAIGN_STATUS = 'app/MicroCampaigns/UPDATE_MICRO_CAMPAIGN_STATUS';
export const GO_TO_MICRO_CAMPAIGN = 'app/MicroCampaigns/GO_TO_MICRO_CAMPAIGN';
export const UPDATE_MICRO_CAMPAIGN_STATUS_DONE = 'app/MicroCampaigns/UPDATE_MICRO_CAMPAIGN_STATUS_DONE';
export const UPDATE_MICRO_CAMPAIGN_STATUS_IN_PROGRESS = 'app/MicroCampaigns/UPDATE_MICRO_CAMPAIGN_STATUS_IN_PROGRESS';
export const CLEAN_MICRO_CAMPAIGNS = 'app/MicroCampaigns/CLEAN_MICRO_CAMPAIGNS';
export const GET_MICRO_CAMPAIGN_STATS = 'app/MicroCampaigns/GET_MICRO_CAMPAIGN_STATS';
export const GET_MICRO_CAMPAIGN_STATS_SUCCESS = 'app/MicroCampaigns/GET_MICRO_CAMPAIGN_STATS_SUCCESS';
export const GET_MICRO_CAMPAIGN_STATS_ERROR = 'app/MicroCampaigns/GET_MICRO_CAMPAIGN_STATS_ERROR';
export const TOGGLE_COLLAPSED_STATE = 'app/MicroCampaigns/TOGGLE_COLLAPSED_STATE';

// STORE CONSTANTS
export const MICRO_CAMPAIGNS = 'microCampaigns';
export const MICRO_CAMPAIGN_UPDATE_STATUS_IN_PROGRESS = 'updateStatusInProgress';
export const MICRO_CAMPAIGNS_FETCHED = 'microCampaignsFetched';
export const MICRO_CAMPAIGN_STATS_FETCHED = 'microCampaignStatsFetched';
export const MICRO_CAMPAIGN_STATS = 'microCampaignStats';
export const IS_OPEN = 'isOpen';
export const START_CAMPAIGN_DATE = 'start_campaign_date';
export const WEEK = 'week';
export const MEMBERS = 'Members';
