/**
*
* CaseConditions
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import Conditions from '../conditions/conditions';

class CaseConditions extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string,
    caseConditions: PropTypes.object,
    caseIndex: PropTypes.number,
    casesActions: PropTypes.object,
    conditionsPerTrigger: PropTypes.object,
    dateTimeConditions: PropTypes.object,
    features: PropTypes.object,
    intl: PropTypes.object,
    membershipConditions: PropTypes.object,
    trigger: PropTypes.string,
    updateMembershipConditions: PropTypes.object,
    validationErrors: PropTypes.object,
    abTestMode: PropTypes.bool,
    isOneTimeActivityDisabled: PropTypes.bool
  };

  // use local functions to inject case index to callback functions

  addCondition = () => {
    this.props.casesActions.addCondition(this.props.caseIndex);
  };

  deleteCondition = (index) => {
    this.props.casesActions.deleteCondition(index, this.props.caseIndex);
  };

  updateConditionField = (fieldName, fieldValue, conditionIndex, abTestMode) => {
    this.props.casesActions.updateConditionField(fieldName, fieldValue, conditionIndex, this.props.caseIndex, abTestMode);
  };

  updateDateRangeDate = (dateObject, isStart, localeTimeFormat, conditionIndex, businessTimezone, allowInfinite) => {
    this.props.casesActions.updateDateRangeDate(dateObject, isStart, localeTimeFormat, conditionIndex, this.props.caseIndex, businessTimezone, allowInfinite);
  };

  updateDateRangeTime = (timeObject, isStart, localeTimeFormat, conditionIndex, businessTimezone) => {
    this.props.casesActions.updateDateRangeTime(timeObject, isStart, localeTimeFormat, conditionIndex, this.props.caseIndex, businessTimezone);
  };

  addDaysTimesCondition = (conditionIndex) => {
    this.props.casesActions.addDaysTimesCondition(conditionIndex, this.props.caseIndex);
  };

  deleteDaysTimesCondition = (daysTimesLineIndex, conditionIndex) => {
    this.props.casesActions.deleteDaysTimesCondition(daysTimesLineIndex, conditionIndex, this.props.caseIndex);
  };

  updateDaysTimes = (data, daysTimesLineIndex, isMilitaryTime, conditionIndex, businessTimezone) => {
    this.props.casesActions.updateDaysTimes(data, daysTimesLineIndex, isMilitaryTime, conditionIndex, this.props.caseIndex, businessTimezone);
  };

  render() {
    const { formatMessage } = this.props.intl;
    const conditionsSubTitleKey = this.props.abTestMode ? 'activity.cases.conditionsPrefix.abTest' : 'activity.cases.conditionsPrefix';
    return (
      <Grid>
        <Grid.Row className="case-conditions">
          <Grid.Column width={1} />
          <Grid.Column width={15}>
            <p className="case-conditions-label">{formatMessage({ id: conditionsSubTitleKey })}</p>
            <Conditions
              activityConditions={this.props.caseConditions}
              automationIdPrefix={`${this.props.automationIdPrefix}`}
              conditionsList={this.props.conditionsPerTrigger.concat(this.props.dateTimeConditions).concat(this.props.membershipConditions)}
              isCaseCondition
              label={false}
              mainConditions
              trigger={this.props.trigger}
              addCondition={this.addCondition}
              deleteCondition={this.deleteCondition}
              updateConditionField={this.updateConditionField}
              updateDateRangeDate={this.updateDateRangeDate}
              updateDateRangeTime={this.updateDateRangeTime}
              addDaysTimesCondition={this.addDaysTimesCondition}
              deleteDaysTimesCondition={this.deleteDaysTimesCondition}
              updateDaysTimes={this.updateDaysTimes}
              features={this.props.features}
              updateMembershipConditions={this.props.updateMembershipConditions}
              validationErrors={this.props.validationErrors}
              isOneTimeActivityDisabled={this.props.isOneTimeActivityDisabled}
              // validationErrors={this.props.validationErrors ? this.props.validationErrors.get(activityConstants.CONDITIONS) : null}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default injectIntl(CaseConditions);
