/**
*
* CommunicationRule
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Grid, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import * as activityConstants from '../../activity.constants';
import DropdownWrapper from '../../../../common/components/DropdownWrapper/DropdownWrapper';
import ConfirmationDialog from '../../../../common/components/ConfirmationDialog/ConfirmationDialog';
import ActionTemplate from '../actions/ActionTemplate';
import { transformMentionText } from '../../../../utils/textFormat';

class CommunicationRule extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string,
    communicationRule: PropTypes.object.isRequired,
    deleteCommunicationRule: PropTypes.func.isRequired,
    expandCollapseCommunicationRule: PropTypes.func.isRequired,
    features: PropTypes.object,
    index: PropTypes.number.isRequired,
    intl: PropTypes.object,
    mentionsList: PropTypes.object,
    rulesCount: PropTypes.number.isRequired,
    schemaActions: PropTypes.object.isRequired,
    schemaTriggers: PropTypes.object.isRequired,
    updateCommunicationRuleAction: PropTypes.func.isRequired,
    updateCommunicationRuleTrigger: PropTypes.func.isRequired,
    validationErrors: PropTypes.object,
    submitActionToCustomer: PropTypes.func
  };

  static state = {
    actionsList: null,
    triggersList: null,
  };

  componentWillMount() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const actionsList = this.props.schemaActions
      .map((action) => ({
        key: action,
        value: action,
        text: formatMessage({ id: `activity.action.${action}` }),
      })).toJS();
    const triggersList = this.props.schemaTriggers
      .map((trigger) => ({
        key: trigger.get(activityConstants.NAME),
        value: trigger.get(activityConstants.NAME),
        text: formatMessage({ id: `activity.punchCard.communication.triggers.${trigger.get(activityConstants.NAME)}` }),
      })).toJS();
    this.setState({
      actionsList,
      triggersList,
    });
  }

  onActionChange = (fieldName, fieldValue) => {
    this.props.updateCommunicationRuleAction(this.props.index, fieldName, fieldValue);
  };

  onDeleteCommunicationRule = () => {
    this.props.deleteCommunicationRule(this.props.index);
  };

  onExpandCollapseRule(isCollapsed) {
    this.props.expandCollapseCommunicationRule(this.props.index, !isCollapsed);
  }

  onSelectActionType = (e, data) => {
    this.props.updateCommunicationRuleAction(this.props.index, data.name, data.value);
    this.props.expandCollapseCommunicationRule(this.props.index, false);
  };

  onSelectTrigger = (e, data) => {
    this.props.updateCommunicationRuleTrigger(this.props.index, data.value);
    this.props.expandCollapseCommunicationRule(this.props.index, false);
  };

  get action() {
    const { communicationRule } = this.props;
    return communicationRule ? communicationRule.getIn([activityConstants.ACTIONS, 0, activityConstants.ACTIONS_LIST, 0]) : null;
  }

  get expandCollapseTooltip() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    return this.isCollapsed ?
      formatMessage({ id: 'activity.rules.expand.tooltip' }) :
      formatMessage({ id: 'activity.rules.collapse.tooltip' });
  }

  get isCollapsed() {
    return this.props.communicationRule.has(activityConstants.IS_COLLAPSED)
    ? this.props.communicationRule.get(activityConstants.IS_COLLAPSED)
    : true;
  }

  get serverId() {
    return this.props.communicationRule.get(activityConstants.SERVER_ID);
  }

  get trigger() {
    const { communicationRule } = this.props;
    return communicationRule ? communicationRule.get(activityConstants.TRIGGER) : null;
  }

  get triggersList() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    return this.props.schemaTriggers
      .map((trigger) => ({
        key: trigger.get(activityConstants.NAME),
        value: trigger.get(activityConstants.NAME),
        text: formatMessage({ id: `activity.punchCard.communication.triggers.${trigger.get(activityConstants.NAME)}` }),
      }))
      .toJS();
  }

  get allowDelete() {
    return this.props.rulesCount > 1 || !(!this.props.communicationRule.get(activityConstants.TRIGGER) && !this.props.communicationRule.get(activityConstants.ACTION_TYPE));
  }

  render() {

    const { features, index, intl, mentionsList, validationErrors } = this.props;
    const { formatMessage } = intl; // eslint-disable-line react/prop-types
    const automationIdPrefix = `${this.props.automationIdPrefix}.${this.props.index}`;

    return (
      <Grid.Row>
        <Grid.Column width={16} className="action-container">
          <Grid>
            <Grid.Row>
              <Grid.Column width={1}>
                <Icon
                  className={`expand-collapse-toggle como-ic-angle-${this.isCollapsed ? 'right' : 'down'}`}
                  onClick={() => this.onExpandCollapseRule(this.isCollapsed)}
                  size="big"
                  data-automation-id={`${automationIdPrefix}.expand-collapse-handler`}
                  title={this.expandCollapseTooltip}
                />
              </Grid.Column>
              <Grid.Column width={14} className={'action-wrapper'}>
                <Accordion className={`action-template-wrapper ${this.action.get(activityConstants.ACTION_TYPE)}`}>
                  <Accordion.Title active={!this.isCollapsed}>
                    <DropdownWrapper
                      error={validationErrors && validationErrors.getIn([activityConstants.ACTIONS, activityConstants.VALIDATION_ERRORS_NO_ACTION])}
                      index={index}
                      onSelectOption={this.onSelectActionType}
                      name={activityConstants.ACTION_TYPE}
                      options={this.state.actionsList}
                      placeholder={formatMessage({ id: 'activity.punchCard.communication.action.placeholder' })}
                      prefix={`${automationIdPrefix}.${activityConstants.ACTION_TYPE}`}
                      value={this.action ? this.action.get(activityConstants.ACTION_TYPE) : null}
                    />
                    <span className="glue">when member</span>
                    <DropdownWrapper
                      className={'communication-trigger'}
                      error={validationErrors && validationErrors.get(activityConstants.TRIGGER)}
                      index={index}
                      name={activityConstants.TRIGGER}
                      onSelectOption={this.onSelectTrigger}
                      options={this.state.triggersList}
                      placeholder={formatMessage({ id: 'activity.punchCard.communication.trigger.placeholder' })}
                      prefix={`${automationIdPrefix}.${activityConstants.TRIGGER}`}
                      value={this.trigger}
                    />
                    {
                      this.isCollapsed && this.action.get(activityConstants.PUSH_MESSAGE) &&
                      <p className={'communication-rule-message'}>&quot;{transformMentionText(this.action.get(activityConstants.PUSH_MESSAGE), this.props.mentionsList)}&quot;</p>
                    }
                  </Accordion.Title>
                  <Accordion.Content active={!this.isCollapsed}>
                    {
                      this.action.get(activityConstants.ACTION_TYPE)
                      ? (
                        <ActionTemplate
                          action={this.action}
                          actionsMode={activityConstants.ACTIONS_SIMPLE_MODE}
                          assetContext={activityConstants.ACTIVITY_TYPE_PUNCH_CARD}
                          automationIdPrefix={automationIdPrefix}
                          bundle={null}
                          features={features}
                          index={index}
                          mentionsList={mentionsList}
                          updateActionField={this.onActionChange}
                          validationErrors={validationErrors && validationErrors.get(activityConstants.ACTIONS)}
                          submitActionToCustomer={this.props.submitActionToCustomer}
                        />
                        )
                      : <p>{ formatMessage({ id: 'activity.punchCard.communication.emptyRule' }) }</p>
                    }
                  </Accordion.Content>
                </Accordion>
              </Grid.Column>
              <Grid.Column width={1} textAlign={'right'}>
                {
                  this.allowDelete &&
                  <ConfirmationDialog
                    automationIdPrefix={`${automationIdPrefix}.delete-rule`}
                    confirmAction={this.onDeleteCommunicationRule}
                    confirmButtonKey="general.button.delete"
                    contentMessageKey="activity.punchCard.communication.delete.confirmationMessage"
                    icon="como-ic-delete"
                    titleKey="activity.header.back.confirmationHeader"
                    tooltipMessageKey="activity.punchCard.communication.delete.tooltip"
                  />
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

export default injectIntl(CommunicationRule);

