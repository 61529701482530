import { fromJS } from 'immutable';
import * as constants from './activity.constants';
import * as schemaConstants from './activitySchema/activitySchema.constants';

export function processActivityForPublish(activityState, isDraft) {

  // extract the activity data from the activity state:
  let activity = activityState.get(constants.DATA);

  // set activity status:
  activity = activity.set(constants.STATUS, isDraft ? constants.ACTIVITY_STATUS_DRAFT : constants.ACTIVITY_STATUS_ACTIVE);

  if (activity.getIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS, constants.TYPE]) === constants.MEMBERS_SPECIFIC) {
    // remove empty default condition(s) from the membership global condition conditions list:
    const listPath = [constants.MEMBERSHIP_GLOBAL_CONDITIONS, constants.CONDITIONS, constants.CONDITIONS_LIST];
    const newList = activity.getIn(listPath).filter((condition) => condition.get(constants.CONDITION_KEY));
    activity = activity.setIn(listPath, newList);
  }

  // return JS object ready for submission:
  return activity.toJS();
}

export function processActivityForSavingAsTemplate(activity) {
  if (activity.membershipGlobalConditions && activity.membershipGlobalConditions.type == constants.MEMBERS_SPECIFIC) {
    const conditionsList = activity.membershipGlobalConditions.conditions.conditionsList;
    const newConditionsList = conditionsList.filter((condition) => condition.conditionKey != '');
    activity.membershipGlobalConditions.conditions.conditionsList = newConditionsList;
  }
  return activity;
}

export function hasDeleteMembersAction(activityState) {
  const actionsList = activityState.getIn([constants.DATA, constants.ACTIONS, 0, constants.ACTIONS_LIST]);
  const deleteMemberAction = actionsList ? actionsList.toJSON().find((action) => action.actionType === constants.DELETE_MEMBERSHIP_ACTION) : false;
  if (!deleteMemberAction) {
    const cases = activityState.getIn([constants.DATA, constants.CASES]);
    let casesActionsList;
    if (cases) {
      casesActionsList = [];
      cases.toJSON().forEach((c) => {
        const actions = c.actions[0].actionsList.find((action) => action.actionType === constants.DELETE_MEMBERSHIP_ACTION);
        if (actions) {
          casesActionsList.push(actions);
        }
      });
      return casesActionsList && casesActionsList.length > 0;
    }
    return false;
  }
  return true;
}

export function hasSmsAction(activityState) {
  const actionsList = activityState.getIn([constants.DATA, constants.ACTIONS, 0, constants.ACTIONS_LIST]);
  const sendMemberSmsAction = actionsList ? actionsList.find((action) => action.get(constants.ACTION_TYPE) === constants.SEND_MEMBER_SMS_ACTION) : false;
  const sendMemberConsentBySmsAction = actionsList ? actionsList.find((action) => action.get(constants.ACTION_TYPE) === constants.SEND_TC_CONSENT_SMS_OR_EMAIL_ACTION && action.get(constants.CONSENT_TYPE) === constants.SEND_CONSENT_BY_SMS) : false;
  if (sendMemberSmsAction || sendMemberConsentBySmsAction) return true; // has sms action or consent action by sms
  const cases = activityState.getIn([constants.DATA, constants.CASES]);
  let smsActionInCases = fromJS([]);
  if (cases) {
    cases.forEach((c) => {
      const hasSendMemberSmsActionInCases = c.getIn([constants.ACTIVITY_ACTIONS, 0, constants.ACTIONS_LIST]).find((action) => action.get(constants.ACTION_TYPE) === constants.SEND_MEMBER_SMS_ACTION);
      if (hasSendMemberSmsActionInCases) smsActionInCases = smsActionInCases.push(hasSendMemberSmsActionInCases);
      const hasSendMemberConsentBySmsActionInCases = c.getIn([constants.ACTIVITY_ACTIONS, 0, constants.ACTIONS_LIST]).find((action) => action.get(constants.ACTION_TYPE) === constants.SEND_TC_CONSENT_SMS_OR_EMAIL_ACTION && action.get(constants.CONSENT_TYPE) === constants.SEND_CONSENT_BY_SMS);
      if (hasSendMemberSmsActionInCases) smsActionInCases = smsActionInCases.push(hasSendMemberConsentBySmsActionInCases);
    });
    return smsActionInCases.count() > 0;
  }
  return false;
}
export function hasEmailAction(activityState) {
  const actionsList = activityState.getIn([constants.DATA, constants.ACTIONS, 0, constants.ACTIONS_LIST]);
  const sendMemberEmailAction = actionsList ? actionsList.find((action) => action.get(constants.ACTION_TYPE) === schemaConstants.SEND_MEMBER_EMAIL_ACTION) : false;
  const sendMemberConsentByEmailAction = actionsList ? actionsList.find((action) => action.get(constants.ACTION_TYPE) === constants.SEND_TC_CONSENT_SMS_OR_EMAIL_ACTION && action.get(constants.CONSENT_TYPE) === constants.SEND_CONSENT_BY_EMAIL) : false;
  if (sendMemberEmailAction || sendMemberConsentByEmailAction) return true; // has email action or consent action by email
  const cases = activityState.getIn([constants.DATA, constants.CASES]);
  let emailActionInCases = fromJS([]);
  if (cases) {
    cases.forEach((c) => {
      const hasSendMemberEmailActionInCases = c.getIn([constants.ACTIVITY_ACTIONS, 0, constants.ACTIONS_LIST]).find((action) => action.get(constants.ACTION_TYPE) === schemaConstants.SEND_MEMBER_EMAIL_ACTION);
      if (hasSendMemberEmailActionInCases) emailActionInCases = emailActionInCases.push(hasSendMemberEmailActionInCases);
      const hasSendMemberConsentByEmailActionInCases = c.getIn([constants.ACTIVITY_ACTIONS, 0, constants.ACTIONS_LIST]).find((action) => action.get(constants.ACTION_TYPE) === constants.SEND_TC_CONSENT_SMS_OR_EMAIL_ACTION && action.get(constants.CONSENT_TYPE) === constants.SEND_CONSENT_BY_EMAIL);
      if (hasSendMemberEmailActionInCases) emailActionInCases = emailActionInCases.push(hasSendMemberConsentByEmailActionInCases);
    });
    return emailActionInCases.count() > 0;
  }
  return false;
}

/**
 * check if the current actions and cases can live in the context of the new trigger
 * @param {Map} activityData
 * @param {Map} schema
 * @param {string} newTrigger
 * @returns {boolean}
 */
export function canKeepActionsAndCasesAfterTriggerChange(activityData, schema, newTrigger) {
  if (newTrigger !== activityData.get(constants.TRIGGER)) {
    if (newTrigger !== schemaConstants.TRIGGER_PURCHASE_ANALYZED) {
      if (hasActionsInRepeatMode(activityData, activityData.get(constants.CASES))) {
        return false;
      }
    }
    if (activityData.has(constants.CASES) && activityData.get(constants.CASES)) {
      if (hasTriggerConditionsInCases(activityData.get(constants.CASES))) {
        return false;
      }
    }
    if (hasUnsupportedActions(activityData, activityData.get(constants.CASES), schema.getIn([constants.ACTIONS_PER_TRIGGERS, newTrigger]))) {
      return false;
    }
  }
  return true;
}

/**
 * return true if actions mode in root actions or in even one of the cases is set to 'occurrence', otherwise false
 * @param {Map} activityData
 * @param {List} cases
 * @returns {boolean}
 */
function hasActionsInRepeatMode(activityData, cases) {
  if (cases) {
    return cases.some((singleCase) => singleCase.get(constants.ACTIONS).some((action) => action.get(constants.ACTIONS_MODE) === constants.ACTIONS_OCCURRENCES_MODE));
  }
  return activityData.get(constants.ACTIONS).some((action) => action.get(constants.ACTIONS_MODE) === constants.ACTIONS_OCCURRENCES_MODE);
}

/**
 * check if cases have trigger-related conditions (non global)
 * @param {List} cases
 * @returns {boolean}
 */
function hasTriggerConditionsInCases(cases) {
  return cases
    .some((singleCase) =>
      singleCase.getIn([constants.CONDITIONS, constants.CONDITIONS_LIST])
        .some((condition) =>
          condition.get(constants.CONDITION_KEY)
          && !condition.get(constants.CONDITION_KEY).includes(constants.MEMBERSHIP_GLOBAL_CONDITION_PREFIX)
          && !condition.get(constants.CONDITION_KEY).includes(schemaConstants.CONTEXT_TIMESTAMP)
          && !condition.get(constants.CONDITION_KEY).includes(schemaConstants.CONTEXT_TIMESTAMP_DAYS)
        )
  );
}

/**
 * go over all actions (in root / cases), check if at least one does not exist in the new actionsPerTrigger list
 * @param {Map} activityData
 * @param {List} cases
 * @param {List} actionsPerTrigger
 * @returns {boolean}
 */
function hasUnsupportedActions(activityData, cases, actionsPerTrigger) {
  const supportedActionNames = getSchemaActionNames(actionsPerTrigger);
  if (cases) {
    return cases
      .some((singleCase) => singleCase.get(constants.ACTIONS).some((actionsWrapper) => actionsWrapper.get(constants.ACTIONS_LIST).some((action) => action.get(constants.ACTION_TYPE) && !supportedActionNames.includes(action.get(constants.ACTION_TYPE)))));
  }
  return activityData
    .get(constants.ACTIONS).some((actionsWrapper) => actionsWrapper.get(constants.ACTIONS_LIST).some((action) => action.get(constants.ACTION_TYPE) && !supportedActionNames.includes(action.get(constants.ACTION_TYPE))));
}

/**
 * flatten schema actionsPerTrigger object to return action names array
 * @param {List} actionsPerTrigger
 * @returns {List}
 */
function getSchemaActionNames(actionsPerTrigger) {
  return actionsPerTrigger
    ? actionsPerTrigger
      .reduce((actions, group) => actions.concat(group.get(constants.ACTION_FIELDS).reduce((arr, a) => arr.concat(a.get(constants.KEY)), [])), [])
    : [];
}
