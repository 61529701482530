import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import DisabledAddOn from './DisabledAddOn';
import DisabledItemPosRestriction from './DisabledItemPosRestriction';
import withAuthorization from '../../helpers/authorization';
import * as constants from '../../../features/Activity/activity.constants';
import {FEATURE_PLAN} from "../../../features/App/app.constants";

class DependentOnPosOrAddOnItem extends React.PureComponent {
  static propTypes ={
    children: PropTypes.node,
    restrictions: PropTypes.object,
    conditionFeatures: PropTypes.array,
    getBusinessBundlePlan: PropTypes.func.isRequired,
  };

  render() {
    const { children, restrictions, conditionFeatures, getBusinessBundlePlan } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line
    const isPosRestricted = restrictions && restrictions.get(constants.RESTRICTED_BY_POS_FEATURES);
    const isAddOnRestricted = !isPosRestricted && restrictions && restrictions.get(constants.RESTRICTED_BY_ADD_ON_FEATURES);
    const isAddOnFeature = restrictions && restrictions.get(constants.RESTRICTING_ADD_ON_FEATURES).size > 0;
    const partOfPlan = conditionFeatures && conditionFeatures.some((feature) => feature.type === FEATURE_PLAN);
    const plan = getBusinessBundlePlan();

    return (
      <React.Fragment>
        {
          isPosRestricted &&
          <DisabledItemPosRestriction>
            {children}
          </DisabledItemPosRestriction>
        }
        {
          isAddOnRestricted &&
          <DisabledAddOn
            bundlePlan={plan}
            withUnAvailableIcon
          >
            {children}
            <Icon className="como-ic-add-on" />
          </DisabledAddOn>
        }
        {!(isPosRestricted || isAddOnRestricted) &&
        <React.Fragment>
          {children}
          {isAddOnFeature && (!partOfPlan) &&
          <span className="inside-sentence">
            <Icon className="como-ic-add-on" />
            </span>}
        </React.Fragment>
        }
      </React.Fragment>
    );
  }
}

export default withAuthorization(injectIntl(DependentOnPosOrAddOnItem));
