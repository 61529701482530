/*
 *
 * FilterMembers api
 *
 */

import axios from 'axios';
import _ from 'lodash';
import { normalizeMembershipGlobalConditionTempModel } from '../Activity/reducers/utils/globalCondition.reducer.utils';
import * as activityConstants from '../Activity/activity.constants';
import { LOCATION_ID } from '../App/app.constants';

export function filterMembers(globalMemberConditions, locationId) {
  // Returns the membership conditions
  const filterConditions = normalizeMembershipGlobalConditionTempModel(globalMemberConditions).toJS();
  const mergedFilterConditions = mergeAllFilterConditions(filterConditions);
  // Returns only the user action filters
  const userActionFilters = adjustUserActionsConditions(filterConditions);
  const url = '/api/FilterPopulation/withSample';
  const requestData = {
    [activityConstants.FILTERED_POPULATION_CONDITIONS]: mergedFilterConditions,
    [activityConstants.FILTERED_POPULATION_USER_ACTIONS_CONDITIONS]: userActionFilters,
    [LOCATION_ID]: locationId,
  };
  return axios.post(url, requestData).then((res) => res.data);
}
export function getActivities() {
  const url = '/api/Activities/smsActionsActivities';
  return axios.get(url).then((res) => res.data);
}

export function exportMembers(globalMemberConditions, locationId) {
  // Returns the membership conditions
  const filterConditions = normalizeMembershipGlobalConditionTempModel(globalMemberConditions).toJS();
  const mergedFilterConditions = mergeAllFilterConditions(filterConditions);
  // Returns only the user action filters
  const userActionFilters = adjustUserActionsConditions(filterConditions);
  const url = '/api/FilterPopulation/exportPopulation';
  const requestData = {
    [activityConstants.FILTERED_POPULATION_CONDITIONS]: mergedFilterConditions,
    [activityConstants.FILTERED_POPULATION_USER_ACTIONS_CONDITIONS]: userActionFilters,
    [LOCATION_ID]: locationId,
  };
  return axios.post(url, requestData).then((res) => res.data);
}

function mergeAllFilterConditions(filterConditions) {
  let mergedConditions = [];
  const conditions = filterConditions[activityConstants.CONDITIONS];
  let conditionsOperator = activityConstants.CONDITION_AND;
  if (conditions && conditions[activityConstants.CONDITIONS_LIST]) {
    const cleanConditions = adjustConditions(conditions[activityConstants.CONDITIONS_LIST]);
    mergedConditions = mergedConditions.concat(cleanConditions);
    if (conditions[activityConstants.CONDITION_LIST_OPERATOR]) {
      conditionsOperator = conditions[activityConstants.CONDITION_LIST_OPERATOR];
    }
  }

  const statusCondition = filterConditions[activityConstants.MEMBERSHIP_STATUS_CONDITION];
  if (statusCondition) {
    mergedConditions.push(statusCondition);
  }

  const importMembersConditionWrapper = filterConditions[activityConstants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER];
  if (importMembersConditionWrapper) {
    const importMembersConditionMode = importMembersConditionWrapper[activityConstants.IMPORT_MEMBERS_MODE];
    const importMembersCondition = importMembersConditionMode === activityConstants.IMPORT_MEMBERS_COPY_PASTE_MODE
      ? importMembersConditionWrapper[activityConstants.IMPORT_MEMBERS_COPY_PASTE_MODE]
      : importMembersConditionWrapper[activityConstants.IMPORT_MEMBERS_UPLOAD_FILE_MODE];
    if (importMembersCondition) {
      const cleanImportCondition = adjustConditions([importMembersCondition])[0];
      mergedConditions.push(cleanImportCondition);
    }
  }
  return {
    [activityConstants.CONDITION_LIST_OPERATOR]: conditionsOperator,
    [activityConstants.CONDITIONS_LIST]: mergedConditions
  };
}

/**
 * Remove empty user action filters (no user action chosen) ans from each user action, remove empty conditions
 * @param filterConditions
 * @returns {*}
 */
function adjustUserActionsConditions(filterConditions) {
  const userActionsConditions = filterConditions[activityConstants.USER_ACTIONS_CONDITIONS];
  if (!userActionsConditions) return null;
  const userActionsConditionsList = userActionsConditions[activityConstants.USER_ACTIONS_CONDITIONS_LIST];
  if (!userActionsConditionsList || userActionsConditionsList.length === 0) return null;
  const adjustedConditionList = userActionsConditionsList.map((userActionConditions) => {
    const adjustedFilters = adjustConditions(userActionConditions[activityConstants.CONDITIONS][activityConstants.CONDITIONS_LIST]);
    userActionConditions[activityConstants.CONDITIONS][activityConstants.CONDITIONS_LIST] = adjustedFilters;
    return userActionConditions;
  }).filter((userActionConditions) =>
    userActionConditions[activityConstants.USER_ACTION_KEY] !== ''
  );
  if (adjustedConditionList.length === 0) {
    delete userActionsConditions[activityConstants.USER_ACTIONS_CONDITIONS_LIST];
  } else {
    userActionsConditions[activityConstants.USER_ACTIONS_CONDITIONS_LIST] = adjustedConditionList;
  }
  return userActionsConditions;
}

/**
 * remove empty conditions,
 * cast value to array when condition is list
 * @param conditions
 */
function adjustConditions(conditions) {
  return conditions
    .filter((condition) => condition[activityConstants.CONDITION_KEY] !== '')
    .map((condition) => {
      const newCondition = condition;
      if ([activityConstants.IS_ONE_OF, activityConstants.IS_NOT_ONE_OF].indexOf(newCondition[activityConstants.OPERATOR_KEY]) > -1 &&
        Array.isArray(newCondition[activityConstants.CONDITION_VALUE])) {
        newCondition[activityConstants.CONDITION_VALUE] = newCondition[activityConstants.CONDITION_VALUE]
          .map((str) => str.trim())
          .filter((str) => !_.isEmpty(str));
      } else if ([activityConstants.IS_ONE_OF, activityConstants.IS_NOT_ONE_OF].indexOf(newCondition[activityConstants.OPERATOR_KEY]) > -1 &&
        typeof newCondition[activityConstants.CONDITION_VALUE] === 'object') { // This case if for referral code with specific codes
        if (newCondition[activityConstants.CONDITION_VALUE][activityConstants.CONDITION_VALUE_REFERRAL_CODE]) {
          newCondition[activityConstants.CONDITION_VALUE][activityConstants.CONDITION_VALUE_REFERRAL_CODE] =
            newCondition[activityConstants.CONDITION_VALUE][activityConstants.CONDITION_VALUE_REFERRAL_CODE]
              .map((str) => str.trim())
              .filter((str) => !_.isEmpty(str));
        }
      } else if (typeof newCondition[activityConstants.CONDITION_VALUE] === 'string') {
        newCondition[activityConstants.CONDITION_VALUE] = newCondition[activityConstants.CONDITION_VALUE].trim();
      }
      if (!_.isNil(newCondition[activityConstants.ITEMS_POPULATION]) && !_.isNil(newCondition[activityConstants.ITEMS_POPULATION][activityConstants.CONDITIONS])) {
        newCondition[activityConstants.ITEMS_POPULATION][activityConstants.CONDITIONS][activityConstants.CONDITIONS_LIST] =
          adjustConditions(newCondition[activityConstants.ITEMS_POPULATION][activityConstants.CONDITIONS][activityConstants.CONDITIONS_LIST]);
      }
      return newCondition;
    });
}
