import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Radio } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import * as activityConstants from '../../../../activity.constants';
import DateTimeRange from '../../dateTimeConditions/DateTimeRangeCondition';
import DaysTimesCondition from '../../dateTimeConditions/DaysTimesCondition';
import './dateTimeGlobalConditions.styles.less';

class DateTimeGlobalConditions extends React.PureComponent {
  static propTypes = {
    actions: PropTypes.object,
    intl: PropTypes.object,
    datesRangeCondition: PropTypes.object,
    daysTimesCondition: PropTypes.object,
    onRadioChange: PropTypes.func,
    conditionType: PropTypes.string,
    tempValidationErrors: PropTypes.object,
    activityType: PropTypes.string,
    datePickerInsideModal: PropTypes.bool
  };

  render() {
    const { formatMessage } = this.props.intl;

    // const daysTimeData = this.props.daysTimesConditions && this.props.daysTimesConditions.size > 0 ? this.props.daysTimesConditions.get(activityConstants.CONDITION_VALUE) : List();
    return (
      <div className="choose-dateTimes-group">
        <p className={`dateTime-rule-title ${[activityConstants.ACTIVITY_TYPE_PUNCH_CARD, activityConstants.ACTIVITY_TYPE_GIFT, activityConstants.ACTIVITY_TYPE_PROMO_CODE].includes(this.props.activityType) ? 'one-option' : ''}`}>{formatMessage({ id: 'activity.globalConditions.datetTime.subTitle' })}</p>
        {![activityConstants.ACTIVITY_TYPE_PUNCH_CARD, activityConstants.ACTIVITY_TYPE_GIFT, activityConstants.ACTIVITY_TYPE_PROMO_CODE].includes(this.props.activityType) &&
          <span>
            <Radio
              label={formatMessage({ id: 'activity.globalConditions.dateTime.radioBtn.any' })}
              name="dateTimeGroup"
              value={activityConstants.DATETIME_ANY}
              checked={this.props.conditionType === activityConstants.DATETIME_ANY}
              onChange={this.props.onRadioChange}
              data-automation-id="globalConditions.dateTime.radioBtn.any"
            /><br />
            <Radio
              label={formatMessage({ id: 'activity.globalConditions.datetTime.radioBtn.specific' })}
              name="dateTimeGroup"
              value={activityConstants.DATETIME_SPECIFIC}
              checked={this.props.conditionType === activityConstants.DATETIME_SPECIFIC}
              onChange={this.props.onRadioChange}
              data-automation-id="globalConditions.dateTime.radioBtn.specificDateTime"
            />
            <br />
          </span>
        }
        {
          this.props.conditionType === activityConstants.DATETIME_SPECIFIC
            ?
              <Grid className="dateTime-fields-conditions gray-panel">
                <Grid.Row className="date-condition-row ">
                  <Grid.Column width={3} className="day-condition-row-subtitle inline-subtitle" >{formatMessage({ id: 'activity.globalConditions.datetTime.betweenDates' })}</Grid.Column>
                  <Grid.Column width={13}>
                    <DateTimeRange
                      condition={this.props.datesRangeCondition}
                      updateDateRangeDate={this.props.actions.updateGlobalDateRangeDate}
                      errors={this.props.tempValidationErrors ? this.props.tempValidationErrors.get(activityConstants.DATES_RANGE_CONDITION) : null}
                      allowForever={![activityConstants.ACTIVITY_TYPE_PUNCH_CARD, activityConstants.ACTIVITY_TYPE_GIFT, activityConstants.ACTIVITY_TYPE_PROMO_CODE].includes(this.props.activityType)}
                      allowNow={![activityConstants.ACTIVITY_TYPE_PUNCH_CARD, activityConstants.ACTIVITY_TYPE_GIFT, activityConstants.ACTIVITY_TYPE_PROMO_CODE].includes(this.props.activityType)}
                      datePickerInsideModal={this.props.datePickerInsideModal}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="row-divider"><div className="date-conditions-divider">&nbsp;</div></Grid.Row>
                <Grid.Row>
                  <DaysTimesCondition
                    automationIdPrefix="activity.globalCondition.daysTimesCondition"
                    condition={this.props.daysTimesCondition}
                    updateDaysTimes={this.props.actions.updateGlobalDaysTimes}
                    deleteDaysTimesCondition={this.props.actions.deleteGlobalDaysTimesCondition}
                    addDaysTimesCondition={this.props.actions.addGlobalDaysTimesCondition}
                    errors={this.props.tempValidationErrors ? this.props.tempValidationErrors.get(activityConstants.DAYS_TIMES_CONDITION) : null}
                  />
                </Grid.Row>
              </Grid>
            :
            null
        }

      </div>
    );
  }
}
export default injectIntl(DateTimeGlobalConditions);
