import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Grid, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import InputWrapper from '../../../../../../common/components/FormInputFields/InputWrapper/InputWrapper';
import * as schemaConstants from '../../../../activitySchema/activitySchema.constants';
import './exportEvent.styles.less';
import CheckBox from '../../../../../../common/components/FormInputFields/CheckboxWrapper/CheckboxWrapper';
import CustomHeader from './customHeader';
import CustomFieldWithMention from './customFieldWithMention';

class exportEvent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      advancedModeOpen: props.action && props.action.get(schemaConstants.EXPORT_EVENT_CUSTOM_BODY_FIELDS) && props.action.get(schemaConstants.EXPORT_EVENT_CUSTOM_BODY_FIELDS).size > 0 && props.action.getIn([schemaConstants.EXPORT_EVENT_CUSTOM_BODY_FIELDS, 0, schemaConstants.EXPORT_EVENT_BODY_KEY_FIELD]) !== '',
      customFields: [],
      customBodies: []
    };
  }

  static propTypes = {
    automationIdPrefix: PropTypes.string,
    onActionFieldUpdate: PropTypes.func.isRequired,
    action: PropTypes.object,
    index: PropTypes.number,
    validationErrors: PropTypes.object,
    mentionsList: PropTypes.object,
  };

  onCustomHeadersUpdate(name, value, customHeadersIndex, actionIndex) {
    const customHeaders = this.getCustomHeader();
    customHeaders[customHeadersIndex] = { ...customHeaders[customHeadersIndex], [name]: value.trim() };
    this.props.onActionFieldUpdate(schemaConstants.EXPORT_EVENT_CUSTOM_HEADERS, customHeaders, actionIndex);
  }
  onCustomBodyFieldUpdate(name, value, customBodyFieldsIndex, actionIndex) {
    const customBodyFields = this.getCustomBody();
    customBodyFields[customBodyFieldsIndex] = { ...customBodyFields[customBodyFieldsIndex], [name]: value.trim() };
    this.props.onActionFieldUpdate(schemaConstants.EXPORT_EVENT_CUSTOM_BODY_FIELDS, customBodyFields, actionIndex);
  }

  componentDidMount() {
    const customHeaders = this.getCustomHeader();
    const customBodyFields = this.getCustomBody();

    this.setState({customFields: customHeaders, customBodies: customBodyFields});
  }

  addCustomHeadersFields = (actionIndex) => {
    const customHeaders = this.getCustomHeader();
    customHeaders[this.state.customFields.length] = {'key': '', 'value': ''}; // default value for the column
    this.props.onActionFieldUpdate(schemaConstants.EXPORT_EVENT_CUSTOM_HEADERS, customHeaders, actionIndex);

    this.setState({ customFields: [...customHeaders] });
  }

  removeCustomHeaderField = (key, actionIndex) => {
    const customHeaders = this.getCustomHeader();
    customHeaders.splice(key, 1);
    this.props.onActionFieldUpdate(schemaConstants.EXPORT_EVENT_CUSTOM_HEADERS, customHeaders, actionIndex);

    this.setState({ customFields: [...customHeaders]});
  }

  addCustomBodyFields = (actionIndex) => {
    const customBodyFields = this.getCustomBody();
    customBodyFields[this.state.customBodies.length] = {'key': '', 'value': ''}; // default value for the column
    this.props.onActionFieldUpdate(schemaConstants.EXPORT_EVENT_CUSTOM_BODY_FIELDS, customBodyFields, actionIndex);

    this.setState({ customBodies: [...customBodyFields] });
  }

  removeCustomBodyField = (key, actionIndex) => {
    const customBodyFields = this.getCustomBody();
    customBodyFields.splice(key, 1);
    this.props.onActionFieldUpdate(schemaConstants.EXPORT_EVENT_CUSTOM_BODY_FIELDS, customBodyFields, actionIndex);

    this.setState({customBodies: [...customBodyFields]});

  }

  getCustomHeader() {
    const immutableCustomHeaders = this.props.action.get(schemaConstants.EXPORT_EVENT_CUSTOM_HEADERS);
    return immutableCustomHeaders ? immutableCustomHeaders.toJSON() : [];
  }

  getCustomBody(){
    const immutableCustomBodyFields = this.props.action.get(schemaConstants.EXPORT_EVENT_CUSTOM_BODY_FIELDS);
    return immutableCustomBodyFields ? immutableCustomBodyFields.toJSON() : [];
  }

  render() {
    const { index, action, onActionFieldUpdate, validationErrors, automationIdPrefix, mentionsList } = this.props;
    const { advancedModeOpen } = this.state;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    return (
      <Grid className="action export-event-action">
        <Grid.Row columns={2}>
          <Grid.Column width={8}>
            <p className="action-label">{formatMessage({ id: 'activity.action.eventName' })}</p>
            <InputWrapper
              fluid
              name={schemaConstants.EXPORT_EVENT_NAME_FIELD}
              onChange={(e, data) => onActionFieldUpdate(data.name, data.value, index)}
              value={action.get(schemaConstants.EXPORT_EVENT_NAME_FIELD)}
              prefix={`${automationIdPrefix}.name`}
              error={validationErrors ? validationErrors.get(schemaConstants.EXPORT_EVENT_NAME_FIELD) : null}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <p className="action-label">{formatMessage({ id: 'activity.action.url' })}</p>
            <InputWrapper
              fluid
              name={schemaConstants.EXPORT_EVENT_DESTINATION_FIELD}
              onChange={(e, data) => onActionFieldUpdate(data.name, data.value, index)}
              value={action.get(schemaConstants.EXPORT_EVENT_DESTINATION_FIELD)}
              prefix={`${automationIdPrefix}.destination`}
              error={validationErrors ? validationErrors.get(schemaConstants.EXPORT_EVENT_DESTINATION_FIELD) : null}
            />
          </Grid.Column>
        </Grid.Row>
        {
          (this.state.customFields.map((x, i) => {
            const customHeader = action.getIn([schemaConstants.EXPORT_EVENT_CUSTOM_HEADERS, i]);
            return (<CustomHeader
                key={i}
                validationErrors={validationErrors}
                customHeader={customHeader}
                automationIdPrefix={automationIdPrefix}
                index={i}
                onActionFieldUpdate={(customHeadersIndex, key, value) => this.onCustomHeadersUpdate(key, value, customHeadersIndex, index)}
                mentionsList={mentionsList}
                removeItem={this.removeCustomHeaderField.bind(this)}
                showDeleteButton={this.state.customFields.length > 1}
                parentIndex={index}
              />
            );
          }))
        }
        <a role={"button"} style={{marginBottom: 20}} onClick={() => { this.addCustomHeadersFields.bind(this)(index); }}>
          <Icon
            style={{fontsize: '10px'}}
            className="icon como-ic-plus-in-circle"
          />Add Custom Header
        </a>
        <Accordion data-automation-id={`${this.props.automationIdPrefix}.advanced`} style={{width: '100%'}}>
          <Accordion.Title active={advancedModeOpen} onClick={() => this.setState({advancedModeOpen: !this.state.advancedModeOpen})}>
            <Icon className={`como-ic-angle-double-${advancedModeOpen ? 'down' : 'right'}`} />
            Advanced
          </Accordion.Title>
          <Accordion.Content className={"advanced-content"} style={{boxShadow: "inset 0 2px 9px 0 rgb(0 0 0 / 20%)", backgroundColor: 'white'}} active={advancedModeOpen}>
            <div className={"body-fields-wrapper"}>
              {
                (this.state.customBodies.map((x, i) => {
                  const customBodyField = action.getIn([schemaConstants.EXPORT_EVENT_CUSTOM_BODY_FIELDS, i]);
                  return (<CustomFieldWithMention
                    key={i}
                    validationErrors={validationErrors}
                    customBodyField={customBodyField}
                    automationIdPrefix={automationIdPrefix}
                    index={i}
                    onActionFieldUpdate={(customBodyFieldIndex, key, value) => this.onCustomBodyFieldUpdate(key, value, customBodyFieldIndex, index)}
                    mentionsList={mentionsList}
                    removeItem={this.removeCustomBodyField.bind(this)}
                    showDeleteButton={this.state.customBodies.length > 1}
                    parentIndex={index}
                  />);
                }))
              }
              <a role={"button"} style={{marginBottom: 20}} onClick={() => { this.addCustomBodyFields.bind(this)(index); }}>
                <Icon
                  style={{fontsize: '10px', paddingTop: '20px'}}
                  className="icon como-ic-plus-in-circle"
                />Add Custom Body
              </a>
            </div>
          </Accordion.Content>
        </Accordion>
        <Grid.Row columns={2}>
          <Grid.Column width={8}>
            <CheckBox
              name={schemaConstants.EXPORT_EVENT_EXTEND_MEMBERSHIP_DATA_FIELD}
              checked={action.get(schemaConstants.EXPORT_EVENT_EXTEND_MEMBERSHIP_DATA_FIELD)}
              data-automation-id={`${automationIdPrefix}.${schemaConstants.EXPORT_EVENT_EXTEND_MEMBERSHIP_DATA_FIELD}`}
              onChange={(e, data) => onActionFieldUpdate(schemaConstants.EXPORT_EVENT_EXTEND_MEMBERSHIP_DATA_FIELD, data.checked, index)}
              label={formatMessage({ id: 'activity.extendMembershipData' })}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default injectIntl(exportEvent);
