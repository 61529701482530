import axios from 'axios';

export function createPointShopItem(data) {
  return axios.post('/api/pointShopItems', data)
    .then((res) => res.data);
}

export function deletePointShopItem(id) {
  return axios.delete(`/api/pointShopItems/${id}`)
    .then((res) => res.data);
}
