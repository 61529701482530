/*
 *
 * FilterMembers constants
 *
 */

// ACTIONS CONSTANTS

export const LARGE_TABLE_QUERY = 'large_table_query';
export const DATA_EXPORT = 'DataExport';
export const DATA = 'data';
export const CONFIG = 'config';
export const CONFIG_FETCHED = 'configFetched';
export const FILES_DATA = 'filesData';
export const FILES_DATA_FETCHED = 'filesDataFetched';
export const SIGNED_URL = 'signedUrl';
export const SIGNED_URL_FETCHED = 'signedUrlFetched';
export const ACTIVATE = 'activate';
export const ACTIVATING = 'activating';
export const GET_CONFIG = 'dataAndBi/DataExport/GET_CONFIG';
export const CONFIG_FETCHED_SUCCESS = 'dataAndBi/DataExport/CONFIG_FETCHED_SUCCESS';
export const CONFIG_FETCHED_FAIL = 'dataAndBi/DataExport/CONFIG_FETCHED_FAIL';
export const GET_FILES_DATA = 'dataAndBi/DataExport/GET_FILES_DATA';
export const FILES_DATA_FETCHED_SUCCESS = 'dataAndBi/DataExport/FILES_DATA_FETCHED_SUCCESS';
export const FILES_DATA_FETCHED_FAIL = 'dataAndBi/DataExport/FILES_DATA_FETCHED_FAIL';
export const GET_SIGNED_URL = 'dataAndBi/DataExport/GET_SIGNED_URL';
export const SIGNED_URL_FETCHED_SUCCESS = 'dataAndBi/DataExport/SIGNED_URL_FETCHED_SUCCESS';
export const SIGNED_URL_FETCHED_FAIL = 'dataAndBi/DataExport/SIGNED_URL_FETCHED_FAIL';
export const ACTIVATED_SUCCESS = 'dataAndBi/DataExport/ACTIVATED_SUCCESS';
export const ACTIVATED_FAIL = 'dataAndBi/DataExport/ACTIVATED_FAIL';

// STORE CONSTANTS
