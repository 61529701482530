/* eslint-disable no-undef */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import * as _ from 'lodash';
import { List } from 'immutable';
import Modal from '../../../../../../common/components/CustomModal/CustomModal';
import MembershipGlobalConditions from './MembershipGlobalConditions';
import * as activityConstants from '../../../../activity.constants';
import * as globalConditionsActions from '../globalConditions.actions';
import ValidationErrorMessage from '../../../../../../common/components/ValidationErrorMessage/ValidationErrorMessage';
import tracker from '../../../../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../../../../common/utils/tracking/tracking.consts';
import * as activitySelectors from '../../../../activity.selectors';
import * as activitySelectorsUtils from '../../../../activity.selectors.utils';
import Tooltip from '../../../../../../common/components/Tooltip/Tooltip';
import CaseConditionSummary from '../../../summary/ConditionSummary';
import * as commonComponentsConstants from '../../../../../../common/components/common.components.constants';
import FilterUserActionSummary from '../filters/FilterUserActionsSummary';
import OneTimeStats from '../../../../../BusinessCenter/views/OneTimeStats';
import * as appConstants from '../../../../../App/app.constants';
import withAuthorization from '../../../../../../common/helpers/authorization';

const AUTOMATION_NAME = 'membershipGlobalConditions';

class MembershipGlobalConditionsWrapper extends React.PureComponent {


  static propTypes = {
    currentMembershipConditions: PropTypes.object,
    filteredPopulationSize: PropTypes.number,
    filtersInProgress: PropTypes.bool,
    isMembersFilter: PropTypes.bool,
    isModalOpen: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    tempModel: PropTypes.object,
    currentConditionType: PropTypes.string,
    tempMembershipStatusCondition: PropTypes.object,
    schemaMembershipConditions: PropTypes.object,
    actions: PropTypes.object,
    validationErrors: PropTypes.object,
    modalValidationErrors: PropTypes.object,
    membershipGlobalConditionModalValidationErrors: PropTypes.object,
    noMembersValidationErrors: PropTypes.string,
    isSaveSearchCriteriaAllowed: PropTypes.bool,
    disabled: PropTypes.bool,
    exportInProgress: PropTypes.bool,
    filteredPopulation: PropTypes.object,
    membershipGlobalOperator: PropTypes.string,
    activityStatus: PropTypes.string,
    exportMembersReady: PropTypes.bool,
    schedulingData: PropTypes.object,
    activity: PropTypes.object,
    abTestMode: PropTypes.bool,
    isABTestingAllowed: PropTypes.bool,
    hasFullPermission: PropTypes.func,
    getBusinessBundlePlan: PropTypes.func.isRequired,
  };

  state = {
    isAnalysisModalOpen: false
  };


  acceptChanges = () => {
    if (this.props.isMembersFilter) {
      this.props.actions.filterMembersAndApplyFromTempModel();
    } else {
      this.props.actions.closeGlobalMembershipConditionsModal(this.props.isMembersFilter, true);
    }
  };

  cancelChanges = () => {
    if (this.props.filtersInProgress) {
      return;
    }
    this.props.actions.closeGlobalMembershipConditionsModal(this.props.isMembersFilter, false);
  };

  saveSearchCriteria = () => {
    if (this.props.filteredPopulationSize) {
      this.props.actions.clearFilteredPopulation();
    }
    this.props.actions.closeGlobalMembershipConditionsModal(this.props.isMembersFilter, true);
  };

  openModal = () => {
    this.props.actions.openGlobalMembershipConditionsModal(this.props.isMembersFilter);
  };

  handleAnalysisModalOpen = () => {
    this.setState({
      isAnalysisModalOpen: true
    });
  };
  handleAnalysisModalClose = () => {
    this.setState({
      isAnalysisModalOpen: false
    });
  };

  /**
   * trigger message depending on condition type
   */
  get triggerMessage() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    if (this.props.isMembersFilter) {
      if (!_.isNil(this.props.filteredPopulationSize)) {
        if (this.props.schedulingData.get(activityConstants.TYPE) === activityConstants.SCHEDULE_IMMEDIATE) {
          return formatMessage({ id: 'onetime.filter.population.label' }, { numOfMembers: this.props.filteredPopulationSize });
        }
        if (this.props.exportMembersReady) {
          return <span><Icon className="como-svg-tilda" /><span>{formatMessage({ id: 'onetime.completed.filter.population.label' }, { numOfMembers: this.props.filteredPopulationSize })}</span></span>;
        }
        return formatMessage({ id: 'onetime.scheduled.filter.population.label' }, { numOfMembers: this.props.filteredPopulationSize });

      }
      return formatMessage({ id: 'onetime.no.filter.population.label' });
    }
    let triggerMessage = formatMessage({ id: 'activity.globalConditions.toAllCostumers' });
    if (this.props.currentConditionType === activityConstants.MEMBERS_REGISTERED) {
      triggerMessage = formatMessage({ id: 'activity.globalConditions.toActiveMembers' });
    } else if (this.props.currentConditionType === activityConstants.MEMBERS_SPECIFIC) {
      triggerMessage = formatMessage({ id: 'activity.globalConditions.toSpecificMembers' });
    } else if (this.props.currentConditionType === activityConstants.NON_MEMBERS) {
      triggerMessage = formatMessage({ id: 'activity.globalConditions.nonMembers' });
    }
    return triggerMessage;
  }

  get currentMembershipGlobalConditions() {
    return this.props.currentMembershipConditions ?
      this.props.currentMembershipConditions.getIn([activityConstants.CONDITIONS, activityConstants.CONDITIONS_LIST]) :
      null;
  }

  get currentMembershipGlobalConditionsOperator() {
    return this.props.currentMembershipConditions ?
      this.props.currentMembershipConditions.getIn([activityConstants.CONDITIONS, activityConstants.CONDITION_LIST_OPERATOR]) :
      activityConstants.CONDITION_AND;
  }

  get currentMemberActionConditions() {
    return this.props.currentMembershipConditions ?
      this.props.currentMembershipConditions.getIn([activityConstants.USER_ACTIONS_CONDITIONS, activityConstants.USER_ACTIONS_CONDITIONS_LIST]) :
      null;
  }

  get currentMemberActionConditionsOperator() {
    return this.props.currentMembershipConditions ?
      this.props.currentMembershipConditions.getIn([activityConstants.USER_ACTIONS_CONDITIONS, activityConstants.CONDITION_LIST_OPERATOR]) :
      null;
  }

  get currentImportMembersCondition() {
    return this.props.currentMembershipConditions ?
      this.props.currentMembershipConditions.get(activityConstants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER) :
      null;
  }

  get currentMembershipStatusCondition() {
    return this.props.currentMembershipConditions ?
      this.props.currentMembershipConditions.get(activityConstants.MEMBERSHIP_STATUS_CONDITION) : null;
  }

  get currentMergedMembershipConditionsList() {
    return this.currentMembershipGlobalConditions ?
      this.currentMembershipGlobalConditions.push(this.currentMembershipStatusCondition) :
      List().push(this.currentMembershipStatusCondition);
  }

  get tempModelMembershipGlobalConditions() {
    return this.props.tempModel ?
      this.props.tempModel.getIn([activityConstants.CONDITIONS, activityConstants.CONDITIONS_LIST]) :
      null;
  }

  get tempModelMembershipGlobalConditionsOperator() {
    return this.props.tempModel ?
      this.props.tempModel.getIn([activityConstants.CONDITIONS, activityConstants.CONDITION_LIST_OPERATOR]) :
      null;
  }

  get tempModelMembershipStatusCondition() {
    return this.props.tempModel ? this.props.tempModel.get(activityConstants.MEMBERSHIP_STATUS_CONDITION) : null;
  }

  get tempModelImportMembershipCondition() {
    return this.props.tempModel ? this.props.tempModel.get(activityConstants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER) : null;
  }

  get conditionType() {
    return this.props.tempModel ? this.props.tempModel.get(activityConstants.TYPE) : null;
  }

  get trigger() {
    return this.renderMembershipConditionsTriggerComponent();
  }

  renderMembershipConditionsTriggerComponent() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    return (<div className="modal-trigger">
      <span>
        {(this.props.isMembersFilter && !_.isNil(this.props.filteredPopulationSize) && this.props.activityStatus === activityConstants.ACTIVITY_STATUS_ACTIVE && this.props.exportMembersReady) &&
          (this.props.schedulingData.get(activityConstants.TYPE) !== activityConstants.SCHEDULE_IMMEDIATE)
            ?
              <Tooltip
                wide="very"
                trigger={(<Icon className="como-ic-help" />)}
                content={formatMessage({ id: 'action.filter.hint.memberCount' })}
              />
            :
            null
          }
      </span>

      {this.props.isMembersFilter && !_.isNil(this.props.filteredPopulationSize) ? this.renderFilterMembersTriggerComponent() :
        this.memberShipConditionLink()
      }

      {(this.props.isMembersFilter && !_.isNil(this.props.filteredPopulationSize) && this.props.exportMembersReady) &&
        <div className={'export-members-wrapper divider'}>
          {(this.props.activityStatus === activityConstants.ACTIVITY_STATUS_ACTIVE) ?
            <Button
              className={'link export-filter unset-padding '}
              basic
              id={'export-filter'}
              disabled={this.props.exportInProgress}
              onClick={() => this.props.actions.exportOneTimePopulation(this.props.filteredPopulation)}
              loading={this.props.exportInProgress}
            >{formatMessage({ id: 'filter.export' })}
              {!this.props.exportInProgress && <Icon className="como-ic-export unset-padding" />}
            </Button>
            :
            null
          }
          {activitySelectorsUtils.calculateActivityViewStatus(this.props.activity) === activityConstants.ACTIVITY_STATUS_COMPLETED ?
            <span className={'divider'} style={{ marginLeft: 15 }}>
              <Button
                className={'link export-filter unset-padding'}
                basic
                id={'export-filter'}
                onClick={() => this.handleAnalysisModalOpen()}
              >{formatMessage({ id: 'action.filter.updatedMembers' })}
                {!this.props.exportInProgress && <Icon className="como-ic-activity-analysis como-ic-activity-analysis-oneTime unset-padding" />}
              </Button>
            </span>
            :
            null
          }
          <OneTimeStats
            activity={this.props.activity}
            onConfirm={this.handleAnalysisModalClose}
            open={this.state.isAnalysisModalOpen}
            activityName={this.props.activity.get(activityConstants.NAME)}
            type={this.props.activity.get(activityConstants.TYPE)}
          />
        </div>
      }
      {this.props.validationErrors && !this.props.isMembersFilter ?
        <ValidationErrorMessage
          errorLayoutType={commonComponentsConstants.COMPONENT_TYPE_PARAGRAPH}
          dataAutomationId={'member.global.conditions.error.message'}
          errorMessage={formatMessage({ id: 'activity.validation.error.popup.outside.message' })}
        />
        : null}

      {this.props.abTestMode && !this.props.isABTestingAllowed ?
        <ValidationErrorMessage
          errorLayoutType={commonComponentsConstants.COMPONENT_TYPE_PARAGRAPH}
          dataAutomationId={'member.global.conditions.error.message'}
          errorMessage={formatMessage({ id: 'activity.validation.error.abtesting.message' })}
        />
        : null
      }

    </div>);
  }

  renderFilterMembersTriggerComponent() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    let hasImportMembersCopyPasteConditionValue = false;
    if (this.currentImportMembersCondition) {
      const conditionValue = this.currentImportMembersCondition.getIn([activityConstants.IMPORT_MEMBERS_COPY_PASTE_MODE, activityConstants.CONDITION_VALUE]);
      if (conditionValue && conditionValue.size > 0) {
        hasImportMembersCopyPasteConditionValue = true;
      }
    }
    const hasImportMembersFromFileMode = this.currentImportMembersCondition && this.currentImportMembersCondition.get(activityConstants.IMPORT_MEMBERS_MODE) === activityConstants.IMPORT_MEMBERS_UPLOAD_FILE_MODE;
    return (<Tooltip
      className="population-filter-tooltip"
      data-automation-id="population-filters-tooltip"
      wide="very"
      content={
        <div>
          {
            this.currentImportMembersCondition && this.currentImportMembersCondition.get(activityConstants.IMPORT_MEMBERS_MODE) === activityConstants.IMPORT_MEMBERS_UPLOAD_FILE_MODE &&
            (<div>
              {formatMessage({ id: 'activity.globalConditions.filter.import.members.file.tooltip' })}
            </div>)
          }
          {!hasImportMembersCopyPasteConditionValue && !hasImportMembersFromFileMode && this.currentMergedMembershipConditionsList.map((condition, index) => {
            const isStatusCondition = condition.equals(this.currentMembershipStatusCondition);

            return (
              <div key={index.toString()}>
                {index > 0 && this.currentMergedMembershipConditionsList.getIn([index - 1, activityConstants.CONDITION_KEY]) &&
                  <div className="conjunction">
                    {formatMessage({ id: `conjunction.${isStatusCondition ? 'and' : this.currentMembershipGlobalConditionsOperator}` })}
                  </div>
                }
                <CaseConditionSummary
                  condition={condition}
                  conditionsFromSchema={this.props.schemaMembershipConditions}
                />
              </div>
            );
          })}
          {!hasImportMembersCopyPasteConditionValue && this.currentMemberActionConditions
            && this.currentMemberActionConditions.map((userAction, index) =>
              (
                <div key={index.toString()}>
                  {(userAction.get(activityConstants.USER_ACTION_KEY) && (index > 0 || this.currentMergedMembershipConditionsList)) &&
                    <div className="conjunction">{formatMessage({ id: `conjunction.${this.currentMemberActionConditionsOperator}` })}</div>}
                  <FilterUserActionSummary userAction={userAction} />
                </div>
              )
            )}
          {hasImportMembersCopyPasteConditionValue &&
            (
              <div>
                {formatMessage({ id: 'activity.globalConditions.filter.import.members.tooltip' })}
              </div>
            )
          }
          {this.props.filteredPopulation.get(activityConstants.FILTERED_POPULATION_ADJUSTED_BY_COMILLIA_HINT) &&
            <span>{` (${formatMessage({ id: 'adjusted.by.comillia.hint' })})`}</span>
          }
        </div>
      }
      trigger={this.memberShipConditionLink()}
      position="bottom left"
    />);
  }


  memberShipConditionLink() {
    const RuleMemberSpecificClassName =
      this.props.currentConditionType === activityConstants.MEMBERS_SPECIFIC &&
      this.props.activity.get(activityConstants.TYPE) === activityConstants.ACTIVITY_TYPE_RULE ?
        'background-Member-Specific-conditions-highlight' : '';

    const membershipConditionTriggerClassNames = classNames('condition-link modal-trigger-link', {
      'modal-trigger-hint': this.props.isMembersFilter && _.isNil(this.props.filteredPopulationSize),
      'errors-inside-global-conditions-highlight': this.props.validationErrors,
      'disabled': this.props.disabled
    });
    return (<span>
      <a
        role="link"
        className={membershipConditionTriggerClassNames}
        onClick={this.props.disabled ? null : this.openModal}
      >
        <span className={RuleMemberSpecificClassName}>
          <Icon className="como-ic-members" data-automation-id="globalConditions.members" />
          {this.triggerMessage}
        </span>


      </a>
    </span>);

  }

  /**
   * render
   * @returns {*}
   */
  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    let filtersErrorMessage = null;
    // Non Member/All customer deal logic
    const isThisNonMemberOrAllCustomerDeal = this.props.activity.get(activityConstants.MEMBERSHIP_GLOBAL_CONDITIONS) ?
        (this.props.activity.get(activityConstants.MEMBERSHIP_GLOBAL_CONDITIONS).get(activityConstants.TYPE) === activityConstants.ALL_COSTUMERS ||
        this.props.activity.get(activityConstants.MEMBERSHIP_GLOBAL_CONDITIONS).get(activityConstants.TYPE) === activityConstants.NON_MEMBERS) : false;
    const isNonMemberDealAllowed = this.props.hasFullPermission(appConstants.FEATURE_NON_MEMBER_DEAL);
    const isThisActivityTypeDeal = this.props.activity.get(activityConstants.TYPE) === activityConstants.ACTIVITY_TYPE_DEAL;
    const isThisActivityAlreadySaved = this.props.activity.get(activityConstants.STATUS) !== '';
    const shouldNonMemberConditionBeShown = (isThisActivityTypeDeal && isNonMemberDealAllowed) ||
      (isThisActivityTypeDeal && !isNonMemberDealAllowed && isThisNonMemberOrAllCustomerDeal && isThisActivityAlreadySaved);

    if (this.props.isMembersFilter) {
      if (this.props.modalValidationErrors) {
        filtersErrorMessage = formatMessage({ id: 'activity.validation.error.filter.general' });
      } else if (this.props.noMembersValidationErrors) {
        filtersErrorMessage = formatMessage({ id: this.props.noMembersValidationErrors });
      }
    }
    if (this.props.membershipGlobalConditionModalValidationErrors) {
      filtersErrorMessage = formatMessage({ id: this.props.membershipGlobalConditionModalValidationErrors });
    }
    const modalClassNames = classNames('membership-global-modal', {
      'membership-filters-modal': this.props.isMembersFilter
    });
    if (this.props.disabled) {
      return this.trigger;
    }
    return (
      <Modal
        className={modalClassNames}
        automationIdPrefix={AUTOMATION_NAME}
        header={
          formatMessage({ id: 'activity.globalConditions.members' })
        }
        actions={[
          <Button
            key="globalMemConditionCancelBtn"
            onClick={this.cancelChanges}
            basic
            className="link modal-button"
            disabled={this.props.filtersInProgress}
            data-automation-id="globalConditions.members.btnCancel"
          >
            {formatMessage({ id: 'general.button.cancel' })}
          </Button>,
          this.props.isSaveSearchCriteriaAllowed &&
          <Button
            key="globalMemConditionSaveFilterCriteria"
            onClick={tracker.wrapButtonAction(this.saveSearchCriteria, trackerConstants.BUTTON_TYPE_SAVE_FILTER_CRITERIA)}
            basic
            className="link modal-button stick-to-left"
            data-automation-id="globalConditions.members.btnSaveFilterCriteria"
            disabled={this.props.filtersInProgress}
          >
            {formatMessage({ id: 'general.button.saveFilterCriteria' })}
          </Button>,
          <Button
            key="globalMemConditionSaveBtn"
            onClick={tracker.wrapButtonAction(this.acceptChanges, trackerConstants.BUTTON_TYPE_FILTER_POPULATION)}
            className="modal-button"
            data-automation-id="globalConditions.members.btnSave"
            disabled={this.props.isReadOnly || this.props.filtersInProgress}
            loading={this.props.filtersInProgress}
          >
            {this.props.isMembersFilter ? formatMessage({ id: 'general.button.filter' }) : formatMessage({ id: 'general.button.save' })}
          </Button>
        ]}
        trigger={this.trigger}
        content={
          <div>
            {filtersErrorMessage && (
              <div className="stick-to-header-wrapper">
                <div className="modal-toolbar-error-panel">
                  <span className="toolbar-error-text">{filtersErrorMessage}</span>
                </div>
              </div>
            )}
            <MembershipGlobalConditions
              clearGlobalImportMembershipCondition={this.props.actions.clearGlobalImportMembershipCondition}
              importMembershipGlobalCondition={this.tempModelImportMembershipCondition}
              membershipGlobalConditions={this.tempModelMembershipGlobalConditions}
              conditionType={this.conditionType}
              isMembersFilter={this.props.isMembersFilter}
              goingToPasteImportMembersData={this.props.actions.goingToPasteImportMembersData}
              membershipStatusCondition={this.tempModelMembershipStatusCondition}
              schemaMembershipConditions={this.props.schemaMembershipConditions}
              tempMembershipStatusCondition={this.props.tempMembershipStatusCondition}
              addCondition={this.props.actions.addGlobalMembershipCondition}
              updateConditionsOperator={this.props.actions.updateGlobalMembershipConditionsOperator}
              membershipGlobalOperator={this.tempModelMembershipGlobalConditionsOperator}
              updateConditionField={this.props.actions.updateGlobalMembershipConditionField}
              updateGlobalConditionsType={this.props.actions.updateGlobalConditionsType}
              deleteCondition={this.props.actions.deleteGlobalMembershipCondition}
              resetCondition={this.props.actions.resetGlobalMembershipCondition}
              updateGlobalImportMembershipCondition={this.props.actions.updateGlobalImportMembershipCondition}
              updateGlobalMembershipStatusCondition={this.props.actions.updateGlobalMembershipStatusCondition}
              validationErrors={this.props.modalValidationErrors}
              resetMembershipStatusCondition={this.props.actions.resetMembershipStatusCondition}
              saveMembershipStatusCondition={this.props.actions.saveMembershipStatusCondition}
              filterErrorExists={!!filtersErrorMessage}
              updateFilterUserActionType={this.props.actions.updateFilterUserActionType}
              adjustedByComillia={this.props.filteredPopulation ? this.props.filteredPopulation.get(activityConstants.FILTERED_POPULATION_ADJUSTED_BY_COMILLIA_HINT) : null}
              shouldNonMemberConditionBeShown={shouldNonMemberConditionBeShown}
              getBusinessBundlePlan={this.props.getBusinessBundlePlan}
            />
          </div>}
        size={960}
        closeIcon={<Icon className="como-ic-close" name="close" onClick={this.cancelChanges} />}
        open={this.props.isModalOpen !== undefined && this.props.isModalOpen}
      />
    );
  }

}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(globalConditionsActions, dispatch)
  };
}

const mapStateToProps = (state, props) => ({
  tempModel: activitySelectors.getMembershipGlobalConditionTempModel(state),
  activity: activitySelectors.getActivityDataModel(state),
  currentConditionType: activitySelectors.getMembershipGlobalConditionType(state, props.isMembersFilter),
  currentMembershipConditions: activitySelectors.getMembershipGlobalConditions(state, props.isMembersFilter),
  filteredPopulationSize: activitySelectors.getFilteredPopulationSize(state, props.isMembersFilter),
  filteredPopulation: activitySelectors.getOneTimeActionFilteredPopulation(state),
  filtersInProgress: activitySelectors.getFiltersInProgressFlag(state),
  isModalOpen: activitySelectors.getIsMembershipConditionsModalOpenFlag(state),
  isReadOnly: activitySelectors.isActivityDisabled(state),
  isSaveSearchCriteriaAllowed: activitySelectors.isOneTimeSaveSearchCriteriaAllowed(state),
  tempMembershipStatusCondition: activitySelectors.getMembershipStatusConditionTempModel(state),
  validationErrors: activitySelectors.getMembershipGlobalConditionValidationErrors(state),
  modalValidationErrors: props.isMembersFilter ? activitySelectors.getFiltersConditionsValidationErrors(state) : activitySelectors.getMembershipGlobalConditionModalValidationErrors(state),
  membershipGlobalConditionModalValidationErrors: activitySelectors.getTempModelMembershipGlobalConditionsValidationErrors(state),
  noMembersValidationErrors: activitySelectors.getFiltersNumberOfMembersValidationErrors(state),
  exportInProgress: activitySelectors.getExportInProgress(state),
  activityStatus: activitySelectors.getActivityStatus(state),
  exportMembersReady: activitySelectors.getActivityExportMembersIsReady(state),
  schedulingData: activitySelectors.getOneTimeActionScheduling(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuthorization(injectIntl(MembershipGlobalConditionsWrapper)));
