export const GET_POINT_SHOP_ITEMS = 'app/PointShop/GET_POINT_SHOP_ITEMS';
export const GET_POINT_SHOP_ITEMS_SUCCESS = 'app/PointShop/GET_POINT_SHOP_ITEMS_SUCCESS';
export const GET_POINT_SHOP_ITEMS_ERROR = 'app/PointShop/GET_POINT_SHOP_ITEMS_ERROR';

export const GET_POINT_SHOP_ITEMS_ANALYSIS = 'app/PointShop/GET_POINT_SHOP_ITEMS_ANALYSIS';
export const GET_POINT_SHOP_ITEMS_ANALYSIS_SUCCESS = 'app/PointShop/GET_POINT_SHOP_ITEMS_ANALYSIS_SUCCESS';
export const GET_POINT_SHOP_ITEMS_ANALYSIS_IN_PROGRESS = 'app/PointShop/GET_POINT_SHOP_ITEMS_ANALYSIS_IN_PROGRESS';
export const GET_POINT_SHOP_ITEMS_ANALYSIS_ERROR = 'app/PointShop/GET_POINT_SHOP_ITEMS_ANALYSIS_ERROR';

export const GET_POINT_SHOP_ITEM = 'app/PointShop/GET_POINT_SHOP_ITEM';
export const GET_POINT_SHOP_ITEM_SUCCESS = 'app/PointShop/GET_POINT_SHOP_ITEM_SUCCESS';
export const GET_POINT_SHOP_ITEM_ERROR = 'app/PointShop/GET_POINT_SHOP_ITEM_ERROR';
export const CLEAR_POINT_SHOP_ITEM_DATA = 'app/PointShop/CLEAR_POINT_SHOP_ITEM_DATA';
export const CLEAR_POINT_SHOP_ITEM_IMAGE_ERROR = 'app/PointShop/CLEAR_POINT_SHOP_ITEM_IMAGE_ERROR';

export const CREATE_POINT_SHOP_ITEM = 'app/PointShop/CREATE_POINT_SHOP_ITEM';
export const CREATE_POINT_SHOP_ITEM_SUCCESS = 'app/PointShop/CREATE_POINT_SHOP_ITEM_SUCCESS';
export const CREATE_POINT_SHOP_ITEM_ERROR = 'app/PointShop/CREATE_POINT_SHOP_ITEM_ERROR';

export const UPDATE_POINT_SHOP_ITEM = 'app/PointShop/UPDATE_POINT_SHOP_ITEM';
export const UPDATE_POINT_SHOP_ITEM_SUCCESS = 'app/PointShop/UPDATE_POINT_SHOP_ITEM_SUCCESS';
export const UPDATE_POINT_SHOP_ITEM_ERROR = 'app/PointShop/UPDATE_POINT_SHOP_ITEM_ERROR';

export const UPDATE_POINT_SHOP_ITEM_STATUS = 'app/PointShop/UPDATE_POINT_SHOP_ITEM_STATUS';
export const UPDATE_POINT_SHOP_ITEM_STATUS_SUCCESS = 'app/PointShop/UPDATE_POINT_SHOP_ITEM_STATUS_SUCCESS';
export const UPDATE_POINT_SHOP_ITEM_STATUS_ERROR = 'app/PointShop/UPDATE_POINT_SHOP_ITEM_STATUS_ERROR';

export const DELETE_POINT_SHOP_ITEM = 'app/PointShop/DELETE_POINT_SHOP_ITEM';
export const DELETE_POINT_SHOP_ITEM_SUCCESS = 'app/PointShop/DELETE_POINT_SHOP_ITEM_SUCCESS';
export const DELETE_POINT_SHOP_ITEM_ERROR = 'app/PointShop/DELETE_POINT_SHOP_ITEM_ERROR';

export const POINT_SHOP_ITEMS = 'pointShopItems';
export const POINT_SHOP_ITEMS_ANALYSIS = 'pointShopItemsAnalysis';
export const POINT_SHOP_ITEMS_ANALYSIS_IN_PROGRESS = 'pointShopItemsAnalysisInProgress';
export const POINT_SHOP_ITEM = 'pointShopItem';
export const SORT = 'sort';
export const ORDER = 'order';
export const ORDER_DESCENDING = 'descending';
export const POINT_SHOP_ITEMS_LOADED = 'pointShopItemsLoaded';
export const POINT_SHOP_ITEMS_CREATED = 'pointShopItemCreated';
export const POINT_SHOP_ITEMS_ERROR = 'pointShopItemError';
export const POINT_SHOP_ITEM_LOADED = 'pointShopItemLoaded';
export const POINT_SHOP_ITEM_ERROR = 'pointShopItemError';
export const POINT_SHOP_ITEM_IMAGE_ERROR = 'pointShopItemImageError';
