import { call, put, takeLatest } from 'redux-saga/effects';
import * as constants from './mailTemplates.constants';
import * as api from './mailTemplates.api';
import * as errorConstant from '../ErrorMessage/errorMessage.constants';

function* getMailTemplates() {
  try {
    const data = yield call(api.getMailTemplates);
    yield put({ type: constants.GET_MAIL_TEMPLATES_SUCCESS, data });
  } catch (err) {
    yield put({ type: constants.GET_MAIL_TEMPLATES_ERROR, message: err.message });
  }
}

function* getMailSenderName() {
  try {
    const data = yield call(api.getMailSenderName);
    yield put({ type: constants.GET_MAIL_SENDER_NAME_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['emailSettings.Missing'] });
  }
}

// sagas listen to dispatched actions too (same as reducers)
function* mailTemplatesSaga() {
  yield takeLatest(constants.GET_MAIL_TEMPLATES, getMailTemplates);
  yield takeLatest(constants.GET_MAIL_SENDER_NAME, getMailSenderName);
}

export default mailTemplatesSaga;
