/*
 *
 * MailTemplates constants
 *
 */

// ACTIONS CONSTANTS

export const GET_MAIL_TEMPLATES = 'app/MailTemplates/GET_MAIL_TEMPLATES';
export const GET_MAIL_TEMPLATES_SUCCESS = 'app/MailTemplates/GET_MAIL_TEMPLATES_SUCCESS';
export const GET_MAIL_TEMPLATES_ERROR = 'app/MailTemplates/GET_MAIL_TEMPLATES_ERROR';
export const GET_MAIL_SENDER_NAME = 'app/MailTemplates/GET_MAIL_SENDER_NAME';
export const GET_MAIL_SENDER_NAME_SUCCESS = 'app/MailTemplates/GET_MAIL_SENDER_NAME_SUCCESS';
export const GET_MAIL_SENDER_NAME_ERROR = 'app/MailTemplates/GET_MAIL_SENDER_NAME_ERROR';
export const CLEAR_NEWLY_CREATED_EMAIL = 'app/MailTemplates/CLEAR_NEWLY_CREATED_EMAIL';

// STORE CONSTANTS
export const MAIL_TEMPLATES = 'mailTemplates';
export const MAIL_SENDER_NAME = 'mailSenderName';
export const MAIL_SENDER_NAME_LOADING = 'mailSenderNameLoading';
export const MAIL_TEMPLATES_MAP = 'mailTemplatesMap';
export const MAIL_TEMPLATES_LOADING = 'mailTemplatesLoading';

// COMMON CONSTANTS
export const APP = 'app';
export const LOCATION_ID = 'locationId';

export const TEMPLATE_NAME = 'name';
export const EXTERNAL_ID = 'externalId';
export const STATUS = 'status';
export const ACTIVE = 'active';

export const ID = 'id';
export const SUBJECT = 'subject';
export const LAYOUT_TYPE = 'layoutType';
export const LAYOUT_TYPE_IMAGE_WITH_TEXT = 'imageWithText';
export const LAYOUT_DATA = 'layoutData';
export const TEXT = 'text';

export const FILTER_SEARCH = 'search';
