export const ReportNames = {
  'Assets': 'Assets',
  'Membership': 'Membership',
  'useraction': 'User Action',
  'PurchaseItem': 'Purchase Item',
  'Purchase': 'Purchase',
};

export const ReportQueryNames = {
  'Assets': 'Assets',
  'Membership': 'Membership',
  'useraction': 'useraction',
  'PurchaseItem': 'PurchaseItem',
  'Purchase': 'Purchase'
};

export const emailOptionsStatic = [
  { key: 'mailto:usasupport@comosense.com', value: 'mailto:usasupport@comosense.com', text: 'USA: usasupport@comosense.com' },
  { key: 'mailto:uksupport@comosense.com', value: 'mailto:uksupport@comosense.com', text: 'UK/Europe: uksupport@comosense.com' },
  { key: 'mailto:emeasupport@comosense.com', value: 'mailto:emeasupport@comosense.com', text: 'EMEA: emeasupport@comosense.com' },
  { key: 'mailto:frsupport@comosense.com', value: 'mailto:frsupport@comosense.com', text: 'France: frsupport@comosense.com' },
];
