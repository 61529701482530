import { delay } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as constants from './forms.constants';
import * as api from './forms.api';
import * as errorConstant from '../ErrorMessage/errorMessage.constants';

function* getForms() {
  try {
    // debounce by 50ms
    yield delay(50);
    const data = yield call(api.getForms);
    yield put({ type: constants.GET_FORMS_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getForms.Fail'] });
  }
}

function* getRegistrationFormFields() {
  try {
    // debounce by 50ms
    yield delay(50);
    const data = yield call(api.getRegistrationFormFields);
    yield put({ type: constants.GET_REGISTRATION_FORM_FIELDS_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getRegistrationFormFields.Fail'] });
  }
}

// sagas listen to dispatched actionsList too (same as reducers)
function* formsSaga() {
  yield [
    takeLatest(constants.GET_FORMS, getForms),
    takeLatest(constants.GET_REGISTRATION_FORM_FIELDS, getRegistrationFormFields),
  ];
}

export default formsSaga;
