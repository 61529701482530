/*
 *
 * Forms constants
 *
 */

// ACTIONS CONSTANTS
export const HANDLE_ERROR = 'app/ErrorMessage/HANDLE_ERROR';
export const HANDLE_TO_MANY_MEMNERS_ERROR = 'app/ErrorMessage/HANDLE_TO_MANY_MEMBERS_ERROR';
export const HANDLE_TO_MANY_MEMBERS_POPUP_VISIBILITY = 'app/ErrorMessage/HANDLE_TO_MANY_MEMBERS_POPUP_VISIBILITY';
export const HANDLE_ERROR_MESSAGE_CLOSE = 'app/ErrorMessage/HANDLE_ERROR_MESSAGE_CLOSE';
export const RESET_ERROR_LEAVE_PATH = 'app/ErrorMessage/RESET_ERROR_LEAVE_PATH';

// STORE CONSTANTS
export const ERROR_MESSAGE = 'errorMessage'; // reducer
export const TO_MANY_MEMBERS_STATUS = 'toManyMembersErrorStatus'; // reducer
export const ERROR_TITLE = 'errorTitle';
export const MESSAGES_KEYS = 'messages';
export const ERROR_TYPE = 'errorType';
export const ERROR_TYPE_CUSTOMER = 'customer';
export const ERROR_TYPE_USER = 'user';
export const TRANSACTIONAL_SAVE_FATAL_ERROR = 'transactionalFatalError';
export const LEAVE_ON_ERROR_CLOSE = 'leaveOnErrorClose';
