import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';

import BenefitsList from '../../../../Benefits/views/benefitsList/benefitsList';
import * as constants from '../../../activity.constants';
import * as schemaConstants from '../../../activitySchema/activitySchema.constants';
import IntegerInputField from '../../../../../common/components/FormInputFields/IntegerInputField/IntegerInputField';
import { getSelectedBenefit } from '../../../../Benefits/benefits.selector';
import { BENEFIT_ACTIVE, BENEFIT_TITLE } from '../../../../Benefits/benefits.constants';

class PunchThePunchCard extends React.PureComponent {

  static propTypes = {
    action: PropTypes.object,
    automationIdPrefix: PropTypes.string,
    index: PropTypes.number,
    selectedBenefit: PropTypes.object,
    onActionFieldUpdate: PropTypes.func.isRequired,
    validationErrors: PropTypes.object
  };

  onBenefitSelect = (e, data) => {
    this.props.onActionFieldUpdate(data.name, data.value, this.props.index);
  };

  render() {

    const { action, index, onActionFieldUpdate, selectedBenefit, validationErrors, automationIdPrefix } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    return (
      <Grid className="action assign-asset-action">
        <Grid.Row>
          <Grid.Column width={16}>
            <div>
              <BenefitsList
                index={index}
                onChange={this.onBenefitSelect}
                value={action.get(constants.PUNCH_CARD_KEY)}
                name={constants.PUNCH_CARD_KEY}
                action={action}
                benefitType={constants.BENEFIT_PUNCH_CARD}
                prefix={`${automationIdPrefix}.punchCardKey`}
                error={validationErrors ? validationErrors.get(constants.PUNCH_CARD_KEY) : null}
              />
              <span className="inner-label">{formatMessage({ id: 'activity.action.quantity' })}</span>
              <IntegerInputField
                name={schemaConstants.AUTO_PUNCH_NUM_OF_PUNCHES}
                onChange={(e, data) => onActionFieldUpdate(data.name, data.value, index)}
                value={action.get(schemaConstants.AUTO_PUNCH_NUM_OF_PUNCHES)}
                index={index}
                prefix={`${automationIdPrefix}.numberOfPunches`}
                error={validationErrors ? validationErrors.get(schemaConstants.AUTO_PUNCH_NUM_OF_PUNCHES) : null}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        {
          selectedBenefit && !selectedBenefit.get(BENEFIT_ACTIVE) &&
          (
            <Grid.Row>
              <Grid.Column>
                <p className="hint">
                  {formatMessage({ id: 'benefits.inactive.hint' }, { benefitName: selectedBenefit.get(BENEFIT_TITLE) })}
                </p>
              </Grid.Column>
            </Grid.Row>
          )
        }
      </Grid>
    );
  }
}

const mapStateToProps = (state, props) => ({
  selectedBenefit: getSelectedBenefit(state, props.action.get(constants.PUNCH_CARD_KEY))
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, injectIntl)(PunchThePunchCard);
