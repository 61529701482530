/*
 *
 * update membership condition constants
 *
 */

// ACTIONS CONSTANTS

export const UPDATE_MEMBERSHIP_COPY_DATA_TO_TEMP = 'app/activity/UPDATE_MEMBERSHIP_COPY_DATA_TO_TEMP';
export const UPDATE_MEMBERSHIP_UPDATE_TEMP_DATA = 'app/activity/UPDATE_MEMBERSHIP_UPDATE_TEMP_DATA';
export const UPDATE_MEMBERSHIP_RESET_TEMP_DATA = 'app/activity/UPDATE_MEMBERSHIP_RESET_TEMP_DATA';
export const UPDATE_MEMBERSHIP_CLEAR_TEMP_DATA = 'app/activity/UPDATE_MEMBERSHIP_CLEAR_TEMP_DATA';

// STORE CONSTANTS
export const UPDATE_MEMBERSHIP_TEMP_DATA = 'membershipChangedTempData';
export const UPDATE_MEMBERSHIP_FROM = 'from';
export const UPDATE_MEMBERSHIP_TO = 'to';
export const UPDATE_MEMBERSHIP_FROM_SUFFIX = '[0].OldValue';
export const UPDATE_MEMBERSHIP_TO_SUFFIX = '[0].NewValue';
