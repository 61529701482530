/*
 *
 * Images reducer
 *
 */

import { fromJS } from 'immutable';
import * as constants from './images.constants';

export const initialState = fromJS({
  [constants.LIST]: {}
});

function imagesReducer(state = initialState, action) {

  let newState;

  switch (action.type) {

    case constants.GET_IMAGES_BY_SIZE_SUCCESS: {
      newState = state.setIn([constants.LIST, action.size], fromJS(action.data));
      break;
    }

    case constants.UPLOAD_IMAGES: {
      newState = state.set(constants.UPLOAD_IN_PROGRESS, fromJS(true));
      break;
    }

    case constants.UPLOAD_IMAGES_ERROR:
    case constants.UPLOAD_IMAGES_SUCCESS: {
      newState = state.set(constants.UPLOAD_IN_PROGRESS, fromJS(false));
      break;
    }

    case constants.DELETE_IMAGE: {
      newState = state.set(constants.DELETE_IN_PROGRESS, fromJS(action.image.get(constants.ID)));
      break;
    }

    case constants.DELETE_IMAGE_ERROR:
    case constants.DELETE_IMAGE_SUCCESS: {
      newState = state.set(constants.DELETE_IN_PROGRESS, fromJS(null));
      break;
    }

    default: {
      newState = state;
    }

  }

  return newState;

}

export default imagesReducer;
