import * as constants from './microCampaign.constants';
import * as activityConstants from '../Activity/activity.constants';

export const getMicroCampaign = (state) => state.get(constants.MICRO_CAMPAIGN);
export const getMicroCampaignValidationErrors = (state) => state.getIn([constants.MICRO_CAMPAIGN, constants.MICRO_CAMPAIGN_VALIDATION_ERRORS]);
export const getCheckingMicroCampaignNameAvailabilityFlag = (state) => state.getIn([constants.MICRO_CAMPAIGN, constants.CHECKING_MICRO_CAMPAIGN_NAME_AVAILABILITY]);
export const isDirty = (state) => state.getIn([constants.MICRO_CAMPAIGN, constants.IS_DIRTY]);
export const getPublishInProgressFlag = (state) => state.getIn([constants.MICRO_CAMPAIGN, constants.PUBLISH_IN_PROGRESS]);
export const getSaveAsDraftInProgressFlag = (state) => state.getIn([constants.MICRO_CAMPAIGN, constants.SAVE_AS_DRAFT_IN_PROGRESS]);
export const getMicroCampaignTestToMyselfData = (state) => state.getIn([constants.MICRO_CAMPAIGN, activityConstants.TEST_TO_MYSELF]);
export const getMicroCampaignTestToMyselfActionPath = (state) => state.getIn([constants.MICRO_CAMPAIGN, activityConstants.TEST_TO_MYSELF, activityConstants.ACTION_PATH]);
