import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import './CardWrapper.styles.less';
import CardWrapper from './CardWrapper';

class MultiCardsWrapper extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string.isRequired,
    cards: PropTypes.arrayOf(PropTypes.node),
    selectCard: PropTypes.func,
    selectedCardId: PropTypes.string,
  };


  render() {
    if (this.props.cards.length > 0) {
      return (
        <Grid>
          <Grid.Row className="multi-card-container">
            {this.props.cards.map((card, index) => (
              <Grid.Column key={card.id} width={5}>
                <CardWrapper
                  automationIdPrefix={`${this.props.automationIdPrefix}.card.${index}`}
                  className={card.id === this.props.selectedCardId ? 'selected-card' : ''}
                  descriptionKey={card.description}
                  headerKey={card.title}
                  onClick={() => this.props.selectCard(card.id)}
                >
                  {card.content}
                </CardWrapper>
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      );
    }
    return null;
  }
}

export default injectIntl(MultiCardsWrapper);
