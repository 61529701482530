import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon, Input } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import DropdownWrapper from '../../../../../common/components/DropdownWrapper/DropdownWrapper';
import * as constants from '../../../activity.constants';
import IntegerInputField from '../../../../../common/components/FormInputFields/IntegerInputField/IntegerInputField';
import CurrencyInputField from '../../../../../common/components/FormInputFields/CurrencyInputField/CurrencyInputField';
import ItemsPopulation from '../../itemsPopulation/itemsPopulation.container';
import './bundle.styles.less';
import * as staticSchema from '../../../activitySchema/activitySchema';

class Bundle extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string,
    bundle: PropTypes.object,
    intl: PropTypes.object,
    validationErrors: PropTypes.object,
    addBundleLine: PropTypes.func,
    deleteBundleLine: PropTypes.func,
    onBundleLineFieldUpdate: PropTypes.func,
    onUpdateBundle: PropTypes.func,
    activityType: PropTypes.string.isRequired,
    posFeature: PropTypes.string.isRequired,
    actionsWrapperIndex: PropTypes.number,
    deleteActionsWrapper: PropTypes.func,
    showNumbersOnMultipleActions: PropTypes.bool,
    showSimpleFeature: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.handlePopupOpen = this.handlePopupOpen.bind(this);
    this.handlePopupClose = this.handlePopupClose.bind(this);

    this.state = {
      isPopupOpen: false,
      options: [
        {
          key: constants.QUANTITY,
          value: constants.QUANTITY,
          text: props.intl.formatMessage({ id: 'activity.bundle.quantity' })
        },
        {
          key: constants.SPEND,
          value: constants.SPEND,
          text: props.intl.formatMessage({ id: 'activity.bundle.spend' })
        }]
    };
  }

  handlePopupOpen = () => {
    this.setState({ isPopupOpen: true });
  };

  handlePopupClose = (e) => {
    if (e.target.className !== 'primary-only-toggle') {
      this.setState({ isPopupOpen: false });
    }
  };

  render() {
    const { actionsWrapperIndex } = this.props; // eslint-disable-line react/prop-types
    // do not remove the <div> that wraps the <Grid>, it is here to make bundle's html the same as action's - this make element's width the same
    const shouldBundleBeRepeatable = staticSchema.redeemableActivities.includes(this.props.activityType) || staticSchema.redeemableRuleAndItsTriggers.includes(this.props.activityType);
    return (
      <div>
        <Grid className="action-block bundle-action-block">
          <Grid.Row>
            <Grid.Column width={15}>
              <span className={`section-title ${this.props.showNumbersOnMultipleActions && this.props.actionsWrapperIndex === 0 ? 'showNumbersOnMultipleActions' : ''}`}>{this.props.intl.formatMessage({ id: 'activity.bundle.hint' })}</span>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Grid className="action-template-wrapper bundle-action-template-wrapper">
                {this.props.showSimpleFeature && <Grid.Row className={'simple-instructions-wrapper'}>
                  <Grid.Column width={16} className={'simple-instructions-column'}>
                                  <span style={{ paddingRight: '15px' }}>
                                    {this.props.intl.formatMessage({ id: 'simple.global.instructions' })}
                                  </span>
                    <Input
                      name={constants.SIMPLE_DESCRIPTION}
                      onChange={(event, elem) => this.props.onUpdateBundle(elem.name, elem.value, actionsWrapperIndex)}
                      value={this.props.bundle.get(constants.SIMPLE_DESCRIPTION)}
                      prefix={`${this.props.automationIdPrefix}.${constants.SIMPLE_DESCRIPTION}`}
                      disabled={false}
                      placeholder={'Add Simple Description'}
                      data-automation-id={`${this.props.automationIdPrefix}.simpleDescription`}
                    />
                  </Grid.Column>
                </Grid.Row>}
                <Grid.Row>
                  <Grid.Column width={14} className="bundle-grid">
                    {
                      this.props.bundle.getIn([constants.VALUE, constants.CONDITIONS_LIST]).map(
                        (bundleLine, index) => {

                          const itemsPopulation = bundleLine.get(constants.ITEMS_POPULATION).toJS();
                          const conditionKey = bundleLine.get(constants.CONDITION_KEY);
                          const automationIdPrefix = `${this.props.automationIdPrefix}.bundle.${index}`;

                          return (
                            <Grid>
                              <Grid.Row className={'bundle-wrapper'}>
                                <Grid.Column width={16}>
                                  <div key={String(index)}>
                                    <div className="bundle-fields">
                                      <div>
                                        <ItemsPopulation
                                          position="bottom left"
                                          index={index}
                                          data={itemsPopulation}
                                          onUpdate={(event, data) => this.props.onBundleLineFieldUpdate(constants.ITEMS_POPULATION, data, index, this.props.actionsWrapperIndex)}
                                          automationIdPrefix={automationIdPrefix}
                                          itemsPopulationValidationErrors={this.props.validationErrors ? this.props.validationErrors.getIn([index.toString(), constants.ITEMS_POPULATION]) : null}
                                          posFeature={this.props.posFeature}
                                          isSimpleMode={this.props.showSimpleFeature}
                                        />
                                      </div>
                                      <div>
                                        <DropdownWrapper
                                          options={this.state.options}
                                          onSelectOption={(event, elem) => this.props.onBundleLineFieldUpdate(elem.name, elem.value, index, this.props.actionsWrapperIndex)}
                                          value={conditionKey}
                                          name="conditionKey"
                                          prefix={`${automationIdPrefix}.${constants.ITEMS_POPULATION}.${constants.CONDITION_KEY}`}
                                        />
                                      </div>
                                      <div>
                                        {conditionKey === constants.QUANTITY
                                          ? (
                                            <IntegerInputField
                                              name="conditionValue"
                                              index={index}
                                              onChange={(event, elem) => this.props.onBundleLineFieldUpdate(elem.name, elem.value, index, this.props.actionsWrapperIndex)}
                                              value={parseInt(bundleLine.get('conditionValue'), 10)}
                                              prefix={`${automationIdPrefix}.${constants.ITEMS_POPULATION}.${constants.CONDITION_VALUE}`}
                                              disabled={false}
                                              error={this.props.validationErrors ? this.props.validationErrors.getIn([index.toString(), constants.CONDITION_VALUE]) : null}
                                            />
                                          )
                                          : (
                                            <CurrencyInputField
                                              name="conditionValue"
                                              index={index}
                                              onChange={(event, elem) => this.props.onBundleLineFieldUpdate(elem.name, elem.value, index, this.props.actionsWrapperIndex)}
                                              value={parseFloat(bundleLine.get('conditionValue'))}
                                              prefix={`${automationIdPrefix}.${constants.ITEMS_POPULATION}.${constants.CONDITION_VALUE}`}
                                              disabled={false}
                                              error={this.props.validationErrors ? this.props.validationErrors.getIn([index.toString(), constants.CONDITION_VALUE]) : null}
                                            />
                                          )
                                        }
                                      </div>
                                    </div>
                                    {shouldBundleBeRepeatable && this.props.bundle.getIn([constants.VALUE, constants.CONDITIONS_LIST]).size > 1 ?
                                      <div className="apply-on-indexed-wrap">
                                        <div className="apply-on-indexed apply-on-dashed-line first" />
                                        <div className="apply-on-indexed apply-on-letter">
                                          {`Line ${String.fromCharCode(65 + index)}`}
                                          <Icon
                                            className="como-ic-delete bundle-bin"
                                            onClick={() => this.props.deleteBundleLine(index, this.props.actionsWrapperIndex)}
                                            data-automation-id={`rule.${automationIdPrefix}.${constants.ITEMS_POPULATION}.delete`}
                                          />
                                        </div>
                                        <div className="apply-on-indexed apply-on-dashed-line small" />
                                      </div> :
                                      null
                                    }
                                    <div className="clearfix" />
                                  </div>
                                </Grid.Column>
                              </Grid.Row>
                              {this.props.showSimpleFeature && <Grid.Row className={'simple-instructions-wrapper'}>
                                <Grid.Column width={16} className={'simple-instructions-column'}>
                                  <span style={{paddingRight: '15px'}}>
                                    {this.props.intl.formatMessage({id: 'simple.instructions'})}
                                  </span>
                                  <Input
                                    name={constants.SIMPLE_DESCRIPTION}
                                    onChange={(event, elem) => this.props.onBundleLineFieldUpdate(elem.name, elem.value, index, this.props.actionsWrapperIndex)}
                                    value={bundleLine.get(constants.SIMPLE_DESCRIPTION)}
                                    prefix={`${automationIdPrefix}.${constants.SIMPLE_DESCRIPTION}`}
                                    disabled={false}
                                    placeholder={'Add Simple Description'}
                                    data-automation-id={`${automationIdPrefix}.simpleDescription`}
                                  />
                                </Grid.Column>
                              </Grid.Row>}
                            </Grid>
                          );
                        }, this)
                    }
                  </Grid.Column>
                  {shouldBundleBeRepeatable && this.props.bundle.getIn([constants.VALUE, constants.CONDITIONS_LIST]).size > 1 ?
                    <Grid.Column width={2} className="recurring-bundle-parentheses">
                      <div>{this.props.intl.formatMessage({ id: 'activity.bundle.recurringBundle' })}</div>
                    </Grid.Column> :
                    <div className="empty-greyed-bundle" />
                  }
                </Grid.Row>
                {
                  shouldBundleBeRepeatable
                    ? (
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <a
                            role="button"
                            className="add-line"
                            onClick={() => this.props.addBundleLine(this.props.actionsWrapperIndex)}
                            data-automation-id={`${this.props.automationIdPrefix}.${constants.ITEMS_POPULATION}.addLine`}
                          >
                            <Icon
                              className="como-ic-plus-in-circle"
                              size="large"
                            /> {this.props.intl.formatMessage({ id: 'activity.bundle.addLine' })}</a>
                        </Grid.Column>
                      </Grid.Row>
                    )
                    : null
                }
              </Grid>
              {
                staticSchema.redeemableActivities.includes(this.props.activityType) &&
                <div className="remove-action-container">
                  <div
                    role="button"
                    data-automation-id={`${this.props.automationIdPrefix}.${this.props.actionsWrapperIndex}.deleteActionsWrapper`}
                    onClick={() => this.props.deleteActionsWrapper(this.props.actionsWrapperIndex)}
                  >
                    <Icon className="como-ic-delete" />
                  </div>
                </div>
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>

    );
  }
}

export default injectIntl(Bundle);
