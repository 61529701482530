import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { injectIntl } from 'react-intl';
import { CustomModal } from '@como/web-hub-components';
import * as selectors from './../promoCodes.selectors';
import * as promoCodesActions from './../promoCodes.actions';
import { Toast } from '@como/web-hub-components';
import BulkCode from './BulkCode';


class BulkCodesListModal extends Component {

  state={
    toastIsOpen: false,
    toastMessageKey: ''
  };
  handleModalCLose = () => {
    this.props.actions.handleBulkListModalVisibility(false);
  };

  copyCode = (code) => {
    const el = document.createElement('input');
    el.value = code;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this.setState({
      toastIsOpen: true,
      toastMessageKey: 'customer.profileData.copiedToClipboard'
    });
  };

  resetToast = () => {
    this.setState({
      toastIsOpen: false,
      toastMessageKey: ''
    });
  };

  render() {
    const { status, intl, bulkList } = this.props;
    const { formatMessage } = intl;

    return (
      <React.Fragment>
        <CustomModal
          open={status}
          className="test-codes-modal"
          onClose={this.handleModalCLose}
          header={<span>{formatMessage({ id: 'promoCode.testCodes.title' })}</span>}
          content={
            <React.Fragment>
              <div className="bulk-code-container">
                <div className="bulk-heading">
                  <div className="code-section">
                    {formatMessage({ id: 'promoCode.codes.title' })}
                  </div>
                  <div className="details-section">
                    {formatMessage({ id: 'promoCode.redeem.details.title' })}
                  </div>
                </div>
                <div className="bulk-content">
                  {bulkList && bulkList.map((item, index) => (<BulkCode
                    key={item.get('code')}
                    code={item.get('code')}
                    status={item.get('status')}
                    copyCode={this.copyCode}
                    index={index}
                  />))}
                </div>
              </div>
              <Toast
                intl={intl}
                className="code-copy-toaster"
                isOpen={this.state.toastIsOpen}
                messageKey={this.state.toastMessageKey}
                onClose={this.resetToast}
              />
            </React.Fragment>
          }
          actions={[
            <a
              data-automation-id="promo-codes.bulk-codes.got-it"
              role="button"
              key="gotIt"
              onClick={this.handleModalCLose}
            >
              {formatMessage({ id: 'general.button.got-it' })}
            </a>,
          ]}
          closeOnDimmerClick
          automationIdPrefix="multiple-members-popup"
        />
      </React.Fragment>

    );
  }
}

BulkCodesListModal.propTypes = {
  actions: PropTypes.object,
  bulkList: PropTypes.object,
};

const mapStateToProps = (state) => ({
  bulkList: selectors.getBulkCodesList(state),
  status: selectors.getBulkCodeModalState(state)
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(promoCodesActions, dispatch)
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);


export default compose(withConnect, injectIntl)(BulkCodesListModal);
