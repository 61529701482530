import { createSelector } from 'reselect';
import * as constants from '../App/app.constants';
import * as membersConstants from './members.constants';
import * as activityConstants from '../Activity/activity.constants';

/**
 * Direct selector to the customer state domain
 */
const selectCustomerDomain = (state) => state.get('customer');

/**
 * Other specific selectors
 */


/**
 * Default selector used by Members
 */

const makeSelectCustomer = () => createSelector(
  selectCustomerDomain,
  (substate) => substate.toJS()
);

export default makeSelectCustomer;
export {
  selectCustomerDomain,
};

export const getCustomerSearch = (state) => state.getIn([constants.CUSTOMER, membersConstants.CUSTOMER_SEARCH]);

export const getMembersFilteredByCommunicationActions = (state) => state.getIn([constants.CUSTOMER, membersConstants.MEMBERS_FILTERED_BY_COMMUNICATION_ACTIONS, activityConstants.FILTERED_POPULATION_COUNT]);
export const getMembersFilteredByCommunicationActionsQuery = (state) => state.getIn([constants.CUSTOMER, membersConstants.MEMBERS_FILTERED_BY_COMMUNICATION_ACTIONS, activityConstants.FILTER_POPULATION_QUERY]);
export const getMembesTouchedLoadedFlag = (state) => state.getIn([constants.CUSTOMER, membersConstants.MEMBERS_TOUCHED_LOADED]);

export const getMembersPopupStatus = (state) => state.getIn([constants.CUSTOMER, membersConstants.MEMBERS_POPUP_STATUS]);
export const getMembersPopupData = (state) => state.getIn([constants.CUSTOMER, membersConstants.MEMBERS_POPUP_DATA]);
export const getMembersPopupTotal = (state) => state.getIn([constants.CUSTOMER, membersConstants.MEMBERS_POPUP_TOTAL]);
export const getMembersSearchQuery = (state) => state.getIn([constants.CUSTOMER, membersConstants.MEMBERS_SEARCH_QUERY]);
