import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import SingleCase from './SingleCase';
import tracker from '../../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../../common/utils/tracking/tracking.consts';
import * as activityConstants from '../../activity.constants';
import * as selectors from '../../activity.selectors';
import './cases.styles.less';

class CasesList extends React.PureComponent {

  static propTypes = {
    actionsPopupContent: PropTypes.object,
    activityTrigger: PropTypes.string,
    activityType: PropTypes.string,
    allowOccurrences: PropTypes.bool,
    cases: PropTypes.object.isRequired,
    casesActions: PropTypes.object.isRequired,
    conditionsPerTrigger: PropTypes.object,
    dateTimeConditions: PropTypes.object,
    features: PropTypes.object,
    hideAdvancedDealActions: PropTypes.bool,
    intl: PropTypes.object,
    isSingleBundleLine: PropTypes.bool,
    membershipConditions: PropTypes.object,
    mentionsList: PropTypes.object,
    trigger: PropTypes.string,
    updateMembershipConditions: PropTypes.object,
    validationErrors: PropTypes.object,
    abTestMode: PropTypes.bool,
    isOneTimeActivityDisabled: PropTypes.bool,
    currentConditionType: PropTypes.string,
    getBusinessBundlePlan: PropTypes.func.isRequired,
    hasFullPermission: PropTypes.func.isRequired
  };

  expandAll = () => {
    this.props.casesActions.setCasesExpandCollapseMode(false);
  };

  collapseAll = () => {
    this.props.casesActions.setCasesExpandCollapseMode(true);
  };

  componentDidUpdate(prevProps, prevState) {
    // if condition type is for non-member, Clear all cases data
    if (prevProps.currentConditionType !== this.props.currentConditionType && (this.props.currentConditionType === activityConstants.NON_MEMBERS
        || this.props.currentConditionType === activityConstants.ALL_COSTUMERS) && this.props.cases) {
      this.props.cases.forEach((activityCase, index) => {
        this.props.casesActions.clearCaseData(index);
      });
    }
  }

  render() {
    const { actionsPopupContent, activityTrigger, allowOccurrences, casesActions, conditionsPerTrigger,
      dateTimeConditions, hideAdvancedDealActions, isSingleBundleLine, membershipConditions, mentionsList, trigger, validationErrors,
      features, activityType, cases, intl, updateMembershipConditions } = this.props;
    const automationIdPrefix = 'activity.cases';
    return (
      <Grid className="cases-list">
        <Grid.Row>
          <Grid.Column width={16}>
            <p className="expand-collapse">
              <a
                role="button"
                onClick={this.expandAll}
                data-automation-id={`${automationIdPrefix}.expand-all`}
              >{intl.formatMessage({ id: 'activity.cases.expandAll' })}</a>
              |
              <a
                role="button"
                onClick={this.collapseAll}
                data-automation-id={`${automationIdPrefix}.collapse-all`}
              >{intl.formatMessage({ id: 'activity.cases.collapseAll' })}</a>
            </p>
          </Grid.Column>
        </Grid.Row>
        {
          this.props.cases.map((activityCase, index) => (
            <Grid.Row key={`case.${index.toString()}`}>
              <Grid.Column width={16}>
                <SingleCase
                  actionsPopupContent={actionsPopupContent}
                  activityTrigger={activityTrigger}
                  allowOccurrences={allowOccurrences}
                  automationIdPrefix={automationIdPrefix}
                  caseIndex={index}
                  casesActions={casesActions}
                  casesCount={cases.size}
                  conditionsPerTrigger={conditionsPerTrigger}
                  dateTimeConditions={dateTimeConditions}
                  hideAdvancedDealActions={hideAdvancedDealActions}
                  key={`case.${index.toString()}`}
                  isSingleBundleLine={isSingleBundleLine}
                  membershipConditions={membershipConditions}
                  mentionsList={mentionsList}
                  singleCase={activityCase}
                  trigger={trigger}
                  validationErrors={validationErrors ? validationErrors.get(index.toString()) : null}
                  features={features}
                  activityType={activityType}
                  updateMembershipConditions={updateMembershipConditions}
                  abTestMode={this.props.abTestMode}
                  isOneTimeActivityDisabled={this.props.isOneTimeActivityDisabled}
                  getBusinessBundlePlan={this.props.getBusinessBundlePlan}
                  hasFullPermission={this.props.hasFullPermission}
                />
              </Grid.Column>
            </Grid.Row>
          ))
        }
        {!this.props.abTestMode && !this.props.isOneTimeActivityDisabled &&
          <Grid.Row>
            <Grid.Column width={16}>
              <a
                role="button"
                className="condition-link add-condition"
                onClick={tracker.wrapButtonAction(casesActions.addCase, trackerConstants.BUTTON_TYPE_CASE_ADD)}
                data-automation-id={`${automationIdPrefix}.add-case`}
              >
                <Icon className="como-ic-plus-in-circle" />
                {intl.formatMessage({ id: 'activity.cases.addCase' })}
              </a>
            </Grid.Column>
          </Grid.Row>
        }
      </Grid>

    );
  }
}


const mapStateToProps = (state) => ({
  currentConditionType: selectors.getMembershipGlobalConditionType(state),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, injectIntl)(CasesList);
