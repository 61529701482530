import axios from 'axios';
import * as activityConstants from '../Activity/activity.constants';
import * as emailsConstants from '../Emails/emails.constants';

const BASE_URL = '/api';

export function createBusinessTemplate(template, entityType) {
  const activityTemplateUrl = getTemplateUrlByType(entityType);
  return axios.post(`${activityTemplateUrl}/business/`, template)
    .then((res) => res.data);
}

export function saveProductTemplate(template, entityType) {
  const activityTemplateUrl = getTemplateUrlByType(entityType);
  return axios.post(`${activityTemplateUrl}/product/`, template)
    .then((res) => res.data);
}

export function getBusinessTemplates(entityType) {
  const templateUrl = getTemplateUrlByType(entityType);
  return axios.get(templateUrl, {
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include'
  }).then((res) => {
    deleteEntityMetadata(res.data, entityType);
    return res.data;
  });
}

function deleteEntityMetadata(templates, entityType) {
  if (entityType !== emailsConstants.EMAIL) {
    templates.forEach((v) => {
      delete v.activity.createDate;
      delete v.activity.lastUpdate;
      delete v.activity.locationId;
      delete v.activity.publishDate;
      delete v.activity.status;
      delete v.activity.campaignKey;
      delete v.activity.goalKey;
      delete v.activity.hubId;
      delete v.activity.dbId;
      delete v.activity.serverId;
    });
  }
}

export function deleteBusinessTemplate(id, entityType) {
  const activityTemplateUrl = getTemplateUrlByType(entityType);
  return axios.delete(`${activityTemplateUrl}/business/${id}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include'
  }).then((res) => res.data);
}

export function deleteProductTemplate(id, entityType) {
  const activityTemplateUrl = getTemplateUrlByType(entityType);
  return axios.delete(`${activityTemplateUrl}/product/${id}`)
    .then((res) => res.data);
}

export function getRulesProductTemplates() {
  return getProductTemplates(activityConstants.ACTIVITY_TYPE_RULE);
}

export function getDealsProductTemplates() {
  return getProductTemplates(activityConstants.ACTIVITY_TYPE_DEAL);
}

export function getOneTimeActionsProductTemplates() {
  return getProductTemplates(activityConstants.ACTIVITY_TYPE_ONE_TIME);
}

export function getAllRulesProductTemplates() {
  return getAllActivityProductTemplates(activityConstants.ACTIVITY_TYPE_RULE);
}

export function getAllDealsProductTemplates() {
  return getAllActivityProductTemplates(activityConstants.ACTIVITY_TYPE_DEAL);
}

export function getAllOneTimeActionsProductTemplates() {
  return getAllActivityProductTemplates(activityConstants.ACTIVITY_TYPE_ONE_TIME);
}

export function getAllPunchCardsProductTemplates() {
  return getAllActivityProductTemplates(activityConstants.ACTIVITY_TYPE_PUNCH_CARD);
}

export function getAllActivityProductTemplates(activityType) {
  const activityTemplateUrl = getTemplateUrlByType(activityType);
  return axios.get(`${activityTemplateUrl}/allProduct`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include'
  }).then((res) => res.data);
}

export function getProductTemplates(activityType) {
  const activityTemplateUrl = getTemplateUrlByType(activityType);
  return axios.get(`${activityTemplateUrl}/product`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include'
  }).then((res) => {

    Object.entries(res.data).forEach(([key, value]) => {
      deleteEntityMetadata(value);
    });

    return res.data;
  });
}

export function updateBusinessTemplate(template, activityType) {
  const activityTemplateUrl = getTemplateUrlByType(activityType);
  return axios.put(`${activityTemplateUrl}/business/`, template)
    .then((res) => res.data);
}

export function updateProductTemplate(template, activityType) {
  const activityTemplateUrl = getTemplateUrlByType(activityType);
  return axios.put(`${activityTemplateUrl}/product/`, template)
    .then((res) => res.data);
}

export function getProductTemplatesCategories(activityType) {
  const activityTemplateCategoriesUrl = getTemplateCategoriesUrlByType(activityType);
  return axios.get(`${activityTemplateCategoriesUrl}`)
    .then((res) => res.data);
}

export function getBusinessesVerticals() {
  return axios.get(`${BASE_URL}/BusinessAccount/GetAllVerticals`)
    .then((res) => res.data);
}

function getTemplateUrlByType(type) {
  switch (type) {
    case activityConstants.ACTIVITY_TYPE_RULE:
    case activityConstants.SERVER_TYPE_RULE:
      return `${BASE_URL}/RuleTemplates`;
    case activityConstants.ACTIVITY_TYPE_DEAL:
    case activityConstants.SERVER_TYPE_DEAL:
      return `${BASE_URL}/DealTemplates`;
    case activityConstants.ACTIVITY_TYPE_ONE_TIME:
    case activityConstants.SERVER_TYPE_ONE_TIME_ACTION:
      return `${BASE_URL}/OneTimeActionTemplates`;
    case activityConstants.ACTIVITY_TYPE_PUNCH_CARD:
    case activityConstants.SERVER_TYPE_PUNCH_CARD:
      return `${BASE_URL}/PunchCardTemplates`;
    case activityConstants.ACTIVITY_TYPE_GIFT:
    case activityConstants.SERVER_TYPE_GIFT:
      return `${BASE_URL}/GiftTemplates`;
    case emailsConstants.EMAIL:
      return `${BASE_URL}/EmailTemplates`;
    default:
      throw new Error(`No such activity type ${type}`);
  }
}

function getTemplateCategoriesUrlByType(entityType) {
  switch (entityType) {
    case activityConstants.ACTIVITY_TYPE_RULE:
    case activityConstants.SERVER_TYPE_RULE:
      return `${BASE_URL}/RuleTemplateCategories`;
    case activityConstants.ACTIVITY_TYPE_DEAL:
    case activityConstants.SERVER_TYPE_DEAL:
      return `${BASE_URL}/DealTemplateCategories`;
    case activityConstants.ACTIVITY_TYPE_ONE_TIME:
    case activityConstants.SERVER_TYPE_ONE_TIME_ACTION:
      return `${BASE_URL}/OneTimeActionTemplateCategories`;
    case activityConstants.ACTIVITY_TYPE_PUNCH_CARD:
    case activityConstants.SERVER_TYPE_PUNCH_CARD:
      return `${BASE_URL}/GiftTemplateCategories`;
      // return `${BASE_URL}/PunchCardTemplateCategories`;
    case activityConstants.ACTIVITY_TYPE_GIFT:
    case activityConstants.SERVER_TYPE_GIFT:
      return `${BASE_URL}/GiftTemplateCategories`;
    default:
      return null;
  }
}
