import * as constants from '../../../activity.constants';

export function resetApplyOnData(actionIndex) {
  return {
    type: constants.RESET_APPLY_ON_DATA,
    actionIndex
  };
}

export function updateTempApplyOnModelField(fieldName, fieldValue, applyOnIndex) {
  return {
    type: constants.UPDATE_TEMP_APPLY_ON_MODEL_FIELD,
    fieldName,
    fieldValue,
    applyOnIndex
  };
}

export function changeBundleItemsModeInTempModel(bundleItemsMode) {
  return {
    type: constants.CHANGE_BUNDLE_ITEMS_MODE_IN_TEMP_MODEL,
    bundleItemsMode
  };
}
