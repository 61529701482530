const builtInAppScreens = [
  {
    'title': 'app.screens.general.info',
    'group_name': 'InfoScreens',
    'id': 'InfoUpdatesTC'
  },
  {
    'title': 'app.screens.map',
    'group_name': 'InfoScreens',
    'id': 'MapTC'
  },
  {
    'title': 'app.screens.rewards.screen',
    'group_name': 'LoyaltyScreens',
    'id': 'OffersTC'
  },
  {
    'title': 'app.screens.photo.gallery',
    'group_name': 'InfoScreens',
    'id': 'PhotoAlbumTC'
  },
  {
    'title': 'app.screens.social.feed',
    'group_name': 'SocialScreens',
    'id': 'SocialTC'
  },
  {
    'title': 'app.screens.member.profile',
    'group_name': 'InfoScreens',
    'id': 'UserProfileTC'
  },
  {
    'title': 'app.screens.gift.list',
    'group_name': 'LoyaltyScreens',
    'id': 'GiftsTC'
  },
  {
    'title': 'app.screens.punch.card.list',
    'group_name': 'LoyaltyScreens',
    'id': 'PunchcardsTC'
  },
  {
    'title': 'app.screens.point.shop',
    'group_name': 'LoyaltyScreens',
    'id': 'ShopMenuTC'
  },
  {
    'title': 'app.screens.web.view',
    'group_name': 'InfoScreens',
    'id': 'GenericWebSiteTC'
  },
  {
    'title': 'app.screens.instagram',
    'group_name': 'SocialScreens',
    'id': 'InstagramTC'
  },
  {
    'title': 'app.screens.location.list',
    'group_name': 'InfoScreens',
    'id': 'BranchesTC'
  },
  {
    'title': 'app.screens.wallet.screen',
    'group_name': 'LoyaltyScreens',
    'id': 'WalletTC'
  }
];

export default builtInAppScreens;
