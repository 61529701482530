import {call, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { delay } from 'redux-saga';
import { fromJS } from 'immutable';

import * as constants from './microCampaign.constants';
import * as api from './microCampaign.api';
import * as activityConstants from '../Activity/activity.constants';
import tracker from '../../common/utils/tracking/tracker';
import * as trackerConstants from '../../common/utils/tracking/tracking.consts';
import * as validationUtils from './utils/microCampaign.validation.utils';
import * as errorConstant from '../ErrorMessage/errorMessage.constants';
import * as routerConstants from '../../constants/route.contants';
import * as microCampaignsConstants from '../MicroCampaigns/microCampaigns.constants';

function* getMicroCampaign(action) {
  try {
    const data = yield call(api.getMicroCampaign, action.hubId);
    return yield put({ type: constants.GET_MICRO_CAMPAIGN_SUCCESS, data });
  } catch (err) {
    return yield put({ type: constants.GET_MICRO_CAMPAIGN_ERROR, message: err.message });
  }
}

function* saveMicroCampaign(action) {
  let microCampaign = action.data;
  yield put({
    type: action.isDraft ? constants.SET_MICRO_CAMPAIGN_SAVE_AS_DRAFT_ON_PROGRESS : constants.SET_MICRO_CAMPAIGN_PUBLISH_IN_PROGRESS
  });
  const isExistingMicroCampaign = Boolean(microCampaign.getIn([activityConstants.DATA, activityConstants.HUB_ID]));
  const publishedBeforeMicroCampaign = Boolean(microCampaign.getIn([activityConstants.DATA, activityConstants.SERVER_ID]));
  tracker.onEvent(trackerConstants.EVENT_TYPE_PUBLISH_ACTIVITY, {
    [trackerConstants.ACTIVITY_PUBLISH_ARGS_EXISTING_ACTIVITY]: isExistingMicroCampaign,
    [trackerConstants.ACTIVITY_PUBLISH_ARGS_PUBLISHED_BEFORE]: publishedBeforeMicroCampaign
  });

  const isDraft = action.isDraft;

  try {
    // validation
    const { validationErrors, nameAvailable } = yield validateMicroCampaign({ microCampaign, isDraft });
    if (validationErrors && validationErrors.size > 0) {
      yield put({
        type: action.isDraft ? constants.REMOVE_MICRO_CAMPAIGN_SAVE_AS_DRAFT_IN_PROGRESS : constants.REMOVE_MICRO_CAMPAIGN_PUBLISH_IN_PROGRESS
      });
      return yield put({
        type: constants.SET_MICRO_CAMPAIGN_ERRORS,
        [constants.MICRO_CAMPAIGN_VALIDATION_ERRORS]: validationErrors,
        [constants.MICRO_CAMPAIGN_VALIDATION_NAME_TAKEN_FLAG]: !nameAvailable,
        [activityConstants.ACTIVITY_VALIDATION_MODE]: isDraft ? activityConstants.ACTIVITY_VALIDATION_MODE_DRAFT : activityConstants.ACTIVITY_VALIDATION_MODE_PUBLISH
      });
    }

    if (action.isDraft) {
      const assets = microCampaign.getIn([activityConstants.DATA, constants.MICRO_CAMPAIGN_ASSET_TEMPLATES]).toJS();
      assets.map((asset) => asset.actions.map((a) => a.isDraft = true));
      microCampaign = microCampaign
        .setIn([activityConstants.DATA, activityConstants.STATUS], activityConstants.ACTIVITY_STATUS_DRAFT).setIn([activityConstants.DATA, constants.MICRO_CAMPAIGN_ASSET_TEMPLATES], fromJS(assets));
    } else {
      // remove empty asset template
      const lastAssetTemplate = microCampaign.getIn([activityConstants.DATA, constants.MICRO_CAMPAIGN_ASSET_TEMPLATES]).last();
      const lastTemplateIndex = microCampaign.getIn([activityConstants.DATA, constants.MICRO_CAMPAIGN_ASSET_TEMPLATES]).size - 1;
      if (!lastAssetTemplate.get(constants.MICRO_CAMPAIGN_ASSET_TEMPLATE)) {
        microCampaign = microCampaign.deleteIn([activityConstants.DATA, constants.MICRO_CAMPAIGN_ASSET_TEMPLATES, lastTemplateIndex]);
      }
      microCampaign = microCampaign.setIn([activityConstants.DATA, activityConstants.STATUS], activityConstants.ACTIVITY_STATUS_ACTIVE);
    }
    microCampaign = microCampaign.setIn([activityConstants.DATA, activityConstants.LOCATION_ID], action.locationId);
    const data = yield call(api.saveMicroCampaign, microCampaign.get(activityConstants.DATA).toJS());
    yield put({ type: constants.SAVE_MICRO_CAMPAIGN_SUCCESS, data });
    return yield put(push({ pathname: routerConstants.MICRO_CAMPAIGNS_ROUTE, search: location.search }));
  } catch (err) {
    return yield put({ type: constants.SAVE_MICRO_CAMPAIGN_ERROR, message: err.message });
  }
}

function* validateMicroCampaign({ microCampaign, isDraft = false }) {
  // todo: implement validation when isDraft
  const microCampaignName = microCampaign.getIn([activityConstants.DATA, constants.MICRO_CAMPAIGN_NAME]);
  const microCampaignHubId = microCampaign.getIn([activityConstants.DATA, activityConstants.HUB_ID]);


  const nameAvailable = microCampaignName !== '' ? yield call(api.checkMicroCampaignNameAvailability, microCampaignHubId, microCampaignName) : true;

  const rootState = yield select();
  let stateWithErrors = validationUtils.validateMicroCampaign(microCampaign, isDraft, rootState);
  if (!nameAvailable) {
    stateWithErrors = validationUtils.handleMicroCampaignNameAlreadyInUseError(microCampaign);
  }
  const validationErrors = stateWithErrors.get(constants.MICRO_CAMPAIGN_VALIDATION_ERRORS);
  return { validationErrors, nameAvailable };

}


function* checkMicroCampaignNameAvailability({ hubId, name }) {
  try {
    const nameAvailable = yield call(api.checkMicroCampaignNameAvailability, hubId, name);
    yield put({
      type: constants.CHECK_MICRO_CAMPAIGN_NAME_AVAILABILITY_SUCCESS,
      nameAvailable,
      value: name
    });
  } catch (err) {
    return yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['checkMicroCampaignNameAvailability.Fail'] });
  }
  return yield put({ type: constants.CHECK_MICRO_CAMPAIGN_NAME_AVAILABILITY_DONE });
}

function* changeMicroCampaignStatus({ microCampaign, isActive }) {
  // debounce by 50ms
  yield delay(50);
  const newStatus = isActive ? activityConstants.ACTIVITY_STATUS_ACTIVE : activityConstants.ACTIVITY_STATUS_INACTIVE;
  const updatedMicroCampaign = microCampaign.set(constants.MICRO_CAMPAIGN_STATUS, newStatus);
  yield put({ type: microCampaignsConstants.UPDATE_MICRO_CAMPAIGN_STATUS_IN_PROGRESS, updatedMicroCampaign });
  try {

    yield call(api.saveMicroCampaign, updatedMicroCampaign);

  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['updateMicroCampaignStatus.Fail'] });
  }
  return yield put({ type: microCampaignsConstants.UPDATE_MICRO_CAMPAIGN_STATUS_DONE, updatedMicroCampaign });
}

// sagas listen to dispatched actions too (same as reducers)
function* microCampaignSaga() {
  yield takeEvery(constants.GET_MICRO_CAMPAIGN, getMicroCampaign);
  yield takeLatest(constants.SAVE_MICRO_CAMPAIGN, saveMicroCampaign);
  yield takeEvery(constants.CHECK_MICRO_CAMPAIGN_NAME_AVAILABILITY, checkMicroCampaignNameAvailability);
  yield takeLatest(microCampaignsConstants.UPDATE_MICRO_CAMPAIGN_STATUS, changeMicroCampaignStatus);

}

export default microCampaignSaga;
