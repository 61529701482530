import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import { Loader, Table } from 'semantic-ui-react';
import { Ref } from '@stardust-ui/react-component-ref';
import { injectIntl } from 'react-intl';
import * as activityConstants from '../../Activity/activity.constants';
import ActivityType from './ActivityType';
import ActivityStatus from '../../Activity/views/ActivityStatus';
import ActivityHistoryLog from './ActivityHistoryLog';
import ActionView from '../../Activity/views/ActionView';
import ActivityContextMenu from './ActivityContextMenu';
import tracker from '../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../common/utils/tracking/tracking.consts';
import RuleStats from './RuleStats';
import DealStats from './DealStats';
import PunchCardStats from './PunchCardStats';
import * as activitySelectorsUtils from '../../Activity/activity.selectors.utils';
import OneTimeStats from './OneTimeStats';
import withAuthorization from '../../../common/helpers/authorization';
import * as appConstants from '../../App/app.constants';
import * as businessConstants from '../businessCenter.constants';

class ActivitiesRow extends React.PureComponent {

  // eslint-disable-next-line no-undef
  static propTypes = {
    activityMoveTo: PropTypes.func.isRequired,
    campaignId: PropTypes.string.isRequired,
    campaignOrderIndex: PropTypes.number.isRequired,
    changeActivityStatus: PropTypes.func.isRequired,
    deleteActivity: PropTypes.func.isRequired,
    goToActivity: PropTypes.func.isRequired,
    highlightedActivityId: PropTypes.string,
    activity: PropTypes.object.isRequired,
    rowIndex: PropTypes.number.isRequired,
    localeDateFormat: PropTypes.string.isRequired,
    localeTimeFormat: PropTypes.string.isRequired,
    hasFullPermission: PropTypes.func,
    getPermissionLevel: PropTypes.func,
    openActivityInNewTab: PropTypes.func,
    businessTimezone: PropTypes.string,
    unpinActivity: PropTypes.func.isRequired,
    pinActivity: PropTypes.func.isRequired,
    openToast: PropTypes.func.isRequired,
  };

  state = {
    isMouseEnter: false,
    isDetailsModalOpen: false,
    isAnalysisModalOpen: false,
    isStatusUpdating: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activity.get(businessConstants.ACTIVITY_PINNING_DATE) !== this.props.activity.get(businessConstants.ACTIVITY_PINNING_DATE)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isStatusUpdating: false });
    }
  }

  handleOnMouseEnter = () => {
    this.setState({
      isMouseEnter: true
    });
  };

  handleOnMouseLeave = () => {
    this.setState({
      isMouseEnter: false
    });
  };

  handleDetailsModalOpen = () => {
    this.setState({
      isDetailsModalOpen: true
    });
  };

  handleDetailsModalClose = () => {
    this.setState({
      isDetailsModalOpen: false
    });
  };
  handleAnalysisModalOpen = () => {
    this.setState({
      isAnalysisModalOpen: true
    });
  };
  handleAnalysisModalClose = () => {
    this.setState({
      isAnalysisModalOpen: false
    });
  };

  handleRef = (node) => {
    const { highlightedActivityId, activity } = this.props;
    if (node && highlightedActivityId && highlightedActivityId === activity.get(activityConstants.HUB_ID)) {
      window.scrollTo({
        top: node ? node.getBoundingClientRect().top : 0,
        behavior: 'smooth'
      });
    }
  };

  handlePinUnpinActivity = () => {
    const { activity } = this.props;
    const rowFavoriteDate = activity.get(businessConstants.ACTIVITY_PINNING_DATE);

    const { isStatusUpdating } = this.state;
    if (isStatusUpdating) return;

    this.setState({ isStatusUpdating: true }, () => {
      if (rowFavoriteDate) {
        this.props.unpinActivity(activity);
        this.props.openToast(this.props.intl.formatMessage({ id: 'activity.pinned.removed' }));
      } else {
        this.props.pinActivity(activity);
        this.props.openToast(this.props.intl.formatMessage({ id: 'activity.pinned.successfully' }));

      }
    });
  }

  render() {
    const { activity, rowIndex, campaignOrderIndex, changeActivityStatus, deleteActivity, campaignId, activityMoveTo, highlightedActivityId, hasFullPermission, businessTimezone, openActivityInNewTab, getPermissionLevel } = this.props;
    const { isMouseEnter, isDetailsModalOpen, isAnalysisModalOpen } = this.state;
    const { formatMessage } = this.props.intl; //eslint-disable-line
    let rowType = activity.get(activityConstants.TYPE);
    const rowFavoriteDate = activity.get(businessConstants.ACTIVITY_PINNING_DATE);
    if (rowType === activityConstants.SERVER_TYPE_RULE) {
      rowType = activityConstants.ACTIVITY_TYPE_RULE;
    } else if (rowType === activityConstants.SERVER_TYPE_DEAL) {
      rowType = activityConstants.ACTIVITY_TYPE_DEAL;
    } else if (rowType === activityConstants.SERVER_TYPE_ONE_TIME_ACTION) {
      rowType = activityConstants.ACTIVITY_TYPE_ONE_TIME;
    } else if (rowType === activityConstants.SERVER_TYPE_PUNCH_CARD) {
      rowType = activityConstants.ACTIVITY_TYPE_PUNCH_CARD;
    }

    // temporary client filter activities for permissions set
    // Campaign Center - NONE + Actions on Bulk of Member = FULL
    if (!hasFullPermission(appConstants.FEATURE_NAME_CAMPAIGN_CENTER) && hasFullPermission(appConstants.FEATURE_ACTIONS_ON_BULK_OF_MEMBER)) {
      if (rowType !== activityConstants.ACTIVITY_TYPE_ONE_TIME) return null;
    }

    const isPOSAPIVersionSupportedStats = hasFullPermission(appConstants.FEATURE_POS_VERSION_4_0) || getPermissionLevel(appConstants.FEATURE_POS_VERSION_4_0) === getPermissionLevel(appConstants.FEATURE_POS_VERSION_2_8);
    const rowName = activity.get(activityConstants.NAME);
    const rowHistoryLog = activity.get(activityConstants.HISTORY_LOG);
    const rowViewStatus = activitySelectorsUtils.calculateActivityViewStatus(activity);
    const rowClass = classNames({
      'rule': rowType === activityConstants.ACTIVITY_TYPE_RULE,
      'deal': rowType === activityConstants.ACTIVITY_TYPE_DEAL,
      'oneTime': rowType === activityConstants.ACTIVITY_TYPE_ONE_TIME,
      'punchCard': rowType === activityConstants.ACTIVITY_TYPE_PUNCH_CARD,
      'disable-pointer-events': rowViewStatus === activityConstants.ACTIVITY_STATUS_INVALID,
      'clickable': rowViewStatus !== activityConstants.ACTIVITY_STATUS_INVALID,
      'highlighted': highlightedActivityId && activity.get(activityConstants.HUB_ID) === highlightedActivityId,
    });

    const rowScheduling = (rowViewStatus !== activityConstants.ACTIVITY_STATUS_DRAFT && activity.get(activityConstants.ONE_TIME_ACTION_SCHEDULING) && activity.get(activityConstants.ONE_TIME_ACTION_SCHEDULING).get('date')) ? activity.get(activityConstants.ONE_TIME_ACTION_SCHEDULING).get('date') : null;
    const dateTimeFormat = `${this.props.localeDateFormat} ${this.props.localeTimeFormat}`;
    const rowSchedulingDate = rowScheduling && moment(rowScheduling).tz(businessTimezone).format(dateTimeFormat);

    const trigger = activity.get(activityConstants.TRIGGER);
    let activityTriggerMsg = '';
    if (trigger) {
      activityTriggerMsg = formatMessage({
        id: `business-center.activity.triggers.${trigger}`,
        defaultMessage: formatMessage({ id: `activity.triggers.${trigger}` })
      });
    } else if (rowType === activityConstants.ACTIVITY_TYPE_ONE_TIME) {
      activityTriggerMsg = formatMessage({ id: 'activity.triggers.oneTimeAction' });
    } else if (rowType === activityConstants.ACTIVITY_TYPE_DEAL) {
      activityTriggerMsg = formatMessage({ id: 'activity.triggers.member.about.to.pay' });
    } else if (rowType === activityConstants.ACTIVITY_TYPE_PUNCH_CARD) {
      activityTriggerMsg = formatMessage({ id: 'business-center.activity.triggers.punchCard' }, { items: activity.get(activityConstants.REQUIRED_PUNCHES) });
    }
    const activityDetailsForTracker = {
      [trackerConstants.ACTIVITY_HUB_ID]: activity.get(activityConstants.HUB_ID),
      [trackerConstants.ACTIVITY_SERVER_ID]: activity.get(activityConstants.SERVER_ID),
      [trackerConstants.ACTIVITY_TYPE]: activity.get(activityConstants.TYPE),
      [trackerConstants.ACTIVITY_STATUS]: activity.get(activityConstants.STATUS),
      [trackerConstants.ACTIVITY_NAME]: activity.get(activityConstants.NAME)
    };

    function goToActivity() {
      return tracker.wrapButtonAction(() => this.props.goToActivity(rowType, activity.get(activityConstants.HUB_ID)),
        trackerConstants.BUTTON_TYPE_ACTIVITY_LOADED,
        activityDetailsForTracker);
    }

    return (
      <Ref innerRef={this.handleRef}>
        <Table.Row
          data-automation-id={`business-center.campaign.${campaignOrderIndex}.row.${rowIndex}`}
          className={rowClass}
          onMouseEnter={this.handleOnMouseEnter}
          onMouseLeave={this.handleOnMouseLeave}
        >
          <ActivityHistoryLog
            type={rowType}
            name={rowName}
            open={isDetailsModalOpen}
            onConfirm={this.handleDetailsModalClose}
            historyLog={rowHistoryLog}
            migrated={activity.get(activityConstants.ACTIVITY_MIGRATED)}
          />
          {
            rowType === activityConstants.ACTIVITY_TYPE_DEAL &&
            <DealStats
              activity={activity}
              onConfirm={this.handleAnalysisModalClose}
              open={isAnalysisModalOpen}
              type={rowType}
              isPOSAPIVersionSupported={isPOSAPIVersionSupportedStats}
            />
          }
          {
            rowType === activityConstants.ACTIVITY_TYPE_RULE &&
            <RuleStats
              activity={activity}
              onConfirm={this.handleAnalysisModalClose}
              open={isAnalysisModalOpen}
              type={rowType}
            />
          }
          {
            rowType === activityConstants.ACTIVITY_TYPE_ONE_TIME &&
            <OneTimeStats
              activity={activity}
              onConfirm={this.handleAnalysisModalClose}
              open={isAnalysisModalOpen}
              type={rowType}
              activityName={rowName}
            />
          }
          {
            rowType === activityConstants.ACTIVITY_TYPE_PUNCH_CARD &&
            <PunchCardStats
              activity={activity}
              onConfirm={this.handleAnalysisModalClose}
              open={isAnalysisModalOpen}
              type={rowType}
            />
          }
          <Table.Cell className="holding-fav-activity-icon">
            {this.state.isStatusUpdating ? (
              <Loader active size="tiny" className="dropdown-inline-loader tw-cursor-not-allowed !tw-w-[42px]" />
            ) : (
              <i
                title={rowFavoriteDate ? formatMessage({ id: 'business-center.campaign.pinned-title' }) : formatMessage({ id: 'business-center.campaign.pin-title' })}
                aria-hidden="true"
                className={`activity-fav-icon favorite-star-${rowFavoriteDate ? 'full' : 'empty'}`}
                onClick={this.handlePinUnpinActivity}
              />
            )}
          </Table.Cell>
          <Table.Cell className="holding-absolute-elem" onClick={goToActivity.call(this)}>
            <ActivityType type={rowType} isAbTestMode={activity.get(activityConstants.AB_TEST_MODE)} />
          </Table.Cell>
          <Table.Cell className="listcontent activity" title={rowName} onClick={goToActivity.call(this)}>
            {rowName}
          </Table.Cell>
          <Table.Cell className="listcontent" onClick={goToActivity.call(this)}>{rowSchedulingDate || activityTriggerMsg}</Table.Cell>
          <Table.Cell className="listcontent" onClick={goToActivity.call(this)}>
            <ActionView
              actionsList={activitySelectorsUtils.reduceActionsForSummary(activity)}
              activityType={activity.get(activityConstants.TYPE)}
              abTestMode={activity.get(activityConstants.AB_TEST_MODE)}
            />
          </Table.Cell>
          <Table.Cell width="1" onClick={goToActivity.call(this)}>
            <ActivityStatus status={rowViewStatus} messagesNamespace="business-center" />
          </Table.Cell>
          <Table.Cell className="listcontent" width="1" textAlign="right">
            <ActivityContextMenu
              campaignId={campaignId}
              hidden={!isMouseEnter}
              onChangeActivityStatus={(newActivity) => {
                const eventType = newActivity.get(activityConstants.STATUS) === activityConstants.ACTIVITY_STATUS_INACTIVE
                  ? trackerConstants.BUTTON_TYPE_STOP_ACTIVITY
                  : trackerConstants.BUTTON_TYPE_RE_ACTIVATE_ACTIVITY;
                tracker.onButtonClick(eventType, activityDetailsForTracker);
                changeActivityStatus(newActivity);
              }}
              onDeleteActivity={
                tracker.wrapButtonAction(deleteActivity, trackerConstants.BUTTON_TYPE_DELETE_ACTIVITY, activityDetailsForTracker)
              }
              onMoveActivityTo={
                tracker.wrapButtonAction(activityMoveTo, trackerConstants.BUTTON_TYPE_SHOW_MOVE_ACTIVITY_DIALOG, activityDetailsForTracker)
              }
              onDetailsOpen={
                tracker.wrapButtonAction(this.handleDetailsModalOpen, trackerConstants.BUTTON_TYPE_SHOW_ACTIVITY_DETAILS, activityDetailsForTracker)
              }
              onAnalysisOpen={
                tracker.wrapButtonAction(this.handleAnalysisModalOpen, trackerConstants.BUTTON_TYPE_SHOW_RULE_ANALYSIS, activityDetailsForTracker)
              }
              onOpenInNewTab={
              tracker.wrapButtonAction(() => openActivityInNewTab(activity), trackerConstants.BUTTON_TYPE_OPEN_IN_NEW_TAB, activityDetailsForTracker)
              }
              activity={activity}
              automationId={`business-center.campaign.${campaignOrderIndex}.row.${rowIndex}.activity-context-menu`}
            />
          </Table.Cell>
        </Table.Row>
      </Ref>
    );
  }
}

export default withAuthorization(injectIntl(ActivitiesRow));
