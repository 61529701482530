/*
 *
 * DataAndBi reducer
 *
 */

import { fromJS } from 'immutable';
import * as constants from './dataAndBi.constants';


const initialState = fromJS({
  [constants.DATA_EXPORT]: {
    [constants.CONFIG]: {
      [constants.DATA]: {},
      [constants.CONFIG_FETCHED]: false
    },
    [constants.FILES_DATA]: {
      [constants.DATA]: [],
      [constants.FILES_DATA_FETCHED]: false
    },
    [constants.SIGNED_URL]: {
      [constants.DATA]: '',
      [constants.SIGNED_URL_FETCHED]: false
    },
    [constants.ACTIVATE]: {
      [constants.ACTIVATING]: false
    }
  }
});

function dataAndBiReducer(state = initialState, action) {
  let newState = state;
  switch (action.type) {
    case constants.GET_CONFIG: {
      newState = newState.setIn([constants.DATA_EXPORT, constants.CONFIG, constants.CONFIG_FETCHED], false);
      break;
    }
    case constants.CONFIG_FETCHED_SUCCESS: {
      newState = newState.setIn([constants.DATA_EXPORT, constants.CONFIG, constants.CONFIG_FETCHED], true).setIn([constants.DATA_EXPORT, constants.CONFIG, constants.DATA], fromJS(action[constants.DATA]));
      break;
    }
    case constants.GET_FILES_DATA: {
      newState = newState.setIn([constants.DATA_EXPORT, constants.FILES_DATA, constants.FILES_DATA_FETCHED], false);
      break;
    }
    case constants.FILES_DATA_FETCHED_SUCCESS: {
      newState = newState.setIn([constants.DATA_EXPORT, constants.FILES_DATA, constants.FILES_DATA_FETCHED], true).setIn([constants.DATA_EXPORT, constants.FILES_DATA, constants.DATA], fromJS(action[constants.DATA]));
      break;
    }
    case constants.GET_SIGNED_URL: {
      newState = newState.setIn([constants.DATA_EXPORT, constants.SIGNED_URL, constants.SIGNED_URL_FETCHED], false);
      break;
    }
    case constants.SIGNED_URL_FETCHED_SUCCESS: {
      newState = newState.setIn([constants.DATA_EXPORT, constants.SIGNED_URL, constants.SIGNED_URL_FETCHED], true).setIn([constants.DATA_EXPORT, constants.SIGNED_URL, constants.DATA], fromJS(action[constants.DATA]));
      break;
    }
    case constants.ACTIVATE: {
      newState = newState.setIn([constants.DATA_EXPORT, constants.ACTIVATE, constants.ACTIVATING], true);
      break;
    }
    case constants.ACTIVATED_SUCCESS: {
      newState = newState.setIn([constants.DATA_EXPORT, constants.ACTIVATE, constants.ACTIVATING], false).setIn([constants.DATA_EXPORT, constants.SIGNED_URL, constants.DATA], fromJS(action[constants.DATA]));
      break;
    }
    default:
      newState = state;
  }

  return newState;
}

export default dataAndBiReducer;
