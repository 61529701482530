
import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';

class RegisterMember extends Component {

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    return (
      <Grid.Row>
        <Grid.Column>
          <p className="hint">
            {formatMessage({ id: 'activity.action.register.hint' })}
          </p>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

export default injectIntl(RegisterMember);
