import { fromJS } from 'immutable';
import * as activityConstants from '../../Activity/activity.constants';
import * as constants from '../microCampaign.constants';
import * as defaults from '../microCampaign.defaults';

export function setDefaultCommunicationActions(actions) {
  if (actions.size === 0) {
    return fromJS([defaults.launchAction, defaults.reminderAction]);
  }
  let newActions;
  if (actions.find((action) => action.get(activityConstants.ACTION_META) === constants.MICRO_CAMPAIGN_ACTION_TYPE_LAUNCH)) {
    newActions = fromJS([actions.get(0), defaults.reminderAction]);
  } else {
    newActions = fromJS([defaults.launchAction, actions.get(0)]);
  }
  return newActions;
}
