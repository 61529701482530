import React from 'react';
import _ from 'lodash';
import { Container, Grid, Icon } from 'semantic-ui-react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import BackLink from '../../common/components/NavigationLink/BackLink/BackLink';
import TemplatesGuidanceHeader from './views/ActivityTemplatesGuidanceHeader/TemplatesGuidanceHeader';
import TemplateCard from './views/templateCard';
import tracker from '../../common/utils/tracking/tracker';
import * as trackerConstants from '../../common/utils/tracking/tracking.consts';

import injectSaga from '../../utils/injectSaga';
import saga from './templates.saga';
import * as templatesConstants from './templates.constants';
import * as routeConstants from '../../constants/route.contants';
import * as activityConstants from '../Activity/activity.constants';
import * as templatesActions from './templates.actions';
import * as activityActions from '../Activity/activity.actions';
import businessSaga from '../BusinessCenter/businessCenter.saga';
import * as templateSelectors from './templates.selectors';
import * as activitySelectors from '../Activity/activity.selectors';
import * as appSelectors from '../App/selectors';
import HeaderNotification from '../Activity/views/activityHeader/HeaderNotification';
import * as reducerConstants from '../../constants/reducer.constants';
import * as emailsConstants from '../Emails/emails.constants';
import * as emailsActions from '../Emails/emails.actions';
import { goToBusinessCenter } from '../BusinessCenter/businessCenter.actions';
import withAuthorization from '../../common/helpers/authorization';
import * as appConstants from '../App/app.constants';

const TEMPLATES_IN_ROW = 5;
const AUTOMATION_PREFIX_NAME = 'activity-templates';
const TEMPLATE_LIBRARY_EXCLUDED_CATEGORIES = [templatesConstants.TEMPLATE_CATEGORY_NONE];

class Templates extends React.PureComponent {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    locationId: PropTypes.number,
    campaignKey: PropTypes.string,
    businessTemplates: PropTypes.object,
    productTemplates: PropTypes.object,
    productTemplatesCategories: PropTypes.object,
    businessesVerticals: PropTypes.object,
    intl: PropTypes.object,
    tempOriginActivity: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    hasFullPermission: PropTypes.func,
    bundlePlan: PropTypes.string,
    entityType: PropTypes.string,
    hasBasicPlanPermissions: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    const { businessTemplates, productTemplates } = props;
    this.state = {
      businessTemplatesChunk: splitIntoChunks(businessTemplates),
      productTemplatesChunk: productTemplates && productTemplates.map((data) => splitIntoChunks(data))
    };
  }

  componentWillMount() {
    const { actions, businessesVerticals } = this.props;
    if (businessesVerticals.size < 1) {
      // noinspection JSUnresolvedFunction
      actions.getBusinessesVerticals();
    }
    if (!this.entityType) {
      actions.goToBusinessCenter();
    } else {
      // noinspection JSUnresolvedFunction
      actions.getTemplates(this.entityType, this.entityType !== emailsConstants.EMAIL);
    }
  }

  componentDidMount() {
    tracker.onPageLoaded(trackerConstants.PAGE_TYPE_TEMPLATES);

    if (this.props.hasFullPermission(appConstants.FEATURE_NAME_ESSENTIALS) && this.props.bundlePlan === appConstants.REVEL) {
      this.props.history.replace(`${this.entityRoute}${location.search}`);
    }
  }

  componentWillReceiveProps(nextProps) {
    const newState = {};
    if (this.props.businessTemplates !== nextProps.businessTemplates) {
      newState.businessTemplatesChunk = splitIntoChunks(nextProps.businessTemplates);
    }
    if (this.props.productTemplates !== nextProps.productTemplates && nextProps.productTemplates) {
      newState.productTemplatesChunk = nextProps.productTemplates.map((value) => splitIntoChunks(value));
    }
    this.setState(newState);
  }

  componentWillUnmount() {
    // noinspection JSUnresolvedFunction
    this.props.actions.clearTemplates();
  }

  getTemplateCategories = (templateType) => templateType === templatesConstants.TEMPLATE_TYPE_PRODUCT ? this.props.productTemplatesCategories : null;

  getOnEditHandler(templateType) {
    return templateType === templatesConstants.TEMPLATE_TYPE_PRODUCT
      ? this.props.actions.updateProductTemplateMetadata
      : this.props.actions.updateBusinessTemplateMetadata;
  }

  handleOnDelete = (templateId, categoryName, templateType) => {
    const handler = templateType === templatesConstants.TEMPLATE_TYPE_PRODUCT
      ? this.props.actions.deleteProductTemplate
      : this.props.actions.deleteBusinessTemplate;
    handler({ templateId, categoryName }, this.entityType);
  };

  get entityType() {
    return this.props.location.type ? this.props.location.type : this.props.entityType;
  }

  get entityRoute() {
    const path = this.props.hasBasicPlanPermissions ? '/b' : '';
    switch (this.entityType) {
      case activityConstants.ACTIVITY_TYPE_RULE:
        return routeConstants.RULES_CREATE_ROUTE;
      case activityConstants.ACTIVITY_TYPE_DEAL:
        return routeConstants.DEALS_CREATE_ROUTE;
      case activityConstants.ACTIVITY_TYPE_ONE_TIME:
        return path + routeConstants.ONETIME_CREATE_ROUTE;
      case activityConstants.ACTIVITY_TYPE_PUNCH_CARD:
        return routeConstants.PUNCH_CARDS_CREATE_ROUTE;
      case activityConstants.ACTIVITY_TYPE_GIFT:
        return routeConstants.GIFTS_CREATE_ROUTE;
      case emailsConstants.EMAIL:
        return path + routeConstants.EMAILS_CREATE_ROUTE;
      default:
        throw new Error('Unknown entity type');
    }
  }

  get backRoute() {
    const path = this.props.hasBasicPlanPermissions ? '/b' : '';
    switch (this.entityType) {
      case activityConstants.ACTIVITY_TYPE_RULE:
      case activityConstants.ACTIVITY_TYPE_DEAL:
      case activityConstants.ACTIVITY_TYPE_ONE_TIME:
      case activityConstants.ACTIVITY_TYPE_PUNCH_CARD:
        return path + routeConstants.BUSINESS_CENTER_ROUTE;
      case activityConstants.ACTIVITY_TYPE_GIFT:
        return routeConstants.GIFTS_ROUTE;
      case emailsConstants.EMAIL:
        return path + routeConstants.EMAILS_ROUTE;
      default:
        throw new Error('Unknown entity type');
    }
  }

  createEntityFromTemplate(template) {
    const { locationId, campaignKey } = this.props;
    this.props.actions.loadTemplateDataIntoEntity({ template, campaignKey, locationId }, this.entityType);
  }

  isValidForRender() {
    const { locationId, campaignKey, businessesVerticals } = this.props;
    if (this.entityType === emailsConstants.EMAIL) {
      return !_.isNil(businessesVerticals);
    }
    return !(_.isNil(campaignKey) || _.isNil(this.entityType) || _.isNil(locationId) || _.isNil(businessesVerticals));
  }

  get isCreatingGiftFromActivity() {
    return this.props.tempOriginActivity;
  }

  get isCreatingEmailFromActivity() {
    return this.props.tempOriginActivity;
  }

  renderTemplatesCard(templates = [], categoryName, templateType) {
    return templates.map((chunk, index) => (
      <Grid.Row key={`activityTemplateRow${index.toString()}`}>
        {chunk.map((template, column) =>
          (<Grid.Column key={`${index.toString()}_${column.toString()}`} className="templatesColumn">
            <Link to={{ pathname: this.entityRoute, search: location.search }}>
              <TemplateCard
                prefix={AUTOMATION_PREFIX_NAME}
                onClick={() => {
                  this.createEntityFromTemplate(template);
                  tracker.onButtonClick(trackerConstants.BUTTON_TYPE_TEMPLATE_SELECTED, { type: templateType });
                }}
                onDelete={(templateId) => this.handleOnDelete(templateId, categoryName, templateType)}
                template={template}
                templateType={templateType}
                entityType={this.entityType}
                categories={this.getTemplateCategories(templateType)}
                onEdit={this.getOnEditHandler(templateType)}
              />
            </Link>
          </Grid.Column>
          )
        )}
      </Grid.Row>
    ));
  }

  createEntity = () => {
    if (this.entityType === emailsConstants.EMAIL) {
      this.props.actions.createEmail();
    } else {
      this.props.actions.createActivity(this.props.locationId, this.props.campaignKey,
        null /* templateKey */, null /* goalkey */, this.entityType);
    }
  };

  render() {
    if (!this.isValidForRender()) {
      return null;
    }

    const { formatMessage } = this.props.intl;
    const { businessTemplatesChunk, productTemplatesChunk } = this.state;
    const originActivityType = this.props.tempOriginActivity ? this.props.tempOriginActivity.getIn([activityConstants.DATA, activityConstants.TYPE]) : '';

    return (
      <div className={'activityTemplates'}>
        {
          (this.isCreatingGiftFromActivity || this.isCreatingEmailFromActivity) &&
          <HeaderNotification
            activityName={this.props.tempOriginActivity.getIn([activityConstants.DATA, activityConstants.NAME])}
            activityType={originActivityType}
            automationIdPrefix={AUTOMATION_PREFIX_NAME}
            createdEntityType={this.props.tempOriginActivity.get(activityConstants.TYPE)}
          />
        }
        <div className="back-btn-nav-container">
          {
            this.isCreatingGiftFromActivity
              ? (
                <span role="button" tabIndex={-1} onClick={this.props.actions.cancelCreateGiftFromActivity}>
                  <Icon className="como-ic-angle-left" />
                  {formatMessage({ id: 'activity.header.back' })}
                </span>
              )
              : (
                <BackLink pathname={this.backRoute}>
                  <Icon className="como-ic-angle-left" />
                  <span>{formatMessage({ id: 'activity.header.back' })}</span>
                </BackLink>
              )
          }
        </div>
        <div className={'activityTemplatesLibrary'}>
          <TemplatesGuidanceHeader
            createPath={this.entityRoute}
            prefix={AUTOMATION_PREFIX_NAME}
            createEntity={this.createEntity}
          />
          <Container fluid>
            {businessTemplatesChunk.size === 0 ? null : (
              <Grid stretched columns={TEMPLATES_IN_ROW} className="businessTemplatesByCategoryContainer">
                <Grid.Row className="savedTemplatesTextRow">
                  <Grid.Column>
                    <div className="savedTemplatesText">
                      {formatMessage({ id: 'templates.title.my-templates' })}
                    </div>
                  </Grid.Column>
                </Grid.Row>
                {this.renderTemplatesCard(businessTemplatesChunk, null, templatesConstants.TEMPLATE_TYPE_BUSINESS)}
              </Grid>
              )}
            <Grid>
              {productTemplatesChunk.entrySeq().map((category) => {
                const categoryName = category[0];
                const categoryData = category[1];
                const numberOfTemplatesInCategory = categoryData.size;

                if (numberOfTemplatesInCategory < 1 || TEMPLATE_LIBRARY_EXCLUDED_CATEGORIES.includes(categoryName)) {
                  return null;
                }

                return (
                  <Grid.Row
                    key={categoryName}
                    className="productTemplatesByCategoryContainer"
                  >
                    <Grid
                      stretched
                      columns={numberOfTemplatesInCategory < TEMPLATES_IN_ROW ? numberOfTemplatesInCategory : TEMPLATES_IN_ROW}
                    >
                      <Grid.Row className="savedTemplatesTextRow">
                        <Grid.Column>
                          <div className="savedTemplatesText">
                            {categoryName}
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                      {this.renderTemplatesCard(categoryData, categoryName, templatesConstants.TEMPLATE_TYPE_PRODUCT)}
                    </Grid>
                  </Grid.Row>);
              }
              )}
            </Grid>
          </Container>
        </div>
      </div>
    );
  }
}

function splitIntoChunks(list, chunkSize = TEMPLATES_IN_ROW) {
  if (_.isNil(list)) {
    return null;
  }
  return Immutable.Range(0, list.size, chunkSize)
    .map((chunkStart) => list.slice(chunkStart, chunkStart + chunkSize));
}

const mapStateToProps = (state) => ({
  locationId: appSelectors.getLocationId(state),
  hasBasicPlanPermissions: appSelectors.hasBasicPlanPermissions(state),
  bundlePlan: appSelectors.getBusinessBundlePlan(state),
  campaignKey: activitySelectors.getActivityCampaignKey(state),
  businessTemplates: templateSelectors.getBusinessTemplates(state),
  productTemplates: templateSelectors.getProductTemplates(state),
  productTemplatesCategories: templateSelectors.getProductTemplatesCategories(state),
  businessesVerticals: templateSelectors.getBusinessVerticals(state),
  tempOriginActivity: activitySelectors.getTempOriginActivity(state),
  entityType: templateSelectors.getEntityType(state)
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...templatesActions,
      ...activityActions,
      goToBusinessCenter,
      ...emailsActions
    }, dispatch)
});


const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.TEMPLATES_BRANCH, saga });
const withBusinessSaga = injectSaga({ key: reducerConstants.BUSINESS_CENTER_BRANCH, saga: businessSaga });

export default compose(
  withSaga,
  withBusinessSaga,
  withConnect,
  withRouter,
  withAuthorization
)(injectIntl(Templates));
