import { fromJS } from 'immutable';

import * as constants from '../microCampaign.constants';
import * as activityConstants from '../../Activity/activity.constants';
import * as actionsValidationUtils from '../../Activity/reducers/utils/validations/action.validation.utils';

export function validateMicroCampaign(state, isDraft, rootState) {
  let newState = resetValidation(state);

  if (!newState.getIn([activityConstants.DATA, constants.MICRO_CAMPAIGN_NAME])) {
    newState = newState.setIn([constants.MICRO_CAMPAIGN_VALIDATION_ERRORS, constants.MICRO_CAMPAIGN_HEADER, constants.MICRO_CAMPAIGN_NAME], 'microCampaign.validation.error.name.required');
  }
  if (isDraft) {
    return newState;
  }
  if (newState.get(constants.MICRO_CAMPAIGN_VALIDATION_NAME_TAKEN_FLAG)) {
    newState = handleMicroCampaignNameAlreadyInUseError(newState);
  }

  newState.getIn([activityConstants.DATA, constants.MICRO_CAMPAIGN_ASSET_TEMPLATES]).forEach((assetSet, index) => {
    if (!assetSet.get(constants.MICRO_CAMPAIGN_ASSET_TEMPLATE)) {
      newState = newState.setIn([constants.MICRO_CAMPAIGN_VALIDATION_ERRORS, constants.MICRO_CAMPAIGN_ASSET_TEMPLATES, index, constants.MICRO_CAMPAIGN_ASSET_TEMPLATE], 'activity.validation.error.activity.action.value.required');
    }
    const actions = assetSet.get(activityConstants.ACTIONS);
    const actionsListErrors = {};
    actions.forEach((action, actionIndex) => {
      let actionErrors = {};
      if (!action.get(activityConstants.ACTION_TYPE)) {
        actionErrors[activityConstants.VALIDATION_ERRORS_NO_ACTION] = 'activity.validation.error.activity.action.value.required';
      } else {
        actionErrors = actionsValidationUtils.validateAction(action, rootState);
      }
      if (Object.keys(actionErrors).length > 0) {
        actionsListErrors[actionIndex.toString()] = actionErrors;
        newState = newState.setIn([constants.MICRO_CAMPAIGN_VALIDATION_ERRORS, constants.MICRO_CAMPAIGN_ASSET_TEMPLATES, index, activityConstants.ACTIONS], fromJS(actionsListErrors));
      }
    });
  });

  return newState;
}

export function handleMicroCampaignNameAlreadyInUseError(state) {
  return state.setIn([constants.MICRO_CAMPAIGN_VALIDATION_ERRORS, constants.MICRO_CAMPAIGN_HEADER],
    fromJS({ [constants.MICRO_CAMPAIGN_NAME]: 'microCampaign.validation.error.name.already.used' }));
}

function resetValidation(state) {
  return state.delete(constants.MICRO_CAMPAIGN_VALIDATION_ERRORS);
}
