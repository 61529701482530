import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import DropdownWrapper from '../../../../common/components/DropdownWrapper/DropdownWrapper';
import * as conditionValueOptionsSchema from '../../activitySchema/activitySchema.condition.value.options';
import * as schemaConstants from '../../activitySchema/activitySchema.constants';

class ConditionListOfValues extends React.PureComponent {

  static propTypes = {
    disabled: PropTypes.bool,
    index: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    onChange: PropTypes.func,
    prefix: PropTypes.string,
    className: PropTypes.string,
    error: PropTypes.string,
    type: PropTypes.oneOf(conditionValueOptionsSchema.conditionValueTypes),
    dynamicOptions: PropTypes.array,
    multiple: PropTypes.bool,
    intl: PropTypes.object,
    allowAdditions: PropTypes.bool,
    placeholder: PropTypes.string,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    allowAdditions: false,
  };

  getOptionsList() {
    let options;
    switch (this.props.type) {
      case schemaConstants.VALUE_TYPE_BEACON_SIGNALS_LIST:
        options = conditionValueOptionsSchema.beaconSignalTypes;
        break;
      case schemaConstants.VALUE_TYPE_CATALOG_ITEMS_LIST:
        options = conditionValueOptionsSchema.catalogItems;
        break;
      case schemaConstants.VALUE_TYPE_PAYMENT_FLOW:
        options = conditionValueOptionsSchema.paymentFlows;
        break;
      case schemaConstants.VALUE_TYPE_PAYMENT_FLOW_IN_APP:
        options = conditionValueOptionsSchema.paymentFlowsInApp;
        break;
      case schemaConstants.FILTER_VALUE_TYPE_PAYMENT_FLOW_IN_APP:
        options = conditionValueOptionsSchema.filterPaymentFlows;
        break;
      case schemaConstants.VALUE_TYPE_ASSET_SOURCE_LIST:
        options = conditionValueOptionsSchema.assetSourceList;
        break;
      case schemaConstants.VALUE_TYPE_MEMBERSHIP_STATUS_LIST:
        options = conditionValueOptionsSchema.membershipStatuses;
        break;
      case schemaConstants.VALUE_TYPE_MEMBERSHIP_CONSENT_LIST:
        options = conditionValueOptionsSchema.membershipConsentValues;
        break;
      case schemaConstants.VALUE_TYPE_MONTHS_LIST:
        options = conditionValueOptionsSchema.months;
        break;
      case schemaConstants.VALUE_TYPE_MEMBERSHIP_RFM_LIST:
        options = conditionValueOptionsSchema.membershipRfmValues;
        break;
      case schemaConstants.VALUE_TYPE_MEMBERSHIP_LAST_VISIT_LIST:
        options = conditionValueOptionsSchema.membershipLastVisitValues;
        break;
      case schemaConstants.VALUE_TYPE_GAVE_CONSENT_SOURCE:
        options = conditionValueOptionsSchema.consentSourceOptions;
        break;
      case schemaConstants.VALUE_TYPE_JOINING_SOURCE:
        options = conditionValueOptionsSchema.joinClubSourceOptions;
        break;
      case schemaConstants.VALUE_TYPE_DYNAMIC:
      case schemaConstants.VALUE_TYPE_LOCATIONS:
      case schemaConstants.VALUE_TYPE_EMAIL_TEMPLATE:
      case schemaConstants.VALUE_TYPE_AUTO_COMPLETE:
        options = this.props.dynamicOptions;
        break;
      case schemaConstants.VALUE_TYPE_SENT_FROM:
        options = conditionValueOptionsSchema.communicationSourceOptions;
        break;
      case schemaConstants.CONTEXT_FAIL_SMS_TYPE:
        options = conditionValueOptionsSchema.smsTypes;
        break;
      case schemaConstants.CONTEXT_FAIL_SMS_STEP:
        options = conditionValueOptionsSchema.smsSteps;
        break;
      default:
        options = [];
        break;
    }
    return options.map((option, index) => {
      const key = `opt.value.${index}`;
      const value = option.value.toString();
      const { label, formatted, text } = option;
      if (formatted) {
        return { key, value, text: this.props.intl.formatMessage({ id: label }) };
      }
      return { key, value, text: label || text };
    });
  }

  render() {

    const options = this.getOptionsList();

    let value = this.props.value;
    if (this.props.multiple) {
      value = this.props.value ? this.props.value.map((val) => val.toString()) : [];
    }
    const placeHolder = this.props.placeholder ? (this.props.placeholder) : (this.props.type === schemaConstants.VALUE_TYPE_AUTO_COMPLETE ? this.props.intl.formatMessage({ id: 'dropdown.placeholder.select.or.add' }) : null);

    return (
      <DropdownWrapper
        className={this.props.className}
        options={options}
        onSelectOption={this.props.onChange}
        value={value}
        disabled={this.props.disabled}
        index={this.props.index}
        prefix={this.props.prefix}
        error={this.props.error}
        multiple={this.props.multiple}
        search
        allowAdditions={this.props.allowAdditions}
        placeholder={placeHolder}
        additionLabel={`(${this.props.intl.formatMessage({id: 'adding.new.tag'})})`}
        onOpen={this.props.onOpen}
        onClose={this.props.onClose}
      />
    );
  }
}

export default injectIntl(ConditionListOfValues);
