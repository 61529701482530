import { fromJS } from 'immutable';
import moment from 'moment';
import * as datesConstants from '../../../common/utils/dateTime.constants';
import * as constants from '../activity.constants';
import * as schemaConstants from '../activitySchema/activitySchema.constants';

export function activityOneTimeActionReducer(state, action) {

  switch (action.type) {
    case constants.UPDATE_SCHEDULING_TYPE: {
      let newState = state.setIn([constants.TEMP_SCHEDULING_DATA, constants.TYPE], action.schedulingType);
      if (action.schedulingType === constants.SCHEDULE_SCHEDULED && !newState.getIn([constants.TEMP_SCHEDULING_DATA, constants.SCHEDULE_DATE])) {
        const now = moment().format(datesConstants.STANDARD_TIME_FORMAT);
        newState = newState.setIn([constants.TEMP_SCHEDULING_DATA, constants.SCHEDULE_DATE], fromJS(now));
      }
      return newState;
    }

    case constants.UPDATE_SCHEDULING_DATE: {
      const newDate = moment.utc(action.dateObject).format(datesConstants.STANDARD_TIME_FORMAT);
      return state.setIn([constants.TEMP_SCHEDULING_DATA, constants.SCHEDULE_DATE], fromJS(newDate));
    }

    case constants.CREATE_TEMP_SCHEDULING_DATA: {
      return state.set(constants.TEMP_SCHEDULING_DATA, state.getIn([constants.DATA, constants.ONE_TIME_ACTION_SCHEDULING]));
    }

    case constants.RESET_SCHEDULING_DATA: {
      return state.delete(constants.TEMP_SCHEDULING_DATA);
    }

    case constants.SAVE_SCHEDULING_CHANGES: {
      let newState = state.deleteIn([constants.DATA, constants.ONE_TIME_ACTION_SCHEDULING]);
      const scheduleType = newState.getIn([constants.TEMP_SCHEDULING_DATA, constants.TYPE]);
      if (scheduleType === constants.SCHEDULE_SCHEDULED) {
        const scheduleDate = newState.getIn([constants.TEMP_SCHEDULING_DATA, constants.SCHEDULE_DATE]);
        const formattedDate = moment.utc(scheduleDate).format(datesConstants.STANDARD_TIME_FORMAT);
        newState = newState.setIn([constants.DATA, constants.ONE_TIME_ACTION_SCHEDULING, constants.SCHEDULE_DATE], fromJS(formattedDate));
        newState = newState.setIn([constants.DATA, constants.ONE_TIME_ACTION_SCHEDULING, constants.TYPE], scheduleType);
      }
      newState = newState.setIn([constants.DATA, constants.ONE_TIME_ACTION_SCHEDULING, constants.TYPE], scheduleType);
      return newState.delete(constants.TEMP_SCHEDULING_DATA);
    }

    case constants.SET_FILTERED_POPULATION_DATA: {
      const memberCount = action[constants.FILTERED_NUM_OF_MEMBERS];
      const filterString = action[constants.FILTER_POPULATION_NAME];
      const fileName = action[constants.FILTERED_POPULATION_FILE_NAME];
      const bucketName = action[constants.FILTERED_POPULATION_BUCKET_NAME];
      const source = action[constants.FILTERED_POPULATION_SOURCE];
      return setFilterPopulationInfoToStore(memberCount, filterString, fileName, bucketName, source);
    }

    case constants.FILTER_MEMBERS_SUCCESS: {
      const memberCount = action.data.filterByConditions.count;
      const query = action.data.filterByConditions.query;
      const fileName = action.data.filterByConditions.fileName || null;
      const bucketName = action.data.filterByConditions.bucketName || null;
      return setFilterPopulationInfoToStore(memberCount, null, fileName, bucketName, null, query);
    }

    default:
      return state;
  }


  function setFilterPopulationInfoToStore(memberCount, filterString, fileName, bucketName, source, query) {
    const pathPrefix = [constants.DATA, constants.ONE_TIME_ACTION_FILTERED_POPULATION];
    let newState = state
      .setIn([...pathPrefix, constants.FILTERED_NUM_OF_MEMBERS], memberCount)
      .setIn([...pathPrefix, constants.FILTER_POPULATION_NAME], filterString)
      .setIn([...pathPrefix, constants.FILTERED_POPULATION_FILE_NAME], fileName)
      .setIn([...pathPrefix, constants.FILTERED_POPULATION_BUCKET_NAME], bucketName)
      .setIn([...pathPrefix, constants.FILTERED_POPULATION_SOURCE], source)
      .setIn([...pathPrefix, constants.FILTERED_POPULATION_ADJUSTED_BY_COMILLIA_HINT], false)
      .setIn([...pathPrefix, constants.FILTER_POPULATION_QUERY], query);

    if (newState.getIn([constants.DATA, constants.ACTIONS])) {
      newState.getIn([constants.DATA, constants.ACTIONS, 0, constants.ACTIONS_LIST]).forEach((activityAction, index) => {
        if (activityAction.get(constants.ACTION_TYPE) === schemaConstants.SEND_MEMBER_EMAIL_ACTION) {
          newState = newState.setIn([constants.DATA, constants.ACTIONS, 0, constants.ACTIONS_LIST, index, schemaConstants.SEND_MEMBER_EMAIL_SELECTED_MEMBERS_COUNT], memberCount);
        }
      });
    } else if (newState.getIn([constants.DATA, constants.CASES])) {
      let updatedCases = newState.getIn([constants.DATA, constants.CASES]);
      updatedCases.forEach((caseElem, caseIndex) => {
        caseElem.getIn([constants.ACTIONS, 0, constants.ACTIONS_LIST]).forEach((caseActivityAction, caseActivityIndex) => {
          if (caseActivityAction.get(constants.ACTION_TYPE) === schemaConstants.SEND_MEMBER_EMAIL_ACTION) {
            updatedCases = updatedCases.setIn([caseIndex, constants.ACTIONS, 0, constants.ACTIONS_LIST, caseActivityIndex, schemaConstants.SEND_MEMBER_EMAIL_SELECTED_MEMBERS_COUNT], memberCount);
          }
        });
      });
      newState = newState.setIn([constants.DATA, constants.CASES], updatedCases);
    }
    return newState;
  }

}
