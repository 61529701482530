import axios from 'axios';

export function getForms() {
  return axios.get('/api/Forms')
    .then((res) => res.data);
}

export function getRegistrationFormFields() {
  const url = '/api/Forms/registration';
  return axios.get(url)
    .then((res) => res.data);
}
