import React from 'react';
import { injectIntl } from 'react-intl';
import Modal from '../../../common/components/CustomModal/CustomModal';
import { Button, Grid, Icon } from 'semantic-ui-react';
import DateTimeRange from '../../Activity/views/conditions/dateTimeConditions/DateTimeRangeCondition';
import { fromJS } from 'immutable';
import moment from 'moment';
import * as activityConstants from '../../Activity/activity.constants';
import { getDaysConditions, getStartDate, getDateTimeRangeConditionValueAsString } from '../../Activity/reducers/utils/activity.datetime.reducer.utils';
import * as dateConstants from '../../../common/utils/dateTime.constants';

class DatesCondition extends React.PureComponent {

  state = {
    datesRangeCondition: {
      conditionKey: 'context.TimeStamp',
      conditionValue: moment(),
      dataValueType: 'dateTimeBetween',
      operatorKey: 'dateTimeIsAfter'
    }
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.validFrom && nextProps.validUntil) {
      this.setState({
        datesRangeCondition: {
          conditionKey: 'context.TimeStamp',
          conditionValue: {
            dateFrom: moment(nextProps.validFrom),
            dateTo: moment(nextProps.validUntil)
          },
          dataValueType: 'dateTimeBetween',
          operatorKey: 'dateTimeIsBetween'
        }
      });
    }
  }

  /**
   * If starts date is later then now "Starts on ..." msg is added to the trigger message
   * @returns {string}
   */
  getStartsMsg() {
    const { formatMessage } = this.props.intl;
    const date = getStartDate(fromJS(this.state.datesRangeCondition));
    const dateObject = moment.utc(date).tz(this.props.businessTimeZone);

    return dateObject.isAfter(moment.tz(this.props.businessTimeZone))
      ? `${formatMessage({ id: 'pointShopDateTimeGlobalConditions.startsOn' })} ${dateObject.format(this.props.localeDateFormat)}`
      : '';
  }

  /**
   * If end date exists add Expires on date message
   * @returns {string}
   */
  getExpiresMsg() {
    const { formatMessage } = this.props.intl;
    let dateMsg = formatMessage({ id: 'dateTimeGlobalConditions.neverExpires' });
    if (fromJS(this.state.datesRangeCondition).getIn([activityConstants.DATES_RANGE_CONDITION, activityConstants.OPERATOR_KEY]) !== dateConstants.DATE_TIME_IS_AFTER) {
      dateMsg = `${formatMessage({ id: 'pointShopDateTimeGlobalConditions.expiresOn' })} ${moment(fromJS(this.state.datesRangeCondition).getIn([activityConstants.CONDITION_VALUE, dateConstants.DATE_TO])).format(this.props.localeDateFormat)}`;
    }
    return dateMsg;
  }

  get triggerMessage() {
    const { formatMessage } = this.props.intl;
    const triggerMessage = formatMessage({ id: 'dateTimeGlobalConditions.atAnyDateAndTime' });

    let msg = '';
    const startsMsg = this.getStartsMsg();
    const expiresMsg = this.getExpiresMsg();
    msg = `${startsMsg} ${startsMsg && expiresMsg ? ', ' : ''} ${expiresMsg}`;
    return msg;
  }

  get trigger() {
    return (
      <div className={'modal-trigger' + (this.props.error ? ' modal-trigger-error' : '')}>
        <a
          role="link"
          className={'condition-link'}
          onClick={this.props.triggerModal}
        >
          <Icon className="como-ic-date" data-automation-id="globalConditions.dateTime" />
          {this.triggerMessage}
        </a>
      </div>
    );
  }

  apply = () => {
    this.props.handleDatesApply(this.state.datesRangeCondition.conditionValue, this.state.datesRangeCondition);
    this.props.triggerModal();
  };

  handleDateChange = (date, isStart) => {
    const { datesRangeCondition } = this.state;
    let condition = {};

    if (isStart) {
      condition = {
        conditionKey: 'context.TimeStamp',
        conditionValue: datesRangeCondition.conditionValue.hasOwnProperty('dateTo') ? {
          dateFrom: date,
          dateTo: datesRangeCondition.conditionValue.dateTo
        } : date,
        dataValueType: 'dateTimeBetween',
        operatorKey: 'dateTimeIsBetween'
      };
    } else {
      if (date === null) {
        condition = {
          conditionKey: 'context.TimeStamp',
          conditionValue: datesRangeCondition.conditionValue,
          dataValueType: 'dateTimeBetween',
          operatorKey: 'dateTimeIsAfter'
        };
      } else {
        condition = {
          conditionKey: 'context.TimeStamp',
          conditionValue: {
            dateFrom: datesRangeCondition.conditionValue.hasOwnProperty('dateFrom') ? datesRangeCondition.conditionValue.dateFrom : datesRangeCondition.conditionValue,
            dateTo: date
          },
          dataValueType: 'dateTimeBetween',
          operatorKey: 'dateTimeIsBetween'
        };
      }
    }
    this.setState({ datesRangeCondition: condition });
  };

  render() {
    const { formatMessage } = this.props.intl;

    return (
      <Modal
        className={'pointShop-conditions-modal'}
        automationIdPrefix={'AUTOMATION_NAME'}
        header={
          formatMessage({ id: 'activity.globalConditions.dateTime' })
        }
        actions={[
          <Button
            key="globalMemConditionCancelBtn"
            onClick={this.props.triggerModal}
            basic
            className="link modal-button"
            disabled={this.props.filtersInProgress}
            data-automation-id="globalConditions.members.btnCancel"
          >
            {formatMessage({ id: 'general.button.cancel' })}
          </Button>,
          <Button
            key="globalMemConditionSaveFilterCriteria"
            onClick={this.apply}
            className="modal-button stick-to-left point-shop-condition-save-btn"
            data-automation-id="globalConditions.members.btnSaveFilterCriteria"
          >
            {formatMessage({ id: 'general.button.save' })}
          </Button>,
        ]}
        trigger={this.trigger}
        content={
          <div>
            <Grid className="gray-panel point-shop-date-condition-grey-panel">
              <Grid.Row className={'date-condition-row' + (this.props.error ? ' date-condition-row-error' : null)}>
                <Grid.Column
                  width={3}
                  className="day-condition-row-subtitle inline-subtitle"
                >{formatMessage({ id: 'activity.globalConditions.datetTime.betweenDates' })}</Grid.Column>
                <Grid.Column width={9}>
                  <DateTimeRange
                    errors={fromJS({[activityConstants.CONDITION_VALUE]: this.props.error || null })}
                    condition={fromJS(this.state.datesRangeCondition)}
                    updateDateRangeDate={this.handleDateChange}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        }
        size={960}
        closeIcon={<Icon className="como-ic-close" name="close" onClick={this.props.triggerModal} />}
        open={this.props.isModalOpen}
      />
    );
  }
}

export default injectIntl(DatesCondition);
