import axios from 'axios';
const BASE_URL = window.location.origin;

export function userLogoutRequest(hub1Path) {
  return fetch(`${hub1Path}/api/private/user/logout`, { mode: 'no-cors', credentials: 'include' });
}

export function getBusinessData() {
  return axios.get('/api/business')
    .then((res) => res.data);
}

export function loadTranslationsFetch(locale) {
  return axios.get(`/api/translations/${locale}`)
    .then((res) => res.data);
}

export function loadSsrTranslationsFetch(locale) {
  return axios.get(`/api/translations/ssrTranslations/${locale}`)
    .then((res) => res.data);
}
export function getLegalSettings(locale) {
  return axios.get(`/api/bpls?countryCode=${locale}`)
    .then((res) => res.data);
}

export function getApplicationData() {
  return axios.get('/api/ApplicationData')
    .then((res) => res.data);
}

export function getBusinessesData() {
  return axios.get('/api/ApplicationData/businessesData')
    .then((res) => res.data);
}

export function getFranchisesData() {
  return axios.get('/api/user/franchisesData')
    .then((res) => res.data);
}

export function contactSupport(data) {
  return axios.post('/api/user/contactSupport', data)
    .then((res) => res.data).catch((error) => error.response.data);
}
export function getMonthlyCommunicationsLimitation() {
  return axios.get('/api/ApplicationData/getMonthlyCommunicationsLimitation')
    .then((res) => res.data);
}

export function getLocations() {
  return axios.get('/api/ApplicationData/getLocations')
    .then((res) => res.data);
}

export function getFeatures() {
  return axios.get('/api/Feature')
    .then((res) => res.data);
}

export function checkMembershipKey(data) {
  return axios.get(`${BASE_URL}/api/Customer/checkMembershipKeyIsLegal/${data.membershipKey}`)
    .then((res) => res.data);
}

export function queryCustomers(data) {
  return axios.get(`${BASE_URL}/api/Customer/queryCustomers?type=${data.type}&search=${data.search}&allowMultiple=${data.allowMultiple ? '1' : '0'}`)
    .then((res) => res.data);
}

export function updateUser(data) {
  return axios.put('/api/user', data)
    .then((res) => res.data);
}

export function getVerificationCode(data) {
  return axios.get(`/api/tools/verificationCode/${data.userRegisterValidationType}/${data.identifierValue}`, data)
    .then((res) => res.data).catch((err) => console.error(err));
}

export function getAppClientId(data) {
  return axios.get(`/api/tools/getAppClientId/${data.userRegisterValidationType}/${data.identifierValue}`, data)
  .then((res) => res.data).catch((err) => console.error(err));
}

export function unblockMember(phoneNumber) {
  return axios.get(`/api/tools/unblockMember?phoneNumber=${phoneNumber}`, )
    .then((res) => res.data);
}

export function setDemoMode(demoMode) {
  return axios.post(`/api/user/updateDemoMode/${demoMode}`)
    .then((res) => res.data);
}
export function getRegistrationFields() {
  return axios.get('/api/tools/registrationFields')
    .then((res) => res.data);
}

export function createMember(data) {
  return axios.post('/api/tools/createMember', data)
    .then((res) => res.data).catch((error) => error.response.data);
}

export function cancel3rdPartyCode(data) {
  return axios.post('/api/tools/cancelCodes', data)
    .then((res) => res.data);
}

export function getCurrentSMSProviders(locationCode) {
  return axios.get(`/api/comnSettings/sms/${locationCode}/currentProviders`).then((response) => response.data);
}

export function changeUserPassword(data) {
  return axios.post('/api/tools/changePassword', data)
    .then((response) => {
      return { status: 'success' };
    })
    .catch((error) => {
      console.log(error.response);
      return { status: 'error' };
    });
}

/**
 * get membership tags per business
 * @returns {Promise<AxiosResponse<any> | never>}
 */
export function getTags() {
  return axios.get('/api/Business/tags')
    .then((res) => res.data);
}

export function requestToUpgradeToProfessional(data) {
  return axios.post('/api/tools/requestToUpgradeToProfessional', data)
    .then((res) => res.data).catch((error) => error.response.data);
}

export function getBusinessTimeInfo() {
  return axios.get('/api/ApplicationData/getBusinessTimeInfo')
    .then((res) => res.data);
}
