import { fromJS } from 'immutable';

import * as constants from './email.constants';
import * as defaults from './email.defaults';
import * as featuresConstants from '../features.constants';
import * as appConstants from '../App/app.constants';
import * as validationUtils from './utils/validation.utils';
import * as templatesConstants from '../Templates/templates.constants';

const initialState = fromJS({});

function emailReducer(state = initialState, action) {

  let forceValidation = false;
  let newState = state;

  switch (action.type) {

    case constants.UPDATE_EMAIL_FIELD:
      newState = state.setIn([constants.EMAIL_DATA, action.fieldName], action.fieldValue);
      break;

    case constants.CREATE_EMAIL:
      {
        newState = newState.set(featuresConstants.DATA, defaults.initialEmail);
        newState = newState.setIn([featuresConstants.DATA, appConstants.LOCATION_ID], action.locationId);
        break;
      }
    case constants.GET_EMAIL_SUCCESS:
      newState = state
        .set(featuresConstants.DATA, fromJS(action.data))
        .set(constants.IS_DIRTY, false)
        .set(constants.EMAIL_DATA_LOADED, true);
      break;

    case constants.SET_EMAIL_DATA_LOADING:
      newState = state.set(constants.EMAIL_DATA_LOADED, false);
      break;

    case constants.CLEAR_EMAIL_DATA:
      newState = state
        .delete(constants.EMAIL_DATA)
        .delete(constants.EMAIL_EDITOR_UID)
        .delete(constants.EMAIL_EDITOR_TOKEN)
        .delete(constants.TEMPLATE)
        .delete(constants.TEMPLATE_KEY);
      break;

    case constants.INITIALIZE_EMAIL_EDITOR_SUCCESS:
      newState = state
        .set(constants.EMAIL_EDITOR_TOKEN, action.authData.token)
        .set(constants.EMAIL_EDITOR_UID, action.authData.uid);
      break;

    case constants.UPDATE_EMAIL_METADATA:
      newState = state.setIn([constants.EMAIL_DATA, action.key], action.value);
      break;

    case constants.SET_EMAIL_PUBLISH_IN_PROGRESS:
      newState = state.set(action[featuresConstants.ACTION_IN_PROGRESS_NAME], action[featuresConstants.ACTION_IN_PROGRESS_VALUE]);
      break;

    case constants.SET_EMAIL_ERRORS:
      newState = state
        .set(constants.EMAIL_VALIDATION_ERRORS, action[constants.EMAIL_VALIDATION_ERRORS])
        .set(constants.EMAIL_VALIDATION_NAME_TAKEN_FLAG, action[constants.EMAIL_VALIDATION_NAME_TAKEN_FLAG]);
      break;

    case constants.CHECK_EMAIL_NAME_AVAILABILITY_SUCCESS: {
      // update metadata before validation
      newState = newState.set(constants.EMAIL_VALIDATION_NAME_TAKEN_FLAG, !action.nameAvailable);
      forceValidation = true;
      break;
    }

    case constants.CHECK_EMAIL_NAME_AVAILABILITY: {
      newState = newState.set(constants.CHECKING_EMAIL_NAME_AVAILABILITY, true);
      break;
    }

    case constants.CHECK_EMAIL_NAME_AVAILABILITY_DONE: {
      newState = newState.set(constants.CHECKING_EMAIL_NAME_AVAILABILITY, false);
      break;
    }

    case constants.MARK_AS_DIRTY: {
      newState = newState.set(constants.IS_DIRTY, true);
      break;
    }

    case templatesConstants.LOAD_TEMPLATE_DATA_INTO_EMAIL: {
      const { template } = action.payload;

      newState = newState
        .set(constants.EMAIL_DATA, template.get(constants.EMAIL))
        .set(constants.TEMPLATE, template.delete(constants.EMAIL))
        .set(constants.TEMPLATE_KEY, template.get(templatesConstants.DB_ID));
      break;
    }

    default:
      newState = state;
  }

  newState = emailValidationsImpl(newState, forceValidation);
  newState = markAsDirtyIfNeeded(action, state, newState);

  return newState;

}

function emailValidationsImpl(state, forceValidation) {
  // Re-validate if needed
  let newState = state;
  const validationErrors = newState.get(constants.EMAIL_VALIDATION_ERRORS);
  if (forceValidation || validationErrors) {
    const validationMode = featuresConstants.VALIDATION_MODE_PUBLISH;
    if (validationMode === featuresConstants.VALIDATION_MODE_PUBLISH) {
      newState = validationUtils.validateEmail(newState);
    }
  }
  return newState;
}

function markAsDirtyIfNeeded(action, prevState, newState) {
  if (action.type === constants.INITIALIZE_EMAIL_EDITOR_SUCCESS) {
    return newState;
  }
  if (prevState !== newState && prevState.get(constants.EMAIL_DATA_LOADED)) {
    return newState.set(constants.IS_DIRTY, true);
  }
  return newState;
}

export default emailReducer;

