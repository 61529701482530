/*
 *
 * Members api
 *
 */

import axios from 'axios';
import {
  adjustUserActionsConditions,
  mergeAllFilterConditions,
  normalizeMembershipGlobalConditionTempModel
} from '../Activity/reducers/utils/globalCondition.reducer.utils';
import * as activityConstants from '../Activity/activity.constants';
import { LOCATION_ID } from '../App/app.constants';

const MEMBERS_DEFAULT_LIMIT = 50;

export function getCustomer() {
  return axios.get('/api/customer')
    .then((res) => res.data)
    .catch((err) => err.message);
}

export function getMembersFilteredByCommunicationActions(globalMemberConditions, locationId) {
  // Returns the membership conditions
  const filterConditions = normalizeMembershipGlobalConditionTempModel(globalMemberConditions).toJS();
  const mergedFilterConditions = mergeAllFilterConditions(filterConditions);
  // Returns only the user action filters
  const userActionFilters = adjustUserActionsConditions(filterConditions);
  const url = '/api/FilterPopulation/membersCountTouchedByCommunication';
  const requestData = {
    [activityConstants.FILTERED_POPULATION_CONDITIONS]: mergedFilterConditions,
    [activityConstants.FILTERED_POPULATION_USER_ACTIONS_CONDITIONS]: userActionFilters,
    [LOCATION_ID]: locationId
  };
  return axios.post(url, requestData).then((res) => {
    return res.data;
  });
}

export function findMembers(searchQuery, limit = MEMBERS_DEFAULT_LIMIT) {

  return axios.get(`/api/Customer/findMembers`, {
    params: {
      search: searchQuery,
      limit: limit
    }
  }).then((res) => res.data);
}

