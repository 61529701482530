import { takeEvery, call, put, select } from 'redux-saga/effects';
import { actions as toastrActions } from '../../../common/components/ReduxToast';
import * as selectors from './admin.selectors';
import * as constantsTypes from './constants/types';
import * as appActions from '../../App/app.actions';
import * as templatesAdminApi from './admin.api';
import * as errorConstant from '../../ErrorMessage/errorMessage.constants';

function notificationAction(message) {
  return toastrActions.add({
    message,
    options: {
      showCloseButton: true,
      className: 'simple-hub2-toastr-notification',
      removeOnHover: true
    }
  });
}

function* createTemplateCategory() {
  try {

    yield put(appActions.setLoadingState(true));
    const type = yield select(selectors.getCategoryType);

    if (!type) {
      yield put(notificationAction('Category type has to be specified'));
      return null;
    }

    const name = yield select(selectors.getCategoryName);

    yield call(templatesAdminApi.createTemplateCategory, type, name);
    yield put({ type: constantsTypes.CREATE_TEMPLATE_CATEGORY_SUCCESS });

    yield put(notificationAction('Template category has been successfully added'));
  } catch (e) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['createTemplateCategory.Fail'] });
  } finally {
    yield put(appActions.setLoadingState(false));
  }
}

function* templatesAdminSaga() {
  yield [
    takeEvery(constantsTypes.CREATE_TEMPLATE_CATEGORY, createTemplateCategory)
  ];
}

export default templatesAdminSaga;
