import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Tooltip from '../../../../common/components/Tooltip/Tooltip';
import * as benefitsActions from '../../../Benefits/benefits.actions';
import * as itemsPopulationActions from '../itemsPopulation/itemsPopulation.actions';
import * as itemGroupsActions from '../../../ItemGroups/itemGroups.actions';
import * as casesActions from '../cases/cases.actions';
import * as constants from '../../activity.constants';
import * as schemaConstants from '../../activitySchema/activitySchema.constants';

import CaseConditionSummary from './ConditionSummary';
import { getDecimalPrecision, getLocationId } from '../../../App/selectors';
import { getTransformedMentions } from '../../activitySchema/mentions.selectos';
import { getBenefitsList, getBenefitsListFetchedFlag } from '../../../Benefits/benefits.selector';
import { getItemGroupsList, getItemGroupsListFetchedFlag } from '../../../ItemGroups/itemGroups.selectors';
import ActionView from '../ActionView';


class CaseSummary extends React.PureComponent {

  static propTypes = {
    case: PropTypes.object.isRequired,
    caseIndex: PropTypes.number.isRequired,
    conditionsList: PropTypes.object,
    benefitsFetched: PropTypes.bool,
    locationId: PropTypes.number.isRequired,
    itemGroupsFetched: PropTypes.bool,
    actions: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    abTestMode: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.expandCase = this.expandCase.bind(this);
    this.state = { hasError: false };
  }

  componentDidMount() {
    const action = this.getFirstAction();
    if (action) {
      const actionType = action.get(constants.ACTION_TYPE);
      if ((actionType === schemaConstants.PUNCH_EXCEEDED_ACTION ||
        actionType === schemaConstants.AUTO_PUNCH_ACTION ||
        actionType === schemaConstants.ASSIGN_ASSET_ACTION) && !this.props.benefitsFetched) {
        // noinspection JSUnresolvedFunction
        this.props.actions.getBenefits();
      }
      if (action.get(constants.DISCOUNT_ON) && !this.props.itemGroupsFetched) {
        this.props.actions.getItemGroups();
      }
    }
  }

  getMoreExistsTooltip(count, messageIdPrefix) {
    // noinspection JSUnresolvedFunction
    return (<Tooltip
      className="pre-formatted-tooltip"
      wide
      trigger={
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <span className="more-tooltip-trigger" onClick={this.expandCase}>
          <FormattedMessage id="activity.cases.summary.more.exists" />
        </span>}
      position="top left"
    >
      <h4>
        <FormattedMessage
          id={`${messageIdPrefix}.more.exists.tooltip.title`}
          values={{ count: count - 1 }}
        />
      </h4>
      <p>
        <FormattedMessage id={`${messageIdPrefix}.more.exists.tooltip.message`} />
      </p>
      <p>
        <FormattedMessage id="activity.cases.summary.more.exists.click.to.expand" />
      </p>
    </Tooltip>);
  }

  getFirstCondition() {
    return this.props.case.getIn([constants.CONDITIONS, constants.CONDITIONS_LIST, 0]);
  }

  getFirstAction() {
    return this.props.case.getIn([constants.ACTIONS, 0, constants.ACTIONS_LIST, 0]);
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  expandCase() {
    // noinspection JSUnresolvedFunction
    this.props.actions.setCasesExpandCollapseMode(false, this.props.caseIndex);
  }

  get conditionDescription() {
    const firstCondition = this.getFirstCondition();
    if (!firstCondition ||
      !firstCondition.get(constants.CONDITION_KEY) ||
      !firstCondition.get(constants.OPERATOR_KEY)) {
      return (<span className="summary-label">
        <FormattedMessage id="activity.cases.summary.apply.without.conditions" />&nbsp;
      </span>);
    }
    const conditionsList = this.props.case.getIn([constants.CONDITIONS, constants.CONDITIONS_LIST]);
    return (<span>
      <span className="summary-label head-of-sentence">
        <FormattedMessage id="activity.cases.summary.when" />
      </span>
      <span className="summary-message">
        <CaseConditionSummary
          condition={firstCondition}
          conditionsFromSchema={this.props.conditionsList}
          abTestMode={this.props.abTestMode}
        />
      </span>
      {conditionsList.size > 1 && this.getMoreExistsTooltip(conditionsList.size, 'activity.cases.summary.condition')}
    </span>);
  }

  get actionDescription() {
    const actionsWrapper = this.props.case.getIn([constants.ACTIONS, 0]);
    const actionsMode = actionsWrapper.get(constants.ACTIONS_MODE);
    const actionsList = actionsWrapper.get(constants.ACTIONS_LIST);
    const firstAction = this.getFirstAction();

    if (!firstAction || !firstAction.get(constants.ACTION_TYPE)) {
      return (<span className="summary-label head-of-sentence">
        <FormattedMessage id="activity.cases.summary.actions.do.nothing" />
      </span>);
    }
    if (actionsMode === constants.ACTIONS_OCCURRENCES_MODE) {
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      return (<span className="clickable" onClick={this.expandCase}>
        <FormattedMessage id="activity.cases.summary.actions.complex.action.click.to.expand" />
      </span>);
    }
    const actionsCount = this.props.case.get(constants.ACTIONS).count((action) => action.getIn([constants.ACTIONS_LIST, 0, constants.ACTION_TYPE]) !== '');

    return (<span>
      <span className="summary-label head-of-sentence">
        <FormattedMessage id="activity.cases.summary.actions.do" />
      </span>
      <span className="summary-message">
        <ActionView actionsList={actionsList} />
      </span>
      {actionsCount > 1 && this.getMoreExistsTooltip(actionsCount, 'activity.cases.summary.actions')}
    </span>);
  }

  render() {
    if (!this.props.case || !this.props.conditionsList || !this.props.locationId || this.state.hasError) {
      return null;
    }
    return (<div className="case-summary">
      <div>
        {this.conditionDescription}
      </div>
      <div>
        {this.actionDescription}
      </div>
    </div>);
  }
}

const mapStateToProps = (state) => ({
  mentions: getTransformedMentions(state),
  benefits: getBenefitsList(state),
  benefitsFetched: getBenefitsListFetchedFlag(state),
  decimalPrecision: getDecimalPrecision(state),
  itemGroups: getItemGroupsList(state),
  itemGroupsFetched: getItemGroupsListFetchedFlag(state),
  locationId: getLocationId(state),
});

function mapDispatchToProps(dispatch) {
  const creators = {
    ...benefitsActions,
    ...itemGroupsActions,
    ...itemsPopulationActions,
    ...casesActions
  };
  return { actions: bindActionCreators(creators, dispatch) };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, injectIntl)(CaseSummary);
