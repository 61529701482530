

export function transformMentionText(message, mentions) {
  if (!mentions || !message) {
    return '';
  }
  const parts = message.split(/{{([\s\w.]+)}}/g);
  const mentionsDictionary = mentions.reduce((map, mention) => {
    map[mention.get('id')] = mention.get('display');
    return map;
  }, {});
  for (let i = 0; i < parts.length; i++) {
    if (parts[i] in mentionsDictionary) {
      parts[i] = mentionsDictionary[parts[i]];
    }
  }
  return parts.filter((p) => !!p).join(' ');
}

export function removeDataUnsupportedCharacters(replaceString) {
  return replaceString
    .replace(/\//g, ' ')
    .replace(/\*/g, ' ')
    .replace(/\\/g, '')
    .replace(/:/g, '')
    .replace(/"/g, '')
    .replace(/״/g, '')
    .replace(/\?/g, '');
}

export function removeNewLineCharacters(value) {
  return typeof value === 'string' ? value.replace(/\\n/g, '').trim() : value;
}
