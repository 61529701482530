import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import '../../conditions.styles.less';
import * as activityActions from '../../../../activity.actions';
import * as activitySelectors from '../../../../activity.selectors';
import * as activityConstants from '../../../../activity.constants';
import DropdownWrapper from '../../../../../../common/components/DropdownWrapper/DropdownWrapper';
import './BirthdayOrAnniversaryEventTypeCondition.styles.less';

class BirthdayOrAnniversaryEventTypeCondition extends React.PureComponent {

  static propTypes = {
    value: PropTypes.string,
    actions: PropTypes.object,
    intl: PropTypes.object,
    trigger: PropTypes.string.isRequired
  };

  get dropdownOptions() {
    return [activityConstants.EVENT_SCOPE_DAY, activityConstants.EVENT_SCOPE_MONTH].map((value) => ({
      value,
      text: this.props.intl.formatMessage(
        { id: `birthday.or.anniversary.event.type.condition.value.${value}` },
        { trigger: this.props.trigger })
    }));
  }

  render() {
    const { formatMessage } = this.props.intl;
    if (!this.props.value) {
      return null;
    }
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={2} className="condition-left-column">
            <p>{formatMessage({ id: 'birthday.or.anniversary.event.type.condition.title' })}</p>
          </Grid.Column>
          <Grid.Column width={14}>
            <Grid className="condition-row last-condition">
              <Grid.Row>
                <Grid.Column width={16}>
                  <DropdownWrapper
                    prefix="birthday-or-anniversary-event-type-condition"
                    value={this.props.value}
                    options={this.dropdownOptions}
                    className="birthday-or-anniversary-event-type-condition"
                    onSelectOption={
                    (e, data) => this.props.actions.setBirthdayOrAnniversaryEventScope(data.value)
                  }
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => ({
  value: activitySelectors.getBirthdayOrAnniversaryEventScope(state),
  trigger: activitySelectors.getSelectedTrigger(state)
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(activityActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(BirthdayOrAnniversaryEventTypeCondition);
