import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Icon } from 'semantic-ui-react';

import * as activityConstants from '../../Activity/activity.constants';
import * as templateConstants from '../templates.constants';

import templatesSaga from '../templates.saga';
import * as templateActions from '../templates.actions';
import withAuthorization from '../../../common/helpers/authorization';
import saga from '../../Activity/activity.saga';
import * as selectors from '../../Activity/activity.selectors';
import injectSaga from '../../../utils/injectSaga';

import SaveTemplate from '../views/SaveTemplate/SaveTemplate';
import ReplaceOrNewTemplate from '../views/ReplaceOrNewTemplate/ReplaceOrNewTemplate';

import * as templatesSelectors from '../templates.selectors';
import * as appSelectors from '../../App/selectors';
import tracker from '../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../common/utils/tracking/tracking.consts';
import * as reducerConstants from '../../../constants/reducer.constants';
import * as emailsConstants from '../../Emails/emails.constants';
import * as emailSelectors from '../../Email/email.selectors';

class SaveTemplateTrigger extends React.PureComponent {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    locationId: PropTypes.number,
    templateCategories: PropTypes.object,
    businessesVerticals: PropTypes.object,
    activity: PropTypes.object,
    email: PropTypes.object,
    entityType: PropTypes.string.isRequired,
    template: PropTypes.object,
    templateType: PropTypes.string.isRequired,
    activityPrefix: PropTypes.string.isRequired,
    onEmailSaveAsTemplate: PropTypes.func

  };

  constructor(props) {
    super(props);
    const { actions, businessesVerticals, entityType } = props;
    if (businessesVerticals.size < 1) {
      // noinspection JSUnresolvedFunction
      actions.getBusinessesVerticals();
    }
    if (entityType !== emailsConstants.EMAIL) {
      // noinspection JSUnresolvedFunction
      actions.getProductCategories(entityType);
    }
  }

  handleSaveBusinessTemplate = (template, entityType) => {
    // noinspection JSUnresolvedFunction
    if (entityType !== emailsConstants.EMAIL) {
      this.props.actions.saveAsBusinessTemplate(template, entityType);
    } else {
      this.props.onEmailSaveAsTemplate(template, true);
    }

    tracker.onEvent(trackerConstants.EVENT_TYPE_BUSINESS_TEMPLATE_SAVED, {
      [trackerConstants.BUSINESS_TEMPLATE_SAVED_ARGS_ACTIVITY_TYPE]: entityType
    });
  };

  handleUpdateBusinessTemplate = (template, entityType) => {
    if (entityType !== emailsConstants.EMAIL) {
      this.props.actions.updateBusinessTemplate(template, entityType);
    } else {
      this.props.onEmailSaveAsTemplate(template, false);
    }
  };

  render() {
    const { actions, locationId, email, activity, entityType, template, templateType, templateCategories,
      businessesVerticals, activityPrefix, intl } = this.props;
    const { formatMessage } = intl;
    const entity = entityType !== emailsConstants.EMAIL ? activity : email;
    const trigger = (
      <a
        className="activity-link"
        data-automation-id={`${activityConstants.AUTOMATION_NAME}.save-as-template.${templateType}.trigger`}
      >
        <Icon
          className="como-ic-template"
          size="large"
        />
        {formatMessage({ id: `activity.save-as-template.${templateType}.trigger` })}
      </a>);
    const isExistingTemplate = template && (
      (templateType === templateConstants.TEMPLATE_TYPE_PRODUCT && template.get(templateConstants.CATEGORY_DB_ID))
      || (templateType === templateConstants.TEMPLATE_TYPE_BUSINESS && !template.get(templateConstants.CATEGORY_DB_ID)));
    if (!isExistingTemplate) {
      // noinspection JSUnresolvedVariable
      return (
        <SaveTemplate
          key={templateType}
          templateType={templateType}
          header="activity.save-as-template.header.save"
          prefix={activityPrefix}
          entity={entity}
          entityType={entityType}
          saveAsTemplate={
            templateType === templateConstants.TEMPLATE_TYPE_PRODUCT
              ? actions.saveAsProductTemplate
              : this.handleSaveBusinessTemplate
          }
          categories={templateCategories}
          trigger={trigger}
        />
      );
    }
    // noinspection JSUnresolvedVariable
    return (
      <ReplaceOrNewTemplate
        key={templateType}
        templateType={templateType}
        prefix={activityPrefix}
        locationId={locationId}
        saveTemplate={
          templateType === templateConstants.TEMPLATE_TYPE_PRODUCT
            ? actions.saveAsProductTemplate
            : this.handleSaveBusinessTemplate
        }
        editTemplate={
          templateType === templateConstants.TEMPLATE_TYPE_PRODUCT
            ? actions.updateProductTemplate
            : this.handleUpdateBusinessTemplate
        }
        categories={templateCategories}
        entity={entity}
        entityType={entityType}
        template={template}
        trigger={trigger}
        businessesVerticals={businessesVerticals}
        templateVerticals={template.get(templateConstants.TEMPLATE_VERTICALS)}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  activity: selectors.getActivityDataModel(state),
  email: emailSelectors.getEmailDataModel(state),
  locationId: appSelectors.getLocationId(state),
  businessesVerticals: templatesSelectors.getBusinessVerticals(state),
  templateCategories: templatesSelectors.getProductTemplatesCategories(state)
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(templateActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.ACTIVITY_BRANCH, saga });
const withTemplatesSaga = injectSaga({ key: reducerConstants.TEMPLATES_BRANCH, saga: templatesSaga });

export default compose(
  withSaga,
  withTemplatesSaga,
  withConnect,
  withAuthorization
)(injectIntl(SaveTemplateTrigger));
