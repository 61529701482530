import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Grid, Segment } from 'semantic-ui-react';
import classNames from 'classnames';

import * as activityConstants from '../../activity.constants';
import CaseHeader from './CaseHeader';
import CaseConditions from './CaseConditions';
import CaseActions from './CaseActions';
import CaseOrderControls from './CaseOrderControls';

import tracker from '../../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../../common/utils/tracking/tracking.consts';


class SingleCase extends React.PureComponent {

  static propTypes = {
    actionsPopupContent: PropTypes.object,
    activityTrigger: PropTypes.string,
    activityType: PropTypes.string,
    allowOccurrences: PropTypes.bool,
    automationIdPrefix: PropTypes.string,
    caseIndex: PropTypes.number,
    casesActions: PropTypes.object,
    casesCount: PropTypes.number,
    conditionsPerTrigger: PropTypes.object,
    dateTimeConditions: PropTypes.object,
    features: PropTypes.object,
    hideAdvancedDealActions: PropTypes.bool,
    isSingleBundleLine: PropTypes.bool,
    membershipConditions: PropTypes.object,
    mentionsList: PropTypes.object,
    singleCase: PropTypes.object.isRequired,
    trigger: PropTypes.string,
    updateMembershipConditions: PropTypes.object,
    validationErrors: PropTypes.object,
    abTestMode: PropTypes.bool,
    isOneTimeActivityDisabled: PropTypes.bool,
    getBusinessBundlePlan: PropTypes.func.isRequired,
    hasFullPermission: PropTypes.func.isRequired
  };

  get conditionsList() {
    const { conditionsPerTrigger, dateTimeConditions, membershipConditions } = this.props;
    if (!conditionsPerTrigger || !dateTimeConditions || !membershipConditions) {
      return null;
    }
    return conditionsPerTrigger.concat(dateTimeConditions).concat(membershipConditions);
  }

  render() {

    const isCollapsed = this.props.singleCase.has(activityConstants.IS_COLLAPSED) ? this.props.singleCase.get(activityConstants.IS_COLLAPSED) : true;
    const automationIdPrefix = `${this.props.automationIdPrefix}.${this.props.caseIndex}`;
    const singleCaseClassName = classNames('single-case', { 'collapsed': isCollapsed });
    const conditionsList = this.conditionsList;
    if (!conditionsList) {
      return null;
    }
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={this.props.abTestMode ? 16 : 15}>
            <Segment className={singleCaseClassName}>
              <Accordion>
                <Accordion.Title active={!isCollapsed}>
                  <CaseHeader
                    automationIdPrefix={automationIdPrefix}
                    caseIndex={this.props.caseIndex}
                    casesActions={this.props.casesActions}
                    casesCount={this.props.casesCount}
                    conditionsList={conditionsList}
                    case={this.props.singleCase}
                    membershipConditions={this.props.membershipConditions}
                    abTestMode={this.props.abTestMode}
                    isOneTimeActivityDisabled={this.props.isOneTimeActivityDisabled}
                  />
                </Accordion.Title>
                <Accordion.Content active={!isCollapsed}>
                  <CaseConditions
                    automationIdPrefix={automationIdPrefix}
                    caseConditions={this.props.singleCase.getIn([activityConstants.CONDITIONS, activityConstants.CONDITIONS_LIST])}
                    caseIndex={this.props.caseIndex}
                    casesActions={this.props.casesActions}
                    conditionsPerTrigger={this.props.conditionsPerTrigger}
                    dateTimeConditions={this.props.dateTimeConditions}
                    membershipConditions={this.props.membershipConditions}
                    trigger={this.props.trigger}
                    features={this.props.features}
                    validationErrors={this.props.validationErrors ?
                      this.props.validationErrors.get(activityConstants.CONDITIONS) :
                      null}
                    updateMembershipConditions={this.props.updateMembershipConditions}
                    abTestMode={this.props.abTestMode}
                    isOneTimeActivityDisabled={this.props.isOneTimeActivityDisabled}
                  />
                  <CaseActions
                    automationIdPrefix={automationIdPrefix}
                    actionsWrappers={this.props.singleCase.get(activityConstants.ACTIONS)}
                    actionsPopupContent={this.props.actionsPopupContent}
                    activityTrigger={this.props.activityTrigger}
                    allowOccurrences={this.props.allowOccurrences}
                    caseIndex={this.props.caseIndex}
                    casesActions={this.props.casesActions}
                    conditionsList={this.props.conditionsPerTrigger}
                    hideAdvancedDealActions={this.props.hideAdvancedDealActions}
                    isSingleBundleLine={this.props.isSingleBundleLine}
                    mentionsList={this.props.mentionsList}
                    validationErrors={this.props.validationErrors ?
                      this.props.validationErrors.get(activityConstants.ACTIONS) :
                      null}
                    activityType={this.props.activityType}
                    features={this.props.features}
                    isOneTimeActivityDisabled={this.props.isOneTimeActivityDisabled}
                    getBusinessBundlePlan={this.props.getBusinessBundlePlan}
                    hasFullPermission={this.props.hasFullPermission}
                  />
                </Accordion.Content>
              </Accordion>
            </Segment>
          </Grid.Column>
          { !this.props.abTestMode && <Grid.Column width={1} className="case-order-controls-column">
            <CaseOrderControls
              automationIdPrefix={automationIdPrefix}
              caseIndex={this.props.caseIndex}
              casesCount={this.props.casesCount}
              updateCaseOrder={tracker.wrapButtonAction(
                this.props.casesActions.updateCaseOrder, trackerConstants.BUTTON_TYPE_CASE_CHANGE_PRIORITY)}
            />
          </Grid.Column>
            }
        </Grid.Row>
      </Grid>);
  }
}

export default SingleCase;
