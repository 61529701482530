import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';

class PopupFooter extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string.isRequired,
    onApply: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  render() {

    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    return (
      <div className="popupFooter">
        <a role="link" tabIndex={0} onClick={this.props.onCancel} data-automation-id={`${this.props.automationIdPrefix}.cancel`}>
          {formatMessage({ id: 'activity.itemsPopulation.action.cancel', defaultMessage: 'Cancel' })}
        </a>
        <Button tabIndex={0} onClick={this.props.onApply} data-automation-id={`${this.props.automationIdPrefix}.apply`}>
          {formatMessage({ id: 'activity.itemsPopulation.action.apply', defaultMessage: 'Apply' })}
        </Button>
      </div>
    );
  }
}

export default injectIntl(PopupFooter);
