import { fromJS } from 'immutable';
import * as constants from './errorMessage.constants';

const initialState = fromJS({});

function errorMessageReducer(state = initialState, action) {
  switch (action.type) {
    case constants.HANDLE_ERROR: {
      let newState = state
        .set(constants.MESSAGES_KEYS, fromJS(action[constants.MESSAGES_KEYS]))
        .set(constants.ERROR_TITLE, action[constants.ERROR_TITLE] || 'error.message.header');
      if (action[constants.LEAVE_ON_ERROR_CLOSE]) {
        newState = newState.set(constants.LEAVE_ON_ERROR_CLOSE, action[constants.LEAVE_ON_ERROR_CLOSE]);
      }
      if (action[constants.ERROR_TYPE]) {
        newState = newState.set(constants.ERROR_TYPE, action[constants.ERROR_TYPE]);
      }
      return newState;
    }
    case constants.HANDLE_ERROR_MESSAGE_CLOSE: {
      return state.set(constants.MESSAGES_KEYS, fromJS([]));
    }
    case constants.HANDLE_TO_MANY_MEMBERS_POPUP_VISIBILITY: {
      return state.set(constants.TO_MANY_MEMBERS_STATUS, action.show);
    }
    case constants.RESET_ERROR_LEAVE_PATH: {
      return state.delete(constants.LEAVE_ON_ERROR_CLOSE);
    }
    default:
      return state;
  }
}

export default errorMessageReducer;
