import { fromJS } from 'immutable';

import * as constants from '../email.constants';
import * as featuresConstants from '../../features.constants';

export function validateEmail(state) {
  let newState = resetValidation(state);
  if (!newState.getIn([featuresConstants.DATA, constants.SUBJECT])) {
    newState = newState.setIn([constants.EMAIL_VALIDATION_ERRORS, constants.SUBJECT], 'validation.error.email.subject.required');
  }
  if (!newState.getIn([featuresConstants.DATA, constants.EMAIL_NAME])) {
    newState = newState.setIn([constants.EMAIL_VALIDATION_ERRORS, constants.EMAIL_HEADER, constants.EMAIL_NAME], 'email.validation.error.name.required');
  }
  if (newState.get(constants.EMAIL_VALIDATION_NAME_TAKEN_FLAG)) {
    newState = handleEmailNameAlreadyInUseError(newState);
  }
  return newState;
}

export function handleEmailNameAlreadyInUseError(state) {
  return state.setIn([constants.EMAIL_VALIDATION_ERRORS, constants.EMAIL_HEADER],
    fromJS({ [constants.EMAIL_NAME]: 'validation.error.email.name.already.used' }));
}

function resetValidation(state) {
  return state.delete(constants.EMAIL_VALIDATION_ERRORS);
}
