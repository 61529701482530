import { fromJS } from 'immutable';
import * as constants from '../activity.constants';
import * as defaults from './activity.reducer.defaults';
import * as utils from './utils/activity.datetime.reducer.utils';

function getFilterUserActionDateRangeConditionPath(userActionIndex) {
  return [constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS,
    constants.USER_ACTIONS_CONDITIONS_LIST, userActionIndex, constants.USER_ACTION_DATE_RANGE_CONDITION];
}

export function activityDatetimeConditionsReducer(state, action) {
  switch (action.type) {

    case constants.UPDATE_DATE_RANGE_DATE: {
      let conditionPath = [constants.TEMP_DATETIME_GLOBAL_CONDITIONS, constants.DATES_RANGE_CONDITION];
      if (state.hasIn([constants.DATA, constants.CASES]) && (action.conditionParent === constants.CASES)) {
        conditionPath = [constants.DATA, constants.CASES, action.caseIndex, constants.CONDITIONS, constants.CONDITIONS_LIST, action.conditionIndex];
      } else if (action.conditionParent === constants.FILTERS_USER_ACTION) {
        conditionPath = getFilterUserActionDateRangeConditionPath(action.userActionIndex);
      }
      const condition = state.getIn(conditionPath);
      const updatedCondition = utils.updateDateRangeConditionDate(condition, action.isStart, action.dateObject, action.allowInfinite);
      return state.setIn(conditionPath, updatedCondition);
    }

    case constants.ADD_DAYS_TIMES_CONDITION: {
      const pathPrefix = state.hasIn([constants.DATA, constants.CASES]) && (action.conditionParent === constants.CASES)
        ? [constants.DATA, constants.CASES, action.caseIndex, constants.CONDITIONS, constants.CONDITIONS_LIST, action.conditionIndex]
        : [constants.TEMP_DATETIME_GLOBAL_CONDITIONS, constants.DAYS_TIMES_CONDITION];
      return state
        .setIn([...pathPrefix, constants.CONDITION_VALUE],
          fromJS([...state.getIn([...pathPrefix, constants.CONDITION_VALUE]), fromJS(defaults.defaultDaysTimesConditionValue)]));
    }

    case constants.DELETE_DAYS_TIMES_CONDITION: {
      const pathPrefix = state.hasIn([constants.DATA, constants.CASES]) && (action.conditionParent === constants.CASES)
        ? [constants.DATA, constants.CASES, action.caseIndex, constants.CONDITIONS, constants.CONDITIONS_LIST, action.conditionIndex]
        : [constants.TEMP_DATETIME_GLOBAL_CONDITIONS, constants.DAYS_TIMES_CONDITION];
      const conditionsList = state.getIn([...pathPrefix, constants.CONDITION_VALUE]);
      const updatedConditionsList = utils.removeDateTimeConditionFromList(conditionsList, action.index);
      return state.setIn([...pathPrefix, constants.CONDITION_VALUE], updatedConditionsList);
    }

    case constants.UPDATE_DAYS_TIMES_CONDITION: {
      const pathPrefix = state.hasIn([constants.DATA, constants.CASES]) && (action.conditionParent === constants.CASES)
        ? [constants.DATA, constants.CASES, action.caseIndex, constants.CONDITIONS, constants.CONDITIONS_LIST, action.conditionIndex]
        : [constants.TEMP_DATETIME_GLOBAL_CONDITIONS, constants.DAYS_TIMES_CONDITION];
      const conditionToUpdate = state.getIn([...pathPrefix, constants.CONDITION_VALUE, action.index]);
      const { timeFrom, timeTo, daysActive } = action.daysTimes.data;
      const updatedCondition = utils.fixDaysTimesConditionTimeFormat(
        conditionToUpdate, action.isMilitaryTime, timeFrom, timeTo, daysActive, action.businessTimezone);
      return state.setIn([...pathPrefix, constants.CONDITION_VALUE, action.index], updatedCondition);
    }

    default:
      return state;
  }
}
