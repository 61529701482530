import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Icon, Input, Menu } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import tracker from '../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../common/utils/tracking/tracking.consts';
import * as constants from '../gifts.constants';
import FilterSettings from '../../../common/components/FilterSettings/FilterSettings';
import * as activityConstants from "../../Activity/activity.constants";

class GiftsPageToolbar extends React.PureComponent {

  static propTypes = {
    filters: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    isFiltered: PropTypes.bool,
    onFilterUpdate: PropTypes.func.isRequired,
    onSortUpdate: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    searchTerm: PropTypes.string,
    sort: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      [constants.SEARCH_TERM]: props[constants.SEARCH_TERM]
    };
    this.onSearchInvocationTimeout = null;
  }

  onSearch = (e, data) => {
    this.setState({ [constants.SEARCH_TERM]: data.value });
    // we don't want the UI to hang while the user is still typing,
    // so we wait 250ms after each keystroke before calling the onSearch callback:
    // step 1 - clear previous timeout set by previous keystroke:
    clearTimeout(this.onSearchInvocationTimeout);
    // step 2 - wait 250ms before calling onSearch function:
    this.onSearchInvocationTimeout = setTimeout(() => this.props.onSearch(data.value), 250);
  };

  onSearchIconClick = () => {
    if (this.searchInputRef) {
      this.searchInputRef.focus(); // focuses on the search box input
      this.searchInputRef.inputRef.select(); // selects all text inside the search box input
    }
  };

  render() {
    const { filters, onFilterUpdate, onSortUpdate, isFiltered } = this.props;
    const sortIcon = this.props.sort === constants.ORDER_DESCENDING ? 'como-ic-down-arrow' : 'como-ic-arrow-up';
    const { formatMessage } = this.props.intl;

    return (
      <Grid columns="equal" className="gifts-page-toolbar">
        <Grid.Row className="gifts-page-toolbar-header">
          <Grid.Column>
            <Header className="gifts-header">{formatMessage({ id: 'gifts-page.gifts' })}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="gifts-page-toolbar">
          <Grid.Column>
            <Menu secondary>
              <FilterSettings
                messagesNamespace="gifts"
                filters={filters}
                onFilterUpdate={onFilterUpdate}
                isFiltered={isFiltered}
                filterKeysToExclude={[activityConstants.ACTIVITY_STATUS_COMPLETED]}
              />
              <span>&nbsp;|&nbsp;</span>
              <Menu.Item
                className="sort"
                onClick={tracker.wrapButtonAction(onSortUpdate, trackerConstants.BUTTON_TYPE_CAMPAIGNS_SORT)}
                data-automation-id="gifts-page.sort-sets"
              >
                {formatMessage({ id: 'gifts-page.last_created' })}
              </Menu.Item>
              <Icon className={`${sortIcon} sort-icon`} />
              <span>&nbsp;|</span>
              <Menu.Item className={`search ${this.props.searchTerm ? 'active' : ''}`}>
                <Icon className="como-ic-search" onClick={this.onSearchIconClick} />
                <Input
                  className="search-input"
                  placeholder={formatMessage({ id: 'gifts.search' })}
                  value={this.state[constants.SEARCH_TERM]}
                  onChange={this.onSearch}
                  ref={(node) => { this.searchInputRef = node; }}
                  data-automation-id="gifts-page.search-box"
                />
              </Menu.Item>
            </Menu>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default injectIntl(GiftsPageToolbar);
