import moment from 'moment';
import 'moment-timezone';
import 'moment/min/locales.min';

import * as constants from './dateTime.constants';

/**
 * update locale
 */
export function updateLocale(intl, currentLocale) {

  const { formatMessage } = intl;
  // texts are subject to the chosen language and not to browser locale
  // so keys are passed here
  moment.updateLocale(currentLocale, {
    weekdaysMin: getWeekdaysMin(intl),
    months: [
      formatMessage({ id: 'datePicker.months.Jan' }),
      formatMessage({ id: 'datePicker.months.Feb' }),
      formatMessage({ id: 'datePicker.months.Mar' }),
      formatMessage({ id: 'datePicker.months.Apr' }),
      formatMessage({ id: 'datePicker.months.May' }),
      formatMessage({ id: 'datePicker.months.Jun' }),
      formatMessage({ id: 'datePicker.months.Jul' }),
      formatMessage({ id: 'datePicker.months.Aug' }),
      formatMessage({ id: 'datePicker.months.Sep' }),
      formatMessage({ id: 'datePicker.months.Oct' }),
      formatMessage({ id: 'datePicker.months.Nov' }),
      formatMessage({ id: 'datePicker.months.Dec' })
    ]
  });
}

/**
 * weekdays should be:
 *  1. ordered according to the localeData (start Sun or Mon)
 *  2. converted from ISO week (1-7) to moment locale week (0-6)
 *  3. indexes mapped to names
 * @param ids
 * @param intl
 * @param locale
 * @returns {*}
 */
export function getSelectedDaysAsString(ids, intl, locale) {
  if (!ids) {
    return null;
  }
  const daysObjects = [];
  ids.forEach((weekId) => {
    daysObjects.push(getWeekDayObject(weekId));
  });
  // check locale
  const localeFirstDow = moment.localeData(locale).firstDayOfWeek();
  if (localeFirstDow === 0) {
    const first = daysObjects.find((obj) => obj.id === 7);
    // eslint-disable-next-line no-nested-ternary
    daysObjects.sort((x, y) => x === first ? -1 : (y === first ? 1 : 0));
  }
  const days = daysObjects.map((obj) => obj.name);
  return days.join(', ');
}

export function getWeekDaysTimesAsString(timeRange, localeTimeFormat) {
  const timeFrom = moment(timeRange.get(constants.TIME_FROM), constants.HH_MM_SS_FORMAT).format(localeTimeFormat);
  const timeTo = moment(timeRange.get(constants.TIME_TO), constants.HH_MM_SS_FORMAT).format(localeTimeFormat);
  return `${timeFrom} - ${timeTo}`;
}

/**
 *
 * @returns {Array}
 */
export function prepareWeekDays(intl, locale) {
  updateLocale(intl, locale);
  const weekDays = moment.weekdaysMin();
  const fixesWeekDays = [];
  // iso week starts from 1(Mon) to 7(Sun)
  for (let index = 0; index < weekDays.length; index++) {
    fixesWeekDays.push(getWeekDayObject(index));
  }
  return fixesWeekDays;
}

/**
 * update date with new time
 * @param dateObject
 * @param timeData
 */
export function updateDateWithNewTime(dateObject, timeData) {
  const timeDataFormatted = getTimeDataIn24HourFormat(timeData);
  return dateObject.set({ [constants.HOUR]: timeDataFormatted.hour, [constants.MINUTE]: timeDataFormatted.minute });
}

/**
 * receives timeData(hour, minute,format)
 * converts am/pm time to 24-hours time
 * @param timeData
 * @returns {*}
 */
function getTimeDataIn24HourFormat(timeData) {
  if (!timeData.format) {
    return timeData;
  }
  const formattedDateArr = moment(`${timeData.hour}:${timeData.minute} ${timeData.format}`, constants.AM_FORMAT).format(constants.HH_MM_FORMAT).split(':');
  return {
    [constants.HOUR]: formattedDateArr[0],
    [constants.MINUTE]: formattedDateArr[1]
  };
}

function getWeekdaysMin(intl) {
  const { formatMessage } = intl;
  return [
    formatMessage({ id: 'datePicker.weekdayMin.Sun' }),
    formatMessage({ id: 'datePicker.weekdayMin.Mon' }),
    formatMessage({ id: 'datePicker.weekdayMin.Tue' }),
    formatMessage({ id: 'datePicker.weekdayMin.Wed' }),
    formatMessage({ id: 'datePicker.weekdayMin.Thu' }),
    formatMessage({ id: 'datePicker.weekdayMin.Fri' }),
    formatMessage({ id: 'datePicker.weekdayMin.Sat' })
  ];
}

function getWeekDayObject(index) {
  const weekDays = moment.weekdaysMin();
  const isoId = index === 0 ? 7 : index;
  const localeWeekId = index === 7 ? 0 : index;
  return {
    id: isoId,
    name: weekDays[localeWeekId]
  };
}

/**
 * prepare hour to show in correct format (24 or 12)
 * @param isMilitaryTime
 * @param dateObject
 * @returns {*}
 */
export function getHourByFormat(isMilitaryTime, dateObject) {
  let hour = null;
  if (dateObject) {
    hour = isMilitaryTime ? parseInt(dateObject.format(constants.HOURS_FORMAT_24), 10) : parseInt(dateObject.format(constants.HOURS_FORMAT_12), 10);
  }
  return hour;
}

/**
 * format minute
 * @param dateObject
 * @returns {number}
 */
export function getMinute(dateObject) {
  let minute = null;
  if (dateObject) {
    minute = dateObject.format(constants.MINUTES_FORMAT);
  }
  return parseInt(minute, 10);
}

export function getTimeFormat(timeObject, is12HourFormat) {
  let format = null;
  if (!is12HourFormat) {
    format = timeObject && timeObject.format(constants.AM_FORMAT).indexOf(constants.AM_TEXT) === -1 ? constants.PM_TEXT_LOWERCASE : constants.AM_TEXT_LOWERCASE;
  }
  return format;
}

function addLeadingZeros(timeNumber) {
  const formattedNumber = timeNumber < 10 ? `0${timeNumber}` : timeNumber;
  return formattedNumber;
}

export function representTimeFromTo(timeFrom, timeTo) {
  const timeFromFormat = timeFrom.format ? ` ${timeFrom.format.toUpperCase()}` : '';
  const timeToFormat = timeTo.format ? ` ${timeTo.format.toUpperCase()}` : '';
  return `${addLeadingZeros(timeFrom.hour)}:${addLeadingZeros(timeFrom.minute)}${timeFromFormat} - ${addLeadingZeros(timeTo.hour)}:${addLeadingZeros(timeTo.minute)}${timeToFormat}`;
}

/**
 * days hours are saved in business time zone, no need to convert them to utc and back
 * @param timeString
 * @param isMilitaryTime
 * @param businessTimezone
 * @returns {{[p: string]: }}
 */
export function buildTimeObject(timeString, isMilitaryTime, businessTimezone, shouldConvertToBusinessTimezone = true) {
  let timeObject = {};
  if (shouldConvertToBusinessTimezone) {
    timeObject = moment.utc(timeString, constants.HH_MM_SS_FORMAT).tz(businessTimezone);
  } else {
    timeObject = moment.tz(timeString, constants.HH_MM_SS_FORMAT, businessTimezone);
  }
  const hourFormat = isMilitaryTime ? constants.HOURS_FORMAT_24 : constants.HOURS_FORMAT_12;
  return {
    [constants.HOUR]: !isNaN(parseInt(timeObject.format(hourFormat), 10)) ? parseInt(timeObject.format(hourFormat), 10) : 0,
    [constants.MINUTE]: !isNaN(parseInt(timeObject.format(constants.MINUTES_FORMAT), 10)) ? parseInt(timeObject.format(constants.MINUTES_FORMAT), 10) : 0,
    [constants.FORMAT]: isMilitaryTime ? null : timeObject.format(constants.AM_TIME_LOWERCASE)
  };
}

/**
 * sorts the given array of immutable objects by date
 * @param arr the immutable array
 * @param key the key of the date field inside each object
 * @param orderFactor the order of the sort, 1 = ascending, -1 = descending
 * @returns {*}
 */
export function sortArrayByDate(arr, key, orderFactor) {
  return arr.sort((item1, item2) => {
    const item1time = moment(item1.getIn(key));
    const item2time = moment(item2.getIn(key));
    return item1time.diff(item2time) * orderFactor;
  });
}

export function getMaxDate(dates) {
  const moments = dates.map((d) => moment.utc(d));
  return moment.max(moments);
}

export function changeDateTimeFormat(dateTimeString, newFormat) {
  return moment(dateTimeString).format(newFormat);
}
