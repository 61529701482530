/**
*
* CaseOrderControls
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

class CaseOrderControls extends React.Component {

  static propTypes = {
    automationIdPrefix: PropTypes.string.isRequired,
    caseIndex: PropTypes.number.isRequired,
    casesCount: PropTypes.number.isRequired,
    intl: PropTypes.object.isRequired,
    updateCaseOrder: PropTypes.func.isRequired,
  };

  moveCaseUp = () => {
    if (this.props.caseIndex !== 0) {
      this.props.updateCaseOrder(this.props.caseIndex, this.props.caseIndex - 1);
    }
  };

  moveCaseDown = () => {
    if (this.props.caseIndex < this.props.casesCount - 1) {
      this.props.updateCaseOrder(this.props.caseIndex, this.props.caseIndex + 1);
    }
  };

  render() {

    const { formatMessage } = this.props.intl;
    const orderUpClasses = classNames('como-ic-angle-up', {
      disabled: this.props.caseIndex === 0
    });
    const orderDownClasses = classNames('como-ic-angle-down', {
      disabled: this.props.caseIndex === this.props.casesCount - 1
    });

    return (
      <div className="case-order-controls">
        <Icon
          className={orderUpClasses}
          onClick={this.moveCaseUp}
          size="big"
          data-automation-id={`${this.props.automationIdPrefix}.order.up`}
          title={this.props.caseIndex === 0 ? null : formatMessage({ id: 'activity.cases.moveUp.tooltip' })}
        />
        <Icon
          className={orderDownClasses}
          onClick={this.moveCaseDown}
          size="big"
          data-automation-id={`${this.props.automationIdPrefix}.order.down`}
          title={this.props.caseIndex === this.props.casesCount - 1 ? null : formatMessage({ id: 'activity.cases.moveDown.tooltip' })}
        />
      </div>
    );
  }
}

export default injectIntl(CaseOrderControls);
