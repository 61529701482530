import { createSelector } from 'reselect';
import { fromJS, List, Map } from 'immutable';
import { arrayOperators } from './activitySchema.operators';
import { datesRange } from './activitySchema.conditions';
import activitiesSchema from './activitySchema';
import * as constants from '../activity.constants';
import { getBusinessWallet, getTranslations } from '../../App/selectors';
import * as appConstants from '../../App/app.constants';
import { getRegistrationFormFields } from '../../Forms/forms.selectors';
import * as schemaConstants from './activitySchema.constants';
import * as reducerConstants from '../../../constants/reducer.constants';

/**
 * returns the current activities schema
 */
export const getSchema = createSelector([], () => fromJS(activitiesSchema));

export const getDeprecatedTriggers = createSelector([getSchema], getDeprecatedTriggersImpl);

export const getDateRangeCondition = createSelector([], () => fromJS(datesRange));

export const getListContainingDateRangeCondition = createSelector([], () => fromJS([datesRange]));

const getGlobalMembershipConditionsFromSchema = createSelector(
  [getSchema, (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.TRIGGER]) ? state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.TRIGGER]) : state.getIn([constants.FILTER_MEMBERS_TRIGGER, constants.TRIGGER])],
  getGlobalMembershipConditionByTrigger);


export function getGlobalMembershipConditionByTrigger(schema, trigger) {
  if (trigger === schemaConstants.TRIGGER_ONE_TIME_ACTION) {
    return schema.getIn([constants.ONE_TIME_CONDITIONS, constants.MEMBERSHIP_GLOBAL_CONDITIONS]);
  }
  return schema.getIn([constants.GLOBAL_CONDITIONS, constants.MEMBERSHIP_GLOBAL_CONDITIONS, trigger]);
}

const getUpdateMembershipConditionsFromSchema = createSelector(getSchema,
  (schema) => schema.get(constants.UPDATE_MEMBERSHIP_CONDITIONS));

/**
 * returns a ready-to-use (filtered and translated) update membership conditions list
 */
export const getTransformedUpdateMembershipConditions = createSelector(
  [getUpdateMembershipConditionsFromSchema, getTranslations, getRegistrationFormFields, getBusinessWallet],
  getTransformedUpdateMembershipConditionsImpl);

/**
 * returns a ready-to-use (filtered and translated) global membership conditions list
 */
export const getTransformedGlobalMembershipConditions = createSelector(
  [getGlobalMembershipConditionsFromSchema, getTranslations, getRegistrationFormFields, getBusinessWallet],
  getTransformedGlobalMembershipConditionsImpl);


function createConditionFeaturesList(condition, featuresResult) {
  let featuresList = List();
  const features = condition.get(constants.FEATURES);

  if (features) {
    features.forEach((featurePointer, featurePointerIndex) => {
      const feature = featuresResult.find((feat) => feat.get(constants.FEATURE_NAME) === featurePointer.get(constants.FEATURE_NAME));

      if (feature) {
        const newMap = Map().set(featurePointerIndex, featurePointer.set(constants.FIELD_LEVEL_KEY, feature.get(constants.FIELD_LEVEL_KEY)));
        featuresList = featuresList.push(newMap);
      }
    });
  }
  return featuresList;
}

/**
 * returns schema enriched with features metadata
 * @returns {*}
 */
export function getSchemaWithFeaturesImpl(schema, featuresResult, triggerResult, businessWallet) {
  let result = schema;

  // filter triggers according to business wallet
  result = result.set(constants.TRIGGERS, filterTriggerByBusinessWallet(schema.get(constants.TRIGGERS), businessWallet));

  // filter user action filters according to business wallet
  result = result.set(constants.USER_ACTION_FILTERS, filterTriggerByBusinessWallet(schema.get(constants.USER_ACTION_FILTERS), businessWallet));

  if (featuresResult) {
    result = result.set(constants.TRIGGERS, filterByHideOnFeatureDeniedParam(result.get(constants.TRIGGERS), featuresResult));
  }

  if (schema && featuresResult && triggerResult) {

    schema.getIn([constants.ACTIONS_PER_TRIGGERS, triggerResult]).forEach((action, triggersIndex) => {
      action.get(constants.ACTION_FIELDS).forEach((field, actionIndex) => {

        const features = field.get(constants.FEATURES);

        if (features) {
          features.forEach((featurePointer, featurePointerIndex) => {
            const feature = featuresResult.find((feat) => feat.get(constants.FEATURE_NAME) === featurePointer.get(constants.FEATURE_NAME));

            if (feature) {
              const newMap = featurePointer.set(constants.FIELD_LEVEL_KEY, feature.get(constants.FIELD_LEVEL_KEY));
              result = result.setIn([constants.ACTIONS_PER_TRIGGERS, triggerResult, triggersIndex, constants.ACTION_FIELDS, actionIndex, constants.FEATURES, featurePointerIndex], newMap);
            }
          });

        }
      });
    });
    schema.getIn([constants.CONDITIONS_PER_TRIGGERS, triggerResult]).forEach((condition, conditionIndex) => {
      const featuresList = createConditionFeaturesList(condition, featuresResult);
      featuresList.forEach((featureObjVal, featureObjIndex) => {
        result = result.setIn([constants.CONDITIONS_PER_TRIGGERS, triggerResult, conditionIndex, constants.FEATURES, featureObjIndex],
          featureObjVal.get(featureObjIndex));
      });
    });

    if (triggerResult === schemaConstants.TRIGGER_ONE_TIME_ACTION) {
      schema.get(constants.CONDITIONS_PER_USER_ACTION).forEach((value, userActionKey) => {
        const conditions = schema.getIn([constants.CONDITIONS_PER_USER_ACTION, userActionKey, schemaConstants.CONDITIONS_PER_ACTION]);
        if (conditions !== undefined) {
          conditions.forEach((condition, conditionIndex) => {
            const featuresList = createConditionFeaturesList(condition, featuresResult);
            featuresList.forEach((featureObjVal, featureObjIndex) => {
              result = result.setIn([constants.CONDITIONS_PER_USER_ACTION, userActionKey, schemaConstants.CONDITIONS_PER_ACTION,
                conditionIndex, constants.FEATURES, featureObjIndex], featureObjVal.get(featureObjIndex));
            });
          });
        }
      });
    }
  }

  return result;
}

export function getPunchCommunicationTriggersImpl(schema) {
  return schema.get(constants.PUNCH_COMMUNICATION_TRIGGERS);
}

export function getPunchCommunicationActionsImpl(schema) {
  return schema
    .getIn([constants.PUNCH_COMMUNICATION_ACTIONS, constants.ACTION_FIELDS])
    .map((actionField) => actionField.get(constants.KEY));
}

export function getActionsForSelectedTriggerImpl(schema, trigger, features, businessBundlePlan, IsComoEmployee) {

  if (trigger === constants.TEMPLATE_ONE_TIME_ACTION_CATEGORY) {
    return filteredActionsList(schema.getIn([constants.ACTIONS_PER_TRIGGERS, trigger]), features, businessBundlePlan === appConstants.TRIAL && !IsComoEmployee);
  }
  return schema.getIn([constants.ACTIONS_PER_TRIGGERS, trigger]);
}

export function getActionsForMicroCampaignImpl(schema) {
  return schema.getIn([constants.ACTIONS_PER_TRIGGERS, schemaConstants.TRIGGER_MICRO_CAMPAIGN]);
}


function filterConditionsByBusinessWallet(conditionsList, businessWallet) {
  let conditionsToFilter = null;
  switch (businessWallet) {
    case constants.ADD_POINTS_WALLET_POINTS:
      conditionsToFilter = [schemaConstants.CONTEXT_MEMBERSHIP_BUDGETWEIGHTED, schemaConstants.CONTEXT_MEMBERSHIP_ACCUMULATED_BUDGET_WEIGHTED];
      break;
    case constants.ADD_POINTS_WALLET_BUDGET:
      conditionsToFilter = [schemaConstants.CONTEXT_MEMBERSHIP_POINTS, schemaConstants.CONTEXT_MEMBERSHIP_ACCUMULATED_POINTS];
      break;
    default:
      return conditionsList;
  }
  return conditionsList.filter(
    (condition) => !conditionsToFilter.includes(condition.get(constants.KEY)));
}

export function filterMembershipConditionsByRegFormFields(conditionsFromRegFormFields, registrationFormFields) {
  const filteredConditionsFromRegFormFields = conditionsFromRegFormFields.filter((condition) => findFormFieldMatchingCondition(registrationFormFields, condition) !== undefined).map((condition) => {
    if (condition.get(constants.KEY) === schemaConstants.CONTEXT_MEMBERSHIP_BIRTHDAY) {
      return conditionsFromRegFormFields.find((formField) => formField.get(constants.KEY) === schemaConstants.CONTEXT_MEMBERSHIP_BIRTHDAY_MONTH);
    }
    if (condition.get(constants.KEY) === schemaConstants.CONTEXT_MEMBERSHIP_ANNIVERSARY) {
      return conditionsFromRegFormFields.find((formField) => formField.get(constants.KEY) === schemaConstants.CONTEXT_MEMBERSHIP_ANNIVERSARY_MONTH);
    }
    const foundFormField = findFormFieldMatchingCondition(registrationFormFields, condition);
    const options = JSON.parse(foundFormField.get(appConstants.REGISTRATION_FORM_FIELD_OPTIONS));
    let newCondition = condition;
    if (options && options.length) {
      newCondition = newCondition.set(constants.SCHEMA_CONDITION_OPERATORS, fromJS(arrayOperators));
      newCondition = newCondition.set(schemaConstants.OPTIONS, options).set(constants.VALUE_TYPE, schemaConstants.VALUE_TYPE_DYNAMIC);
    }
    if (foundFormField) {
      newCondition = newCondition.set('text', foundFormField.get(appConstants.REGISTRATION_FORM_FIELD_TITLE));
    }
    return newCondition;
  });
  const birthdayFilteredConditionsFromRegFormFields = conditionsFromRegFormFields.filter((condition) => findFormFieldMatchingCondition(registrationFormFields, condition) !== undefined);
  if (birthdayFilteredConditionsFromRegFormFields.find((condition) => condition.get(constants.KEY) === schemaConstants.CONTEXT_MEMBERSHIP_BIRTHDAY)) {
    return filteredConditionsFromRegFormFields.concat(conditionsFromRegFormFields.filter((formField) => formField.get(constants.KEY) === schemaConstants.CONTEXT_MEMBERSHIP_BIRTHDAY_MONTH_AND_DAY));
  }
  return filteredConditionsFromRegFormFields;
}

function findFormFieldMatchingCondition(registrationFormFields, condition) {
  const conditionKey = condition.get(constants.GLOBAL_CONDITION_KEY).split(constants.MEMBERSHIP_GLOBAL_CONDITION_PREFIX)[1];
  return registrationFormFields.find((formField) => formField.get(appConstants.REGISTRATION_FORM_FIELD_NAME) === conditionKey);
}

/**
 * Filter out conditions (except for must-show) that are not part of the registration form fields and by business wallet
 * @param conditionsMap
 * @param translations
 * @param registrationFormFields
 * @param businessWallet
 * @param regFormFieldsKey - the key in the schema object
 * @returns {*} filtered results merged into a single list
 */
function computeConditionsList(conditionsMap, translations, registrationFormFields, businessWallet, regFormFieldsKey) {
  // Filter out membership conditions (except for must-show) that are not part of the registration form fields
  let transformedMembershipConditions = conditionsMap
    .set(regFormFieldsKey, filterMembershipConditionsByRegFormFields(conditionsMap.get(regFormFieldsKey), registrationFormFields));
  // Merge all conditions to one array
  transformedMembershipConditions = transformedMembershipConditions
    .reduce((accumulator, values) => accumulator.concat(values), List());
  // filter conditions according to the business wallet
  return filterConditionsByBusinessWallet(transformedMembershipConditions, businessWallet);
}

function filterTriggerByBusinessWallet(triggers, businessWallet) {
  let triggersToFilter = null;
  switch (businessWallet) {
    case constants.ADD_POINTS_WALLET_POINTS:
      triggersToFilter = [schemaConstants.TRIGGER_USED_CREDIT,
        schemaConstants.TRIGGER_RECEIVED_CREDIT,
        schemaConstants.TRIGGER_PAYS_WITH_CREDIT_AT_POS];
      break;
    case constants.ADD_POINTS_WALLET_BUDGET:
      triggersToFilter = [schemaConstants.TRIGGER_USED_POINTS,
        schemaConstants.TRIGGER_RECEIVED_POINTS,
        schemaConstants.TRIGGER_PAYS_WITH_POINTS_AT_POS];
      break;
    default:
      return triggers;
  }

  return triggers.map((map) =>
    map.set(constants.VALUES,
      map.get(constants.VALUES).filter((v) => triggersToFilter.indexOf(v.get(constants.NAME)) === -1))
  );
}

function getTransformedGlobalMembershipConditionsImpl(globalMembershipConditions, translations, registrationFormFields, businessWallet) {
  if (translations && globalMembershipConditions && registrationFormFields) {
    return computeConditionsList(globalMembershipConditions, translations, registrationFormFields, businessWallet, constants.CONDITIONS_MEMBERSHIP_BY_REG_FORM_CATEGORY);
  }
  return null;
}

function getTransformedUpdateMembershipConditionsImpl(updateMembershipConditions, translations, registrationFormFields, businessWallet) {
  if (translations && updateMembershipConditions && registrationFormFields) {
    // Filter out membership conditions (except for must-show) that are not part of the registration form fields
    const transformedUpdateMembershipConditions =
      computeConditionsList(updateMembershipConditions, translations, registrationFormFields, businessWallet,
        constants.CONDITIONS_ALL_MEMBERSHIP_BY_REG_FORM_CATEGORY);
    return adjustConditionKeyToUpdateConditionNamingConvention(transformedUpdateMembershipConditions);
  }
  return null;
}

function getDeprecatedTriggersImpl(schema) {
  const triggersGroups = schema.get(constants.TRIGGERS);
  let deprecatedTriggers = new List();
  triggersGroups.forEach((triggerGroup) => {
    deprecatedTriggers = deprecatedTriggers.push(...triggerGroup.get(constants.VALUES).filter((trigger) => trigger.get(constants.DEPRECATED)));
  });
  return deprecatedTriggers;
}

function adjustConditionKeyToUpdateConditionNamingConvention(transformedUpdateMembershipConditions) {
  return transformedUpdateMembershipConditions.map((c) => {
    const key = c.get(constants.KEY);
    const updateMembershipKey = key.replace(schemaConstants.UPDATE_MEMBERSHIP_MEMBERSHIP, schemaConstants.UPDATE_MEMBERSHIP_UPDATED_FIELDS);
    return c.set(constants.KEY, updateMembershipKey);
  });
}

function filteredActionsList(actionsGroups, features, isBasicPlan) {

  let filteredActionGroups = List();
  actionsGroups.forEach((group) => {
    let actionGroup = group;
    const indexesToDelete = [];
    group.get(constants.ACTION_FIELDS).forEach((action, actionIndex) => {
      if (action.get(constants.FEATURES)) {
        action.get(constants.FEATURES).forEach((feature) => {
          if (shouldRemoveTrigger(feature.get(constants.NAME), action.get(schemaConstants.HIDE_ON_FEATURE_DENINED), features)) {
            indexesToDelete.push(actionIndex);
          }
        });
      }
    });
    // if plan is basic keep only basic actions
    actionGroup = actionGroup.set(constants.ACTION_FIELDS, actionGroup.get(constants.ACTION_FIELDS).filter((actionElem, actionElemIndex) => !indexesToDelete.includes(actionElemIndex) && isBasicPlan ? actionElem.get(constants.BASIC_ACTION) : true));
    // remove empty groups
    if (actionGroup.get(constants.ACTION_FIELDS).size > 0) {
      filteredActionGroups = filteredActionGroups.push(actionGroup);
    }
  });
  return filteredActionGroups;
}

/**
 * filter triggers inside groups by hideOnFeatureDenied param (from schema)
 * @param triggerGroups
 * @param businessFeatures
 */
function filterByHideOnFeatureDeniedParam(triggerGroups, businessFeatures) {
  let filteredTriggerGroups = List();
  triggerGroups.forEach((group) => {
    let triggerGroup = group;
    const indexesToDelete = [];
    group.get(constants.VALUES).forEach((trigger, triggerIndex) => {
      if (trigger.get(constants.FEATURES)) {
        trigger.get(constants.FEATURES).forEach((feature) => {
          if (shouldRemoveTrigger(feature.get(constants.NAME), trigger.get(schemaConstants.HIDE_ON_FEATURE_DENINED), businessFeatures)) {
            indexesToDelete.push(triggerIndex);
          }
        });
      }
    });
    triggerGroup = triggerGroup.set(constants.VALUES, triggerGroup.get(constants.VALUES).filter((triggerElem, triggerElemIndex) => !indexesToDelete.includes(triggerElemIndex)));
    filteredTriggerGroups = filteredTriggerGroups.push(triggerGroup);
  });
  return filteredTriggerGroups;
}

/**
 * if trigger has feature with hideOnFeatureDenied it should be removed from triggerList
 * @param featureName
 * @param hideOnFeatureDenied
 * @param businessFeatures
 * @returns {boolean}
 */
function shouldRemoveTrigger(featureName, hideOnFeatureDenied, businessFeatures) {
  if (!hideOnFeatureDenied) {
    return false;
  }
  if (businessFeatures.find((businessFeature) => businessFeature.get('name') === featureName && businessFeature.get('level') === 'None')) {
    return true;
  }
  return false;
}

export const punchCardRequiredPunches = createSelector([], () => activitiesSchema[schemaConstants.PUNCH_CARD_REQUIRED_PUNCHES_OPTIONS]);

