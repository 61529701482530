import React from 'react';
import PropTypes from 'prop-types';

class LazyLoadModule extends React.Component {

  static propTypes = {
    navigation: PropTypes.object.isRequired,
    resolve: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      module: null
    };
  }

  async componentDidMount() {
    const { resolve } = this.props;
    const { default: module } = await resolve();
    this.setState({ module });
  }

  render() {
    const { module } = this.state;
    if (!module) return null;
    if (module.view) return React.createElement(module.view, { ...this.props });
  }
}

export default LazyLoadModule;
