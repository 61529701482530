import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip/Tooltip';

import './DisabledItem.styles.less';

class DisabledItem extends Component {
  static propTypes = {
    children: PropTypes.node,
    tooltipMessage: PropTypes.string,
    withUnAvailableIcon: PropTypes.bool,
    automationId: PropTypes.string,
    icon: PropTypes.string
  };

  static defaultProps = {
    withUnAvailableIcon: true
  };

  render() {
    const { children, tooltipMessage, withUnAvailableIcon, automationId } = this.props;
    return (
      <div
        className="como-tooltip-disabled"
      >
        <Tooltip
          data-automation-id={`disabled-tooltip.${automationId}`}
          content={tooltipMessage}
          position="right center"
          wide
          trigger={
            <div
              className={'como-tooltip-disabled-trigger'}
              data-automation-id={`disabled-tooltip.${automationId}.trigger`}
            >
              {children}
              { withUnAvailableIcon &&
                <Icon
                  className={`${this.props.icon || ''} como-ic-unavailable como-ic-unavailable-tooltip-trigger`}
                  data-automation-id="disabled-icon"
                />
              }
            </div>
        }
        />
      </div>
    );
  }
}
export default DisabledItem;
