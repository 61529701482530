import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import * as constants from '../../../activity.constants';
import DropdownWrapper from '../../../../../common/components/DropdownWrapper/DropdownWrapper';
import CheckboxWrapper from '../../../../../common/components/FormInputFields/CheckboxWrapper/CheckboxWrapper';
import PopupFooter from '../../../../../common/components/popupFooter';
import ValidationErrorMessage from '../../../../../common/components/ValidationErrorMessage/ValidationErrorMessage';

class ApplyOnBundleLines extends React.PureComponent {

  static propTypes = {
    validationErrors: PropTypes.object,
    automationIdPrefix: PropTypes.string.isRequired,
    onApply: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    bundle: PropTypes.object,
    updateTempApplyOnModelField: PropTypes.func.isRequired,
    changeBundleItemsModeInTempModel: PropTypes.func.isRequired,
    tempApplyOnModel: PropTypes.object,
    disabledMode: PropTypes.string,
  };

  static buildQuantityOptions(limitTo) {
    const options = [];
    for (let i = 1; i <= limitTo; i++) {
      const optionObj = {
        [constants.KEY]: `quantity_${i}`,
        [constants.VALUE]: i,
        [constants.TEXT]: i
      };
      options.push(optionObj);
    }
    return options;
  }

  componentDidMount() {
    setTimeout(() => { this.scrollArea.scrollIntoView({ behavior: 'smooth' }); });
  }

  checkInputStatus(index) {
    const inputEnabled = this.props.tempApplyOnModel.get(constants.BUNDLE_ITEMS_MODE) === constants.BUNDLE_ITEMS_MODE_SPECIFIC
      && (this.props.tempApplyOnModel.getIn([constants.APPLY_ON, index, constants.CONDITION_SELECTED]) || this.props.bundle.getIn([constants.VALUE, constants.CONDITIONS_LIST]).size === 1);
    return !inputEnabled;
  }

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const bundleLines = this.props.bundle.getIn([constants.VALUE, constants.CONDITIONS_LIST]);
    if (!this.props.tempApplyOnModel) {
      return null;
    }
    return (
      <div className="apply-on-wrap" id="applyOnScrollable" >
        { this.props.validationErrors && this.props.validationErrors.get(constants.BUNDLE_ITEMS_MODE)
          && (
          <ValidationErrorMessage
            className="bold"
            errorMessage={formatMessage({ id: 'deals.validations.errors.bundle.items.mode.required' })}
            dataAutomationId={`${this.props.automationIdPrefix}.error.message`}
          />
        )
        }
        <Radio
          label={`${formatMessage({ id: 'deals.entireBundle' })}`}
          name="bundleItemsPopulation"
          value={constants.BUNDLE_ITEMS_MODE_ENTIRE}
          checked={this.props.tempApplyOnModel.get(constants.BUNDLE_ITEMS_MODE) === constants.BUNDLE_ITEMS_MODE_ENTIRE}
          onChange={() => { this.props.changeBundleItemsModeInTempModel(constants.BUNDLE_ITEMS_MODE_ENTIRE); }}
          data-automation-id={`${this.props.automationIdPrefix}.button.entireBundle`}
          disabled={this.props.disabledMode === constants.BUNDLE_ITEMS_MODE_ENTIRE}
          className={this.props.validationErrors && this.props.validationErrors.get(constants.BUNDLE_ITEMS_MODE) ? 'error' : ''}
        />
        <hr />
        <Radio
          label={`${formatMessage({ id: 'deals.specificItems' })}`}
          name="bundleItemsPopulation"
          value={constants.BUNDLE_ITEMS_MODE_SPECIFIC}
          checked={this.props.tempApplyOnModel.get(constants.BUNDLE_ITEMS_MODE) === constants.BUNDLE_ITEMS_MODE_SPECIFIC}
          onChange={() => { this.props.changeBundleItemsModeInTempModel(constants.BUNDLE_ITEMS_MODE_SPECIFIC); }}
          data-automation-id={`${this.props.automationIdPrefix}.button.specificItems`}
          disabled={this.props.disabledMode === constants.BUNDLE_ITEMS_MODE_SPECIFIC}
          className={this.props.validationErrors && this.props.validationErrors.get(constants.BUNDLE_ITEMS_MODE) ? 'error' : ''}
        />
        <div
          className={`items-list applyOn-items-list ${this.props.validationErrors && this.props.validationErrors.get(constants.APPLY_ON) ? 'error' : ''}`}
          ref={(ref) => { this.scrollArea = ref; }}
        >
          { bundleLines.map((bundleLine, index) => {
            const limitTo = bundleLine.get(constants.CONDITION_KEY) === constants.QUANTITY ? bundleLine.get(constants.CONDITION_VALUE) : 10;
            const options = ApplyOnBundleLines.buildQuantityOptions(limitTo);
            const inputDisabled = this.checkInputStatus(index);
            const pointToBundleText = bundleLines.size > 1 ? `${formatMessage({ id: 'deals.applyOn.line' })} ${String.fromCharCode(65 + index)}` : formatMessage({ id: 'deals.applyOn.the.above.items' });
            return (
              <div key={bundleLine.get(constants.CONDITION_ID)} className="applyOn-row">
                {bundleLines.size > 1 &&
                <CheckboxWrapper
                  name={constants.CONDITION_SELECTED}
                  checked={this.props.tempApplyOnModel.getIn([constants.APPLY_ON, index, constants.CONDITION_SELECTED])}
                  data-automation-id={`${this.props.automationIdPrefix}.${index}.checkbox`}
                  onChange={(event, elem) => {
                    this.props.updateTempApplyOnModelField(constants.CONDITION_SELECTED, elem.checked, index);
                  }}
                  label=""
                  disabled={this.props.tempApplyOnModel.get(constants.BUNDLE_ITEMS_MODE) !== constants.BUNDLE_ITEMS_MODE_SPECIFIC}
                />
              }
                <DropdownWrapper
                  className={`short-dropdown ${this.props.tempApplyOnModel.get(constants.BUNDLE_ITEMS_MODE) === constants.BUNDLE_ITEMS_MODE_ENTIRE ? 'disabled' : ''}`}
                  options={options}
                  onSelectOption={(event, elem) => { this.props.updateTempApplyOnModelField(constants.QUANTITY, elem.value, index); }}
                  value={this.props.tempApplyOnModel.getIn([constants.APPLY_ON, index, constants.QUANTITY])}
                  index={index}
                  prefix={`${this.props.automationIdPrefix}.${index}.quantity`}
                  disabled={inputDisabled}
                />
                <span className={`inside-sentence${this.props.tempApplyOnModel.get(constants.BUNDLE_ITEMS_MODE) !== constants.BUNDLE_ITEMS_MODE_SPECIFIC ? ' disabled' : ''}`}>of</span>
                <span className={`${bundleLines.size > 1 ? 'dashed-and-italic' : ''}${this.props.tempApplyOnModel.get(constants.BUNDLE_ITEMS_MODE) !== constants.BUNDLE_ITEMS_MODE_SPECIFIC ? ' disabled' : ''}`}>
                  {pointToBundleText}
                </span>
              </div>
            );
          }, this)
              }
          { this.props.validationErrors && this.props.validationErrors.get(constants.APPLY_ON) ? (
            <ValidationErrorMessage
              errorMessage={formatMessage({ id: this.props.validationErrors.get(constants.APPLY_ON) })}
              dataAutomationId={`${this.props.automationIdPrefix}.error.message`}
            />
          ) : null }
        </div>
        <PopupFooter
          automationIdPrefix={this.props.automationIdPrefix}
          onApply={this.props.onApply}
          onCancel={this.props.onCancel}
        />
      </div>
    );
  }
}

export default injectIntl(ApplyOnBundleLines);
