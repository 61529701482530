/*
 *
 * MicroCampaigns actions
 *
 */

import * as constants from './microCampaigns.constants';

export function getMicroCampaigns() {
  return {
    type: constants.GET_MICRO_CAMPAIGNS,
  };
}

export function goToMicroCampaigns() {
  return {
    type: constants.GO_TO_MICRO_CAMPAIGNS,
  };
}

export function onStatusChange(microCampaign, isActive) {
  return {
    type: constants.UPDATE_MICRO_CAMPAIGN_STATUS,
    microCampaign,
    isActive
  };
}

export function goToMicroCampaign(microCampaignId) {
  return {
    type: constants.GO_TO_MICRO_CAMPAIGN,
    hubId: microCampaignId
  };
}

export function cleanMicroCampaigns() {
  return {
    type: constants.CLEAN_MICRO_CAMPAIGNS
  };
}

export function getMicroCampaignStats(id, locationId) {
  return {
    type: constants.GET_MICRO_CAMPAIGN_STATS,
    id,
    locationId
  };
}

export function toggleCollapsed(index) {
  return {
    type: constants.TOGGLE_COLLAPSED_STATE,
    index
  };
}
