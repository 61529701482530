import React from 'react';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import * as selectors from '../selectors';

const ResponseContent = (props) => {
  const { intl, textRegenerate, sendForm, isLoading, setSelectedItemIndex, setIsCopied, onTextUpdate, prompts = {}, selectedItemIndex, isCopied, onReturnBack, close } = props;

  const { formatMessage } = intl;

  const promptsData = prompts.toJS() || {};
  const promptsList = promptsData.data ? promptsData.data : [];

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
    setIsCopied(false);
  };

  const handleCopyClick = () => {
    if (selectedItemIndex !== null && promptsList[selectedItemIndex]) {
      const selectedContent = promptsList[selectedItemIndex];

      navigator.clipboard.writeText(selectedContent)
        .then(() => setIsCopied(true))
        .catch((error) => console.error('Copy failed:', error));
      onTextUpdate(selectedContent);

      close();
    }
  };

  const finalList = (promptsList != null && promptsList.filter((x) => x !== '')) || [];

  return (
    <div className="tw-min-h-[530px] tw-h-[530px] tw-p-2 tw-rounded-lg tw-shadow-lg tw-ring-1 tw-flex tw-flex-col tw-justify-between tw-items-center tw-ring-black tw-ring-opacity-5">
      <div className={'tw-text-left tw-items-center tw-my-2 tw-mt-4 tw-flex tw-flex-row tw-justify-between tw-w-full tw-items-baseline tw-italic'}>
        {formatMessage({ id: 'vertex.ai.insert-select-response' })}
        <div onClick={sendForm} className={'text-italic text-sm tw-cursor-pointer'}>
          <ArrowPathIcon className={`tw-w-6 fill-none tw-fill-none ${isLoading ? 'tw-animate-spin' : ''}`} />
        </div>
      </div>
      <div className={'tw-overflow-y-auto tw-min-h-[350px] tw-h-[400px]'}>
        {!textRegenerate && finalList.length > 0 ? (finalList.map((item, index) => (
          <div
            key={index}
            className={'tw-my-3 tw-bg-white tw-p-2 tw-rounded-lg tw-shadow-lg tw-flex tw-flex-col tw-justify-center tw-items-center'}
            onClick={() => handleItemClick(index)}
            style={selectedItemIndex === index ? { border: '2px solid #03B9E4' } : {}}
          >
            {item || ''}
          </div>
        ))) : (
          <div className={'tw-h-[350px] tw-min-h-[350px] tw-text-center tw-mt-4 tw-text-lg tw-italic'}>
            {formatMessage({ id: !isLoading ? 'location.bulk-upload.no-data' : 'vertex.ai.insert-wait-please' })}
          </div>
        )}
      </div>

      <div className={'tw-h-12 tw-w-full'}>
        {selectedItemIndex !== null && (
        <button
          className="ai-submit-btn tw-mt-2 tw-p-2 tw-text-white tw-h-12 tw-rounded-md tw-w-full tw-rounded"
          onClick={() => handleCopyClick({ close })}
        >
          {formatMessage({ id: isCopied ? 'vertex.ai.inserted' : 'vertex.ai.insert-selected-prompt' })}
        </button>
      )}
      </div>
      <div onClick={onReturnBack} className={'tw-text-xs tw-underline tw-my-4 tw-bg-primary tw-cursor-pointer'}>
        {formatMessage({ id: 'vertex.ai.return-to-prompt' })}
      </div>
    </div>
  );
};

ResponseContent.prototype = {
  intl: PropTypes.object,
  prompts: PropTypes.object,
  onReturnBack: PropTypes.func
};

function mapStateToProps(state) {
  return {
    prompts: selectors.getPrompts(state)
  };
}

const WithRouter = withRouter(connect(mapStateToProps, {})(ResponseContent));

export default injectIntl(WithRouter);
