import React from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';

class PointShopPageToolbar extends React.PureComponent {
  render() {
    const { formatMessage } = this.props.intl;

    return (
      <Grid columns="equal" className="point-shop-page-toolbar">
        <Grid.Row className="point-shop-page-toolbar-header">
          <Grid.Column>
            <Header className="point-shop-header">{formatMessage({ id: 'point-shop-page.title' })}</Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default injectIntl(PointShopPageToolbar);
