import axios from 'axios';

export function getItemGroups() {
  return axios.get('/api/itemGroups')
  .then((res) => res.data);
}

export function createItemsGroup(itemsGroup) {
  return axios.post('/api/itemGroups/', itemsGroup)
  .then((res) => res.data);
}

export function updateItemsGroup(itemsGroup) {
  return axios.put('/api/itemGroups/', itemsGroup)
  .then((res) => res.data);
}

export function deactivateItemGroups(id) {
  return axios.delete(`/api/itemGroups/${id}`)
  .then((res) => res.data);
}
