/*
 *
 * Items Population constants
 *
 */

// ACTIONS CONSTANTS

export const ITEMS_POPULATION_COPY_DATA_TO_TEMP_ITEMS_POPULATION = 'app/activity/itemsPopulation/COPY_DATA_TO_TEMP_ITEMS_POPULATION';
export const ITEMS_POPULATION_COPY_DATA_TO_TEMP_ITEMS_GROUP = 'app/activity/itemsPopulation/COPY_DATA_TO_TEMP_ITEMS_GROUP';
export const ITEMS_POPULATION_UPDATE_TYPE = 'app/activity/itemsPopulation/UPDATE_TYPE';
export const ITEMS_POPULATION_CLEAR_ALL_IN_TYPE = 'app/activity/itemsPopulation/CLEAR_ALL_IN_TYPE';
export const ITEMS_POPULATION_UPDATE_CONDITION = 'app/activity/itemsPopulation/UPDATE_CONDITION';
export const ITEMS_POPULATION_UPDATE_WHOLE_CONDITION = 'app/activity/itemsPopulation/ITEMS_POPULATION_UPDATE_WHOLE_CONDITION';
export const ITEMS_POPULATION_UPDATE_CONDITIONS_RELATIONS = 'app/activity/itemsPopulation/UPDATE_CONDITIONS_RELATIONS';
export const ITEMS_POPULATION_ADD_CONDITION = 'app/activity/itemsPopulation/ADD_CONDITION';
export const ITEMS_POPULATION_DELETE_CONDITION = 'app/activity/itemsPopulation/DELETE_CONDITION';
export const ITEMS_POPULATION_CLEAR_DATA = 'app/activity/itemsPopulation/CLEAR_DATA';
export const ITEMS_POPULATION_UPDATE_GROUP_NAME = 'app/activity/itemsPopulation/UPDATE_GROUP_NAME';

// STORE CONSTANTS

export const ITEMS_POPULATION = 'itemsPopulation';
export const TEMP_ITEMS_POPULATION = 'tempItemsPopulation';
export const TEMP_ITEMS_GROUP = 'tempItemsGroup';
export const INITIAL_GROUPS = 'initialGroups';
export const ITEMS_POPULATION_TYPE = 'type';
export const ITEMS_POPULATION_TYPE_ANY = 'any';
export const ITEMS_POPULATION_TYPE_ITEM_CODES = 'itemCodes';
export const ITEMS_POPULATION_TYPE_DEPARTMENT_CODES = 'departmentCodes';
export const ITEMS_POPULATION_TYPE_ITEM_GROUPS = 'itemsGroup';
export const ITEMS_POPULATION_TYPE_ADVANCED_RULES = 'advancedRules';
export const ITEMS_POPULATION_TYPE_PUNCH_CARD_DEFAULT_DISCOUNT = 'punchCardDefaultDiscountItems';

export const IS_POPUP_OPEN = 'isPopupOpen';
export const IS_PRISTINE = 'isPristine';
export const TOGGLE_ANIMATION = 'toggleAnimation';
export const STATE_VALUE = 'stateValue';
