import moment from 'moment';
import 'moment-timezone';
import { fromJS } from 'immutable';
import * as membershipChangedConstants from '../views/conditions/updateMembershipCondition/updateMembership.constants';
import * as schemaConstants from '../activitySchema/activitySchema.constants';
import * as constants from '../activity.constants';
import * as itemsPopulationConstants from '../views/itemsPopulation/itemsPopulation.constants';
import * as itemGroupsConstants from '../../ItemGroups/itemGroups.constants';
import * as genericActionConstants from '../../GenericAction/genericAction.contants';
import * as dateConstants from '../../../common/utils/dateTime.constants';
import { getCurrentDateTime, getDefaultGiftDateTo } from './utils/activity.datetime.reducer.utils';
import * as actionDefaults from './activity.action.defaults';
import * as conditionDefaults from './activity.condition.defaults';

export const emptyItemsPopulation = {
  [constants.TYPE]: null,
  [constants.CONDITIONS]: {
    [constants.CONDITION_LIST_OPERATOR]: constants.CONDITION_OR,
    [constants.CONDITIONS_LIST]: []
  }
};

export const defaultBundleLine = {
  [constants.CONDITION_ID]: null,
  [constants.CONDITION_KEY]: constants.QUANTITY,
  [constants.OPERATOR_KEY]: constants.EQUALS_OPERATOR,
  [constants.CONDITION_VALUE]: 1,
  [constants.CONDITION_VALUE_DATA_TYPE]: schemaConstants.DATA_VALUE_TYPE_INTEGER,
  [constants.ITEMS_POPULATION]: emptyItemsPopulation
};

export const defaultBundle = {
  [constants.VALUE]: {
    [constants.CONDITION_LIST_OPERATOR]: constants.CONDITION_AND,
    [constants.CONDITIONS_LIST]: [defaultBundleLine]
  }
};

export const emptyAction = actionDefaults.emptyAction;

export const emptyActionsWrapper = {
  [constants.ACTIONS_LIST]: [emptyAction],
  [constants.ACTIONS_MODE]: constants.ACTIONS_SIMPLE_MODE
};

export const defaultActionsWrapperBenefit = {
  [constants.ACTIONS_LIST]: [emptyAction],
  [constants.ACTIONS_MODE]: constants.SIMPLE_ENTIRE_TICKET_DISCOUNT
};

export const emptyActionsWrapperWithBundle = {
  [constants.BUNDLE]: defaultBundle,
  [constants.ACTIONS_LIST]: [emptyAction],
  [constants.ACTIONS_MODE]: constants.ACTIONS_OCCURRENCES_MODE
};

export const emptyCondition = conditionDefaults.emptyCondition;

export const defaultABTestCondition = conditionDefaults.defaultABTestCondition;

export const emptyConditions = {
  [constants.CONDITION_LIST_OPERATOR]: constants.CONDITION_AND,
  [constants.CONDITIONS_LIST]: []
};

export const emptyItemsPopulationConditions = {
  [constants.CONDITION_LIST_OPERATOR]: constants.CONDITION_OR,
  [constants.CONDITIONS_LIST]: []
};

export const emptyItemsPopulationImmutable = fromJS(emptyItemsPopulation);

export const emptyTempItemsPopulation = {
  [itemsPopulationConstants.ITEMS_POPULATION]: {
    [itemsPopulationConstants.ITEMS_POPULATION_TYPE_ANY]: {
      [itemsPopulationConstants.ITEMS_POPULATION_TYPE]: itemsPopulationConstants.ITEMS_POPULATION_TYPE_ANY,
      [constants.CONDITIONS]: emptyConditions
    },
    [itemsPopulationConstants.ITEMS_POPULATION_TYPE_ITEM_CODES]: {
      [itemsPopulationConstants.ITEMS_POPULATION_TYPE]: itemsPopulationConstants.ITEMS_POPULATION_TYPE_ITEM_CODES,
      [constants.CONDITIONS]: emptyConditions
    },
    [itemsPopulationConstants.ITEMS_POPULATION_TYPE_DEPARTMENT_CODES]: {
      [itemsPopulationConstants.ITEMS_POPULATION_TYPE]: itemsPopulationConstants.ITEMS_POPULATION_TYPE_DEPARTMENT_CODES,
      [constants.CONDITIONS]: emptyConditions
    },
    [itemsPopulationConstants.ITEMS_POPULATION_TYPE_ITEM_GROUPS]: {
      [itemsPopulationConstants.ITEMS_POPULATION_TYPE]: itemsPopulationConstants.ITEMS_POPULATION_TYPE_ITEM_GROUPS,
      [constants.CONDITIONS]: emptyItemsPopulationConditions
    },
    [itemsPopulationConstants.ITEMS_POPULATION_TYPE_ADVANCED_RULES]: {
      [itemsPopulationConstants.ITEMS_POPULATION_TYPE]: itemsPopulationConstants.ITEMS_POPULATION_TYPE_ADVANCED_RULES,
      [constants.CONDITIONS]: emptyConditions
    },
  },
  [itemsPopulationConstants.ITEMS_POPULATION_TYPE]: itemsPopulationConstants.ITEMS_POPULATION_TYPE_ITEM_CODES,
  [itemsPopulationConstants.INITIAL_GROUPS]: [],
};

export const emptyTempItemsGroup = {
  [itemGroupsConstants.NAME]: '',
  [itemsPopulationConstants.ITEMS_POPULATION_TYPE]: itemsPopulationConstants.ITEMS_POPULATION_TYPE_ITEM_CODES,
  [itemsPopulationConstants.ITEMS_POPULATION]: {
    [itemsPopulationConstants.ITEMS_POPULATION_TYPE_ITEM_CODES]: {
      [itemsPopulationConstants.ITEMS_POPULATION_TYPE]: itemsPopulationConstants.ITEMS_POPULATION_TYPE_ITEM_CODES,
      [constants.CONDITIONS]: emptyConditions
    },
    [itemsPopulationConstants.ITEMS_POPULATION_TYPE_DEPARTMENT_CODES]: {
      [itemsPopulationConstants.ITEMS_POPULATION_TYPE]: itemsPopulationConstants.ITEMS_POPULATION_TYPE_DEPARTMENT_CODES,
      [constants.CONDITIONS]: emptyConditions
    },
    [itemsPopulationConstants.ITEMS_POPULATION_TYPE_ADVANCED_RULES]: {
      [itemsPopulationConstants.ITEMS_POPULATION_TYPE]: itemsPopulationConstants.ITEMS_POPULATION_TYPE_ADVANCED_RULES,
      [constants.CONDITIONS]: emptyConditions
    },
  }
};

export const defaultMembershipStatusCondition = {
  [constants.CONDITION_KEY]: schemaConstants.CONTEXT_MEMBERSHIP_STATUS,
  [constants.OPERATOR_KEY]: constants.IS_ONE_OF_CONDITION_OPERATOR,
  [constants.CONDITION_VALUE_DATA_TYPE]: schemaConstants.DATA_VALUE_TYPE_STRING_ARRAY,
  [constants.CONDITION_VALUE]: [schemaConstants.MEMBERSHIP_STATUS_VALUE_REGISTERED]
};

export const defaultMembershipImportCopyPasteCondition = {
  [constants.CONDITION_KEY]: schemaConstants.CONTEXT_MEMBERSHIP_KEY_STR,
  [constants.OPERATOR_KEY]: constants.IS_ONE_OF_CONDITION_OPERATOR,
  [constants.CONDITION_VALUE_DATA_TYPE]: schemaConstants.DATA_VALUE_TYPE_STRING_ARRAY,
  [constants.CONDITION_VALUE]: []
};

export const defaultConditionKeyToConditionTypeMapping = {
  [schemaConstants.CONTEXT_MEMBERSHIP_KEY_STR]: constants.MEMBERS_IMPORT,
  [schemaConstants.CONTEXT_MEMBERSHIP_PHONE_NUMBER]: constants.MEMBERS_PHONENUMBER_IMPORT,
  [schemaConstants.MEMBERSHIP_KEYS_FILE]: constants.MEMBERS_IMPORT,
  [schemaConstants.MEMBERSHIP_PHONENUMBER_KEYS_FILE]: constants.MEMBERS_PHONENUMBER_IMPORT,
};

export const defaultMembershipImportUploadFileCondition = {
  [constants.CONDITION_KEY]: schemaConstants.MEMBERSHIP_KEYS_FILE,
  [constants.OPERATOR_KEY]: constants.EQUALS_OPERATOR,
  [constants.CONDITION_VALUE_DATA_TYPE]: schemaConstants.DATA_VALUE_TYPE_FILE_LINK,
  [constants.CONDITION_VALUE]: {
    [constants.UPLOADED_FILE_NAME]: null,
    [constants.UPLOADED_BUCKET_NAME]: null
  }
};

export const defaultMembershipImportConditionsWrapper = {
  [constants.IMPORT_MEMBERS_COPY_PASTE_MODE]: defaultMembershipImportCopyPasteCondition,
  [constants.IMPORT_MEMBERS_UPLOAD_FILE_MODE]: defaultMembershipImportUploadFileCondition,
  [constants.IMPORT_MEMBERS_MODE]: constants.IMPORT_MEMBERS_COPY_PASTE_MODE,
  [constants.CONDITION_KEY_TO_CONDITION_TYPE]: defaultConditionKeyToConditionTypeMapping,
  [constants.CONDITION_TYPE_TO_COPY_PASTE_VALUE]: {},
  [constants.CONDITION_TYPE_TO_IMPORT_FILE_VALUE]: {}
};

export const emptyGlobalMembershipConditionsConditions = {
  [constants.CONDITIONS_LIST]: [emptyCondition],
  [constants.CONDITION_LIST_OPERATOR]: constants.CONDITION_AND
};

export const defaultGlobalMembershipConditions = {
  [constants.TYPE]: constants.MEMBERS_REGISTERED,
  [constants.MEMBERSHIP_STATUS_CONDITION]: defaultMembershipStatusCondition
};

export const specificGlobalMembershipConditions = {
  [constants.TYPE]: constants.MEMBERS_SPECIFIC,
  [constants.MEMBERSHIP_STATUS_CONDITION]: defaultMembershipStatusCondition,
  [constants.CONDITIONS]: emptyGlobalMembershipConditionsConditions
};

export const registeredMembersGlobalMembershipCondition = {
  [constants.TYPE]: constants.MEMBERS_REGISTERED,
};

export const allCostumersGlobalMembershipCondition = {
  [constants.TYPE]: constants.ALL_COSTUMERS
};

export const defaultDaysTimesConditionValue = {
  [dateConstants.DAYS]: [1, 2, 3, 4, 5, 6, 7],
  [dateConstants.TIME_RANGE]: {
    [dateConstants.TIME_FROM]: dateConstants.DAYS_OBJECT_START_OF_DAY_TIME,
    [dateConstants.TIME_TO]: dateConstants.DAYS_OBJECT_END_OF_DAY_TIME
  }
};

export const defaultGlobalDateTimeConditionGift = {
  [constants.DATES_RANGE_CONDITION]: {
    [constants.CONDITION_KEY]: schemaConstants.CONTEXT_TIMESTAMP,
    [constants.OPERATOR_KEY]: dateConstants.DATE_TIME_IS_BETWEEN,
    [constants.CONDITION_VALUE]: {
      [dateConstants.DATE_FROM]: getCurrentDateTime(),
      [dateConstants.DATE_TO]: getDefaultGiftDateTo()
    },
    [constants.CONDITION_VALUE_DATA_TYPE]: schemaConstants.DATA_VALUE_TYPE_DATE_TIME_BETWEEN
  },
  [constants.DAYS_TIMES_CONDITION]: {
    [constants.CONDITION_KEY]: schemaConstants.CONTEXT_TIMESTAMP_DAYS,
    [constants.OPERATOR_KEY]: dateConstants.DAY_OF_WEEK,
    [constants.CONDITION_VALUE]: [defaultDaysTimesConditionValue],
    [constants.CONDITION_VALUE_DATA_TYPE]: schemaConstants.DATA_VALUE_TYPE_DAYS_OF_WEEK_RANGE_CONDITION
  }
};

export const instanceExpirationGlobalCondition = {
  [constants.ACTIVATION_CONDITION]: {
    [constants.ACTIVATION_MODE]: constants.ACTIVATION_MODE_DEFAULT,
    [constants.ACTIVATION_SPECIFIC_TIME_VALUE]: 1,
    [constants.ACTIVATION_SPECIFIC_TIME_UNIT]: dateConstants.DAY
  },
  [constants.DEACTIVATION_CONDITION]: {
    [constants.ACTIVATION_MODE]: constants.ACTIVATION_MODE_DEFAULT,
    [constants.ACTIVATION_SPECIFIC_TIME_VALUE]: 1,
    [constants.ACTIVATION_SPECIFIC_TIME_UNIT]: dateConstants.DAY
  }
};

export const defaultGlobalOccurrencesLimitCondition = {
  [constants.LIMIT_OCCURRENCES_PER_MEMBER_CONDITION_TYPE]: constants.INFINITE,
  [constants.BY_PERIOD_CONDITION_ENABLED]: false,
  [constants.BY_PERIOD_CONDITION]: null,
  [constants.TOTAL_OCCURRENCES_CONDITION_ENABLED]: false,
  [constants.TOTAL_OCCURRENCES_CONDITION]: null
};

export const emptyCase = {
  [constants.DESCRIPTION]: '',
  [constants.ACTIONS]: [emptyActionsWrapper],
  [constants.CONDITIONS]: emptyConditions,
  [constants.IS_COLLAPSED]: false,
};

export const defaultTotalOccurrencesConditionValue = {
  [constants.CONDITION_KEY]: constants.TOTAL_TAG_OCCURRENCES_CALC,
  [constants.OPERATOR_KEY]: schemaConstants.OPERATOR_KEY_LESS_THAN_VALUE,
  [constants.CONDITION_VALUE_DATA_TYPE]: schemaConstants.DATA_VALUE_TYPE_INTEGER,
  [constants.CONDITION_VALUE]: 1
};
export const defaultDealTotalOccurrencesConditionValue = {
  ...defaultTotalOccurrencesConditionValue,
  [constants.CONDITION_KEY]: constants.DEAL_OCCURRENCES_CALC,
};

export const defaultByPeriodLimitConditionValue = {
  [constants.CONDITION_KEY]: constants.TAG_OCCURRENCES_BY_PERIOD_CALC,
  [constants.OPERATOR_KEY]: schemaConstants.OPERATOR_KEY_LESS_THAN_VALUE,
  [constants.CONDITION_VALUE_DATA_TYPE]: schemaConstants.DATA_VALUE_TYPE_INTEGER,
  [constants.CONDITION_VALUE]: {
    [constants.BY_PERIOD_CONDITION_COUNT]: 1,
    [constants.BY_PERIOD_CONDITION_PERIOD]: dateConstants.WEEK
  }
};
export const defaultDealByPeriodLimitConditionValue = {
  ...defaultByPeriodLimitConditionValue,
  [constants.CONDITION_KEY]: constants.DEAL_OCCURRENCES_BY_PERIOD_CALC,
};

export const emptyActionScheduling = {
  [constants.TYPE]: constants.SCHEDULE_IMMEDIATE
};

export const defaultDelay = {
  [constants.DELAY_UNITS]: dateConstants.MINUTE,
  [constants.DELAY_NUMBER]: constants.DEFAULT_DELAY_NUMBER_FOR_PUNCH
};

export const emptyActivity = {
  [constants.ACTIONS]: [emptyActionsWrapper],
  [constants.CAMPAIGN_KEY]: '',
  [constants.CONDITIONS]: emptyConditions,
  [constants.CREATE_DATE]: '',
  [constants.DESCRIPTION]: '',
  [constants.GOAL_KEY]: '',
  [constants.HUB_ID]: '',
  [constants.LAST_UPDATE]: '',
  [constants.LOCATION_ID]: '',
  [constants.NAME]: '',
  [constants.PUBLISH_DATE]: '',
  [constants.SERVER_ID]: null,
  [constants.STATUS]: '',
  [constants.TEMPLATE_KEY]: '',
  [constants.TRIGGER]: schemaConstants.NO_TRIGGER,
  [constants.EXPORT_IN_PROGRESS]: false,
};

export const inAppMessageDefaults = {
  [constants.POPUP_MESSAGE]: '',
  [constants.MESSAGE_MAX_AGE_IN_DAYS]: 7,
  [constants.MESSAGE_TRIGGER_BY_OPENING_THE_APP]: true,
  [constants.MESSAGE_TRIGGER_BY_NAVIGATION_TO_HOME_SCREEN]: true,
  [constants.MESSAGE_TRIGGER_BY_AFTER_REDEEM]: true,
  [constants.MESSAGE_TRIGGER_BY_AFTER_PUNCH]: true,
  [constants.MESSAGE_TRIGGER_BY_AFTER_COUPON_CODE_ACTION]: true,
  [constants.SAVE_MESSAGE_TO_INBOX]: true,
  [constants.GENERIC_ACTION_ARGS]: {
    [genericActionConstants.BUTTON_ACTION_TYPE]: genericActionConstants.DO_NOTHING
  }
};

export const emptyUpdateMembershipConditionValue = {
  [membershipChangedConstants.UPDATE_MEMBERSHIP_FROM]: {
    [constants.OPERATOR_KEY]: schemaConstants.OPERATORS_IS_ANY_VALUE,
    [constants.SCHEMA_CONDITION_VALUE_DATA_TYPE]: schemaConstants.OPERATORS_IS_ANY_VALUE
  },
  [membershipChangedConstants.UPDATE_MEMBERSHIP_TO]: {
    [constants.OPERATOR_KEY]: schemaConstants.OPERATORS_IS_ANY_VALUE,
    [constants.SCHEMA_CONDITION_VALUE_DATA_TYPE]: schemaConstants.OPERATORS_IS_ANY_VALUE
  },
};

export const smsDefaults = {
  [constants.SMS_MESSAGE]: '',
  [constants.SEND_SMS_WITH_IMAGE]: false,
  [constants.MMS_IMAGE_URL]: null,
  [constants.MMS_IMAGE]: null,
};

export const pushMessageDefaults = {
  [constants.PUSH_MESSAGE]: '',
  [constants.PUSH_TITLE]: '',
  [constants.SAVE_MESSAGE_TO_INBOX]: true,
  [constants.SEND_PUSH_WITH_IMAGE]: false,
  [constants.PUSH_NOTIFICATION_IMAGE_URL]: null,
  [constants.PUSH_NOTIFICATION_IMAGE]: null,
  [constants.MESSAGE_MAX_AGE_IN_DAYS]: 7,
  [constants.EXTRA_DATA]: [],
  [constants.GENERIC_ACTION_ARGS]: {
    [genericActionConstants.BUTTON_ACTION_TYPE]: genericActionConstants.NAVIGATE_TO_TARGET_TC
  }
};

export const userActionEmptyConditions = {
  [constants.USER_ACTION_KEY]: '',
  [constants.CONDITIONS]: emptyConditions
};

export const userActionsEmptyFilters = {
  [constants.CONDITION_LIST_OPERATOR]: constants.CONDITION_AND,
  [constants.USER_ACTIONS_CONDITIONS_LIST]: [userActionEmptyConditions]
};

export const userActionAggregationEmptyConditionWrapper = {
  [constants.IS_USER_ACTION_AGGREGATED_CONDITIONS_ACTIVE]: false,
  [constants.CONDITION]: emptyCondition
};

export const initialState = fromJS({
  [constants.DATA]: emptyActivity,
  [constants.SCHEMA]: {
    [constants.TRIGGERS]: [],
    [constants.CONDITIONS_PER_TRIGGERS]: {},
    [constants.ACTIONS_PER_TRIGGERS]: {},
    [constants.MENTIONS_PER_TRIGGERS]: {},
    [constants.GLOBAL_CONDITIONS]: {}
  },
  [constants.ACTIVITY_DATA_LOADED]: false,
  [constants.PUBLISH_OR_SAVE_IN_PROGRESS]: false,
  [constants.SAVE_AS_DRAFT_IN_PROGRESS]: false,
  [constants.TEST_TO_MYSELF]: {
    [constants.TEST_TO_MYSELF_IN_PROGRESS]: false,
    [constants.TEST_TO_MYSELF_IN_PROGRESS_INDEX]: 0
  }
});

export const defaultBirthdayOrAnniversaryOrClubMemberAnniversaryGlobalOccurrencesLimitCondition = {
  [constants.LIMIT_OCCURRENCES_PER_MEMBER_CONDITION_TYPE]: constants.LIMITED_PER_MEMBER,
  [constants.BY_PERIOD_CONDITION_ENABLED]: true,
  [constants.BY_PERIOD_CONDITION]: {
    ...defaultByPeriodLimitConditionValue,
    [constants.CONDITION_VALUE]: {
      ...defaultByPeriodLimitConditionValue[constants.CONDITION_VALUE],
      [constants.BY_PERIOD_CONDITION_PERIOD]: dateConstants.YEAR
    }
  },
  [constants.TOTAL_OCCURRENCES_CONDITION_ENABLED]: false,
  [constants.TOTAL_OCCURRENCES_CONDITION]: null
};
export const timesLimit = {
  [constants.LIMIT_VALUE]: 1,
  [constants.LIMIT_ACTIVE]: true
};

export const defaultBirthdayOrAnniversarySchedule = (businessTimeZone) =>
  moment.tz(businessTimeZone).startOf('day').add(17, 'hours').utc().format(dateConstants.HH_MM_SS_FORMAT);
export const defaultClubMemberAnniversaryOneTimeObject = fromJS({
  [dateConstants.UNIT]: dateConstants.YEAR,
  [dateConstants.VALUE]: 1
});

export const defaultBirthdayOrAnniversaryEventScope = constants.EVENT_SCOPE_MONTH;
export const defaultClubMemberAnniversaryEventScope = constants.EVENT_SCOPE_PERIOD;

export const defaultReferralDataConditionValueImmutable = fromJS({
  [constants.CONDITION_VALUE_REFERRAL_DATA_TYPE]: null,
  [constants.CONDITION_VALUE_IS_REFERRED]: false,
  [constants.CONDITION_VALUE_REFERRAL_CODE]: []
});

export const initialPunchCardTab = constants.ACTIVITY_TAB_DEFINITION;
export const initialGiftTab = constants.ACTIVITY_TAB_DISCOUNT;
export const initialPromoCodeTab = constants.ACTIVITY_TAB_DISCOUNT;

export const punchCardActionWrapper = fromJS(emptyActionsWrapper)
  .set(constants.ACTIONS_LIST, fromJS([
    actionDefaults.discountFreeItemAction
      .setIn([constants.DISCOUNT_ON, constants.TYPE], itemsPopulationConstants.ITEMS_POPULATION_TYPE_PUNCH_CARD_DEFAULT_DISCOUNT)
  ]))
  .set(constants.ACTIONS_MODE, constants.SIMPLE_SPECIFIC_ITEMS_DISCOUNT);

export const requiredPunches = 9;

export function createPunchCardChildActivities(locationId) {
  return {
    [constants.PUNCH_CARD_RULE_PUNCH]: punchCardRelatedPunchRule(locationId),
    [constants.PUNCH_CARD_COMMUNICATION_RULES]: [],
    [constants.PUNCH_CARD_RULE_SEND_ON_EXPIRATION]: punchCardRelatedSendOnExpirationRule(locationId)
  };
}

export function punchCardChildActivityCommon(locationId) {
  return fromJS(emptyActivity)
    .set(constants.TYPE, constants.ACTIVITY_TYPE_RULE)
    .set(constants.LOCATION_ID, locationId)
    .set(constants.STATUS, constants.ACTIVITY_STATUS_ACTIVE);
}

function punchCardRelatedPunchRule(locationId) {
  return punchCardChildActivityCommon(locationId)
    .set(constants.TRIGGER, schemaConstants.TRIGGER_PURCHASE_ANALYZED)
    .set(constants.MEMBERSHIP_GLOBAL_CONDITIONS, fromJS(defaultGlobalMembershipConditions))
    .set(constants.DATETIME_GLOBAL_CONDITIONS, fromJS(defaultGlobalDateTimeConditionGift))
    .setIn([constants.ACTIONS, 0], fromJS(punchCardRelatedPunchRuleActions))
    .setIn([constants.ACTIONS, 0, constants.BUNDLE, constants.VALUE, constants.CONDITIONS_LIST, 0, constants.ITEMS_POPULATION, constants.TYPE], itemsPopulationConstants.ITEMS_POPULATION_TYPE_ITEM_CODES);
}

export function punchCardRelatedSendOnRedeemRule(locationId) {
  return punchCardChildActivityCommon(locationId)
    .set(constants.TRIGGER, schemaConstants.TRIGGER_REDEEM)
    .set(constants.CONDITIONS, fromJS(punchCardRelatedSendOnRedeemConditions))
    .setIn([constants.ACTIONS, 0], fromJS(punchCardRelatedRuleActionsWithAssignAsset));
}

export function punchCardRelatedSendOnLastPunchRule(locationId) {
  return punchCardChildActivityCommon(locationId)
    .set(constants.TRIGGER, schemaConstants.TRIGGER_PUNCH)
    .set(constants.CONDITIONS, fromJS(punchCardRelatedSendOnLastPunchConditions))
    .setIn([constants.ACTIONS, 0], fromJS(punchCardRelatedRuleActionsWithAssignAsset));
}

export function punchCardRelatedSendOnExpirationRule(locationId) {
  return punchCardChildActivityCommon(locationId)
    .set(constants.TRIGGER, schemaConstants.TRIGGER_PURCHASE_ANALYZED)
    .set(constants.CONDITIONS, fromJS(punchCardRelatedSendOnExpirationRuleConditions))
    .setIn([constants.ACTIONS, 0], fromJS(punchCardRelatedRuleActionsWithAssignAsset));
}

export function punchCardRelatedPunchOverflowRule(locationId) {
  return punchCardChildActivityCommon(locationId)
    .set(constants.TRIGGER, schemaConstants.TRIGGER_EXCEEDED_PUNCH)
    .set(constants.CONDITIONS, fromJS(punchCardRelatedPunchOverflowConditions))
    .setIn([constants.ACTIONS, 0], fromJS(punchCardRelatedPunchOverflowActions));
}

const punchCardRelatedSendOnExpirationRuleConditions = fromJS(emptyConditions)
  .set(constants.CONDITIONS_LIST, fromJS([
    conditionDefaults.shoppingCardContainsAtLeastOneCondition,
    conditionDefaults.noActiveFutureInProgressPunchCardCondition
  ]));

const punchCardRelatedSendOnRedeemConditions = fromJS(emptyConditions)
  .set(constants.CONDITIONS_LIST, fromJS([
    conditionDefaults.availablePunchCardCondition,
    conditionDefaults.assetTemplateCondition
  ]));

const punchCardRelatedSendOnLastPunchConditions = fromJS(emptyConditions)
  .set(constants.CONDITIONS_LIST, fromJS([
    conditionDefaults.availablePunchCardCondition,
    conditionDefaults.assetTemplateCondition,
    conditionDefaults.lastPunchCondition
  ]));

const punchCardRelatedPunchRuleActions = fromJS(emptyActionsWrapperWithBundle)
  .set(constants.ACTIONS_LIST, fromJS([
    actionDefaults.punchAction
  ]));

const punchCardRelatedRuleActionsWithAssignAsset = fromJS(emptyActionsWrapper)
  .set(constants.ACTIONS_LIST, fromJS([
    actionDefaults.assignAssetAction
  ]));

const punchCardRelatedPunchOverflowConditions = fromJS(emptyConditions)
  .set(constants.CONDITIONS_LIST, fromJS([
    conditionDefaults.assetTemplateCondition
  ]));

const punchCardRelatedPunchOverflowActions = fromJS(emptyActionsWrapper)
  .set(constants.ACTIONS_LIST, fromJS([
    actionDefaults.punchExceededAction
  ]));

export const punchCardCommunicationRuleConditions = {
  [schemaConstants.TRIGGER_PUNCH]: [conditionDefaults.assetTemplateCondition],
  [schemaConstants.TRIGGER_PUNCH_MIDDLE_PUNCH]: [conditionDefaults.assetTemplateCondition, conditionDefaults.punchNumberCondition],
  [schemaConstants.TRIGGER_PUNCH_LAST_PUNCH]: [conditionDefaults.assetTemplateCondition, conditionDefaults.lastPunchCondition],
  [schemaConstants.TRIGGER_RECEIVED_ASSET]: [conditionDefaults.assetTemplateCondition],
  [schemaConstants.TRIGGER_REDEEM]: [conditionDefaults.assetTemplateCondition],
};

// activity model defaults by type

export const ruleData = fromJS(emptyActivity)
  .set(constants.TYPE, constants.ACTIVITY_TYPE_RULE)
  .set(constants.ACTIVITY_TAGS, fromJS([]))
  .set(constants.MEMBERSHIP_GLOBAL_CONDITIONS, fromJS(defaultGlobalMembershipConditions));

export const dealData = fromJS(emptyActivity)
  .set(constants.TYPE, constants.ACTIVITY_TYPE_DEAL)
  .set(constants.TRIGGER, schemaConstants.TRIGGER_DEAL)
  .set(constants.ACTIONS, fromJS([defaultActionsWrapperBenefit]))
  .set(constants.ACTIVITY_TAGS, fromJS([]))
  .set(constants.MEMBERSHIP_GLOBAL_CONDITIONS, fromJS(defaultGlobalMembershipConditions));

export const oneTimeActionData = fromJS(emptyActivity)
  .set(constants.TYPE, constants.ACTIVITY_TYPE_ONE_TIME)
  .set(constants.CONFIRM_DELETE_ACTION_DIALOG_OPEN, false)
  .set(constants.BLOCK_SMS_ACTION_DIALOG_OPEN, false)
  .set(constants.RE_FILTER_DIALOG_OPEN, false)
  .set(constants.EXPORT_IN_PROGRESS, false)
  .set(constants.ACTIVITY_TAGS, fromJS([]))
  .setIn([constants.TRIGGER], schemaConstants.TRIGGER_ONE_TIME_ACTION)
  .setIn([constants.ONE_TIME_ACTION_SCHEDULING], fromJS(emptyActionScheduling))
  .setIn([constants.ONE_TIME_ACTION_FILTERED_POPULATION, constants.FILTERED_POPULATION_CONDITIONS], fromJS(defaultGlobalMembershipConditions));

export function createPunchCardData(locationId) {
  return fromJS(emptyActivity)
    .set(constants.TYPE, constants.ACTIVITY_TYPE_PUNCH_CARD)
    .set(constants.TRIGGER, schemaConstants.TRIGGER_PUNCH_CARD)
    .set(constants.ACTIONS, fromJS([punchCardActionWrapper]))
    .set(constants.MEMBERSHIP_GLOBAL_CONDITIONS, fromJS(defaultGlobalMembershipConditions))
    .set(constants.DATETIME_GLOBAL_CONDITIONS, fromJS(defaultGlobalDateTimeConditionGift))
    .set(constants.INSTANCE_EXPIRATION_GLOBAL_CONDITION, fromJS(instanceExpirationGlobalCondition))
    .set(constants.SHOW_REDEEM_CODE_IN_APP, false)
    .set(constants.REQUIRED_PUNCHES, requiredPunches)
    .set(constants.PUNCH_CARD_REWARD_MODE, constants.PUNCH_CARD_REWARD_MODE_SIMPLE)
    .set(constants.PUNCH_CARD_AUTO_RENEW, true)
    .set(constants.PUNCH_CARD_AUTO_RENEW_TRIGGER, constants.PUNCH_CARD_RENEW_ON_LAST_PUNCH)
    .set(constants.ACTIVITY_TAGS, fromJS([]))
    .set(constants.PUNCH_CARD_CHILD_ACTIVITIES, fromJS(createPunchCardChildActivities(locationId)));
}

export const bulkCodes = fromJS({
  [constants.PROMO_CODE_MODE_SINGLE_CODE]: [{
    [constants.PROMO_CODE_BULK_TYPE]: constants.PROMO_CODE_BULK_TYPE_SINGLE_CODE,
    [constants.PROMO_CODE_SINGLE_CODE_BULK_VALUE]: null,
    [constants.PROMO_CODE_BULK_IS_NEW]: true
  }],
  [constants.PROMO_CODE_MODE_FILE_UPLOADED]: [{
    [constants.PROMO_CODE_BULK_TYPE]: constants.PROMO_CODE_BULK_TYPE_FILE_UPLOADED,
    [constants.PROMO_CODE_FILE_UPLOADED_BULK_FILENAME]: null,
    [constants.PROMO_CODE_FILE_UPLOADED_BULK_BUCKETNAME]: null,
    [constants.PROMO_CODE_FILE_UPLOADED_BULK_MEDIALINK]: null,
    [constants.PROMO_CODE_BULK_IS_NEW]: true
  }]
});

export function promoCodeData() {
  const data = fromJS(emptyActivity)
    .set(constants.TYPE, constants.ACTIVITY_TYPE_PROMO_CODE)
    .set(constants.TRIGGER, schemaConstants.TRIGGER_PROMO_CODE)
    .set(constants.ACTIONS, fromJS([defaultActionsWrapperBenefit]))
    .set(constants.MEMBERSHIP_GLOBAL_CONDITIONS, fromJS(allCostumersGlobalMembershipCondition))
    .set(constants.DATETIME_GLOBAL_CONDITIONS, fromJS(defaultGlobalDateTimeConditionGift))
    .set(constants.PROMO_CODE_BULKS, bulkCodes)
    .setIn([constants.PROMO_CODE_BULKS, constants.PROMO_CODE_MODE], constants.PROMO_CODE_MODE_SINGLE_CODE);
  return data.setIn([constants.ACTIONS, 0, constants.ACTIONS_MODE], constants.SIMPLE_SPECIFIC_ITEMS_DISCOUNT);
}

export function giftData() {
  const data = fromJS(emptyActivity)
    .set(constants.TYPE, constants.ACTIVITY_TYPE_GIFT)
    .set(constants.TRIGGER, schemaConstants.TRIGGER_GIFT)
    .set(constants.ACTIONS, fromJS([defaultActionsWrapperBenefit]))
    .set(constants.MEMBERSHIP_GLOBAL_CONDITIONS, fromJS(defaultGlobalMembershipConditions))
    .set(constants.DATETIME_GLOBAL_CONDITIONS, fromJS(defaultGlobalDateTimeConditionGift))
    .set(constants.INSTANCE_EXPIRATION_GLOBAL_CONDITION, fromJS(instanceExpirationGlobalCondition))
    .set(constants.SHOW_REDEEM_CODE_IN_APP, fromJS(false))
    .set(constants.GIFT_INTERACTIVE_TYPE, fromJS(constants.GIFT_INTERACTIVE_TYPE_NONE));

  return data.setIn([constants.ACTIONS, 0, constants.ACTIONS_MODE], constants.SIMPLE_SPECIFIC_ITEMS_DISCOUNT);
}

export const comilliaHintActions = [schemaConstants.SEND_MEMBER_SMS_ACTION, schemaConstants.SEND_MEMBER_EMAIL_ACTION, schemaConstants.SEND_PERSONAL_PUSH_ACTION, schemaConstants.SEND_MESSAGE_ACTION];
