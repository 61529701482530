import { createSelector } from 'reselect';


import * as constants from './mailTemplates.constants';
import * as reducerConstants from '../../constants/reducer.constants';
import * as activityConstants from '../Activity/activity.constants';

export const getMailTemplates = (state) => state.getIn([reducerConstants.MAIL_TEMPLATES_BRANCH, constants.MAIL_TEMPLATES_MAP]);
export const getSelectedEmailFromList = (emails, selectedEmailId) => {
  if (emails) {
    return emails.find((email) => email.get(constants.EXTERNAL_ID) === selectedEmailId);
  }
  return null;
};
export const getSelectedEmail = createSelector(
  [getMailTemplates, (state, emailId) => emailId], getSelectedEmailFromList);

export const getNewlyCreatedEmailId = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, activityConstants.NEWLY_CREATED_EMAIL]);

export const getNewlyCreatedEmail = createSelector(
  [getMailTemplates, getNewlyCreatedEmailId], getSelectedEmailFromList);
