import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import CampaignToolbar from './CampaignToolbar';
import ActivitiesTable from './ActivitiesTable';
import * as constants from '../businessCenter.constants';
import * as activityConstants from '../../Activity/activity.constants';
import ConfirmationModal from '../../../common/components/CustomModal/ConfirmationModal/ConfirmationModal';

class Campaign extends React.PureComponent {

  static propTypes = {
    activityMoveTo: PropTypes.func.isRequired,
    campaign: PropTypes.object.isRequired,
    updateActivity: PropTypes.func.isRequired,
    createActivity: PropTypes.func.isRequired,
    deleteActivity: PropTypes.func.isRequired,
    deleteCampaign: PropTypes.func.isRequired,
    updateCampaign: PropTypes.func.isRequired,
    openActivityInNewTab: PropTypes.func.isRequired,
    focus: PropTypes.bool.isRequired,
    goToActivity: PropTypes.func.isRequired,
    handleOnBlur: PropTypes.func,
    highlightedActivityId: PropTypes.string,
    index: PropTypes.number.isRequired,
    locationId: PropTypes.number.isRequired,
    onUpdateCampaign: PropTypes.func.isRequired,
    localeDateFormat: PropTypes.string.isRequired,
    localeTimeFormat: PropTypes.string.isRequired,
    deprecatedTriggers: PropTypes.object,
    activityActivateError: PropTypes.func,
    businessTimezone: PropTypes.string,
    hasBasicPlanPermissions: PropTypes.bool,
    businessBundlePlan: PropTypes.string,
    updateActivityPin: PropTypes.func.isRequired,
    openToast: PropTypes.func,
  };

  state = {
    promptAutoUnarchiveCampaignModalOpen: false
  };

  handleChangeActivityStatus = (activity) => {
    if (activity.get(activityConstants.TYPE) === activityConstants.SERVER_TYPE_RULE
      && this.props.deprecatedTriggers.find((trigger) => trigger.get(activityConstants.NAME) === activity.get(activityConstants.TRIGGER))) {
      this.props.activityActivateError('activate.error.deprecated.trigger');
      return;
    }
    const newActivityStatus = activity.get(activityConstants.STATUS);
    const currentCampaignStatus = this.props.campaign.get(constants.CAMPAIGN_STATUS);
    if (newActivityStatus === activityConstants.ACTIVITY_STATUS_ACTIVE &&
        currentCampaignStatus !== constants.CAMPAIGN_STATUS_ACTIVE) {
      this.setState({ promptAutoUnarchiveCampaignModalOpen: true, activity });
    } else {
      this.props.updateActivity(activity);
    }
  };

  handleOnConfirmAutoUnarchive = () => {
    this.props.updateActivity(this.state.activity);
    this.setState({ activity: null, promptAutoUnarchiveCampaignModalOpen: false });
  };

  pinActivity = (activity) => {
    const activityId =  activity.get(activityConstants.DB_ID);
    const type = activity.get(activityConstants.TYPE);
    const campaignId = this.props.campaign.get(constants.ID);
    this.props.updateActivityPin(campaignId, activityId, type, new Date().toISOString());
  }

  unpinActivity = (activity) => {
    const activityId =  activity.get(activityConstants.DB_ID);
    const type = activity.get(activityConstants.TYPE);
    const campaignId = this.props.campaign.get(constants.ID);
    this.props.updateActivityPin(campaignId, activityId, type, null);
  }

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line
    const { focus, campaign, index, deleteActivity, onUpdateCampaign, activityMoveTo, businessTimezone, openActivityInNewTab } = this.props;
    const isFocusedClass = classNames('campaign', {
      'campaignFocused': focus,
      'listViewArchive': campaign.get(constants.CAMPAIGN_STATUS) === constants.CAMPAIGN_STATUS_ARCHIVE,
      'pinned': campaign.get(constants.CAMPAIGN_PINED_DATE)
    });

    const activities = campaign.get(constants.ACTIVITIES);
    const campaignId = campaign.get(constants.ID);
    return (
      <Segment
        className={isFocusedClass}
        loading={campaign.get(constants.ID) === -1}
      >
        { campaign.get(constants.CAMPAIGN_STATUS) === constants.CAMPAIGN_STATUS_ARCHIVE && (
          <i className="archivedCampaign como-svg-icon-archivedShape">
            <span className="archivedText">{formatMessage({ id: 'business-center.campaign.activity.status.archived' })}</span>
          </i>
          )}
        <ConfirmationModal
          open={this.state.promptAutoUnarchiveCampaignModalOpen}
          onConfirm={this.handleOnConfirmAutoUnarchive}
          onCancel={() => this.setState({ promptAutoUnarchiveCampaignModalOpen: false })}
        >
          {formatMessage({ id: 'business-center.campaign.archive.confirmation.content' })}
        </ConfirmationModal>
        <CampaignToolbar
          campaignIndex={index}
          campaign={campaign}
          locationId={this.props.locationId}
          createActivity={this.props.createActivity}
          deleteCampaign={this.props.deleteCampaign}
          focus={focus}
          handleOnBlur={this.props.handleOnBlur}
          onUpdateCampaign={onUpdateCampaign}
          hasBasicPlanPermissions={this.props.hasBasicPlanPermissions}
          businessBundlePlan={this.props.businessBundlePlan}
          updateCampaign={this.props.updateCampaign}
          openToast={this.props.openToast}
        />
        {activities && activities.size > 0 &&
          <ActivitiesTable
            localeDateFormat={this.props.localeDateFormat}
            localeTimeFormat={this.props.localeTimeFormat}
            activities={activities}
            activityMoveTo={activityMoveTo}
            campaignId={campaignId}
            campaignOrderIndex={index}
            changeActivityStatus={this.handleChangeActivityStatus}
            deleteActivity={deleteActivity}
            goToActivity={this.props.goToActivity}
            highlightedActivityId={this.props.highlightedActivityId}
            key={campaignId}
            businessTimezone={businessTimezone}
            openActivityInNewTab={openActivityInNewTab}
            pinActivity={this.pinActivity}
            unpinActivity={this.unpinActivity}
            openToast={this.props.openToast}
          />
        }
      </Segment>
    );
  }
}

export default injectIntl(Campaign);
