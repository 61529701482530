/**
 *
 * Business Wallets
 * Selection of the business wallets available for the business
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as constants from '../../../../../activity.constants';
import DropdownWrapper from '../../../../../../../common/components/DropdownWrapper/DropdownWrapper';
import './businessWallets.styles.less';
import { getBusinessWallet } from '../../../../../../App/selectors';

class BusinessWallets extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
    onActionFieldUpdate: PropTypes.func.isRequired,
    value: PropTypes.string,
    index: PropTypes.number,
    defaultWallet: PropTypes.string.isRequired,
    error: PropTypes.object,
    name: PropTypes.string
  };

  static defaultProps = {
    index: 0
  };

  componentWillMount() {
    const { defaultWallet, value, name, index, onActionFieldUpdate } = this.props;

    if (!value) {
      const updateWalletTo = defaultWallet === constants.ADD_POINTS_WALLET_BUDGET ?
        constants.ADD_POINTS_WALLET_BUDGET :
        constants.ADD_POINTS_WALLET_POINTS;
      onActionFieldUpdate(null, { name, value: updateWalletTo, index });
    }
  }

  getWalletsListByDefaultWallet() {
    const { intl, value, defaultWallet } = this.props;

    let configuration = defaultWallet;
    if (value && defaultWallet !== value) {
      configuration = constants.ADD_POINTS_WALLET_BOTH;
    }

    switch (configuration) {
      case constants.ADD_POINTS_WALLET_POINTS:
        return [
          {
            value: constants.ADD_POINTS_WALLET_POINTS,
            text: intl.formatMessage({ id: 'activity.action.addPoints.budgetType.points' })
          }];

      case constants.ADD_POINTS_WALLET_BUDGET:
        return [
          {
            value: constants.ADD_POINTS_WALLET_BUDGET,
            text: intl.formatMessage({ id: 'activity.action.addPoints.budgetType.budget' })
          }];

      case constants.ADD_POINTS_WALLET_BOTH:
      default:
        return [
          {
            value: constants.ADD_POINTS_WALLET_POINTS,
            text: intl.formatMessage({ id: 'activity.action.addPoints.budgetType.points' })
          },
          {
            value: constants.ADD_POINTS_WALLET_BUDGET,
            text: intl.formatMessage({ id: 'activity.action.addPoints.budgetType.budget' })
          }];
    }
  }

  render() {
    const { intl, value, onActionFieldUpdate, error, automationIdPrefix, name, index } = this.props;

    return (
      <DropdownWrapper
        options={this.getWalletsListByDefaultWallet()}
        index={index}
        onSelectOption={onActionFieldUpdate}
        name={name}
        value={value}
        prefix={automationIdPrefix}
        className="business-wallets-select"
        error={error}
        displayDropDownWithSingleOption={false}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  defaultWallet: getBusinessWallet(state) || constants.ADD_POINTS_WALLET_BOTH
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, injectIntl)(BusinessWallets);
