// ACTIONS_LIST CONSTANTS

export const GET_CAMPAIGNS = 'app/BusinessCenter/GET_CAMPAIGNS';
export const FETCH_CAMPAIGNS_WITH_ARCHIVE_ACTIVITIES = 'app/BusinessCenter/FETCH_CAMPAIGNS_WITH_ARCHIVE_ACTIVITIES';
export const GET_CAMPAIGNS_SUCCESS = 'app/BusinessCenter/GET_CAMPAIGNS_SUCCESS';
export const SET_CAMPAIGNS_LOADED = 'app/BusinessCenter/SET_CAMPAIGNS_LOADED';
export const OPEN_GOALS = 'app/BusinessCenter/OPEN_GOALS';
export const GO_TO_ACTIVITY = 'app/BusinessCenter/GO_TO_ACTIVITY';

export const UPDATE_FILTERS = 'app/BusinessCenter/UPDATE_FILTERS';
export const UPDATE_TRIGGER_FILTERS = 'app/BusinessCenter/UPDATE_TRIGGER_FILTERS';
export const UPDATE_TAGS_FILTERS = 'app/BusinessCenter/UPDATE_TAGS_FILTERS';
export const UPDATE_IS_ACTION_FILTER_UPDATED = 'app/BusinessCenter/UPDATE_IS_ACTION_FILTER_UPDATED';
export const UPDATE_RULE_FILTER_MODE = 'app/BusinessCenter/UPDATE_RULE_FILTER_MODE';
export const UPDATE_ACTION_FILTER_MODE = 'app/BusinessCenter/UPDATE_ACTION_FILTER_MODE';
export const UPDATE_TRIGGER_FILTERS_SHOW_LIST = 'app/BusinessCenter/UPDATE_TRIGGER_FILTERS_SHOW_LIST';
export const UPDATE_ACTION_FILTERS = 'app/BusinessCenter/UPDATE_ACTION_FILTERS';
export const UPDATE_ACTION_FILTERS_SHOW_LIST = 'app/BusinessCenter/UPDATE_ACTION_FILTERS_SHOW_LIST';
export const FETCHED_ACTIVITIES_WITH_ARCHIVE_SUCCESS = 'app/BusinessCenter/FETCHED_ACTIVITIES_WITH_ARCHIVE_SUCCESS';
export const GO_TO_BUSINESS_CENTER = 'app/BusinessCenter/GO_TO_BUSINESS_CENTER';
export const GO_TO_UPGRADE_PAGE = 'app/BusinessCenter/GO_TO_UPGRADE_PAGE';
export const OPEN_ACTIVITY_IN_NEW_TAB = 'app/BusinessCenter/OPEN_ACTIVITY_IN_NEW_TAB';
export const OPEN_TOAST = 'app/BusinessCenter/OPEN_TOAST';
export const CLOSE_TOAST = 'app/BusinessCenter/CLOSE_TOAST';

// campaign
export const UPDATE_CAMPAIGN = 'app/BusinessCenter/UPDATE_CAMPAIGN';
export const UPDATE_CAMPAIGN_SUCCESS = 'app/BusinessCenter/UPDATE_CAMPAIGN_SUCCESS';

// activitiesBoxes Constants
export const CREATE_CAMPAIGN = 'app/BusinessCenter/CREATE_CAMPAIGN';
export const CREATE_CAMPAIGN_SUCCESS = 'app/BusinessCenter/CREATE_CAMPAIGN_SUCCESS';
export const DEFOCUS_CAMPAIGN = 'app/BusinessCenter/DEFOCUS_CAMPAIGN';
export const FOCUS_CAMPAIGN = 'app/BusinessCenter/FOCUS_CAMPAIGN';
export const UPDATE_ORDER = 'app/BusinessCenter/UPDATE_ORDER';
export const DELETE_CAMPAIGN = 'app/BusinessCenter/DELETE_CAMPAIGN';
export const DELETE_CAMPAIGN_SUCCESS = 'app/BusinessCenter/DELETE_CAMPAIGN_SUCCESS';


// activities Constants
export const ADD_ACTIVITY = 'app/BusinessCenter/ADD_ACTIVITY';
export const UPDATE_ACTIVITY = 'app/BusinessCenter/UPDATE_ACTIVITY';
export const UPDATE_ACTIVITY_PIN = 'app/BusinessCenter/UPDATE_ACTIVITY_PIN';
export const SORT_ACTIVITIES = 'app/BusinessCenter/SORT_ACTIVITIES';
export const DELETE_ACTIVITY = 'app/BusinessCenter/DELETE_ACTIVITY';
export const DELETE_ACTIVITY_SUCCESS = 'app/BusinessCenter/DELETE_ACTIVITY_SUCCESS';
export const UPDATE_ACTIVITY_SUCCESS = 'app/BusinessCenter/UPDATE_ACTIVITY_SUCCESS';
export const ACTIVITY_MOVE_TO_CAMPAIGN = 'app/BusinessCenter/ACTIVITY_MOVE_TO_CAMPAIGN';
export const ACTIVITY_MOVE_TO_CAMPAIGN_SUCCESS = 'app/BusinessCenter/ACTIVITY_MOVE_TO_CAMPAIGN_SUCCESS';
export const UPDATE_ACTIVITY_PINNED_DATE = 'app/BusinessCenter/UPDATE_ACTIVITY_PINNED_DATE';

// STORE CONSTANTS
export const CAMPAIGNS = 'campaigns';
export const FOCUSED_CAMPAIGN_ID = 'focusedCampaignId';
export const ACTIVITIES = 'activities';
export const APP = 'app';
export const LOCATION_ID = 'locationId';
export const ID = 'id';
export const IS_NOT_VISIBLE = 'isNotVisible';
export const CAMPAIGN_GOAL_ID = 'goalId';
export const CAMPAIGN_PINED_DATE = 'pinnedDate';
export const CAMPAIGN_CREATED_ON = 'createdOn';
export const CAMPAIGN_NAME = 'name';
export const CAMPAIGN_STATUS_ARCHIVE = 'archive';
export const CAMPAIGN_STATUS_ACTIVE = 'active';
export const CAMPAIGN_STATUS = 'status';
export const CAMPAIGNS_LOADED = 'campaignsLoaded';
export const FETCHED_ACTIVITIES_WITH_ARCHIVE = 'fetchedActivitiesWithArchives';
export const UPDATE_SCROLL_POSITION = 'updateScrollYPosition';
export const SCROLL_Y_POSITION = 'scrollYPosition';
export const SCROLL_Y_POSITION_UPDATED = 'scrollYPositionUpdated';
export const ALL_BUSINESS_CENTER_ACTIVITY_TAGS = 'allBusinessCenterActivityTags';
export const ACTIVITY_TAGS_FILTER = 'filterActivityTags';

// Filters
export const FILTERS = 'filters';
export const TRIGGERS = 'triggers';
export const ACTIONS = 'actions';
export const NO_ACTION = 'noAction';
export const FILTERS_GROUP_NAME_CAMPAIGN = 'campaign';
export const FILTERS_IS_AUTO_ARCHIVE = 'isAutoArchived';
export const FILTERS_GROUP_NAME_ACTIVITY_BY_TYPE = 'activityByType';
export const FILTERS_GROUP_NAME_ACTIVITY_BY_STATUS = 'activityByStatus';
export const FILTERS_GROUP_NAME_ACTIVITY_EXTRA = 'activityExtra';
export const FILTERS_CAMPAIGN_ARCHIVED = 'archived';
export const FILTERS_GROUP_NAME_ALL_TRIGGER = 'allTriggers';
export const FILTERS_GROUP_NAME_ALL_ACTION = 'allActions';
export const FILTERS_RESET_ALL_FILTERS = 'resetAllFilters';
export const FILTERS_NO_FILTERS_TO_SHOW = 'noFiltersShow';
export const FILTERS_TO_DISABLE_MAP = 'filtersToDisableMap';
export const IS_RULE_FILTER_ENABLED = 'isRuleFilterEnabled';
export const IS_ACTION_FILTER_ENABLED = 'isActionFilterEnabled';
export const IS_VIEW_ACTION_FILTER_DISABLED = 'isViewActionFilterDisabled';
export const IS_ACTION_FILTER_UPDATED = 'isActionFilterUpdated';
export const TEMP_FILTER_MAP = 'tempFiltersMap';
export const TEMP_ACTION_FILTER_MAP = 'tempActionFiltersMap';
export const IS_AUTO_ARCHIVED_FILTER_ENABLED = 'isAutoArchiveFilterEnabled';
export const IS_ARCHIVE_CAMPAIGN_FILTER_ENABLED = 'isArchiveCampaignFilterEnabled';
export const FILTER_GROUPING_MAP = 'filterGroupingMap';
// BUSINESS CENTER FEATURES CONSTANTS

// Order
export const SORT = 'sort';
export const ORDER = 'order';
export const ORDER_DESCENT = 'desc';
export const ORDER_ASCEND = 'asc';

// ab testing analysis
export const AB_TEST_CATEGORY = 'category';
export const AB_TEST_DATA = 'data';
export const AB_TEST_CATEGORY_GENERAL = 'general';
export const AB_TEST_CASE1_VALUE = 'case1Value';
export const AB_TEST_CASE2_VALUE = 'case2Value';
export const AB_TEST_FORMAT = 'format';
export const AB_TEST_TYPE = 'type';
export const AB_TEST_DIFF = 'diff';
export const AB_TEST_KEY = 'key';
export const AB_TEST_FORMAT_CURRENCY = 'currency';
export const AB_TEST_FORMAT_PERCENT = 'percent';
export const AB_TEST_FORMAT_CURRENCY_PER_SPEND = 'currencyPerSpend';
export const CASE_A = 'case_a';
export const CASE_B = 'case_b';
export const AB_TEST_CATEGORY_NUM_APPLIED = 'numApplied';
export const AB_TEST_TYPE_FLOAT = 'float';
export const AB_TEST_TYPE_INTEGER = 'integer';
export const AB_TEST_TIME_UPDATED = 'timeUpdated';

// nav
export const BUSINESS_CENTER_NAV = 'businessCenter';
export const FILTER_MEMBERS = 'filterMembers';
export const MICRO_CAMPAIGNS_NAV = 'microCampaigns';
export const GIFTS_NAV = 'gifts';
export const PROMO_CODES_NAV = 'promoCodes';
export const EMAILS_NAV = 'emails';
export const POINT_SHOP = 'pointShop';
export const ACTIVITY_PINNING_DATE = 'pinningDate';
export const IS_TOAST_OPEN = 'isToastOpen';
export const TOAST_MESSAGE = 'toastMessage';
