import React from 'react';
import PropTypes from 'prop-types';
import '../../../features/Templates/templates.styles.less';
import BasicCard from './BasicCard/BasicCard';
import './ImageTextCard.styles.less';

class ImageTextCard extends React.PureComponent {

  static propTypes = {
    onClick: PropTypes.func,
    glowOnHover: PropTypes.bool,
    image: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    automationId: PropTypes.string.isRequired
  };

  render() {
    const { image, text, glowOnHover, onClick, automationId } = this.props;
    return (
      <BasicCard onClick={onClick} glowOnHover={glowOnHover} automationId={automationId}>
        <div className="imageTextContainer">
          <div className="imageContainer">
            <img src={image} alt="" />
          </div>
          <div className={'textContainer card-header'}>
            {text}
          </div>
        </div>
      </BasicCard>
    );
  }
}

export default ImageTextCard;
