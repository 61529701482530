import axios from 'axios';
import * as queryStringUtils from '../utils/queryStringUtils';

axios.defaults.withCredentials = false;

const initRequester = (hub1Path, increaseCounter, decreaseCounter, push) => {

  axios.interceptors.response.use(responseSuccess, responseError);

  function responseSuccess(res) {
    decreaseCounter();
    return res;
  }

  function responseError(err) {
    const status = err.response.status;

    // Add a 401 response interceptor
    if (status === 401) {
      return window.location.replace(`${hub1Path}/dashboard/#/login`);
    }

    if (status === 403) {
      push({
        pathname: '/forbidden',
        search: location.search,
      });
    }
    if (err.request.responseURL.includes('ApplicationData') && status === 500) {
      push({
        pathname: '/somethingsWentWrong',
        search: location.search,
      });
    }

    decreaseCounter();
    return Promise.reject(err);
  }

  // Add a request interceptor
  axios.interceptors.request.use(requestSuccess, requestError);

  function requestSuccess(config) {
    const { 'location_id': locationId } = queryStringUtils.parse(location.search);
    if (locationId) {
      config.headers['hub-auth-location-id'] = Number(locationId);
    }
    increaseCounter();
    return config;
  }

  function requestError() {
  }

};

export default initRequester;
