import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Dimmer, Grid, Image, Loader } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import Tooltip from '../../../common/components/Tooltip/Tooltip';
import Modal from '../../../common/components/CustomModal/CustomModal';
import * as activityConstants from '../../Activity/activity.constants';
import imagesSaga from '../../Images/images.saga';

import * as imagesConstants from '../../Images/images.constants';
import injectSaga from '../../../utils/injectSaga';
import * as giftsActions from '../gifts.actions';
import giftsSaga from '../gifts.saga';
import * as selectors from '../gifts.selectors';
import * as reducerConstants from '../../../constants/reducer.constants';
import ActivityStatistic from '../../BusinessCenter/views/ActivityStatistic';
import moment from 'moment';
import * as appSelectors from '../../App/selectors';

const NAME_LETTERS_MAX = 25;

class GiftStats extends React.PureComponent {
  static propTypes = {
    actions: PropTypes.object,
    gift: PropTypes.object.isRequired,
    onConfirm: PropTypes.func,
    open: PropTypes.bool,
    stats: PropTypes.object,
    thumbImage: PropTypes.object,
    currencySymbol: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = { isTooltipOpen: false };
    this.onTooltipOpen = this.onTooltipOpen.bind(this);
    this.onTooltipClose = this.onTooltipClose.bind(this);
    this.onClose = this.onClose.bind(this);

  }

  componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open) {
      this.props.actions.getGiftStats(this.props.gift.get(activityConstants.HUB_ID));
    }
  }

  onTooltipOpen() {
    setTimeout(() => this.setState({ isTooltipOpen: true }), 2000);
  }

  onTooltipClose() {
    this.setState({ isTooltipOpen: false });
  }

  onClose() {
    this.props.actions.cleanGiftStats();
    this.props.onConfirm();
  }

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line
    const { open, gift, stats, thumbImage } = this.props;
    const createDate = gift.getIn([activityConstants.HISTORY_LOG, activityConstants.ACTIVITY_HISTORY_LOG_CREATED, activityConstants.ACTIVITY_HISTORY_LOG_DATE]);
    const name = gift.get(activityConstants.NAME);
    const title = <p>{formatMessage({ id: 'gift-stats.title' }, { name })}</p>;
    const hint = formatMessage({ id: 'HubDeal-stats.hint' });

    return (
      <Modal
        open={open}
        className="gift-stats-modal"
        onClose={this.onClose}
        automationIdPrefix="gift.analysis"
        header={
          <div className="type-and-name">
            {thumbImage && <Image
              className="gift-mini-thumbnail"
              src={thumbImage.get(imagesConstants.URL)}
            />}
            {name.length > NAME_LETTERS_MAX ?
              <Tooltip
                content={title}
                trigger={title}
                position="bottom right"
              /> : title
            }
          </div>
        }

        content={
          <div className="stats-modal-content">
            <Dimmer inverted active={!stats}>
              <Loader active />
            </Dimmer>
            <Tooltip
              open={this.state.isTooltipOpen}
              onOpen={this.onTooltipOpen}
              onClose={this.onTooltipClose}
              className="tooltip.ui.popup"
              content={formatMessage({ id: 'gift-stats.tooltip' }, { 'sent': stats ? stats.get('sent') : '?', 'redeemed': stats ? stats.get('redeemed') : '?' })}
              trigger={
                <>
                  <div data-automation-id="gift-stats.createdOn">
                    {`${formatMessage({ id: 'gift-stats.createdOn' })}: ${moment(createDate).format(this.props.localeDateFormat)} ${moment(createDate).format(this.props.localeTimeFormat)}`}
                  </div>
                  <ul>
                    <Grid className="statsGrid">
                      <ActivityStatistic
                        label={formatMessage({ id: 'gift-stats.num_received' })}
                        value={stats && stats.get('num_received')}
                      />
                      <ActivityStatistic
                        label={formatMessage({ id: 'gift-stats.num_members' })}
                        value={stats && stats.get('num_members')}
                      />
                      <ActivityStatistic
                        label={formatMessage({ id: 'gift-stats.num_redeem' })}
                        value={stats && stats.get('num_redeem')}
                      />
                      <ActivityStatistic
                        label={formatMessage({ id: 'gift-stats.redeem_rate' })}
                        value={stats && stats.get('redeem_rate') > 0 ? stats.get('redeem_rate') : 0}
                        isPercentage
                      />
                      <ActivityStatistic
                        label={formatMessage({ id: 'gift-stats.sales' })}
                        value={stats && stats.get('sales') > 0 ? stats.get('sales') : 0}
                        currencySymbol={this.props.currencySymbol}
                      />

                      <ActivityStatistic
                        label={formatMessage({ id: 'gift-stats.visits' })}
                        value={stats && stats.get('visits')}
                      />
                      <ActivityStatistic
                        label={formatMessage({ id: 'gift-stats.avg_spend_per_visit' })}
                        value={stats && stats.get('avg_spend_per_visit') > 0 ? stats.get('avg_spend_per_visit') : 0}
                        currencySymbol={this.props.currencySymbol}
                      />
                      <ActivityStatistic
                        label={formatMessage({ id: 'gift-stats.avg_spend_per_member' })}
                        value={stats && stats.get('avg_spend_per_member') > 0 ? stats.get('avg_spend_per_member') : 0}
                        currencySymbol={this.props.currencySymbol}
                      />
                    </Grid>
                  </ul>
                </>
              }
              position="bottom left"
            />
            <span className="hint-tip-label stick-to-bottom">
              {hint}
            </span>
          </div>
          }

        actions={[
          <a data-automation-id="gift-stats.got.it.button" role="button" key="gotIt" onClick={this.onClose}>{formatMessage({ id: 'general.button.got-it' })}</a>
        ]}
        closeOnDimmerClick
      />
    );
  }
}

const mapStateToProps = (state) => ({
  stats: selectors.getGiftStats(state),
  currencySymbol: appSelectors.getLocationCurrencySymbol(state),
  localeDateFormat: appSelectors.getBrowserLocaleDateFormat(state),
  localeTimeFormat: appSelectors.getBrowserLocaleTimeFormat(state),
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(giftsActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.GIFTS_BRANCH, saga: giftsSaga });
const withImagesSaga = injectSaga({ key: reducerConstants.IMAGES_BRANCH, saga: imagesSaga });

export default compose(
  withConnect,
  withSaga,
  withImagesSaga,
  injectIntl
)(GiftStats);
