import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import InputWrapper from '../InputWrapper/InputWrapper';
import { INTEGER_TYPE } from '../inputConstants';
import { getDecimalPrecision } from '../../../../features/App/selectors';

export const defaultPrecision = 2;
export const defaultPrecisionPow = 10 ** defaultPrecision;

class PointsInputField extends React.PureComponent {

  static propTypes = {
    allowOnlyPositive: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    fluid: PropTypes.bool,
    name: PropTypes.string,
    prefix: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func,
    precision: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number
  };

  static defaultProps = {
    fluid: false,
    allowOnlyPositive: true
  };

  constructor(props) {
    super(props);
    this.state = { displayValue: getDisplayValueByPrecision(props.value, this.props.precision) };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ displayValue: getDisplayValueByPrecision(nextProps.value, this.props.precision) });
  }

  onKeyDown = (event) => {
    if (this.props.allowOnlyPositive && event.key === '-') {
      event.preventDefault();
      return false;
    }
    if (this.props.precision === 0 && event.key === '.') {
      event.preventDefault();
      return false;
    }
    return true;
  };

  onChange = (e, data) => {
    this.setState({ displayValue: this.GetValueByPrecision(data.value) });
    // save currency in cents (therefore multiple with defaultPrecisionPow)
    let formattedValue;
    if (this.props.precision === defaultPrecision) {
      formattedValue = parseFloat(data.value ? data.value : null) * defaultPrecisionPow;
    } else {
      formattedValue = parseFloat(data.value ? data.value : null);
    }
    const newData = { name: data.name, value: formattedValue };
    this.props.onChange(e, newData);
  };

  GetValueByPrecision(value) {
    if (this.props.precision === defaultPrecision) {
      return value === null ? '' : parseFloat(value).toFixed(defaultPrecision);
    }
    return value === null ? '' : parseFloat(value);
  }

  render() {

    const { disabled, error, fluid, name, prefix, min, max } = this.props;
    const className = fluid ? this.props.className : `${this.props.className} points-input-field`;

    return (<InputWrapper
      className={className}
      disabled={disabled}
      error={error}
      fluid={fluid}
      name={name}
      onChange={this.onChange}
      onKeyDown={this.onKeyDown}
      prefix={prefix}
      step={0.01}
      type={INTEGER_TYPE}
      value={this.state.displayValue}
      min={min}
      max={max}
    />
    );
  }
}

const mapStateToProps = (state) => ({
  precision: getDecimalPrecision(state)
});

const withConnect = connect(mapStateToProps);

export default compose(
  withConnect,
  injectIntl,
)(PointsInputField);

export function getDisplayValueByPrecision(value, precision) {
  if (precision === defaultPrecision) {
    return value === null ? '' : (value / defaultPrecisionPow).toFixed(defaultPrecision);
  }
  return value === null ? '' : value;
}
