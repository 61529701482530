import moment from 'moment';
import 'moment-timezone';
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import HourAndMinute from '../../../common/components/HourAndMinute/HourAndMinute';
import * as datesConstants from '../../../common/utils/dateTime.constants';
import * as dateUtils from '../../../common/utils/dateTimeUtils';
import * as appSelectors from '../../App/selectors';
import * as activityActions from '../activity.actions';
import * as activitySelectors from '../activity.selectors';
import Tooltip from '../../../common/components/Tooltip/Tooltip';
import ValidationErrorMessage from '../../../common/components/ValidationErrorMessage/ValidationErrorMessage';

class TriggerSchedule extends React.PureComponent {

  static propTypes = {
    value: PropTypes.string,
    localeTimeFormat: PropTypes.string,
    businessTimeZone: PropTypes.string,
    actions: PropTypes.object,
    errorMessage: PropTypes.string,
    intl: PropTypes.object
  };

  handleOnChange = (timeData) => {
    const updatedDate = dateUtils.updateDateWithNewTime(moment().tz(this.props.businessTimeZone).startOf('day'), timeData);
    this.props.actions.updateTriggerScheduleValue(updatedDate.utc().format(datesConstants.HH_MM_SS_FORMAT));
  };

  render() {
    const isMilitaryTime = this.props.localeTimeFormat.indexOf(datesConstants.AM_TIME) === -1;
    const timeObj = dateUtils.buildTimeObject(this.props.value, isMilitaryTime, this.props.businessTimeZone, true);
    const hour = timeObj[datesConstants.HOUR];
    const minute = timeObj[datesConstants.MINUTE];
    const format = timeObj[datesConstants.FORMAT];
    return (
      <Grid className="trigger-schedule-container">
        <Grid.Row>
          <Grid.Column className="left-column" width={2}>
            <p>{this.props.intl.formatMessage({ id: 'activity.scheduled.run.on.label' })}</p>
          </Grid.Column>
          <Grid.Column width={14}>
            <span className="head-of-sentence">
              {this.props.intl.formatMessage({ id: 'activity.scheduled.every.day.at.label' })}
            </span>
            <HourAndMinute
              isMilitary={isMilitaryTime}
              format={format}
              hour={hour}
              minute={minute}
              onChange={this.handleOnChange}
              prefix={'triggerSchedule'}
            />
            <Tooltip
              wide="very"
              trigger={(<Icon className="como-ic-help" />)}
              className="pre-formatted-tooltip"
              content={<div>
                <h3>{this.props.intl.formatMessage({ id: 'activity.scheduled.tooltip.title' })}</h3>
                <p>
                  <FormattedMessage
                    id="activity.scheduled.tooltip.title.body"
                    values={{
                      'timezone': moment.tz(this.props.businessTimeZone).format('z'),
                      'subtext': <strong>{
                        this.props.intl.formatMessage({ id: 'activity.scheduled.tooltip.title.body.subtext' })
                      }</strong>
                    }}
                  />
                </p>
              </div>}
            />
            {this.props.errorMessage && <ValidationErrorMessage
              dataAutomationId={'trigger.schedule.error.message'}
              errorMessage={this.props.intl.formatMessage({ id: this.props.errorMessage })}
            />}
          </Grid.Column>
        </Grid.Row>
      </Grid>);
  }
}

const mapStateToProps = (state) => ({
  localeTimeFormat: appSelectors.getBrowserLocaleTimeFormat(state),
  businessTimeZone: appSelectors.getBusinessTimeZone(state),
  value: activitySelectors.getTriggerScheduleValue(state),
  errorMessage: activitySelectors.getScheduleParametersScheduleValueValidationError(state)
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(activityActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(TriggerSchedule);
