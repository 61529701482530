/**
*
* Date
*
*/

import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import DatePicker from '../../CustomDatePicker/CustomDatePicker';
import * as appSelectors from '../../../../features/App/selectors';
import * as datesConstants from '../../../utils/dateTime.constants';
import './DateInputField.styles.less';

class DateInputField extends React.PureComponent {

  static propTypes = {
    disabled: PropTypes.bool,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    prefix: PropTypes.string,
    error: PropTypes.string,
    locale: PropTypes.string.isRequired,
    localeDateFormat: PropTypes.string.isRequired,
    businessTimezone: PropTypes.string.isRequired,
    name: PropTypes.string
  };
  momentToDate = (momentObject) => {
    if (momentObject) {
      return momentObject.toDate();
    }
    return null;
  };

  onChange = (value) => {
    const newData = {name: this.props.name, value: moment(value).format(datesConstants.DATE_ONLY_FORMAT)};
    this.props.onChange(value, newData);
  };

  get value() {
    return this.momentToDate(moment(this.props.value, datesConstants.DATE_ONLY_FORMAT));
  }

  get triggerText() {
    return moment(this.value).format(this.props.localeDateFormat);
  }

  render() {
    return (<div className="date-input-field">
      <DatePicker
        timeZone={this.props.businessTimezone}
        data-automation-id={this.props.prefix}
        openToDate={this.value}
        locale={this.props.locale}
        onChange={this.onChange}
        selected={this.value}
        disabled={this.props.disabled}
        shouldCloseOnSelect={false}
        dateFormatCalendar="MMMM"
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={20}
        customInput={
          <Button basic className="link" data-automation-id={`${this.props.prefix}.input`}>
            <span>
              {this.triggerText}
            </span>
            <Icon className="como-ic-date" />
          </Button>
        }
        error={this.props.error}
        fixedHeight
      />
    </div>);
  }
}

const mapStateToProps = (state) => ({
  locale: appSelectors.getBrowserLocale(state),
  localeDateFormat: appSelectors.getBrowserLocaleDateFormat(state),
  businessTimezone: appSelectors.getBusinessTimeZone(state),
});

export default connect(mapStateToProps)(injectIntl(DateInputField));
