import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import * as activityConstants from '../../../activity.constants';
import './addPoints/addPoints.styles.less';
import PointsInputField from '../../../../../common/components/FormInputFields/PointsInputField/PointsInputField';
import BusinessWallets from './addPoints/businessWallets/businessWallets';
import DropdownWrapper from '../../../../../common/components/DropdownWrapper/DropdownWrapper';
import * as constants from '../../../activitySchema/activitySchema.constants';

class DeductDynamicPoints extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
    onActionFieldUpdate: PropTypes.func.isRequired,
    action: PropTypes.object,
    index: PropTypes.number,
    validationErrors: PropTypes.object
  };

  static defaultProps = {
    limit: false,
    action: {}
  };

  componentWillMount() {
    if (!this.props.action.get(activityConstants.POINTS_TYPE)) {
      this.props.onActionFieldUpdate(activityConstants.POINTS_TYPE, activityConstants.ACCUMULATE_POINTS_TYPE_FIXED, this.props.index);
    }
  }

  render() {
    const { intl, index, action, onActionFieldUpdate, validationErrors, automationIdPrefix } = this.props;
    const { formatMessage } = intl;
    const pointsTypeOptions = [
      {
        value: activityConstants.ACCUMULATE_POINTS_TYPE_FIXED,
        text: intl.formatMessage({ id: 'activity.action.accumulatePoints.deduct.type.fixed' })
      },
      {
        value: activityConstants.ACCUMULATE_POINTS_TYPE_DYNAMIC,
        text: intl.formatMessage({ id: 'activity.action.accumulatePoints.deduct.type.dynamic' })
      }
    ];
    const isDynamicPointsType = this.props.action.get(activityConstants.POINTS_TYPE) === activityConstants.ACCUMULATE_POINTS_TYPE_DYNAMIC;

    const dynamicPointsValues = [
      {
        value: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_1,
        text: intl.formatMessage({ id: 'condition.context.Data.Data.NumericValue1' })
      },
      {
        value: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_2,
        text: intl.formatMessage({ id: 'condition.context.Data.Data.NumericValue2' })
      },
      {
        value: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_3,
        text: intl.formatMessage({ id: 'condition.context.Data.Data.NumericValue3' })
      },
      {
        value: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_4,
        text: intl.formatMessage({ id: 'condition.context.Data.Data.NumericValue4' })
      },
      {
        value: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_5,
        text: intl.formatMessage({ id: 'condition.context.Data.Data.NumericValue5' })
      },
      {
        value: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_6,
        text: intl.formatMessage({ id: 'condition.context.Data.Data.NumericValue6' })
      },
      {
        value: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_7,
        text: intl.formatMessage({ id: 'condition.context.Data.Data.NumericValue7' })
      },
      {
        value: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_8,
        text: intl.formatMessage({ id: 'condition.context.Data.Data.NumericValue8' })
      },
      {
        value: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_9,
        text: intl.formatMessage({ id: 'condition.context.Data.Data.NumericValue9' })
      },
      {
        value: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_10,
        text: intl.formatMessage({ id: 'condition.context.Data.Data.NumericValue10' })
      }
    ];

    const pointsTypeValue = this.props.action.get(activityConstants.POINTS_TYPE) || activityConstants.ACCUMULATE_POINTS_TYPE_FIXED;
    return (
      <Grid className="action add-points-action">
        <Grid.Row>
          <Grid.Column width={16}>
            <DropdownWrapper
              options={pointsTypeOptions}
              onSelectOption={(e, data) => onActionFieldUpdate(data.name, data.value, index)}
              name={activityConstants.POINTS_TYPE}
              value={pointsTypeValue}
              prefix={`${automationIdPrefix}.deduct.points.types`}
              className="deduct-points-type"
              error={validationErrors ? validationErrors.get(activityConstants.POINTS_TYPE) : null}
            />
            <div>
              <div className="points-action-data">
                <span>{formatMessage({ id: 'activity.action.deductPoints.points' })}</span>
                { !isDynamicPointsType
                  ? <PointsInputField
                    name={activityConstants.POINTS}
                    onChange={(e, data) => onActionFieldUpdate(data.name, data.value, index)}
                    value={this.props.action.get(activityConstants.POINTS)}
                    prefix={`${automationIdPrefix}.points`}
                    error={validationErrors ? validationErrors.get(activityConstants.POINTS) : null}
                    min={0}
                  />
                  : <DropdownWrapper
                    options={dynamicPointsValues}
                    onSelectOption={(e, data) => onActionFieldUpdate(data.name, data.value, index)}
                    name={activityConstants.DYNAMIC_POINTS_VALUE}
                    value={action.get(activityConstants.DYNAMIC_POINTS_VALUE)}
                    prefix={`${automationIdPrefix}.deduct.points.dynamic.value`}
                    className="deduct-points-dynamic-value"
                    error={validationErrors ? validationErrors.get(activityConstants.DYNAMIC_POINTS_VALUE) : null}
                  />
                }
                <BusinessWallets
                  onActionFieldUpdate={(e, data) => onActionFieldUpdate(data.name, data.value, index)}
                  name={activityConstants.BUDGET_TYPE}
                  index={index}
                  value={action.get(activityConstants.BUDGET_TYPE)}
                  automationIdPrefix={`${automationIdPrefix}.budgetType`}
                  error={validationErrors ? validationErrors.get(activityConstants.BUDGET_TYPE) : null}
                />
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default injectIntl(DeductDynamicPoints);
