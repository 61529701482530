export const GET_RULES_PRODUCT_TEMPLATES = 'app/ActivityGoals/Admin/GET_RULES_PRODUCT_TEMPLATES';
export const GET_RULES_PRODUCT_TEMPLATES_SUCCESS = 'app/ActivityGoals/Admin/GET_RULES_PRODUCT_TEMPLATES_SUCCESS';

export const GET_DEALS_PRODUCT_TEMPLATES = 'app/ActivityGoals/Admin/GET_DEALS_PRODUCT_TEMPLATES';
export const GET_DEALS_PRODUCT_TEMPLATES_SUCCESS = 'app/ActivityGoals/Admin/GET_DEALS_PRODUCT_TEMPLATES_SUCCESS';

export const GET_ONE_TIME_ACTIONS_PRODUCT_TEMPLATES = 'app/ActivityGoals/Admin/GET_ONE_TIME_ACTIONS_PRODUCT_TEMPLATES';
export const GET_ONE_TIME_ACTIONS_PRODUCT_TEMPLATES_SUCCESS = 'app/ActivityGoals/Admin/GET_ONE_TIME_ACTIONS_PRODUCT_TEMPLATES_SUCCESS';

export const GET_PUNCH_CARDS_PRODUCT_TEMPLATES = 'app/ActivityGoals/Admin/GET_PUNCH_CARDS_PRODUCT_TEMPLATES';
export const GET_PUNCH_CARDS_PRODUCT_TEMPLATES_SUCCESS = 'app/ActivityGoals/Admin/GET_PUNCH_CARDS_PRODUCT_TEMPLATES_SUCCESS';

export const GET_GOALS = 'app/ActivityGoals/Admin/GET_GOALS';
export const GET_GOALS_SUCCESS = 'app/ActivityGoals/Admin/GET_GOALS_SUCCESS';

export const CREATE_GOAL = 'app/ActivityGoals/Admin/CREATE_GOAL';
export const CREATE_GOAL_SUCCESS = 'app/ActivityGoals/Admin/CREATE_GOAL_SUCCESS';

export const UPDATE_GOAL = 'app/ActivityGoals/Admin/UPDATE_GOAL';
export const UPDATE_GOAL_SUCCESS = 'app/ActivityGoals/Admin/UPDATE_GOAL_SUCCESS';

export const ADD_TOOL = 'app/ActivityGoals/Admin/ADD_TOOL';
export const UPDATE_TOOL = 'app/ActivityGoals/Admin/UPDATE_TOOL';
export const SELECT_GOAL = 'app/ActivityGoals/Admin/SELECT_GOAL';
export const CHANGE_GOAL_NAME = 'app/ActivityGoals/Admin/CHANGE_GOAL_NAME';
export const CHANGE_GOAL_ICON = 'app/ActivityGoals/Admin/CHANGE_GOAL_ICON';
