import React from 'react';
import PropTypes from 'prop-types';
import './Hint.styles.less';

const Hint = ({ children }) => (
  <div className="hint-container">
    {children}
  </div>
  );

Hint.propTypes = {
  children: PropTypes.node.isRequired
};

export default Hint;
