export function displayNumberWithCommas(number) {
  if (number == undefined || number == null) {
    return "Not available";
  }
  let returnNumber = number;
  if (number % 1 !== 0) {
    returnNumber = number.toFixed(2);
  }
  return returnNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}


function roundToDigits(number, numOfDigits, excludeInts = false) {
  const multiplier = 10 ** numOfDigits;
  const rounded = Math.round(number * multiplier) / multiplier;
  if(excludeInts && rounded % 1 === 0) {
    return rounded;
  }
  return rounded.toFixed(numOfDigits);
}

function roundToInt(number) {
  return Math.round(number);
}

// Math.round( number * 10 ) / 10;
export function formatByValue(value, digitsAfterPoint = 1, forceToDigits = false) {
  const val = parseFloat(value);
  if ((val > 0 && val < 10) || (val < 0 && val > -10)) {
    return roundToDigits(val, digitsAfterPoint);
  } else if ((val >= 10 && val < 1000) || (val <= -10 && val > -1000)) {
    return forceToDigits ? roundToDigits(value, digitsAfterPoint, true) : roundToInt(val);
  } else if ((val >= 1000 && val < 10000) || (val <= -1000 && val > -10000)) {
    return `${roundToDigits(val / 1000, digitsAfterPoint)}K`;
  } else if ((val >= 10000 && val < 1000000) || (val <= -10000 && val > -1000000)) {
    return forceToDigits ? `${roundToDigits(val / 1000, digitsAfterPoint, true)}K` : `${roundToInt(val / 1000)}K`;
  } else if ((val >= 1000000 && val < 10000000) || (val <= -1000000 && val > -10000000)) {
    return `${roundToDigits(val / 1000000, digitsAfterPoint, true)}M`;
  } else if (val > 10000000 || val < -10000000) {
    return forceToDigits ? `${roundToInt(val / 1000)}M` : `${roundToInt(val / 1000)}M`;
  }
  return value;
}
