import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import * as activityConstants from '../../activity.constants';
import ConditionsContainer from '../conditions/ConditionsContainer';
import Tooltip from '../../../../common/components/Tooltip/Tooltip';
import CheckboxWrapper from '../../../../common/components/FormInputFields/CheckboxWrapper/CheckboxWrapper';
import DropdownWrapper from '../../../../common/components/DropdownWrapper/DropdownWrapper';
import {CONTEXT_PURCHASE_TOTAL_SUM} from "../../activitySchema/activitySchema.constants";
import {GIFT} from "../../../Gifts/gifts.constants";

class TermsTab extends React.PureComponent {

  static propTypes = {
    actions: PropTypes.object,
    activityConditions: PropTypes.object,
    activityDateTimeGlobalConditions: PropTypes.object,
    activityPrefix: PropTypes.string,
    activityTrigger: PropTypes.string,
    activityType: PropTypes.string,
    activityValidationErrors: PropTypes.object,
    autoRenew: PropTypes.bool,
    autoRenewTrigger: PropTypes.string,
    disabledGlobalConditions: PropTypes.object,
    headerMsgId: PropTypes.string,
    schemaConditionsPerTrigger: PropTypes.object,
    schemaMembershipConditions: PropTypes.object,
    sendOnPurchaseRule: PropTypes.object,
    getBusinessBundlePlan: PropTypes.func.isRequired,
  };

  componentWillMount() {
    if (!this.props.autoRenewTrigger) {
      // set autoRenewTrigger default, can't be empty
      this.props.actions.onRenewOptionsChange(activityConstants.PUNCH_CARD_RENEW_ON_LAST_PUNCH);
    }
  }

  onRenewOptionsChange = (e, data) => {
    this.props.actions.onRenewOptionsChange(data.value);
  };

  toggleSendOnPurchase = (e, data) => {
    this.props.actions.toggleSendOnPurchase(data.checked);
  };

  toggleAutoRenew = (e, data) => {
    this.props.actions.toggleAutoRenew(data.checked);
  };

  get autoSendOnPurchase() {
    const { sendOnPurchaseRule } = this.props;
    return sendOnPurchaseRule && sendOnPurchaseRule.get(activityConstants.STATUS) !== activityConstants.ACTIVITY_STATUS_INACTIVE;
  }

  get autoRenewOptions() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    return [
      activityConstants.PUNCH_CARD_RENEW_ON_REDEEM,
      activityConstants.PUNCH_CARD_RENEW_ON_LAST_PUNCH
    ].map((opt) => ({
      key: opt,
      value: opt,
      text: formatMessage({ id: `${this.props.activityPrefix}.${opt}` })
    }));
  }

  render() {

    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const { actions, activityConditions, activityDateTimeGlobalConditions, activityPrefix, activityTrigger, activityType, activityValidationErrors, autoRenew, autoRenewTrigger, disabledGlobalConditions, headerMsgId, schemaMembershipConditions, getBusinessBundlePlan } = this.props;
    let { schemaConditionsPerTrigger } = this.props;
    const { addCondition, deleteCondition, updateConditionField } = actions;
    const conditionsValidationErrors = activityValidationErrors ? activityValidationErrors.get(activityConstants.CONDITIONS) : null;
    const automationIdPrefix = `${activityPrefix}.terms`;
    if (activityTrigger === GIFT) { // Server 2.8 may not burn gift if total spend is defined as global condition
      let totalSumConditionIndex = schemaConditionsPerTrigger.findIndex(c => c.get(activityConstants.KEY) === CONTEXT_PURCHASE_TOTAL_SUM);
      schemaConditionsPerTrigger = schemaConditionsPerTrigger.setIn([totalSumConditionIndex, "disabled"], true);
    }
    return (
      <Grid className="activity-terms-tab">
        <Grid.Row>
          <Grid.Column>
            <Header>{formatMessage({ id: headerMsgId })}</Header>
            {activityType !== activityConstants.ACTIVITY_TYPE_PROMO_CODE &&
              <div className="hint-tip-label">
                {formatMessage({ id: `${activityType}s.header.hint` })}
                {
                  activityType === activityConstants.ACTIVITY_TYPE_GIFT &&
                  (
                    <Tooltip
                      wide="very"
                      trigger={(<Icon className="como-ic-help" />)}
                      content={formatMessage({ id: 'gifts.header.tooltip' })}
                    />
                  )
                }
              </div>
            }
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16} floated="right">
            <ConditionsContainer
              activityConditions={activityConditions}
              conditionsList={schemaConditionsPerTrigger}
              label
              mainConditions
              trigger={activityTrigger}
              addCondition={addCondition}
              deleteCondition={deleteCondition}
              updateConditionField={updateConditionField}
              schemaMembershipConditions={schemaMembershipConditions}
              dateTimeGlobalConditions={activityDateTimeGlobalConditions}
              disabledGlobalConditions={disabledGlobalConditions}
              conditionsValidationErrors={conditionsValidationErrors}
              activityType={activityType}
              getBusinessBundlePlan={getBusinessBundlePlan}
            />
          </Grid.Column>
        </Grid.Row>
        {
          activityType === activityConstants.ACTIVITY_TYPE_PUNCH_CARD &&
          (
            <Grid.Row>
              <Grid.Column>
                <div>
                  <CheckboxWrapper
                    label={formatMessage({ id: 'punchCard.definition.SendPunchCardAutomation' })}
                    onChange={this.toggleSendOnPurchase}
                    checked={this.autoSendOnPurchase}
                    data-automation-id={`${automationIdPrefix}.SendPunchCardAutomation`}
                  />
                  <Tooltip
                    wide="very"
                    trigger={(<Icon className="como-ic-help send-on-purchase-hint" />)}
                    content={formatMessage({ id: 'activity.punchCard.sendOnPurchase.hint' })}
                  />
                </div>
                <div className="punch-card-renew">
                  <CheckboxWrapper
                    label={formatMessage({ id: `${automationIdPrefix}.renew.checkbox` })}
                    onChange={this.toggleAutoRenew}
                    checked={this.props.autoRenew}
                    data-automation-id={`${automationIdPrefix}.renew.checkbox`}
                  />
                  <DropdownWrapper
                    name={`${automationIdPrefix}.renew.options`}
                    options={this.autoRenewOptions}
                    onSelectOption={this.onRenewOptionsChange}
                    prefix={`${automationIdPrefix}.renew.options`}
                    value={autoRenewTrigger}
                    disabled={!autoRenew}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          )
        }
      </Grid>
    );
  }
}

export default injectIntl(TermsTab);
