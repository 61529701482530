/**
 *
 * ComilliaHint
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Icon } from 'semantic-ui-react';
import { compose, bindActionCreators } from 'redux';

import * as selectors from './comilliaHint.selectors';
import * as actions from './comilliaHint.actions';
import * as constants from './comilliaHint.constants';
import comilliaEnterGif from '../../images/comillia/Poof-new.gif';
import tracker from '../../common/utils/tracking/tracker';
import * as trackerConstants from '../../common/utils/tracking/tracking.consts';

import './comilliaHint.styles.less';
import comilliaPointer from '../../images/comillia/commiliaPointsToHint.svg';


export class ComilliaHint extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

  static propTypes = {
    comilliaHint: PropTypes.object,
    contentTextKey: PropTypes.string.isRequired,
    contentTextParam: PropTypes.string,
    confirmButtonKey: PropTypes.string,
    confirmButtonParam: PropTypes.string,
    cancelButtonKey: PropTypes.string,
    actions: PropTypes.object,
    onHintConfirm: PropTypes.func
  };

  static defaultProps = {
    confirmButtonKey: 'general.button.ok',
    cancelButtonKey: 'general.button.cancel',
  };

  confirmAction = () => {
    this.props.onHintConfirm();
    tracker.onButtonClick(trackerConstants.BUTTON_TYPE_ADJUST_BY_COMILLIA_HINT);
    this.props.actions.handleHintAppearance(false);

  };

  cancelAction = (cancelType) => {
    switch (cancelType) {
      case 'ignore': {
        tracker.onButtonClick(trackerConstants.BUTTON_TYPE_IGNORE_COMILLIA_HINT);
        this.props.actions.handleHintAppearance(false);
        break;
      }
      case 'close': {
        tracker.onButtonClick(trackerConstants.BUTTON_TYPE_CLOSE_COMILLIA_HINT);
        this.props.actions.handleHintAppearance(false);
        break;
      }
      default: {
        this.props.actions.handleHintAppearance(false);
      }
    }
  };

  render() {

    const automationIdPrefix = 'comilliaHint';
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    if (!this.props.comilliaHint || !this.props.comilliaHint.get(constants.SHOW_HINT)) {
      return null;
    }

    const contentTextParam = this.props.comilliaHint.get(this.props.contentTextParam) || '';
    const confirmButtonParam = this.props.comilliaHint.get(this.props.confirmButtonParam) || '';

    return (
      <div className="comilliaHintWrap">
        <div className="comilliaHint">
          <img className="pointer" src={comilliaPointer} alt="" />
          <Icon
            className="como-ic-close close-toast"
            data-automation-id={`${automationIdPrefix}.close`}
            onClick={() => this.cancelAction('close')}
          />
          <p>
            <FormattedMessage
              id={this.props.contentTextKey}
              values={{
                [this.props.contentTextParam]: <span className="param">{
                  contentTextParam
                }</span>
              }}
            />
          </p>
          <div className="buttons">
            <Button
              basic
              className="link"
              key="cancel"
              role="button"
              data-automation-id={`${automationIdPrefix}.cancel`}
              onClick={() => this.cancelAction('ignore')}
              inverted
            >
              {formatMessage({ id: this.props.cancelButtonKey })}
            </Button>
            <Button
              key="confirm"
              data-automation-id={`${automationIdPrefix}.confirm`}
              onClick={this.confirmAction}
              loading={this.props.comilliaHint.get(constants.DATA_LOADING)}
            >
              <FormattedMessage
                id={this.props.confirmButtonKey}
                values={{
                  [this.props.confirmButtonParam]: <span className="param">{
                    confirmButtonParam
                  }</span>
                }}
              />
            </Button>
          </div>
        </div>
        <div className="overlay">
          <img src={comilliaEnterGif} alt="" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  comilliaHint: selectors.selectComilliaHintDomain(state)
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
)(ComilliaHint);
