import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import * as activityConstants from '../../Activity/activity.constants';
import tracker from '../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../common/utils/tracking/tracking.consts';
import ContextMenu from '../../../common/components/ContextMenu/ContextMenu';

class SingleMicroCampaignContextMenu extends React.PureComponent {
  static propTypes = {
    onDetailsOpen: PropTypes.func.isRequired,
    hidden: PropTypes.bool,
    automationId: PropTypes.string,
    intl: PropTypes.object,
    detailsForTracker: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showDeactivateConfirmationDialog: false
    };
  }

  getMenuOptions() {
    const { formatMessage } = this.props.intl;
    return [{
      key: activityConstants.CONTEXT_MENU_OPTION_DETAILS,
      iconName: 'como-ic-info',
      text: formatMessage({ id: 'microCampaigns-page.activity.menu.microCampaign-details' }),
      cb: this.handleShowDetailsClick
    }];
  }

  handleShowDetailsClick = () => {
    tracker.onButtonClick(trackerConstants.BUTTON_TYPE_SHOW_MICRO_CAMPAIGNL_DETAILS, this.props.detailsForTracker);
    this.props.onDetailsOpen();
  };

  render() {
    return (
      <React.Fragment>
        <ContextMenu
          options={this.getMenuOptions()}
          automationId={this.props.automationId}
          hidden={this.props.hidden}
        />
      </React.Fragment>);
  }
}

export default injectIntl(SingleMicroCampaignContextMenu);
