/*
 *
 * Ai actions
 *
 */

import * as constants from './ai.constants';

export function getPromptText(data) {
  return { type: constants.GET_PROMPT, payload: data };
}

export function clearPromptText(data) {
  return { type: constants.CLEAR_PROMPT, payload: data };
}
