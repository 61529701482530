import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const Languages = (props) => {
  const { intl, selectedLanguage, setSelectedLanguage, customLanguage, setCustomLanguage } = props;

  const { formatMessage } = intl;

  const handleLanguages = (event) => {
    const theValue = event.target.value;

    setSelectedLanguage(theValue);

    if (theValue !== 'Custom') {
      setCustomLanguage('');
    }
  };

  const handleCustomLanguage = (event) => {
    setCustomLanguage(event.target.value);
  };

  return (
    <React.Fragment>
      <span className={'tw-text-sm tw-flex tw-flex-row tw-mt-2'}>
        {formatMessage({ id: 'vertex.ai.language.pick-language' })}
      </span>
      <select value={selectedLanguage} onChange={handleLanguages} className={'select tw-my-2 select-bordered tw-w-full tw-max-w-xs'}>
        <option disabled>{formatMessage({ id: 'vertex.ai.language' })}</option>
        <option>{formatMessage({ id: 'vertex.ai.language.pick-language.english' })}</option>
        <option>{formatMessage({ id: 'vertex.ai.language.pick-language.french' })}</option>
        <option>{formatMessage({ id: 'vertex.ai.language.pick-language.spanish' })}</option>
        <option>{formatMessage({ id: 'vertex.ai.language.pick-language.arabic' })}</option>
        <option>{formatMessage({ id: 'vertex.ai.language.pick-language.hebrew' })}</option>
        <option>{formatMessage({ id: 'vertex.ai.language.pick-language.custom' })}</option>
      </select>
      {selectedLanguage === 'Custom' && (
        <div className={'flex flex-col mb-2'}>
          <span className={'text-sm mt-2'}>{formatMessage({ id: 'vertex.ai.language.tone-of-voice' })}</span>
          <input
            type="text"
            name={'customLanguage'}
            placeholder={formatMessage({ id: 'vertex.ai.language.custom.placeholder' })}
            className={'input my-2 input-bordered w-full max-w-xs'}
            value={customLanguage}
            onChange={handleCustomLanguage}
          />
        </div>
      )}
    </React.Fragment>
  );
};

Languages.prototype = {
  intl: PropTypes.object,
  selectedLanguage: PropTypes.string,
  setSelectedLanguage: PropTypes.func,
  customLanguage: PropTypes.string,
  setCustomLanguage: PropTypes.func
};

export default injectIntl(Languages);
