import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import * as constants from '../../../activity.constants';
import * as schemaConstants from '../../../activitySchema/activitySchema.constants';
import * as appConstants from '../../../../App/app.constants';
import CurrencyInputField from '../../../../../common/components/FormInputFields/CurrencyInputField/CurrencyInputField';
import PercentageInputField
  from '../../../../../common/components/FormInputFields/PercentageInputField/PercentageInputField';
import ItemsPopulation from '../../itemsPopulation/itemsPopulation.container';
import CheckBox from '../../../../../common/components/FormInputFields/CheckboxWrapper/CheckboxWrapper';
import SingleLineAccordion from '../../../../../common/components/SingleLineAccordion/singleLineAccordion';
import withAuthorization from '../../../../../common/helpers/authorization';
import DisabledItemPosRestriction from '../../../../../common/components/DisabledItem/DisabledItemPosRestriction';

class DiscountOnEntireTicket extends React.PureComponent {

  static propTypes = {
    action: PropTypes.object,
    onActionFieldUpdate: PropTypes.func.isRequired,
    getPermissionLevel: PropTypes.func.isRequired,
    index: PropTypes.number,
    validationErrors: PropTypes.object,
    automationIdPrefix: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
    features: PropTypes.object.isRequired,
  };

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const { onActionFieldUpdate, index, action, validationErrors, automationIdPrefix, features, getPermissionLevel } = this.props;
    const percentageMode = this.props.action.get(constants.ACTION_TYPE) === schemaConstants.DISCOUNT_PERCENT_OFF_ON_ENTIRE_TICKET_ACTION;
    const hasFeature = getPermissionLevel(appConstants.FEATURES_SHOPPING_CART_GET_MEMBER_BENEFITS_AND_REDEEM) !== constants.FEATURE_LEVEL.NONE;
    const excludeItemsPopulation = action.get(constants.EXCLUDE_ITEMS_POPULATION);

    return (
      <Grid className={`deal-action ${this.props.action.get(constants.ACTION_TYPE)}`}>
        <Grid.Row>
          <Grid.Column width={16}>
            <div className="deal-fields">
              <div className="line">
                <span className="head-of-sentence">{formatMessage({ id: 'deals.give' })}</span>
                {percentageMode ? <PercentageInputField
                  allowOnlyPositive
                  name={constants.DISCOUNT_VALUE}
                  onChange={(e, data) => onActionFieldUpdate(data.name, data.value, index)}
                  value={parseFloat(action.get(constants.DISCOUNT_VALUE))}
                  prefix={`${automationIdPrefix}.percent`}
                  disabled={false}
                  error={validationErrors ? validationErrors.get(constants.DISCOUNT_VALUE) : null}
                /> : <CurrencyInputField
                  name={constants.DISCOUNT_VALUE}
                  onChange={(e, data) => onActionFieldUpdate(data.name, data.value, index)}
                  value={parseFloat(action.get(constants.DISCOUNT_VALUE))}
                  prefix={`${automationIdPrefix}.amount`}
                  disabled={false}
                  error={validationErrors ? validationErrors.get(constants.DISCOUNT_VALUE) : null}
                />
              }
                <span className="inside-sentence">{formatMessage({ id: 'deals.discountOnEntireTicket' })}</span>
              </div>
              <div className="line">
                { hasFeature ?
                  <SingleLineAccordion
                    automationIdPrefix={`${automationIdPrefix}.excludeItems`}
                    title={formatMessage({ id: 'activity.action.discountOnEntireTicket.exclude.items.title' })}
                    initialCollapseState={!this.props.action.get(constants.EXCLUDE_ITEMS_POPULATION_STATUS)}
                  >
                    <CheckBox
                      className="accordion-content exclude-items"
                      name={constants.EXCLUDE_ITEMS_POPULATION_STATUS}
                      checked={action.get(constants.EXCLUDE_ITEMS_POPULATION_STATUS)}
                      data-automation-id={`${automationIdPrefix}.excludeItems.checkbox`}
                      onChange={(e, data) => onActionFieldUpdate(constants.EXCLUDE_ITEMS_POPULATION_STATUS, data.checked, index)}
                      label={formatMessage({ id: 'activity.action.discountOnEntireTicket.exclude.items.checkbox' })}
                    />
                    <ItemsPopulation
                      disabled={!action.get(constants.EXCLUDE_ITEMS_POPULATION_STATUS)}
                      disableAny
                      position="bottom left"
                      index={index}
                      data={excludeItemsPopulation.toJS()}
                      onUpdate={(e, data) => onActionFieldUpdate(constants.EXCLUDE_ITEMS_POPULATION, data, index)}
                      automationIdPrefix={`${automationIdPrefix}.excludeItems`}
                      itemsPopulationValidationErrors={validationErrors ? validationErrors.get(constants.EXCLUDE_ITEMS_POPULATION) : null}
                      features={this.props.features}
                      posFeature={appConstants.FEATURES_SHOPPING_CART_GET_MEMBER_BENEFITS_AND_REDEEM}
                    />
                  </SingleLineAccordion> :
                  (<DisabledItemPosRestriction>
                    {formatMessage({ id: 'activity.action.discountOnEntireTicket.exclude.items.title' })}
                  </DisabledItemPosRestriction>)
                }
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default withAuthorization(injectIntl(DiscountOnEntireTicket));
