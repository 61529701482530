/*

  DropdownWrapper

  This component overrides some functionallity of Semantic UI React dropdown.
  Using it is similar to Semantic UI React dropdown, except to:

    1. Use `{ text: '', value: '' }` options structure
        (similar to the source code)

    2. Use prefix and name props for generating data-automation-id (prefix.name.optionValue)

    3. Use hideTrigger boolean prop for hide the trigger of the dropdown

  props:
    options{array}        - required  - the options which will be rendered as dropdown items
    hideTrigger{boolean}
    prefix{string}        - required  - the prefix of data-automation-id
    name{string}          - required  - the name of data-automation-id (after prefix)
    onSelectOption        - required  - call this function when dropdown value is changed

*/

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Dropdown, Icon, Loader } from 'semantic-ui-react';
import _ from 'lodash';
import { injectIntl } from 'react-intl';

import * as constants from '../../../features/Activity/activity.constants';
import DisabledItemPosRestriction from '../DisabledItem/DisabledItemPosRestriction';
import './DropdownWrapper.styles.less';
import ValidationErrorMessage from '../ValidationErrorMessage/ValidationErrorMessage';
import { getScrollParent } from '../../utils/domUtils';
import { isChromeBrowser } from '../../utils/browserUtils';

class DropdownWrapper extends React.PureComponent {

  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    displayDropDownWithSingleOption: PropTypes.bool,
    error: PropTypes.string,
    hideTrigger: PropTypes.bool,
    index: PropTypes.number,
    intl: PropTypes.object.isRequired,
    loadingData: PropTypes.bool,
    loadingDataMessage: PropTypes.string,
    multiple: PropTypes.bool,
    name: PropTypes.string,
    onSelectOption: PropTypes.func.isRequired,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    prefix: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array
    ]),
    showHint: PropTypes.bool,
    hintText: PropTypes.string,
    scrollIntoViewIfNeeded: PropTypes.bool,
    allowAdditions: PropTypes.bool,
    search: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    hideTrigger: true, /* use when you want to hide the dropdown trigger when dropdown is opened */
    selection: true, /* by default the dropdown is selectable */
    displayDropDownWithSingleOption: true, /* by default, if there only one item in the selection, the dropdown is not shown, instead, a non-selectable single item is shown */
    scrollIntoViewIfNeeded: true,
    allowAdditions: false,
    search: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false
    };
    this.dropDownRef = React.createRef();
  }

  onDropdownOpen = () => {
    this.setState({
      isDropdownOpen: true
    }, () => {
      if (this.props.scrollIntoViewIfNeeded
          && isChromeBrowser()
          && this.dropDownRef
          && this.dropDownRef.current) {
        const dropDownEl = this.dropDownRef.current.ref;
        const dropDownContentEl = document.querySelector('.dropdown-wrapper .menu');

        const rect = dropDownContentEl.getBoundingClientRect();
        const scrollParent = getScrollParent(dropDownContentEl);
        if (scrollParent === document.body) {
          const innerHeight = window.innerHeight;
          if (rect.bottom > innerHeight) {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            window.scrollTo({ top: ((rect.top + rect.height + scrollTop) - innerHeight) + 23, behavior: 'smooth' });
          }
        } else {
          const scrollParentRect = scrollParent.getBoundingClientRect();
          const parentHeight = scrollParentRect.height;
          if (rect.bottom > (scrollParent.scrollTop + parentHeight) - scrollParentRect.top) {
            const scrollTop = ((rect.top + rect.height + scrollParent.scrollTop) - parentHeight - scrollParentRect.top) + 23;
            scrollParent.scrollTo({ top: scrollTop, behavior: 'smooth' });
          }
        }
      }
      if (this.props.onOpen) {
        this.props.onOpen();
      }
    });
  };

  onDropdownClose = () => {
    this.setState({
      isDropdownOpen: false
    });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  /**
   * add data-automation-id to each option
   * display loading / no items message when options list is unavailable
   * add divider where needed
   * @returns {*}
   */
  get parsedOptions() {
    const { formatMessage } = this.props.intl;
    if (!this.props.options) {
      return [];
    }
    if (this.props.loadingData) {
      return [{
        key: 'loading',
        text: (<span>
          <Loader active size="tiny" className="dropdown-inline-loader" />
          {this.props.loadingDataMessage ?
            this.props.loadingDataMessage :
            this.props.intl.formatMessage({ id: 'general.status.loading' })}
        </span>),
        value: 'loading',
        'data-automation-id': `${this.props.prefix}.loading`,
        disabled: true
      }];
    } else if (!this.props.options.length) {
      return [{
        key: 'noItems',
        text: this.props.intl.formatMessage({ id: 'general.no.items' }),
        value: 'noItems',
        'data-automation-id': `${this.props.prefix}.noItems`,
        disabled: true
      }];
    }
    const returnOptions = this.props.options.map((op, index) => {
      const obj = Object.assign({}, op);
      if (!obj.key) {
        obj.key = `option_${index.toString()}`;
      }
      if ((obj.type && obj.type.name) && (obj.type.name === 'DropdownDivider' || obj.type.name === 'DropDownItem')) {
        return obj;
      }
      obj['data-automation-id'] = `${this.props.prefix}.${op.value}`;
      if (!featureSupported(op)) {
        if (op.features.find((f) => f.hide)) {
          return null;
        }
        if (op.content) {
          obj.content = <DisabledItemPosRestriction>{op.content}</DisabledItemPosRestriction>;
        }
        if (op.text) {
          obj.text = <DisabledItemPosRestriction>{op.text}</DisabledItemPosRestriction>;
        }
        obj.disabled = true;
      }
      if (op.disabledcondition) {
        obj.disabled = true;
        console.warn("Total Spend on Gift not supported well on 2.8, do not use, use a a case instead")
      }
      delete obj.category; // remove category from option to avoid Unknown Prop warning
      delete obj.features; // remove features from option to avoid Unknown Prop warning
      delete obj.isAutoTag; // remove isAutoTag from option to avoid Unknown Prop warning
      return obj;
    });
    if (this.props.showHint && this.props.hintText) {
      const hintObj = { [constants.KEY]: constants.HINT_OPTION, [constants.TEXT]: formatMessage({ id: `businessStrategy_${this.props.hintText}` }), [constants.VALUE]: null, disabled: true, className: constants.HINT_OPTION };
      returnOptions.push(hintObj);
    }
    return returnOptions.filter((op) => op !== null);
  }

  render() {

    const { formatMessage } = this.props.intl;
    // noinspection JSUnusedLocalSymbols
    const { className, disabled, displayDropDownWithSingleOption, error, hideTrigger, loadingData, loadingDataMessage,
      onSelectOption, name, prefix, value, placeholder, scrollIntoViewIfNeeded, ...dropdownProps } = this.props;
    const parsedOptions = this.parsedOptions;
    delete dropdownProps.index;
    delete dropdownProps.intl;
    delete dropdownProps.options;
    delete dropdownProps.hintText;
    delete dropdownProps.showHint;
    delete dropdownProps.autoCompleteOptions;
    delete dropdownProps.hasAutoComplete;
    const dropDownClassName = classNames(className, { hideTrigger, 'inputFieldError': !_.isNil(error) });
    const singleElementClassName = classNames(className, 'selected-option single-option ui selection dropdown', { 'disabled': dropdownProps.disabled });
    const errorMessage = error ? formatMessage({ id: error }) : '';

    return (
      <div className="dropdown-wrapper">
        {(displayDropDownWithSingleOption || (parsedOptions.length !== 1)) && (
          <Dropdown
            {...dropdownProps}
            disabled={disabled || loadingData}
            className={dropDownClassName}
            icon={<Icon className="como-ic-triangle-down" />}
            name={name}
            value={loadingData ? 'loading' : value}
            onChange={onSelectOption}
            options={parsedOptions}
            selectOnNavigation={false}
            selectOnBlur={false}
            data-automation-id={prefix}
            open={this.state.isDropdownOpen}
            onOpen={this.onDropdownOpen}
            onClose={this.onDropdownClose}
            placeholder={this.props.placeholder || formatMessage({ id: 'dropdown.select' })}
            allowAdditions={this.props.allowAdditions}
            search={this.props.allowAdditions || this.props.search}
            ref={this.dropDownRef}
          />)}
        {(!displayDropDownWithSingleOption && parsedOptions.length === 1) && (
          <p className={singleElementClassName} data-automation-id={prefix}>{parsedOptions[0].text}</p>)}
        {error ? (<ValidationErrorMessage
          errorMessage={errorMessage}
          dataAutomationId={`${prefix}.error.message`}
        />) : null}
      </div>
    );
  }
}

export default injectIntl(DropdownWrapper);

/**
 * prepare array that will be sent to the dropdown component
 * @param {object} options - immutable map of options
 * @param {string} textField - the name of the label property of the value type
 * @returns {any | *}
 */
export const getOptionsListFromMap = (options, textField) =>
  options.toSeq().map((value, key) => ({ key, text: value.get(textField), value: key })).toArray();

export const Divider = Dropdown.Divider;

/**
 * Checks whether the given option's feature are supported
 * @param option
 * @returns {boolean}
 */
export const featureSupported = (option) => {
  const features = option[constants.FEATURES];
  if (features) {
    return !features.some((feature) => feature[constants.FIELD_LEVEL_KEY] === constants.FEATURE_LEVEL.NONE ||
      feature[constants.FIELD_LEVEL_KEY] === constants.FEATURE_LEVEL.READ);
  }
  return true;
};
