import _ from 'lodash';
import * as routes from '../../constants/route.contants';
import * as constants from './businessCenter.constants';
import * as appConstants from '../App/app.constants';

export function getEntityIndexById(entities, keyName, id) {

  if (_.isNil(entities) || !_.isString(keyName) || _.isNil(id)) {
    return null;
  }

  return entities
    .findIndex((entity) => entity.get(keyName) === id);
}

export function adjustNewAndOldFilters(newMap, oldMap) {
  for (let [key, value] of newMap) {
    //console.log(key + " = " + value);
    if (oldMap.has(key)) {
      newMap = newMap.set(key, oldMap.get(key));
    }
  }
  return newMap;
}

export function getCampaignsActivityTags(campaigns) {
  const allActivityTags = [];
  campaigns.forEach((campaign) => {
    campaign.activities.forEach((activity) => {
      if (activity.activityTags && activity.activityTags.length > 0) {
        allActivityTags.push(...activity.activityTags);
      }
    });
  });
  return allActivityTags;
}

export function getSideNavbarSettings(formatMessage, location, hasFullPermission, getPermissionLevel) {
  const pointShopPermissionLevel = getPermissionLevel ? getPermissionLevel(appConstants.FEATURE_NAME_POINTS_SHOP) : appConstants.FEATURE_LEVEL_FULL;
  const promoCodePermissionLevel = getPermissionLevel ? getPermissionLevel(appConstants.FEATURE_NAME_PROMO_CODES) : appConstants.FEATURE_LEVEL_FULL;
  const aiCampaignPermissionLevel = getPermissionLevel ? getPermissionLevel(appConstants.FEATURE_NAME_AI_CAMPAIGN) : appConstants.FEATURE_LEVEL_FULL;
  const baseNavbarSettings = [
    {
      id: constants.BUSINESS_CENTER_NAV,
      text: formatMessage({ id: 'side-navbar.campaign_center' }),
      target: { search: location.search, pathname: routes.BUSINESS_CENTER_ROUTE },
      automationId: 'navigation.campaign.center',
      icon: 'como-svg-side-nav-icon-campaigns'
    }
  ];

  if (hasFullPermission && hasFullPermission(appConstants.FEATURE_NAME_CAMPAIGN_CENTER) && aiCampaignPermissionLevel !== appConstants.FEATURE_LEVEL_NONE) {
    baseNavbarSettings.push({
      id: constants.MICRO_CAMPAIGNS_NAV,
      text: formatMessage({ id: 'side-navbar.micro_campaign_center' }),
      target: {
        search: location.search,
        pathname: aiCampaignPermissionLevel === appConstants.FEATURE_LEVEL_READ ? routes.UPGRADE_ROUTE.replace('/:id', routes.MICRO_CAMPAIGNS_ROUTE) : routes.MICRO_CAMPAIGNS_ROUTE
      },
      automationId: 'navigation.micro.campaign.center',
      icon: 'como-svg-side-nav-icon-micro-campaign',
      disable: aiCampaignPermissionLevel === appConstants.FEATURE_LEVEL_READ
    });
  }

  if (hasFullPermission && !hasFullPermission(appConstants.FEATURE_NAME_CAMPAIGN_CENTER) && hasFullPermission(appConstants.FEATURE_ACTIONS_ON_BULK_OF_MEMBER)) {
    return baseNavbarSettings;
  }
  baseNavbarSettings.push({
    id: constants.GIFTS_NAV,
    text: formatMessage({ id: 'side-navbar.gifts' }),
    target: { search: location.search, pathname: routes.GIFTS_ROUTE },
    automationId: 'navigation.gifts',
    icon: 'como-svg-side-nav-icon-gifts'
  });
  if (promoCodePermissionLevel !== appConstants.FEATURE_LEVEL_NONE) {
    baseNavbarSettings.push({
      id: constants.PROMO_CODES_NAV,
      text: formatMessage({ id: 'side-navbar.promo-codes' }),
      target: {
        search: location.search,
        pathname: promoCodePermissionLevel === appConstants.FEATURE_LEVEL_READ ? routes.UPGRADE_ROUTE.replace('/:id', routes.PROMO_CODES_ROUTE) : routes.PROMO_CODES_ROUTE
      },
      automationId: 'navigation.promo-codes',
      icon: 'como-svg-side-nav-icon-promo-codes',
      disable: promoCodePermissionLevel === appConstants.FEATURE_LEVEL_READ
    });
  }
  baseNavbarSettings.push({
    id: constants.EMAILS_NAV,
    text: formatMessage({ id: 'side-navbar.emails' }),
    target: { search: location.search, pathname: routes.EMAILS_ROUTE },
    automationId: 'navigation.emails',
    icon: 'como-svg-side-nav-icon-email'
  });
  if (pointShopPermissionLevel !== appConstants.FEATURE_LEVEL_NONE) {
    baseNavbarSettings.push({
      id: constants.POINT_SHOP,
      text: formatMessage({ id: 'side-navbar.point-shop' }),
      target: {
        search: location.search,
        pathname: pointShopPermissionLevel === appConstants.FEATURE_LEVEL_READ ? routes.UPGRADE_ROUTE.replace('/:id', routes.POINT_SHOP_ROUTE) : routes.POINT_SHOP_ROUTE
      },
      automationId: 'navigation.point-shop',
      icon: 'como-svg-side-nav-icon-point-shop',
      disable: pointShopPermissionLevel === appConstants.FEATURE_LEVEL_READ
    });
  }
  return baseNavbarSettings;
}
