import React from 'react';
import { Button, Dimmer, Grid, Icon, Loader, Table, Transition, Dropdown } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PopupWrapper from '../../../common/components/PopupWrapper/PopupWrapper';
import Files from './Files';

import DataAndBiContainer from '../dataAndBi.container';

import withAuthorization from '../../../common/helpers/authorization';

import './dataExport.styles.less';

import * as reducerConstants from '../../../constants/reducer.constants';
import * as routeConstants from '../../../constants/route.contants';
import * as appConstants from '../../App/app.constants';
import * as dataAndBiConstants from '../dataAndBi.constants';

import * as actions from '../dataAndBi.actions';
import { actions as toastrActions } from '../../../common/components/ReduxToast';

import * as selectors from '../dataAndBi.selectors';
import * as appSelectors from '../../App/selectors';

import dataAndBiSaga from '../dataAndBi.saga';
import injectSaga from '../../../utils/injectSaga';

import { emailOptionsStatic, ReportNames, ReportQueryNames } from './constants';

export class DataExport extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  static propTypes = {
    intl: PropTypes.object,
    features: PropTypes.array,
    actions: PropTypes.object.isRequired,
    config: PropTypes.object,
    filesData: PropTypes.object,
    exportActivated: PropTypes.object,
    supportEmails: PropTypes.object,
    hasFullPermission: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      reportKey: undefined,
      selectedFileName: undefined,
    };

    this.handleOnOpen = this.handleOnOpen.bind(this);
    this.handleCancelWithButton = this.handleCancelWithButton.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChecked = this.handleChecked.bind(this);

    const featureDisabled = this.getDataExportFeature(props.features || {});

    if (featureDisabled) {
      return;
    }

    this.props.actions.getConfig();
  }

  componentWillMount() {
    if (!this.props.hasFullPermission(appConstants.FEATURE_NAME_DATA_EXPORT)) {
      window.location.href = `${routeConstants.FORBIDDEN_ROUTE + window.location.search}`;
    }
  }

  getDataExportFeature = (features) => {
    const dataExportFeature = features.find((f) => f.get(appConstants.FEATURE_NAME) === appConstants.FEATURE_NAME_DATA_EXPORT);
    const dataExportLevel = dataExportFeature ? dataExportFeature.get(appConstants.FEATURE_LEVEL) : null;

    return dataExportLevel === 'None';
  }

  handleOnOpen = (reportKey) => {
    this.props.actions.getFiles(ReportQueryNames[reportKey]);
    this.setState({ reportKey });
  };

  handleCancelWithButton = () => {
    this.closePopup();
  };

  closePopup = () => {
    this.setState({ reportKey: undefined });
  };

  handleSave = (objectName) => {
    this.props.actions.getSignedUrl(objectName);
    this.closePopup();
  };

  handleChecked = (checkId) => {
    this.setState({ selectedFileName: checkId });
  };

  notificationAction(message) {
    return this.props.actions.addToast({
      message,
      options: {
        showCloseButton: true,
        className: 'simple-hub2-toastr-notification',
        removeOnHover: true
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { intl, exportActivated } = this.props;
    const { formatMessage } = intl;

    const { activating } = exportActivated.toJSON();
    const { activating: prevActivating } = prevProps.exportActivated.toJSON();

    if (prevActivating && !activating) {
      this.notificationAction(formatMessage({ id: 'data-and-bi.export.feature.activate.success.msg' }));
    }
  }

  render() {
    const { intl, config, filesData, exportActivated, features, supportEmails } = this.props;
    const { reportKey, selectedFileName } = this.state;

    const featureDisabled = this.getDataExportFeature(features || {});

    const { formatMessage } = intl;
    const { data: reports, configFetched } = config.toJSON();
    const { data: files, filesDataFetched } = filesData.toJSON();
    const { activating } = exportActivated.toJSON();

    const { deletionPoliciesPerExport, enterpriseClient } = reports || {};

    const reportKeys = deletionPoliciesPerExport ? Object.keys(deletionPoliciesPerExport).filter((x) => !x.includes(dataAndBiConstants.LARGE_TABLE_QUERY)) : [];
    const haveReports = reportKeys.length > 0;
    const haveFiles = files.length > 0;

    const isDisabled = featureDisabled || (configFetched && !haveReports) || enterpriseClient;
    const inactive = !featureDisabled && !haveReports;

    const headingKey = enterpriseClient ? 'enterpriseClient' : inactive ? 'inactive' : 'disabled';
    const notActive = inactive && !enterpriseClient;

    const supportChannels = supportEmails.toJS();

    const emailOptions = supportChannels ? supportChannels.map((x) => ({ key: x.email, value: `mailto:${x.email}`, text: x.email })) || emailOptionsStatic : emailOptionsStatic;

    return (
      <DataAndBiContainer
        className={'dataExport'}
        headClassName={'data-export'}
        translationKey={'data-and-bi.export.title'}
        noTitle={isDisabled}
      >
        {isDisabled ? (
          <Grid.Row>
            <Grid.Column width={16}>
              <div className="no-config">
                <div className="activate-data-export">
                  <p className={`quote ${enterpriseClient ? 'enterprise' : ''}`}>
                    <span className={'author'}>
                      {formatMessage({ id: `data-and-bi.export.feature.${headingKey}.heading` })}
                    </span>
                  </p>
                  <p className="button-title">{formatMessage({ id: `data-and-bi.export.feature.${headingKey}.sub-heading` })}</p>
                  <Dropdown
                    trigger={
                      <div className="button-wrap">
                        <div>
                          <div>
                            <Button
                              disabled={activating}
                              data-automation-id={`data-and-bi.export.feature.${headingKey}.btn`}
                              onClick={() => {
                                if (notActive) {
                                  this.props.actions.activateExport();
                                }
                              }}
                            >
                              <Icon className="como-svg-icon-email-white" />
                              <span>{formatMessage({ id: `general.button.${notActive ? 'activate' : 'contactUs'}` })}</span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    }
                    options={notActive ? [] : emailOptions}
                    closeOnChange
                    onChange={(e, { value }) => {
                      window.location.href = value;
                    }}
                    icon={null}
                  />
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        ) : (
          <div className={'data-export-wrapper'}>
            <div className="stick-to-header-wrapper">
            </div>
            <div className="inner-data-export-wrapper">
              {!configFetched ? (
                <Dimmer inverted active>
                  <Loader active />
                </Dimmer>
              ) : (
                <Table className="ui single line fixed selectable very basic table">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className="list-column-name activity-type-column">{formatMessage({ id: 'data-and-bi.export.report.type', defaultMessage: 'Report Type' })}</Table.HeaderCell>
                      <Table.HeaderCell className="list-column-name activity-name-column">{formatMessage({ id: 'data-and-bi.export.report.deletionTime', defaultMessage: 'Deletion Time (Days)' })}</Table.HeaderCell>
                      <Table.HeaderCell className="activity-context-menu-column" />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {reportKeys.map((key) => {
                      const value = deletionPoliciesPerExport[key];

                      return (
                        <Table.Row className={'rule clickable'} key={`${key}-data-export-row`}>
                          <Table.Cell className="listcontent">{ReportNames[key]}</Table.Cell>
                          <Table.Cell className="listcontent">{value}</Table.Cell>
                          <Table.Cell width="1">
                            <PopupWrapper
                              className="data-export-popup"
                              onOpen={() => this.handleOnOpen(key)}
                              onClose={this.handleCancelWithButton}
                              open={reportKey === key}
                              on="click"
                              fixed
                              position="bottom left"
                              trigger={(
                                <div className="inline-div">
                                  <Icon size="large" className={'como-ic-export'} />
                                  <span className="listcontent" >
                                  Export
                                </span>
                                </div>
                              )}
                            >
                              <Transition
                                animation="shake"
                                duration={200}
                                transitionOnMount={false}
                                mountOnShow={false}
                              >
                                <React.Fragment>
                                  <div className={'data-export-popup-listing-section'}>
                                    {!filesDataFetched ? (
                                      <Loader active />
                                    ) : haveFiles ? (
                                      <Files files={files} reportKey={key} selectedValue={selectedFileName} onSelect={this.handleChecked} />
                                    ) : formatMessage({ id: 'data-and-bi.export.no-files' })}
                                  </div>
                                  <div className="buttons-section">
                                    <Button data-automation-id="cancel.button" basic className="link modal-button" onClick={this.handleCancelWithButton}>
                                      {formatMessage({ id: `general.button.${haveFiles ? 'cancel' : 'close'}` })}
                                    </Button>
                                    {haveFiles && (
                                      <Button disabled={!selectedFileName} data-automation-id="save.button" className="modal-button" onClick={() => this.handleSave(selectedFileName)}>
                                        {formatMessage({ id: 'general.button.download' })}
                                      </Button>
                                    )}
                                  </div>
                                </React.Fragment>
                              </Transition>
                            </PopupWrapper>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}
            </div>
          </div>
        )}
      </DataAndBiContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  config: selectors.getConfig(state),
  filesData: selectors.getFiles(state),
  exportActivated: selectors.getActivated(state),
  features: appSelectors.getFeatures(state),
  supportEmails: appSelectors.getBusinessSupportEmails(state),
});

function mapDispatchToProps(dispatch) {
  const creators = Object.assign({},
    {
      getConfig: actions.getConfig,
      getFiles: actions.getFiles,
      getSignedUrl: actions.getSignedUrl,
      activateExport: actions.activateExport,
      addToast: toastrActions.add,
    });

  return {
    actions: bindActionCreators(creators, dispatch),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withDataAndBiSaga = injectSaga({ key: reducerConstants.DATA_AND_BI, saga: dataAndBiSaga });

export default compose(withConnect, withDataAndBiSaga, withAuthorization, injectIntl)(DataExport);
