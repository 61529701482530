import { fromJS } from 'immutable';
import * as constants from './goals.constants';
import adminReducer, { initState as initAdminState } from './Admin/admin.reducer';

export const initGoal = fromJS({
  [constants.GOAL_TOOLS]: []
});

export const initialState = fromJS({
  [constants.DATA]: [],
  [constants.GOAL]: initGoal,
  [constants.GOALS_ADMIN]: initAdminState
});

const goalsReducer = (state = initialState, action) => {
  let newState;

  const { data, type, message } = action;

  switch (type) {

    case constants.GET_GOALS_SUCCESS:

      newState = state.set(constants.DATA, fromJS(data));
      break;

    case constants.GET_GOAL_SUCCESS:
      newState = state.set(constants.GOAL, fromJS(data));
      break;

    case constants.GET_GOAL_ERROR:
    case constants.CREATE_BUNDLE_ERROR:
    case constants.GET_GOALS_ERROR:
      console.error('message: ', message);
      newState = state;
      break;

    case constants.CLEAR_GOAL:
      newState = state.set(constants.GOAL, initGoal);
      break;

    default:
      newState = state.set(constants.GOALS_ADMIN, adminReducer(state.get(constants.GOALS_ADMIN), action));
  }

  return newState;
};

export default goalsReducer;
