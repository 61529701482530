import { createSelector } from 'reselect';
import * as constants from './genericAction.contants';
import builtInAppScreens from '../AppScreens/builtInLayoutScreens';
import * as appSelectors from '../App/selectors';


export const getCustomScreens = (state) => state.getIn([constants.GENERIC_ACTION, constants.CUSTOM_APP_SCREENS]);
export const getCustomWebViews = (state) => state.getIn([constants.GENERIC_ACTION, constants.CUSTOM_WEB_VIEWS]);

const getCatalogs = (state) => state.getIn([constants.GENERIC_ACTION, constants.CATALOGS]);

export const getScreensListViewItems = createSelector(
  [getCustomScreens, appSelectors.getTranslations],
  (customScreens, translations) => {
    if (customScreens === undefined || customScreens === null) {
      return null;
    }
    const builtIn = builtInAppScreens.map((screen) => ({
      value: screen.id,
      text: translations[screen.title],
    })).sort((a, b) => a.text.localeCompare(b.text));
    const custom = customScreens.map((screen) => ({
      value: `Custom_${screen.get(constants.CUSTOM_APP_SCREEN_ID)}`,
      text: screen.get(constants.NAME)
    })).sort((a, b) => a.text.localeCompare(b.text));
    return { builtIn, custom };
  });

export const getWebViewsListViewItems = createSelector(
  [getCustomWebViews], (customWebViews) => {
    if (customWebViews === undefined || customWebViews === null) {
      return null;
    }
    return customWebViews.map((view) => ({
      value: view.get(constants.CUSTOM_WEB_VIEW_CONSTANT_ID).toString(),
      text: view.get(constants.NAME)
    })).toJS();
  });

export const getCatalogListViewItems = createSelector(
  [getCatalogs], (catalogs) => {
    if (catalogs === undefined || catalogs === null) {
      return null;
    }
    return catalogs.map((catalog) => ({
      value: catalog.get(constants.CATALOG_CONSTANT_ID).toString(),
      text: catalog.get(constants.CATALOG_TITLE)
    })).toJS();
  });
