import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import SimpleCard from '../EmptyCard/EmptyCard';
import './ColorBorderCard.styles.less';

const AUTOMATION_NAME = 'card.color-border';

const ColorBorderCard = (props) => {
  const { active, ...restProps } = props;

  return (
    <SimpleCard
      className={classNames('card--color-border', { active })}
      automationId={AUTOMATION_NAME}
      {...restProps}
    />
  );
};

ColorBorderCard.propTypes = {
  automationId: PropTypes.string.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ColorBorderCard.defaultProps = {
  active: false
};

export default ColorBorderCard;
