import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import classNames from 'classnames';
import * as activityConstants from '../activity.constants';

class ActivityStatus extends React.PureComponent {
  static propTypes = {
    status: PropTypes.string.isRequired,
    intl: PropTypes.object,
    messagesNamespace: PropTypes.string.isRequired,
    statusIconOverrideFactory: PropTypes.func
  };

  render() {
    const { status, intl, statusIconOverrideFactory } = this.props;
    let classNamesMap = {
      'blue': status === activityConstants.ACTIVITY_STATUS_ACTIVE,
      'como-ic-ok': status === activityConstants.ACTIVITY_STATUS_ACTIVE,
      'como-ic-draft': status === activityConstants.ACTIVITY_STATUS_DRAFT,
      'como-ic-deactivate': status === activityConstants.ACTIVITY_STATUS_STOPPED || status === activityConstants.ACTIVITY_STATUS_INACTIVE,
      'como-ic-expired': status === activityConstants.ACTIVITY_STATUS_COMPLETED,
      'como-ic-unavailable': status === activityConstants.ACTIVITY_STATUS_INVALID,
      'como-ic-future': status === activityConstants.ACTIVITY_STATUS_FUTURE,
    };

    if (statusIconOverrideFactory) {
      classNamesMap = { ...classNamesMap, ...statusIconOverrideFactory(status) };
    }

    const statusIconClass = classNames(classNamesMap);

    return (
      <p className="activities-status">
        <Icon size="large" className={statusIconClass} />
        <span className="listcontent" >
          {intl.formatMessage({ id: `${this.props.messagesNamespace}.activity.status.${status}` })}
        </span>
      </p>
    );
  }
}

export default injectIntl(ActivityStatus);
