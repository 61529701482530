import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Input } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import CurrencyInputField from '../../../../../common/components/FormInputFields/CurrencyInputField/CurrencyInputField';
import ItemsPopulation from '../../itemsPopulation/itemsPopulation.container';
import * as constants from '../../../activity.constants';
import * as schemaConstants from '../../../activitySchema/activitySchema.constants';
import * as appConstants from '../../../../App/app.constants';

class NewPriceToSpecificItems extends React.PureComponent {

  static propTypes = {
    action: PropTypes.object,
    onActionFieldUpdate: PropTypes.func.isRequired,
    index: PropTypes.number,
    validationErrors: PropTypes.object,
    automationIdPrefix: PropTypes.string.isRequired,
    features: PropTypes.object,
    isSimpleMode: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false
    };
    this.handlePopupOpen = this.handlePopupOpen.bind(this);
    this.handlePopupClose = this.handlePopupClose.bind(this);
  }

  handlePopupOpen() {
    this.setState({ isPopupOpen: true });
  }

  handlePopupClose() {
    this.setState({ isPopupOpen: false });
  }

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const { onActionFieldUpdate, index, action, validationErrors, automationIdPrefix } = this.props;
    const freeItemsMode = this.props.action.get(constants.ACTION_TYPE) === schemaConstants.DISCOUNT_FREE_ITEMS_ACTION;
    return (
      <Grid className={`deal-action ${this.props.action.get(constants.ACTION_TYPE)}`}>
        <Grid.Row>
          <Grid.Column width={16}>
            <div className="deal-fields">
              <span className="head-of-sentence text-aligned-to-input-wrapper">
                {formatMessage({ id: freeItemsMode ? 'deals.make.each' : 'deals.set.the.price.of' })}
              </span>
              <ItemsPopulation
                disableAny
                position="bottom left"
                index={index}
                data={action.get(constants.DISCOUNT_ON).toJS()}
                onUpdate={(e, data) => onActionFieldUpdate(constants.DISCOUNT_ON, data, index)}
                automationIdPrefix={automationIdPrefix}
                itemsPopulationValidationErrors={validationErrors ? validationErrors.get(constants.DISCOUNT_ON) : null}
                features={this.props.features}
                posFeature={appConstants.FEATURES_SHOPPING_CART_GET_MEMBER_BENEFITS_AND_REDEEM}
                isSimpleMode={this.props.isSimpleMode}
              />
              <span className="inside-sentence text-aligned-to-input-wrapper">
                {formatMessage({ id: freeItemsMode ? 'deals.make.each.free' : 'deals.set.the.price.to' })}
              </span>
              {!freeItemsMode &&
                <CurrencyInputField
                  name={constants.DISCOUNT_VALUE}
                  onChange={(e, data) => onActionFieldUpdate(data.name, data.value, index)}
                  value={parseFloat(action.get(constants.DISCOUNT_VALUE))}
                  prefix={`${automationIdPrefix}.amount`}
                  disabled={false}
                  error={validationErrors ? validationErrors.get(constants.DISCOUNT_VALUE) : null}
                />
              }
              {!freeItemsMode &&
                <span className="inside-sentence text-aligned-to-input-wrapper">
                  {formatMessage({ id: 'deals.set.the.price.each' })}
                </span>
              }
              {this.props.isSimpleMode && <>
                <span style={{ paddingLeft: '70px', paddingRight: '15px' }}>{formatMessage({ id: 'simple.instructions' })}</span>
                <Input
                  name={constants.SIMPLE_DESCRIPTION}
                  onChange={(e, data) => onActionFieldUpdate(data.name, data.value, index)}
                  value={action.get(constants.SIMPLE_DESCRIPTION)}
                  prefix={`${automationIdPrefix}.${constants.SIMPLE_DESCRIPTION}`}
                  disabled={false}
                  placeholder={'Add Simple Description'}
                  data-automation-id={`${automationIdPrefix}.simpleDescription`}
                />
              </>}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default injectIntl(NewPriceToSpecificItems);

