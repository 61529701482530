import * as queryString from 'query-string';

export const parse = queryString.parse;
export const stringify = queryString.stringify;

export function removeParameter(qs, parameter) {
  // noinspection JSUnusedLocalSymbols
  const { [parameter]: _, ...restQueryString } = parse(qs);
  return stringify(restQueryString);
}

export function getLocationId(qs) {
  const { 'location_id': locationId } = parse(qs);
  return locationId;
}

export function getSearchSegment(qs) { // https://..../customer?location_id=6406&search=edpGth59DuQ6B5liZDVnIK4wAadhGpdoQRXmu4xZJf7joGVGEuvI8 (last part, serach=...)
  const { 'search': search } = parse(qs);
  return search;
}
