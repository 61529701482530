/*
 *
 * Members reducer
 *
 */

import { fromJS } from 'immutable';
import * as constants from './members.constants';
import * as appConstants from '../App/app.constants';
import * as activityConstants from '../Activity/activity.constants';

const initialState = fromJS({});

function membersReducer(state = initialState, action) {

  let newState;

  switch (action.type) {
    case appConstants.GET_CUSTOMER: {
      newState = state.set(constants.CUSTOMER_SEARCH, action.search);
      break;
    }

    case activityConstants.FILTER_MEMBERS_SUCCESS: {
      newState = state
        .set(constants.MEMBERS_TOUCHED_LOADED, true)
        .setIn([constants.MEMBERS_FILTERED_BY_COMMUNICATION_ACTIONS, activityConstants.FILTERED_POPULATION_COUNT], fromJS(action.data.filterByConditionsAndCommunication.count))
        .setIn([constants.MEMBERS_FILTERED_BY_COMMUNICATION_ACTIONS, activityConstants.FILTER_POPULATION_QUERY], fromJS(action.data.filterByConditionsAndCommunication.query));
      break;
    }

    case constants.GET_MEMBERS_FILTERED_BY_COMMUNICATION_ACTIONS_SUCCESS: {
      newState = state
        .set(constants.MEMBERS_TOUCHED_LOADED, true)
        .setIn([constants.MEMBERS_FILTERED_BY_COMMUNICATION_ACTIONS, activityConstants.FILTERED_POPULATION_COUNT], fromJS(action.touchedMembers.count))
        .setIn([constants.MEMBERS_FILTERED_BY_COMMUNICATION_ACTIONS, activityConstants.FILTER_POPULATION_QUERY], fromJS(action.touchedMembers.query));
      break;
    }

    case constants.SHOW_MULTIPLE_MEMBERS_POPUP: {
      newState = state
        .set(constants.MEMBERS_POPUP_STATUS, true)
        .set(constants.MEMBERS_POPUP_DATA, action.data.results)
        .set(constants.MEMBERS_POPUP_TOTAL, action.data.total)
        .set(constants.MEMBERS_SEARCH_QUERY, action.searchQuery);
      break;
    }

    case constants.HANDLE_MEMBER_POPUP_VISIBILITY: {
      newState = state.set(constants.MEMBERS_POPUP_STATUS, action.show);
      break;
    }

    case constants.CLEAR_SEARCH_QUERY: {
      newState = state.set(constants.MEMBERS_SEARCH_QUERY, '');
      break;
    }

    case activityConstants.CLEAR_DATA_ON_UNMOUNT: {
      newState = state
        .delete(constants.MEMBERS_TOUCHED_LOADED)
        .delete(constants.MEMBERS_FILTERED_BY_COMMUNICATION_ACTIONS);
      break;
    }

    default: {
      newState = state;
    }

  }

  return newState;

}

export default membersReducer;
