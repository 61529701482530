/*
 * AiConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

// Action types
export const AI = 'ai';
export const IS_LOADING = 'ai/AiPopover/IS_LOADING';
export const SET_LOADING_STATE = 'ai/AiPopover/SET_LOADING_STATE';
export const PROMPTS = 'ai/AiPopover/PROMPTS';
export const CLEAR_PROMPT = 'ai/AiPopover/CLEAR_PROMPT';
export const GET_PROMPT = 'ai/AiPopover/GET_PROMPT';
export const GET_PROMPT_SUCCESS = 'ai/AiPopover/GET_PROMPT_SUCCESS';
export const GET_PROMPT_FAIL = 'ai/AiPopover/GET_PROMPT_FAIL';
export const GET_PROMPT_ERROR = 'ai/AiPopover/GET_PROMPT_ERROR';
export const GET_PROMPT_ERROR_CODE = 'ai/AiPopover/GET_PROMPT_ERROR_CODE';

