import * as constants from './activitySchema.constants';
import * as appConstants from '../../App/app.constants';

export const openedApp = {
  name: constants.TRIGGER_OPENED_CLUB,
  group: constants.APP_GROUP,
  primary: false,
  allowOccurrences: false,
  features: [{ name: appConstants.FEATURES_APP }, appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const didNotOpenApp = {
  name: constants.TRIGGER_DID_NOT_OPEN_CLUB,
  group: constants.APP_GROUP,
  primary: false,
  allowOccurrences: false,
  features: [{ name: appConstants.FEATURES_APP }, appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const paidWithApp = {
  name: constants.TRIGGER_PAYS_WITH_APP,
  group: constants.APP_GROUP,
  primary: false,
  allowOccurrences: false,
  features: [{name: appConstants.FEATURES_APP}, appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const joinedApp = {
  name: constants.TRIGGER_JOINED_APP,
  group: constants.APP_GROUP,
  primary: false,
  allowOccurrences: false,
  deprecated: true,
  features: [{ name: appConstants.FEATURES_APP }]
};

export const formFilled = {
  name: constants.TRIGGER_FORM_FILLED,
  group: 'misc',
  primary: false,
  allowOccurrences: false,
  features: [{ name: appConstants.FEATURES_APP }]
};

export const claim = {
  name: constants.TRIGGER_CLAIM,
  group: constants.APP_GROUP,
  primary: false,
  allowOccurrences: false,
  features: [{ name: appConstants.FEATURES_APP }]
};

export const oneTimeCode = {
  name: constants.TRIGGER_ONE_TIME_CODE,
  group: 'codes',
  primary: false,
  allowOccurrences: false,
  features: [{ name: appConstants.FEATURES_APP }]
};

export const shareBudget = {
  name: constants.TRIGGER_SHARE_BUDGET,
  group: constants.APP_GROUP,
  primary: false,
  allowOccurrences: false,
  features: [{ name: appConstants.FEATURES_APP }]
};
export const userClaimSharedBudget = {
  name: constants.TRIGGER_USER_CLAIMED_SHARED_BUDGET,
  group: constants.BENEFITS_GROUP,
  primary: false,
  allowOccurrences: false,
  features: [appConstants.FEATURE_ESSENTIALS_OFF]
};
export const notifyUserSharedBudgetDone = {
  name: constants.TRIGGER_NOTIFY_USER_SHARED_BUDGET_DONE,
  group: constants.BENEFITS_GROUP,
  primary: false,
  allowOccurrences: false,
  features: [appConstants.FEATURE_ESSENTIALS_OFF]
};
export const receivedAsset = {
  name: constants.TRIGGER_RECEIVED_ASSET,
  group: constants.BENEFITS_GROUP,
  primary: false,
  allowOccurrences: false,
};

export const receivedAssetTriggerUserAction = {
  name: constants.TRIGGER_RECEIVED_ASSET,
  group: constants.BENEFITS_GROUP,
  primary: true,
  primaryOrder: 4,
  allowOccurrences: false,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const redeem = {
  name: constants.TRIGGER_REDEEM,
  group: constants.BENEFITS_GROUP,
  primary: true,
  primaryOrder: 7,
  allowOccurrences: false,
  features: [appConstants.FEATURE_ESSENTIALS_OFF]
};

export const redeemTriggerUserAction = {
  name: constants.TRIGGER_REDEEM,
  group: constants.BENEFITS_GROUP,
  primary: true,
  primaryOrder: 5,
  allowOccurrences: false,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const purchasedAsset = {
  name: constants.TRIGGER_PURCHASED_ASSET,
  group: constants.PURCHASES_GROUP,
  primary: false,
  allowOccurrences: false,
  features: [appConstants.FEATURE_ESSENTIALS_OFF]
};

export const punch = {
  name: constants.TRIGGER_PUNCH,
  group: constants.BENEFITS_GROUP,
  primary: true,
  primaryOrder: 5,
  allowOccurrences: false
};

export const punchFilter = {
  name: constants.TRIGGER_PUNCH,
  group: constants.BENEFITS_GROUP,
  primary: false,
  allowOccurrences: false,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const punchLastPunch = {
  name: constants.TRIGGER_PUNCH_LAST_PUNCH,
  group: constants.BENEFITS_GROUP,
  primary: false,
  allowOccurrences: false
};

export const punchMiddlePunch = {
  name: constants.TRIGGER_PUNCH_MIDDLE_PUNCH,
  group: constants.BENEFITS_GROUP,
  primary: false,
  allowOccurrences: false
};

export const exceededPunch = {
  name: constants.TRIGGER_EXCEEDED_PUNCH,
  group: constants.BENEFITS_GROUP,
  primary: false,
  allowOccurrences: false,
  deprecated: true,
};

export const receivedPoints = {
  name: constants.TRIGGER_RECEIVED_POINTS,
  group: constants.BENEFITS_GROUP,
  primary: false,
  allowOccurrences: false,
};

export const usedPoints = {
  name: constants.TRIGGER_USED_POINTS,
  group: constants.BENEFITS_GROUP,
  primary: false,
  allowOccurrences: false,
  features: [appConstants.FEATURE_ESSENTIALS_OFF]
};

export const receivedCredit = {
  name: constants.TRIGGER_RECEIVED_CREDIT,
  group: constants.BENEFITS_GROUP,
  primary: false,
  features: [appConstants.FEATURE_ESSENTIALS_OFF, appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const usedCredit = {
  name: constants.TRIGGER_USED_CREDIT,
  group: constants.BENEFITS_GROUP,
  primary: false,
  features: [appConstants.FEATURE_ESSENTIALS_OFF, appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const codeGenerator = {
  name: constants.TRIGGER_CODE_GENERATOR,
  group: 'codes',
  primary: false,
  allowOccurrences: false,
  features: [appConstants.FEATURE_ESSENTIALS_OFF]
};

export const joinedClub = {
  name: constants.TRIGGER_JOINED_CLUB,
  group: constants.MEMBERSHIP_GROUP,
  primary: true,
  primaryOrder: 2,
  allowOccurrences: false
};

export const clubMemberAnniversary = {
  name: constants.TRIGGER_CLUB_MEMBER_ANNIVERSARY,
  group: constants.MEMBERSHIP_GROUP,
  primary: false,
  primaryOrder: 2.1,
  allowOccurrences: false,
};

export const joinedClubUserAction = {
  name: constants.TRIGGER_JOINED_CLUB,
  group: constants.MEMBERSHIP_GROUP,
  primary: true,
  primaryOrder: 1,
  allowOccurrences: false
};
export const gaveConsentAction = {
  name: constants.TRIGGER_GAVE_CONSENT,
  group: constants.MEMBERSHIP_GROUP,
  primary: false,
  allowOccurrences: false
};

export const updateMembership = {
  name: constants.TRIGGER_UPDATE_MEMBERSHIP,
  group: constants.MEMBERSHIP_GROUP,
  primary: true,
  primaryOrder: 3,
  allowOccurrences: false,
  features: [appConstants.FEATURE_ESSENTIALS_OFF]
};

export const birthday = {
  name: constants.TRIGGER_MEMBER_HAS_BIRTHDAY,
  group: constants.MEMBERSHIP_GROUP,
  primary: true,
  allowOccurrences: false
};

export const anniversary = {
  name: constants.TRIGGER_MEMBER_HAS_ANNIVERSARY,
  group: constants.MEMBERSHIP_GROUP,
  primary: false,
  allowOccurrences: false,
  features: [appConstants.FEATURE_ESSENTIALS_OFF]
};

export const tagOperation = {
  name: constants.TRIGGER_TAG_OPERATION,
  group: constants.MEMBERSHIP_GROUP,
  primary: false,
  primaryOrder: 4,
  allowOccurrences: false,
  features: [appConstants.FEATURE_ESSENTIALS_OFF]
};

export const untagOperation = {
  name: constants.TRIGGER_UNTAG_OPERATION,
  group: constants.MEMBERSHIP_GROUP,
  primary: false,
  allowOccurrences: false,
  features: [appConstants.FEATURE_ESSENTIALS_OFF]
};

// todo - fix typo
export const consentedToTermsAndCodnitions = {
  name: constants.TRIGGER_CONSENTED_TO_TERMS_AND_CONDITIONS,
  group: constants.MEMBERSHIP_GROUP,
  primary: false,
  allowOccurrences: false
};

export const askToBeForgotten = {
  name: constants.TRIGGER_ASK_TO_BE_FORGOTTEN,
  group: constants.MEMBERSHIP_GROUP,
  primary: false,
  allowOccurrences: false
};


export const beaconSignal = {
  name: constants.TRIGGER_BEACON_SIGNAL,
  group: 'misc',
  primary: false,
  allowOccurrences: false,
  features: [{ name: appConstants.FEATURES_BEACON_SERVICE }, appConstants.FEATURE_ESSENTIALS_OFF, appConstants.FEATURE_BASIC_PLAN_EXCLUDE],
  deprecated: true
};

export const externalEvent = {
  name: constants.TRIGGER_EXTERNAL_EVENT,
  group: 'misc',
  primary: false,
  allowOccurrences: false,
  features: [appConstants.FEATURE_ESSENTIALS_OFF]
};

export const smsUnsubscribe = {
  name: constants.TRIGGER_SMS_UNSUBSCRIBE,
  group: 'misc',
  primary: false,
  allowOccurrences: false,
  features: [appConstants.FEATURE_ESSENTIALS_OFF]
};

export const emailUnsubscribe = {
  name: constants.TRIGGER_EMAIL_UNSUBSCRIBE,
  group: 'misc',
  primary: false,
  allowOccurrences: false,
  features: [appConstants.FEATURE_ESSENTIALS_OFF]
};

export const purchaseAnalyzed = {
  name: constants.TRIGGER_PURCHASE_ANALYZED,
  group: constants.PURCHASES_GROUP,
  primary: true,
  primaryOrder: 1,
  allowOccurrences: true
};

export const purchaseAnalyzedUserAction = {
  name: constants.TRIGGER_PURCHASE_ANALYZED,
  group: constants.PURCHASES_GROUP,
  primary: true,
  primaryOrder: 2,
  allowOccurrences: true
};

export const didntPurchase = {
  name: constants.TRIGGER_DIDNT_PURCHASE,
  group: 'purchase',
  primary: true,
  primaryOrder: 3,
  allowOccurrences: false
};

export const didntRedeem = {
  name: constants.TRIGGER_DIDNT_REDEEM,
  group: constants.BENEFITS_GROUP,
  primary: true,
  primaryOrder: 6,
  allowOccurrences: false,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};


export const didntReceiveAsset = {
  name: constants.TRIGGER_DIDNT_RECEIVE_ASSET,
  group: constants.BENEFITS_GROUP,
  primary: false,
  allowOccurrences: false,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const paysWithPointsAtPos = {
  name: constants.TRIGGER_PAYS_WITH_POINTS_AT_POS,
  group: constants.PURCHASES_GROUP,
  primary: false,
  allowOccurrences: false,
  features: [{ name: 'Pay With Budget' }, appConstants.FEATURE_ESSENTIALS_OFF]
};

export const paysWithCreditAtPos = {
  name: constants.TRIGGER_PAYS_WITH_CREDIT_AT_POS,
  group: constants.PURCHASES_GROUP,
  primary: false,
  allowOccurrences: false,
  features: [{ name: 'Pay With Budget' }, appConstants.FEATURE_ESSENTIALS_OFF]
};

export const paysWithAppCreditCard = {
  name: constants.TRIGGER_PAYS_WITH_APP_CREDIT_CARD,
  group: constants.PURCHASES_GROUP,
  primary: false,
  allowOccurrences: false,
  features: [{ name: appConstants.FEATURES_PAY_WITH_BUDGET }, { name: appConstants.FEATURES_MISHLOHA_SERVICES }, appConstants.FEATURE_ESSENTIALS_OFF],
  hideOnFeatureDenied: [appConstants.FEATURES_ZOOZ_SERVICES],
  deprecated: true
};

export const paysWithApp = {
  name: constants.TRIGGER_PAYS_WITH_APP,
  group: constants.PURCHASES_GROUP,
  primary: false,
  allowOccurrences: false,
  features: [{ name: appConstants.FEATURES_PAY_WITH_BUDGET }, { name: appConstants.FEATURES_MISHLOHA_SERVICES }, appConstants.FEATURE_ESSENTIALS_OFF]
};

export const paysWithPayPal = {
  name: constants.TRIGGER_PAYS_WITH_PAYPAL,
  group: constants.PURCHASES_GROUP,
  primary: false,
  allowOccurrences: false,
  features: [{ name: appConstants.FEATURES_PAYPAL_SERVICES }, appConstants.FEATURE_ESSENTIALS_OFF],
  hideOnFeatureDenied: true
};

export const appCreditCardPaymentIsDeclined = {
  name: constants.TRIGGER_APP_CREDIT_CARD_PAYMENT_IS_DECLINED,
  group: constants.PURCHASES_GROUP,
  primary: false,
  allowOccurrences: false,
  features: [{ name: appConstants.FEATURES_PAY_WITH_BUDGET }, { name: appConstants.FEATURES_MISHLOHA_SERVICES }, appConstants.FEATURE_ESSENTIALS_OFF],
  hideOnFeatureDenied: [appConstants.FEATURES_ZOOZ_SERVICES],
  deprecated: true
};

export const payPalPaymentIsDeclined = {
  name: constants.TRIGGER_PAYPAL_PAYMENT_IS_DECLINED,
  group: constants.PURCHASES_GROUP,
  primary: false,
  allowOccurrences: false,
  features: [{ name: appConstants.FEATURES_PAYPAL_SERVICES }, appConstants.FEATURE_ESSENTIALS_OFF],
  hideOnFeatureDenied: true
};

export const startPurchase = {
  name: constants.TRIGGER_START_PURCHASE,
  group: constants.PURCHASES_GROUP,
  primary: false,
  features: [appConstants.FEATURE_ESSENTIALS_OFF],
  allowOccurrences: true
};

export const referredFriendMadePurchase = {
  name: constants.TRIGGER_REFERRED_FRIEND_PURCHASE,
  group: constants.MEMBERSHIP_GROUP,
  primary: false,
  allowOccurrences: false,
  features: [appConstants.FEATURE_ESSENTIALS_OFF, appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const referredFriendMadeHisFirstPurchase = {
  name: constants.TRIGGER_REFERRED_FRIEND_PURCHASE,
  group: constants.REFERRAL_GROUP,
  primary: false,
  allowOccurrences: false,
  features: [appConstants.FEATURE_ESSENTIALS_OFF]
};

export const failedToSendSms = {
  name: constants.TRIGGER_FAILED_TO_SEND_SMS,
  group: constants.COMMUNICATION_GROUP,
  primary: false,
  allowOccurrences: false,
  features: [appConstants.FEATURE_ESSENTIALS_OFF, appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const joinedClubWithReferralCode = {
  name: constants.TRIGGER_JOINED_CLUB_WITH_REFERRAL_CODE,
  group: constants.REFERRAL_GROUP,
  primary: false,
  allowOccurrences: false,
  features: [appConstants.FEATURE_ESSENTIALS_OFF]
};

export const usedMyReferralCode = {
  name: constants.TRIGGER_USED_MY_REFERRAL_CODE,
  group: 'friendReferral',
  primary: false,
  allowOccurrences: false,
  features: [appConstants.FEATURE_ESSENTIALS_OFF]
};

export const pushSent = {
  name: constants.TRIGGER_PUSH_FAILED,
  group: 'communication',
  primary: false,
  allowOccurrences: false,
  features: [appConstants.FEATURE_ESSENTIALS_OFF, appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const emailReceivedSuccessfully = {
  name: constants.TRIGGER_EMAIL_RECEIVED_SUCCESSFULLY,
  group: 'communication',
  primary: false,
  allowOccurrences: false,
  features: [appConstants.FEATURE_ESSENTIALS_OFF]
};

export const emailWasNotReceived = {
  name: constants.TRIGGER_EMAIL_WAS_NOT_RECEIVED,
  group: 'communication',
  primary: false,
  allowOccurrences: false,
  features: [appConstants.FEATURE_ESSENTIALS_OFF]
};

export const emailWasNotOpened = {
  name: constants.TRIGGER_EMAIL_WAS_NOT_OPENED,
  group: 'communication',
  primary: false,
  allowOccurrences: false,
  features: [appConstants.FEATURE_ESSENTIALS_OFF]
};

export const topUp = {
  name: constants.TRIGGER_TOP_UP,
  group: constants.PURCHASES_GROUP,
  primary: false,
  allowOccurrences: false,
  features: [{ name: appConstants.FEATURES_PAY_WITH_BUDGET }, { name: appConstants.FEATURES_MISHLOHA_SERVICES }, appConstants.FEATURE_ESSENTIALS_OFF],
};

