import {fromJS, OrderedMap} from 'immutable';
import * as constants from '../activity.constants';
import * as schemaConstants from '../activitySchema/activitySchema.constants';

export const emptyCondition = {
  [constants.CONDITION_KEY]: '',
  [constants.OPERATOR_KEY]: '',
  [constants.CONDITION_VALUE]: null,
  [constants.CONDITION_VALUE_DATA_TYPE]: ''
};

export const allowEmailCondition = OrderedMap([
  [`${[constants.CONDITION_KEY]}`, schemaConstants.CONTEXT_MEMBERSHIP_ALLOW_EMAIL],
  [`${[constants.OPERATOR_KEY]}`, constants.EQUALS_OPERATOR],
  [`${[constants.CONDITION_VALUE]}`, schemaConstants.TRUE],
  [`${[constants.CONDITION_VALUE_DATA_TYPE]}`, schemaConstants.DATA_VALUE_TYPE_BOOLEAN]
]);

export const allowSMSCondition = OrderedMap([
  [`${[constants.CONDITION_KEY]}`, schemaConstants.CONTEXT_MEMBERSHIP_ALLOW_SMS],
  [`${[constants.OPERATOR_KEY]}`, constants.EQUALS_OPERATOR],
  [`${[constants.CONDITION_VALUE]}`, schemaConstants.TRUE],
  [`${[constants.CONDITION_VALUE_DATA_TYPE]}`, schemaConstants.DATA_VALUE_TYPE_BOOLEAN]
]);

export const allowPushCondition = OrderedMap([
  [`${[constants.CONDITION_KEY]}`, schemaConstants.CONTEXT_MEMBERSHIP_PUSH_NOTIFICATION_ENABLED],
  [`${[constants.OPERATOR_KEY]}`, constants.EQUALS_OPERATOR],
  [`${[constants.CONDITION_VALUE]}`, schemaConstants.TRUE],
  [`${[constants.CONDITION_VALUE_DATA_TYPE]}`, schemaConstants.DATA_VALUE_TYPE_BOOLEAN]
]);

export const allowConsentCondition = OrderedMap([
  [`${[constants.CONDITION_KEY]}`, schemaConstants.CONTEXT_MEMBERSHIP_HAS_CONSENT],
  [`${[constants.OPERATOR_KEY]}`, constants.IS_ONE_OF_CONDITION_OPERATOR],
  [`${[constants.CONDITION_VALUE]}`, [schemaConstants.MEMBERSHIP_CONSENT_VALUE_YES, schemaConstants.MEMBERSHIP_CONSENT_VALUE_BUSINESS_APPROVAL]],
  [`${[constants.CONDITION_VALUE_DATA_TYPE]}`, schemaConstants.DATA_VALUE_TYPE_STRING_ARRAY]
]);


export const defaultABTestCondition = {
  [constants.CONDITION_KEY]: schemaConstants.PROBABILITY,
  [constants.OPERATOR_KEY]: '==',
  [constants.CONDITION_VALUE]: 50,
  [constants.CONDITION_VALUE_DATA_TYPE]: schemaConstants.DATA_VALUE_TYPE_INTEGER
};

export const shoppingCardContainsAtLeastOneCondition = fromJS(emptyCondition)
  .set(constants.CONDITION_KEY, schemaConstants.CONTEXT_SHOPPING_CART_TOTAL_QUANTITY)
  .set(constants.OPERATOR_KEY, schemaConstants.OPERATOR_KEY_GREATER_EQUALS_VALUE)
  .set(constants.CONDITION_VALUE, 1)
  .set(constants.CONDITION_VALUE_DATA_TYPE, schemaConstants.DATA_VALUE_TYPE_INTEGER);

export const availablePunchCardCondition = fromJS(emptyCondition)
  .set(constants.CONDITION_KEY, schemaConstants.CONTEXT_AVAILABLE_PUNCH_CARD)
  .set(constants.OPERATOR_KEY, constants.EQUALS_OPERATOR)
  .set(constants.CONDITION_VALUE, constants.PUNCH_CARD_ID_PLACEHOLDER)
  .set(constants.CONDITION_VALUE_DATA_TYPE, schemaConstants.DATA_VALUE_TYPE_STRING);

export const noActiveFutureInProgressPunchCardCondition = fromJS(emptyCondition)
  .set(constants.CONDITION_KEY, schemaConstants.CONTEXT_NO_ACTIVE_FUTURE_INPROGRESS_PUNCHCARD)
  .set(constants.OPERATOR_KEY, constants.EQUALS_OPERATOR)
  .set(constants.CONDITION_VALUE, constants.PUNCH_CARD_ID_PLACEHOLDER)
  .set(constants.CONDITION_VALUE_DATA_TYPE, schemaConstants.DATA_VALUE_TYPE_STRING);

export const expiredPunchCardCondition = fromJS(emptyCondition)
  .set(constants.CONDITION_KEY, schemaConstants.CONTEXT_EXPIRED_PUNCH_CARD)
  .set(constants.OPERATOR_KEY, constants.EQUALS_OPERATOR)
  .set(constants.CONDITION_VALUE, constants.PUNCH_CARD_ID_PLACEHOLDER)
  .set(constants.CONDITION_VALUE_DATA_TYPE, schemaConstants.DATA_VALUE_TYPE_STRING);

export const assetTemplateCondition = fromJS(emptyCondition)
  .set(constants.CONDITION_KEY, schemaConstants.CONTEXT_ASSET_TEMPLATE)
  .set(constants.OPERATOR_KEY, constants.EQUALS_OPERATOR)
  .set(constants.CONDITION_VALUE, constants.PUNCH_CARD_ID_PLACEHOLDER)
  .set(constants.CONDITION_VALUE_DATA_TYPE, schemaConstants.DATA_VALUE_TYPE_STRING);

export const lastPunchCondition = fromJS(emptyCondition)
  .set(constants.CONDITION_KEY, schemaConstants.CONTEXT_LAST_PUNCH)
  .set(constants.OPERATOR_KEY, constants.EQUALS_OPERATOR)
  .set(constants.CONDITION_VALUE, true)
  .set(constants.CONDITION_VALUE_DATA_TYPE, schemaConstants.DATA_VALUE_TYPE_BOOLEAN);

export const punchNumberCondition = fromJS(emptyCondition)
  .set(constants.CONDITION_KEY, schemaConstants.CONTEXT_TOTAL_PUNCHES_AFTER_OPERATION)
  .set(constants.OPERATOR_KEY, constants.EQUALS_OPERATOR)
  .set(constants.CONDITION_VALUE_DATA_TYPE, schemaConstants.DATA_VALUE_TYPE_INTEGER);

export const occurOnceCondition = fromJS(emptyCondition)
  .set(constants.CONDITION_KEY, constants.TOTAL_TAG_OCCURRENCES_CALC)
  .set(constants.OPERATOR_KEY, schemaConstants.OPERATOR_KEY_LESS_THAN_VALUE)
  .set(constants.CONDITION_VALUE, 1)
  .set(constants.CONDITION_VALUE_DATA_TYPE, schemaConstants.DATA_VALUE_TYPE_INTEGER);
