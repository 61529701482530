import { createSelector } from 'reselect';
import * as constants from './images.constants';
import * as reducerConstants from '../../constants/reducer.constants';

/**
 * Direct selector to the images state domain
 */
const selectImagesDomain = (state) => state.get(reducerConstants.IMAGES_BRANCH);

/**
 * Other specific selectors
 */

export const imagesListSelector = createSelector(selectImagesDomain, (substate) => substate.get(constants.LIST));
export const uploadInProgressSelector = createSelector(selectImagesDomain, (substate) => substate.get(constants.UPLOAD_IN_PROGRESS));
export const deleteInProgressSelector = createSelector(selectImagesDomain, (substate) => substate.get(constants.DELETE_IN_PROGRESS));
export const getImagesListBySize = (state, size) => selectImagesDomain(state).getIn([constants.LIST, size]);
export const isImagesListLoaded = (state, size) => {
  const imagesList = getImagesListBySize(state, size);
  return (imagesList && imagesList.size > 0) || false;
};
export const getImageById = (imagesListForSize, imageId) => {
  if (!imagesListForSize) {
    return null;
  }
  return imagesListForSize.find((image) => image.get(constants.ID) === imageId);
};

export const getAllActiveImageThumbnails = createSelector(imagesListSelector, (images) => {
  const thumbs = images.get(constants.IMAGE_SIZE_THUMBNAIL);
  return thumbs
    ? images
      .get(constants.IMAGE_SIZE_THUMBNAIL)
      .filter((image) => !image.get(constants.ARCHIVED))
      .sort((img1, img2) => {
        const orderFactor = -1; // 1 - ascending, -1 - descending
        return (img1.get(constants.ID) - img2.get(constants.ID)) * orderFactor;
      })
    : null;
});

export function getDefaultImage(images) {
  if (images) {
    return images.find((image) => image.get(constants.SOURCE) === constants.IMAGE_SOURCE_DEFAULT);
  }
  return null;
}

export function getDefaultStampImage(images) {
  if (images) {
    return images.find((image) => image.get(constants.SOURCE) === constants.IMAGE_SOURCE_DEFAULT_STAMP);
  }
  return null;
}

export const getDefaultImageThumbnail = createSelector([getAllActiveImageThumbnails], getDefaultImage);
export const getDefaultStampImageThumbnail = createSelector([getAllActiveImageThumbnails], getDefaultStampImage);
