/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import ConditionListOfValues from './conditionListOfValues';
import * as schemaConstants from '../../activitySchema/activitySchema.constants';
import * as constants from '../../../MailTemplates/mailTemplates.constants';
import { getOptionsListFromMap } from '../../../../common/components/DropdownWrapper/DropdownWrapper';
import saga from '../../../MailTemplates/mailTemplates.saga';
import * as mailTemplatesActions from '../../../MailTemplates/mailTemplates.actions';
import injectSaga from '../../../../utils/injectSaga';
import { getMailTemplatesMap } from '../../../App/selectors';


class EmailTemplateList extends React.PureComponent {

  static propTypes = {
    actions: PropTypes.object.isRequired,
    emailTemplates: PropTypes.object,
  };

  componentWillMount() {
    if (this.props.emailTemplates.size === 0) {
      this.props.actions.getMailTemplates();
    }
  }

  get emailTemplateOptions() {
    const sortedEmailTemplates = this.props.emailTemplates ? this.props.emailTemplates.sortBy((e) => e.get('sort')) : [];
    return getOptionsListFromMap(sortedEmailTemplates, constants.TEMPLATE_NAME);
  }

  render() {

    return (<ConditionListOfValues
      {...this.props}
      type={schemaConstants.VALUE_TYPE_EMAIL_TEMPLATE}
      dynamicOptions={this.emailTemplateOptions}
      multiple
    />);
  }
}

const mapStateToProps = (state) => ({
  emailTemplates: getMailTemplatesMap(state)
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(mailTemplatesActions, dispatch)
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: constants.MAIL_TEMPLATES, saga });

export default compose(withConnect, withSaga, injectIntl)(EmailTemplateList);
