import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import LazyLoadModule from '../views/LazyLoadModule';

const ModulesManager = ({ config, applicationData }) => (
  <React.Fragment>
    {config.modules
      .filter((i) => i.active)
      .map((m) => (<Route
        key={m.route}
        exact path={m.route} render={(props) => (<LazyLoadModule
          applicationData={applicationData}
          navigation={props}
          resolve={m.resolve}
        />)}
      />))}
  </React.Fragment>
);

ModulesManager.propTypes = {
  config: PropTypes.object.isRequired,
  applicationData: PropTypes.object.isRequired
};

export default ModulesManager;
