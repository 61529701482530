import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Tooltip from '../../../common/components/Tooltip/Tooltip';

const PromptLength = (props) => {
  const { intl, setSelectedOption, selectedPromptLength } = props;

  const { formatMessage } = intl;

  const handlePromptLength = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <React.Fragment>
      <span className={'tw-text-sm tw-flex tw-flex-row tw-mt-2'}>
        {formatMessage({ id: 'vertex.ai.length' })}
        <Tooltip content={formatMessage({ id: 'vertex.ai.length.tooltip' })} trigger={<InformationCircleIcon className={'tw-ml-1 tw-w-4 fill-none tw-fill-none'} />} position="bottom right" />
      </span>
      <select value={selectedPromptLength} onChange={handlePromptLength} className="tw-my-2 tw-w-full tw-max-w-xs">
        <option value={'regular'}>{formatMessage({ id: 'vertex.ai.length.pick-lenght.regular' })}</option>
        <option value={'short'}>{formatMessage({ id: 'vertex.ai.length.pick-lenght.short' })}</option>
        <option value={'long'}>{formatMessage({ id: 'vertex.ai.length.pick-lenght.long' })}</option>
      </select>
    </React.Fragment>
  );
};

PromptLength.prototype = {
  intl: PropTypes.object,
  setSelectedOption: PropTypes.func,
  selectedPromptLength: PropTypes.string
};

export default injectIntl(PromptLength);
