import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import PromoCodeRow from './PromoCodeRow';

class PromoCodesTable extends React.PureComponent {
  static propTypes = {
    promoCodes: PropTypes.object.isRequired,
    changeActivityStatus: PropTypes.func.isRequired,
    extendPromoCodeStatus: PropTypes.func.isRequired,
    onDeletePromoCode: PropTypes.func.isRequired,
    goToPromoCode: PropTypes.func.isRequired,
    openPromoCodeInNewTab: PropTypes.func.isRequired,
    highlightedPromoCodeId: PropTypes.string,
  };

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    return (
      <Table className="ui single line fixed selectable very basic table">
        <Table.Header>
          <Table.Row className="promoCode-heading-row">
            <Table.HeaderCell
              className="list-column-name promoCode-type-column"
            >{formatMessage({ id: 'promoCodes-page.promoCode.type' })}</Table.HeaderCell>
            <Table.HeaderCell
              className="list-column-name promoCode-name-column"
            >{formatMessage({ id: 'promoCodes-page.promoCode.name' })}</Table.HeaderCell>
            <Table.HeaderCell
              className="list-column-name promoCode-benefits-column"
            >{formatMessage({ id: 'promoCodes-page.promoCode.benefits' })}</Table.HeaderCell>
            <Table.HeaderCell
              className="list-column-name promoCode-codes-column"
            >{formatMessage({ id: 'promoCodes-page.promoCode.codes' })}</Table.HeaderCell>
            <Table.HeaderCell
              className="list-column-name promoCode-status-column"
            >{formatMessage({ id: 'promoCodes-page.promoCode.status' })}</Table.HeaderCell>
            <Table.HeaderCell className="list-column-name promoCode-expand-arrow-column" />
            <Table.HeaderCell className="list-column-name promoCode-context-menu-column" />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.promoCodes.map((promoCode, index) => (
            <PromoCodeRow
              changeActivityStatus={this.props.changeActivityStatus}
              onDeletePromoCode={this.props.onDeletePromoCode}
              goToPromoCode={this.props.goToPromoCode}
              highlightedActivityId={this.props.highlightedPromoCodeId}
              key={index.toString()}
              promoCode={promoCode}
              rowIndex={index}
              formatMessage={formatMessage}
              openPromoCodeInNewTab={this.props.openPromoCodeInNewTab}
              extendPromoCodeStatus={this.props.extendPromoCodeStatus}
            />
            ))}
        </Table.Body>
      </Table>
    );
  }
}

export default injectIntl(PromoCodesTable);
