/*
 *
 * FilterMembers reducer
 *
 */

import {fromJS, List} from 'immutable';
import moment from 'moment/moment';
import * as constants from './filterMembers.constants';
import * as activityConstants from '../Activity/activity.constants';
import * as activitySchemaConstants from '../Activity/activitySchema/activitySchema.constants';
import * as formConstant from '../Forms/forms.constants';
import * as globalConditionReducerUtils from '../Activity/reducers/utils/globalCondition.reducer.utils';
import * as conditionReducerUtils from '../Activity/reducers/utils/condition.reducer.utils';
import * as activitySchemaSelectors from '../Activity/activitySchema/activitySchema.selectors';
import * as dateTimeReducerUtils from '../Activity/reducers/utils/activity.datetime.reducer.utils';
import * as defaults from '../Activity/reducers/activity.reducer.defaults';
import * as validationUtils from './filterMembers.utils';


const emptyCondition = {conditionKey: '', operatorKey: '', conditionValue: null, dataValueType: ''};
const tempModel = {
  fetchedFormFields: false,
  conditions: {
    conditionsList: [emptyCondition],
    conditionsOperator: activityConstants.CONDITION_AND
  },
  userActionsConditions: {
    conditionsOperator: activityConstants.CONDITION_AND,
    userActionsConditionsList: [{
      userActionKey: '',
      conditions: {conditionsOperator: activityConstants.CONDITION_AND, conditionsList: []}
    }]
  },
  importConditionWrapper: {
    copyPasteMode: {
      conditionKey: activitySchemaConstants.CONTEXT_MEMBERSHIP_KEY_STR,
      operatorKey: activityConstants.IS_ONE_OF,
      dataValueType: activitySchemaConstants.DATA_VALUE_TYPE_STRING_ARRAY,
      conditionValue: []
    },
  },
  type: activityConstants.MEMBERS_SPECIFIC,
  statusCondition: {
    conditionKey: activitySchemaConstants.CONTEXT_MEMBERSHIP_STATUS,
    operatorKey: activityConstants.IS_ONE_OF_CONDITION_OPERATOR,
    dataValueType: activitySchemaConstants.DATA_VALUE_TYPE_STRING_ARRAY,
    conditionValue: [activitySchemaConstants.MEMBERSHIP_STATUS_VALUE_REGISTERED]
  }
};
const initialState = fromJS({
  trigger: constants.FILTER_MEMBERS,
  [constants.FILTERS_MEMBERS_IN_PROGRESS]: false,
  [constants.EXPORT_FILTERED_POPULATION_IN_PROGRESS]: false,
  tempModel,
  [constants.ACTIVITIES]: {
    [constants.DATA]: [],
    [constants.ACTIVITIES_FETCHED]: false
  }
});

function getGlobalConditionPath(conditionParent, conditionIndex, userActionIndex) {
  switch (conditionParent) {
    case constants.FILTERS_USER_ACTION:
      return [constants.FILTER_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS,
        constants.USER_ACTIONS_CONDITIONS_LIST, userActionIndex, constants.CONDITIONS,
        constants.CONDITIONS_LIST, conditionIndex];
    case constants.FILTER_MEMBERS_TEMP_MODEL:
      return [constants.FILTER_TEMP_MODEL, constants.CONDITIONS, activityConstants.CONDITIONS_LIST, conditionIndex];
    case constants.TEMP_REFERRAL_CODE_MODEL:
      return [constants.TEMP_REFERRAL_CODE_MODEL];
    default:
      return [constants.FILTER_TEMP_MODEL, constants.CONDITIONS, constants.CONDITIONS_LIST, conditionIndex];
  }
}

function getFilterUserActionDateRangeConditionPath(userActionIndex) {
  return [constants.FILTER_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS,
    constants.USER_ACTIONS_CONDITIONS_LIST, userActionIndex, constants.USER_ACTION_DATE_RANGE_CONDITION];
}

function getFilterUserActionConditionPath(userActionIndex, conditionIndex) {
  return [constants.FILTER_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS, constants.USER_ACTIONS_CONDITIONS_LIST, userActionIndex, constants.CONDITIONS,
    constants.CONDITIONS_LIST, conditionIndex];
}

function filterMembersReducer(state = initialState, action, rootState) {
  const validationOptions = {force: false, validate: false};
  let newState = state;
  switch (action.type) {
    case constants.CREATE_FILTER_REFERRAL_CODE_TEMP_MODEL: {
      const condition = state
        .getIn([constants.FILTER_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS, constants.USER_ACTIONS_CONDITIONS_LIST, action.userActionIndex, constants.CONDITIONS, constants.CONDITIONS_LIST, action.conditionIndex]);
      return state
        .set(constants.TEMP_REFERRAL_CODE_MODEL, condition);
    }

    case constants.DELETE_FILTER_REFERRAL_CODE_TEMP_MODEL: {
      return state.delete(constants.TEMP_REFERRAL_CODE_MODEL);
    }
    case constants.SAVE_FILTER_REFERRAL_CODE_TEMP_MODEL_TO_CONDITIONS: {
      const condition = globalConditionReducerUtils.prepareReferralCodeTempConditionToSave(state.get(constants.TEMP_REFERRAL_CODE_MODEL));
      return state
        .setIn([constants.FILTER_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS, constants.USER_ACTIONS_CONDITIONS_LIST,
          action.userActionIndex, constants.CONDITIONS, constants.CONDITIONS_LIST, action.conditionIndex], condition);
    }
    case formConstant.GET_REGISTRATION_FORM_FIELDS_SUCCESS: {
      newState = state.set(constants.FETCHED_FORM_FIELDS, true);
      break;
    }
    case constants.UPDATE_FILTER_GLOBAL_MEMBERSHIP_CONDITION_FIELD: {
      newState = state;
      const path = getGlobalConditionPath(action.conditionParent, action.conditionIndex, action.userActionIndex);
      const condition = newState.getIn(path);
      const isUserActionFilter = action.conditionParent === constants.FILTERS_USER_ACTION;
      const trigger = isUserActionFilter ? newState.getIn([constants.FILTER_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS, constants.USER_ACTIONS_CONDITIONS_LIST, action.userActionIndex, activityConstants.USER_ACTION_KEY]) : '';
      const updatedCondition = conditionReducerUtils.updateConditionField(
        condition, trigger, action.name, action.value, activitySchemaSelectors.getSchema(newState), isUserActionFilter);
      newState = newState.setIn([...path], updatedCondition);
      break;
    }
    case constants.CLEAR_FILTER_CONDITION_FIELDS: {
      newState = state;
      const path = [constants.FILTER_TEMP_MODEL];
      newState = newState.setIn([...path], fromJS(tempModel));
      break;
    }
    case constants.ADD_FILTER_GLOBAL_MEMBERSHIP_CONDITION: {
      const path = [constants.FILTER_TEMP_MODEL, constants.CONDITIONS, constants.CONDITIONS_LIST];
      const updatedConditionsList = globalConditionReducerUtils.addGlobalMembershipCondition(state.getIn(path) || List());
      newState = state.setIn(path, updatedConditionsList);
      break;
    }
    case constants.UPDATE_FILTER_GLOBAL_MEMBERSHIP_CONDITION_ATTRIBUTE_OPERATOR: {
      const path = [constants.FILTER_TEMP_MODEL, constants.CONDITIONS, constants.CONDITION_LIST_OPERATOR];
      newState = state.setIn(path, action.value);
      break;
    }
    case constants.UPDATE_FILTER_GLOBAL_MEMBERSHIP_CONDITION_ACTION_OPERATOR: {
      const path = [constants.FILTER_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS, constants.CONDITION_LIST_OPERATOR];
      newState = state.setIn(path, action.value);
      break;
    }
    case constants.DELETE_FILTER_MEMBERSHIP_CONDITION: {
      if (action.conditionParent === constants.FILTERS_USER_ACTION) {
        const path = getFilterUserActionConditionPath(action.userActionIndex, action.conditionIndex);
        newState = state.deleteIn(path);
        break;
      }
      const path = [constants.FILTER_TEMP_MODEL, constants.CONDITIONS, constants.CONDITIONS_LIST];
      const updatedConditionsList = globalConditionReducerUtils.removeGlobalMembershipCondition(state.getIn(path), action.index);
      newState = state.setIn(path, updatedConditionsList);
      break;
    }
    case constants.UPDATE_DATE_RANGE_DATE: {
      let conditionPath = [constants.TEMP_DATETIME_GLOBAL_CONDITIONS, constants.DATES_RANGE_CONDITION];
      if (state.hasIn([constants.DATA, activityConstants.CASES]) && (action.conditionParent === activityConstants.CASES)) {
        conditionPath = [constants.DATA, activityConstants.CASES, action.caseIndex, constants.CONDITIONS, constants.CONDITIONS_LIST, action.conditionIndex];
      } else if (action.conditionParent === constants.FILTERS_USER_ACTION) {
        conditionPath = getFilterUserActionDateRangeConditionPath(action.userActionIndex);
      }
      const condition = state.getIn(conditionPath);
      const updatedCondition = dateTimeReducerUtils.updateDateRangeConditionDate(condition, action.isStart, action.dateObject);
      newState = state.setIn(conditionPath, updatedCondition);
      break;
    }
    case constants.DELETE_USER_ACTION_FROM_FILTER: {
      const userActionsListPath = [constants.FILTER_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS, constants.USER_ACTIONS_CONDITIONS_LIST];
      if (state.getIn(userActionsListPath).size <= 1) {
        newState = state.setIn([...userActionsListPath, 0], fromJS(defaults.userActionEmptyConditions));
      }
      newState = state.deleteIn([...userActionsListPath, action.userActionIndex]);
      break;
    }
    case constants.ADD_FILTER_TEMP_FILTERS_ERRORS: {
      newState = newState.set(activityConstants.TEMP_FILTERS_ERRORS, action[activityConstants.TEMP_FILTERS_ERRORS]);
      break;
    }
    case constants.EXPORT_FILTERED_POPULATION_IN_PROGRESS: {
      newState = newState.set(constants.EXPORT_IN_PROGRESS, fromJS(action[constants.EXPORT_FILTERED_POPULATION_IN_PROGRESS]));
      break;
    }
    case constants.ACTIVITIES_FETCHED_SUCCESS: {
      newState = newState.setIn([constants.ACTIVITIES, constants.ACTIVITIES_FETCHED], true).setIn([constants.ACTIVITIES, constants.DATA], fromJS(action[constants.DATA]));
      break;
    }
    case constants.EXPORT_FILTERED_POPULATION_SUCCESS: {
      newState = newState.set(constants.EXPORT_IN_PROGRESS, false);
      break;
    }
    case constants.CREATE_REFERRAL_CODE_TEMP_MODEL: {
      const condition = state
        .getIn([constants.FILTER_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS, constants.USER_ACTIONS_CONDITIONS_LIST, action.userActionIndex, constants.CONDITIONS, constants.CONDITIONS_LIST, action.conditionIndex]);
      newState = state
        .set(constants.TEMP_REFERRAL_CODE_MODEL, condition);
      break;
    }
    case constants.SET_FILTER_MEMBERS_IN_PROGRESS: {
      newState = newState.setIn([constants.FILTERS_MEMBERS_IN_PROGRESS], fromJS(action[constants.FILTERS_MEMBERS_IN_PROGRESS]));
      break;
    }
    case constants.FILTER_MEMBERS_FILTER_SUCCESS: {
      const memberCount = action.data.count;
      const filterString = action.data.filter;
      const samples = action.data.samples;
      const fileName = action.data.fileName;
      const bucketName = action.data.bucketName;
      const source = constants.FILTER_SOURCE;
      const query = action.data.query;
      newState = setFilterPopulationInfoToStore(newState, memberCount, filterString, fileName, bucketName, source, samples, query);
      break;
    }
    case constants.ADD_FILTER_MEMBERS_TEMP_FILTERS_ERRORS: {
      newState = newState.set(constants.TEMP_FILTER_MEMBERS_ERRORS, action[constants.TEMP_FILTER_MEMBERS_ERRORS]);
      break;
    }
    case constants.FILTER_MEMBERS_UPDATE_FILTER_USER_ACTION_TYPE: {
      const path = [constants.FILTER_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS];
      const dateAttributeModel = globalConditionReducerUtils.prepareDateAttributeModel(activitySchemaSelectors.getDateRangeCondition(state));
      const now = moment();
      const dateRangeCondition = dateTimeReducerUtils.updateDateRangeConditionDate(dateAttributeModel, false, now);
      const activitySchema = activitySchemaSelectors.getSchema(state);
      const schemaAggregatedConditionsPerUserAction = activitySchema.getIn([activityConstants.CONDITIONS_PER_USER_ACTION, action.userActionType, activitySchemaConstants.AGGREGATED_CONDITIONS_PER_ACTION]);
      const updatedUserActions = globalConditionReducerUtils.initUserActionsConditions(state.getIn(path),
        action.userActionType, action.userActionIndex, dateRangeCondition, schemaAggregatedConditionsPerUserAction);
      newState = state.setIn(path, updatedUserActions);
      break;
    }
    case constants.ADD_USER_ACTION_CRITERIA_TO_FILTER_MEMBERS: {
      const path = [constants.FILTER_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS, constants.USER_ACTIONS_CONDITIONS_LIST, action.userActionIndex, constants.CONDITIONS, constants.CONDITIONS_LIST];
      const updatedConditionsList = globalConditionReducerUtils.addGlobalMembershipCondition(state.getIn(path) || List());
      newState = state.setIn(path, updatedConditionsList);
      break;
    }
    case constants.UPDATE_USER_ACTION_AGGREGATION_CRITERIA: {
      const path = [constants.FILTER_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS, constants.USER_ACTIONS_CONDITIONS_LIST, action.userActionIndex, constants.USER_ACTION_AGGREGATION_CONDITIONS, action.attributeIndex];
      const conditionPath = [...path, constants.CONDITION];
      const isConditionActivePath = [...path, constants.IS_USER_ACTION_AGGREGATED_CONDITIONS_ACTIVE];
      if (action.name != null) {
        const condition = newState.getIn(conditionPath);
        const userActionFilter = newState.getIn([constants.FILTER_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS,
          constants.USER_ACTIONS_CONDITIONS_LIST, action.userActionIndex, constants.USER_ACTION_KEY]);
        const updatedCondition = conditionReducerUtils.updateConditionField(
          condition, userActionFilter, action.name, action.value, activitySchemaSelectors.getSchema(newState), true);
        newState = newState.setIn(conditionPath, updatedCondition);
      }
      return newState.setIn(isConditionActivePath, action.isActive);
    }

    default:
      newState = state;
  }
  validationOptions.validate = true;
  const hasAttributeErrors =
    state.getIn([constants.TEMP_FILTER_ERRORS]) &&
    state.getIn([constants.TEMP_FILTER_ERRORS, activityConstants.GLOBAL_CONDITIONS]) &&
    state.getIn([constants.TEMP_FILTER_ERRORS, activityConstants.GLOBAL_CONDITIONS, activityConstants.MEMBERSHIP_GLOBAL_CONDITIONS]) &&
    state.getIn([constants.TEMP_FILTER_ERRORS, activityConstants.GLOBAL_CONDITIONS, activityConstants.MEMBERSHIP_GLOBAL_CONDITIONS, constants.CONDITIONS]) &&
    state.getIn([constants.TEMP_FILTER_ERRORS, activityConstants.GLOBAL_CONDITIONS, activityConstants.MEMBERSHIP_GLOBAL_CONDITIONS, constants.CONDITIONS]).size > 0;
  const hasActionsErrors =
    state.getIn([constants.TEMP_FILTER_ERRORS]) &&
    state.getIn([constants.TEMP_FILTER_ERRORS, activityConstants.GLOBAL_CONDITIONS]) &&
    state.getIn([constants.TEMP_FILTER_ERRORS, activityConstants.GLOBAL_CONDITIONS, activityConstants.MEMBERSHIP_GLOBAL_CONDITIONS]) &&
    state.getIn([constants.TEMP_FILTER_ERRORS, activityConstants.GLOBAL_CONDITIONS, activityConstants.MEMBERSHIP_GLOBAL_CONDITIONS, constants.USER_ACTIONS_CONDITIONS]) &&
    state.getIn([constants.TEMP_FILTER_ERRORS, activityConstants.GLOBAL_CONDITIONS, activityConstants.MEMBERSHIP_GLOBAL_CONDITIONS, constants.USER_ACTIONS_CONDITIONS]).size > 0;
  const hasErrors = hasAttributeErrors || hasActionsErrors;
  if (validationOptions.validate && hasErrors) {
    newState = activityValidationReducerImpl(newState, validationOptions, rootState);
  }
  return newState;
}

function setFilterPopulationInfoToStore(state, memberCount, filterString, fileName, bucketName, source, samples, query) {
  const pathPrefix = [constants.DATA, activityConstants.ONE_TIME_ACTION_FILTERED_POPULATION];
  return state
    .setIn([...pathPrefix, constants.FILTERED_NUM_OF_MEMBERS], memberCount)
    .setIn([...pathPrefix, activityConstants.FILTER_EMPTY_STATE], memberCount === 0)
    .setIn([...pathPrefix, activityConstants.FILTER_POPULATION_NAME], filterString)
    .setIn([...pathPrefix, activityConstants.FILTERED_POPULATION_FILE_NAME], fileName)
    .setIn([...pathPrefix, activityConstants.FILTERED_POPULATION_BUCKET_NAME], bucketName)
    .setIn([...pathPrefix, activityConstants.ACTIVITY_SOURCE], source)
    .setIn([...pathPrefix, constants.FILTERED_MEMBERS_SAMPLES], samples)
    .setIn([...pathPrefix, activityConstants.FILTER_POPULATION_QUERY], query);
}

function activityValidationReducerImpl(state, validationOptions, rootState) {
  // Re-validate if needed
  let newState = state;
  const tempFiltersValidationErrors = newState.get(activityConstants.TEMP_FILTERS_ERRORS);
  if (tempFiltersValidationErrors) {
    const filtersErrors = validationUtils.validateTempFilters(newState.get('tempModel'), rootState);
    if (filtersErrors && filtersErrors.size > 0) {
      newState = newState.setIn([constants.FILTER_MEMBERS_TEMP_MODEL, constants.TEMP_FILTER_ERRORS], filtersErrors);
    } else {
      newState = newState.deleteIn([constants.TEMP_FILTER_ERRORS]);
    }

  }
  return newState;
}

export default filterMembersReducer;
