/*
 *
 * DataAndBi api
 *
 */

import axios from 'axios';

export function getConfig() {
  const url = '/api/DataExport/config';
  return axios.get(url).then((res) => res.data);
}

export function getFilesData(queryName) {
  const url = `/api/DataExport/files?queryName=${queryName}`;
  return axios.get(url).then((res) => res.data);
}

export function getSignedUrl(objectName) {
  const url = `/api/DataExport/url?objectName=${objectName}`;
  return axios.get(url).then((res) => res.data);
}

export function postActivate() {
  const url = '/api/DataExport/activate';
  return axios.post(url).then((res) => res);
}
