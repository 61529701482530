import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import classNames from 'classnames';
import { bindActionCreators, compose } from 'redux';
import FileUploader from '../../../../common/components/FileUploader/FileUploader';
import * as activityActions from '../../activity.actions';
import ValidationErrorMessage from '../../../../common/components/ValidationErrorMessage/ValidationErrorMessage';
import '../importFile.styles.less';
import CardWrapper from '../../../../common/components/Cards/CardWrapper/CardWrapper';
import './promoCodeSingleCodeBulk.styles.less';

export const VALID_TYPES = 'text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .csv, .xls, .xlsx';
export const MAX_FILE_SIZE = 1024 * 1024 * 100; // 100MB

class PromoCodesImportCodesBulkFile extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

  static propTypes = {
    actions: PropTypes.object,
    automationIdPrefix: PropTypes.string,
    error: PropTypes.string,
    onUpload: PropTypes.func,
    uploadInProgress: PropTypes.bool,
    uploadedFileName: PropTypes.string,
    uploadedFileLink: PropTypes.string,
    onModeCLick: PropTypes.func,
    selected: PropTypes.bool,
    disabled: PropTypes.bool
  };

  onModeClick = () => {
    if (!this.props.disabled) {
      this.props.onModeCLick();
    }
  };

  /**
   * render
   * @returns {*}
   */
  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    return (
      <CardWrapper
        automationIdPrefix="promoCode.bulks.fileCodes"
        headerKey="promoCode.bulks.fileCodes.header"
        descriptionKey="promoCode.bulks.fileCodes.description"
        onClick={this.onModeClick}
        className={classNames({ selected: this.props.selected })}

      >
        <div className={'import-codes-file-uploaded'}>
          <FileUploader
            allowMultiple={false}
            automationIdPrefix="globalConditions.codes.importCodes.fileUploader"
            classNames={this.props.uploadedFileName ? 'import-codes-file-uploaded-dnd' : 'import-codes-file-dnd'}
            iconClassName="como-svg-icon-upload-file"
            fileUploadName={this.props.uploadedFileName}
            fileNameClassName="import-codes-file-name"
            onClick={this.props.onUpload}
            displayUploadError={this.props.actions.displayUploadCodesBulkFileError}
            maxFileSize={MAX_FILE_SIZE}
            uploadFiles={(fileHandles) => this.props.actions.uploadCodesBulkFile(fileHandles[0], 0)}
            uploadInProgress={this.props.uploadInProgress}
            validTypes={VALID_TYPES}
            disabled={this.props.disabled}
          />
          {this.props.uploadedFileLink && <a className={'download-file'} href={this.props.uploadedFileLink}><Icon className="como-svg-icon-download" /> {formatMessage({ id: 'download.file' })}</a>}
          {this.props.error &&
          <ValidationErrorMessage
            className="file-error-message"
            errorMessage={formatMessage({ id: this.props.error })}
            dataAutomationId={`${this.props.automationIdPrefix}.error.message`}
          />
        }
        </div>
      </CardWrapper>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(activityActions, dispatch)
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(injectIntl(PromoCodesImportCodesBulkFile));
