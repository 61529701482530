/**
 *
 * ImageGalleryContent
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';

import * as constants from '../../images.constants';
import FileUpload from '../ImageUpload/ImageUpload';
import SingleImage from './SingleImage';

class ImageGalleryContent extends React.PureComponent {

  static propTypes = {
    allowAdditions: PropTypes.bool,
    automationIdPrefix: PropTypes.string,
    deleteInProgress: PropTypes.number,
    images: PropTypes.object,
    imageUploadInstructionsKey: PropTypes.string,
    intl: PropTypes.object,
    localeDateFormat: PropTypes.string.isRequired,
    onDelete: PropTypes.func,
    onSelect: PropTypes.func,
    onDoubleClick: PropTypes.func,
    selectedId: PropTypes.number,
  };

  render() {
    const { images, intl } = this.props;
    const { formatMessage } = intl;

    const jsonObject = images && images.size ? images.map(JSON.stringify) : [];
    const uniqueSet = new Set(jsonObject) || [];
    const uniqueImages = fromJS(Array.from(uniqueSet).map(JSON.parse) || []);

    return (
      <div className="image-gallery-content">
        {this.props.allowAdditions ? (
          <FileUpload
            automationIdPrefix={`${this.props.automationIdPrefix}.upload`}
            imageUploadInstructionsKey={this.props.imageUploadInstructionsKey}
          />
        ) : null
        }
        <p className="image-gallery-content-title">{formatMessage({ id: `imageGallery.label.with${this.props.allowAdditions ? '' : 'out'}Upload` })}</p>
        {uniqueImages && uniqueImages.size ? (
          <div className="image-gallery-thumbs">
            {uniqueImages.map((image, index) => (
              <SingleImage
                automationIdPrefix={`${this.props.automationIdPrefix}.image-${index}`}
                deleteInProgress={this.props.deleteInProgress}
                image={image}
                key={image.get(constants.ID)}
                localeDateFormat={this.props.localeDateFormat}
                onDelete={this.props.onDelete}
                onSelect={this.props.onSelect}
                onDoubleClick={this.props.onDoubleClick}
                selectedId={this.props.selectedId}
              />
            ))}
          </div>
        ) : (
          <p>{formatMessage({ id: 'imageGallery.noImages' })}</p>
        )}
        <div style={{ clear: 'both' }} />
      </div>
    );
  }
}

export default injectIntl(ImageGalleryContent);

