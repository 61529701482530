import { fromJS, List } from 'immutable';
import * as constants from '../../activity.constants';
import { defaultizeCondition } from './condition.reducer.utils';
import { getDefaultBundle, initializeActionByType } from './action.reducer.utils';
import * as defaults from '../activity.reducer.defaults';
import * as staticSchema from '../../activitySchema/activitySchema';

export function createCaseFromActionsWrapper(actionsWrappers) {
  return fromJS(defaults.emptyCase)
    .setIn([constants.ACTIONS], actionsWrappers)
    .setIn([constants.CONDITIONS, constants.CONDITIONS_LIST], fromJS([defaults.emptyCondition]));
}

export function addCase(cases, trigger, schema) {
  // duplicate last case from cases array, strip conditionValues and actions parameters and add it as new case in array
  const duplicatedCase = duplicateCase(cases.last(), trigger, schema);
  return cases.push(duplicatedCase);
}

export function duplicateCase(currentCase, trigger, schema) {
  const duplicateCaseConditions = currentCase.getIn([constants.CONDITIONS, constants.CONDITIONS_LIST])
    .map((condition) => defaultizeCondition(condition, trigger, schema));

  const actionsWrappers = currentCase.get(constants.ACTIONS);
  let duplicateCaseActionsWrappers = List();
  actionsWrappers.forEach((actionsWrapper) => {
    const actionsMode = actionsWrapper.get(constants.ACTIONS_MODE);
    const hasBundle = actionsMode === constants.ADVANCED_DISCOUNT || actionsMode === constants.ACTIONS_OCCURRENCES_MODE;
    let duplicateCaseBundle;
    if (hasBundle) {
      const duplicateBundleSize = actionsWrapper.getIn([constants.BUNDLE, constants.VALUE, constants.CONDITIONS_LIST]).size;
      duplicateCaseBundle = getDefaultBundle(duplicateBundleSize);
    }
    const duplicateCaseActions = actionsWrapper.get(constants.ACTIONS_LIST)
      .map((action) => initializeActionByType(action.get(constants.ACTION_TYPE), actionsMode, trigger, action));
    let duplicateActionsWrapper = fromJS(defaults.emptyActionsWrapper)
      .set(constants.ACTIONS_LIST, duplicateCaseActions)
      .set(constants.ACTIONS_MODE, actionsMode);
    if (hasBundle) {
      duplicateActionsWrapper = duplicateActionsWrapper.set(constants.BUNDLE, duplicateCaseBundle);
    }
    duplicateCaseActionsWrappers = duplicateCaseActionsWrappers.push(duplicateActionsWrapper);
  });

  return currentCase
    .set(constants.IS_COLLAPSED, false)
    .set(constants.DESCRIPTION, '')
    .setIn([constants.CONDITIONS, constants.CONDITIONS_LIST], duplicateCaseConditions)
    .set(constants.ACTIONS, duplicateCaseActionsWrappers);
}


export function toggleCasesCollapse(cases, caseIndex, collapse) {
  if (!isNaN(parseFloat(caseIndex))) {
    return cases.setIn([caseIndex, constants.IS_COLLAPSED], collapse);
  }
  return cases.map((singleCase) => singleCase.set(constants.IS_COLLAPSED, collapse));
}

export function createEmptyCase(activityType) {
  let newCase = fromJS(defaults.emptyCase);
  if (staticSchema.redeemableActivities.includes(activityType)) {
    newCase = newCase.setIn([constants.ACTIONS, 0, constants.ACTIONS_MODE],
                            constants.SIMPLE_ENTIRE_TICKET_DISCOUNT);
  }
  return newCase;
}

export function swapCases(casesList, caseIndex, targetIndex) {
  // swap between the selected case and the one above/below it
  const theCase = casesList.get(caseIndex);
  return casesList.set(caseIndex, casesList.get(targetIndex)).set(targetIndex, theCase);
}
