/**
*
* CaseActions
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import ActivityActions from '../actions/activityActions';
import * as schema from '../../activitySchema/activitySchema';

class CaseActions extends React.Component {

  static propTypes = {
    actionsWrappers: PropTypes.object,
    actionsPopupContent: PropTypes.object,
    activityTrigger: PropTypes.string,
    activityType: PropTypes.string,
    allowOccurrences: PropTypes.bool,
    automationIdPrefix: PropTypes.string,
    caseIndex: PropTypes.number,
    casesActions: PropTypes.object,
    features: PropTypes.object,
    hideAdvancedDealActions: PropTypes.bool,
    isSingleBundleLine: PropTypes.bool,
    mentionsList: PropTypes.object,
    validationErrors: PropTypes.object,
    deleteActionsWrapper: PropTypes.func,
    getBusinessBundlePlan: PropTypes.func.isRequired,
    hasFullPermission: PropTypes.func,
    isOneTimeActivityDisabled: PropTypes.bool
  };

  // use local functions to inject case index to callback functions
  onBundleLineFieldUpdate = (fieldName, fieldValue, bundleConditionIndex, actionsWrapperIndex) => {
    this.props.casesActions.onBundleLineFieldUpdate(fieldName, fieldValue, bundleConditionIndex, actionsWrapperIndex, this.props.caseIndex);
  };
  addBundleLine = (actionsWrapperIndex) => {
    this.props.casesActions.addBundleLine(actionsWrapperIndex, this.props.caseIndex);
  };
  deleteAction = (index, actionsWrapperIndex) => {
    this.props.casesActions.deleteAction(index, actionsWrapperIndex, this.props.caseIndex);
  };
  deleteBundleLine = (index, actionsWrapperIndex) => {
    this.props.casesActions.deleteBundleLine(index, actionsWrapperIndex, this.props.caseIndex);
  };
  changeActivityActionMode = (mode, actionsWrapperIndex) => {
    this.props.casesActions.changeActivityActionMode(mode, actionsWrapperIndex, this.props.caseIndex);
  };
  updateActionField = (fieldName, fieldValue, index, actionsWrapperIndex) => {
    this.props.casesActions.updateActionField(fieldName, fieldValue, index, actionsWrapperIndex, this.props.caseIndex);
  };
  updateActionType = (actionType, index, actionsWrapperIndex, actionsMode) => {
    this.props.casesActions.updateActionType(actionType, index, actionsWrapperIndex, this.props.caseIndex, actionsMode, this.props.activityTrigger);
  };

  addPointsOnChangeType = (addPointsType, index, actionsWrapperIndex) => {
    this.props.casesActions.addPointsOnChangeType(addPointsType, index, actionsWrapperIndex, this.props.caseIndex);
  };
  saveApplyOnData = (index, actionsWrapperIndex) => {
    this.props.casesActions.saveApplyOnData(index, actionsWrapperIndex, this.props.caseIndex);
  };
  createTempApplyOnModel = (index, actionsWrapperIndex) => {
    this.props.casesActions.createTempApplyOnModel(index, actionsWrapperIndex, this.props.caseIndex);
  };
  createGiftFromActivity = (actionIndex, actionsWrapperIndex) => {
    this.props.casesActions.createGiftFromActivity(actionIndex, actionsWrapperIndex, this.props.caseIndex);
  };
  createEmailFromActivity = (actionIndex, actionsWrapperIndex) => {
    this.props.casesActions.createEmailFromActivity(actionIndex, actionsWrapperIndex, this.props.caseIndex);

  };
  deleteActionsWrapper = (actionsWrapperIndex) => {
    this.props.casesActions.deleteActionsWrapper(actionsWrapperIndex, this.props.caseIndex);
  };
  submitActionToCustomer = (user, value, actionsWrapperIndex, actionIndex) => {
    this.props.casesActions.submitActionToCustomer(user, value, this.props.caseIndex, actionsWrapperIndex, actionIndex);
  };

  render() {

    const { actionsWrappers, actionsPopupContent, activityTrigger, allowOccurrences, hideAdvancedDealActions, isSingleBundleLine,
      mentionsList, validationErrors, activityType, caseIndex, features, getBusinessBundlePlan, hasFullPermission } = this.props;

    return (
      <div className="case-actions">
        <ActivityActions
          actionsWrappers={actionsWrappers}
          actionsPopupContent={actionsPopupContent}
          activityTrigger={activityTrigger}
          activityType={activityType}
          addBundleLine={this.addBundleLine}
          addPointsOnChangeType={this.addPointsOnChangeType}
          allowOccurrences={allowOccurrences}
          automationIdPrefix={this.props.automationIdPrefix}
          changeActivityActionMode={this.changeActivityActionMode}
          createGiftFromActivity={this.createGiftFromActivity}
          createEmailFromActivity={this.createEmailFromActivity}
          createTempApplyOnModel={this.createTempApplyOnModel}
          deleteAction={schema.redeemableActivities.includes(activityType) ? this.deleteActionsWrapper : this.deleteAction}
          deleteBundleLine={this.deleteBundleLine}
          features={features}
          hideAdvancedDealActions={hideAdvancedDealActions}
          isSingleBundleLine={isSingleBundleLine}
          mentionsList={mentionsList}
          onBundleLineFieldUpdate={this.onBundleLineFieldUpdate}
          saveApplyOnData={this.saveApplyOnData}
          uniqueId={`cases.${caseIndex}.actions`}
          updateActionField={this.updateActionField}
          updateActionType={this.updateActionType}
          validationErrors={validationErrors}
          deleteActionsWrapper={this.deleteActionsWrapper}
          submitActionToCustomer={this.submitActionToCustomer}
          caseIndex={this.props.caseIndex}
          isOneTimeActivityDisabled={this.props.isOneTimeActivityDisabled}
          getBusinessBundlePlan={getBusinessBundlePlan}
          hasFullPermission={hasFullPermission}
        />
      </div>
    );
  }
}

export default CaseActions;

