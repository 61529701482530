import * as types from './constants/types';

export const getRulesProductTemplates = () => ({ type: types.GET_RULES_PRODUCT_TEMPLATES });

export const getDealsProductTemplates = () => ({ type: types.GET_DEALS_PRODUCT_TEMPLATES });

export const getOneTimeActionsProductTemplates = () => ({ type: types.GET_ONE_TIME_ACTIONS_PRODUCT_TEMPLATES });

export const getActivitiesProductTemplates = () => ([getRulesProductTemplates(), getDealsProductTemplates(), getOneTimeActionsProductTemplates()]);

export const getGoals = () => ({ type: types.GET_GOALS });

export const createGoal = () => ({
  type: types.CREATE_GOAL
});

export const addTool = () => ({
  type: types.ADD_TOOL
});

export const updateTool = (index, key, value) => ({
  type: types.UPDATE_TOOL,
  payload: {
    index,
    key,
    value
  }
});

export const changeGoalName = (name) => ({
  type: types.CHANGE_GOAL_NAME,
  payload: name
});

export const changeGoalIcon = (icon) => ({
  type: types.CHANGE_GOAL_ICON,
  payload: icon
});

export const updateGoal = () => ({
  type: types.UPDATE_GOAL
});

export const selectGoal = (goalId) => ({
  type: types.SELECT_GOAL,
  payload: goalId
});

