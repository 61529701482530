/*
 *
 * MicroCampaigns api
 *
 */

import axios from 'axios';

const microCampaignApiPath = '/api/microCampaign';

export function getMicroCampaigns() {
  return axios.get(microCampaignApiPath)
  .then((res) => res.data);
}

export function getMicroCampaignStats(id, locationId) {
  return axios.get(`${microCampaignApiPath}/analysis?id=${id}&locationId=${locationId}`)
    .then((res) => res.data);
}
