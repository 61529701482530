/**
 *
 * SendConsentEmail
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import MentionTextArea from '../../../../../../common/components/MentionableTextArea/MentionTextArea';
import * as constants from '../../../../activity.constants';
import AiPopover from '../../../../../Ai/AiPopover';
import withAuthorization from '../../../../../../common/helpers/authorization';


class SendConsentEmail extends React.PureComponent {
  static propTypes = {
    automationIdPrefix: PropTypes.string,
    message: PropTypes.string,
    subject: PropTypes.string,
    onActionFieldUpdate: PropTypes.func,
    index: PropTypes.number,
    mentionsList: PropTypes.object,
    validationErrors: PropTypes.object,
    getPermissionLevel: PropTypes.func.isRequired,
  };

  render() {
    const { mentionsList, onActionFieldUpdate, index, getPermissionLevel, validationErrors, automationIdPrefix, message, subject } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <p className="action-label">{formatMessage({ id: 'activity.action.email.subject' })}</p>
              {mentionsList && (
                <MentionTextArea
                  automationIdPrefix={automationIdPrefix}
                  mentionOptions={mentionsList}
                  message={subject}
                  onTextUpdate={(newMessage) => onActionFieldUpdate(constants.EMAIL_SUBJECT, newMessage, index)}
                  index={index}
                  error={validationErrors ? validationErrors.get(constants.EMAIL_SUBJECT) : null}
                  hideTips
                  singleLine
                />
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <p className="action-label">{formatMessage({ id: 'activity.action.email.content' })}</p>
              <AiPopover
                showLengthPrompt
                getPermissionLevel={getPermissionLevel}
                onTextUpdate={(newMessage) => onActionFieldUpdate(constants.EMAIL_MESSAGE, newMessage, index)}
                prePromptKey={'vertex.ai.prePrompt.email'}
                mainButtonTextKey={'vertex.ai.main-btn-txt'}
                titleToolTipKey={'vertex.ai.btn-tooltip'}
                justifyContent={'tw-justify-end'}
                charLength={40}
              />
              {mentionsList && (
                <MentionTextArea
                  automationIdPrefix={automationIdPrefix}
                  mentionOptions={mentionsList}
                  message={message}
                  onTextUpdate={(newMessage) => onActionFieldUpdate(constants.EMAIL_MESSAGE, newMessage, index)}
                  index={index}
                  error={validationErrors ? validationErrors.get(constants.EMAIL_MESSAGE) : null}
                  hideTips
                />
              )}
            </Grid.Column>
          </Grid.Row>
          {
            validationErrors && validationErrors.get(constants.CONSENT_LANDING_PAGE_LINK_MENTION) &&
            <div className="text-area-mandatory-field-error">
              {formatMessage({ id: validationErrors.get(constants.CONSENT_LANDING_PAGE_LINK_MENTION) })}
            </div>
          }
        </Grid>
        <div className="clearfix fixed-height">
          <p className="action-hint italic left-note left">
            {formatMessage({ id: 'activity.action.sendConsentEmail.additional_charges_warning' })}
          </p>
          <p />
        </div>
      </div>
    );
  }
}

export default withAuthorization(compose(injectIntl)(SendConsentEmail));

