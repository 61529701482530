import _ from 'lodash';
import { push } from 'react-router-redux';
import { fromJS } from 'immutable';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as validationUtils from './filterMembers.utils';
import * as activityConstants from '../Activity/activity.constants';
import * as activitySchemaConstants from '../Activity/activitySchema/activitySchema.constants';
import * as api from './filterMembers.api';
import * as constants from './filterMembers.constants';
import * as errorConstant from '../ErrorMessage/errorMessage.constants';
import * as selectors from './filterMembers.selectors';
import * as appSelectors from '../App/selectors';
import * as dataAndBiSelectors from '../DataAndBI/dataAndBi.selectors';
import * as routeConstants from '../../constants/route.contants';
import * as queryStringUtils from '../../utils/queryStringUtils';
import * as textUtils from '../../utils/textFormat';

// sagas listen to dispatched actionsList too (same as reducers)


function* filterMembersByAttributesAndActions() {
  let filterConditions = yield select(selectors.getModel);
  const isFranchises = yield select(dataAndBiSelectors.getIsUserFranchise);

  if (isFranchises) {
    const franchisesField = yield select(appSelectors.getFranchisesField);
    const getFranchisesByPreferredLocation = franchisesField === activitySchemaConstants.PREFERRED_LOCATION;
    const franchisesCondition = yield getFranchisesAllowedBranchesCondition(getFranchisesByPreferredLocation);
    if (franchisesCondition) {
      const updatedConditions = filterConditions.getIn([constants.CONDITIONS, constants.CONDITIONS_LIST]).push(fromJS(franchisesCondition));
      filterConditions = filterConditions.setIn([constants.CONDITIONS, constants.CONDITIONS_LIST], updatedConditions);
    }
  }
  yield* filterMembersAndValidate(filterConditions, true);
}

function* getFranchisesAllowedBranchesCondition(byPreferredLocation) {
  const allLocations = yield select(appSelectors.getLocations);
  const franchisesLocations = yield select(selectors.getFranchisesLocations);
  const locationsBranchesNames = franchisesLocations.map((location) => location.get('branchName'));
  if (!byPreferredLocation && locationsBranchesNames && locationsBranchesNames.size > 0) {
    return {
      conditionKey: activitySchemaConstants.CONTEXT_MEMBERSHIP_HOME_BRANCH_ID,
      operatorKey: activityConstants.IS_ONE_OF,
      conditionValue: locationsBranchesNames,
      dataValueType: activitySchemaConstants.DATA_VALUE_TYPE_STRING_ARRAY
    };
  }
  const locationsBranchIds = franchisesLocations.map((location) => location.get('branchId'));
  const franchisesFromAllLocations = allLocations.filter((location) => locationsBranchIds.includes(location.get('id')));
  const franchisesFromAllLocationsNames = franchisesFromAllLocations.map((location) => location.get('location_name'));
  const filteredLocationsBranchesNames = franchisesFromAllLocationsNames.filter((str) => !!textUtils.removeDataUnsupportedCharacters(str));
  const preferredAllowedBranches = filteredLocationsBranchesNames.map((str) => activitySchemaConstants.LOCATIONS_TAG_BORDER + activitySchemaConstants.LOCATIONS_TAG_PREFERRED + str + activitySchemaConstants.LOCATIONS_TAG_BORDER);
  if (preferredAllowedBranches && preferredAllowedBranches.size > 0) {
    return {
      conditionKey: activitySchemaConstants.CONTEXT_MEMBERSHIP_PREFERRED_LOCATION,
      operatorKey: activityConstants.IS_ONE_OF,
      conditionValue: preferredAllowedBranches,
      dataValueType: activitySchemaConstants.DATA_VALUE_TYPE_STRING_ARRAY
    };
  }
  return null;
}

function* filterAllMembers() {
  yield put({
    type: constants.CLEAR_FILTER_CONDITION_FIELDS
  });
  yield put({
    type: constants.FILTER_MEMBERS_BY_ATTRIBUTES_AND_ACTIONS
  });
}

function* getActivities() {
  try {
    const data = yield call(api.getActivities);
    yield put({ type: constants.ACTIVITIES_FETCHED_SUCCESS, data });
  } catch (err) {
    if (_.get(err, 'response.status') !== 403) {
      yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: [constants.ACTIVITIES_FETCHED_FAIL] });
    }
  }
}

function* exportFilteredPopulation() {
  yield put({
    type: constants.EXPORT_FILTERED_POPULATION_IN_PROGRESS,
    [constants.EXPORT_FILTERED_POPULATION_IN_PROGRESS]: true
  });
  let filterConditions = yield select(selectors.getModel);
  const locationId = yield select(appSelectors.getLocationId);
  const isFranchises = yield select(dataAndBiSelectors.getIsUserFranchise);

  if (isFranchises) {
    const getFranchisesByPreferredLocation = yield select(appSelectors.getFranchisesField) === activitySchemaConstants.PREFERRED_LOCATION;
    const franchisesCondition = yield getFranchisesAllowedBranchesCondition(getFranchisesByPreferredLocation);
    if (franchisesCondition) {
      const updatedConditions = filterConditions.getIn([constants.CONDITIONS, constants.CONDITIONS_LIST]).push(fromJS(franchisesCondition));
      filterConditions = filterConditions.setIn([constants.CONDITIONS, constants.CONDITIONS_LIST], updatedConditions);
    }
  }
  try {
    const data = yield call(api.exportMembers, filterConditions, locationId);
    window.location.href = data.signedUrl;
    yield put({ type: constants.EXPORT_FILTERED_POPULATION_SUCCESS, data });
  } catch (err) {
    if (_.get(err, 'response.status') !== 403) {
      yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: [constants.FILTER_MEMBER_FAIL] });
    }
  } finally {
    yield put({
      type: constants.EXPORT_FILTERED_POPULATION_IN_PROGRESS,
      [constants.EXPORT_FILTERED_POPULATION_IN_PROGRESS]: false
    });
  }

}

function* filterMembersAndValidate(filterConditions, validateConditions) {
  yield put({
    type: constants.SET_FILTER_MEMBERS_IN_PROGRESS,
    [constants.FILTERS_MEMBERS_IN_PROGRESS]: true
  });
  let validationErrors = null;
  try {
    if (validateConditions) {
      const filterState = yield select(selectors.getModel);
      const rootState = yield select();
      validationErrors = validationUtils.validateTempFilters(filterState, rootState);
    }
    if (validationErrors) {
      yield put({
        type: constants.ADD_FILTER_TEMP_FILTERS_ERRORS,
        [activityConstants.TEMP_FILTERS_ERRORS]: validationErrors
      });
    } else {
      const locationId = yield select(appSelectors.getLocationId);
      const data = yield call(api.filterMembers, filterConditions, locationId);
      yield put({ type: constants.FILTER_MEMBERS_FILTER_SUCCESS, data });
    }
  } catch
    (err) {
    if (_.get(err, 'response.status') !== 403) {
      yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: [constants.FILTER_MEMBER_FAIL] });
    }
  } finally {
    yield put({ type: constants.SET_FILTER_MEMBERS_IN_PROGRESS, [activityConstants.FILTERS_IN_PROGRESS]: false });
  }
}

function* goToFilterMembers() {
  const locationSearch = queryStringUtils.removeParameter(location.search, activityConstants.MIGRATION_ID);
  const hasBasicPlanPermissions = yield select(appSelectors.hasBasicPlanPermissions);
  yield put(push({
    pathname: hasBasicPlanPermissions ? routeConstants.BASIC_FILTER_MEMBERS_ROUTE : routeConstants.FILTER_MEMBERS_ROUTE,
    search: locationSearch,
  }));
}


function* filterMembersSaga() {
  yield [
    takeLatest(constants.FILTER_MEMBERS_BY_ATTRIBUTES_AND_ACTIONS, filterMembersByAttributesAndActions),
    takeLatest(constants.FILTER_ALL_MEMBERS, filterAllMembers),
    takeLatest(constants.EXPORT_FILTERED_POPULATION, exportFilteredPopulation),
    takeLatest(constants.GO_TO_FILTER_MEMBERS, goToFilterMembers),
    takeLatest(constants.GET_ACTIVITIES, getActivities)
  ];
}

export default filterMembersSaga;
