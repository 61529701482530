/**
 *
 * Input field component
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Input } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import * as constants from '../inputConstants';
import ValidationErrorMessage from '../../ValidationErrorMessage/ValidationErrorMessage';

class InputWrapper extends React.PureComponent {

  static propTypes = {
    allowTyping: PropTypes.bool,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    disabled: PropTypes.bool,
    fluid: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.oneOf([constants.INTEGER_TYPE, constants.STRING_TYPE, constants.CURRENCY_TYPE, constants.DATE_TYPE]),
    name: PropTypes.string,
    placeholder: PropTypes.string,
    icon: PropTypes.string,
    iconPosition: PropTypes.string,
    step: PropTypes.number,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onPaste: PropTypes.func,
    prefix: PropTypes.string,
    error: PropTypes.string,
    autoComplete: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    label: PropTypes.string,
    labelPosition: PropTypes.string,
    loading: PropTypes.bool,
    resetValueOnOverflow: PropTypes.bool,
    resetValueOnOverflowFromMinMax: PropTypes.bool,
    withSeparator: PropTypes.bool,
    noSpaces: PropTypes.bool,
  };

  static defaultProps = {
    allowTyping: true,
    type: constants.STRING_TYPE,
    autoComplete: true,
    resetValueOnOverflow: true,
    resetValueOnOverflowFromMinMax: false,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onInputRef = this.onInputRef.bind(this);
    const value = !this.props.withSeparator ? props.value : this.addSeparator(props.value);
    this.state = { value };
  }

  componentWillReceiveProps(nextProps) {
    const value = !this.props.withSeparator ? nextProps.value : this.addSeparator(nextProps.value);
    this.setState({ value });
  }

  addSeparator = (val) => val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  removeSeparator = (val) => val.toString().replace(/,/g, '');

  onFocus = (e) => {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }

    if (this.props.withSeparator) {
      this.setState({
        withSeparatorProps: {
          type: constants.INTEGER_TYPE
        },
        value: Number(this.removeSeparator(this.state.value))
      }, () => { this.inputRef.focus(); });
    }
  };

  onBlur = (e) => {
    if (this.props.resetValueOnOverflowFromMinMax) {
      const minInput = this.props.min ? this.props.min : -2147483647;

      if (this.props.type === constants.INTEGER_TYPE && e.target.value <= minInput) {
        e.target.value = minInput;
      }
    }

    if (this.props.onBlur) {
      this.props.onBlur(e);
    }

    if (this.props.onChange && this.props.allowTyping) {
      this.props.onChange(e, e.target);
    }

    if (this.props.withSeparator) {
      this.setState({
        withSeparatorProps: {
          type: constants.STRING_TYPE
        },
        value: this.addSeparator(this.state.value)
      });
    }
  };

  onInputRef(el) {
    this.inputRef = el;
    if (!this.props.autoComplete && el && el.inputRef) {
      el.inputRef.autocomplete = 'off';
    }
  }

  handleChange(e, data) {
    if (!this.props.allowTyping) {
      return;
    }

    const maxInput = this.props.resetValueOnOverflowFromMinMax && this.props.max ? this.props.max : 2147483647;

    if (this.props.type === constants.INTEGER_TYPE && data.value >= maxInput) {
      // todo: replace console.error with toaster
      console.error(`${data.name} reached max integer size ${maxInput}`);
      if (this.props.resetValueOnOverflowFromMinMax) {
        data.value = maxInput;
      } else if (this.props.resetValueOnOverflow) {
        data.value = 0;
      } else {
        return;
      }
    }

    if (this.props.noSpaces) {
      data.value = data.value.replace(/\s/g, '');
    }

    this.setState({ value: data.value });
  }

  render() {

    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const {
      className, inputClassName, type, disabled, name, placeholder, icon, iconPosition, step,
      fluid, prefix, error, onKeyDown, onFocus, onPaste, min, max, label, labelPosition, loading
    } = this.props;
    const clsName = classNames(className, { 'inputFieldError': !!error });
    const withSeparatorProps = this.props.withSeparator ? { ...this.state.withSeparatorProps } : {};

    return (
      <div className="input-wrapper">
        <Input
          className={clsName}
          type={type}
          value={this.state.value}
          icon={icon}
          input={{
            className: inputClassName,
          }}
          iconPosition={iconPosition}
          step={step}
          fluid={fluid}
          onKeyDown={onKeyDown}
          onChange={this.handleChange}
          onBlur={this.onBlur}
          ref={this.onInputRef}
          onFocus={this.onFocus}
          onPaste={onPaste}
          disabled={disabled}
          data-automation-id={prefix}
          name={name}
          placeholder={placeholder}
          min={min}
          max={max}
          label={label}
          labelPosition={labelPosition}
          loading={loading}
          {...withSeparatorProps}
        />
        {error ? (<ValidationErrorMessage
          errorMessage={formatMessage({ id: error })}
          dataAutomationId={`${prefix}.error.message`}
        />) : null}
      </div>
    );
  }
}

export default injectIntl(InputWrapper);
