/*
 *
 * Date picker constants
 *
 */
export const TIME_FROM = 'timeFrom';
export const TIME_TO = 'timeTo';
export const TIME_ERRORS = 'errors';
export const DATE_FROM = 'dateFrom';
export const DATE_TO = 'dateTo';
export const DATE_TIME_IS_AFTER = 'dateTimeIsAfter';
export const DATE_TIME_IS_BEFORE_OR_EQUAL = 'dateTimeIsBeforeOrEqual';
export const DATE_TIME_IS_BETWEEN = 'dateTimeIsBetween';
export const DATE_TIME_IS_INFINITE = 'dateTimeIsInfinite';
export const DAY_OF_WEEK = 'dayOfWeek';
export const TIME_RANGE = 'timeRange';

export const HOURS_FORMAT_24 = 'HH';
export const HOURS_FORMAT_12 = 'hh';
export const MINUTES_FORMAT = 'mm';
export const AM_TIME = 'A';
export const AM_TIME_LOWERCASE = 'a';
export const AM_FORMAT = 'hh:mm A';
export const AM_FORMAT_WITH_SECONDS = 'hh:mm:ss A';
export const HH_MM_FORMAT = 'HH:mm';
export const HH_MM_SS_FORMAT = 'HH:mm:ss';
export const TIME_LIMITATION_ERROR = 'timeLimitationError';
export const AM_TEXT = 'AM';
export const AM_TEXT_LOWERCASE = 'am';
export const PM_TEXT_LOWERCASE = 'pm';
export const END_OF_DAY_TIME = '23:59';
export const END_OF_MINUTE = '59';
export const START_OF_DAY_TIME = '00:00';
export const SECOND = 'second';
export const MINUTE = 'minute';
export const HOUR = 'hour';
export const DAY = 'day';
export const DAYS = 'days';
export const WEEK = 'week';
export const MONTH = 'month';
export const YEAR = 'year';
export const YEARS = 'years';
export const UNIT = 'unit';
export const VALUE = 'value';

export const DAYS_OBJECT_START_OF_DAY_TIME = '00:00:00';
export const DAYS_OBJECT_END_OF_DAY_TIME = '23:59:59';
export const STANDARD_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const DATE_ONLY_FORMAT = 'YYYY-MM-DD';
export const FORMAT = 'format';
export const GIFT_DEFAULT_DATE_TO_YEARS = 5;

