import React, { useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Icon, Menu } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Cog8ToothIcon as GearOutline } from '@heroicons/react/24/outline';
import { Cog8ToothIcon as GearSolid } from '@heroicons/react/24/solid';
import TopNotificationMessage
  from '@como/web-hub-business-profile-module/src/Sections/Communication/TopNotificationMessage';
import PopupWrapper from '../../common/components/PopupWrapper/PopupWrapper';
import ToggleButton from '../../common/components/ToggleButton/ToggleButton';
import * as constants from './email.constants';

const Settings = (props) => {
  // eslint-disable-next-line react/prop-types
  const { intl, emailData, onChange } = props;
  const { formatMessage } = intl;

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [settings, setSettings] = useState({
    [constants.EMAIL_OPEN_TRACKING]: {
      value: emailData.get(constants.EMAIL_OPEN_TRACKING) || 'true',
    },
    [constants.EMAIL_CLICK_TRACKING]: {
      value: emailData.get(constants.EMAIL_CLICK_TRACKING) || 'true',
    },
    [constants.EMAIL_2FA_TEMPLATE]: {
      value: emailData.get(constants.EMAIL_2FA_TEMPLATE) || 'false',
      disable: constants.EMAIL_CONSENT_TEMPLATE,
      warningKey: 'emails-page.settings.email.2faTemplate.warning',
    },
    [constants.EMAIL_CONSENT_TEMPLATE]: {
      value: emailData.get(constants.EMAIL_CONSENT_TEMPLATE) || 'false',
      disable: constants.EMAIL_2FA_TEMPLATE,
      warningKey: 'emails-page.settings.email.consentTemplate.warning',
    },
  });

  const isFiltered = useMemo(() => Object.keys(settings).filter((x) => settings[x].value === 'true').length > 0, [settings]);
  const is2faEnabled = useMemo(() => settings[constants.EMAIL_2FA_TEMPLATE].value === 'true', [settings]);
  const isConsentEnabled = useMemo(() => settings[constants.EMAIL_CONSENT_TEMPLATE].value === 'true', [settings]);

  const handleSettingsChange = (key, newValue) => {
    setSettings((prevSettings) => {
      const updatedSettings = {
        ...prevSettings,
        [key]: {
          ...prevSettings[key],
          value: newValue,
        },
      };

      if (prevSettings[key] !== undefined && prevSettings[key].disable !== undefined) {
        updatedSettings[prevSettings[key].disable] = {
          ...prevSettings[prevSettings[key].disable],
          value: 'false',
        };
      }

      return updatedSettings;
    });

    onChange(key, newValue);
  };

  return (
    <React.Fragment>
      <span className={'tw-pt-4 tw-pr-4'}>
        <Menu secondary>
          <PopupWrapper
            className="filter-settings"
            position="bottom left"
            hideTrigger={false}
            flowing
            on="click"
            open={isPopupOpen}
            onOpen={() => setIsPopupOpen(true)}
            onClose={() => setIsPopupOpen(false)}
            automationId={'filter-settings-popup'}
            basic
            trigger={
              <Menu.Item
                active={isPopupOpen}
                data-automation-id={'popup.filter-settings-popup.trigger'}
              >
                {isFiltered ? <GearSolid className={'tw-mr-2 tw-w-5'} /> : <GearOutline className={'tw-fill-none tw-mr-2 tw-w-5'} />}
                <span>
                  {formatMessage({ id: 'emails-page.settings' })}
                </span>
              </Menu.Item>
            }
          >
            <ul>
              {Object.keys(settings).map((key) => (
                <li
                  key={`emails-page.settings.email.${key}`}
                  className={'tw-flex tw-justify-between'}
                  onClick={() => handleSettingsChange(key, settings[key].value === 'true' ? 'false' : 'true')}
                >
                  {formatMessage({ id: `emails-page.settings.email.${key}` })}
                  <ToggleButton
                    automationId={`emails-page.settings.email.${key}`}
                    id={`emails-page.settings.email.${key}`}
                    enabled={settings[key].value === 'true'}
                  />
                </li>
              ))}
            </ul>
          </PopupWrapper>
        </Menu>
      </span>
      {(is2faEnabled || isConsentEnabled) && (
        <TopNotificationMessage
          message={formatMessage({ id: settings[is2faEnabled ? constants.EMAIL_2FA_TEMPLATE : constants.EMAIL_CONSENT_TEMPLATE].warningKey })}
          warning
          visible
        />
      )}
    </React.Fragment>
  );
};

Settings.prototype = {
  intl: PropTypes.object,
  emailData: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

export default injectIntl(Settings);
