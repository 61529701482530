import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Input } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import {
  ITEMS_POPULATION_TYPE_ADVANCED_RULES,
  ITEMS_POPULATION_TYPE_DEPARTMENT_CODES,
  ITEMS_POPULATION_TYPE_ITEM_CODES,
} from './itemsPopulation.constants';
import CustomModal from '../../../../common/components/CustomModal/CustomModal';
import ValidationErrorMessage from '../../../../common/components/ValidationErrorMessage/ValidationErrorMessage';

class SaveAsGroup extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onSaveAsGroup: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      loading: false,
      name: '',
      validationError: null
    };
    this.onSave = this.onSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  /**
   * trigger save callback and close modal
   */
  onSave = () => {
    const validationError = this.validateName(this.state.name);
    if (validationError) {
      this.setState({ validationError });
      return;
    }
    this.setState({
      loading: true,
    }, () => {
      this.props.onSaveAsGroup(this.state.name);
    });
  };

  /**
   * handle input changes
   * @param {object} e
   * @param {object} data
   */
  onChange = (e, data) => {
    this.setState({
      name: data.value
    });
    if (this.state.validationError) {
      const newValidationError = this.validateName(data.value);
      this.setState({ validationError: newValidationError });
    }
  };

  /**
   * handle key strokes
   * save modal content on enter
   * @param {object} e
   */
  onKeyUp = (e) => {
    switch (e.keyCode) {
      // enter
      case 13:
        this.onSave();
        break;
      default:
        break;
    }
  };

  validateName(newName) {
    if (newName === '') {
      return 'activity.validation.error.itemGroup.name.required';
    }
    return null;
  }

  /**
   * handle modal open
   */
  openModal = () => {
    this.setState({
      isModalOpen: true,
      loading: false,
    });
  };

  /**
   * handle modal close
   */
  closeModal = () => {
    this.setState({
      isModalOpen: false,
      name: ''
    });
  };

  render() {

    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const saveAsGroupClass = classNames('accordion-button', {
      disabled: [ITEMS_POPULATION_TYPE_ITEM_CODES, ITEMS_POPULATION_TYPE_DEPARTMENT_CODES, ITEMS_POPULATION_TYPE_ADVANCED_RULES].indexOf(this.props.type) < 0
    });
    const automationIdPrefix = `${this.props.automationIdPrefix}.saveAsGroup`;
    const cancelButtonClassNames = classNames('link', {
      disabled: this.state.loading
    });
    return (
      <CustomModal
        automationIdPrefix={automationIdPrefix}
        className="save-as-group-modal como-dialog"
        size="mini"
        open={this.state.isModalOpen}
        onClose={this.closeModal}
        onOpen={this.openModal}
        header={formatMessage({ id: 'activity.itemsPopulation.itemGroups.newGroup.title' })}
        actions={[
          <Button
            basic
            key="cancel"
            role="button"
            className={cancelButtonClassNames}
            data-automation-id={`${automationIdPrefix}.cancel`}
            onClick={this.state.loading ? null : this.closeModal}
          >
            {formatMessage({ id: 'general.button.cancel', defaultMessage: 'Cancel' })}
          </Button>,
          <Button
            key="save"
            data-automation-id={`${automationIdPrefix}.save`}
            disabled={this.state.loading}
            onClick={this.onSave}
            loading={this.state.loading}
          >
            {formatMessage({ id: 'general.button.save' })}
          </Button>
        ]}
        trigger={(
          <a
            role="link"
            tabIndex={0}
            onClick={this.openModal}
            data-automation-id={automationIdPrefix}
            className={saveAsGroupClass}
          >
            {formatMessage({ id: 'activity.itemsPopulation.action.saveAsGroup', defaultMessage: 'Save as group' })}
          </a>
        )}
        content={(
          <Grid verticalAlign="middle" className="create-group-modal">
            <Grid.Row>
              <Grid.Column width={3}>
                <span>{formatMessage({ id: 'activity.itemsPopulation.itemGroups.newGroup.name', defaultMessage: 'Name' })}</span>
              </Grid.Column>
              <Grid.Column width={13}>
                <div className="input-wrapper">
                  <Input
                    fluid
                    value={this.state.name}
                    ref={(node) => {
                      if (node) {
                        setTimeout(node.focus);
                      }
                    }}
                    onChange={this.onChange}
                    onKeyUp={this.onKeyUp}
                    data-automation-id={`${automationIdPrefix}.name`}
                    className={this.state.validationError && 'inputFieldError'}
                  />
                  { this.state.validationError ? (
                    <ValidationErrorMessage
                      errorMessage={formatMessage({ id: this.state.validationError })}
                      dataAutomationId={`${automationIdPrefix}.error.message`}
                    />
                  ) : null }
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      />
    );
  }
}

export default injectIntl(SaveAsGroup);

