import { fromJS, List } from 'immutable';
import * as constants from '../activity.constants';
import * as schemaConstants from '../activitySchema/activitySchema.constants';
import * as genericActionConstants from '../../GenericAction/genericAction.contants';
import * as defaults from './activity.reducer.defaults';
import * as actionsUtils from './utils/action.reducer.utils';
import * as punchCardUtils from './utils/punchCard.reducer.utils';
import * as itemsPopulationConstants from '../views/itemsPopulation/itemsPopulation.constants';

export function activityPunchCardReducer(state, action) {

  const PUNCH_CARD_COMMUNICATION_RULES_PATH = [constants.DATA, constants.PUNCH_CARD_CHILD_ACTIVITIES, constants.PUNCH_CARD_COMMUNICATION_RULES];

  switch (action.type) {

    case constants.UPDATE_REQUIRED_PUNCHES: {
      return state.setIn([constants.DATA, constants.REQUIRED_PUNCHES], fromJS(action.requiredPunches));
    }

    case constants.HANDLE_SEND_ON_EXPIRATION_CHECKBOX_CHANGE: {
      const sendOnExpirationRulePath = [constants.DATA, constants.PUNCH_CARD_CHILD_ACTIVITIES, constants.PUNCH_CARD_RULE_SEND_ON_EXPIRATION];

      // for a rule that has been saved to the server, change rule's status
      if (state.getIn([...sendOnExpirationRulePath, constants.SERVER_ID])) {
        return state.setIn([...sendOnExpirationRulePath, constants.STATUS], action.autoSend ? constants.ACTIVITY_STATUS_ACTIVE : constants.ACTIVITY_STATUS_INACTIVE);
      }

      // if rule doesn't already exist, create a new rule or delete it from local model
      if (action.autoSend) {
        // add sendOnPurchase rule to punch card child activities
        return state
          .setIn(sendOnExpirationRulePath, defaults.punchCardRelatedSendOnExpirationRule(state.getIn([constants.DATA, constants.LOCATION_ID])));
      }
      // delete unsaved sendOnPurchase rule from hub model
      return state.deleteIn(sendOnExpirationRulePath);
    }

    case constants.UPDATE_PUNCH_CARD_SHOPPING_CART_ITEMS: {
      let newState = state;
      if (newState.getIn([constants.DATA, constants.PUNCH_CARD_CHILD_ACTIVITIES, constants.PUNCH_CARD_RULE_PUNCH])) {
        const bundleContents = fromJS(defaults.defaultBundle)
          .setIn([constants.VALUE, constants.CONDITIONS_LIST, 0, constants.ITEMS_POPULATION], action.itemsPopulation);
        newState = newState
          .setIn([constants.DATA, constants.PUNCH_CARD_CHILD_ACTIVITIES, constants.PUNCH_CARD_RULE_PUNCH, constants.ACTIONS, 0, constants.BUNDLE], bundleContents);
      }
      return newState;
    }

    case constants.UPDATE_PUNCH_CARD_PUNCH_RULE_LIMIT: {
      return state
        .setIn([constants.DATA, constants.PUNCH_CARD_CHILD_ACTIVITIES, constants.PUNCH_CARD_RULE_PUNCH, constants.ACTIONS, 0, constants.ACTIONS_LIST, 0, constants.LIMIT], fromJS(action.limit));
    }

    case constants.ADD_COMMUNICATION_RULE: {
      const punchCardCommunicationRules = state.getIn([...PUNCH_CARD_COMMUNICATION_RULES_PATH]);
      const newCommunicationRule = defaults.punchCardChildActivityCommon(state.getIn([constants.DATA, constants.LOCATION_ID]));
      const newPunchCardCommunicationRules = punchCardCommunicationRules ? [...punchCardCommunicationRules, newCommunicationRule] : [newCommunicationRule];
      return state
        .setIn([constants.DATA, constants.PUNCH_CARD_CHILD_ACTIVITIES, constants.PUNCH_CARD_COMMUNICATION_RULES], fromJS(newPunchCardCommunicationRules));
    }

    case constants.DELETE_COMMUNICATION_RULE: {
      const communicationRulePath = [...PUNCH_CARD_COMMUNICATION_RULES_PATH, action.index];
      let newState = state;
      if (newState.getIn([...communicationRulePath, constants.SERVER_ID])) {
        const newPunchCardDeletedRules = punchCardUtils.addRuleToDeletionList(newState.getIn([constants.DATA, constants.PUNCH_CARD_CHILD_ACTIVITIES, constants.PUNCH_CARD_DELETED_RULES]), newState.getIn([...communicationRulePath, constants.SERVER_ID]));
        newState = newState.setIn([constants.DATA, constants.PUNCH_CARD_CHILD_ACTIVITIES, constants.PUNCH_CARD_DELETED_RULES], newPunchCardDeletedRules);
      }
      newState = newState.deleteIn(communicationRulePath);
      return newState;
    }

    case constants.UPDATE_COMMUNICATION_RULE_ACTION: {
      const actionPath = [...PUNCH_CARD_COMMUNICATION_RULES_PATH, action.index, constants.ACTIONS, 0, constants.ACTIONS_LIST, 0];
      let actionObject = state.getIn(actionPath);
      if (action.fieldName === constants.ACTION_TYPE) {
        actionObject = actionsUtils.initializeActionByType(action.fieldValue, constants.ACTIONS_SIMPLE_MODE, null, null);
        actionObject = actionObject.setIn([constants.GENERIC_ACTION_ARGS, genericActionConstants.BUTTON_ACTION_TYPE], genericActionConstants.OPEN_THIS_ASSET_SCREEN);
      } else {
        actionObject = actionObject.set(action.fieldName, action.fieldValue);
      }
      return state.setIn(actionPath, actionObject);
    }

    case constants.UPDATE_COMMUNICATION_RULE_TRIGGER: {
      const rulePath = [...PUNCH_CARD_COMMUNICATION_RULES_PATH, action.index];

      let newState = state
        .setIn([...rulePath, constants.TRIGGER], action.trigger)
        .setIn([...rulePath, constants.CONDITIONS, constants.CONDITIONS_LIST], fromJS(defaults.punchCardCommunicationRuleConditions[action.trigger]));

      if (action.trigger === schemaConstants.TRIGGER_PUNCH_MIDDLE_PUNCH) {
        const updatedConditions = punchCardUtils.updateMiddlePunchConditions(newState.getIn([constants.DATA, constants.REQUIRED_PUNCHES]), newState.getIn(rulePath));
        newState = newState.setIn([...rulePath, constants.CONDITIONS, constants.CONDITIONS_LIST], updatedConditions);
      }

      return newState;
    }

    case constants.UPDATE_COMMUNICATION_RULE_DISPLAY: {
      return state
        .setIn([...PUNCH_CARD_COMMUNICATION_RULES_PATH, action.index, constants.IS_COLLAPSED], action.isCollapsed);
    }

    case constants.SET_PUNCH_CARD_DEFAULT_DISCOUNT_ON_ACTION_TYPE: {
      return state
        .setIn([constants.DATA, constants.ACTIONS, 0, constants.ACTIONS_LIST, 0, constants.DISCOUNT_ON, constants.TYPE], itemsPopulationConstants.ITEMS_POPULATION_TYPE_PUNCH_CARD_DEFAULT_DISCOUNT);
    }

    case itemsPopulationConstants.ITEMS_POPULATION_UPDATE_TYPE: {
      if (action.itemsPopulationType === itemsPopulationConstants.ITEMS_POPULATION_TYPE_PUNCH_CARD_DEFAULT_DISCOUNT) {
        const conditionsToCopy = state.getIn([constants.DATA, constants.PUNCH_CARD_CHILD_ACTIVITIES, constants.PUNCH_CARD_RULE_PUNCH, constants.ACTIONS, 0, constants.BUNDLE, constants.VALUE, constants.CONDITIONS_LIST, 0, constants.ITEMS_POPULATION, constants.CONDITIONS]);
        const pathToTempItemsPopulations = [itemsPopulationConstants.TEMP_ITEMS_POPULATION, constants.ITEMS_POPULATION, action.itemsPopulationType];
        return state
          .setIn([...pathToTempItemsPopulations, itemsPopulationConstants.ITEMS_POPULATION_TYPE], action.itemsPopulationType)
          .setIn([...pathToTempItemsPopulations, constants.CONDITIONS], conditionsToCopy);
      }
      return state;
    }

    case constants.HANDLE_AUTO_RENEW_CHECKBOX_CHANGE: {
      return state
        .setIn([constants.DATA, constants.PUNCH_CARD_AUTO_RENEW], action.autoRenew);
    }

    case constants.HANDLE_RENEW_OPTION_CHANGE: {
      return state
        .setIn([constants.DATA, constants.PUNCH_CARD_AUTO_RENEW_TRIGGER], action.renewValue);
    }

    case constants.HANDLE_PUNCH_CARD_REWARD_MODE_CHANGE: {
      let newState = state;
      const tempActionsWrappers = newState.get(constants.TEMP_ACTIONS);
      const tempCases = newState.get(constants.TEMP_CASES);

      // save current data to temp data, delete irrelevant nodes
      const currentActionsWrappers = newState.getIn([constants.DATA, constants.ACTIONS]);
      if (currentActionsWrappers) {
        newState = newState
          .set(constants.TEMP_ACTIONS, currentActionsWrappers);
      } else {
        newState = newState
          .delete(constants.TEMP_ACTIONS);
      }
      const currentCases = newState.getIn([constants.DATA, constants.CASES]);
      if (currentCases) {
        newState = newState
          .set(constants.TEMP_CASES, currentCases);
      } else {
        newState = newState
          .delete(constants.TEMP_CASES);
      }
      const simpleToCustom = !action.currentRewardMode || action.currentRewardMode === constants.PUNCH_CARD_REWARD_MODE_SIMPLE;
      const punchRuleItemsPopulationPath = [constants.DATA, constants.PUNCH_CARD_CHILD_ACTIVITIES, constants.PUNCH_CARD_RULE_PUNCH, constants.ACTIONS, 0, constants.BUNDLE, constants.VALUE, constants.CONDITIONS_LIST, 0, constants.ITEMS_POPULATION];
      const punchRuleItemsPopulationList = newState.getIn([...punchRuleItemsPopulationPath, constants.CONDITIONS]);
      const punchRuleItemsPopulationType = simpleToCustom ? newState.getIn([...punchRuleItemsPopulationPath, constants.TYPE]) : itemsPopulationConstants.ITEMS_POPULATION_TYPE_PUNCH_CARD_DEFAULT_DISCOUNT;
      const actionWrapperMode = simpleToCustom ? constants.SIMPLE_SPECIFIC_ITEMS_DISCOUNT : constants.ACTIONS_SIMPLE_MODE;
      const defaultActionsWrapper = fromJS(defaults.punchCardActionWrapper)
        .set(constants.ACTIONS_MODE, actionWrapperMode)
        .setIn([constants.ACTIONS_LIST, 0, constants.DISCOUNT_ON, constants.TYPE], punchRuleItemsPopulationType)
        .setIn([constants.ACTIONS_LIST, 0, constants.DISCOUNT_ON, constants.CONDITIONS], punchRuleItemsPopulationList);
      let defaultActionsWrappers = new List();
      defaultActionsWrappers = defaultActionsWrappers.push(defaultActionsWrapper);
      const newRewardMode = simpleToCustom ? constants.PUNCH_CARD_REWARD_MODE_CUSTOM : constants.PUNCH_CARD_REWARD_MODE_SIMPLE;

      // restore temp data or set from default
      if (simpleToCustom) {
        const emptyActionsWrapper = fromJS(defaults.defaultActionsWrapperBenefit);
        const customDefaultActionsWrappers = fromJS([defaultActionsWrapper, emptyActionsWrapper]);

        if (tempActionsWrappers) {
        //  tempActionsWrappers = tempActionsWrappers.push(emptyActionsWrapper);
          newState = newState
            .setIn([constants.DATA, constants.ACTIONS], tempActionsWrappers);
        } else if (tempCases) {
          newState = newState
            .setIn([constants.DATA, constants.ACTIONS], null)
            .setIn([constants.DATA, constants.CASES], tempCases);
        } else {
          newState = newState
            .setIn([constants.DATA, constants.ACTIONS], fromJS(customDefaultActionsWrappers));
        }
      } else {
        if (tempActionsWrappers) {
          newState = newState
            .setIn([constants.DATA, constants.ACTIONS], tempActionsWrappers)
            .setIn([constants.DATA, constants.CASES], null);
        } else {
          newState = newState
            .setIn([constants.DATA, constants.ACTIONS], defaultActionsWrappers)
            .setIn([constants.DATA, constants.CASES], null);
        }
      }

      // set new reward mode (simple/custom)
      return newState
        .setIn([constants.DATA, constants.PUNCH_CARD_REWARD_MODE], newRewardMode);
    }

    case constants.EXPAND_COLLAPSE_ALL_COMMUNICATION_RULES: {
      const updatedCommunicationRules = state
        .getIn([constants.DATA, constants.PUNCH_CARD_CHILD_ACTIVITIES, constants.PUNCH_CARD_COMMUNICATION_RULES])
        .map((rule) => rule.set(constants.IS_COLLAPSED, action.collapse));
      return state
        .setIn([constants.DATA, constants.PUNCH_CARD_CHILD_ACTIVITIES, constants.PUNCH_CARD_COMMUNICATION_RULES], updatedCommunicationRules);
    }

    default:
      return state;
  }

}
