import { fromJS, List } from 'immutable';
import * as constants from '../activity.constants';
import * as schemaConstants from '../activitySchema/activitySchema.constants';
import { changeAddPointsActionType } from './utils/actions/addPoints.reducer.utils';
import * as actionsUtils from './utils/action.reducer.utils';
import * as emailsConstants from '../../Emails/emails.constants';
import * as mailTemplatesConstants from '../../MailTemplates/mailTemplates.constants';
import * as commonConstants from '../../../common/components/common.components.constants';
import * as schema from '../activitySchema/activitySchema';
import * as appConstants from '../../App/app.constants';
import * as actionValidationUtils from './utils/validations/action.validation.utils';

export function activityActionsReducer(state, action, rootState) {
  switch (action.type) {

    case constants.ADD_BUNDLE_LINE: {
      const pathPrefix = action.actionParent === constants.CASES ? [constants.DATA, constants.CASES,
        action.caseIndex, constants.ACTIONS, action.actionsWrapperIndex] : [constants.DATA, constants.ACTIONS, action.actionsWrapperIndex];
      const path = [...pathPrefix, constants.BUNDLE, constants.VALUE, constants.CONDITIONS_LIST];
      const bundleLines = state.getIn(path);
      return state.setIn(path, actionsUtils.addBundleLine(bundleLines));
    }

    case constants.DELETE_BUNDLE_LINE: {
      const pathPrefix = action.actionParent === constants.CASES
        ? [constants.DATA, constants.CASES, action.caseIndex, constants.ACTIONS, action.actionsWrapperIndex]
        : [constants.DATA, constants.ACTIONS, action.actionsWrapperIndex];
      const path = [...pathPrefix, constants.BUNDLE, constants.VALUE, constants.CONDITIONS_LIST];
      return state.setIn(path, actionsUtils.removeBundleLine(state.getIn(path), action.index));
    }

    case constants.UPDATE_BUNDLE_LINE_FIELD: {
      const pathPrefix = action.actionParent === constants.CASES
        ? [constants.DATA, constants.CASES, action.caseIndex, constants.ACTIONS, action.actionsWrapperIndex]
        : [constants.DATA, constants.ACTIONS, action.actionsWrapperIndex];
      const actionsWrapper = state.getIn(pathPrefix);
      const activityType = state.getIn([constants.DATA, constants.TYPE]);
      const newActionsWrapper = actionsUtils.updateActionsWrapperBundleLineField(
        actionsWrapper, action.bundleConditionIndex, action.fieldName, action.fieldValue, activityType);
      return state.setIn(pathPrefix, newActionsWrapper);
    }

    case constants.UPDATE_BUNDLE: {
      const pathPrefix = [constants.DATA, constants.ACTIONS, action.actionsWrapperIndex, constants.BUNDLE, action.fieldName];
      return state.setIn(pathPrefix, action.fieldValue);
    }

    case constants.UPDATE_ACTION_FIELD: {
      // update single action inside actionsList
      const actionsWrapperPath = action.actionParent === constants.CASES ?
        [constants.DATA, constants.CASES, action.caseIndex, constants.ACTIONS, action.actionsWrapperIndex] :
        [constants.DATA, constants.ACTIONS, action.actionsWrapperIndex];
      const actionPath = [...actionsWrapperPath, constants.ACTIONS_LIST, action.index];
      return state.setIn([...actionPath, action.fieldName], fromJS(action.fieldValue));
    }

    case constants.DELETE_ACTION: {
      const pathPrefix = action.actionParent === constants.CASES
        ? [constants.DATA, constants.CASES, action.caseIndex, constants.ACTIONS, action.actionsWrapperIndex]
        : [constants.DATA, constants.ACTIONS, action.actionsWrapperIndex];
      const path = [...pathPrefix, constants.ACTIONS_LIST];
      const newActionsList = actionsUtils.removeActionsListAction(state.getIn(path), action.index);
      return state.setIn(path, newActionsList);
    }

    case constants.CHANGE_ACTION_MODE: {
      // save current action data to temp data, load from temp when users select this action mode again
      const path = action.actionParent === constants.CASES
        ? [constants.DATA, constants.CASES, action.caseIndex, constants.ACTIONS, action.actionsWrapperIndex]
        : [constants.DATA, constants.ACTIONS, action.actionsWrapperIndex];
      const newActionsMode = action.mode;
      const oldActionsMode = state.getIn([...path, constants.ACTIONS_MODE]);
      // try to extract old actions wrapper data for the new mode from TEMP_ACTION_DATA:
      const existingDataForNewActionsMode = state.getIn([constants.TEMP_ACTION_DATA, newActionsMode]);
      // change the action mode
      const newActionsWrapper = actionsUtils.changeActionsWrapperMode(existingDataForNewActionsMode, newActionsMode);
      // store old actions wrapper data at TEMP_ACTION_DATA:
      const newState = state.setIn([constants.TEMP_ACTION_DATA, oldActionsMode], state.getIn([...path]));
      // overwrite current action wrapper:
      if (newActionsMode !== oldActionsMode) {
        return newState.setIn(path, newActionsWrapper);
      }
      return newState;
    }
    case constants.UPDATE_GIFT_SIMPLE_MODE: {
      return state.setIn([constants.DATA, constants.GIFT_SIMPLE_MODE], action.value);
    }
    case constants.UPDATE_ACTION_TYPE: {
      // this updates action with correct parameters by the chosen actionType
      // actually it updates the previously created empty action with all specifically relevant staff

      let newState = state;
      const pathPrefix = action.actionParent === constants.CASES
        ? [constants.DATA, constants.CASES, action.caseIndex]
        : [constants.DATA];

      const activityTrigger = action.trigger ? action.trigger : null;
      let actionsMode = action[constants.ACTIONS_MODE] ? action[constants.ACTIONS_MODE] : null;
      const actionsWrapperIndex = action.actionsWrapperIndex;
      const actionIndex = action.index;

      // if the action is discount, create it in a new actionsWrapper
      if (schema.redeemableActivities.includes(activityTrigger)) {
        actionsMode = actionsUtils.defineActionsModeFromActionType(action.actionType);
      }

      const path = [...pathPrefix, constants.ACTIONS, actionsWrapperIndex, constants.ACTIONS_LIST];

      const activityType = state.getIn([constants.DATA, constants.TYPE]);
      let actionObject = actionsUtils.initializeActionByType(action.actionType, actionsMode, activityTrigger, null);
      if (state.getIn([constants.DATA, constants.TYPE]) === constants.ACTIVITY_TYPE_GIFT && actionsMode === constants.SIMPLE_SPECIFIC_ITEMS_DISCOUNT) {
        actionObject = actionsUtils.setDefaultLimit(actionObject);
      }
      newState = newState
        .setIn([...path, actionIndex], actionObject)
        .setIn([...pathPrefix, constants.ACTIONS, actionsWrapperIndex, constants.ACTIONS_MODE], actionsMode);

      if (actionsMode === constants.ADVANCED_DISCOUNT && !newState.getIn([...pathPrefix, constants.ACTIONS, actionsWrapperIndex, constants.BUNDLE])) {
        const bundle = actionsUtils.getDefaultBundle();
        newState = newState
          .setIn([...pathPrefix, constants.ACTIONS, actionsWrapperIndex, constants.BUNDLE], bundle);
      } else if (actionsMode !== constants.ADVANCED_DISCOUNT && schema.redeemableActivities.includes(activityTrigger) && newState.getIn([...pathPrefix, constants.ACTIONS, actionsWrapperIndex, constants.BUNDLE])) {
        newState = newState.deleteIn([...pathPrefix, constants.ACTIONS, actionsWrapperIndex, constants.BUNDLE]);
      }

      const newActionsList = actionsUtils.addEmptyActionIfNeeded(newState.getIn([...pathPrefix, constants.ACTIONS]), activityType);
      return newState.setIn([...pathPrefix, constants.ACTIONS], newActionsList);
    }

    case constants.DELETE_ACTIONS_WRAPPER: {
      let newState = state;
      const pathPrefix = action.actionParent === constants.CASES
        ? [constants.DATA, constants.CASES, action.caseIndex, constants.ACTIONS]
        : [constants.DATA, constants.ACTIONS];
      newState = newState.deleteIn([...pathPrefix, action.actionsWrapperIndex]);

      const activityType = state.getIn([constants.DATA, constants.TYPE]);
      const newActionsList = actionsUtils.addEmptyActionIfNeeded(newState.getIn([...pathPrefix]), activityType);
      return newState.setIn([...pathPrefix, constants.ACTIONS], newActionsList);
    }

    case constants.ADD_POINTS_TYPE_CHANGE: {
      const pathPrefix = action.actionParent === constants.CASES
        ? [constants.DATA, constants.CASES, action.caseIndex, constants.ACTIONS, action.actionsWrapperIndex, constants.ACTIONS_LIST, action.index]
        : [constants.DATA, constants.ACTIONS, action.actionsWrapperIndex, constants.ACTIONS_LIST, action.index];
      const addPointsType = action[constants.ADD_POINTS_TYPE];
      const pointsAction = state.getIn(pathPrefix);
      const newPointsAction = changeAddPointsActionType(pointsAction, addPointsType);
      return state.setIn(pathPrefix, newPointsAction);
    }

    case constants.CREATE_TEMP_APPLY_ON_MODEL: {
      const pathPrefix = state.getIn([constants.DATA, constants.CASES])
        ? [constants.DATA, constants.CASES, action.caseIndex, constants.ACTIONS, action.actionsWrapperIndex]
        : [constants.DATA, constants.ACTIONS, action.actionsWrapperIndex];
      const actionPath = [...pathPrefix, constants.ACTIONS_LIST, action.actionIndex];
      const bundleConditions = state.getIn([...pathPrefix, constants.BUNDLE, constants.VALUE, constants.CONDITIONS_LIST]);
      const applyOnData = state.getIn([...actionPath, constants.APPLY_ON])
        ? state.getIn([...actionPath, constants.APPLY_ON])
        : List();
      const bundleItemsMode = state.getIn([...actionPath, constants.BUNDLE_ITEMS_MODE]);
      const applyOnBundleData = actionsUtils.createTempApplyOnBundleData(bundleConditions, applyOnData, bundleItemsMode);
      if (applyOnBundleData) {
        return state.set(constants.TEMP_APPLY_ON_BUNDLE_MODEL, fromJS(applyOnBundleData));
      }
      return state;
    }

    case constants.SAVE_APPLY_ON_DATA: {
      const pathPrefix = state.getIn([constants.DATA, constants.CASES])
        ? [constants.DATA, constants.CASES, action.caseIndex, constants.ACTIONS, action.actionsWrapperIndex]
        : [constants.DATA, constants.ACTIONS, action.actionsWrapperIndex];
      const actionPath = [...pathPrefix, constants.ACTIONS_LIST, action.actionIndex];
      const bundleItemsMode = state.getIn([constants.TEMP_APPLY_ON_BUNDLE_MODEL, constants.BUNDLE_ITEMS_MODE]);
      const applyOnTempModel = state.getIn([constants.TEMP_APPLY_ON_BUNDLE_MODEL, constants.APPLY_ON]);
      const currentAction = state.getIn(actionPath);
      const newAction = actionsUtils.applyApplyOnDataFromTempModel(currentAction, bundleItemsMode, applyOnTempModel);
      return state
        .setIn(actionPath, newAction)
        .delete(constants.TEMP_APPLY_ON_BUNDLE_MODEL);
    }

    case constants.RESET_APPLY_ON_DATA: {
      return state.delete(constants.TEMP_APPLY_ON_BUNDLE_MODEL);
    }

    case constants.CHANGE_BUNDLE_ITEMS_MODE_IN_TEMP_MODEL: {
      return state.setIn([constants.TEMP_APPLY_ON_BUNDLE_MODEL, constants.BUNDLE_ITEMS_MODE], action.bundleItemsMode);
    }

    case constants.UPDATE_TEMP_APPLY_ON_MODEL_FIELD: {
      return state.setIn([constants.TEMP_APPLY_ON_BUNDLE_MODEL, constants.APPLY_ON, action.applyOnIndex, action.fieldName], action.fieldValue);
    }

    case constants.CREATE_GIFT_FROM_ACTIVITY: {
      const originPathPrefix = action.actionParent === constants.CASES
        ? [constants.DATA, constants.CASES, action.caseIndex]
        : [constants.DATA];
      const originPath = [...originPathPrefix, constants.ACTIONS, action.actionsWrapperIndex, constants.ACTIONS_LIST, action.actionIndex, schemaConstants.ASSIGN_ASSET_BENEFIT_KEY_FIELD];
      const originUrl = {
        [constants.RETURN_URL_PATHNAME]: window.location.pathname,
        [constants.RETURN_URL_SEARCH]: window.location.search
      };
      return state
        .setIn([constants.TEMP_ORIGIN_ACTIVITY, constants.DATA], state.get(constants.DATA))
        .setIn([constants.TEMP_ORIGIN_ACTIVITY, constants.UPDATE_PATH], fromJS(originPath))
        .setIn([constants.TEMP_ORIGIN_ACTIVITY, constants.RETURN_URL], fromJS(originUrl))
        .setIn([constants.TEMP_ORIGIN_ACTIVITY, constants.TYPE], constants.ACTIVITY_TYPE_GIFT_CAPITAL);
    }

    case constants.CREATE_EMAIL_FROM_ACTIVITY: {
      const originPathPrefix = action.actionParent === constants.CASES
        ? [constants.DATA, constants.CASES, action.caseIndex]
        : [constants.DATA];
      const originPath = [...originPathPrefix, constants.ACTIONS, action.actionsWrapperIndex, constants.ACTIONS_LIST, action.actionIndex, emailsConstants.EMAIL_EXTERNAL_ID];
      const originUrl = {
        [constants.RETURN_URL_PATHNAME]: window.location.pathname,
        [constants.RETURN_URL_SEARCH]: window.location.search
      };
      return state
        .setIn([constants.TEMP_ORIGIN_ACTIVITY, constants.DATA], state.get(constants.DATA))
        .setIn([constants.TEMP_ORIGIN_ACTIVITY, constants.UPDATE_PATH], fromJS(originPath))
        .setIn([constants.TEMP_ORIGIN_ACTIVITY, constants.RETURN_URL], fromJS(originUrl))
        .setIn([constants.TEMP_ORIGIN_ACTIVITY, constants.TYPE], constants.ACTIVITY_TYPE_EMAIL_CAPITAL);
    }

    case constants.GO_BACK_TO_ACTIVITY_FROM_CREATE_GIFT: {
      let newState = state
        .set(constants.DATA, state.getIn([constants.TEMP_ORIGIN_ACTIVITY, constants.DATA]));
      if (action.data) {
        newState = newState
          .set(constants.NEWLY_CREATED_BENEFIT, fromJS(action.data[constants.SERVER_ID]))
          .setIn(newState.getIn([constants.TEMP_ORIGIN_ACTIVITY, constants.UPDATE_PATH]), fromJS(action.data[constants.SERVER_ID]));
      }
      newState = newState.delete(constants.TEMP_ORIGIN_ACTIVITY);
      return newState;
    }

    case constants.GO_BACK_TO_ACTIVITY_FROM_CREATE_EMAIL: {
      let newState = state
        .set(constants.DATA, state.getIn([constants.TEMP_ORIGIN_ACTIVITY, constants.DATA]));
      if (action.data) {
        newState = newState
          .set(constants.NEWLY_CREATED_EMAIL, fromJS(action.data[constants.SERVER_ID]))
          .setIn(newState.getIn([constants.TEMP_ORIGIN_ACTIVITY, constants.UPDATE_PATH]), fromJS(action.data[constants.SERVER_ID]));
      }
      newState = newState
        .delete(constants.TEMP_ORIGIN_ACTIVITY)
        .set(constants.BACK_TO_ACTIVITY_IN_STATE, true);
      return newState;
    }

    case constants.CLEAR_NEWLY_SELECTED_GIFT_ID: {
      return state.delete(constants.NEWLY_CREATED_BENEFIT);
    }

    case mailTemplatesConstants.CLEAR_NEWLY_CREATED_EMAIL: {
      return state.delete(constants.NEWLY_CREATED_EMAIL);
    }

    case commonConstants.SET_MENTION_COMPONENT_COPIED_VALUE: {
      return state.set(commonConstants.MENTION_COPIED_VALUE, action.value);
    }

    case constants.UPDATE_PROGRESS_STATUS: {
      return state.setIn([constants.TEST_TO_MYSELF, constants.TEST_TO_MYSELF_IN_PROGRESS], action.status)
                  .setIn([constants.TEST_TO_MYSELF, constants.TEST_TO_MYSELF_IN_PROGRESS_WRAPPER_INDEX], action.wrapperIndex)
                  .setIn([constants.TEST_TO_MYSELF, constants.TEST_TO_MYSELF_IN_PROGRESS_INDEX], action.index);
    }

    case constants.SUBMIT_ACTION_TO_CUSTOMER: {
      let newState = state;
      const originPathPrefix = action.actionParent === constants.CASES
        ? [constants.DATA, constants.CASES, action.caseIndex]
        : [constants.DATA];
      const errorsPathPrefix = action.actionParent === constants.CASES
        ? [constants.VALIDATION_ERRORS, constants.ACTIONS, constants.CASES, action.caseIndex]
        : [constants.VALIDATION_ERRORS, constants.ACTIONS];
      let activityAction;
      if (newState.getIn([...originPathPrefix, constants.TYPE]) === constants.BENEFIT_PUNCH_CARD) {
        activityAction = newState.getIn([...originPathPrefix, constants.PUNCH_CARD_CHILD_ACTIVITIES, constants.PUNCH_CARD_COMMUNICATION_RULES, 0, constants.ACTIONS, 0, constants.ACTIONS_LIST, action.actionIndex]);
      } else {
        activityAction = newState.getIn([...originPathPrefix, constants.ACTIONS, action.actionsWrapperIndex, constants.ACTIONS_LIST, action.actionIndex]);
      }
      newState = newState.delete(constants.VALIDATION_ERRORS);
      const actionValidationErrors = actionValidationUtils.validateAction(activityAction, rootState);
      let testToMyself = fromJS({});
      testToMyself = testToMyself
        .set(appConstants.USER, action.user)
        .set(constants.ACTION, activityAction)
        .set(constants.VALIDATION_ERRORS, fromJS(actionValidationErrors))
        .set(constants.TEST_TO_MYSELF_COMPLETED, false)
        .setIn([constants.ACTION_PATH, constants.ACTIONS_WRAPPER_INDEX], action.actionsWrapperIndex)
        .setIn([constants.ACTION_PATH, constants.ACTION_INDEX], action.actionIndex)
        .set(constants.TEST_TO_MYSELF_SUCCEEDED, false)
        .set(constants.TEST_TO_MYSELF_IN_PROGRESS, true)
        .set(constants.TEST_TO_MYSELF_IN_PROGRESS_WRAPPER_INDEX, action.actionsWrapperIndex)
        .set(constants.TEST_TO_MYSELF_IN_PROGRESS_INDEX, action.actionIndex);

      if (actionValidationErrors && Object.keys(actionValidationErrors).length !== 0) {
        newState = newState.setIn([...errorsPathPrefix, constants.ACTIONS, action.actionsWrapperIndex, constants.ACTIONS_LIST, action.actionIndex.toString()], fromJS(actionValidationErrors));
      }
      newState = newState
        .set(constants.TEST_TO_MYSELF, testToMyself)
        .setIn([constants.VALIDATION_METADATA, constants.ACTIVITY_VALIDATION_MODE], constants.ACTIVITY_VALIDATION_MODE_DONT_VALIDATE);
      return newState;
    }

    case constants.DECLARE_TEST_COMPLETED: {
      return state.setIn([constants.TEST_TO_MYSELF, constants.TEST_TO_MYSELF_COMPLETED], true)
              .setIn([constants.TEST_TO_MYSELF, constants.TEST_TO_MYSELF_IN_PROGRESS], false);
    }

    case constants.DECLARE_TEST_SUCCEEDED: {
      return state.setIn([constants.TEST_TO_MYSELF, constants.TEST_TO_MYSELF_SUCCEEDED], true);
    }

    case constants.CLEAR_TEST_TO_MYSELF_SUCCEEDED: {
      return state.deleteIn([constants.TEST_TO_MYSELF, constants.TEST_TO_MYSELF_SUCCEEDED]);
    }

    case constants.INITIALIZE_UPDATE_MEMBERSHIP_FIELDS: {
      let newState = state;
      const pathPrefix = state.getIn([constants.DATA, constants.CASES])
        ? [constants.DATA, constants.CASES, action.caseIndex, constants.ACTIONS, 0, constants.ACTIONS_LIST]
        : [constants.DATA, constants.ACTIONS, 0, constants.ACTIONS_LIST];
      const existingMemberFieldsLength = newState.getIn([...pathPrefix]).size;
      for (let actionIndex = 0; actionIndex < existingMemberFieldsLength; actionIndex++) {
        if (newState.getIn([...pathPrefix, actionIndex, schemaConstants.UPDATE_MEMBERSHIP_FIELD_LISTS])) {
          const internalMembersFieldsListLength = newState.getIn([...pathPrefix, actionIndex, schemaConstants.UPDATE_MEMBERSHIP_FIELD_LISTS]).size;
          for (let membershipFieldsIndex = 0; membershipFieldsIndex < internalMembersFieldsListLength; membershipFieldsIndex++) {
            let existingMemberFieldValue = newState.getIn([...pathPrefix, actionIndex, schemaConstants.UPDATE_MEMBERSHIP_FIELD_LISTS, membershipFieldsIndex]);
            existingMemberFieldValue = existingMemberFieldValue.set(schemaConstants.UPDATE_MEMBERSHIP_FIELD_VALUE_LIST, fromJS(actionsUtils.getFieldValueListBasedOnSelectedMembershipField(existingMemberFieldValue.get(constants.KEY))));
            newState = newState.setIn([...pathPrefix, actionIndex, schemaConstants.UPDATE_MEMBERSHIP_FIELD_LISTS, membershipFieldsIndex], existingMemberFieldValue);
          }
        }
      }
      return newState;
    }

    case constants.UPDATE_MEMBERSHIP_FIELD_VALUE_UPDATE: {
      let newState = state;
      const originPath = state.getIn([constants.DATA, constants.CASES])
        ? [constants.DATA, constants.CASES, action.caseIndex]
        : [constants.DATA];
      const path = [...originPath, constants.ACTIONS, 0, constants.ACTIONS_LIST, action.actionIndex, schemaConstants.UPDATE_MEMBERSHIP_FIELD_LISTS, action.membershipFieldsIndex];
      let existingMemberFieldValue = newState.getIn([...path]);
      existingMemberFieldValue = existingMemberFieldValue.set(action.name, action.value.trim());
      newState = newState.setIn([...path], existingMemberFieldValue);
      return newState;
    }

    case constants.UPDATE_MEMBERSHIP_FIELD_LIST_UPDATE: {
      let newState = state;
      const originPath = state.getIn([constants.DATA, constants.CASES])
        ? [constants.DATA, constants.CASES, action.caseIndex]
        : [constants.DATA];
      const fieldValList = actionsUtils.getFieldValueListBasedOnSelectedMembershipField(action.fieldListValue.trim());
      const fieldValue = fieldValList.length > 0 ? fieldValList[0].value : ''; // Select the default value with first index whenever field key is selected
      const path = [...originPath, constants.ACTIONS, 0, constants.ACTIONS_LIST, action.actionIndex, schemaConstants.UPDATE_MEMBERSHIP_FIELD_LISTS, action.membershipFieldsIndex];
      let existingMemberField = newState.getIn([...path]);
      existingMemberField = existingMemberField
                            .set(action.fieldListKey, action.fieldListValue.trim())
                            .set(action.fieldValueKey, fieldValue)
                            .set(schemaConstants.UPDATE_MEMBERSHIP_FIELD_VALUE_LIST, fromJS(fieldValList));
      newState = newState.setIn([...path], existingMemberField);
      return newState;
    }

    case constants.ADD_MEMBERSHIP_FIELD: {
      let newState = state;
      const originPath = state.getIn([constants.DATA, constants.CASES])
        ? [constants.DATA, constants.CASES, action.caseIndex]
        : [constants.DATA];
      const path = [...originPath, constants.ACTIONS, 0, constants.ACTIONS_LIST, action.actionIndex, schemaConstants.UPDATE_MEMBERSHIP_FIELD_LISTS];
      const existingMemberFieldsLength = newState.getIn([...path]).size;
      const existingMemberFields = newState.getIn([...path]).toJSON();
      existingMemberFields[existingMemberFieldsLength] = { key: schemaConstants.GENERIC_CHECKBOX_1, value: schemaConstants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_1, updateMembershipFieldValueList: actionsUtils.getFieldValueListBasedOnSelectedMembershipField(schemaConstants.GENERIC_CHECKBOX_1) };
      newState = newState.setIn([...path], fromJS(existingMemberFields));
      return newState;
    }

    case constants.REMOVE_MEMBERSHIP_FIELD: {
      let newState = state;
      const originPath = state.getIn([constants.DATA, constants.CASES])
        ? [constants.DATA, constants.CASES, action.caseIndex]
        : [constants.DATA];
      const path = [...originPath, constants.ACTIONS, 0, constants.ACTIONS_LIST, action.actionIndex, schemaConstants.UPDATE_MEMBERSHIP_FIELD_LISTS];
      const existingMemberFields = newState.getIn([...path]).toJSON();
      existingMemberFields.splice(action.membershipFieldsIndex, 1);
      newState = newState.setIn([...path], fromJS(existingMemberFields));
      return newState;
    }

    case constants.UPDATE_AB_TESTING_POPULATION_SIZE: {
      const casePopulationSize = action.filteredPopulation ? Math.floor(parseInt(action.filteredPopulation.get(constants.FILTERED_POPULATION_COUNT), 10) * (action.value / 100)) : null;
      return state.setIn([constants.DATA, constants.CASES, action.caseIndex, constants.AB_TESTING_FILTERED_POPULATION], casePopulationSize);
    }

    default:
      return state;
  }
}
