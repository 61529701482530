/*
 *
 * Images api
 *
 */

import axios from 'axios';
import * as constants from './images.constants';

export function getImagesBySize(size) {
  return axios.get(`/api/images/getAll/${size || constants.IMAGE_SIZE_ORIGINAL}`)
  .then((res) => res.data)
  .catch((err) => err);
}

export function deleteImage(imageId) {
  return axios.delete(`/api/images/${imageId}`)
  .then((res) => res.data)
  .catch((err) => err);
}

export function uploadImages(file) {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post('/api/ImageUpload/', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  .then((res) => res.data)
  .catch((err) => err);
}

