import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import * as schemaConstants from '../../../../activitySchema/activitySchema.constants';
import './updateMembership.styles.less';
import MembershipField from './membershipField';
import * as actionDefaults from "../../../../reducers/activity.action.defaults";
import * as activityActions from '../../../../activity.actions';


class updateMembershipFields extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string,
    action: PropTypes.object,
    index: PropTypes.number,
    validationErrors: PropTypes.object,
    caseIndex: PropTypes.number
  };

  componentDidMount() {
    this.props.actions.initializeUpdateMembershipFields(this.props.caseIndex);
  }

  onMembershipFieldValueUpdate = (name, value, membershipFieldsIndex, actionIndex) => {
    this.props.actions.onMembershipFieldValueUpdate(name, value, membershipFieldsIndex, actionIndex, this.props.caseIndex);
  }

  onMembershipFieldListUpdate = (fieldListKey, fieldListValue, fieldValueKey, membershipFieldsIndex, actionIndex) => {
    this.props.actions.onMembershipFieldListUpdate(fieldListKey, fieldListValue, fieldValueKey, membershipFieldsIndex, actionIndex, this.props.caseIndex);
  }

  addMembershipFields = (actionIndex) => {
    this.props.actions.onAddMembershipField(actionIndex, this.props.caseIndex);
  }

  removeUpdateMembershipField = (membershipFieldsIndex, actionIndex) => {
    this.props.actions.onRemoveMembershipField(membershipFieldsIndex, actionIndex, this.props.caseIndex);
  }

  getMembershipFieldsList =() => {
    const immutableMembershipFields = this.props.action.get(schemaConstants.UPDATE_MEMBERSHIP_FIELD_LISTS);
    return immutableMembershipFields ? immutableMembershipFields.toJSON() : [];
  }

  getSelectedFieldValueList = (membershipField) => {
    const selectedFieldValueList = membershipField && membershipField.get(schemaConstants.UPDATE_MEMBERSHIP_FIELD_VALUE_LIST) ? membershipField.get(schemaConstants.UPDATE_MEMBERSHIP_FIELD_VALUE_LIST).toJSON() : [];
    return selectedFieldValueList;
  }


  render() {
    const { index, action, validationErrors, automationIdPrefix } = this.props;
    return (
      <Grid className="action export-event-action">
        {
          (this.getMembershipFieldsList().map((_, i) => {
            const membershipField = action.getIn([schemaConstants.UPDATE_MEMBERSHIP_FIELD_LISTS, i]);
            return (<MembershipField
              validationErrors={validationErrors}
              fieldListOptions={actionDefaults.defaultUpdateMembershipFieldOptions}
              membershipField={membershipField}
              selectedFieldValueList={this.getSelectedFieldValueList(membershipField)}
              selectedFieldValue={membershipField ? membershipField.get(schemaConstants.MEMBERSHIP_UPDATE_VALUE_FIELD) : ''}
              automationIdPrefix={automationIdPrefix}
              index={i}
              onMembershipFieldValueUpdate={(membershipFieldsIndex, key, value) => this.onMembershipFieldValueUpdate(key, value, membershipFieldsIndex, index)}
              onMembershipFieldListUpdate={(membershipFieldsIndex, fieldListKey, fieldListValue, fieldValueKey) => this.onMembershipFieldListUpdate(fieldListKey, fieldListValue, fieldValueKey, membershipFieldsIndex, index)}
              removeItem={this.removeUpdateMembershipField.bind(this)}
              showDeleteButton={this.getMembershipFieldsList().length > 1}
              parentIndex={index}
            />
            );
          }))
        }
          <a role={"button"} style={{marginBottom: 20}} onClick={() => { this.addMembershipFields.bind(this)(index); }}>
            <Icon
              style={{fontsize: '10px'}}
              className="icon como-ic-plus-in-circle"
            />Add Membership field
          </a>
      </Grid>
    );
  }
  }


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(activityActions, dispatch)
  };
}

const withConnect = connect(null ,mapDispatchToProps);

export default compose(withConnect)(injectIntl(updateMembershipFields));

