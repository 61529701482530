import * as constants from './activitySchema.constants';


// Receive points/credit mentions
export const weightedPointsMention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_AMOUNTS_WEIGHTED_POINTS,
  [constants.MENTION_CATEGORY]: constants.MENTION_POINTS_TRANSACTION_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const weightedBudgetMention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_AMOUNTS_BUDGET_WEIGHTED,
  [constants.MENTION_CATEGORY]: constants.MENTION_POINTS_TRANSACTION_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
// Used points mentions
export const usedPointsMention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_AMOUNTS_POINTS,
  [constants.MENTION_CATEGORY]: constants.MENTION_POINTS_TRANSACTION_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
// Membership mentions
export const memberIdMention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_MEMBER_ID,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const firstNameMention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_FIRST_NAME,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const lastNameMention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_LAST_NAME,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const emailMention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_EMAIL,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const birthdayMention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_BIRTHDAY,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const anniversaryMention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_ANNIVERSARY,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const termsOfUseDateMention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_TERMS_OF_USE_DATE,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const genericString1Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_STRING_1,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const genericString2Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_STRING_2,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const genericString3Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_STRING_3,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const genericString4Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_STRING_4,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const genericString5Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_STRING_5,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const genericString6Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_STRING_6,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const genericString7Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_STRING_7,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const genericString8Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_STRING_8,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const genericString9Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_STRING_9,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const genericString10Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_STRING_10,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const genericInteger1Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_INTEGER_1,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const genericInteger2Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_INTEGER_2,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const genericInteger3Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_INTEGER_3,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const genericInteger4Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_INTEGER_4,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const genericInteger5Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_INTEGER_5,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const genericInteger6Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_INTEGER_6,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const genericInteger7Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_INTEGER_7,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const genericInteger8Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_INTEGER_8,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const genericInteger9Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_INTEGER_9,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const genericInteger10Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_INTEGER_10,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const genericDate1Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_1,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const genericDate2Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_2,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const genericDate3Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_3,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const genericDate4Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_4,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const genericDate5Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_5,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const genericDate6Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_6,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const genericDate7Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_7,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const genericDate8Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_8,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const genericDate9Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_9,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const genericDate10Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_10,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const addressCityMention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_ADDRESS_CITY,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const addressCountryMention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_ADDRESS_COUNTRY,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const addressFloorMention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_ADDRESS_FLOOR,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const addressHomeMention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_ADDRESS_HOME,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const addressLine1Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_ADDRESS_LINE_1,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const addressLine2Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_ADDRESS_LINE_2,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const addressStateMention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_ADDRESS_STATE,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const addressStreetMention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_ADDRESS_STREET,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const addressZipCodeMention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_ADDRESS_ZIP_CODE,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const favoriteBranchIdMention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_FAVORITE_BRANCH_ID,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const homeBranchIdMention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_HOME_BRANCH_ID,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const commonExtIDMention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_COMMON_EXT_ID,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const phoneNumberMention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_PHONE_NUMBER,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const budgetWeightedMention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_BUDGETWEIGHTED,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const expirationDateMention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_EXPIRATION_DATE,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const pointsMention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_POINTS,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const comoMemberIdMention = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_COMO_MEMBER_ID,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const comoMemberIdMentionForEmailAction = {
  [constants.MENTION_ID]: constants.CONTEXT_MEMBERSHIP_COMO_MEMBER_ID_FOR_EMAIL_ACTION,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const shareBudgetSenderMention = {
  [constants.MENTION_ID]: constants.CONTEXT_SHARE_BUDGET_SENDER,
  [constants.MENTION_CATEGORY]: constants.MENTION_SHARE_BUDGET_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const shareBudgetReceiveMention = {
  [constants.MENTION_ID]: constants.CONTEXT_SHARE_BUDGET_RECEIVER,
  [constants.MENTION_CATEGORY]: constants.MENTION_SHARE_BUDGET_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const shareBudgetAmountMention = {
  [constants.MENTION_ID]: constants.CONTEXT_SHARE_BUDGET_AMOUNT,
  [constants.MENTION_CATEGORY]: constants.MENTION_SHARE_BUDGET_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const shareBudgetTypeMention = {
  [constants.MENTION_ID]: constants.CONTEXT_SHARE_BUDGET_TYPE,
  [constants.MENTION_CATEGORY]: constants.MENTION_SHARE_BUDGET_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const shareBudgetLinkMention = {
  [constants.MENTION_ID]: constants.CONTEXT_SHARE_BUDGET_LINK,
  [constants.MENTION_CATEGORY]: constants.MENTION_SHARE_BUDGET_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};

// External events mentions
export const dateValue1Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_DATE_VALUE_1,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const dateValue2Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_DATE_VALUE_2,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const dateValue3Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_DATE_VALUE_3,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const dateValue4Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_DATE_VALUE_4,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const dateValue5Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_DATE_VALUE_5,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const dateValue6Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_DATE_VALUE_6,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const dateValue7Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_DATE_VALUE_7,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const dateValue8Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_DATE_VALUE_8,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const dateValue9Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_DATE_VALUE_9,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const dateValue10Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_DATE_VALUE_10,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const numericValue1Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_1,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const numericValue2Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_2,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const numericValue3Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_3,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const numericValue4Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_4,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const numericValue5Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_5,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const numericValue6Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_6,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const numericValue7Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_7,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const numericValue8Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_8,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const numericValue9Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_9,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const numericValue10Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_10,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const stringValue1Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_STRING_VALUE_1,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const stringValue2Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_STRING_VALUE_2,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const stringValue3Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_STRING_VALUE_3,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const stringValue4Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_STRING_VALUE_4,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const stringValue5Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_STRING_VALUE_5,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const stringValue6Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_STRING_VALUE_6,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const stringValue7Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_STRING_VALUE_7,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const stringValue8Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_STRING_VALUE_8,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const stringValue9Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_STRING_VALUE_9,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const stringValue10Mention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_DATA_STRING_VALUE_10,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const subTypeMention = {
  [constants.MENTION_ID]: constants.CONTEXT_DATA_SUB_TYPE,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const eventTimeExternalEventMention = {
  [constants.MENTION_ID]: constants.CONTEXT_EVENT_TIME,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_AND_TIME_MENTION_LENGTH
};
export const branchIdMention = {
  [constants.MENTION_ID]: constants.CONTEXT_PURCHASE_BRANCH_ID,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_PURCHASE_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const cashierMention = {
  [constants.MENTION_ID]: constants.CONTEXT_PURCHASE_CASHIER,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_PURCHASE_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const totalSumMention = {
  [constants.MENTION_ID]: constants.CONTEXT_PURCHASE_TOTAL_SUM,
  [constants.MENTION_CATEGORY]: constants.MENTION_MEMBERSHIP_PURCHASE_CATEGORY,
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};

// Update membership mentions
export const addressCityUpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.AddressCity[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const addressCountryUpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.AddressCountry[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const addressFloorUpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.AddressFloor[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const addressHomeUpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.AddressHome[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const addressLine1UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.AddressLine1[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const addressLine2UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.AddressLine2[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const addressStateUpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.AddressState[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const addressStreetUpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.AddressStreet[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const addressZipCodeUpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.AddressZipCode[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const anniversaryUpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.Anniversary[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const birthdayUpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.Birthday[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const budgetWeightedUpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.BudgetWeighted[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const emailUpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.Email[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const expirationDateUpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.ExpirationDate[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const favoriteBranchIdUpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.FavoriteBranchID[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const firstNameUpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.FirstName[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const genericDate1UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericDate1[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const genericDate2UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericDate2[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const genericDate3UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericDate3[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const genericDate4UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericDate4[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const genericDate5UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericDate5[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const genericDate6UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericDate6[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const genericDate7UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericDate7[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const genericDate8UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericDate8[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const genericDate9UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericDate9[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const genericDate10UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericDate10[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
export const genericInteger1UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericInteger1[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const genericInteger2UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericInteger2[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const genericInteger3UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericInteger3[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const genericInteger4UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericInteger4[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const genericInteger5UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericInteger5[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const genericInteger6UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericInteger6[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const genericInteger7UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericInteger7[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const genericInteger8UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericInteger8[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const genericInteger9UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericInteger9[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const genericInteger10UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericInteger10[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const genericString1UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericString1[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const genericString2UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericString2[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const genericString3UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericString3[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const genericString4UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericString4[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const genericString5UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericString5[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const genericString6UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericString6[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const genericString7UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericString7[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const genericString8UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericString8[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const genericString9UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericString9[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const genericString10UpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.GenericString10[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const homeBranchIdUpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.HomeBranchID[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const lastNameUpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.LastName[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const memberIdUpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.MemberID[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const phoneNumberUpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.PhoneNumber[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.TEXT_MENTION_LENGTH
};
export const pointsUpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.Points[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.NUMBER_MENTION_LENGTH
};
export const termsOfUseDateUpdateMembershipMention = {
  [constants.MENTION_ID]: 'context.UpdatedFields.TermsOfUseDate[0].OldValue',
  [constants.MENTION_CATEGORY]: 'BeforeUpdateMembership',
  [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_MENTION_LENGTH
};
// Factory mentions
export const GetTimeStampMention = (category) => ({ [constants.MENTION_ID]: constants.CONTEXT_TIMESTAMP, category, [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_AND_TIME_MENTION_LENGTH });
export const GetUnixTimeStampMention = (category) => ({ [constants.MENTION_ID]: constants.CONTEXT_PURCHASE_TIMESTAMP, category, [constants.MENTION_DEFAULT_SMS_LENGTH]: constants.DATE_AND_TIME_MENTION_LENGTH });
