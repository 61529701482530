import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { fromJS } from 'immutable';
import { injectIntl } from 'react-intl';
import { ITEMS_POPULATION_TYPE_ADVANCED_RULES, } from './itemsPopulation.constants';
import Conditions from '../conditions/conditions';
import DropdownWrapper from '../../../../common/components/DropdownWrapper/DropdownWrapper';
import * as constants from '../../activity.constants';

class AdvancedRules extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string,
    conditionsRelations: PropTypes.string,
    conditions: PropTypes.array.isRequired,
    advancedRulesSchemaConditions: PropTypes.object,
    type: PropTypes.string,
    updateConditionField: PropTypes.func,
    onAddCondition: PropTypes.func,
    onDeleteCondition: PropTypes.func,
    updateConditionsRelations: PropTypes.func,
    errors: PropTypes.object,
    getBusinessBundlePlan: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.createEmptyCondition = this.createEmptyCondition.bind(this);
  }

  componentWillMount() {
    this.createEmptyCondition(this.props.conditions);
  }

  componentWillReceiveProps(nextProps) {
    this.createEmptyCondition(nextProps.conditions);
  }

  createEmptyCondition(conditions) {
    if (!conditions.length) {
      this.props.onAddCondition(ITEMS_POPULATION_TYPE_ADVANCED_RULES);
    }
  }

  render() {

    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const clsName = classNames('items-list', { 'item-box-error': !!this.props.errors });

    return (
      <div className={clsName}>
        <div className="conditions">
          <Conditions
            allowDeleteOnlyCondition={false}
            activityConditions={fromJS(this.props.conditions)}
            automationIdPrefix={this.props.automationIdPrefix}
            conditionsList={this.props.advancedRulesSchemaConditions}
            conditionsRelations={this.props.conditionsRelations}
            mainConditions={false} // todo: rename to allowFilterCondition
            addCondition={() => this.props.onAddCondition(this.props.type)}
            deleteCondition={(index) => this.props.onDeleteCondition(this.props.type, index)}
            updateConditionField={this.props.updateConditionField}
            validationErrors={this.props.errors}
            getBusinessBundlePlan={this.props.getBusinessBundlePlan}
            isAdvancedRuleConditions
          />
        </div>
        <div className="conditions-relations">
          <span>{formatMessage({ id: 'activity.itemsPopulation.conditionsRelationMessage.prefix' })} <DropdownWrapper
            inline
            selection={false}
            fluid={false}
            options={[
              {
                text: formatMessage({ id: `activity.itemsPopulation.conditionsRelationMessage.${constants.CONDITION_AND_TEXT}` }), // eslint-disable-line react/prop-types
                value: constants.CONDITION_AND,
                key: constants.CONDITION_AND_TEXT
              },
              {
                text: formatMessage({ id: `activity.itemsPopulation.conditionsRelationMessage.${constants.CONDITION_OR_TEXT}` }), // eslint-disable-line react/prop-types
                value: constants.CONDITION_OR,
                key: constants.CONDITION_OR_TEXT
              }
            ]}
            onSelectOption={(e, data) => this.props.updateConditionsRelations(this.props.type, data.value)}
            value={this.props.conditionsRelations}
            name={constants.CONDITION_RELATIONS}
            prefix={`${this.props.automationIdPrefix}.${constants.CONDITION_RELATIONS}`}
          /> {formatMessage({ id: 'activity.itemsPopulation.conditionsRelationMessage.suffix' })}</span>
        </div>
      </div>
    );
  }
}

export default injectIntl(AdvancedRules);
