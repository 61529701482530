import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Grid, Radio } from 'semantic-ui-react';
import classNames from 'classnames';

import '../membershipConditions/MembershipGlobalConditions.styles.less';
import * as constants from '../../../../activity.constants';
import * as activitySelectors from '../../../../activity.selectors';
import withAuthorization from '../../../../../../common/helpers/authorization';
import * as schemaSelectors from '../../../../activitySchema/activitySchema.selectors';
import FilterUserAction from './FilterUserAction';
import * as appSelectors from '../../../../../App/selectors';

class FilterUserActions extends React.PureComponent {

  static propTypes = {
    className: PropTypes.string,
    conditionsModel: PropTypes.object,
    dateRangeConditionSchema: PropTypes.object,
    features: PropTypes.object,
    schemaConditionsPerUserAction: PropTypes.object.isRequired,
    filtersInProgress: PropTypes.bool,
    schemaUserActions: PropTypes.object,
    tempReferralCondition: PropTypes.object,
    tempCondition: PropTypes.object,
    validationErrors: PropTypes.object,
    businessTimezone: PropTypes.string,
    updateFilterUserActionType: PropTypes.func.isRequired,
    addUserActionCriteriaToFilter: PropTypes.func.isRequired,
    updateFilterUserActionAttribute: PropTypes.func.isRequired,
    deleteUserActionFromFilter: PropTypes.func.isRequired,
    deleteUserActionCriteriaFromFilter: PropTypes.func.isRequired,
    updateUserActionFilterDateRange: PropTypes.func.isRequired,
    updateFilterUserActionAggregationAttribute: PropTypes.func.isRequired,
    referralTempError: PropTypes.string,
    createReferralCodeTempModel: PropTypes.func,
    deleteReferralCodeTempModel: PropTypes.func,
    saveReferralCodeTempModelToGlobalConditions: PropTypes.func,
    getBusinessBundlePlan: PropTypes.func.isRequired,
    onUpdateConditionRelation: PropTypes.func
  };

  get tempModelMembershipUserActionsConditions() {
    return this.props.conditionsModel ?
      this.props.conditionsModel.getIn([constants.USER_ACTIONS_CONDITIONS, constants.USER_ACTIONS_CONDITIONS_LIST]) :
      null;
  }

  get tempModelMembershipUserActionsOperator() {
    return this.props.conditionsModel ?
      this.props.conditionsModel.getIn([constants.USER_ACTIONS_CONDITIONS, constants.CONDITION_LIST_OPERATOR]) :
      null;
  }

  handleUpdateConditionRelation(relation) {
    this.props.onUpdateConditionRelation(relation);
  }

  /**
   * render
   * @returns {*}
   */
  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    const haveConditions = this.tempModelMembershipUserActionsConditions.count() > 1;
    const conditionsRelation = this.tempModelMembershipUserActionsOperator || constants.CONDITION_AND;

    return this.tempModelMembershipUserActionsConditions ? (
      <Grid className={classNames('member-fields-conditions gray-panel member-actions-fields-conditions', this.props.className)}>
        <p className="member-conditions-subtitle">
          <i className="como-svg-icon-memberActions" />
          {formatMessage({ id: 'activity.global.conditions.members.actions' })}
          <span
            className="hint-tip-label"
          >{formatMessage({ id: 'global.conditions.specific.members.actions.hint' })}</span>
        </p>
        <div className={classNames('!tw-p-0', haveConditions && 'user-actions tw-relative tw-ring-1 tw-rounded-md tw-w-full tw-ml-10 tw-mr-8 !tw-pt-12 !tw-pb-6 !tw-px-4')}>
          {haveConditions && (
            <div className={'gray-panel tw-absolute -tw-top-3 tw-left-5 tw-px-2'}>
              <Radio
                className={'!tw-p-0 tw-mb-0 tw-mr-3 tw-uppercase'}
                label={formatMessage({ id: 'activity.conditionsRelation.and' })}
                value={'and'}
                disabled={this.props.filtersInProgress}
                onChange={(e, target) => this.handleUpdateConditionRelation(target.value)}
                checked={conditionsRelation === 'and'}
              />
              <Radio
                className={'!tw-p-0 tw-mb-0 tw-uppercase'}
                label={formatMessage({ id: 'activity.conditionsRelation.or' })}
                value={'or'}
                disabled={this.props.filtersInProgress}
                onChange={(e, target) => this.handleUpdateConditionRelation(target.value)}
                checked={conditionsRelation === 'or'}
              />
            </div>
          )}
          {this.tempModelMembershipUserActionsConditions.map((userAction, userActionIndex) => (
            <FilterUserAction
              key={userActionIndex.toString()}
              userAction={userAction}
              userActionIndex={userActionIndex}
              deleteUserActionFromFilter={() => this.props.deleteUserActionFromFilter(userActionIndex)}
              deleteUserActionCriteriaFromFilter={(conditionIndex) => this.props.deleteUserActionCriteriaFromFilter(userActionIndex, conditionIndex)}
              updateUserActionFilterDateRange={(dateObject, isStart, localeTimeFormat, allowInfinite) => this.props.updateUserActionFilterDateRange(dateObject, isStart, localeTimeFormat, allowInfinite, userActionIndex, this.props.businessTimezone)}
              updateFilterUserActionAggregationAttribute={(name, value, isActive, index) => this.props.updateFilterUserActionAggregationAttribute(name, value, isActive, index, userActionIndex)}
              updateFilterUserActionAttribute={(name, value, index, conditionParent) => this.props.updateFilterUserActionAttribute(name, value, index, userActionIndex, conditionParent)}
              tempReferralCondition={this.props.tempReferralCondition}
              schemaConditionsPerUserAction={this.props.schemaConditionsPerUserAction}
              features={this.props.features}
              validationErrors={this.props.validationErrors ? this.props.validationErrors.getIn([constants.GLOBAL_CONDITIONS, constants.MEMBERSHIP_GLOBAL_CONDITIONS, constants.USER_ACTIONS_CONDITIONS, userActionIndex]) : null}
              addUserActionCriteriaToFilter={() => this.props.addUserActionCriteriaToFilter(userActionIndex)}
              dateRangeConditionSchema={this.props.dateRangeConditionSchema}
              updateFilterUserActionType={(selectedActionName) => this.props.updateFilterUserActionType(selectedActionName, userActionIndex, this.props.businessTimezone)}
              schemaUserActions={this.props.schemaUserActions}
              dataAutomationPrefix={`filter-user-action.${userActionIndex}`}
              placeholder={userActionIndex === 0 ? 'activity.globalConditions.identifyMemberByActions' : 'activity.globalConditions.identifyMemberByAnotherAction'}
              tempCondition={this.props.tempCondition}
              referralTempError={this.props.referralTempError}
              createReferralCodeTempModel={this.props.createReferralCodeTempModel}
              deleteReferralCodeTempModel={this.props.deleteReferralCodeTempModel}
              saveReferralCodeTempModelToGlobalConditions={this.props.saveReferralCodeTempModelToGlobalConditions}
              getBusinessBundlePlan={this.props.getBusinessBundlePlan}
            />
          ))}
        </div>
      </Grid>
    ) : (
      <></>
    );
  }
}

const mapStateToProps = (state) => ({
  dateRangeConditionSchema: schemaSelectors.getListContainingDateRangeCondition(state),
  schema: activitySelectors.getSchemaWithFeatures(state),
  schemaUserActions: activitySelectors.getUserActionFilters(state),
  schemaConditionsPerUserAction: activitySelectors.getConditionsPerUserAction(state),
  filtersInProgress: activitySelectors.getFiltersInProgressFlag(state),
  businessTimezone: appSelectors.getBusinessTimeZone(state)
});

const withConnect = connect(mapStateToProps, null);

export default compose(
  withConnect,
  withAuthorization
)(injectIntl(FilterUserActions));
