import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Dimmer, Loader, Table } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import Tooltip from '../../../common/components/Tooltip/Tooltip';
import Modal from '../../../common/components/CustomModal/CustomModal';
import * as activityConstants from '../../Activity/activity.constants';
import promoCodesSaga from '../promoCodes.saga';
import injectSaga from '../../../utils/injectSaga';
import * as selectors from '../promoCodes.selectors';
import * as promoCodeActions from '../promoCodes.actions';

import * as reducerConstants from '../../../constants/reducer.constants';

const NAME_LETTERS_MAX = 25;

class PromoCodeStats extends React.PureComponent {
  static propTypes = {
    actions: PropTypes.object,
    promoCode: PropTypes.object.isRequired,
    onConfirm: PropTypes.func,
    open: PropTypes.bool,
    stats: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = { isTooltipOpen: false };
    this.onTooltipOpen = this.onTooltipOpen.bind(this);
    this.onTooltipClose = this.onTooltipClose.bind(this);
    this.onClose = this.onClose.bind(this);

  }

  componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open) {
      this.props.actions.getPromoCodeStats(this.props.promoCode.get(activityConstants.HUB_ID), this.props.promoCode.get(activityConstants.PROMO_CODE_BULKS_TYPE));
    }
  }

  onTooltipOpen() {
    setTimeout(() => this.setState({ isTooltipOpen: true }), 2000);
  }

  onTooltipClose() {
    this.setState({ isTooltipOpen: false });
  }

  onClose() {
    this.props.actions.cleanPromoCodeStats();
    this.props.onConfirm();
  }

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line
    const { open, promoCode, stats } = this.props;
    const name = promoCode.get(activityConstants.NAME);
    const type = promoCode.get(activityConstants.PROMO_CODE_BULKS_TYPE);
    const title = type != activityConstants.PROMO_CODE_MODE_GENERATED ?
      <p>{formatMessage({ id: 'promoCode-stats.title' }, { name })}</p> : <p>{name}</p>;
    return (
      <Modal
        open={open}
        className="promoCode-stats-modal"
        onClose={this.onClose}
        automationIdPrefix="promoCode.analysis"
        header={
          <div className="type-and-name">
            {name.length > NAME_LETTERS_MAX ?
              <Tooltip
                content={title}
                trigger={title}
                position="bottom right"
              /> : title
            }
          </div>
        }

        content={
          <div className="stats-modal-content">
            <Dimmer inverted active={!stats}>
              <Loader active />
            </Dimmer>
            {type != activityConstants.PROMO_CODE_MODE_GENERATED &&
              <Tooltip
                open={this.state.isTooltipOpen}
                onOpen={this.onTooltipOpen}
                onClose={this.onTooltipClose}
                className="tooltip.ui.popup"
                content={formatMessage({ id: 'promoCode-stats.tooltip' }, { 'redeemed': stats ? stats.get('redeemed') : '?' })}
                trigger={
                  <ul>
                    <li>
                      <span
                        className="log--title stats "
                        data-automation-id="promoCode-stats.redeemed.key"
                      >{formatMessage({ id: 'promoCode-stats.redeemed.title' })}</span>
                      <span
                        className="log--content"
                        data-automation-id="promoCode-stats.redeemed.value"
                      >{stats ? stats.get('redeemed') : '?'}</span>
                    </li>
                  </ul>
                }
                position="bottom left"
              /> }
            {open && type == activityConstants.PROMO_CODE_MODE_GENERATED &&
              <Table className="ui single line fixed selectable very basic table">
                <Table.Header>
                  <Table.Row className="promoCode-bulk-stats-heading-row">
                    <Table.HeaderCell
                      style={{ textAlign: 'left', width: '22%' }}
                      className="list-column-name promoCode-bulk-stats-name-column"
                    >{formatMessage({ id: 'promoCode-bulk-stats.name' })}</Table.HeaderCell>
                    <Table.HeaderCell
                      style={{ textAlign: 'center', width: '30%' }}
                      className="list-column-name promoCode-bulk-stats-progress-column"
                    />
                    <Table.HeaderCell
                      style={{ textAlign: 'center', width: '15%' }}
                      className="list-column-name promoCode-bulk-stats-issued-column"
                    >{formatMessage({ id: 'promoCode-bulk-stats.issued' })}</Table.HeaderCell>
                    <Table.HeaderCell
                      style={{ textAlign: 'center', width: '15%' }}
                      className="list-column-name promoCode-bulk-stats-used-column"
                    >{formatMessage({ id: 'promoCode-bulk-stats.used' })}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                {open && stats && <Table.Body>
                  {stats ? stats.map((s, index) => (
                    <Table.Row key={index} className={'promoCode-bulk-stats-body-row'}>
                      <Table.Cell className="promoCode-bulk-stats-used-column" style={{ textAlign: 'left', width: '35%' }}>
                        <div className={'grey'}>{(index + 1) + '.   '}</div>
                        { s ? '   ' + s.get('name') : '?'}
                      </Table.Cell>
                      <Table.Cell className="members-bar" style={{ textAlign: 'center', width: '35%' }} >
                        <div className={'empty-bar'} style={{ width: '100%', background: `linear-gradient(to right,#b6bcd1 ${((s ? s.get('usedCount') : 0) / (s ? s.get('issuedCount') : 1)) * 100}% , rgb(234, 240, 243) 0%)` }}></div>
                      </Table.Cell>
                      <Table.Cell className="promoCode-bulk-stats-used-column" style={{ textAlign: 'center', width: '15%' }} >
                        {s ? s.get('issuedCount') : '?'}
                      </Table.Cell>
                      <Table.Cell className="promoCode-bulk-stats-used-column" style={{ textAlign: 'center', width: '15%' }}>
                        {s ? s.get('usedCount') : '?'}
                      </Table.Cell>
                    </Table.Row>
                  )) : ''}
                </Table.Body>}
              </Table>}
          </div>
        }

        actions={[
          <a data-automation-id="promoCode-stats.got.it.button" role="button" key="gotIt" onClick={this.onClose}>{formatMessage({ id: 'general.button.got-it' })}</a>
        ]}
        closeOnDimmerClick
      />
    );
  }
}

const mapStateToProps = (state) => ({
  stats: selectors.getCPromoCodesStats(state)
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(promoCodeActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.PROMO_CODES_BRANCH, saga: promoCodesSaga });

export default compose(
  withConnect,
  withSaga,
  injectIntl
)(PromoCodeStats);
