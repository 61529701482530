/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Dimmer, Loader } from 'semantic-ui-react';
import ReduxToastr from 'react-redux-toastr';
import moment from 'moment';

import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import reducer from './app.reducer';
import saga from './app.saga';
import AdminRoute from './routers/admin';

import * as constants from './app.constants';
import * as appActions from './app.actions';
import * as routeConstants from '../../constants/route.contants';
import './app.styles.less';
import NotFoundPage from './../NotFoundPage/Loadable';
import SomethingWentWrongPage from './../SomethingWentWrong/Loadable';
import ForbiddenPage from './../Forbidden/Loadable';

import Header from './../Header/header.Loadable';
import HomePage from './../HomePage/Loadable';
import BusinessCenterPage from './../BusinessCenter/businessCenter.container';
import ActivityPage from '../Activity/activity.container';
import Templates from '../Templates/Templates.container';
import TemplatesAdminPage from '../Templates/Admin/admin.container';
import GoalsPage from '../ActivityGoals/goals.container';
import GoalPage from '../ActivityGoals/Goal/goal.container';
import GoalsAdminPage from '../ActivityGoals/Admin/admin.container';
import GiftsPage from '../Gifts/gifts.container';
import PromoCodesPage from '../PromoCodes/promoCodes.container';
import EmailsPage from '../Emails/Emails.container';
import EmailPage from '../Email/Email.container';
import WelcomePage from '../Welcome/Welcome.container';
import PointShop from '../PointShop/pointShop.container';
import PointShopItem from '../PointShopItem/pointShopItem.container';
import BasicPage from '../Basic/Basic.container';
import Upgrade from '../Upgrade/Upgrade.container';

import * as selectors from './selectors';
import ErrorMessage from '../ErrorMessage/views/ErrorMessage';

import ModulesManagerConfig from '../../config/modules.manager.config';
import ModulesManager from './routers/module';

import Customer from '../Members/Members.container';
import MicroCampaigns from '../MicroCampaigns/MicroCampaigns.container';
import MicroCampaign from '../MicroCampaign/MicroCampaign.container';
import FilterMembers from '../FilterMembers/FilterMembers.container';
import DataExport from '../DataAndBI/DataExport/dataExport.container';
import LocationsGallery from '../Images/views/LocationsGallery/LocationsGallery.container';
import HeaderNotification from '../Activity/views/activityHeader/HeaderNotification';

export class App extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    ajaxRequestsCount: PropTypes.number.isRequired,
    business: PropTypes.object,
    businessCsms: PropTypes.object,
    supportEmails: PropTypes.object,
    consentContent: PropTypes.object,
    countryCode: PropTypes.string,
    businessBundlePlan: PropTypes.string,
    domains: PropTypes.object,
    hub1Path: PropTypes.string.isRequired,
    initialAppDataLoaded: PropTypes.bool.isRequired,
    isoCurrencySymbol: PropTypes.string,
    currencySymbol: PropTypes.string,
    isLoading: PropTypes.bool,
    isRtl: PropTypes.bool,
    locale: PropTypes.string,
    messages: PropTypes.object,
    userEmail: PropTypes.string,
    userTotpOtpPath: PropTypes.string,
    comoAcademyUrl: PropTypes.string,
    isComoUser: PropTypes.bool,
    localeName: PropTypes.string,
    user: PropTypes.object,
    location: PropTypes.object,
    hotReload: PropTypes.bool,
    isFranchises: PropTypes.bool,
    unsubscribeMessage: PropTypes.string,
    features: PropTypes.object,
    lastTagsRetrievalTime: PropTypes.string,
    applicationDataErrorCode: PropTypes.number,
    locationId: PropTypes.number,
    monthlySmsMessagesLimitation: PropTypes.number,
    monthlyEmailMessagesLimitation: PropTypes.number,
    locations: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.array.isRequired
    ]),
    hasBasicPlanPermissions: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      contextRef: null
    };
  }

  componentWillMount() {
    const { actions, hub1Path, hotReload, countryCode } = this.props;

    if (hotReload) {
      return;
    }

    actions.getApplicationData(hub1Path);
    actions.getFranchisesData();
    actions.changeLocale(constants.DEFAULT_LOCALE, countryCode);
    actions.getLocations();
    actions.getMonthlyCommunicationsLimitation();
    actions.getFeatures();
  }

  componentDidMount() {
    window.addEventListener('click', this.refetchBusinessTagsIfNeeded);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.refetchBusinessTagsIfNeeded);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.locationId && prevProps.locationId !== this.props.locationId) {
      this.props.actions.getBusinessTags();
    }
  }

  refetchBusinessTagsIfNeeded = () => {
    if (moment().diff(moment(this.props.lastTagsRetrievalTime)) > constants.TIME_TO_RETRIEVE_MILLISECONDS) {
      this.props.actions.getBusinessTags();
    }
  };

  getRoutesFromModuleConfig() {
    const moduleRoutes = [];
    ModulesManagerConfig.modules.map((module) => moduleRoutes.push(module.route));
    return moduleRoutes;
  }
  isBasicAllowedRoutes = () => {
    const basicAllowedRoutes = [routeConstants.FORBIDDEN_ROUTE, routeConstants.CUSTOMER_ROUTE, routeConstants.BASIC_ROUTE, routeConstants.WELCOME_ROUTE];
    const currentURL = window.location.href;

    for (let i = 0; i < basicAllowedRoutes.length; i++) {
      if (currentURL.includes(basicAllowedRoutes[i])) {
        return true;
      }
    }
    return false;
  }
  render() {
    const { isoCurrencySymbol, localeName, consentContent, applicationDataErrorCode } = this.props;
    const reactIntlFormats = {
      number: {
        'hub-currency': { style: 'currency', minimumFractionDigits: 2, currency: isoCurrencySymbol }
      }
    };
    const showDemoModeNote = this.props.user && this.props.user.get(constants.IS_DEMO_USER);
    const isBasicPlan = this.props.business && this.props.business.get(constants.BUNDLE_PLAN) === constants.TRIAL;
    const showBasicNote = this.props.user && this.props.user.get(constants.IS_COMO_USER) && isBasicPlan;
    const moduleComponentRoutes = this.getRoutesFromModuleConfig();
    const locale = this.props.locale
      ? this.props.locale
      : constants.DEFAULT_LOCALE;

    if (applicationDataErrorCode === 500) {
      return (<IntlProvider locale={locale} key={locale} messages={this.props.messages} formats={reactIntlFormats}>
        <Switch>
          <Route exact path={routeConstants.SOMETHING_WENT_WRONG} component={SomethingWentWrongPage}/>
        </Switch>
      </IntlProvider>);
    }

    if (this.props.hasBasicPlanPermissions && !this.isBasicAllowedRoutes()) {
      window.location.href = `${routeConstants.BASIC_ROUTE + window.location.search}`;
    }

    if (this.props.messages) {
      return (
        <div className={this.props.isRtl ? 'rtl' : ''}>
          <Dimmer active={this.props.isLoading || !this.props.initialAppDataLoaded} inverted>
            <Loader data-automation-id="app.loader" />
          </Dimmer>
          {
            this.props.initialAppDataLoaded
              ? (<IntlProvider locale={locale} key={locale} messages={this.props.messages} formats={reactIntlFormats}>
                <div className={'header-wrapper'}>
                  <Header
                    business={this.props.business}
                    businessCsms={this.props.businessCsms}
                    supportEmails={this.props.supportEmails}
                    hub1Path={this.props.hub1Path}
                    isComoUser={this.props.isComoUser}
                    localeName={localeName}
                    onLogout={this.props.actions.userLogout}
                    location={this.props.location}
                    userEmail={this.props.userEmail}
                    userId={this.props.user.get('id')}
                    userTotpOtpPath={this.props.userTotpOtpPath}
                    comoAcademyUrl={this.props.comoAcademyUrl}
                  />
                  {showDemoModeNote && <HeaderNotification
                    messageKey="header.demo.notification"
                    automationIdPrefix={'header.notification'}
                  />}
                  {(showBasicNote && !showDemoModeNote) && <HeaderNotification
                    messageKey="header.trial.notification"
                    automationIdPrefix={'header.notification'}
                  />}
                  <ErrorMessage />
                  <Container className="como-hub">
                    <ReduxToastr
                      newestOnTop={false}
                      position="bottom-center"
                      transitionIn="fadeIn"
                      transitionOut="fadeOut"
                      className="comoToast"
                      attention
                      timeOut={5000}
                      showCloseButton
                      getState={(state) => state.get('toastr')}
                    />
                    <Switch>
                      <Route exact path="/" component={HomePage} />
                      <Route exact path={routeConstants.FORBIDDEN_ROUTE} component={ForbiddenPage} />
                      <Route exact path={routeConstants.BASIC_ROUTE} component={BasicPage} />
                      <Route exact path={routeConstants.BUSINESS_CENTER_ROUTE} component={BusinessCenterPage} />
                      <Route exact path={routeConstants.BASIC_BUSINESS_CENTER_ROUTE} component={BusinessCenterPage} />
                      <Route exact path={routeConstants.RULES_CREATE_ROUTE} component={ActivityPage} />
                      <Route exact path={routeConstants.RULES_SPECIFIC_ROUTE} component={ActivityPage} />
                      <Route exact path={routeConstants.DEALS_CREATE_ROUTE} component={ActivityPage} />
                      <Route exact path={routeConstants.DEALS_SPECIFIC_ROUTE} component={ActivityPage} />
                      <Route exact path={routeConstants.ONETIME_CREATE_ROUTE} component={ActivityPage} />
                      <Route exact path={routeConstants.BASIC_ONETIME_CREATE_ROUTE} component={ActivityPage} />
                      <Route exact path={routeConstants.ONETIME_SPECIFIC_ROUTE} component={ActivityPage} />
                      <Route exact path={routeConstants.BASIC_ONETIME_SPECIFIC_ROUTE} component={ActivityPage} />
                      <AdminRoute
                        exact
                        path={routeConstants.TEMPLATE_LIBRARY_ADMIN_ROUTE}
                        redirectTo={routeConstants.TEMPLATE_LIBRARY_ROUTE}
                        component={TemplatesAdminPage}
                        featureName={constants.FEATURES_PRODUCT_TEMPLATES}
                      />
                      <Route exact path={routeConstants.TEMPLATE_LIBRARY_ROUTE} component={Templates} />
                      <Route exact path={routeConstants.BASIC_TEMPLATE_LIBRARY_ROUTE} component={Templates} />
                      <AdminRoute
                        exact
                        path={routeConstants.GOALS_ADMIN_ROUTE}
                        redirectTo={routeConstants.GOALS_ROUTE}
                        component={GoalsAdminPage}
                        featureName={constants.FEATURES_PRODUCT_TEMPLATES}
                      />

                      <Route exact path={routeConstants.GOALS_SPECIFIC_ROUTE} component={GoalPage} />
                      <Route exact path={routeConstants.GOALS_ROUTE} component={GoalsPage} />
                      <Route exact path={routeConstants.GIFTS_ROUTE} component={GiftsPage} />
                      <Route exact path={routeConstants.GIFTS_CREATE_ROUTE} component={ActivityPage} />
                      <Route exact path={routeConstants.GIFTS_SPECIFIC_ROUTE} component={ActivityPage} />
                      <Route exact path={routeConstants.PUNCH_CARDS_CREATE_ROUTE} component={ActivityPage} />
                      <Route exact path={routeConstants.PUNCH_CARDS_SPECIFIC_ROUTE} component={ActivityPage} />
                      <Route exact path={routeConstants.PROMO_CODES_ROUTE} component={PromoCodesPage} />
                      <Route exact path={routeConstants.PROMO_CODES_CREATE_ROUTE} component={ActivityPage} />
                      <Route exact path={routeConstants.PROMO_CODES_SPECIFIC_ROUTE} component={ActivityPage} />
                      <Route exact path={routeConstants.EMAILS_ROUTE} component={EmailsPage} />
                      <Route exact path={routeConstants.BASIC_EMAILS_ROUTE} component={EmailsPage} />
                      <Route exact path={routeConstants.BASIC_EMAILS_CREATE_ROUTE} component={EmailPage} />
                      <Route exact path={routeConstants.EMAILS_CREATE_ROUTE} component={EmailPage} />
                      <Route exact path={routeConstants.EMAILS_SPECIFIC_ROUTE} component={EmailPage} />
                      <Route exact path={routeConstants.BASIC_EMAILS_SPECIFIC_ROUTE} component={EmailPage} />

                      <Route exact path={routeConstants.CUSTOMER_ROUTE} component={Customer} />
                      <Route exact path={routeConstants.WELCOME_ROUTE} component={WelcomePage} />
                      <Route exact path={routeConstants.UPGRADE_ROUTE} component={Upgrade} />

                      <Route exact path={routeConstants.MICRO_CAMPAIGNS_ROUTE} component={MicroCampaigns} />
                      <Route exact path={routeConstants.MICRO_CAMPAIGN_CREATE_ROUTE} component={MicroCampaign} />
                      <Route exact path={routeConstants.MICRO_CAMPAIGN_SPECIFIC_ROUTE} component={MicroCampaign} />
                      <Route exact path={routeConstants.FILTER_MEMBERS_ROUTE} component={FilterMembers} />
                      <Route exact path={routeConstants.BASIC_FILTER_MEMBERS_ROUTE} component={FilterMembers} />
                      <Route exact path={routeConstants.DATA_EXPORT_ROUTE} component={DataExport} />

                      <Route exact path={routeConstants.POINT_SHOP_ROUTE} component={PointShop} />
                      <Route exact path={routeConstants.POINT_SHOP_ITEM_CREATE_ROUTE} component={PointShopItem} />
                      <Route exact path={routeConstants.POINT_SHOP_ITEM_UPDATE_ROUTE} component={PointShopItem} />


                      {moduleComponentRoutes.includes(this.props.location.pathname) &&
                        <ModulesManager
                          applicationData={{
                            business: this.props.business,
                            userEmail: this.props.userEmail,
                            locale: this.props.locale,
                            localeDateFormat: this.props.localeDateFormat,
                            localeTimeFormat: this.props.localeTimeFormat,
                            currencySymbol: this.props.currencySymbol,
                            consentContent: this.props.consentContent,
                            hub1Url: this.props.hub1Path,
                            user: this.props.user,
                            countryCode: this.props.countryCode,
                            domains: this.props.domains,
                            features: this.props.features,
                            getFeatures: this.props.actions.getFeatures,
                            getLocations: this.props.actions.getLocations,
                            unsubscribeMessage: this.props.unsubscribeMessage,
                            gallery: LocationsGallery,
                            customAction: this.props.actions.customAction,
                            isFranchises: this.props.isFranchises,
                            monthlySmsMessagesLimitation: this.props.monthlySmsMessagesLimitation,
                            monthlyEmailMessagesLimitation: this.props.monthlyEmailMessagesLimitation,
                            locations: this.props.locations,
                          }}
                          config={ModulesManagerConfig}
                        />
                      }

                      <Route component={NotFoundPage} />
                    </Switch>
                  </Container>
                </div>
              </IntlProvider>)
              : null
          }
        </div>);
    }
    return null;
  }
}

function mapStateToProps(state) {
  return {
    ajaxRequestsCount: selectors.getLiveHttpRequestCount(state),
    business: selectors.getBusiness(state),
    locations: selectors.getLocations(state),
    businessCsms: selectors.getBusinessCsms(state),
    isFranchises: selectors.isFranchises(state),
    monthlySmsMessagesLimitation: selectors.monthlySmsMessagesLimitation(state),
    monthlyEmailMessagesLimitation: selectors.monthlyEmailMessagesLimitation(state),
    domains: selectors.getDomains(state),
    hub1Path: selectors.getHub1Path(state),
    initialAppDataLoaded: selectors.getInitialAppDataLoadedFlag(state),
    isoCurrencySymbol: selectors.getLocationIsoCurrencySymbol(state),
    currencySymbol: selectors.getLocationCurrencySymbol(state),
    isLoading: selectors.getIsAppLoadingFlag(state),
    isRtl: selectors.isRtl(state),
    locale: selectors.getLocale(state),
    messages: selectors.getTranslations(state),
    user: selectors.getUser(state),
    userEmail: selectors.getUserEmail(state),
    isComoUser: selectors.getIsComoUser(state),
    localeName: selectors.getLocaleName(state),
    businessBundlePlan: selectors.getBusinessBundlePlan(state),
    countryCode: selectors.getCountryCode(state),
    consentContent: {
      [constants.ACTION_SMS_CONTENT]: selectors.getDefaultSMSConsentActionContent(state),
      [constants.ACTION_EMAIL_CONTENT]: selectors.getDefaultEmailConsentActionContent(state),
      [constants.ACTION_EMAIL_SUBJECT]: selectors.getDefaultEmailConsentActionSubject(state),
    },
    features: selectors.getFeatures(state),
    unsubscribeMessage: selectors.getUnsubscribeMessage(state),
    localeDateFormat: selectors.getBrowserLocaleDateFormat(state),
    localeTimeFormat: selectors.getBrowserLocaleTimeFormat(state),
    lastTagsRetrievalTime: selectors.getLastTagsRetrievalTime(state),
    userTotpOtpPath: selectors.getUserTotpOtpPath(state),
    comoAcademyUrl: selectors.getComoAcademyUrl(state),
    applicationDataErrorCode: selectors.getApplicationDataErrorCode(state),
    locationId: selectors.getLocationId(state),
    hasBasicPlanPermissions: selectors.hasBasicPlanPermissions(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(appActions, dispatch)
  };
}

const withReducer = injectReducer({ key: constants.APP, reducer });
const withSaga = injectSaga({ key: constants.APP, saga });
const WithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
const WithSaga = withSaga(WithRouter);
const WithReducer = withReducer(WithSaga);

export default WithReducer;
