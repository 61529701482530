import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import FileUploader from '../../../../../../common/components/FileUploader/FileUploader';
import * as globalConditionsActions from '../globalConditions.actions';
import ValidationErrorMessage from '../../../../../../common/components/ValidationErrorMessage/ValidationErrorMessage';
import '../../../importFile.styles.less';


export const VALID_TYPES = 'text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .csv, .xls, .xlsx';
export const MAX_FILE_SIZE = 1024 * 1024 * 100; // 100MB

class ImportMembersFile extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

  static propTypes = {
    actions: PropTypes.object,
    automationIdPrefix: PropTypes.string,
    error: PropTypes.string,
    onUpload: PropTypes.func,
    uploadInProgress: PropTypes.bool,
    uploadedFileName: PropTypes.string,
    uploadedFileLink: PropTypes.string,
    uploadFileType: PropTypes.string
  };

  /**
   * render
   * @returns {*}
   */
  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    return (
      <div className={'import-members-file-uploaded'}>
        <FileUploader
          allowMultiple={false}
          automationIdPrefix="globalConditions.members.importMembers.fileUploader"
          classNames={this.props.uploadedFileName ? 'import-members-file-uploaded-dnd' : 'import-members-file-dnd'}
          iconClassName="como-svg-icon-upload-file"
          fileUploadName={this.props.uploadedFileName}
          fileNameClassName="import-members-file-name"
          onClick={this.props.onUpload}
          displayUploadError={this.props.actions.displayUploadMembersFileError}
          maxFileSize={MAX_FILE_SIZE}
          uploadFiles={(fileHandles) => this.props.actions.uploadImportMembersFile(fileHandles[0], this.props.uploadFileType)}
          uploadInProgress={this.props.uploadInProgress}
          validTypes={VALID_TYPES}
        />
        {this.props.uploadedFileLink && <a className={'download-file'} href={this.props.uploadedFileLink}><Icon className="como-svg-icon-download" /> {formatMessage({ id: 'download.file' })}</a>}
        {this.props.error &&
          <ValidationErrorMessage
            className="file-error-message"
            errorMessage={formatMessage({ id: this.props.error })}
            dataAutomationId={`${this.props.automationIdPrefix}.error.message`}
          />
        }
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(globalConditionsActions, dispatch)
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(injectIntl(ImportMembersFile));
