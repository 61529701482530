import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Dimmer, Grid, Loader } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import Tooltip from '../../../common/components/Tooltip/Tooltip';
import Modal from '../../../common/components/CustomModal/CustomModal';
import * as activityConstants from '../../Activity/activity.constants';
import injectSaga from '../../../utils/injectSaga';
import * as activityActions from '../../Activity/activity.actions';
import activitySaga from '../../Activity/activity.saga';
import * as selectors from '../../Activity/activity.selectors';
import * as appSelectors from '../../../features/App/selectors';
import ActivityType from './ActivityType';
import * as reducerConstants from '../../../constants/reducer.constants';
import ActivityStatistic from './ActivityStatistic';

const NAME_LETTERS_MAX = 25;

class DealStats extends React.PureComponent {
  // eslint-disable-next-line no-undef
  static propTypes = {
    activity: PropTypes.object.isRequired,
    open: PropTypes.bool,
    onConfirm: PropTypes.func,
    stats: PropTypes.object,
    type: PropTypes.string.isRequired,
    actions: PropTypes.object,
    currencySymbol: PropTypes.string,
    localeDateFormat: PropTypes.string.isRequired,
    localeTimeFormat: PropTypes.string.isRequired,
    isPOSAPIVersionSupported: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { isTooltipOpen: false };
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open) {
      if (this.props.isPOSAPIVersionSupported) {
        this.props.actions.getActivityStats(this.props.activity.get(activityConstants.HUB_ID), this.props.activity.get(activityConstants.TYPE));
      }
    }
  }
  shouldDimmerBeShown(stats, isPOSAPIVersionSupported) {
    return isPOSAPIVersionSupported && !stats;
  }

  onOpen() {
    setTimeout(() => this.setState({ isTooltipOpen: true }), 2000);
  }

  onClose() {
    this.setState({ isTooltipOpen: false });
  }

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line
    const { type, open, onConfirm, activity, stats, isPOSAPIVersionSupported } = this.props;
    const createDate = activity.getIn([activityConstants.HISTORY_LOG, activityConstants.ACTIVITY_HISTORY_LOG_CREATED, activityConstants.ACTIVITY_HISTORY_LOG_DATE]);
    const name = activity.get(activityConstants.NAME);
    const title = <p>{formatMessage({ id: 'deal-stats.title' }, { name })}</p>;
    const hint = formatMessage({ id: 'HubDeal-stats.hint' });

    return (
      <Modal
        open={open}
        className="modal__activity__log-details_deals"
        onClose={onConfirm}
        automationIdPrefix={'HubDeal.analysis'}
        header={
          <div className="type-and-name">
            <ActivityType type={type}/>
            {name.length > NAME_LETTERS_MAX ?
              <Tooltip
                content={title}
                trigger={title}
                position="bottom right"
              /> : title
            }
          </div>
        }
        content={
          <div className="stats-modal-content">
            <Dimmer inverted active={this.shouldDimmerBeShown(stats, isPOSAPIVersionSupported)} >
              <Loader active/>
            </Dimmer>
            <Tooltip
              open={this.state.isTooltipOpen}
              onOpen={this.onOpen}
              onClose={this.onClose}
              className="tooltip.ui.popup"
              content={formatMessage({ id: 'deal-stats.tooltip' },
                {
                  'total': stats ? stats.get('totalMembers') : '?',
                  'distinct': stats ? stats.get('distinctMembers') : '?',
                  'discountValue': stats && stats.get('totalDiscount') > 0 ? this.props.currencySymbol + ((stats.get('totalDiscount') / 100).toFixed(2)) : formatMessage({ id: 'deals.stats.no.information' })
                })}
              trigger={

                <span>
                  {
                    isPOSAPIVersionSupported ?
                    <span>
                      <div data-automation-id="deal-stats.createdOn">
                        {`${formatMessage({id: 'deal-stats.createdOn'})}: ${moment(createDate).format(this.props.localeDateFormat)} ${moment(createDate).format(this.props.localeTimeFormat)}`}
                      </div>
                      <ul>
                            <Grid className="statsGrid">
                              <ActivityStatistic
                                label={formatMessage({id: 'deal-stats.total'})}
                                value={stats && stats.get('dealApplied')}
                              />
                              <ActivityStatistic
                                label={formatMessage({id: 'deal-stats.distinct'})}
                                value={stats && stats.get('distinctMembers')}
                              />
                              <ActivityStatistic
                                label={formatMessage({id: 'deal-stats.totalDealTicketSale'})}
                                value={stats && stats.get('totalDealTicketSale') > 0 ? stats.get('totalDealTicketSale') : 0}
                                currencySymbol={this.props.currencySymbol}
                              />
                              <ActivityStatistic
                                label={formatMessage({id: 'deal-stats.avgSpendPerTicket'})}
                                value={stats && stats.get('avgSpendPerTicket') > 0 ? stats.get('avgSpendPerTicket') : 0}
                                currencySymbol={this.props.currencySymbol}
                              />
                              <ActivityStatistic
                                label={formatMessage({id: 'deal-stats.avgSpendPerMember'})}
                                value={stats && stats.get('avgSpendPerMember') > 0 ? stats.get('avgSpendPerMember') : 0}
                                currencySymbol={this.props.currencySymbol}
                              />
                              <ActivityStatistic
                                label={formatMessage({id: 'deal-stats.discount'})}
                                value={stats && stats.get('totalDiscount')}
                                currencySymbol={this.props.currencySymbol}
                                defaultValue={formatMessage({id: 'deals.stats.no.information'})}
                              />
                              <ActivityStatistic
                                label={formatMessage({id: 'deal-stats.avgDiscountPerTicket'})}
                                value={stats && stats.get('avgDiscountPerTicket')}
                                currencySymbol={this.props.currencySymbol}
                                defaultValue={formatMessage({id: 'deals.stats.no.information'})}
                              />
                              <ActivityStatistic
                                label={formatMessage({id: 'deal-stats.avgDealAppliedPerMember'})}
                                value={stats && stats.get('avgDealAppliedPerMember')}
                              />
                            </Grid>
                          </ul>
                      <span className="hint-tip-label stick-to-bottom">
                        {hint}
                      </span>
                    </span>
                    :
                    <span>{formatMessage({id: 'deal-stats.pos.notSupported'})}</span>
                  }
                </span>

              }
              position="bottom left"
            />


          </div>
        }
        actions={[
          <a data-automation-id="deal-stats.got.it.button" role="button" key="gotIt" onClick={onConfirm}>
            {formatMessage({ id: 'general.button.got-it' })}
          </a>
        ]}
        closeOnDimmerClick
      />
    );
  }
}

const mapStateToProps = (state) => ({
  stats: selectors.getActivityStats(state),
  currencySymbol: appSelectors.getLocationCurrencySymbol(state),
  localeDateFormat: appSelectors.getBrowserLocaleDateFormat(state),
  localeTimeFormat: appSelectors.getBrowserLocaleTimeFormat(state),
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(activityActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.ACTIVITY_BRANCH, saga: activitySaga });

export default compose(
  withConnect,
  withSaga,
  injectIntl
)(DealStats);

