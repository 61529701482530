/**
 *
 * AccumulatePoints abstract component
 * Depending on the action.type, we are rendering a accumulatePoints XXX component
 *
 * AccumulatePoints is similar to AddPoints, however, it supports the purchase trigger. the AddPoints support any
 * trigger that is not purchase
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import DropdownWrapper from '../../../../../../common/components/DropdownWrapper/DropdownWrapper';
import DisabledItemPosRestriction from '../../../../../../common/components/DisabledItem/DisabledItemPosRestriction';
import * as activityConstants from '../../../../activity.constants';
import AddPointsFixed from './accumulatePointsFixed';
import AddPointsPercentage from './accumulatePointsPercentage';
import AddPointsRate from './accumulatePointsRate';
import CheckBox from '../../../../../../common/components/FormInputFields/CheckboxWrapper/CheckboxWrapper';
import ItemsPopulation from '../../../itemsPopulation/itemsPopulation.container';
import StringArrayInputField
  from '../../../../../../common/components/FormInputFields/StringArrayInputField/StringArrayInputField';
import SingleLineAccordion from '../../../../../../common/components/SingleLineAccordion/singleLineAccordion';
import * as appConstants from '../../../../../App/app.constants';
import * as schemaConstants from '../../../../activitySchema/activitySchema.constants';
import withAuthorization from '../../../../../../common/helpers/authorization';

import './addPoints.styles.less';
import PercentageInputField
  from '../../../../../../common/components/FormInputFields/PercentageInputField/PercentageInputField';

class AccumulatePoints extends React.PureComponent {

  static propTypes = {
    getPermissionLevel: PropTypes.func.isRequired,
    automationIdPrefix: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
    onActionFieldUpdate: PropTypes.func.isRequired,
    limit: PropTypes.bool,
    action: PropTypes.object,
    index: PropTypes.number,
    trigger: PropTypes.string.isRequired,
    addPointsOnChangeType: PropTypes.func,
    features: PropTypes.object,
    validationErrors: PropTypes.object,
    actionsMode: PropTypes.string.isRequired,
    actionsWrapperIndex: PropTypes.number,
    isNegative: PropTypes.bool
  };

  static defaultProps = {
    limit: false,
    action: {
      [activityConstants.TYPE]: activityConstants.ACCUMULATE_POINTS_TYPE_FIXED
    }
  };

  constructor(props) {
    super(props);
    const { intl } = props;

    this.availableWallets = [
      {
        value: activityConstants.ADD_POINTS_WALLET_POINTS,
        text: intl.formatMessage({ id: 'activity.action.addPoints.budgetType.points' })
      },
      {
        value: activityConstants.ADD_POINTS_WALLET_BUDGET,
        text: intl.formatMessage({ id: 'activity.action.addPoints.budgetType.budget' })
      }];

    this.addPointsTypes = {
      [activityConstants.ACCUMULATE_POINTS_TYPE_FIXED]: {
        value: activityConstants.ACCUMULATE_POINTS_TYPE_FIXED,
        text: intl.formatMessage({ id: 'activity.action.accumulatePoints.type.fixed' })
      },
      [activityConstants.ACCUMULATE_POINTS_TYPE_RATE]: {
        value: activityConstants.ACCUMULATE_POINTS_TYPE_RATE,
        text: intl.formatMessage({ id: 'activity.action.accumulatePoints.type.rate' })
      },
      [activityConstants.ACCUMULATE_POINTS_TYPE_PERCENTAGE]: {
        value: activityConstants.ACCUMULATE_POINTS_TYPE_PERCENTAGE,
        text: intl.formatMessage({ id: 'activity.action.accumulatePoints.type.percentage' })
      }
    };
    this.deductPointsTypes = {
      [activityConstants.ACCUMULATE_POINTS_TYPE_FIXED]: {
        value: activityConstants.ACCUMULATE_POINTS_TYPE_FIXED,
        text: intl.formatMessage({id: 'activity.action.deductPoints.type.fixed'})
      },
      [activityConstants.ACCUMULATE_POINTS_TYPE_RATE]: {
        value: activityConstants.ACCUMULATE_POINTS_TYPE_RATE,
        text: intl.formatMessage({id: 'activity.action.deductPoints.type.rate'})
      },
      [activityConstants.ACCUMULATE_POINTS_TYPE_PERCENTAGE]: {
        value: activityConstants.ACCUMULATE_POINTS_TYPE_PERCENTAGE,
        text: intl.formatMessage({id: 'activity.action.deductPoints.type.percentage'})
      }
    };
  }

  handleOnTypeSelect = (e, data) => {
    const { addPointsOnChangeType, index } = this.props;

    switch (data.value) {
      case activityConstants.ACCUMULATE_POINTS_TYPE_PERCENTAGE:
        addPointsOnChangeType(activityConstants.ACCUMULATE_POINTS_TYPE_PERCENTAGE, index, this.props.actionsWrapperIndex);
        break;

      case activityConstants.ACCUMULATE_POINTS_TYPE_RATE:
        addPointsOnChangeType(activityConstants.ACCUMULATE_POINTS_TYPE_RATE, index, this.props.actionsWrapperIndex);
        break;

      case activityConstants.ACCUMULATE_POINTS_TYPE_FIXED:
      default:
        addPointsOnChangeType(activityConstants.ACCUMULATE_POINTS_TYPE_FIXED, index, this.props.actionsWrapperIndex);
    }
  };

  renderExcludeItemsPopulation() {
    const {intl, index, action, onActionFieldUpdate, validationErrors, automationIdPrefix, isNegative } = this.props;
    const {formatMessage} = intl;
    return (
      <div className="limit__content excludeItemsPopulation">
        <div>
          <CheckBox
            name={activityConstants.EXCLUDE_ITEMS_POPULATION_STATUS}
            checked={action.get(activityConstants.EXCLUDE_ITEMS_POPULATION_STATUS)}
            data-automation-id={`${automationIdPrefix}.itemsPopulationStatus`}
            onChange={(e, data) => onActionFieldUpdate(data.name, data.checked, index)}
            label={formatMessage({'id': isNegative ? 'activity.action.redeemPoints.excludeItemsPopulationStatus' : 'activity.action.addPoints.excludeItemsPopulationStatus'})}
          />
          <ItemsPopulation
            disableAny
            disabled={!action.get(activityConstants.EXCLUDE_ITEMS_POPULATION_STATUS)}
            position="bottom left"
            index={index}
            title={formatMessage({'id': 'activity.action.addPoints.excludeItemsPopulation.modal.title'})}
            data={action.get(activityConstants.EXCLUDE_ITEMS_POPULATION).toJS()}
            onUpdate={(e, data) => onActionFieldUpdate(activityConstants.EXCLUDE_ITEMS_POPULATION, data, index)}
            automationIdPrefix={`${automationIdPrefix}.excluded`}
            itemsPopulationValidationErrors={validationErrors ? validationErrors.get(activityConstants.EXCLUDE_ITEMS_POPULATION) : null}
            features={this.props.features}
            posFeature={appConstants.FEATURES_SHOPPING_CART_SUBMIT_PURCHASE}
          />
        </div>
        <div>
          <CheckBox
            name={activityConstants.EXCLUDED_MEANS_OF_PAYMENT_STATUS}
            checked={action.get(activityConstants.EXCLUDED_MEANS_OF_PAYMENT_STATUS)}
            data-automation-id={`${automationIdPrefix}.excludedMeansOfPaymentStatus`}
            onChange={(e, data) => onActionFieldUpdate(data.name, data.checked, index)}
            label={formatMessage({'id': isNegative ? 'activity.action.redeemPoints.excludedMeansOfPayment' : 'activity.action.addPoints.excludedMeansOfPayment'})}
          />
          <StringArrayInputField
            disabled={!action.get(activityConstants.EXCLUDED_MEANS_OF_PAYMENT_STATUS)}
            value={action.get(activityConstants.EXCLUDED_MEANS_OF_PAYMENT).toArray()}
            onChange={(e, data) => onActionFieldUpdate(activityConstants.EXCLUDED_MEANS_OF_PAYMENT, data, index)}
            prefix={`${automationIdPrefix}.excludedMeansOfPayment`}
            error={validationErrors ? validationErrors.get(activityConstants.EXCLUDED_MEANS_OF_PAYMENT) : null}
          />
        </div>
        <div className={'deduct_percent'}>
          <CheckBox
            name={activityConstants.DEDUCT_PERCENT_FROM_MEANS_OF_PAYMENT_STATUS}
            checked={action.get(activityConstants.DEDUCT_PERCENT_FROM_MEANS_OF_PAYMENT_STATUS)}
            data-automation-id={`${automationIdPrefix}.deductPercentFromMeansOfPaymentStatus`}
            onChange={(e, data) => onActionFieldUpdate(data.name, data.checked, index)}
            label={formatMessage({'id': isNegative ? 'activity.action.redeemPoints.deductPercentFromMeansOfPayment' : 'activity.action.addPoints.deductPercentFromMeansOfPayment'})}
          />
          <PercentageInputField
            resetValueOnOverflowFromMinMax
            disabled={!action.get(activityConstants.DEDUCT_PERCENT_FROM_MEANS_OF_PAYMENT_STATUS)}
            value={parseFloat(action.get(activityConstants.DEDUCT_PERCENT_FROM_MEANS_OF_PAYMENT))}
            onChange={(e, data) => onActionFieldUpdate(activityConstants.DEDUCT_PERCENT_FROM_MEANS_OF_PAYMENT, data.value, index)}
            prefix={`${automationIdPrefix}.deductPercentFromMeansOfPayment`}
            min={0}
            max={100}
            error={validationErrors ? validationErrors.get(activityConstants.DEDUCT_PERCENT_FROM_MEANS_OF_PAYMENT) : null}
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      intl,
      index,
      action,
      onActionFieldUpdate,
      limit,
      trigger,
      validationErrors,
      automationIdPrefix,
      features,
      actionsMode,
      getPermissionLevel,
      isNegative
    } = this.props;
    const {formatMessage} = intl;
    const hasFeature = getPermissionLevel(appConstants.FEATURES_SHOPPING_CART_SUBMIT_PURCHASE) !== activityConstants.FEATURE_LEVEL.NONE;
    const addPointAvailableTypes = [];
    // todo: it is purchase always
    if (actionsMode === activityConstants.ACTIONS_SIMPLE_MODE && trigger === schemaConstants.TRIGGER_PURCHASE_ANALYZED) {
      if (isNegative) {
        addPointAvailableTypes.push(this.deductPointsTypes[activityConstants.ACCUMULATE_POINTS_TYPE_PERCENTAGE]);
        addPointAvailableTypes.push(this.deductPointsTypes[activityConstants.ACCUMULATE_POINTS_TYPE_RATE]);
      } else {
        addPointAvailableTypes.push(this.addPointsTypes[activityConstants.ACCUMULATE_POINTS_TYPE_PERCENTAGE]);
        addPointAvailableTypes.push(this.addPointsTypes[activityConstants.ACCUMULATE_POINTS_TYPE_RATE]);
      }

    }

    addPointAvailableTypes.push(this.addPointsTypes[activityConstants.ACCUMULATE_POINTS_TYPE_FIXED]);

    const componentProps = {
      onActionFieldUpdate,
      action,
      limit,
      index,
      wallets: this.availableWallets,
      validationErrors,
      automationIdPrefix,
      features,
      isNegative
    };

    let actionTemplate;
    switch (action.get(activityConstants.TYPE)) {
      case activityConstants.ACCUMULATE_POINTS_TYPE_RATE:
        actionTemplate = (<AddPointsRate {...componentProps} />);
        break;
      case activityConstants.ACCUMULATE_POINTS_TYPE_PERCENTAGE:
        actionTemplate = (<AddPointsPercentage {...componentProps} />);
        break;
      case activityConstants.ACCUMULATE_POINTS_TYPE_FIXED:
      default:
        actionTemplate = (<AddPointsFixed {...componentProps} />);
    }

    const shouldDisplayExcludeItemsPopulation = [
      activityConstants.ACCUMULATE_POINTS_TYPE_PERCENTAGE,
      activityConstants.ACCUMULATE_POINTS_TYPE_RATE
    ].indexOf(action.get(activityConstants.TYPE)) > -1;
    return (
      <Grid className="action add-points-action">
        <Grid.Row>
          <Grid.Column width={12}>
            <DropdownWrapper
              options={addPointAvailableTypes}
              onSelectOption={this.handleOnTypeSelect}
              name={activityConstants.TYPE}
              value={action.get(activityConstants.TYPE)}
              prefix={`${automationIdPrefix}.type`}
              className="add-points-type"
              error={validationErrors ? validationErrors.get(activityConstants.TYPE) : null}
            />
          </Grid.Column>
        </Grid.Row>
        {actionTemplate}
        {shouldDisplayExcludeItemsPopulation && <Grid.Row>
          <Grid.Column width={12}>
            {
              hasFeature ?
                (<SingleLineAccordion
                  automationIdPrefix={`${automationIdPrefix}.ExcludeItems`}
                  title={formatMessage({id: isNegative ? 'activity.action.redeemPoints.excludeItemsPopulation' : 'activity.action.addPoints.excludeItemsPopulation'})}
                  initialCollapseState={!this.props.action.get(activityConstants.EXCLUDE_ITEMS_POPULATION_STATUS) && !action.get(activityConstants.EXCLUDED_MEANS_OF_PAYMENT_STATUS)}
                >
                  {this.renderExcludeItemsPopulation()}
                </SingleLineAccordion>) :
                (<div className={'disabled-exclude-points-container'} data-automation-id={'disabled-exclude-points-container'}>
                  <DisabledItemPosRestriction>
                    {formatMessage({id: isNegative ? 'activity.action.redeemPoints.excludeItemsPopulation' : 'activity.action.addPoints.excludeItemsPopulation'})}
                  </DisabledItemPosRestriction>
                </div>)
            }
          </Grid.Column>
        </Grid.Row>}
      </Grid>
    );
  }
}

export default withAuthorization(injectIntl(AccumulatePoints));
