import React from 'react';
import PropTypes from 'prop-types';
import './PopupWithTabsContentList.less';
import PopupWrapper from '../PopupWrapper';
import TabsContentList from '../../Tabs/TabsContentList';

class PopupWithTabsContentList extends React.Component {

  static propTypes = {
    trigger: PropTypes.node.isRequired,
    onChange: PropTypes.func.isRequired,
    automationId: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.shape({
          key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          text: PropTypes.string
        }),
        values: PropTypes.oneOfType([
          PropTypes.object,
          PropTypes.arrayOf(
            PropTypes.shape({
              key: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
              ]),
              text: PropTypes.string
            }))
        ])
      })
    ).isRequired,
    selectedOption: PropTypes.string,
    selectedTabIndex: PropTypes.number,
    disabled: PropTypes.bool
  };

  state = {
    isPopupOpen: false
  };

  handleOnSelect = (value) => {
    this.props.onChange(value);
    this.handleOnPopupClose();
  };

  handleOnPopupOpen = () => {
    this.setState({
      isPopupOpen: true
    });
  };

  handleOnPopupClose = () => {
    this.setState({
      isPopupOpen: false
    });
  };

  render() {

    const { trigger, options, automationId, selectedOption, selectedTabIndex, disabled } = this.props;
    return (
      <PopupWrapper
        className="popup-with-tabs"
        open={this.state.isPopupOpen}
        position="bottom left"
        hideTrigger={false}
        on="click"
        onOpen={this.handleOnPopupOpen}
        onClose={this.handleOnPopupClose}
        flowing
        automationId={`${automationId}.popup`}
        trigger={trigger}
        disabled={disabled}
      >
        <TabsContentList
          automationId={automationId}
          menu={{ attached: true, tabular: true, pointing: true, secondary: true }}
          options={options}
          onSelectValue={this.handleOnSelect}
          selectedOption={selectedOption}
          selectedTabIndex={selectedTabIndex}
        />
      </PopupWrapper>);
  }
}

export default PopupWithTabsContentList;
