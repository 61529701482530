import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import Tooltip from '@como/web-hub-components/src/Tooltip/Tooltip';
import tracker from '../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../common/utils/tracking/tracking.consts';
import * as CustomPropTypes from './proptypes';
import './VerticalNavBar.styles.less';

class NavBarItem extends React.PureComponent {
  static propTypes = {
    itemKey: PropTypes.string,
    text: PropTypes.string.isRequired,
    icon: PropTypes.string,
    iconSize: PropTypes.string,
    target: PropTypes.shape({ pathname: PropTypes.string, search: PropTypes.string }),
    collapsed: PropTypes.bool,
    onToggleCollapse: PropTypes.func,
    children: CustomPropTypes.NavBarItemPropType,
    parent: PropTypes.instanceOf(NavBarItem),
    automationId: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    parentId: PropTypes.string,
    isContainerCollapsed: PropTypes.bool
  };

  onClick = (e) => {
    if (this.isContainer) {
      this.props.onToggleCollapse(this.props.itemKey, !this.props.collapsed);
    } else {
      this.props.history.push(this.props.target);
      e.preventDefault();
    }
  };

  get isContainer() {
    return this.props.children && React.Children.count(this.props.children) > 0;
  }

  get isActive() {
    return this.props.target &&
      this.props.target.pathname === this.props.location.pathname &&
      this.props.target.search === this.props.location.search;
  }

  getItemContent = (className) => (
    <div
      data-automation-id={this.props.automationId}
      className={className}
    >
      <div
        role={'button'}
        className="vertical-nav-bar-text"
        data-automation-id={`${this.props.automationId}.text`}
        onClick={this.onClick}
      >
        {this.isContainer && <Icon
          className={`item-icon como-ic-triangle-${this.props.collapsed ? 'right' : 'down'}`}
          size="small"
          data-automation-id={`${this.props.automationId}.toggle`}
        />}
        {this.props.icon && <Icon
          className={`item-icon ${this.props.icon}`}
          size={this.props.iconSize}
          data-automation-id={`${this.props.automationId}.icon`}
        />}
        <a role={"button"} onClick={tracker.wrapButtonAction(this.onClick, trackerConstants.BUTTON_TYPE_SIDE_NAR_NAVIGATION, {navigationTarget: this.props.text})}>{this.props.text}</a>
      </div>
      {!this.props.collapsed && this.isContainer &&
        React.Children.map(this.props.children, (item, index) =>
          React.cloneElement(item, {
            itemKey: `${this.props.itemKey}.${index}`,
            parent: this,
            onToggleCollapse: this.props.onToggleCollapse
          }))
        }
    </div>
    );

  render() {
    const className = classNames({
      'nav-bar-items-container': this.isContainer,
      'vertical-nav-bar-child vertical-nav-bar-item': this.props.parentId,
      'vertical-nav-bar-item': !this.props.parent,
      'collapsed': this.props.collapsed,
      'active': this.isActive,
    });
    const itemContent = this.getItemContent(className);
    return (
      this.props.isContainerCollapsed
      ? <Tooltip
        trigger={itemContent}
        content={this.props.text}
        position={'right center'}
        className="vertical-navbar-tooltip"
      />
        : itemContent
    );
  }
}

export default withRouter(NavBarItem);
