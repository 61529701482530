import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import * as constants from '../templates.constants';
import * as activityConstants from '../../Activity/activity.constants';
import SaveTemplateTrigger from './SaveTemplateTrigger';
import * as emailsConstants from '../../Emails/emails.constants';
import ToggleButton from '../../../common/components/ToggleButton/ToggleButton';
import Tooltip from '../../../common/components/Tooltip/Tooltip';
import * as routeConstants from '../../../constants/route.contants';

class TemplatesMenu extends React.PureComponent {

  static propTypes = {
    permissionLevel: PropTypes.string.isRequired,
    prefix: PropTypes.string.isRequired,
    prefixNode: PropTypes.node,
    template: PropTypes.object,
    intl: PropTypes.object,
    showMenu: PropTypes.bool,
    entityType: PropTypes.string.isRequired,
    onEmailSaveAsTemplate: PropTypes.func,
    shouldActivityTagBeShown: PropTypes.bool,
    activity: PropTypes.object,
    updateGiftSimpleMode: PropTypes.func,
    showSimpleModeToggle: PropTypes.bool,
    disableSimpleModeToggle: PropTypes.bool,
    simpleModeTooltipMessage: PropTypes.string,
    hasBasicPlanPermissions: PropTypes.bool,
  };

  render() {
    const { prefix, prefixNode, intl } = this.props;
    const { formatMessage } = intl;
    const classes = classNames('activity-templates-links', !this.props.shouldActivityTagBeShown ? 'activity-templates-links-position' : '');
    const templateLibraryPath = this.props.hasBasicPlanPermissions ? routeConstants.BASIC_TEMPLATE_LIBRARY_ROUTE : routeConstants.TEMPLATE_LIBRARY_ROUTE;

    return (
      <div className={classes}>
        {(typeof this.props.showMenu === 'undefined' || this.props.showMenu) &&
        <div className="activity-links tw-flex tw-items-center">
          {prefixNode}
          <SaveTemplateTrigger
            templateType={constants.TEMPLATE_TYPE_BUSINESS}
            activityPrefix={this.props.prefix}
            entityType={this.props.entityType}
            onEmailSaveAsTemplate={this.props.onEmailSaveAsTemplate}
            template={this.props.template}
          />
          {this.props.permissionLevel === activityConstants.FEATURE_LEVEL.FULL && this.props.entityType !== emailsConstants.EMAIL && (
            <SaveTemplateTrigger
              templateType={constants.TEMPLATE_TYPE_PRODUCT}
              activityPrefix={this.props.prefix}
              entityType={this.props.entityType}
              template={this.props.template}
            />
          )}
          {this.props.template && (
            <div className="loaded-template activity-link">
              <span>{formatMessage({ id: 'activity.indication.template.loaded' })}</span>
              <Link
                to={{ pathname: templateLibraryPath, search: location.search, type: this.props.entityType }}
                data-automation-id={`${activityConstants.AUTOMATION_NAME}.template.load-another`}
              >
                <Icon className="como-ic-reload" size="large" />
                {formatMessage({ id: 'activity.template.trigger.load-another' })}
              </Link>
            </div>
          )}
          {this.props.showSimpleModeToggle && <div style={{ float: 'right', marginTop: '15px' }}>
            {this.props.simpleModeTooltipMessage ?
              <Tooltip
                content={this.props.simpleModeTooltipMessage}
                trigger={
                  <div>
                    <ToggleButton
                      name="simpleMode"
                      data-automation-id={'gift.simpleMode'}
                      automationId={'gift.simpleMode'}
                      id={'simpleMode'}
                      enabled={this.props.activity.get(activityConstants.GIFT_SIMPLE_MODE)}
                      onChange={this.props.updateGiftSimpleMode}
                      disabled={this.props.disableSimpleModeToggle}
                    />
                    <p style={{ display: 'inline', margin: '0 30px 0 15px' }}>{formatMessage({ id: 'gift.simpleMode' })}</p>
                  </div>
                }
              />
              :
              <div>
                <ToggleButton
                  name="simpleMode"
                  data-automation-id={'gift.simpleMode'}
                  automationId={'gift.simpleMode'}
                  id={'simpleMode'}
                  enabled={this.props.activity.get(activityConstants.GIFT_SIMPLE_MODE)}
                  onChange={this.props.updateGiftSimpleMode}
                  disabled={this.props.disableSimpleModeToggle}
                />
                <p style={{ display: 'inline', margin: '0 30px 0 15px' }}>{formatMessage({ id: 'gift.simpleMode' })}</p>
              </div>}
          </div>}
        </div>
        }
      </div>
    );
  }
}

export default injectIntl(TemplatesMenu);
