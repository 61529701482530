import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import DateFnsTz from 'date-fns-tz';

import './customDatePicker.styles.less';

class CustomDatePicker extends React.PureComponent {

  static propTypes = {
    ...DatePicker.propTypes,
    popperClass: PropTypes.string,
    timeZone: PropTypes.string.isRequired,
    datePickerInsideModal: PropTypes.bool
  };

  static defaultProps = {
    ...DatePicker.defaultProps,
    popperClass: 'date-picker-range-wrapper',
    datePickerInsideModal: false
  };

  convertToTimeZone = (momentObj) => {
    if (momentObj.tz() === this.props.timeZone) {
      return momentObj.clone();
    }
    return momentObj.clone().tz(this.props.timeZone, true);
  };

  onChangeHandler = (dateObj, event) => {
    const momentObj = moment(dateObj);
    const changedDateInProperTimeZone = this.convertToTimeZone(momentObj);
    this.props.onChange(changedDateInProperTimeZone.toDate(), event);
  };

  render() {

    // noinspection JSUnusedLocalSymbols
    const { onChange, selected, timeZone, ...restProps } = this.props;

    // const convertedSelected = selected ? DateFnsTz.utcToZonedTime(selected, timeZone) : null
    const convertedSelected = selected || null;
    return (<DatePicker
      {...restProps}
      onChange={this.onChangeHandler}
      withPortal={this.props.datePickerInsideModal}
      selected={convertedSelected}
    />);
  }
}

export default CustomDatePicker;
