import { fromJS, List } from 'immutable';
import * as constants from './emails.constants';
import * as activityConstants from '../Activity/activity.constants';
import * as featuresConstants from '../features.constants';
import * as utils from './utils/emails.reducer.utils';

const initialState = fromJS({
  [constants.FILTERS]: {
    [constants.EMAILS]: {
      [activityConstants.FILTERS_ACTIVITY_ACTIVE]: true,
      [activityConstants.FILTERS_ACTIVITY_STOPPED]: false,
    },
  },
  [constants.SORT]: {
    [constants.ORDER]: constants.ORDER_DESCENDING
  },
  [constants.EMAILS]: [],
  [constants.EMAILS_LOADED]: false
});

function emailsReducer(state = initialState, action) {
  switch (action.type) {
    case constants.GET_EMAILS: {
      return state
        .set(constants.EMAILS_LOADED, false);
    }
    case constants.GET_EMAILS_SUCCESS: {
      return state
        .set(constants.EMAILS, fromJS(action.data))
        .set(constants.EMAILS_LOADED, true);
    }
    case constants.UPDATE_FILTERS: {
      const { status, key, groupKey } = action;
      return state.setIn([constants.FILTERS, groupKey, key], status);
    }
    case constants.UPDATE_ORDER: {
      return state
        .setIn([constants.SORT, constants.ORDER],
          state.getIn([constants.SORT, constants.ORDER]) === featuresConstants.ORDER_DESCENDING ?
            featuresConstants.ORDER_ASCENDING : featuresConstants.ORDER_DESCENDING
        );
    }

    case constants.HIGHLIGHT_ACTIVITY: {
      if (action[activityConstants.HIGHLIGHTED_ACTIVITY_ID]) {
        return state.set(activityConstants.HIGHLIGHTED_ACTIVITY_ID, action[activityConstants.HIGHLIGHTED_ACTIVITY_ID]);
      }
      return state.delete(activityConstants.HIGHLIGHTED_ACTIVITY_ID);
    }

    case constants.SEARCH_EMAILS: {
      return state.set(constants.SEARCH_TERM, action[constants.SEARCH_TERM]);
    }

    case constants.CLEAR_EMAIL_LIST: {
      return state.set(constants.EMAILS, List());
    }

    case constants.GET_EMAIL_STATS_SUCCESS: {
      const stats = action.data;
      return state
        .set(constants.EMAIL_STATS, fromJS(stats))
        .set(constants.EMAIL_STATS_LOADING, false);
    }
    case constants.UPDATE_EMAIL_STATUS_SUCCESS: {
      const email = action.data;
      const emails = state.get(constants.EMAILS);
      const i = emails.findIndex((e) => e.get(constants.EMAIL_SERVER_ID) === email.serverId);
      return state
        .setIn([constants.EMAILS, i], fromJS(email));
    }

    case constants.SET_EMAIL_STATS_LOADING: {
      return state.set(constants.EMAIL_STATS_LOADING, true);
    }
    case constants.CLEAN_EMAIL_STATS: {
      return state
        .delete(constants.EMAIL_STATS)
        .set(constants.EMAIL_STATS_LOADING, false);
    }

    case constants.GET_DETAILED_STATS_PER_ONETIME_CAMPAIGN_SUCCESS: {
      return state
        .setIn([constants.EMAIL_STATS_PER_CAMPAIGN, constants.EMAIL_CAMPAIGN_ID], action[constants.EMAIL_CAMPAIGN_ID])
        .setIn([constants.EMAIL_STATS_PER_CAMPAIGN, featuresConstants.DATA], fromJS(JSON.parse(action.data)));
    }

    case constants.GET_DETAILED_STATS_PER_RULE_SUCCESS: {
      let stats;
      if (action.data.length === 1) {
        stats = JSON.parse(action.data[0]);
      } else {
        stats = utils.combineStats(action.data);
      }
      return state
        .setIn([constants.EMAIL_STATS_PER_RULE, constants.RULE_ID], action.ruleId)
        .setIn([constants.EMAIL_STATS_PER_RULE, featuresConstants.DATA], fromJS(stats));
    }

    case constants.GET_DETAILED_STATS_PER_MICRO_CAMPAIGN_SUCCESS: {
      let stats;
      if (action.data.length === 1) {
        stats = JSON.parse(action.data[0]);
      } else {
        stats = utils.combineStats(action.data);
      }
      return state
        .setIn([constants.EMAIL_STATS_PER_MICRO_CAMPAIGN, constants.MICRO_CAMPAIGN_ID], action.microCampaignId)
        .setIn([constants.EMAIL_STATS_PER_MICRO_CAMPAIGN, featuresConstants.DATA], fromJS(stats));
    }

    case constants.CLEAN_DETAILED_EMAIL_STATS: {
      return state
        .delete(constants.EMAIL_STATS_PER_CAMPAIGN)
        .delete(constants.EMAIL_STATS_PER_RULE);
    }

    case constants.CLEAN_EMAIL_USAGES_DATA: {
      return state.delete(constants.EMAIL_USAGES);
    }

    case constants.GET_EMAIL_USAGES_SUCCESS: {
      const usages = action.data;
      return state.set(constants.EMAIL_USAGES, fromJS(usages));
    }

    default: {
      return state;
    }
  }
}

export default emailsReducer;
