import React from 'react';
import PropTypes from 'prop-types';
import {fromJS} from 'immutable';
import {injectIntl} from 'react-intl';
import {Button, Radio} from 'semantic-ui-react';
import classNames from 'classnames';
import PopupWrapper from '../../../../../../../common/components/PopupWrapper/PopupWrapper';
import PopupTrigger from '../../../../../../../common/components/PopupTrigger/PopupTrigger';
import * as constants from '../../../../../activity.constants';
import Capsules from '../../../../../../../common/components/Capsules/Capsules';
import './referralCode.styles.less';
import ConditionOperator from '../../../ConditionOperator';
import { arrayOperators } from '../../../../../activitySchema/activitySchema.operators';


class ReferralCodeCondition extends React.PureComponent {

  static propTypes = {
    error: PropTypes.string,
    index: PropTypes.number,
    intl: PropTypes.object,
    onChange: PropTypes.func,
    prefix: PropTypes.string,
    value: PropTypes.object,
    userActionIndex: PropTypes.number,
    tempCondition: PropTypes.object,
    tempError: PropTypes.string,
    createReferralCodeTempModel: PropTypes.func.isRequired,
    deleteReferralCodeTempModel: PropTypes.func.isRequired,
    saveReferralCodeTempModelToGlobalConditions: PropTypes.func.isRequired,
    operatorKey: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false
    };
    this.onPopupOpen = this.onPopupOpen.bind(this);
    this.onPopupClose = this.onPopupClose.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.onReferralCodeFieldChange = this.onReferralCodeFieldChange.bind(this);
  }


  componentDidMount() {
    this.handleScroll();
  }

  componentDidUpdate() {
    this.handleScroll();
  }


  onPopupOpen() {
    this.setState({ isPopupOpen: true });
    this.props.createReferralCodeTempModel(this.props.userActionIndex, this.props.index);
  }

  onPopupClose(event) {
    if (event && event.srcElement && event.srcElement.classList.contains('delete')) {
      return;
    }
    this.setState({ isPopupOpen: false });
    this.props.deleteReferralCodeTempModel();
  }

  onReferralCodeFieldChange(e, data) {
    const conditionValue = this.props.tempCondition.get(constants.CONDITION_VALUE).toJS();
    if (Array.isArray(data.value)) {
      conditionValue[constants.CONDITION_VALUE_REFERRAL_CODE] = data.value;
    } else {
      conditionValue[constants.CONDITION_VALUE_IS_REFERRED] = data.value === constants.REFERRAL_CODE_TYPE_ANY;
      conditionValue[constants.CONDITION_VALUE_REFERRAL_DATA_TYPE] = data.value;
    }
    this.props.onChange(e, { name: constants.CONDITION_VALUE, value: conditionValue }, this.props.index);
  }

  handleScroll() {
    setTimeout(() => {
      if (this.scrollArea) {
        this.scrollArea.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }

  saveChanges() {
    this.props.saveReferralCodeTempModelToGlobalConditions(this.props.userActionIndex, this.props.index);
    this.onPopupClose();
  }

  get referralDataConditionType() {
    return this.props.tempCondition ? this.props.tempCondition.getIn([constants.CONDITION_VALUE, constants.REFERRAL_CODE_CONDITION_TYPE]) : null;
  }

  get triggerMessage() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const type = this.props.value[constants.CONDITION_VALUE_REFERRAL_DATA_TYPE];
    let displayValue = formatMessage({ id: 'activity.condition.referral.select' });
    if (type === constants.REFERRAL_CODE_TYPE_SPECIFIC && this.props.value[constants.CONDITION_VALUE_REFERRAL_CODE].length > 0) {
      const prefix = this.props.operatorKey === constants.IS_NOT_ONE_OF ? formatMessage({ id: 'hidden.operator.is.not' }) : '';
      displayValue = `${prefix}  ${this.props.value[constants.CONDITION_VALUE_REFERRAL_CODE].join(', ')}`;
    } else if (type === constants.REFERRAL_CODE_TYPE_ANY) {
      displayValue = formatMessage({ id: 'activity.condition.referral.any' });
    } else if (type === constants.REFERRAL_CODE_TYPE_NO) {
      displayValue = formatMessage({ id: 'activity.condition.referral.no' });
    }
    return displayValue;
  }

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const triggerClasses = classNames('ui fluid selection dropdown selected-option popup-trigger', {
      'popup-trigger-error': this.props.error
    });
    const referralConditionType = this.referralDataConditionType;
    const referralCode = this.props.tempCondition ? this.props.tempCondition.getIn([constants.CONDITION_VALUE, constants.CONDITION_VALUE_REFERRAL_CODE]).toJS() : [];
    return (
      <div>
        <PopupWrapper
          className="condition-membership-referral-code-popup"
          on="click"
          size={'large'}
          position="bottom left"
          onOpen={this.onPopupOpen}
          open={this.state.isPopupOpen}
          automationId={`${this.props.prefix}.popup`}
          wide="very"
          trigger={(
            <div className="inline-div">
              <PopupTrigger
                className={triggerClasses}
                message={this.triggerMessage}
                automationId={this.props.prefix}
              />
            </div>
          )}
        >
          <div className="div-scrollable">
            <Radio
              label={`${formatMessage({ id: 'activity.condition.referral.no' })}`}
              name="referralCodeCondition"
              value={constants.REFERRAL_CODE_TYPE_NO}
              checked={referralConditionType === constants.REFERRAL_CODE_TYPE_NO}
              onChange={(e, data) => this.onReferralCodeFieldChange(e, data)}
              data-automation-id={`${this.props.prefix}.radio.no`}
            /><br />
            <Radio
              label={`${formatMessage({ id: 'activity.condition.referral.any' })}`}
              name="referralCodeCondition"
              value={constants.REFERRAL_CODE_TYPE_ANY}
              checked={referralConditionType === constants.REFERRAL_CODE_TYPE_ANY}
              onChange={(e, data) => this.onReferralCodeFieldChange(e, data)}
              data-automation-id={`${this.props.prefix}.radio.any`}
            /><br />
            <Radio
              className="specific-values-checkbox"
              label={`${formatMessage({ id: 'activity.condition.referral.specific' })}`}
              name="referralCodeCondition"
              value={constants.REFERRAL_CODE_TYPE_SPECIFIC}
              checked={referralConditionType === constants.REFERRAL_CODE_TYPE_SPECIFIC}
              onChange={(e, data) => this.onReferralCodeFieldChange(e, data)}
              data-automation-id={`${this.props.prefix}.radio.specific`}
            />
            {referralConditionType === constants.REFERRAL_CODE_TYPE_SPECIFIC &&
              <div className="specific-values">
                <ConditionOperator
                  automationIdPrefix={this.props.prefix}
                  options={fromJS(arrayOperators)}
                  value={this.props.tempCondition.get(constants.OPERATOR_KEY) ? this.props.tempCondition.get(constants.OPERATOR_KEY) : arrayOperators[0]}
                  index={this.props.index}
                  name={constants.OPERATOR_KEY}
                  onConditionUpdate={this.props.onChange}
                />
                <Capsules
                  value={referralCode}
                  onChange={(e, data) => this.onReferralCodeFieldChange(e, data)}
                  error={this.props.tempError}
                  prefix={`${this.props.prefix}.specificValues`}
                />
              </div>
            }
          </div>
          <div className="divider popupFooter">
            <Button
              onClick={this.onPopupClose}
              basic
              className="link"
              data-automation-id={`${this.props.prefix}.cancel`}
            >
              {formatMessage({ id: 'general.button.cancel' })}
            </Button>
            <Button
              disabled={!referralConditionType || (referralConditionType === constants.REFERRAL_CODE_TYPE_SPECIFIC && referralCode.length === 0)}
              onClick={this.saveChanges}
              data-automation-id={`${this.props.prefix}.save`}
            >
              {formatMessage({ id: 'general.button.save' })}
            </Button>
          </div>
        </PopupWrapper>
      </div>
    );
  }
}

export default injectIntl(ReferralCodeCondition);

