import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import IntegerInputField from '../../../../../../common/components/FormInputFields/IntegerInputField/IntegerInputField';
import * as schemaConstants from '../../../../activitySchema/activitySchema.constants';
import './updateMembership.styles.less';

/**
 * This action should allow the user to update fields on the membership based on:
 * The registration form fields
 * And a static list of special fields

 * Currently it will only create update membership expiration action.
 * This is a spacial case with a special ui.

 * Due to ROI = The model for the full update membership action will break when more fields will be added.
 * */
class UpdateMembershipExpiration extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string,
    onActionFieldUpdate: PropTypes.func.isRequired,
    action: PropTypes.object,
    index: PropTypes.number,
    validationErrors: PropTypes.object
  };


  render() {
    const { action, index, onActionFieldUpdate, validationErrors, automationIdPrefix } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    return (
      <Grid>
        <Grid.Row className="action update-membership">
          <Grid.Column width={16}>
            <div>
              <span className="inner-label">{formatMessage({ id: 'activity.action.updateMembershipExpiration.extendExpirationSentence' })}</span>
              <IntegerInputField
                allowOnlyPositive
                name={schemaConstants.UPDATE_MEMBERSHIP_EXPIRATION_DURATION_VALUE_FIELD}
                onChange={(e, data) => onActionFieldUpdate(data.name, data.value, index)}
                value={action.get(schemaConstants.UPDATE_MEMBERSHIP_EXPIRATION_DURATION_VALUE_FIELD)}
                index={index}
                prefix={`${automationIdPrefix}.durationValue`}
                error={validationErrors ? validationErrors.get(schemaConstants.UPDATE_MEMBERSHIP_EXPIRATION_DURATION_VALUE_FIELD) : null}
              />
              <span>{formatMessage({ id: 'global.time.duration.years' })}</span>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default injectIntl(UpdateMembershipExpiration);
