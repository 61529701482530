import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';
import { injectIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';
import CopyToClipboard from '@como/web-hub-components/src/CopyToClipboard/InputWithCopyToClipboard';
import CustomModal from '../../../../common/components/CustomModal/CustomModal';
import './landingPageModal.styles.less';

class LandingPageModal extends Component {
  static propTypes = {
    url: PropTypes.any.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    locationId: PropTypes.number.isRequired,
    activityName: PropTypes.string.isRequired,
  };

  componentDidMount() {
    QRCode.toCanvas(document.getElementById('qr-code-modal'), this.props.url || '', {
      width: 70,
      errorCorrectionLevel: 'H'
    }, function (error) {
      if (error) console.error(error);
    });
  }

  downloadQrCode = (el) => {
    el.preventDefault();
    const data = document.getElementById('qr-code-modal').toDataURL().replace('image/png', 'image/octet-stream;');
    const filename = `${this.props.locationId}_${this.props.activityName}_LandingPage.png`;
    const saveLink = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
    saveLink.href = data;
    saveLink.download = filename;
    const event = document.createEvent('MouseEvents');
    event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    saveLink.dispatchEvent(event);
  };

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    return (
      <CustomModal
        header={formatMessage({ id: 'landingPage.modal.header' })}
        actions={[
          <Button
            key="save"
            data-automation-id={'ok'}
            onClick={this.props.onClose}
          >
            Ok
          </Button>
        ]}
        className="activity-move-to--modal como-dialog landingPageModal"
        onClose={this.props.onClose}
        content={
          <div className={'wrapper'}>
            <div className={'content'} style={{ display: 'inline-block' }}>
              <p>{formatMessage({ id: 'landingPage.modal.copyTheLandingPageUrl' })}</p>
              <CopyToClipboard
                value={this.props.url}
                labelKey={' '}
                disabled
                propertyName={'LandingPageLink'}
                onChange={() => false}
              />
              <a
                href={this.props.url}
                target={'_blank'}
                className={'link'}
              >{formatMessage({ id: 'landingPage.modal.clickToView' })}</a>
            </div>
            <div className={'qr-code-wrapper'}>
              <canvas id={'qr-code-modal'}/>
              <a
                id="download"
                href={''}
                className={'download-link link'}
                onClick={this.downloadQrCode}
              >{formatMessage({ id: 'activity.landing.modal.downloadQrCode' })}</a>
            </div>
          </div>
        }
        open={this.props.open}
        size="tiny"
        automationIdPrefix={'landingPageModal'}
      />);
  }
}

export default injectIntl(LandingPageModal);
