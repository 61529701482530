import React, { PureComponent } from 'react';
import { Form } from 'formsy-semantic-ui-react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import * as goalsConstants from '../../../ActivityGoals/goals.constants';
import * as templateConstants from '../../../Templates/templates.constants';
import ErrorLabel from './errorLabel';

class Tool extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    rulesProductTemplates: PropTypes.object.isRequired,
    dealsProductTemplates: PropTypes.object.isRequired,
    oneTimeActionsProductTemplates: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    tool: PropTypes.object.isRequired
  };

  handleOnChangeRuleTemplates = (e, { value }) => {
    e.preventDefault();
    const { index, onChange } = this.props;
    onChange(index, goalsConstants.GOAL_TOOL_TEMPLATES_RULES_POINTERS, value);
  };
  handleOnChangeDealTemplates = (e, { value }) => {
    e.preventDefault();
    const { index, onChange } = this.props;
    onChange(index, goalsConstants.GOAL_TOOL_TEMPLATES_DEALS_POINTERS, value);
  };
  handleOnChangeOneTimeActionTemplates = (e, { value }) => {
    e.preventDefault();
    const { index, onChange } = this.props;
    onChange(index, goalsConstants.GOAL_TOOL_TEMPLATES_ONETIME_POINTERS, value);
  };
  handleOnChangeName = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const { index, onChange } = this.props;

    onChange(index, goalsConstants.GOAL_TOOL_NAME, value);
  };
  handleOnChangeDescription = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const { index, onChange } = this.props;

    onChange(index, goalsConstants.GOAL_TOOL_DESCRIPTION, value);
  };
  generateTemplateOptions(data) {
    return data.map((template, index) => ({ key: index, text: template[templateConstants.TEMPLATE_NAME], value: template[templateConstants.DB_ID] }));
  }
  render() {
    const { formatMessage } = this.props.intl;  // eslint-disable-line react/prop-types
    const { rulesProductTemplates, dealsProductTemplates, oneTimeActionsProductTemplates, tool } = this.props;

    const rulesTemplatesOptions = this.generateTemplateOptions(rulesProductTemplates.toJS());
    const dealsTemplatesOptions = this.generateTemplateOptions(dealsProductTemplates.toJS());
    const oneTimeActionsTemplatesOptions = this.generateTemplateOptions(oneTimeActionsProductTemplates.toJS());

    const dealPointers = tool.get(goalsConstants.GOAL_TOOL_TEMPLATES_DEALS_POINTERS);
    const rulePointers = tool.get(goalsConstants.GOAL_TOOL_TEMPLATES_RULES_POINTERS);
    const oneTimePointers = tool.get(goalsConstants.GOAL_TOOL_TEMPLATES_ONETIME_POINTERS);

    return (
      <Form.Field>
        <Form.Group widths="equal">
          <Form.Input
            label={formatMessage({ id: 'goals.tool.label.name' })}
            data-automation-id="goals.admin.form.tool.name"
            name="name"
            placeholder={formatMessage({ id: 'goals.tool.placeholder.name' })}
            onChange={this.handleOnChangeName}
            value={tool.get(goalsConstants.GOAL_TOOL_NAME)}
            required
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.TextArea
            name="description"
            data-automation-id="goals.admin.form.tool.description"
            label={formatMessage({ id: 'goals.tool.label.description' })}
            placeholder={formatMessage({ id: 'goals.tool.placeholder.description' })}
            onChange={this.handleOnChangeDescription}
            value={tool.get(goalsConstants.GOAL_TOOL_DESCRIPTION)}
          />
        </Form.Group>
        <Form.Group widths="equal">
          {Array.isArray(rulesTemplatesOptions) && rulesTemplatesOptions.length > 0 &&
          <Form.Dropdown
            name="ruleTemplates"
            label={formatMessage({ id: 'goals.tool.label.rulesTemplates' })}
            data-automation-id="goals.admin.form.tool.templates.rule"
            placeholder={formatMessage({ id: 'goals.tool.placeholder.templates' })}
            multiple
            search
            selection
            options={rulesTemplatesOptions}
            onChange={this.handleOnChangeRuleTemplates}
            value={rulePointers && rulePointers.toJS()}
            validationErrors={{
              isDefaultRequiredValue: formatMessage({ id: 'goals.tool.error.rulesTemplates' }),
            }}
            errorLabel={<ErrorLabel />}
          />
          }
        </Form.Group>
        <Form.Group widths="equal">
          {Array.isArray(dealsTemplatesOptions) && dealsTemplatesOptions.length > 0 &&
          <Form.Dropdown
            name="dealTemplates"
            label={formatMessage({ id: 'goals.tool.label.dealsTemplates' })}
            placeholder={formatMessage({ id: 'goals.tool.placeholder.templates' })}
            data-automation-id="goals.admin.form.tool.templates.deal"
            multiple
            search
            selection
            options={dealsTemplatesOptions}
            onChange={this.handleOnChangeDealTemplates}
            value={dealPointers && dealPointers.toJS()}
            validationErrors={{
              isDefaultRequiredValue: formatMessage({ id: 'goals.tool.error.dealsTemplates' }),
            }}
            errorLabel={<ErrorLabel />}
          />}
        </Form.Group>
        <Form.Group widths="equal">
          {Array.isArray(oneTimeActionsTemplatesOptions) && oneTimeActionsTemplatesOptions.length > 0 &&
          <Form.Dropdown
            name="oneTimeActionTemplates"
            label={formatMessage({ id: 'goals.tool.label.oneTimeActionsTemplates' })}
            placeholder={formatMessage({ id: 'goals.tool.placeholder.templates' })}
            data-automation-id="goals.admin.form.tool.templates.onetime"
            multiple
            search
            selection
            options={oneTimeActionsTemplatesOptions}
            onChange={this.handleOnChangeOneTimeActionTemplates}
            value={oneTimePointers && oneTimePointers.toJS()}
            validationErrors={{
              isDefaultRequiredValue: formatMessage({ id: 'goals.tool.error.oneTimeActionsTemplates' }),
            }}
            errorLabel={<ErrorLabel />}
          />}
        </Form.Group>
      </Form.Field>);
  }
}

export default injectIntl(Tool);
