export const GET_CONFIGURATION = 'app/agentConfiguration/GET_CONFIGURATION';
export const SET_AGENT_CONFIGURATION_DATA_IS_LOADING = 'app/agentConfiguration/SET_AGENT_CONFIGURATION_DATA_IS_LOADING';
export const GET_AGENT_CONFIGURATION_SUCCESS = 'app/agentConfiguration/GET_AGENT_CONFIGURATION_SUCCESS';
export const ON_CONFIGURATION_FIELD_CHANGE = 'app/agentConfiguration/ON_CONFIGURATION_CHANGE';
export const SAVE_CONFIGURATION = 'app/agentConfiguration/SAVE_CONFIGURATION';
export const SAVING_CONFIGURATION = 'app/agentConfiguration/SAVING_CONFIGURATION';
export const CONFIGURATION_SAVE_SUCCESS = 'app/agentConfiguration/CONFIGURATION_SAVE_SUCCESS';
export const CLOSE_SAVED_CONFIGURATION_POPUP_MESSAGE = 'app/agentConfiguration/CLOSE_SAVED_CONFIGURATION_POPUP_MESSAGE';
export const ERROR_OCCURRED = 'app/agentConfiguration/ERROR_OCCURRED';

// State path
export const AGENT_CONFIGURATION_DATA_LOADING = 'AgentConfigurationDataLoaded';
export const AGENT_CONFIGURATION_DATA = 'data';
export const AGENT_CONFIGURATION = 'agentConfiguration';
export const SAVING = 'saving';
export const SAVED_CONFIGURATION = 'savedConfiguration';
export const ERROR = 'error';


//Data
export const CONFIGURATIONS = 'configurations';
export const DEFAULT_LANGUAGE = 'defaultLanguage';
export const SHOW_EMPLOYEE_WIDGET = 'showEmployeeWidget';
export const SHOW_INACTIVE_ASSETS = 'showInActiveAssets';
export const ALLOW_NON_MEMBER = 'allowNonMembers';
export const ALLOW_POINT_SHOP = 'allowPointShop';
export const ALLOW_BENEFITS = 'allowBenefits';
export const ALLOW_PUNCH_CARDS = 'allowPunchCards';
export const ALLOW_PROMO_CODE = 'allowPromoCode';
export const ALLOW_PAY_WITH_COMO = 'allowPayWithComo';
export const ALLOW_PAY_WITH_COMO_NEGATIVE_AMOUNT = 'allowPayWithComoNegativeAmount';
export const ALLOW_PAY_WITH_COMO_VERIFICATION = 'allowPayWithComoVerification';
export const ALLOW_IDENTIFY_MEMBER_WITH_QR = 'allowIdentifyMemberWithQr';
export const SUPPORT_EXISTING_PURCHASE = 'supportExistingPurchase';
export const ALLOW_LANGUAGE_SELECTION = 'allowLanguageSelection';
