/**
 *
 * ActivitiesType
 *
 */

import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from 'semantic-ui-react';

import * as constants from '../../Activity/activity.constants';

class ActivityType extends PureComponent {

  static propTypes = {
    type: PropTypes.string.isRequired,
    isAbTestMode: PropTypes.bool
  };

  render() {
    const { type } = this.props;
    const { formatMessage } = this.props.intl; //eslint-disable-line

    const activityClass = classNames('ui circular center activity label', {
      rule: type === constants.ACTIVITY_TYPE_RULE,
      deal: type === constants.ACTIVITY_TYPE_DEAL,
      oneTime: type === constants.ACTIVITY_TYPE_ONE_TIME,
      punchCard: type === constants.ACTIVITY_TYPE_PUNCH_CARD,
      microCampaign: type === constants.ACTIVITY_TYPE_MICRO_CAMPAIGN
    });

    return (
      <div className={activityClass}>
        {formatMessage({ id: `activity.type.${type}` })}
      </div>
    );
  }
}

export default injectIntl(ActivityType);
