import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { injectIntl } from 'react-intl';
import * as activityConstants from '../../activity.constants';

import ActionsWrapper from './actionsWrapper';
import ActionsList from './actionsList';
import BenefitActionsWrapper from './BenefitActionsWrapper';
import './actions.styles.less';
import * as staticSchema from '../../activitySchema/activitySchema';

class ActivityActions extends React.PureComponent {

  static propTypes = {
    actionsWrappers: PropTypes.object,
    actionsPopupContent: PropTypes.object,
    activityTrigger: PropTypes.string,
    activityType: PropTypes.string,
    addBundleLine: PropTypes.func,
    addPointsOnChangeType: PropTypes.func,
    allowOccurrences: PropTypes.bool,
    automationIdPrefix: PropTypes.string,
    changeActivityActionMode: PropTypes.func,
    createGiftFromActivity: PropTypes.func,
    createEmailFromActivity: PropTypes.func,
    createTempApplyOnModel: PropTypes.func,
    deleteAction: PropTypes.func,
    deleteBundleLine: PropTypes.func,
    features: PropTypes.object,
    hideAdvancedDealActions: PropTypes.bool,
    intl: PropTypes.object,
    isSingleBundleLine: PropTypes.bool,
    mentionsList: PropTypes.object,
    onBundleLineFieldUpdate: PropTypes.func,
    onUpdateBundle: PropTypes.func,
    saveApplyOnData: PropTypes.func,
    uniqueId: PropTypes.string.isRequired,
    updateActionField: PropTypes.func,
    updateActionType: PropTypes.func,
    validationErrors: PropTypes.object,
    deleteActionsWrapper: PropTypes.func,
    submitActionToCustomer: PropTypes.func,
    hasFullPermission: PropTypes.func,
    getBusinessBundlePlan: PropTypes.func.isRequired,
    caseIndex: PropTypes.number,
    isOneTimeActivityDisabled: PropTypes.bool
  };

  render() {

    const { actionsWrappers, actionsPopupContent, activityTrigger, activityType, addBundleLine, addPointsOnChangeType, allowOccurrences, changeActivityActionMode, createTempApplyOnModel, deleteAction, deleteBundleLine, features, hideAdvancedDealActions, intl, isSingleBundleLine, mentionsList, onBundleLineFieldUpdate, onUpdateBundle, saveApplyOnData, uniqueId, updateActionField, updateActionType, validationErrors, } = this.props;
    const { formatMessage } = intl;

    const automationIdPrefix = `${this.props.automationIdPrefix}.actions`;

    return staticSchema.redeemableActivities.includes(activityType) && !staticSchema.redeemableRuleAndItsTriggers.includes(activityType)
    ?
      actionsWrappers.map((actionsWrapper, index) =>
         (
           <BenefitActionsWrapper
             actionsList={actionsWrapper.get(activityConstants.ACTIONS_LIST) || List()}
             actionsMode={actionsWrapper.get(activityConstants.ACTIONS_MODE)}
             actionsPopupContent={actionsPopupContent}
             activityType={this.props.activityType}
             addBundleLine={addBundleLine}
             automationIdPrefix={automationIdPrefix}
             bundle={actionsWrapper.get(activityConstants.BUNDLE)}
             changeActivityActionMode={changeActivityActionMode}
             createTempApplyOnModel={createTempApplyOnModel}
             deleteAction={deleteAction}
             deleteBundleLine={deleteBundleLine}
             hideAdvancedDealActions={hideAdvancedDealActions}
             onBundleLineFieldUpdate={onBundleLineFieldUpdate}
             onUpdateBundle={onUpdateBundle}
             saveApplyOnData={saveApplyOnData}
             uniqueId={uniqueId}
             updateActionField={updateActionField}
             updateActionType={updateActionType}
             validationErrors={validationErrors ? validationErrors.get(index) : null}
             actionsWrapperIndex={index}
             key={`actionsWrapper_${index.toString()}`}
             activityTrigger={activityTrigger}
             actionsWrappersSize={actionsWrappers.size}
             deleteActionsWrapper={this.props.deleteActionsWrapper}
             caseIndex={this.props.caseIndex}
             isOneTimeActivityDisabled={this.props.isOneTimeActivityDisabled}
           />
      ))
      : (allowOccurrences
      ? (
        <ActionsWrapper
          actionPlaceholder={formatMessage({ id: `${activityType}.actions.dropdown.default.first` })}
          actionsList={actionsWrappers.getIn([0, activityConstants.ACTIONS_LIST]) ? actionsWrappers.getIn([0, activityConstants.ACTIONS_LIST]) : List()}
          actionsMode={actionsWrappers.getIn([0, activityConstants.ACTIONS_MODE])}
          actionsPopupContent={actionsPopupContent}
          activityTrigger={activityTrigger}
          activityType={this.props.activityType}
          addBundleLine={addBundleLine}
          addPointsOnChangeType={addPointsOnChangeType}
          automationIdPrefix={automationIdPrefix}
          bundle={actionsWrappers.getIn([0, activityConstants.BUNDLE])}
          changeActivityActionMode={changeActivityActionMode}
          createGiftFromActivity={this.props.createGiftFromActivity}
          createEmailFromActivity={this.props.createEmailFromActivity}
          deleteAction={deleteAction}
          deleteBundleLine={deleteBundleLine}
          features={features}
          isSingleBundleLine={isSingleBundleLine}
          mentionsList={mentionsList}
          onBundleLineFieldUpdate={onBundleLineFieldUpdate}
          uniqueId={uniqueId}
          updateActionField={updateActionField}
          updateActionType={updateActionType}
          validationErrors={validationErrors ? validationErrors.get(0) : null}
          actionsWrapperIndex={0}
          submitActionToCustomer={this.props.submitActionToCustomer}
          caseIndex={this.props.caseIndex}
        />
      )
      : (
        <ActionsList
          actionPlaceholder={formatMessage({ id: `${activityType}.actions.dropdown.default.first` })}
          actionsList={actionsWrappers.getIn([0, activityConstants.ACTIONS_LIST]) ? actionsWrappers.getIn([0, activityConstants.ACTIONS_LIST]) : List()}
          actionsMode={actionsWrappers.getIn([0, activityConstants.ACTIONS_MODE])}
          actionsPopupContent={actionsPopupContent}
          activityTrigger={activityTrigger}
          activityType={this.props.activityType}
          addPointsOnChangeType={addPointsOnChangeType}
          automationIdPrefix={automationIdPrefix}
          createGiftFromActivity={this.props.createGiftFromActivity}
          createEmailFromActivity={this.props.createEmailFromActivity}
          deleteAction={deleteAction}
          mentionsList={mentionsList}
          updateActionField={updateActionField}
          updateActionType={updateActionType}
          validationErrors={validationErrors && validationErrors.get(0) ? validationErrors.getIn([0, activityConstants.ACTIONS_LIST]) : null}
          actionsWrapperIndex={0}
          submitActionToCustomer={this.props.submitActionToCustomer}
          caseIndex={this.props.caseIndex}
          isOneTimeActivityDisabled={this.props.isOneTimeActivityDisabled}
          getBusinessBundlePlan={this.props.getBusinessBundlePlan}
          hasFullPermission={this.props.hasFullPermission}
        />
      ));
  }
}

export default injectIntl(ActivityActions);
