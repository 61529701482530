// ACTIONS CONSTANTS

export const UPDATE_EMAIL_FIELD = 'app/Emails/UPDATE_EMAIL_FIELD';
export const SAVE_EMAIL = 'app/Emails/SAVE_EMAIL';
export const SAVE_EMAIL_SUCCESS = 'app/Emails/SAVE_EMAIL_SUCCESS';
export const SAVE_EMAIL_ERROR = 'app/Emails/SAVE_EMAIL_ERROR';
export const CREATE_EMAIL = 'app/Emails/CREATE_EMAIL';
export const GET_EMAIL = 'app/Emails/GET_EMAIL';
export const GET_EMAIL_SUCCESS = 'app/Emails/GET_EMAIL_SUCCESS';
export const GET_EMAIL_ERROR = 'app/Emails/GET_EMAIL_ERROR';
export const CLEAR_EMAIL_DATA = 'app/Emails/CLEAR_EMAIL_DATA';
export const GET_BUSINESS_EMAIL_EDITOR_CREDENTIALS = 'app/Emails/GET_BUSINESS_EMAIL_EDITOR_CREDENTIALS';
export const INITIALIZE_EMAIL_EDITOR_SUCCESS = 'app/Emails/INITIALIZE_EMAIL_EDITOR_SUCCESS';
export const INITIALIZE_EMAIL_EDITOR_ERROR = 'app/Emails/INITIALIZE_EMAIL_EDITOR_ERROR';
export const UPDATE_EMAIL_METADATA = 'app/Emails/UPDATE_EMAIL_METADATA';
export const SET_EMAIL_PUBLISH_IN_PROGRESS = 'app/Emails/SET_EMAIL_PUBLISH_IN_PROGRESS';
export const SET_EMAIL_ERRORS = 'app/Emails/SET_EMAIL_ERRORS';
export const CHECK_EMAIL_NAME_AVAILABILITY_SUCCESS = 'app/Emails/CHECK_EMAIL_NAME_AVAILABILITY_SUCCESS';
export const CHECK_EMAIL_NAME_AVAILABILITY = 'app/Emails/CHECK_EMAIL_NAME_AVAILABILITY';
export const CHECK_EMAIL_NAME_AVAILABILITY_DONE = 'app/Emails/CHECK_EMAIL_NAME_AVAILABILITY_DONE';
export const GO_TO_EMAILS = 'app/Emails/GO_TO_EMAILS';
export const SET_EMAIL_DATA_LOADING = 'app/Emails/SET_EMAIL_DATA_LOADING';
export const MARK_AS_DIRTY = 'app/Emails/MARK_AS_DIRTY';

// STORE CONSTANTS
export const EMAIL = 'email';
export const EMAIL_DATA = 'data';
export const EMAIL_EDITOR_TOKEN = 'token';
export const EMAIL_EDITOR_UID = 'uid';
export const EMAIL_VALIDATION_ERRORS = 'validationErrors';
export const EMAIL_HEADER = 'emailHeader';
export const EMAIL_NAME = 'name';
export const EMAIL_DESCRIPTION = 'description';
export const EMAIL_CONTENT_JSON = 'jsonContent';
export const EMAIL_CONTENT_HTML = 'htmlContent';
export const SUBJECT = 'subject';
export const EMAIL_OPEN_TRACKING = 'openTracking';
export const EMAIL_CLICK_TRACKING = 'clickTracking';
export const EMAIL_2FA_TEMPLATE = '2faTemplate';
export const EMAIL_CONSENT_TEMPLATE = 'consentTemplate';
export const CHECKING_EMAIL_NAME_AVAILABILITY = 'checkingEmailNameAvailability';
export const IS_DIRTY = 'isDirty';
export const PUBLISH_IN_PROGRESS = 'publishInProgress';
export const EMAIL_VALIDATION_NAME_TAKEN_FLAG = 'emailValidationNameTaken';
export const EMAIL_DATA_LOADED = 'emailDataLoaded';
export const CREATED = 'created';
export const UPDATED = 'updated';
export const HISTORY_LOG_FIRST_NAME = 'firstName';
export const HISTORY_LOG_LAST_NAME = 'lastName';
export const HISTORY_LOG_DATE = 'date';
export const FROM_HEADER = 'emailFromHeader';
export const TEMPLATE_KEY = 'templateKey';
export const TEMPLATE = 'template';


