import 'moment-timezone';
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import InputWrapper from '../../../../common/components/FormInputFields/InputWrapper/InputWrapper';
import * as activityConstants from '../../activity.constants';
import ValidationErrorMessage from '../../../../common/components/ValidationErrorMessage/ValidationErrorMessage';
import * as commonConstants from '../../../../common/components/common.components.constants';

class PromoCodeSingleCodeBulk extends React.PureComponent {

  static propTypes = {
    bulk: PropTypes.object,
    onUpdate: PropTypes.func,
    errorMessage: PropTypes.string,
    intl: PropTypes.object,
    automationIdPrefix: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    onModeCLick: PropTypes.func,
    disabled: PropTypes.bool
  };

  onChange = (e, { value }) => {
    this.props.onUpdate(value);
  };

  componentDidMount() {
    if (!this.props.bulk.get(activityConstants.PROMO_CODE_SINGLE_CODE_BULK_VALUE)) {
      this.props.initializeSingleCode();
    }
  }

  handleKeyDown= (event) => {
    const keyCode = event.keyCode;

    // Allow backspace
    if(keyCode === 8) {
      return true;
    }

    // regular expression pattern to match only numbers or alphabets
    const pattern = /^[0-9a-zA-Z]*$/;
    if (!pattern.test(event.key)) {
      event.preventDefault();
    }
  }

  onModeClick = () => {
    if (!this.props.disabled) {
      this.props.onModeCLick();
    }
  };

  render() {
    const value = this.props.bulk.get(activityConstants.PROMO_CODE_SINGLE_CODE_BULK_VALUE);
    const disabled = !this.props.bulk.get(activityConstants.PROMO_CODE_BULK_IS_NEW);

    return (
      <div className="promoCode-fixed-code-row">
        <span className="line">
          <span className="inside-sentence tw-flex tw-flex-col">
            <div>
              <span className="head-of-sentence">
                {this.props.intl.formatMessage({id: 'promo.code.bulk.single.fixed.code.label'})}
              </span>
              <InputWrapper
                value={value || ''}
                onKeyDown={this.handleKeyDown}
                onChange={this.onChange}
                className="single-code-input"
                loading={value === null}
                disabled={disabled}
                resetValueOnOverflow={false}
                prefix={`${this.props.automationIdPrefix}singleCode.value`}
                onBlur={(e) => e.target.value ? this.props.checkCodeIsAvailable(e.target.value) : false}
              />
            </div>
            <div className="hint-tip-label">
              {this.props.intl.formatMessage({id: 'promoCode.displayTab.allowed.characters'})}
            </div>
          </span>
        </span>

        {this.props.errorMessage && <ValidationErrorMessage
          errorLayoutType={commonConstants.COMPONENT_TYPE_PARAGRAPH}
          errorMessage={this.props.intl.formatMessage({id: this.props.errorMessage})}
          dataAutomationId={`${this.props.automationIdPrefix}singleCode.error.message`}
        />}
      </div>
    );
  }
}


export default injectIntl(PromoCodeSingleCodeBulk);
