/**
*
* UpdateMembershipPopupActions
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';

class UpdateMembershipPopupActions extends React.Component {

  static propTypes = {
    onApply: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    prefix: PropTypes.string.isRequired,
  };

  render() {

    const { onApply, onCancel, intl, prefix } = this.props;
    const { formatMessage } = intl;

    return (
      <div className="popupFooter">
        <a
          role="link"
          tabIndex={0}
          onClick={onCancel}
          data-automation-id={`${prefix}.cancel`}
        >
          {formatMessage({ id: 'general.button.cancel' })}
        </a>
        <Button
          tabIndex={0}
          onClick={onApply}
          data-automation-id={`${prefix}.apply`}
        >
          {formatMessage({ id: 'general.button.done' })}
        </Button>
      </div>
    );
  }
}

export default injectIntl(UpdateMembershipPopupActions);

