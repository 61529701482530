/**
 *
 * Toast
 *
 */
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import './Toast.styles.less';

class Toast extends React.PureComponent {

  static propTypes = {
    className: PropTypes.string,
    isOpen: PropTypes.bool,
    messageKey: PropTypes.string,
    onClose: PropTypes.func,
    secondsToShow: PropTypes.number
  };

  static defaultProps = {
    secondsToShow: 5
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen && !this.props.isOpen) {
      setTimeout(() => this.props.onClose(), this.props.secondsToShow * 1000);
    }
  }

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    return (
      <div>
        {this.props.isOpen ?
          <div className={`toast-container ${this.props.className}`}>
            <span className="toast-notification">
              {formatMessage({ id: this.props.messageKey })}
            </span>
            <Icon
              className="como-ic-close close-toast"
              onClick={this.props.onClose}
            />
          </div>
      : null}
      </div>
    );
  }
}

export default injectIntl(Toast);
