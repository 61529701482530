import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import * as activityConstants from '../activity.constants';
import Tooltip from '../../../common/components/Tooltip/Tooltip';
import Trigger from './triggers/trigger';
import ConditionsContainer from './conditions/ConditionsContainer';
import ActionsContainer from './ActionsContainer';
import TriggerSchedule from './TriggerSchedule';
import * as schemaConstants from '../activitySchema/activitySchema.constants';

class ActivityBody extends React.PureComponent {

  static propTypes = {
    actions: PropTypes.object.isRequired,
    activityActionsWrappers: PropTypes.object,
    activityConditions: PropTypes.object,
    activityDateTimeGlobalConditions: PropTypes.object,
    activityTrigger: PropTypes.string,
    activityType: PropTypes.string.isRequired,
    allowOccurrences: PropTypes.bool,
    cases: PropTypes.object,
    casesActions: PropTypes.object,
    casesMode: PropTypes.bool,
    clearTempTrigger: PropTypes.func,
    getBusinessBundlePlan: PropTypes.func.isRequired,
    disabledGlobalConditions: PropTypes.object,
    features: PropTypes.object,
    filteredPopulation: PropTypes.object,
    filters: PropTypes.array,
    headerMsgId: PropTypes.string,
    hideAdvancedDealActions: PropTypes.bool,
    isOneTimeActionFilteredExternally: PropTypes.func,
    mentionsList: PropTypes.object,
    onSelectTrigger: PropTypes.func,
    pendingTrigger: PropTypes.object,
    schemaActionsPerTrigger: PropTypes.object,
    schemaConditionsPerTrigger: PropTypes.object,
    schemaDateTimeConditions: PropTypes.object,
    schemaMembershipConditions: PropTypes.object,
    triggerGroups: PropTypes.object,
    updateTriggerPreValidation: PropTypes.func,
    validationErrors: PropTypes.object,
    deleteActionsWrapper: PropTypes.func,
    hasFullPermission: PropTypes.func,
    activityViewStatus: PropTypes.string,
    abTestMode: PropTypes.bool,
    showSplit: PropTypes.bool,
    isABTestingAllowed: PropTypes.bool,
    showBasicPlan: PropTypes.bool,
  };

  get showTriggerSchedule() {
    return [schemaConstants.TRIGGER_MEMBER_HAS_ANNIVERSARY, schemaConstants.TRIGGER_MEMBER_HAS_BIRTHDAY, schemaConstants.TRIGGER_CLUB_MEMBER_ANNIVERSARY]
      .includes(this.props.activityTrigger);
  }

  get showConditions() {
    switch (this.props.activityType) {
      case activityConstants.ACTIVITY_TYPE_GIFT:
      case activityConstants.ACTIVITY_TYPE_PUNCH_CARD:
      case activityConstants.ACTIVITY_TYPE_PROMO_CODE:
        return false;
      case activityConstants.ACTIVITY_TYPE_ONE_TIME:
        return !this.props.isOneTimeActionFilteredExternally();
      default:
        return true;
    }
  }

  get showPromoCodeSection() {
    return this.props.activityType === activityConstants.ACTIVITY_TYPE_PROMO_CODE;
  }

  render() {

    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const { actions, validationErrors, activityType, activityTrigger } = this.props;
    const upperConditionsList = this.props.schemaConditionsPerTrigger ? this.props.schemaConditionsPerTrigger.filter((condition) => !condition.get(schemaConstants.CASES_ONLY_CONDITION)) : null;
    const adjustedByComillia = this.props.filteredPopulation ? this.props.filteredPopulation.get(activityConstants.FILTERED_POPULATION_ADJUSTED_BY_COMILLIA_HINT) : false;

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Grid>
              {![activityConstants.ACTIVITY_TYPE_PUNCH_CARD, activityConstants.ACTIVITY_TYPE_GIFT, activityConstants.ACTIVITY_TYPE_PROMO_CODE].includes(activityType) &&
              <Grid.Row>
                <Grid.Column>
                  <Header>{formatMessage({ id: this.props.headerMsgId })}</Header>
                </Grid.Column>
              </Grid.Row>
              }
            </Grid>
            {activityType === activityConstants.ACTIVITY_TYPE_RULE
                ?
                  <Trigger
                    clearTempTrigger={this.props.clearTempTrigger}
                    pendingTrigger={this.props.pendingTrigger}
                    selectedTrigger={activityTrigger}
                    selectTrigger={this.props.onSelectTrigger}
                    triggersList={this.props.triggerGroups}
                    updateTriggerPreValidation={this.props.updateTriggerPreValidation}
                    validationError={validationErrors ? validationErrors.get(activityConstants.TRIGGER) : null}
                  />
                :
                activityType === activityConstants.ACTIVITY_TYPE_ONE_TIME && this.props.isOneTimeActionFilteredExternally() &&
                <Grid>
                  <Grid.Row className="filtered-population" data-automation-id="filteredPopulation">
                    {(this.props.filters && this.props.filters.length > 0) || adjustedByComillia ?
                      (<Tooltip
                        wide
                        content={this.props.filters && this.props.filters.length > 0 ? this.props.filters.map((filter, index) => <p key={index.toString()}>{filter}</p>) : <p>{formatMessage({ id: 'adjusted.by.comillia.hint' })}</p>}
                        trigger={<span>{`${formatMessage({ id: 'onetime.filter.members.label' }, { numOfMembers: this.props.filteredPopulation.get(activityConstants.FILTERED_NUM_OF_MEMBERS) })}`}</span>}
                        position="bottom left"
                      />) :
                      (<span>{`${formatMessage({ id: 'onetime.filter.members.label' }, { numOfMembers: this.props.filteredPopulation.get(activityConstants.FILTERED_NUM_OF_MEMBERS) })}`}</span>)}
                  </Grid.Row>
                </Grid>
              }
            {this.showConditions
                ?
                  <ConditionsContainer
                    activityConditions={this.props.activityConditions}
                    conditionsList={upperConditionsList}
                    label
                    mainConditions
                    trigger={activityTrigger}
                    addCondition={actions.addCondition}
                    deleteCondition={actions.deleteCondition}
                    updateConditionField={actions.updateConditionField}
                    schemaMembershipConditions={this.props.schemaMembershipConditions}
                    dateTimeGlobalConditions={this.props.activityDateTimeGlobalConditions}
                    disabledGlobalConditions={this.props.disabledGlobalConditions}
                    conditionsValidationErrors={validationErrors ? validationErrors.get(activityConstants.CONDITIONS) : null}
                    globalConditionsvalidationErrors={validationErrors ? validationErrors.get(activityConstants.GLOBAL_CONDITIONS) : null}
                    activityType={activityType}
                    className={(this.showTriggerSchedule || this.showPromoCodeSection) ? 'continuous' : ''}
                    abTestMode={this.props.abTestMode}
                    isABTestingAllowed={this.props.isABTestingAllowed}
                    getBusinessBundlePlan={this.props.getBusinessBundlePlan}
                  />
                :
                    <div className="no-conditions-section" />
              }
            {this.showTriggerSchedule && <TriggerSchedule />}
            <ActionsContainer
              actionsWrappers={this.props.activityActionsWrappers}
              actionsPopupContent={this.props.schemaActionsPerTrigger}
              activityTrigger={activityTrigger}
              activityType={activityType}
              addBundleLine={actions.addBundleLine}
              addPointsOnChangeType={actions.addPointsOnChangeType}
              allowOccurrences={this.props.allowOccurrences}
              cases={this.props.cases}
              casesActions={this.props.casesActions}
              casesMode={this.props.casesMode}
              changeActivityActionMode={actions.changeActivityActionMode}
              conditionsPerTrigger={this.props.schemaConditionsPerTrigger}
              createGiftFromActivity={actions.createGiftFromActivity}
              createEmailFromActivity={actions.createEmailFromActivity}
              createTempApplyOnModel={actions.createTempApplyOnModel}
              dateTimeConditions={this.props.schemaDateTimeConditions}
              deleteAction={actions.deleteAction}
              deleteBundleLine={actions.deleteBundleLine}
              features={this.props.features}
              hideAdvancedDealActions={this.props.hideAdvancedDealActions}
              membershipConditions={this.props.schemaMembershipConditions}
              mentionsList={this.props.mentionsList}
              onBundleLineFieldUpdate={actions.onBundleLineFieldUpdate}
              onUpdateBundle={actions.onUpdateBundle}
              saveApplyOnData={actions.saveApplyOnData}
              trigger={activityTrigger}
              updateActionField={actions.updateActionField}
              updateActionType={actions.updateActionType}
              validationErrors={validationErrors ? validationErrors.get(activityConstants.ACTIVITY_ACTIONS) : null}
              deleteActionsWrapper={this.props.actions.deleteActionsWrapper}
              submitActionToCustomer={this.props.actions.submitActionToCustomer}
              abTestMode={this.props.abTestMode}
              isABTestingAllowed={this.props.isABTestingAllowed}
              activityViewStatus={this.props.activityViewStatus}
              getBusinessBundlePlan={this.props.getBusinessBundlePlan}
              showSplit={this.props.showSplit}
              showBasicPlan={this.props.showBasicPlan}
              hasFullPermission={this.props.hasFullPermission}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default injectIntl(ActivityBody);

