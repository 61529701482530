/* eslint-disable no-param-reassign */
import { fromJS, OrderedMap } from 'immutable';
import * as constants from './businessCenter.constants';
import * as activityConstants from '../Activity/activity.constants';
import * as activitySchemaConstants from '../Activity/activitySchema/activitySchema.constants';
import * as utils from './businessCenter.utils';

const disableFiltersMap = new Map([
  [activityConstants.TRIGGER, false],
  [activityConstants.ACTION, false]
]);

const filterGroupingMap = new Map([
  [`${[activitySchemaConstants.TOP_UP_POINTS_ACTION]}`, `${[activitySchemaConstants.ADD_POINTS_ACTION]}`],
  [`${[activitySchemaConstants.ACCUMULATE_POINTS_ACTION]}`, `${[activitySchemaConstants.ADD_POINTS_ACTION]}`],
  [`${[activitySchemaConstants.DYNAMIC_TAG_MEMBERSHIP_ACTION]}`, `${[activitySchemaConstants.TAG_MEMBERSHIP_ACTION]}`],
  [`${[activitySchemaConstants.DYNAMIC_UN_TAG_MEMBERSHIP_ACTION]}`, `${[activitySchemaConstants.UN_TAG_MEMBERSHIP_ACTION]}`]
]);

let initialState = fromJS({
  [constants.SORT]: {
    [constants.ORDER]: constants.ORDER_DESCENT
  },
  [constants.CAMPAIGNS]: [],
  [constants.FILTER_GROUPING_MAP]: filterGroupingMap,
  [constants.FILTERS_TO_DISABLE_MAP]: disableFiltersMap,
  [constants.IS_RULE_FILTER_ENABLED]: false,
  [constants.IS_ACTION_FILTER_ENABLED]: false,
  [constants.IS_ACTION_FILTER_UPDATED]: false,
  [constants.IS_VIEW_ACTION_FILTER_DISABLED]: false,
  [constants.TEMP_FILTER_MAP]: OrderedMap(),
  [constants.TEMP_ACTION_FILTER_MAP]: OrderedMap(),
  [constants.IS_AUTO_ARCHIVED_FILTER_ENABLED]: false,
  [constants.IS_ARCHIVE_CAMPAIGN_FILTER_ENABLED]: false,
  [constants.CAMPAIGNS_LOADED]: false,
  [constants.FETCHED_ACTIVITIES_WITH_ARCHIVE]: false
});

const activityByTypeFilters = OrderedMap([
  [`${[activityConstants.FILTERS_ACTIVITY_RULE]}`, true],
  [`${[activityConstants.FILTERS_ACTIVITY_DEAL]}`, true],
  [`${[activityConstants.FILTERS_ACTIVITY_ONE_TIME]}`, true],
  [`${[activityConstants.FILTERS_ACTIVITY_PUNCH_CARD]}`, true]
]);

const activityByStatusFilters = OrderedMap([
  [`${[activityConstants.FILTERS_ACTIVITY_ACTIVE]}`, true],
  [`${[activityConstants.FILTERS_ACTIVITY_COMPLETED]}`, true],
  [`${[activityConstants.FILTERS_ACTIVITY_STOPPED]}`, true],
  [`${[activityConstants.FILTERS_ACTIVITY_FUTURE]}`, true],
  [`${[activityConstants.FILTERS_ACTIVITY_DRAFT]}`, true]
]);

const activityExtraFilters = OrderedMap([
  [`${[activityConstants.FILTERS_ACTIVITY_PINNED]}`, false],
]);

const campaignsFilter = OrderedMap([
  [`${[activityConstants.FILTERS_ACTIVITY_AUTO_ARCHIVED]}`, false],
  [`${[constants.FILTERS_CAMPAIGN_ARCHIVED]}`, false]
]);

const searchByActivityFilter = OrderedMap([
  [`${[activityConstants.FILTERS_ACTIVITY_SEARCH_BY_TEXT]}`, '']
]);

//  Trigger filters
const triggerTypeByPurchase = OrderedMap([
  [`${[activitySchemaConstants.TRIGGER_PURCHASE_ANALYZED]}`, true],
  [`${[activitySchemaConstants.TRIGGER_TOP_UP]}`, true],
  [`${[activitySchemaConstants.TRIGGER_PAYS_WITH_APP]}`, true],
  [`${[activitySchemaConstants.TRIGGER_PAYS_WITH_APP_CREDIT_CARD]}`, true],
  [`${[activitySchemaConstants.TRIGGER_PAYS_WITH_PAYPAL]}`, true],
  [`${[activitySchemaConstants.TRIGGER_APP_CREDIT_CARD_PAYMENT_IS_DECLINED]}`, true],
  [`${[activitySchemaConstants.TRIGGER_PAYS_WITH_POINTS_AT_POS]}`, true],
  [`${[activitySchemaConstants.TRIGGER_START_PURCHASE]}`, true],
  [`${[activitySchemaConstants.TRIGGER_PAYS_WITH_CREDIT_AT_POS]}`, true]
]);

const resetAllFilters = OrderedMap([
  [`${[activitySchemaConstants.RESET_ALL_FILTERS]}`, true]
]);

const noShowFilters = OrderedMap([
  [`${[activitySchemaConstants.FILTERS_NO_FILTERS_TO_SHOW]}`, true]
]);

const triggerTypeByBenefits = OrderedMap([
  [`${[activitySchemaConstants.TRIGGER_RECEIVED_ASSET]}`, true],
  [`${[activitySchemaConstants.TRIGGER_REDEEM]}`, true],
  [`${[activitySchemaConstants.TRIGGER_PURCHASED_ASSET]}`, true],
  [`${[activitySchemaConstants.TRIGGER_PUNCH]}`, true],
  [`${[activitySchemaConstants.TRIGGER_EXCEEDED_PUNCH]}`, true],
  [`${[activitySchemaConstants.TRIGGER_RECEIVED_POINTS]}`, true],
  [`${[activitySchemaConstants.TRIGGER_USED_POINTS]}`, true],
  [`${[activitySchemaConstants.TRIGGER_RECEIVED_CREDIT]}`, true],
  [`${[activitySchemaConstants.TRIGGER_USED_CREDIT]}`, true],
  [`${[activitySchemaConstants.TRIGGER_USER_CLAIMED_SHARED_BUDGET]}`, true],
  [`${[activitySchemaConstants.TRIGGER_NOTIFY_USER_SHARED_BUDGET_DONE]}`, true]
]);

const triggerTypeByFriendReferral = OrderedMap([
  [`${[activitySchemaConstants.TRIGGER_JOINED_CLUB_WITH_REFERRAL_CODE]}`, true],
  [`${[activitySchemaConstants.TRIGGER_REFERRED_FRIEND_PURCHASE]}`, true],
  [`${[activitySchemaConstants.TRIGGER_USED_MY_REFERRAL_CODE]}`, true]
]);

const triggerTypeByApp = OrderedMap([
  [`${[activitySchemaConstants.TRIGGER_OPENED_CLUB]}`, true],
  [`${[activitySchemaConstants.TRIGGER_JOINED_APP]}`, true],
  [`${[activitySchemaConstants.TRIGGER_FORM_FILLED]}`, true],
  [`${[activitySchemaConstants.TRIGGER_CLAIM]}`, true],
  [`${[activitySchemaConstants.TRIGGER_ONE_TIME_CODE]}`, true],
  [`${[activitySchemaConstants.TRIGGER_SHARE_BUDGET]}`, true],
]);

const triggerTypeByMembership = OrderedMap([
  [`${[activitySchemaConstants.TRIGGER_JOINED_CLUB]}`, true],
  [`${[activitySchemaConstants.TRIGGER_UPDATE_MEMBERSHIP]}`, true],
  [`${[activitySchemaConstants.TRIGGER_MEMBER_HAS_BIRTHDAY]}`, true],
  [`${[activitySchemaConstants.TRIGGER_MEMBER_HAS_ANNIVERSARY]}`, true],
  [`${[activitySchemaConstants.TRIGGER_CLUB_MEMBER_ANNIVERSARY]}`, true],
  [`${[activitySchemaConstants.TRIGGER_TAG_OPERATION]}`, true],
  [`${[activitySchemaConstants.TRIGGER_UNTAG_OPERATION]}`, true],
  [`${[activitySchemaConstants.TRIGGER_CONSENTED_TO_TERMS_AND_CONDITIONS]}`, true]
]);

const triggerTypeByConnectivity = OrderedMap([
  [`${[activitySchemaConstants.TRIGGER_BEACON_SIGNAL]}`, true],
  [`${[activitySchemaConstants.TRIGGER_EXTERNAL_EVENT]}`, true],
  [`${[activitySchemaConstants.TRIGGER_SMS_UNSUBSCRIBE]}`, true],
  [`${[activitySchemaConstants.TRIGGER_EMAIL_UNSUBSCRIBE]}`, true]
]);

const communicationActions = OrderedMap([
  [`${[activitySchemaConstants.SEND_PERSONAL_PUSH_ACTION]}`, true],
  [`${[activitySchemaConstants.SEND_MESSAGE_ACTION]}`, true],
  [`${[activitySchemaConstants.SEND_MEMBER_SMS_ACTION]}`, true],
  [`${[activitySchemaConstants.SEND_MEMBER_EMAIL_ACTION]}`, true],
  [`${[activitySchemaConstants.WALLET_PASS_PUSH_NOTIFICATION_ACTION]}`, true],
  [`${[activitySchemaConstants.WALLET_PASS_SYNC_ACTION]}`, true],
  [`${[activitySchemaConstants.UNSUBSCRIBE_EMAIL_OR_SMS]}`, true],
  [`${[activitySchemaConstants.SEND_TC_CONSENT_SMS_OR_EMAIL_ACTION]}`, true]
]);

const membershipActions = OrderedMap([
  [`${[activitySchemaConstants.UPDATE_MEMBERSHIP_EXPIRATION_ACTION]}`, true],
  [`${[activitySchemaConstants.TAG_MEMBERSHIP_ACTION]}`, true],
  [`${[activitySchemaConstants.UN_TAG_MEMBERSHIP_ACTION]}`, true],
  [`${[activitySchemaConstants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_ACTION]}`, true],
  [`${[activitySchemaConstants.DYNAMIC_UN_TAG_MEMBERSHIP_ACTION]}`, true],
  [`${[activitySchemaConstants.DYNAMIC_TAG_MEMBERSHIP_ACTION]}`, true]
]);

const beneiftActions = OrderedMap([
  [`${[activitySchemaConstants.TOP_UP_POINTS_ACTION]}`, true],
  [`${[activitySchemaConstants.DEDUCT_POINTS_ACTION]}`, true],
  [`${[activitySchemaConstants.ASSIGN_ASSET_ACTION]}`, true],
  [`${[activitySchemaConstants.REMOVE_ASSET_ACTION]}`, true],
  [`${[activitySchemaConstants.AUTO_PUNCH_ACTION]}`, true],
  [`${[activitySchemaConstants.ADD_POINTS_ACTION]}`, true],
  [`${[activitySchemaConstants.ACCUMULATE_POINTS_ACTION]}`, true],
]);

const exportEventActions = OrderedMap([
  [`${[activitySchemaConstants.EXPORT_EVENT_ACTION]}`, true]
]);

const allActions = communicationActions.merge(membershipActions).merge(beneiftActions)
  .merge(exportEventActions);

const allTriggers = triggerTypeByPurchase.merge(triggerTypeByBenefits).merge(triggerTypeByFriendReferral)
  .merge(triggerTypeByApp).merge(triggerTypeByMembership).merge(triggerTypeByConnectivity);

const disableFiltersActivityMapper = new Map([
  [activityConstants.FILTERS_ACTIVITY_RULE, activityConstants.TRIGGER],
  [activityConstants.FILTERS_ACTIVITY_ONE_TIME, activityConstants.ACTION]
]);

initialState = initialState
  .setIn([constants.FILTERS, constants.FILTERS_GROUP_NAME_ACTIVITY_BY_TYPE], activityByTypeFilters)
  .setIn([constants.FILTERS, constants.FILTERS_GROUP_NAME_ACTIVITY_BY_STATUS], activityByStatusFilters)
  .setIn([constants.FILTERS, constants.FILTERS_GROUP_NAME_ACTIVITY_EXTRA], activityExtraFilters)
  .setIn([constants.FILTERS, constants.FILTERS_GROUP_NAME_CAMPAIGN], campaignsFilter)
  .setIn([constants.FILTERS, activityConstants.FILTER_BY_ACTIVITY], searchByActivityFilter)
  .setIn([constants.TRIGGERS, constants.FILTERS_RESET_ALL_FILTERS], resetAllFilters)
  .setIn([constants.TRIGGERS, constants.FILTERS_GROUP_NAME_ALL_TRIGGER], allTriggers)
  .setIn([constants.ACTIONS, constants.FILTERS_RESET_ALL_FILTERS], resetAllFilters)
  .setIn([constants.ACTIONS, constants.FILTERS_GROUP_NAME_ALL_ACTION], allActions)
  .setIn([constants.ALL_BUSINESS_CENTER_ACTIVITY_TAGS], [])
  .setIn([constants.ACTIVITY_TAGS_FILTER], []);


function businessCenterReducer(state = initialState, action) {
  switch (action.type) {

    case constants.GET_CAMPAIGNS_SUCCESS: {
      return state
        .set(constants.CAMPAIGNS, fromJS(action.data))
        .set(constants.ALL_BUSINESS_CENTER_ACTIVITY_TAGS, utils.getCampaignsActivityTags(action.data))
        .set(constants.CAMPAIGNS_LOADED, true);
    }

    case constants.SET_CAMPAIGNS_LOADED: {
      return state
        .set(constants.CAMPAIGNS_LOADED, fromJS(action.isLoaded));
    }

    case constants.CREATE_CAMPAIGN_SUCCESS: {
      return state
        .set(constants.CAMPAIGNS, state.get(constants.CAMPAIGNS).push(fromJS(action.data)))
        .set(constants.FOCUSED_CAMPAIGN_ID, action.data.id)
        .set(constants.CAMPAIGNS_LOADED, true);
    }

    case constants.FOCUS_CAMPAIGN: {
      const { campaignId } = action;
      return state.set(constants.FOCUSED_CAMPAIGN_ID, campaignId);
    }

    case constants.DEFOCUS_CAMPAIGN: {
      return state.set(constants.FOCUSED_CAMPAIGN_ID, null);
    }

    case constants.UPDATE_CAMPAIGN_SUCCESS: {
      const { data } = action;
      return state.update(constants.CAMPAIGNS, (campaigns) =>
        campaigns.map((campaign) => (campaign.get(constants.ID) === data.id) ? fromJS(data) : campaign));
    }

    case constants.DELETE_CAMPAIGN_SUCCESS: {
      const newCampaigns = state.get(constants.CAMPAIGNS).filter((campaign) => campaign.get(constants.ID) !== action[constants.ID]);
      return state.set(constants.CAMPAIGNS, fromJS(newCampaigns));
    }

    case constants.UPDATE_ACTIVITY_SUCCESS: {
      const { data } = action;

      const campaignIndex = utils.getEntityIndexById(
        state.get(constants.CAMPAIGNS),
        constants.ID,
        data[activityConstants.CAMPAIGN_KEY]
      );

      const activityIndex = utils.getEntityIndexById(
        state.getIn([constants.CAMPAIGNS, campaignIndex, constants.ACTIVITIES]),
        activityConstants.HUB_ID,
        data[activityConstants.HUB_ID]
      );

      const updatedActivity = fromJS(data);

      let newState = state;

      // update campaign status according to activity status
      if (updatedActivity.get(activityConstants.STATUS) === activityConstants.ACTIVITY_STATUS_ACTIVE) {
        newState = newState.setIn([constants.CAMPAIGNS, campaignIndex, constants.CAMPAIGN_STATUS], constants.CAMPAIGN_STATUS_ACTIVE);
      }

      return newState.setIn([constants.CAMPAIGNS, campaignIndex, constants.ACTIVITIES, activityIndex], updatedActivity);
    }

    case constants.ACTIVITY_MOVE_TO_CAMPAIGN_SUCCESS: {
      const { data, oldCampaignId } = action;

      const oldCampaignIndex = utils.getEntityIndexById(
        state.get(constants.CAMPAIGNS),
        constants.ID,
        oldCampaignId
      );

      const oldActivityIndex = utils.getEntityIndexById(
        state.getIn([constants.CAMPAIGNS, oldCampaignIndex, constants.ACTIVITIES]),
        activityConstants.HUB_ID,
        data[activityConstants.HUB_ID]
      );

      let newState = state.deleteIn([constants.CAMPAIGNS, oldCampaignIndex, constants.ACTIVITIES, oldActivityIndex]);

      const campaignIndex = utils.getEntityIndexById(
        state.get(constants.CAMPAIGNS),
        constants.ID,
        data.campaignKey
      );

      newState = newState.setIn([constants.CAMPAIGNS, campaignIndex, constants.ACTIVITIES],
        newState.getIn([constants.CAMPAIGNS, campaignIndex, constants.ACTIVITIES]).push(fromJS(data)));

      const { viewStatus } = data;

      // update campaign status according to activity status
      if (viewStatus === activityConstants.ACTIVITY_STATUS_FUTURE || viewStatus === activityConstants.ACTIVITY_STATUS_ACTIVE) {
        newState = newState.setIn([constants.CAMPAIGNS, campaignIndex, constants.CAMPAIGN_STATUS], constants.CAMPAIGN_STATUS_ACTIVE);
      }
      return newState;
    }

    case constants.UPDATE_FILTERS: {
      // This is the section of the code where we handle view filter code logic
      // When we enable or disable any of the filters, it has impact on other filters

      const { status, key, groupKey } = action;
      let exisingDisabledFiltersMap = state.get(constants.FILTERS_TO_DISABLE_MAP);
      if (disableFiltersActivityMapper.has(key)) {
        exisingDisabledFiltersMap = exisingDisabledFiltersMap.set(disableFiltersActivityMapper.get(key), !status);
        if (disableFiltersActivityMapper.get(key) === activityConstants.ACTION) {
          state = state.set(constants.IS_VIEW_ACTION_FILTER_DISABLED, !status);
        }
      }
      let newState = state;

      if (key === constants.FILTERS_CAMPAIGN_ARCHIVED) {
        newState = state.set(constants.IS_ARCHIVE_CAMPAIGN_FILTER_ENABLED, status);
      } else if (key === constants.FILTERS_IS_AUTO_ARCHIVE) {
        newState = newState.set(constants.IS_AUTO_ARCHIVED_FILTER_ENABLED, status);
      } else if (key === activityConstants.ACTIVITY_TYPE_RULE && !status) {
        const triggerFilters = newState.getIn([constants.TRIGGERS, constants.FILTERS_GROUP_NAME_ALL_TRIGGER]);
        if (triggerFilters.size > 0) { // This is to make sure that if the user has clicked on any Only button on any filter other than rule..
          newState = newState.set(constants.TEMP_FILTER_MAP, triggerFilters);
        }
        newState = newState.setIn([constants.TRIGGERS, constants.FILTERS_NO_FILTERS_TO_SHOW], noShowFilters);
        newState = newState.setIn([constants.TRIGGERS, constants.FILTERS_RESET_ALL_FILTERS], OrderedMap());
        newState = newState.setIn([constants.TRIGGERS, constants.FILTERS_GROUP_NAME_ALL_TRIGGER], OrderedMap());
      } else if (key === activityConstants.ACTIVITY_TYPE_RULE && status) {
        newState = newState.setIn([constants.TRIGGERS, constants.FILTERS_RESET_ALL_FILTERS], resetAllFilters);
        newState = newState.setIn([constants.TRIGGERS, constants.FILTERS_GROUP_NAME_ALL_TRIGGER], newState.get(constants.TEMP_FILTER_MAP));
        newState = newState.setIn([constants.TRIGGERS, constants.FILTERS_NO_FILTERS_TO_SHOW], OrderedMap());
      }

      // The entire remaining section of this code block is for action view filter
      // Action filter is dependent on two view filter selections...trigger and one time action
      if (exisingDisabledFiltersMap.get(activityConstants.TRIGGER) && newState.get(constants.IS_VIEW_ACTION_FILTER_DISABLED)){
        const actionFilters = newState.getIn([constants.ACTIONS, constants.FILTERS_GROUP_NAME_ALL_ACTION]);
        // Retain action view filter value even if it has been clicked more than once...
        if (actionFilters.size > 0) {
          newState = newState.set(constants.TEMP_ACTION_FILTER_MAP, actionFilters);
        }
        newState = newState.setIn([constants.ACTIONS, constants.FILTERS_NO_FILTERS_TO_SHOW], noShowFilters);
        newState = newState.setIn([constants.ACTIONS, constants.FILTERS_RESET_ALL_FILTERS], OrderedMap());
        newState = newState.setIn([constants.ACTIONS, constants.FILTERS_GROUP_NAME_ALL_ACTION], OrderedMap());
        exisingDisabledFiltersMap = exisingDisabledFiltersMap.set(activityConstants.ACTION, true);
      }
      else {
        newState = newState.setIn([constants.ACTIONS, constants.FILTERS_NO_FILTERS_TO_SHOW], OrderedMap());
        newState = newState.setIn([constants.ACTIONS, constants.FILTERS_RESET_ALL_FILTERS], resetAllFilters);
        newState = newState.setIn([constants.ACTIONS, constants.FILTERS_GROUP_NAME_ALL_ACTION], newState.get(constants.TEMP_ACTION_FILTER_MAP));
        exisingDisabledFiltersMap = exisingDisabledFiltersMap.set(activityConstants.ACTION, false);
      }

      newState = newState.set(constants.FILTERS_TO_DISABLE_MAP, exisingDisabledFiltersMap);
      return newState.setIn([constants.FILTERS, groupKey, key], status);
    }

    case constants.UPDATE_IS_ACTION_FILTER_UPDATED: {
      const { status } = action;
      return state.set(constants.IS_ACTION_FILTER_UPDATED, status);
    }

    case constants.UPDATE_TRIGGER_FILTERS_SHOW_LIST: {
      const { triggerList, isPageLoadedFirstTime } = action;
      if (isPageLoadedFirstTime && state.get(constants.TEMP_FILTER_MAP) && state.get(constants.TEMP_FILTER_MAP).size > 0) {
        state = state.set(constants.TEMP_FILTER_MAP, utils.adjustNewAndOldFilters(triggerList, state.get(constants.TEMP_FILTER_MAP)));
      }
      else {
        state = state.set(constants.TEMP_FILTER_MAP, triggerList);
      }
      // If no filter is being shown...then no need to update the filters
      const noTriggerFilter = state.getIn([constants.TRIGGERS, constants.FILTERS_NO_FILTERS_TO_SHOW]);
      if (!noTriggerFilter || (noTriggerFilter && noTriggerFilter.size === 0)) {
        state = state.setIn([constants.TRIGGERS, constants.FILTERS_GROUP_NAME_ALL_TRIGGER], state.get(constants.TEMP_FILTER_MAP));
        state = state.setIn([constants.TRIGGERS, constants.FILTERS_RESET_ALL_FILTERS], resetAllFilters);
      }
      return state;
    }

    case constants.UPDATE_ACTION_FILTERS_SHOW_LIST: {
      const { actionsList, isPageLoadedFirstTime } = action;
      if (isPageLoadedFirstTime && state.get(constants.TEMP_ACTION_FILTER_MAP) && state.get(constants.TEMP_ACTION_FILTER_MAP).size > 0) {
        state = state.set(constants.TEMP_ACTION_FILTER_MAP, utils.adjustNewAndOldFilters(actionsList, state.get(constants.TEMP_ACTION_FILTER_MAP)));
      }
      else {
        state = state.set(constants.TEMP_ACTION_FILTER_MAP, actionsList);
      }
      // If no filter is being shown...then no need to update the filters
      const noActionFilter = state.getIn([constants.ACTIONS, constants.FILTERS_NO_FILTERS_TO_SHOW]);
      if ((!noActionFilter) || (noActionFilter && noActionFilter.size === 0)) {
        state = state.setIn([constants.ACTIONS, constants.FILTERS_RESET_ALL_FILTERS], resetAllFilters);
        state = state.setIn([constants.ACTIONS, constants.FILTERS_GROUP_NAME_ALL_ACTION], state.get(constants.TEMP_ACTION_FILTER_MAP));
      }
      return state;
    }

    case constants.UPDATE_ACTION_FILTERS: {
      const { status, key, groupKey } = action;
      state = state.setIn([constants.ACTIONS, groupKey, key], status);
      state = state.set(constants.TEMP_ACTION_FILTER_MAP, state.getIn([constants.ACTIONS, constants.FILTERS_GROUP_NAME_ALL_ACTION]));
      return state;
    }

    case constants.UPDATE_TRIGGER_FILTERS: {
      const { status, key, groupKey } = action;
      state = state.setIn([constants.TRIGGERS, groupKey, key], status);
      state = state.set(constants.TEMP_FILTER_MAP, state.getIn([constants.TRIGGERS, constants.FILTERS_GROUP_NAME_ALL_TRIGGER]));
      return state;
    }

    case constants.UPDATE_TAGS_FILTERS: {
      const { key } = action;
      return state.set(constants.ACTIVITY_TAGS_FILTER, key);
    }

    case constants.UPDATE_RULE_FILTER_MODE: {
      const { isEnabled } = action;
      return state.set(constants.IS_RULE_FILTER_ENABLED, isEnabled);
    }

    case constants.UPDATE_ACTION_FILTER_MODE: {
      const { isEnabled } = action;
      return state.set(constants.IS_ACTION_FILTER_ENABLED, isEnabled);
    }

    case constants.FETCHED_ACTIVITIES_WITH_ARCHIVE_SUCCESS: {
      return state.set(constants.CAMPAIGNS, fromJS(action.data))
                  .set(constants.CAMPAIGNS_LOADED, true)
                  .set(constants.FETCHED_ACTIVITIES_WITH_ARCHIVE, true)
                  .setIn([constants.FILTERS, constants.FILTERS_GROUP_NAME_CAMPAIGN, constants.FILTERS_IS_AUTO_ARCHIVE], true);
    }

    case constants.UPDATE_ORDER: {
      return state
        .setIn([constants.SORT, constants.ORDER],
          state.getIn([constants.SORT, constants.ORDER]) === constants.ORDER_DESCENT ?
            constants.ORDER_ASCEND : constants.ORDER_DESCENT
        );
    }

    case constants.DELETE_ACTIVITY_SUCCESS: {
      const { campaignId, activityId } = action;

      const campaignIndex = utils.getEntityIndexById(
        state.get(constants.CAMPAIGNS),
        constants.ID,
        campaignId
      );

      const activityIndex = utils.getEntityIndexById(
        state.getIn([constants.CAMPAIGNS, campaignIndex, constants.ACTIVITIES]),
        activityConstants.HUB_ID,
        activityId
      );

      return state.deleteIn([constants.CAMPAIGNS, campaignIndex, constants.ACTIVITIES, activityIndex]);
    }

    case activityConstants.HIGHLIGHT_ACTIVITY: {
      if (action[activityConstants.HIGHLIGHTED_ACTIVITY_ID]) {
        return state.set(activityConstants.HIGHLIGHTED_ACTIVITY_ID, action[activityConstants.HIGHLIGHTED_ACTIVITY_ID]);
      }
      return state.delete(activityConstants.HIGHLIGHTED_ACTIVITY_ID);
    }

    case constants.SCROLL_Y_POSITION_UPDATED: {
      return state.set(constants.SCROLL_Y_POSITION, action.yPos.scrollYPosition);
    }

    case constants.UPDATE_ACTIVITY_PINNED_DATE: {

      const campaignIndex = utils.getEntityIndexById(
        state.get(constants.CAMPAIGNS),
        constants.ID,
        action.campaignId
      );

      const activityIndex = utils.getEntityIndexById(
        state.getIn([constants.CAMPAIGNS, campaignIndex, constants.ACTIVITIES]),
        activityConstants.DB_ID,
        action.activityId
      );

      return state.setIn([constants.CAMPAIGNS, campaignIndex, constants.ACTIVITIES, activityIndex, constants.ACTIVITY_PINNING_DATE], action.pinningDate);
    }

    case constants.CLOSE_TOAST: {
      return state.set(constants.IS_TOAST_OPEN, false)
        .set(constants.TOAST_MESSAGE, '');
    }

    case constants.OPEN_TOAST: {
      const { message } = action;

      return state.set(constants.IS_TOAST_OPEN, true)
          .set(constants.TOAST_MESSAGE, message);
    }

    case constants.OPEN_ACTIVITY_IN_NEW_TAB: {
      const activity = action.activity;
      const type = activity.get(activityConstants.TYPE);
      const activityId = activity.get(activityConstants.HUB_ID);
      let path = '';
      // eslint-disable-next-line default-case
      switch (type) {
        case activityConstants.SERVER_TYPE_GIFT:
          path += activityConstants.PATH_GIFTS;
          break;
        case activityConstants.SERVER_TYPE_RULE:
          path += activityConstants.PATH_RULES;
          break;
        case activityConstants.SERVER_TYPE_PUNCH_CARD:
          path += activityConstants.PATH_PUNCH_CARDS;
          break;
        case activityConstants.SERVER_TYPE_PROMO_CODE:
          path += activityConstants.PATH_PROMO_CODES;
          break;
        case activityConstants.SERVER_TYPE_ONE_TIME_ACTION:
          path += activityConstants.PATH_ONE_TIME;
          break;
        case activityConstants.SERVER_TYPE_DEAL:
          path += activityConstants.PATH_DEALS;
          break;
        case activityConstants.SERVER_TYPE_POINTSHOP:
          path += activityConstants.PATH_POINTSHOP;
          break;
        case activityConstants.SERVER_TYPE_EMAIL:
          path += activityConstants.PATH_EMAILS;
          break;
      }
      window.open(`${window.location.origin}/${path}/${activityId}${location.search}`, '_blank');
    }
    // eslint-disable-next-line no-fallthrough
    default: {
      return state;
    }
  }
}

export default businessCenterReducer;
