/**
 *
 * SendSms
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Button, Grid} from 'semantic-ui-react';
import {bindActionCreators, compose} from 'redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import MentionTextArea from '../../../../../../common/components/MentionableTextArea/MentionTextArea';
import * as constants from '../../../../activity.constants';
import * as smsUtils from './sendSMS.view.utils';
import SMSCharacterCounter from './SMSCharacterCounter';
import * as appConstants from '../../../../../App/app.constants';
import * as selectors from '../../../../../App/selectors';
import * as activitySelectors from '../../../../../Activity/activity.selectors';
import * as appActions from '../../../../../App/app.actions';
import TestToMyself from '../testToMyself/TestToMyself.container';
import * as schemaConstants from '../../../../activitySchema/activitySchema.constants';
import CheckBox from '../../../../../../common/components/FormInputFields/CheckboxWrapper/CheckboxWrapper';
import PushNotificationGallery
  from '../../../../../Images/views/PushNotificationGallery/PushNotificationGallery.container';
import * as imageSelectors from '../../../../../Images/images.selectors';
import * as imagesConstants from '../../../../../Images/images.constants';
import withAuthorization from '../../../../../../common/helpers/authorization';
import HeaderNotification from '../../../activityHeader/HeaderNotification';
import AiPopover from '../../../../../Ai/AiPopover';
import * as aiActions from '../../../../../Ai/ai.actions';

const SMS_SCALE_WARNING = 100000;
const MMS_MESSAGE_COUNT = 1;
class SendSMS extends React.PureComponent {

  // eslint-disable-next-line no-undef
  static propTypes = {
    automationIdPrefix: PropTypes.string,
    onActionFieldUpdate: PropTypes.func.isRequired,
    includeImage: PropTypes.bool,
    index: PropTypes.number.isRequired,
    mentionsList: PropTypes.object,
    validationErrors: PropTypes.object,
    unsubscribeMessage: PropTypes.string,
    overrideSmsWarning: PropTypes.string,
    isOperationalSms: PropTypes.bool,
    smsMessage: PropTypes.string,
    actions: PropTypes.object,
    aiActions: PropTypes.object,
    countryCode: PropTypes.string,
    operational: PropTypes.string,
    marketing: PropTypes.string,
    imageId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    actionsWrapperIndex: PropTypes.number.isRequired,
    submitActionToCustomer: PropTypes.func,
    isConsentSms: PropTypes.bool,
    images: PropTypes.object,
    defaultPreviewImage: PropTypes.object,
    getPermissionLevel: PropTypes.func.isRequired,
    trigger: PropTypes.string,
    filteredPopulation: PropTypes.object,
    numberOfMembers: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    abTestMode: PropTypes.bool,
    cases: PropTypes.object
  };

  static defaultProps = {
    isConsentSms: false,
  };

  state = {
    [constants.NUMBER_OF_MESSAGES]: 1,
    [constants.MESSAGE_CONTENT_LENGTH]: 1,
    [constants.FILTERED_POPULATION_COUNT]: 0
  };

  componentDidMount() {
    this.props.actions.getCurrentSMSProviders(this.props.countryCode);
  }

  componentWillMount() {
    if (!this.props.images) {
      this.props.actions.getImagesBySize(imagesConstants.IMAGE_SIZE_THUMBNAIL);
    }
  }

  componentWillUnmount() {
    this.props.aiActions.clearPromptText();
  }

  onImageSelected(selectedId, index) {
    if (this.props.images) {
      const selectedImage = this.getImageById(selectedId);
      const imageUrl = selectedImage.get(imagesConstants.URL);
      this.props.onActionFieldUpdate(constants.MMS_IMAGE, selectedId, index);
      this.props.onActionFieldUpdate(constants.MMS_IMAGE_URL, imageUrl, index);
    }
  }

  getImageById(selectedId) {
    return this.props.images.find((image) => image.get(imagesConstants.ID) === selectedId);
  }

  convertSMSTextByReplacingCurlyQuotes = (smsMessage) => smsMessage.replace(new RegExp('‘', 'g'), "'")
      .replace(new RegExp('’', 'g'), "'")
      .replace(new RegExp('“', 'g'), "''")
      .replace(new RegExp('”', 'g'), "''")

  transformSMSTextByReplacingQuotes = (smsMessage) => {
    smsMessage = this.convertSMSTextByReplacingCurlyQuotes(smsMessage);
    this.props.onActionFieldUpdate(constants.SMS_MESSAGE, smsMessage, this.props.index);
  }

  isMessageUnicodeAndCanItBeConvertedToGSM(smsMessage) {
    const originalText = smsMessage + (this.props.unsubscribeMessage || '');
    const potentialMessageAfterRemovingQuotes = this.convertSMSTextByReplacingCurlyQuotes(originalText);
    return SMSCharacterCounter.isUnicodeRequired(originalText) && !SMSCharacterCounter.isUnicodeRequired(potentialMessageAfterRemovingQuotes);
  }

  static getDerivedStateFromProps(props, state) {
    const {
      isOperationalSms,
      operational,
      marketing,
      smsMessage,
      unsubscribeMessage,
      abTestMode,
      automationIdPrefix,
      cases,
      numberOfMembers,
      includeImage
    } = props;

    const provider = isOperationalSms ? operational : marketing;
    const smsInfo = SMSCharacterCounter.getInfo(smsMessage, unsubscribeMessage, provider);
    const messageContentLength = smsInfo.charactersCount;
    const numberOfMessages = includeImage ? MMS_MESSAGE_COUNT : smsInfo.messagesCount;

    const unicodeChars = smsUtils.getSpecialChars(unsubscribeMessage, smsMessage, provider);

    state[constants.MESSAGE_CONTENT_LENGTH] = messageContentLength;
    state[constants.NUMBER_OF_MESSAGES] = numberOfMessages;
    state[constants.MESSAGE_SPECIAL_CHARS] = unicodeChars;
    if (abTestMode) {
      const prefixParts = automationIdPrefix.split('cases.');
      const caseIndex = prefixParts[1].split('.')[0];
      state[constants.FILTERED_POPULATION_COUNT] = cases.get(caseIndex).get(constants.AB_TESTING_FILTERED_POPULATION);
    } else {
      state[constants.FILTERED_POPULATION_COUNT] = numberOfMembers;
    }
    return state;
  }

  renderSpecialCharsMessage() {
    return this.state[constants.MESSAGE_SPECIAL_CHARS] && this.state[constants.MESSAGE_SPECIAL_CHARS].length ?
      <b>
        <FormattedMessage
          id={'sendSms.action.specialChars'}
          values={{ specialChars: this.state[constants.MESSAGE_SPECIAL_CHARS] }}
        />
      </b>
      : null;
  }

  render() {
    const {
      mentionsList,
      onActionFieldUpdate,
      index,
      validationErrors,
      automationIdPrefix,
      overrideSmsWarning,
      smsMessage,
      unsubscribeMessage,
      includeImage,
      defaultPreviewImage,
      imageId,
      images,
      getPermissionLevel,
      trigger,
      filteredPopulation
    } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const smsImage = imageId && images ? this.getImageById(imageId).get(imagesConstants.URL) : null;
    const hasFeature = getPermissionLevel(appConstants.FEATURES_MMS) !== constants.FEATURE_LEVEL.NONE;

    const filterPopulationCount = filteredPopulation && filteredPopulation.get('count') ? filteredPopulation.get('count') : 0;
    const showSmsScaleWarning = trigger === 'oneTimeAction' && filterPopulationCount > SMS_SCALE_WARNING;
    const calculatedTotalCharge = this.state[constants.FILTERED_POPULATION_COUNT] * (this.state[constants.NUMBER_OF_MESSAGES]);

    return (
      <div>
        <Grid>
          {showSmsScaleWarning && <Grid.Row style={{ padding: 'unset' }}>
            <Grid.Column width={16}>
              <HeaderNotification
                messageKey="activity.actions.smsAction.scaleWarning"
                automationIdPrefix={'activity.actions.smsAction.scaleWarning'}
              />
            </Grid.Column>
          </Grid.Row>}
          <Grid.Row>
            <Grid.Column width={16}>
              <AiPopover
                getPermissionLevel={getPermissionLevel}
                onTextUpdate={(newMessage) => onActionFieldUpdate(constants.SMS_MESSAGE, newMessage, index)}
                prePromptKey={'vertex.ai.prePrompt.sms'}
                mainButtonTextKey={'vertex.ai.main-btn-txt'}
                titleToolTipKey={'vertex.ai.btn-tooltip'}
                justifyContent={'tw-justify-end'}
                charLength={80}
              />
              {mentionsList ? (
                <MentionTextArea
                  automationIdPrefix={automationIdPrefix}
                  mentionOptions={mentionsList}
                  message={smsMessage}
                  onTextUpdate={(newMessage) => onActionFieldUpdate(constants.SMS_MESSAGE, newMessage, index)}
                  index={index}
                  error={validationErrors ? validationErrors.get(constants.SMS_MESSAGE) : null}
                  autoAddedMessage={unsubscribeMessage}
                />
              ) : null}
            </Grid.Column>
            {calculatedTotalCharge > 10 ?
              <Grid.Column width={16}>
                <br />
                <FormattedMessage
                  id={'sendSms.action.PriceWarning'}
                  description="Warning to the user about SMS pricing"
                  values={{
                    yourAccountWillBeChargeFor: <b>{formatMessage({ id: 'sendSms.action.yourAccountWillBeChargeFor' })}</b>,
                    numberOfMembers: this.state[constants.FILTERED_POPULATION_COUNT],
                    numberOfMessages: this.state[constants.NUMBER_OF_MESSAGES],
                    calculatedTotalCharge: <span style={{ color: 'red' }}><b> {calculatedTotalCharge}</b></span>,
                    messages: <span style={{ color: 'red' }}><b> {formatMessage({ id: 'sendSms.action.messages' })}</b></span>
                  }}
                />
                <br />
              </Grid.Column>
              : null}
            <Grid.Column width={16}>
              {this.renderSpecialCharsMessage()}
              {this.isMessageUnicodeAndCanItBeConvertedToGSM(smsMessage) ?
                <span>
                  <span className="sms-transform-span-text">{formatMessage({ id: 'sendSms.action.transformSMS-message' })}</span>
                  <Button
                    key="save"
                    data-automation-id={'settings.legal.сonsent.save'}
                    onClick={() => this.transformSMSTextByReplacingQuotes(smsMessage)}
                    className="button-sms-transform"
                  >
                    {formatMessage({ id: 'sendSms.action.transformSMS-confirm-button.title' })}
                  </Button>
                </span>
                : null}
            </Grid.Column>
          </Grid.Row>
          {
            validationErrors && validationErrors.get(constants.CONSENT_LANDING_PAGE_LINK_MENTION) &&
            <div className="text-area-mandatory-field-error">
              {formatMessage({ id: validationErrors.get(constants.CONSENT_LANDING_PAGE_LINK_MENTION) })}
            </div>
          }
        </Grid>
        <div className="clearfix fixed-height">
          <p className="action-hint italic left-note left">
            {formatMessage({ id: overrideSmsWarning || (includeImage ? 'activity.action.mms.additional_charges_warning' : 'activity.action.sms.additional_charges_warning') })}
          </p>
          <p className="action-hint italic right split-left">
            { includeImage ? formatMessage({ id: 'activity.actions.smsAction.chargedMms' }, { [constants.NUMBER_OF_MESSAGES]: this.state[constants.NUMBER_OF_MESSAGES] === 0 ? 'N/A' : this.state[constants.NUMBER_OF_MESSAGES] }) : formatMessage({ id: 'activity.actions.smsAction.chargedMessages' }, { [constants.NUMBER_OF_MESSAGES]: this.state[constants.NUMBER_OF_MESSAGES] === 0 ? 'N/A' : this.state[constants.NUMBER_OF_MESSAGES] })}
          </p>
          <p className="action-hint italic right">
            <FormattedMessage
              id={'activity.actions.smsAction.smsContentLength'}
              values={{ [constants.MESSAGE_CONTENT_LENGTH]: this.state[constants.MESSAGE_CONTENT_LENGTH] }}
            />
          </p>
        </div>
        {hasFeature && <div style={{ marginTop: '2em' }}>
          <CheckBox
            name={constants.SEND_SMS_WITH_IMAGE}
            checked={includeImage}
            data-automation-id={`action.${index}.sendSmsWithImage`}
            onChange={(e, data) => onActionFieldUpdate(constants.SEND_SMS_WITH_IMAGE, data.checked, index)}
            label={formatMessage({ id: 'activity.sms.action.include.image' })}
          />
          {includeImage && <PushNotificationGallery
            selectedPreviewImage={defaultPreviewImage}
            onImageSelected={(id) => this.onImageSelected(id, index)}
            imageUrl={smsImage}
          />}
        </div>}
        {
          !this.props.isConsentSms && <TestToMyself
            label={includeImage ? 'test.to.myself.sendMemberMms' : null}
            actionType={schemaConstants.SEND_MEMBER_SMS_ACTION}
            actionIndex={this.props.index}
            actionsWrapperIndex={this.props.actionsWrapperIndex}
            submitActionToCustomer={this.props.submitActionToCustomer}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  unsubscribeMessage: state.getIn([appConstants.APP, appConstants.UNSUBSCRIBE_MESSAGE]),
  countryCode: selectors.getCountryCode(state),
  operational: selectors.getCurrentSMSOperationalProvider(state),
  marketing: selectors.getCurrentSMSMarketingProvider(state),
  numberOfMembers: activitySelectors.getFilteredPopulationSize(state, true),
  images: imageSelectors.getAllActiveImageThumbnails(state),
  defaultPreviewImage: imageSelectors.getDefaultImageThumbnail(state),
  filteredPopulation: activitySelectors.getOneTimeActionFilteredPopulation(state),
  abTestMode: activitySelectors.getABTestMode(state),
  cases: activitySelectors.getActivityCases(state)
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(appActions, dispatch),
    aiActions: bindActionCreators(aiActions, dispatch)
  };
}

export default withAuthorization(compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)(SendSMS));

