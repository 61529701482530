import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import ValidationErrorMessage from '../ValidationErrorMessage/ValidationErrorMessage';

class PopupTrigger extends React.PureComponent {

  static propTypes = {
    className: PropTypes.string,
    automationId: PropTypes.string,
    message: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    validationErrorMessage: PropTypes.string,
    validationErrorClassName: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  };

  render() {
    return (
      <div className="inline-div">
        <p
          className={this.props.className}
          data-automation-id={this.props.automationId}
        >
          <span className="text" title={this.props.title}>
            {this.props.message}
          </span>
          <Icon className="como-ic-triangle-down" />
        </p>
        { this.props.validationErrorMessage ?
          (
            <ValidationErrorMessage
              className={this.props.validationErrorClassName}
              errorMessage={this.props.validationErrorMessage}
              dataAutomationId={`${this.props.automationId}.error.message`}
            />
          ) : null }
      </div>
    );
  }
}

export default PopupTrigger;

