import React from 'react';
import { injectIntl } from 'react-intl';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Header, Loader } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import Dashboard from '@como/web-hub-data-and-bi/src/components/Dashboard';
import * as IframeService from '@como/web-hub-data-and-bi/src/services/IframeService';
import * as appSelectors from '../App/selectors';
import * as appActions from '../App/app.actions';
import './basic.styles.less';
import { getSideNavbarSettings } from './basic.utils';
import SideNavBar from '../SideNavBar/SideNavBar';
import withAuthorization from '../../common/helpers/authorization';
import '../Email/email.styles.less';


class BasicPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      iframeSettings: null,
      height: 0,
    };
  }
  static propTypes = {
    business: PropTypes.object,
    user: PropTypes.object,
    locationId: PropTypes.number,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    isFilterMembers: PropTypes.bool,
    isBusinessCenter: PropTypes.bool,
    isEmails: PropTypes.bool,
    className: PropTypes.string,
    headClassName: PropTypes.string,
    translationKey: PropTypes.string,
    noTitle: PropTypes.bool,
  };
  componentDidMount() {
    this.setFeaturesToState();
    this.initResizeListener();
  }
  setFeaturesToState = () => {
    const { business, user, locationId } = this.props;
    const userId = user.get('id');
    const userEmail = user.get('email');
    const webpropertyid = business.get('webpropertyId');
    const reportcode = business.get('reportCode');
    const iframeData = { userId, email: userEmail, webpropertyid, reportcode, locationId, allDates: false };
    IframeService.getSettings(iframeData).then((result) => {
      this.setState({
        iframeSettings: result
      });
    });
  }
  initResizeListener = () => {
    this.updateHeight();
    window.addEventListener('resize', this.updateHeight);
  };
  updateHeight = () => {
    const headerHeight = 65;

    this.setState({ contentHeight: (window.innerHeight - headerHeight) });
  };

  getIframe = () => {
    const { iframeSettings, contentHeight } = this.state;
    if (iframeSettings) {
      return (
          <div className={'iframe-wrapper'}>
            <Dashboard url={iframeSettings.dashboardUrl} contentHeight={contentHeight} />
          </div>);
    }
    return <Loader active />;
  };
  getFilterMembersContent = () => {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    return (
      <div className="basic-page-content">
        <div className={this.props.className}>
          <Helmet>
            {formatMessage({ id: this.props.translationKey })}
            <title>{formatMessage({ id: this.props.translationKey })}</title>
            <meta name="description" content={'Description of Filter Members'} />
          </Helmet>
          <div id="filter-members-all">
            {!this.props.noTitle && (
            <Header className={this.props.headClassName}>
                {formatMessage({ id: this.props.translationKey })}
            </Header>
              )}
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
  getChildrenContent = () => {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    return this.props.children;
  }
  renderPageContent = (page) => {
    switch (page){
      case 'filterMembers':
        return this.getFilterMembersContent();
      case 'businessCenter':
      case 'emails':
        return this.getChildrenContent();
      default:
        return this.getIframe();
    }
  }
  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const { isFilterMembers, isBusinessCenter, isEmails } = this.props; // eslint-disable-line react/prop-types
    const sideNavBarItems = getSideNavbarSettings(formatMessage, location);
    // eslint-disable-next-line no-nested-ternary
    const page = isFilterMembers ? 'filterMembers' : isBusinessCenter ? 'businessCenter' : isEmails ? 'emails' : 'dashboard';
    return (
      <div className="basic-page">
        <SideNavBar sideNavBarItems={sideNavBarItems} />
        {this.renderPageContent(page)}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    locationId: appSelectors.getLocationId(state),
    business: appSelectors.getBusiness(state),
    user: appSelectors.getUser(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(appActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
    withConnect, withAuthorization)(injectIntl(BasicPage));
