export const BUSINESS_CENTER_ROUTE = '/businessCenter';
export const BASIC_BUSINESS_CENTER_ROUTE = '/b/BusinessCenter';
export const RULES_ROUTE = '/rules/create';
export const RULES_CREATE_ROUTE = '/rules/create';
export const RULES_SPECIFIC_ROUTE = '/rules/:id';
export const DEALS_ROUTE = '/deals/create';
export const DEALS_CREATE_ROUTE = '/deals/create';
export const DEALS_SPECIFIC_ROUTE = '/deals/:id';
export const TEMPLATE_LIBRARY_ROUTE = '/templateLibrary';
export const BASIC_TEMPLATE_LIBRARY_ROUTE = '/b/templateLibrary';
export const TEMPLATE_LIBRARY_ADMIN_ROUTE = '/templateLibrary/admin';
export const GOALS_ROUTE = '/goals';
export const GOALS_SPECIFIC_ROUTE = '/goals/:goalId';
export const GOALS_ADMIN_ROUTE = '/goals/admin';
export const FORBIDDEN_ROUTE = '/forbidden';
export const GIFTS_ROUTE = '/gifts';
export const GIFTS_SPECIFIC_ROUTE = '/gifts/:id';
export const GIFTS_CREATE_ROUTE = '/gifts/create';
export const ONETIME_CREATE_ROUTE = '/onetime/create';
export const BASIC_ONETIME_CREATE_ROUTE = '/b/onetime/create';
export const ONETIME_SPECIFIC_ROUTE = '/onetime/:id';
export const BASIC_ONETIME_SPECIFIC_ROUTE = '/b/onetime/:id';
export const PUNCH_CARDS_ROUTE = '/punchCards';
export const PUNCH_CARDS_CREATE_ROUTE = '/punchCards/create';
export const PUNCH_CARDS_SPECIFIC_ROUTE = '/punchCards/:id';
export const PROMO_CODES_ROUTE = '/promoCodes';
export const PROMO_CODES_CREATE_ROUTE = '/promoCodes/create';
export const PROMO_CODES_SPECIFIC_ROUTE = '/promoCodes/:id';
export const EMAILS_ROUTE = '/emails';
export const BASIC_EMAILS_ROUTE = '/b/emails';
export const POINT_SHOP_ROUTE = '/pointShop';
export const POINT_SHOP_ITEM_CREATE_ROUTE = '/pointShop/create';
export const POINT_SHOP_ITEM_UPDATE_ROUTE = '/pointShop/:id';
export const SOMETHING_WENT_WRONG = '/somethingsWentWrong';
export const BASIC_EMAILS_CREATE_ROUTE = '/b/emails/create';
export const EMAILS_CREATE_ROUTE = '/emails/create';
export const EMAILS_SPECIFIC_ROUTE = '/emails/:id';
export const BASIC_EMAILS_SPECIFIC_ROUTE = '/b/emails/:id';
export const CUSTOMER_ROUTE = '/customer';
export const DATA_BI_ROUTE = '/databi';
export const DATA_BI_LOOKER_ROUTE = '/databi/looker';
export const DATA_BI_LOOKER_DASHBOARDS_ROUTE = '/databi/lookerDashboards';
export const DATA_BI_LOOKER_ANALYTICS = '/databi/analytics';
export const WELCOME_ROUTE = '/welcome';
export const UPGRADE_ROUTE = '/upgrade/:id';
export const BUSINESS_PROFILE = '/business-profile';
export const BUSINESS_PROFILE_REGISTRATION = '/business-profile/registration';
export const BASIC_ROUTE = '/b/';
export const BUSINESS_PROFILE_COMMUNICATION = '/business-profile/communication';
export const BUSINESS_PROFILE_LEGAL = '/business-profile/legal';
export const BUSINESS_PROFILE_LOCATIONS = '/business-profile/locations';
export const BUSINESS_PROFILE_FEATURES = '/business-profile/features';
export const MICRO_CAMPAIGNS_ROUTE = '/microCampaigns';
export const MICRO_CAMPAIGN_CREATE_ROUTE = '/microCampaigns/create';
export const MICRO_CAMPAIGN_SPECIFIC_ROUTE = '/microCampaigns/:id';
export const FILTER_MEMBERS_ROUTE = '/databi/filterMembers';
export const BASIC_FILTER_MEMBERS_ROUTE = '/b/FilterMembers';
export const DATA_EXPORT_ROUTE = '/databi/dataExport';

