import React from 'react';
import {injectIntl} from 'react-intl';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Helmet} from "react-helmet";
import {Grid} from "semantic-ui-react";
import * as appSelectors from '../App/selectors';
import * as appActions from '../App/app.actions';
import './upgrade.styles.less';
import SideNavBar from "../SideNavBar/SideNavBar";
import {getSideNavbarSettings} from "../BusinessCenter/businessCenter.utils";
import withAuthorization from "../../common/helpers/authorization";
import * as routes from "../../constants/route.contants";
import {Clip} from '../Clip/Clip';


class Upgrade extends React.PureComponent {

  static propTypes = {
    defaultPage: PropTypes.string,
    actions: PropTypes.object,
    hasFullPermission: PropTypes.func.isRequired,
    getPermissionLevel: PropTypes.func.isRequired,
    businessUserData: PropTypes.object
  };

  requestFeatureActivation = () => {
    this.props.actions.requestToUpgradeToProfessional(this.props.businessUserData);
  };

  renderUpgradeContainer = (route) => {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    let titleId;
    let mediaSource;
    const upgradeRoute = '/' + route; // TODO: Ugly temp hack
    switch (upgradeRoute) {
      case routes.MICRO_CAMPAIGNS_ROUTE: {
        titleId = 'side-navbar.micro_campaign_center';
        mediaSource = 'https://static-mk-aws.prod.bcomo.com/St/Comillia.mp4';
        break;
      }
      case routes.PROMO_CODES_ROUTE: {
        titleId = 'promoCodes-page.promoCodes';
        mediaSource = 'https://static-mk-aws.prod.bcomo.com/St/PromoCodes.mp4';
        break;
      }
      case routes.POINT_SHOP_ROUTE: {
        titleId = 'point-shop-page.title';
        mediaSource = 'https://static-mk-aws.prod.bcomo.com/St/PointShop.mp4';
        break;
      }
      default:
        titleId = route;
        mediaSource = 'https://www.youtube.com/embed/ZZLo2lz6UA?autoplay=1&origin=http://example.com';
        break;
    }
    return (
      <Grid className={'upgrade-container'}>
        <Grid.Row>
          <div className={'upgrade-header'}>
            {formatMessage({ id: titleId })}
          </div>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Clip url={mediaSource} width={960} height={540}></Clip>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const sideNavBarItems = getSideNavbarSettings(formatMessage, location, this.props.hasFullPermission, this.props.getPermissionLevel);
    return (
      <div className="upgrade-page" onClick={this.requestFeatureActivation}>
        <Helmet>
          <title>Upgrade to Como Professional</title>
        </Helmet>
        <SideNavBar sideNavBarItems={sideNavBarItems}/>
        {this.renderUpgradeContainer(this.props.match.params.id)}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    defaultPage: appSelectors.getDefaultPage(state),
    businessUserData: {
      locationId: appSelectors.getLocationId(state),
      locationDisplayName: appSelectors.getBusinessDisplayName(state),
      user: appSelectors.getUserEmail(state)
    }
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(appActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect, withAuthorization)(injectIntl(Upgrade));
