import * as routes from '../../constants/route.contants';

export function getSideNavbarSettings(formatMessage, location) {
  return [
    {
      id: 'basic',
      text: formatMessage({ id: 'data-and-bi.dashboard.title' }),
      target: { search: location.search, pathname: routes.BASIC_ROUTE },
      automationId: 'navigation.basic',
      icon: 'new-dashboard-icon'
    },
    {
      id: 'basic.filterMembers',
      text: formatMessage({ id: 'filterMembers' }),
      target: { search: location.search, pathname: routes.BASIC_FILTER_MEMBERS_ROUTE },
      automationId: 'navigation.basic.filter',
      icon: 'como-svg-side-nav-icon-filter-members',
    },
    {
      id: 'basic.campaignCenter',
      text: formatMessage({ id: 'side-navbar.campaign_center' }),
      target: { search: location.search, pathname: routes.BASIC_BUSINESS_CENTER_ROUTE },
      automationId: 'navigation.basic.campaign.center',
      icon: 'como-svg-side-nav-icon-campaigns'
    },
    {
      id: 'basic.emails',
      text: formatMessage({ id: 'side-navbar.emails' }),
      target: { search: location.search, pathname: routes.BASIC_EMAILS_ROUTE },
      automationId: 'navigation.emails',
      icon: 'como-svg-side-nav-icon-email'
    }
  ];
}
