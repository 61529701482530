import React from 'react';
import PropTypes from 'prop-types';

import * as CustomPropTypes from './proptypes';
import './VerticalNavBar.styles.less';
import VerticalNavBarItem from './NavBarItem';

export class VerticalNavBar extends React.PureComponent {

  static propTypes = {
    children: CustomPropTypes.NavBarItemPropType,
    onToggleCollapse: PropTypes.func,
    collapseMap: PropTypes.object,
    className: PropTypes.string,
  };

  render() {
    return (
      <div className={`vertical-nav-bar ${this.props.className || ''}`}>
        {this.props.children &&
            React.Children.map(this.props.children, (item, index) => React.cloneElement(item, {
              onToggleCollapse: this.props.onToggleCollapse,
              collapsed: this.props.collapseMap ? this.props.collapseMap.get(index.toString()) : false,
              itemKey: index.toString(),

            }))}
      </div>
    );
  }
}

export const NavBarItem = VerticalNavBarItem;
