import { createSelector } from 'reselect';


import * as constants from './microCampaigns.constants';
import * as reducerConstants from '../../constants/reducer.constants';
import * as utils from './utils/microCampaigns.selectors.utils';

export const getMicroCampaigns = (state) => state.getIn([reducerConstants.MICRO_CAMPAIGNS_BRANCH, constants.MICRO_CAMPAIGNS]);

export const getSortedMicroCampaigns = createSelector([getMicroCampaigns], utils.sortMicroCampaigns);

export const getMicroCampaignsFetchedFlag = (state) => state.getIn([reducerConstants.MICRO_CAMPAIGNS_BRANCH, constants.MICRO_CAMPAIGNS_FETCHED]);
export const getMicroCampaignUpdateStatus = (state) => state.getIn([reducerConstants.MICRO_CAMPAIGNS_BRANCH, constants.MICRO_CAMPAIGN_UPDATE_STATUS_IN_PROGRESS]);

export const getMicroCampaignStatsFetchedFlag = (state) => state.getIn([reducerConstants.MICRO_CAMPAIGNS_BRANCH, constants.MICRO_CAMPAIGN_STATS_FETCHED]);

export const getMicroCampaignStats = (state) => state.getIn([reducerConstants.MICRO_CAMPAIGNS_BRANCH, constants.MICRO_CAMPAIGN_STATS]) || null;
