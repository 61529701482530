import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import tracker from '../../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../../common/utils/tracking/tracking.consts';
import ConfirmationDialog from '../../../../common/components/ConfirmationDialog/ConfirmationDialog';
import * as casesActions from './cases.actions';
import Tooltip from '../../../../common/components/Tooltip/Tooltip';

const AUTOMATION_PREFIX_NAME = 'toggle-ab-testing';

class ToggleABTesting extends React.PureComponent {

  static propTypes = {
    abTestMode: PropTypes.bool,
    automationIdPrefix: PropTypes.string.isRequired,
    cancelABTest: PropTypes.func,
    intl: PropTypes.object.isRequired,
    startABTest: PropTypes.func,
    disabled: PropTypes.bool,
    actions: PropTypes.object.isRequired,
  };

  confirmCancelABTest = () => {
    this.props.actions.cancelABTest();
    tracker.onButtonClick(trackerConstants.BUTTON_TYPE_AB_TEST_CANCEL);
  };

  handleSplitToCases = () => {
    if (!this.props.disabled) {
      this.props.actions.startABTest();
      tracker.onButtonClick(trackerConstants.BUTTON_TYPE_AB_TEST_START);
    }
  };

  render() {
    const { formatMessage } = this.props.intl;
    const splitButtonClasses = classNames('ab-test', 'activity-link', { 'disabled': this.props.disabled });
    return this.props.abTestMode
      ? (
        <ConfirmationDialog
          automationIdPrefix={`${this.props.automationIdPrefix}.actions.${AUTOMATION_PREFIX_NAME}`}
          confirmButtonKey="activity.action.cancelABTest.confirmationMessage.confirm"
          cancelButtonKey="activity.action.cancelABTest.confirmationMessage.cancel"
          triggerClassName="cancel-split-to-cases"
          triggerLabelKey="activity.action.cancelABTest"
          triggerAutomationId={`${this.props.automationIdPrefix}.actions.cancel-ab-test`}
          contentMessageKey="activity.action.cancelABTest.confirmationMessage"
          titleKey="activity.action.cancelABTest.confirmationHeader"
          confirmAction={this.confirmCancelABTest}
          icon="como-ic-close"
        />
      )
      : (
        <span>
          <Tooltip
            wide="very"
            trigger={(<Icon className="como-ic-help" />)}
            content={this.props.disabled ? formatMessage({ id: 'abTest.tooltip.disabled.explained' }) : formatMessage({ id: 'abTest.tooltip.explained' })}
          />
          <a role="button" className={splitButtonClasses} onClick={this.handleSplitToCases}>
            <Icon className="como-ic-select-all" size="large" data-automation-id={`${this.props.automationIdPrefix}.actions.start-ab-test`} />
            {formatMessage({ id: 'activity.action.startABTest' })}
          </a>
        </span>
      );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(casesActions, dispatch)
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(injectIntl(ToggleABTesting));

