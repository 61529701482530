import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from 'semantic-ui-react';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import PopupWrapper from '../../../../common/components/PopupWrapper/PopupWrapper';
import CustomModal from '../../../../common/components/CustomModal/CustomModal';
import TriggersList from './triggersList';
import ValidationErrorMessage from '../../../../common/components/ValidationErrorMessage/ValidationErrorMessage';
import * as commonComponents from '../../../../common/components/common.components.constants';
import * as constants from '../../activity.constants';
import * as activitySchemaConstants from '../../activitySchema/activitySchema.constants';
import PopupTrigger from '../../../../common/components/PopupTrigger/PopupTrigger';
import './triggers.styles.less';
import tracker from '../../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../../common/utils/tracking/tracking.consts';

class Trigger extends React.PureComponent {

  static propTypes = {
    clearTempTrigger: PropTypes.func,
    pendingTrigger: PropTypes.object,
    selectedTrigger: PropTypes.string,
    selectTrigger: PropTypes.func.isRequired,
    triggersList: PropTypes.object.isRequired,
    updateTriggerPreValidation: PropTypes.func,
    validationError: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.changeTrigger = this.changeTrigger.bind(this);
    this.handlePopupOpen = this.handlePopupOpen.bind(this);
    this.handlePopupClose = this.handlePopupClose.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.confirmTriggerChange = this.confirmTriggerChange.bind(this);
    this.rejectTriggerChange = this.rejectTriggerChange.bind(this);
    this.state = {
      isPopupOpen: false,
      isConfirmationModalOpen: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.pendingTrigger) {
      this.handleModalOpen();
    }
  }

  changeTrigger(e, option) {
    this.props.updateTriggerPreValidation(option);
    tracker.onEvent(trackerConstants.EVENT_TYPE_TRIGGER_SELECTED, {
      [trackerConstants.TRIGGER_SELECTED_ARGS_TRIGGER]: option.get(constants.NAME)
    });
    this.handlePopupClose(e);
  }

  handlePopupOpen() {
    this.setState({ isPopupOpen: true });
  }

  handlePopupClose(e) {
    if (e && e.target.className !== 'primary-only-toggle') {
      this.setState({ isPopupOpen: false });
    }
  }

  handleModalOpen() {
    this.setState({ isConfirmationModalOpen: true });
  }

  handleModalClose() {
    this.setState({ isConfirmationModalOpen: false });
  }

  confirmTriggerChange() {
    const trigger = this.props.pendingTrigger.get(constants.NAME);
    this.props.selectTrigger(trigger, true);
    tracker.onEvent(trackerConstants.EVENT_TYPE_TRIGGER_SELECTION_CONFIRMED, {
      [trackerConstants.TRIGGER_SELECTED_ARGS_TRIGGER]: trigger
    });
    this.handleModalClose();
  }

  rejectTriggerChange() {
    this.props.clearTempTrigger();
    this.handleModalClose();
  }

  renderTriggerMessage() {
    return this.props.selectedTrigger === activitySchemaConstants.TRIGGER_JOINED_CLUB ?
            <span className="hint join-program-condition">
              {this.props.intl.formatMessage({ id: 'activity.trigger.join-program-condition-hint' })}
            </span>
            : null;
  }

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const selectedTriggerKey = this.props.selectedTrigger ? `activity.triggers.${this.props.selectedTrigger}` : 'activity.triggers.initial';
    const triggerClassName = classNames('selected-option conditions-dropdown', {
      'dropdownFieldError': this.props.validationError,
      'placeholder': !this.props.selectedTrigger
    });
    const renderTriggerMessage = this.renderTriggerMessage();
    return (
      <Grid>
        <Grid.Row className="trigger-section">
          <Grid.Column>
            <PopupWrapper
              fixed
              basic
              on="click"
              className="triggers-popup with-top-border"
              onOpen={this.handlePopupOpen}
              onClose={this.handlePopupClose}
              open={this.state.isPopupOpen}
              position="bottom left"
              scrollToViewIfNeeded={!this.props.selectedTrigger}
              automationId="trigger.select.popup"
              trigger={
                <div className="inline-div-trigger">
                  <PopupTrigger
                    className={triggerClassName}
                    automationId="trigger.select"
                    message={formatMessage({ id: selectedTriggerKey })}
                  />
                  {renderTriggerMessage}
                </div>
              }
            >
              <TriggersList
                options={this.props.triggersList}
                selectedTrigger={this.props.selectedTrigger}
                onSelectOption={this.changeTrigger}
              />
            </PopupWrapper>
            {this.props.validationError ? (
              <ValidationErrorMessage
                errorLayoutType={commonComponents.COMPONENT_TYPE_TEXT}
                errorMessage={formatMessage({ id: this.props.validationError })}
                dataAutomationId={'activity.trigger.error.message'}
              />
            ) : null}
            <CustomModal
              automationIdPrefix={'activity.trigger.changeConfirmation'}
              open={this.state.isConfirmationModalOpen}
              onOpen={this.handleModalOpen}
              onClose={this.handleModalClose}
              closeOnDimmerClick={false}
              actions={[
                <a key={'cancel'} role="button" tabIndex={-1} onClick={this.rejectTriggerChange}>{formatMessage({ id: 'general.button.cancel' })}</a>,
                <Button key={'confirm'} onClick={this.confirmTriggerChange}>{formatMessage({ id: 'activity.trigger.confirmChange.confirm' })}</Button>
              ]}
              content={<p>{formatMessage({ id: 'activity.trigger.confirmChange.content' })}</p>}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default injectIntl(Trigger);
