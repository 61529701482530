import * as constants from '../../../../../activity.constants';

export function createReferralCodeTempModel(userActionIndex, conditionIndex) {
  return {
    type: constants.CREATE_REFERRAL_CODE_TEMP_MODEL,
    userActionIndex,
    conditionIndex
  };
}

export function deleteReferralCodeTempModel() {
  return {
    type: constants.DELETE_REFERRAL_CODE_TEMP_MODEL
  };
}

export function saveReferralCodeTempModelToGlobalConditions(userActionIndex, conditionIndex) {
  return {
    type: constants.SAVE_REFERRAL_CODE_TEMP_MODEL_TO_CONDITIONS,
    userActionIndex,
    conditionIndex
  };
}

