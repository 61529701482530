/**
*
* Capsules
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';
import classNames from 'classnames';
import _ from 'lodash';
import ValidationErrorMessage from '../ValidationErrorMessage/ValidationErrorMessage';

class Capsules extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    fluid: PropTypes.bool,
    index: PropTypes.number,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    prefix: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.array.isRequired,
  };

  state = {
    options: [],
    searchQuery: ''
  };

  componentWillMount() {
    this.setState({ options: this.props.value.map((opt) => ({ text: opt, value: opt })) || [] });
  }

  componentWillReceiveProps(nextProps) {
    // handle capsule deletion
    if (nextProps.value.length < this.props.value.length) {
      const options = this.state.options.reduce((newOptions, opt) => {
        if (!nextProps.value.includes(opt.value)) {
          return newOptions;
        }
        return [...newOptions, opt];
      }, []);
      this.setState({ options });
    }
  }

  /**
   * trim added value
   * ignore change if added value is empty
   * insert added value to values array and fire onChange event
   * @param e
   * @param value
   * @param name
   */
  handleAddition = (e, { value, name }) => {
    const trimmedValue = value.trim();
    if (trimmedValue.length === 0) {
      return;
    }
    this.setState({ options: [...this.state.options, { text: trimmedValue, value: trimmedValue }] }, () => {
      this.handleChange(e, { value: this.state.options.map((opt) => opt.value), name });
    });
  };

  /**
   * send component data to model
   * and empty searchQuery in state
   * @param e
   * @param data
   */
  handleChange = (e, data) => {
    this.props.onChange(e, data);
    this.setState({ searchQuery: '' });
  };

  /**
   * add search query value to options array, same as when pressing enter
   * (only if search query is not empty
   * @param e
   * @param data
   */
  handleBlur = (e, data) => {
    if (this.state.searchQuery.length) {
      this.handleAddition(e, { value: this.state.searchQuery, name: data.name });
    }
  };

  /**
   * write searchQuery to state so we can use it on blur
   * @param e
   * @param data
   */
  handleSearchChange = (e, data) => {
    this.setState({ searchQuery: data.searchQuery });
  };

  render() {

    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    // noinspection JSUnusedLocalSymbols
    const { error, index, intl, prefix, type, ...props } = this.props; // eslint-disable-line react/prop-types
    const errorMessage = error ? formatMessage({ id: error }) : '';
    const dropDownClassName = classNames(this.props.className, { capsules: true, inputFieldError: !_.isNil(error) });
    const placeHolder = this.props.placeholder ? this.props.placeholder : formatMessage({ id: 'activity.condition.conditionValue.placeholder.arrayExplanation' });
    return (
      <div className="dropdown-wrapper">
        <Dropdown
          {...props}
          allowAdditions
          className={dropDownClassName}
          data-automation-id={prefix}
          multiple
          name={this.props.name}
          onAddItem={this.handleAddition}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onSearchChange={this.handleSearchChange}
          options={this.state.options}
          placeholder={placeHolder}
          selection
          search
        />
        {error ? (<ValidationErrorMessage
          errorMessage={errorMessage}
          dataAutomationId={`${prefix}.error.message`}
        />) : null}
      </div>
    );
  }
}

export default injectIntl(Capsules);
