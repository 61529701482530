import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import moment from 'moment';
import { Button, Icon } from 'semantic-ui-react';
import * as datesConstants from '../../utils/dateTime.constants';
import '../../../features/Activity/views/conditions/dateTimeConditions/dateTimeRangeAndDaysHoursCondition.style.less';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import * as dateTimeUtils from '../../utils/dateTimeUtils';
import ValidationErrorMessage from '../ValidationErrorMessage/ValidationErrorMessage';
import * as appSelectors from '../../../features/App/selectors';
import HourAndMinute from '../HourAndMinute/HourAndMinute';

class DateTimePicker extends React.Component {

  static propTypes = {
    selectedDate: PropTypes.object,
    error: PropTypes.string,
    locale: PropTypes.string.isRequired,
    localeDateFormat: PropTypes.string.isRequired,
    localeTimeFormat: PropTypes.string.isRequired,
    updateDate: PropTypes.func.isRequired,
    dataAutomationId: PropTypes.string.isRequired,
    selectsStart: PropTypes.bool,
    selectsEnd: PropTypes.bool,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    topButtonsData: PropTypes.array,
    triggerMessageKey: PropTypes.string,
    businessTimezone: PropTypes.string.isRequired,
    datePickerInsideModal: PropTypes.bool
  };

  static defaultProps = {
    selectsStart: true,
    selectsEnd: false
  };

  componentWillMount() {
    dateTimeUtils.updateLocale(this.props.intl, this.props.locale); // eslint-disable-line react/prop-types
  }

  /**
   * this function receives timeData (hour, minute,format) in business timezone
   * and sets it to the selected date which is a moment object in business timezone received from a parent (ex: DateTimeRangeCondition, ScheduleGlobalCondition)
   * @param timeData
   */
  updateDateWithNewTime = (timeData) => {
    const updatedDate = dateTimeUtils.updateDateWithNewTime(this.props.selectedDate, timeData);
    this.props.updateDate(updatedDate);
  };

  momentToDate = (momentObject) => {
    if (momentObject && momentObject.isValid()) {
      return momentObject.toDate();
    }
    return null;
  };

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    const selectedDate = this.props.selectedDate;
    const is12HourFormat = this.props.localeTimeFormat.indexOf(datesConstants.AM_TIME) === -1;
    const dateTimeFormatMoment = `${this.props.localeDateFormat} ${this.props.localeTimeFormat}`;
    const timeFormat = this.props.localeTimeFormat.replace('A', 'aa');
    const dateTimeFormat = `${this.props.localeDateFormat.replace('DD', 'dd').replace('YYYY', 'yyyy')} ${timeFormat}`;
    const hour = dateTimeUtils.getHourByFormat(is12HourFormat, selectedDate);
    const minute = dateTimeUtils.getMinute(selectedDate);
    const format = dateTimeUtils.getTimeFormat(selectedDate, is12HourFormat);
    const className = classNames('date-picker-range-wrapper', { 'errors-inside-global-conditions-highlight': this.props.error });
    const triggerText = this.props.triggerMessageKey ? formatMessage({ id: this.props.triggerMessageKey }) : (selectedDate ? selectedDate.format(dateTimeFormatMoment) : null);

    return (
      <span className={className}>
        <CustomDatePicker
          data-automation-id={this.props.dataAutomationId}
          minTime={moment().toDate()}
          selectsStart={this.props.selectsStart}
          selectsEnd={this.props.selectsEnd}
          openToDate={this.momentToDate(selectedDate)}
          locale={this.props.locale}
          timeZone={this.props.businessTimezone}
          onChange={this.props.updateDate}
          selected={this.momentToDate(selectedDate)}
          dateFormat={dateTimeFormat}
          timeString={selectedDate ? selectedDate.format(this.props.localeTimeFormat) : null}
          shouldCloseOnSelect={false}
          dateFormatCalendar="MMMM"
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={20}
          customInput={
              (<Button basic className="link" data-automation-id={`${this.props.dataAutomationId}.input`}>
                <span>
                  {triggerText}
                </span>
                <Icon className="como-ic-date" />
              </Button>)
            }
          minDate={this.momentToDate(this.props.minDate)}
          maxDate={this.momentToDate(this.props.maxDate)}
          fixedHeight
          datePickerInsideModal={this.props.datePickerInsideModal}

        >
          <div className="container-hour-and-minute--wrapper">
            <div className="container-hour-and-minute-label">
              {formatMessage({ id: 'datePicker.selectedStart.time.at' })}
            </div>
            <HourAndMinute
              onChange={this.updateDateWithNewTime}
              isMilitary={is12HourFormat}
              hour={hour}
              minute={minute}
              format={format}
              prefix="globalConditions.schedule.time"
            />
          </div>
          {
              this.props.topButtonsData && this.props.topButtonsData.length > 0
                ?
                this.props.topButtonsData.map((topButton) => (
                  <Button
                    basic
                    className="link special-button"
                    onClick={topButton.callback}
                    data-automation-id={`${this.props.dataAutomationId}.${topButton.id}`}
                    key={topButton.id}
                  >
                    {formatMessage({ id: topButton.messageKey })}
                  </Button>
                ))
                : null
            }
        </CustomDatePicker>
        {
          this.props.error
            ? <ValidationErrorMessage
              errorMessage={formatMessage({ id: this.props.error })}
              dataAutomationId={`${this.props.dataAutomationId}.error.message`}
            /> : null
        }
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: appSelectors.getBrowserLocale(state),
  localeDateFormat: appSelectors.getBrowserLocaleDateFormat(state),
  localeTimeFormat: appSelectors.getBrowserLocaleTimeFormat(state),
  businessTimezone: appSelectors.getBusinessTimeZone(state),
});

export default connect(mapStateToProps)(injectIntl(DateTimePicker));
