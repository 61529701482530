import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment/min/moment-with-locales';
import * as numbersFormatUtils from '../../../utils/numbersFormat';
import {Icon} from "semantic-ui-react";
import {Tooltip} from "@como/web-hub-components";


class BarChartCustom extends React.PureComponent {
  static propTypes = {
    data: PropTypes.array.isRequired,
    chartWidth: PropTypes.number.isRequired,
    chartHeightDomain: PropTypes.number,
    maxGraphElemHeight: PropTypes.number,
    overallElemWidth: PropTypes.number,
    currencySymbol: PropTypes.string,
    localeDateFormat: PropTypes.string,
  };

  static defaultProps = {
    maxGraphElemHeight: 302,
    overallElemWidth: 50
  };

  componentDidMount() {
    this.handleScroll();
  }

  handleScroll() {
    setTimeout(() => {
      const scrollLeftPosition = this.props.chartWidth;
      if (this.scrollArea) {
        this.scrollArea.scrollLeft = scrollLeftPosition;
      }
    });
  }

  getElemHeightOnGraph = (value) => value > 0 ? Math.floor((value * this.props.maxGraphElemHeight) / this.props.chartHeightDomain) : 0;

  getElemLeftPosition = (index) => (this.props.overallElemWidth / 2) + (index * this.props.overallElemWidth);

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const currencySymbol = this.props.currencySymbol || '';
    let order = 0;
    return (
      <div
        className={`elems-wrap ${this.props.data.length > 8 ? 'with-scroll' : ''}`}
        ref={(ref) => {
          this.scrollArea = ref;
        }}
      >
        {
          this.props.data.map((elem, index) => {
            const tempOrder = order;
            if (elem.length === 1) {
              order += 1;
              const dateObject = moment(elem[0].weekDate);
              const dateObjectFormat = dateObject.format(this.props.localeDateFormat.split("/YYYY")[0]);
              const height = this.getElemHeightOnGraph(elem[0].y);
              const leftPosition = this.getElemLeftPosition(tempOrder);
              return (
                <div
                  key={`elem_${(tempOrder).toString()}`}
                  className="chartElemWrap"
                  style={{left: `${leftPosition}px`}}
                >
                  {elem[0].status === "sent" ?
                    <div
                      className="chartElem"
                      style={{height: `${height}px`}}
                      title={`${currencySymbol}${numbersFormatUtils.displayNumberWithCommas(elem[0].y)}`}
                    >
                  <span title={`${currencySymbol}${numbersFormatUtils.displayNumberWithCommas(elem[0].y)}`}>
                    {`${currencySymbol}${numbersFormatUtils.formatByValue(elem[0].y, 1)} `}
                  </span>
                    </div> :
                    <div>
                      <Tooltip
                        data-automation-id={`como_alert_tooltip`}
                        content={elem[0].reason || formatMessage({id: "unknown_reason"})}
                        position="top center"
                        wide
                        trigger={
                          <div className={'como_alert_tooltip'} data-automation-id={`como_alert_tooltip.trigger`}>
                            <Icon className="como-svg-alert"/>
                          </div>
                        }
                      />
                      <div className={'fail-left fail'}></div>
                    </div>
                  }
                  <div className="label">{dateObjectFormat || elem[0].x}</div>

                </div>
              );
            }
            const length = elem.length;
            const middle = Number.parseInt(length / 2);
            return (
              elem.map((e, i) => {
                const innerTempOrder = order;
                order += 1;
                const dateObject1 = moment(e.weekDate);
                const dateObjectFormat1 = dateObject1.format(this.props.localeDateFormat.split("/YYYY")[0]);
                const leftPosition1 = this.getElemLeftPosition(innerTempOrder);
                return (
                  <div
                    key={`elem_${innerTempOrder.toString()}`}
                    className="chartElemWrap"
                    style={{left: `${leftPosition1}px`}}
                  >
                    <div>
                      <Tooltip
                        className={'micro_campaign_tooltip'}
                        data-automation-id={`como_alert_tooltip`}
                        content={formatMessage({id: 'comillia.micro_campaign.' + e.reason}) || formatMessage({id: 'comillia.micro_campaign.unknown'})}
                        position="top center"
                        wide
                        trigger={
                          <div className={'como_alert_tooltip'} data-automation-id={`como_alert_tooltip.trigger`}>
                            {(i === middle) &&
                            <Icon
                              style={length % 2 === 0 ? {right: '25px'} : {right: '0'}}
                              className={'como-svg-alert'}
                            />}
                          </div>
                        }
                      />
                      <div
                        className={'fail-center ' + (i === 0 ? 'fail-left fail' : i === length - 1 ? 'fail-right fail' : 'fail empty')}
                      >
                      </div>
                    </div>

                    <div className="label">{(i === 0 || i === (length - 1)) ? (dateObjectFormat1 || e.x) : '...'}
                    </div>
                  </div>
                );
              })
            )
              ;
          })
        }
      </div>
    );
  }
}

export default injectIntl(BarChartCustom);

