/*

  Modal Component

  This component overrides some functionallity of Semantic UI React modal.
  Props:
    header{string}        -  required
    actions{array}        -  required (should be array of buttons)
    trigger{node}         -  required (should be any component that will trigger the modal)
    content{node}         -  required (should be any component with content)
    size{string|number}   -  required (fullscreen/large/mini/small/tiny or width in pixels)

  Use example:
    <Modal
      header={'This is Sparta'}
      content={
        <div>
          some content
        </div>
      }
      actions={[
        <Button basic data-automation-id="modal-btn1">
          <Icon name="remove" /> No
        </Button>,
        <Button color="green" data-automation-id="modal-btn2">
          <Icon name="checkmark" /> Yes
        </Button>
      ]}
      trigger={<Button>Open Modal</Button>}
    />

*/

import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

const AUTOMATION_PREFIX = 'modal';

class CustomModal extends React.PureComponent {
  static propTypes = {
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    actions: PropTypes.array.isRequired,
    content: PropTypes.node.isRequired,
    trigger: PropTypes.node, // TODO: why not required
    automationIdPrefix: PropTypes.string.isRequired,
    size: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    className: PropTypes.string
  };

  static defaultProps = {
    header: '',
    size: 'small'
  };

  render() {
    const { header, actions, trigger, content, size, automationIdPrefix, ...modalProps } = this.props;
    return (
      <Modal
        className={this.props.className}
        data-automation-id={`${AUTOMATION_PREFIX}.${automationIdPrefix}`}
        trigger={trigger}
        size={typeof (size) === 'string' ? size : 'large'}
        style={{ width: `${size}px` }}
        closeIcon
        closeOnDimmerClick={false}
        // dimmer="blurring"
        {...modalProps}
      >
        {header && <Modal.Header>{header}</Modal.Header>}
        <Modal.Content>{content}</Modal.Content>
        {actions && <Modal.Actions actions={actions} />}
      </Modal>
    );
  }
}

export default CustomModal;
