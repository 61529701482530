import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Loader } from 'semantic-ui-react';
import AutoDirectionProvider from 'react-with-direction/dist/AutoDirectionProvider';
import { List } from 'immutable';
import * as _ from 'lodash';

import * as datetimeUtils from '../../reducers/utils/activity.datetime.reducer.utils';
import * as pointsInputField from '../../../../common/components/FormInputFields/PointsInputField/PointsInputField';

import * as dateTimeConstants from '../../../../common/utils/dateTime.constants';
import * as schemaConstants from '../../activitySchema/activitySchema.constants';
import * as benefitConstants from '../../../Benefits/benefits.constants';
import * as constants from '../../activity.constants';
import * as appConstants from '../../../App/app.constants';
import * as formsConstants from '../../../Forms/forms.constants';
import * as itemsPopulationConstants from '../itemsPopulation/itemsPopulation.constants';
import * as updateMembershipConstants from '../conditions/updateMembershipCondition/updateMembership.constants';

import * as itemsPopulationUtils from '../itemsPopulation/itemsPopulation.utils';
import * as benefitsSelectors from '../../../Benefits/benefits.selector';
import * as formsListActions from '../../../Forms/forms.actions';
import * as formsSelectors from '../../../Forms/forms.selectors';
import * as appSelectors from '../../../App/selectors';
import * as itemGroupsSelectors from '../../../ItemGroups/itemGroups.selectors';
import * as benefitsActions from '../../../Benefits/benefits.actions';
import * as itemsPopulationActions from '../itemsPopulation/itemsPopulation.actions';
import * as itemGroupsActions from '../../../ItemGroups/itemGroups.actions';
import { calculateDisplayValue } from '../../../../common/components/FormInputFields/CurrencyInputField/CurrencyInputField';
import { arrayOperators } from '../../activitySchema/activitySchema.operators';

class ConditionSummary extends React.PureComponent {

  static propTypes = {
    actions: PropTypes.object.isRequired,
    benefits: PropTypes.object,
    benefitsFetched: PropTypes.bool,
    condition: PropTypes.object.isRequired,
    conditionsFromSchema: PropTypes.object.isRequired,
    conditionValueOnly: PropTypes.bool,
    decimalPrecision: PropTypes.number.isRequired,
    formsList: PropTypes.object.isRequired,
    formsListFetched: PropTypes.bool,
    itemGroups: PropTypes.object,
    intl: PropTypes.object.isRequired,
    itemGroupsFetched: PropTypes.bool,
    localeSettings: PropTypes.object,
    isoCurrencySymbol: PropTypes.string,
    abTestMode: PropTypes.bool,
    locationsGroups: PropTypes.object
  };

  componentDidMount() {
    const { condition } = this.props;
    if (condition) {
      const conditionKey = condition.get(constants.CONDITION_KEY);
      const conditionMetadata = this.props.conditionsFromSchema.find((x) => x.get(constants.KEY) === conditionKey);
      if (conditionMetadata) {
        const valueType = conditionMetadata.get(constants.VALUE_TYPE);
        if (valueType === schemaConstants.VALUE_TYPE_BENEFITS_LIST && !this.props.benefitsFetched) {
          // noinspection JSUnresolvedFunction
          this.props.actions.getBenefits();
        } else if (valueType === schemaConstants.VALUE_TYPE_FORMS_LIST && !this.props.formsListFetched) {
          // noinspection JSUnresolvedFunction
          this.props.actions.getFormsList();
        }
        const itemsPopulation = condition.get(constants.ITEMS_POPULATION);
        if (itemsPopulation && !this.props.itemGroupsFetched) {
          // noinspection JSUnresolvedFunction
          this.props.actions.getItemGroups();
        }
      }
    }
  }

  getItemsPopulation() {
    const { itemGroups, itemGroupsFetched, condition, intl } = this.props;
    const itemsPopulation = condition.get(constants.ITEMS_POPULATION);
    if (itemsPopulation) {
      if (!itemGroupsFetched) {
        return null;
      } else if (itemsPopulation.get(constants.TYPE) === itemsPopulationConstants.ITEMS_POPULATION_TYPE_ITEM_CODES &&
        (!itemsPopulation.getIn([constants.CONDITIONS, constants.CONDITIONS_LIST]) ||
          itemsPopulation.getIn([constants.CONDITIONS, constants.CONDITIONS_LIST]).size === 0)) {
        return '';
      }
      return itemsPopulationUtils.getItemsPopulationDisplayList(itemsPopulation.toJS(), itemGroups, intl.formatMessage);
    }
    return '';
  }

  getConditionKeyMessage(conditionKey, conditionText) {
    return conditionText || this.props.intl.formatMessage({ id: `activity.cases.summary.condition.${conditionKey}` });
  }

  getOperatorKey = (operatorValue, conditionMetadata) => {
    if (operatorValue === schemaConstants.OPERATORS_UPDATE_MEMBERSHIP) {
      return operatorValue;
    }
    const schemaOperator = conditionMetadata.get(constants.SCHEMA_CONDITION_OPERATORS)
      .find((operator) => operator.get(constants.VALUE) === operatorValue);
    return schemaOperator ? schemaOperator.get(constants.KEY) : operatorValue;
  };

  formatConditionValue(condition, conditionMetadata) {
    const rawValue = condition.get(constants.CONDITION_VALUE);
    let value = null;
    if (rawValue != null) {
      value = this.parseConditionValueByType(conditionMetadata.get(constants.VALUE_TYPE), condition);
    } else if ([schemaConstants.OPERATORS_IS_ANY_VALUE, schemaConstants.OPERATORS_IS_EMPTY].includes(condition.get(constants.OPERATOR_KEY))) {
      value = this.props.intl.formatMessage({ id: `operator.memberFieldChanged.${condition.get(constants.OPERATOR_KEY)}` });
    }
    return value;
  }

  parseConditionValueByType = (valueType, condition) => {

    const { decimalPrecision, localeSettings, benefits, benefitsFetched, formsList, formsListFetched, intl, isoCurrencySymbol, conditionValueOnly } = this.props;
    let value = null;
    const timeFormat = localeSettings.get(appConstants.TIME_FORMAT);
    const dateFormat = localeSettings.get(appConstants.DATE_FORMAT);
    const rawValue = condition.get(constants.CONDITION_VALUE);
    const valuesDelimiter = conditionValueOnly ? ' or ' : ', ';
    switch (valueType) {
      case schemaConstants.VALUE_TYPE_POINTS:
        value = pointsInputField.getDisplayValueByPrecision(rawValue, decimalPrecision);
        break;
      case schemaConstants.VALUE_TYPE_BOOLEAN:
        value = intl.formatMessage({ id: `boolean.${rawValue}` });
        break;
      case schemaConstants.VALUE_TYPE_ARRAY:
        value = rawValue ? rawValue.join(valuesDelimiter) : rawValue;
        break;
      case schemaConstants.VALUE_TYPE_CURRENCY:
        value = intl.formatNumber(rawValue ? calculateDisplayValue(rawValue) : null, { style: 'currency', minimumFractionDigits: 2, currency: isoCurrencySymbol });
        break;
      case schemaConstants.VALUE_TYPE_DATE_TIME:
        value = datetimeUtils.getDateTimeRangeConditionValueAsString(condition, intl, `${timeFormat} ${dateFormat}`);
        break;
      case schemaConstants.VALUE_TYPE_DATE:
        value = datetimeUtils.getDateConditionValueAsString(condition, dateFormat);
        break;
      case schemaConstants.VALUE_TYPE_DAYS_TIMES: {
        const daysConditions = datetimeUtils.getDaysConditions(condition, intl, localeSettings.get(appConstants.BROWSER_LOCALE), timeFormat);
        value = daysConditions.length ?
          daysConditions.join(valuesDelimiter) :
          intl.formatMessage({ id: 'dateTimeGlobalConditions.atAnyDateAndTime' });
        break;
      }
      case schemaConstants.VALUE_TYPE_BENEFITS_LIST: {
        if (!benefitsFetched) {
          value = <Loader size="tiny" active inline />;
        } else {
          const selectedAsset = benefitsSelectors.getSelectedBenefitFromList(benefits, rawValue);
          if (!selectedAsset) {
            value = '';
          } else {
            value = selectedAsset.get(benefitConstants.BENEFIT_TITLE);
          }
        }
        break;
      }
      case schemaConstants.VALUE_TYPE_STRING:
      case schemaConstants.VALUE_TYPE_NUMBER:
        value = rawValue;
        break;
      case schemaConstants.VALUE_TYPE_PAYMENT_FLOW:
      case schemaConstants.VALUE_TYPE_PAYMENT_FLOW_IN_APP:
        value = rawValue ? intl.formatMessage({ id: `activity.cases.summary.condition.context.paymentFlow.${rawValue}` }) : null;
        break;
      case schemaConstants.VALUE_TYPE_BEACON_SIGNALS_LIST:
        value = rawValue ? intl.formatMessage({ id: `activity.cases.summary.condition.context.beaconSignalTypes.${rawValue}` }) : null;
        break;
      case schemaConstants.VALUE_TYPE_FORMS_LIST: {
        if (!formsListFetched) {
          return <Loader size="tiny" active inline />;
        }
        const selectedFormName = formsList.find((form) => form.get(formsConstants.FORM_KEY) === rawValue);
        value = selectedFormName ? <span>{selectedFormName.get(formsConstants.FORM_TITLE)}</span> : null;
        break;
      }
      case schemaConstants.VALUE_TYPE_MONTHS_LIST: {
        value = rawValue.map((v) => this.formatMonth(v)).join(valuesDelimiter);
        break;
      }
      case schemaConstants.VALUE_TYPE_MEMBERSHIP_RFM_LIST: {
        value = rawValue.map((v) => this.formatRfmTag(v)).join(valuesDelimiter);
        break;
      }
      case schemaConstants.VALUE_TYPE_MEMBERSHIP_LAST_VISIT_LIST: {
        value = rawValue.map((v) => this.formatLastVisitTag(v)).join(valuesDelimiter);
        break;
      }

      case schemaConstants.VALUE_TYPE_LOCATIONS: {
        value = rawValue.map((v) => this.formatLocations(v, condition.get(constants.CONDITION_KEY))).join(valuesDelimiter);
        break;
      }
      case schemaConstants.VALUE_TYPE_MEMBERSHIP_STATUS_LIST: {
        if (!_.isNil(rawValue) && rawValue.size > 0) {
          value = rawValue.map((singleValue) => this.props.intl.formatMessage({ id: `activity.globalConditions.memberStatus.${singleValue}` })).join(valuesDelimiter);
        } else if (!_.isNil(rawValue) && typeof rawValue === 'object' && Array.isArray(rawValue)) {
          value = new List(rawValue).map((singleValue) => this.props.intl.formatMessage({ id: `activity.globalConditions.memberStatus.${singleValue}` })).join(valuesDelimiter);
        } else {
          value = '';
        }
        break;
      }
      case schemaConstants.VALUE_TYPE_MEMBERSHIP_CONSENT_LIST: {
        if (!_.isNil(rawValue) && (rawValue.size > 0 || rawValue.length > 0)) {
          value = rawValue.map((singleValue) => this.props.intl.formatMessage({ id: `activity.globalConditions.consentValue.${singleValue}` })).join(valuesDelimiter);
        } else {
          value = '';
        }
        break;
      }
      case schemaConstants.VALUE_TYPE_DYNAMIC: {
        if (Array.isArray(rawValue) || List.isList(rawValue)) {
          value = rawValue.join(valuesDelimiter);
        } else {
          value = rawValue;
        }
        break;
      }
      case schemaConstants.VALUE_TYPE_UPDATE_MEMBERSHIP_OPERATION: {
        const boolValue = rawValue === schemaConstants.OPERATION_VALUE_JOIN_CLUB;
        value = intl.formatMessage({ id: `boolean.${boolValue}` });
        break;
      }
      case schemaConstants.VALUE_TYPE_PERCENTAGE_INTEGER: {
        value = `${rawValue} %`;
        break;
      }
      case schemaConstants.DATA_VALUE_TYPE_LOCATIONS_GROUPS: {
        if (rawValue && rawValue.size > 0) {
          const locationsGroupNames = [];
          this.props.locationsGroups.forEach((locationsGroup) => {
            if (rawValue.includes(locationsGroup.get(constants.LOCATIONS_GROUP_Id))) {
              locationsGroupNames.push(locationsGroup.get(constants.LOCATIONS_GROUP_NAME));
            }
          });
          value = locationsGroupNames.join(', ');
        } else {
          value = '';
        }
        break;
      }
      default:
        console.warn('Condition value type is not parsed at cases summary!');
        value = <span>{rawValue}</span>;
        break;
    }
    const itemsPopulation = this.getItemsPopulation();
    if (itemsPopulation) {
      return (<span>{value}&nbsp;{itemsPopulation !== null ? itemsPopulation : <Loader size="tiny" active inline />}</span>);
    }
    return value;
  };

  formatUpdateMembershipCondition = (conditionValue, conditionMetadata) => {
    const { intl } = this.props;
    return (
      `${intl.formatMessage({ id: 'activity.updateMembershipCondition.changedFrom' })} ` +
      `${this.formatConditionValue(conditionValue.get(updateMembershipConstants.UPDATE_MEMBERSHIP_FROM), conditionMetadata)} ` +
      `${intl.formatMessage({ id: 'activity.updateMembershipCondition.changedTo' })} ` +
      `${this.formatConditionValue(conditionValue.get(updateMembershipConstants.UPDATE_MEMBERSHIP_TO), conditionMetadata)}`
    );
  };

  formatMonth(rawValue) {
    const monthName = Object.keys(schemaConstants.MONTHS).find((key) => schemaConstants.MONTHS[key] === rawValue);
    return monthName ? this.props.intl.formatMessage({ id: `activity.conditions.months.${monthName.toLowerCase()}` }) : null;
  }

  formatRfmTag(rawValue) {
    const rfmTag = Object.keys(schemaConstants.RFM_TAGS).find((key) => schemaConstants.RFM_TAGS[key] === rawValue);
    return rfmTag ? this.props.intl.formatMessage({ id: `activity.conditions.rfm.${rfmTag.toLowerCase()}` }) : null;
  }

  formatLastVisitTag(rawValue) {
    const lastVisitTag = Object.keys(schemaConstants.TIME_SINCE_LAST_VISIT_TAGS).find((key) => schemaConstants.TIME_SINCE_LAST_VISIT_TAGS[key] === rawValue);
    return lastVisitTag ? this.props.intl.formatMessage({ id: `activity.conditions.LastVisitTag.${lastVisitTag.toLowerCase()}` }) : null;
  }

  formatLocations(rawValue, conditionKey) {
    const tag = conditionKey === schemaConstants.CONTEXT_MEMBERSHIP_PREFERRED_LOCATION ? schemaConstants.LOCATIONS_TAG_PREFERRED : schemaConstants.LOCATIONS_TAG_SECOND_PREFERRED;
    return rawValue.slice(rawValue.indexOf(tag) + tag.length, rawValue.lastIndexOf(schemaConstants.LOCATIONS_TAG_BORDER));
  }

  render() {
    const { condition, conditionsFromSchema, conditionValueOnly, intl } = this.props;
    if (!condition) {
      return null;
    }
    const conditionKey = condition.get(constants.CONDITION_KEY);
    const operatorValue = condition.get(constants.OPERATOR_KEY);
    const conditionValue = condition.get(constants.CONDITION_VALUE);
    const conditionMetadata = conditionsFromSchema.find((x) => x.get(constants.KEY) === conditionKey);
    if (!conditionMetadata) {
      return null;
    }
    const conditionText = this.props.abTestMode ? intl.formatMessage({ id: 'activity.cases.conditionsPrefix.abTest' }) : conditionMetadata.get(constants.TEXT);
    const conditionKeyMessage = this.getConditionKeyMessage(conditionKey, conditionText);
    const operatorKey = this.getOperatorKey(operatorValue, conditionMetadata);
    let operatorKeyMessageId = `operator.${operatorKey}`;
    if (operatorKey === schemaConstants.OPERATORS_UPDATE_MEMBERSHIP ||
      operatorKey === dateTimeConstants.DATE_TIME_IS_AFTER ||
      operatorKey === dateTimeConstants.DAY_OF_WEEK ||
      operatorKey === dateTimeConstants.DATE_TIME_IS_BETWEEN) {
      operatorKeyMessageId = '';
    } else if (
        arrayOperators.some((operator) => operator[constants.KEY] === operatorKey)
        && conditionValue && (conditionValue.size === 1 || conditionValue.length === 1)) {
      if (operatorKey === schemaConstants.OPERATOR_KEY_IS_ONE_OF) {
        operatorKeyMessageId = `operator.${schemaConstants.OPERATOR_KEY_EQUALS}`;
      } else if (operatorKey === schemaConstants.OPERATOR_KEY_IS_NOT_ONE_OF) {
        operatorKeyMessageId = `operator.${schemaConstants.OPERATOR_KEY_NOT_EQUALS}`;
      }
    }
    let formattedConditionValue;
    if (operatorValue === schemaConstants.OPERATORS_UPDATE_MEMBERSHIP) {
      formattedConditionValue = this.formatUpdateMembershipCondition(conditionValue, conditionMetadata);
    } else {
      formattedConditionValue = this.formatConditionValue(condition, conditionMetadata);
    }
    let translationValue = '';
    if (operatorKeyMessageId !== '') {
      translationValue = intl.formatMessage({ id: operatorKeyMessageId });
    }

    if (conditionValueOnly) {
      if (formattedConditionValue) {
        const negativeOperators = [
          schemaConstants.OPERATOR_KEY_NOT_EQUALS,
          schemaConstants.OPERATOR_KEY_IS_NOT_ONE_OF,
          constants.IS_NOT_ONE_OF
        ];
        const formattedOperatorValue = negativeOperators.includes(operatorKey)
          ? `${intl.formatMessage({ id: 'operator.general.negative' })} `
          : '';
        return `${formattedOperatorValue} ${formattedConditionValue}`;
      }
      return '';
    }

    return (<AutoDirectionProvider text={translationValue}><span>
      {conditionKeyMessage}&nbsp;
      {operatorKeyMessageId && translationValue}
      {operatorKeyMessageId && ' '}
      {formattedConditionValue}
    </span></AutoDirectionProvider>);
  }
}

const mapStateToProps = (state) => ({
  benefits: benefitsSelectors.getBenefitsList(state),
  benefitsFetched: benefitsSelectors.getBenefitsListFetchedFlag(state),
  formsList: formsSelectors.getFormsList(state),
  formsListFetched: formsSelectors.getFormsListFetchedFlag(state),
  decimalPrecision: appSelectors.getDecimalPrecision(state),
  itemGroups: itemGroupsSelectors.getItemGroupsList(state),
  itemGroupsFetched: itemGroupsSelectors.getItemGroupsListFetchedFlag(state),
  localeSettings: appSelectors.getBrowserLocaleSettings(state),
  isoCurrencySymbol: appSelectors.getLocationIsoCurrencySymbol(state),
  locationsGroups: appSelectors.getLocationsGroups(state),
});

function mapDispatchToProps(dispatch) {
  const creators = {
    ...benefitsActions,
    ...itemGroupsActions,
    ...itemsPopulationActions,
    ...formsListActions,
  };
  return { actions: bindActionCreators(creators, dispatch) };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, injectIntl)(ConditionSummary);

