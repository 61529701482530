/**
 *
 * TabbedActivityContent
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import ActivityBody from '../ActivityBody';
import * as activityConstants from '../../activity.constants';
import DisplayTab from './DisplayTab';
import TermsTab from './TermsTab';
import PromoCodesCodeTab from './PromoCodesCodeTab';
import PunchCardDefinitionTab from './PunchCardDefinitionTab';
import PunchCardCommunicationTab from './PunchCardCommunicationTab';
import ChildActivitiesIds from './ChildActivitiesIds';
import './tabbedContent.styles.less';
import * as actions from '../../activity.actions';
import { getImagesBySize } from '../../../Images/images.actions';
import * as punchCardActions from './punchCard.actions';
import * as selectors from '../../activity.selectors';
import * as appSelectors from '../../../App/selectors';
import * as appConstants from '../../../App/app.constants';
import * as imageSelectors from '../../../Images/images.selectors';
import * as imagesConstants from '../../../Images/images.constants';
import injectSaga from '../../../../utils/injectSaga';
import * as reducerConstants from '../../../../constants/reducer.constants';
import imagesSaga from '../../../Images/images.saga';
import LandingPageTabContent from './LandingPageTabContent';

class TabbedActivityContent extends React.PureComponent {

  static propTypes = {
    actions: PropTypes.object,
    actionsMode: PropTypes.string,
    activeTab: PropTypes.string,
    activityActionsWrappers: PropTypes.object,
    activityConditions: PropTypes.object,
    activityDateTimeGlobalConditions: PropTypes.object,
    activityDescription: PropTypes.string,
    activityName: PropTypes.string,
    activityPrefix: PropTypes.string,
    activityTrigger: PropTypes.string,
    activityType: PropTypes.string,
    activityValidationErrors: PropTypes.object,
    allowOccurrences: PropTypes.bool,
    autoRenew: PropTypes.bool,
    autoRenewTrigger: PropTypes.string,
    bundle: PropTypes.object,
    cases: PropTypes.object,
    casesActions: PropTypes.object,
    casesMode: PropTypes.bool,
    defaultPreviewImage: PropTypes.object,
    defaultStampImage: PropTypes.object,
    disabledGlobalConditions: PropTypes.object,
    displayDescription: PropTypes.string,
    displayName: PropTypes.string,
    features: PropTypes.object,
    displayTabValidationErrors: PropTypes.object,
    headerMsgId: PropTypes.string,
    hiddenGlobalConditions: PropTypes.object,
    images: PropTypes.object,
    interactiveType: PropTypes.string,
    punchCommunicationRules: PropTypes.object,
    schemaConditionsPerTrigger: PropTypes.object,
    schemaDateTimeConditions: PropTypes.object,
    schemaMembershipConditions: PropTypes.object,
    schemaActionsPerTrigger: PropTypes.object,
    selectedBackgroundImage: PropTypes.object,
    selectedPreviewImage: PropTypes.object,
    selectedStampImage: PropTypes.object,
    sendOnPurchaseRule: PropTypes.object,
    showRedeemCode: PropTypes.bool,
    landingPageData: PropTypes.object,
    landingPageBaseUrl: PropTypes.string,
    landingPageViewDirection: PropTypes.string,
    isActivityDraft: PropTypes.bool,
    showSplit: PropTypes.bool,
    locationId: PropTypes.number,
    locationDisplayName: PropTypes.string,
    legalSectionText: PropTypes.string,
    legalSectionTextWithLocationTerms: PropTypes.string,
    legalSettings: PropTypes.object,
    getBusinessBundlePlan: PropTypes.func.isRequired,
  };

  componentWillMount() {
    if (!this.props.images) {
      this.props.actions.getImagesBySize(imagesConstants.IMAGE_SIZE_THUMBNAIL);
    }
  }

  parseLegalLinks(legalText, legalLinks) {
    return Object.keys(legalLinks || {})
      .reduce((replaced, key) => replaced.replace(`{{${key}}}`, legalLinks[key]), legalText);
  }

  get tabContent() {
    const {
      promoCodeType,
      location,
      actionsMode,
      activeTab,
      activityActionsWrappers,
      activityConditions,
      activityDateTimeGlobalConditions,
      activityDescription,
      activityName,
      activityPrefix,
      activityTrigger,
      activityType,
      activityValidationErrors,
      allowOccurrences,
      autoRenew,
      autoRenewTrigger,
      bundle,
      cases,
      casesActions,
      casesMode,
      defaultPreviewImage,
      defaultStampImage,
      disabledGlobalConditions,
      displayDescription,
      displayName,
      displayTabValidationErrors,
      features,
      headerMsgId,
      hiddenGlobalConditions,
      interactiveType,
      images,
      punchCommunicationRules,
      schemaActionsPerTrigger,
      schemaConditionsPerTrigger,
      schemaDateTimeConditions,
      schemaMembershipConditions,
      selectedBackgroundImage,
      selectedPreviewImage,
      selectedStampImage,
      sendOnPurchaseRule,
      showRedeemCode,
      legalSectionText,
      legalSectionTextWithLocationTerms,
      legalSettings,
      getBusinessBundlePlan,
      showSplit
    } = this.props;
    const legalContent = legalSettings.links.club_terms_url ? legalSectionTextWithLocationTerms : legalSectionText;
    const legalLinksHtml = legalSectionText || legalSectionTextWithLocationTerms ? this.parseLegalLinks(legalContent, legalSettings.links) : undefined;
    switch (activeTab) {
      case activityConstants.PROMO_CODE_TAB_CODES:
        return (
          <PromoCodesCodeTab
            activityActionsWrappers={activityActionsWrappers}
            activityPrefix={activityPrefix}
            activityType={activityType}
            features={features}
            validationErrors={activityValidationErrors}
            location={location}
            promoCodeType={promoCodeType}
          />
        );
      case activityConstants.ACTIVITY_TAB_DEFINITION:
        return (
          <PunchCardDefinitionTab
            activityActionsWrappers={activityActionsWrappers}
            activityPrefix={activityPrefix}
            activityType={activityType}
            features={features}
            validationErrors={activityValidationErrors}
            cases={cases}
            casesActions={casesActions}
            casesMode={casesMode}
            getBusinessBundlePlan={getBusinessBundlePlan}
          />
        );
      case activityConstants.ACTIVITY_TAB_COMMUNICATION:
        return (
          <PunchCardCommunicationTab
            activityPrefix={activityPrefix}
            features={features}
            punchCommunicationRules={punchCommunicationRules}
          />
        );
      case activityConstants.ACTIVITY_TAB_DISCOUNT:
        return (
          <ActivityBody
            actions={this.props.actions}
            actionsMode={actionsMode}
            activityActionsWrappers={activityActionsWrappers}
            activityConditions={activityConditions}
            activityDateTimeGlobalConditions={activityDateTimeGlobalConditions}
            activityPrefix={activityPrefix}
            activityTrigger={activityTrigger}
            activityType={activityType}
            allowOccurrences={allowOccurrences}
            bundle={bundle}
            cases={cases}
            casesActions={casesActions}
            casesMode={casesMode}
            features={features}
            headerMsgId={headerMsgId}
            schemaActionsPerTrigger={schemaActionsPerTrigger}
            schemaConditionsPerTrigger={schemaConditionsPerTrigger}
            schemaDateTimeConditions={schemaDateTimeConditions}
            schemaMembershipConditions={schemaMembershipConditions}
            validationErrors={activityValidationErrors}
            getBusinessBundlePlan={getBusinessBundlePlan}
            showSplit={showSplit}
          />
        );
      case activityConstants.ACTIVITY_TAB_TERMS:
        return (
          <TermsTab
            actions={this.props.actions}
            activityConditions={activityConditions}
            activityDateTimeGlobalConditions={activityDateTimeGlobalConditions}
            activityPrefix={activityPrefix}
            activityTrigger={activityTrigger}
            activityType={activityType}
            activityValidationErrors={activityValidationErrors}
            autoRenew={autoRenew}
            autoRenewTrigger={autoRenewTrigger}
            disabledGlobalConditions={disabledGlobalConditions}
            headerMsgId={headerMsgId}
            hiddenGlobalConditions={hiddenGlobalConditions}
            schemaConditionsPerTrigger={schemaConditionsPerTrigger}
            schemaMembershipConditions={schemaMembershipConditions}
            sendOnPurchaseRule={sendOnPurchaseRule}
            getBusinessBundlePlan={getBusinessBundlePlan}
          />
        );
      case activityConstants.ACTIVITY_TAB_DISPLAY:
        return (
          <DisplayTab
            actions={this.props.actions}
            activityDescription={activityDescription}
            activityName={activityName}
            activityPrefix={activityPrefix}
            activityType={activityType}
            defaultImage={defaultPreviewImage}
            defaultStampImage={defaultStampImage}
            displayDescription={displayDescription}
            displayName={displayName}
            selectedBackgroundImage={selectedBackgroundImage}
            selectedPreviewImage={selectedPreviewImage}
            selectedStampImage={selectedStampImage}
            showRedeemCode={showRedeemCode}
            validationErrors={displayTabValidationErrors}
            interactiveType={interactiveType}
            images={images}
          />
        );
      case activityConstants.ACTIVITY_TAB_LANDING_PAGE:
        return (
          <LandingPageTabContent
            actions={this.props.actions}
            activityPrefix={activityPrefix}
            activityType={activityType}
            headerMsgId={'landingpage.tab.title'}
            isActivityDraft={this.props.isActivityDraft}
            landingPageData={this.props.landingPageData}
            landingPageBaseUrl={this.props.landingPageBaseUrl}
            landingPageViewDirection={this.props.landingPageViewDirection}
            locationId={this.props.locationId}
            locationDisplayName={this.props.locationDisplayName}
            activityName={this.props.activityName}
            legalSectionText={legalLinksHtml}
          />
        );
      default:
        return null;
    }
  }

  render() {
    return (
      <div className={'tabbed-content'}>
        {this.tabContent}
        {
          this.props.activityType === activityConstants.ACTIVITY_TYPE_PUNCH_CARD &&
          <ChildActivitiesIds/>
        }
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  const creators = {
    ...actions,
    ...punchCardActions,
    getImagesBySize
  };
  return { actions: bindActionCreators(creators, dispatch) };
}

const mapStateToProps = (state) => ({
  activeTab: selectors.getActiveTab(state),
  autoRenew: selectors.getPunchCardAutoRenew(state),
  autoRenewTrigger: selectors.getPunchCardRenewTrigger(state),
  defaultPreviewImage: imageSelectors.getDefaultImageThumbnail(state),
  defaultStampImage: imageSelectors.getDefaultStampImageThumbnail(state),
  displayDescription: selectors.getDisplayDescription(state),
  displayName: selectors.getDisplayName(state),
  disabledGlobalConditions: selectors.getDisabledGlobalConditionsForSelectedTrigger(state),
  images: imageSelectors.getAllActiveImageThumbnails(state),
  interactiveType: selectors.getInteractiveType(state),
  punchCommunicationRules: selectors.getCommunicationRules(state),
  selectedBackgroundImage: selectors.getSelectedBackgroundImage(state),
  selectedPreviewImage: selectors.getSelectedPreviewImage(state),
  selectedStampImage: selectors.getSelectedStampImage(state),
  sendOnPurchaseRule: selectors.getRelatedSendOnExpiraionRule(state),
  showRedeemCode: selectors.getShowRedeemCode(state),
  landingPageBaseUrl: selectors.getLandingPageBaseUrl(state),
  landingPageViewDirection: selectors.getLandingPageViewDirection(state),
  isActivityDraft: selectors.getIsActivityDraft(state),
  locationId: appSelectors.getLocationId(state),
  locationDisplayName: appSelectors.getBusinessDisplayName(state),
  promoCodeType: selectors.getPromoCodeType(state),
  legalSectionTextWithLocationTerms: appSelectors.getSsrTranslationSelector(state, appConstants.LEGAL_TEXT_WITH_BUSINESS_TERMS),
  legalSectionText: appSelectors.getSsrTranslationSelector(state, appConstants.LEGAL_TEXT),
  legalSettings: appSelectors.getLegalSettings(state)
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.IMAGES_BRANCH, saga: imagesSaga });

export default compose(
  withConnect,
  withSaga
)(TabbedActivityContent);
