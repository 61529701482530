import axios from 'axios';
import * as constants from './constants/constants';

const BASE_URL = '/api';

export function createTemplateCategory(categoryType, categoryName) {
  switch (categoryType) {
    case constants.TEMPLATE_RULE_CATEGORY:
      return createRuleTempalateCategory(categoryName);
    case constants.TEMPLATE_DEAL_CATEGORY:
      return createDealTemplateCategory(categoryName);
    case constants.TEMPLATE_ONE_TIME_ACTION_CATEGORY:
      return createOneTimeActionTemplateCategory(categoryName);
    default:
      throw new Error('category has an invalid format');
  }
}

function createRuleTempalateCategory(name) {
  return axios.post(`${BASE_URL}/RuleTemplateCategories`, { name })
    .then((res) => res.data);
}

function createDealTemplateCategory(name) {
  return axios.post(`${BASE_URL}/DealTemplateCategories`, { name })
    .then((res) => res.data);
}

function createOneTimeActionTemplateCategory(name) {
  return axios.post(`${BASE_URL}/OneTimeActionTemplateCategories`, { name })
    .then((res) => res.data);
}
