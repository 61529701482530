import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import * as constants from '../../../../activity.constants';
import injectSaga from '../../../../../../utils/injectSaga';
import * as reducerConstants from '../../../../../../constants/reducer.constants';
import imagesSaga from '../../../../../Images/images.saga';
import * as appConstants from '../../../../../App/app.constants';
import withAuthorization from '../../../../../../common/helpers/authorization';
import ExtraDataFields from './ExtraDataFields';

class ExtraAppData extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      extraData: []
    };
  }

  static propTypes = {
    action: PropTypes.object,
    automationIdPrefix: PropTypes.string,
    index: PropTypes.number,
    intl: PropTypes.object.isRequired,
    mentionsList: PropTypes.object,
    onActionFieldUpdate: PropTypes.func.isRequired,
    validationErrors: PropTypes.object,
    getPermissionLevel: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { getPermissionLevel } = this.props;
    const hasExternalAppDataFeature = getPermissionLevel(appConstants.FEATURE_EXTERNAL_APP) !== constants.FEATURE_LEVEL.NONE;

    if (hasExternalAppDataFeature) {
      const extraData = this.getExtraData();
      this.setState({ extraData });
    }
  }

  getExtraData() {
    const immutableExtraData = this.props.action.get(constants.EXTRA_DATA);
    return immutableExtraData ? immutableExtraData.toJSON() : [];
  }

  onExtraDataUpdate(name, value, extraDataIndex, actionIndex) {
    const extraData = this.getExtraData();
    extraData[extraDataIndex] = { ...extraData[extraDataIndex], [name]: value.trim() };
    this.props.onActionFieldUpdate(constants.EXTRA_DATA, extraData, actionIndex);
  }

  addExtraData = (actionIndex) => {
    const extraData = this.getExtraData();
    extraData[this.state.extraData.length] = { 'key': '', 'value': '' }; // default value for the column
    this.props.onActionFieldUpdate(constants.EXTRA_DATA, extraData, actionIndex);

    this.setState({ extraData: [...extraData] });
  }

  removeExtraData = (key, actionIndex) => {
    const extraData = this.getExtraData();
    extraData.splice(key, 1);
    this.props.onActionFieldUpdate(constants.EXTRA_DATA, extraData, actionIndex);

    this.setState({ extraData: [...extraData] });
  }

  render() {
    const {
      action,
      automationIdPrefix,
      index,
      mentionsList,
      validationErrors,
      getPermissionLevel,
    } = this.props;

    const { formatMessage } = this.props.intl;
    const hasExternalAppDataFeature = getPermissionLevel(appConstants.FEATURE_EXTERNAL_APP) !== constants.FEATURE_LEVEL.NONE;

    return hasExternalAppDataFeature ? (
      <React.Fragment>
        <Grid.Row>
          <Grid.Column width={16}>
            <p className="action-title">
              {formatMessage({ id: 'activity.push.external.app.data.config.label' })}
            </p>
          </Grid.Column>
        </Grid.Row>
        {this.state.extraData.map((x, i) => {
          const extraData = action ? action.getIn([constants.EXTRA_DATA, i]) : {};

          return (
            <ExtraDataFields
              key={i}
              validationErrors={validationErrors}
              extraData={extraData}
              automationIdPrefix={automationIdPrefix}
              index={i}
              onActionFieldUpdate={(extraDataIndex, key, value) => this.onExtraDataUpdate(key, value, extraDataIndex, index)}
              mentionsList={mentionsList}
              removeItem={this.removeExtraData.bind(this)}
              showDeleteButton={this.state.extraData.length > 1}
              parentIndex={index}
            />
          );
        })}
        <a
          role={'button'} style={{ marginBottom: 20 }} onClick={() => {
          this.addExtraData.bind(this)(index);
        }}
        >
          <Icon
            style={{ fontsize: '10px' }}
            className="icon como-ic-plus-in-circle"
          />Add Extra Data
        </a>
      </React.Fragment>
    ) : (<></>);
  }
}

const withSaga = injectSaga({ key: reducerConstants.IMAGES_BRANCH, saga: imagesSaga });
export default withAuthorization(compose(withSaga)(injectIntl(ExtraAppData)));
