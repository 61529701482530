/*
 *
 * MicroCampaign constants
 *
 */

// ACTIONS CONSTANTS

export const UPDATE_MICRO_CAMPAIGN_METADATA = 'app/MicroCampaign/UPDATE_MICRO_CAMPAIGN_METADATA';
export const GET_MICRO_CAMPAIGN = 'app/MicroCampaign/GET_MICRO_CAMPAIGN';
export const CREATE_MICRO_CAMPAIGN = 'app/MicroCampaign/CREATE_MICRO_CAMPAIGN';
export const SAVE_MICRO_CAMPAIGN = 'app/MicroCampaign/SAVE_MICRO_CAMPAIGN';
export const SAVE_MICRO_CAMPAIGN_SUCCESS = 'app/MicroCampaign/SAVE_MICRO_CAMPAIGN_SUCCESS';
export const SAVE_MICRO_CAMPAIGN_ERROR = 'app/MicroCampaign/SAVE_MICRO_CAMPAIGN_ERROR';
export const GET_MICRO_CAMPAIGN_SUCCESS = 'app/MicroCampaign/GET_MICRO_CAMPAIGN_SUCCESS';
export const GET_MICRO_CAMPAIGN_ERROR = 'app/MicroCampaign/GET_MICRO_CAMPAIGN_ERROR';
export const UPDATE_MICRO_CAMPAIGN_FIELD = 'app/MicroCampaign/UPDATE_MICRO_CAMPAIGN_FIELD';
export const CHECK_MICRO_CAMPAIGN_NAME_AVAILABILITY = 'app/MicroCampaign/CHECK_MICRO_CAMPAIGN_NAME_AVAILABILITY';
export const CHECK_MICRO_CAMPAIGN_NAME_AVAILABILITY_SUCCESS = 'app/MicroCampaign/CHECK_MICRO_CAMPAIGN_NAME_AVAILABILITY_SUCCESS';
export const CHECK_MICRO_CAMPAIGN_NAME_AVAILABILITY_DONE = 'app/MicroCampaign/CHECK_MICRO_CAMPAIGN_NAME_AVAILABILITY_DONE';
export const UPDATE_COMMUNICATION_ACTION_TYPE = 'app/MicroCampaign/UPDATE_COMMUNICATION_ACTION_TYPE';
export const SUBMIT_COMMUNICATION_ACTION_TO_CUSTOMER = 'app/MicroCampaign/SUBMIT_COMMUNICATION_ACTION_TO_CUSTOMER';
export const CLEAR_MICRO_CAMPAIGN_DATA = 'app/MicroCampaign/CLEAR_MICRO_CAMPAIGN_DATA';
export const SET_MICRO_CAMPAIGN_PUBLISH_IN_PROGRESS = 'app/MicroCampaign/SET_MICRO_CAMPAIGN_PUBLISH_IN_PROGRESS';
export const SET_MICRO_CAMPAIGN_SAVE_AS_DRAFT_ON_PROGRESS = 'app/MicroCampaign/SET_MICRO_CAMPAIGN_SAVE_AS_DRAFT_ON_PROGRESS';
export const REMOVE_MICRO_CAMPAIGN_PUBLISH_IN_PROGRESS = 'app/MicroCampaign/REMOVE_MICRO_CAMPAIGN_PUBLISH_IN_PROGRESS';
export const REMOVE_MICRO_CAMPAIGN_SAVE_AS_DRAFT_IN_PROGRESS = 'app/MicroCampaign/REMOVE_MICRO_CAMPAIGN_SAVE_AS_DRAFT_IN_PROGRESS';
export const SET_MICRO_CAMPAIGN_ERRORS = 'app/MicroCampaign/SET_MICRO_CAMPAIGN_ERRORS';
export const ADD_BENEFIT_TO_MICRO_CAMPAIGN = 'app/MicroCampaign/ADD_BENEFIT_TO_MICRO_CAMPAIGN';
export const DELETE_ASSET_TEMPLATE = 'app/MicroCampaign/DELETE_ASSET_TEMPLATE';
export const CLEAR_ASSET_TEMPLATE = 'app/MicroCampaign/CLEAR_ASSET_TEMPLATE';
export const TOGGLE_ASSET_SET_DISPLAY = 'app/MicroCampaign/TOGGLE_ASSET_SET_DISPLAY';
export const SET_ALL_EXPAND_COLLAPSE_MODE = 'app/MicroCampaign/SET_ALL_EXPAND_COLLAPSE_MODE';


// STORE CONSTANTS
export const MICRO_CAMPAIGN_NAME = 'name';
export const MICRO_CAMPAIGN_HEADER = 'microCampaignHeader';
export const MICRO_CAMPAIGN = 'microCampaign';
export const MICRO_CAMPAIGN_VALIDATION_ERRORS = 'validationErrors';
export const CHECKING_MICRO_CAMPAIGN_NAME_AVAILABILITY = 'checkingMicroCampaignNameAvailability';
export const IS_DIRTY = 'isDirty';
export const PUBLISH_IN_PROGRESS = 'publishInProgress';
export const SAVE_AS_DRAFT_IN_PROGRESS = 'publishInProgress';
export const MICRO_CAMPAIGN_ACTION_TYPE_LAUNCH = 'sendNotifyAction';
export const MICRO_CAMPAIGN_ACTION_TYPE_REMINDER = 'remindNotifyAction';

export const MICRO_CAMPAIGN_DESCRIPTION = 'description';
export const MICRO_CAMPAIGN_POPULATION_SIZE = 'maxPopulation';
export const MICRO_CAMPAIGN_POPULATION_SIZE_MIN = 500;
export const MICRO_CAMPAIGN_POPULATION_SIZE_MAX = 5000;
export const MICRO_CAMPAIGN_ASSET_TEMPLATES = 'assets';
export const MICRO_CAMPAIGN_ASSET_TEMPLATE = 'template';
export const MICRO_CAMPAIGN_ASSET_ACTIONS = 'actions';

export const MICRO_CAMPAIGN_VALIDATION_NAME_TAKEN_FLAG = 'microCampaignNameTaken';
export const MICRO_CAMPAIGN_DATA_LOADED = 'microCampaignDataLoaded';
export const MICRO_CAMPAIGN_STATUS = 'status';
export const ACTION_META_DATA = 'actionMetaData';

