import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as routeConstants from '../../../constants/route.contants';
import { CloseLink, Header } from '../views/index';
import { BackLinkWhite } from '../../../common/components/NavigationLink/BackLink';
import Carousel from '../../../common/components/Carousel/MultipleItems';
import * as constants from '../goals.constants';
import injectSaga from '../../../utils/injectSaga';
import * as goalActions from './goal.actions';
import saga from '../goals.saga';
import Tool from './tool';

import './goal.styles.less';
import tracker from '../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../common/utils/tracking/tracking.consts';
import * as appSelectors from '../../App/selectors';


const AUTOMATION_NAME = 'goal';
const CAROUSEL_LENGTH_MIN = 5;

class Goal extends PureComponent {
  static propTypes = {
    goal: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    locationId: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      selected: -1,
      tools: this.getGoalTools(props.goal)
    };
  }

  componentWillMount() {
    const { match, actions } = this.props;
    const { goalId } = match.params;
    if (goalId) {
      actions.getGoal(goalId);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { goal } = this.props;


    if (goal !== nextProps.goal) {
      this.setState({ tools: this.getGoalTools(nextProps.goal) });
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.clearGoal();
  }

  getGoalTools(goal) {
    return goal.get(constants.GOAL_TOOLS);
  }

  handleCardSelect = (index) => {
    this.setState({ selected: index });
  };

  handleCreateBundle = () => {
    const { actions, goal, locationId } = this.props;
    const { selected, tools } = this.state;

    if (selected >= 0) {

      actions.createBundle(
        locationId,
        goal.get(constants.GOAL_DB_ID),
        goal.get(constants.GOAL_NAME),
        tools.getIn([selected.toString(), constants.GOAL_TEMPLATES_RULES]).toJS(),
        tools.getIn([selected.toString(), constants.GOAL_TEMPLATES_DEALS]).toJS(),
        tools.getIn([selected.toString(), constants.GOAL_TEMPLATES_ONE_TIME_ACTIONS]).toJS()
      );
    }
  };

  renderTools() {
    const { tools, selected } = this.state;

    const data = tools.map((tool, index) => (<Tool key={index} onCardSelect={this.handleCardSelect} tool={tool} index={index} active={selected === index} />));

    if (data.size >= CAROUSEL_LENGTH_MIN) {
      return (
        <Carousel
          className="carousel"
          infinite={false}
          slidesToShow={4}
          dots={false}
        >
          {data}
        </Carousel>
      );
    }

    return (
      <div className="tools">
        {data}
      </div>
    );
  }

  render() {
    const {formatMessage} = this.props.intl; //eslint-disable-line
    const { goal } = this.props;
    const { tools } = this.state;

    return (
      <div className="goal">
        <Helmet>
          <title>{formatMessage({ id: 'goals.goal.helmet.title' })}</title>
          <meta name="description" content="Description of Goal" />
        </Helmet>
        <Header>
          <BackLinkWhite
            pathname={routeConstants.GOALS_ROUTE}
            border
            onClick={() => tracker.onButtonClick(trackerConstants.BUTTON_TYPE_BACK_TO_GOALS_SELECTION_PAGE)}
          />
          <div className="title">
            <Icon className={`como-svg-icon-goals-white-${goal.get(constants.GOAL_ICON)}`} />
            <p>{goal.get(constants.GOAL_NAME)}</p>
          </div>
          <CloseLink onClick={() => tracker.onButtonClick(trackerConstants.BUTTON_TYPE_CLOSE_GOALS_WIZARD)} />
        </Header>
        {
          tools.size > 0 &&
          <main>
            <p
              className="title"
            >{formatMessage({ id: 'goals.goal.content.title' })}</p>
            {this.renderTools()}
            <Button
              disabled={this.state.selected === -1}
              onClick={tracker.wrapButtonAction(this.handleCreateBundle, trackerConstants.BUTTON_TYPE_CREATE_GOAL_CAMPAIGN)}
              data-automation-id={`${AUTOMATION_NAME}.create.bundle`}
            >
              {formatMessage({ id: 'general.button.create' })}
            </Button>
          </main>
          }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  goal: state.getIn([constants.ACTIVITY_GOALS_BRANCH, constants.GOAL]),
  locationId: appSelectors.getLocationId(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(goalActions, dispatch)
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: constants.SAGA_KEY_GOALS, saga });

export default compose(
   withConnect,
   withSaga,
  injectIntl
)(Goal);
