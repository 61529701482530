import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import '../../conditions.styles.less';
import * as constants from '../../../../activity.constants';
import InstanceExpirationConditionPopup from './InstanceExpirationConditionPopup';
import * as activityActions from '../../../../activity.actions';
import './instanceExpirationCondition.styles.less';
import * as activitySelectors from '../../../../activity.selectors';
import * as appSelectors from '../../../../../App/selectors';

class InstanceExpirationCondition extends React.PureComponent {

  static propTypes = {
    actualValue: PropTypes.object,
    tempValue: PropTypes.object,
    automationIdPrefix: PropTypes.string.isRequired,
    intl: PropTypes.object,
    isLastCondition: PropTypes.bool,
    actions: PropTypes.object.isRequired,
    errors: PropTypes.object,
    tempErrors: PropTypes.object,
    activity: PropTypes.object.isRequired,
    localeDateFormat: PropTypes.string,
    businessTimezone: PropTypes.string,
    showActivateDelayCondition: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = { isPopupOpen: false };
  }

  onPopupOpen = () => {
    if (this.state.isPopupOpen) {
      return false; // do not allow more than one popup at a time
    }
    this.props.actions.resetGiftInstanceExpirationGlobalConditionTempModel();
    this.setState({ isPopupOpen: true });
    return true;
  };

  onPopupClose =() => {
    this.setState({ isPopupOpen: false });
  };

  handleOnChange = (section, key, value) => {
    this.props.actions.updateGiftInstanceExpirationGlobalConditionTempModel(section, key, value);
  };

  render() {
    const { formatMessage } = this.props.intl;
    if (!this.props.actualValue) {
      return null;
    }
    const datetimeGlobalConditions = this.props.activity.get(constants.DATETIME_GLOBAL_CONDITIONS);
    const translationsNamespace = `${this.props.activity.get(constants.TYPE)}s`;
    const gridClassNames = classNames(['condition-row', 'instance-expiration-conditions'], { 'last-condition': this.props.isLastCondition });
    return (
      <Grid className={gridClassNames}>
        {this.props.showActivateDelayCondition &&
        <Grid.Row>
          <Grid.Column width={16}>
            <span>{formatMessage({ id: `${translationsNamespace}.activation.condition.member.can.redeem.after` })}</span>
            <InstanceExpirationConditionPopup
              automationIdPrefix={`${this.props.automationIdPrefix}.can.reedem.after`}
              actualValue={this.props.actualValue.get(constants.ACTIVATION_CONDITION)}
              tempValue={this.props.tempValue ? this.props.tempValue.get(constants.ACTIVATION_CONDITION) : null}
              onChange={(key, value) => this.handleOnChange(constants.ACTIVATION_CONDITION, key, value)}
              onOpen={this.onPopupOpen}
              onClose={this.onPopupClose}
              onSave={this.props.actions.applyGiftInstanceExpirationGlobalConditionTempModel}
              isPristine={this.props.tempValue ? this.props.tempValue.get(constants.PRISTINE_FLAG) : true}
              error={this.props.errors && this.props.errors.get(constants.ACTIVATION_CONDITION)}
              tempError={this.props.tempErrors && this.props.tempErrors.get(constants.ACTIVATION_CONDITION)}
              datetimeGlobalCondition={datetimeGlobalConditions}
              localeDateFormat={this.props.localeDateFormat}
              businessTimezone={this.props.businessTimezone}
              translationsNamespace={translationsNamespace}
              activityType={this.props.activity.get(constants.TYPE)}
            />
          </Grid.Column>
        </Grid.Row>
        }
        <Grid.Row>
          <Grid.Column width={16}>
            <span>{formatMessage({ id: `${translationsNamespace}.activation.condition.and.up.to` })}</span>
            <InstanceExpirationConditionPopup
              automationIdPrefix={`${this.props.automationIdPrefix}.expiresAfter`}
              actualValue={this.props.actualValue.get(constants.DEACTIVATION_CONDITION)}
              tempValue={this.props.tempValue ? this.props.tempValue.get(constants.DEACTIVATION_CONDITION) : null}
              deactivationMode
              onChange={(key, value) => this.handleOnChange(constants.DEACTIVATION_CONDITION, key, value)}
              onOpen={this.onPopupOpen}
              onClose={this.onPopupClose}
              onSave={this.props.actions.applyGiftInstanceExpirationGlobalConditionTempModel}
              isPristine={this.props.tempValue ? this.props.tempValue.get(constants.PRISTINE_FLAG) : true}
              error={this.props.errors && this.props.errors.get(constants.DEACTIVATION_CONDITION)}
              tempError={this.props.tempErrors && this.props.tempErrors.get(constants.DEACTIVATION_CONDITION)}
              datetimeGlobalCondition={datetimeGlobalConditions}
              localeDateFormat={this.props.localeDateFormat}
              businessTimezone={this.props.businessTimezone}
              translationsNamespace={translationsNamespace}
              activityType={this.props.activity.get(constants.TYPE)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => ({
  actualValue: activitySelectors.getGiftInstanceExpirationGlobalConditionModel(state),
  tempValue: activitySelectors.getGiftInstanceExpirationGlobalConditionTempModel(state),
  errors: activitySelectors.getGiftInstanceExpirationGlobalConditionValidationErrors(state),
  tempErrors: activitySelectors.getGiftInstanceExpirationGlobalConditionTempValidationErrors(state),
  localeDateFormat: appSelectors.getBrowserLocaleDateFormat(state),
  activity: activitySelectors.getActivityDataModel(state),
  businessTimezone: appSelectors.getBusinessTimeZone(state)
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(activityActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(InstanceExpirationCondition);
