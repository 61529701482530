/**
 *
 * Currency
 *
 */

import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InputWrapper from '../InputWrapper/InputWrapper';
import { INTEGER_TYPE } from '../inputConstants';
import './currencyInputField.styles.less';
import { getLocationCurrencySymbol } from '../../../../features/App/selectors';

export const calculateDisplayValue = (value) => value === null ? '' : (value / 100).toFixed(2);

class CurrencyInputField extends React.PureComponent {

  static propTypes = {
    allowOnlyPositive: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    fluid: PropTypes.bool,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    prefix: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func,
    currencySymbol: PropTypes.string
  };
// todo: add disabled: true to defaults
  static defaultProps = {
    fluid: false,
    allowOnlyPositive: true
  };

  constructor(props) {
    super(props);
    this.state = { displayValue: calculateDisplayValue(props.value) };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ displayValue: calculateDisplayValue(nextProps.value) });
  }

  onKeyDown = (event) => {
    if (this.props.allowOnlyPositive && event.key === '-') {
      event.preventDefault();
      return false;
    }
    return true;
  };

  onChange = (e, data) => {
    this.setState({ displayValue: data.value ? (parseFloat(data.value)).toFixed(2) : '' });
    // save currency in cents (therefore multiple with 100)
    const formattedValue = data.value ? parseFloat(data.value) * 100 : null;
    const newData = { name: data.name, value: formattedValue };
    this.props.onChange(e, newData);
  };

  render() {

    const { disabled, error, fluid, name, prefix, placeholder, currencySymbol } = this.props;
    const classNames = this.props.className ? `${this.props.className} currency-field` : 'currency-field';
    return (
      <InputWrapper
        className={classNames}
        disabled={disabled}
        error={error}
        fluid={fluid}
        name={name}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
        prefix={prefix}
        step={0.01}
        type={INTEGER_TYPE}
        value={this.state.displayValue}
        placeholder={placeholder}
        label={currencySymbol}
        labelPosition={'left'}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  currencySymbol: getLocationCurrencySymbol(state)
});

function mapDispatchToProps() {
  return {
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect
)(CurrencyInputField);
