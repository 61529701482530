import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import '../../conditions.styles.less';
import * as activityActions from '../../../../activity.actions';
import * as activitySelectors from '../../../../activity.selectors';
import * as activityConstants from '../../../../activity.constants';
import DropdownWrapper from '../../../../../../common/components/DropdownWrapper/DropdownWrapper';
import './ClubMemberAnniversaryEventTypeCondition.styles.less';
import * as dateConstants from '../../../../../../common/utils/dateTime.constants';
import { AUTOMATION_PREFIX_NAME } from '../occurrencesLimitCondition/limitPerMemberGlobalConditionWrapper';
import IntegerInputField from '../../../../../../common/components/FormInputFields/IntegerInputField/IntegerInputField';

class ClubMemberAnniversaryEventTypeCondition extends React.PureComponent {

  static propTypes = {
    eventType: PropTypes.string,
    scheduleObject: PropTypes.object,
    actions: PropTypes.object,
    intl: PropTypes.object,
    trigger: PropTypes.string.isRequired
  };

  get dropdownOptions() {
    return [activityConstants.EVENT_SCOPE_PERIOD, activityConstants.EVENT_SCOPE_ONE_TIME].map((value) => ({
      value,
      text: this.props.intl.formatMessage(
        { id: `club.member.anniversary.event.type.condition.value.${value}` },
        { trigger: "clubMemberAnniversary" })
    }));
  }
  get periodDropDown() {
    const { formatMessage } = this.props.intl;
    return (<DropdownWrapper
      style={{marginLeft: "20px", width: "30px", textAlign: "center"}}
      options={[
        {
          value: dateConstants.DAY,
          text: formatMessage({ id: 'activity.conditions.occurrences.limit.unit.day' })
        },
        {
          value: dateConstants.WEEK,
          text: formatMessage({ id: 'activity.conditions.occurrences.limit.unit.week' })
        },
        {
          value: dateConstants.MONTH,
          text: formatMessage({ id: 'activity.conditions.occurrences.limit.unit.month' })
        },
        {
          value: dateConstants.YEAR,
          text: formatMessage({ id: 'activity.conditions.occurrences.limit.unit.year' })
        },
      ]}
      prefix={`${AUTOMATION_PREFIX_NAME}.limitedPerMember.byPeriod.timesPer`}
      name="value"
      onSelectOption={(e, data) => this.props.actions.setClubMemberAnniversaryOneTImeObjectUnit(data.value)}
      selection
      value={this.props.scheduleObject.get('unit') || dateConstants.DAY}
      className="period-drop-down"
    />);
  }
  render() {
    const { formatMessage } = this.props.intl;
    if (!this.props.eventType) {
      return null;
    }
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={2} className="condition-left-column">
            <p>{formatMessage({ id: 'birthday.or.anniversary.event.type.condition.title' })}</p>
          </Grid.Column>
          <Grid.Column width={14}>
            <Grid className="condition-row last-condition">
              <Grid.Row>
                <Grid.Column width={16}>
                  <DropdownWrapper
                    prefix="trigger-event-type-condition"
                    value={this.props.eventType}
                    options={this.dropdownOptions}
                    className="trigger-event-type-condition"
                    onSelectOption={
                    (e, data) => this.props.actions.setScheduleEventScope(data.value)
                  }
                  />
                </Grid.Column>
                {this.props.eventType === activityConstants.EVENT_SCOPE_ONE_TIME && <Grid.Column width={16}>
                  <span className={`head-of-sentence align-baseline`}>
                  {formatMessage({ id: 'activity.conditions.occurrences.limit.times.after' })}
                </span>
                  <IntegerInputField
                    value={this.props.scheduleObject.get('value')}
                    onChange={(e, data) => this.props.actions.setClubMemberAnniversaryOneTImeObjectValue(data.value)}
                    prefix={`${AUTOMATION_PREFIX_NAME}.limitedPerMember.byPeriod.count`}
                    /* error={errors.byPeriodPeriodCountError} */
                  >
                  </IntegerInputField>
                  {this.periodDropDown}
                </Grid.Column>}
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => ({
  scheduleObject: activitySelectors.getClubMemberAnniversaryScheduleObject(state),
  eventType: activitySelectors.getClubMemberAnniversaryEventScope(state),
  trigger: activitySelectors.getSelectedTrigger(state)
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(activityActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(ClubMemberAnniversaryEventTypeCondition);
