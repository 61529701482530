import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Icon } from 'semantic-ui-react';
import moment from 'moment';
import classNames from 'classnames';
import Modal from '../../../../../../common/components/CustomModal/CustomModal';
import ScheduleGlobalConditions from './ScheduleGlobalConditions';
import * as globalConditionsActions from '../globalConditions.actions';
import * as activityConstants from '../../../../activity.constants';
import ValidationErrorMessage from '../../../../../../common/components/ValidationErrorMessage/ValidationErrorMessage';
import * as activitySelectors from '../../../../activity.selectors';
import * as commonComponentsConstants from '../../../../../../common/components/common.components.constants';
import * as appSelectors from '../../../../../App/selectors';
import './scheduleConditionWrapper.styles.less';

class ScheduleConditionWrapper extends React.PureComponent {

  static propTypes = {
    localeDateFormat: PropTypes.string.isRequired,
    localeTimeFormat: PropTypes.string.isRequired,
    schedulingData: PropTypes.object.isRequired,
    tempSchedulingData: PropTypes.object,
    actions: PropTypes.object.isRequired,
    validationErrors: PropTypes.object,
    tempValidationErrors: PropTypes.object,
    businessTimezone: PropTypes.string,
    activityViewStatus: PropTypes.string,
    isActivityDisabled: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      isModalOpen: false
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.cancelChanges = this.cancelChanges.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.getTriggerMessage = this.getTriggerMessage.bind(this);
  }

  getTriggerMessage() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    if (this.props.schedulingData.get(activityConstants.TYPE) === activityConstants.SCHEDULE_IMMEDIATE) {
      return formatMessage({ id: 'activity.globalConditions.schedule.radioBtn.immediate' });
    }
    const dateTimeFormat = `${this.props.localeDateFormat}  ${this.props.localeTimeFormat}`;
    return moment(this.props.schedulingData.get(activityConstants.SCHEDULE_DATE)).tz(this.props.businessTimezone).format(dateTimeFormat);
  }

  isActivityCompletedAndImmediateScheduled() {
    return this.props.activityViewStatus === activityConstants.ACTIVITY_STATUS_COMPLETED &&
      this.props.schedulingData.get(activityConstants.TYPE) === activityConstants.SCHEDULE_IMMEDIATE;
  }

  getImmediateScheduleDate() {
    const dateTimeFormat = `${this.props.localeDateFormat}  ${this.props.localeTimeFormat}`;
    return moment(this.props.schedulingData.get(activityConstants.SCHEDULE_DATE)).tz(this.props.businessTimezone).format(dateTimeFormat);
  }

  renderImmediateScheduledDateComponent() {
    if (this.isActivityCompletedAndImmediateScheduled()) {
      return (<div className='modal-trigger divider' >
                <span> {this.getImmediateScheduleDate()} </span>
            </div>);
    }
    return null;
  }

  /**
   * opens modal
   */
  openModal = () => {
    this.setState({
      isModalOpen: true,
    });
    this.props.actions.createTempSchedulingData();
  };

  /**
   * close modal
   */
  closeModal = () => {
    this.setState({
      isModalOpen: false
    });
  };

  saveChanges() {
    this.props.actions.saveSchedulingChanges();
    this.closeModal();
  }

  cancelChanges() {
    this.props.actions.resetSchedulingData();
    this.closeModal();
  }

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const triggerClassNames = classNames('condition-link tooltip-trigger modal-trigger-link', { 'errors-inside-global-conditions-highlight': this.props.validationErrors },
      this.props.isActivityDisabled ? 'activity-globalConditions-schedule-disable' : '');

    return (
      <Modal
        automationIdPrefix="schedule.global.modal"
        className="schedule-global-modal"
        header={formatMessage({ id: 'activity.globalConditions.schedule' })}
        actions={[
          <Button
            key="scheduleConditionCancelBtn"
            onClick={this.cancelChanges}
            basic
            className="link modal-button"
            data-automation-id="globalConditions.schedule.btnCancel"
          >
            {formatMessage({ id: 'general.button.cancel' })}
          </Button>,
          <Button
            className="modal-button"
            key="scheduleConditionSaveBtn"
            onClick={this.saveChanges}
            data-automation-id="globalConditions.schedule.btnSave"
          >
            {formatMessage({ id: 'general.button.save' })}
          </Button>
        ]}
        trigger={
          <div className="modal-trigger">
            <a
              role="button"
              className={triggerClassNames}
              onClick={this.openModal}
            >
              <Icon className="como-ic-date" data-automation-id="globalConditions.schedule" />
              <span> {this.getTriggerMessage()} </span>
            </a>
            {this.renderImmediateScheduledDateComponent()}
            {this.props.schedulingData.get(activityConstants.TYPE) === activityConstants.SCHEDULE_SCHEDULED
              && moment(this.props.schedulingData.get(activityConstants.SCHEDULE_DATE)) > moment() &&
              <span className="hint scheduling-condition">
                {formatMessage({ id: 'onetime.scheduled.filter.population.hint' })}
              </span>
            }
            {this.props.validationErrors ?
              <ValidationErrorMessage
                errorLayoutType={commonComponentsConstants.COMPONENT_TYPE_PARAGRAPH}
                errorMessage={formatMessage({ id: 'activity.validation.error.popup.outside.message' })}
                dataAutomationId={'globalConditions.schedule.error.message'}
              />
              : null
            }
          </div>
        }
        content={
          <ScheduleGlobalConditions
            schedulingData={this.props.tempSchedulingData}
            onRadioChange={(event, elem) => this.props.actions.updateGlobalSchedulingType(elem.value)}
            errors={this.props.tempValidationErrors}
            updateDate={this.props.actions.updateGlobalSchedulingDate}
            businessTimezone={this.props.businessTimezone}
            datePickerInsideModal
          />
        }
        size="small"
        closeIcon={<Icon className="como-ic-close" name="close" onClick={this.cancelChanges} data-automation-id="globalConditions.schedule.closeBtn" />}
        open={this.state.isModalOpen}
      />

    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(globalConditionsActions, dispatch)
  };
}

const mapStateToProps = (state) => ({
  schedulingData: activitySelectors.getOneTimeActionScheduling(state),
  localeDateFormat: appSelectors.getBrowserLocaleDateFormat(state),
  localeTimeFormat: appSelectors.getBrowserLocaleTimeFormat(state),
  tempSchedulingData: activitySelectors.getTempSchedulingData(state),
  validationErrors: activitySelectors.getOneTimeActionValidationErrors(state),
  tempValidationErrors: activitySelectors.getOneTimeActionTempValidationErrors(state),
  businessTimezone: appSelectors.getBusinessTimeZone(state),
  isActivityDisabled: activitySelectors.isActivityDisabled(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ScheduleConditionWrapper));
