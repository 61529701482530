import { Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';

/*
 WARNING - IF THE POPUP IS OPENED IN THE WRONG LOCATION:
 Please make sure that you aren't focusing an element inside the popup,
 Semantic-UI Popup component calculates the popup position only after it is open (drawn):
 the calculation assume that the scroll offset hasn't changed since the mouse click on the trigger,
 so focusing element upon it's show (e.g. componentDidMount) breaks the positioning calculation.

 If you have to focus an element inside the popup,
 Please make sure to run the focus action outside of the current cycle (with setTimeout),
 So that the positioning of the popup will be done before the focus happens.
*/

export class PopupWithFixedPosition extends Popup {
  static propTypes = {
    fixed: PropTypes.bool
  };

  isStyleInViewport(style) {
    if (!this.props.fixed) {
      return Popup.prototype.isStyleInViewport.apply(this, [style]);
    }
    return true;
  }

  // the handleOpen is not a prototype function but an arrow function (which is a variable of the instance)
  // noinspection ES6ClassMemberInitializationOrder
  originalHandleOpenRef = this.handleOpen;

  handleOpen = (e) => {
    if (this.props.fixed) {
      window.scrollBy(0, -1); // work-around CNP-11566
    }
    this.originalHandleOpenRef(e);
  };

}

Popup.handledProps = Popup.handledProps.concat('fixed');
