/**
 *
 * SendTcConsent
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'semantic-ui-react';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import './sendTcConsent.style.less';
import SendSMS from '../sendSMS/SendSMS';
import SendConsentEmail from './SendConsentEmail';
import * as selectors from '../../../../../App/selectors';
import * as constants from '../../../../activity.constants';
import * as mentionsSelectors from '../../../../activitySchema/mentions.selectos';

class SendTcConsent extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string,
    smsConsentDefaultMessage: PropTypes.string,
    emailConsentDefaultMessage: PropTypes.string,
    emailConsentDefaultSubject: PropTypes.string,
    onActionFieldUpdate: PropTypes.func.isRequired,
    action: PropTypes.object,
    index: PropTypes.number,
    actionsWrapperIndex: PropTypes.number,
    mentionsList: PropTypes.object,
    emailMentionsList: PropTypes.object,
    validationErrors: PropTypes.object,
  };

  componentWillMount() {
    if (!this.props.action.get(constants.CONSENT_TYPE)) {
      this.props.onActionFieldUpdate(constants.CONSENT_TYPE, constants.SEND_CONSENT_BY_SMS, this.props.index);
      this.props.onActionFieldUpdate(constants.SMS_MESSAGE, this.props.smsConsentDefaultMessage, this.props.index);
      this.props.onActionFieldUpdate(constants.EMAIL_MESSAGE, this.props.emailConsentDefaultMessage, this.props.index);
      this.props.onActionFieldUpdate(constants.EMAIL_SUBJECT, this.props.emailConsentDefaultSubject, this.props.index);
    }
  }

  onUpdate = (e, data) => {
    this.props.onActionFieldUpdate(constants.CONSENT_TYPE, data.value, this.props.index);
  };

  render() {
    const { action, mentionsList, emailMentionsList, index, validationErrors, automationIdPrefix, onActionFieldUpdate, actionsWrapperIndex } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    return (
      <div>
        <div>
          <div className={'sendMethod'}>
            <Radio
              label={formatMessage({ id: `activity.action.consent.type.${constants.SEND_CONSENT_BY_SMS}` })}
              value={constants.SEND_CONSENT_BY_SMS}
              onClick={this.onUpdate}
              checked={action.get(constants.CONSENT_TYPE) === constants.SEND_CONSENT_BY_SMS}
            />
            &nbsp; &nbsp;
            <Radio
              label={formatMessage({ id: `activity.action.consent.type.${constants.SEND_CONSENT_BY_EMAIL}` })}
              value={constants.SEND_CONSENT_BY_EMAIL}
              onClick={this.onUpdate}
              checked={action.get(constants.CONSENT_TYPE) === constants.SEND_CONSENT_BY_EMAIL}
            />
          </div>
          {action.get(constants.CONSENT_TYPE) === constants.SEND_CONSENT_BY_EMAIL ? (
            <SendConsentEmail
              onActionFieldUpdate={onActionFieldUpdate}
              validationErrors={validationErrors}
              automationIdPrefix={automationIdPrefix}
              index={index}
              mentionsList={emailMentionsList}
              message={action.get(constants.EMAIL_MESSAGE)}
              subject={action.get(constants.EMAIL_SUBJECT)}
            />
          ) : (
            <SendSMS
              smsMessage={action.get(constants.SMS_MESSAGE)}
              onActionFieldUpdate={onActionFieldUpdate}
              validationErrors={validationErrors}
              automationIdPrefix={automationIdPrefix}
              index={index}
              mentionsList={mentionsList}
              overrideSmsWarning={'sendConsent.sms.warning'}
              isOperationalSms
              isConsentSms
              actionsWrapperIndex={actionsWrapperIndex}
            />
          )}
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    smsConsentDefaultMessage: selectors.getDefaultSMSConsentActionContent(state),
    emailConsentDefaultMessage: selectors.getDefaultEmailConsentActionContent(state),
    emailConsentDefaultSubject: selectors.getDefaultEmailConsentActionSubject(state),
    emailMentionsList: mentionsSelectors.getEmailMentionsTransformed(state),
  };
}

export default compose(connect(mapStateToProps), injectIntl)(SendTcConsent);

