import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { List } from 'immutable';
import moment from 'moment';
import { connect } from 'react-redux';

import * as activityConstants from '../../../activity.constants';
import DaysAndTimes from '../../../../../common/components/DaysAndTimes/DaysAndTimes';
import * as datesConstants from '../../../../../common/utils/dateTime.constants';
import ValidationErrorMessage from '../../../../../common/components/ValidationErrorMessage/ValidationErrorMessage';
import * as dateUtils from '../../../../../common/utils/dateTimeUtils';
import * as appSelectors from '../../../../App/selectors';

class DaysTimesCondition extends React.Component {

  static propTypes = {
    automationIdPrefix: PropTypes.string,
    condition: PropTypes.object,
    errors: PropTypes.object,
    intl: PropTypes.object,
    locale: PropTypes.string,
    localeTimeFormat: PropTypes.string,
    addDaysTimesCondition: PropTypes.func,
    deleteDaysTimesCondition: PropTypes.func,
    updateDaysTimes: PropTypes.func,
    businessTimezone: PropTypes.string
  };

  render() {

    const { formatMessage } = this.props.intl;
    const daysConditionsList = this.props.condition && this.props.condition.get(activityConstants.CONDITION_VALUE).size > 0 ? this.props.condition.get(activityConstants.CONDITION_VALUE) : List();
    const isMilitaryTime = this.props.localeTimeFormat.indexOf(datesConstants.AM_TIME) === -1;
    const timesName = {
      from: formatMessage({ id: 'activity.globalConditions.daysTimes.time.fromLabel' }),
      to: formatMessage({ id: 'activity.globalConditions.daysTimes.time.toLabel' })
    };

    const errors = this.props.errors ? this.props.errors.get(activityConstants.CONDITION_VALUE) : null;
    const firstDayOfWeek = moment.localeData(this.props.locale).firstDayOfWeek();
    return (
      <Grid.Column className="days-times-conditions" width={16}>
        <Grid>
          {
            daysConditionsList.map((condition, index) => {
              // daysTimes hours are save in datastore in business timezone so no need to convert
              const timeFrom = dateUtils.buildTimeObject(condition.getIn([datesConstants.TIME_RANGE, datesConstants.TIME_FROM]), isMilitaryTime, this.props.businessTimezone, false);
              const timeTo = dateUtils.buildTimeObject(condition.getIn([datesConstants.TIME_RANGE, datesConstants.TIME_TO]), isMilitaryTime, this.props.businessTimezone, false);
              const timeErrors = condition.getIn([datesConstants.TIME_RANGE, datesConstants.TIME_ERRORS]);
              return (
                <Grid.Row className={`day-condition-row-${index} day-condition-row`} key={`dayTimes_${index}`}>
                  <Grid.Column className="day-condition-row-subtitle" width={2}>
                    {formatMessage({ id: 'activity.globalConditions.datetTime.onDays' })}
                  </Grid.Column>
                  <Grid.Column width={13} className="align-column-to-left">
                    <DaysAndTimes
                      automationIdPrefix={this.props.automationIdPrefix}
                      onChange={(data) => this.props.updateDaysTimes(data, index, isMilitaryTime, this.props.businessTimezone)}
                      daysActive={condition.get(datesConstants.DAYS).toJS()}
                      index={index}
                      allDayName={formatMessage({ id: 'activity.globalConditions.dateTime.AllDay' })}
                      timeFrom={timeFrom}
                      timeTo={timeTo}
                      isMilitaryTime={isMilitaryTime}
                      startIndex={firstDayOfWeek}
                      timesName={timesName}
                      errors={timeErrors}
                    />
                    {
                      errors && errors.get(String(index)) ?
                        <ValidationErrorMessage
                          errorMessage={formatMessage({ id: errors.get(String(index)) })}
                          dataAutomationId={`${this.props.automationIdPrefix}.${index}.error.message`}
                        />
                        : null
                    }
                  </Grid.Column>
                  <Grid.Column width={1}>
                    { daysConditionsList.size !== 1 ?
                      <Icon
                        className="como-ic-delete"
                        onClick={() => this.props.deleteDaysTimesCondition(index)}
                        data-automation-id={`${this.props.automationIdPrefix}.${index}.delete`}
                      />
                      : null
                    }
                  </Grid.Column>
                </Grid.Row>
              );
            }, this)
          }
          {
            <Grid.Row className="add-days-times">
              <Grid.Column width={2} className="day-condition-row-subtitle" />
              <Grid.Column width={13} className="align-column-to-left">
                <a
                  role="button"
                  className="condition-link add-condition global-condition-link"
                  onClick={this.props.addDaysTimesCondition}
                  data-automation-id="globalConditions.dateTime.add-global-condition"
                >
                  <Icon className="como-ic-plus-in-circle" size="large" />
                  {formatMessage({ id: 'activity.globalConditions.dateTime.addCondition' })}
                </a>
              </Grid.Column>
            </Grid.Row>
          }
        </Grid>
      </Grid.Column>
    );
  }
}

const mapStateToProps = (state) => ({
  businessTimezone: appSelectors.getBusinessTimeZone(state),
  locale: appSelectors.getBrowserLocale(state),
  localeTimeFormat: appSelectors.getBrowserLocaleTimeFormat(state)
});

export default (connect)(mapStateToProps)(injectIntl(DaysTimesCondition));
