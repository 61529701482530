/**
*
* PunchCardCommunicationTab
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as constants from '../../activity.constants';
import * as punchCardActions from './punchCard.actions';
import * as selectors from '../../activity.selectors';
import * as mentionsSelectors from '../../activitySchema/mentions.selectos';
import CommunicationRule from './CommunicationRule';
import * as activityActions from '../../activity.actions';

class PunchCardCommunicationTab extends React.PureComponent {

  static propTypes = {
    actions: PropTypes.object,
    activityPrefix: PropTypes.string,
    features: PropTypes.object,
    intl: PropTypes.object,
    mentionsList: PropTypes.object,
    punchCommunicationRules: PropTypes.object,
    schemaTriggers: PropTypes.object,
    schemaActions: PropTypes.object,
    validationErrors: PropTypes.object,
  };

  componentDidMount() {
    this.addCommunicationRule();
  }

  componentDidUpdate() {
    this.addCommunicationRule();
  }

  addCommunicationRule = () => {
    if (!this.props.punchCommunicationRules || !this.props.punchCommunicationRules.size) {
      this.props.actions.addCommunicationRule();
    }
  };

  expandAll = () => {
    this.props.actions.expandCollapseAllCommunicationRules(false);
  };

  collapseAll = () => {
    this.props.actions.expandCollapseAllCommunicationRules(true);
  };

  render() {

    const { actions, activityPrefix, features, intl, mentionsList, punchCommunicationRules, schemaActions, schemaTriggers, validationErrors } = this.props;
    const { formatMessage } = intl; // eslint-disable-line react/prop-types
    const automationIdPrefix = `${activityPrefix}.${constants.ACTIVITY_TAB_COMMUNICATION}`;

    return (
      <Grid className={'communication'}>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header>{formatMessage({ id: 'activity.punchCard.communication.title' })}</Header>
            <p className={'hint-tip-label'}>{formatMessage({ id: 'activity.punchCard.communication.hint' })}</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <p className="expand-collapse">
              <a
                role="button"
                onClick={this.expandAll}
                data-automationid={`${automationIdPrefix}.expand-all`}
              >{intl.formatMessage({ id: 'activity.cases.expandAll' })}</a>
              |
              <a
                role="button"
                onClick={this.collapseAll}
                data-automation-id={`${automationIdPrefix}.collapse-all`}
              >{intl.formatMessage({ id: 'activity.cases.collapseAll' })}</a>
            </p>
          </Grid.Column>
        </Grid.Row>
        {
          punchCommunicationRules
          ? (
              punchCommunicationRules.map((communicationRule, index) => (
                <CommunicationRule
                  automationIdPrefix={automationIdPrefix}
                  communicationRule={communicationRule}
                  deleteCommunicationRule={actions.deleteCommunicationRule}
                  features={features}
                  index={index}
                  key={`${automationIdPrefix}.${index.toString()}`}
                  mentionsList={mentionsList}
                  rulesCount={punchCommunicationRules.size}
                  schemaActions={schemaActions}
                  schemaTriggers={schemaTriggers}
                  updateCommunicationRuleAction={actions.updateCommunicationRuleAction}
                  expandCollapseCommunicationRule={actions.expandCollapseCommunicationRule}
                  updateCommunicationRuleTrigger={actions.updateCommunicationRuleTrigger}
                  validationErrors={validationErrors && validationErrors.get(index)}
                  submitActionToCustomer={actions.submitActionToCustomer}
                />
              ))
            )
          : null
        }
        <Grid.Row>
          <div className={'add-communication-rule'}>
            <a
              role="button"
              onClick={actions.addCommunicationRule}
              data-automation-id={`${automationIdPrefix}.addRule`}
            >
              <Icon className="como-ic-plus-in-circle" />
              {formatMessage({ id: 'activity.punchCard.communication.add' })}
            </a>
          </div>
        </Grid.Row>
      </Grid>);
  }
}

const mapStateToProps = (state) => ({
  mentionsList: mentionsSelectors.getTransformedMentions(state),
  schemaActions: selectors.getPunchCommunicationActions(state),
  schemaTriggers: selectors.getPunchCommunicationTriggers(state),
  validationErrors: selectors.getPunchCardCommunicationRulesErrors(state),
});

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({...punchCardActions, ...activityActions}, dispatch) };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl,
)(PunchCardCommunicationTab);
