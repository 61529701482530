/* eslint-disable no-underscore-dangle */
import * as api from './tracker.api';
import * as constants from './tracking.consts';

/**
 * Base class for tracker implementations
 * Implementation should override the _trackImpl function
 */
export class trackerBase {

  /*
   TODO - User Access level - An option could be to have a checkbox (default = No) if the user is employee . Dev to set all Como employee to this flag
  */

  constructor() {
    this.initialized = new Promise((resolve, reject) => {
      this.initializedDeffered = { resolve, reject };
    });
  }

  _trackImpl(event, trackArgs) {
    // just a placeholder for extending classes to override
  }

  _track(event, trackArgs) {
    this.initialized.then(() => {
      this._trackImpl(event, { ...trackArgs, ...this._getMetadata() });
    }).catch(() => {}); // suppress error messages
  }

  /**
   * Initializes the tracker object
   * Must be called once after axios configuration completed as it makes AJAX calls
   */
  initialize() {
    // get all relevant data and resolve the initialization deferred promise:
    api.fetchTrackerSettings().then((data) => {
      if (!data) {
        console.warn('no tracker config from back-end, will not initialize tracker.');
        this.initializedDeffered.reject();
        return;
      }
      const { businessName, businessId, businessStatus, salesForceAccountId, trackerConfig, vertical, user, features } = data;
      this.businessInfo = { businessId, businessName, salesForceAccountId, vertical, businessStatus };
      this.trackerConfig = trackerConfig;
      this.user = user;
      this.userFeatures = features;
      this.initializedDeffered.resolve();
    }).catch(this.initializedDeffered.reject);
  }

  /**
   * Provides a metadata map that should be included in all tracked events
   * @returns {{$email: *, userFeatures: *, browserLanguage: string}}
   */
  _getMetadata() {
    return {
      email: this.user.email,
      isComoUser: this.user.isComoUser,
      userFeatures: this.userFeatures,
      browserLanguage: window.navigator.language,
      ...this.businessInfo,
    };
  }

  /**
   * Log page loaded event
   * @param pageName - the name of the page
   * @param trackArgs - optional - map of additional arguments to log
   */
  onPageLoaded(pageName, trackArgs) {
    this._track(constants.ACTION_TYPE_PAGE_LOADED, { pageName, ...trackArgs });
  }

  /**
   * Creates a callback function that calls 'onButtonClick' and than invokes an action
   * @param button - the name of the button
   * @param callback - the callback to call
   * @param trackArgs - optional - map of additional arguments to log
   * @returns {function(...[*]): *}
   */
  wrapButtonAction(callback, button, trackArgs) {
    return (...args) => {
      this.onButtonClick(button, trackArgs);
      if (callback) {
        return callback(...args);
      }
      return null;
    };
  }

  /**
   * Log event other than button click (such as blur, key-press)
   * @param event - the name of the event
   * @param trackArgs - optional - map of additional arguments to log
   **/
  onEvent(event, trackArgs) {
    this._track(event, { ...trackArgs });
  }

  /**
   * Log button click event
   * @param button the name of the button
   * @param trackArgs optional - map of additional arguments to log
   */
  onButtonClick(button, trackArgs) {
    this._track(constants.ACTION_TYPE_BUTTON_CLICKED, { button, ...trackArgs });
  }
}
