import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import InputWrapper from '../../../../../../common/components/FormInputFields/InputWrapper/InputWrapper';
import * as schemaConstants from '../../../../activitySchema/activitySchema.constants';

class ExtraDataFields extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      key: '',
      value: '',
    };
  }

  static propTypes = {
    automationIdPrefix: PropTypes.string,
    keyLabel: PropTypes.string,
    valueLabel: PropTypes.string,
    onActionFieldUpdate: PropTypes.func.isRequired,
    extraData: PropTypes.object,
    index: PropTypes.number,
    validationErrors: PropTypes.object,
    hideKey: PropTypes.bool,
    removeItem: PropTypes.func.isRequired,
    showDeleteButton: PropTypes.bool,
    parentIndex: PropTypes.number
  };

  render() {
    const { index, onActionFieldUpdate, validationErrors, automationIdPrefix, extraData, hideKey, keyLabel, valueLabel, removeItem, showDeleteButton, parentIndex } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    return (
      <Grid.Row columns={2} className={'external-app-data-row'}>
        {!hideKey && <Grid.Column width={8}>
          <p className="action-label">{keyLabel || formatMessage({ id: 'activity.action.extra.data.key' })}</p>
          <InputWrapper
            noSpaces
            fluid
            onChange={(e, data) => onActionFieldUpdate(index, schemaConstants.SEND_PERSONAL_PUSH_EXTRA_DATA_KEY_FIELD, data.value)}
            name={`${schemaConstants.SEND_PERSONAL_PUSH_EXTRA_DATA}.${schemaConstants.SEND_PERSONAL_PUSH_EXTRA_DATA_KEY_FIELD}`}
            value={extraData ? extraData.get(schemaConstants.SEND_PERSONAL_PUSH_EXTRA_DATA_KEY_FIELD) : ''}
            prefix={`${automationIdPrefix}.${schemaConstants.SEND_PERSONAL_PUSH_EXTRA_DATA}.${schemaConstants.SEND_PERSONAL_PUSH_EXTRA_DATA_KEY_FIELD}`}
            error={validationErrors ? validationErrors.get(`${schemaConstants.SEND_PERSONAL_PUSH_EXTRA_DATA}.${index}.${schemaConstants.SEND_PERSONAL_PUSH_EXTRA_DATA_KEY_FIELD}`) : null}
          />
        </Grid.Column>}
        <Grid.Column width={8}>
          {showDeleteButton && <Icon
            style={{ cursor: 'pointer', float: 'right' }}
            className="icon como-ic-delete"
            onClick={() => { removeItem(index, parentIndex); }}
          />}
          <p className="action-label">{valueLabel || formatMessage({ id: 'activity.action.extra.data.value' })}</p>
          <InputWrapper
            fluid
            onChange={(e, data) => onActionFieldUpdate(index, schemaConstants.SEND_PERSONAL_PUSH_EXTRA_DATA_VALUE_FIELD, data.value)}
            name={`${schemaConstants.SEND_PERSONAL_PUSH_EXTRA_DATA}.${schemaConstants.SEND_PERSONAL_PUSH_EXTRA_DATA_VALUE_FIELD}`}
            value={extraData ? extraData.get(schemaConstants.SEND_PERSONAL_PUSH_EXTRA_DATA_VALUE_FIELD) : ''}
            prefix={`${automationIdPrefix}.${schemaConstants.SEND_PERSONAL_PUSH_EXTRA_DATA}.${schemaConstants.SEND_PERSONAL_PUSH_EXTRA_DATA_VALUE_FIELD}`}
            error={validationErrors ? validationErrors.get(`${schemaConstants.SEND_PERSONAL_PUSH_EXTRA_DATA}.${index}.${schemaConstants.SEND_PERSONAL_PUSH_EXTRA_DATA_VALUE_FIELD}`) : null}
          />
        </Grid.Column>
      </Grid.Row>
    );
  }
}

export default injectIntl(ExtraDataFields);
