import * as constants from './gifts.constants';
import * as activityConstants from '../Activity/activity.constants';

export const onFilterUpdate = (groupKey, key, status) => ({
  type: constants.UPDATE_FILTERS, groupKey, key, status
});
export const onSortUpdate = () => ({ type: constants.UPDATE_ORDER });
export const getGifts = () => ({ type: constants.GET_GIFTS });
export const goToGift = (giftId) => ({ type: constants.GO_TO_GIFT, [activityConstants.HUB_ID]: giftId });
export const search = (term) => ({ type: constants.SEARCH_GIFTS, [constants.SEARCH_TERM]: term });
export const createGift = () => ({ type: constants.CREATE_GIFT });
export const updateGift = (gift) => ({ type: constants.UPDATE_GIFT, gift });
export const goToGiftsList = () => ({ type: constants.GO_TO_GIFTS_LIST });
export const getGiftStats = (giftId) => ({ type: constants.GET_GIFT_STATS, [activityConstants.HUB_ID]: giftId });
export const cleanGiftStats = () => ({ type: constants.CLEAN_GIFT_STATS });
export const deleteGift = (giftId) => ({ type: constants.DELETE_GIFT, [activityConstants.HUB_ID]: giftId });
export const getGiftUsages = (giftId) => ({ type: constants.GET_GIFT_RELATED_ACTIVITIES, [activityConstants.HUB_ID]: giftId });
export const cleanGiftRelatedActivitiesData = () => ({ type: constants.CLEAN_GIFT_RELATED_ACTIVITIES_DATA });
