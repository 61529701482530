import { List } from 'immutable';
import * as _ from 'lodash';
import * as constants from '../../../activity.constants';
import * as genericActionConstants from '../../../../GenericAction/genericAction.contants';
import * as schemaConstants from '../../../activitySchema/activitySchema.constants';
import builtInAppScreens from '../../../../AppScreens/builtInLayoutScreens';
import { validateItemsPopulationData } from './conditions.validation.utils';
import * as genericActionSelectors from '../../../../GenericAction/genericAction.selectors';

function addRequiredFieldErrorIfNeeded(action, fieldName, actionValidationErrors) {
  if (!action.get(fieldName) || (List.isList(action.get(fieldName)) && action.get(fieldName).isEmpty())) {
    actionValidationErrors[fieldName] = 'activity.validation.error.activity.action.value.required';
  }
}

function addUrlFieldErrorIfNeeded(action, fieldName, actionValidationErrors) {
  if (action.get(fieldName) && !action.get(fieldName).match(/^http(s*):/i)) {
    actionValidationErrors[fieldName] = 'activity.generic.action.validation.error.invalid.url';
  }
}

function validateExtraDataFields(action, actionValidationErrors) {
  const extraDataFields = action.get('extraData');
  const duplicates = {};

  if (extraDataFields) {
    extraDataFields.forEach((extraDataField, i) => {
      if (!extraDataField.get('key') && !extraDataField.get('value') && (i !== 0 || extraDataFields.size > 1)) {
        actionValidationErrors[`extraData.${i}.key`] = 'activity.validation.error.activity.action.value.required';
        actionValidationErrors[`extraData.${i}.value`] = 'activity.validation.error.activity.action.value.required';
      }
      if (!extraDataField.get('key') && extraDataField.get('value')) {
        actionValidationErrors[`extraData.${i}.key`] = 'activity.validation.error.activity.action.value.required';
      }
      if (extraDataField.get('key') && !extraDataField.get('value')) {
        actionValidationErrors[`extraData.${i}.value`] = 'activity.validation.error.activity.action.value.required';
      }
      if (extraDataField.get('key') && extraDataField.get('value')) {
        duplicates[extraDataField.get('key')] = duplicates[extraDataField.get('key')] || [];
        duplicates[extraDataField.get('key')].push(i);
      }
    });

    for (const property in duplicates) {
      if (duplicates[property].length > 1) {
        duplicates[property].forEach((extraDataIndex, i) => {
          actionValidationErrors[`extraData.${extraDataIndex}.key`] = 'activity.validation.error.activity.action.value.duplicate';
        });
      }
    }
  }
}

function validateCustomHeaders(action, actionValidationErrors) {
  const customHeaders = action.get('customHeaders');
  const duplicates = {};

  if (customHeaders) {

    customHeaders.forEach((customHeader, i) => {
      if (!customHeader.get('key') && !customHeader.get('value') && (i !== 0 || customHeaders.size > 1)) {
        actionValidationErrors[`customHeaders.${i}.key`] = 'activity.validation.error.activity.action.value.required';
        actionValidationErrors[`customHeaders.${i}.value`] = 'activity.validation.error.activity.action.value.required';
      }
      if (!customHeader.get('key') && customHeader.get('value')) {
        actionValidationErrors[`customHeaders.${i}.key`] = 'activity.validation.error.activity.action.value.required';
      }
      if (customHeader.get('key') && !customHeader.get('value')) {
        actionValidationErrors[`customHeaders.${i}.value`] = 'activity.validation.error.activity.action.value.required';
      }
      if (customHeader.get('key') && customHeader.get('value')) {
        duplicates[customHeader.get('key')] = duplicates[customHeader.get('key')] || [];
        duplicates[customHeader.get('key')].push(i);
      }
    });

    for (const property in duplicates) {
      if (duplicates[property].length > 1) {
        duplicates[property].forEach((customHeaderIndex, i) => {
          actionValidationErrors[`customHeaders.${customHeaderIndex}.key`] = 'activity.validation.error.activity.action.value.duplicate';
        });
      }
    }
  }
}

function validateUpdateMembershipFields(action, actionValidationErrors) {
  const fieldsToUpdate = action.get('fieldsToUpdate');
  const duplicates = {};

  if (fieldsToUpdate) {
    fieldsToUpdate.forEach((field, i) => {
      if (!field.get('key')) {
        actionValidationErrors[`fieldsToUpdate.${i}.key`] = 'activity.validation.error.activity.action.value.required';
      }
      if (!field.get('value')) {
        actionValidationErrors[`fieldsToUpdate.${i}.value`] = 'activity.validation.error.activity.action.value.required';
      }

      if (field.get('key') && field.get('value')) {
        const key = field.get('key').trim();
        duplicates[key] = duplicates[key] || [];
        duplicates[key].push(i);
      }
    });

    for (const property in duplicates) {
      if (duplicates[property].length > 1) {
        duplicates[property].forEach((fieldIndex, i) => {
          actionValidationErrors[`fieldsToUpdate.${fieldIndex}.key`] = 'activity.validation.error.activity.action.value.duplicate';
        });
      }
    }
  }
}

function validateBodyCustomField(action, actionValidationErrors) {
  const customBodyFields = action.get('customBodyFields');
  const duplicates = {};

  if (customBodyFields) {
    customBodyFields.forEach((customBodyField, i) => {
      if (!customBodyField.get('key') && !customBodyField.get('value') && (i !== 0 || customBodyFields.size > 1)) {
        actionValidationErrors[`customBodyFields.${i}.key`] = 'activity.validation.error.activity.action.value.required';
        actionValidationErrors[`customBodyFields.${i}.value`] = 'activity.validation.error.activity.action.value.required';
      }
      if (!customBodyField.get('key') && customBodyField.get('value')) {
        actionValidationErrors[`customBodyFields.${i}.key`] = 'activity.validation.error.activity.action.value.required';
      }
      if (customBodyField.get('key') && !customBodyField.get('value')) {
        actionValidationErrors[`customBodyFields.${i}.value`] = 'activity.validation.error.activity.action.value.required';
      }

      if (customBodyField.get('key') && customBodyField.get('value')) {
        duplicates[customBodyField.get('key')] = duplicates[customBodyField.get('key')] || [];
        duplicates[customBodyField.get('key')].push(i);
      }

    });
    for (const property in duplicates) {
      if (duplicates[property].length > 1) {
        duplicates[property].forEach((customBodyIndex, i) => {
          actionValidationErrors[`customBodyFields.${customBodyIndex}.key`] = 'activity.validation.error.activity.action.value.duplicate';
        });
      }
    }

  }
}

function doesFieldIncludeMandatorySubString(action, fieldName, mandatorySubString) {
  const fieldValue = action.get(fieldName);
  return !(!fieldValue || !fieldValue.includes(mandatorySubString));
}

function addPositiveValueFieldErrorIfNeeded(action, fieldName, actionValidationErrors) {
  const fieldValue = action.get(fieldName);
  if (fieldValue === null || isNaN(fieldValue)) {
    addRequiredFieldErrorIfNeeded(action, fieldName, actionValidationErrors);
  } else if (fieldValue <= 0) {
    actionValidationErrors[fieldName] = 'activity.validation.error.activity.action.value.must.be.positive';
  }
}

function addNegativeValueFieldErrorIfNeeded(action, fieldName, actionValidationErrors) {
  const fieldValue = action.get(fieldName);
  if (fieldValue === null || isNaN(fieldValue)) {
    addRequiredFieldErrorIfNeeded(action, fieldName, actionValidationErrors);
  } else if (fieldValue < 0) {
    actionValidationErrors[fieldName] = 'activity.validation.error.activity.action.value.must.not.be.negative';
  }
}

function addPercentageValueFieldErrorIfNeeded(action, fieldName, actionValidationErrors) {
  const fieldValue = action.get(fieldName);
  if (fieldValue === null || isNaN(fieldValue)) {
    addRequiredFieldErrorIfNeeded(action, fieldName, actionValidationErrors);
  } else if (!(fieldValue > 0 && fieldValue < 101)) {
    actionValidationErrors[fieldName] = 'activity.validation.error.activity.action.percentage.value.out.of.range';
  }
}


function addItemsPopulationFieldErrorIfNeeded(action, fieldName, actionValidationErrors) {
  const itemPopulationErrors = validateItemsPopulationData(action.get(fieldName));
  if (!_.isEmpty(itemPopulationErrors)) {
    actionValidationErrors[fieldName] = itemPopulationErrors;
  }
}

function addLimitsErrorsIfNeeded(action, actionValidationErrors) {
  if (action.get(constants.LIMIT) && action.getIn([constants.LIMIT, constants.LIMIT_ACTIVE])) {
    if (!action.getIn([constants.LIMIT, constants.LIMIT_VALUE])) {
      if (action.getIn([constants.LIMIT, constants.LIMIT_VALUE]) === 0) {
        actionValidationErrors[constants.LIMIT] = 'activity.validation.error.activity.action.limit.must.be.positive';
      } else {
        actionValidationErrors[constants.LIMIT] = 'activity.validation.error.activity.action.limit.required';
      }
    }
  }
  if (action.get(constants.AMOUNT_LIMIT) && action.getIn([constants.AMOUNT_LIMIT, constants.LIMIT_ACTIVE])) {
    if (!action.getIn([constants.AMOUNT_LIMIT, constants.LIMIT_VALUE])) {
      if (action.getIn([constants.AMOUNT_LIMIT, constants.LIMIT_VALUE]) === 0) {
        actionValidationErrors[constants.AMOUNT_LIMIT] = 'activity.validation.error.activity.action.limit.must.be.positive';
      } else {
        actionValidationErrors[constants.AMOUNT_LIMIT] = 'activity.validation.error.activity.action.limit.required';
      }
    }
  }
}

function validateGenericAction(action, actionValidationErrors, rootState) {
  const genericActionErrors = {};
  const actionType = action.getIn([constants.GENERIC_ACTION_ARGS, genericActionConstants.BUTTON_ACTION_TYPE]);
  if (actionType === genericActionConstants.DO_NOTHING) {
    return;
  }
  if (!action.getIn([constants.GENERIC_ACTION_ARGS, genericActionConstants.GENERIC_ACTION_BUTTON_TEXT])) {
    genericActionErrors[genericActionConstants.GENERIC_ACTION_BUTTON_TEXT] = 'activity.validation.error.activity.action.value.required';
  }
  const actionValue = action.getIn([constants.GENERIC_ACTION_ARGS, genericActionConstants.BUTTON_ACTION_VALUE]);
  if (actionType !== genericActionConstants.DO_NOTHING && actionType !== genericActionConstants.RATE_APP) {
    if (!actionValue && actionType !== genericActionConstants.OPEN_THIS_ASSET_SCREEN && actionType !== genericActionConstants.SHARE_BUDGET) {
      genericActionErrors[genericActionConstants.BUTTON_ACTION_VALUE] = 'activity.validation.error.activity.action.value.required';
    } else if (actionType === genericActionConstants.OPEN_WEB_VIEW) {
      const customWebViewsRaw = genericActionSelectors.getCustomWebViews(rootState);
      const customWebViews = customWebViewsRaw ? customWebViewsRaw.toJS() : [];
      const customWebViewIds = customWebViews.map((view) => view[genericActionConstants.CUSTOM_WEB_VIEW_CONSTANT_ID]);
      if (!customWebViewIds.includes(parseInt(actionValue, 10))) {
        genericActionErrors[genericActionConstants.BUTTON_ACTION_VALUE] = 'activity.generic.action.validation.error.web.view.deleted';
      }
    } else if (actionType === genericActionConstants.NAVIGATE_TO_TARGET_TC) {
      const customScreensRaw = genericActionSelectors.getCustomScreens(rootState);
      const customScreens = customScreensRaw ? customScreensRaw.toJS() : [];
      const customScreenIds = customScreens.map((screen) => (`Custom_${screen[genericActionConstants.CUSTOM_APP_SCREEN_ID]}`));
      const builtInScreenIds = builtInAppScreens.map((screen) => screen.id);
      const possibleScreens = builtInScreenIds.concat(customScreenIds);
      if (!possibleScreens.includes(actionValue)) {
        genericActionErrors[genericActionConstants.BUTTON_ACTION_VALUE] = 'activity.generic.action.validation.error.screen.attribute.deleted';
      }
    } else if (actionType === genericActionConstants.OPEN_WEB_PAGE) {
      if (typeof (actionValue) !== 'string' || !actionValue.match(/^http(s*):/i)) {
        genericActionErrors[genericActionConstants.BUTTON_ACTION_VALUE] = 'activity.generic.action.validation.error.invalid.url';
      }
    } else if (actionType === genericActionConstants.SHARE_BUDGET) {
      if (!action.getIn([constants.GENERIC_ACTION_ARGS, genericActionConstants.SHARE_BUDGET_TYPE])) {
        genericActionErrors[genericActionConstants.SHARE_BUDGET_TYPE] = 'activity.validation.error.activity.action.value.required';
      } else if (action.getIn([constants.GENERIC_ACTION_ARGS, genericActionConstants.SHARE_BUDGET_TYPE]) === genericActionConstants.SHARE_BUDGET_TYPE_FIXED && !action.getIn([constants.GENERIC_ACTION_ARGS, genericActionConstants.SHARE_BUDGET_VALUE])) {
        genericActionErrors[genericActionConstants.SHARE_BUDGET_VALUE] = 'activity.validation.error.activity.action.value.required';
      } else if (action.getIn([constants.GENERIC_ACTION_ARGS, genericActionConstants.SHARE_BUDGET_TYPE]) === genericActionConstants.SHARE_BUDGET_TYPE_FIXED && action.getIn([constants.GENERIC_ACTION_ARGS, genericActionConstants.SHARE_BUDGET_VALUE]) <= 0) {
        genericActionErrors[genericActionConstants.SHARE_BUDGET_VALUE] = 'activity.validation.error.activity.action.value.negative';
      }
    }
  }
  if (Object.keys(genericActionErrors).length > 0) {
    actionValidationErrors[constants.GENERIC_ACTION_ARGS] = genericActionErrors;
  }
}

function validateApplyOnComponent(action, actionValidationErrors) {
  if (!action.get(constants.BUNDLE_ITEMS_MODE)) {
    actionValidationErrors[constants.BUNDLE_ITEMS_MODE] = 'activity.validation.error.deal.action.bundle.items.mode.required';
  }
  if (action.get(constants.BUNDLE_ITEMS_MODE) === constants.BUNDLE_ITEMS_MODE_SPECIFIC && action.get(constants.APPLY_ON).size === 0) {
    actionValidationErrors[constants.APPLY_ON] = 'activity.validation.error.deal.action.applyOn.required';
  }
}

function validateDiscountOnComponent(action, actionValidationErrors) {
  const discountOn = action.get(constants.DISCOUNT_ON);
  if (!discountOn || discountOn.getIn([constants.CONDITIONS, constants.CONDITIONS_LIST]).size === 0) {
    actionValidationErrors[constants.DISCOUNT_ON] = 'activity.validation.error.deal.action.applyOn.required';
  }
}

export function validateAction(action, rootState) {
  const actionValidationErrors = {};
  const actionType = action.get(constants.ACTION_TYPE);
  switch (actionType) {
    case schemaConstants.SEND_MEMBER_EMAIL_ACTION:
      addRequiredFieldErrorIfNeeded(action, schemaConstants.SEND_MEMBER_EMAIL_KEY_FIELD, actionValidationErrors);
      break;
    case schemaConstants.ASSIGN_ASSET_ACTION:
      addRequiredFieldErrorIfNeeded(action, schemaConstants.ASSIGN_ASSET_BENEFIT_KEY_FIELD, actionValidationErrors);
      addPositiveValueFieldErrorIfNeeded(action, schemaConstants.ASSIGN_ASSET_QUANTITY_FIELD, actionValidationErrors);
      break;
    case schemaConstants.DEDUCT_POINTS_ACTION:
    case schemaConstants.ACCUMULATE_POINTS_ACTION:
      // todo: test
      addPositiveValueFieldErrorIfNeeded(action, constants.POINTS, actionValidationErrors);
      if (action.get(constants.TYPE) === constants.ACCUMULATE_POINTS_TYPE_RATE) {
        addPositiveValueFieldErrorIfNeeded(action, constants.POINTS_PER_RATE, actionValidationErrors);
      }
      if (action.get(constants.TYPE) === constants.ACCUMULATE_POINTS_TYPE_RATE ||
        action.get(constants.TYPE) === constants.ACCUMULATE_POINTS_TYPE_PERCENTAGE) {
        addItemsPopulationFieldErrorIfNeeded(action, constants.ITEMS_POPULATION, actionValidationErrors);
        if (action.get(constants.EXCLUDE_ITEMS_POPULATION_STATUS) === true) {
          addItemsPopulationFieldErrorIfNeeded(action, constants.EXCLUDE_ITEMS_POPULATION, actionValidationErrors);
        }

        if (action.get(constants.EXCLUDED_MEANS_OF_PAYMENT_STATUS) === true) {
          const excludedMeanOfPaymentArrayValue = action.get(constants.EXCLUDED_MEANS_OF_PAYMENT);
          if (List.isList(excludedMeanOfPaymentArrayValue) && excludedMeanOfPaymentArrayValue.size === 0) {
            actionValidationErrors[constants.EXCLUDED_MEANS_OF_PAYMENT] = 'activity.validation.error.activity.action.value.required';
          }
        }
      }
      break;
    case schemaConstants.ADD_POINTS_ACTION:
      addPositiveValueFieldErrorIfNeeded(action, constants.POINTS, actionValidationErrors);
      break;
    case schemaConstants.TOP_UP_POINTS_ACTION:
      if (action.get(constants.POINTS_TYPE) === constants.ACCUMULATE_POINTS_TYPE_FIXED) {
        addPositiveValueFieldErrorIfNeeded(action, constants.POINTS, actionValidationErrors);
      } else if (action.get(constants.POINTS_TYPE) === constants.ACCUMULATE_POINTS_TYPE_TOP_UP) {
        addPositiveValueFieldErrorIfNeeded(action, constants.POINTS_TOP_UP_PERCENTS, actionValidationErrors);
      }
      break;
    case schemaConstants.DEDUCT_DYNAMIC_POINTS_ACTION:
      if (action.get(constants.POINTS_TYPE) === constants.ACCUMULATE_POINTS_TYPE_FIXED) {
        addPositiveValueFieldErrorIfNeeded(action, constants.POINTS, actionValidationErrors);
      } else if (action.get(constants.POINTS_TYPE) === constants.ACCUMULATE_POINTS_TYPE_DYNAMIC) {
        addRequiredFieldErrorIfNeeded(action, constants.DYNAMIC_POINTS_VALUE, actionValidationErrors);
      }
      break;
    case schemaConstants.EXPORT_EVENT_ACTION:
      addRequiredFieldErrorIfNeeded(action, schemaConstants.EXPORT_EVENT_NAME_FIELD, actionValidationErrors);
      addRequiredFieldErrorIfNeeded(action, schemaConstants.EXPORT_EVENT_DESTINATION_FIELD, actionValidationErrors);
      addUrlFieldErrorIfNeeded(action, schemaConstants.EXPORT_EVENT_DESTINATION_FIELD, actionValidationErrors);
      validateCustomHeaders(action, actionValidationErrors);
      validateBodyCustomField(action, actionValidationErrors);
      break;
    case schemaConstants.UPDATE_MEMBERSHIP_FIELDS_ACTION:
      validateUpdateMembershipFields(action, actionValidationErrors);
      break;
    case schemaConstants.WALLET_PASS_SYNC_ACTION:
    case schemaConstants.WALLET_PASS_PUSH_NOTIFICATION_ACTION:
      validateCustomHeaders(action, actionValidationErrors);
      validateBodyCustomField(action, actionValidationErrors);
      break;
    case schemaConstants.SEND_MEMBER_SMS_ACTION:
      addRequiredFieldErrorIfNeeded(action, constants.SMS_MESSAGE, actionValidationErrors);
      break;
    case schemaConstants.SEND_TC_CONSENT_SMS_OR_EMAIL_ACTION: {
      let messageFieldName = constants.SMS_MESSAGE;
      if (action.get(constants.CONSENT_TYPE) === constants.SEND_CONSENT_BY_EMAIL) {
        messageFieldName = constants.EMAIL_MESSAGE;
        addRequiredFieldErrorIfNeeded(action, constants.EMAIL_SUBJECT, actionValidationErrors);
      }
      /* if (!doesFieldIncludeMandatorySubString(action, messageFieldName, constants.CONSENT_LANDING_PAGE_LINK_MENTION)) {
        actionValidationErrors[constants.CONSENT_LANDING_PAGE_LINK_MENTION] = 'activity.validation.error.activity.action.consent.landingPageMention.missing';
        actionValidationErrors[messageFieldName] = 'activity.validation.error.activity.action.value.required';
      } */
      break;
    }
    case schemaConstants.SEND_PERSONAL_PUSH_ACTION:
      addRequiredFieldErrorIfNeeded(action, constants.PUSH_MESSAGE, actionValidationErrors);
      validateGenericAction(action, actionValidationErrors, rootState);
      validateExtraDataFields(action, actionValidationErrors);
      break;
    case schemaConstants.SEND_MESSAGE_ACTION:
      addRequiredFieldErrorIfNeeded(action, constants.POPUP_SUBJECT, actionValidationErrors);
      addRequiredFieldErrorIfNeeded(action, constants.POPUP_MESSAGE, actionValidationErrors);
      addRequiredFieldErrorIfNeeded(action, constants.MESSAGE_MAX_AGE_IN_DAYS, actionValidationErrors);
      if (!action.get(constants.MESSAGE_TRIGGER_BY_OPENING_THE_APP) &&
        !action.get(constants.MESSAGE_TRIGGER_BY_NAVIGATION_TO_HOME_SCREEN) &&
        !action.get(constants.MESSAGE_TRIGGER_BY_AFTER_REDEEM) &&
        !action.get(constants.MESSAGE_TRIGGER_BY_AFTER_PUNCH) &&
        !action.get(constants.MESSAGE_TRIGGER_BY_AFTER_COUPON_CODE_ACTION)) {
        actionValidationErrors[constants.MESSAGE_TRIGGER_BY] =
          'activity.in.app.message.validation.error.please.select.at.least.one.trigger';
      }
      validateGenericAction(action, actionValidationErrors, rootState);
      break;
    case schemaConstants.AUTO_PUNCH_ACTION:
      addRequiredFieldErrorIfNeeded(action, constants.PUNCH_CARD_KEY, actionValidationErrors);
      addPositiveValueFieldErrorIfNeeded(action, schemaConstants.AUTO_PUNCH_NUM_OF_PUNCHES, actionValidationErrors);
      break;
    case schemaConstants.PUNCH_EXCEEDED_ACTION:
      addRequiredFieldErrorIfNeeded(action, constants.PUNCH_CARD_KEY, actionValidationErrors);
      break;
    case schemaConstants.TAG_MEMBERSHIP_ACTION:
    case schemaConstants.UN_TAG_MEMBERSHIP_ACTION:
    case schemaConstants.DYNAMIC_TAG_MEMBERSHIP_ACTION:
    case schemaConstants.DYNAMIC_UN_TAG_MEMBERSHIP_ACTION:
      if (action.get(constants.TAGS_TYPE) === constants.TAGS_TYPE_DYNAMIC) {
        addRequiredFieldErrorIfNeeded(action, constants.DYNAMIC_TAGS_VALUE, actionValidationErrors);
      } else {
        addRequiredFieldErrorIfNeeded(action, schemaConstants.TAG_MEMBERSHIP_TAG_FIELD, actionValidationErrors);
      }
      break;
    case schemaConstants.UPDATE_MEMBERSHIP_EXPIRATION_ACTION:
      addPositiveValueFieldErrorIfNeeded(action, schemaConstants.UPDATE_MEMBERSHIP_EXPIRATION_DURATION_VALUE_FIELD, actionValidationErrors);
      break;
    case schemaConstants.REGISTER_MEMBERSHIP_ACTION:
      break;
    case schemaConstants.DISCOUNT_AMOUNT_OFF_ON_ENTIRE_TICKET_ACTION:
      addPositiveValueFieldErrorIfNeeded(action, constants.DISCOUNT_VALUE, actionValidationErrors);
      if (action.get(constants.EXCLUDE_ITEMS_POPULATION_STATUS) === true) {
        addItemsPopulationFieldErrorIfNeeded(action, constants.EXCLUDE_ITEMS_POPULATION, actionValidationErrors);
      }
      break;
    case schemaConstants.DISCOUNT_AMOUNT_OFF_ON_SPECIFIC_ITEMS_ACTION:
      addPositiveValueFieldErrorIfNeeded(action, constants.DISCOUNT_VALUE, actionValidationErrors);
      validateDiscountOnComponent(action, actionValidationErrors);
      break;
    case schemaConstants.DISCOUNT_AMOUNT_OFF_ON_SPECIFIC_ITEMS_ADVANCED_ACTION:
      addPositiveValueFieldErrorIfNeeded(action, constants.DISCOUNT_VALUE, actionValidationErrors);
      validateApplyOnComponent(action, actionValidationErrors);
      break;
    case schemaConstants.DISCOUNT_PERCENT_OFF_ON_ENTIRE_TICKET_ACTION:
      addPercentageValueFieldErrorIfNeeded(action, constants.DISCOUNT_VALUE, actionValidationErrors);
      if (action.get(constants.EXCLUDE_ITEMS_POPULATION_STATUS) === true) {
        addItemsPopulationFieldErrorIfNeeded(action, constants.EXCLUDE_ITEMS_POPULATION, actionValidationErrors);
      }
      break;
    case schemaConstants.DISCOUNT_PERCENT_OFF_ON_SPECIFIC_ITEMS_ACTION:
      addPercentageValueFieldErrorIfNeeded(action, constants.DISCOUNT_VALUE, actionValidationErrors);
      validateDiscountOnComponent(action, actionValidationErrors);
      break;
    case schemaConstants.DISCOUNT_PERCENT_OFF_ON_SPECIFIC_ITEMS_ADVANCED_ACTION:
      addPercentageValueFieldErrorIfNeeded(action, constants.DISCOUNT_VALUE, actionValidationErrors);
      validateApplyOnComponent(action, actionValidationErrors);
      break;
    case schemaConstants.DISCOUNT_FREE_ITEMS_ACTION:
      validateDiscountOnComponent(action, actionValidationErrors);
      break;
    case schemaConstants.DISCOUNT_FREE_ITEMS_ADVANCED_ACTION:
      validateApplyOnComponent(action, actionValidationErrors);
      break;
    case schemaConstants.DISCOUNT_SPECIAL_PRICE_ACTION:
      addNegativeValueFieldErrorIfNeeded(action, constants.DISCOUNT_VALUE, actionValidationErrors);
      validateDiscountOnComponent(action, actionValidationErrors);
      break;
    case schemaConstants.DISCOUNT_SPECIAL_PRICE_ADVANCED_ACTION:
      addNegativeValueFieldErrorIfNeeded(action, constants.DISCOUNT_VALUE, actionValidationErrors);
      validateApplyOnComponent(action, actionValidationErrors);
      break;
    case constants.SEND_CODES_TO_POS:
      addRequiredFieldErrorIfNeeded(action, constants.CODES, actionValidationErrors);
      break;
    default:
      break;
  }
  addLimitsErrorsIfNeeded(action, actionValidationErrors);
  return actionValidationErrors;
}
