import moment from 'moment';
import * as constants from '../pointShopItem.constants';
const VALIDATION_VALUE_REQUIRED = 'activity.validation.error.activity.action.value.required';

export function validatePointShopItem(state, isDraft) {
  const validationErrors = {};

  if (isDraft) {
    if (!state.name.length) {
      validationErrors['name'] = VALIDATION_VALUE_REQUIRED;
    }
  } else {
    if (!state.name.length) {
      validationErrors['name'] = VALIDATION_VALUE_REQUIRED;
    }
    if (!state.description.length) {
      validationErrors['description'] = VALIDATION_VALUE_REQUIRED;
    }
    if (state.price === null) {
      validationErrors['price'] = VALIDATION_VALUE_REQUIRED;
    }

    if (state.pointShopItemMode === constants.POINT_SHOP_ITEM_MODE_CUSTOM) {
      if (state.assetTemplate === null) {
        validationErrors['assetTemplate'] = VALIDATION_VALUE_REQUIRED;
      }
    }
    if (state.pointShopItemMode === constants.POINT_SHOP_ITEM_MODE_CLASSIC) {
      if (!state.itemGroupsData.conditions.conditionsList.length) {
        validationErrors['itemGroupsData'] = VALIDATION_VALUE_REQUIRED;
      }
    }
    if (moment(state.validFrom) > moment(state.validUntil)) {
      validationErrors['dateTimeRange'] = 'activity.validation.error.activity.action.dateRangeInvalid';
    }
  }
  return validationErrors;
}

function resetValidation(state) {
  return state.delete(constants.POINT_SHOP_ITEM_VALIDATION_ERRORS);
}
