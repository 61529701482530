import { createSelector } from 'reselect';

import * as constants from './benefits.constants';

export const getBenefitsList = (state) => state.getIn([constants.BENEFITS, constants.BENEFITS_LIST]);
export const getBenefitsListFetchedFlag = (state) => state.getIn([constants.BENEFITS, constants.BENEFITS_LIST_LOADED]);

/**
 * Finds a specific benefit from the benefits list.
 * This function doesn't use cache, so use getSelectedBenefit wherever possible since it does.
 * @param benefits
 * @param selectedBenefitId
 * @returns {*}
 */
export const getSelectedBenefitFromList = (benefits, selectedBenefitId) =>
  benefits.find((benefit) => benefit.get(constants.BENEFIT_SERVER_ID) === selectedBenefitId);

export const getSelectedBenefit = createSelector(
  [getBenefitsList, (state, benefitId) => benefitId], getSelectedBenefitFromList);

