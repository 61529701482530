import * as activityConstants from '../../activity.constants';
import * as itemsPopulationConstants from './itemsPopulation.constants';
import * as itemGroupsConstants from '../../../ItemGroups/itemGroups.constants';
import { removeNewLineCharacters } from '../../../../utils/textFormat';

/**
 * set the display name for the selected values by type
 */
export function getItemsPopulationDisplayList(itemsPopulation, itemGroups, formatMessage, punchCardDefaultOptionLabel) {

  let valueDisplay = formatMessage({ id: 'activity.itemsPopulation.emptyState' });

  if (itemsPopulation[activityConstants.TYPE]) {
    switch (itemsPopulation[activityConstants.TYPE]) {

      case itemsPopulationConstants.ITEMS_POPULATION_TYPE_ANY: {
        valueDisplay = formatMessage({ id: 'activity.itemsPopulation.any' });
        break;
      }

      case itemsPopulationConstants.ITEMS_POPULATION_TYPE_ITEM_CODES:
      case itemsPopulationConstants.ITEMS_POPULATION_TYPE_DEPARTMENT_CODES: {
        if (itemsPopulation[activityConstants.CONDITIONS][activityConstants.CONDITIONS_LIST] && itemsPopulation[activityConstants.CONDITIONS][activityConstants.CONDITIONS_LIST].length) {
          valueDisplay = itemsPopulation[activityConstants.CONDITIONS][activityConstants.CONDITIONS_LIST][0][activityConstants.CONDITION_VALUE].filter((val) => val !== '').join(', ');
        }
        break;
      }

      case itemsPopulationConstants.ITEMS_POPULATION_TYPE_ITEM_GROUPS: {
        if (itemsPopulation[activityConstants.CONDITIONS][activityConstants.CONDITIONS_LIST] && itemsPopulation[activityConstants.CONDITIONS][activityConstants.CONDITIONS_LIST].length && itemGroups) {
          const groupIds = itemsPopulation[activityConstants.CONDITIONS][activityConstants.CONDITIONS_LIST][0][activityConstants.CONDITION_VALUE];
          const groupNames = groupIds.reduce((namesArray, id) => {
            const newNamesArray = [...namesArray];
            const name = itemGroups.filter((group) => group.get(itemGroupsConstants.SERVER_ID) === id).getIn([0, activityConstants.NAME]);
            if (name) {
              newNamesArray.push(name);
            }
            return newNamesArray;
          }, []);
          valueDisplay = groupNames.join(', ');
        }
        break;
      }

      case itemsPopulationConstants.ITEMS_POPULATION_TYPE_ADVANCED_RULES: {
        valueDisplay = formatMessage({ id: 'activity.itemsPopulation.advancedRule' });
        break;
      }

      case itemsPopulationConstants.ITEMS_POPULATION_TYPE_PUNCH_CARD_DEFAULT_DISCOUNT: {
        if (punchCardDefaultOptionLabel) {
          valueDisplay = punchCardDefaultOptionLabel;
        } else if (itemsPopulation[activityConstants.CONDITIONS][activityConstants.CONDITIONS_LIST] && itemsPopulation[activityConstants.CONDITIONS][activityConstants.CONDITIONS_LIST].length) {
          valueDisplay = itemsPopulation[activityConstants.CONDITIONS][activityConstants.CONDITIONS_LIST][0][activityConstants.CONDITION_VALUE].filter((val) => val !== '').join(', ');
        }

        break;
      }

      default:
        break;
    }
  }

  return removeNewLineCharacters(valueDisplay);
}

export function getItemsPopulationContentOptions(formatMessage, posFeature, disableAny, punchCardDefaultOptionLabel) {

  const anyItem = {
    label: formatMessage({ id: 'activity.itemsPopulation.any' }),
    type: itemsPopulationConstants.ITEMS_POPULATION_TYPE_ANY,
    note: formatMessage({ id: 'activity.itemsPopulation.any.note' })
  };
  const itemCodes = {
    label: formatMessage({ id: 'activity.itemsPopulation.itemCodes' }),
    type: itemsPopulationConstants.ITEMS_POPULATION_TYPE_ITEM_CODES,
    note: null,
    features: [{ name: posFeature }]
  };
  const departmentCodes = {
    label: formatMessage({ id: 'activity.itemsPopulation.departmentCodes' }),
    type: itemsPopulationConstants.ITEMS_POPULATION_TYPE_DEPARTMENT_CODES,
    note: null,
    features: [{ name: posFeature }]
  };
  const itemGroups = {
    label: formatMessage({ id: 'activity.itemsPopulation.itemGroups' }),
    type: itemsPopulationConstants.ITEMS_POPULATION_TYPE_ITEM_GROUPS,
    note: null,
    features: [{ name: posFeature }]
  };
  const advancedRule = {
    label: formatMessage({ id: 'activity.itemsPopulation.advancedRule' }),
    type: itemsPopulationConstants.ITEMS_POPULATION_TYPE_ADVANCED_RULES,
    note: null,
    features: [{ name: posFeature }]
  };
  const punchCardDefaultOptionLabelValue = {
    label: punchCardDefaultOptionLabel,
    type: itemsPopulationConstants.ITEMS_POPULATION_TYPE_PUNCH_CARD_DEFAULT_DISCOUNT,
    note: null
  };
  const basicItemsPopulationOptions = [].concat(itemCodes).concat(departmentCodes).concat(advancedRule);
  const allItemsPopulationOptions = [].concat(itemCodes).concat(departmentCodes).concat(itemGroups).concat(advancedRule);
  if (!disableAny) {
    allItemsPopulationOptions.unshift(anyItem);
  }
  if (punchCardDefaultOptionLabel) {
    allItemsPopulationOptions.unshift(punchCardDefaultOptionLabelValue);
  }
  return { basicItemsPopulationOptions, allItemsPopulationOptions };
}
