import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Icon } from 'semantic-ui-react';

import '../microCampaigns.styles.less';
import '../../Activity/views/cases/cases.styles.less';
import * as numbersFormatUtils from '../../../utils/numbersFormat';

export class StatisticsDetailsSection extends React.PureComponent {

  static propTypes = {
    intl: PropTypes.object,
    automationIdPrefix: PropTypes.string,
    titleKey: PropTypes.string,
    formatTitleKey: PropTypes.bool,
    membersBarWidth: PropTypes.number,
    membersNumber: PropTypes.number,
    revenue: PropTypes.number,
    redeemRate: PropTypes.number,
    revenuePerMember: PropTypes.number,
    currencySymbol: PropTypes.string
  };

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    return (
      <div className="details-part">
        <p className="subtitle">{this.props.formatTitleKey ? formatMessage({id: this.props.titleKey}) : this.props.titleKey}</p>
        <div>
          <Icon className="como-svg-icon-members" size="large" />
          <span className="small-title">{formatMessage({ id: 'microCampaigns.statistics.members' })}</span>
          <div className="members-bar">
            <div style={{ width: this.props.membersBarWidth }}></div>
            <span title={numbersFormatUtils.displayNumberWithCommas(this.props.membersNumber)}>{numbersFormatUtils.formatByValue(this.props.membersNumber)}</span>
          </div>
        </div>
        <div>
          <div className="one-third">
            <Icon className="como-svg-icon-wallet" size="large" />
            <span className="small-title">{formatMessage({ id: 'microCampaigns.statistics.revenue' })}</span>
            <div className="numbers" title={numbersFormatUtils.displayNumberWithCommas(this.props.revenue)}>{`${this.props.currencySymbol}${numbersFormatUtils.formatByValue(this.props.revenue)}`}</div>
          </div>
          <div className="one-third">
            <Icon className="como-svg-icon-gift" size="large" />
            <span className="small-title">{formatMessage({ id: 'microCampaigns.statistics.redeemRate' })}</span>
            <div className="numbers" title={numbersFormatUtils.displayNumberWithCommas(this.props.redeemRate)}>{`${numbersFormatUtils.formatByValue(this.props.redeemRate, 2, true)}%`}</div>

          </div>
          <div className="one-third">
            <Icon className="como-svg-icon-revPerMember" size="large" />
            <span className="small-title">{formatMessage({ id: 'microCampaigns.statistics.revenuePerMember' })}</span>
            <div className="numbers" title={numbersFormatUtils.displayNumberWithCommas(this.props.revenuePerMember)}>{`${this.props.currencySymbol}${numbersFormatUtils.formatByValue(this.props.revenuePerMember, 2, true)}`}</div>
          </div>
        </div>
      </div>
    );
  }
}


export default injectIntl(StatisticsDetailsSection);
