/*
 *
 * Benefits reducer
 *
 */

import { fromJS } from 'immutable';
import * as constants from './benefits.constants';
import * as activityConstants from '../Activity/activity.constants';
import * as giftsConstants from '../Gifts/gifts.constants';

const initialState = fromJS({
  [constants.BENEFITS_LIST]: [],
  [constants.BENEFITS_LIST_LOADED]: false,
});

function benefitsReducer(state = initialState, action) {
  switch (action.type) {

    case constants.GET_BENEFITS_SUCCESS: {
      return state
        .set(constants.BENEFITS_LIST, fromJS(action.data))
        .set(constants.BENEFITS_LIST_LOADED, true);
    }

    case activityConstants.SAVE_ACTIVITY_SUCCESS: {
      if ([activityConstants.SERVER_TYPE_GIFT, activityConstants.SERVER_TYPE_PUNCH_CARD].includes(action.data.type)) {
        return state.set(constants.BENEFITS_LIST_LOADED, false);
      }
      return state;
    }

    case giftsConstants.UPDATE_GIFT_SUCCESS: {
      return state.set(constants.BENEFITS_LIST_LOADED, false);
    }

    default:
      return state;

  }
}

export default benefitsReducer;
