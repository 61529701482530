import _ from 'lodash';
import { delay } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import * as constants from './promoCodes.constants';
import * as api from './promoCodes.api';
import * as errorConstant from '../ErrorMessage/errorMessage.constants';
import * as routeConstants from '../../constants/route.contants';
import * as activityActions from '../Activity/activity.actions';
import * as activityConstants from '../Activity/activity.constants';
import { getLocationId } from '../App/selectors';

import * as appActions from '../App/app.actions';
import * as queryStringUtils from '../../utils/queryStringUtils';

function* getPromoCodes() {
  try {
    // debounce by 50ms
    yield delay(50);
    const data = yield call(api.getPromoCodes);
    yield put({ type: constants.GET_PROMO_CODES_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getPromoCodes.Fail'] });
  }
}

function* getPromoCode(action) {
  try {
    // debounce by 50ms
    yield delay(50);
    const data = yield call(api.getPromoCode, action[activityConstants.HUB_ID]);
    yield put({ type: constants.GET_PROMO_CODES_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getPromoCode.Fail'] });
  }
}

function* goToPromoCode(action) {
  yield put({ type: activityConstants.RESET_ACTIVITY_DATA });
  yield put(push({
    pathname: `/promoCodes/${action[activityConstants.HUB_ID]}`,
    search: location.search,
    state: { type: action.promoCodeType }
  }));
}

function* createPromoCode(data) {
  const locationId = yield select(getLocationId);
  yield put(activityActions.createActivity(locationId, null, null, null,
    activityConstants.ACTIVITY_TYPE_PROMO_CODE));
  yield put(push({
    pathname: routeConstants.PROMO_CODES_CREATE_ROUTE,
    search: location.search,
    state: { type: data.promoCodeType }
  }));
}

function* updatePromoCode(action) {
  try {
    const data = yield call(api.savePromoCode, action[constants.PROMO_CODE].toJS());
    yield put({ type: constants.UPDATE_PROMO_CODE_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['updatePromoCode.Fail'] });
  }
}

function* updatePromoCodeStatus(action) {
  try {
    const data = yield call(api.savePromoCode, action[constants.PROMO_CODE].toJS());
    yield put({ type: constants.UPDATE_PROMO_CODE_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['updatePromoCode.Fail'] });
  }
}

function* changePromoCodeStatus(action) {
  const promoCodeData = action[constants.PROMO_CODE].toJS();
  if (promoCodeData && (promoCodeData.isDeactivated && promoCodeData.status === 'active')) {
    yield put({
      type: errorConstant.HANDLE_ERROR,
      [errorConstant.MESSAGES_KEYS]: ['updatePromoCode.deactivated.Fail']
    });
  } else {
    const data = yield call(api.updatePromoCodeStatus, promoCodeData);
    yield put({ type: constants.UPDATE_PROMO_CODE_SUCCESS, data: promoCodeData });
  }
}

function* goToPromoCodesList() {
  const locationSearch = queryStringUtils.removeParameter(location.search, activityConstants.MIGRATION_ID);
  yield put(push({
    pathname: routeConstants.PROMO_CODES_ROUTE,
    search: locationSearch,
  }));
}

function* getPromoCodeStats(action) {
  try {
    let data;
    yield put({ type: constants.CLEAN_PROMO_CODE_STATS });
    if (action[activityConstants.PROMO_CODE_BULKS_TYPE] === activityConstants.PROMO_CODE_MODE_GENERATED) {
      data = yield call(api.getPromoCodeBulksStats, action[activityConstants.HUB_ID]);
    }else{
      data = yield call(api.getPromoCodeStats, action[activityConstants.HUB_ID]);
    }
    yield put({type: constants.GET_PROMO_CODE_STATS_SUCCESS, data});
  } catch (err) {
    yield put({type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getPromoCodeStats.Fail']});
  }
}

function* deletePromoCode(action) {
  try {
    yield call(api.deletePromoCode, action[activityConstants.HUB_ID]);
    yield put({ type: constants.DELETE_PROMO_CODE_SUCCESS, [activityConstants.HUB_ID]: action[activityConstants.HUB_ID] });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['deletePromoCode.Fail'] });
  }
}

function* extendPromoCode(action) {
  try {
    yield call(api.extendPromoCode, action[constants.PROMO_CODE]);
    yield put({ type: constants.EXTEND_PROMO_CODE_SUCCESS, [activityConstants.HUB_ID]: action[activityConstants.HUB_ID] });
    yield put({ type: constants.GET_PROMO_CODES});
    goToPromoCodesList();
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['extendPromoCode.Fail'] });
  }
}

function* getPromoCodeRelatedActivities(action) {
  try {
    yield put({ type: constants.CLEAN_PROMO_CODE_RELATED_ACTIVITIES_DATA, data });
    const data = yield call(api.getPromoCodeRelatedActivities, action[activityConstants.HUB_ID]);
    yield put({ type: constants.GET_PROMO_CODE_RELATED_ACTIVITIES_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getPromoCodeRelatedActivities.Fail'] });
  }
}

function* handleErrorMessageClose(action) {
  if (action.shouldGoBack === activityConstants.PATH_PROMO_CODES) {
    yield put({ type: constants.GO_TO_PROMO_CODES_LIST });
    yield put({ type: errorConstant.RESET_ERROR_LEAVE_PATH });
  }
}

function* setInitialPromoCodeSingleBulkValue() {
  try {
    const data = yield call(api.getPromoCodeSuggestion);
    yield put({
      type: activityConstants.UPDATE_PROMO_CODE_SINGLE_CODE_VALUE, value: data.code, bulkIndex: 0, dontMarkModelAsDirty: true
    });
  } catch (err) {
    if (_.get(err, 'response.status') !== 403) {
      yield put(appActions.setLoadingState(false));
      yield put({
        type: errorConstant.HANDLE_ERROR,
        [errorConstant.MESSAGES_KEYS]: ['getPromoCodeSuggestion.Fail'],
        [errorConstant.LEAVE_ON_ERROR_CLOSE]: activityConstants.PATH_PROMO_CODES
      });
    }
  }
}

function* checkCodeIsAvailable(action) {
  try {
    const data = yield call(api.checkCodeIsAvailable, action.value);
    yield put({
      type: activityConstants.UPDATE_PROMO_CODE_BULK_IS_AVAILABILITY, value: data.available, dontMarkModelAsDirty: true
    });
  } catch (err) {
    if (_.get(err, 'response.status') !== 403) {
      yield put(appActions.setLoadingState(false));
      yield put({
        type: errorConstant.HANDLE_ERROR,
        [errorConstant.MESSAGES_KEYS]: ['getPromoCodeSuggestion.Fail'],
        [errorConstant.LEAVE_ON_ERROR_CLOSE]: activityConstants.PATH_PROMO_CODES
      });
    }
  }
}

function* getFreeCodesNumber(payload) {
  try {
    const data = yield call(api.getFreeCodesNumber, payload.codeLength);

    yield put({ type: activityConstants.GET_FREE_PROMO_CODES_NUMBER_SUCCESS, value: data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getPromoCodeStats.Fail'] });
  }
}

function* getBukCodesList(action) {
  try {
    const data = yield call(api.getBukCodesList, action.bulkKey, action.serverId);

    yield put({ type: constants.GET_BULK_CODES_LIST_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getBukCodesList.Fail'] });
  }
}

function* getBulkExport(action) {
  try {
    yield put({ type: constants.BULK_EXPORT_LOADING_STATUSES_ADD, key: action.bulkKey });
    const data = yield call(api.getExportBulk, action.bulkKey, action.serverId);

    yield put({ type: constants.GET_BULK_CODES_LIST_SUCCESS, data });
    yield put({ type: constants.BULK_EXPORT_LOADING_STATUSES_REMOVE, key: action.bulkKey });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getBukCodesList.Fail'] });
    yield put({ type: constants.BULK_EXPORT_LOADING_STATUSES_REMOVE, key: action.bulkKey });
  }
}

// sagas listen to dispatched actionsList too (same as reducers)
export default function* PromoCodesSaga() {
  yield [
    takeLatest(constants.GET_PROMO_CODE, getPromoCode),
    takeLatest(constants.GET_PROMO_CODES, getPromoCodes),
    takeLatest(constants.GET_BULK_CODES_LIST, getBukCodesList),
    takeLatest(constants.GET_BULKS_EXPORT, getBulkExport),
    takeLatest(constants.GO_TO_PROMO_CODE, goToPromoCode),
    takeLatest(constants.CREATE_PROMO_CODE, createPromoCode),
    takeLatest(constants.UPDATE_PROMO_CODE, updatePromoCode),
    takeLatest(constants.CHANGE_PROMO_CODE_STATUS, changePromoCodeStatus),
    takeLatest(constants.GO_TO_PROMO_CODES_LIST, goToPromoCodesList),
    takeLatest(constants.GET_PROMO_CODE_STATS, getPromoCodeStats),
    takeLatest(constants.GET_PROMO_CODE_RELATED_ACTIVITIES, getPromoCodeRelatedActivities),
    takeLatest(constants.DELETE_PROMO_CODE, deletePromoCode),
    takeLatest(constants.EXTEND_PROMO_CODE, extendPromoCode),
    takeLatest(errorConstant.HANDLE_ERROR_MESSAGE_CLOSE, handleErrorMessageClose),
    takeLatest(activityConstants.SET_INITIAL_SINGLE_PROMO_CODE, setInitialPromoCodeSingleBulkValue),
    takeLatest(activityConstants.CHECK_PROMO_CODE_BULK_IS_AVAILABLE, checkCodeIsAvailable),
    takeLatest(activityConstants.GET_PROMO_CODE_FREE_CODES_NUMBER, getFreeCodesNumber),
  ];
}
