import * as constants from './mailTemplates.constants';

export function getMailTemplates() {
  return {
    type: constants.GET_MAIL_TEMPLATES
  };
}

export function getMailSenderName() {
  return {
    type: constants.GET_MAIL_SENDER_NAME
  };
}

export function clearNewlyCreatedEmail() {
  return {
    type: constants.CLEAR_NEWLY_CREATED_EMAIL
  };
}
