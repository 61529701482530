import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {compose} from "redux";
import { Button, Grid, Icon } from 'semantic-ui-react';

import Modal from '../../../common/components/CustomModal/CustomModal';
import * as schemaConstants from "../../Activity/activitySchema/activitySchema.constants";
import ConditionListOfValues from "../../Activity/views/conditions/conditionListOfValues";
import * as appSelectors from "../../App/selectors";


class MembershipTagCondition extends React.PureComponent {

  // eslint-disable-next-line no-undef
  static propTypes = {
    autoCompleteOptions: PropTypes.object,
    tags: PropTypes.array,
    triggerModal: PropTypes.func,
    handleTagsApply: PropTypes.func,
  }

  state = {
    tags: [],
    isModalOpen: false,
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.tags.length) {
      this.setState({ tags: nextProps.tags });
    }
  }

  get triggerMessage() {
    // eslint-disable-next-line react/prop-types
    const { formatMessage } = this.props.intl;
    let triggerMessage = formatMessage({ id: 'activity.globalConditions.toActiveMembers' });
    if (this.state.tags.length) {
      triggerMessage = formatMessage({ id: 'activity.globalConditions.toSpecificMembers' });
    }
    return triggerMessage;
  }

  get trigger() {
    return (
      <div className="modal-trigger">
        <a
          role="link"
          className={'condition-link'}
          onClick={this.props.triggerModal}
        >
          <Icon className="como-ic-members" data-automation-id="globalConditions.members" />
          {this.triggerMessage}
        </a>
      </div>
    );
  }


  get parsedAutoCompleteOptions() {
    let options = [];
    if (this.props.autoCompleteOptions) {
      options = this.props.autoCompleteOptions.toJS().map((option) => ({ key: option, text: option, value: option }));
    }
    this.state.tags.forEach((tag) => options.push({key: tag, text: tag, value: tag}));
    return options;
  }


  onActionFieldUpdate = (data) => {
    this.setState({ tags: data.value });
  };

  apply = () => {
    this.props.handleTagsApply(this.state.tags);
    this.props.triggerModal();
  };

  render() {
    const { formatMessage } = this.props.intl;

    return (
      <Modal
        className={'pointShop-conditions-modal'}
        automationIdPrefix={'AUTOMATION_NAME'}
        header={
          formatMessage({ id: 'activity.globalConditions.members' })
        }
        actions={[
          <Button
            key="globalMemConditionCancelBtn"
            onClick={this.props.triggerModal}
            basic
            className="link modal-button"
            disabled={this.props.filtersInProgress}
            data-automation-id="globalConditions.members.btnCancel"
          >
            {formatMessage({ id: 'general.button.cancel' })}
          </Button>,
          <Button
            key="globalMemConditionSaveFilterCriteria"
            onClick={this.apply}
            className="modal-button stick-to-left point-shop-condition-save-btn"
            data-automation-id="globalConditions.members.btnSaveFilterCriteria"
          >
            {formatMessage({ id: 'general.button.save' })}
          </Button>,
        ]}
        trigger={this.trigger}
        content={
          <div>
            <Grid className="member-fields-conditions gray-panel">
              <Grid.Row>
                <div className="left-sided-label-text">
                  {formatMessage({ id: 'point-shop-page.tags.condition.label' })}
                </div>
                <ConditionListOfValues
                  type={schemaConstants.VALUE_TYPE_AUTO_COMPLETE}
                  dynamicOptions={this.parsedAutoCompleteOptions}
                  multiple
                  allowAdditions
                  className="auto-complete-wrapper"
                  value={this.state.tags}
                  onChange={(e, data) => this.onActionFieldUpdate(data)}
                  hasAutoComplete
                  prefix={''}
                />
              </Grid.Row>
            </Grid>
          </div>
        }
        size={960}
        closeIcon={<Icon className="como-ic-close" name="close" onClick={this.props.triggerModal} />}
        open={this.props.isModalOpen}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  autoCompleteOptions: appSelectors.getBusinessTagsWithoutRfm(state)
});

const withConnect = connect(mapStateToProps);


export default compose(withConnect, injectIntl)(MembershipTagCondition);
