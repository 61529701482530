import { fromJS } from 'immutable';
import * as constants from './agentConfiguration.constants';

const initialState = fromJS({});


function agentConfigurationReducer(state = initialState, action) {

  let newState;

  switch (action.type) {
    case constants.SET_AGENT_CONFIGURATION_DATA_IS_LOADING :
      newState = state.set(constants.AGENT_CONFIGURATION_DATA_LOADING, action.isLoading);
      break;
    case constants.GET_AGENT_CONFIGURATION_SUCCESS :
      newState = state.set(constants.AGENT_CONFIGURATION_DATA, fromJS(action.data.configuration));
      break;
    case constants.ON_CONFIGURATION_FIELD_CHANGE:
      const { field, value } = action.payload;
      newState = state.setIn([constants.AGENT_CONFIGURATION_DATA, constants.CONFIGURATIONS, field], fromJS(value));
      break;
    case constants.SAVING_CONFIGURATION:
      newState = state.set(constants.SAVING, true);
      break;
    case constants.CONFIGURATION_SAVE_SUCCESS:
      newState = state.set(constants.SAVING, false)
        .set(constants.SAVED_CONFIGURATION, true);
      break;
    case constants.CLOSE_SAVED_CONFIGURATION_POPUP_MESSAGE:
      newState = state.set(constants.SAVED_CONFIGURATION, false);
      break;
    case constants.ERROR_OCCURRED:
      newState = state.set(constants.ERROR, true);
      break;
    default:
      newState = state;
  }

  return newState;
}

export default agentConfigurationReducer;
