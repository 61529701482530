import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import * as constants from '../emails.constants';
import * as featuresConstants from '../../features.constants';
import EmailStatsDetailed from './EmailStatsDetailed';

class EmailStatsOnetime extends React.PureComponent {
  static propTypes = {
    onetimeStats: PropTypes.object,
    campaignStatistics: PropTypes.object,
    getDetailedStatsPerRow: PropTypes.func,
    showCampaignStats: PropTypes.bool,
    formatDate: PropTypes.func
  };

  render() {
    const {formatMessage} = this.props.intl; // eslint-disable-line
    return (
      <Grid.Row>
        <Grid.Column width={16}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                {formatMessage({ id: 'email-stats.sentFromOnetime' })}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="stats-title ">
              <Grid.Column width={6}>
                <span
                  className="log--title stats name"
                  data-automation-id="email-stats.name.key"
                >{formatMessage({ id: 'email-stats.name.title' })}</span>
              </Grid.Column>
              <Grid.Column width={5}>
                <span
                  className="log--title stats date"
                  data-automation-id="email-stats.date.key"
                >{formatMessage({ id: 'email-stats.date.title' })}</span>
              </Grid.Column>
              <Grid.Column width={5}>
                <span
                  className="log--title stats "
                  data-automation-id="email-stats.count.key"
                >{formatMessage({ id: 'email-stats.count.title' })}</span>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className={`stats-data ${this.props.onetimeStats && this.props.onetimeStats.size ? '' : 'no-border'}`}>
              <Grid.Column width={16}>
                {
                  this.props.onetimeStats && this.props.onetimeStats.map((statsElem, index) => (
                    <Grid key={`statsElem_${index.toString()}`} className="stats-elem-wrap">
                      <Grid.Row className="email-statistics-row link" onClick={() => this.props.getDetailedStatsPerRow(statsElem.get(constants.EMAIL_CAMPAIGN_ID), 'oneTime')}>
                        <Grid.Column className="ellipsis" width={6}>{statsElem.get(constants.EMAIL_STATS_ACTIVITY_NAME) ? statsElem.get(constants.EMAIL_STATS_ACTIVITY_NAME) : formatMessage({ id: 'email-stats.name.noName' })}</Grid.Column>
                        <Grid.Column width={5}>{this.props.formatDate(statsElem.get(constants.EMAIL_STATS_DATE))}</Grid.Column>
                        <Grid.Column width={4}>{statsElem.get(constants.EMAIL_STATS_COUNT)}</Grid.Column>
                        <Grid.Column width={1} className="toggle-campaign-stats">
                          <Icon className={`${this.props.campaignStatistics && this.props.campaignStatistics.get(featuresConstants.DATA) && this.props.campaignStatistics.get(constants.EMAIL_CAMPAIGN_ID) === statsElem.get(constants.EMAIL_CAMPAIGN_ID) && this.props.showCampaignStats ? 'como-ic-angle-up' : 'como-ic-angle-down'}`}></Icon>
                        </Grid.Column>
                      </Grid.Row>
                      { this.props.campaignStatistics && this.props.campaignStatistics.get(featuresConstants.DATA) && this.props.campaignStatistics.get(constants.EMAIL_CAMPAIGN_ID) === statsElem.get(constants.EMAIL_CAMPAIGN_ID) &&
                      <Grid.Row className={`campaign-stats ${this.props.showCampaignStats ? 'show' : 'hide'}`}>
                        <Grid.Column width={16}>
                          <div>
                            {
                              this.props.campaignStatistics && this.props.campaignStatistics.get(featuresConstants.DATA) && this.props.campaignStatistics.get(featuresConstants.DATA).map((statsCampaignElem, campIndex) => (
                                <EmailStatsDetailed
                                  key={`stats_${campIndex}`}
                                  statsCampaignElem={statsCampaignElem}
                                  campIndex={campIndex}
                                />
                              ))
                            }
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                      }

                    </Grid>))
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    );
  }
}
export default injectIntl(EmailStatsOnetime);
