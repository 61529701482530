/**
*
* ImageUpload
*
*/

import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import * as constants from '../../images.constants';
import injectSaga from '../../../../utils/injectSaga';
import saga from '../../images.saga';
import * as imagesActions from '../../images.actions';
import { uploadInProgressSelector } from '../../images.selectors';
import * as reducerConstants from '../../../../constants/reducer.constants';
import FileUploader from '../../../../common/components/FileUploader/FileUploader';

class ImageUpload extends React.PureComponent {

  static propTypes = {
    actions: PropTypes.object.isRequired,
    allowMultiple: PropTypes.bool,
    automationIdPrefix: PropTypes.string,
    imageUploadInstructionsKey: PropTypes.string,
    intl: PropTypes.object,
    uploadInProgress: PropTypes.bool,
    validTypes: PropTypes.string,
  };

  static defaultProps = {
    allowMultiple: true,
    locationId: PropTypes.number.isRequired,
    validTypes: constants.VALID_TYPES
  };

  /**
   * handler for trying to upload files
   * @param accepted
   * @param rejected
   */
  onDrop = (accepted, rejected) => {
    if (rejected.length) {
      this.props.actions.displayUploadError();
    } else if (accepted.length) {
      this.props.actions.uploadImages(accepted);
    }
  };

  render() {

    const { formatMessage } = this.props.intl;

    return (
      <FileUploader
        allowMultiple={this.props.allowMultiple}
        automationIdPrefix={this.props.automationIdPrefix}
        displayUploadError={this.props.actions.displayUploadError}
        fileUploadInstructionsKey={this.props.imageUploadInstructionsKey}
        fileUploadLabelKey={"imageGallery.upload.label"}
        maxFileSize={constants.MAX_FILE_SIZE}
        uploadFiles={this.props.actions.uploadImages}
        uploadInProgress={this.props.uploadInProgress}
        validTypes={this.props.validTypes}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  uploadInProgress: uploadInProgressSelector(state),
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(imagesActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.IMAGES_BRANCH, saga });

export default compose(
  withConnect,
  withSaga,
  injectIntl
)(ImageUpload);
