import * as constants from './itemGroups.constants';

export function getItemGroups() {
  return { type: constants.GET_ITEM_GROUPS };
}

export function createItemsGroup(itemsGroup) {
  return {
    type: constants.CREATE_ITEMS_GROUP,
    itemsGroup,
  };
}

export function updateItemsGroup(itemsGroup) {
  return {
    type: constants.UPDATE_ITEMS_GROUP,
    itemsGroup
  };
}

export function deactivateItemsGroup(itemsGroupId, serverId) {
  return {
    type: constants.DEACTIVATE_ITEMS_GROUP,
    itemsGroupId,
    serverId
  };
}
