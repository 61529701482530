import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './MultipleItems.styles.less';

const AUTOMATION_NAME = 'carousel.multiple.items';

const settings = {
  dots: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3
};

class MultipleItems extends Component {

  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
  }

  render() {
    const { children, className, ...restProps } = this.props;
    return (
      <Slider
        className={`carousel__multiple-items ${className}`}
        {...settings}
        {...restProps}
        data-automation-id={AUTOMATION_NAME}
      >
        {children}
      </Slider>
    );
  }
}

export default MultipleItems;
