import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Popover, Transition } from '@headlessui/react';
import { ArrowPathIcon, ChevronDownIcon, CursorArrowRaysIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import ToneOfVoice from './views/ToneOfVoice';
import Instructions from './views/Instructions';
import Languages from './views/Languages';
import ResponseContent from './views/ResponseContent';
import Tooltip from '../../common/components/Tooltip/Tooltip';
import PromptLength from './views/PromptLength';

import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import * as appConstants from '../App/app.constants';
import * as activityConstants from '../Activity/activity.constants';
import * as constants from './ai.constants';
import * as aiActions from './ai.actions';
import * as selectors from './selectors';
import reducer from './ai.reducer';
import saga from './ai.saga';

import './aiPrompt.less';

const AiPopover = (props) => {
  const { intl, showLengthPrompt = false, getPermissionLevel, onTextUpdate, isLoading, charLength, prePromptKey, mainButtonTextKey, justifyContent, titleToolTipKey, prompts = {}, actions } = props;

  const hasFeature = getPermissionLevel(appConstants.FEATURE_ARTIFICIAL_INTELLIGENCE) !== activityConstants.FEATURE_LEVEL.NONE;

  const [selectedToneOfVoice, setSelectedToneOfVoice] = useState('');
  const [selectedPromptLength, setSelectedPromptLength] = useState('regular');
  const [userPrompt, setUserPrompt] = useState('');
  const [instructions, setInstructions] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [buttonText, setButtonText] = useState('Generate Text');
  const [responseScreen, setResponseScreen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [customLanguage, setCustomLanguage] = useState('');
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [textRegenerate, setTextRegenerate] = useState(null);

  const { formatMessage } = intl;

  const promptsData = prompts.toJS() || {};
  const promptsList = promptsData.data ? promptsData.data : [];

  useEffect(() => {
    if (promptsList.length > 0) {
      // The API request was successful
      setResponseScreen(true);
      setTextRegenerate(null);
    } else {
      setResponseScreen(false);
      setTextRegenerate(null);
    }
  }, [responseScreen, promptsList]);

  const onReturnBack = () => {
    actions.clearPromptText();
    setButtonText('Generate Text');
  };

  const getCharLength = () => {
    switch (selectedPromptLength) {
      case 'regular':
        return charLength;
      case 'short':
        return charLength / 2;
      case 'long':
        return charLength * 2;
      default:
        return charLength;
    }
  };

  const SendForm = async (e) => {
    e.preventDefault();

    setButtonText(`${formatMessage({ id: 'vertex.ai.btn-txt' })}...`);
    setTextRegenerate(formatMessage({ id: 'vertex.ai.txt-regenerate' }));

    const selectedTone = selectedToneOfVoice ? formatMessage({ id: 'vertex.ai.selected-tone-of-voice' }, { selectedToneOfVoice }) : '';
    const userInstructions = instructions ? formatMessage({ id: 'vertex.ai.selected-instructions' }, { instructions }) : '';
    const businessNameInput = businessName ? formatMessage({ id: 'vertex.ai.selected-business' }, { businessName }) : '';
    const language = selectedLanguage ? formatMessage({ id: 'vertex.ai.selected-language' }, { selectedLanguage: customLanguage || selectedLanguage }) : '';

    const prompt = `${formatMessage({ id: prePromptKey })}, ${userInstructions}, ${businessNameInput}, ${selectedTone}, ${language}: Text: ${userPrompt}`;
    const data = {
      'instances': [
        {
          'prompt': prompt
        }
      ],
      'parameters': {
        'candidateCount': 3,
        'temperature': 0.6,
        'maxOutputTokens': getCharLength(),
        'topK': 40,
        'topP': 0.95
      }
    };

    actions.getPromptText(data);
  };

  return !hasFeature ? (
      <></>
  ) : (
    <Popover className="tw-relative">
      {({ open, close }) => (
        <div className={'tw-flex'}>
          <Popover.Button
            className={`${open ? '' : 'tw-text-opacity-90'} ai-popover-btn tw-group tw-flex tw-flex-grow tw-justify-between tw-self-center tw-items-center tw-min-w-1/3 tw-rounded-md tw-bg-orange-700 tw-px-3 tw-py-2 tw-text-base tw-font-medium tw-text-white hover:tw-text-opacity-100 focus:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-white focus-visible:tw-ring-opacity-75`}
          >
            <span className={`tw-flex tw-flex-grow ${justifyContent}`}>
              <CursorArrowRaysIcon className="tw-mr-2 tw-w-6" />
              {formatMessage({ id: mainButtonTextKey })}
            </span>
            <ChevronDownIcon
              className={`${open ? '' : 'tw-text-opacity-70'}
                          ai-dropdown-icon tw-ml-2 tw-h-5 tw-w-5 tw-text-orange-300 tw-transition tw-duration-150 tw-ease-in-out group-hover:tw-text-opacity-80`}
              aria-hidden="true"
            />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="tw-transition tw-ease-out tw-duration-200"
            enterFrom="tw-opacity-0 tw-translate-y-1"
            enterTo="tw-opacity-100 tw-translate-y-0"
            leave="tw-transition tw-ease-in tw-duration-150"
            leaveFrom="tw-opacity-100 tw-translate-y-0"
            leaveTo="tw-opacity-0 tw-translate-y-1"
          >
            <Popover.Panel
              className="tw-absolute tw-z-10 tw-mt-3 tw-w-2 tw-w-80 tw-bg-white tw-px-4 sm:tw-px-0"
              style={{
                ...(justifyContent === 'tw-justify-end' ? { right: 0, top: '20px' } : { left: 0, top: '20px' })
              }}
            >
              {responseScreen ? (
                <ResponseContent
                  textRegenerate={textRegenerate}
                  sendForm={SendForm}
                  isLoading={isLoading}
                  setSelectedItemIndex={setSelectedItemIndex}
                  setIsCopied={setIsCopied}
                  onTextUpdate={onTextUpdate}
                  selectedItemIndex={selectedItemIndex}
                  isCopied={isCopied}
                  onReturnBack={onReturnBack}
                  close={close}
                />
              ) : (
                <div
                  className="min-h-[530px] tw-overflow-hidden tw-p-2 tw-rounded-lg tw-shadow-lg tw-ring-1 tw-flex tw-flex-col tw-justify-start tw-items-center tw-ring-black tw-ring-opacity-5"
                >
                  <span className={'tw-text-left tw-my-2 tw-flex tw-w-full tw-items-baseline'}>
                    {formatMessage({ id: 'vertex.ai.generate-txt-using-ai' })}

                    <Tooltip content={formatMessage({ id: titleToolTipKey })} trigger={<InformationCircleIcon className={'tw-w-4 fill-none tw-fill-none tw-ml-1'} />} position="bottom right" />
                  </span>
                  <form onSubmit={SendForm} className={'tw-w-full'}>
                    {/* text area for user input */}
                    <div className={'tw-text-sm tw-mt-2 tw-flex tw-flex-col'}>
                      <span className={'tw-my-2'}>{formatMessage({ id: 'vertex.ai.write-text' })}</span>
                      <textarea
                        className="tw-textarea tw-textarea-bordered tw-h-36 tw-color-gray-900"
                        placeholder={formatMessage({ id: 'vertex.ai.write-text-placeholder' })}
                        value={userPrompt}
                        onChange={(e) => setUserPrompt(e.target.value)}
                      ></textarea>
                    </div>
                    {showLengthPrompt && <PromptLength selectedPromptLength={selectedPromptLength} setSelectedOption={setSelectedPromptLength} />}
                    <ToneOfVoice selectedOption={selectedToneOfVoice} setSelectedOption={setSelectedToneOfVoice} />
                    <Instructions instructions={instructions} setInstructions={setInstructions} />
                    <input value={businessName} onChange={(e) => setBusinessName(e.target.value)} name={'BusinessName'} type={'text'} placeholder={formatMessage({ id: 'vertex.ai.business-name-placeholder' })} className={'input input-bordered w-[280px] my-4 mb-4'} />
                    <Languages selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} customLanguage={customLanguage} setCustomLanguage={setCustomLanguage} />
                    <button
                      disabled={isLoading}
                      className={`ai-submit-btn tw-btn tw-w-full tw-capitalize tw-mt-2 tw-rounded tw-h-12 tw-text-white tw-flex tw-items-center tw-justify-center ${isLoading ? 'tw-cursor-not-allowed' : ''}`}
                    >
                      {isLoading ? (
                        <ArrowPathIcon className={'tw-w-6 tw-mr-1 fill-none tw-fill-none tw-animate-spin'} />
                      ) : (
                        <CursorArrowRaysIcon className="tw-mr-1 tw-w-4" />
                      )}
                      {buttonText}
                    </button>
                  </form>
                </div>
              )
              }
            </Popover.Panel>
          </Transition>
        </div>
      )}
    </Popover>
  );
};

AiPopover.propTypes = {
  intl: PropTypes.object,
  showLengthPrompt: PropTypes.bool,
  onTextUpdate: PropTypes.func,
  charLength: PropTypes.number,
  justifyContent: PropTypes.string,
  prePromptKey: PropTypes.string,
  mainButtonTextKey: PropTypes.string,
  titleToolTipKey: PropTypes.string,
  getPermissionLevel: PropTypes.func.isRequired,
  actions: PropTypes.object,
  prompts: PropTypes.object,
  isLoading: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    prompts: selectors.getPrompts(state),
    isLoading: selectors.getIsLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(aiActions, dispatch)
  };
}

const withReducer = injectReducer({ key: constants.AI, reducer });
const withSaga = injectSaga({ key: constants.AI, saga });
const WithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(AiPopover));
const WithIntl = injectIntl(WithRouter);
const WithSaga = withSaga(WithIntl);
const WithReducer = withReducer(WithSaga);

export default WithReducer;
