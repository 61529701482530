export const ACTION_TYPE_PAGE_LOADED = 'Page Loaded';
export const ACTION_TYPE_BUTTON_CLICKED = 'Button Clicked';

export const PAGE_TYPE_ACTIVITY = 'Activity';
export const PAGE_TYPE_GOALS = 'Goals';
export const PAGE_TYPE_TEMPLATES = 'Templates';
export const PAGE_TYPE_BUSINESS_CENTER = 'Business Center';
export const PAGE_TYPE_GIFTS = 'Gifts';
export const PAGE_TYPE_POINT_SHOP = 'Point Shop';
export const PAGE_TYPE_PROMO_CODES = 'Promo Codes';
export const PAGE_TYPE_FORBIDDEN = 'Forbidden';
export const PAGE_TYPE_ALL_DASHBOARDS = 'All Dashboards';
export const PAGE_TYPE_MAIN_DASHBOARD = 'Main Dashboard';
export const PAGE_TYPE_ANALYTICS = 'Analytics';
export const PAGE_TYPE_DATA_EXPLORER = 'Data Explorer';
export const PAGE_TYPE_NOT_FOUND = 'Not found';
export const SOMETHING_WENT_WRONG = 'Something went wrong';
export const PAGE_TYPE_MICRO_CAMPAIGNS = 'Micro campaigns';

export const EVENT_TYPE_CAMPAIGN_NAME_CHANGED = 'Campaign name changed';
export const EVENT_TYPE_VALIDATION_ERRORS = 'Validation Errors';
export const EVENT_TYPE_SAVE_ACTIVITY_SERVER_ERRORS = 'Save activity server errors';
export const EVENT_TYPE_SAVE_EMAIL_SERVER_ERRORS = 'Save email server errors';
export const EVENT_TYPE_PUBLISH_ACTIVITY = 'Activity publish';
export const EVENT_TYPE_BUSINESS_TEMPLATE_SAVED = 'Saved business template';
export const EVENT_TYPE_TRIGGER_SELECTED = 'Trigger selected';
export const EVENT_TYPE_TRIGGER_SELECTION_CONFIRMED = 'Trigger selection confirmed';
export const EVENT_TYPE_ACTION_SELECTED = 'Action selected';
export const EVENT_TYPE_CONDITION_SELECTED = 'Condition selected';
export const EVENT_TYPE_CONDITION_ADD = 'Add condition';
export const EVENT_TYPE_REPEAT_MODE_SELECTED = 'Selected repeat mode';
export const EVENT_TYPE_SIMPLE_MODE_SELECTED = 'Selected simple mode';
export const EVENT_TYPE_CAMPAIGNS_FILTERS_CHANGED = 'Campaign center filters changed';
export const EVENT_TYPE_TRIGGER_FILTERS_CHANGED = 'Campaign center trigger filters changed';
export const EVENT_TYPE_ACTION_FILTERS_CHANGED = 'Campaign center action filters changed';
export const EVENT_TYPE_CREATING_ONE_TIME_ACTION = 'Creating new one-time-action';

export const BUTTON_TYPE_TEMPLATE_SELECTED = 'Template selected';
export const BUTTON_TYPE_CAMPAIGN_ADD = 'Add campaign';
export const BUTTON_TYPE_CAMPAIGN_ARCHIVE = 'Archive campaign';
export const BUTTON_TYPE_CAMPAIGN_UN_ARCHIVE = 'Un-archive campaign';
export const BUTTON_TYPE_CAMPAIGNS_SORT = 'Sort campaigns';
export const BUTTON_TYPE_ACTIVITY_LOADED = 'Open existing activity';
export const BUTTON_TYPE_GIFT_LOADED = 'Open existing gift';
export const BUTTON_TYPE_SIDE_NAR_NAVIGATION = 'Sidebar navigation';
export const BUTTON_TYPE_SHOW_ACTIVITY_DETAILS = 'Show activity details';
export const BUTTON_TYPE_SHOW_EMAIL_DETAILS = 'Show email details';
export const BUTTON_TYPE_SHOW_EMAIL_ANALYSIS = 'Show email analysis';
export const BUTTON_TYPE_SHOW_EMAIL_USAGES = 'Show email usages';
export const BUTTON_TYPE_SHOW_GIFT_ANALYSIS = 'Show gift analysis';
export const BUTTON_TYPE_SHOW_GIFT_USAGES = 'Show gift usages';
export const BUTTON_TYPE_SHOW_RULE_ANALYSIS = 'Show rule analysis';
export const BUTTON_TYPE_OPEN_IN_NEW_TAB = 'Open in new tab';
export const BUTTON_TYPE_SHOW_MOVE_ACTIVITY_DIALOG = 'Show move activity dialog';
export const BUTTON_TYPE_MOVE_ACTIVITY = 'Move activity';
export const BUTTON_TYPE_DELETE_ACTIVITY = 'Delete activity';
export const BUTTON_TYPE_RESTART_ACTIVITY = 'Restart activity';
export const BUTTON_TYPE_STOP_ACTIVITY = 'Stop activity';
export const BUTTON_TYPE_PAUSE_ACTIVITY = 'Pause activity';
export const BUTTON_TYPE_RE_ACTIVATE_ACTIVITY = 'Re-activate activity';
export const BUTTON_TYPE_EXTEND_ACTIVITY = 'Extend activity';
export const BUTTON_TYPE_ADD_ACTIVITY = 'Add activity';
export const BUTTON_TYPE_BACK = 'Back';
export const BUTTON_TYPE_CANCEL = 'Cancel';
export const BUTTON_TYPE_SAVE = 'Save';
export const BUTTON_TYPE_SAVE_AS_DRAFT = 'Save as draft';
export const BUTTON_TYPE_CASES_SPLIT_TO_CASES = 'Split to cases';
export const BUTTON_TYPE_CASES_CANCEL_SPLIT = 'Cancel split to cases';
export const BUTTON_TYPE_AB_TEST_START = 'Create AB testing';
export const BUTTON_TYPE_AB_TEST_CANCEL = 'Cancel AB testing';
export const BUTTON_TYPE_CASE_ADD = 'Add case';
export const BUTTON_TYPE_CASE_DELETE = 'Delete case';
export const BUTTON_TYPE_CASE_CLEAR = 'Clear case';
export const BUTTON_TYPE_CASE_CHANGE_PRIORITY = 'Change case priority';
export const BUTTON_TYPE_OPEN_GOALS = 'Open goals page';
export const BUTTON_TYPE_SELECT_GOAL = 'Select goal';
export const BUTTON_TYPE_SELECT_GOAL_CAMPAIGN = 'Select goal campaign';
export const BUTTON_TYPE_CREATE_GOAL_CAMPAIGN = 'Create goal campaign';
export const BUTTON_TYPE_CLOSE_GOALS_WIZARD = 'Close goals wizard';
export const BUTTON_TYPE_BACK_TO_GOALS_SELECTION_PAGE = 'Back to goals selection page';
export const BUTTON_TYPE_GIFT_CREATE = 'Create gift';
export const BUTTON_TYPE_POINT_SHOP_ITEM_CREATE = 'Create point shop item';
export const BUTTON_TYPE_CREATE_ONE_TIME_ACTIVITY = 'Create one time activity';
export const BUTTON_TYPE_FILTER_POPULATION = 'Filter population';
export const BUTTON_CLEAR_FILTER_POPULATION = 'Clear filter population';
export const BUTTON_TYPE_SAVE_FILTER_CRITERIA = 'Save filter criteria';
export const BUTTON_FILTER_ALL_MEMBERS = 'Filter all members';
export const BUTTON_TYPE_PROMO_CODE_LOADED = 'Open existing promo-code';
export const BUTTON_TYPE_PROMO_CODE_CREATE = 'Create promo code';
export const BUTTON_TYPE_EMAIL_CREATE = 'Create email';
export const CONTEXT = 'context';
export const CONTEXT_TYPE_ACTIVITY = 'activity';
export const CONTEXT_TYPE_ACTIVITY_CASES = 'cases';
export const CONTEXT_TYPE_MEMBERSHIP_GLOBAL_CONDITION = 'membership global condition';
export const ACTIVITY_HUB_ID = 'activityHubId';
export const ACTIVITY_SERVER_ID = 'activityServerId';
export const ACTIVITY_TYPE = 'activityType';
export const ACTIVITY_STATUS = 'activityStatus';
export const ACTIVITY_NAME = 'activityName';
export const BUTTON_TYPE_EMAIL_LOADED = 'emailLoaded';
export const TEMPLATE_START_FROM_SCRATCH = 'Start from scratch';

export const EVENT_SOURCE_ENTER_KEY = 'Enter key';

export const ACTIVITY_PUBLISH_ARGS_EXISTING_ACTIVITY = 'isExistingActivity';
export const ACTIVITY_PUBLISH_ARGS_PUBLISHED_BEFORE = 'publishedBefore';
export const CONDITION_SELECTED_ARGS_CONDITION_KEY = 'conditionKey';
export const BUSINESS_TEMPLATE_SAVED_ARGS_ACTIVITY_TYPE = 'activityType';
export const VALIDATION_ERRORS_ARGS_VALIDATION_ERRORS = 'validationErrors';
export const SAVE_ACTIVITY_SERVER_ERRORS_ARGS_ERRORS = 'serverErrors';
export const SAVE_EMAIL_SERVER_ERRORS_ARGS_ERRORS = 'serverErrors';
export const TRIGGER_SELECTED_ARGS_TRIGGER = 'trigger';
export const ACTION_SELECTED_ARGS_ACTION_KEY = 'actionKey';
export const CAMPAIGN_NAME_CHANGED_ARGS_SOURCE = 'source';
export const CAMPAIGN_NAME = 'campaign name';
export const GOAL_NAME = 'goal name';

export const LINK_BUSINESS_CENTER = 'Business center link';

export const BUTTON_TYPE_SHOW_MICRO_CAMPAIGNL_DETAILS = 'Show microCampaign details';
export const BUTTON_TYPE_SHOW_MICRO_CAMPAIGNL_USAGES = 'Show microCampaign usages';

export const BUTTON_TYPE_ADJUST_BY_COMILLIA_HINT = 'Adjust population filter by comillia hint';
export const BUTTON_TYPE_IGNORE_COMILLIA_HINT = 'Ignore Comillia hint';
export const BUTTON_TYPE_CLOSE_COMILLIA_HINT = 'Close Comillia hint';

// microCampaigns
export const BUTTON_TYPE_MICRO_CAMPAIGN_CASE_DELETE = 'Delete AI campaign case';
export const BUTTON_TYPE_MICRO_CAMPAIGN_CASE_CLEAR = 'Clear AI campaign case';
export const BUTTON_TYPE_MICRO_CAMPAIGN_SAVE_AS_DRAFT = 'Save as draft AI campaign';

// External Links
export const EVENT_TYPE_CLICK_KNOWLEDGE_BASE = 'Clicked Knowledge Base';
export const EVENT_TYPE_CLICK_COMO_ACADEMY = 'Clicked Como Academy';
export const EVENT_TYPE_CLICK_FAQS = 'Clicked Como faqs';
export const EVENT_TYPE_CLICK_WHATS_NEW = 'Clicked Whats New';
export const EVENT_TYPE_CLICK_CONTACT_US = 'Clicked Contact Us';
