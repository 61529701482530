import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Grid, Loader } from 'semantic-ui-react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import classNames from 'classnames';

import injectSaga from '../../../utils/injectSaga';
import BenefitsList from '../../Benefits/views/benefitsList/benefitsList';
import InputWrapper from '../../../common/components/FormInputFields/InputWrapper/InputWrapper';
import DropDownWrapper from '../../../common/components/DropdownWrapper/DropdownWrapper';
import CheckBox from '../../../common/components/FormInputFields/CheckboxWrapper/CheckboxWrapper';
import * as constants from '../genericAction.contants';
import * as genericActionActions from '../genericAction.actions';
import * as selectors from '../genericAction.selectors';
import saga from '../genericAction.saga';
import './genericAction.styles.less';
import PointsInputField from '../../../common/components/FormInputFields/PointsInputField/PointsInputField';


class GenericAction extends React.PureComponent {

  static propTypes = {
    actionData: PropTypes.object,
    assetContext: PropTypes.string,
    index: PropTypes.number,
    validationErrors: PropTypes.object,
    intl: PropTypes.object.isRequired,
    screens: PropTypes.object,
    webViews: PropTypes.array,
    catalogs: PropTypes.array,
    automationPrefix: PropTypes.string.isRequired,
    onUpdate: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    doNothingLabelStringId: PropTypes.string,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    doNothingLabelStringId: 'activity.generic.action.button.actions.do.nothing',
  };

  constructor(props) {
    // noinspection JSCheckFunctionSignatures
    super(props);
    this.handleAssetSelected = this.handleAssetSelected.bind(this);
    this.handleActionSelected = this.handleActionSelected.bind(this);
    this.onActionFieldUpdate = this.onActionFieldUpdate.bind(this);
  }

  componentWillMount() {
    // noinspection JSUnresolvedFunction
    this.props.actions.getCustomAppScreensData();
    // noinspection JSUnresolvedFunction
    this.props.actions.getCustomWebViewsData();
    // noinspection JSUnresolvedFunction
    this.props.actions.getCatalogs();
  }

  onActionFieldUpdate(fieldName, value) {
    this.props.onUpdate(this.props.actionData.set(fieldName, value), this.props.index);
  }

  handleAssetSelected(e, data) {
    const newVal = this.props.actionData
      .set(constants.BUTTON_ACTION_VALUE, data.value)
      .set(constants.ASSET_TYPE, data.type)
      .set(constants.ASSET_KEY, data.serverEntityKey);
    this.props.onUpdate(newVal, this.props.index);
  }

  handleActionSelected(e, { value }) {
    const { webViews } = this.props;
    let buttonActionValue = null;
    // in case that there's only one web view option - auto-select it
    // (as manual selection is disabled in dropdown with 1 element):
    if (value === constants.OPEN_WEB_VIEW && webViews && webViews.length === 1) {
      buttonActionValue = webViews[0].value;
    }
    let newVal = this.props.actionData
      .set(constants.BUTTON_ACTION_TYPE, value)
      .set(constants.BUTTON_ACTION_VALUE, buttonActionValue);
    if (value === constants.OPEN_CATALOG_PAGE) {
      newVal = newVal.set(constants.CATALOG_MODE, constants.CATALOG_MODE_LIST);
    }
    this.props.onUpdate(newVal, this.props.index);
  }

  get actionsDropDown() {
    const { actionData, assetContext, automationPrefix, disabled, intl, validationErrors } = this.props;
    const { formatMessage } = intl;
    let options = [
      {
        value: constants.DO_NOTHING,
        text: formatMessage({ id: this.props.doNothingLabelStringId })
      },
      {
        value: constants.NAVIGATE_TO_TARGET_TC,
        text: formatMessage({ id: 'activity.generic.action.button.actions.navigate.to.target.tc' })
      },
      {
        value: constants.RATE_APP,
        text: formatMessage({ id: 'activity.generic.action.button.actions.rate.app' })
      },
      {
        value: constants.OPEN_WEB_VIEW,
        text: formatMessage({ id: 'activity.generic.action.button.actions.open.web.view' })
      },
      {
        value: constants.OPEN_WEB_PAGE,
        text: formatMessage({ id: 'activity.generic.action.button.actions.open.web.page' })
      },
      {
        value: constants.SHARE_BUDGET,
        text: formatMessage({ id: 'activity.generic.action.button.actions.share.budget' })
      },
      {
        value: constants.OPEN_SPECIFIC_ASSET_SCREEN,
        text: formatMessage({ id: 'activity.generic.action.button.actions.open.specific.asset.screen' })
      },
      {
        value: constants.OPEN_CATALOG_PAGE,
        text: formatMessage({ id: 'activity.generic.action.button.actions.open.catalog.page' })
      }
    ];
    if (assetContext) {
      options = [{
        value: constants.OPEN_THIS_ASSET_SCREEN,
        text: formatMessage({ id: `activity.generic.action.button.actions.open.this.${assetContext}.screen` })
      }]
        .concat(
          options.filter((opt) => opt.value !== constants.OPEN_SPECIFIC_ASSET_SCREEN)
        );
    }
    return (<DropDownWrapper
      className="generic-action-type-drop-down"
      disabled={disabled}
      options={options}
      name={constants.BUTTON_ACTION_TYPE}
      prefix={`${automationPrefix}.${constants.BUTTON_ACTION_TYPE}`}
      hideTrigger
      onSelectOption={this.handleActionSelected}
      selection
      value={actionData.get(constants.BUTTON_ACTION_TYPE)}
      error={validationErrors ? validationErrors.get(constants.BUTTON_ACTION_TYPE) : null}
    >
    </DropDownWrapper>);
  }

  get screensDropDown() {
    const { screens, automationPrefix, actionData, validationErrors, disabled } = this.props;
    const { formatMessage } = this.props.intl;
    const screensList = [
      ...screens.builtIn,
      (<Dropdown.Item disabled key="custom">
        <h4>
          {formatMessage({ id: 'app.screens.custom' })}
        </h4>
      </Dropdown.Item>),
      ...screens.custom];
    return (<span>
      <DropDownWrapper
        search
        disabled={disabled}
        options={screensList}
        name={constants.APP_SCREEN}
        prefix={`${automationPrefix}.${constants.APP_SCREEN}`}
        hideTrigger
        onSelectOption={(e, { value }) => this.onActionFieldUpdate(constants.BUTTON_ACTION_VALUE, value)}
        selection
        value={actionData.get(constants.BUTTON_ACTION_VALUE)}
        error={validationErrors ? validationErrors.get(constants.BUTTON_ACTION_VALUE) : null}
      >
      </DropDownWrapper>
    </span>);
  }

  get customWebViewsDropDown() {
    const { webViews, automationPrefix, actionData, validationErrors, disabled } = this.props;
    return (<DropDownWrapper
      search
      disabled={disabled}
      options={webViews}
      name={constants.WEB_VIEW}
      prefix={`${automationPrefix}.${constants.WEB_VIEW}`}
      hideTrigger
      onSelectOption={(e, { value }) => this.onActionFieldUpdate(constants.BUTTON_ACTION_VALUE, value)}
      selection
      value={actionData.get(constants.BUTTON_ACTION_VALUE)}
      error={validationErrors ? validationErrors.get(constants.BUTTON_ACTION_VALUE) : null}
    >
    </DropDownWrapper>);
  }

  get catalogsDropDown() {
    const { catalogs, automationPrefix, actionData, validationErrors, disabled } = this.props;
    return (<DropDownWrapper
      search
      disabled={disabled}
      options={catalogs}
      name={constants.CATALOG}
      prefix={`${automationPrefix}.${constants.CATALOG}`}
      hideTrigger
      onSelectOption={(e, { value }) => this.onActionFieldUpdate(constants.BUTTON_ACTION_VALUE, value)}
      selection
      value={actionData.get(constants.BUTTON_ACTION_VALUE)}
      error={validationErrors ? validationErrors.get(constants.BUTTON_ACTION_VALUE) : null}
    >
    </DropDownWrapper>);
  }

  get buttonActionArgLabel() {
    const { formatMessage } = this.props.intl;
    switch (this.props.actionData.get(constants.BUTTON_ACTION_TYPE)) {
      case constants.NAVIGATE_TO_TARGET_TC:
        return formatMessage({ id: 'activity.generic.action.button.actions.navigate.to.target.tc.label' });
      case constants.OPEN_WEB_VIEW:
        return formatMessage({ id: 'activity.generic.action.button.actions.open.web.view.label' });
      case constants.OPEN_WEB_PAGE:
        return formatMessage({ id: 'activity.generic.action.button.actions.open.web.page.label' });
      case constants.OPEN_SPECIFIC_ASSET_SCREEN:
        return formatMessage({ id: 'activity.generic.action.button.actions.open.specific.asset.screen.label' });
      case constants.OPEN_CATALOG_PAGE:
        return formatMessage({ id: 'activity.generic.action.button.actions.open.catalog.page.label' });
      default:
        return null;
    }
  }

  get buttonActionArgControl() {
    const { intl, index, actionData, validationErrors, automationPrefix, disabled } = this.props;
    const { formatMessage } = intl;
    switch (actionData.get(constants.BUTTON_ACTION_TYPE)) {
      case constants.NAVIGATE_TO_TARGET_TC:
        return this.screensDropDown;
      case constants.OPEN_WEB_VIEW:
        return this.customWebViewsDropDown;
      case constants.OPEN_WEB_PAGE:
        return (<InputWrapper
          disabled={disabled}
          name={constants.WEB_PAGE_URL}
          onChange={(e, data) => this.onActionFieldUpdate(constants.BUTTON_ACTION_VALUE, data.value)}
          value={actionData.get(constants.BUTTON_ACTION_VALUE) || ''}
          prefix={`${automationPrefix}.${constants.WEB_PAGE_URL}`}
          placeholder={formatMessage({ id: 'activity.generic.action.button.actions.open.web.page.placeholder' })}
          error={validationErrors ? validationErrors.get(constants.BUTTON_ACTION_VALUE) : null}
        />);
      case constants.SHARE_BUDGET:
        return (<div className={'share_budget_type'}>
          <FormattedMessage id={'activity.generic.action.button.actions.share.budget.label'} />
          <DropDownWrapper
            search
            options={[{
              'key': constants.SHARE_BUDGET_TYPE_FIXED,
              'text': constants.SHARE_BUDGET_TYPE_FIXED,
              'value': constants.SHARE_BUDGET_TYPE_FIXED
            }, {
              'key': constants.SHARE_BUDGET_TYPE_CUSTOM,
              'text': constants.SHARE_BUDGET_TYPE_CUSTOM,
              'value': constants.SHARE_BUDGET_TYPE_CUSTOM
            }]}
            name={constants.SHARE_BUDGET}
            prefix={`${automationPrefix}.${constants.SHARE_BUDGET}`}
            hideTrigger
            onSelectOption={(e, { value }) => this.onActionFieldUpdate(constants.SHARE_BUDGET_TYPE, value)}
            selection
            value={actionData.get(constants.SHARE_BUDGET_TYPE)}
            error={validationErrors ? validationErrors.get(constants.SHARE_BUDGET_TYPE) : null}
          >
          </DropDownWrapper>
          {actionData.get(constants.SHARE_BUDGET_TYPE) === constants.SHARE_BUDGET_TYPE_FIXED &&
          <PointsInputField
            name={constants.SHARE_BUDGET}
            className={'share_budget_amount'}
            onChange={(e, { value }) => this.onActionFieldUpdate(constants.SHARE_BUDGET_VALUE, value)}
            value={actionData.get(constants.SHARE_BUDGET_VALUE) || 0}
            prefix={`${automationPrefix}.${constants.SHARE_BUDGET_VALUE}`}
            error={validationErrors ? validationErrors.get(constants.SHARE_BUDGET_VALUE) : null}
            min={0}
          />}
        </div>);
      case constants.OPEN_SPECIFIC_ASSET_SCREEN:
        return (
          <div className="generic-action-complex-list">
            <BenefitsList
              disabled={disabled}
              index={index}
              onChange={this.handleAssetSelected}
              value={actionData.get(constants.BUTTON_ACTION_VALUE)}
              name={constants.TARGET_ASSET_ID}
              prefix={`${automationPrefix}.${constants.TARGET_ASSET_ID}`}
              error={validationErrors ? validationErrors.get(constants.BUTTON_ACTION_VALUE) : null}
            />
          </div>
        );
      case constants.OPEN_CATALOG_PAGE:
        return this.catalogsDropDown;
      default:
        return null;
    }
  }

  get inAppButtonLabel() {
    const { formatMessage } = this.props.intl;
    const className = classNames('generic-action-in-app-button-label', { disabled: this.props.disabled });
    return (<label className={className} htmlFor={constants.GENERIC_ACTION_BUTTON_TEXT}>
      {formatMessage({ id: 'activity.generic.action.button.text.label' })}
    </label>);
  }

  get inAppButtonInput() {
    const { actionData, validationErrors, automationPrefix, disabled } = this.props;
    return (<InputWrapper
      disabled={disabled}
      id={constants.GENERIC_ACTION_BUTTON_TEXT}
      name={constants.GENERIC_ACTION_BUTTON_TEXT}
      onChange={(e, data) => this.onActionFieldUpdate(constants.GENERIC_ACTION_BUTTON_TEXT, data.value)}
      value={actionData.get(constants.GENERIC_ACTION_BUTTON_TEXT) || ''}
      prefix={`${automationPrefix}.${constants.GENERIC_ACTION_BUTTON_TEXT}`}
      error={validationErrors ? validationErrors.get(constants.GENERIC_ACTION_BUTTON_TEXT) : null}
    />);
  }

  render() {
    const genericActionOptionLabelClasses = classNames('generic-action-option-label', { disabled: this.props.disabled });
    if (this.props.webViews === null || this.props.screens === null || this.props.catalogs === null) {
      return <Loader inline active size="small" />;
    }
    const { formatMessage } = this.props.intl;

    return (
      <Grid className="generic-action">
        <Grid.Row className="generic-action-row">
          <Grid.Column width={16}>
            <div>
              <span className={genericActionOptionLabelClasses}>{formatMessage({ id: 'activity.generic.action.button.text' })}</span>
              {this.actionsDropDown}
              <span className={genericActionOptionLabelClasses}>{this.buttonActionArgLabel}</span>
              {this.buttonActionArgControl}
            </div>
            {
              ![constants.DO_NOTHING].includes(this.props.actionData.get(constants.BUTTON_ACTION_TYPE)) &&
                <div>
                  {this.inAppButtonLabel}
                  {this.inAppButtonInput}
                </div>
            }
          </Grid.Column>
        </Grid.Row>
        {
          this.props.actionData.get(constants.BUTTON_ACTION_TYPE) === constants.OPEN_CATALOG_PAGE &&
          <Grid.Row className="generic-action-row">
            <Grid.Column width={16}>
              <CheckBox
                className="catalog-gallery-mode-checkbox"
                name="catalogGalleryMode"
                checked={this.props.actionData.get(constants.CATALOG_MODE) === constants.CATALOG_MODE_GALLERY}
                data-automation-id={`${this.props.automationPrefix}.catalogGalleryMode`}
                onChange={(e, data) => this.onActionFieldUpdate(constants.CATALOG_MODE,
                  data.checked ? constants.CATALOG_MODE_GALLERY : constants.CATALOG_MODE_LIST)}
                label={this.props.intl.formatMessage({ id: 'activity.generic.action.catalog.gallery.mode' })}
              />
            </Grid.Column>
          </Grid.Row>
        }
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  screens: selectors.getScreensListViewItems(state),
  webViews: selectors.getWebViewsListViewItems(state),
  catalogs: selectors.getCatalogListViewItems(state)
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(genericActionActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: constants.GENERIC_ACTION, saga });
export default compose(withConnect, withSaga, injectIntl)(GenericAction);
