import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import * as constants from '../../activity.constants';
import DropDownPopup from '../../../../common/components/PopupWrapper/DropDownPopup';
import DropdownWrapper from '../../../../common/components/DropdownWrapper/DropdownWrapper';

class ConditionOperator extends React.PureComponent {

  // eslint-disable-next-line no-undef
  static propTypes = {
    isInsideModal: PropTypes.bool,
    automationIdPrefix: PropTypes.string,
    disabled: PropTypes.bool,
    intl: PropTypes.object,
    options: PropTypes.object,
    value: PropTypes.string,
    index: PropTypes.number,
    onConditionUpdate: PropTypes.func,
    displayDropDownWithSingleOption: PropTypes.bool,
    condition: PropTypes.object
  };

  componentDidMount() {
    this.setDefaultValuesIfNeeded();
  }

  componentDidUpdate() {
    this.setDefaultValuesIfNeeded();
  }

  setDefaultValuesIfNeeded() {
    if (this.props.options && this.props.condition) {
      if (!this.props.options.find((option) => option.get(constants.VALUE) === this.props.condition.get(constants.OPERATOR_KEY))) {
        this.props.onConditionUpdate(null, {
          name: constants.OPERATOR_KEY,
          value: this.props.options.getIn([0, constants.VALUE])
        });
      }
    }
  }

  render() {
    const transformedOptions = this.props.options.map((opt) =>
      opt.set('text', this.props.intl.formatMessage({ id: `operator.${opt.get(constants.KEY)}` }))
    ).toJS();

    const displayValue = this.props.options.find((option) => option.get(constants.VALUE) === this.props.value) ? this.props.value : this.props.options.getIn([0, constants.VALUE]);
    return (
        this.props.isInsideModal ? (
          <DropDownPopup
            automationIdPrefix={`${this.props.automationIdPrefix}.${constants.CONDITION_LIST_OPERATOR}`}
            className="condition-operator-popup"
            disabled={this.props.disabled}
            options={transformedOptions}
            placeholder={this.props.intl.formatMessage({ id: 'condition.operator.placeholder' })}
            onSelectOption={this.props.onConditionUpdate}
            value={displayValue}
            name={constants.OPERATOR_KEY}
          />) : (
            <DropdownWrapper
              disabled={this.props.disabled}
              fluid
              options={transformedOptions}
              onSelectOption={this.props.onConditionUpdate}
              value={displayValue}
              prefix={`${this.props.automationIdPrefix}.${constants.CONDITION_LIST_OPERATOR}`}
              name={constants.OPERATOR_KEY}
              placeholder={this.props.intl.formatMessage({ id: 'condition.operator.placeholder' })}
              index={this.props.index}
              displayDropDownWithSingleOption={this.props.displayDropDownWithSingleOption}
              allowAdditions={false}
              search={false}
            />
        )
    );

  }
}

export default injectIntl(ConditionOperator);
