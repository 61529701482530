import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import DropdownWrapper from '../../../../common/components/DropdownWrapper/DropdownWrapper';
import * as conditionValueOptionsSchema from '../../activitySchema/activitySchema.condition.value.options';
import * as schemaConstants from '../../activitySchema/activitySchema.constants';
import ConditionListOfValues from "./conditionListOfValues";

class ConditionMonthAndDay extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onConditionMonthUpdate = this.onConditionMonthUpdate.bind(this);
    this.onConditionDayUpdate = this.onConditionDayUpdate.bind(this);
  }

  static propTypes = {
    disabled: PropTypes.bool,
    index: PropTypes.number,
    value: PropTypes.object,
    type: PropTypes.string,
    onChange: PropTypes.func,
    prefix: PropTypes.string,
    error: PropTypes.string,
    className: PropTypes.string,
    intl: PropTypes.object
  };

  componentWillMount() {
    if (!this.props.value) {
      this.props.onChange({}, {value: {month: "1", day: "1"}});
    }
  }

  onConditionMonthUpdate(e, data) {
    const value = {month: data.value, day: "1"};
    this.props.onChange(e, {value: value});
  }

  onConditionDayUpdate(e, data) {
    const value = {month: this.props.value.month, day: data.value};
    this.props.onChange(e, {value: value});
  }


  render() {
    if (this.props.value){
      this.props.value.month = this.props.value.month.toString().padStart(2,'0');
    }
    const stateValue = this.props.value || {month: "01", day: "1"};
    const options = Array.from({length: conditionValueOptionsSchema.daysByMonths[stateValue.month]}, (x, i) => i + 1).map((option, index) => {
      const key = `opt.value.${index}`;
      const label = option;
      const value = option.toString();
      const text = option;
      return {key, value, text: label || text};
    });
    return (
      <div className={"birthday-month-condition"} style={{width: 'max-content'}}>
        <ConditionListOfValues
          prefix={this.props.prefix}
          onChange={this.onConditionMonthUpdate}
          type={schemaConstants.VALUE_TYPE_MONTHS_LIST}
          value={stateValue.month.toString()}
        />
        <DropdownWrapper
          className={this.props.className + ' day-value'}
          options={options}
          onSelectOption={this.onConditionDayUpdate}
          value={stateValue.day.toString()}
          disabled={this.props.disabled}
          index={this.props.index}
          prefix={this.props.prefix}
          error={this.props.error}
          search
        />
      </div>
    );
  }
}

export default injectIntl(ConditionMonthAndDay);
