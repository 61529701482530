import {fromJS} from 'immutable';
import * as constants from './pointShop.constants';

const initialState = fromJS({
  [constants.SORT]: {
    [constants.ORDER]: constants.ORDER_DESCENDING
  },
  [constants.POINT_SHOP_ITEMS]: [],
  [constants.POINT_SHOP_ITEMS_LOADED]: false,
  [constants.POINT_SHOP_ITEMS_ANALYSIS]: [],
});

function pointShopReducer(state = initialState, action) {

  switch (action.type) {
    case constants.GET_POINT_SHOP_ITEMS: {
      return state
        .set(constants.POINT_SHOP_ITEMS_LOADED, false);
    }
    case constants.GET_POINT_SHOP_ITEMS_SUCCESS: {
      return state
        .set(constants.POINT_SHOP_ITEMS, fromJS(action.data))
        .set(constants.POINT_SHOP_ITEMS_LOADED, true)
        .set(constants.POINT_SHOP_ITEMS_CREATED, false);
    }

    case constants.GET_POINT_SHOP_ITEM: {
      return state
        .set(constants.POINT_SHOP_ITEM_LOADED, false);
    }
    case constants.GET_POINT_SHOP_ITEM_SUCCESS: {
      return state
        .set(constants.POINT_SHOP_ITEM, action.data)
        .set(constants.POINT_SHOP_ITEM_LOADED, true);
    }

    case constants.CREATE_POINT_SHOP_ITEM_SUCCESS: {
      return state.set(constants.POINT_SHOP_ITEMS_CREATED, true)
        .set(constants.POINT_SHOP_ITEMS_ERROR, false);
    }
    case constants.GET_POINT_SHOP_ITEMS_ANALYSIS_SUCCESS: {
      return state.set(constants.POINT_SHOP_ITEMS_ANALYSIS_IN_PROGRESS, false)
        .set(constants.POINT_SHOP_ITEMS_ANALYSIS, fromJS(action.data));
    }
    case constants.GET_POINT_SHOP_ITEMS_ANALYSIS_ERROR: {
      return state.set(constants.POINT_SHOP_ITEMS_ANALYSIS_IN_PROGRESS, false)
        .set(constants.POINT_SHOP_ITEMS_ANALYSIS, fromJS([]));
    }
    case constants.GET_POINT_SHOP_ITEMS_ANALYSIS_IN_PROGRESS: {
      return state.set(constants.POINT_SHOP_ITEMS_ANALYSIS_IN_PROGRESS, true);
    }
    case constants.GET_POINT_SHOP_ITEMS_ERROR: {
      return state.set(constants.POINT_SHOP_ITEMS_ERROR, action.message);
    }
    case constants.GET_POINT_SHOP_ITEM_ERROR: {
      return state.set(constants.POINT_SHOP_ITEM_ERROR, action.message);
    }
    case constants.UPDATE_POINT_SHOP_ITEM_SUCCESS: {
      return state
        .set(constants.POINT_SHOP_ITEMS, fromJS(action.data))
        .set(constants.POINT_SHOP_ITEMS_LOADED, true)
        .set(constants.POINT_SHOP_ITEMS_CREATED, false);
    }

    case constants.UPDATE_POINT_SHOP_ITEM_STATUS_SUCCESS: {
      let pointShopItems = state.get('pointShopItems');
      pointShopItems = pointShopItems.update(
        pointShopItems.findIndex((item) => item.get('id') === action.data.id), (item) => item.set('status', action.data.status)
      );
      return state.set(constants.POINT_SHOP_ITEMS, pointShopItems);
    }

    case constants.DELETE_POINT_SHOP_ITEM_SUCCESS: {
      let pointShopItems = state.get('pointShopItems');
      pointShopItems = pointShopItems.filter((item) => item.get('hubId') !== action.id);
      return state.set(constants.POINT_SHOP_ITEMS, pointShopItems);
    }
    case constants.CLEAR_POINT_SHOP_ITEM_DATA: {
      return state
        .delete(constants.POINT_SHOP_ITEM)
        .delete(constants.POINT_SHOP_ITEM_LOADED);
    }
    case constants.CREATE_POINT_SHOP_ITEM_ERROR: {
      return state
        .set(constants.POINT_SHOP_ITEMS_ERROR, action.message)
        .set(constants.POINT_SHOP_ITEM_IMAGE_ERROR, true)
        .set(constants.POINT_SHOP_ITEM_LOADED, false);
    }
    case constants.CLEAR_POINT_SHOP_ITEM_IMAGE_ERROR: {
      return state
        .set(constants.POINT_SHOP_ITEM_IMAGE_ERROR, false);
    }
    default: {
      return state;
    }
  }
}

export default pointShopReducer;
