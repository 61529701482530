/*
 *
 * Emails constants
 *
 */

// ACTIONS CONSTANTS
export const GO_TO_EMAIL = 'app/Emails/GO_TO_EMAIL';
export const GET_EMAILS = 'app/Emails/GET_EMAILS';
export const GET_EMAILS_SUCCESS = 'app/Emails/GET_EMAILS_SUCCESS';
export const GET_EMAILS_ERROR = 'app/Emails/GET_EMAILS_ERROR';
export const CREATE_EMAIL = 'app/Emails/CREATE_EMAIL';
export const GO_TO_EMAILS_LIST = 'app/Emails/GO_TO_EMAILS_LIST';
export const UPDATE_ORDER = 'app/Emails/UPDATE_ORDER';
export const UPDATE_FILTERS = 'app/Emails/UPDATE_FILTERS';
export const SEARCH_EMAILS = 'app/Emails/SEARCH_EMAILS';
export const UPDATE_EMAIL = 'app/Emails/UPDATE_EMAIL';
export const UPDATE_EMAIL_STATUS = 'app/Emails/UPDATE_EMAIL_STATUS';
export const UPDATE_EMAIL_STATUS_SUCCESS = 'app/Emails/UPDATE_EMAIL_STATUS_SUCCESS';
export const HIGHLIGHT_ACTIVITY = 'app/Emails/HIGHLIGHT_ACTIVITY';
export const CLEAR_EMAIL_LIST = 'app/Emails/CLEAR_EMAIL_LIST';
export const GET_EMAIL_STATS = 'app/Emails/GET_EMAIL_STATS';
export const GET_EMAIL_STATS_SUCCESS = 'app/Emails/GET_EMAIL_STATS_SUCCESS';
export const CLEAN_EMAIL_STATS = 'app/Emails/CLEAN_EMAIL_STATS';
export const GET_DETAILED_STATS_PER_CAMPAIGN = 'app/Emails/GET_DETAILED_STATS_PER_CAMPAIGN';
export const GET_DETAILED_STATS_PER_ONETIME_CAMPAIGN_SUCCESS = 'app/Emails/GET_DETAILED_STATS_PER_ONETIME_CAMPAIGN_SUCCESS';
export const GET_DETAILED_STATS_PER_RULE_SUCCESS = 'app/Emails/GET_DETAILED_STATS_PER_RULE_SUCCESS';
export const GET_DETAILED_STATS_PER_MICRO_CAMPAIGN_SUCCESS = 'app/Emails/GET_DETAILED_STATS_PER_MICRO_CAMPAIGN_SUCCESS';
export const CLEAN_DETAILED_EMAIL_STATS = 'app/Emails/CLEAN_DETAILED_EMAIL_STATS';
export const GET_DETAILED_STATS_PER_ONETIME_CAMPAIGN = 'app/Emails/GET_DETAILED_STATS_PER_ONETIME_CAMPAIGN';
export const GET_DETAILED_STATS_PER_RULE = 'app/Emails/GET_DETAILED_STATS_PER_RULE';
export const GET_DETAILED_STATS_PER_MICRO_CAMPAIGN = 'app/Emails/GET_DETAILED_STATS_PER_MICRO_CAMPAIGN';
export const SET_EMAIL_STATS_LOADING = 'app/Emails/SET_EMAIL_STATS_LOADING';
export const GET_EMAIL_USAGES = 'app/Emails/GET_EMAIL_USAGES';
export const CLEAN_EMAIL_USAGES_DATA = 'app/Emails/CLEAN_EMAIL_USAGES_DATA';
export const GET_EMAIL_USAGES_SUCCESS = 'app/Emails/GET_EMAIL_USAGES_SUCCESS';

// STORE
export const SEARCH_TERM = 'searchTerm';
export const EMAILS = 'emails';
export const EMAIL = 'email';
export const SORT = 'sort';
export const ORDER = 'order';
export const FILTERS = 'filters';
export const ORDER_DESCENDING = 'descending';
export const EMAIL_CAMPAIGN_ID = 'campaignId';
export const EMAILS_LOADED = 'emailsLoaded';
export const EMAIL_STATS = 'stats';
export const EMAIL_STATS_PER_CAMPAIGN = 'statsPerCampaign';
export const EMAIL_STATS_DATE = 'date';
export const EMAIL_STATS_COUNT = 'count';
export const EMAIL_STATS_TYPE = 'activityType';
export const EMAIL_STATS_TYPE_RULE = 'rule';
export const EMAIL_STATS_TYPE_ONE_TIME = 'oneTime';
export const EMAIL_STATS_TYPE_MICROCAMPAIGN = 'microCampaign';
export const EMAIL_EXTERNAL_ID = 'externalId';
export const EMAIL_SERVER_ID = 'serverId';
export const EMAIL_STATS_PER_RULE = 'statsPerRule';
export const EMAIL_STATS_PER_MICRO_CAMPAIGN = 'statsPerMicroCampaign';
export const RULE_ID = 'ruleId';
export const MICRO_CAMPAIGN_ID = 'microCampaignId';
export const EMAIL_STATS_ACTIVITY_ID = 'activityId';
export const EMAIL_STATS_ACTIVITY_NAME = 'activityName';
export const EMAIL_STATS_CAMPAIGNS = 'campaigns';
export const EMAIL_STATS_NAME = 'name';
export const EMAIL_STATS_VALUE = 'value';
export const EMAIL_STATS_RATE = 'rate';
export const EMAIL_STATS_LOADING = 'emailStatsLoading';
export const EMAIL_USAGES = 'usages';

