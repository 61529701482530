import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './EmptyCard.styles.less';

const EmptyCard = ({ id, onClick, children, automationId, className } = {}) =>
  (
    <div
      role="menu"
      className={`${className} card--empty`}
      onClick={() => _.isFunction(onClick) && onClick(id)}
      data-automation-id={automationId}
    >
      {children}
    </div>
  );

EmptyCard.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  automationId: PropTypes.string.isRequired
};

export default EmptyCard;
