import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import * as activityConstants from '../../Activity/activity.constants';
import ContextMenu from '../../../common/components/ContextMenu/ContextMenu';
import * as selectors from '../../BusinessCenter/businessCenter.selectors';

class PointShopContextMenu extends React.PureComponent {
  static propTypes = {
    hidden: PropTypes.bool,
    automationId: PropTypes.string,
    intl: PropTypes.object
  };
  getMenuOptions() {
    const { formatMessage } = this.props.intl;
    const visibleContextMenuItems = selectors.getContextMenuItemsVisibilityMap(this.props.item);

    const options = [
      {
        key: 'activity-details',
        iconName: 'como-ic-info',
        text: formatMessage({ id: 'point-shop-page.menu.activity-details' }),
        cb: this.props.onDetailsOpen
      },
      {
        key: 'openInNewTab',
        iconName: 'como-ic-arrow-right',
        className: 'benefit-action-list-item-set-up-key',
        text: formatMessage({ id: 'business-center.campaign.activity.menu.openInNewTab' }),
        cb: () => this.props.onOpenInNewTab(this.props.item)
      }
    ];

    if (visibleContextMenuItems[activityConstants.CONTEXT_MENU_OPTION_DEACTIVATE]) {
      options.push({
        key: 'deactivate',
        iconName: 'como-ic-deactivate',
        text: formatMessage({ id: 'business-center.campaign.activity.menu.stop' }),
        cb: () => this.props.deactivate(this.props.item)
      });
    }

    if (visibleContextMenuItems[activityConstants.CONTEXT_MENU_OPTION_DELETE]) {
      options.push({
        key: 'delete',
        iconName: 'como-ic-delete',
        text: formatMessage({ id: 'business-center.campaign.activity.menu.delete' }),
        cb: () => this.props.delete(this.props.item.get('hubId'))
      });
    }

    return options;
  }

  render() {
    return (<ContextMenu
      options={this.getMenuOptions()}
      automationId={this.props.automationId}
      hidden={this.props.hidden}
    />);
  }
}

export default injectIntl(PointShopContextMenu);
