import * as constants from './itemsPopulation.constants';

export function copyActivityDataToTempData(data, editGroupMode) {
  return {
    type: editGroupMode
      ? constants.ITEMS_POPULATION_COPY_DATA_TO_TEMP_ITEMS_GROUP
      : constants.ITEMS_POPULATION_COPY_DATA_TO_TEMP_ITEMS_POPULATION,
    data
  };
}

export function updateType(itemsPopulationType, editGroupMode, actionsWrapperIndex) {
  return {
    type: constants.ITEMS_POPULATION_UPDATE_TYPE,
    itemsPopulationType,
    editGroupMode,
    actionsWrapperIndex
  };
}

export function clearAllInType(itemsPopulationType, editGroupMode) {
  return {
    type: constants.ITEMS_POPULATION_CLEAR_ALL_IN_TYPE,
    itemsPopulationType,
    editGroupMode
  };
}

export function updateCondition(itemsPopulationType, index, fieldKey, fieldValue, editGroupMode) {
  return {
    type: constants.ITEMS_POPULATION_UPDATE_CONDITION,
    itemsPopulationType,
    fieldKey,
    fieldValue,
    index,
    editGroupMode
  };
}

export function updateWholeCondition(itemsPopulationType, index, key, operator, value, editGroupMode) {
  return {
    type: constants.ITEMS_POPULATION_UPDATE_WHOLE_CONDITION,
    itemsPopulationType,
    key,
    operator,
    index,
    value,
    editGroupMode
  };
}

export function updateConditionsRelations(itemsPopulationType, relation, editGroupMode) {
  return {
    type: constants.ITEMS_POPULATION_UPDATE_CONDITIONS_RELATIONS,
    itemsPopulationType,
    relation,
    editGroupMode
  };
}

export function addCondition(itemsPopulationType, editGroupMode) {
  return {
    type: constants.ITEMS_POPULATION_ADD_CONDITION,
    itemsPopulationType,
    editGroupMode
  };
}

export function deleteCondition(itemsPopulationType, index, editGroupMode) {
  return {
    type: constants.ITEMS_POPULATION_DELETE_CONDITION,
    itemsPopulationType,
    index,
    editGroupMode
  };
}

export function clearTempData(editGroupMode) {
  return {
    type: constants.ITEMS_POPULATION_CLEAR_DATA,
    editGroupMode
  };
}

export function updateGroupName(name) {
  return {
    type: constants.ITEMS_POPULATION_UPDATE_GROUP_NAME,
    name
  };
}
