// store keys:
export const PROMO_CODES = 'promoCodes';
export const PROMO_CODE = 'promoCode';
export const FILTERS = 'filters';
export const SORT = 'sort';
export const ORDER = 'order';
export const PROMO_CODES_LOADED = 'promoCodesLoaded';
export const ORDER_DESCENDING = 'descending';
export const ORDER_ASCENDING = 'ascending';
export const SEARCH_TERM = 'searchTerm';
export const PROMO_CODES_STATS = 'stats';
export const BULK_KEY = 'bulkKey';
export const BULK_CODES_LIST = 'bulkCodesList';
export const BULK_CODES_LIST_MODAL_VISIBILITY_STATUS = 'bulkCodesListModalVisibilityStatus';
export const BULK_EXPORT_LOADING_STATUSES = 'app/promoCodes/BULK_EXPORT_LOADING_STATUSES';

// actions:
export const SEARCH_PROMO_CODES = 'app/promoCodes/SEARCH_PROMO_CODES';
export const GO_TO_PROMO_CODE = 'app/promoCodes/GO_TO_PROMO_CODE';
export const GET_PROMO_CODES = 'app/promoCodes/GET_PROMO_CODES';
export const GET_PROMO_CODE = 'app/promoCodes/GET_PROMO_CODE';
export const CREATE_PROMO_CODE = 'app/promoCodes/CREATE_PROMO_CODE';
export const UPDATE_ORDER = 'app/promoCodes/UPDATE_ORDER';
export const UPDATE_FILTERS = 'app/promoCodes/UPDATE_FILTERS';
export const DELETE_PROMO_CODE = 'app/promoCodes/DELETE_PROMO_CODE';
export const DELETE_PROMO_CODE_SUCCESS = 'app/promoCodes/DELETE_PROMO_CODE_SUCCESS';
export const EXTEND_PROMO_CODE_SUCCESS = 'app/promoCodes/EXTEND_PROMO_CODE_SUCCESS';
export const HIGHLIGHT_ACTIVITY = 'app/promoCodes/HIGHLIGHT_ACTIVITY';
export const GET_PROMO_CODES_SUCCESS = 'app/promoCodes/GET_PROMO_CODES_SUCCESS';
export const SET_PROMO_CODES_LOADED = 'app/promoCodes/SET_PROMO_CODES_LOADED';
export const UPDATE_PROMO_CODE = 'app/promoCodes/UPDATE_PROMO_CODE';
export const EXTEND_PROMO_CODE = 'app/promoCodes/EXTEND_PROMO_CODE';
export const UPDATE_PROMO_CODE_SUCCESS = 'app/promoCodes/UPDATE_PROMO_CODE_SUCCESS';
export const GO_TO_PROMO_CODES_LIST = 'app/promoCodes/GO_TO_PROMO_CODES_LIST';
export const GET_PROMO_CODE_STATS = 'app/promoCodes/GET_PROMO_CODE_STATS';
export const GET_PROMO_CODE_STATS_SUCCESS = 'app/promoCodes/GET_PROMO_CODE_STATS_SUCCESS';
export const GET_PROMO_CODE_STATS_FAILURE = 'app/promoCodes/GET_PROMO_CODE_STATS_FAILURE';
export const CLEAN_PROMO_CODE_STATS = 'app/promoCodes/CLEAN_PROMO_CODE_STATS';
export const GET_PROMO_CODE_RELATED_ACTIVITIES = 'app/promoCodes/GET_PROMO_CODE_RELATED_ACTIVITIES';
export const GET_PROMO_CODE_RELATED_ACTIVITIES_SUCCESS = 'app/promoCodes/GET_PROMO_CODE_RELATED_ACTIVITIES_SUCCESS';
export const CLEAN_PROMO_CODE_RELATED_ACTIVITIES_DATA = 'app/promoCodes/CLEAN_PROMO_CODE_RELATED_ACTIVITIES_DATA';
export const GET_FREE_PROMO_CODES_NUMBER_SUCCESS = 'app/promoCodes/GET_FREE_PROMO_CODES_NUMBER_SUCCESS';
export const GET_BULK_CODES_LIST = 'app/promoCodes/GET_BULK_CODES_LIST';
export const GET_BULK_CODES_LIST_SUCCESS = 'app/promoCodes/GET_BULK_CODES_LIST_SUCCESS';
export const HANDLE_BULK_LIST_MODAL_VISIBILITY = 'app/promoCodes/HANDLE_BULK_LIST_MODAL_VISIBILITY';
export const GET_BULKS_EXPORT = 'app/promoCodes/GET_BULKS_EXPORT';
export const CLOSE_BULK_LIST_MODAL = 'app/promoCodes/CLOSE_BULK_LIST_MODAL';
export const BULK_EXPORT_LOADING_STATUSES_ADD = 'app/promoCodes/BULK_EXPORT_LOADING_STATUSES_ADD';
export const BULK_EXPORT_LOADING_STATUSES_REMOVE = 'app/promoCodes/BULK_EXPORT_LOADING_STATUSES_REMOVE';
export const CHANGE_PROMO_CODE_STATUS = 'app/promoCodes/CHANGE_PROMO_CODE_STATUS';

// types
export const PROMO_CODE_TYPE = 'singleCode';
export const PROMO_CODE_GENERATED = 'generated';
export const PROMO_CODE_IMPORTED = 'imported';
