import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import * as constants from '../../activity.constants';
import * as appConstants from '../../../../features/App/app.constants';
import tracker from '../../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../../common/utils/tracking/tracking.consts';
import EntityHeader from '../../../../common/components/EntityHeader/EntityHeader';
import * as selectors from '../../activity.selectors';
import * as selectorsUtils from '../../activity.selectors.utils';

class ActivityHeader extends React.PureComponent {

  // eslint-disable-next-line no-undef
  static propTypes = {
    activityType: PropTypes.string,
    activityName: PropTypes.string,
    activityDescription: PropTypes.string,
    checkingActivityNameAvailability: PropTypes.bool.isRequired,
    intl: PropTypes.object,
    isHeaderSticky: PropTypes.bool.isRequired,
    isActivityDisabled: PropTypes.bool,
    publishOrSaveInProgress: PropTypes.bool,
    saveAsDraftInProgress: PropTypes.bool,
    refocusOnName: PropTypes.bool,
    saveActivity: PropTypes.func.isRequired,
    saveActivityAsDraft: PropTypes.func.isRequired,
    updateMetadata: PropTypes.func.isRequired,
    updateName: PropTypes.func.isRequired,
    activityValidationErrors: PropTypes.object,
    onCancelOrBackClick: PropTypes.func.isRequired,
    tempOriginActivity: PropTypes.object,
    isActivityDirty: PropTypes.bool,
    isHeaderShadowed: PropTypes.bool,
    tabs: PropTypes.array,
    tabsErrors: PropTypes.object,
    activityHasMigrated: PropTypes.bool,
    originalActivity: PropTypes.object,
    hasFullPermission: PropTypes.func,
    getBusinessBundlePlan: PropTypes.func.isRequired,
  };

  static DisableAutosizeInputAutoComplete(element) {
    if (element && element.input) {
      element.input.autocomplete = 'off';
    }
  }

  constructor(props) {
    super(props);
    this.handleActivityMetadataChange = this.handleActivityMetadataChange.bind(this);
    this.handleActivityNameChange = this.handleActivityNameChange.bind(this);
    this.handleActivityNameInputRef = this.handleActivityNameInputRef.bind(this);
  }

  getActivityNameError() {
    if (!this.props.activityValidationErrors) {
      return null;
    }
    return this.props.activityValidationErrors.getIn([constants.ACTIVITY_HEADER, constants.NAME]);
  }

  getActivityStatusText() {
    if (!this.activityStatus) {
      return '';
    }
    let messageIdPrefix;
    switch (this.props.activityType) {
      case constants.ACTIVITY_TYPE_GIFT:
        messageIdPrefix = 'gift.activity.status';
        break;
      case constants.ACTIVITY_TYPE_PROMO_CODE:
        messageIdPrefix = 'promoCode.activity.status';
        break;
      default:
        messageIdPrefix = 'activity.header.status';
        break;
    }
    return this.props.intl.formatMessage({ id: `${messageIdPrefix}.${this.activityStatus}` });
  }

  getActivityReadOnlyMessageText() {
    switch (this.props.activityType) {
      case constants.ACTIVITY_TYPE_PROMO_CODE:
        return this.props.intl.formatMessage(
          { id: 'promo.code.read.only.info.message' }, { 'status': this.getActivityStatusText() });
      default:
        return this.props.intl.formatMessage({ id: 'activity.read.only.info.message' });
    }
  }

  // eslint-disable-next-line no-undef
  handleSaveAsDraftButtonClick = () => {
    tracker.onButtonClick(trackerConstants.BUTTON_TYPE_SAVE_AS_DRAFT);
    this.props.saveActivityAsDraft();
  };

  handleActivityNameInputRef(element) {
    this.activityNameInput = element;
    ActivityHeader.DisableAutosizeInputAutoComplete(element);
  }

  handleActivityMetadataChange(event) {
    this.props.updateMetadata(event.target.name, event.target.value.trim());
  }

  handleActivityNameChange(event) {
    this.props.updateName(event.target.value.trim());
  }

  get isCreatingGiftFromActivity() {
    return this.props.tempOriginActivity;
  }

  get statusMessage() {
    return this.getActivityStatusText();
  }

  get statusClassName() {
    return `status ${this.activityStatus === constants.ACTIVITY_STATUS_ACTIVE && 'active'}`;
  }

  get activityStatus() {
    return selectorsUtils.calculateActivityViewStatus(this.props.originalActivity);
  }

  render() {
    const activityNameError = this.getActivityNameError();
    const { formatMessage } = this.props.intl;
    const activityNameClassNames = classNames({ 'inputFieldError': !!activityNameError });
    const activityHeaderClassNames = classNames({
      sticky: this.props.isHeaderSticky,
      shadowed: this.props.isHeaderShadowed
    });
    const activityDescriptionClassNames = classNames({
      'error': this.props.activityValidationErrors
              && this.props.activityValidationErrors.get(constants.ACTIVITY_HEADER)
              && this.props.activityValidationErrors.getIn([constants.ACTIVITY_HEADER, constants.DESCRIPTION])
    });
    const isServerSavedActivity = this.activityStatus === constants.ACTIVITY_STATUS_ACTIVE;
    const allowedActivities = [constants.ACTIVITY_TYPE_ONE_TIME].includes(this.props.activityType); // for creating new activities need to remove from essentials flag and return essentials flag after.
    const isActivateAddon = this.props.hasFullPermission(appConstants.FEATURE_NAME_ESSENTIALS) && !allowedActivities && !isServerSavedActivity;
    const canSaveAsDraft = (!this.activityStatus || this.activityStatus === constants.ACTIVITY_STATUS_DRAFT) && !this.isCreatingGiftFromActivity && !this.props.activityHasMigrated;

    return (
      <EntityHeader
        headerClassName={activityHeaderClassNames}
        nameClassName={activityNameClassNames}
        descriptionClassName={activityDescriptionClassNames}
        namePlaceholder={formatMessage({ id: `${this.props.activityType}.title.name.placeholder` })}
        descriptionPlaceholder={formatMessage({ id: 'activity.title.description.placeholder' })}
        isLoading={this.props.checkingActivityNameAvailability}
        name={this.props.activityName}
        description={this.props.activityDescription}
        refocusOnName={this.props.refocusOnName}
        nameValidationError={activityNameError}
        backButton={this.backButton}
        promptBeforeLeave={this.props.isActivityDirty && !this.props.publishOrSaveInProgress}
        onCancelOrBackClick={this.props.onCancelOrBackClick}
        handleNameChange={this.handleActivityNameChange}
        handleDescriptionChange={this.handleActivityMetadataChange}
        prefix="activity"
        statusMessage={this.statusMessage}
        statusClassName={this.statusClassName}
        backCancelButtonsDisabled={this.props.publishOrSaveInProgress || this.props.saveAsDraftInProgress}
        publishOrSaveInProgress={this.props.publishOrSaveInProgress}
        saveAsDraftInProgress={this.props.saveAsDraftInProgress}
        canSaveAsDraft={canSaveAsDraft}
        onSaveAsDraftClick={this.handleSaveAsDraftButtonClick}
        canSave={this.activityStatus !== constants.ACTIVITY_STATUS_STOPPED}
        publishButtonDisabled={Boolean(this.props.publishOrSaveInProgress || this.props.isActivityDisabled)}
        publishButtonText={this.props.activityType === constants.ACTIVITY_TYPE_GIFT
          ? formatMessage({ id: 'gift.activity.header.publish' })
          : formatMessage({ id: 'activity.header.publish' })
        }
        onSaveEntityClick={this.props.saveActivity}
        showHeaderNote={this.props.isActivityDisabled}
        headerNoteMessage={this.getActivityReadOnlyMessageText()}
        hasTabs={[constants.ACTIVITY_TYPE_PUNCH_CARD, constants.ACTIVITY_TYPE_GIFT, constants.ACTIVITY_TYPE_PROMO_CODE].includes(this.props.activityType)}
        tabs={this.props.tabs}
        tabsErrors={this.props.tabsErrors}
        isActivateAddon={isActivateAddon}
        getBusinessBundlePlan={this.props.getBusinessBundlePlan}
      />

    );
  }
}

const mapStateToProps = (state) => ({
  activityType: selectors.getActivityType(state),
  activityName: selectors.getActivityName(state),
  activityDescription: selectors.getActivityDescription(state),
  checkingActivityNameAvailability: selectors.getCheckingActivityNameAvailabilityFlag(state) || false,
  activityValidationErrors: selectors.getActivityValidationErrors(state),
  isActivityDirty: selectors.isActivityDirty(state),
  publishOrSaveInProgress: selectors.getPublishOrSaveInProgressFlag(state),
  saveAsDraftInProgress: selectors.getSaveAsDraftInProgressFlag(state),
  isActivityDisabled: selectors.isActivityDisabled(state),
  tabs: selectors.getActivityTabs(state),
  tabsErrors: selectors.getActivityTabsErrors(state),
  originalActivity: selectors.getOriginalActivityDataModel(state),
  tempOriginActivity: selectors.getTempOriginActivity(state),

});

export default (connect)(mapStateToProps)(injectIntl(ActivityHeader));
