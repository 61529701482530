import { injectIntl } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon } from 'semantic-ui-react';
import * as constants from '../../../activity.constants';
import Tooltip from '../../../../../common/components/Tooltip/Tooltip';
import DropdownWrapper from '../../../../../common/components/DropdownWrapper/DropdownWrapper';
import Capsules from '../../../../../common/components/Capsules/Capsules';

class NoPosGift extends React.PureComponent {

  static propTypes = {
  };


  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    return (

      <Grid className="action-block">
        <Grid.Row>
          <Grid.Column>
            <p className="hint">
              {formatMessage(
               { id: 'noPosGift.hint' },
             )}
            </p>
          </Grid.Column>

        </Grid.Row>
      </Grid>
    );
  }
}

export default injectIntl(NoPosGift);
