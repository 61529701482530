import * as types from './constants/types';

export const createTemplateCategory = () => ({
  type: types.CREATE_TEMPLATE_CATEGORY
});

export const updateTemplateCategory = (key, value) => ({
  type: types.UPDATE_TEMPLATE_CATEGORY,
  payload: {
    key,
    value
  }
});
