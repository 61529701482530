import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Icon, Image, List } from 'semantic-ui-react';

import * as constants from '../../benefits.constants';

class BenefitListItem extends React.PureComponent {

  static propTypes = {
    benefit: PropTypes.object,
    selected: PropTypes.bool,
    onChange: PropTypes.func,
    automationId: PropTypes.string.isRequired,
    intl: PropTypes.object
  };

  render() {
    const { benefit, selected, onChange, automationId } = this.props;
    const { formatMessage } = this.props.intl;
    const isInactive = !benefit[constants.BENEFIT_ACTIVE];
    const isExpired = moment(benefit[constants.BENEFIT_VALID_UNTIL]).isBefore(moment());
    return (
      <List.Item
        key={benefit[constants.BENEFIT_SERVER_ID]}
        className={selected ? 'selected' : ''}
        onClick={onChange}
        data-automation-id={automationId}
      >
        <Icon className="como-ic-checkmark" />
        <Image src={benefit[constants.BENEFIT_IMAGE_URL]} avatar verticalAlign="middle" />
        <span
          className="benefit-name"
          title={benefit[constants.BENEFIT_TITLE]}
        >{benefit[constants.BENEFIT_TITLE]}</span>
        {
          (isInactive || isExpired) && (
            <span className={`benefit-${isInactive ? 'deactivated' : 'expired'}`}>
              <Icon className={`como-ic-${isInactive ? 'deactivate-indicator' : 'date'}`} />
              {formatMessage({ id: `benefits.status.${isInactive ? 'deactivated' : 'expired'}` })}
            </span>
          )
        }
      </List.Item>);
  }
}

export default injectIntl(BenefitListItem);
