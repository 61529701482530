/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import CheckBox from '../../../../../common/components/FormInputFields/CheckboxWrapper/CheckboxWrapper';
import IntegerInputField from '../../../../../common/components/FormInputFields/IntegerInputField/IntegerInputField';
import * as constants from '../../../activity.constants';
import './Limit.styles.less';
import SingleLineAccordion from '../../../../../common/components/SingleLineAccordion/singleLineAccordion';
import CurrencyInputField from '../../../../../common/components/FormInputFields/CurrencyInputField/CurrencyInputField';
import Hint from '../../../../../common/components/Hint/Hint';

class Limit extends Component {
  static propTypes = {
    activityType: PropTypes.string,
    automationIdPrefix: PropTypes.string.isRequired,
    onActionFieldUpdate: PropTypes.func.isRequired,
    index: PropTypes.number,
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    amountLimit: PropTypes.object,
    limit: PropTypes.object,
    limitActions: PropTypes.bool,
    showLimits: PropTypes.object,
    intl: PropTypes.object.isRequired,
    actionsWrapperIndex: PropTypes.number
  };

  static defaultProps = {
    limitActions: true,
  };

  shouldComponentUpdate(nextProps) {
    const { amountLimit, limit, showLimits, disabled, index, errors, automationIdPrefix } = this.props;
    return (
      amountLimit !== nextProps.amountLimit ||
      limit !== nextProps.limit ||
      showLimits[constants.LIMIT_SHOW_TIMES] !== nextProps.showLimits[constants.LIMIT_SHOW_TIMES] ||
      showLimits[constants.LIMIT_SHOW_AMOUNT] !== nextProps.showLimits[constants.LIMIT_SHOW_AMOUNT] ||
      disabled !== nextProps.disabled ||
      index !== nextProps.index ||
      errors !== nextProps.errors ||
      automationIdPrefix !== nextProps.automationIdPrefix
    );
  }

  getTextsByType = () => {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    let beforeText = '';
    let afterText = '';
    const limitActions = this.props.activityType !== constants.ACTIVITY_TYPE_PUNCH_CARD || this.props.limitActions;
    if (this.props.showLimits[constants.LIMIT_SHOW_TIMES]) {
      beforeText = formatMessage({ id: limitActions ? 'activity.action.limit.description.upTo' : 'punchCard.action.limit.description.upTo' });
      afterText = formatMessage({ id: limitActions ? 'activity.action.limit.description.timesWhole' : 'punchCard.action.limit.description.timesWhole' });
    } else if (this.props.showLimits[constants.LIMIT_SHOW_AMOUNT]) {
      beforeText = formatMessage({ id: `${this.props.activityType}s.discountLimit.timesLimit` });
    }
    return {
      beforeText, afterText
    };
  };

  handlerInputOnChange = (value, limitType) => {
    const { index, onActionFieldUpdate } = this.props;
    const active = this.props[limitType] ? this.props[limitType].get(constants.LIMIT_ACTIVE) : false;
    onActionFieldUpdate(limitType, {
      [constants.LIMIT_VALUE]: value,
      [constants.LIMIT_ACTIVE]: active
    }, index, this.props.actionsWrapperIndex);
  };

  handlerCheckBoxChange = (checked, limitType) => {
    const { index, onActionFieldUpdate } = this.props;
    const value = this.props[limitType] ? this.props[limitType].get(constants.LIMIT_VALUE) : null;
    onActionFieldUpdate(limitType, {
      [constants.LIMIT_ACTIVE]: checked,
      [constants.LIMIT_VALUE]: value
    }, index, this.props.actionsWrapperIndex);
  };

  renderAccordionContent() {
    const { index, disabled, errors, amountLimit, limit, showLimits, automationIdPrefix, activityType } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const timesLimitText = this.getTextsByType();
    return (
      <div className="limit__content">
        {showLimits.times &&
        <div className="limit-input-wrap">
          <CheckBox
            name={constants.LIMIT_ACTIVE}
            checked={limit ? limit.get(constants.LIMIT_ACTIVE) : false}
            disabled={disabled}
            data-automation-id={`${automationIdPrefix}.limit.checkbox`}
            onChange={(e, data) => this.handlerCheckBoxChange(data.checked, constants.LIMIT)}
            label={timesLimitText.beforeText}
          />
          <IntegerInputField
            name={constants.LIMIT_VALUE}
            fluid={false}
            onChange={(e, data) => this.handlerInputOnChange(data.value, constants.LIMIT)}
            value={limit ? limit.get(constants.LIMIT_VALUE) : null}
            index={index}
            prefix={`${automationIdPrefix}.limit.value`}
            disabled={disabled || !limit || (limit && !limit.get(constants.LIMIT_ACTIVE))}
            error={errors ? errors.get(constants.LIMIT) : null}
            className={'limit-input-el'}
          />
          <span className={classNames('checkbox-label', { 'active': !disabled && limit && limit.get(constants.LIMIT_ACTIVE) })}>{timesLimitText.afterText}</span>
        </div>
          }
        {showLimits.amount &&
        <div className="limit-input-wrap">
          <CheckBox
            name={constants.LIMIT_ACTIVE}
            checked={amountLimit ? amountLimit.get(constants.LIMIT_ACTIVE) : false}
            disabled={disabled}
            data-automation-id={`${automationIdPrefix}.amountLimit.checkbox`}
            onChange={(e, data) => this.handlerCheckBoxChange(data.checked, constants.AMOUNT_LIMIT)}
            label={formatMessage({ id: `${activityType}s.discountLimit.amountLimit` })}
          />
          <CurrencyInputField
            name={constants.LIMIT_VALUE}
            fluid={false}
            onChange={(e, data) => this.handlerInputOnChange(data.value, constants.AMOUNT_LIMIT)}
            value={amountLimit ? amountLimit.get(constants.LIMIT_VALUE) : null}
            index={index}
            prefix={`${automationIdPrefix}.amountLimit.value`}
            disabled={disabled || !amountLimit || (amountLimit && !amountLimit.get(constants.LIMIT_ACTIVE))}
            error={errors ? errors.get(constants.AMOUNT_LIMIT) : null}
          />
        </div>
          }
        {disabled &&
        <Hint>
            {formatMessage({ id: 'activity.action.limit.hint.noneRepetitive' })}
        </Hint>
        }
      </div>
    );
  }

  render() {
    const automationIdPrefix = this.props.automationIdPrefix;
    const isTimesLimitNotActive = !this.props.limit || (this.props.limit && !this.props.limit.get(constants.LIMIT_ACTIVE));
    const isAmountLimitNotActive = !this.props.amountLimit || (this.props.amountLimit && !this.props.amountLimit.get(constants.LIMIT_ACTIVE));
    return (
      <SingleLineAccordion
        automationIdPrefix={automationIdPrefix}
        title={this.props.intl.formatMessage({ id: 'activity.action.limit.header' })}
        initialCollapseState={isTimesLimitNotActive && isAmountLimitNotActive}
      >
        {this.renderAccordionContent()}
      </SingleLineAccordion>
    );
  }
}


export default injectIntl(Limit);
