import * as constants from './emails.constants';
import * as featuresConstants from '../features.constants';
export const onFilterUpdate = (groupKey, key, status) => ({
  type: constants.UPDATE_FILTERS, groupKey, key, status
});
export const goToEmail = (hubId) => ({ type: constants.GO_TO_EMAIL, hubId });
export const createEmail = () => ({ type: constants.CREATE_EMAIL });
export const getEmails = () => ({ type: constants.GET_EMAILS });
export const onSortUpdate = () => ({ type: constants.UPDATE_ORDER });
export const search = (term) => ({ type: constants.SEARCH_EMAILS, [constants.SEARCH_TERM]: term });
export const updateEmail = (email) => ({ type: constants.UPDATE_EMAIL, [constants.EMAIL]: email });
export const updateEmailStatus = (email) => ({ type: constants.UPDATE_EMAIL_STATUS, [constants.EMAIL]: email });
export const goToEmailsList = () => ({ type: constants.GO_TO_EMAILS_LIST });
export const clearEmailList = () => ({ type: constants.CLEAR_EMAIL_LIST });
export const getEmailsStats = (hubId) => ({ type: constants.GET_EMAIL_STATS, [featuresConstants.HUB_ID]: hubId });
export const cleanEmailStats = () => ({ type: constants.CLEAN_EMAIL_STATS });
export const cleanEmailCampaignStats = () => ({ type: constants.CLEAN_DETAILED_EMAIL_STATS });
export const getDetailedStatsPerOnetimeCampaign = (hubId, campaignId) => ({ type: constants.GET_DETAILED_STATS_PER_ONETIME_CAMPAIGN, [featuresConstants.HUB_ID]: hubId, [constants.EMAIL_CAMPAIGN_ID]: campaignId });
export const getDetailedStatsPerRule = (hubId, campaigns, ruleId) => ({ type: constants.GET_DETAILED_STATS_PER_RULE, [featuresConstants.HUB_ID]: hubId, campaigns, ruleId });
export const getDetailedStatsPerMicroCampaign = (hubId, campaigns, microCampaignId) => ({ type: constants.GET_DETAILED_STATS_PER_MICRO_CAMPAIGN, [featuresConstants.HUB_ID]: hubId, campaigns, microCampaignId });
export const getEmailUsages = (hubId) => ({ type: constants.GET_EMAIL_USAGES, [featuresConstants.HUB_ID]: hubId });
export const cleanEmailUsagesData = () => ({ type: constants.CLEAN_EMAIL_USAGES_DATA });

