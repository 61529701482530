/*
 *
 * Ai reducer
 *
 */

import { fromJS } from 'immutable';

import * as constants from './ai.constants';

const initObj = {
  [constants.IS_LOADING]: false,
  [constants.PROMPTS]: []
};

const initialState = fromJS(initObj);

function appReducer(state = initialState, action) {

  let newState;

  switch (action.type) {

    case constants.CLEAR_PROMPT: {
      newState = fromJS(initObj);

      break;
    }

    case constants.GET_PROMPT_SUCCESS: {
      newState = state.set(constants.PROMPTS, fromJS(action.data));

      break;
    }

    case constants.GET_PROMPT_ERROR: {
      newState = state;
      break;
    }

    case constants.GET_PROMPT_FAIL: {
      newState = state.set(constants.GET_PROMPT_ERROR_CODE, 500);
      break;
    }

    case constants.SET_LOADING_STATE: {
      newState = (state.get(constants.IS_LOADING) === action[constants.IS_LOADING]) ? state : state.set(constants.IS_LOADING, fromJS(action[constants.IS_LOADING]));
      break;
    }

    default:
      newState = state;
  }
  return newState;
}

export default appReducer;
