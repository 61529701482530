/*
 *
 * Templates reducer
 *
 */

import { fromJS, List } from 'immutable';
import * as constants from './templates.constants';
import adminReducer from './Admin/admin.reducer';

export const initialState = fromJS({
  [constants.BUSINESS_TEMPLATES]: [],
  [constants.PRODUCT_TEMPLATES_CATEGORIES]: [],
  [constants.BUSINESSES_VERTICALS]: [],
  [constants.PRODUCT_TEMPLATES]: {},
  [constants.SAVE_TEMPLATE_IN_PROGRESS]: false
});

function templatesReducer(state = initialState, action) {

  let newState;

  switch (action.type) {
    case constants.GET_BUSINESS_TEMPLATES_SUCCESS: {
      newState = state
        .set(constants.BUSINESS_TEMPLATES, fromJS(action.data))
        .set(constants.TEMPLATES_ENTITY_TYPE, action.entityType);
      break;
    }

    case constants.GET_PRODUCT_TEMPLATES_SUCCESS: {
      newState = state.set(constants.PRODUCT_TEMPLATES, fromJS(action.data));
      break;
    }
    case constants.DELETE_BUSINESS_TEMPLATE_SUCCESS: {
      newState = state.set(constants.BUSINESS_TEMPLATES, fromJS(
        state.get(constants.BUSINESS_TEMPLATES)
          .filter(
            (template) => template.get(constants.DB_ID) !== action.payload
          )
      ));
      break;
    }
    case constants.DELETE_PRODUCT_TEMPLATE_SUCCESS: {
      const { templateId, categoryName } = action.payload;
      newState = state.setIn([constants.PRODUCT_TEMPLATES, categoryName], fromJS(
        state.getIn([constants.PRODUCT_TEMPLATES, categoryName])
          .filter(
            (template) => template.get(constants.DB_ID) !== templateId
          )
      ));

      break;
    }
    case constants.UPDATE_BUSINESS_TEMPLATE_SUCCESS: {
      const { dbId, name, description } = action.payload;
      newState = state
        .update(constants.BUSINESS_TEMPLATES,
        (activities) => activities.map((activity) => {
          if (activity.get(constants.DB_ID) !== dbId) {
            return activity;
          }
          return activity
            .set(constants.TEMPLATE_NAME, name)
            .set(constants.TEMPLATE_DESCRIPTION, description);
        }))
        .set(constants.SAVE_TEMPLATE_IN_PROGRESS, fromJS(false));
      break;
    }
    case constants.UPDATE_PRODUCT_TEMPLATE_SUCCESS: {
      const newTemplate = action.payload;
      const { dbId, categoryDbId } = newTemplate;

      const category = state.get(constants.PRODUCT_TEMPLATES_CATEGORIES)
        .find((item) => item.get(constants.DB_ID) === categoryDbId);
      const categoryName = category.get(constants.NAME);

      let newProductTemplates = state
        .get(constants.PRODUCT_TEMPLATES)
        .map((value) => value.filter((item) => item.get(constants.DB_ID) !== dbId));

      newProductTemplates = newProductTemplates
        .set(categoryName, (newProductTemplates.get(categoryName) || List()).push(fromJS(newTemplate)));
      newState = state
        .set(constants.PRODUCT_TEMPLATES, newProductTemplates)
        .set(constants.SAVE_TEMPLATE_IN_PROGRESS, fromJS(false));
      break;
    }

    case constants.GET_PRODUCT_CATEGORIES_SUCCESS: {
      newState = state.set(constants.PRODUCT_TEMPLATES_CATEGORIES, fromJS(action.payload));
      break;
    }

    case constants.GET_BUSINESSES_VERTICALS_SUCCESS: {
      newState = state.set(constants.BUSINESSES_VERTICALS, fromJS(action.payload));
      break;
    }

    case constants.CLEAR_TEMPLATES: {
      newState = initialState.set(constants.BUSINESSES_VERTICALS, state.get(constants.BUSINESSES_VERTICALS));
      break;
    }

    case constants.SET_SAVE_TEMPLATE_IN_PROGRESS: {
      newState = state.set(constants.SAVE_TEMPLATE_IN_PROGRESS, fromJS(action[constants.SAVE_TEMPLATE_IN_PROGRESS]));
      break;
    }

    case constants.SAVE_AS_TEMPLATE_SUCCESS: {
      newState = state.set(constants.SAVE_TEMPLATE_IN_PROGRESS, fromJS(false));
      break;
    }

    case constants.MARK_SAVE_AS_TEMPLATE_IN_PROGRESS_FALSE: {
      newState = state.set(constants.SAVE_TEMPLATE_IN_PROGRESS, fromJS(false));
      break;
    }

    default: {
      newState = state.set(constants.TEMPLATE_ADMIN, adminReducer(state.get(constants.TEMPLATE_ADMIN), action));
    }
  }

  return newState;
}

export default templatesReducer;
