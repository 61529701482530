import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Icon} from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import * as constants from '../../../../activitySchema/activitySchema.constants';
import DropdownWrapper from "../../../../../../common/components/DropdownWrapper/DropdownWrapper";

class MembershipField extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string,
    onMembershipFieldValueUpdate: PropTypes.func.isRequired,
    onMembershipFieldListUpdate: PropTypes.func.isRequired,
    membershipField: PropTypes.object,
    index: PropTypes.number,
    validationErrors: PropTypes.object,
    fieldListOptions: PropTypes.array,
    selectedFieldValueList: PropTypes.array,
    selectedFieldValue: PropTypes.string,
    removeItem: PropTypes.func.isRequired,
    showDeleteButton: PropTypes.bool,
    parentIndex: PropTypes.number
  };

  onMembershipFieldUpdate = (index, name, value) => {
    this.props.onMembershipFieldListUpdate(index, name, value, constants.MEMBERSHIP_UPDATE_VALUE_FIELD);
  }

  onMembershipFieldValueUpdate = (index, name, value) => {
    this.props.onMembershipFieldValueUpdate(index, name, value);
  }

  getTransformedFieldValueList = (fieldValueList, formatMessage) => {
    return fieldValueList.map(function (fieldValue, _) {
      return {text: formatMessage({id: fieldValue.text}), value: fieldValue.value}
    });
  }

  render() {
    const { index, validationErrors, automationIdPrefix, membershipField, removeItem, showDeleteButton, parentIndex, fieldListOptions } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    return (
      <Grid.Row columns={2}>
        {<Grid.Column width={8}>
          <p className="action-label">{formatMessage({ id: 'activity.action.fieldLists' })}</p>
          <DropdownWrapper
            options={fieldListOptions}
            onSelectOption={(e, data) => this.onMembershipFieldUpdate(index, constants.MEMBERSHIP_UPDATE_KEY_FIELD, data.value)}
            name={`${constants.UPDATE_MEMBERSHIP_FIELD_LISTS}.${constants.MEMBERSHIP_UPDATE_KEY_FIELD}`}
            value={membershipField ? membershipField.get(constants.MEMBERSHIP_UPDATE_KEY_FIELD) : ''}
            prefix={`${automationIdPrefix}.accumulate.tags.types`}
            className="deduct-tags-type"
            error={validationErrors ? validationErrors.get(`${constants.UPDATE_MEMBERSHIP_FIELD_LISTS}.${index}.${constants.MEMBERSHIP_UPDATE_KEY_FIELD}`) : null}
          />
        </Grid.Column>}
        <Grid.Column width={8}>
          {showDeleteButton && <Icon
            style={{ cursor: 'pointer', float: 'right'}}
            className="icon como-ic-delete"
            onClick={() => { removeItem(index, parentIndex); }}
          />}
          <p className="action-label">{formatMessage({ id: 'activity.action.fieldValues' })}</p>
          <div className={"dynamic-tag-wrapper"}>
            {<DropdownWrapper
                options={this.getTransformedFieldValueList(this.props.selectedFieldValueList, formatMessage)}
                onSelectOption={(e, data) => this.onMembershipFieldValueUpdate(index, data.name, data.value)}
                name={constants.MEMBERSHIP_UPDATE_VALUE_FIELD}
                value={this.props.selectedFieldValue}
                prefix={`${automationIdPrefix}.accumulate.tags.dynamic.value`}
                className="deduct-tags-dynamic-value"
                error={validationErrors ? validationErrors.get(`${constants.UPDATE_MEMBERSHIP_FIELD_LISTS}.${index}.${constants.MEMBERSHIP_UPDATE_VALUE_FIELD}`) : null}
            />}
          </div>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

export default injectIntl(MembershipField);
