import axios from 'axios';

const BASE_URL = '/api/goals';

export function getGoals() {
  return axios.get(BASE_URL)
    .then((res) => res.data);
}

export function getGoal(goalId) {
  return axios.get(`${BASE_URL}/${goalId}`)
    .then((res) => res.data);
}

export function createGoal(goal) {
  return axios.post(BASE_URL, goal)
    .then((res) => res.data);
}

export function updateGoal(goal) {
  return axios.put(BASE_URL, goal)
    .then((res) => res.data);
}
