import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import UpAndDownCaret from '../../UpAndDownCaret/UpAndDownCaret';
import './TimeInputFieldWithUpDownButtons.styles.less';
import TimeInputField from './TimeInputField';
import * as utils from './TimeInputFieldWithUpDownButtons.utils';

const AUTOMATION_PREFIX_NAME = 'cycle_counter';

class TimeInputFieldWithUpDownButtons extends React.PureComponent {
  static propTypes = {
    showAs: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    max: utils.numberPropChecker,
    min: utils.numberPropChecker,
    value: utils.numberPropChecker,
    isIncludeMax: PropTypes.bool,
    isIncludeMin: PropTypes.bool,
    className: PropTypes.string,
    prefix: PropTypes.string,
    disabled: PropTypes.bool,
  };

  get absoluteMin() {
    return this.props.isIncludeMin ? this.props.min : this.props.min + 1;
  }

  get absoluteMax() {
    return (this.props.isIncludeMax || this.props.max === 0) ? this.props.max + 1 : this.props.max;
  }

  handleIncrement = () => {

    const { onChange, value: propValue } = this.props;

    let value = (propValue + 1) % this.absoluteMax;

    if (value === 0) {
      value += this.absoluteMin;
      onChange(value, true);
      return;
    }

    onChange(value, false);

  };

  handleDecrement = () => {

    const { onChange, value: propValue } = this.props;
    let value = propValue - 1;

    const absMin = this.absoluteMin;
    if (value < absMin) {
      value = (value + this.absoluteMax) - absMin;
      onChange(value, true);
      return;
    }

    onChange(value, false);
  };

  handlerEditableCounter = (e, value = {}) => {
    const val = value.value;
    const { onChange } = this.props;

    if (typeof val === 'undefined' || val === null) {
      onChange(val);
      return;
    }

    if (this.absoluteMax <= val) {
      return;
    }

    if (this.absoluteMin > val) {
      return;
    }

    onChange(val, false);
  };

  render() {
    const { className, prefix, disabled, showAs, value, min, max } = this.props;
    const isValueNaN = isNaN(value);
    return (
      <div className={`container__counter ${className} ${disabled ? 'disabled' : ''}`}>
        <TimeInputField
          className="numerical-input"
          value={isValueNaN ? null : (showAs ? showAs(value) : value)}
          onChange={this.handlerEditableCounter}
          prefix={`${prefix}.${AUTOMATION_PREFIX_NAME}`}
          disabled={disabled}
          minValue={min}
          maxValue={max}
          digits={_.size(`${max}`)}
        />
        <UpAndDownCaret
          prefix={`${prefix}.${AUTOMATION_PREFIX_NAME}`}
          onIncrement={this.handleIncrement}
          onDecrement={this.handleDecrement}
          disabled={isValueNaN}
        />
      </div>
    );
  }
}

export default TimeInputFieldWithUpDownButtons;
