import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {injectIntl} from 'react-intl';
import { bindActionCreators, compose } from 'redux';
import injectSaga from '../../../utils/injectSaga';
import * as filterMembersActions from '../../../features/FilterMembers/filterMembers.actions';
import saga from '../../../features/Activity/activity.saga';
import * as constants from '../../../features/Activity/activity.constants';
import { getActivitiesList, getActivitiesListFetchedFlag } from '../../../features/FilterMembers/filterMembers.selectors';
import './activitiesList.styles.less';
import DropdownWrapper from "../DropdownWrapper/DropdownWrapper";

export class ActivitiesList extends React.PureComponent {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    activitiesList: PropTypes.object.isRequired,
    activitiesFetched: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    index: PropTypes.number,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    prefix: PropTypes.string,
    value: PropTypes.array,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      isPopupOpen: false
    };
  }

  componentWillMount() {
    if (!this.props.activitiesFetched) {
      this.props.actions.getActivitiesList();
    }
  }

  onChange = (activity) => {
    this.handlePopupClose();
    this.props.onChange(null, {
      name: this.props.name,
      value: activity[constants.SERVER_ID],
      type: activity[constants.TYPE],
      serverEntityKey: activity[constants.SERVER_ID],
      title: activity[constants.FILTER_ACTIVITY_NAME]
    });
  };

  handlePopupClose = () => {
    this.setState({
      isPopupOpen: false
    });
  };

  render() {
    const options = this.props.activitiesList.map((a) => {
      return {text: a.get(constants.NAME), value: a.get(constants.SERVER_ID)};
    });

    const value = this.props.value ? this.props.value.map((val) => val.toString()) : [];
    return (
      <DropdownWrapper
        className={this.props.className}
        options={options.toJS()}
        onSelectOption={this.props.onChange}
        disabled={this.props.disabled}
        index={this.props.index}
        prefix={this.props.prefix}
        error={this.props.error}
        value={value}
        multiple
        search
        //  placeholder={this.props.intl.formatMessage({ id: 'dropdown.placeholder.select.or.add' })}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  activitiesList: getActivitiesList(state),
  activitiesFetched: getActivitiesListFetchedFlag(state),
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(filterMembersActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({key: constants.ACTIVITY, saga});

export default compose(
  withConnect,
  withSaga,
  injectIntl
)(ActivitiesList);
