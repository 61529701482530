/*
 *
 * Forms actions
 *
 */

import * as constants from './forms.constants';

export function getFormsList() {
  return {
    type: constants.GET_FORMS
  };
}

export function getRegistrationFormFields() {
  return {
    type: constants.GET_REGISTRATION_FORM_FIELDS
  };
}
