import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Header, Icon, Menu } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as constants from '../businessCenter.constants';
import FilterSettings from '../../../common/components/FilterSettings/FilterSettings';
import tracker from '../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../common/utils/tracking/tracking.consts';
import * as activityConstants from '../../Activity/activity.constants';
import * as appConstants from '../../App/app.constants';

class BusinessCenterToolbar extends React.PureComponent {

  static propTypes = {
    createCampaign: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    triggers: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    isFiltered: PropTypes.bool,
    showBasicPlan: PropTypes.bool,
    onFilterUpdate: PropTypes.func.isRequired,
    onFilterTriggerUpdate: PropTypes.func.isRequired,
    onFilterActionUpdate: PropTypes.func.isRequired,
    onFilterTagUpdate: PropTypes.func.isRequired,
    onSortUpdate: PropTypes.func.isRequired,
    sort: PropTypes.string,
    filtersToDisable: PropTypes.object,
    allOtherActivityTags: PropTypes.array,
    activityTagsFilter: PropTypes.array,
    hasFullPermission: PropTypes.func.isRequired,
  };

  onAddBoxClick = () => {
    const { formatMessage } = this.props.intl;
    this.props.createCampaign({
      name: formatMessage({ id: 'business-center.campaign.toolbar.create.campaign.untitled' }),
      status: constants.CAMPAIGN_STATUS_ACTIVE
    });
  };


  isThisFilterDisabled = (filterType) => {
    return this.props.filtersToDisable.has(filterType) && this.props.filtersToDisable.get(filterType);
  };

  render() {
    const { filters, onFilterUpdate, onFilterTriggerUpdate, onFilterActionUpdate, onSortUpdate, isFiltered, triggers,
      actions, allOtherActivityTags, onFilterTagUpdate, activityTagsFilter } = this.props;
    const sortIcon = this.props.sort === constants.ORDER_DESCENT ? 'como-ic-down-arrow' : 'como-ic-arrow-up';
    const { formatMessage } = this.props.intl;

    return (
      <Grid columns="equal" className="businessCenterToolbar">
        <Grid.Row className="business-center-toolbar-header">
          <Grid.Column>
            <Header className="campaigns-header">{formatMessage({ id: 'business-center.campaigns' })}</Header>
            <Button
              secondary
              size="tiny"
              className="add-box-button"
              onClick={tracker.wrapButtonAction(this.onAddBoxClick, trackerConstants.BUTTON_TYPE_CAMPAIGN_ADD)}
              data-automation-id="business-center.add-campaign"
            >
              <Icon className="como-ic-plus" />
              <span>{formatMessage({ id: 'business-center.add_campaign' })}</span>
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="business-center-toolbar">
          <Grid.Column>
            <Menu secondary>
              {!this.props.showBasicPlan && <><FilterSettings
                  messagesNamespace="business-center"
                  filters={filters}
                  onFilterUpdate={onFilterUpdate}
                  isFiltered={isFiltered}
                  filterKeysToExclude={[constants.FILTERS_CAMPAIGN_ARCHIVED]}
              />
                <span>&nbsp;|&nbsp;</span>
              <FilterSettings
                messagesNamespace="business-center"
                filterType={activityConstants.TRIGGER}
                filters={triggers}
                onFilterUpdate={onFilterTriggerUpdate}
                isFiltered={isFiltered}
                isDisabled={this.isThisFilterDisabled(activityConstants.TRIGGER)}
              />
              <span>&nbsp;|&nbsp;</span></>}
              <FilterSettings
                messagesNamespace="business-center"
                filterType={activityConstants.ACTION}
                filters={actions}
                onFilterUpdate={onFilterActionUpdate}
                isFiltered={isFiltered}
                isDisabled={this.isThisFilterDisabled(activityConstants.ACTION)}
              />
              {(this.props.hasFullPermission(appConstants.FEATURE_FILTER_ACTIVITY_INPUT)) &&
                <>
                  <span>&nbsp;|&nbsp;</span>
                  <FilterSettings
                    messagesNamespace="business-center"
                    filters={filters}
                    onFilterUpdate={onFilterUpdate}
                    isFiltered={isFiltered}
                    filterType={activityConstants.FILTER_BY_ACTIVITY}
                    filterKeysToExclude={[activityConstants.FILTER_BY_ACTIVITY]}
                  />
                </>
              }
              {(this.props.hasFullPermission(appConstants.FEATURE_ACTIVITY_TAGS)) &&
                <>
                  <span>&nbsp;|&nbsp;</span>
                  <FilterSettings
                    messagesNamespace="business-center"
                    filterType={activityConstants.TAG}
                    filters={allOtherActivityTags}
                    onFilterUpdate={onFilterTagUpdate}
                    isFiltered={isFiltered}
                    isDisabled={this.isThisFilterDisabled(activityConstants.TAG)}
                    tags={activityTagsFilter}
                  />
                </>
              }
              <span>&nbsp;|&nbsp;</span>
              <Menu.Item
                className="sort"
                onClick={tracker.wrapButtonAction(onSortUpdate, trackerConstants.BUTTON_TYPE_CAMPAIGNS_SORT)}
                data-automation-id="business-center.sort-sets"
              >
                <FormattedMessage id="business-center.last_created" />
              </Menu.Item>
              <Icon className={`${sortIcon} sort-icon`} />
            </Menu>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default injectIntl(BusinessCenterToolbar);
