/*
 *
 * ErrorMessage actions
 *
 */

import * as constants from './errorMessage.constants';

export function handleErrorMessageClose(shouldGoBack) {
  return {
    type: constants.HANDLE_ERROR_MESSAGE_CLOSE,
    shouldGoBack
  };
}

export function handleTooManyMembersPopup(show) {
  return {
    type: constants.HANDLE_TO_MANY_MEMBERS_POPUP_VISIBILITY,
    show
  };
}

export function handleError(errorKeys, errorHeader = 'error.message.header') {
  return {
    type: constants.HANDLE_ERROR,
    [constants.ERROR_TITLE]: errorHeader,
    [constants.MESSAGES_KEYS]: [...errorKeys],
    [constants.ERROR_TYPE]: constants.ERROR_TYPE_USER
  };
}
