import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { Header, Icon } from 'semantic-ui-react';
import { Ref } from '@stardust-ui/react-component-ref';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import * as activityConstants from '../../activity.constants';
import DisabledItemPosRestriction from '../../../../common/components/DisabledItem/DisabledItemPosRestriction';
import DisabledAddOn from '../../../../common/components/DisabledItem/DisabledAddOn';
import withAuthorization from '../../../../common/helpers/authorization';
import { isChromeBrowser } from '../../../../common/utils/browserUtils';
import * as appConstants from '../../../App/app.constants';

class TriggersList extends React.PureComponent {

  static propTypes = {
    getPermissionLevelAndAddOn: PropTypes.func.isRequired,
    isFeatureAddOn: PropTypes.func.isRequired,
    onSelectOption: PropTypes.func.isRequired,
    getBusinessBundlePlan: PropTypes.func.isRequired,
    options: PropTypes.object.isRequired,
    selectedTrigger: PropTypes.string.isRequired,
    triggerKeyPrefix: PropTypes.string,
    triggerListElemPrefix: PropTypes.string
  };

  static defaultProps = {
    triggerKeyPrefix: 'activity.triggers'
  };

  constructor(props) {
    super(props);
    this.isSelectedTriggerPrimary = this.isSelectedTriggerPrimary.bind(this);
    this.handleTogglePrimary = this.handleTogglePrimary.bind(this);
    this.state = {
      primaryOnly: true,
      triggersCount: null
    };
  }

  componentWillMount() {
    const isSelectedTriggerPrimary = this.isSelectedTriggerPrimary(this.props.options, this.props.selectedTrigger);
    this.setState({
      primaryOnly: (_.isEmpty(this.props.selectedTrigger) || isSelectedTriggerPrimary) && this.props.getBusinessBundlePlan() !== appConstants.TRIAL
    });
    this.getTriggersCount();
  }

  onSelect = (e, option) => {
    this.props.onSelectOption(e, option);
  };

  /**
   * calculate the available triggers count
   */
  getTriggersCount = () => {
    const triggersCount = this.props.options.reduce((count, triggerGroup) => {
      const triggerGroupCount = triggerGroup.get(activityConstants.VALUES).reduce((groupCount, trigger) => {
        if (trigger.get(activityConstants.NAME) === this.props.selectedTrigger || !trigger.get(activityConstants.DEPRECATED)) {
          // count only triggers that are not deprecated,
          // if they are currently selected count them even if they are deprecated
          return groupCount + 1;
        }
        return groupCount;
      }, 0);
      return count + triggerGroupCount;
    }, 0);
    this.setState({ triggersCount });
  };

  getDisplayGroups(groups, businessPlan) {
    if (businessPlan !== appConstants.REVEL && businessPlan !== appConstants.TRIAL) {
      return groups;
    }

    let essentialsOffGroup = List();
    groups.forEach((group) => {
      const options = group.get(activityConstants.VALUES).filter((option) => !this.isOptionFeatureRestricted(option));
      if (options.size > 0) {
        essentialsOffGroup = essentialsOffGroup.push(group.set(activityConstants.VALUES, options));
      }
    });
    return essentialsOffGroup;
  }

  isOptionFeatureRestricted(option) {
    const features = option.get(activityConstants.FEATURES) || [];
    const essentialsOffFeature = features.find((feature) => feature.get(appConstants.FEATURE_NAME) === appConstants.FEATURE_NAME_ESSENTIALS_OFF);
    const basicExcludeFeature = features.find((feature) => feature.get(appConstants.FEATURE_NAME) === appConstants.FEATURE_NAME_BASIC_PLAN_EXCLUDE);
    const hasEssentialsRestriction = essentialsOffFeature && this.props.getPermissionLevelAndAddOn(essentialsOffFeature.get(activityConstants.FEATURE_NAME)).level === activityConstants.FEATURE_LEVEL.NONE;
    const hasBasicExcludeRestriction = basicExcludeFeature && this.props.getPermissionLevelAndAddOn(basicExcludeFeature.get(activityConstants.FEATURE_NAME)).level === activityConstants.FEATURE_LEVEL.FULL;
    return hasEssentialsRestriction || hasBasicExcludeRestriction;
  }

  getPrimaryOptions(plan) {
    let primaryOptions = List();
    this.props.options.forEach((group) => {
      const primaryOptionsPerGroup = group.get(activityConstants.VALUES).filter((option) => option.get(activityConstants.PRIMARY_TRIGGER) === true);
      primaryOptionsPerGroup.forEach((option) => {
        if (plan !== appConstants.REVEL && plan !== appConstants.TRIAL) {
          primaryOptions = primaryOptions.push(option);
        } else {
          const isFeatureRestricted = this.isOptionFeatureRestricted(option);
          if (!isFeatureRestricted) {
            primaryOptions = primaryOptions.push(option);
          }
        }
      });
    });
    const sortedPrimaryOptions = primaryOptions.sort(
      (a, b) => a.get(activityConstants.PRIMARY_TRIGGER_ORDER) - b.get(activityConstants.PRIMARY_TRIGGER_ORDER));
    let sortedPrimaryGroup = Map();
    sortedPrimaryGroup = sortedPrimaryGroup
      .set(activityConstants.KEY, activityConstants.PRIMARY_TRIGGER)
      .set(activityConstants.VALUES, sortedPrimaryOptions);
    return new List().push(sortedPrimaryGroup);
  }

  setPopupBottomRef = (node) => {
    this.setState({
      popupBottomRef: node
    });
  };

  /**
   * determine whether to open the triggers list in a collapsed or expanded state
   * according to the selected gertrig's primary flag
   * @param {List} triggerGroups
   * @param {String} triggerStr
   */
  isSelectedTriggerPrimary = (triggerGroups, triggerStr) => {
    const selectedTriggerObj = triggerGroups.reduce((selected, triggerGroup) => {
      const selectedTrigger = triggerGroup.get('values').find((trigger) => trigger.get('name') === triggerStr);
      return selectedTrigger || selected;
    }, Map());
    return selectedTriggerObj ? selectedTriggerObj.get('primary') : true;
  };

  /**
   * toggle between trigger list expanded/collapsed view
   */
  handleTogglePrimary = () => {
    this.setState({
      primaryOnly: !this.state.primaryOnly
    }, this.scrollToBottom());
  };

  scrollToBottom = () => {
    setTimeout(() => {
      if (this.state.popupBottomRef) {
        this.state.popupBottomRef.scrollIntoView({ block: 'nearest', inline: 'nearest', behavior: 'smooth' });
      }
    }, 0);
  };

  scrollToSelected = (node) => {
    if (node && isChromeBrowser()) {
      // first scroll window so whole window is visible
      this.scrollToBottom();

      // then scroll inner window to reveal selected option
      setTimeout(() => {
        const rect = node.parentNode.getBoundingClientRect();
        const vHeight = window.innerHeight;
        if ((rect.bottom + 400) > vHeight) {
          node.parentNode.scrollIntoView({ block: 'nearest', inline: 'nearest', behavior: 'smooth' });
        }
      }, 500);
    }
  };

  render() {
    const { getPermissionLevelAndAddOn, isFeatureAddOn, getBusinessBundlePlan } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const listStyle = classNames('two-columned', {
      grouped: !this.state.primaryOnly
    });
    const plan = getBusinessBundlePlan();
    const primaryGroup = this.getPrimaryOptions(plan);
    const displayGroups = this.state.primaryOnly ? primaryGroup : this.getDisplayGroups(this.props.options, plan);
    const triggerListElemPrefix = `${this.props.triggerListElemPrefix}.`;
    return (
      <div className="triggers-list">
        <div>
          <ul className={listStyle}>
            {
              displayGroups.map((optGroup) => (
                <li key={optGroup.get(activityConstants.KEY)}>
                  {!this.state.primaryOnly &&
                  <Header>
                    <i className={`como-svg-icon-${optGroup.get(activityConstants.KEY)} trigger-icon`} />
                    {
                      formatMessage({ id: `activity.trigger.group.${optGroup.get(activityConstants.KEY)}` })
                    }
                  </Header>
                  }
                  <ul className="selectable-options">
                    {
                      optGroup.get(activityConstants.VALUES).map((option, index) => {

                        if (option.get(activityConstants.DEPRECATED)) {
                          return null;
                        }
                        const features = option.get(activityConstants.FEATURES) || [];
                        const isFeatureRestricted = features.some((feature) => {
                          const { level, isAddOn } = getPermissionLevelAndAddOn(feature.get(activityConstants.FEATURE_NAME));
                          if (feature.get(activityConstants.FEATURE_NAME) === appConstants.FEATURE_NAME_BASIC_PLAN_EXCLUDE) {
                            return level === activityConstants.FEATURE_LEVEL.FULL && !isAddOn;
                          }
                          return level === activityConstants.FEATURE_LEVEL.NONE && !isAddOn;
                        });

                        if (isFeatureRestricted) {
                          return (
                            <li
                              className="disabled pos-restriction"
                              key={`option.name_${index.toString()}`}
                              data-automation-id={`${this.props.triggerListElemPrefix ? triggerListElemPrefix : ''}trigger.${option.get(activityConstants.NAME)}`}
                            >
                              <DisabledItemPosRestriction>
                                <div>
                                  <i className={`como-svg-icon-${option.get(activityConstants.NAME)} trigger-icon`} />
                                  {formatMessage({
                                    id: `${this.props.triggerKeyPrefix}.${option.get(activityConstants.NAME)}`
                                  })}
                                </div>
                              </DisabledItemPosRestriction>
                            </li>
                          );
                        }

                        const isAddOnRestricted = features.some(
                            (feature) => {
                              const { level, isAddOn } = getPermissionLevelAndAddOn(feature.get(activityConstants.FEATURE_NAME));
                              return level === activityConstants.FEATURE_LEVEL.NONE && isAddOn;
                            });
                        const essentialsOffFeature = features.find((feature) => feature.get(appConstants.FEATURE_NAME) === appConstants.FEATURE_NAME_ESSENTIALS_OFF);
                        if (isAddOnRestricted) {
                          return (
                            <li
                              className="disabled"
                              key={`option.name_${index.toString()}`}
                              data-automation-id={`${this.props.triggerListElemPrefix ? triggerListElemPrefix : ''}trigger.${option.get(activityConstants.NAME)}`}
                            >
                              <DisabledAddOn className="space-between" bundlePlan={essentialsOffFeature ? plan : null}>
                                <div>
                                  <i className={`como-svg-icon-${option.get(activityConstants.NAME)} trigger-icon`} />
                                  {formatMessage({
                                    id: `${this.props.triggerKeyPrefix}.${option.get(activityConstants.NAME)}`
                                  })}
                                </div>
                                <Icon className="como-ic-add-on" />
                              </DisabledAddOn>
                            </li>
                          );
                        }

                        // If it's there solely because it's part of a plan - no need ot show the icon
                        const showAddonIcon = features.some((feature) => isFeatureAddOn(feature.get(activityConstants.FEATURE_NAME)) && feature.get(appConstants.FEATURE_TYPE) !== appConstants.FEATURE_PLAN);
                        const selected = this.props.selectedTrigger === option.get(activityConstants.NAME);
                        const isDeprecated = option.get(activityConstants.DEPRECATED);
                        const showTrigger = selected || !isDeprecated;
                        return showTrigger
                        ? (
                          <li
                            key={`option.name_${index.toString()}`}
                            className={classNames('svg-icon-container', { selected })}
                            onClick={(e) => !isFeatureRestricted && !isAddOnRestricted && this.onSelect(e, option)}
                          >
                            <div
                              data-automation-id={`${this.props.triggerListElemPrefix ? triggerListElemPrefix : ''}trigger.${option.get(activityConstants.NAME)}`}
                              ref={selected ? this.scrollToSelected : null}
                              role={'button'}
                              tabIndex={-1}
                            >
                              <i className={`como-svg-icon-${option.get(activityConstants.NAME)} trigger-icon`} />
                              {formatMessage({
                                id: `${this.props.triggerKeyPrefix}.${option.get(activityConstants.NAME)}`
                              })}
                            </div>
                            <div>
                              {showAddonIcon && <Icon className="como-ic-add-on" />}
                              <i className="icon como-ic-checkmark" />
                            </div>
                          </li>
                        )
                        : null;
                      })
                    }
                  </ul>
                </li>
              ))
            }
          </ul>
        </div>
        { plan !== appConstants.TRIAL && <div className="expandCollapseHandler">
          {this.state.primaryOnly
            ? (
              <a
                tabIndex={-1}
                className="primary-only-toggle"
                onClick={this.handleTogglePrimary}
                role="button"
                data-automation-id="trigger.see-more"
              >
                {formatMessage({ id: 'activity.triggers.seeAll' }, { count: this.state.triggersCount })}&nbsp;
                <Icon className="como-ic-angle-double-down" size="small" />
              </a>
            )
            : (
              <a
                tabIndex={-1}
                className="primary-only-toggle"
                onClick={this.handleTogglePrimary}
                role="button"
                data-automation-id="trigger.see-less"
              >
                {formatMessage({ id: 'activity.triggers.seeTop' })}&nbsp;
                <Icon className="como-ic-angle-double-up" size="small" />
              </a>
            )
          }
          <Ref innerRef={this.setPopupBottomRef}>
            <div className="empty-div" />
          </Ref>
        </div> }
      </div>
    );

  }
}

export default withAuthorization(injectIntl(TriggersList));
