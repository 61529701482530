import moment from 'moment/min/moment-with-locales';
import { addLocaleData } from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import browserLocale from 'browser-locale';
import * as datesConstants from './common/utils/dateTime.constants';


export const getBrowserLocaleData = () => {
  const localeStr = browserLocale();
  moment.locale(localeStr);
  return {
    browserLocale: moment.locale(),
    dateFormat: moment.localeData().longDateFormat('L'),
    timeFormat: moment.localeData().longDateFormat('LT')
  };
};

export const configure = () => {
  // add support per language
  addLocaleData({ locale: 'ar', parentLocale: 'en' });
  addLocaleData({ locale: 'da', parentLocale: 'en' });
  addLocaleData({ locale: 'de', parentLocale: 'en' });
  addLocaleData({ locale: 'es', parentLocale: 'en' });
  addLocaleData({ locale: 'fr', parentLocale: 'en' });
  addLocaleData({ locale: 'numeric', parentLocale: 'en' });
  addLocaleData({ locale: 'he', parentLocale: 'en' });
  addLocaleData({ locale: 'hu', parentLocale: 'en' });
  addLocaleData({ locale: 'it', parentLocale: 'en' });
  addLocaleData({ locale: 'pl', parentLocale: 'en' });
  addLocaleData({ locale: 'pt', parentLocale: 'en' });
  addLocaleData({ locale: 'ro', parentLocale: 'en' });
  addLocaleData({ locale: 'ru', parentLocale: 'en' });
  addLocaleData({ locale: 'se', parentLocale: 'en' });
  addLocaleData({ locale: 'tr', parentLocale: 'en' });
  addLocaleData({ locale: 'zh', parentLocale: 'en' });
  addLocaleData(enLocaleData);
  // set default format
  moment.defaultFormat = datesConstants.STANDARD_TIME_FORMAT;
};
