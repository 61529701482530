import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import * as appSelectors from '../../../App/selectors';
import * as imageSelectors from '../../images.selectors';
import * as activitySelectors from '../../../Activity/activity.selectors';
import injectSaga from '../../../../utils/injectSaga';
import * as reducerConstants from '../../../../constants/reducer.constants';
import imagesSaga from '../../images.saga';
import * as activityActions from '../../../Activity/activity.actions';
import Gallery from '../ImageGallery/ImageGallery.container';
import { Grid, Icon, Image } from 'semantic-ui-react';
import * as activityConstants from '../../../Activity/activity.constants';
import classNames from 'classnames';
import * as imagesConstants from '../../images.constants';
import './locationsGallery.styles.less';

class LocationsGallery extends React.PureComponent {

  state = {
    imageId: null
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.imageId && nextProps.imageId !== this.state.imageId) {
      this.setState({ imageId: nextProps.imageId });
    }
  }

  onImageSelected = (type, selectedId) => {
    let fieldName = '';
    switch (type) {
      case activityConstants.IMAGE_TYPE_BACKGROUND:
        fieldName = activityConstants.BACKGROUND_IMAGE_ID;
        break;
      default:
        fieldName = activityConstants.PREVIEW_IMAGE_ID;
    }

    this.props.activityActions.updateDisplayField([{
      [activityConstants.DISPLAY_TAB_FIELD_NAME]: fieldName,
      [activityConstants.DISPLAY_TAB_FIELD_VALUE]: selectedId
    }]);
    this.setState({
      imageId: selectedId,
    });

    if(this.props.onImageSelected)
      this.props.onImageSelected(selectedId);
  };

  render() {
    const { formatMessage } = this.props.intl;
    const { imageUrl, isImageSelected } = this.props;
    const selectedImage = this.props.selectedPreviewImage || imageUrl;

    const imageType = activityConstants.IMAGE_TYPE_PREVIEW;
    const imageWrapperClassNames = classNames('image-wrapper', {
      'no-image-selected': !selectedImage
    });

    // const defaultImageUrl = this.props.defaultStampImage;
    let triggerImageUrl = null;

    if (imageUrl) {
      triggerImageUrl = imageUrl;
    }

    if (this.props.selectedPreviewImage && this.props.isImageSelected) {
      triggerImageUrl = this.props.selectedPreviewImage.get(imagesConstants.URL);
    }

    const changeImageButtonText = formatMessage({
      id: selectedImage
        ? 'activity.displayTab.change.image'
        : 'activity.displayTab.select.image'
    });

    return (
      <div className="locations-gallery">
        <p className="action-label">{formatMessage({ id: `activity.displayTab.${imageType}.image` })}</p>
        <Gallery
          onApply={(e, data) => this.onImageSelected(imageType, data.selectedId)}
          selectedId={this.state.imageId ? this.state.imageId : null}
          images={this.props.images}
          imageUploadInstructionsKey={'imageGallery.upload.preview.image.instruction'}
          defaultImage={this.props.defaultImage}
          defaultIfNull
          triggerNode={
            <div
              className={`image-trigger ${imageType}-image-trigger`}
              data-automation-id={`select.${imageType}.image.trigger`}
            >
              {selectedImage && triggerImageUrl ?
                <div className={imageWrapperClassNames}>
                  <Image
                    data-automation-id={`select.${imageType}.image`}
                    className="gallery-image"
                    style={{ backgroundImage: `url(${encodeURI(triggerImageUrl)})` }}
                  />
                </div>
                :
                <div className="locations-gallery-default-image-container">
                  <div className="locations-gallery-default-image">
                    <i className="como-svg-locations-gallery-default-image" />
                    <div className="locations-gallery-default-image-overlay">
                      <i className="como-svg-locations-gallery-choose-image" />
                      <p>{formatMessage({ id: 'locations.gallery.choose.image' })}</p>
                      <span>{formatMessage({ id: 'locations.gallery.choose.image.description' })}</span>
                    </div>
                  </div>
                </div>
              }
              <div className="change-image-button" data-automation-id={`select.${imageType}.image.trigger.button`}>
                <Icon className="como-ic-pencil" />{changeImageButtonText}
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  defaultPreviewImage: imageSelectors.getDefaultImageThumbnail(state),
  defaultStampImage: imageSelectors.getDefaultStampImageThumbnail(state),
  images: imageSelectors.getAllActiveImageThumbnails(state),
  selectedBackgroundImage: activitySelectors.getSelectedBackgroundImage(state),
  selectedStampImage: activitySelectors.getSelectedStampImage(state),
  selectedPreviewImage: activitySelectors.getSelectedPreviewImage(state)
});

function mapDispatchToProps(dispatch) {
  return {
    activityActions: bindActionCreators(activityActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withImages = injectSaga({ key: reducerConstants.IMAGES_BRANCH, saga: imagesSaga });

export default compose(
  withImages,
  withConnect
)(injectIntl(LocationsGallery));
