import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import moment from 'moment';
import * as constants from './goals.constants';
import * as api from './goals.api';
import * as routeConstants from '../../constants/route.contants';
import * as businessCenterApi from '../BusinessCenter/businessCenter.api';
import * as businessCenterActions from '../BusinessCenter/businessCenter.actions';
import * as businessCenterConstants from '../BusinessCenter/businessCenter.constants';
import * as activityConstants from '../Activity/activity.constants';
import * as templatesConstants from '../Templates/templates.constants';
import * as appActions from '../App/app.actions';
import * as errorConstant from '../ErrorMessage/errorMessage.constants';

function* getGoals() {
  try {
    yield put(appActions.setLoadingState(true));
    const data = yield call(api.getGoals);
    yield put({ type: constants.GET_GOALS_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getGoals.Fail'] });
  } finally {
    yield put(appActions.setLoadingState(false));
  }
}

function* getGoal(goalId) {
  try {
    yield put(appActions.setLoadingState(true));
    const data = yield call(api.getGoal, goalId);
    yield put({ type: constants.GET_GOAL_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getGoal.Fail'] });
  } finally {
    yield put(appActions.setLoadingState(false));
  }
}

function prepareActivityForServer(template, goalId, locationId, campaignId) {
  const activity = template[templatesConstants.ACTIVITY];
  activity[activityConstants.HUB_ID] = null;
  activity[activityConstants.DB_ID] = null;
  activity[activityConstants.SERVER_ID] = null;
  activity[activityConstants.PUBLISH_DATE] = null;

  return {
    ...activity,
    [activityConstants.GOAL_KEY]: goalId,
    [activityConstants.TEMPLATE_KEY]: template[templatesConstants.DB_ID],
    [activityConstants.CAMPAIGN_KEY]: campaignId,
    [activityConstants.LOCATION_ID]: locationId,
    [activityConstants.NAME]: `${activity[activityConstants.NAME]} ${moment().format(constants.TIMESTAMP_FORMAT_OF_ACTIVITY_NAME)}`
  };
}

function* createBundle(locationId, goalId, goalName, rulesTemplates, dealsTemplates, oneTimeActionsTemplates) {
  try {
    yield put(appActions.setLoadingState(true));
    const campaignData = yield call(businessCenterApi.createCampaign, {
      [businessCenterConstants.CAMPAIGN_NAME]: goalName,
      [businessCenterConstants.CAMPAIGN_STATUS]: businessCenterConstants.CAMPAIGN_STATUS_ACTIVE,
      [businessCenterConstants.CAMPAIGN_GOAL_ID]: goalId
    });

    const campaignId = campaignData[businessCenterConstants.ID];

    if (Array.isArray(rulesTemplates) && rulesTemplates.length > 0) {
      yield call(businessCenterApi.saveRulesActivities, rulesTemplates.map((rule) => prepareActivityForServer(rule, goalId, locationId, campaignId)));
    }

    if (Array.isArray(dealsTemplates) && dealsTemplates.length > 0) {
      yield call(businessCenterApi.saveDealsActivities, dealsTemplates.map((deal) => prepareActivityForServer(deal, goalId, locationId, campaignId)));
    }

    if (Array.isArray(oneTimeActionsTemplates) && oneTimeActionsTemplates.length > 0) {
      yield call(businessCenterApi.saveOneTimeActionsActivities, oneTimeActionsTemplates.map((oneTimeAction) => prepareActivityForServer(oneTimeAction, goalId, locationId, campaignId)));
    }

    yield put(businessCenterActions.focusCampaign(campaignId));

    yield put(push({
      pathname: `${routeConstants.BUSINESS_CENTER_ROUTE}`,
      search: location.search,
    }));

  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['createBundle.Fail'] });
  } finally {
    yield put(appActions.setLoadingState(false));
  }
}


function* goalsSaga() {
  yield [
    takeLatest(constants.GET_GOALS, getGoals),
    takeLatest(constants.GET_GOAL, ({ goalId } = {}) => getGoal(goalId)),
    takeEvery(constants.CREATE_BUNDLE, ({ locationId, goalId, goalName, rulesTemplates, dealsTemplates, oneTimeActionsTemplates } = {}) => createBundle(locationId, goalId, goalName, rulesTemplates, dealsTemplates, oneTimeActionsTemplates))
  ];
}

export default goalsSaga;
