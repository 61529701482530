/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import { reducer as toastrReducer } from './common/components/ReduxToast';


import * as constants from './constants/reducer.constants';
import activityReducer from './features/Activity/reducers/activity.reducer';
import appReducer from './features/App/app.reducer';
import aiReducer from './features/Ai/ai.reducer';
import benefitsReducer from './features/Benefits/benefits.reducer';
import formsReducer from './features/Forms/forms.reducer';
import businessCenterReducer from './features/BusinessCenter/businessCenter.reducer';
import imagesReducer from './features/Images/images.reducer';
import itemGroupsReducer from './features/ItemGroups/itemGroups.reducer';
import genericActionReducer from './features/GenericAction/genericAction.reducer';
import mailTemplatesReducer from './features/MailTemplates/mailTemplates.reducer';
import templatesReducer from './features/Templates/templates.reducer';
import activityGoalsReducer from './features/ActivityGoals/goals.reducer';
import errorMessageReducer from './features/ErrorMessage/errorMessage.reducer';
import sideNavBarReducer from './features/SideNavBar/sideNavBar.reducer';
import giftsReducer from './features/Gifts/gifts.reducer';
import promoCodesReducer from './features/PromoCodes/promoCodes.reducer';
import emailReducer from './features/Email/email.reducer';
import emailsReducer from './features/Emails/emails.reducer';
import membersReducer from './features/Members/members.reducer';
import microCampaignsReducer from './features/MicroCampaigns/microCampaigns.reducer';
import filterMembersReducer from './features/FilterMembers/filterMembers.reducer';
import dataAndBiReducer from './features/DataAndBI/dataAndBi.reducer';
import microCampaignReducer from './features/MicroCampaign/microCampaign.reducer';
import pointShopReducer from './features/PointShop/pointShop.reducer';
import comilliaHintReducer from './features/ComilliaHint/comilliaHint.reducer';
import customerPortalReducer from './features/CustomerPortal/customerPortal.reducer';
import agentConfigurationReducer from "./features/AgentConfiguration/agentConfiguration.reducer";

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */

// Initial routing state
const routeInitialState = fromJS({ location: null });

/**
 * Merge route into the global application state
 */
function routeReducer(state = routeInitialState, action) {
  if (action.type === LOCATION_CHANGE) {
    return state.merge({ location: action.payload });
  }
  return state;

}

function reduceReducers(state, action, reducersMap) {
  return Object.keys(reducersMap).reduce((newState, branch) =>
    newState.set(branch, reducersMap[branch](newState.get(branch), action, newState)), state);
}

export default function createReducer() {
  return (state = fromJS({}), action) => reduceReducers(state, action, {
    [constants.ROUTE_BRANCH]: routeReducer,
    [constants.TOASTR_BRANCH]: toastrReducer,
    [constants.ACTIVITY_BRANCH]: activityReducer,
    [constants.APP_BRANCH]: appReducer,
    [constants.AI_BRANCH]: aiReducer,
    [constants.BENEFITS_BRANCH]: benefitsReducer,
    [constants.FORMS_BRANCH]: formsReducer,
    [constants.BUSINESS_CENTER_BRANCH]: businessCenterReducer,
    [constants.IMAGES_BRANCH]: imagesReducer,
    [constants.ITEM_GROUPS_BRANCH]: itemGroupsReducer,
    [constants.GENERIC_ACTION_BRANCH]: genericActionReducer,
    [constants.MAIL_TEMPLATES_BRANCH]: mailTemplatesReducer,
    [constants.TEMPLATES_BRANCH]: templatesReducer,
    [constants.ACTIVITY_GOALS_BRANCH]: activityGoalsReducer,
    [constants.ERROR_MESSAGE_BRANCH]: errorMessageReducer,
    [constants.SIDE_NAVBAR_BRANCH]: sideNavBarReducer,
    [constants.GIFTS_BRANCH]: giftsReducer,
    [constants.PROMO_CODES_BRANCH]: promoCodesReducer,
    [constants.EMAIL_BRANCH]: emailReducer,
    [constants.EMAILS_BRANCH]: emailsReducer,
    [constants.CUSTOMER_BRANCH]: membersReducer,
    [constants.MICRO_CAMPAIGNS_BRANCH]: microCampaignsReducer,
    [constants.MICRO_CAMPAIGN_BRANCH]: microCampaignReducer,
    [constants.POINT_SHOP_BRANCH]: pointShopReducer,
    [constants.FILTER_MEMBERS]: filterMembersReducer,
    [constants.DATA_AND_BI]: dataAndBiReducer,
    [constants.COMILLIA_HINT_BRANCH]: comilliaHintReducer,
    [constants.CUSTOMER_PORTAL_BRANCH]: customerPortalReducer,
    [constants.AGENT_CONFIGURATION_BRANCH]: agentConfigurationReducer,
  });
}
