import { fromJS } from 'immutable';
import _ from 'lodash';
import moment from 'moment';
import * as constants from '../Activity/activity.constants';
import * as validationUtils from '../Activity/reducers/utils/validations/conditions.validation.utils';
import { getSchema } from '../Activity/activitySchema/activitySchema.selectors';
import * as itemPopulationConstants from '../Activity/views/itemsPopulation/itemsPopulation.constants';
import * as schemaConstants from '../Activity/activitySchema/activitySchema.constants';
import * as datesConstants from '../../common/utils/dateTime.constants';
// images for members
import hatGlasses from '../../../app/images/icons/memberImages/hatGlasses.svg';
import glasses from '../../../app/images/icons/memberImages/glasses.svg';
import hair from '../../../app/images/icons/memberImages/hair.svg';
import headphones from '../../../app/images/icons/memberImages/headphones.svg';
import mustache from '../../../app/images/icons/memberImages/mustache.svg';
import mustacheGlasses from '../../../app/images/icons/memberImages/mustacheGlasses.svg';
import ninja from '../../../app/images/icons/memberImages/ninja.svg';
import superWomen from '../../../app/images/icons/memberImages/superWomen.svg';
import workOut from '../../../app/images/icons/memberImages/workOut.svg';

export function validateTempFilters(state, rootState) {
  let newState = validateFiltersInTempModel(state, rootState);
  newState = validateTempItemSelectionData(newState);
  return newState.get(constants.TEMP_VALIDATION_ERRORS);
}

function validateTempItemSelectionData(state) {
  const populationType = state.getIn([itemPopulationConstants.TEMP_ITEMS_POPULATION, itemPopulationConstants.ITEMS_POPULATION_TYPE]);
  let validationErrors = {};
  let newState = state;
  if (!_.isNil(populationType)) {
    const itemPopulationConditions = state.getIn([itemPopulationConstants.TEMP_ITEMS_POPULATION, itemPopulationConstants.ITEMS_POPULATION, populationType, constants.CONDITIONS, constants.CONDITIONS_LIST]);
    validationErrors = validateItemPopulationConditions(itemPopulationConditions, populationType);
    if (!_.isEmpty(validationErrors)) {
      newState = newState.setIn([constants.TEMP_VALIDATION_ERRORS, itemPopulationConstants.TEMP_ITEMS_POPULATION, populationType], fromJS(validationErrors));
    }
  }
  return newState;
}

export function validateItemPopulationConditions(itemPopulationConditions, populationType) {
  const conditionValue = itemPopulationConditions.getIn([0, constants.CONDITION_VALUE]);
  if (itemPopulationConditions.isEmpty() &&
    populationType !== itemPopulationConstants.ITEMS_POPULATION_TYPE_ADVANCED_RULES &&
    populationType !== itemPopulationConstants.ITEMS_POPULATION_TYPE_ANY) {
    return `activity.validation.error.item.population.no.${populationType}`;
  } else if (conditionValue && typeof conditionValue === 'object' && [constants.IS_ONE_OF, constants.IS_NOT_ONE_OF].includes(itemPopulationConditions.getIn([0, constants.OPERATOR_KEY]))) {
    const hasEmptyItems = conditionValue.filter((item) => item !== '' && item.split(' ').join('') === '');
    if (hasEmptyItems.size || hasEmptyItems.length) {
      return 'activity.validation.error.condition.value.no.empty.items';
    }
  }
  return validationUtils.validateConditionsList(itemPopulationConditions, true);
}

function validateDateCondition(state, conditionPath, validationErrorsPath) {
  const dateCondition = state.getIn(conditionPath);
  if (!dateCondition) {
    return state;
  }
  let newState = state;
  if (dateCondition.get(constants.CONDITION_KEY) === schemaConstants.CONTEXT_TIMESTAMP &&
    dateCondition.get(constants.OPERATOR_KEY) === datesConstants.DATE_TIME_IS_BETWEEN) {
    const dateFromString = dateCondition.getIn([constants.CONDITION_VALUE, datesConstants.DATE_FROM]);
    const dateFrom = moment(dateFromString);
    const dateToString = dateCondition.getIn([constants.CONDITION_VALUE, datesConstants.DATE_TO]);
    const dateTo = moment(dateToString);
    if (!dateFrom.isValid() || !dateTo.isValid() || !dateFrom.isBefore(dateTo)) {
      const conditionErrors = 'activity.validation.error.condition.value.dateTime.endHourAfterStart';
      newState = newState.setIn([...validationErrorsPath, constants.USER_ACTION_DATE_RANGE_CONDITION, constants.CONDITION_VALUE], conditionErrors);
    }
  }
  return newState;
}

export function validateFiltersInTempModel(state, rootState) {
  return validateMembershipConditionTempModel(state, rootState);
}

function validateUserActionAggregationConditions(userAction, validationErrorsPath, state) {
  let newState = state;
  if (userAction.get(constants.USER_ACTION_AGGREGATION_CONDITIONS)) {
    userAction.get(constants.USER_ACTION_AGGREGATION_CONDITIONS).forEach((aggregationConditionWrapper, conditionIndex) => {
      if (aggregationConditionWrapper.get(constants.IS_USER_ACTION_AGGREGATED_CONDITIONS_ACTIVE)) {
        const aggregationCondition = aggregationConditionWrapper.get(constants.CONDITION);
        const conditionErrors = validationUtils.validateCondition(aggregationCondition);
        if (Object.keys(conditionErrors).length > 0) {
          const conditionErrorPath = [...validationErrorsPath, constants.USER_ACTION_AGGREGATION_CONDITIONS, conditionIndex];
          newState = newState.setIn(conditionErrorPath, fromJS(conditionErrors));
        }
      }
    });
  }
  return newState;
}

function validateMembershipConditionTempModel(state, rootState) {
  const membershipFilterType = state.get(constants.TYPE);
  let newState = state;
  switch (membershipFilterType) {
    case constants.MEMBERS_SPECIFIC: {
      const errorPath = [constants.TEMP_VALIDATION_ERRORS, constants.GLOBAL_CONDITIONS, constants.MEMBERSHIP_GLOBAL_CONDITIONS];
      newState = validateMembershipConditionModel(
        newState, rootState, [constants.CONDITIONS, constants.CONDITIONS_LIST], errorPath);
      const userActions = newState.getIn([constants.USER_ACTIONS_CONDITIONS, constants.USER_ACTIONS_CONDITIONS_LIST]);
      if (userActions) {
        userActions.forEach((userAction, index) => {
          const validationErrorsPath = [...errorPath, constants.USER_ACTIONS_CONDITIONS, index];
          newState = validateMembershipConditionModel(
            newState, rootState, [constants.USER_ACTIONS_CONDITIONS, constants.USER_ACTIONS_CONDITIONS_LIST, index, constants.CONDITIONS, constants.CONDITIONS_LIST],
            validationErrorsPath);
          const dateConditionPath = [constants.USER_ACTIONS_CONDITIONS, constants.USER_ACTIONS_CONDITIONS_LIST, index, constants.USER_ACTION_DATE_RANGE_CONDITION];
          newState = validateDateCondition(newState, dateConditionPath, validationErrorsPath);
          newState = validateUserActionAggregationConditions(userAction, validationErrorsPath, newState);
        });
      }
      break;
    }
    case constants.MEMBERS_IMPORT: {
      const importConditionWrapper = newState.getIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER]);
      if (importConditionWrapper.get(constants.IMPORT_MEMBERS_MODE) === constants.IMPORT_MEMBERS_COPY_PASTE_MODE) {
        const conditionError = validationUtils.validateCondition(importConditionWrapper.get(constants.IMPORT_MEMBERS_COPY_PASTE_MODE));
        if (Object.keys(conditionError).length > 0) {
          const errorPath = [constants.TEMP_VALIDATION_ERRORS, constants.GLOBAL_CONDITIONS,
            constants.MEMBERSHIP_GLOBAL_CONDITIONS, constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER,
            constants.IMPORT_MEMBERS_COPY_PASTE_MODE];
          newState = newState.setIn(errorPath, fromJS(conditionError));
        }
      } else {
        const fileName = importConditionWrapper.getIn([constants.IMPORT_MEMBERS_UPLOAD_FILE_MODE, constants.CONDITION_VALUE, constants.UPLOADED_FILE_NAME]);
        if (!fileName) {
          const errorPath = [constants.TEMP_VALIDATION_ERRORS, constants.GLOBAL_CONDITIONS,
            constants.MEMBERSHIP_GLOBAL_CONDITIONS, constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER,
            constants.IMPORT_MEMBERS_UPLOAD_FILE_MODE, constants.CONDITION_VALUE];
          newState = newState.setIn(errorPath, 'activity.validation.error.condition.value.required');
        }
      }
      break;
    }
    default:
      return state; // if all registered members selected skip validation on condition list
  }

  return newState;
}

function validateMembershipConditionModel(state, rootState, dataPath, validationErrorsPath) {
  const conditionsList = state.getIn(dataPath);
  if (!conditionsList) {
    return state;
  }
  let newState = state;
  const conditionsListErrors = validationUtils.validateConditionsList(conditionsList);
  const registrationFormFieldsErrors = validationUtils.validateRegistrationFormFieldsInConditions(
    conditionsList, rootState, getSchema(state), 'filterMember');
  let schemaFormFieldsErrors = {};
  if (!validationErrorsPath.includes(constants.USER_ACTIONS_CONDITIONS)) {
    schemaFormFieldsErrors = validationUtils.validateSchemaFormFieldsInConditions(conditionsList, getSchema(state), 'filterMember');
  }
  const membershipConditionErrors = Object.assign({}, conditionsListErrors, registrationFormFieldsErrors, schemaFormFieldsErrors);
  if (Object.keys(membershipConditionErrors).length > 0) {
    const errors = fromJS(membershipConditionErrors);
    newState = newState.setIn([...validationErrorsPath, constants.CONDITIONS], errors);
  } else {
    newState = newState.deleteIn([...validationErrorsPath]);

  }
  return newState;
}

export function getRandomMemberImage() {
  const images = [hatGlasses, glasses, hair, headphones, mustache, mustacheGlasses, ninja, superWomen, workOut];
  const i = Math.floor(Math.random() * (images.length - 1));

  return images[i];
}
