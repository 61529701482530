import React from 'react';
import classNames from 'classnames';
import { Image, Table } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';

import tracker from '../../../common/utils/tracking/tracker';
import * as trackerConstants from "../../../common/utils/tracking/tracking.consts";
import * as activityConstants from '../../Activity/activity.constants';
import * as activitySelectorsUtils from '../../Activity/activity.selectors.utils';
import ActivityStatus from '../../Activity/views/ActivityStatus';
import PointShopHistoryLog from './PointShopHistoryLog';
import PointShopContextMenu from './PointShopContextMenu';

class PointShopRow extends React.PureComponent {
  state = {
    isDetailsModalOpen: false
  };

  handleDetailsModalOpen = () => {
    this.setState({
      isDetailsModalOpen: true
    });
  };

  handleDetailsModalClose = () => {
    this.setState({
      isDetailsModalOpen: false
    });
  };

  handleOnMouseEnter = () => {
    this.setState({
      isMouseEnter: true
    });
  };

  handleOnMouseLeave = () => {
    this.setState({
      isMouseEnter: false
    });
  };

  render() {
    const { item, rowIndex } = this.props;
    const itemName = item.get(activityConstants.NAME);
    const itemPrice = item.get(activityConstants.PRICE);
    const rowHistoryLog = item.get(activityConstants.HISTORY_LOG);
    const rowViewStatus = activitySelectorsUtils.calculateActivityViewStatus(item);

    const pointShopDetailsForTracker = {
      [trackerConstants.ACTIVITY_HUB_ID]: item.get(activityConstants.HUB_ID),
      [trackerConstants.ACTIVITY_SERVER_ID]: item.get(activityConstants.SERVER_ID),
      [trackerConstants.ACTIVITY_STATUS]: item.get(activityConstants.STATUS),
      [trackerConstants.ACTIVITY_NAME]: item.get(activityConstants.NAME),
      [trackerConstants.ACTIVITY_TYPE]: item.get(activityConstants.ACTIVITY_TYPE_POINT_SHOP),
    };

    const rowClass = classNames('point-shop-row', {
      'disable-pointer-events': rowViewStatus === activityConstants.ACTIVITY_STATUS_INVALID,
      'clickable': rowViewStatus !== activityConstants.ACTIVITY_STATUS_INVALID
    });

    return (
      <Table.Row
        data-automation-id={`gifts-page.campaign.row.${rowIndex}`}
        className={rowClass}
        onMouseEnter={this.handleOnMouseEnter}
        onMouseLeave={this.handleOnMouseLeave}
        onClick={() => this.props.goToItem(item.toJS())}
      >
        <PointShopHistoryLog
          name={itemName}
          open={this.state.isDetailsModalOpen}
          onConfirm={this.handleDetailsModalClose}
          historyLog={rowHistoryLog}
        />
        <Table.Cell className="listcontent" title={itemName}>
          <Image
            className="gift-mini-thumbnail"
            src={item.get('imageUrl')}
          />
        </Table.Cell>
        <Table.Cell className="listcontent" title={itemName}>{itemName}</Table.Cell>
        <Table.Cell className="listcontent" title={itemName}>{itemPrice !== null ? itemPrice / 100 : null}</Table.Cell>
        <Table.Cell className="listcontent" title={itemName}>
          <ActivityStatus status={item.get('status')} messagesNamespace="gift" />
        </Table.Cell>
        <Table.Cell className="listcontent" textAlign="right">
          <PointShopContextMenu
            item={item}
            hidden={!this.state.isMouseEnter}
            onDetailsOpen={this.handleDetailsModalOpen}
            automationId={`point-shop-page.gift.${rowIndex}.point-shop-context-menu`}
            deactivate={this.props.deactivate}
            delete={this.props.delete}
            onOpenInNewTab={tracker.wrapButtonAction(() => this.props.openPointShopInNewTab(item), trackerConstants.BUTTON_TYPE_OPEN_IN_NEW_TAB, pointShopDetailsForTracker)}
          />
        </Table.Cell>

      </Table.Row>
    );
  }
}

export default injectIntl(PointShopRow);
