/*
 *
 * App actions
 *
 */

import * as constants from './app.constants';
import * as errorConstant from '../ErrorMessage/errorMessage.constants';

export function customAction(type, payload) {
  return { type, payload };
}

export function changeLocale(languageLocale, countryCode) {
  return { type: constants.CHANGE_LOCALE, locale: languageLocale, countryCode };
}

export function defaultAction() {
  return { type: constants.DEFAULT_ACTION };
}

export function getApplicationData(hub1Path) {
  return { type: constants.GET_APPLICATION_DATA, payload: hub1Path };
}

export function getFranchisesData() {
  return { type: constants.GET_FRANCHISES_DATA };
}

export function getFeatures() {
  return {
    type: constants.GET_FEATURES
  };
}

export function contactSupport(subject, body, name, ccEmails, toEmails) {
  return { type: constants.CONTACT_SUPPORT, data: { subject, body, name, ccEmails, toEmails } };
}

export function getBusiness(hub1Path, businessId) {
  return { type: constants.GET_BUSINESS, payload: { businessId, hub1Path } };
}

export function getBusinesses() {
  return { type: constants.GET_BUSINESSES };
}

export function userLogout(path) {
  return { type: constants.USER_LOGOUT, payload: path };
}

export function setHub1Path(path) {
  return { type: constants.SET_HUB_1_PATH, payload: path };
}

export function increaseAjaxRequestsCounter() {
  return { type: constants.INCREASE_AJAX_REQUESTS_COUNTER };
}

export function decreaseAjaxRequestsCounter() {
  return { type: constants.DECREASE_AJAX_REQUESTS_COUNTER };
}

export function setBrowserLocale(browserLocaleData) {
  return { type: constants.SET_BROWSER_LOCALE_SETTINGS, browserLocaleData };
}

export function setLoadingState(isLoading) {
  return { type: constants.SET_LOADING_STATE, isLoading };
}

export function getLocations() {
  return {
    type: constants.GET_LOCATIONS
  };
}
export function getMonthlyCommunicationsLimitation() {
  return {
    type: constants.GET_MONTHLY_COMMUNICATIONS_LIMITATION
  };
}

export function getCustomer(search) {
  return {
    type: constants.GET_CUSTOMER,
    search
  };
}

export function goToPage(pageName) {
  return {
    type: constants.GO_TO_PAGE,
    pageName
  };
}

export function goToWelcomePage() {
  return {
    type: constants.GO_TO_WELCOME_PAGE,
  };
}

export function showMemberNotFoundError() {
  return {
    type: errorConstant.HANDLE_ERROR,
    [errorConstant.MESSAGES_KEYS]: ['getCustomer.IllegalInput.notFound', 'getCustomer.IllegalInput.notFound.subtext'],
    [errorConstant.ERROR_TYPE]: errorConstant.ERROR_TYPE_CUSTOMER
  };
}

export function updateUser(user, countryCode) {
  return {
    type: constants.USER_UPDATE,
    user,
    countryCode
  };
}
export function setDemoMode(demoMode) {
  return {
    type: constants.SET_DEMO_MODE,
    demoMode
  };
}

export function getVerificationCode(data) {
  return {
    type: constants.GET_VERIFICATION_CODE,
    data
  };
}

export function getAppClientId(data) {
  return {
    type: constants.GET_APP_CLIENT_ID,
    data
  };
}

export function getRegistrationFields(data) {
  return {
    type: constants.GET_REGISTRATION_FIELDS,
    data
  };
}

export function createMember(data) {
  return {
    type: constants.CREATE_MEMBER,
    data
  };
}

export function changeUserPassword(data) {
  return {
    type: constants.CHANGE_USER_PASSWORD,
    data
  };
}

export function cancel3rdPartyCode(codesArray) {
  return {
    type: constants.CANCEL_3RD_PARTY_CODE,
    codesArray
  };
}

export function cancel3rdPartyCodeStatusClear() {
  return {
    type: constants.CANCEL_3RD_PARTY_CLEAR_STATUS
  };
}

export function getCurrentSMSProviders(countryCode) {
  return {
    type: constants.GET_CURRENT_SMS_PROVIDERS,
    countryCode
  };
}

export function requestToUpgradeToProfessional(data) {
  return {
    type: constants.REQUEST_TO_UPGRADE_TO_PROFESSIONAL,
    data
  };
}

/**
 * get membership tags for business
 * @returns {{type: string}}
 */
export function getBusinessTags() {
  return {
    type: constants.GET_TAGS,
  };
}

export function getBusinessTimeInfo() {
  return {
    type: constants.GET_BUSINESS_TIME_INFO
  };
}

export function updateDateFormat(data) {
  return {
    type: constants.UPDATE_BUSINESS_DATE_FORMAT,
    data
  };
}
