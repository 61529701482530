/**
*
* Boolean
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'semantic-ui-react';
import * as constants from './Boolean.constants';

class Boolean extends React.PureComponent {

  static propTypes = {
    disabled: PropTypes.bool,
    index: PropTypes.number,
    prefix: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    onChange: PropTypes.func,
    name: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange = (e, data) => {
    const returnData = data;
    returnData.value = String(data.value);
    this.props.onChange(e, returnData);
  };

  render() {
    const value = this.props.value ? String(this.props.value) : constants.FALSE;
    return (
      <div>
        <Radio
          label={constants.YES}
          value={constants.TRUE}
          onChange={this.onChange}
          disabled={this.props.disabled}
          data-automation-id={`${this.props.prefix}.yes`}
          checked={value === constants.TRUE}
        />
        &nbsp; &nbsp;
        <Radio
          label={constants.NO}
          value={constants.FALSE}
          onChange={this.onChange}
          disabled={this.props.disabled}
          data-automation-id={`${this.props.prefix}.no`}
          checked={value === constants.FALSE}
        />
      </div>
    );
  }

}

export default Boolean;
