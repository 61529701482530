/**
 *
 * ErrorMessage
 *
 */

import React from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import classNames from 'classnames';

import '../errorMessage.styles.less';
import * as constants from '../../ErrorMessage/errorMessage.constants';
import * as errorMessageAction from '../../ErrorMessage/errorMessage.actions';
import CustomModal from '../../../common/components/CustomModal/CustomModal';

class ErrorMessage extends React.PureComponent {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    messages: PropTypes.object,
    title: PropTypes.string,
    leaveOnErrorClose: PropTypes.string,
    errorType: PropTypes.string
  };

  getContent = (messages, isCustomerErrorMessage) => {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    if (!isCustomerErrorMessage) {
      return (
         messages.size === 1 ?
           <span
             className="errorMessageText"
             data-automation-id="Error.ErrorMessage"
           >{formatMessage({ id: messages.get(0) })}</span> :
           <ol>
             {this.props.messages.map((message, i) =>
            (
              <li
                className={'errorMessageText'}
                key={i.toString()}
                data-automation-id={`Error.ErrorMessage_${i}`}
              >
                <span>{formatMessage({ id: message })}</span>
              </li>
            )
          )}
           </ol>
      );
    }
    return (
      <div>
        <p className="customer-error-messages customer-error-subtitle">{formatMessage({ id: messages.get(0) })}</p>
        <p className="customer-error-messages customer-error-text">{formatMessage({ id: messages.get(1) })}</p>
      </div>
    );
    
  }


  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const messages = this.props.messages;
    if (!messages || messages.size === 0) {
      return null;
    }
    const isCustomerErrorMessage = this.props.errorType === constants.ERROR_TYPE_CUSTOMER;
    const modalSize = isCustomerErrorMessage ? 1050 : 'mini';
    const modalHeader = isCustomerErrorMessage ? '' : formatMessage({ id: this.props.title });
    const modalClassNames = classNames('error-message-modal', 'como-dialog', { 'customer-error-message': isCustomerErrorMessage });

    return (
      <CustomModal
        automationIdPrefix="Error"
        className={modalClassNames}
        size={modalSize}
        open={messages.size > 0}
        onClose={() => this.props.actions.handleErrorMessageClose(this.props.leaveOnErrorClose)}
        header={modalHeader}
        actions={[
          <Button
            key="general.button.close"
            data-automation-id="Error.CloseButton"
            onClick={() => this.props.actions.handleErrorMessageClose(this.props.leaveOnErrorClose)}
          >
            {formatMessage({ id: 'general.button.close' })}
          </Button>
        ]}
        content={this.getContent(messages, isCustomerErrorMessage)}

      >
      </CustomModal>
    );
  }
}


const mapStateToProps = (state) => ({
  messages: state.getIn([constants.ERROR_MESSAGE, constants.MESSAGES_KEYS]),
  title: state.getIn([constants.ERROR_MESSAGE, constants.ERROR_TITLE]),
  leaveOnErrorClose: state.getIn([constants.ERROR_MESSAGE, constants.LEAVE_ON_ERROR_CLOSE]),
  errorType: state.getIn([constants.ERROR_MESSAGE, constants.ERROR_TYPE])
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(errorMessageAction, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, injectIntl)(ErrorMessage);
