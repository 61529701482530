import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

import Tooltip from '../../../common/components/Tooltip/Tooltip';

const ToneOfVoice = (props) => {
  const { intl, selectedOption, setSelectedOption } = props;

  const { formatMessage } = intl;

  const handleToneOfVoice = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <React.Fragment>
      <span className={'tw-text-sm tw-flex tw-flex-row tw-mt-2'}>
        {formatMessage({ id: 'vertex.ai.tone-of-voice' })}
        <Tooltip content={formatMessage({ id: 'vertex.ai.language.tone-of-voice.tooltip' })} trigger={<InformationCircleIcon className={'tw-ml-1 tw-w-4 fill-none tw-fill-none'} />} position="bottom right" />
      </span>
      <select value={selectedOption} onChange={handleToneOfVoice} className="tw-my-2 tw-w-full tw-max-w-xs">
        <option disabled>{formatMessage({ id: 'vertex.ai.tone-of-voice' })}</option>
        <option>{formatMessage({ id: 'vertex.ai.one-of-voice.pick-tone.expert' })}</option>
        <option>{formatMessage({ id: 'vertex.ai.one-of-voice.pick-tone.playful' })}</option>
        <option>{formatMessage({ id: 'vertex.ai.one-of-voice.pick-tone.sophisticated' })}</option>
        <option>{formatMessage({ id: 'vertex.ai.one-of-voice.pick-tone.supportive' })}</option>
        <option>{formatMessage({ id: 'vertex.ai.one-of-voice.pick-tone.engaging' })}</option>
        <option>{formatMessage({ id: 'vertex.ai.one-of-voice.pick-tone.witty' })}</option>
        <option>{formatMessage({ id: 'vertex.ai.one-of-voice.pick-tone.custom' })}</option>
      </select>
      {selectedOption === 'Custom' && (
        <div className={'tw-flex tw-flex-col tw-mb-2'}>
          <span className={'tw-text-sm tw-mt-2'}>{formatMessage({ id: 'vertex.ai.one-of-voice.custom-tone-of-voice' })}</span>
          <input type="text" name={'customTone'} placeholder={formatMessage({ id: 'vertex.ai.one-of-voice.custom.placeholder' })} className="input my-2 input-bordered w-full max-w-xs" />
        </div>
      )}
    </React.Fragment>
  );
};

ToneOfVoice.prototype = {
  intl: PropTypes.object,
  selectedOption: PropTypes.string,
  setSelectedOption: PropTypes.func
};

export default injectIntl(ToneOfVoice);

