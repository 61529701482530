import { createSelector } from 'reselect';
import * as constants from './gifts.constants';
import * as utils from './utils/gifts.selectors.utils';
import * as activityConstants from '../Activity/activity.constants';
import * as reducerConstants from '../../constants/reducer.constants';

export const getGifts = (state) => state.getIn([reducerConstants.GIFTS_BRANCH, constants.GIFTS]);
export const getGiftsTags = (state) => state.getIn([reducerConstants.GIFTS_BRANCH, constants.GIFTS_ACTIVITY_TAGS]);
export const getFilters = (state) => state.getIn([reducerConstants.GIFTS_BRANCH, constants.FILTERS]);
export const getOrder = (state) => state.getIn([reducerConstants.GIFTS_BRANCH, constants.SORT, constants.ORDER]);
export const getGiftsLoadedFlag = (state) => state.getIn([reducerConstants.GIFTS_BRANCH, constants.GIFTS_LOADED]);
export const getHighlightedGiftId = (state) => state.getIn([reducerConstants.GIFTS_BRANCH, activityConstants.HIGHLIGHTED_ACTIVITY_ID]);
export const getSearchTerm = (state) => state.getIn([reducerConstants.GIFTS_BRANCH, constants.SEARCH_TERM]);
export const getFilteredAndSortedGifts = createSelector([getGifts, getFilters, getOrder, getSearchTerm], utils.getFilteredAndSortedGiftsImpl);
export const isGiftsFiltered = createSelector([getGifts, getFilteredAndSortedGifts], utils.isGiftsFilteredImpl);
export const getGiftStats = (state) => state.getIn([reducerConstants.GIFTS_BRANCH, constants.GIFT_STATS]);
export const getGiftUsages = (state) => state.getIn([reducerConstants.GIFTS_BRANCH, constants.GIFT_USAGES]);
export const getContextMenuItemsVisibilityMap = utils.getContextMenuItemsVisibilityMap; // maybe will memoize in the future
