import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as activityConstants from '../../../activity.constants';
import * as appConstants from '../../../../App/app.constants';
import PopupWrapper from '../../../../../common/components/PopupWrapper/PopupWrapper';
import DateTimeRange from './DateTimeRangeCondition';
import { getDateTimeRangeConditionValueAsString } from '../../../reducers/utils/activity.datetime.reducer.utils';
import ValidationErrorMessage from '../../../../../common/components/ValidationErrorMessage/ValidationErrorMessage';
import PopupTrigger from '../../../../../common/components/PopupTrigger/PopupTrigger';
import * as selectors from '../../../activity.selectors';
import * as domUtils from '../../../../../common/utils/domUtils';

class DateTimeField extends React.PureComponent {

  static propTypes = {
    condition: PropTypes.object,
    error: PropTypes.string,
    index: PropTypes.number,
    intl: PropTypes.object,
    isPastDateRange: PropTypes.bool,
    isInfiniteDateRange: PropTypes.bool,
    localeSettings: PropTypes.object,
    prefix: PropTypes.string,
    updateDateRangeDate: PropTypes.func,
    activityType: PropTypes.string,
    datePickerInsideModal: PropTypes.bool,
    isInsideUserAction: PropTypes.bool
  };

  static defaultProps = {
    isPastDateRange: false,
    isInfiniteDateRange: false,
    isInsideUserAction: false
  };

  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false,
    };
  }

  handlePopupClose = (e) => {
    const datePickerDomParent =
      domUtils.findMatchingParentOrSelf(e.srcElement, (node) => node.className.includes('react-datepicker'));
    if (!datePickerDomParent) {
      // if the source DOM element of this event is not a descendant of the date-picker, close the pop-up:
      this.setState({ isPopupOpen: false });
    }
  };

  handlePopupOpen = () => {
    this.setState({ isPopupOpen: true });
  };

  // use local functions to inject condition index to callback functions
  updateDateRangeDate = (dateObject, isStart, localeTimeFormat, businessTimezone, allowInfinite) => {
    this.props.updateDateRangeDate(dateObject, isStart, localeTimeFormat, this.props.index, businessTimezone, allowInfinite);
  };

  render() {
    const { formatMessage } = this.props.intl;
    const { condition, intl, localeSettings, prefix, error } = this.props;
    const conditionData = condition.has(activityConstants.DATES_RANGE_CONDITION) ? condition.get(activityConstants.DATES_RANGE_CONDITION) : condition;
    const valueAsString = getDateTimeRangeConditionValueAsString(conditionData, intl, localeSettings.get(appConstants.DATE_FORMAT), false, this.props.isInfiniteDateRange);
    const className = classNames('date-picker-range-wrapper', { 'errors-inside-global-conditions-highlight': this.props.error });

    return (
      <span className={className}>
        <PopupWrapper
          trigger={
            <div className="inline-div date-time-field">
              <PopupTrigger
                className="popup-trigger"
                message={valueAsString}
                title={valueAsString}
                automationId={prefix}
              />
            </div>
        }
          automationId={`${prefix}.popup`}
          on="click"
          position="top left"
          flowing
          onOpen={this.handlePopupOpen}
          onClose={this.handlePopupClose}
          open={this.state.isPopupOpen}
        >
          <DateTimeRange
            condition={condition}
            intl={intl}
            updateDateRangeDate={this.updateDateRangeDate}
            allowForever={![activityConstants.ACTIVITY_TYPE_PUNCH_CARD, activityConstants.ACTIVITY_TYPE_GIFT].includes(this.props.activityType) && !this.props.isPastDateRange}
            isPastDateRange={this.props.isPastDateRange}
            isInfiniteDateRange={this.props.isInfiniteDateRange}
            isInsideUserAction={this.props.isInsideUserAction}
            prefix={`${prefix}.datetime`}
            datePickerInsideModal={this.props.datePickerInsideModal}
          />
        </PopupWrapper>
        { error ? (
          <ValidationErrorMessage
            errorMessage={formatMessage({ id: error })}
            dataAutomationId={`${prefix}.error.message`}
          />
        ) : null }

      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  localeSettings: state.getIn([appConstants.APP, appConstants.BROWSER_LOCALE_SETTINGS]),
  activityType: selectors.getActivityType(state),
});

const withConnect = connect(mapStateToProps, null);

export default compose(
  withConnect,
  injectIntl
)(injectIntl(DateTimeField));
