/**
 *
 * TagMembership
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import * as schemaConstants from '../../../../activitySchema/activitySchema.constants';
import './tagMembership.styles.less';
import DropdownWrapper from '../../../../../../common/components/DropdownWrapper/DropdownWrapper';
import * as appSelectors from '../../../../../App/selectors';

class TagMembership extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string,
    onActionFieldUpdate: PropTypes.func.isRequired,
    action: PropTypes.object,
    index: PropTypes.number,
    validationErrors: PropTypes.object,
    autoCompleteOptions: PropTypes.object
  };

  get autoCompleteOptions() {
    let options = [];
    if (this.props.autoCompleteOptions && this.props.autoCompleteOptions.size > 0) {
      options = this.props.autoCompleteOptions.toJS().map((option) => ({ text: option, value: option }));
    }
    if (this.props.action && this.props.action.get(schemaConstants.TAG_MEMBERSHIP_TAG_FIELD)) {
      const value = this.props.action.get(schemaConstants.TAG_MEMBERSHIP_TAG_FIELD);
      options.push({ text: value, value });
    }
    return options;
  }

  render() {
    const { index, action, onActionFieldUpdate, validationErrors, automationIdPrefix } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    return (
      <Grid className="action tag-membership-action">
        <Grid.Row>
          <Grid.Column width={16}>
            <p className="action-label">{formatMessage({ id: 'activity.action.tag' })}</p>
            <DropdownWrapper
              className="auto-complete-wrapper"
              options={this.autoCompleteOptions}
              onSelectOption={(e, data) => onActionFieldUpdate(data.name, data.value.trim(), index)}
              value={action.get(schemaConstants.TAG_MEMBERSHIP_TAG_FIELD)}
              disabled={false}
              index={this.props.index}
              prefix={`${automationIdPrefix}.value`}
              error={validationErrors ? validationErrors.get(schemaConstants.TAG_MEMBERSHIP_TAG_FIELD) : null}
              search
              allowAdditions
              name={schemaConstants.TAG_MEMBERSHIP_TAG_FIELD}
              placeholder={formatMessage({ id: 'dropdown.placeholder.select.or.add' })}
              selection
              additionLabel={`(${formatMessage({ id: 'adding.new.tag' })})`}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    autoCompleteOptions: appSelectors.getBusinessTagsWithoutRfm(state),
  };
}
const withConnect = connect(mapStateToProps, null);

export default compose(withConnect, injectIntl)(TagMembership);
