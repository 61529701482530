import * as constants from '../../activity.constants';

export function updateRequiredPunches(requiredPunches) {
  return {
    type: constants.UPDATE_REQUIRED_PUNCHES,
    requiredPunches
  };
}

export function toggleSendOnPurchase(autoSend) {
  return {
    type: constants.HANDLE_SEND_ON_EXPIRATION_CHECKBOX_CHANGE,
    autoSend
  };
}

export function updatePunchCardShoppingCartItems(itemsPopulation) {
  return {
    type: constants.UPDATE_PUNCH_CARD_SHOPPING_CART_ITEMS,
    itemsPopulation
  };
}

export function updatePunchRuleLimit(limit) {
  return {
    type: constants.UPDATE_PUNCH_CARD_PUNCH_RULE_LIMIT,
    limit
  };
}

export function addCommunicationRule() {
  return {
    type: constants.ADD_COMMUNICATION_RULE,
  };
}

export function deleteCommunicationRule(index) {
  return {
    type: constants.DELETE_COMMUNICATION_RULE,
    index
  };
}

export function updateCommunicationRuleTrigger(index, trigger) {
  return {
    type: constants.UPDATE_COMMUNICATION_RULE_TRIGGER,
    index,
    trigger
  };
}

export function updateCommunicationRuleAction(index, fieldName, fieldValue) {
  return {
    type: constants.UPDATE_COMMUNICATION_RULE_ACTION,
    index,
    fieldName,
    fieldValue
  };
}

export function expandCollapseCommunicationRule(index, isCollapsed) {
  return {
    type: constants.UPDATE_COMMUNICATION_RULE_DISPLAY,
    index,
    isCollapsed
  };
}

export function setDefaultActionsType() {
  return {
    type: constants.SET_PUNCH_CARD_DEFAULT_DISCOUNT_ON_ACTION_TYPE
  };
}

export function toggleAutoRenew(autoRenew) {
  return {
    type: constants.HANDLE_AUTO_RENEW_CHECKBOX_CHANGE,
    autoRenew
  };
}

export function onRenewOptionsChange(renewValue) {
  return {
    type: constants.HANDLE_RENEW_OPTION_CHANGE,
    renewValue
  };
}

export function onChangeRewardMode(currentRewardMode) {
  return {
    type: constants.HANDLE_PUNCH_CARD_REWARD_MODE_CHANGE,
    currentRewardMode
  };
}

export function expandCollapseAllCommunicationRules(collapse) {
  return {
    type: constants.EXPAND_COLLAPSE_ALL_COMMUNICATION_RULES,
    collapse
  };
}
