import moment from 'moment';
import { fromJS, Map } from 'immutable';
import * as constants from '../../activity.constants';
import * as dateConstants from '../../../../common/utils/dateTime.constants';
import * as utils from '../../../../common/utils/dateTimeUtils';

/**
 * parse days and times as strings
 * @param {object} conditionData
 * @param {object} intl
 * @param {object} locale
 * @param {string} localeTimeFormat
 * @returns {*}
 */
export function getDaysConditions(conditionData, intl, locale, localeTimeFormat) {
  const { formatMessage } = intl;
  utils.updateLocale(intl, locale);
  const daysConditions = conditionData.hasIn([constants.DAYS_TIMES_CONDITION])
    ? conditionData.getIn([constants.DAYS_TIMES_CONDITION, constants.CONDITION_VALUE])
    : conditionData.getIn([constants.CONDITION_VALUE]);
  if (!daysConditions || daysConditions.size === 0) {
    return null;
  }
  const daysConditionsContent = [];
  let validAtAllTimes = false;
  daysConditions.forEach((condition) => {
    // if dayTime condition is default (7 days all day) don't add it to the trigger message
    if (condition.get(dateConstants.DAYS).size !== 7
      || condition.getIn([dateConstants.TIME_RANGE, dateConstants.TIME_FROM]) !== dateConstants.DAYS_OBJECT_START_OF_DAY_TIME
      || condition.getIn([dateConstants.TIME_RANGE, dateConstants.TIME_TO]) !== dateConstants.DAYS_OBJECT_END_OF_DAY_TIME) {
      const weekdays = utils.getSelectedDaysAsString(condition.get(dateConstants.DAYS), intl, locale);
      const weekDaysTimes = utils.getWeekDaysTimesAsString(condition.get(dateConstants.TIME_RANGE), localeTimeFormat);
      daysConditionsContent.push(`${weekdays}${formatMessage({ id: 'dateTimeGlobalConditions.trigger.daysHoursSeparator' })} ${weekDaysTimes}`);
    } else {
      validAtAllTimes = true;
    }
  });
  return validAtAllTimes ? [] : daysConditionsContent;
}

/**
 * return start date value according to the data structure (returns value in utc)
 * @returns {string}
 */
export function getStartDate(conditionData) {
  let startDate = null;
  if (conditionData.get(constants.OPERATOR_KEY) === dateConstants.DATE_TIME_IS_AFTER) {
    startDate = conditionData.get(constants.CONDITION_VALUE);
  } else {
    startDate = conditionData.getIn([constants.CONDITION_VALUE, dateConstants.DATE_FROM]);
  }
  return startDate;
}

/**
 * return end date value according to the data structure
 * @returns {string}
 */
export function getEndDate(conditionData) {
  let endDate = null;
  if (conditionData.get(constants.OPERATOR_KEY) === dateConstants.DATE_TIME_IS_BEFORE_OR_EQUAL) {
    endDate = conditionData.get(constants.CONDITION_VALUE);
  } else if (conditionData.get(constants.OPERATOR_KEY) !== dateConstants.DATE_TIME_IS_AFTER) {
    endDate = conditionData.getIn([constants.CONDITION_VALUE, dateConstants.DATE_TO]);
  }
  return endDate;
}

/**
 * parse start date and end date as range string
 * @param {object} conditionData
 * @param {object} intl
 * @param {string} localeDateFormat
 * @param dateInTooltip
 * @param allowInfinite
 * @returns {string}
 */
export function getDateTimeRangeConditionValueAsString(conditionData, intl, localeDateFormat, dateInTooltip = false, allowInfinite = false) {
  const { formatMessage } = intl;
  const startDate = getStartDate(conditionData);
  let formattedStartDate;
  const defaultStartDateTextKey = dateInTooltip ? 'datePicker.selectedStart.onOrBefore' : 'datePicker.selectedStart.sinceEver';
  if (startDate) {
    formattedStartDate = moment(startDate).format(localeDateFormat);
  } else {
    formattedStartDate = formatMessage({ id: defaultStartDateTextKey });
  }
  const endDate = getEndDate(conditionData);
  let formattedEndDate;
  if (endDate) {
    formattedEndDate = moment(endDate).format(localeDateFormat);
  } else {
    if (conditionData.get(constants.OPERATOR_KEY) === dateConstants.DATE_TIME_IS_INFINITE || (conditionData.get(constants.OPERATOR_KEY) === dateConstants.DATE_TIME_IS_AFTER && allowInfinite)) {
      formattedEndDate = formatMessage({ id: 'datePicker.selectedEnd.until.now' });
    } else {
      formattedEndDate = formatMessage({ id: 'datePicker.selectedEnd.forever' });
    }
  }
  const hasStartDate = !!startDate;
  return buildFormattedDateString(formattedStartDate, formattedEndDate, dateInTooltip, hasStartDate, intl);
}

export function buildFormattedDateString(startDate, endDate, dateInTooltip, hasStartDate, intl) {
  if (!dateInTooltip) {
    if (!endDate && !hasStartDate) {
      return startDate;
    }
    if (!endDate) {
      return `${intl.formatMessage({ id: 'datePicker.selectedStart.SinceDate' })} ${startDate}`;
    }
    return `${startDate} - ${endDate}`;
  }
  if (hasStartDate) {
    return `${intl.formatMessage({ id: 'userAction.dateRange.between' })} ${startDate} ${intl.formatMessage({ id: 'conjunction.and' })} ${endDate}`;
  }
  return `${intl.formatMessage({ id: 'datePicker.selectedStart.onOrBefore' })} ${endDate}`;
}

export function getDateConditionValueAsString(conditionData, localeDateFormat) {
  return moment(conditionData.get(constants.CONDITION_VALUE)).format(localeDateFormat);
}

/**
 * update date range date, dateObject is in utc here
 */
export function updateDateRangeConditionDate(condition, isStart, nonMomentObject) {
  const dateObject = nonMomentObject ? moment(nonMomentObject) : null;
  // condition is received from store therefore it's utc time
  // date object came from the component therefore it's business timezone time
  // all business timezone moment objects are converted from to utc before manipulation
  const dateConditionValue = condition.get(constants.CONDITION_VALUE);
  const dateOperatorKey = condition.get(constants.OPERATOR_KEY);
  // convert to utc
  const dateObjectInUtc = dateObject ? dateObject.clone().utc() : null;
  // if start datePicker
  if (isStart) {
    if (dateObject !== null) { // "since ever" not clicked
      if (dateOperatorKey === dateConstants.DATE_TIME_IS_BEFORE_OR_EQUAL) { // "since ever" was chosen previously, need to fix times now
        const dateFromValueToSave = dateObject.clone().startOf('day').utc();
        const newValue = Map({
          // parse as utc
          [dateConstants.DATE_FROM]: dateFromValueToSave.format(dateConstants.STANDARD_TIME_FORMAT),
          [dateConstants.DATE_TO]: dateConditionValue
        });
        return condition
          .set(constants.CONDITION_VALUE, newValue)
          .set(constants.OPERATOR_KEY, dateConstants.DATE_TIME_IS_BETWEEN);
      } else if (dateOperatorKey === dateConstants.DATE_TIME_IS_INFINITE) {
        const dateFromValueToSave = dateObject.clone().startOf('day').utc();
        return condition
          .set(constants.CONDITION_VALUE, dateFromValueToSave.format(dateConstants.STANDARD_TIME_FORMAT))
          .set(constants.OPERATOR_KEY, dateConstants.DATE_TIME_IS_AFTER);
      }
      if (typeof dateConditionValue === 'object') {
        const newValue = dateConditionValue.set(dateConstants.DATE_FROM, dateObjectInUtc.format(dateConstants.STANDARD_TIME_FORMAT));
        return condition.set(constants.CONDITION_VALUE, newValue);
      }
      const dateString = dateObjectInUtc.format(dateConstants.STANDARD_TIME_FORMAT);
      return condition.set(constants.CONDITION_VALUE, dateString);
    }
    // The user chose since ever
    // if condition was between dates it turns to condition is before
    if (condition.get(constants.OPERATOR_KEY) === dateConstants.DATE_TIME_IS_BETWEEN) {
      return condition
        .set(constants.CONDITION_VALUE, dateConditionValue.get(dateConstants.DATE_TO))
        .set(constants.OPERATOR_KEY, dateConstants.DATE_TIME_IS_BEFORE_OR_EQUAL);
    } else if (condition.get(constants.OPERATOR_KEY) === dateConstants.DATE_TIME_IS_AFTER) {
      return condition
        .set(constants.CONDITION_VALUE, null)
        .set(constants.OPERATOR_KEY, dateConstants.DATE_TIME_IS_INFINITE);
    }
    return condition;
  }
  // if end datePicker
  let valueToSave = dateObjectInUtc;
  if (valueToSave !== null) {
    // we want to set endDatePicker dateTime and we currently have dateTimeIsAfter
    if (dateOperatorKey === dateConstants.DATE_TIME_IS_AFTER) {
      valueToSave = dateObject.clone().endOf('day').utc();
      const newValue = Map({
        [dateConstants.DATE_FROM]: dateConditionValue,
        [dateConstants.DATE_TO]: valueToSave.format(dateConstants.STANDARD_TIME_FORMAT)
      });
      return condition
        .set(constants.CONDITION_VALUE, newValue)
        .set(constants.OPERATOR_KEY, dateConstants.DATE_TIME_IS_BETWEEN);
    } else if (dateOperatorKey === dateConstants.DATE_TIME_IS_INFINITE) {
      valueToSave = dateObject.clone().endOf('day').utc();
      return condition
        .set(constants.CONDITION_VALUE, valueToSave.format(dateConstants.STANDARD_TIME_FORMAT))
        .set(constants.OPERATOR_KEY, dateConstants.DATE_TIME_IS_BEFORE_OR_EQUAL);
    }
    const dateTo = valueToSave.format(dateConstants.STANDARD_TIME_FORMAT);
    if (typeof dateConditionValue === 'object') {
      const newValue = dateConditionValue.set(dateConstants.DATE_TO, dateTo);
      return condition.set(constants.CONDITION_VALUE, newValue);
    }
    return condition.set(constants.CONDITION_VALUE, dateTo);
  }
  // if condition was in between -> will become is after
  if (condition.get(constants.OPERATOR_KEY) === dateConstants.DATE_TIME_IS_BETWEEN) {
    return condition
      .set(constants.CONDITION_VALUE, dateConditionValue.get(dateConstants.DATE_FROM))
      .set(constants.OPERATOR_KEY, dateConstants.DATE_TIME_IS_AFTER);
  } else if (condition.get(constants.OPERATOR_KEY) === dateConstants.DATE_TIME_IS_BEFORE_OR_EQUAL) {
    return condition
      .set(constants.CONDITION_VALUE, null)
      .set(constants.OPERATOR_KEY, dateConstants.DATE_TIME_IS_INFINITE);
  }
  return condition;
}

export function removeDateTimeConditionFromList(conditionsList, conditionIndex) {
  return conditionsList.filter((condition, index) => index !== conditionIndex);
}

export function fixDaysTimesConditionTimeFormat(condition, isMilitaryTime, timeFrom, timeTo, daysActive, businessTimezone) {
  // fix timeFrom and timeTo to contract format - HH:mm:ss format
  const timeFromFormat = isMilitaryTime ? '' : `:${timeFrom.format}`;
  const timeToFormat = isMilitaryTime ? '' : `:${timeTo.format}`;
  const timeFromStr = `${timeFrom.hour}:${timeFrom.minute}${timeFromFormat}`;
  const timeToStr = `${timeTo.hour}:${timeTo.minute}:${dateConstants.END_OF_MINUTE}${timeToFormat}`;
  const newTimeFrom = isMilitaryTime
    ? moment.tz(timeFromStr, dateConstants.HH_MM_FORMAT, businessTimezone).format(dateConstants.HH_MM_SS_FORMAT)
    : moment.tz(timeFromStr, `${dateConstants.AM_FORMAT}`, businessTimezone).format(dateConstants.HH_MM_SS_FORMAT);
  const newTimeTo = isMilitaryTime
    ? moment.tz(timeToStr, dateConstants.HH_MM_SS_FORMAT, businessTimezone).format(dateConstants.HH_MM_SS_FORMAT)
    : moment.tz(timeToStr, `${dateConstants.AM_FORMAT_WITH_SECONDS}`, businessTimezone).format(dateConstants.HH_MM_SS_FORMAT);
  return condition
    .set(dateConstants.DAYS, fromJS(daysActive))
    .setIn([dateConstants.TIME_RANGE, dateConstants.TIME_FROM], fromJS(newTimeFrom))
    .setIn([dateConstants.TIME_RANGE, dateConstants.TIME_TO], fromJS(newTimeTo))
    .setIn([dateConstants.TIME_RANGE, dateConstants.TIME_ERRORS], fromJS(validateConditionValue(newTimeFrom, newTimeTo)));
}
export function validateConditionValue(timeFrom, timeTo) {
  const errors = [];
  if (!moment(timeFrom, dateConstants.HH_MM_SS_FORMAT).isBefore(moment(timeTo, dateConstants.HH_MM_SS_FORMAT))){
    errors.push(dateConstants.TIME_LIMITATION_ERROR);
  }
  return errors;
}

export function getCurrentDateTime() {
  return moment().format(dateConstants.STANDARD_TIME_FORMAT);
}

export function getDefaultGiftDateTo() {
  return moment().add(dateConstants.GIFT_DEFAULT_DATE_TO_YEARS, dateConstants.YEARS).format(dateConstants.STANDARD_TIME_FORMAT);
}
