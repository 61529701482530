import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './ToggleButton.styles.less';

class ToggleButton extends PureComponent {
  static propTypes = {
    enabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    automationId: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    children: PropTypes.node
  };

  static defaultProps = {
    enabled: false,
    disabled: false
  };

  handleOnChange = (e) => {
    const { onChange, enabled } = this.props;
    if (_.isFunction(onChange)) {
      onChange(!enabled);
    } else {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  render() {

    const { id, enabled, disabled, automationId, className = '', children } = this.props;

    return (
      <div
        className={`toggle-button ${className}`}
        data-automation-id={`${automationId}.toggle.button.${id}`}
      >
        <input
          onClick={this.handleOnChange}
          type="checkbox"
          id={`toggle_${id}`}
          className="cbx hidden"
          onChange={this.handleOnChange}
          checked={enabled}
          value={enabled}
          disabled={disabled}
        />
        <label htmlFor={`toggle_${id}`} className="lbl" style={{opacity: disabled ? '0.5' : '1' }} />
        {children}
      </div>
    );
  }
}

export default ToggleButton;
