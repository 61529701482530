import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import '../../../../features/Templates/templates.styles.less';
import SimpleCard from '../EmptyCard/EmptyCard';

import './BasicCard.styles.less';

class BasicCard extends React.PureComponent {

  static propTypes = {
    onClick: PropTypes.func,
    glowOnHover: PropTypes.bool,
    children: PropTypes.node,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    automationId: PropTypes.string.isRequired
  };

  static defaultProps = {
    glowOnHover: true
  };

  render() {
    const { glowOnHover, ...restProps } = this.props;
    return (
      <SimpleCard
        className={classNames('basicCard', { 'basicCard-with-hover': glowOnHover })}
        {...restProps}
      />
    );
  }
}

export default BasicCard;
