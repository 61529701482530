import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import MembershipGlobalConditionsWrapper from './membershipConditions/MembershipGlobalConditionsWrapper';
import DateTimeGlobalConditionWrapper from './dateTimeConditions/DateTimeGlobalConditionWrapper';
import LimitPerMemberGlobalConditionWrapper from './occurrencesLimitCondition/limitPerMemberGlobalConditionWrapper';
import * as schemaConstants from '../../../activitySchema/activitySchema.constants';
import '../conditions.styles.less';

/**
 *
 * GlobalConditions
 *
 */
class GlobalConditions extends React.PureComponent {

  // eslint-disable-next-line no-undef
  static propTypes = {
    schemaMembershipConditions: PropTypes.object,
    dateTimeGlobalConditions: PropTypes.object,
    activityTrigger: PropTypes.string.isRequired,
    disabledGlobalConditions: PropTypes.object,
    limitPerMemberGlobalConditionDisabled: PropTypes.bool,
    abTestMode: PropTypes.bool,
    isABTestingAllowed: PropTypes.bool
  };

  isGlobalConditionEnabled(condition) {
    return !this.props.disabledGlobalConditions
      || !this.props.disabledGlobalConditions.includes(condition);
  }

  /**
   * render
   * @returns {*}
   */
  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column className="condition-left-column" width={2}>
            <p>{formatMessage({ id: 'activity.globalConditions.applyIt' })}</p>
          </Grid.Column>
          <Grid.Column width={14}>
            <div>
              <span>
                <MembershipGlobalConditionsWrapper
                  isMembersFilter={this.props.activityTrigger === schemaConstants.TRIGGER_ONE_TIME_ACTION}
                  disabled={!this.isGlobalConditionEnabled(schemaConstants.MEMBERSHIP_GLOBAL_CONDITION)}
                  schemaMembershipConditions={this.props.schemaMembershipConditions}
                  abTestMode={this.props.abTestMode}
                  isABTestingAllowed={this.props.isABTestingAllowed}
                />
                {this.isGlobalConditionEnabled(schemaConstants.DATETIME_GLOBAL_CONDITION) &&
                <DateTimeGlobalConditionWrapper
                  dateTimeGlobalConditions={this.props.dateTimeGlobalConditions}
                />
                }{this.isGlobalConditionEnabled(schemaConstants.OCCURRENCES_GLOBAL_CONDITION) &&
                <LimitPerMemberGlobalConditionWrapper
                  disabled={this.props.limitPerMemberGlobalConditionDisabled}
                  isMembersFilter={this.props.activityTrigger === schemaConstants.TRIGGER_ONE_TIME_ACTION}
                />
                }
              </span>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>);
  }
}

export default injectIntl(GlobalConditions);

