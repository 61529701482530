/**
 *
 * IntegerInputField
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import InputWrapper from './../InputWrapper/InputWrapper';
import { INTEGER_TYPE } from '../inputConstants';

class PercentageIntegerInputField extends React.PureComponent {

  static propTypes = {
    allowOnlyPositive: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    fluid: PropTypes.bool,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    prefix: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    fluid: false,
    allowOnlyPositive: true
  };

  onChange = (e, data) => {
    const formattedValue = data.value ? parseInt(data.value, 10) : null;
    const newData = { name: data.name, value: formattedValue };
    this.props.onChange(e, newData);
  };

  onKeyDown = (event) => {
    if (event.key === '.') {
      event.preventDefault();
      return false;
    }
    if (this.props.allowOnlyPositive && event.key === '-') {
      event.preventDefault();
      return false;
    }
    return true;
  };

  render() {

    const { value, fluid, disabled, name, prefix, error, placeholder } = this.props;
    const className = fluid ? this.props.className : `${this.props.className} numeric-field probability-icon`;

    return (
      <InputWrapper
        className={className}
        disabled={disabled}
        error={error}
        fluid={fluid}
        name={name}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
        prefix={prefix}
        step={1}
        type={INTEGER_TYPE}
        value={value === null || value === undefined ? '' : value}
        placeholder={placeholder}
        icon="percent"
      />
    );
  }
}

export default PercentageIntegerInputField;
