import { call, put, takeLatest } from 'redux-saga/effects';
import * as api from './images.api';
import * as constants from './images.constants';
import * as errorConstant from '../ErrorMessage/errorMessage.constants';

function* getImagesBySize({ size }) {
  try {
    const data = yield call(api.getImagesBySize, size);
    return yield put({ type: constants.GET_IMAGES_BY_SIZE_SUCCESS, data, size });
  } catch (err) {
    return yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getImages.Fail'] });
  }
}

function* deleteImage(action) {
  try {
    const data = yield call(api.deleteImage, action.image.get(constants.ID));
    return yield put({ type: constants.DELETE_IMAGE_SUCCESS, data });
  } catch (err) {
    yield put({ type: constants.DELETE_IMAGE_ERROR });
    return yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['deleteImage.Fail'] });
  }
}

function* uploadImages(action) {
  try {
    const uploadSuccess = [];
    const uploadFailure = {};
    for (let i = 0, len = action.accepted.length; i < len; i++) {
      const data = yield call(api.uploadImages, action.accepted[i]);
      if (data && data.statusText && data.statusText === 'Bad Request') {
        uploadFailure[data.data] = uploadFailure[data.data] || [];
        uploadFailure[data.data].push(action.accepted[i]);
      } else {
        uploadSuccess.push(action.accepted[i]);
      }
    }
    if (action.accepted.length !== uploadSuccess.length) {
      yield put({ type: constants.UPLOAD_IMAGES_ERROR });
      return yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['uploadImages.partial.Fail'] });
    }
    return yield put({ type: constants.UPLOAD_IMAGES_SUCCESS });
  } catch (err) {
    yield put({ type: constants.UPLOAD_IMAGES_ERROR });
    return yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['uploadImages.Fail'] });
  }
}

// sagas listen to dispatched actions too (same as reducers)
function* imagesSaga() {
  yield [
    takeLatest(constants.GET_IMAGES_BY_SIZE, getImagesBySize),
    takeLatest(constants.DELETE_IMAGE, deleteImage),
    takeLatest(constants.UPLOAD_IMAGES, uploadImages),
  ];
}

export default imagesSaga;
