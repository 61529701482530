/**
 *
 * Codes
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon, Input, List } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import CopyToClipboard from '@como/web-hub-components/src/CopyToClipboard/InputWithCopyToClipboard';
import { removeNewLineCharacters } from '../../../utils/textFormat';

import './itemList.styles.less';

// todo: common

class ItemList extends Component {

  static propTypes = {
    automationIdPrefix: PropTypes.string,
    errors: PropTypes.string,
    items: PropTypes.array,
    onChange: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      focusedInput: null,
      focusedItemIndex: 0
    };
    this.onChange = this.onChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onPaste = this.onPaste.bind(this);
  }

  componentWillMount() {
    setTimeout(this.setFocusedItem);
  }

  /**
   * update codes condition
   * @param index
   * @param value
   */
  onChange = (index, value) => {
    const newItems = [...this.props.items];
    newItems[index] = removeNewLineCharacters(value);
    this.props.onChange(newItems);
  };

  /**
   * catch the Enter key to add a new line and focus on it
   * catch up and down arrow keys to loop through the list
   * @param {int} key
   * @param {int} index
   */
  onKeyUp = (key, index) => {
    switch (key) {

      // enter
      case 13: {
        const newItems = this.props.items.filter((item) => item !== '').concat(['']);
        this.props.onChange(newItems);
        this.setFocusedItem(newItems.length - 1);
        break;
      }

      // up
      case 38: {
        const newIndex = (index === 0) ? index : index - 1;
        this.setFocusedItem(newIndex);
        break;
      }

      // down
      case 40: {
        const newIndex = (index === (this.props.items.length - 1)) ? index : index + 1;
        this.setFocusedItem(newIndex);
        break;
      }

      // on any other keystroke make sure there's an empty line at the end of the list
      default: {
        const itemsArray = [...this.props.items];
        let filteredItemsArray = itemsArray.filter((item, i) =>
          item !== '' || i === itemsArray.length - 1);
        if (filteredItemsArray.length === 1 && filteredItemsArray[0] === '') {
          filteredItemsArray = [];
        } else if (filteredItemsArray[filteredItemsArray.length - 1] !== '' && this.state.focusedItemIndex === filteredItemsArray.length - 1) {
          filteredItemsArray = filteredItemsArray.concat(['']);
        }
        if (JSON.stringify(this.props.items) !== JSON.stringify(filteredItemsArray)) {
          this.props.onChange(filteredItemsArray);
        }
        break;
      }
    }
  };

  /**
   * add pasted list to existing list,
   * remove empty items that might be in the middle of the list,
   * add an empty option at the end and focus on it
   * @param e
   */
  onPaste = (e) => {
    const pastedData = e.clipboardData.getData('text/plain');
    let pastedArray = [];
    if (pastedData.includes(String.fromCharCode(13))) {
      pastedArray = pastedData.split(String.fromCharCode(13)).map((item) => removeNewLineCharacters(item));
    } else if (pastedData.includes(String.fromCharCode(10))) {
      pastedArray = pastedData.split(String.fromCharCode(10)).map((item) => removeNewLineCharacters(item));
    } else if (pastedData.includes(',')) {
      pastedArray = pastedData.split(',').map((item) => removeNewLineCharacters(item));
    } else {
      pastedArray.push(removeNewLineCharacters(pastedData));
    }
    const newItems = [...this.props.items, ...pastedArray].filter((item) => item !== '').concat(['']);
    this.props.onChange(newItems);
    this.setFocusedItem(newItems.length - 1);
    e.preventDefault();
  };

  /**
   * delete code
   * @param index
   */
  onDelete = (index) => {
    const newItems = [...this.props.items];
    newItems.splice(index, 1);
    this.props.onChange(newItems);
    this.setFocusedItem(newItems.length - 1);
  };

  /**
   * set the focused item to allow editing an item from the middle of the list
   * @param index
   */
  onFocus(index) {
    this.setFocusedItem(index);
  }

  /**
   * set focused item to index (if supplied) or last item
   * @param index
   */
  setFocusedItem = (index) => {
    const focusedItemIndex = (index || index === 0) ? index : this.props.items.length - 1;
    this.setState({ focusedItemIndex }, () => {
      this.state.focusedInput.focus();
    });
  };

  render() {

    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const items = this.props.items;
    const clsName = classNames('items-list', { 'item-box-error': !!this.props.errors });

    return (
      <div>
        <div className={clsName}>
          <p>{formatMessage({ id: 'activity.itemsPopulation.codesNote' })}</p>
          <List>
            {
              items.length > 0
              ? items.map((item, index) => (
                <List.Item key={index.toString()}>
                  <Input
                    ref={(input) => { if (index === this.state.focusedItemIndex) { this.state.focusedInput = input; } }}
                    value={removeNewLineCharacters(item)}
                    onChange={(e, data) => this.onChange(index, data.value)}
                    onPaste={this.onPaste}
                    onKeyUp={(e) => this.onKeyUp(e.keyCode, index)}
                    onFocus={() => this.onFocus(index)}
                    data-automation-id={`${this.props.automationIdPrefix}.item.${index}`}
                  />
                  {items.length > 1 ?
                    (
                      <Icon
                        className="como-ic-delete"
                        onClick={() => this.onDelete(index)}
                        data-automation-id={`${this.props.automationIdPrefix}.item.${index}.delete`}
                      />
                      )
                    : null}
                </List.Item>
                ))
              : null
              }
          </List>
          <CopyToClipboard
            className={'item-population-copy'}
            value={items && items.length ? items : ''}
            labelKey={'item-population-copy-label'}
            disabled
            propertyName={'items'}
            onChange={() => false}
          ></CopyToClipboard>
        </div>
        { this.props.errors ? (<div className="item-box-error-message">{formatMessage({ id: this.props.errors })}</div>) : null }
      </div>
    );
  }

}

export default injectIntl(ItemList);
