import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Grid, Icon } from 'semantic-ui-react';
import Condition from './condition';
import * as schemaConstants from '../../activitySchema/activitySchema.constants';
import './conditions.styles.less';
import * as staticSchema from '../../activitySchema/activitySchema';
import * as selectors from '../../activity.selectors';


class Conditions extends React.PureComponent {

  static propTypes = {
    activityConditions: PropTypes.object.isRequired,
    addCondition: PropTypes.func.isRequired,
    addDaysTimesCondition: PropTypes.func,
    allowDeleteOnlyCondition: PropTypes.bool,
    automationIdPrefix: PropTypes.string,
    conditionsList: PropTypes.object,
    conditionsRelations: PropTypes.string,
    deleteCondition: PropTypes.func.isRequired,
    deleteDaysTimesCondition: PropTypes.func,
    features: PropTypes.object,
    isCaseCondition: PropTypes.bool,
    label: PropTypes.bool,
    mainConditions: PropTypes.bool,
    schemaMembershipConditions: PropTypes.object,
    trigger: PropTypes.string,
    updateConditionField: PropTypes.func.isRequired,
    updateDateRangeDate: PropTypes.func,
    updateDateRangeTime: PropTypes.func,
    updateDaysTimes: PropTypes.func,
    validationErrors: PropTypes.object,
    children: PropTypes.node,
    activityType: PropTypes.string,
    abTestMode: PropTypes.bool,
    isAdvancedRuleConditions: PropTypes.bool,
    isOneTimeActivityDisabled: PropTypes.bool,
    getBusinessBundlePlan: PropTypes.func.isRequired
  };

  static defaultProps = {
    allowDeleteOnlyCondition: true,
    label: false,
    mainConditions: true,
    isAdvancedRuleConditions: false
  };

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const hint =
      staticSchema.redeemableActivities.includes(this.props.activityType)
      ? formatMessage({ id: 'activity.conditions.deal.onlyInterested' })
      : formatMessage({ id: 'activity.conditions.onlyInterested' });
    if (!this.props.conditionsList) {
      return null;
    }
    return (
      <Grid>
        {
          (this.props.conditionsList && this.props.conditionsList.size) || this.props.trigger === schemaConstants.TRIGGER_UPDATE_MEMBERSHIP
            ? (
              <Grid.Row>
                {
                  this.props.label && (
                    <Grid.Column className="condition-left-column" width={2}>
                      <p>{ formatMessage({ id: 'activity.conditions.conditions' }) }</p>
                    </Grid.Column>
                  )
                }
                <Grid.Column width={this.props.label ? 14 : 16}>
                  { this.props.children }
                  {
                    this.props.activityConditions.map((condition, index) => (
                      <Condition
                        addDaysTimesCondition={this.props.addDaysTimesCondition}
                        automationIdPrefix={`${this.props.automationIdPrefix}.condition.${index}`}
                        condition={condition}
                        conditionsList={this.props.conditionsList}
                        conditionsRelations={this.props.conditionsRelations}
                        deleteCondition={this.props.deleteCondition}
                        deleteDaysTimesCondition={this.props.deleteDaysTimesCondition}
                        features={this.props.features}
                        index={index}
                        isCaseCondition={this.props.isCaseCondition}
                        isDeletable={this.props.allowDeleteOnlyCondition || this.props.activityConditions.size > 1}
                        isLastCondition={index === this.props.activityConditions.size - 1}
                        key={`condition-${index.toString()}`}
                        mainConditions={this.props.mainConditions}
                        schemaMembershipConditions={this.props.schemaMembershipConditions}
                        trigger={this.props.trigger}
                        updateConditionField={this.props.updateConditionField}
                        updateDateRangeDate={this.props.updateDateRangeDate}
                        updateDateRangeTime={this.props.updateDateRangeTime}
                        updateDaysTimes={this.props.updateDaysTimes}
                        validationErrors={this.props.validationErrors ? this.props.validationErrors.get(index.toString()) : null}
                        abTestMode={this.props.abTestMode}
                        isAdvancedRuleConditions={this.props.isAdvancedRuleConditions}
                        isOneTimeActivityDisabled={this.props.isOneTimeActivityDisabled}
                        getBusinessBundlePlan={this.props.getBusinessBundlePlan}
                      />
                    ))
                  }
                  {!this.props.abTestMode && !this.props.isOneTimeActivityDisabled && <a
                    role="button"
                    tabIndex={-1}
                    className="condition-link add-condition"
                    onClick={this.props.addCondition}
                    data-automation-id={`${this.props.automationIdPrefix ? this.props.automationIdPrefix : 'rule'}.addCondition`}
                  >
                    <Icon className="como-ic-plus-in-circle" />
                    {formatMessage({ id: 'activity.conditions.addCondition' })}
                  </a>
                  }
                  {
                    !this.props.activityConditions.size &&
                    (
                      <span className="hint-tip-label">
                        {hint}
                      </span>
                    )
                  }

                </Grid.Column>
              </Grid.Row>
            )
            : null
        }
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  abTestMode: selectors.getABTestMode(state)
});

const withConnect = connect(mapStateToProps, null);

export default compose(withConnect)(injectIntl(Conditions));
