import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {Grid, Icon} from 'semantic-ui-react';

import '../microCampaigns.styles.less';
import '../../Activity/views/cases/cases.styles.less';
import ToggleButton from '../../../common/components/ToggleButton/ToggleButton';
import * as activityConstants from '../../Activity/activity.constants';

import SingleMicroCampaignContextMenu from './SingleMicroCampaignContextMenu';
import * as trackerConstants from '../../../common/utils/tracking/tracking.consts';
import * as microCampaignConstants from '../../MicroCampaign/microCampaign.constants';
import tracker from '../../../common/utils/tracking/tracker';
import ActivityHistoryLog from '../../BusinessCenter/views/ActivityHistoryLog';
import * as numbersFormatUtils from "../../../utils/numbersFormat";

export class SingleMicroCampaignHeader extends React.PureComponent {

  static propTypes = {
    intl: PropTypes.object,
    name: PropTypes.string,
    automationIdPrefix: PropTypes.string,
    maxPopulation: PropTypes.number,
    benefitTitle: PropTypes.string,
    status: PropTypes.string,
    onStatusChange: PropTypes.func,
    singleMicroCampaign: PropTypes.object,
    index: PropTypes.number,
    goToMicroCampaign: PropTypes.func,
    historyLog: PropTypes.object,
    isOpen: PropTypes.bool,
    toggleCollapsed: PropTypes.func
  };

  state = {
    isDetailsModalOpen: false
  };

  handleDetailsModalOpen = () => {
    this.setState({
      isDetailsModalOpen: true
    });
  };

  handleDetailsModalClose = () => {
    this.setState({
      isDetailsModalOpen: false
    });
  };

  openMicroCampaign = () => {
    tracker.onButtonClick(trackerConstants.BUTTON_TYPE_SHOW_MICRO_CAMPAIGNL_USAGES);
    this.props.goToMicroCampaign();
  };

  render() {
    const {formatMessage} = this.props.intl; // eslint-disable-line react/prop-types
    const detailsForTracker = {
      [trackerConstants.ACTIVITY_HUB_ID]: this.props.singleMicroCampaign.get(activityConstants.HUB_ID),
      [trackerConstants.ACTIVITY_SERVER_ID]: this.props.singleMicroCampaign.get(activityConstants.HUB_ID),
      [trackerConstants.ACTIVITY_STATUS]: this.props.status,
      [trackerConstants.ACTIVITY_NAME]: this.props.name,
      [trackerConstants.ACTIVITY_TYPE]: microCampaignConstants.MICRO_CAMPAIGN
    };
    return (
      <Grid>
        <Grid.Row className="microCampaign-row">
          <Grid.Column onClick={this.openMicroCampaign} width={5}>
            {this.props.name}
          </Grid.Column>
          <Grid.Column width={5} onClick={this.openMicroCampaign}>
            {this.props.benefitTitle}
          </Grid.Column>
          <Grid.Column width={4} onClick={this.openMicroCampaign}>
            {formatMessage({id: 'microCampaign.summary.maxPopSize'}, {maxPopulation: numbersFormatUtils.displayNumberWithCommas(this.props.maxPopulation)})}
          </Grid.Column>
          <Grid.Column width={2} className="micro-campaign-context-menu">
            <Icon
              className={`${this.props.isOpen ? 'como-ic-angle-up' : 'como-ic-angle-down'}`}
              onClick={this.props.toggleCollapsed}
            />
            <ToggleButton
              enabled={this.props.status === activityConstants.ACTIVITY_STATUS_ACTIVE}
              disabled={this.props.status === activityConstants.ACTIVITY_STATUS_DRAFT}
              id={`microCampaignStatus_${this.props.index}}`}
              onChange={(enabled) => this.props.onStatusChange(enabled)}
              automationId={`${this.props.automationIdPrefix}.toggleStatus`}
            />
            <SingleMicroCampaignContextMenu
              detailsForTracker={detailsForTracker}
              hidden={false}
              automationId={`microCampaigns-page.microCampaign.${this.props.index}.microCampaign-context-menu`}
              onDetailsOpen={this.handleDetailsModalOpen}
            />
          </Grid.Column>
        </Grid.Row>
        <ActivityHistoryLog
          type={activityConstants.ACTIVITY_TYPE_MICRO_CAMPAIGN}
          name={this.props.name}
          open={this.state.isDetailsModalOpen}
          onConfirm={this.handleDetailsModalClose}
          historyLog={this.props.historyLog}
        />
      </Grid>
    );
  }
}


export default injectIntl(SingleMicroCampaignHeader);
