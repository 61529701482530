/**
 *
 * CustomMenu
 * receives 1 property:
 * data: array of objects
 * each abject has the following properties:
 * text:string -  the text to be renders as menu option
 * iconName:string - icon name from https://react.semantic-ui.com/elements/icon
 * cb:function a callback function to be called on click
 */

import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';

import './CustomMenu.styles.less';

const CustomMenu = (props) => {
  const data = props.data;

  /* eslint-disable react/no-array-index-key */
  const RenderedList = data.map((item, index) => (
    <List.Item
      key={index}
      className={'menuItem'}
      onClick={(e) => { e.preventDefault(); e.stopPropagation(); item.cb(index); }}
      data-automation-id={`${props.AutomationIdPrefix}.${index}`}
    >
      <List.Icon
        className={item.iconName}
      />
      <List.Content>
        {item.text}
      </List.Content>
    </List.Item>
  ));

  return (
    <List>
      {RenderedList}
    </List>
  );
};

CustomMenu.propTypes = {
  data: PropTypes.array.isRequired,
  AutomationIdPrefix: PropTypes.string.isRequired,
};

export default CustomMenu;
