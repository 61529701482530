import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'semantic-ui-react';
import PaneContentList from './PaneContentList';

const AUTOMATION_PREFIX_NAME = 'tabs';

class TabsContentList extends React.Component {

  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.shape({
          key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          text: PropTypes.string
        }),
        values: PropTypes.oneOfType([
          PropTypes.object,
          PropTypes.arrayOf(
            PropTypes.shape({
              key: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
              ]),
              text: PropTypes.string,
              disable: PropTypes.object
            }))
        ])
      })
    ).isRequired,
    automationId: PropTypes.string,
    onSelectValue: PropTypes.func,
    menu: PropTypes.object,
    selectedOption: PropTypes.string,
    selectedTabIndex: PropTypes.number,
  };

  static defaultProps = {
    automationId: ''
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTabIndex: props.selectedTabIndex || 0
    };
  }

  onTabChange = (e, { activeIndex }) => {
    this.setState({
      activeTabIndex: activeIndex
    });
  };

  render() {
    // noinspection JSUnusedLocalSymbols
    const { options, onSelectValue, automationId, selectedOption, selectedTabIndex, ...restProps } = this.props;
    const panes = options.map(({ name, values } = {}) => ({
      menuItem: name.text,
      render: () =>
        (<PaneContentList
          automationId={`${automationId}${AUTOMATION_PREFIX_NAME}.${name.key}`}
          values={values}
          onSelect={(e, value) => onSelectValue(value, name)}
          selectedOption={selectedOption}
        />)
    }));

    return (
      <Tab
        data-automation-id={`${automationId}${AUTOMATION_PREFIX_NAME}`}
        panes={panes}
        activeIndex={this.state.activeTabIndex}
        onTabChange={this.onTabChange}
        {...restProps}
      />
    );
  }
}

export default TabsContentList;
