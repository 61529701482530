import axios from 'axios';

export function getPromoCodes() {
  return axios.get('/api/PromoCodes').then((res) => res.data);
}

export function getPromoCode(promoCodeId) {
  return axios.get(`/api/PromoCodes/${promoCodeId}`).then((res) => res.data);
}

export function getPromoCodeRelatedActivities(promoCodeId) {
  return axios.get(`/api/PromoCodes/relatedActivities/${promoCodeId}`).then((res) => res.data);
}

export function savePromoCode(promoCode) {
  return axios.post('/api/PromoCodes', promoCode).then((res) => res.data);
}

export function updatePromoCodeStatus(data) {
  return axios.post('/api/promoCodes/changeStatus', {
    hubPromoCodeId: data.hubId,
    serverId: data.serverId,
    status: data.status
  }).then((res) => res.data);
}

export function getPromoCodeStats(promoCodeId) {
  return axios.get(`/api/PromoCodes/GetStats?hubPromoCodeId=${promoCodeId}`).then((res) => res.data);
}
export function getPromoCodeBulksStats(promoCodeId) {
  return axios.get(`/api/PromoCodes/getBulksAnalysis?hubPromoCodeId=${promoCodeId}`).then((res) => res.data);
}
export function deletePromoCode(promoCodeId) {
  return axios.delete(`/api/PromoCodes/${promoCodeId}`).then((res) => res.data);
}

export function extendPromoCode(promoCodeId) {
  return axios.post(`/api/PromoCodes/Extend/${promoCodeId}`).then((res) => res.data);
}

export function getPromoCodeSuggestion() {
  return axios.get('/api/PromoCodes/SuggestSingleCode').then((res) => res.data);
}

export function checkCodeIsAvailable(code) {
  return axios.get(`/api/PromoCodes/CheckCodeIsAvailable/${code}`).then((res) => res.data);
}

export function getFreeCodesNumber(codeLength) {
  return axios.get(`/api/PromoCodes/freeCodesNumber/${codeLength}`).then((res) => res.data);
}

export function getExportBulk(bulkKey, serverId) {
  return axios.get(`/api/PromoCodes/exportBulk/${bulkKey}/${serverId}`).then((res) => res.data);
}

export function getBukCodesList(bulkKey, serverId) {
  return axios.get(`/api/PromoCodes/getBulkCodesList/${bulkKey}/${serverId}`).then((res) => res.data);
}
