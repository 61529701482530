import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import DisabledItem from './DisabledItem';
import * as appConstants from '../../../features/App/app.constants';

class DisabledAddOn extends Component {
  render() {
    const { children, bundlePlan } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line
    const tooltipMessage = bundlePlan === appConstants.REVEL ? formatMessage({ id: 'revel.activity.triggers.add-on.is.not.available' }) : formatMessage({ id: 'activity.triggers.add-on.is.not.available' });
    return (
      <DisabledItem tooltipMessage={tooltipMessage} withUnAvailableIcon={false} automationId="addOn">
        {children}
      </DisabledItem>
    );
  }
}

DisabledAddOn.propTypes = {
  children: PropTypes.node,
  bundlePlan: PropTypes.string.isRequired,
};

export default injectIntl(DisabledAddOn);
