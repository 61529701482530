import { fromJS } from 'immutable';
import * as constants from './microCampaign.constants';
import * as activityActionDefaults from '../Activity/reducers/activity.action.defaults';
import * as activityConstants from '../Activity/activity.constants';
import * as schemaConstants from '../Activity/activitySchema/activitySchema.constants';


export const launchAction = fromJS(activityActionDefaults.emptyAction).set(activityConstants.ACTION_META, constants.MICRO_CAMPAIGN_ACTION_TYPE_LAUNCH);
export const reminderAction = fromJS(activityActionDefaults.emptyAction).set(activityConstants.ACTION_META, constants.MICRO_CAMPAIGN_ACTION_TYPE_REMINDER);

export const emptyAssetTemplate = {
  [constants.MICRO_CAMPAIGN_ASSET_TEMPLATE]: null,
  [activityConstants.ACTIONS]: [
    launchAction, reminderAction
  ]
};

export const emptyAssetTemplateImmutable = fromJS({
  [constants.MICRO_CAMPAIGN_ASSET_TEMPLATE]: null,
  [activityConstants.ACTIONS]: [
    launchAction, reminderAction
  ]
});

export const initialMicroCampaign = fromJS({
  [activityConstants.LOCATION_ID]: '',
  [activityConstants.TRIGGER]: schemaConstants.TRIGGER_MICRO_CAMPAIGN,
  [activityConstants.NAME]: '',
  [activityConstants.DESCRIPTION]: '',
  [constants.MICRO_CAMPAIGN_ASSET_TEMPLATES]: [
    emptyAssetTemplate
  ],
  [constants.MICRO_CAMPAIGN_POPULATION_SIZE]: Math.floor(constants.MICRO_CAMPAIGN_POPULATION_SIZE_MAX / 2)
});
