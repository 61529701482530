import React from 'react';
import PropTypes from 'prop-types';

import PopupWrapper from './PopupWrapper';
import DisabledItemPosRestriction from '../DisabledItem/DisabledItemPosRestriction';
import { featureSupported } from '../DropdownWrapper/DropdownWrapper';
import PopupTrigger from '../PopupTrigger/PopupTrigger';

class DropDownPopup extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool,
    options: PropTypes.array,
    onSelectOption: PropTypes.func,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    automationIdPrefix: PropTypes.string.isRequired,
    className: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false
    };
  }

  onOptionSelect = (e, value) => {
    this.props.onSelectOption(e, { name: this.props.name, value });
    this.handleOnPopupClose();
  };

  handleOnPopupOpen = () => {
    this.setState({
      isPopupOpen: true
    });
  };

  handleOnPopupClose = () => {
    this.setState({
      isPopupOpen: false
    });
  };


  get optionsWithAutomationIds() {
    if (!this.props.options) {
      return [];
    }

    return this.props.options.map((op) => {
      const obj = Object.assign({}, op);
      obj['data-automation-id'] = `${this.props.automationIdPrefix}.${op.value}`;
      if (!featureSupported(op)) {
        if (op.content) {
          obj.content = (
            <DisabledItemPosRestriction>
              {op.content}
            </DisabledItemPosRestriction>
          );
        }
        if (op.text) {
          obj.text = (
            <DisabledItemPosRestriction>
              {op.text}
            </DisabledItemPosRestriction>
          );
        }
        obj.disabled = true;
      }
      return obj;
    });
  }

  render() {
    const selectedValue = this.props.value ? this.props.options.find((x) => x.value === this.props.value) : null;
    const selectedOptionText = selectedValue ? selectedValue.text : this.props.placeholder;
    return (
      <PopupWrapper
        open={this.state.isPopupOpen}
        className={`drop-down-like ${this.props.className}`}
        disabled={this.props.disabled}
        position="bottom left"
        on="click"
        onOpen={this.handleOnPopupOpen}
        onClose={this.handleOnPopupClose}
        flowing
        automationId={`${this.props.automationIdPrefix}.popup`}
        trigger={
          <div className="inline-div">
            <PopupTrigger
              className={`ui selection fluid dropdown ${this.props.value ? '' : 'placeholder'}`}
              message={selectedOptionText}
              automationId={this.props.automationIdPrefix}
            />
          </div>
        }
      >
        <ul className="selectable-options" data-automation-id={`${this.props.automationIdPrefix}.list`}>
          {
            this.optionsWithAutomationIds.map((option) => (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <li
                key={option.key}
                data-automation-id={`${this.props.automationIdPrefix}.${option.value}`}
                value={option.value}
                onClick={(e) => this.onOptionSelect(e, option.value)}
                className={option.value === this.props.value ? 'selected' : ''}
              >
                {option.text}
              </li>
              )
            )
          }
        </ul>
      </PopupWrapper>);
  }
}

export default DropDownPopup;
