import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Dimmer, Grid, Loader } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import Tooltip from '../../../common/components/Tooltip/Tooltip';
import Modal from '../../../common/components/CustomModal/CustomModal';
import * as activityConstants from '../../Activity/activity.constants';
import injectSaga from '../../../utils/injectSaga';
import * as activityActions from '../../Activity/activity.actions';
import activitySaga from '../../Activity/activity.saga';
import * as selectors from '../../Activity/activity.selectors';
import * as appSelectors from '../../../features/App/selectors';
import ActivityType from './ActivityType';
import * as reducerConstants from '../../../constants/reducer.constants';
import ActivityStatistic from './ActivityStatistic';

const NAME_LETTERS_MAX = 25;

class PunchCardStats extends React.PureComponent {
  static propTypes = {
    activity: PropTypes.object.isRequired,
    open: PropTypes.bool,
    onConfirm: PropTypes.func,
    stats: PropTypes.object,
    type: PropTypes.string.isRequired,
    currencySymbol: PropTypes.string,
    actions: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = { isTooltipOpen: false };
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);

  }

  componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open) {
      this.props.actions.getActivityStats(this.props.activity.get(activityConstants.HUB_ID), this.props.activity.get(activityConstants.TYPE));
    }
  }

  onOpen() {
    setTimeout(() => this.setState({ isTooltipOpen: true }), 2000);
  }

  onClose() {
    this.setState({ isTooltipOpen: false });
  }

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line
    const { type, open, onConfirm, activity, stats } = this.props;
    const name = activity.get(activityConstants.NAME);
    const title = <p>{formatMessage({ id: 'punch.card.stats.title' }, { name })}</p>;
    const hint = formatMessage({ id: 'punch.card.stats.hint' });

    return (
      <Modal
        open={open}
        className="modal__activity__log-details_punch_card"
        onClose={onConfirm}
        automationIdPrefix={'HubPunchCard.analysis'}
        header={
          <div className="type-and-name">
            <ActivityType type={type} />
            {name.length > NAME_LETTERS_MAX ?
              <Tooltip
                content={title}
                trigger={title}
                position="bottom right"
              /> : title
            }
          </div>
        }
        content={
          <div className="stats-modal-content">
            <Dimmer inverted active={!stats}>
              <Loader active />
            </Dimmer>
            <Tooltip
              open={this.state.isTooltipOpen}
              onOpen={this.onOpen}
              onClose={this.onClose}
              className="tooltip.ui.popup"
              content={formatMessage({ id: 'punch.card.stats.tooltip' },
                { 'totalSent': stats ? stats.get('totalSent') : '?',
                  'fullyPunched': stats ? stats.get('fullyPunched') : '?',
                  'redeemed': stats ? stats.get('redeemed') : '?' })}
              trigger={
                <ul>
                  <Grid className="statsGrid" >
                    <ActivityStatistic
                      label={formatMessage({ id: 'punch.card.stats.punch_punch_received' })}
                      value={stats && stats.get('punch_punch_received')}
                    />
                    <ActivityStatistic
                      label={formatMessage({ id: 'punch.card.stats.punch_full_punch_redeem' })}
                      value={stats && stats.get('punch_full_punch_redeem')}
                    />
                    <ActivityStatistic
                      label={formatMessage({ id: 'punch.card.stats.punch_count_punches' })}
                      value={stats && stats.get('punch_count_punches')}
                    />
                    <ActivityStatistic
                      label={formatMessage({ id: 'punch.card.stats.punch_full_unredeemed_punch_cards' })}
                      value={stats && stats.get('punch_full_unredeemed_punch_cards')}
                    />
                    <ActivityStatistic
                      label={formatMessage({ id: 'punch.card.stats.total_sales' })}
                      value={stats && stats.get('total_sales')}
                      currencySymbol={this.props.currencySymbol}
                      defaultValue={formatMessage({ id: 'punchCard.stats.no.information' })}
                    />
                    <ActivityStatistic
                      label={formatMessage({ id: 'punch.card.stats.average_sale_per_ticket' })}
                      value={stats && stats.get('average_sale_per_ticket')}
                      currencySymbol={this.props.currencySymbol}
                      defaultValue={formatMessage({ id: 'punchCard.stats.no.information' })}
                    />
                    <ActivityStatistic
                      label={formatMessage({ id: 'punch.card.stats.avg_sale_with_redeem' })}
                      value={stats && stats.get('avg_sale_with_redeem')}
                      currencySymbol={this.props.currencySymbol}
                      defaultValue={formatMessage({ id: 'punchCard.stats.no.information' })}
                    />
                    <ActivityStatistic
                      label={formatMessage({ id: 'punch.card.stats.punch_days_until_redeem_avg_days_between_punch_receive_and_redeem' })}
                      value={stats && stats.get('punch_days_until_redeem_avg_days_between_punch_receive_and_redeem')}
                    />
                  </Grid>
                </ul>
              }
              position="bottom left"
            />
            <span className="hint-tip-label stick-to-bottom">
              {hint}
            </span>

          </div>
          }
        actions={[
          <a data-automation-id="punch.card.stats.got.it.button" role="button" key="gotIt" onClick={onConfirm}>{formatMessage({ id: 'general.button.got-it' })}</a>
        ]}
        closeOnDimmerClick
      />
    );
  }
}

const mapStateToProps = (state) => ({
  stats: selectors.getActivityStats(state),
  currencySymbol: appSelectors.getLocationCurrencySymbol(state)
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(activityActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.ACTIVITY_BRANCH, saga: activitySaga });

export default compose(
  withConnect,
  withSaga,
  injectIntl
)(PunchCardStats);

