export function getWeeklyRevenuesDataForChart(stats, weekDateConst) {
  const res = stats.map((s) => {
    return {
      x: s.get('week'),
      y: s.get('Revenue') || 0,
      status: s.get('status'),
      weekDate: s.get(weekDateConst || 'weekDate'),
      reason: s.get('Reason')
    };
  });
  const revenues = stats.map((s) => s.get('Revenue') || 0);
  const arr = [];
  res.toJSON().forEach((s) => {
    if ((arr.length === 0) || (arr[arr.length - 1][arr[arr.length - 1].length - 1].status === 'sent') || (arr[arr.length - 1][arr[arr.length - 1].length - 1].reason !== s.reason)) {
      // if empty arr or last stat status is sent or last stat status is fail but the reason is different create new array in the next array index
      arr[arr.length] = [s];
    } else {
      // add to the existing array the new stat
      if (arr[arr.length - 1].length < 4) {
        arr[arr.length - 1].push(s);
      }else {
        arr[arr.length - 1][3] = s;
      }
    }
  });
  return {data: arr, heightDomain: getChartHeightDomain(revenues)};
  // todo: remove:
  // const tempRevenues = [1233, 852, 1066, 700, 0, -500, 1233, 852, 1066, 700, 0, 1233, 852, 1066, 0, -500, -500, 1233, 852, 1066, 0, -500, 1233];
  // return { data: prepareForChart(labels, tempRevenues), heightDomain: getChartHeightDomain(tempRevenues) };
}

export function getRecentBatchData(stats) {
  return stats.get(-1);
}

function getChartHeightDomain(numbersArray) {
  const maxNumber = Math.max(...numbersArray);
  return maxNumber * 1.2;
}
