import { fromJS, List } from 'immutable';
import * as constants from '../activity.constants';
import * as itemsPopulationConstants from '../views/itemsPopulation/itemsPopulation.constants';
import * as itemGroupsConstants from '../../ItemGroups/itemGroups.constants';
import * as defaults from './activity.reducer.defaults';
import * as utils from './utils/itemsPopulation.reducer.utils';
import * as conditionReducerUtils from './utils/condition.reducer.utils';
import { getSchema } from '../activitySchema/activitySchema.selectors';

export function activityItemsPopulationReducer(state, action) {

  switch (action.type) {

    case itemsPopulationConstants.ITEMS_POPULATION_COPY_DATA_TO_TEMP_ITEMS_POPULATION: {
      const newTempItemsPopulation = utils.copyDataToTempItemsPopulation(action.data);
      return state.set(itemsPopulationConstants.TEMP_ITEMS_POPULATION, newTempItemsPopulation);
    }

    case itemsPopulationConstants.ITEMS_POPULATION_COPY_DATA_TO_TEMP_ITEMS_GROUP: {
      const newItemsGroup = utils.itemsPopulationCopyDataToTempItemsGroup(action.data);
      return state.set(itemsPopulationConstants.TEMP_ITEMS_GROUP, newItemsGroup);
    }

    case itemsPopulationConstants.ITEMS_POPULATION_UPDATE_TYPE: {
      const path = [
        (action.editGroupMode ? itemsPopulationConstants.TEMP_ITEMS_GROUP : itemsPopulationConstants.TEMP_ITEMS_POPULATION),
        itemsPopulationConstants.ITEMS_POPULATION_TYPE];
      return state.setIn(path, fromJS(action.itemsPopulationType));
    }

    case itemsPopulationConstants.ITEMS_POPULATION_UPDATE_GROUP_NAME: {
      return state.setIn([itemsPopulationConstants.TEMP_ITEMS_GROUP, itemGroupsConstants.NAME], fromJS(action.name));
    }

    case itemsPopulationConstants.ITEMS_POPULATION_CLEAR_ALL_IN_TYPE: {
      const path = [action.editGroupMode ? itemsPopulationConstants.TEMP_ITEMS_GROUP : itemsPopulationConstants.TEMP_ITEMS_POPULATION,
        itemsPopulationConstants.ITEMS_POPULATION, action.itemsPopulationType, constants.CONDITIONS, constants.CONDITIONS_LIST];
      return state.setIn(path, List());
    }

    case itemsPopulationConstants.ITEMS_POPULATION_UPDATE_CONDITION: {
      const pathPrefix = action.editGroupMode ? itemsPopulationConstants.TEMP_ITEMS_GROUP : itemsPopulationConstants.TEMP_ITEMS_POPULATION;
      const path = [pathPrefix, itemsPopulationConstants.ITEMS_POPULATION, action.itemsPopulationType, constants.CONDITIONS, constants.CONDITIONS_LIST, action.index];
      const condition = state.getIn(path);
      const updatedCondition = conditionReducerUtils.updateConditionField(
        condition, constants.SHOPPING_CART, action.fieldKey, action.fieldValue, getSchema(state));
      return state.setIn(path, updatedCondition);
    }

    case itemsPopulationConstants.ITEMS_POPULATION_UPDATE_WHOLE_CONDITION: {
      const pathPrefix = action.editGroupMode ? itemsPopulationConstants.TEMP_ITEMS_GROUP : itemsPopulationConstants.TEMP_ITEMS_POPULATION;
      const path = [pathPrefix, itemsPopulationConstants.ITEMS_POPULATION, action.itemsPopulationType, constants.CONDITIONS, constants.CONDITIONS_LIST, action.index];
      if (action.value && action.value.length === 0) {
        return state.deleteIn(path);
      }
      const updatedCondition = utils.itemsPopulationUpdateWholeCondition(action.key, action.operator, action.value);
      return state.setIn(path, updatedCondition);
    }

    case itemsPopulationConstants.ITEMS_POPULATION_UPDATE_CONDITIONS_RELATIONS: {
      const path = [action.editGroupMode ? itemsPopulationConstants.TEMP_ITEMS_GROUP : itemsPopulationConstants.TEMP_ITEMS_POPULATION,
        itemsPopulationConstants.ITEMS_POPULATION, action.itemsPopulationType, constants.CONDITIONS, constants.CONDITION_LIST_OPERATOR];
      return state.setIn(path, fromJS(action.relation));
    }

    case itemsPopulationConstants.ITEMS_POPULATION_ADD_CONDITION: {
      const path = [action.editGroupMode ? itemsPopulationConstants.TEMP_ITEMS_GROUP : itemsPopulationConstants.TEMP_ITEMS_POPULATION,
        itemsPopulationConstants.ITEMS_POPULATION, action.itemsPopulationType, constants.CONDITIONS, constants.CONDITIONS_LIST];
      return state.setIn(path, fromJS([...state.getIn(path), defaults.emptyCondition]));
    }

    case itemsPopulationConstants.ITEMS_POPULATION_DELETE_CONDITION: {
      const path = [action.editGroupMode ? itemsPopulationConstants.TEMP_ITEMS_GROUP : itemsPopulationConstants.TEMP_ITEMS_POPULATION, itemsPopulationConstants.ITEMS_POPULATION,
        action.itemsPopulationType, constants.CONDITIONS, constants.CONDITIONS_LIST, action.index];
      return state.deleteIn(path);
    }

    case itemGroupsConstants.CREATE_ITEMS_GROUP_SUCCESS: {
      /**
       * handles save-as-group side effects
       * set type to 'itemGroups' so radio button will move to pre defined groups
       * save new group's serverId to conditionValue so it will be checked in the list
       */
      const typePath = [itemsPopulationConstants.TEMP_ITEMS_POPULATION, itemsPopulationConstants.ITEMS_POPULATION_TYPE];
      const itemGroupsConditionsListPath = [itemsPopulationConstants.TEMP_ITEMS_POPULATION, itemsPopulationConstants.ITEMS_POPULATION,
        itemsPopulationConstants.ITEMS_POPULATION_TYPE_ITEM_GROUPS, constants.CONDITIONS, constants.CONDITIONS_LIST];
      const itemGroupsConditionsList = state.getIn(itemGroupsConditionsListPath);
      const newItemGroupsCondition = utils.appendItemsGroup(action.group, itemGroupsConditionsList);
      return state
        .setIn(typePath, fromJS(itemsPopulationConstants.ITEMS_POPULATION_TYPE_ITEM_GROUPS))
        .setIn(itemGroupsConditionsListPath, List().push(newItemGroupsCondition));
    }

    case itemGroupsConstants.DEACTIVATE_ITEMS_GROUP_SUCCESS: {
      const path = [itemsPopulationConstants.TEMP_ITEMS_POPULATION, itemsPopulationConstants.ITEMS_POPULATION,
        itemsPopulationConstants.ITEMS_POPULATION_TYPE_ITEM_GROUPS, constants.CONDITIONS, constants.CONDITIONS_LIST, 0,
        constants.CONDITION_VALUE];
      const itemGroupsList = state.getIn(path);
      if (!itemGroupsList) { return state; }
      return state.setIn(path, utils.removeItemGroupFromList(itemGroupsList, action.serverId));
    }

    case itemsPopulationConstants.ITEMS_POPULATION_CLEAR_DATA: {
      if (action.editGroupMode) {
        return state.delete(itemsPopulationConstants.TEMP_ITEMS_GROUP);
      }
      return state.delete(itemsPopulationConstants.TEMP_ITEMS_POPULATION);
    }

    default:
      return state;
  }
}
