import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as api from './dataAndBi.api';
import * as constants from './dataAndBi.constants';
import * as errorConstant from '../ErrorMessage/errorMessage.constants';

// sagas listen to dispatched actionsList too (same as reducers)

function* getConfig() {
  try {
    const data = yield call(api.getConfig);
    yield put({ type: constants.CONFIG_FETCHED_SUCCESS, data });
  } catch (err) {

    console.log(err);
    if (_.get(err, 'response.status') !== 403) {
      yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: [constants.CONFIG_FETCHED_FAIL] });
    }
  }
}

function* getFilesData(action) {
  try {
    const data = yield call(api.getFilesData, action.payload.queryName);
    yield put({ type: constants.FILES_DATA_FETCHED_SUCCESS, data });
  } catch (err) {
    if (_.get(err, 'response.status') !== 403) {
      yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: [constants.FILES_DATA_FETCHED_FAIL] });
    }
  }
}

function* getSignedUrl(action) {
  try {
    const data = yield call(api.getSignedUrl, action.payload.objectName);

    window.open(data.signedUrl);
    yield put({ type: constants.SIGNED_URL_FETCHED_SUCCESS, data: data.signedUrl });
  } catch (err) {
    if (_.get(err, 'response.status') !== 403) {
      yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: [constants.SIGNED_URL_FETCHED_FAIL] });
    }
  }
}

function* postActivateExport() {
  try {
    const data = yield call(api.postActivate);
    yield put({ type: constants.ACTIVATED_SUCCESS, data });
    yield put({ type: constants.GET_CONFIG });
  } catch (err) {

    console.log(err);
    if (_.get(err, 'response.status') !== 403) {
      yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: [constants.ACTIVATED_FAIL] });
    }
  }
}

function* dataAndBiSaga() {
  yield [
    takeLatest(constants.GET_CONFIG, getConfig),
    takeLatest(constants.GET_FILES_DATA, getFilesData),
    takeLatest(constants.GET_SIGNED_URL, getSignedUrl),
    takeLatest(constants.ACTIVATE, postActivateExport)
  ];
}

export default dataAndBiSaga;
