import axios from 'axios';
import * as activityConstants from '../Activity/activity.constants';

export function getCampaigns(archiveIncluded) {
  return axios.get(`/api/Campaigns?archiveIncluded=${archiveIncluded}`)
    .then((res) => res.data);
}

export function createCampaign(campaign) {
  return axios.post('/api/Campaigns', campaign)
    .then((res) => res.data);
}

export function updateCampaign(campaign) {
  return axios.put('/api/Campaigns', campaign)
    .then((res) => res.data);
}

export function deleteCampaign(campaignId) {
  return axios.delete(`/api/Campaigns/${campaignId}`)
    .then((res) => res.data);
}

// TODO: have to move to activity.api

export function deleteActivity(type, hubId) {
  const activityApiPath = getActivityApiPath(type);
  return axios.delete(`/api/${activityApiPath}/${hubId}`)
    .then((res) => res.data);
}

export function saveActivity(activity) {
  const { type } = activity;
  const activityApiPath = getActivityApiPath(type);
  return axios.post(`/api/${activityApiPath}`, activity)
    .then((res) => res.data);
}

export function updateActivityPin(activityId, activityType, pinningDate) {
  const activityApiPath = getActivityApiPath(activityType);
  const uri = pinningDate ? `?pinningDate=${pinningDate}` : "";
  return axios.put(`/api/${activityApiPath}/pinActivity/${activityId}${uri}`)
      .then((res) => res.data);
}

export function saveRulesActivities(activities) {
  return axios.post(`/api/${activityConstants.API_PATH_RULES}/bulk`, activities)
    .then((res) => res.data);
}

export function saveDealsActivities(activities) {
  return axios.post(`/api/${activityConstants.API_PATH_DEALS}/bulk`, activities)
    .then((res) => res.data);
}

export function saveOneTimeActionsActivities(activities) {
  return axios.post(`/api/${activityConstants.API_PATH_ONE_TIME}/bulk`, activities)
    .then((res) => res.data);
}

function getActivityApiPath(activityType) {
  switch (activityType) {
    case activityConstants.ACTIVITY_TYPE_RULE:
    case activityConstants.SERVER_TYPE_RULE:
      return activityConstants.API_PATH_RULES;
    case activityConstants.ACTIVITY_TYPE_DEAL:
    case activityConstants.SERVER_TYPE_DEAL:
      return activityConstants.API_PATH_DEALS;
    case activityConstants.ACTIVITY_TYPE_ONE_TIME:
    case activityConstants.SERVER_TYPE_ONE_TIME_ACTION:
      return activityConstants.API_PATH_ONE_TIME;
    case activityConstants.ACTIVITY_TYPE_PUNCH_CARD:
    case activityConstants.SERVER_TYPE_PUNCH_CARD:
      return activityConstants.API_PATH_PUNCH_CARDS;
    case activityConstants.ACTIVITY_TYPE_GIFT:
    case activityConstants.SERVER_TYPE_GIFT:
      return activityConstants.API_PATH_GIFTS;
    case activityConstants.ACTIVITY_TYPE_PROMO_CODE:
    case activityConstants.SERVER_TYPE_PROMO_CODE:
      return activityConstants.API_PATH_PROMO_CODES;
    default:
      throw new Error('unknown activity type!');
  }
}
