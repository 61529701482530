// store keys:
export const GIFTS = 'gifts';
export const GIFTS_ACTIVITY_TAGS = 'giftsActivityTags';
export const GIFT = 'gift';
export const FILTERS = 'filters';
export const SORT = 'sort';
export const ORDER = 'order';
export const GIFTS_LOADED = 'giftsLoaded';
export const ORDER_DESCENDING = 'descending';
export const ORDER_ASCENDING = 'ascending';
export const GIFT_STATS = 'stats';
export const GIFT_USAGES = 'usages';
export const GIFT_STATS_REDEEMED = 'redeemed';
export const GIFT_STATS_ASSIGNED = 'assigned';

// actions:
export const SEARCH_GIFTS = 'app/gifts/SEARCH_GIFTS';
export const SEARCH_TERM = 'searchTerm';
export const GO_TO_GIFT = 'app/gifts/GO_TO_GIFT';
export const GET_GIFTS = 'app/gifts/GET_GIFTS';
export const GET_GIFT = 'app/gifts/GET_GIFT';
export const CREATE_GIFT = 'app/gifts/CREATE_GIFT';
export const DEFOCUS_GIFT = 'app/gifts/DEFOCUS_GIFT';
export const FOCUS_GIFT = 'app/gifts/FOCUS_GIFT';
export const CREATE_GIFT_ERROR = 'app/gifts/CREATE_GIFT_ERROR';
export const UPDATE_ORDER = 'app/gifts/UPDATE_ORDER';
export const UPDATE_FILTERS = 'app/gifts/UPDATE_FILTERS';
export const DELETE_GIFT = 'app/gifts/DELETE_GIFT';
export const DELETE_GIFT_SUCCESS = 'app/gifts/DELETE_GIFT_SUCCESS';
export const DELETE_GIFT_ERROR = 'app/gifts/DELETE_GIFT_ERROR';
export const HIGHLIGHT_ACTIVITY = 'app/gifts/HIGHLIGHT_ACTIVITY';
export const GET_GIFTS_SUCCESS = 'app/gifts/GET_GIFTS_SUCCESS';
export const SET_GIFTS_LOADED = 'app/gifts/SET_GIFTS_LOADED';
export const UPDATE_GIFT = 'app/gifts/UPDATE_GIFT';
export const UPDATE_GIFT_SUCCESS = 'app/gifts/UPDATE_GIFT_SUCCESS';
export const GO_TO_GIFTS_LIST = 'app/gifts/GO_TO_GIFTS_LIST';
export const GET_GIFT_STATS = 'app/gifts/GET_GIFT_STATS';
export const GET_GIFT_STATS_SUCCESS = 'app/gifts/GET_GIFT_STATS_SUCCESS';
export const CLEAN_GIFT_STATS = 'app/gifts/CLEAN_GIFT_STATS';
export const GET_GIFT_RELATED_ACTIVITIES = 'app/gifts/GET_GIFT_RELATED_ACTIVITIES';
export const GET_GIFT_RELATED_ACTIVITIES_SUCCESS = 'app/gifts/GET_GIFT_RELATED_ACTIVITIES_SUCCESS';
export const CLEAN_GIFT_RELATED_ACTIVITIES_DATA = 'app/gifts/CLEAN_GIFT_RELATED_ACTIVITIES_DATA';
