import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Grid, Header, Icon, Image } from 'semantic-ui-react';
import classNames from 'classnames';
import InputWrapper from '../../../../common/components/FormInputFields/InputWrapper/InputWrapper';
import * as activityConstants from '../../activity.constants';
import Gallery from '../../../Images/views/ImageGallery/ImageGallery.container';
import CheckboxWrapper from '../../../../common/components/FormInputFields/CheckboxWrapper/CheckboxWrapper';
import * as imagesConstants from '../../../Images/images.constants';
import Tooltip from '../../../../common/components/Tooltip/Tooltip';
import DropdownWrapper from '../../../../common/components/DropdownWrapper/DropdownWrapper';
import PunchCardPreview from './PunchCardPreview';


class DisplayTab extends React.PureComponent {

  static propTypes = {
    actions: PropTypes.object,
    activityDescription: PropTypes.string,
    activityName: PropTypes.string,
    activityPrefix: PropTypes.string,
    activityType: PropTypes.string,
    defaultImage: PropTypes.object,
    defaultStampImage: PropTypes.object,
    displayDescription: PropTypes.string,
    displayName: PropTypes.string,
    images: PropTypes.object,
    intl: PropTypes.object,
    selectedBackgroundImage: PropTypes.object,
    selectedPreviewImage: PropTypes.object,
    selectedStampImage: PropTypes.object,
    showRedeemCode: PropTypes.bool,
    validationErrors: PropTypes.object,
    interactiveType: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.onInputChange = this.onInputChange.bind(this);
    this.onShowRedeemCheckboxChange = this.onShowRedeemCheckboxChange.bind(this);
    this.onInteractiveTypeChange = this.onInteractiveTypeChange.bind(this);
  }

  onImageSelected = (type, selectedId) => {
    let fieldName = '';
    switch (type) {
      case activityConstants.IMAGE_TYPE_BACKGROUND:
        fieldName = activityConstants.BACKGROUND_IMAGE_ID;
        break;
      case activityConstants.IMAGE_TYPE_STAMP:
        fieldName = activityConstants.STAMP_IMAGE_ID;
        break;
      default:
        fieldName = activityConstants.PREVIEW_IMAGE_ID;
    }
    this.props.actions.updateDisplayField([{
      [activityConstants.DISPLAY_TAB_FIELD_NAME]: fieldName,
      [activityConstants.DISPLAY_TAB_FIELD_VALUE]: selectedId
    }]);
  };

  onInputChange(e, data) {
    this.props.actions.updateDisplayField([{
      [activityConstants.DISPLAY_TAB_FIELD_NAME]: data.name,
      [activityConstants.DISPLAY_TAB_FIELD_VALUE]: data.value
    }]);
  }

  onShowRedeemCheckboxChange(e, data) {
    this.props.actions.updateDisplayField([
      {
        [activityConstants.DISPLAY_TAB_FIELD_NAME]: data.name,
        [activityConstants.DISPLAY_TAB_FIELD_VALUE]: data.checked
      }
    ]);
  }

  onInteractiveTypeChange(e, data) {
    this.props.actions.updateDisplayField([
      {
        [activityConstants.DISPLAY_TAB_FIELD_NAME]: activityConstants.GIFT_INTERACTIVE_TYPE,
        [activityConstants.DISPLAY_TAB_FIELD_VALUE]: data.value
      }
    ]);
  }

  getTooltipContent() {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        {formatMessage({ id: this.props.activityType === activityConstants.ACTIVITY_TYPE_PUNCH_CARD ? 'activity.punchCard.redeem.tooltip' : 'gift.display.redeem.tooltip' })}
        <p className="tooltip-note">
          <span className="underlined">
            {formatMessage({ id: 'activity.displayTab.redeem.tooltip.note' })}
          </span>
          {formatMessage({ id: 'activity.displayTab.redeem.tooltip.note.content' })}
        </p>
      </div>
    );
  }

  getDefaultImagePropByType(imageType) {

    /* console.log(imageType);
     console.log(this.props.defaultStampImage);
     console.log(this.props.defaultImage);*/

    switch (imageType) {
      case activityConstants.IMAGE_TYPE_STAMP:
        return this.props.defaultStampImage;
      default:
        return this.props.defaultImage;
    }
  }

  getImageUploadInstructionsKey(imageType) {
    switch (imageType) {
      case activityConstants.IMAGE_TYPE_PREVIEW:
        return 'imageGallery.upload.preview.image.instruction';
      case activityConstants.IMAGE_TYPE_BACKGROUND:
        return 'imageGallery.upload.background.image.instruction';
      case activityConstants.IMAGE_TYPE_STAMP:
        return 'imageGallery.upload.stamp.image.instruction';
      default:
        return null;
    }
  }

  getImageSelector = (imageType, selectedImage) => {

    const { formatMessage } = this.props.intl;
    const defaultImageUrl = this.getDefaultImagePropByType(imageType);
    const imageWrapperClassNames = classNames('image-wrapper', {
      'no-image-selected': !selectedImage
    });
    const changeImageButtonText = formatMessage({
      id: selectedImage
        ? 'activity.displayTab.change.image'
        : 'activity.displayTab.select.image'
    });

    let triggerImageUrl = defaultImageUrl ? defaultImageUrl.get(imagesConstants.URL) : '';
    if (selectedImage) {
      triggerImageUrl = selectedImage.get(imagesConstants.URL);
    }

    return (
      <Grid.Column width={4}>
        <p className="action-label">{formatMessage({ id: `activity.displayTab.${imageType}.image` })}</p>
        <Gallery
          onApply={(e, data) => this.onImageSelected(imageType, data.selectedId)}
          selectedId={selectedImage ? selectedImage.get(imagesConstants.ID) : null}
          images={this.props.images}
          imageUploadInstructionsKey={this.getImageUploadInstructionsKey(imageType)}
          defaultImage={this.props.defaultImage}
          defaultIfNull
          triggerNode={
            <div className={`image-trigger ${imageType}-image-trigger`}
                 data-automation-id={`select.${imageType}.image.trigger`}>
              <div className={imageWrapperClassNames}>
                <Image
                  data-automation-id={`select.${imageType}.image`}
                  className="gallery-image"
                  style={{ backgroundImage: `url(${encodeURI(triggerImageUrl)})` }}
                />
              </div>
              <div className="change-image-button" data-automation-id={`select.${imageType}.image.trigger.button`}>
                <Icon className="como-ic-pencil"/>{changeImageButtonText}
              </div>
            </div>
          }
        />
        {
          selectedImage && selectedImage.get(imagesConstants.ARCHIVED) &&
          <p className="hint-tip-label archived-hint">
            {formatMessage({ id: 'activity.displayTab.image.archived.hint' })}
          </p>
        }
      </Grid.Column>
    );
  };

  get interactiveType() {
    const { formatMessage } = this.props.intl;
    return [
      {
        [activityConstants.TEXT]: formatMessage({ id: 'gift.interactive.type.text.none' }),
        [activityConstants.VALUE]: activityConstants.GIFT_INTERACTIVE_TYPE_NONE
      },
      {
        [activityConstants.TEXT]: formatMessage({ id: 'gift.interactive.type.text.scratch' }),
        [activityConstants.VALUE]: activityConstants.GIFT_INTERACTIVE_TYPE_SCRATCH
      }
    ];
  }

  render() {

    const { formatMessage } = this.props.intl;
    const tooltipContent = this.getTooltipContent();
    const interactiveType = this.props.interactiveType || activityConstants.GIFT_INTERACTIVE_TYPE_NONE;

    return (
      <Grid className="display-tab">
        <Grid.Row>
          <Grid.Column width={8}>
            <Header>{formatMessage({ id: `activity.actions.benefits.${this.props.activityType}` })} {formatMessage({ id: 'gift.tab.display' })}</Header>
            <p className="hint-tip-label">
              {formatMessage({ id: 'activity.displayTab.explained' })}
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <p className="action-label">{formatMessage({ id: 'activity.displayTab.name' })}</p>
            <InputWrapper
              className={!this.props.displayName ? 'placeholder' : ''}
              name={activityConstants.DISPLAY_TAB_NAME}
              onChange={this.onInputChange}
              value={this.props.displayName || ''}
              prefix={`${this.props.activityPrefix}.displayName`}
              placeholder={this.props.activityName || formatMessage({ id: `activity.${this.props.activityType}.displayTab.name.placeholder` })}
            />
            <p className="action-label">{formatMessage({ id: 'activity.displayTab.description' })}</p>
            <InputWrapper
              className={!this.props.displayDescription ? 'placeholder' : ''}
              name={activityConstants.DISPLAY_TAB_DESCRIPTION}
              onChange={this.onInputChange}
              value={this.props.displayDescription || ''}
              prefix={`${this.props.activityPrefix}.displayDescription`}
              error={this.props.validationErrors ? this.props.validationErrors.get(activityConstants.DISPLAY_TAB_DESCRIPTION) : null}
              placeholder={this.props.activityDescription || formatMessage({ id: `activity.${this.props.activityType}.displayTab.description.placeholder` })}
            />
            {(this.props.validationErrors && this.props.validationErrors.get(activityConstants.DISPLAY_TAB_DESCRIPTION)) ? null :
              <div className="hint-tip-label">
                {formatMessage({ id: 'activity.displayDescription.limit' }, { length: this.props.displayDescription ? this.props.displayDescription.length : 0, lengthLimit: activityConstants.ACTIVITY_DESCRIPTION_LENGTH_LIMIT})}
              </div> }
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {this.getImageSelector(activityConstants.IMAGE_TYPE_PREVIEW, this.props.selectedPreviewImage && this.props.selectedPreviewImage)}
          {
            this.props.activityType === activityConstants.ACTIVITY_TYPE_PUNCH_CARD &&
            <React.Fragment>
              {this.getImageSelector(activityConstants.IMAGE_TYPE_BACKGROUND, this.props.selectedBackgroundImage && this.props.selectedBackgroundImage)}
              {this.getImageSelector(activityConstants.IMAGE_TYPE_STAMP, this.props.selectedStampImage && this.props.selectedStampImage)}
              <Grid.Column width={4} className="punch-preview-wrap">
                <p className="action-label">{formatMessage({ id: 'activity.displayTab.result' })}</p>
                <PunchCardPreview/>
              </Grid.Column>
            </React.Fragment>
          }
        </Grid.Row>
        {
          (this.props.activityType === activityConstants.ACTIVITY_TYPE_GIFT || this.props.activityType === activityConstants.ACTIVITY_TYPE_PUNCH_CARD) &&
          (
            <Grid.Row>
              <Grid.Column width={16}>
                <CheckboxWrapper
                  name="showRedeemCode"
                  checked={this.props.showRedeemCode}
                  data-automation-id={`${this.props.activityPrefix}.showRedeemCode`}
                  onChange={this.onShowRedeemCheckboxChange}
                  label={formatMessage({ id: 'activity.show.redeem.code.in.app' })}
                />
                <div className="hint-tip-label">
                  <Tooltip
                    wide="very"
                    trigger={(<Icon className="como-ic-help"/>)}
                    content={tooltipContent}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          )
        }
        {
          this.props.activityType === activityConstants.ACTIVITY_TYPE_GIFT &&
          <Grid.Row>
            <Grid.Column width={16}>
              <span
                className="head-of-sentence inline-label">{formatMessage({ id: 'activity.interactive.type.label' })}</span>
              <DropdownWrapper
                options={this.interactiveType}
                onSelectOption={this.onInteractiveTypeChange}
                value={interactiveType}
                name="interactiveType"
                prefix={`${this.props.activityPrefix}.chooseInteractiveType`}
                className={this.props.validationErrors && this.props.validationErrors.get(activityConstants.SCRATCH_ERROR) ? 'inputFieldError' : ''}
              />
            </Grid.Column>
          </Grid.Row>
        }
      </Grid>
    );
  }
}

export default injectIntl(DisplayTab);
