/**
 *
 * Benefits
 * display a list of benefits, grouped by type
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Header, List } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import * as constants from '../../benefits.constants';
import BenefitListItem from './benefitListItem';

class Benefits extends React.PureComponent {

  static propTypes = {
    allowCreateGift: PropTypes.bool,
    automationIdPrefix: PropTypes.string,
    index: PropTypes.number.isRequired,
    list: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onCreateGift: PropTypes.func,
    type: PropTypes.string.isRequired,
    value: PropTypes.string,
  };

  static defaultProps = {
    allowCreateGift: false
  };

  render() {

    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    return (
      <div className="benefits-group">
        <Header className="underline">
          {formatMessage({ id: `benefits.header.${this.props.type}` })}
          {
            this.props.allowCreateGift &&
            <span
              role="button"
              tabIndex={-1}
              onClick={this.props.onCreateGift}
              className="create-gift-link"
              data-automation-id={`${this.props.automationIdPrefix}.create`}
            >{formatMessage({ id: 'activity.createGiftFromActivity.createGift' })}</span>
          }
        </Header>
        {
          this.props.list.length > 0
            ? (
              <List size="large" verticalAlign="middle">
                {
                  this.props.list.map((benefit, index) => (
                    <BenefitListItem
                      key={index.toString()}
                      benefit={benefit}
                      selected={this.props.value === benefit[constants.BENEFIT_SERVER_ID]}
                      onChange={() => this.props.onChange(benefit)}
                      automationId={`benefitsList.${this.props.index}.benefit.${index}`}
                    />
                  ))
                }
              </List>
            )
            : <p>{formatMessage({ id: `activity.actions.benefits.no.${this.props.type}` })}</p>
        }
      </div>
    );
  }
}

export default injectIntl(Benefits);
