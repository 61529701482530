import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ToggleCasesMode from './cases/ToggleCasesMode';
import CasesList from './cases/CasesList';
import ActivityActions from './actions/activityActions';
import * as activityConstants from '../activity.constants';
import ScheduleConditionWrapper from './conditions/globalConditions/scheduleConditions/ScheduleConditionWrapper';
import ToggleABTesting from './cases/ToggleABTesting';
import * as selectors from '../activity.selectors';


class ActionsContainer extends React.PureComponent {

  static propTypes = {
    actionsWrappers: PropTypes.object,
    actionsPopupContent: PropTypes.object,
    activityTrigger: PropTypes.string,
    activityType: PropTypes.string,
    addBundleLine: PropTypes.func,
    addPointsOnChangeType: PropTypes.func,
    allowOccurrences: PropTypes.bool,
    cases: PropTypes.object,
    casesActions: PropTypes.object,
    casesMode: PropTypes.bool.isRequired,
    getBusinessBundlePlan: PropTypes.func.isRequired,
    changeActivityActionMode: PropTypes.func,
    conditionsPerTrigger: PropTypes.object,
    createGiftFromActivity: PropTypes.func,
    createEmailFromActivity: PropTypes.func,
    createTempApplyOnModel: PropTypes.func,
    dateTimeConditions: PropTypes.object,
    deleteAction: PropTypes.func,
    deleteBundleLine: PropTypes.func,
    features: PropTypes.object,
    hideAdvancedDealActions: PropTypes.bool,
    intl: PropTypes.object,
    isSingleBundleLine: PropTypes.bool,
    membershipConditions: PropTypes.object,
    mentionsList: PropTypes.object,
    onBundleLineFieldUpdate: PropTypes.func,
    onUpdateBundle: PropTypes.func,
    saveApplyOnData: PropTypes.func,
    trigger: PropTypes.string,
    updateActionField: PropTypes.func,
    updateActionType: PropTypes.func,
    validationErrors: PropTypes.object,
    abTestMode: PropTypes.bool,
    deleteActionsWrapper: PropTypes.func,
    isABTestingAllowed: PropTypes.bool,
    submitActionToCustomer: PropTypes.func,
    hasFullPermission: PropTypes.func,
    activityViewStatus: PropTypes.string,
    isActivityDisabled: PropTypes.bool,
    showSplit: PropTypes.bool,
    showBasicPlan: PropTypes.bool,
    isSimpleMode: PropTypes.bool,
  };


  get isThisOneTimeActivity() {
    return this.props.activityType === activityConstants.ACTIVITY_TYPE_ONE_TIME;
  }

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const toggleCasesDisabled = (this.props.activityType === activityConstants.ACTIVITY_TYPE_RULE && !this.props.activityTrigger) || this.props.isSimpleMode;
    const isOneTimeActivityDisabled = this.props.activityType === activityConstants.ACTIVITY_TYPE_ONE_TIME && this.props.isActivityDisabled;
    const showSplitLink = (this.props.activityType !== activityConstants.ACTIVITY_TYPE_PROMO_CODE && this.props.activityType !== activityConstants.BENEFIT_GIFT) || this.props.showSplit;
    return (
      <Grid className={'actions-container'}>
        {(!this.props.showBasicPlan && !isOneTimeActivityDisabled) && <Grid.Row>
          <Grid.Column width={8} floated="right">
            <div className="split-cases-wrap" style={{ zIndex: showSplitLink ? '1' : '0' }}>
              <ToggleCasesMode
                automationIdPrefix="activity"
                cancelSplit={this.props.casesActions.cancelSplit}
                casesMode={this.props.casesMode}
                splitToCases={this.props.casesActions.splitToCases}
                disabled={toggleCasesDisabled}
                abTestMode={this.props.abTestMode}
                tooltipMessage={this.props.isSimpleMode ? formatMessage({ id: 'activity.action.splitToCasesOnSimpleMode.tooltip' }) : undefined}
              />
              {
                (this.props.isABTestingAllowed || this.isThisOneTimeActivity) &&
                <ToggleABTesting
                  automationIdPrefix="activity"
                  disabled={!this.props.isABTestingAllowed}
                  abTestMode={this.props.abTestMode}
                  casedMode={this.props.casesMode}
                />
              }
            </div>
          </Grid.Column>
        </Grid.Row>}
        <Grid.Row className={`${this.props.activityType}-actions-header`}>
          <Grid.Column>
            <Header className={this.props.activityType.toLowerCase()}>{formatMessage({ id: `${this.props.activityType}.actions.title` })}</Header>
          </Grid.Column>
        </Grid.Row>
        {
          (!this.props.showBasicPlan && this.props.activityType === activityConstants.ACTIVITY_TYPE_ONE_TIME) && (

            <Grid.Row className="scheduling-actions">
              <Grid.Column className="condition-left-column" width={4}>
                <p>{formatMessage({ id: 'action.scheduling.apply.label' })}</p>
              </Grid.Column>
              <Grid.Column width={12}>
                <div>
                  <ScheduleConditionWrapper activityViewStatus={this.props.activityViewStatus} />
                </div>
              </Grid.Column>
            </Grid.Row>
          )
        }

        <Grid.Row className="actions-section">
          <Grid.Column width={16}>
            {
                this.props.casesMode
                  ? (
                    <CasesList
                      actionsPopupContent={this.props.actionsPopupContent}
                      activityTrigger={this.props.activityTrigger}
                      activityType={this.props.activityType}
                      allowOccurrences={this.props.allowOccurrences}
                      cases={this.props.cases}
                      casesActions={this.props.casesActions}
                      conditionsPerTrigger={this.props.conditionsPerTrigger}
                      dateTimeConditions={this.props.dateTimeConditions}
                      features={this.props.features}
                      hideAdvancedDealActions={this.props.hideAdvancedDealActions}
                      isSingleBundleLine={this.props.isSingleBundleLine}
                      membershipConditions={this.props.membershipConditions}
                      mentionsList={this.props.mentionsList}
                      trigger={this.props.trigger}
                      validationErrors={this.props.validationErrors
                        ? this.props.validationErrors.get(activityConstants.CASES)
                        : null
                      }
                      abTestMode={this.props.abTestMode}
                      isOneTimeActivityDisabled={isOneTimeActivityDisabled}
                      getBusinessBundlePlan={this.props.getBusinessBundlePlan}
                      hasFullPermission={this.props.hasFullPermission}
                    />
                  )
                  : <ActivityActions
                    hasFullPermission={this.props.hasFullPermission}
                    actionsWrappers={this.props.actionsWrappers}
                    actionsPopupContent={this.props.actionsPopupContent}
                    activityTrigger={this.props.activityTrigger}
                    activityType={this.props.activityType}
                    addBundleLine={this.props.addBundleLine}
                    addPointsOnChangeType={this.props.addPointsOnChangeType}
                    allowOccurrences={this.props.allowOccurrences}
                    automationIdPrefix="activity"
                    changeActivityActionMode={this.props.changeActivityActionMode}
                    createGiftFromActivity={this.props.createGiftFromActivity}
                    createEmailFromActivity={this.props.createEmailFromActivity}
                    createTempApplyOnModel={this.props.createTempApplyOnModel}
                    deleteAction={this.props.deleteAction}
                    deleteBundleLine={this.props.deleteBundleLine}
                    features={this.props.features}
                    hideAdvancedDealActions={this.props.hideAdvancedDealActions}
                    isSingleBundleLine={this.props.isSingleBundleLine}
                    mentionsList={this.props.mentionsList}
                    onBundleLineFieldUpdate={this.props.onBundleLineFieldUpdate}
                    onUpdateBundle={this.props.onUpdateBundle}
                    saveApplyOnData={this.props.saveApplyOnData}
                    uniqueId="no.cases.actions"
                    updateActionField={this.props.updateActionField}
                    updateActionType={this.props.updateActionType}
                    validationErrors={this.props.validationErrors
                      ? this.props.validationErrors.get(activityConstants.ACTIONS)
                      : null
                    }
                    deleteActionsWrapper={this.props.deleteActionsWrapper}
                    submitActionToCustomer={this.props.submitActionToCustomer}
                    isOneTimeActivityDisabled={isOneTimeActivityDisabled}
                    getBusinessBundlePlan={this.props.getBusinessBundlePlan}
                  />
              }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}


const mapStateToProps = (state) => ({
  isActivityDisabled: selectors.isActivityDisabled(state),
  isSimpleMode: selectors.getActivitySimpleMode(state),
});

const withConnect = connect(mapStateToProps);

export default compose(
  withConnect,
  injectIntl,
)(ActionsContainer);
