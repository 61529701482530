/**
 *
 * Range
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import InputRange from 'react-input-range';
import './rangeInputField.styles.less';
import * as numbersFormatUtils from "../../../../utils/numbersFormat";


class RangeInputField extends React.PureComponent {

  static propTypes = {
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    value: PropTypes.number,
    onChange: PropTypes.func,
    step: PropTypes.number,
    name: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange = (e, data) => {
    const returnData = data;
    returnData.value = String(data.value);
    this.props.onChange(e, returnData);
  };

  render() {
    return (
      <InputRange
        maxValue={this.props.maxValue}
        minValue={this.props.minValue}
        value={this.props.value}
        onChange={this.props.onChange}
        name={this.props.name}
        step={this.props.step}
        formatLabel={(value) => numbersFormatUtils.displayNumberWithCommas(value)}
      />
    );
  }

}

export default RangeInputField;
