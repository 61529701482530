import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';


import * as activityConstants from '../../Activity/activity.constants';
import tracker from '../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../common/utils/tracking/tracking.consts';
import ConfirmationDialog from '../../../common/components/ConfirmationDialog/ConfirmationDialog';


class MicroCampaignSingleAssetSetHeader extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string,
    assetSetIndex: PropTypes.number,
    assetSetsCount: PropTypes.number,
    intl: PropTypes.object,
    assetSet: PropTypes.object.isRequired,
    deleteAssetSet: PropTypes.func,
    clearAssetSetData: PropTypes.func,
    selectedBenefit: PropTypes.string,
    index: PropTypes.number,
    toggleAssetSetDisplay: PropTypes.func,
    hasValidationErrors: PropTypes.bool
  };

  toggleAssetSetDisplay = () => {
    this.props.toggleAssetSetDisplay(!this.isCollapsed, this.props.assetSetIndex);
  };

  deleteAssetSet = () => {
    this.props.deleteAssetSet(this.props.assetSetIndex);
    tracker.onButtonClick(trackerConstants.BUTTON_TYPE_MICRO_CAMPAIGN_CASE_DELETE);
  };

  clearAssetSetData = () => {
    this.props.clearAssetSetData(this.props.assetSetIndex);
    tracker.onButtonClick(trackerConstants.BUTTON_TYPE_MICRO_CAMPAIGN_CASE_CLEAR);
  };

  get isCollapsed() {
    return this.props.assetSet.has(activityConstants.IS_COLLAPSED) ? this.props.assetSet.get(activityConstants.IS_COLLAPSED) : this.props.index !== 0 && !this.props.hasValidationErrors;
  }

  render() {
    const isCollapsed = this.isCollapsed;
    const { formatMessage } = this.props.intl;
    const expandCollapseIconClass = classNames('expand-collapse-toggle', {
      'como-ic-angle-right': isCollapsed,
      'como-ic-angle-down': !isCollapsed
    });
    const headerClassName = classNames('case-header', { 'collapsed': isCollapsed });
    const expandCollapseTooltip = isCollapsed ?
      formatMessage({ id: 'activity.cases.expand.tooltip' }) :
      formatMessage({ id: 'activity.cases.collapse.tooltip' });
    return (
      <Grid className={headerClassName}>
        <Grid.Row>
          <Grid.Column width={1}>
            <Icon
              className={expandCollapseIconClass}
              onClick={this.toggleAssetSetDisplay}
              size="big"
              data-automation-id={`${this.props.automationIdPrefix}.expand-collapse-handler`}
              title={expandCollapseTooltip}
            />
          </Grid.Column>
          <Grid.Column width={15}>
            <h1 className="case-title micro-campaign-case-title" data-automation-id={`${this.props.automationIdPrefix}.title`} onDoubleClick={this.toggleAssetSetDisplay}>
              {formatMessage({ id: 'activity.benefits.caseHeader' }, {
                caseIndex: this.props.assetSetIndex + 1,
                casesCount: this.props.assetSetsCount
              })}
            </h1>


            {
              isCollapsed &&
              <span>{this.props.selectedBenefit}</span>
            }

            <div className="case-controlls">
              {
                this.props.assetSetsCount > 1 &&
                <ConfirmationDialog
                  automationIdPrefix={`${this.props.automationIdPrefix}.delete-case`}
                  confirmButtonKey="general.button.delete"
                  tooltipMessageKey="activity.cases.delete.tooltip"
                  contentMessageKey="activity.microCampaign.cases.delete.confirmationMessage"
                  confirmAction={this.deleteAssetSet}
                  className="clear case"
                  icon="como-ic-delete "
                />
              }
              {
                !isCollapsed &&
                <ConfirmationDialog
                  automationIdPrefix={`${this.props.automationIdPrefix}.clear-case`}
                  confirmButtonKey="general.button.clear"
                  tooltipMessageKey="activity.cases.clear.tooltip"
                  contentMessageKey="activity.microCampaign.cases.clear.confirmationMessage"
                  confirmAction={this.clearAssetSetData}
                  className="erase-case"
                  icon="como-ic-erase"
                />
              }
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default injectIntl(MicroCampaignSingleAssetSetHeader);
