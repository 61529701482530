// Authorization HOC

import { fromJS, Map } from 'immutable';
import { connect } from 'react-redux';
import _ from 'lodash';
import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import hoistNonReactStatics from 'hoist-non-react-statics';
import * as selectors from '../../features/App/selectors';
import * as constants from '../../features/Activity/activity.constants';
import * as appConstants from '../../features/App/app.constants';

const withAuthorization = (WrappedComponent) => {
  class Authorization extends Component {
    static propTypes = {
      features: PropTypes.object,
      businessBundlePlan: PropTypes.string,
      isComoUser: PropTypes.bool,
      isDemo: PropTypes.bool
    };

    static displayName = `withAuthorization(${(WrappedComponent.displayName || WrappedComponent.name || 'Component')})`;

    static getPermissionLevelHelper = (featureName, features) => {
      const feature = features.find((f) => f.get(constants.FEATURE_NAME) === featureName);
      if (!feature) {
        console.error('undefined featureName', featureName);
        return constants.FEATURE_LEVEL.NONE;
      }
      return feature.get(constants.FEATURE_LEVEL_PATH);
    };

    static getPermissionLevelAndAddOnHelper = (featureName, features) => {
      const feature = features.find((f) => f.get(constants.FEATURE_NAME) === featureName);

      if (!feature) {
        console.error('undefined featureName', featureName);
        return { level: constants.FEATURE_LEVEL.NONE, isAddOn: false };
      }

      return { level: feature.get(constants.FEATURE_LEVEL_PATH), isAddOn: feature.get(constants.FEATURE_IS_ADD_ON) };
    };

    static isFeatureAddOnHelper = (featureName, features) => {
      const feature = features.find((f) => f.get(constants.FEATURE_NAME) === featureName);

      if (!feature) {
        console.error('undefined featureName', featureName);
        return false;
      }
      return feature.get(constants.FEATURE_IS_ADD_ON);
    };

    static hasFullPermissionHelper = (featureName, features) =>
      Authorization.getPermissionLevelHelper(featureName, features) === constants.FEATURE_LEVEL.FULL;

    static getRestrictions = (requiredFeatures, availableFeatures, bundlePlan) => {
      if (!requiredFeatures) {
        return null;
      }
      let hiddenFeatures = null;
      const restrictingPosFeatures = {};
      const restrictingAddOnFeatures = {};
      requiredFeatures.forEach((feature) => {
        const featureName = Map.isMap(feature) ? feature.get(constants.FEATURE_NAME) : feature[constants.FEATURE_NAME];
        const { level, isAddOn } = Authorization.getPermissionLevelAndAddOnHelper(featureName, availableFeatures);
        if (featureName === appConstants.FEATURE_NAME_BASIC_PLAN_EXCLUDE) {
          if (level === constants.FEATURE_LEVEL.FULL) {
            if (!hiddenFeatures) {
              hiddenFeatures = {};
            }
            hiddenFeatures[featureName] = true;
          } else {
            return null;
          }
        }
        if (bundlePlan && bundlePlan === 'revel' && featureName === appConstants.FEATURE_NAME_ESSENTIALS_OFF && level === constants.FEATURE_LEVEL.NONE) {
          if (!hiddenFeatures) {
            hiddenFeatures = {};
          }
          hiddenFeatures[featureName] = true;
        } else {
          if (isAddOn) {
            restrictingAddOnFeatures[feature] = level;
          } else {
            restrictingPosFeatures[feature] = level;
          }
        }
      });
      return fromJS({
        [constants.RESTRICTING_POS_FEATURES]: restrictingPosFeatures,
        [constants.RESTRICTED_BY_POS_FEATURES]: Object.keys(restrictingPosFeatures)
            .some((feature) => restrictingPosFeatures[feature] === constants.FEATURE_LEVEL.NONE),
        [constants.RESTRICTING_ADD_ON_FEATURES]: restrictingAddOnFeatures,
        [constants.RESTRICTED_BY_ADD_ON_FEATURES]: Object.keys(restrictingAddOnFeatures)
          .some((feature) => restrictingAddOnFeatures[feature] === constants.FEATURE_LEVEL.NONE),
        [constants.HIDE_BY_BUSINESS_PLAN]: hiddenFeatures
      });
    };

    getPermissionLevel = (featureName) => Authorization.getPermissionLevelHelper(featureName, this.props.features);
    getBusinessBundlePlan = () => this.props.businessBundlePlan;
    getPermissionLevelAndAddOn = (featureName) => Authorization.getPermissionLevelAndAddOnHelper(featureName, this.props.features);
    getRestrictions = (featureNames, bundlePlan) => Authorization.getRestrictions(featureNames, this.props.features, bundlePlan);
    isFeatureAddOn = (featureName) => Authorization.isFeatureAddOnHelper(featureName, this.props.features);
    hasFullPermission = (featureName) => Authorization.hasFullPermissionHelper(featureName, this.props.features);

    render() {
      if (_.isNil(this.props.features)) { // TODO: Check for empty array
        return null;
      }

      return (
        <WrappedComponent
          features={this.props.features}
          getPermissionLevel={this.getPermissionLevel}
          getPermissionLevelAndAddOn={this.getPermissionLevelAndAddOn}
          getBusinessBundlePlan={this.getBusinessBundlePlan}
          isComoUser={this.props.isComoUser}
          isDemo={this.props.isDemo}
          getRestrictions={this.getRestrictions}
          isFeatureAddOn={this.isFeatureAddOn}
          hasFullPermission={this.hasFullPermission}
          showBasicPlan={!this.props.isComoUser && this.props.businessBundlePlan === appConstants.TRIAL}
          {...this.props}
        />
      );
    }
  }

  return hoistNonReactStatics(Authorization, WrappedComponent);
};


const mapStateToProps = (state) => ({
  features: selectors.getFeatures(state),
  businessBundlePlan: selectors.getBusinessBundlePlan(state),
  isComoUser: selectors.getIsComoUser(state),
  isDemo: selectors.getIsDemoUser(state)
});


export default compose(connect(mapStateToProps), withAuthorization);

