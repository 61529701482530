import * as constants from './email.constants';

export const getEmail = (state) => state.get(constants.EMAIL);
export const getEmailEditorToken = (state) => state.getIn([constants.EMAIL, constants.EMAIL_EDITOR_TOKEN]);
export const getEmailEditorUid = (state) => state.getIn([constants.EMAIL, constants.EMAIL_EDITOR_UID]);
export const getEmailValidationErrors = (state) => state.getIn([constants.EMAIL, constants.EMAIL_VALIDATION_ERRORS]);
export const getCheckingEMailNameAvailabilityFlag = (state) => state.getIn([constants.EMAIL, constants.CHECKING_EMAIL_NAME_AVAILABILITY]);
export const isDirty = (state) => state.getIn([constants.EMAIL, constants.IS_DIRTY]);
export const getPublishInProgressFlag = (state) => state.getIn([constants.EMAIL, constants.PUBLISH_IN_PROGRESS]);
export const getEmailDataModel = (state) => state.getIn([constants.EMAIL, constants.EMAIL_DATA]);
export const getEmailTemplate = (state) => state.getIn([constants.EMAIL, constants.TEMPLATE]);
