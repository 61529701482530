import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { CloseLink, Header } from './views';
import SimpleCard from '../../common/components/Cards/EmptyCard/EmptyCard';

import './goals.styles.less';
import * as goalsActions from './goals.actions';
import injectSaga from '../../utils/injectSaga';
import saga from './goals.saga';
import * as constants from './goals.constants';
import * as constantsRoute from '../../constants/route.contants';
import tracker from '../../common/utils/tracking/tracker';
import * as trackerConstants from '../../common/utils/tracking/tracking.consts';
import { APP, BUSINESS, BUSINESS_DISPLAY_NAME } from '../App/app.constants';

const AUTOMATION_NAME = 'goals';

class Goals extends Component {
  static propTypes = {
    goals: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    businessDisplayName: PropTypes.string.isRequired
  };

  componentWillMount() {
    tracker.onPageLoaded(trackerConstants.PAGE_TYPE_GOALS);
    this.props.actions.getGoals();
  }

  shouldComponentUpdate(nextProps) {
    return this.props.goals !== nextProps.goals;
  }

  render() {
    const {formatMessage} = this.props.intl; //eslint-disable-line
    const { goals, businessDisplayName } = this.props;

    return (
      <div className="goals">
        <Helmet>
          <title>{formatMessage({ id: 'goals.helmet.title' })} - {businessDisplayName}</title>
        </Helmet>
        <Header>
          <div className="title">
            <Icon className="como-ic-magic-wand" />
            <p>{formatMessage({ id: 'goals.header.title' })}</p>
          </div>
          <CloseLink onClick={() => tracker.onButtonClick(trackerConstants.BUTTON_TYPE_CLOSE_GOALS_WIZARD)} />
        </Header>
        <main>
          {goals.map((goal, index) =>
            (
              <Link
                key={index.toString()}
                to={{ pathname: `${constantsRoute.GOALS_ROUTE}/${goal.get(constants.GOAL_DB_ID)}`, search: location.search }}
                data-automation-id={`${AUTOMATION_NAME}.link.to.goal`}
                onClick={() => tracker.onButtonClick(trackerConstants.BUTTON_TYPE_SELECT_GOAL,
                                                      { [trackerConstants.GOAL_NAME]: goal.get(constants.GOAL_NAME) })}
              >
                <SimpleCard
                  automationId={AUTOMATION_NAME}
                >
                  <Icon className={`como-svg-icon-goals-${goal.get(constants.GOAL_ICON)}`} />
                  <p>{goal.get(constants.GOAL_NAME)}</p>
                </SimpleCard>
              </Link>
            )
          )}
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  goals: state.getIn([constants.ACTIVITY_GOALS_BRANCH, constants.DATA]),
  businessDisplayName: state.getIn([APP, BUSINESS, BUSINESS_DISPLAY_NAME])
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(goalsActions, dispatch)
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: constants.SAGA_KEY_GOALS, saga });

export default compose(
  withConnect,
  withSaga,
  injectIntl
)(Goals);
