import { fromJS } from 'immutable';
import * as constants from './genericAction.contants';

const initialState = fromJS({
  [constants.CUSTOM_APP_SCREENS]: [],
  [constants.CUSTOM_WEB_VIEWS]: []
});

function genericActionReducer(state = initialState, action) {
  switch (action.type) {
    case constants.GET_CUSTOM_APP_SCREENS_DATA_SUCCESS: {
      return state.set(constants.CUSTOM_APP_SCREENS, fromJS(action.data));
    }
    case constants.GET_CUSTOM_WEB_VIEWS_DATA_SUCCESS: {
      return state.set(constants.CUSTOM_WEB_VIEWS, fromJS(action.data));
    }
    case constants.GET_CATALOGS_SUCCESS: {
      return state.set(constants.CATALOGS, fromJS(action.data));
    }
    default:
      return state;
  }
}

export default genericActionReducer;
