import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Grid, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import * as schemaConstants from '../../../../activitySchema/activitySchema.constants';
import './walletPass.styles.less';
import CustomHeader from '../exportEvent/customHeader';
import CustomFieldWithMention from '../exportEvent/customFieldWithMention';


class WalletPass extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      advancedModeOpen: props.action && props.action.get(schemaConstants.EXPORT_EVENT_CUSTOM_BODY_FIELDS) && props.action.get(schemaConstants.EXPORT_EVENT_CUSTOM_BODY_FIELDS).size > 0 && props.action.getIn([schemaConstants.EXPORT_EVENT_CUSTOM_BODY_FIELDS, 0, schemaConstants.EXPORT_EVENT_BODY_KEY_FIELD]) !== '',
    };
  }
  // eslint-disable-next-line no-undef
  static propTypes = {
    automationIdPrefix: PropTypes.string,
    onActionFieldUpdate: PropTypes.func.isRequired,
    withMessage: PropTypes.bool,
    action: PropTypes.object,
    index: PropTypes.number,
    validationErrors: PropTypes.object,
    mentionsList: PropTypes.object,
  };

  onCustomHeadersUpdate(name, value, customHeadersIndex, actionIndex) {
    const immutableCustomHeaders = this.props.action.get(schemaConstants.EXPORT_EVENT_CUSTOM_HEADERS);
    const customHeaders = immutableCustomHeaders ? immutableCustomHeaders.toJSON() : [];
    customHeaders[customHeadersIndex] = { ...customHeaders[customHeadersIndex], [name]: value.trim() };
    this.props.onActionFieldUpdate(schemaConstants.EXPORT_EVENT_CUSTOM_HEADERS, customHeaders, actionIndex);
  }
  onCustomBodyFieldUpdate(name, value, customBodyFieldsIndex, actionIndex) {
    const immutableCustomBodyFields = this.props.action.get(schemaConstants.EXPORT_EVENT_CUSTOM_BODY_FIELDS);
    const customBodyFields = immutableCustomBodyFields ? immutableCustomBodyFields.toJSON() : [];
    customBodyFields[customBodyFieldsIndex] = { ...customBodyFields[customBodyFieldsIndex], [name]: value.trim() };
    this.props.onActionFieldUpdate(schemaConstants.EXPORT_EVENT_CUSTOM_BODY_FIELDS, customBodyFields, actionIndex);
  }

  renderCustomHeadersFields(numOfCustomHeaders, hideKey = false, overrideValueLabel = undefined) {
    const { index, action, validationErrors, automationIdPrefix, mentionsList } = this.props;
    const headersFields = [];
    for (let i = 0; i < numOfCustomHeaders; i++) {
      const customHeader = action.getIn([schemaConstants.EXPORT_EVENT_CUSTOM_HEADERS, i]);
      headersFields.push(<CustomHeader
        key={i}
        validationErrors={validationErrors}
        customHeader={customHeader}
        automationIdPrefix={automationIdPrefix}
        index={i}
        hideKey={hideKey}
        mentionsList={mentionsList}
        onActionFieldUpdate={(customHeadersIndex, key, value) => this.onCustomHeadersUpdate(key, value, customHeadersIndex, index)}
        valueLabel={overrideValueLabel}
      />);
    }
    return <>{headersFields}</>;
  }

  renderCustomBodyFields(numOfCustomBodyFields, hideKey = false, overrideValueLabel = undefined) {
    const { index, action, validationErrors, automationIdPrefix, mentionsList } = this.props;
    const bodyFields = [];
    for (let i = 0; i < numOfCustomBodyFields; i++) {
      const customBodyField = action.getIn([schemaConstants.EXPORT_EVENT_CUSTOM_BODY_FIELDS, i]);
      bodyFields.push(<CustomFieldWithMention
        key={i}
        validationErrors={validationErrors}
        customBodyField={customBodyField}
        automationIdPrefix={automationIdPrefix}
        hideKey={hideKey}
        index={i}
        onActionFieldUpdate={(customBodyFieldIndex, key, value) => this.onCustomBodyFieldUpdate(key, value, customBodyFieldIndex, index)}
        mentionsList={mentionsList}
        valueLabel={overrideValueLabel}
      />);
    }
    return <div className={'body-fields-wrapper'}>{bodyFields}</div>;
  }
  render() {
    const { action, withMessage } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    return (
      <Grid className="action export-event-action">
        {this.renderCustomHeadersFields(action.get(schemaConstants.EXPORT_EVENT_CUSTOM_HEADERS) ? action.get(schemaConstants.EXPORT_EVENT_CUSTOM_HEADERS).size  : 0)}
        <div style={{ boxShadow: 'inset 0 2px 9px 0 rgb(0 0 0 / 20%)', backgroundColor: 'white', width: '100%' }} >
          {withMessage && this.renderCustomBodyFields(1, true, formatMessage({ id: 'activity.action.walletPass.notificationMessage.value' }))}
          {this.renderCustomBodyFields(action.get(schemaConstants.EXPORT_EVENT_CUSTOM_BODY_FIELDS) ? action.get(schemaConstants.EXPORT_EVENT_CUSTOM_BODY_FIELDS).size - 1 : 0)}
        </div>
      </Grid>
    );
  }
}

export default injectIntl(WalletPass);
