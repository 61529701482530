import axios from 'axios';

export function getMailTemplates() {
  return axios.get('/api/mailTemplates')
    .then((res) => res.data)
    .catch((err) => err.message);
}

export function getMailSenderName() {
  return axios.get('/api/mailTemplates/settings/senderName')
    .then((res) => res.data);
}
