import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';
import { cloneDeep } from 'lodash';
import './updateMdembership.styles.less';
import * as schemaConstants from '../../../activitySchema/activitySchema.constants';
import * as activityConstants from '../../../activity.constants';
import * as updateMembershipConstants from './updateMembership.constants';
import * as updateMembershipActions from './updateMembership.actions';
import UpdateMembershipPopup from './UpdateMembershipPopup';
import ConditionSummary from '../../summary/ConditionSummary';
import * as selectors from '../../../activity.selectors';

const IS_POPUP_OPEN = 'isPopupOpen';
const IS_PRISTINE = 'isPristine';
const TOGGLE_ANIMATION = 'toggleAnimation';

class UpdateMembershipConditionValue extends React.PureComponent {

  static propTypes = {
    actions: PropTypes.object.isRequired,
    error: PropTypes.object,
    intl: PropTypes.object,
    onChange: PropTypes.func,
    prefix: PropTypes.string,
    conditionSchema: PropTypes.object,
    tempErrors: PropTypes.object,
    tempValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array, PropTypes.number]),
    conditionsPerTrigger: PropTypes.object
  };

  state = {
    [updateMembershipConstants.UPDATE_MEMBERSHIP_FROM]: fromJS({
      [IS_POPUP_OPEN]: false,
      [IS_PRISTINE]: true,
      [TOGGLE_ANIMATION]: false,
    }),
    [updateMembershipConstants.UPDATE_MEMBERSHIP_TO]: fromJS({
      [IS_POPUP_OPEN]: false,
      [IS_PRISTINE]: true,
      [TOGGLE_ANIMATION]: false,
    }),
  };

  componentWillMount() {
    this.props.actions.copyDataToTemp(this.props.value, this.props.conditionSchema);
  }

  onApply = (node) => {
    this.props.onChange(null, { name: activityConstants.CONDITION_VALUE, value: this.props.tempValue.toJS() });
    this.onPopupClose(node);
  };

  onCancel = (node) => {
    this.onPopupClose(node);
  };

  onUpdate = (node, key, value) => {
    this.setAsDirty(node);
    this.props.actions.updateTempData(node, key, value);
  };

  onPopupClose = (node, e) => {
    if (!e || this.state.getIn([node, IS_PRISTINE])) {
      this.setBackgroundClicks(true);
      this.setState({
        ...this.state,
        [node]: this.state[node].set(IS_POPUP_OPEN, false).set(IS_PRISTINE, true)
      });
    } else if (!e.target.classList.contains('delete')) {
      this.setState({
        ...this.state,
        [node]: this.state[node].set(TOGGLE_ANIMATION, !this.state[node].get(TOGGLE_ANIMATION))
      });
    }

  };

  /**
   * open to/from condition value popup
   * (only if the other one is closed)
   * @param {string} node
   */
  onPopupOpen = (node) => {
    this.props.actions.copyDataToTemp(this.props.value, this.props.conditionSchema);
    this.setState({
      ...this.state,
      [node]: this.state[node].set(IS_POPUP_OPEN, true)
    });
    this.setBackgroundClicks(false);
  };

  setAsDirty = (node) => {
    this.setState({
      ...this.state,
      [node]: this.state[node].set(IS_PRISTINE, false)
    });
  };

  /**
   * disable background clicks when items population popup is open,
   * return to normal when it's closed
   * @param enable
   */
  setBackgroundClicks = (enable) => {
    // todo: property on popupWrapper
    if (enable) {
      document.getElementById('app').classList.remove('disable-pointer-events');
    } else {
      document.getElementById('app').classList.add('disable-pointer-events');
    }
  };

  getConditionForValueFormatting = (condition) => {
    const newCondition = cloneDeep(condition);
    newCondition.conditionKey = newCondition.conditionKey
      .replace('[0]', '')
      .replace('.OldValue', '')
      .replace('.NewValue', '');
    return fromJS(newCondition);
  };

  getPopupTriggerText = (node) => {
    if (
      [schemaConstants.OPERATORS_IS_ANY_VALUE, schemaConstants.OPERATORS_IS_EMPTY].includes(this.props.value[node][activityConstants.OPERATOR_KEY]) ||
      this.props.conditionSchema.get(activityConstants.SCHEMA_CONDITION_VALUE_DATA_TYPE) === schemaConstants.DATA_VALUE_TYPE_INTEGER
    ) {
      const operator = this.props.value[node].operatorKey;
      return [schemaConstants.OPERATORS_IS_ANY_VALUE, schemaConstants.OPERATORS_IS_EMPTY].includes(operator)
        ? this.props.intl.formatMessage({ id: `operator.memberFieldChanged.${operator}` })
        : this.props.intl.formatMessage({ id: 'operator.memberFieldChanged.specificValue' });
    }
    return (
      <ConditionSummary
        condition={this.getConditionForValueFormatting(this.props.value[node])}
        conditionsFromSchema={this.props.conditionsPerTrigger}
        conditionValueOnly
      />
    );
  };

  render() {

    const operators = this.props.conditionSchema.get(activityConstants.SCHEMA_CONDITION_OPERATORS);
    const nodeFrom = updateMembershipConstants.UPDATE_MEMBERSHIP_FROM;
    const nodeTo = updateMembershipConstants.UPDATE_MEMBERSHIP_TO;

    return (
      this.props.tempValue
        ? (
          <div className={'update-membership-condition-value'}>
            <span>{this.props.intl.formatMessage({ id: 'activity.updateMembershipCondition.changedFrom' })}</span>
            <UpdateMembershipPopup
              data={this.props.tempValue.get(nodeFrom)}
              error={this.props.error && this.props.error.get(nodeFrom) ? this.props.error.get(nodeFrom) : null}
              isPopupOpen={this.state[nodeFrom].get(IS_POPUP_OPEN)}
              onApply={() => this.onApply(nodeFrom)}
              onCancel={() => this.onCancel(nodeFrom)}
              onPopupClose={(e) => this.onPopupClose(nodeFrom, e)}
              onPopupOpen={() => this.onPopupOpen(nodeFrom)}
              onUpdate={(key, value) => this.onUpdate(nodeFrom, key, value)}
              popupTrigger={this.getPopupTriggerText(nodeFrom)}
              prefix={'updateMembershipPopup'}
              conditionSchema={this.props.conditionSchema}
              tempError={this.props.tempErrors && this.props.tempErrors.get(nodeFrom) ? this.props.tempErrors.get(nodeFrom) : null}
              toggleAnimation={this.state[nodeFrom].get(TOGGLE_ANIMATION)}
              triggerAutomationId={`${this.props.prefix}.updateMembershipPopup.${nodeFrom}`}
            />
            <span>{this.props.intl.formatMessage({ id: 'activity.updateMembershipCondition.changedTo' })}</span>
            <UpdateMembershipPopup
              data={this.props.tempValue.get(nodeTo)}
              error={this.props.error && this.props.error.get(nodeTo) ? this.props.error.get(nodeTo) : null}
              isPopupOpen={this.state[nodeTo].get(IS_POPUP_OPEN)}
              onApply={() => this.onApply(nodeTo)}
              onCancel={() => this.onCancel(nodeTo)}
              onPopupClose={(e) => this.onPopupClose(nodeTo, e)}
              onPopupOpen={() => this.onPopupOpen(nodeTo)}
              onUpdate={(key, value) => this.onUpdate(nodeTo, key, value)}
              operators={operators}
              popupTrigger={this.getPopupTriggerText(nodeTo)}
              prefix={'updateMembershipPopup'}
              conditionSchema={this.props.conditionSchema}
              tempError={this.props.tempErrors && this.props.tempErrors.get(nodeTo) ? this.props.tempErrors.get(nodeTo) : null}
              toggleAnimation={this.state[nodeTo].get(TOGGLE_ANIMATION)}
              triggerAutomationId={`${this.props.prefix}.updateMembershipPopup.${nodeTo}`}
            />
          </div>
        )
        : null
    );
  }
}

const mapStateToProps = (state) => ({
  conditionsPerTrigger: selectors.getConditionsForSelectedTrigger(state),
  tempValue: selectors.getUpdateMembershipTempData(state),
  tempErrors: selectors.getUpdateMembershipTempValidationErrors(state),
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(updateMembershipActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(UpdateMembershipConditionValue);
