export { ACTIVITY_GOALS_BRANCH } from '../../constants/reducer.constants';
export const SAGA_KEY_GOALS = 'goals';

// keys
export const DATA = 'data';
export const GOAL = 'goal';
export const GOAL_DB_ID = 'dbId';
export const GOAL_NAME = 'name';
export const GOAL_ICON = 'icon';
export const GOAL_TEMPLATES_RULES = 'templatesRules';
export const GOAL_TEMPLATES_DEALS = 'templatesDeals';
export const GOAL_TEMPLATES_ONE_TIME_ACTIONS = 'templatesOneTimeActions';
export const GOAL_TOOLS = 'tools';
export const GOAL_TOOL_DESCRIPTION = 'description';
export const GOAL_TOOL_NAME = 'name';
export const GOAL_TOOL_TEMPLATES_DEALS_POINTERS = 'templatesDealsPointers';
export const GOAL_TOOL_TEMPLATES_RULES_POINTERS = 'templatesRulesPointers';
export const GOAL_TOOL_TEMPLATES_ONETIME_POINTERS = 'templatesOneTimeActionsPointers';

export const GOALS_ADMIN = 'admin';
// actions
export const GET_GOALS = 'app/ActivityGoals/GET_GOALS';
export const GET_GOALS_SUCCESS = 'app/ActivityGoals/GET_GOALS_SUCCESS';
export const GET_GOALS_ERROR = 'app/ActivityGoals/GET_GOALS_ERROR';
export const GET_GOAL = 'app/ActivityGoals/Goal/GO_TO_GOAL';
export const GET_GOAL_SUCCESS = 'app/ActivityGoals/Goal/GET_GOAL_SUCCESS';
export const GET_GOAL_ERROR = 'app/ActivityGoals/Goal/GET_GOAL_ERROR';
export const CREATE_BUNDLE = 'app/ActivityGoals/Goal/CREATE_BUNDLE';
export const CREATE_BUNDLE_SUCCESS = 'app/ActivityGoals/Goal/CREATE_BUDLE_SUCCESS';
export const CREATE_BUNDLE_ERROR = 'app/ActivityGoals/Goal/CREATE_BUDLE_ERROR';
export const CLEAR_GOAL = 'app/ActivityGoals/Goal/CLEAR_GOAL';

// formats
export const TIMESTAMP_FORMAT_OF_ACTIVITY_NAME = 'YYYY-MM-DD HH:mm:ss';
