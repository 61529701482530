import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import * as constants from '../../activity.constants';
import DropdownWrapper from '../../../../common/components/DropdownWrapper/DropdownWrapper';
import ConditionKeyListPopup from './conditionKeyListPopup';
import CaseConditionKey from '../cases/CaseConditionKey';

class ConditionKey extends React.Component {

  // eslint-disable-next-line no-undef
  static propTypes = {
    conditionKey: PropTypes.string,
    conditionsList: PropTypes.object,
    automationIdPrefix: PropTypes.string,
    disabled: PropTypes.bool,
    isCaseCondition: PropTypes.bool,
    isFixedConditionKey: PropTypes.bool,
    isInsideModal: PropTypes.bool,
    validationErrors: PropTypes.object,
    index: PropTypes.number,
    intl: PropTypes.object,
    onConditionUpdate: PropTypes.func,
    getBusinessBundlePlan: PropTypes.func.isRequired,
    selectedConditionSchema: PropTypes.object
  };

  transformConditionKeysToDropDownOptions = () => this.props.conditionsList.map((opt) => (
    {
      key: opt.get(constants.KEY),
      text: opt.get(constants.TEXT) || this.props.intl.formatMessage({ id: `condition.${opt.get(constants.KEY)}` }),
      value: opt.get(constants.KEY),
      features: opt.get('features') ? opt.get('features').toJSON() : null,
      isAutoTag: opt.get(constants.IS_AUTO_TAG),
      disabledcondition: opt.get('disabled')
    })).toJS();

  render() {
    const { isCaseCondition, isInsideModal, validationErrors, index,
      automationIdPrefix, conditionsList, intl, conditionKey } = this.props;
    let conditionKeyNormalized = conditionKey;
    if (conditionKey) {
      if (!conditionsList.some((x) => x.get(constants.KEY) === conditionKey)) {
        // Key not found in the conditionsList, replace it with a human-readable error
        conditionKeyNormalized = this.props.intl.formatMessage({ id: 'activity.conditions.key.deleted' }, { attribute: this.props.intl.formatMessage({ id: conditionKey }) });
      }
    }
    if (this.props.isFixedConditionKey) {
      return <div className="fixed-condition-key">{conditionKeyNormalized}</div>;
    }
    if (isInsideModal) {
      return (
        <ConditionKeyListPopup
          disabled={this.props.disabled}
          options={this.transformConditionKeysToDropDownOptions()}
          onSelectOption={this.props.onConditionUpdate}
          index={index}
          value={conditionKeyNormalized}
          name={constants.CONDITION_KEY}
          prefix={`${automationIdPrefix}.${constants.CONDITION_KEY}`}
          error={validationErrors ? validationErrors.get(constants.CONDITION_KEY) : null}
        />
      );
    }
    if (isCaseCondition) {
      return (
        <CaseConditionKey
          automationIdPrefix={`${automationIdPrefix}.${constants.CONDITION_KEY}`}
          conditionKey={conditionKeyNormalized}
          conditionsList={conditionsList}
          index={index}
          onConditionUpdate={this.props.onConditionUpdate}
          selectedConditionSchema={this.props.selectedConditionSchema}
          error={validationErrors ? validationErrors.get(constants.CONDITION_KEY) : null}
          disabled={this.props.disabled}
          getBusinessBundlePlan={this.props.getBusinessBundlePlan}
        />
      );
    }
    return (
      <DropdownWrapper
        disabled={this.props.disabled}
        fluid
        options={this.transformConditionKeysToDropDownOptions()}
        onSelectOption={this.props.onConditionUpdate}
        value={conditionKeyNormalized}
        prefix={`${automationIdPrefix}.${constants.CONDITION_KEY}`}
        name={constants.CONDITION_KEY}
        placeholder={intl.formatMessage({ id: 'activity.conditions.key.placeholder' })}
        index={index}
        error={validationErrors ? validationErrors.get(constants.CONDITION_KEY) : null}
        allowAdditions={false}
        search={false}
      />
    );
  }
}

export default injectIntl(ConditionKey);
