import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { bindActionCreators, compose } from 'redux';
import { Form, Grid, Header, Icon } from 'semantic-ui-react';
import classNames from 'classnames';
import moment from 'moment';

import * as actions from './microCampaign.actions';
import '../Activity/activity.styles.less';
import './microCampaign.styles.less';
import * as mentionsSelectors from '../Activity/activitySchema/mentions.selectos';
import * as constants from './microCampaign.constants';
import EntityHeader from '../../common/components/EntityHeader/EntityHeader';
import * as selectors from './microCampaign.selectors';
import injectSaga from '../../utils/injectSaga';
import saga from './microCampaign.saga';
import * as appSelectors from '../App/selectors';
import * as featuresConstants from '../features.constants';
import * as reducerConstants from '../../constants/reducer.constants';
import mailTemplatesSaga from '../MailTemplates/mailTemplates.saga';
import * as activityConstants from '../Activity/activity.constants';
import * as activitySelectors from '../Activity/activity.selectors';
import * as activityActions from '../Activity/activity.actions';
import businessSaga from '../BusinessCenter/businessCenter.saga';
import benefitsSaga from '../Benefits/benefits.saga';
import * as schemaConstants from '../Activity/activitySchema/activitySchema.constants';
import RangeInputField from '../../common/components/FormInputFields/RangeInputField/RangeInputField';
import * as formsActions from '../Forms/forms.actions';
import * as formsSelectors from '../Forms/forms.selectors';
import formsSaga from '../Forms/forms.saga';
import * as emailConstants from '../MailTemplates/mailTemplates.constants';
import * as emailUtils from '../MailTemplates/mailTemplates.utils';
import tracker from '../../common/utils/tracking/tracker';
import * as trackerConstants from '../../common/utils/tracking/tracking.consts';
import microCampaignsSaga from '../MicroCampaigns/microCampaigns.saga';
import * as benefitSelectors from '../Benefits/benefits.selector';
import * as benefitsActions from '../Benefits/benefits.actions';
import * as benefitsConstants from '../Benefits/benefits.constants';
import MicroCampaignSingleAssetSet from './views/microCampaignSingleAssetSet';
import withAuthorization from '../../common/helpers/authorization';


export class MicroCampaign extends React.PureComponent {

  // eslint-disable-next-line no-undef
  static propTypes = {
    mentions: PropTypes.object,
    microCampaign: PropTypes.object,
    validationErrors: PropTypes.object,
    actions: PropTypes.object,
    match: PropTypes.object.isRequired,
    checkingMicroCampaignNameAvailability: PropTypes.bool,
    isDirty: PropTypes.bool,
    publishInProgress: PropTypes.bool,
    schemaActionsList: PropTypes.object,
    registrationFormFields: PropTypes.object,
    formsActions: PropTypes.object,
    emailsList: PropTypes.object,
    saveAsDraftInProgress: PropTypes.bool,
    locationId: PropTypes.number,
    benefitsList: PropTypes.object,
    benefitsActions: PropTypes.object,
    rootState: PropTypes.object,
    activityActions: PropTypes.object,
    getBusinessBundlePlan: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isHeaderShadowed: false,
      loading: true
    };
    this.updateCommunicationActionField = this.updateCommunicationActionField.bind(this);
  }

  componentWillMount() {

    if (this.props.match.params.id) {
      this.props.actions.getMicroCampaign(this.props.match.params.id);

    } else {
      this.props.actions.createMicroCampaign();

    }

    if (!this.props.registrationFormFields) {
      // noinspection JSUnresolvedFunction
      this.props.formsActions.getRegistrationFormFields();
    }
    if (!this.props.benefitsList) {
      // noinspection JSUnresolvedFunction
      this.props.benefitsActions.getBenefits();
    }

  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScrollView);
  }

  componentWillUnmount() {
    this.props.actions.clearMicroCampaignData();
    window.removeEventListener('scroll', this.handleScrollView);
  }


  get mentions() {
    return this.props.mentions.map((mention) => ({
      name: mention.get('display'),
      value: `{{${mention.get('id')}}}`
    }));
  }

  saveMicroCampaign = () => {
    this.props.actions.saveMicroCampaign(this.props.microCampaign, false, this.props.locationId);
  };

  handleSaveAsDraftButtonClick = () => {
    tracker.onButtonClick(trackerConstants.BUTTON_TYPE_MICRO_CAMPAIGN_SAVE_AS_DRAFT);
    this.props.actions.saveMicroCampaign(this.props.microCampaign, true, this.props.locationId);
  };


  handleScrollView = () => {
    this.setState({
      isHeaderShadowed: window.scrollY > 0 && !this.props.validationErrors
    });
  };

  handleNameChange = (event) => {
    const name = event.target.value.trim();
    this.props.actions.updateMicroCampaignMetadata(constants.MICRO_CAMPAIGN_NAME, name);
    if (name && this.props.validationErrors) {
      this.props.actions.checkMicroCampaignNameAvailability(
        this.props.microCampaign.getIn([featuresConstants.DATA, featuresConstants.HUB_ID]),
        name
      );
    }
  };

  handleDescriptionChange = (event) => {
    this.props.actions.updateMicroCampaignMetadata(constants.MICRO_CAMPAIGN_DESCRIPTION, event.target.value.trim());
  };

  handleCancelOrBack = () => {
    this.props.actions.goToMicroCampaigns();
  };

  onPopulationChange = (data) => {
    this.props.actions.updateMicroCampaignField({ [activityConstants.NAME]: constants.MICRO_CAMPAIGN_POPULATION_SIZE, [activityConstants.VALUE]: data.value, rootState: this.props.rootState });
  };

  sendCommunicationAndUpdateProgressStatus = (user, customerSearchField, actionsWrapperIndex, actionIndex, actionType) => {
    this.props.actions.submitMicroCampaignActionToCustomer(user, customerSearchField, actionsWrapperIndex, actionIndex, actionType);
    this.props.activityActions.updateProgressStatus(true, actionsWrapperIndex, actionIndex);
  }

  onBenefitChange = (e, data, index) => {
    let assets = this.props.microCampaign.getIn([activityConstants.DATA, constants.MICRO_CAMPAIGN_ASSET_TEMPLATES]);
    assets = assets.setIn([index, constants.MICRO_CAMPAIGN_ASSET_TEMPLATE], data.value);
    this.props.actions.updateMicroCampaignField({ [activityConstants.NAME]: constants.MICRO_CAMPAIGN_ASSET_TEMPLATES, [activityConstants.VALUE]: assets, rootState: this.props.rootState });
  };

  deleteCommunicationAction = () => false;

  updateCommunicationActionField = (name, value, index, assetSetIndex) => {
    let assets = this.props.microCampaign.getIn([activityConstants.DATA, constants.MICRO_CAMPAIGN_ASSET_TEMPLATES]);
    let updatedAction = assets.getIn([assetSetIndex, activityConstants.ACTIONS, index]);
    if (updatedAction.get(activityConstants.ACTION_TYPE) === schemaConstants.SEND_MEMBER_EMAIL_ACTION && name === emailConstants.EXTERNAL_ID) {
      const selectedEmail = emailUtils.getSeletcedMail(this.props.emailsList, value);
      updatedAction = updatedAction.set(name, value).set(emailConstants.TEMPLATE_NAME, selectedEmail.get(emailConstants.TEMPLATE_NAME));
      assets = assets.setIn([assetSetIndex, activityConstants.ACTIONS, index], updatedAction);
      this.props.actions.updateMicroCampaignField({
        [activityConstants.NAME]: constants.MICRO_CAMPAIGN_ASSET_TEMPLATES,
        [activityConstants.VALUE]: assets,
        rootState: this.props.rootState
      });
    } else if (updatedAction.get(activityConstants.ACTION_TYPE) !== schemaConstants.SEND_MEMBER_EMAIL_ACTION) {
      updatedAction = updatedAction.set(name, value);
      assets = assets.setIn([assetSetIndex, activityConstants.ACTIONS, index], updatedAction);
      this.props.actions.updateMicroCampaignField({
        [activityConstants.NAME]: constants.MICRO_CAMPAIGN_ASSET_TEMPLATES,
        [activityConstants.VALUE]: assets,
        rootState: this.props.rootState
      });
    }
  };


  updateCommunicationActionType = (actionName, index, assetSetIndex) => {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const defaultText = index === 0 ? formatMessage({ id: 'microCampaign.notifyAction.send.default' }) : formatMessage({ id: 'microCampaign.notifyAction.remind.default' });
    this.props.actions.updateCommunicationActionType(actionName, index, defaultText, assetSetIndex);
  };

  checkLastAssetIsEmpty = (microCampaignData) => {
    if(microCampaignData.get(constants.MICRO_CAMPAIGN_ASSET_TEMPLATES).size){
      return !microCampaignData.get(constants.MICRO_CAMPAIGN_ASSET_TEMPLATES).last().get(constants.MICRO_CAMPAIGN_ASSET_TEMPLATE);
    }
    return true;
  };

  getSelectedBenefit = (index, microCampaignData) => benefitSelectors.getSelectedBenefitFromList(this.props.benefitsList, microCampaignData.getIn([constants.MICRO_CAMPAIGN_ASSET_TEMPLATES, index, constants.MICRO_CAMPAIGN_ASSET_TEMPLATE]));

  getSelectedBenefitStatus = (index, microCampaignData) => {
    const benefit = benefitSelectors.getSelectedBenefitFromList(this.props.benefitsList, microCampaignData.getIn([constants.MICRO_CAMPAIGN_ASSET_TEMPLATES, index, constants.MICRO_CAMPAIGN_ASSET_TEMPLATE]));
    if (benefit) {
      if (!benefit.get(benefitsConstants.BENEFIT_ACTIVE)) {
        return benefitsConstants.BENEFIT_INACTIVE;
      }

      if (moment.utc(benefit.get(benefitsConstants.BENEFIT_VALID_UNTIL)).isBefore(moment.utc())) {
        return benefitsConstants.BENEFIT_EXPIRED;
      }

      return benefitsConstants.BENEFIT_ACTIVE;
    }
    return benefitsConstants.BENEFIT_ACTIVE;
  };

  expandAll = () => {
    this.props.actions.setAssetSetsExpandCollapseMode(false);
  };

  collapseAll = () => {
    this.props.actions.setAssetSetsExpandCollapseMode(true);
  };

  render() {
    const microCampaignData = this.props.microCampaign ? this.props.microCampaign.get(featuresConstants.DATA) : null;
    if (!microCampaignData) {
      return null;
    }

    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const headerClassNames = classNames({ shadowed: this.state.isHeaderShadowed });
    const microCampaignNameError = this.props.validationErrors ? this.props.validationErrors.getIn([constants.MICRO_CAMPAIGN_HEADER, constants.MICRO_CAMPAIGN_NAME]) : null;
    const nameClassNames = classNames({ 'inputFieldError': !!microCampaignNameError });
    const automationIdPrefix = 'microCampaign';
    const maxPopulation = microCampaignData.get(constants.MICRO_CAMPAIGN_POPULATION_SIZE) || Math.floor(constants.MICRO_CAMPAIGN_POPULATION_SIZE_MAX / 2);

    const lastAssetIsEmpty = this.checkLastAssetIsEmpty(microCampaignData);
    const automationPrefix = 'microCampaign';
    const isBenefitAddable = !lastAssetIsEmpty && microCampaignData.get(constants.MICRO_CAMPAIGN_ASSET_TEMPLATES).size > 1 && microCampaignData.get(constants.MICRO_CAMPAIGN_ASSET_TEMPLATES).size < 3;

    return (
      <div className="activity micro-campaign form">
        <EntityHeader
          headerClassName={headerClassNames}
          nameClassName={nameClassNames}
          namePlaceholder={formatMessage({ id: 'microCampaign.title.name.placeholder' })}
          isLoading={this.props.checkingMicroCampaignNameAvailability}
          name={microCampaignData.get(constants.MICRO_CAMPAIGN_NAME)}
          nameValidationError={microCampaignNameError}
          promptBeforeLeave={this.props.isDirty && !this.props.publishInProgress}
          canSaveAsDraft={!microCampaignData.get(constants.MICRO_CAMPAIGN_STATUS) || microCampaignData.get(constants.MICRO_CAMPAIGN_STATUS) === activityConstants.ACTIVITY_STATUS_DRAFT}
          saveAsDraftInProgress={this.props.saveAsDraftInProgress}
          onSaveAsDraftClick={this.handleSaveAsDraftButtonClick}
          publishButtonDisabled={this.props.publishInProgress}
          publishButtonText={formatMessage({ id: 'microCampaign.header.publish' })}
          onSaveEntityClick={this.saveMicroCampaign}
          hasTabs={false}
          handleNameChange={this.handleNameChange}
          handleDescriptionChange={this.handleDescriptionChange}
          onCancelOrBackClick={this.handleCancelOrBack}
          prefix={'activity'}
          showPreview={false}
          descriptionPlaceholder={formatMessage({ id: 'activity.title.description.placeholder' })}
          description={microCampaignData.get(constants.MICRO_CAMPAIGN_DESCRIPTION) || ''}
          isActivateAddon={false}
          getBusinessBundlePlan={this.props.getBusinessBundlePlan}
        />
        <Form className="form activity-body">
          {this.props.validationErrors &&
            <div className="toolbar-error-panel">
              <span
                className="toolbar-error-text"
              >{formatMessage({ id: 'microCampaign.validation.error.general' })}</span>
            </div>
          }
          <Grid className="actions-section cases-list">
            <Grid.Row className="set-up micro-container-part">
              <Grid.Column width={16}>
                <Header>{formatMessage({ id: 'microCampaigns.header.setUp' })}</Header>
                <p className="note explanation">{formatMessage({ id: 'microCampaigns.recommended.benefits' })}</p>
                <div className="input-range-wrapper">
                  <span className="head-of-sentence"><span className="note"> {formatMessage({ id: 'microCampaign.limitUpTo' })} </span><span className="min-number">{constants.MICRO_CAMPAIGN_POPULATION_SIZE_MIN}</span></span>
                  <RangeInputField
                    maxValue={constants.MICRO_CAMPAIGN_POPULATION_SIZE_MAX}
                    minValue={constants.MICRO_CAMPAIGN_POPULATION_SIZE_MIN}
                    value={maxPopulation}
                    onChange={(value) => this.onPopulationChange({ value })}
                    name={constants.MICRO_CAMPAIGN_POPULATION_SIZE}
                    step={10}
                  />
                  <span className="inside-sentence">{formatMessage({ id: 'microCampaign.limitMax' }, { maxValue: constants.MICRO_CAMPAIGN_POPULATION_SIZE_MAX })}</span>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <p className="expand-collapse">
                  <a
                    role="button"
                    onClick={this.expandAll}
                    data-automation-id={`${automationIdPrefix}.expand-all`}
                  >{formatMessage({ id: 'activity.cases.expandAll' })}</a>
                  |
                  <a
                    role="button"
                    onClick={this.collapseAll}
                    data-automation-id={`${automationIdPrefix}.collapse-all`}
                  >{formatMessage({ id: 'activity.cases.collapseAll' })}</a>
                </p>
              </Grid.Column>
            </Grid.Row>
            { microCampaignData.get(constants.MICRO_CAMPAIGN_ASSET_TEMPLATES).map((assetTemplate, index) => {
              const selectedBenefit = this.getSelectedBenefit(index, microCampaignData);
              const selectedBenefitStatus = this.getSelectedBenefitStatus(index, microCampaignData);
              return (
                <Grid.Row key={`assetSet.${index.toString()}`}>
                  <Grid.Column width={16}>
                    <MicroCampaignSingleAssetSet
                      assetSet={assetTemplate}
                      index={index}
                      validationErrors={this.props.validationErrors && this.props.validationErrors.get(constants.MICRO_CAMPAIGN_ASSET_TEMPLATES) ? this.props.validationErrors.getIn([constants.MICRO_CAMPAIGN_ASSET_TEMPLATES, index]) : null}
                      automationIdPrefix={automationIdPrefix}
                      deleteAssetTemplate={this.props.actions.deleteAssetTemplate}
                      actionsList={microCampaignData.getIn([constants.MICRO_CAMPAIGN_ASSET_TEMPLATES, index, activityConstants.ACTIONS])}
                      schemaActionsList={this.props.schemaActionsList}
                      mentions={this.props.mentions}
                      deleteCommunicationAction={this.deleteCommunicationAction}
                      updateCommunicationActionField={this.updateCommunicationActionField}
                      updateCommunicationActionType={this.updateCommunicationActionType}
                      assetSetsCount={microCampaignData.get(constants.MICRO_CAMPAIGN_ASSET_TEMPLATES).size}
                      benefitsList={this.props.benefitsList}
                      selectedBenefit={selectedBenefit}
                      selectedBenefitStatus={selectedBenefitStatus}
                      benefitsListValue={microCampaignData.getIn([constants.MICRO_CAMPAIGN_ASSET_TEMPLATES, index, constants.MICRO_CAMPAIGN_ASSET_TEMPLATE]) || null}
                      trigger={microCampaignData.get(activityConstants.TRIGGER)}
                      onBenefitChange={this.onBenefitChange}
                      clearAssetSetData={this.props.actions.clearAssetSetData}
                      toggleAssetSetDisplay={this.props.actions.toggleAssetSetDisplay}
                      submitActionToCustomer={this.sendCommunicationAndUpdateProgressStatus}
                      getBusinessBundlePlan={this.props.getBusinessBundlePlan}
                    />
                  </Grid.Column>
                </Grid.Row>
              );
            })
            }

            <Grid.Row>
              <Grid.Column width={16}>
                {isBenefitAddable && <a
                  role="button"
                  tabIndex={-1}
                  className="condition-link add-condition"
                  onClick={this.props.actions.addBenefit}
                  data-automation-id={`${automationPrefix}.addBenefit`}
                >
                  <Icon className="como-ic-plus-in-circle" />
                    {formatMessage({ id: 'microCampaign.addBenefit' })}
                  </a>
                  }
              </Grid.Column>
            </Grid.Row>

          </Grid>
        </Form>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mentions: mentionsSelectors.getTransformedMentionsForMicroCampaigns(state),
  microCampaign: selectors.getMicroCampaign(state),
  locationId: appSelectors.getLocationId(state),
  validationErrors: selectors.getMicroCampaignValidationErrors(state),
  checkingMicroCampaignNameAvailability: selectors.getCheckingMicroCampaignNameAvailabilityFlag(state),
  isDirty: selectors.isDirty(state),
  publishInProgress: selectors.getPublishInProgressFlag(state),
  schemaActionsList: activitySelectors.getActionsForMicroCampaign(state),
  registrationFormFields: formsSelectors.getRegistrationFormFields(state),
  emailsList: appSelectors.getMailTemplatesMap(state),
  saveAsDraftInProgress: selectors.getSaveAsDraftInProgressFlag(state),
  benefitsList: benefitSelectors.getBenefitsList(state),
  rootState: state
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    activityActions: bindActionCreators(activityActions, dispatch),
    formsActions: bindActionCreators(formsActions, dispatch),
    benefitsActions: bindActionCreators(benefitsActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.MICRO_CAMPAIGN_BRANCH, saga });
const withBusinessSaga = injectSaga({ key: reducerConstants.BUSINESS_CENTER_BRANCH, saga: businessSaga });
const withBenefitsSaga = injectSaga({ key: benefitsConstants.BENEFITS, saga: benefitsSaga });
const withFormsSaga = injectSaga({ key: reducerConstants.FORMS_BRANCH, saga: formsSaga });
const withMailTemplatesSaga = injectSaga({ key: reducerConstants.MAIL_TEMPLATES_BRANCH, saga: mailTemplatesSaga });
const withMicroCampaignsSaga = injectSaga({ key: reducerConstants.MICRO_CAMPAIGNS_BRANCH, saga: microCampaignsSaga });


export default compose(
  withSaga,
  withBusinessSaga,
  withBenefitsSaga,
  withFormsSaga,
  withMailTemplatesSaga,
  withMicroCampaignsSaga,
  withConnect,
  withAuthorization)(injectIntl(MicroCampaign));
