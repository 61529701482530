/*
 *
 * Images constants
 *
 */

// ACTIONS CONSTANTS

export const GET_IMAGES_BY_SIZE = 'app/Images/GET_IMAGES_BY_SIZE';
export const GET_IMAGES_BY_SIZE_SUCCESS = 'app/Images/GET_IMAGES_BY_SIZE_SUCCESS';

export const DELETE_IMAGE = 'app/Images/DELETE_IMAGE';
export const DELETE_IMAGE_SUCCESS = 'app/Images/DELETE_IMAGE_SUCCESS';
export const DELETE_IMAGE_ERROR = 'app/Images/DELETE_IMAGE_ERROR';

export const UPLOAD_IMAGES = 'app/UPLOAD_IMAGES';
export const UPLOAD_IMAGES_SUCCESS = 'app/UPLOAD_IMAGES_SUCCESS';
export const UPLOAD_IMAGES_ERROR = 'app/UPLOAD_IMAGES_ERROR';

// STORE CONSTANTS

export const LIST = 'list';
export const UPLOAD_IN_PROGRESS = 'uploadInProgress';
export const DELETE_IN_PROGRESS = 'deleteInProgress';

export const VALID_TYPES = 'image/jpeg, image/png';
export const MAX_FILE_SIZE = 1024 * 1024 * 10; // 10MB

export const IMAGE_SIZE_ORIGINAL = 'original';
export const IMAGE_SIZE_THUMBNAIL = 'thumb';

export const IMAGE_SOURCE_DEFAULT = 'default';
export const IMAGE_SOURCE_DEFAULT_STAMP = 'defaultStamp';

// MODEL CONSTANTS
export const ID = 'key';
export const URL = 'url';
export const DISPLAY_NAME = 'displayName';
export const TYPE = 'type';
export const SIZE = 'size';
export const CREATE_TIME = 'createDateTime';
export const ARCHIVED = 'archived';
export const SOURCE = 'source';
