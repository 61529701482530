
export const GET_POINT_SHOP_ITEMS_ERROR = 'app/PointShop/GET_POINT_SHOP_ITEMS_ERROR';

export const CREATE_POINT_SHOP_ITEM = 'app/PointShop/CREATE_POINT_SHOP_ITEM';
export const UPDATE_POINT_SHOP_ITEM_STATUS = 'app/PointShop/UPDATE_POINT_SHOP_ITEM_STATUS';
export const SORT = 'sort';
export const ORDER = 'order';
export const ORDER_DESCENDING = 'descending';

export const POINT_SHOP_ITEM_MODE_CLASSIC = 'Classic';
export const POINT_SHOP_ITEM_MODE_CUSTOM = 'Custom';

export const POINT_SHOP_ITEM_STATUS_DRAFT = 'draft';
export const POINT_SHOP_ITEM_STATUS_ACTIVE = 'active';

export const POINT_SHOP_ITEM_VALIDATION_ERRORS = 'validationErrors';