import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import * as constants from './email.constants';
import * as api from './email.api';
import * as featuresConstants from '../features.constants';
import tracker from '../../common/utils/tracking/tracker';
import * as trackerConstants from '../../common/utils/tracking/tracking.consts';
import * as validationUtils from './utils/validation.utils';
import * as errorConstant from '../ErrorMessage/errorMessage.constants';
import * as routerConstants from '../../constants/route.contants';
import * as activitySelectors from '../Activity/activity.selectors';
import * as emailsConstants from '../Emails/emails.constants';
import * as activityConstants from '../Activity/activity.constants';
import * as appSelectors from '../App/selectors';


function* saveEmail({ email }) {
  yield put({
    type: constants.SET_EMAIL_PUBLISH_IN_PROGRESS,
    [featuresConstants.ACTION_IN_PROGRESS_NAME]: constants.PUBLISH_IN_PROGRESS,
    [featuresConstants.ACTION_IN_PROGRESS_VALUE]: true
  });
  const isExistingEmail = Boolean(email.getIn([featuresConstants.DATA, featuresConstants.HUB_ID]));
  const publishedBefore = Boolean(email.getIn([featuresConstants.DATA, featuresConstants.SERVER_ID]));
  tracker.onEvent(trackerConstants.EVENT_TYPE_PUBLISH_ACTIVITY, {
    [trackerConstants.ACTIVITY_PUBLISH_ARGS_EXISTING_ACTIVITY]: isExistingEmail,
    [trackerConstants.ACTIVITY_PUBLISH_ARGS_PUBLISHED_BEFORE]: publishedBefore
  });
  const isDraft = false;
  try {
    const { validationErrors, nameAvailable } = yield validateEmail({ email, isDraft });
    if (validationErrors && validationErrors.size > 0) {
      yield put({
        type: constants.SET_EMAIL_PUBLISH_IN_PROGRESS,
        [featuresConstants.ACTION_IN_PROGRESS_NAME]: constants.PUBLISH_IN_PROGRESS,
        [featuresConstants.ACTION_IN_PROGRESS_VALUE]: false
      });
      return yield put({
        type: constants.SET_EMAIL_ERRORS,
        [constants.EMAIL_VALIDATION_ERRORS]: validationErrors,
        [constants.EMAIL_VALIDATION_NAME_TAKEN_FLAG]: !nameAvailable
      });
    }
    const data = email.get(featuresConstants.DATA).toJS();
    const response = yield call(api.saveEmail, data);
    return yield put({ type: constants.SAVE_EMAIL_SUCCESS, data: response });
  } catch (err) {
    yield put({ type: constants.SAVE_EMAIL_ERROR, message: err.message });
    return yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: err.response.status === 400 ? ['saveEmail.Fail.fix'] : ['saveEmail.Fail'] });
  } finally {
    yield put(
      {
        type: constants.SET_EMAIL_PUBLISH_IN_PROGRESS,
        [featuresConstants.ACTION_IN_PROGRESS_NAME]: constants.PUBLISH_IN_PROGRESS,
        [featuresConstants.ACTION_IN_PROGRESS_VALUE]: false
      });
  }
}

function* validateEmail({ email, isDraft = false }) {
  const emailName = email.getIn([featuresConstants.DATA, constants.EMAIL_NAME]);
  const emailHubId = email.getIn([featuresConstants.DATA, featuresConstants.HUB_ID]);
  const nameAvailable = emailName !== '' ? yield call(api.checkEmailNameAvailability, emailHubId, emailName) : true;
  let stateWithErrors = validationUtils.validateEmail(email);
  if (!nameAvailable) {
    stateWithErrors = validationUtils.handleEmailNameAlreadyInUseError(email);
  }
  const validationErrors = stateWithErrors.get(constants.EMAIL_VALIDATION_ERRORS);
  return { validationErrors, nameAvailable };
}

function* getEmail({ hubId }) {
  yield put({ type: constants.SET_EMAIL_DATA_LOADING });
  try {
    const data = yield call(api.getEmail, hubId);
    yield put({ type: constants.GET_EMAIL_SUCCESS, data });
  } catch (err) {
    yield put({ type: constants.GET_EMAIL_ERROR, message: err.message });
  }
}

function* getEmailEditorBusinessCredentials() {
  try {
    const authData = yield call(api.getEmailEditorBusinessCredentials);
    yield put({ type: constants.INITIALIZE_EMAIL_EDITOR_SUCCESS, authData });
  } catch (err) {
    yield put({ type: constants.INITIALIZE_EMAIL_EDITOR_ERROR, message: err.message });
  }
}

function* checkEmailNameAvailability({ name, emailId }) {
  try {
    const nameAvailable = yield call(api.checkEmailNameAvailability, emailId, name);
    yield put({
      type: constants.CHECK_EMAIL_NAME_AVAILABILITY_SUCCESS,
      nameAvailable,
      value: name
    });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['checkEmailNameAvailability.Fail'] });
  }
  return yield put({ type: constants.CHECK_EMAIL_NAME_AVAILABILITY_DONE });
}

function* saveEmailSuccess(action) {
  const tempOriginActivity = yield select(activitySelectors.getTempOriginActivity);
  if (tempOriginActivity) {
    yield put({ type: emailsConstants.GET_EMAILS });
    yield put({ type: activityConstants.GO_BACK_TO_ACTIVITY_FROM_CREATE_EMAIL, data: action.data });
    yield put(push({
      pathname: tempOriginActivity.getIn([activityConstants.RETURN_URL, activityConstants.RETURN_URL_PATHNAME]),
      search: tempOriginActivity.getIn([activityConstants.RETURN_URL, activityConstants.RETURN_URL_SEARCH])
    }));
  } else {
    const hasBasicPlanPermissions = yield select(appSelectors.hasBasicPlanPermissions);
    const emailPath = hasBasicPlanPermissions ? routerConstants.BASIC_EMAILS_ROUTE : routerConstants.EMAILS_ROUTE;
    yield put(push({ pathname: emailPath, search: location.search }));
  }
}

function* goToEmailList() {
  const hasBasicPlanPermissions = yield select(appSelectors.hasBasicPlanPermissions);
  const emailPath = hasBasicPlanPermissions ? routerConstants.BASIC_EMAILS_ROUTE : routerConstants.EMAILS_ROUTE;
  yield put(push({ pathname: emailPath, search: location.search }));
}

// sagas listen to dispatched actions too (same as reducers)
function* emailSaga() {
  yield takeLatest(constants.SAVE_EMAIL, saveEmail);
  yield takeEvery(constants.GET_EMAIL, getEmail);
  yield takeLatest(constants.GET_BUSINESS_EMAIL_EDITOR_CREDENTIALS, getEmailEditorBusinessCredentials);
  yield takeLatest(constants.CHECK_EMAIL_NAME_AVAILABILITY, checkEmailNameAvailability);
  yield takeLatest(constants.SAVE_EMAIL_SUCCESS, saveEmailSuccess);
  yield takeLatest(constants.GO_TO_EMAILS, goToEmailList);
}

export default emailSaga;
