import * as constants from './activity.constants';
import * as schemaConstants from './activitySchema/activitySchema.constants';
import * as defaults from './reducers/activity.reducer.defaults';
import * as dateConstants from '../../common/utils/dateTime.constants';

export function handlePunchCardChildActivities(parentActivity) {
  const childActivities = parentActivity[constants.PUNCH_CARD_CHILD_ACTIVITIES];

  handleRenewRelatedChildActivities(parentActivity);
  setOverflowRule(parentActivity, childActivities);

  Object.keys(childActivities).forEach((key) => {
    if (key === constants.PUNCH_CARD_COMMUNICATION_RULES) {
      if (childActivities[key].length) {
        childActivities[key].forEach((activity) => {
          updateChildActivity(parentActivity, activity, key);
        });
        childActivities[key] = childActivities[key].filter((activity) => activity[constants.TRIGGER] && activity[constants.ACTIONS][0][constants.ACTIONS_LIST][0][constants.ACTION_TYPE]);
      } else {
        delete childActivities[key];
      }
    } else {
      updateChildActivity(parentActivity, childActivities[key], key);
    }
  });

  setDelay(parentActivity, childActivities);

  return childActivities;
}

function updateChildActivity(parentActivity, childActivity, key) {
  if (childActivity[constants.NAME]) {
    childActivity[constants.NAME] = setChildActivityName(parentActivity, childActivity, key);
  }
  normalizeActions(childActivity);
  addParentConditionsToChildActivity(parentActivity, childActivity);
  updateItemsPopulationInChildActivities(parentActivity, childActivity);
}

function setChildActivityName(parentActivity, childActivity, childActivityKey) {
  return `${childActivityKey} ${childActivity[constants.TYPE].toLowerCase().replace('hub', '')} for ${parentActivity[constants.NAME]} ${parentActivity[constants.TYPE]}`;
}

function addParentConditionsToChildActivity(parentActivity, childActivity) {
  if (childActivity[constants.TRIGGER] === schemaConstants.TRIGGER_PURCHASE_ANALYZED) {
    if (childActivity[constants.CONDITIONS] && parentActivity[constants.CONDITIONS]) {
      const updatedConditions = childActivity[constants.CONDITIONS][constants.CONDITIONS_LIST].filter((condition) => [
        schemaConstants.CONTEXT_SHOPPING_CART_TOTAL_QUANTITY,
        schemaConstants.CONTEXT_AVAILABLE_PUNCH_CARD,
        schemaConstants.CONTEXT_NO_ACTIVE_FUTURE_INPROGRESS_PUNCHCARD,
        schemaConstants.CONTEXT_ASSET_TEMPLATE,
        schemaConstants.CONTEXT_EXPIRED_PUNCH_CARD,
      ].includes(condition[constants.CONDITION_KEY]));
      childActivity[constants.CONDITIONS][constants.CONDITIONS_LIST] = [...updatedConditions, ...parentActivity[constants.CONDITIONS][constants.CONDITIONS_LIST]];
    } else if (parentActivity[constants.CONDITIONS]) {
      childActivity[constants.CONDITIONS] = parentActivity[constants.CONDITIONS];
    } else {
      childActivity[constants.CONDITIONS][constants.CONDITIONS_LIST] = childActivity[constants.CONDITIONS][constants.CONDITIONS_LIST].filter((condition) => [
        schemaConstants.CONTEXT_SHOPPING_CART_TOTAL_QUANTITY,
        schemaConstants.CONTEXT_AVAILABLE_PUNCH_CARD,
        schemaConstants.CONTEXT_NO_ACTIVE_FUTURE_INPROGRESS_PUNCHCARD
      ].includes(condition[constants.CONDITION_KEY]));
    }
    childActivity[constants.MEMBERSHIP_GLOBAL_CONDITIONS] = parentActivity[constants.MEMBERSHIP_GLOBAL_CONDITIONS];
  }
  childActivity[constants.DATETIME_GLOBAL_CONDITIONS] = parentActivity[constants.DATETIME_GLOBAL_CONDITIONS];
}

function updateItemsPopulationInChildActivities(parentActivity, childActivity) {
  if (childActivity[constants.TRIGGER] === schemaConstants.TRIGGER_PURCHASE_ANALYZED) {
    const itemsPopulation = parentActivity[constants.PUNCH_CARD_CHILD_ACTIVITIES][constants.PUNCH_CARD_RULE_PUNCH][constants.ACTIONS][0][constants.BUNDLE][constants.VALUE][constants.CONDITIONS_LIST][0][constants.ITEMS_POPULATION];
    if (childActivity[constants.CONDITIONS]) {
      childActivity[constants.CONDITIONS][constants.CONDITIONS_LIST].forEach((condition) => {
        if (condition[constants.CONDITION_KEY] === schemaConstants.CONTEXT_SHOPPING_CART_TOTAL_QUANTITY) {
          condition[constants.ITEMS_POPULATION] = itemsPopulation;
        }
      });
    }
  }
}

export function handleRenewRelatedChildActivities(parentActivity) {
  const locationId = parentActivity[constants.LOCATION_ID];
  const childActivities = parentActivity[constants.PUNCH_CARD_CHILD_ACTIVITIES];

  if (parentActivity[constants.PUNCH_CARD_AUTO_RENEW]) {
    switch (parentActivity[constants.PUNCH_CARD_AUTO_RENEW_TRIGGER]) {
      case constants.PUNCH_CARD_RENEW_ON_REDEEM: {
        setRenewRules(locationId, childActivities, [constants.PUNCH_CARD_RULE_SEND_ON_REDEEM], [constants.PUNCH_CARD_RULE_SEND_ON_LAST_PUNCH]);
        break;
      }
      case constants.PUNCH_CARD_RENEW_ON_LAST_PUNCH: {
        setRenewRules(locationId, childActivities, [constants.PUNCH_CARD_RULE_SEND_ON_LAST_PUNCH], [constants.PUNCH_CARD_RULE_SEND_ON_REDEEM]);
        break;
      }
      default:
        break;
    }
  } else {
    setRenewRules(locationId, childActivities, [], [constants.PUNCH_CARD_RULE_SEND_ON_REDEEM, constants.PUNCH_CARD_RULE_SEND_ON_LAST_PUNCH]);
  }
}

/**
 * generate or activate relevant rules to child activities
 * deactivate irrelevant sendAsset rules
 * @param {int} locationId
 * @param {object} childActivities
 * @param {array} activitiesToAdd
 * @param {array} activitiesToRemove
 * @returns {object}
 */
function setRenewRules(locationId, childActivities, activitiesToAdd, activitiesToRemove) {
  for (let i = 0, len1 = activitiesToAdd.length; i < len1; i++) {
    const activityKey = activitiesToAdd[i];
    let defaultActivityObjectKey;
    switch (activityKey) {
      case constants.PUNCH_CARD_RULE_SEND_ON_EXPIRATION: {
        defaultActivityObjectKey = defaults.punchCardRelatedSendOnExpirationRule;
        break;
      }
      case constants.PUNCH_CARD_RULE_SEND_ON_LAST_PUNCH: {
        defaultActivityObjectKey = defaults.punchCardRelatedSendOnLastPunchRule;
        break;
      }
      case constants.PUNCH_CARD_RULE_SEND_ON_REDEEM: {
        defaultActivityObjectKey = defaults.punchCardRelatedSendOnRedeemRule;
        break;
      }

      default: {
        break;
      }
    }
    childActivities[activityKey] = generateOrActivate(childActivities[activityKey], locationId, defaultActivityObjectKey);
  }
  for (let j = 0, len2 = activitiesToRemove.length; j < len2; j++) {
    const activity = childActivities[activitiesToRemove[j]];
    if (activity) {
      childActivities[activitiesToRemove[j]][constants.STATUS] = constants.ACTIVITY_STATUS_INACTIVE;
    }
  }
}

export function generateOrActivate(activity, locationId, getActivityDefaultsFunction) {
  let updatedActivity = activity;
  if (activity) {
    updatedActivity[constants.STATUS] = constants.ACTIVITY_STATUS_ACTIVE;
  } else {
    updatedActivity = getActivityDefaultsFunction(locationId).toJS();
  }
  return updatedActivity;
}

/**
 * add delay to punch and punchOverflow rules action so that server gets to analyze purchase
 * and send punch card if needed before it tries to punch it
 * remove delay when no sendPunchCard rules are active
 * @param {object} parentActivity
 * @param {object} childActivities
 * @returns {object}
 */
function setDelay(parentActivity, childActivities) {
  if (parentActivity[constants.PUNCH_CARD_AUTO_RENEW] || (childActivities[constants.PUNCH_CARD_RULE_SEND_ON_EXPIRATION] && childActivities[constants.PUNCH_CARD_RULE_SEND_ON_EXPIRATION][constants.STATUS] === constants.ACTIVITY_STATUS_ACTIVE)) {
    const delay = {
      [constants.DELAY_UNITS]: dateConstants.MINUTE,
      [constants.DELAY_NUMBER]: 1
    };
    childActivities[constants.PUNCH_CARD_RULE_PUNCH][constants.ACTIONS][0][constants.ACTIONS_LIST][0][constants.DELAY] = delay;
    if (childActivities[constants.PUNCH_CARD_RULE_PUNCH_OVERFLOW]) {
      childActivities[constants.PUNCH_CARD_RULE_PUNCH_OVERFLOW][constants.ACTIONS][0][constants.ACTIONS_LIST][0][constants.DELAY] = delay;
    }
  } else {
    childActivities[constants.PUNCH_CARD_RULE_PUNCH][constants.ACTIONS][0][constants.ACTIONS_LIST][0][constants.DELAY] = null;
    if (childActivities[constants.PUNCH_CARD_RULE_PUNCH_OVERFLOW]) {
      childActivities[constants.PUNCH_CARD_RULE_PUNCH_OVERFLOW][constants.ACTIONS][0][constants.ACTIONS_LIST][0][constants.DELAY] = null;
    }
  }
}

function setOverflowRule(parentActivity, childActivities) {
  if (parentActivity[constants.PUNCH_CARD_AUTO_RENEW] && [constants.PUNCH_CARD_RENEW_ON_LAST_PUNCH].includes(parentActivity[constants.PUNCH_CARD_AUTO_RENEW_TRIGGER])) {
    // generate or activate punchOverflow rule
    if (childActivities[constants.PUNCH_CARD_RULE_PUNCH_OVERFLOW]) {
      childActivities[constants.PUNCH_CARD_RULE_PUNCH_OVERFLOW][constants.STATUS] = constants.ACTIVITY_STATUS_ACTIVE;
    } else {
      childActivities[constants.PUNCH_CARD_RULE_PUNCH_OVERFLOW] = defaults.punchCardRelatedPunchOverflowRule(parentActivity[constants.LOCATION_ID]).toJS();
    }
  } else if (childActivities[constants.PUNCH_CARD_RULE_PUNCH_OVERFLOW]) {
    // delete or deactivate punchOverflow rule
    if (childActivities[constants.PUNCH_CARD_RULE_PUNCH_OVERFLOW][constants.SERVER_ID]) {
      childActivities[constants.PUNCH_CARD_RULE_PUNCH_OVERFLOW][constants.STATUS] = constants.ACTIVITY_STATUS_INACTIVE;
    } else {
      delete childActivities[constants.PUNCH_CARD_RULE_PUNCH_OVERFLOW];
    }
  }
}

function normalizeActions(activity) {
  if (activity[constants.ACTIONS]) {
    activity[constants.ACTIONS].forEach((actionsWrapper, index) => {
      activity[constants.ACTIONS][index][constants.ACTIONS_LIST] = activity[constants.ACTIONS][index][constants.ACTIONS_LIST].filter((action) => action[constants.ACTION_TYPE] !== '');
      nullifyEmptyExcludedItemsPopulation(activity[constants.ACTIONS][index]);
    });
  }
  return activity;
}

export function nullifyEmptyExcludedItemsPopulation(actionsListWrapper) {
  actionsListWrapper[constants.ACTIONS_LIST].forEach((actionsList) => {
    if ((actionsList[constants.EXCLUDE_ITEMS_POPULATION]) && (actionsList[constants.EXCLUDE_ITEMS_POPULATION][constants.TYPE] === null)) {
      actionsList[constants.EXCLUDE_ITEMS_POPULATION] = null;
    }
  });
}
