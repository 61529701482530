import { delay } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as constants from './benefits.constants';
import * as api from './benefits.api';
import * as errorConstant from '../ErrorMessage/errorMessage.constants';

function* getBenefits() {
  // debounce by 50ms
  yield delay(50);
  try {
    const data = yield call(api.getBenefits);
    yield put({ type: constants.GET_BENEFITS_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getBenefits.Fail'] });
  }
}

// sagas listen to dispatched actionsList too (same as reducers)
function* benefitsSaga() {
  yield takeLatest(constants.GET_BENEFITS, getBenefits);
}

export default benefitsSaga;
