/**
 *
 * Members
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose, bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';

import injectSaga from '../../utils/injectSaga';
import * as appActions from '../App/app.actions';
import appSaga from '../App/app.saga';
import mailTemplateSaga from '../MailTemplates/mailTemplates.saga';
import './members.styles.less';
import * as routerConstants from '../../constants/reducer.constants';
import * as mailTemplateConstants from '../MailTemplates/mailTemplates.constants';
import * as queryStringUtils from '../../utils/queryStringUtils';
import LazyLoadModule from '../App/views/LazyLoadModule';
import * as selectors from '../App/selectors';
import * as constants from '../App/app.constants';
import * as membersSelectors from './members.selectors';
import * as mentionsSelectors from '../Activity/activitySchema/mentions.selectos';
import * as formsSelectors from '../Forms/forms.selectors';
import formsSaga from '../Forms/forms.saga';
import * as formsActions from '../Forms/forms.actions';

export class HubCustomer extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

  static propTypes = {
    business: PropTypes.object,
    consentContent: PropTypes.object,
    currencySymbol: PropTypes.string,
    locale: PropTypes.string,
    userEmail: PropTypes.string,
    actions: PropTypes.object,
    membershipKey: PropTypes.string,
    search: PropTypes.string,
    customerDataLoading: PropTypes.bool,
    businessDisplayName: PropTypes.string,
    countryCode: PropTypes.string,
    features: PropTypes.object,
    unsubscribeMessage: PropTypes.string,
    mentionsList: PropTypes.object,
    emailMentionsList: PropTypes.object,
    registrationFormFields: PropTypes.object,
  };

  componentWillMount() {
    if (!this.props.registrationFormFields) {
      // noinspection JSUnresolvedFunction
      this.props.actions.getRegistrationFormFields();
    }
  }

  componentDidMount() {
    if (!this.props.membershipKey && !this.props.search) {
      const { search } = queryStringUtils.parse(location.search);
      this.props.actions.getCustomer(search);
    }
  }

  render() {
    return (
      this.props.membershipKey && !this.props.customerDataLoading ?
        (
          <div>
            <Helmet>
              <title>Member Profile{this.props.businessDisplayName ? ` - ${this.props.businessDisplayName}` : null}</title>
            </Helmet>
            <LazyLoadModule
              applicationData={{
                business: this.props.business,
                userEmail: this.props.userEmail,
                locale: this.props.locale,
                currencySymbol: this.props.currencySymbol,
                consentContent: this.props.consentContent,
                membershipKey: this.props.membershipKey,
                countryCode: this.props.countryCode,
                features: this.props.features,
                unsubscribeMessage: this.props.unsubscribeMessage,
                localeDateFormat: this.props.localeDateFormat,
                localeTimeFormat: this.props.localeTimeFormat,
                mentionsList: this.props.mentionsList,
                emailMentionsList: this.props.emailMentionsList,
                user: this.props.user,
              }}
              navigation={this.props}
              resolve={() => import('@como/web-hub-customer-module')}
            />
          </div>
        )
        :
        null
    );
  }
}

function mapDispatchToProps(dispatch) {
  const creators = Object.assign({},
    appActions,
    formsActions);

  return {
    actions: bindActionCreators(creators, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    business: selectors.getBusiness(state),
    isoCurrencySymbol: selectors.getLocationIsoCurrencySymbol(state),
    currencySymbol: selectors.getLocationCurrencySymbol(state),
    locale: selectors.getLocale(state),
    messages: selectors.getTranslations(state),
    userEmail: selectors.getUserEmail(state),
    consentContent: {
      [constants.ACTION_SMS_CONTENT]: selectors.getDefaultSMSConsentActionContent(state),
      [constants.ACTION_EMAIL_CONTENT]: selectors.getDefaultEmailConsentActionContent(state),
      [constants.ACTION_EMAIL_SUBJECT]: selectors.getDefaultEmailConsentActionSubject(state),
    },
    membershipKey: selectors.getCustomerMembershipKey(state),
    search: membersSelectors.getCustomerSearch(state),
    customerDataLoading: selectors.getCustomerDataLoading(state),
    businessDisplayName: selectors.getBusinessDisplayName(state),
    countryCode: selectors.getCountryCode(state),
    features: selectors.getFeatures(state),
    unsubscribeMessage: selectors.getUnsubscribeMessage(state),
    localeDateFormat: selectors.getBrowserLocaleDateFormat(state),
    localeTimeFormat: selectors.getBrowserLocaleTimeFormat(state),
    registrationFormFields: formsSelectors.getRegistrationFormFields(state),
    mentionsList: mentionsSelectors.getTransformedMentions(state),
    emailMentionsList: mentionsSelectors.getEmailMentionsTransformed(state),
    user: selectors.getUser(state)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withAppSaga = injectSaga({ key: routerConstants.APP_BRANCH, saga: appSaga });
const withFormsSaga = injectSaga({ key: routerConstants.FORMS_BRANCH, saga: formsSaga });
const withMailTemplateSaga = injectSaga({ key: mailTemplateConstants.MAIL_TEMPLATES, saga: mailTemplateSaga });

export default compose(withAppSaga, withFormsSaga, withMailTemplateSaga, withConnect, injectIntl)(HubCustomer);
