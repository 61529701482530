/*
 *
 * ComilliaHint actions
 *
 */

import * as constants from './comilliaHint.constants';

export function handleHintAppearance(show) {
  return {
    type: constants.HANDLE_HINT_APPEARANCE,
    show
  };
}
