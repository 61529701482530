import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import injectSaga from '../../../utils/injectSaga';
import * as formsListActions from '../../../features/Forms/forms.actions';
import saga from '../../../features/Forms/forms.saga';
import * as constants from '../../../features/Forms/forms.constants';

import DropdownWrapper from '../DropdownWrapper/DropdownWrapper';
import { getFormsList, getFormsListFetchedFlag } from '../../../features/Forms/forms.selectors';
import './formsList.styles.less';

export class FormsList extends React.PureComponent {

  static propTypes = {
    actions: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    forms: PropTypes.object.isRequired,
    formsFetched: PropTypes.bool,
    index: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    prefix: PropTypes.string,
    error: PropTypes.string,
  };

  componentDidMount() {
    if (!this.props.formsFetched) {
      this.props.actions.getFormsList();
    }
  }

  parseFormsList = (list) => list.map((form) => ({
    key: form.get(constants.FORM_KEY),
    text: form.get(constants.FORM_TITLE),
    value: form.get(constants.FORM_KEY)
  })).toJS();

  render() {
    return (
      <DropdownWrapper
        options={this.parseFormsList(this.props.forms)}
        onSelectOption={this.props.onChange}
        value={this.props.value}
        disabled={this.props.disabled}
        index={this.props.index}
        prefix={this.props.prefix}
        error={this.props.error}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  forms: getFormsList(state),
  formsFetched: getFormsListFetchedFlag(state)
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(formsListActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: constants.FORMS, saga });

export default compose(
  withConnect,
  withSaga,
)(FormsList);
