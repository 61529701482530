import React, { Component } from 'react';
import PropTypes from 'prop-types';

const BulkCode = (props) => (

    <div className="bulk-code">
      <div className="code-section">
        <span className="index">{props.index + 1}.</span><span>{props.code}</span>
        <div
          className="bulk-code-copy" onClick={() => {
          props.copyCode(props.code);
        }}
        >
          <i className="icon como-ic-duplicate" />
        </div>
      </div>
      <div className="details-section">
        <span className="bulk-code-status">{props.status}</span>
      </div>
    </div>
  );

BulkCode.propTypes = {
  index: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  copyCode: PropTypes.func.isRequired,
};

export default BulkCode;
