import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import classNames from 'classnames';

import injectSaga from '../../../../utils/injectSaga';
import * as benefitsListActions from '../../benefits.actions';
import saga from '../../benefits.saga';
import * as constants from '../../benefits.constants';

import BenefitsListContent from './BenefitsListContent';
import PopupWrapper from '../../../../common/components/PopupWrapper/PopupWrapper';
import { getBenefitsList, getBenefitsListFetchedFlag, getSelectedBenefit } from '../../benefits.selector';
import PopupTrigger from '../../../../common/components/PopupTrigger/PopupTrigger';
import { getLocationId } from '../../../App/selectors';

export class BenefitsList extends React.PureComponent {

  static propTypes = {
    actions: PropTypes.object.isRequired,
    allowCreateGift: PropTypes.bool,
    benefitsList: PropTypes.object.isRequired,
    benefitsListFetched: PropTypes.bool,
    benefitType: PropTypes.string,
    className: PropTypes.string,
    createGiftFromActivity: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    index: PropTypes.number,
    locationId: PropTypes.number.isRequired,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    prefix: PropTypes.string,
    selectedBenefit: PropTypes.object,
    value: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      isPopupOpen: false
    };
  }

  componentWillMount() {
    if (!this.props.benefitsListFetched) {
      this.props.actions.getBenefits();
    }
  }

  onChange = (benefit) => {
    this.handlePopupClose();
    this.props.onChange(null, {
      name: this.props.name,
      value: benefit[constants.BENEFIT_SERVER_ID],
      type: benefit[constants.BENEFIT_TYPE],
      serverEntityKey: benefit[constants.BENEFIT_SERVER_ENTITY_KEY],
      validUntil: benefit[constants.BENEFIT_VALID_UNTIL],
      title: benefit[constants.BENEFIT_TITLE]
    });
  };

  onCreateGift = () => {
    this.props.createGiftFromActivity(this.props.index);
  };

  getBenefitListTriggerValue = (benefitType, selectedBenefit) => {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    if (selectedBenefit) {
      return selectedBenefit.get('title');
    }

    if (benefitType === constants.PUNCH_CARD) {
      return formatMessage({ id: 'activity.triggers.selectPunchCard' });
    }

    return formatMessage({ id: 'activity.triggers.selectBenefit' });

  };

  handlePopupOpen = () => {
    this.setState({
      isPopupOpen: !this.props.disabled
    });
  };

  handlePopupClose = () => {
    this.setState({
      isPopupOpen: false
    });
  };

  render() {

    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const { benefitType } = this.props;
    const benefitsListTriggerClassName = classNames(`ui fluid selection dropdown complex-list-trigger ${this.props.className}`, {
      'inputFieldError': this.props.error,
      'placeholder': !this.props.value,
      'disabled': this.props.disabled
    });

    return (
      <PopupWrapper
        className="complex-list"
        disabled={this.props.disabled}
        hideTrigger
        on="click"
        onOpen={this.handlePopupOpen}
        onClose={this.handlePopupClose}
        open={this.state.isPopupOpen}
        position="bottom left"
        automationId={`${this.props.prefix}.popup`}
        trigger={(
          <div className="inline-div">
            <PopupTrigger
              automationId={this.props.prefix}
              className={benefitsListTriggerClassName}
              message={this.getBenefitListTriggerValue(benefitType, this.props.selectedBenefit)}
              validationErrorMessage={this.props.error ? formatMessage({ id: this.props.error }) : null}
              validationErrorClassName={this.props.error ? 'complex-list-error' : ''}
            />
          </div>
          )}
      >
        <BenefitsListContent
          allowCreateGift={this.props.allowCreateGift}
          automationIdPrefix={this.props.prefix}
          benefits={this.props.benefitsList}
          benefitType={this.props.benefitType}
          index={this.props.index}
          onCreateGift={this.onCreateGift}
          onSelectOption={this.onChange}
          selectedBenefit={this.props.selectedBenefit}
          value={this.props.value}
        />
      </PopupWrapper>
    );
  }
}

const mapStateToProps = (state, props) => ({
  benefitsList: getBenefitsList(state),
  benefitsListFetched: getBenefitsListFetchedFlag(state),
  locationId: getLocationId(state),
  selectedBenefit: getSelectedBenefit(state, props.value),
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(benefitsListActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: constants.BENEFITS, saga });

export default compose(
  withConnect,
  withSaga,
  injectIntl
)(BenefitsList);
