/*
 *
 * Forms reducer
 *
 */

import { fromJS } from 'immutable';
import * as constants from './forms.constants';

const initialState = fromJS({
  [constants.FORMS_LIST]: []
});

function formsReducer(state = initialState, action) {
  let newState;

  switch (action.type) {
    case constants.GET_FORMS_SUCCESS: {
      newState = state
        .set(constants.FORMS_LIST, fromJS(action.data))
        .set(constants.FORMS_LIST_LOADED, fromJS(true));
      break;
    }
    case constants.GET_REGISTRATION_FORM_FIELDS_SUCCESS: {
      newState = state.set(constants.REGISTRATION_FORM_FIELDS, fromJS(action.data))
                      .set(constants.REGISTRATION_FORM_FIELDS_FETCHED, fromJS(true));
      break;
    }
    default:
      newState = state;
  }
  return newState;
}

export default formsReducer;
