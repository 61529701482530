import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';

class SearchItems extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string.isRequired,
    query: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  render() {

    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    return (
      <Input
        className="items-population-search"
        icon="search"
        placeholder={formatMessage({ id: 'activity.itemsPopulation.itemGroups.search.placeholder', defaultMessage: 'Search Group' })} // todo - insert to translations
        fluid
        onChange={(e, data) => { this.props.onChange(data.value); }}
        data-automation-id={`${this.props.automationIdPrefix}.searchBox`}
        value={this.props.query}
      />
    );
  }
}

export default injectIntl(SearchItems);

