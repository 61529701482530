import { createSelector } from 'reselect';
import * as constants from './promoCodes.constants';
import * as utils from './utils/promoCodes.selectors.utils';
import * as activityConstants from '../Activity/activity.constants';
import * as reducerConstants from '../../constants/reducer.constants';

export const getPromoCodes = (state) => state.getIn([reducerConstants.PROMO_CODES_BRANCH, constants.PROMO_CODES]);
export const getFilters = (state) => state.getIn([reducerConstants.PROMO_CODES_BRANCH, constants.FILTERS]);
export const getOrder = (state) => state.getIn([reducerConstants.PROMO_CODES_BRANCH, constants.SORT, constants.ORDER]);
export const getPromoCodesLoadedFlag = (state) => state.getIn([reducerConstants.PROMO_CODES_BRANCH, constants.PROMO_CODES_LOADED]);
export const getHighlightedPromoCodeId = (state) => state.getIn([reducerConstants.PROMO_CODES_BRANCH, activityConstants.HIGHLIGHTED_ACTIVITY_ID]);
export const getSearchTerm = (state) => state.getIn([reducerConstants.PROMO_CODES_BRANCH, constants.SEARCH_TERM]);
export const getFilteredAndSortedPromoCodes = createSelector([getPromoCodes, getFilters, getOrder, getSearchTerm], utils.getFilteredAndSortedPromoCodesImpl);
export const isPromoCodesFiltered = createSelector([getPromoCodes, getFilteredAndSortedPromoCodes], utils.isPromoCodesFilteredImpl);
export const getContextMenuItemsVisibilityMap = utils.getContextMenuItemsVisibilityMap; // maybe will memoize in the future
export const getCPromoCodesStats = (state) => state.getIn([reducerConstants.PROMO_CODES_BRANCH, constants.PROMO_CODES_STATS]);
export const getBulkCodesList = (state) => state.getIn([reducerConstants.PROMO_CODES_BRANCH, constants.BULK_CODES_LIST]);
export const getBulkCodeModalState = (state) => state.getIn([reducerConstants.PROMO_CODES_BRANCH, constants.BULK_CODES_LIST_MODAL_VISIBILITY_STATUS]);
export const getBulkCodesExportLoadingStatuses = (state) => state.getIn([reducerConstants.PROMO_CODES_BRANCH, constants.BULK_EXPORT_LOADING_STATUSES]);
export const getBulkCodesExportLoadingStatusByCode = (state, key) => {
  const statuses = getBulkCodesExportLoadingStatuses(state);

  return !!statuses.find((item) => item.get('key') === key);
};
