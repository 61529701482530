import _ from 'lodash';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Button, Form, Grid } from 'semantic-ui-react';
import * as activityConstants from '../../../Activity/activity.constants';
import * as constants from '../../templates.constants';
import DropDown from '../../../../common/components/DropdownWrapper/DropdownWrapper';
import Modal from '../../../../common/components/CustomModal/CustomModal';
import './SaveTemplate.styles.less';
import * as businessSelectors from '../../../App/selectors';
import * as templateSelectors from '../../templates.selectors';

const AUTOMATION_PREFIX_NAME = 'save-as-template';

class SaveTemplate extends React.PureComponent {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    locationId: PropTypes.number,
    businessesVerticals: PropTypes.object,
    templateType: PropTypes.string,
    saveAsTemplate: PropTypes.func.isRequired,
    trigger: PropTypes.node,
    onClose: PropTypes.func,
    isOpen: PropTypes.bool,
    name: PropTypes.string,
    header: PropTypes.string,
    entity: PropTypes.object,
    entityType: PropTypes.string,
    prefix: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string,
    categories: PropTypes.object,
    categoryDbId: PropTypes.string,
    templateVerticals: PropTypes.object,
    saveTemplateInProgress: PropTypes.bool,
  };

  static defaultProps = {
    name: '',
    description: '',
    header: 'modal.header.edit.template'
  };

  constructor(props) {
    super();
    const { name, description, categoryDbId, isOpen, templateVerticals, categories } = props;
    const newState = {
      isModalOpen: isOpen,
      name,
      description,
      categoryDbId,
      templateVerticals: templateVerticals ? templateVerticals.toJS() : [],
    };

    if (_.isNil(categoryDbId) && !_.isNil(categories) && categories.size > 0) {
      const findDefaultCategory = categories.find((obj) => obj.get(constants.NAME) === constants.DEFAULT_CATEGORY);
      newState.defaultCategoryDbId = findDefaultCategory ? findDefaultCategory.get(constants.DB_ID) : null;
    }

    this.state = newState;
  }

  componentWillReceiveProps(nextProps) {
    const { name, description, categoryDbId, templateVerticals, saveTemplateInProgress } = this.props;
    const newState = {};

    if (categoryDbId !== nextProps.categoryDbId) {
      newState.categoryDbId = nextProps.categoryDbId;
    }

    if (_.isNil(nextProps.categoryDbId) && !_.isNil(nextProps.categories) && nextProps.categories.size > 0) {
      const findDefaultCategory = nextProps.categories.find((obj) => obj.get(constants.NAME) === constants.DEFAULT_CATEGORY);
      newState.defaultCategoryDbId = findDefaultCategory ? findDefaultCategory.get(constants.DB_ID) : null;
    }

    if (!_.isNil(nextProps.templateVerticals) && templateVerticals !== nextProps.templateVerticals) {
      newState.templateVerticals = nextProps.templateVerticals.toJS();
    }

    if (name !== nextProps.name) {
      newState.name = nextProps.name;
    }

    if (description !== nextProps.description) {
      newState.description = nextProps.description;
    }

    if (saveTemplateInProgress && !nextProps.saveTemplateInProgress) {
      this.handlerModalClose();
    }

    this.setState(newState);
  }

  handlerModalOpen = () => {
    this.setState({
      isModalOpen: true
    });
  };

  handleOnClose = () => {
    const { name, description, categoryDbId } = this.props;
    this.setState({ name, description, categoryDbId });
    this.handlerModalClose();
  };

  handlerModalClose = () => {
    this.setState({
      isModalOpen: false
    }, this.props.onClose);
  };

  handlerSave = () => {
    const { name, description, categoryDbId, templateVerticals, defaultCategoryDbId } = this.state;
    const { entity, saveAsTemplate, locationId, id } = this.props;
    const params = {};

    if (entity) {
      params.entity = { ...(entity.toJS()), status: activityConstants.ACTIVITY_STATUS_DRAFT };
    }

    if (id) {
      params.dbId = id;
    }

    if (locationId) {
      params.locationId = locationId;
    }

    if (_.isString(name) && name.length > 0) {
      params.templateName = name;
    }

    if (_.isString(description) && description.length > 0) {
      params.templateDescription = description;
    }

    if (_.isString(categoryDbId) && categoryDbId.length > 0) {
      params.categoryDbId = categoryDbId;
    } else {
      params.categoryDbId = defaultCategoryDbId;
    }

    params.verticals = templateVerticals;

    saveAsTemplate(params, this.props.entityType);
  };

  handlerName = (e, data = {}) => {
    this.setState({ name: data.value });
  };

  handlerDescription = (e, data = {}) => {
    this.setState({ description: data.value });
  };

  handleOnChangeCategory = (e, data) => {
    this.setState({ categoryDbId: data.value });
  };

  handleOnChangeVerticalsDropDown = (e, data) => {
    this.setState({ templateVerticals: data.value });
  };

  genCategoriesOption() {
    return this.props.categories.map((category) => ({ text: category.get(constants.NAME), value: category.get(constants.DB_ID) })).toArray();
  }

  modalContentHtml() {
    const { categories, businessesVerticals, templateType } = this.props;
    const { formatMessage } = this.props.intl;
    const { name, description, categoryDbId, templateVerticals, defaultCategoryDbId } = this.state;
    const nameColumnWidth = templateType === constants.TEMPLATE_TYPE_PRODUCT ? 6 : 12;
    return (
      <Form>
        <Grid>
          <Grid.Row>
            <Grid.Column width={nameColumnWidth}>
              <Form.Input
                name="name"
                value={name}
                onChange={this.handlerName}
                placeholder={formatMessage({ id: 'activity.save-as-template.label.name' })}
                data-automation-id={`${AUTOMATION_PREFIX_NAME}.name`}
              />
            </Grid.Column>
            {categories && templateType === constants.TEMPLATE_TYPE_PRODUCT &&
            <Grid.Column width={6}>
              <div>
                <DropDown
                  name="categories"
                  options={this.genCategoriesOption()}
                  onSelectOption={this.handleOnChangeCategory}
                  prefix={`${AUTOMATION_PREFIX_NAME}.dropdown`}
                  hideTrigger
                  selection
                  value={categoryDbId}
                  placeholder={formatMessage({ id: 'activity.save-as-template.label.categories' })}
                  defaultValue={defaultCategoryDbId}
                />
              </div>
            </Grid.Column>
            }
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Form.TextArea
                name="description"
                value={description}
                autoHeight
                rows={4}
                onChange={this.handlerDescription}
                placeholder={formatMessage({ id: 'activity.save-as-template.label.description' })}
                data-automation-id={`${AUTOMATION_PREFIX_NAME}.description`}
              />
            </Grid.Column>
          </Grid.Row>
          { businessesVerticals && templateType === constants.TEMPLATE_TYPE_PRODUCT && _.isArray(templateVerticals) &&
          <Grid.Row>
            <Grid.Column width={6}>
              <div>
                <DropDown
                  className="verticals"
                  placeholder="Choose verticals"
                  prefix={`${AUTOMATION_PREFIX_NAME}.verticals.dropdown`}
                  fluid
                  multiple
                  selection
                  onSelectOption={this.handleOnChangeVerticalsDropDown}
                  options={businessesVerticals.filter((vertical) => vertical != null).map((vertical) => ({ text: vertical, value: vertical })).toArray()}
                  value={templateVerticals}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
          }
        </Grid>
      </Form>
    );
  }

  modalActionsHtml() {
    return [
      <Button
        key="cancel"
        className="link"
        basic
        data-automation-id={`${AUTOMATION_PREFIX_NAME}.btn.cancel`}
        onClick={this.handleOnClose}
      >
        {this.props.intl.formatMessage({ id: 'activity.save-as-template.cancel' })}
      </Button>,
      <Button
        key="save"
        data-automation-id={`${AUTOMATION_PREFIX_NAME}.btn.save`}
        onClick={this.handlerSave}
        loading={this.props.saveTemplateInProgress}
        disabled={_.size(this.state.name.trim()) === 0}
      >
        {this.props.intl.formatMessage({ id: 'activity.save-as-template.save' })}
      </Button>
    ];
  }

  render() {
    const { prefix, trigger, header } = this.props;
    return (
      <Modal
        className="modal__save-as-template como-dialog"
        header={this.props.intl.formatMessage({ id: header })}
        trigger={trigger}
        content={this.modalContentHtml()}
        onOpen={this.handlerModalOpen}
        onClose={this.handleOnClose}
        open={this.state.isModalOpen}
        actions={this.modalActionsHtml()}
        automationIdPrefix={`${prefix}.${AUTOMATION_PREFIX_NAME}.save-as-template`}
        size={this.props.templateType === constants.TEMPLATE_TYPE_PRODUCT ? 'small' : 'mini'}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  saveTemplateInProgress: templateSelectors.getSaveTemplateInProgressFlag(state),
  locationId: businessSelectors.getLocationId(state),
  businessesVerticals: templateSelectors.getBusinessVerticals(state)
});

const withConnect = connect(mapStateToProps, null);

export default compose(
  withConnect,
  injectIntl,
)(SaveTemplate);
