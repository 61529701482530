import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import * as activityConstants from '../../Activity/activity.constants';
import tracker from '../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../common/utils/tracking/tracking.consts';
import ContextMenu from '../../../common/components/ContextMenu/ContextMenu';

class EmailContextMenu extends React.PureComponent {
  static propTypes = {
    onDetailsOpen: PropTypes.func.isRequired,
    hidden: PropTypes.bool,
    automationId: PropTypes.string,
    intl: PropTypes.object,
    detailsForTracker: PropTypes.object.isRequired,
    onAnalysisOpen: PropTypes.func,
    onOpenInNewTab: PropTypes.func.isRequired,
    onUsagesOpen: PropTypes.func,
    onChangeEmailStatus: PropTypes.func,
    emailViewStatus: PropTypes.string,
    email: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      showDeactivateConfirmationDialog: false
    };
  }

  getMenuOptions() {
    const { formatMessage } = this.props.intl;
    const menu = [{
      key: activityConstants.CONTEXT_MENU_OPTION_DETAILS,
      iconName: 'como-ic-info',
      text: formatMessage({ id: 'emails-page.activity.menu.activity-details' }),
      cb: this.handleShowDetailsClick
    }, {
      key: activityConstants.CONTEXT_MENU_OPTION_STATS,
      iconName: 'como-ic-activity-analysis',
      text: formatMessage({ id: 'emails-page.activity.menu.activity-analysis' }),
      cb: this.handleShowAnalysisClick
    }, {
      key: activityConstants.CONTEXT_MENU_OPTION_USAGES,
      iconName: 'como-ic-related-activities',
      text: formatMessage({ id: 'emails-page.activity.menu.activity-usages' }),
      cb: this.handleShowUsagesClick
    }];
    if (this.props.emailViewStatus === activityConstants.ACTIVITY_STATUS_ACTIVE) {
      menu.push({
        key: activityConstants.CONTEXT_MENU_OPTION_DEACTIVATE,
        iconName: 'como-ic-deactivate',
        text: formatMessage({id: 'emails-page.activity.menu.stop'}),
        cb: () => this.props.onChangeEmailStatus(this.props.email)
      });
    } else {
      menu.push({
        key: activityConstants.CONTEXT_MENU_OPTION_ACTIVATE,
        iconName: 'como-ic-ok',
        text: formatMessage({id: 'emails-page.activity.menu.activate'}),
        cb: () => this.props.onChangeEmailStatus(this.props.email)
      });
    }
    menu.push({
      key: 'openInNewTab',
      className: 'benefit-action-list-item-set-up-key',
      iconName: 'como-ic-arrow-right',
      text: formatMessage({ id: 'business-center.campaign.activity.menu.openInNewTab' }),
      cb: () => this.props.onOpenInNewTab(this.props.email)
    });

    return menu;
  }

  handleShowDetailsClick = () => {
    tracker.onButtonClick(trackerConstants.BUTTON_TYPE_SHOW_EMAIL_DETAILS, this.props.detailsForTracker);
    this.props.onDetailsOpen();
  };

  handleShowAnalysisClick = () => {
    tracker.onButtonClick(trackerConstants.BUTTON_TYPE_SHOW_EMAIL_ANALYSIS);
    this.props.onAnalysisOpen();
  };

  handleShowUsagesClick = () => {
    tracker.onButtonClick(trackerConstants.BUTTON_TYPE_SHOW_EMAIL_USAGES);
    this.props.onUsagesOpen();
  };

  render() {
    return (
      <React.Fragment>
        <ContextMenu
          options={this.getMenuOptions()}
          automationId={this.props.automationId}
          hidden={this.props.hidden}
        />
      </React.Fragment>);
  }
}

export default injectIntl(EmailContextMenu);
