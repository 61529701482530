import axios from 'axios';

export function getEmails() {
  return axios.get('/api/emails')
  .then((res) => res.data);
}

export function getEmailStats(hubEmailId) {
  return axios.get(`/api/emails/getStats?hubEmailId=${hubEmailId}`)
    .then((res) => res.data);
}

export function getEmailStatsPerCampaign(hubEmailId, campaignId) {
  return axios.get(`/api/emails/getStatsPerCampaign?hubEmailId=${hubEmailId}&campaignId=${encodeURIComponent(campaignId)}`)
    .then((res) => res.data);
}

export function getEmailStatsPerRule(hubEmailId, campaigns) {
  const campaignsStr = campaigns.join(',');
  return axios.get(`/api/emails/getStatsPerRule?hubEmailId=${hubEmailId}&campaignIds=${encodeURIComponent(campaignsStr)}`)
    .then((res) => res.data);
}

export function getEmailUsages(emailId) {
  return axios.get(`/api/Emails/usages/${emailId}`).then((res) => res.data);
}
