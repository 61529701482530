import { fromJS } from 'immutable';
import moment from 'moment';
import * as validationUtils from './conditions.validation.utils';
import * as constants from '../../../activity.constants';
import { getSchema } from '../../../activitySchema/activitySchema.selectors';
import { validateLimitOccurrencesPerMemberGlobalConditions } from './validation.utils';
import * as schemaConstants from '../../../activitySchema/activitySchema.constants';
import * as datesConstants from '../../../../../common/utils/dateTime.constants';

const IMPORT_MEMBERS_LIMIT_COUNT = 15000;


export function validateGlobalConditions(state, rootState) {
  let newState = state;
  newState = validateMembershipGlobalCondition(newState, rootState);
  newState = validateDateTimeGlobalConditions(newState);
  newState = validateTempDateTimeGlobalConditions(newState);
  newState = validateLimitOccurrencesPerMemberGlobalConditions(newState);
  newState = validateGiftInstanceExpirationGlobalCondition(newState);
  newState = validateFilteredPopulation(newState);
  return newState;
}

function validateFilteredPopulation(state) {
  let newState = state;
  if (newState.getIn([constants.DATA, constants.TYPE]) === constants.ACTIVITY_TYPE_ONE_TIME) {
    const populationSize = newState.getIn([constants.DATA, constants.ONE_TIME_ACTION_FILTERED_POPULATION, constants.FILTERED_NUM_OF_MEMBERS]);
    if (!populationSize) {
      newState = newState.setIn([constants.VALIDATION_ERRORS, constants.GLOBAL_CONDITIONS, constants.MEMBERSHIP_GLOBAL_CONDITIONS], fromJS({}));
    }
    else
    {
      // Validate A/B testing condition here if Abtest mode is on.
      const abTestMode = newState.getIn([constants.DATA, constants.AB_TEST_MODE]);
      if (populationSize < constants.MINIMUM_FILTERED_POPULATION_COUNT_FOR__TESTING && abTestMode) {
        newState = newState.setIn([constants.VALIDATION_ERRORS, constants.GLOBAL_CONDITIONS, constants.MEMBERSHIP_GLOBAL_CONDITIONS], fromJS({}));
      }
    }
  }
  return newState;
}

export function validateFiltersInTempModel(state, rootState) {
  return validateMembershipConditionTempModel(state, rootState);
}

export function isImportMembersLimitExceeded(importMembersCount) {
  return importMembersCount > IMPORT_MEMBERS_LIMIT_COUNT;
}

function validateDateTimeGlobalConditions(state) {
  return validateDateTimeConditions(state, [constants.DATA, constants.DATETIME_GLOBAL_CONDITIONS], [constants.VALIDATION_ERRORS]);
}

function validateTempDateTimeGlobalConditions(state) {
  return validateDateTimeConditions(state, [constants.TEMP_DATETIME_GLOBAL_CONDITIONS], [constants.TEMP_VALIDATION_ERRORS]);
}

function validateDateTimeConditions(state, dataPath, validationErrorsPath) {
  let newState = state;
  const dateTimeConditionErrors = {};
  if (!newState.getIn(dataPath)) {
    return newState;
  }
  let datesRangeConditionErrors = {};
  let daysTimesConditionErrors = {};
  if (newState.getIn([...dataPath, constants.DATES_RANGE_CONDITION])) {
    datesRangeConditionErrors = validationUtils.validateCondition(newState.getIn([...dataPath, constants.DATES_RANGE_CONDITION]));
  }
  if (Object.keys(datesRangeConditionErrors).length !== 0) {
    dateTimeConditionErrors[constants.DATES_RANGE_CONDITION] = datesRangeConditionErrors;
  }
  if (newState.getIn([...dataPath, constants.DAYS_TIMES_CONDITION])) {
    daysTimesConditionErrors = validationUtils.validateCondition(newState.getIn([...dataPath, constants.DAYS_TIMES_CONDITION]));
  }
  if (Object.keys(daysTimesConditionErrors).length !== 0) {
    dateTimeConditionErrors[constants.DAYS_TIMES_CONDITION] = daysTimesConditionErrors;
  }
  if (Object.keys(dateTimeConditionErrors).length !== 0) {
    newState = newState.setIn([...validationErrorsPath, constants.GLOBAL_CONDITIONS, constants.DATETIME_GLOBAL_CONDITIONS], fromJS(dateTimeConditionErrors));
  }
  return newState;
}

function validateMembershipGlobalCondition(state, rootState) {
  let newState = state;
  newState = validateMembershipConditionCurrentModel(newState, rootState);
  newState = validateMembershipConditionTempModel(newState, rootState);
  return newState;
}

function validateMembershipConditionCurrentModel(state, rootState) {
  return validateMembershipConditionModel(
    state, rootState, [constants.DATA, constants.MEMBERSHIP_GLOBAL_CONDITIONS, constants.CONDITIONS, constants.CONDITIONS_LIST], [constants.VALIDATION_ERRORS, constants.GLOBAL_CONDITIONS, constants.MEMBERSHIP_GLOBAL_CONDITIONS]);
}

function validateUserActionAggregationConditions(userAction, validationErrorsPath, state) {
  let newState = state;
  if (userAction.get(constants.USER_ACTION_AGGREGATION_CONDITIONS)) {
    userAction.get(constants.USER_ACTION_AGGREGATION_CONDITIONS).forEach((aggregationConditionWrapper, conditionIndex) => {
      if (aggregationConditionWrapper.get(constants.IS_USER_ACTION_AGGREGATED_CONDITIONS_ACTIVE)) {
        const aggregationCondition = aggregationConditionWrapper.get(constants.CONDITION);
        const conditionErrors = validationUtils.validateCondition(aggregationCondition);
        if (Object.keys(conditionErrors).length > 0) {
          const conditionErrorPath = [...validationErrorsPath, constants.USER_ACTION_AGGREGATION_CONDITIONS, conditionIndex];
          newState = newState.setIn(conditionErrorPath, fromJS(conditionErrors));
        }
      }
    });
  }
  return newState;
}

function validateMembershipConditionTempModel(state, rootState) {
  const membershipFilterType = state.getIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.TYPE]);
  let newState = state;
  switch (membershipFilterType) {
    case constants.MEMBERS_SPECIFIC: {
      const errorPath = [constants.TEMP_VALIDATION_ERRORS, constants.GLOBAL_CONDITIONS, constants.MEMBERSHIP_GLOBAL_CONDITIONS];
      newState = validateMembershipConditionModel(
        newState, rootState, [constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.CONDITIONS, constants.CONDITIONS_LIST], errorPath);
      const userActions = newState.getIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS, constants.USER_ACTIONS_CONDITIONS_LIST]);
      if (userActions) {
        userActions.forEach((userAction, index) => {
          const validationErrorsPath = [...errorPath, constants.USER_ACTIONS_CONDITIONS, index];
          newState = validateMembershipConditionModel(
            newState, rootState, [constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS, constants.USER_ACTIONS_CONDITIONS_LIST, index, constants.CONDITIONS, constants.CONDITIONS_LIST],
            validationErrorsPath);
          const dateConditionPath = [constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.USER_ACTIONS_CONDITIONS, constants.USER_ACTIONS_CONDITIONS_LIST, index, constants.USER_ACTION_DATE_RANGE_CONDITION];
          newState = validateDateCondition(newState, dateConditionPath, validationErrorsPath);
          newState = validateUserActionAggregationConditions(userAction, validationErrorsPath, newState);
        });
      }
      break;
    }
    case constants.MEMBERS_PHONENUMBER_IMPORT:
    case constants.MEMBERS_IMPORT: {
      const importConditionWrapper = newState.getIn([constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER]);
      if (importConditionWrapper.get(constants.IMPORT_MEMBERS_MODE) === constants.IMPORT_MEMBERS_COPY_PASTE_MODE) {
        const conditionError = validationUtils.validateCondition(importConditionWrapper.get(constants.IMPORT_MEMBERS_COPY_PASTE_MODE));
        if (Object.keys(conditionError).length > 0) {
          const errorPath = [constants.TEMP_VALIDATION_ERRORS, constants.GLOBAL_CONDITIONS,
            constants.MEMBERSHIP_GLOBAL_CONDITIONS, constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER,
            constants.IMPORT_MEMBERS_COPY_PASTE_MODE];
          newState = newState.setIn(errorPath, fromJS(conditionError));
        }
      } else {
        const fileName = importConditionWrapper.getIn([constants.IMPORT_MEMBERS_UPLOAD_FILE_MODE, constants.CONDITION_VALUE, constants.UPLOADED_FILE_NAME]);
        if (!fileName) {
          const errorPath = [constants.TEMP_VALIDATION_ERRORS, constants.GLOBAL_CONDITIONS,
            constants.MEMBERSHIP_GLOBAL_CONDITIONS, constants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER,
            constants.IMPORT_MEMBERS_UPLOAD_FILE_MODE, constants.CONDITION_VALUE];
          newState = newState.setIn(errorPath, 'activity.validation.error.condition.value.required');
        }
      }
      break;
    }
    default:
      return state; // if all registered members selected skip validation on condition list
  }

  return newState;
}

function validateDateCondition(state, conditionPath, validationErrorsPath) {
  const dateCondition = state.getIn(conditionPath);
  if (!dateCondition) {
    return state;
  }
  let newState = state;
  if (dateCondition.get(constants.CONDITION_KEY) === schemaConstants.CONTEXT_TIMESTAMP &&
    dateCondition.get(constants.OPERATOR_KEY) === datesConstants.DATE_TIME_IS_BETWEEN) {
    const dateFromString = dateCondition.getIn([constants.CONDITION_VALUE, datesConstants.DATE_FROM]);
    const dateFrom = moment(dateFromString);
    const dateToString = dateCondition.getIn([constants.CONDITION_VALUE, datesConstants.DATE_TO]);
    const dateTo = moment(dateToString);
    if (!dateFrom.isValid() || !dateTo.isValid() || !dateFrom.isBefore(dateTo)) {
      const conditionErrors = 'activity.validation.error.condition.value.dateTime.endHourAfterStart';
      newState = newState.setIn([...validationErrorsPath, constants.USER_ACTION_DATE_RANGE_CONDITION, constants.CONDITION_VALUE], conditionErrors);
    }
  }
  return newState;
}

function validateMembershipConditionModel(state, rootState, dataPath, validationErrorsPath) {
  const conditionsList = state.getIn(dataPath);
  if (!conditionsList) {
    return state;
  }
  let newState = state;
  const conditionsListErrors = validationUtils.validateConditionsList(conditionsList);
  const registrationFormFieldsErrors = validationUtils.validateRegistrationFormFieldsInConditions(
    conditionsList, rootState, getSchema(state), newState.getIn([constants.DATA, constants.TRIGGER]));
  let schemaFormFieldsErrors = {};
  if (!validationErrorsPath.includes(constants.USER_ACTIONS_CONDITIONS)) {
    schemaFormFieldsErrors = validationUtils.validateSchemaFormFieldsInConditions(conditionsList, getSchema(state), newState.getIn([constants.DATA, constants.TRIGGER]));
  }
  const membershipConditionErrors = Object.assign({}, conditionsListErrors, registrationFormFieldsErrors, schemaFormFieldsErrors);
  if (Object.keys(membershipConditionErrors).length > 0) {
    const errors = fromJS(membershipConditionErrors);
    newState = newState.setIn([...validationErrorsPath, constants.CONDITIONS], errors);
  }
  return newState;
}

function validateGiftInstanceExpirationGlobalCondition(state) {
  let newState = state;
  newState = validateInstanceExpirationConditionCurrentModel(newState);
  newState = validateInstanceExpirationConditionTempModel(newState);
  return newState;
}

function validateInstanceExpirationConditionCurrentModel(state) {
  return validateInstanceExpirationConditionModel(
    state, [constants.DATA, constants.INSTANCE_EXPIRATION_GLOBAL_CONDITION], constants.VALIDATION_ERRORS);
}

function validateInstanceExpirationConditionTempModel(state) {
  return validateInstanceExpirationConditionModel(
    state, [constants.INSTANCE_EXPIRATION_GLOBAL_CONDITION_TEMP_MODEL], constants.TEMP_VALIDATION_ERRORS);
}

function validateInstanceExpirationConditionModel(state, modelPath, errorsRoot) {
  const model = state.getIn(modelPath);
  if (!model) {
    return state;
  }
  const errors = {};
  validateIfGiftActivationDeactivationCondition(model, constants.ACTIVATION_CONDITION, errors);
  validateIfGiftActivationDeactivationCondition(model, constants.DEACTIVATION_CONDITION, errors);
  const errorsPath = [errorsRoot, constants.GLOBAL_CONDITIONS, constants.INSTANCE_EXPIRATION_GLOBAL_CONDITION];
  if (Object.keys(errors).length > 0) {
    return state.setIn(errorsPath, fromJS(errors));
  }
  const activationCondition = model.get(constants.ACTIVATION_CONDITION);
  const deactivationCondition = model.get(constants.DEACTIVATION_CONDITION);
  if (activationCondition.get(constants.ACTIVATION_MODE) ===
    deactivationCondition.get(constants.ACTIVATION_MODE) &&
    activationCondition.get(constants.ACTIVATION_MODE) === constants.ACTIVATION_MODE_ON_SPECIFIC_TIME) {
    const unitsFactor = {
      [datesConstants.MINUTE]: 1,
      [datesConstants.HOUR]: 60,
      [datesConstants.DAY]: 60 * 24,
      [datesConstants.WEEK]: 60 * 24 * 7
    };
    const activationTimeSpan = activationCondition.get(constants.ACTIVATION_SPECIFIC_TIME_VALUE) *
      unitsFactor[activationCondition.get(constants.ACTIVATION_SPECIFIC_TIME_UNIT)];
    const deactivationTimeSpan = deactivationCondition.get(constants.ACTIVATION_SPECIFIC_TIME_VALUE) *
      unitsFactor[deactivationCondition.get(constants.ACTIVATION_SPECIFIC_TIME_UNIT)];
    if (activationTimeSpan >= deactivationTimeSpan) {
      errors[constants.DEACTIVATION_CONDITION] = 'activity.validation.error.condition.value.dateTime.endHourAfterStart';
      return state.setIn(errorsPath, fromJS(errors));
    }
  }
  return state;
}

function validateIfGiftActivationDeactivationCondition(model, conditionKey, errors) {
  const condition = model.get(conditionKey);
  if (condition.get(constants.ACTIVATION_MODE) === constants.ACTIVATION_MODE_DEFAULT) {
    return;
  }
  const timeValue = condition.get(constants.ACTIVATION_SPECIFIC_TIME_VALUE);
  if (!timeValue || timeValue < 1) {
    errors[conditionKey] = 'activity.validation.error.activity.action.value.must.not.be.negative';
  }
}
