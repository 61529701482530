export default {
  namespace: '@como',
  modules: [
    {
      name: 'web-hub-business-profile-module-locations',
      route: '/business-profile/locations',
      active: true,
      resolve: () => import('@como/web-hub-business-profile-module')
    },

    {
      name: 'web-hub-business-profile-module-locations-create',
      route: '/business-profile/locations/create',
      active: true,
      resolve: () => import('@como/web-hub-business-profile-module')
    },
    {
      name: 'web-hub-business-profile-module-locations',
      route: '/business-profile/locations/update',
      active: true,
      resolve: () => import('@como/web-hub-business-profile-module')
    },
    {
      name: 'web-hub-business-profile-module',
      route: '/business-profile/features',
      active: true,
      resolve: () => import('@como/web-hub-business-profile-module')
    },
    {
      name: 'web-hub-business-profile-module',
      route: '/business-profile/legal',
      active: true,
      resolve: () => import('@como/web-hub-business-profile-module')
    },
    {
      name: 'web-hub-business-profile-module',
      route: '/business-profile/social',
      active: true,
      resolve: () => import('@como/web-hub-business-profile-module')
    },
    {
      name: 'web-hub-business-profile-module',
      route: '/business-profile/pos',
      active: true,
      resolve: () => import('@como/web-hub-business-profile-module')
    },
    {
      name: 'web-hub-business-profile-module',
      route: '/business-profile/registration',
      active: true,
      resolve: () => import('@como/web-hub-business-profile-module')
    },
    {
      name: 'web-hub-business-profile-module',
      route: '/business-profile/communication',
      active: true,
      resolve: () => import('@como/web-hub-business-profile-module')
    },
    {
      name: 'web-hub-data-and-bi',
      route: '/databi',
      active: true,
      resolve: () => import('@como/web-hub-data-and-bi')
    },
    {
      name: 'web-hub-data-and-bi-looker',
      route: '/databi/looker',
      active: true,
      resolve: () => import('@como/web-hub-data-and-bi')
    },
    {
      name: 'web-hub-data-and-bi-looker-dashboards',
      route: '/databi/lookerDashboards',
      active: true,
      resolve: () => import('@como/web-hub-data-and-bi')
    },
    {
      name: 'web-hub-data-and-bi-analytics',
      route: '/databi/analytics',
      active: true,
      resolve: () => import('@como/web-hub-data-and-bi')
    },
    {
      name: 'web-hub-business-profile-module',
      route: '/business-profile/customer-portal',
      active: true,
      resolve: () => import('@como/web-hub-business-profile-module')
    },
    {
      name: 'web-hub-business-profile-module',
      route: '/business-profile/franchise',
      active: true,
      resolve: () => import('@como/web-hub-business-profile-module')
    },
    {
      name: 'web-hub-business-profile-module',
      route: '/business-profile/agent-configuration',
      active: true,
      resolve: () => import('@como/web-hub-business-profile-module')
    }
  ]
};
