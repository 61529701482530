import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Button, Grid, Icon, Input, Segment } from 'semantic-ui-react';
import PopupWrapper from '../../../common/components/PopupWrapper/PopupWrapper';
import CustomMenu from '../../../common/components/CustomMenu/CustomMenu';
import AddActivitySegment from './AddActivitySegment';
import * as constants from '../businessCenter.constants';
import * as activityConstants from '../../Activity/activity.constants';
import InformationModal from '../../../common/components/CustomModal/InformationModal/InformationModal';
import tracker from '../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../common/utils/tracking/tracking.consts';
import { calculateActivityViewStatus } from '../../Activity/activity.selectors.utils';
import ValidationErrorMessage from '../../../common/components/ValidationErrorMessage/ValidationErrorMessage';
import withAuthorization from '../../../common/helpers/authorization';
import * as appConstants from '../../App/app.constants';

class CampaignToolbar extends React.PureComponent {

  static propTypes = {
    campaignIndex: PropTypes.number.isRequired,
    campaign: PropTypes.object.isRequired,
    locationId: PropTypes.number.isRequired,
    createActivity: PropTypes.func.isRequired,
    deleteCampaign: PropTypes.func.isRequired,
    onUpdateCampaign: PropTypes.func.isRequired,
    focus: PropTypes.bool,
    handleOnBlur: PropTypes.func,
    updateCampaign: PropTypes.func,
    hasBasicPlanPermissions: PropTypes.bool,
    businessBundlePlan: PropTypes.string,
    openToast: PropTypes.func,
  };

  state = {
    isArchiveInformationOpen: false,
    isCampaignMenuPopupOpen: false,
    hasError: false
  };

  getCampaignContextMenu() {

    const { formatMessage } = this.props.intl; // eslint-disable-line
    const { campaign, onUpdateCampaign } = this.props;
    const isArchive = campaign.get(constants.CAMPAIGN_STATUS) === constants.CAMPAIGN_STATUS_ARCHIVE;

    return [
      {
        text: formatMessage({ id: isArchive ? 'business-center.campaign.menu.un-archive' : 'business-center.campaign.menu.archive' }),
        iconName: 'como-ic-archive',
        cb: () => {
          this.handleCampaignMenuClose();

          const hasActiveActivity = campaign.get(constants.ACTIVITIES).some((activity) => {
            const viewStatus = calculateActivityViewStatus(activity);
            return viewStatus === activityConstants.ACTIVITY_STATUS_FUTURE
              || viewStatus === activityConstants.ACTIVITY_STATUS_ACTIVE;
          });

          if (hasActiveActivity) {
            return this.setState({ isArchiveInformationOpen: true });
          }

          tracker.onButtonClick(isArchive
            ? trackerConstants.BUTTON_TYPE_CAMPAIGN_UN_ARCHIVE
            : trackerConstants.BUTTON_TYPE_CAMPAIGN_ARCHIVE
          );
          return onUpdateCampaign(campaign.set(constants.CAMPAIGN_STATUS,
            isArchive ? constants.CAMPAIGN_STATUS_ACTIVE : constants.CAMPAIGN_STATUS_ARCHIVE));
        }
      }
    ];
  }

  handleSubmitCampaignName = (e) => {
    const newName = e.target.value;
    if (!newName) {
      this.setState({
        hasError: true
      });
      return;
    }
    this.setState({
      hasError: false
    });
    const nameChanged = this.props.campaign.get(constants.CAMPAIGN_NAME) !== newName;
    const newCampaign = this.props.campaign.set(constants.CAMPAIGN_NAME, newName);
    this.props.onUpdateCampaign(newCampaign);
    this.props.handleOnBlur();
    if (nameChanged) {
      tracker.onEvent(trackerConstants.EVENT_TYPE_CAMPAIGN_NAME_CHANGED);
    }
  };

  handleOnKeyDown = (e) => {
    if (e.keyCode === 13) {
      const nameChanged = this.props.campaign.get(constants.CAMPAIGN_NAME) !== e.target.value;
      if (nameChanged) {
        tracker.onEvent(trackerConstants.EVENT_TYPE_CAMPAIGN_NAME_CHANGED, {
          [trackerConstants.CAMPAIGN_NAME_CHANGED_ARGS_SOURCE]: trackerConstants.EVENT_SOURCE_ENTER_KEY
        });
      }
      e.target.blur();
    }
  };

  handleOnFocus = (e) => {
    if (e.target.setSelectionRange) {
      const len = e.target.value.length * 2;
      e.target.setSelectionRange(0, len);
    }
  };

  deleteCampaign = () => {
    this.props.deleteCampaign(this.props.campaign.get(constants.ID));
  };

  handleCloseInformationModal = () => {
    this.setState({ isArchiveInformationOpen: false });
  };

  handleCampaignMenuOpen = () => {
    this.setState({ isCampaignMenuPopupOpen: true });
  };

  handleCampaignMenuClose = () => {
    this.setState({ isCampaignMenuPopupOpen: false });
  };

  pinCampaign = () => {
    const updatedCampaign = this.props.campaign.set(constants.CAMPAIGN_PINED_DATE, new Date());
    this.props.updateCampaign(updatedCampaign);
    this.props.openToast(this.props.intl.formatMessage({ id: 'business-center.campaign.pinned' }));
  }

  unpinCampaign = () => {
    const updatedCampaign = this.props.campaign.set(constants.CAMPAIGN_PINED_DATE, null);
    this.props.updateCampaign(updatedCampaign);
    this.props.openToast(this.props.intl.formatMessage({ id: 'business-center.campaign.unpinned' }));
  }

  render() {

    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const { campaignIndex, campaign, hasFullPermission } = this.props;
    const { isArchiveInformationOpen, isCampaignMenuPopupOpen } = this.state;

    let addActivitySegments = [
      {
        activityType: activityConstants.ACTIVITY_TYPE_ONE_TIME,
        segmentColor: 'oneTimeColor',
        segmentContent: formatMessage({ id: 'business-center.campaign.segment.description.onetime' }),
        segmentCreateLink: 'onetime'
      }
    ];

    if ((!this.props.hasBasicPlanPermissions && this.props.businessBundlePlan !== appConstants.REVEL) && hasFullPermission(appConstants.FEATURE_NAME_CAMPAIGN_CENTER)) {
      addActivitySegments = addActivitySegments.concat([
        {
          activityType: activityConstants.ACTIVITY_TYPE_DEAL,
          segmentColor: 'dealColor',
          segmentContent: formatMessage({ id: 'business-center.campaign.segment.description.deal' }),
          segmentCreateLink: 'deals'
        },
        {
          activityType: activityConstants.ACTIVITY_TYPE_RULE,
          segmentColor: 'ruleColor',
          segmentContent: formatMessage({ id: 'business-center.campaign.segment.description.rule' }),
          segmentCreateLink: 'rules'
        },
        {
          activityType: activityConstants.ACTIVITY_TYPE_PUNCH_CARD,
          segmentColor: 'punchCardColor',
          segmentContent: formatMessage({ id: 'business-center.campaign.segment.description.punchCard' }),
          segmentCreateLink: 'punchCards'
        },
      ]);
    }

    const AddActivityTrigger = (
      <Button
        circular
        className="add-activity"
        data-automation-id={`business-center.campaign.${campaignIndex}.add-activity`}
      >
        <Icon className="como-ic-plus" />
        <span>{formatMessage({ id: 'business-center.campaign.addActivity' })}</span>
      </Button>
    );

    const addActivityContent = (
      <div>
        <Segment.Group>
          {
            addActivitySegments.map((activitySegment) => (
              <AddActivitySegment
                key={activitySegment.activityType}
                campaignIndex={campaignIndex}
                locationId={this.props.locationId}
                activityType={activitySegment.activityType}
                segmentColor={activitySegment.segmentColor}
                segmentContent={activitySegment.segmentContent}
                segmentCreateLink={activitySegment.segmentCreateLink}
                campaignId={campaign.get(constants.ID)}
                hasBasicPlanPermissions={this.props.hasBasicPlanPermissions}
                createActivity={tracker.wrapButtonAction(
                  this.props.createActivity,
                  trackerConstants.BUTTON_TYPE_ADD_ACTIVITY,
                  { activityType: activitySegment.activityType })
                }
              />
            ))
          }
        </Segment.Group>
      </div>
    );

    const isCampaignPinned = campaign.get(constants.CAMPAIGN_PINED_DATE);

    return (
      <Grid
        columns="2"
        className="campaign-toolbar__container"
      >
        <InformationModal
          open={isArchiveInformationOpen}
          onConfirm={this.handleCloseInformationModal}
        >
          <p>{formatMessage({ id: 'business-center.campaign.archive.information.content' })}</p>
        </InformationModal>
        <Grid.Column width={12}>
          <div className="campaign-title__container">
            {campaign.get(constants.CAMPAIGN_GOAL_ID) && <Icon className="goal-source como-ic-magic-wand" />}
            <Input
              autoFocus={this.props.focus}
              type="text"
              defaultValue={campaign.get(constants.CAMPAIGN_NAME)}
              className="ui header campaign-title"
              onBlur={this.handleSubmitCampaignName}
              onKeyDown={this.handleOnKeyDown}
              onFocus={this.handleOnFocus}
              data-automation-id={`business-center.campaign.${campaignIndex}.title`}
            />
            {this.state.hasError ? (<ValidationErrorMessage
              className="campaign-name-error"
              errorMessage={formatMessage({ id: 'campaign.name.error.empty' })}
              dataAutomationId={`business-center.campaign.${campaignIndex}.error.message`}
            />) : null}
          </div>
        </Grid.Column>
        <Grid.Column width={4} className="ui right aligned column campaign-name">
          <div className={'tw-flex tw-justify-end tw-items-center'}>
            <i
              title={isCampaignPinned ? formatMessage({ id: 'business-center.campaign.pinned-title' }) : formatMessage({ id: 'business-center.campaign.pin-title' })}
              className={`como-svg-campaign-${isCampaignPinned ? 'pinned' : 'pin'} tw-cursor-pointer`}
              onClick={() => isCampaignPinned ? this.unpinCampaign() : this.pinCampaign()}
              aria-hidden="true"
            />
            <PopupWrapper
              automationId={`business-center.campaign.${campaignIndex}.add-activity.popup`}
              className="no-padding no-border"
              on="click"
              position="bottom right"
              trigger={AddActivityTrigger}
            >
              {addActivityContent}
            </PopupWrapper>
            <PopupWrapper
              className="no-padding no-border"
              on="click"
              position="bottom right"
              open={isCampaignMenuPopupOpen}
              onOpen={this.handleCampaignMenuOpen}
              onClose={this.handleCampaignMenuClose}
              automationId={`business-center.campaign.${campaignIndex}.contextual-menu.popup`}
              trigger={
                <Icon
                  className="como-ic-more"
                  data-automation-id={`business-center.campaign.${campaignIndex}.contextual-menu`}
                />
            }
            >
              <CustomMenu
                AutomationIdPrefix={`business-center.campaign.${campaignIndex}.contextual-menu`}
                data={this.getCampaignContextMenu()}
              />
            </PopupWrapper>
          </div>
        </Grid.Column>
      </Grid>
    );
  }
}

export default withAuthorization(injectIntl(CampaignToolbar));
