// noinspection JSUnusedGlobalSymbols

/*
 *
 * Activity actions
 *
 */
import React from 'react';

import * as constants from './activity.constants';
import * as errorConstant from '../ErrorMessage/errorMessage.constants';

export function getFeatures() {
  return {
    type: constants.GET_FEATURES
  };
}

export function createActivity(locationId, campaignKey, templateKey, goalKey, activityType) {
  return {
    type: constants.CREATE_ACTIVITY,
    locationId,
    campaignKey,
    templateKey,
    goalKey,
    activityType
  };
}

// TODO: change name to clear templates
export function clearDataOnUnmount() {
  return {
    type: constants.CLEAR_DATA_ON_UNMOUNT
  };
}

export function getActivity(id, activityType) {
  return {
    type: constants.GET_ACTIVITY,
    id,
    activityType
  };
}

export function saveActivity({ isDraft, handleDeleteMemberAction, disableReFilteringFlag }) {
  return {
    type: constants.SAVE_ACTIVITY,
    isDraft,
    handleDeleteMemberAction,
    disableReFilteringFlag
  };
}

export function updateMetadata(key, value) {
  // this action is in charge of updating the root properties of the rule/deal (name, description, status...)
  return {
    type: constants.UPDATE_METADATA,
    key,
    value
  };
}
export function forceUpdateName(locationId, activity, name) {
  return {
    type: constants.FORCE_UPDATE_NAME,
    locationId,
    activity,
    name
  };
}

export function checkActivityNameAvailability(locationId, activityId, name, activityType) {
  return {
    type: constants.CHECK_ACTIVITY_NAME_AVAILABILITY,
    locationId,
    activityId,
    name,
    activityType
  };
}

export function updateTriggerPreValidation(triggerObj) {
  return {
    type: constants.UPDATE_TRIGGER_PRE_VALIDATION,
    triggerObj,
  };
}

export function updateTrigger(trigger, deleteActionsAndCases = true) {
  return {
    type: constants.UPDATE_TRIGGER,
    trigger,
    deleteActionsAndCases
  };
}

export function clearTempTrigger() {
  return {
    type: constants.CLEAR_TEMP_TRIGGER,
  };
}

export function addCondition() {
  return {
    type: constants.ADD_CONDITION,
  };
}

export function updateConditionField(fieldName, fieldValue, conditionIndex) {
  return {
    type: constants.UPDATE_CONDITION_FIELD,
    fieldName,
    fieldValue,
    conditionIndex,
  };
}

export function deleteCondition(index) {
  return {
    type: constants.DELETE_CONDITION,
    index,
  };
}

export function closeConfirmDeleteActionDialog() {
  return {
    type: constants.OPEN_CONFIRM_DELETE_ACTION_DIALOG,
    [constants.CONFIRM_DELETE_ACTION_DIALOG_OPEN]: false,
  };
}
export function closeBlockSmsActionDialog() {
  return {
    type: constants.OPEN_BLOCK_SMS_ACTION_DIALOG,
    [constants.BLOCK_SMS_ACTION_DIALOG_OPEN]: false,
  };
}

export function closeReFilterDialog() {
  return {
    type: constants.OPEN_RE_FILTER_DIALOG,
    [constants.RE_FILTER_DIALOG_OPEN]: false,
  };
}

export function addActivityTags(activityTags) {
  return {
    type: constants.ADD_ACTIVITY_TAGS,
    activityTags
  };
}

export function updateActionField(fieldName, fieldValue, index, actionsWrapperIndex) {
  return {
    type: constants.UPDATE_ACTION_FIELD,
    fieldName,
    fieldValue,
    index,
    actionsWrapperIndex
  };
}

export function deleteAction(index, actionsWrapperIndex) {
  return {
    type: constants.DELETE_ACTION,
    index,
    actionsWrapperIndex
  };
}

export function changeActivityActionMode(mode, actionsWrapperIndex) {
  return {
    type: constants.CHANGE_ACTION_MODE,
    mode,
    actionsWrapperIndex
  };
}

export function updateActionType(actionType, index, actionsWrapperIndex, mode, trigger) {
  return {
    type: constants.UPDATE_ACTION_TYPE,
    actionType,
    index,
    actionsWrapperIndex,
    mode,
    trigger
  };
}

export function updateGiftSimpleMode(value) {
  return {
    type: constants.UPDATE_GIFT_SIMPLE_MODE,
    value
  };
}

export function addBundleLine(actionsWrapperIndex) {
  return {
    type: constants.ADD_BUNDLE_LINE,
    actionsWrapperIndex
  };
}

export function deleteBundleLine(index, actionsWrapperIndex) {
  return {
    type: constants.DELETE_BUNDLE_LINE,
    index,
    actionsWrapperIndex
  };
}

export function onBundleLineFieldUpdate(fieldName, fieldValue, bundleConditionIndex, actionsWrapperIndex) {
  return {
    type: constants.UPDATE_BUNDLE_LINE_FIELD,
    fieldName,
    fieldValue,
    bundleConditionIndex,
    actionsWrapperIndex
  };
}

export function onUpdateBundle(fieldName, fieldValue, actionsWrapperIndex) {
  return {
    type: constants.UPDATE_BUNDLE,
    fieldName,
    fieldValue,
    actionsWrapperIndex
  };
}

export function updateGlobalConditions(globalConditionsCategory, globalConditions) {
  return {
    type: constants.UPDATE_GLOBAL_CONDITIONS,
    globalConditionsCategory,
    globalConditions
  };
}

/**
 * Is responsible for mutating the addPoints action to addPoints rate
 * @param addPointsType
 * @param index
 * @param actionsWrapperIndex
 * @returns {{type, dataType: *}}
 */
export function addPointsOnChangeType(addPointsType, index, actionsWrapperIndex) {
  return {
    type: constants.ADD_POINTS_TYPE_CHANGE,
    [constants.ADD_POINTS_TYPE]: addPointsType,
    [constants.INDEX]: index,
    actionsWrapperIndex
  };
}

export function setActivityTypeData(activityType) {
  return {
    type: constants.SET_ACTIVITY_TYPE_DATA,
    activityType
  };
}

export function setActivitySource(source) {
  return {
    type: constants.SET_ACTIVITY_SOURCE,
    source
  };
}

export function setFilteredPopulationData(memberCount, filter, fileName, bucketName, source) {
  return {
    type: constants.SET_FILTERED_POPULATION_DATA,
    count: memberCount,
    filter,
    fileName,
    bucketName,
    source
  };
}

export function setFilteredPopulationError() {
  return {
    type: constants.SET_FILTERED_POPULATION_ERROR
  };
}

export function resetDraftOneTimeActionWithFilteredPopulationFlag() {
  return {
    type: constants.RESET_DRAFT_ONE_TIME_ACTION_WITH_FILTERED_POPULATION_FLAG
  };
}

export function saveApplyOnData(actionIndex, actionsWrapperIndex) {
  return {
    type: constants.SAVE_APPLY_ON_DATA,
    actionIndex,
    actionsWrapperIndex
  };
}

export function createTempApplyOnModel(actionIndex, actionsWrapperIndex) {
  return {
    type: constants.CREATE_TEMP_APPLY_ON_MODEL,
    actionIndex,
    actionsWrapperIndex
  };
}

export function highlightActivity(activityId) {
  return {
    type: constants.HIGHLIGHT_ACTIVITY,
    [constants.HIGHLIGHTED_ACTIVITY_ID]: activityId
  };
}

export function resetGiftInstanceExpirationGlobalConditionTempModel() {
  return {
    type: constants.RESET_GIFT_INSTANCE_EXPIRATION_GLOBAL_CONDITION_TEMP_MODEL
  };
}

export function updateGiftInstanceExpirationGlobalConditionTempModel(section, key, value) {
  return {
    type: constants.UPDATE_GIFT_INSTANCE_EXPIRATION_GLOBAL_CONDITION_TEMP_MODEL,
    section,
    key,
    value
  };
}

export function applyGiftInstanceExpirationGlobalConditionTempModel() {
  return {
    type: constants.APPLY_GIFT_INSTANCE_EXPIRATION_GLOBAL_CONDITION_TEMP_MODEL
  };
}
export const getActivityStats = (activityId, activityType) => ({ type: constants.GET_ACTIVITY_STATS, [constants.HUB_ID]: activityId, activityType });

export const getAbTestStats = (activityServerId) => ({ type: constants.GET_AB_TEST_STATS, [constants.SERVER_ID]: activityServerId });

export function createGiftFromActivity(actionIndex, actionsWrapperIndex) {
  return {
    type: constants.CREATE_GIFT_FROM_ACTIVITY,
    actionIndex,
    actionsWrapperIndex
  };
}

export function cancelCreateGiftFromActivity() {
  return {
    type: constants.CANCEL_CREATE_GIFT_FROM_ACTIVITY,
  };
}


export function createEmailFromActivity(actionIndex, actionsWrapperIndex) {
  return {
    type: constants.CREATE_EMAIL_FROM_ACTIVITY,
    actionIndex,
    actionsWrapperIndex
  };
}

export function cancelCreateEmailFromActivity() {
  return {
    type: constants.CANCEL_CREATE_EMAIL_FROM_ACTIVITY,
  };
}

export function clearNewlySelectedGiftId() {
  return {
    type: constants.CLEAR_NEWLY_SELECTED_GIFT_ID
  };
}

export function markActivityDirty() {
  return {
    type: constants.MARK_ACTIVITY_DIRTY
  };
}

export function markActivityClean() {
  return {
    type: constants.MARK_ACTIVITY_CLEAN
  };
}

export function setBirthdayOrAnniversaryEventScope(value) {
  return {
    type: constants.SET_BIRTHDAY_OR_ANNIVERSARY_EVENT_SCOPE,
    value
  };
}

export function setScheduleEventScope(value) {
  return {
    type: constants.SET_CLUB_MEMBER_ANNIVERSARY_EVENT_SCOPE,
    value
  };
}

export function setClubMemberAnniversaryOneTImeObjectValue(value) {
  return {
    type: constants.UPDATE_TRIGGER_CLUB_MEMBER_ANNIVERSARY_ONE_TIME_OBJECT_VALUE,
    value
  };
}

export function setClubMemberAnniversaryOneTImeObjectUnit(value) {
  return {
    type: constants.UPDATE_TRIGGER_CLUB_MEMBER_ANNIVERSARY_ONE_TIME_OBJECT_UNIT,
    value
  };
}

export function updateTriggerScheduleValue(value) {
  return {
    type: constants.UPDATE_TRIGGER_SCHEDULE_VALUE,
    value
  };
}

export function selectActivityTab(tab) {
  return {
    type: constants.SELECT_ACTIVITY_TAB,
    tab
  };
}

export function updateDisplayField(fields) {
  return {
    type: constants.UPDATE_DISPLAY_FIELD,
    fields
  };
}

export function getMigratedActivityData(activityType, activityId) {
  return {
    type: constants.GET_MIGRATED_ACTIVITY_DATA,
    activityType,
    activityId
  };
}

export function updatePromoCodeSingleCodeValue(value, bulkIndex) {
  return {
    type: constants.UPDATE_PROMO_CODE_SINGLE_CODE_VALUE,
    value,
    bulkIndex
  };
}

export function updatePromoCodeBulks(bulks) {
  return {
    type: constants.UPDATE_PROMO_CODE_BULKS_VALUE,
    value: bulks
  };
}

export function updatePromoBulkType(type) {
  return {
    type: constants.UPDATE_PROMO_CODE_BULK_TYPE,
    value: type
  };
}

export function checkCodeIsAvailable(code) {
  return {
    type: constants.CHECK_PROMO_CODE_BULK_IS_AVAILABLE,
    value: code
  };
}

export function getFreeCodesNumber(codeLength) {
  return {
    type: constants.GET_PROMO_CODE_FREE_CODES_NUMBER,
    codeLength
  };
}

export function setInitialPromoCodeSingleBulkValue() {
  return {
    type: constants.SET_INITIAL_SINGLE_PROMO_CODE
  };
}

export function removeBackToActivityFlag() {
  return {
    type: constants.REMOVE_BACK_TO_ACTIVITY_FLAG
  };
}

export function uploadCodesBulkFile(fileHandle, bulkIndex) {
  return {
    type: constants.IMPORT_CODES_BULK_UPLOAD,
    fileHandle,
    bulkIndex
  };
}

export function displayUploadCodesBulkFileError() {
  return {
    type: errorConstant.HANDLE_ERROR,
    [errorConstant.MESSAGES_KEYS]: ['uploadCodesBulkFile.rejected.Fail']
  };
}

export function updatePromoCodesMode(mode) {
  return {
    type: constants.UPDATE_PROMO_CODES_MODE,
    mode
  };
}

// Landing Page

export function UpdateLandingPageActivationStatus(isActive,
  landingPageViewDirection,
  landingPageEnterPhoneText,
  landingPageSuccessHeaderText,
  landingPageSuccessMessageText,
  landingPageExpiredHeaderText,
  landingPageExpiredMessageText,
  landingPageExistingMemberHeaderText,
  landingPageExistingMemberMessageText,
  landingPageFacebookPixel,
  landingPageGooglePixel,
  landingPageRedirectUrlSuccess,
  landingPageRedirectUrlFailure) {
  return {
    type: constants.UPDATE_LANDING_PAGE_ACTIVATION_STATUS,
    isActive,
    landingPageViewDirection,
    landingPageEnterPhoneText,
    landingPageSuccessHeaderText,
    landingPageSuccessMessageText,
    landingPageExpiredHeaderText,
    landingPageExpiredMessageText,
    landingPageExistingMemberHeaderText,
    landingPageExistingMemberMessageText,
    landingPageFacebookPixel,
    landingPageGooglePixel,
    landingPageRedirectUrlSuccess,
    landingPageRedirectUrlFailure
  };
}

export function UpdateLandingPageInputText(field, value) {
  return {
    type: constants.UPDATE_LANDING_PAGE_INPUT_TEXT,
    field,
    value
  };
}

export function OnlyNewMembersCheckboxChange(enabled) {
  return {
    type: constants.UPDATE_LANDING_PAGE_ONLY_NEW_MEMBERS_CHECKBOX,
    enabled
  };
}

export function UpdateLandingPageLegalSectionStatus(enabled, legalSectionText) {
  return {
    type: constants.UPDATE_LANDING_PAGE_SHOW_LEGAL_SECTION_TOGGLE,
    enabled,
    legalSectionText
  };
}

export function UpdateLandingPageSocialSectionStatus(enabled) {
  return {
    type: constants.UPDATE_LANDING_PAGE_SHOW_SOCIAL_SECTION_TOGGLE,
    enabled,
  };
}

export function OpenLandingPageModal(url, openModal) {
  return {
    type: constants.OPEN_LANDING_PAGE_MODAL,
    url,
    openModal
  };
}

export function deleteActionsWrapper(actionsWrapperIndex) {
  return {
    type: constants.DELETE_ACTIONS_WRAPPER,
    actionsWrapperIndex
  };
}

export function clearLandingPageActivityModalData() {
  return {
    type: constants.CLEAR_LANDING_PAGE_MODAL_DATA
  };
}

export function requestToEnableGiftLandingPageFeature(data) {
  return {
    type: constants.REQUEST_TO_ENABLE_GIFT_LANDING_PAGE_FEATURE,
    data
  };
}

export function requestToEnablePushNotificationWithImageFeature(data) {
  return {
    type: constants.REQUEST_TO_ENABLE_PUSH_NOTIFICATION_WITH_IMAGE_FEATURE,
    data
  };
}

export function submitActionToCustomer(user, customerSearchField, actionsWrapperIndex, actionIndex, actionType) {
  return {
    type: constants.SUBMIT_ACTION_TO_CUSTOMER,
    user,
    actionsWrapperIndex,
    actionIndex,
    customerSearchField,
    actionType
  };
}

export function updateProgressStatus(status, wrapperIndex, index) {
  return {
    type: constants.UPDATE_PROGRESS_STATUS,
    status,
    wrapperIndex,
    index
  };
}

export function clearTestSucceededFlag() {
  return {
    type: constants.CLEAR_TEST_TO_MYSELF_SUCCEEDED
  };
}

export function updateFilteredPopulation(count, fileName, bucketName, comilliaAdjusted = false, query) {
  return {
    type: constants.UPDATE_FILTERED_POPULATION,
    count,
    fileName,
    bucketName,
    comilliaAdjusted,
    query
  };
}

export function setComilliaHintInAction(caseIndex, actionIndex) {
  return {
    type: constants.SET_COMILLIA_HINT_OPEN_IN_ACTION,
    caseIndex,
    actionIndex
  };
}

export function getLookerQuery(fileName, bucketName) {
  return {
    type: constants.GET_LOOKER_QUERY,
    fileName,
    bucketName
  };
}

export function initializeUpdateMembershipFields(caseIndex) {
  return {
    type: constants.INITIALIZE_UPDATE_MEMBERSHIP_FIELDS,
    caseIndex
  };
}

export function onMembershipFieldValueUpdate(name, value, membershipFieldsIndex, actionIndex, caseIndex) {
  return {
    type: constants.UPDATE_MEMBERSHIP_FIELD_VALUE_UPDATE,
    name,
    value,
    membershipFieldsIndex,
    actionIndex,
    caseIndex
  };
}

export function onMembershipFieldListUpdate(fieldListKey, fieldListValue, fieldValueKey, membershipFieldsIndex, actionIndex, caseIndex) {
  return {
    type: constants.UPDATE_MEMBERSHIP_FIELD_LIST_UPDATE,
    fieldListKey,
    fieldListValue,
    fieldValueKey,
    membershipFieldsIndex,
    actionIndex,
    caseIndex
  };
}

export function onAddMembershipField(actionIndex, caseIndex) {
  return {
    type: constants.ADD_MEMBERSHIP_FIELD,
    actionIndex,
    caseIndex
  };
}

export function onRemoveMembershipField(membershipFieldsIndex, actionIndex, caseIndex) {
  return {
    type: constants.REMOVE_MEMBERSHIP_FIELD,
    membershipFieldsIndex,
    actionIndex,
    caseIndex
  };
}

export function updateTestABPopulationSize(caseIndex, value, filteredPopulation) {
  return {
    type: constants.UPDATE_AB_TESTING_POPULATION_SIZE,
    caseIndex,
    value,
    filteredPopulation
  };
}
