import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as constants from '../../../../activity.constants';
import CurrencyInputField
  from '../../../../../../common/components/FormInputFields/CurrencyInputField/CurrencyInputField';
import PopupWrapper from '../../../../../../common/components/PopupWrapper/PopupWrapper';
import ApplyOnBundleLines from '../ApplyOnBundleLines';
import * as applyOnActions from '../applyOn.actions';
import PercentageInputField
  from '../../../../../../common/components/FormInputFields/PercentageInputField/PercentageInputField';
import * as schemaConstants from '../../../../activitySchema/activitySchema.constants';
import PopupTrigger from '../../../../../../common/components/PopupTrigger/PopupTrigger';
import { getTempApplyOnBundleMode, getTempApplyOnBundleModeValidationErrors } from '../../../../activity.selectors';
import './advancedDiscountActions.styles.less';

class AdvancedDiscountAction extends React.PureComponent {

  static propTypes = {
    action: PropTypes.object,
    onActionFieldUpdate: PropTypes.func.isRequired,
    index: PropTypes.number,
    validationErrors: PropTypes.object,
    automationIdPrefix: PropTypes.string,
    actions: PropTypes.object,
    bundle: PropTypes.object,
    tempApplyOnModel: PropTypes.object,
    tempApplyOnValidationErrors: PropTypes.object,
    saveApplyOnData: PropTypes.func,
    createTempApplyOnModel: PropTypes.func,
    actionsWrapperIndex: PropTypes.number
  };

  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false
    };
    this.resetApplyOnData = this.resetApplyOnData.bind(this);
    this.saveApplyOnData = this.saveApplyOnData.bind(this);
    this.handlePopupOpen = this.handlePopupOpen.bind(this);
    this.handlePopupClose = this.handlePopupClose.bind(this);
    this.getTriggerMessage = this.getTriggerMessage.bind(this);
    this.combineApplyOnItemsToString = this.combineApplyOnItemsToString.bind(this);
    this.getErrorInTrigger = this.getErrorInTrigger.bind(this);
    this.getHeadOfSentence = this.getHeadOfSentence.bind(this);
  }

  getTriggerMessage() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    let message = formatMessage({ id: 'deals.select.bundle.items' });
    if (!this.props.bundle || !this.props.bundle.getIn([constants.VALUE, constants.CONDITIONS_LIST]) || !this.props.bundle.getIn([constants.VALUE, constants.CONDITIONS_LIST]).size) {
      return message;
    }
    if (this.props.action.get(constants.BUNDLE_ITEMS_MODE) === constants.BUNDLE_ITEMS_MODE_ENTIRE) {
      message = formatMessage({ id: 'deals.entireBundle' });
    } else if (this.props.action.get(constants.BUNDLE_ITEMS_MODE) === constants.BUNDLE_ITEMS_MODE_SPECIFIC) {
      if (this.props.bundle.getIn([constants.VALUE, constants.CONDITIONS_LIST]).size > 0) {
        message = this.combineApplyOnItemsToString();
      } else {
        message = formatMessage({ id: 'deals.specificItems' });
      }
    }
    return message;
  }

  getErrorInTrigger() {
    let error = '';
    if (this.props.validationErrors) {
      if (this.props.validationErrors.get(constants.APPLY_ON)) {
        error = this.props.validationErrors.get(constants.APPLY_ON);
      } else if (this.props.validationErrors.get(constants.BUNDLE_ITEMS_MODE)) {
        error = this.props.validationErrors.get(constants.BUNDLE_ITEMS_MODE);
      }
    }
    return error;
  }

  getHeadOfSentence() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    let headOfSentence = '';
    switch (this.props.action.get(constants.ACTION_TYPE)) {
      case schemaConstants.DISCOUNT_FREE_ITEMS_ADVANCED_ACTION:
        headOfSentence = formatMessage({ id: 'deals.make' });
        break;
      case schemaConstants.DISCOUNT_PERCENT_OFF_ON_SPECIFIC_ITEMS_ADVANCED_ACTION:
      case schemaConstants.DISCOUNT_AMOUNT_OFF_ON_SPECIFIC_ITEMS_ADVANCED_ACTION:
        headOfSentence = formatMessage({ id: 'deals.give' });
        break;
      case schemaConstants.DISCOUNT_SPECIAL_PRICE_ADVANCED_ACTION:
        headOfSentence = formatMessage({ id: 'deals.setPrice' });
        break;
      default:
        headOfSentence = '';
    }
    return headOfSentence;
  }

  combineApplyOnItemsToString() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const strParts = [];
    const applyOnItems = this.props.action.get(constants.APPLY_ON);
    const bundleItems = this.props.bundle.getIn([constants.VALUE, constants.CONDITIONS_LIST]);
    applyOnItems.forEach((applyOnItem) => {
      const bundleItemIndex = bundleItems.findIndex((bundleItem) => bundleItem.get(constants.CONDITION_ID) === applyOnItem.get(constants.APPLY_ON_ID));
      if (bundleItemIndex > -1) {
        let strPart = '';
        if (this.props.bundle.getIn([constants.VALUE, constants.CONDITIONS_LIST]).size === 1) {
          strPart = `${applyOnItem.get(constants.QUANTITY)} ${formatMessage({ id: 'deals.applyOn.of' })} ${formatMessage({ id: 'deals.applyOn.line' })} ${formatMessage({ id: 'deals.apply.on.items.from.above' })}`;
        } else {
          strPart = `${applyOnItem.get(constants.QUANTITY)} ${formatMessage({ id: 'deals.applyOn.of' })} ${formatMessage({ id: 'deals.applyOn.line' })} ${String.fromCharCode(65 + bundleItemIndex)}`;
        }
        strParts.push(strPart);
      }
    });
    return strParts.join(', ');
  }

  handlePopupOpen = () => {
    this.props.createTempApplyOnModel(this.props.index, this.props.actionsWrapperIndex);
    this.setState({ isPopupOpen: true });
  };

  handlePopupClose = () => {
    this.setState({ isPopupOpen: false });
  };

  saveApplyOnData() {
    this.props.saveApplyOnData(this.props.index, this.props.actionsWrapperIndex);
    this.handlePopupClose();
  }

  resetApplyOnData() {
    this.props.actions.resetApplyOnData(this.props.index);
    this.handlePopupClose();
  }

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const { onActionFieldUpdate, index, action, validationErrors, automationIdPrefix } = this.props;
    const errorInTrigger = this.getErrorInTrigger();
    const triggerMessage = this.getTriggerMessage();
    const headOfSentence = this.getHeadOfSentence();
    return (
      <div>
        <Grid className={`deal-action ${this.props.action.get(constants.ACTION_TYPE)}`}>
          <Grid.Row>
            <Grid.Column width={16}>
              <div className="deal-fields">
                <span className="head-of-sentence">{headOfSentence}</span>
                {action.get(constants.ACTION_TYPE) === schemaConstants.DISCOUNT_PERCENT_OFF_ON_SPECIFIC_ITEMS_ADVANCED_ACTION &&
                <span>
                  <PercentageInputField
                    name={constants.DISCOUNT_VALUE}
                    onChange={(e, data) => onActionFieldUpdate(data.name, data.value, index)}
                    value={parseFloat(action.get(constants.DISCOUNT_VALUE)) ? parseFloat(action.get(constants.DISCOUNT_VALUE)) : null}
                    prefix={`${automationIdPrefix}.percent`}
                    disabled={false}
                    error={validationErrors ? validationErrors.get(constants.DISCOUNT_VALUE) : null}
                  />
                  <span className="inside-sentence">{formatMessage({ id: 'deals.discountOnSpecificItemsAdvanced' })}</span>
                </span>
              }
                {action.get(constants.ACTION_TYPE) === schemaConstants.DISCOUNT_AMOUNT_OFF_ON_SPECIFIC_ITEMS_ADVANCED_ACTION &&
                <span className="input-wrapper-wrap">
                  <CurrencyInputField
                    name={constants.DISCOUNT_VALUE}
                    onChange={(e, data) => onActionFieldUpdate(data.name, data.value, index)}
                    value={action.get(constants.DISCOUNT_VALUE)}
                    prefix={`${automationIdPrefix}.amount`}
                    disabled={false}
                    error={validationErrors ? validationErrors.get(constants.DISCOUNT_VALUE) : null}
                  />
                  <span className="inside-sentence">{formatMessage({ id: 'deals.discountOnSpecificItemsAdvanced' })}</span>
                </span>
                  }
                <PopupWrapper
                  disabled={
                    this.props.bundle.getIn([constants.VALUE, constants.CONDITIONS_LIST, 0, constants.ITEMS_POPULATION, constants.CONDITIONS, constants.CONDITIONS_LIST])
                    && !this.props.bundle.getIn([constants.VALUE, constants.CONDITIONS_LIST, 0, constants.ITEMS_POPULATION, constants.CONDITIONS, constants.CONDITIONS_LIST]).size
                  }
                  basic
                  fixed
                  hideTrigger={false}
                  on="click"
                  onOpen={this.handlePopupOpen}
                  onClose={this.handlePopupClose}
                  open={this.state.isPopupOpen}
                  automationId={`${automationIdPrefix}.itemsPopulation.popup`}
                  position={'bottom left'}
                  size="large"
                  trigger={
                    <div className="inline-div">
                      <PopupTrigger
                        className={`popup-trigger ui selection dropdown ${errorInTrigger ? 'popup-trigger-error' : ''}`}
                        message={triggerMessage}
                        automationId={`${automationIdPrefix}.itemsPopulation.trigger`}
                        validationErrorMessage={errorInTrigger ? formatMessage({ id: errorInTrigger }) : null}
                      />
                    </div>
                }
                  wide="very"
                >
                  <ApplyOnBundleLines
                    onActionFieldUpdate={this.props.onActionFieldUpdate}
                    index={index}
                    automationIdPrefix={`${automationIdPrefix}.applyOnBundleLines`}
                    bundle={this.props.bundle}
                    bundleItemsMode={action.get(constants.BUNDLE_ITEMS_MODE)}
                    validationErrors={this.props.tempApplyOnValidationErrors ? this.props.tempApplyOnValidationErrors : null}
                    applyOn={action.get(constants.APPLY_ON)}
                    tempApplyOnModel={this.props.tempApplyOnModel}
                    onApply={this.saveApplyOnData}
                    onCancel={this.resetApplyOnData}
                    updateTempApplyOnModelField={this.props.actions.updateTempApplyOnModelField}
                    changeBundleItemsModeInTempModel={this.props.actions.changeBundleItemsModeInTempModel}
                    disabledMode={action.get(constants.ACTION_TYPE) === schemaConstants.DISCOUNT_FREE_ITEMS_ADVANCED_ACTION ? constants.BUNDLE_ITEMS_MODE_ENTIRE : null}
                  />
                </PopupWrapper>
                {action.get(constants.ACTION_TYPE) === schemaConstants.DISCOUNT_FREE_ITEMS_ADVANCED_ACTION &&
                <span className="inside-sentence">{formatMessage({ id: 'deals.free' })}</span>
              }
                {action.get(constants.ACTION_TYPE) === schemaConstants.DISCOUNT_SPECIAL_PRICE_ADVANCED_ACTION &&
                <span>
                  <span className="inside-sentence">{formatMessage({ id: 'deals.to' })}</span>
                  <CurrencyInputField
                    name={constants.DISCOUNT_VALUE}
                    onChange={(e, data) => onActionFieldUpdate(data.name, data.value, index)}
                    value={action.get(constants.DISCOUNT_VALUE)}
                    prefix={`${automationIdPrefix}.amount`}
                    disabled={false}
                    error={validationErrors ? validationErrors.get(constants.DISCOUNT_VALUE) : null}
                  />
                </span>
              }
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(applyOnActions, dispatch)
  };
}

const mapStateToProps = (state) => ({
  tempApplyOnModel: getTempApplyOnBundleMode(state),
  tempApplyOnValidationErrors: getTempApplyOnBundleModeValidationErrors(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AdvancedDiscountAction));

