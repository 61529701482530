import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as activityConstants from '../../../features/Activity/activity.constants';

class ActivityType extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired
  };

  render() {
    const { type } = this.props;

    const activityClass = classNames('ui circular center activity label', {
      rule: type === activityConstants.SERVER_TYPE_RULE,
      deal: type === activityConstants.SERVER_TYPE_DEAL,
      oneTime: type === activityConstants.SERVER_TYPE_ONE_TIME_ACTION,
      microCampaign: type === activityConstants.ACTIVITY_TYPE_MICRO_CAMPAIGN
      // schedule: type === 'HubSchedule'
    });

    const typeName = (t) => {
      switch (t) {
        case activityConstants.SERVER_TYPE_RULE:
          return activityConstants.RULE_SEGMENT_NAME;
        case activityConstants.SERVER_TYPE_DEAL:
          return activityConstants.DEAL_SEGMENT_NAME;
        case activityConstants.SERVER_TYPE_ONE_TIME_ACTION:
          return activityConstants.ONE_TIME_SEGMENT_NAME;
        case activityConstants.ACTIVITY_TYPE_MICRO_CAMPAIGN:
          return activityConstants.MICRO_CAMPAIGN_SEGMENT_NAME;
        default: return '';
      }
    };

    return (
      <div className={activityClass}>{typeName(type)}</div>
    );
  }
}

export default ActivityType;
