import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';

import PopupWrapper from '../../../../../../common/components/PopupWrapper/PopupWrapper';
import PopupTrigger from '../../../../../../common/components/PopupTrigger/PopupTrigger';
import '../membershipConditions/MembershipGlobalConditions.styles.less';
import * as constants from '../../../../activity.constants';
import * as schemaConstants from '../../../../activitySchema/activitySchema.constants';
import TriggersList from '../../../triggers/triggersList';
import Condition from '../../condition';
import './filters.styles.less';
import UserActionConditions from './UserActionConditions';

class FilterUserAction extends React.PureComponent {

  static propTypes = {
    updateFilterUserActionAttribute: PropTypes.func,
    userAction: PropTypes.object,
    tempReferralCondition: PropTypes.object,
    schemaConditionsPerUserAction: PropTypes.object,
    deleteUserActionCriteriaFromFilter: PropTypes.func,
    features: PropTypes.object,
    updateUserActionFilterDateRange: PropTypes.func,
    updateFilterUserActionAggregationAttribute: PropTypes.func,
    validationErrors: PropTypes.object,
    addUserActionCriteriaToFilter: PropTypes.func,
    dateRangeConditionSchema: PropTypes.object,
    updateFilterUserActionType: PropTypes.func,
    schemaUserActions: PropTypes.object,
    tempCondition: PropTypes.object,
    deleteUserActionFromFilter: PropTypes.func,
    dataAutomationPrefix: PropTypes.string,
    placeholder: PropTypes.string,
    userActionIndex: PropTypes.number,
    referralTempError: PropTypes.string,
    createReferralCodeTempModel: PropTypes.func,
    deleteReferralCodeTempModel: PropTypes.func,
    saveReferralCodeTempModelToGlobalConditions: PropTypes.func,
    getBusinessBundlePlan: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.handlePopupOpen = this.handlePopupOpen.bind(this);
    this.handlePopupClose = this.handlePopupClose.bind(this);
    this.onConditionFieldUpdate = this.onConditionFieldUpdate.bind(this);
    this.state = {
      isPopupOpen: false
    };
  }

  onConditionFieldUpdate(name, value, index, conditionKey) {
    let conditionParent = constants.FILTERS_USER_ACTION;
    if (conditionKey === schemaConstants.CONTEXT_REFERRAL_DATA && this.props.tempReferralCondition) {
      conditionParent = constants.TEMP_REFERRAL_CODE_MODEL;
    }
    this.props.updateFilterUserActionAttribute(name, value, index, conditionParent);
  }

  getDateRangeCondition = (userAction) => {
    const actionType = userAction.get(constants.USER_ACTION_KEY);
    if (!!actionType === false) {
      return null;
    }
    const userActionDateRangeCondition = userAction.get(constants.USER_ACTION_DATE_RANGE_CONDITION);

    return (
      <Condition
        className="full-width-condition-value"
        automationIdPrefix={`${this.props.dataAutomationPrefix}.condition.${userActionDateRangeCondition.get(constants.CONDITION_KEY)}`}
        condition={userActionDateRangeCondition}
        conditionsList={this.props.dateRangeConditionSchema}
        features={this.props.features}
        index={-1}
        isDeletable={false}
        hideConditionKey
        isInfiniteDateRange
        isInsideUserAction
        isLastCondition
        trigger={actionType}
        tempCondition={this.props.tempCondition}
        updateConditionField={(conditionName, conditionValue, conditionIndex) =>
          this.onConditionFieldUpdate(conditionName, conditionValue, conditionIndex,
            userActionDateRangeCondition.get(constants.CONDITION_KEY))}
        updateDateRangeDate={(dateObject, isStart, localeTimeFormat, allowInfinite) => this.props.updateUserActionFilterDateRange(dateObject, isStart, localeTimeFormat, allowInfinite)}
        validationErrors={this.props.validationErrors ? this.props.validationErrors.get(constants.USER_ACTION_DATE_RANGE_CONDITION) : null}
        getBusinessBundlePlan={this.props.getBusinessBundlePlan}
      />
    );
  };

  changeUserAction = (selectedAction) => {
    this.setState({ isPopupOpen: false });
    this.props.updateFilterUserActionType(selectedAction.get(constants.NAME));
  };

  handlePopupOpen = () => {
    this.setState({ isPopupOpen: true });
  };

  handlePopupClose = () => {
    this.setState({ isPopupOpen: false });
  };

  get selectedUserActionTextKey() {
    const actionName = this.props.userAction.get(constants.USER_ACTION_KEY);
    if (actionName) {
      return `activity.filters.actions.${actionName}`;
    }
    return this.props.placeholder;
  }
  /**
   * render
   * @returns {*}
   */
  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const handlePopupOpenFunc = this.handlePopupOpen;
    const handlePopupCloseFunc = this.handlePopupClose;
    const isPopupOpen = this.state.isPopupOpen;
    const schemaUserActions = this.props.schemaUserActions;
    const changeUserActionFunc = this.changeUserAction;
    const dateRangeCondition = this.getDateRangeCondition;
    const selectedActionName = this.props.userAction.get(constants.USER_ACTION_KEY);

    return (
      <Grid.Row className="filter-user-action-container">
        <Grid.Column width={16}>
          <div className="right-side-content global-conditions-list">
            <Grid>
              <Grid.Row className="condition-row user-action-row">
                <Grid.Column width={5} style={{ marginRight: '15px' }}>
                  <PopupWrapper
                    basic
                    className="condition-key-popup trigger-list-in-filters"
                    hideTrigger
                    on="click"
                    onOpen={handlePopupOpenFunc}
                    onClose={handlePopupCloseFunc}
                    open={isPopupOpen}
                    position={'bottom left'}
                    automationId={`${this.props.dataAutomationPrefix}.popup`}
                    trigger={
                      <div className="inline-div">
                        <PopupTrigger
                          className={`ui selection dropdown hideTrigger ${selectedActionName ? '' : 'placeholder'} filters-user-action-type`}
                          automationId={this.props.dataAutomationPrefix}
                          message={formatMessage({ id: this.selectedUserActionTextKey })}
                        />
                      </div>
                    }
                    wide="very"
                  >
                    <TriggersList
                      options={schemaUserActions}
                      selectedTrigger={selectedActionName}
                      onSelectOption={(e, selectedAction) => changeUserActionFunc(selectedAction)}
                      triggerKeyPrefix="activity.filters.actions"
                      triggerListElemPrefix={this.props.dataAutomationPrefix}
                    />
                  </PopupWrapper>
                  {selectedActionName === 'failedToSendSms' && <div className={"additional-note-wrapper"}>
                    <span className="additional-note">{formatMessage({id: 'fail_sms.condition.note'})}</span>
                  </div>
                  }
                </Grid.Column>
                <Grid.Column width={6}>
                  {dateRangeCondition(this.props.userAction)}
                </Grid.Column>
                <Grid.Column width={1}>
                  {selectedActionName !== '' &&
                  <Icon
                    className="icon como-ic-delete"
                    onClick={this.props.deleteUserActionFromFilter}
                    data-automation-id={`${this.props.dataAutomationPrefix}.delete`}
                  />
                  }
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="under-trigger-conditions">
                <Grid.Column width={16}>
                  <div>
                    <UserActionConditions
                      addUserActionCriteriaToFilter={this.props.addUserActionCriteriaToFilter}
                      dataAutomationPrefix={this.props.dataAutomationPrefix}
                      deleteUserActionCriteriaFromFilter={this.props.deleteUserActionCriteriaFromFilter}
                      features={this.props.features}
                      schemaConditionsPerUserAction={this.props.schemaConditionsPerUserAction}
                      updateFilterUserActionAggregationAttribute={this.props.updateFilterUserActionAggregationAttribute}
                      updateFilterUserActionAttribute={this.onConditionFieldUpdate}
                      userAction={this.props.userAction}
                      userActionIndex={this.props.userActionIndex}
                      validationErrors={this.props.validationErrors}
                      referralTempCondition={this.props.tempReferralCondition}
                      referralTempError={this.props.referralTempError}
                      createReferralCodeTempModel={this.props.createReferralCodeTempModel}
                      deleteReferralCodeTempModel={this.props.deleteReferralCodeTempModel}
                      saveReferralCodeTempModelToGlobalConditions={this.props.saveReferralCodeTempModelToGlobalConditions}
                      getBusinessBundlePlan={this.props.getBusinessBundlePlan}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

export default injectIntl(FilterUserAction);

