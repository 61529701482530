import { call, put, select, takeLatest } from 'redux-saga/effects';

import { push } from 'react-router-redux';
import { delay } from 'redux-saga';

import * as constants from './emails.constants';
import * as api from './emails.api';
import * as emailApi from '../Email/email.api';
import * as activityConstants from '../Activity/activity.constants';
import * as routeConstants from '../../constants/route.contants';
import * as errorConstant from '../ErrorMessage/errorMessage.constants';
import * as appSelectors from '../App/selectors';
import * as appConstants from '../App/app.constants';

function* getEmails() {
  try {
    // debounce by 50ms
    yield delay(50);
    const data = yield call(api.getEmails);
    yield put({ type: constants.GET_EMAILS_SUCCESS, data });
  } catch (err) {
    yield put({ type: constants.GET_EMAILS_ERROR, message: err.message });
  }
}

function* goToEmail(action) {
  const hasBasicPlanPermissions = yield select(appSelectors.hasBasicPlanPermissions);
  const emailPath = hasBasicPlanPermissions ? routeConstants.BASIC_EMAILS_ROUTE : routeConstants.EMAILS_ROUTE;
  yield put({ type: activityConstants.RESET_ACTIVITY_DATA });
  yield put(push({
    pathname: `${emailPath}/${action[activityConstants.HUB_ID]}`,
    search: location.search,
  }));
}

function* createEmail() {
  const hasBasicPlanPermissions = yield select(appSelectors.hasBasicPlanPermissions);
  const templateLibraryPath = hasBasicPlanPermissions ? routeConstants.BASIC_TEMPLATE_LIBRARY_ROUTE : routeConstants.TEMPLATE_LIBRARY_ROUTE;
  yield put(push({
    pathname: templateLibraryPath,
    search: location.search,
    type: 'email'
  }));
}

function* goToEmailsList() {
  const hasBasicPlanPermissions = yield select(appSelectors.hasBasicPlanPermissions);
  const emailPath = hasBasicPlanPermissions ? routeConstants.BASIC_EMAILS_ROUTE : routeConstants.EMAILS_ROUTE;
  yield put(push({
    pathname: emailPath,
    search: location.search,
  }));
}

function* getEmailStats(action) {
  try {
    yield put({ type: constants.CLEAN_EMAIL_STATS, data });
    yield put({ type: constants.SET_EMAIL_STATS_LOADING });
    const data = yield call(api.getEmailStats, action[activityConstants.HUB_ID]);
    yield put({ type: constants.GET_EMAIL_STATS_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getEmailStats.Fail'] });
  }
}

function* updateEmailStatus(action) {
  try {
    let email = action[activityConstants.ACTIVITY_TYPE_EMAIL];
    email = email.set(activityConstants.STATUS, email.get(activityConstants.STATUS) === activityConstants.ACTIVITY_STATUS_ACTIVE ? activityConstants.ACTIVITY_STATUS_INACTIVE : activityConstants.ACTIVITY_STATUS_ACTIVE);
    const data = yield call(emailApi.saveEmail, email);
    yield put({type: constants.UPDATE_EMAIL_STATUS_SUCCESS, data});
  } catch (err) {
    yield put({type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['updateEmailStatus.Fail']});
  }
}

function* getEmailStatsPerOnetimeCampaign(action) {
  try {
    yield put({ type: constants.CLEAN_DETAILED_EMAIL_STATS, data });
    const data = yield call(api.getEmailStatsPerCampaign, action[activityConstants.HUB_ID], action[constants.EMAIL_CAMPAIGN_ID]);
    yield put({ type: constants.GET_DETAILED_STATS_PER_ONETIME_CAMPAIGN_SUCCESS, [constants.EMAIL_CAMPAIGN_ID]: action[constants.EMAIL_CAMPAIGN_ID], data });

  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getEmailStatsPerCampaign.Fail'] });
  }
}

function* getEmailStatsPerRule(action) {
  try {
    yield put({ type: constants.CLEAN_DETAILED_EMAIL_STATS, data });
    const data = yield call(api.getEmailStatsPerRule, action[activityConstants.HUB_ID], action.campaigns);
    yield put({ type: constants.GET_DETAILED_STATS_PER_RULE_SUCCESS, [constants.EMAIL_CAMPAIGN_ID]: action[constants.EMAIL_CAMPAIGN_ID], data, ruleId: action.ruleId });

  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getEmailStatsPerRule.Fail'] });
  }
}

function* getEmailStatsPerMicroCampaign(action) {
  try {
    yield put({ type: constants.CLEAN_DETAILED_EMAIL_STATS, data });
    const data = yield call(api.getEmailStatsPerRule, action[activityConstants.HUB_ID], action.campaigns);
    yield put({ type: constants.GET_DETAILED_STATS_PER_MICRO_CAMPAIGN_SUCCESS, [constants.EMAIL_CAMPAIGN_ID]: action[constants.EMAIL_CAMPAIGN_ID], data, microCampaignId: action.microCampaignId });

  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getEmailStatsPerRule.Fail'] });
  }
}

function* getEmailUsages(action) {
  try {
    yield put({ type: constants.CLEAN_EMAIL_USAGES_DATA, data });
    const data = yield call(api.getEmailUsages, action[activityConstants.HUB_ID]);
    yield put({ type: constants.GET_EMAIL_USAGES_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getEmailRelatedActivities.Fail'] });
  }
}

// sagas listen to dispatched actions too (same as reducers)
function* emailsSaga() {
  yield [
    takeLatest(constants.GET_EMAILS, getEmails),
    takeLatest(constants.UPDATE_EMAIL_STATUS, updateEmailStatus),
    takeLatest(constants.GO_TO_EMAIL, goToEmail),
    takeLatest(constants.CREATE_EMAIL, createEmail),
    takeLatest(constants.GO_TO_EMAILS_LIST, goToEmailsList),
    takeLatest(constants.GET_EMAIL_STATS, getEmailStats),
    takeLatest(constants.GET_DETAILED_STATS_PER_ONETIME_CAMPAIGN, getEmailStatsPerOnetimeCampaign),
    takeLatest(constants.GET_DETAILED_STATS_PER_RULE, getEmailStatsPerRule),
    takeLatest(constants.GET_DETAILED_STATS_PER_MICRO_CAMPAIGN, getEmailStatsPerMicroCampaign),
    takeLatest(constants.GET_EMAIL_USAGES, getEmailUsages),

  ];
}


export default emailsSaga;
