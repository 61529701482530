import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { List } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { HUB_ID, NAME, SERVER_ID } from '../../../ItemGroups/itemGroups.constants';
import { ACTIVITY_STATUS_ACTIVE, ACTIVITY_STATUS_DELETED, STATUS } from '../../activity.constants';

import SearchItems from './searchItems';
import GroupItem from './groupItem';
import { deactivateInProgressSelector } from '../../../ItemGroups/itemGroups.selectors';

class ItemGroups extends React.PureComponent {

  static propTypes = {
    advancedRulesSchemaConditions: PropTypes.object.isRequired,
    allItemGroups: PropTypes.object.isRequired,
    automationIdPrefix: PropTypes.string.isRequired,
    initialSelectedItemGroups: PropTypes.array.isRequired,
    selectedItemGroups: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onDeleteGroup: PropTypes.func.isRequired,
    onUpdateItemsGroup: PropTypes.func.isRequired,
    errors: PropTypes.string,
    posFeature: PropTypes.string.isRequired,
    itemGroupDeactivateInProgress: PropTypes.string
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      query: ''
    };
  }

  /**
   * update state with search query
   * @param {string} newQuery
   */
  onSearchQueryChange = (newQuery) => {
    this.setState({
      query: newQuery
    });
  };

  /**
   * handler change in checkbox selection for groups
   * @param {string} id
   * @param {boolean} checked
   */
  onChange = (id, checked) => {
    let newGroupsList;
    if (checked) {
      newGroupsList = [...this.props.selectedItemGroups, id];
    } else {
      newGroupsList = this.props.selectedItemGroups.filter((groupId) => groupId !== id);
    }
    this.props.onChange(newGroupsList);
  };

  /**
   * groups is checked when its serverId exists in the selectedItemGroups array
   * @param id
   * @returns {boolean}
   */
  isGroupChecked = (id) => this.props.selectedItemGroups.indexOf(id) > -1;

  render() {

    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const deletedItemGroups = this.props.allItemGroups.filter((group) =>
      group.get(STATUS) === ACTIVITY_STATUS_DELETED && this.props.initialSelectedItemGroups.indexOf(group.get(SERVER_ID)) >= 0);
    const activeItemGroups = this.props.allItemGroups.filter((group) => group.get(STATUS) === ACTIVITY_STATUS_ACTIVE);
    const automationIdPrefix = `${this.props.automationIdPrefix}.item`;
    const clsName = classNames('items-list', { 'item-box-error': !!this.props.errors });

    return (
      <div>
        <div className={clsName}>
          {
            activeItemGroups.size || deletedItemGroups.size
              ? (
                <div>
                  <SearchItems
                    query={this.state.query}
                    onChange={this.onSearchQueryChange}
                    automationIdPrefix={this.props.automationIdPrefix}
                  />
                  <List>
                    {
                      deletedItemGroups
                        .filter((group) => this.state.query.length ? group.get(NAME).toLowerCase().indexOf(this.state.query.toLowerCase()) >= 0 : true)
                        .map((group, index) => {
                          const isChecked = this.isGroupChecked(group.get(SERVER_ID));
                          return (
                            <GroupItem
                              automationIdPrefix={`${automationIdPrefix}.${index}`}
                              group={group}
                              groupsCount={activeItemGroups.size}
                              isChecked={isChecked}
                              isDeleted
                              key={group.get(HUB_ID)}
                              onChange={this.onChange}
                              posFeature={this.props.posFeature}
                              deleteInProgress={this.props.itemGroupDeactivateInProgress}
                            />
                          );
                        }
                        )
                    }
                    {
                      activeItemGroups
                        .filter((group) => this.state.query.length ? group.get(NAME).toLowerCase().indexOf(this.state.query.toLowerCase()) >= 0 : true)
                        .map((group, index) => {
                          const isChecked = this.isGroupChecked(group.get(SERVER_ID));
                          return (
                            <GroupItem
                              advancedRulesSchemaConditions={this.props.advancedRulesSchemaConditions}
                              automationIdPrefix={`${automationIdPrefix}.${index}`}
                              group={group}
                              groupsCount={activeItemGroups.size}
                              isChecked={isChecked}
                              isDeleted={false}
                              key={group.get(HUB_ID)}
                              onChange={this.onChange}
                              onDeleteGroup={this.props.onDeleteGroup}
                              onUpdateItemsGroup={this.props.onUpdateItemsGroup}
                              posFeature={this.props.posFeature}
                              deleteInProgress={this.props.itemGroupDeactivateInProgress}
                            />
                          );
                        }
                        )
                    }
                  </List>
                </div>
              )
              : (
                <p>
                  {formatMessage({
                    id: 'activity.itemsPopulation.itemGroups.empty',
                    defaultMessage: 'You can create a pre-define group of item codes or department codes that you are using often!'
                  })}
                </p>
              )
          }

        </div>
        { this.props.errors ? (<div className="item-box-error-message">{formatMessage({ id: this.props.errors })}</div>) : null }
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  itemGroupDeactivateInProgress: deactivateInProgressSelector(state)
});


const withConnect = connect(mapStateToProps);
export default compose(withConnect, injectIntl)(ItemGroups);
