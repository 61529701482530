/**
*
* HeaderNotification
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

class HeaderNotification extends React.PureComponent {

  static propTypes = {
    activityName: PropTypes.string,
    activityType: PropTypes.string,
    automationIdPrefix: PropTypes.string,
    messageKey: PropTypes.string,
    createdEntityType: PropTypes.string
  };

  /**
   * if headerMessage passed print it, otherwise create it according to activityName and activityType
   * @returns {*}
   */
  get message() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    if (this.props.messageKey) {
      return formatMessage({ id: this.props.messageKey });
    }
    const originActivityType = formatMessage({ id: `business-center.filter-settings.activity.${this.props.activityType}` });
    if (this.props.activityName) {
      return formatMessage({ id: `activity.create${this.props.createdEntityType}FromActivity.headerNotificationWithName` }, {
        originName: this.props.activityName,
        activityType: originActivityType
      });
    }
    return formatMessage({ id: `activity.create${this.props.createdEntityType}FromActivity.headerNotificationWithoutName` }, { activityType: originActivityType });
  }

  render() {
    return (
      <p className="top-notification" data-automation-id={`${this.props.automationIdPrefix}.origin-activity-notification`}>
        {
          this.message
        }
      </p>
    );
  }
}

export default injectIntl(HeaderNotification);

