import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Grid } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import * as schemaConstants from '../../../../activitySchema/activitySchema.constants';
import BenefitsList from '../../../../../Benefits/views/benefitsList/benefitsList';
import IntegerInputField from '../../../../../../common/components/FormInputFields/IntegerInputField/IntegerInputField';
import * as activitySelectors from '../../../../activity.selectors';
import * as benefitSelectors from '../../../../../Benefits/benefits.selector';
import * as activityActions from '../../../../activity.actions';
import './assignAsset.styles.less';
import * as constants from '../../../../../Benefits/benefits.constants';


class assignAsset extends React.PureComponent {

  static propTypes = {
    action: PropTypes.object,
    actions: PropTypes.object,
    automationIdPrefix: PropTypes.string,
    createGiftFromActivity: PropTypes.func,
    index: PropTypes.number,
    newlyCreatedGiftId: PropTypes.string,
    onActionFieldUpdate: PropTypes.func.isRequired,
    selectedBenefit: PropTypes.object,
    validationErrors: PropTypes.object,
    actionsWrapperIndex: PropTypes.number

  };

  constructor(props) {
    super(props);
    this.createGiftFromActivityOriginAction = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.newlyCreatedGiftId && nextProps.newlyCreatedGiftId) {
      // highlight relevant benefits list for a few seconds
      setTimeout(this.props.actions.clearNewlySelectedGiftId, 3000);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.createGiftFromActivityOriginAction.current) {
      this.createGiftFromActivityOriginAction.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  onBenefitSelect = (e, data) => {
    this.props.onActionFieldUpdate(data.name, data.value, this.props.index);
  };

  get isSelectedBenefitNewlyCreated() {
    const { newlyCreatedGiftId, selectedBenefit } = this.props;
    return newlyCreatedGiftId && selectedBenefit && newlyCreatedGiftId === selectedBenefit.get(constants.BENEFIT_SERVER_ID);
  }

  createGiftFromActivity = (index) => {
    this.props.createGiftFromActivity(index, this.props.actionsWrapperIndex);
  };

  render() {

    const { action, automationIdPrefix, index, onActionFieldUpdate, selectedBenefit, validationErrors } = this.props;
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const selectedBenefitIsExpired = selectedBenefit &&
      moment.utc(selectedBenefit.get(constants.BENEFIT_VALID_UNTIL)).isBefore(moment.utc());
    const selectedBenefitIsInactive = selectedBenefit && !selectedBenefit.get(constants.BENEFIT_ACTIVE);
    const benefitsListClass = classNames('', {
      highlighted: this.isSelectedBenefitNewlyCreated
    });

    return (
      <Grid className="action assign-asset-action">
        <Grid.Row>
          <Grid.Column width={16}>
            <div
              ref={this.isSelectedBenefitNewlyCreated ? this.createGiftFromActivityOriginAction : null}
            >
              <BenefitsList
                action={action}
                allowCreateGift
                createGiftFromActivity={this.createGiftFromActivity}
                className={benefitsListClass}
                error={validationErrors ? validationErrors.get(schemaConstants.ASSIGN_ASSET_BENEFIT_KEY_FIELD) : null}
                index={index}
                name={schemaConstants.ASSIGN_ASSET_BENEFIT_KEY_FIELD}
                onChange={this.onBenefitSelect}
                prefix={`${automationIdPrefix}.benefitKey`}
                value={action.get(schemaConstants.ASSIGN_ASSET_BENEFIT_KEY_FIELD)}
              />
              <span className="align-baseline inner-label">{formatMessage({ id: 'activity.action.quantity', defaultMessage: 'Quantity' })}</span>
              <IntegerInputField
                name={schemaConstants.ASSIGN_ASSET_QUANTITY_FIELD}
                fluid={false}
                onChange={(e, data) => onActionFieldUpdate(data.name, data.value, index)}
                value={action.get(schemaConstants.ASSIGN_ASSET_QUANTITY_FIELD)}
                index={index}
                prefix={`${automationIdPrefix}.quantity`}
                error={validationErrors ? validationErrors.get(schemaConstants.ASSIGN_ASSET_QUANTITY_FIELD) : null}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        {
          (selectedBenefitIsInactive || selectedBenefitIsExpired) &&
          (
            <Grid.Row>
              <Grid.Column>
                <p className="hint">
                  {formatMessage(
                    { id: `benefits.${selectedBenefitIsInactive ? 'inactive' : 'expired'}.hint` },
                    { benefitName: selectedBenefit.get(constants.BENEFIT_TITLE) }
                  )}
                </p>
              </Grid.Column>
            </Grid.Row>
          )
        }
      </Grid>
    );
  }
}

const mapStateToProps = (state, props) => ({
  selectedBenefit: benefitSelectors.getSelectedBenefit(state, props.action.get(schemaConstants.ASSIGN_ASSET_BENEFIT_KEY_FIELD)),
  newlyCreatedGiftId: activitySelectors.getNewlyCreatedGiftId(state)
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(activityActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  injectIntl
)(assignAsset);
