import * as constants from '../promoCodes.constants';
import * as activityConstants from '../../Activity/activity.constants';
import { sortArrayByDate } from '../../../common/utils/dateTimeUtils';
import { calculateActivityViewStatus } from '../../Activity/activity.selectors.utils';

export function getFilteredAndSortedPromoCodesImpl(promoCodes, filters, order, searchTerm) {
  const filtered = getFilteredPromoCodes(promoCodes, filters, searchTerm);
  const orderFactor = order === constants.ORDER_ASCENDING ? 1 : -1;
  return sortArrayByDate(filtered, [activityConstants.CREATE_DATE], orderFactor);
}

export function isPromoCodesFilteredImpl(promoCodes, filteredPromoCodes) {
  return promoCodes.size !== filteredPromoCodes.size;
}

export function getContextMenuItemsVisibilityMap(viewStatus) {
  const isDraft = viewStatus === activityConstants.ACTIVITY_STATUS_DRAFT;
  const isActive = viewStatus === activityConstants.ACTIVITY_STATUS_ACTIVE
    || viewStatus === activityConstants.ACTIVITY_STATUS_FUTURE;
  const isPaused = viewStatus === activityConstants.ACTIVITY_STATUS_PAUSED;
  const isCompleted = viewStatus === activityConstants.ACTIVITY_STATUS_COMPLETED;
  return {
    [activityConstants.CONTEXT_MENU_OPTION_DETAILS]: true,
    [activityConstants.CONTEXT_MENU_OPTION_ACTIVATE]: isPaused,
    [activityConstants.CONTEXT_MENU_OPTION_PAUSE]: isActive,
    [activityConstants.CONTEXT_MENU_OPTION_DEACTIVATE]: isPaused || isActive,
    [activityConstants.CONTEXT_MENU_OPTION_DELETE]: isDraft,
    [activityConstants.CONTEXT_MENU_OPTION_EXTEND]: isCompleted,
    [activityConstants.CONTEXT_MENU_OPTION_STATS]: isPaused || isActive,
    [activityConstants.CONTEXT_MENU_OPTION_RESTART]: !isPaused && !isActive
  };
}

function getFilteredPromoCodes(promoCodes, filters, searchTerm) {
  const searchTermLower = searchTerm && searchTerm.toLowerCase();
  const filterFlags = filters.get(constants.PROMO_CODES);
  return promoCodes.filter((promoCode) => {

    const viewStatus = calculateActivityViewStatus(promoCode);
    if ((viewStatus === activityConstants.ACTIVITY_STATUS_ACTIVE && !filterFlags.get(activityConstants.FILTERS_ACTIVITY_ACTIVE)) ||
      (viewStatus === activityConstants.ACTIVITY_STATUS_STOPPED && !filterFlags.get(activityConstants.FILTERS_ACTIVITY_STOPPED)) ||
      (viewStatus === activityConstants.ACTIVITY_STATUS_FUTURE && !filterFlags.get(activityConstants.FILTERS_ACTIVITY_FUTURE)) ||
      (viewStatus === activityConstants.ACTIVITY_STATUS_COMPLETED && !filterFlags.get(activityConstants.FILTERS_ACTIVITY_COMPLETED)) ||
      (viewStatus === activityConstants.ACTIVITY_STATUS_PAUSED && !filterFlags.get(activityConstants.FILTERS_ACTIVITY_PAUSED)) ||
      (viewStatus === activityConstants.ACTIVITY_STATUS_DRAFT && !filterFlags.get(activityConstants.FILTERS_ACTIVITY_DRAFT))) {
      return false;
    }
    return !searchTerm || promoCode.get(activityConstants.NAME).toLowerCase().includes(searchTermLower);
  });
}
