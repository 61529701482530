import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { delay } from 'redux-saga';

import * as constants from './microCampaigns.constants';
import * as api from './microCampaigns.api';
import * as queryStringUtils from '../../utils/queryStringUtils';
import * as activityConstants from '../Activity/activity.constants';
import * as routeConstants from '../../constants/route.contants';
import * as errorConstant from '../ErrorMessage/errorMessage.constants';

function* getMicroCampaigns() {
  try {
    const data = yield call(api.getMicroCampaigns);
    yield put({ type: constants.GET_MICRO_CAMPAIGNS_SUCCESS, data });
  } catch (err) {
    yield put({
      type: errorConstant.HANDLE_ERROR,
      [errorConstant.MESSAGES_KEYS]: ['getMicroCampaigns.Fail']
    });
  }
}

export function* goToMicroCampaigns() {
  const locationSearch = queryStringUtils.removeParameter(location.search, activityConstants.MIGRATION_ID);
  yield put(push({
    pathname: routeConstants.MICRO_CAMPAIGNS_ROUTE,
    search: locationSearch,
  }));
}

export function* goToMicroCampaign({ hubId }) {
  yield put(push({
    pathname: `/microCampaigns/${hubId}`,
    search: location.search,
  }));
}

export function* getMicroCampaignStats({ id, locationId }) {
  // debounce by 50ms
  yield delay(50);
  try {
    const data = yield call(api.getMicroCampaignStats, id, locationId);
    yield put({ type: constants.GET_MICRO_CAMPAIGN_STATS_SUCCESS, data });
  } catch (err) {
    yield put({
      type: errorConstant.HANDLE_ERROR,
      [errorConstant.MESSAGES_KEYS]: ['getMicroCampaignStats.Fail']
    });
  }
}

// sagas listen to dispatched actions too (same as reducers)
function* microCampaignsSaga() {
  yield takeEvery(constants.GET_MICRO_CAMPAIGNS, getMicroCampaigns);
  yield takeEvery(constants.GO_TO_MICRO_CAMPAIGNS, goToMicroCampaigns);
  yield takeLatest(constants.GO_TO_MICRO_CAMPAIGN, goToMicroCampaign);
  yield takeLatest(constants.GET_MICRO_CAMPAIGN_STATS, getMicroCampaignStats);
}

export default microCampaignsSaga;
