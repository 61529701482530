import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import './CardWrapper.styles.less';

class CardWrapper extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string.isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
    descriptionKey: PropTypes.string,
    headerKey: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    glowOnHover: true
  };

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    return (
      <Card
        className={`raised-card-container ${this.props.className || ''}`}
        data-automation-id={`${this.props.automationIdPrefix}.raisedCard`}
        onClick={this.props.onClick}
      >
        <Card.Content>
          <Card.Header className="raised-card-header">{formatMessage({ id: this.props.headerKey })}</Card.Header>
          <Card.Meta className="raised-card-meta">{formatMessage({ id: this.props.descriptionKey })}</Card.Meta>
          {this.props.children}
        </Card.Content>
      </Card>
    );
  }
}

export default injectIntl(CardWrapper);
