/*
 *
 * BusinessCenter reducer
 *
 */

import { fromJS } from 'immutable';
import * as constants from './sideNavBar.constants';

const initialState = fromJS({});

function sideNavBarReducer(state = initialState, action) {
  switch (action.type) {

    case constants.TOGGLE_COLLAPSE: {
      const path = [constants.COLLAPSE_MAP, action[constants.ITEM_KEY]];
      return state.setIn(path, action[constants.ITEM_COLLAPSE]);
    }

    default: {
      return state;
    }
  }
}

export default sideNavBarReducer;
