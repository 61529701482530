import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Radio } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import FilterUserAttributes from '../filters/FilterUserAttribute';
import * as activityConstants from '../../../../activity.constants';
import * as schemaConstants from '../../../../activitySchema/activitySchema.constants';
import Condition from '../../condition';
import './MembershipGlobalConditions.styles.less';
import FilterUserActions from '../filters/FilterUserActions';
import MultiCardsWrapper from '../../../../../../common/components/Cards/CardWrapper/MultiCardsWrapper';
import DownloadCsvFromInput
  from '../../../../../../common/components/FormInputFields/DownloadCsvFromInput/DownloadCsvFromInput';

import ImportMembersFile from '../filters/ImportMembersFile';
import * as activitySelectors from '../../../../activity.selectors';
import * as globalConditionsActions from '../globalConditions.actions';
import * as appConstants from '../../../../../App/app.constants';

class MembershipGlobalConditions extends React.PureComponent {

  static propTypes = {
    actions: PropTypes.object,
    clearGlobalImportMembershipCondition: PropTypes.func,
    importMembershipGlobalCondition: PropTypes.object,
    goingToPasteImportMembersData: PropTypes.func,
    membershipGlobalConditions: PropTypes.object,
    schemaMembershipConditions: PropTypes.object,
    conditionsModel: PropTypes.object,
    addCondition: PropTypes.func,
    updateConditionsOperator: PropTypes.func,
    membershipGlobalOperator: PropTypes.string,
    updateConditionField: PropTypes.func,
    deleteCondition: PropTypes.func,
    isMembersFilter: PropTypes.bool.isRequired,
    resetCondition: PropTypes.func,
    updateGlobalImportMembershipCondition: PropTypes.func,
    validationErrors: PropTypes.object,
    tempValidationErrors: PropTypes.object,
    filtersInProgress: PropTypes.bool,
    updateGlobalConditionsType: PropTypes.func,
    conditionType: PropTypes.string,
    filterErrorExists: PropTypes.bool,
    updateFilterUserActionType: PropTypes.func.isRequired,
    referralTempCondition: PropTypes.object,
    referralTempError: PropTypes.string,
    adjustedByComillia: PropTypes.bool,
    shouldNonMemberConditionBeShown: PropTypes.bool,
    getBusinessBundlePlan: PropTypes.func.isRequired,
  };

  getParsedMembershipImportCopyPasteData() {
    if (this.props.importMembershipGlobalCondition) {
      const conditionTypeToValue = this.props.importMembershipGlobalCondition.getIn([activityConstants.CONDITION_TYPE_TO_COPY_PASTE_VALUE, this.props.conditionType]);
      if (conditionTypeToValue) {
        const copyPasteMembersValue = conditionTypeToValue;
        if (copyPasteMembersValue && copyPasteMembersValue.size > 0) {
          return copyPasteMembersValue.toJS().join('\r\n');
        }
      }
    }
    return '';
  }

  getImportMembersCopyPasteConditionErrors() {
    if (this.props.importMembershipGlobalCondition &&
      this.props.importMembershipGlobalCondition.getIn([activityConstants.IMPORT_MEMBERS_COPY_PASTE_MODE, activityConstants.IMPORT_MEMBERS_LIMIT_EXCEEDED])) {
      return 'activity.validation.error.import.members.condition.limit.exceeded';
    }
    return this.props.validationErrors
      ? this.props.validationErrors.getIn([activityConstants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER, activityConstants.IMPORT_MEMBERS_COPY_PASTE_MODE, activityConstants.CONDITION_VALUE])
      : null;
  }

  get isPastingImportMembersCondition() {
    return this.props.importMembershipGlobalCondition
      ? this.props.importMembershipGlobalCondition.getIn([activityConstants.IMPORT_MEMBERS_COPY_PASTE_MODE, activityConstants.PASTING_IMPORT_MEMBERS])
      : false;
  }

  get selectedImportMembersMode() {
    return this.props.importMembershipGlobalCondition
      ? this.props.importMembershipGlobalCondition.get(activityConstants.IMPORT_MEMBERS_MODE)
      : null;
  }

  get isUploadInProgress() {
    return this.props.importMembershipGlobalCondition
      ? this.props.importMembershipGlobalCondition.getIn([activityConstants.IMPORT_MEMBERS_UPLOAD_FILE_MODE, activityConstants.UPLOAD_MEMBERS_FILE_IN_PROGRESS])
      : false;
  }

  get importedMembersFileName() {
    if (this.props.importMembershipGlobalCondition) {
      const fullFileName = this.props.importMembershipGlobalCondition.getIn([activityConstants.CONDITION_TYPE_TO_IMPORT_FILE_VALUE, this.props.conditionType,
        activityConstants.CONDITION_VALUE, activityConstants.UPLOADED_FILE_NAME]);
      return fullFileName ? fullFileName.substring(fullFileName.lastIndexOf('/') + 1) : null;
    }
    return null;
  }
  get importedMembersFileLink() {
    if (this.props.importMembershipGlobalCondition) {
      const mediaLink = this.props.importMembershipGlobalCondition.getIn([activityConstants.CONDITION_TYPE_TO_IMPORT_FILE_VALUE, this.props.conditionType,
        activityConstants.CONDITION_VALUE, activityConstants.MEDIA_LINK]);
      return mediaLink || null;
    }
    return null;
  }

  get importMembersFileUploadConditionErrors() {
    return this.props.validationErrors
      ? this.props.validationErrors.getIn([activityConstants.MEMBERSHIP_IMPORT_CONDITION_WRAPPER, activityConstants.IMPORT_MEMBERS_UPLOAD_FILE_MODE, activityConstants.CONDITION_VALUE])
      : null;
  }

  selectImportCard = (mode) => this.props.updateGlobalImportMembershipCondition(null, mode, this.props.conditionType);


  /**
   * filters out the specific membership status condition (it has special treatment in a separate from condition component)
   */
  filteredMembershipConditionsSchema() {
    if (this.props.schemaMembershipConditions) {
      return this.props.schemaMembershipConditions.filter((conditionInList) =>
        conditionInList.get(activityConstants.GLOBAL_CONDITION_KEY) !== schemaConstants.CONTEXT_MEMBERSHIP_STATUS);
    }
    return null;
  }

  getImportMembersCards(conditionType, title, name, fileName) {
    const copyPasteCard = (<DownloadCsvFromInput
      error={this.getImportMembersCopyPasteConditionErrors()}
      clearValue={this.props.clearGlobalImportMembershipCondition}
      goingToPasteImportMembersData={this.props.goingToPasteImportMembersData}
      inputValue={this.getParsedMembershipImportCopyPasteData()}
      isPasting={this.isPastingImportMembersCondition}
      inputType={conditionType}
      filename={fileName}
      name={name}
      onChange={this.props.updateGlobalImportMembershipCondition}
      prefix={`filters.${name}.pasteCodes.input`}
    />);

    const importFileCard = (<ImportMembersFile
      automationIdPrefix={`filters.${name}.file.input`}
      error={this.importMembersFileUploadConditionErrors}
      onUpload={() => this.selectImportCard(activityConstants.IMPORT_MEMBERS_UPLOAD_FILE_MODE)}
      uploadInProgress={this.isUploadInProgress}
      uploadedFileName={this.importedMembersFileName}
      uploadedFileLink={this.importedMembersFileLink}
      uploadFileType={conditionType}
    />);

    return [
      {
        title: 'activity.globalConditions.members.radioBtn.import.members.copy.paste.title',
        description: `activity.globalConditions.members.radioBtn.import.members${title}copy.paste.description`,
        content: copyPasteCard,
        id: activityConstants.IMPORT_MEMBERS_COPY_PASTE_MODE
      },
      {
        title: 'activity.globalConditions.members.radioBtn.import.members.upload.file.title',
        description: `activity.globalConditions.members.radioBtn.import.members${title}upload.file.description`,
        content: importFileCard,
        id: activityConstants.IMPORT_MEMBERS_UPLOAD_FILE_MODE
      }
    ];
  }

  renderMembershipAttributeConditions() {
    const filteredMembershipConditionsFromSchema = this.filteredMembershipConditionsSchema();
    const showAddConditionButton = !this.props.membershipGlobalConditions || this.props.membershipGlobalConditions.size === 0 ||
      this.props.membershipGlobalConditions.last().get(activityConstants.CONDITION_KEY) !== '';

    return (
      <FilterUserAttributes
        filtersInProgress={this.props.filtersInProgress}
        membersFilter={this.props.isMembersFilter}
        membershipGlobalConditions={this.props.membershipGlobalConditions}
        prop5={(condition, index) => (
          <Condition
            condition={condition}
            conditionsList={filteredMembershipConditionsFromSchema}
            index={index}
            key={`membershipGlobalCondition-${index.toString()}`}
            deleteCondition={this.props.deleteCondition}
            resetCondition={this.props.resetCondition}
            updateConditionField={this.props.updateConditionField}
            isInsideModal
            validationErrors={this.props.validationErrors ? this.props.validationErrors.getIn([activityConstants.CONDITIONS, (index.toString())]) : null}
            automationIdPrefix={`globalConditions.members.condition.${index}`}
            mainConditions={false}
            isLastCondition={index === this.props.membershipGlobalConditions.size - 1}
            conditionKeyWidth={5}
            conditionOperatorWidth={4}
            conditionValueWidth={5}
            referralTempCondition={this.props.referralTempCondition}
            referralTempError={this.props.referralTempError}
            createReferralCodeTempModel={this.props.actions.createReferralCodeTempModel}
            deleteReferralCodeTempModel={this.props.actions.deleteReferralCodeTempModel}
            saveReferralCodeTempModelToGlobalConditions={this.props.actions.saveReferralCodeTempModelToGlobalConditions}
            getBusinessBundlePlan={this.props.getBusinessBundlePlan}
          />
        )}
        showAddConditionButton={showAddConditionButton}
        onClick={this.props.addCondition}
        onUpdateConditionRelation={this.props.updateConditionsOperator}
        conditionsRelation={this.props.membershipGlobalOperator}
      />
    );
  }

  /**
   * render
   * @returns {*}
   */
  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const contentClassNames = classNames('choose-members-group', {
      'with-errors': this.props.filterErrorExists || this.props.validationErrors
    });
    const phoneNoDisplayTitle = '.phoneno.';
    const importPhoneNumberName = 'importPhoneNumbers';
    const importPhoneNumberFileName = 'importPhoneNumbers.csv';
    const memberShipTitle = '.';
    const membershipName = 'importMembers';
    const membershipFileName = 'membershipKeys.csv';
    const registeredMemberLabel = this.props.shouldNonMemberConditionBeShown ? 'activity.globalConditions.deal.members.radioBtn.active' : 'activity.globalConditions.members.radioBtn.active';

    return (
      <div className={contentClassNames}>
        {
          this.props.adjustedByComillia &&
          <div className="toolbar-info-panel">
            <span className="toolbar-info-text">{formatMessage({ id: 'adjusted.by.comillia.hint' })}</span>
          </div>
        }
        {!this.props.isMembersFilter &&
          <p>{formatMessage({ id: 'activity.globalConditions.members.subTitle' })}</p>
        }
        <div>
          <Radio
            label={formatMessage({ id: registeredMemberLabel })}
            name="membersGroup"
            value={activityConstants.MEMBERS_REGISTERED}
            disabled={this.props.filtersInProgress}
            checked={!!(this.props.conditionType && this.props.conditionType === activityConstants.MEMBERS_REGISTERED)}
            onChange={(event, elem) => this.props.updateGlobalConditionsType(elem.value)}
            data-automation-id="globalConditions.members.radioBtn.activeMembers"
          />
        </div>
        {this.props.shouldNonMemberConditionBeShown &&
          <div>
            <Radio
              label={formatMessage({ id: 'activity.globalConditions.nonmembers.radioBtn.active' })}
              name="membersGroup"
              value={activityConstants.NON_MEMBERS}
              disabled={this.props.filtersInProgress}
              checked={!!(this.props.conditionType && this.props.conditionType === activityConstants.NON_MEMBERS)}
              onChange={(event, elem) => this.props.updateGlobalConditionsType(elem.value)}
              data-automation-id="globalConditions.members.radioBtn.specificMembers"
            />
            <div>
              <Radio
                label={formatMessage({ id: 'activity.globalConditions.allCustomers.radioBtn.active' })}
                name="membersGroup"
                value={activityConstants.ALL_COSTUMERS}
                disabled={this.props.filtersInProgress}
                checked={!!(this.props.conditionType && this.props.conditionType === activityConstants.ALL_COSTUMERS)}
                onChange={(event, elem) => this.props.updateGlobalConditionsType(elem.value)}
                data-automation-id="globalConditions.members.radioBtn.allCustomers"
              />
            </div>
          </div>
        }
        <div>
          <Radio
            label={formatMessage({ id: 'activity.globalConditions.members.radioBtn.specific' })}
            name="membersGroup"
            value={activityConstants.MEMBERS_SPECIFIC}
            disabled={this.props.filtersInProgress}
            checked={!!(this.props.conditionType && this.props.conditionType === activityConstants.MEMBERS_SPECIFIC)}
            onChange={(event, elem) => this.props.updateGlobalConditionsType(elem.value)}
            data-automation-id="globalConditions.members.radioBtn.specificMembers"
          />
        </div>
        {this.props.conditionType === activityConstants.MEMBERS_SPECIFIC && this.props.membershipGlobalConditions &&
          <div>
            {this.renderMembershipAttributeConditions()}
            {this.props.isMembersFilter && (
              <FilterUserActions
                className={'!tw-pb-4'}
                conditionsModel={this.props.conditionsModel}
                updateFilterUserActionType={this.props.updateFilterUserActionType}
                addUserActionCriteriaToFilter={this.props.actions.addUserActionCriteriaToFilter}
                updateFilterUserActionAttribute={this.props.actions.updateFilterUserActionAttribute}
                deleteUserActionFromFilter={(userActionIndex) => this.props.actions.deleteUserActionFromFilter(userActionIndex)}
                deleteUserActionCriteriaFromFilter={(userActionIndex, conditionIndex) => this.props.actions.deleteUserActionCriteriaFromFilter(userActionIndex, conditionIndex)}
                updateUserActionFilterDateRange={(dateObject, isStart, localeTimeFormat, allowInfinite, userActionIndex, businessTimezone) => this.props.actions.updateUserActionFilterDateRange(dateObject, isStart, localeTimeFormat, allowInfinite, userActionIndex, businessTimezone)}
                updateFilterUserActionAggregationAttribute={(name, value, isActive, index, userActionIndex) => this.props.actions.updateFilterUserActionAggregationAttribute(name, value, isActive, index, userActionIndex)}
                tempReferralCondition={this.props.referralTempCondition}
                referralTempError={this.props.referralTempError}
                createReferralCodeTempModel={this.props.actions.createReferralCodeTempModel}
                deleteReferralCodeTempModel={this.props.actions.deleteReferralCodeTempModel}
                saveReferralCodeTempModelToGlobalConditions={this.props.actions.saveReferralCodeTempModelToGlobalConditions}
                validationErrors={this.props.tempValidationErrors}
                onUpdateConditionRelation={this.props.actions.updateGlobalMembershipConditionsActionOperator}
              />
            )}
            <Grid className="member-fields-conditions">
            </Grid>
          </div>
          }
        {(this.props.isMembersFilter && this.props.getBusinessBundlePlan() !== appConstants.REVEL) &&
          <div>
            <Radio
              label={formatMessage({ id: 'activity.globalConditions.members.radioBtn.import.members' })}
              name="membersGroup"
              value={activityConstants.MEMBERS_IMPORT}
              disabled={this.props.filtersInProgress}
              checked={!!(this.props.conditionType && this.props.conditionType === activityConstants.MEMBERS_IMPORT)}
              onChange={(event, elem) => this.props.updateGlobalConditionsType(elem.value)}
              data-automation-id="globalConditions.members.radioBtn.importMembers"
            />
          </div>
        }
        {this.props.conditionType === activityConstants.MEMBERS_IMPORT &&
          <MultiCardsWrapper
            automationIdPrefix={`filters.${membershipName}`}
            cards={this.getImportMembersCards(this.props.conditionType, memberShipTitle, membershipName, membershipFileName)}
            selectCard={this.selectImportCard}
            selectedCardId={this.selectedImportMembersMode}
          >
          </MultiCardsWrapper>
        }
        {this.props.isMembersFilter &&
          <div>
            <Radio
              label={formatMessage({ id: 'activity.globalConditions.members.radioBtn.import.phoneno.members' })}
              name="membersGroup"
              value={activityConstants.MEMBERS_PHONENUMBER_IMPORT}
              disabled={this.props.filtersInProgress}
              checked={!!(this.props.conditionType && this.props.conditionType === activityConstants.MEMBERS_PHONENUMBER_IMPORT)}
              onChange={(event, elem) => this.props.updateGlobalConditionsType(elem.value)}
              data-automation-id="globalConditions.members.radioBtn.importMembers"
            />
          </div>
        }
        {this.props.conditionType === activityConstants.MEMBERS_PHONENUMBER_IMPORT &&
          <MultiCardsWrapper
            automationIdPrefix={`filters.${importPhoneNumberName}`}
            cards={this.getImportMembersCards(this.props.conditionType, phoneNoDisplayTitle, importPhoneNumberName, importPhoneNumberFileName)}
            selectCard={this.selectImportCard}
            selectedCardId={this.selectedImportMembersMode}
          >
          </MultiCardsWrapper>
        }

      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(globalConditionsActions, dispatch)
  };
}

const mapStateToProps = (state) => ({
  conditionsModel: activitySelectors.getMembershipGlobalConditionTempModel(state),
  referralTempCondition: activitySelectors.getReferralCodeTempModelCondition(state),
  referralTempError: activitySelectors.getReferralCodeTempError(state),
  tempValidationErrors: activitySelectors.getUserActionsFilterErrors(state),
  filtersInProgress: activitySelectors.getFiltersInProgressFlag(state),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, injectIntl)(MembershipGlobalConditions);
