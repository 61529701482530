import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Icon, Loader, Menu } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import AutosizeInput from 'react-input-autosize';
import classNames from 'classnames';
import * as constants from '../common.components.constants';
import ValidationErrorMessage from '../ValidationErrorMessage/ValidationErrorMessage';
import Confirmation from '../CustomModal/ConfirmationModal/ConfirmationModal';
import Tabs from '../../../features/Activity/views/tabbedContent/Tabs';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import tracker from '../../utils/tracking/tracker';
import * as trackerConstants from '../../utils/tracking/tracking.consts';
import './entityHeader.styles.less';
import DisabledAddOn from '../DisabledItem/DisabledAddOn';

class EntityHeader extends React.PureComponent {

  static propTypes = {
    headerClassName: PropTypes.string,
    nameClassName: PropTypes.string,
    descriptionClassName: PropTypes.string,
    namePlaceholder: PropTypes.string,
    descriptionPlaceholder: PropTypes.string,
    isLoading: PropTypes.bool,
    name: PropTypes.string,
    description: PropTypes.string,
    refocusOnName: PropTypes.bool,
    nameValidationError: PropTypes.string,
    promptBeforeLeave: PropTypes.bool,
    onCancelOrBackClick: PropTypes.func,
    handleNameChange: PropTypes.func,
    handleDescriptionChange: PropTypes.func,
    prefix: PropTypes.string,
    statusMessage: PropTypes.string,
    statusClassName: PropTypes.string,
    publishOrSaveInProgress: PropTypes.bool,
    saveAsDraftInProgress: PropTypes.bool,
    canSaveAsDraft: PropTypes.bool,
    onSaveAsDraftClick: PropTypes.func,
    canSave: PropTypes.bool,
    publishButtonDisabled: PropTypes.bool,
    publishButtonText: PropTypes.string,
    onSaveEntityClick: PropTypes.func,
    headerNoteMessage: PropTypes.string,
    showHeaderNote: PropTypes.bool,
    hasTabs: PropTypes.bool,
    tabs: PropTypes.array,
    tabsErrors: PropTypes.object,
    onShowPreviewClick: PropTypes.func,
    showPreview: PropTypes.bool,
    isActivateAddon: PropTypes.bool.isRequired,
    businessBundlePlan: PropTypes.string.isRequired,
  };

  static defaultProps = {
    refocusOnName: false,
    canSave: true
  };

  constructor(props) {
    super(props);
    this.state = {
      name: props.name || '',
      description: props.description || ''
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.name !== nextProps.name || this.props.description !== nextProps.description) {
      this.setState({
        name: nextProps.name || '',
        description: nextProps.description || ''
      });
    }
  }

  componentDidMount() {
    if (!this.props.name) {
      this.entityNameInput.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.refocusOnName && this.props.refocusOnName !== prevProps.refocusOnName) {
      this.entityNameInput.focus();
    }
  }

  handleEntityNameInputRef = (element) => {
    this.entityNameInput = element;
    EntityHeader.DisableAutosizeInputAutoComplete(element);
  };

  static DisableAutosizeInputAutoComplete(element) {
    if (element && element.input) {
      element.input.autocomplete = 'off';
    }
  }

  getBackOrCancelButton = (isBackButton) => {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const buttonName = isBackButton ? constants.BACK_BUTTON_NAME : constants.CANCEL_BUTTON_NAME;
    const trackingName = isBackButton ? trackerConstants.BUTTON_TYPE_BACK : trackerConstants.BUTTON_TYPE_CANCEL;
    if (this.props.promptBeforeLeave) {
      return (
        <ConfirmationDialog
          automationIdPrefix={`${this.props.prefix}.${buttonName}`}
          triggerLabelKey={`${this.props.prefix}.header.${buttonName}`}
          contentMessageKey={`${this.props.prefix}.header.back.confirmationMessage`}
          confirmAction={tracker.wrapButtonAction(this.props.onCancelOrBackClick, trackingName)}
          titleKey={`${this.props.prefix}.header.back.confirmationHeader`}
          icon={isBackButton ? 'como-ic-angle-left' : null}
        />
      );
    }
    return (
      <a
        tabIndex={-1}
        role="button"
        data-automation-id={`${this.props.prefix}.${buttonName}.trigger`}
        className={this.props.publishOrSaveInProgress || this.props.saveAsDraftInProgress ? 'disabled' : ''}
        onClick={this.props.publishOrSaveInProgress ? null : tracker.wrapButtonAction(this.props.onCancelOrBackClick, trackingName)}
      >
        {isBackButton && <Icon className="como-ic-angle-left" />}
        {formatMessage({ id: `activity.header.${buttonName}` })}
      </a>
    );
  };

  getPublishButton(clickHandler = null) {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    if (this.props.isActivateAddon){
      return (
        <DisabledAddOn
          bundlePlan={this.props.businessBundlePlan}
          withUnAvailableIcon
        >
          <Button
            secondary
            className="uppercase smaller"
            data-automation-id={`${this.props.prefix}.save`}
            onClick={tracker.wrapButtonAction(clickHandler, trackerConstants.BUTTON_TYPE_SAVE)}
            loading={this.props.publishOrSaveInProgress}
            disabled
          >
            {this.props.publishButtonText}
          </Button>
        </DisabledAddOn>
      );
    }
    return (
      <Button
        secondary
        className="uppercase smaller"
        data-automation-id={`${this.props.prefix}.save`}
        onClick={tracker.wrapButtonAction(clickHandler, trackerConstants.BUTTON_TYPE_SAVE)}
        loading={this.props.publishOrSaveInProgress}
        disabled={this.props.publishButtonDisabled}
      >
        {this.props.publishButtonText}
      </Button>
    );
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.target.blur();
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {

    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const headerClassName = classNames('entity-header', this.props.headerClassName);
    const nameClassName = classNames('entity-name', this.props.nameClassName);
    const descriptionClassName = classNames('entity-description', this.props.descriptionClassName);
    const backButton = this.getBackOrCancelButton(true);
    const cancelButton = this.getBackOrCancelButton(false);
    return (
      <div className={headerClassName}>
        <Form className="overBg">
          <Menu secondary>
            <Menu.Item>
              {backButton}
            </Menu.Item>
            <Menu.Item className="header-item-limited-width">
              <div className="name-and-description">
                <AutosizeInput
                  type="text"
                  name={constants.ENTITY_NAME}
                  value={this.state.name}
                  title={this.state.name}
                  onBlur={this.props.handleNameChange}
                  onKeyPress={this.handleKeyPress}
                  onChange={this.handleChange}
                  ref={this.handleEntityNameInputRef}
                  className={nameClassName}
                  placeholder={this.props.namePlaceholder}
                  data-automation-id={`${this.props.prefix}.name`}
                />
                <Loader active={this.props.isLoading} size="mini" />
                {typeof this.props.description !== 'undefined' && <AutosizeInput
                  ref={EntityHeader.DisableAutosizeInputAutoComplete}
                  name={constants.ENTITY_DESCRIPTION}
                  type="text"
                  value={this.state.description}
                  title={this.state.description}
                  onBlur={this.props.handleDescriptionChange}
                  onKeyPress={this.handleKeyPress}
                  onChange={this.handleChange}
                  className={descriptionClassName}
                  placeholder={`+ ${this.props.descriptionPlaceholder}`}
                  data-automation-id={`${this.props.prefix}.description`}
                />
                }
              </div>
              {
                this.props.nameValidationError &&
                <ValidationErrorMessage
                  errorLayoutType={constants.COMPONENT_TYPE_TEXT}
                  dataAutomationId={`${this.props.prefix}.name.error.message`}
                  errorMessage={formatMessage({ id: this.props.nameValidationError })}
                />
              }
            </Menu.Item>
            {
              this.props.statusMessage &&
              <Menu.Item>
                <span className={this.props.statusClassName}>
                  {this.props.statusMessage}
                </span>
              </Menu.Item>
            }
            <Menu.Menu position="right">
              <Menu.Item>
                {cancelButton}
              </Menu.Item>
              {
                this.props.canSaveAsDraft &&
                <Menu.Item>
                  <a
                    tabIndex={-2}
                    role="button"
                    data-automation-id={`${this.props.prefix}.save-as-draft`}
                    className={this.props.publishOrSaveInProgress || this.props.saveAsDraftInProgress ? 'disabled' : ''}
                    onClick={this.props.publishOrSaveInProgress ? null : this.props.onSaveAsDraftClick}
                  >
                    {formatMessage({ id: 'activity.header.save-as-draft' })}
                  </a>
                </Menu.Item>
              }
              {
                this.props.showPreview &&
                  <Menu.Item>
                    <a
                      tabIndex={-2}
                      role="button"
                      data-automation-id={`${this.props.prefix}.show-preview`}
                      className={this.props.publishOrSaveInProgress || this.props.saveAsDraftInProgress ? 'disabled' : ''}
                      onClick={this.props.publishOrSaveInProgress ? null : this.props.onShowPreviewClick}
                    >
                      {formatMessage({ id: 'activity.header.show-preview' })}
                    </a>
                  </Menu.Item>
              }
              <Menu.Item>
                {
                  this.props.canSave
                    ? (this.getPublishButton(this.props.onSaveEntityClick))
                    : (
                      <Confirmation
                        onConfirm={this.props.onSaveEntityClick}
                        trigger={this.getPublishButton()}
                        className="como-dialog"
                      >
                        {formatMessage({ id: 'activity.header.publish.confirmation.content' })}
                      </Confirmation>

                    )
                }
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </Form>
        {
          this.props.showHeaderNote &&
          <div className="toolbar-info-panel">
            <span className="toolbar-info-text">{this.props.headerNoteMessage}</span>
          </div>
        }
        {
          this.props.hasTabs &&
          <Tabs
            tabs={this.props.tabs}
            tabsErrors={this.props.tabsErrors}
          />
        }
      </div>
    );
  }
}

export default injectIntl(EntityHeader);

