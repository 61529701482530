import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Accordion, Dimmer, Grid, Loader, Segment } from 'semantic-ui-react';
import classNames from 'classnames';
import { fromJS } from 'immutable';

import '../microCampaigns.styles.less';
import '../../Activity/views/cases/cases.styles.less';
import * as activityConstants from '../../Activity/activity.constants';
import * as microCampaignConstants from '../../MicroCampaign/microCampaign.constants';
import SingleMicroCampaignHeader from './SingleMicroCampaignHeader';
import SingleMicroCampaignStatistics from './SingleMicroCampaignStatistics';

export class SingleMicroCampaign extends React.PureComponent {

  static propTypes = {
    intl: PropTypes.object,
    singleMicroCampaign: PropTypes.object,
    automationIdPrefix: PropTypes.string,
    index: PropTypes.number,
    microCampaignStats: PropTypes.object,
    benefit: PropTypes.object,
    onStatusChange: PropTypes.func,
    goToMicroCampaign: PropTypes.func,
    getMicroCampaignStats: PropTypes.func,
    microCampaignStatsFetched: PropTypes.bool,
    currencySymbol: PropTypes.string,
    dateFormat: PropTypes.string,
    businessTimeZone: PropTypes.string,
    isOpen: PropTypes.bool,
    toggleCollapsed: PropTypes.func
  };

  componentWillMount() {
    if (this.props.isOpen && !this.props.microCampaignStatsFetched) {
      this.props.getMicroCampaignStats(this.props.singleMicroCampaign.get(activityConstants.HUB_ID));
    }
  }

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types

    const automationIdPrefix = `${this.props.automationIdPrefix}.${this.props.index}`;
    const singleMicroCampaignClassName = classNames('single-case', { 'collapsed': !this.props.isOpen });
    const historyLog = fromJS({
      created: this.props.singleMicroCampaign.get('created'),
      updated: this.props.singleMicroCampaign.get('updated')
    });

    if (!this.props.singleMicroCampaign) {
      return null;
    }
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Segment className={singleMicroCampaignClassName}>
              <Accordion>
                <Accordion.Title active={this.props.isOpen}>
                  <SingleMicroCampaignHeader
                    name={this.props.singleMicroCampaign.get(microCampaignConstants.MICRO_CAMPAIGN_NAME)}
                    benefitTitle={this.props.benefit ? this.props.benefit.get(activityConstants.TITLE) : ''}
                    maxPopulation={this.props.singleMicroCampaign.get(microCampaignConstants.MICRO_CAMPAIGN_POPULATION_SIZE)}
                    status={this.props.singleMicroCampaign.get(microCampaignConstants.MICRO_CAMPAIGN_STATUS)}
                    automationIdPrefix={automationIdPrefix}
                    onStatusChange={this.props.onStatusChange}
                    index={this.props.index}
                    goToMicroCampaign={() => this.props.goToMicroCampaign(this.props.singleMicroCampaign.get(activityConstants.HUB_ID))}
                    singleMicroCampaign={this.props.singleMicroCampaign}
                    historyLog={historyLog}
                    isOpen={this.props.isOpen}
                    toggleCollapsed={this.props.toggleCollapsed}
                  />
                </Accordion.Title>
                <Accordion.Content active={this.props.isOpen}>
                  {
                    this.props.microCampaignStats && this.props.microCampaignStats.size > 0 &&
                    this.props.singleMicroCampaign.get(microCampaignConstants.MICRO_CAMPAIGN_STATUS) !== activityConstants.ACTIVITY_STATUS_DRAFT &&
                    this.props.isOpen &&
                    this.props.microCampaignStats.get('batches').size > 0
                    &&
                    <div>
                      <Dimmer active={!this.props.microCampaignStatsFetched} inverted>
                        <Loader />
                      </Dimmer>
                      <SingleMicroCampaignStatistics
                        weeklyStats={this.props.microCampaignStats.get('batches')}
                        totalStats={this.props.microCampaignStats.get('total')}
                        automationIdPrefix={automationIdPrefix}
                        microCampaignStatsFetched={this.props.microCampaignStatsFetched}
                        currencySymbol={this.props.currencySymbol}
                        dateFormat={this.props.dateFormat}
                        businessTimeZone={this.props.businessTimeZone}
                      /></div> }
                  { this.props.microCampaignStatsFetched && (!this.props.microCampaignStats || this.props.microCampaignStats.size === 0 || this.props.singleMicroCampaign.get(microCampaignConstants.MICRO_CAMPAIGN_STATUS) === activityConstants.ACTIVITY_STATUS_DRAFT || this.props.microCampaignStats.get('batches').size === 0) &&
                    <div className="empty-statistics">
                      <div>
                        {this.props.singleMicroCampaign.get(microCampaignConstants.MICRO_CAMPAIGN_STATUS) === activityConstants.ACTIVITY_STATUS_DRAFT ? formatMessage({ id: 'microCampaigns.no.statistics.draft' }) : formatMessage({ id: 'microCampaigns.no.statistics.yet' })}
                      </div>
                    </div>
                  }
                </Accordion.Content>
              </Accordion>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default injectIntl(SingleMicroCampaign);

