import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import injectSaga from '../../../../utils/injectSaga';
import imagesSaga from '../../../Images/images.saga';
import * as reducerConstants from '../../../../constants/reducer.constants';
import './punchCardPreview.styles.less';
import * as imagesActions from '../../../Images/images.actions';
import * as selectors from '../../activity.selectors';
import * as imageSelectors from '../../../Images/images.selectors';
import * as imagesConstants from '../../../Images/images.constants';

class PunchCardPreview extends React.PureComponent {

  static propTypes = {
    actions: PropTypes.object.isRequired,
    defaultPreviewImage: PropTypes.object,
    defaultStampImage: PropTypes.object,
    images: PropTypes.object,
    requiredPunches: PropTypes.number,
    selectedBackgroundImage: PropTypes.object,
    selectedStampImage: PropTypes.object,
  };

  componentWillMount() {
    if (!this.props.images) {
      this.props.actions.getImagesBySize(imagesConstants.IMAGE_SIZE_THUMBNAIL);
    }
  }

  getStampStyle = (i) => {
    if (i <= Math.ceil(this.props.requiredPunches / 2) - 1) {
      return {
        backgroundImage: `url(${this.livePreviewStampUrl})`,
        border: 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        boxShadow: '0px 0 3px 0px grey'
      };
    }
    return null;
  };

  get livePreviewBgUrl() {
    if (this.props.selectedBackgroundImage) {
      return this.props.selectedBackgroundImage.get(imagesConstants.URL);
    }
    if (this.props.defaultPreviewImage) {
      return this.props.defaultPreviewImage.get(imagesConstants.URL);
    }
    return null;
  }

  get livePreviewStampUrl() {
    if (this.props.selectedStampImage) {
      return this.props.selectedStampImage.get(imagesConstants.URL);
    }
    if (this.props.defaultStampImage) {
      return this.props.defaultStampImage.get(imagesConstants.URL);
    }
    return null;
  }

  render() {

    const { requiredPunches } = this.props;
    const bgStyle = { backgroundImage: `url(${this.livePreviewBgUrl})` };

    return (
      <div>
        {/* <div className="punch-preview-ghost" style={bgStyle} />*/}
        {/* <div className="punch-preview-ghost" style={bgStyle} />*/}
        {/* <div className="punch-preview-ghost" style={bgStyle} />*/}
        <div className="punch-preview" style={bgStyle}>
          <div className={`punch-item-wrap width-${requiredPunches}`}>
            {_.times(requiredPunches, (i) => (
              <div className="punch-item" key={`punch_${i}`} style={this.getStampStyle(i)} />
            ))
            }
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(imagesActions, dispatch) };
}

const mapStateToProps = (state) => ({
  defaultPreviewImage: imageSelectors.getDefaultImageThumbnail(state),
  defaultStampImage: imageSelectors.getDefaultStampImageThumbnail(state),
  images: imageSelectors.getAllActiveImageThumbnails(state),
  requiredPunches: selectors.getRequiredPunches(state),
  selectedBackgroundImage: selectors.getSelectedBackgroundImage(state),
  selectedStampImage: selectors.getSelectedStampImage(state),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.IMAGES_BRANCH, saga: imagesSaga });

export default compose(
  withConnect,
  withSaga
)(PunchCardPreview);
