/**
 *
 * TopUpPoints
 * Simple add points action
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import * as activityConstants from '../../../../activity.constants';
import './addPoints.styles.less';
import PointsInputField from '../../../../../../common/components/FormInputFields/PointsInputField/PointsInputField';
import BusinessWallets from './businessWallets/businessWallets';
import DropdownWrapper from '../../../../../../common/components/DropdownWrapper/DropdownWrapper';
import PercentageIntegerInputField
  from '../../../../../../common/components/FormInputFields/PercentageIntegerInputField/PercentageIntegerInputField';

class TopUpPoints extends React.PureComponent {

  static propTypes = {
    automationIdPrefix: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
    onActionFieldUpdate: PropTypes.func.isRequired,
    action: PropTypes.object,
    index: PropTypes.number,
    validationErrors: PropTypes.object,
  };

  static defaultProps = {
    limit: false,
    action: {
      [activityConstants.TYPE]: activityConstants.ACCUMULATE_POINTS_TYPE_FIXED
    }
  };

  constructor(props) {
    super(props);
    const { intl } = props;

    this.availableWallets = [
      {
        value: activityConstants.ADD_POINTS_WALLET_POINTS,
        text: intl.formatMessage({ id: 'activity.action.addPoints.budgetType.points' })
      },
      {
        value: activityConstants.ADD_POINTS_WALLET_BUDGET,
        text: intl.formatMessage({ id: 'activity.action.addPoints.budgetType.budget' })
      }];

    this.addPointsTypes = [{
      value: activityConstants.ACCUMULATE_POINTS_TYPE_FIXED,
      text: intl.formatMessage({ id: 'activity.action.accumulatePoints.type.fixed' })
    },
    {
      value: activityConstants.ACCUMULATE_POINTS_TYPE_TOP_UP,
      text: intl.formatMessage({ id: 'activity.action.accumulatePoints.type.topUp' })
    }];
  }

  componentWillMount() {
    if (!this.props.action.get(activityConstants.POINTS_TYPE)) {
      this.props.onActionFieldUpdate(activityConstants.POINTS_TYPE, activityConstants.ACCUMULATE_POINTS_TYPE_FIXED, this.props.index);
    }
  }


  render() {
    const { intl, index, action, onActionFieldUpdate, validationErrors, automationIdPrefix } = this.props;
    const { formatMessage } = intl;
    const isTopupPointsType = this.props.action.get(activityConstants.POINTS_TYPE) === activityConstants.ACCUMULATE_POINTS_TYPE_TOP_UP;
    const pointsTypeValue = this.props.action.get(activityConstants.POINTS_TYPE) || activityConstants.ACCUMULATE_POINTS_TYPE_FIXED;

    return (
      <Grid className="action add-points-action">
        <Grid.Row>
          <Grid.Column width={12}>
            <DropdownWrapper
              options={this.addPointsTypes}
              name={activityConstants.POINTS_TYPE}
              value={pointsTypeValue}
              prefix={`${automationIdPrefix}.type`}
              className="add-points-type"
              error={validationErrors ? validationErrors.get(activityConstants.TYPE) : null}
              displayDropDownWithSingleOption={false}
              onSelectOption={(e, data) => onActionFieldUpdate(data.name, data.value, index)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <div>
              { isTopupPointsType
                ? <span>
                  <span className="inner-label">{formatMessage({ id: 'activity.action.addPoints.topUp' })}</span>
                  <PercentageIntegerInputField
                    error={validationErrors ? validationErrors.get(activityConstants.POINTS_TOP_UP_PERCENTS) : null}
                    name={activityConstants.POINTS_TOP_UP_PERCENTS}
                    placeholder=""
                    prefix={this.props.automationIdPrefix}
                    value={action.get(activityConstants.POINTS_TOP_UP_PERCENTS)}
                    onChange={(e, data) => onActionFieldUpdate(data.name, data.value, index)}
                  />
                  <span className="inner-label">{formatMessage({ id: 'activity.action.addPoints.budgetType.into' })}</span>
                </span>
                : <span>
                  <span className="inner-label">{formatMessage({ id: 'activity.action.addPoints.points' })}</span>
                  <PointsInputField
                    name={activityConstants.POINTS}
                    onChange={(e, data) => onActionFieldUpdate(data.name, data.value, index)}
                    value={action.get(activityConstants.POINTS)}
                    prefix={`${automationIdPrefix}.points`}
                    error={validationErrors ? validationErrors.get(activityConstants.POINTS) : null}
                    min={0}
                  />
                  <span className="inner-label">{formatMessage({ id: 'activity.action.addPoints.budgetType' })}</span>
                </span>
              }
              <BusinessWallets
                onActionFieldUpdate={(e, data) => onActionFieldUpdate(data.name, data.value, index)}
                name={activityConstants.BUDGET_TYPE}
                index={index}
                value={action.get(activityConstants.BUDGET_TYPE)}
                automationIdPrefix={`${automationIdPrefix}.budgetType`}
                error={validationErrors ? validationErrors.get(activityConstants.BUDGET_TYPE) : null}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default injectIntl(TopUpPoints);
