import { fromJS } from 'immutable';
import * as constants from '../activity.constants';
import * as schemaConstants from '../activitySchema/activitySchema.constants';
import * as dateConstants from '../../../common/utils/dateTime.constants';

export const emptyAction = {
  [constants.ACTION_TYPE]: ''
};

export const punchAction = fromJS(emptyAction)
  .set(constants.ACTION_TYPE, schemaConstants.AUTO_PUNCH_ACTION)
  .set(constants.PUNCH_CARD_KEY, constants.PUNCH_CARD_ID_PLACEHOLDER)
  .set(schemaConstants.AUTO_PUNCH_NUM_OF_PUNCHES, 1)
  .set(constants.LIMIT, fromJS({
    [constants.LIMIT_VALUE]: 1,
    [constants.LIMIT_ACTIVE]: false
  }));

export const assignAssetAction = fromJS(emptyAction)
  .set(constants.ACTION_TYPE, schemaConstants.ASSIGN_ASSET_ACTION)
  .set(constants.PUNCH_CARD_KEY, constants.PUNCH_CARD_ID_PLACEHOLDER)
  .set(schemaConstants.ASSIGN_ASSET_QUANTITY_FIELD, 1);

export const discountFreeItemAction = fromJS(emptyAction)
  .set(constants.ACTION_TYPE, schemaConstants.DISCOUNT_FREE_ITEMS_ACTION)
  .set(constants.DISCOUNT_VALUE, null)
  .set(constants.DISCOUNT_ON, fromJS({
    [constants.TYPE]: null,
    [constants.CONDITIONS]: {
      [constants.CONDITION_LIST_OPERATOR]: constants.CONDITION_OR,
      [constants.CONDITIONS_LIST]: []
    }
  }))
  .set(constants.LIMIT, fromJS({
    [constants.LIMIT_VALUE]: 1,
    [constants.LIMIT_ACTIVE]: true
  }));

export const punchExceededAction = fromJS(emptyAction)
  .set(constants.ACTION_TYPE, schemaConstants.PUNCH_EXCEEDED_ACTION)
  .set(constants.PUNCH_CARD_KEY, constants.PUNCH_CARD_ID_PLACEHOLDER)
  .set(schemaConstants.PUNCH_EXCEEDED_NUMBER_OF_PUNCHES_FIELD, schemaConstants.CONTEXT_NUM_OF_PUNCHES)
  .set(constants.DELAY, fromJS({
    [constants.DELAY_NUMBER]: 1,
    [constants.DELAY_UNITS]: dateConstants.MINUTE
  }));

export const defaultUpdateMembershipFieldOptions = [
  {
    value: schemaConstants.GENERIC_CHECKBOX_1,
    text: schemaConstants.GENERIC_CHECKBOX_1
  },
  {
    value: schemaConstants.GENERIC_CHECKBOX_2,
    text: schemaConstants.GENERIC_CHECKBOX_2
  },
  {
    value: schemaConstants.GENERIC_CHECKBOX_3,
    text: schemaConstants.GENERIC_CHECKBOX_3
  },
  {
    value: schemaConstants.GENERIC_CHECKBOX_4,
    text: schemaConstants.GENERIC_CHECKBOX_4
  },
  {
    value: schemaConstants.GENERIC_CHECKBOX_5,
    text: schemaConstants.GENERIC_CHECKBOX_5
  },
  {
    value: schemaConstants.GENERIC_CHECKBOX_6,
    text: schemaConstants.GENERIC_CHECKBOX_6
  },
  {
    value: schemaConstants.GENERIC_CHECKBOX_7,
    text: schemaConstants.GENERIC_CHECKBOX_7
  },
  {
    value: schemaConstants.GENERIC_CHECKBOX_8,
    text: schemaConstants.GENERIC_CHECKBOX_8
  },
  {
    value: schemaConstants.GENERIC_CHECKBOX_9,
    text: schemaConstants.GENERIC_CHECKBOX_9
  },
  {
    value: schemaConstants.GENERIC_CHECKBOX_10,
    text: schemaConstants.GENERIC_CHECKBOX_10
  },
  {
    value: schemaConstants.GENERIC_STRING_1,
    text: schemaConstants.GENERIC_STRING_1
  },
  {
    value: schemaConstants.GENERIC_STRING_2,
    text: schemaConstants.GENERIC_STRING_2
  },
  {
    value: schemaConstants.GENERIC_STRING_3,
    text: schemaConstants.GENERIC_STRING_3
  },
  {
    value: schemaConstants.GENERIC_STRING_4,
    text: schemaConstants.GENERIC_STRING_4
  },
  {
    value: schemaConstants.GENERIC_STRING_5,
    text: schemaConstants.GENERIC_STRING_5
  },
  {
    value: schemaConstants.GENERIC_STRING_6,
    text: schemaConstants.GENERIC_STRING_6
  },
  {
    value: schemaConstants.GENERIC_STRING_7,
    text: schemaConstants.GENERIC_STRING_7
  },
  {
    value: schemaConstants.GENERIC_STRING_8,
    text: schemaConstants.GENERIC_STRING_8
  },
  {
    value: schemaConstants.GENERIC_STRING_9,
    text: schemaConstants.GENERIC_STRING_9
  },
  {
    value: schemaConstants.GENERIC_STRING_10,
    text: schemaConstants.GENERIC_STRING_10
  },
  {
    value: schemaConstants.GENERIC_INTEGER_1,
    text: schemaConstants.GENERIC_INTEGER_1
  },
  {
    value: schemaConstants.GENERIC_INTEGER_2,
    text: schemaConstants.GENERIC_INTEGER_2
  },
  {
    value: schemaConstants.GENERIC_INTEGER_3,
    text: schemaConstants.GENERIC_INTEGER_3,
  },
  {
    value: schemaConstants.GENERIC_INTEGER_4,
    text: schemaConstants.GENERIC_INTEGER_4
  },
  {
    value: schemaConstants.GENERIC_INTEGER_5,
    text: schemaConstants.GENERIC_INTEGER_5
  },
  {
    value: schemaConstants.GENERIC_INTEGER_6,
    text: schemaConstants.GENERIC_INTEGER_6,
  },
  {
    value: schemaConstants.GENERIC_INTEGER_7,
    text: schemaConstants.GENERIC_INTEGER_7
  },
  {
    value: schemaConstants.GENERIC_INTEGER_8,
    text: schemaConstants.GENERIC_INTEGER_8
  },
  {
    value: schemaConstants.GENERIC_INTEGER_9,
    text: schemaConstants.GENERIC_INTEGER_9,
  },
  {
    value: schemaConstants.GENERIC_INTEGER_10,
    text: schemaConstants.GENERIC_INTEGER_10,
  },
  {
    value: schemaConstants.GENERIC_DATE_1,
    text: schemaConstants.GENERIC_DATE_1
  },
  {
    value: schemaConstants.GENERIC_DATE_2,
    text: schemaConstants.GENERIC_DATE_2
  },
  {
    value: schemaConstants.GENERIC_DATE_3,
    text: schemaConstants.GENERIC_DATE_3
  },
  {
    value: schemaConstants.GENERIC_DATE_4,
    text: schemaConstants.GENERIC_DATE_4
  },
  {
    value: schemaConstants.GENERIC_DATE_5,
    text: schemaConstants.GENERIC_DATE_5
  },
  {
    value: schemaConstants.GENERIC_DATE_6,
    text: schemaConstants.GENERIC_DATE_6
  },
  {
    value: schemaConstants.GENERIC_DATE_7,
    text: schemaConstants.GENERIC_DATE_7
  },
  {
    value: schemaConstants.GENERIC_DATE_8,
    text: schemaConstants.GENERIC_DATE_8
  },
  {
    value: schemaConstants.GENERIC_DATE_9,
    text: schemaConstants.GENERIC_DATE_9
  },
  {
    value: schemaConstants.GENERIC_DATE_10,
    text: schemaConstants.GENERIC_DATE_10
  }
];

export const defaultUpdateMembershipFieldValueOptions = {
  booleanValues: [
    {
      value: schemaConstants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_1,
      text: schemaConstants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_1
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_2,
      text: schemaConstants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_2
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_3,
      text: schemaConstants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_3
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_4,
      text: schemaConstants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_4
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_5,
      text: schemaConstants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_5
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_6,
      text: schemaConstants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_6
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_7,
      text: schemaConstants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_7
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_8,
      text: schemaConstants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_8
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_9,
      text: schemaConstants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_9
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_10,
      text: schemaConstants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_10
    }
  ],
  stringValues: [
    {
      value: schemaConstants.CONTEXT_DATA_DATA_STRING_VALUE_1,
      text: schemaConstants.CONTEXT_DATA_DATA_STRING_VALUE_1
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_STRING_VALUE_2,
      text: schemaConstants.CONTEXT_DATA_DATA_STRING_VALUE_2
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_STRING_VALUE_3,
      text: schemaConstants.CONTEXT_DATA_DATA_STRING_VALUE_3
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_STRING_VALUE_4,
      text: schemaConstants.CONTEXT_DATA_DATA_STRING_VALUE_4
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_STRING_VALUE_5,
      text: schemaConstants.CONTEXT_DATA_DATA_STRING_VALUE_5
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_STRING_VALUE_6,
      text: schemaConstants.CONTEXT_DATA_DATA_STRING_VALUE_6
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_STRING_VALUE_7,
      text: schemaConstants.CONTEXT_DATA_DATA_STRING_VALUE_7
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_STRING_VALUE_8,
      text: schemaConstants.CONTEXT_DATA_DATA_STRING_VALUE_8
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_STRING_VALUE_9,
      text: schemaConstants.CONTEXT_DATA_DATA_STRING_VALUE_9
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_STRING_VALUE_10,
      text: schemaConstants.CONTEXT_DATA_DATA_STRING_VALUE_10
    }
  ],
  numericValues: [
    {
      value: schemaConstants.CONTEXT_DATA_DATA_NUMERIC_VALUE_1,
      text: schemaConstants.CONTEXT_DATA_DATA_NUMERIC_VALUE_1
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_NUMERIC_VALUE_2,
      text: schemaConstants.CONTEXT_DATA_DATA_NUMERIC_VALUE_2
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_NUMERIC_VALUE_3,
      text: schemaConstants.CONTEXT_DATA_DATA_NUMERIC_VALUE_3
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_NUMERIC_VALUE_4,
      text: schemaConstants.CONTEXT_DATA_DATA_NUMERIC_VALUE_4
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_NUMERIC_VALUE_5,
      text: schemaConstants.CONTEXT_DATA_DATA_NUMERIC_VALUE_5
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_NUMERIC_VALUE_6,
      text: schemaConstants.CONTEXT_DATA_DATA_NUMERIC_VALUE_6
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_NUMERIC_VALUE_7,
      text: schemaConstants.CONTEXT_DATA_DATA_NUMERIC_VALUE_7
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_NUMERIC_VALUE_8,
      text: schemaConstants.CONTEXT_DATA_DATA_NUMERIC_VALUE_8
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_NUMERIC_VALUE_9,
      text: schemaConstants.CONTEXT_DATA_DATA_NUMERIC_VALUE_9
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_NUMERIC_VALUE_10,
      text: schemaConstants.CONTEXT_DATA_DATA_NUMERIC_VALUE_10
    }
  ],
  dataValues: [
    {
      value: schemaConstants.CONTEXT_DATA_DATA_DATE_VALUE_1,
      text: schemaConstants.CONTEXT_DATA_DATA_DATE_VALUE_1
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_DATE_VALUE_2,
      text: schemaConstants.CONTEXT_DATA_DATA_DATE_VALUE_2
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_DATE_VALUE_3,
      text: schemaConstants.CONTEXT_DATA_DATA_DATE_VALUE_3
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_DATE_VALUE_4,
      text: schemaConstants.CONTEXT_DATA_DATA_DATE_VALUE_4
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_DATE_VALUE_5,
      text: schemaConstants.CONTEXT_DATA_DATA_DATE_VALUE_5
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_DATE_VALUE_6,
      text: schemaConstants.CONTEXT_DATA_DATA_DATE_VALUE_6
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_DATE_VALUE_7,
      text: schemaConstants.CONTEXT_DATA_DATA_DATE_VALUE_7
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_DATE_VALUE_8,
      text: schemaConstants.CONTEXT_DATA_DATA_DATE_VALUE_8
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_DATE_VALUE_9,
      text: schemaConstants.CONTEXT_DATA_DATA_DATE_VALUE_9
    },
    {
      value: schemaConstants.CONTEXT_DATA_DATA_DATE_VALUE_10,
      text: schemaConstants.CONTEXT_DATA_DATA_DATE_VALUE_10
    }
  ]
};
