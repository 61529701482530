import React from 'react';
import { injectIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Modal from '../../../../common/components/CustomModal/CustomModal';
import SaveTemplate from '../SaveTemplate/SaveTemplate';
import * as constants from '../../templates.constants';

const AUTOMATION_PREFIX_NAME = 'replace-or-new-template';
const REPLACE = 'replace';
const SAVE_AS_NEW = 'saveAsNew';

class ReplaceOrNewTemplate extends React.PureComponent {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    trigger: PropTypes.node.isRequired,
    saveTemplate: PropTypes.func.isRequired,
    editTemplate: PropTypes.func.isRequired,
    entity: PropTypes.object.isRequired,
    entityType: PropTypes.string.isRequired,
    template: PropTypes.object.isRequired,
    header: PropTypes.string,
    prefix: PropTypes.string,
    templateType: PropTypes.string.isRequired,
    categories: PropTypes.object,
  };

  static defaultProps = {
    header: 'activity.replace-or-new-template.header'
  };

  state = {
    isModalOpen: false
  };


  handlerModalOpen = () => {
    this.setState({
      isModalOpen: true
    });
  };

  handlerReplace = () => {
    this.setState({ mode: REPLACE });
    this.handlerModalClose();
  };

  handlerSaveAsNew = () => {
    this.setState({ mode: SAVE_AS_NEW });
    this.handlerModalClose();
  };

  handlerModalClose = () => {
    this.setState({
      isModalOpen: false
    });
  };

  modalContentHtml() {
    const { intl, template } = this.props;

    return (
      <p>{intl.formatMessage({ id: 'activity.replace-or-new-template.description' }, { value: template.get(constants.TEMPLATE_NAME) })}</p>
    );
  }

  modalActionsHtml() {
    const { prefix, intl } = this.props;
    return [
      <Button
        key="cancel"
        basic
        data-automation-id={`${prefix}.${AUTOMATION_PREFIX_NAME}.btn.cancel`}
        onClick={this.handlerModalClose}
        className="link"
      >
        {intl.formatMessage({ id: 'activity.save-as-template.cancel' })}
      </Button>,
      <Button
        key="replace"
        basic
        data-automation-id={`${prefix}.${AUTOMATION_PREFIX_NAME}.btn.replace`}
        onClick={this.handlerReplace}
        className="link between-buttons"
      >
        {intl.formatMessage({ id: 'general.button.replace' })}
      </Button>,
      <Button
        key="save"
        data-automation-id={`${prefix}.${AUTOMATION_PREFIX_NAME}.btn.save`}
        onClick={this.handlerSaveAsNew}
      >
        {intl.formatMessage({ id: 'general.button.save-as-new-value' },
          { value: intl.formatMessage({ id: 'activity.replace-or-new-template.template' }) })
        }
      </Button>

    ];
  }

  render() {
    const { prefix, trigger, header, template, intl, saveTemplate,
      templateType, categories, entity, entityType, editTemplate } = this.props;
    switch (this.state.mode) {
      case REPLACE: {
        return (
          <SaveTemplate
            templateType={templateType}
            key="replace"
            isOpen
            onClose={() => this.setState({ mode: null })}
            header="activity.save-as-template.header.edit"
            prefix={prefix}
            entity={entity}
            entityType={entityType}
            saveAsTemplate={editTemplate}
            categories={categories}
            id={template.get(constants.DB_ID)}
            name={template.get(constants.TEMPLATE_NAME)}
            description={template.get(constants.TEMPLATE_DESCRIPTION)}
            categoryDbId={template.get(constants.CATEGORY_DB_ID)}
            templateVerticals={template.get(constants.TEMPLATE_VERTICALS)}
          />);
      }
      case SAVE_AS_NEW:
        return (
          <SaveTemplate
            templateType={templateType}
            key="save"
            isOpen
            onClose={() => this.setState({ mode: null })}
            header="activity.save-as-template.header.save"
            prefix={prefix}
            entity={entity}
            entityType={entityType}
            saveAsTemplate={saveTemplate}
            categories={categories}
          />);
      default:
        return (
          <Modal
            className="modal__replaceOrNewTemplate como-dialog"
            header={intl.formatMessage({ id: this.props.header })}
            trigger={trigger}
            content={this.modalContentHtml()}
            onOpen={this.handlerModalOpen}
            onClose={this.handlerModalClose}
            open={this.state.isModalOpen}
            actions={this.modalActionsHtml()}
            automationIdPrefix={`${prefix}.${AUTOMATION_PREFIX_NAME}.btn.save-as-template`}
            size="mini"
          />
        );
    }
  }
}

export default injectIntl(ReplaceOrNewTemplate);
