import { fromJS, List, Map } from 'immutable';
import _ from 'lodash';
import * as constants from '../../activity.constants';
import * as defaults from '../activity.reducer.defaults';
import * as itemsPopulationConstants from '../../views/itemsPopulation/itemsPopulation.constants';
import * as itemGroupsConstants from '../../../ItemGroups/itemGroups.constants';
import { getSchema } from '../../activitySchema/activitySchema.selectors';
import * as schemaConstants from '../../activitySchema/activitySchema.constants';

export function defaultizeItemsPopulation(actionsWrapper) {
  // restore empty exclude items population
  actionsWrapper[constants.ACTIONS_LIST].forEach((actionsList) => {
    if (actionsList[constants.EXCLUDE_ITEMS_POPULATION] === null) {
      actionsList[constants.EXCLUDE_ITEMS_POPULATION] = defaults.emptyItemsPopulation;
    }
  });
}

export function copyDataToTempItemsPopulation(data) {
  const type = data.get(constants.TYPE);
  let newTempItemsPopulation = fromJS(defaults.emptyTempItemsPopulation);
  const conditionsList = data.getIn([constants.CONDITIONS, constants.CONDITIONS_LIST]);
  newTempItemsPopulation = newTempItemsPopulation
    .set(itemsPopulationConstants.ITEMS_POPULATION_TYPE, type)
    .setIn([itemsPopulationConstants.ITEMS_POPULATION, type], data);
  if (type === itemsPopulationConstants.ITEMS_POPULATION_TYPE_ITEM_GROUPS) {
    const initialGroups = conditionsList.size > 0 ? conditionsList.getIn([0, constants.CONDITION_VALUE]) : List();
    newTempItemsPopulation = newTempItemsPopulation.set(itemsPopulationConstants.INITIAL_GROUPS, initialGroups);
  } else if ((type === itemsPopulationConstants.ITEMS_POPULATION_TYPE_ITEM_CODES
    || type === itemsPopulationConstants.ITEMS_POPULATION_TYPE_DEPARTMENT_CODES) && conditionsList.size > 0) {
    // Add empty string as the last value, if not present already - in order to add empty line at the end
    const adjustedConditionValue = conditionsList.getIn([0, constants.CONDITION_VALUE]).filter((conditionValue) => !_.isEmpty(conditionValue)).concat('');
    newTempItemsPopulation = newTempItemsPopulation.setIn([itemsPopulationConstants.ITEMS_POPULATION, type,
      constants.CONDITIONS, constants.CONDITIONS_LIST, 0, constants.CONDITION_VALUE], adjustedConditionValue);
  }
  return newTempItemsPopulation;
}

export function itemsPopulationCopyDataToTempItemsGroup(data) {
  return fromJS(defaults.emptyTempItemsGroup)
    .set(itemGroupsConstants.NAME, data.get(itemGroupsConstants.NAME))
    .set(itemsPopulationConstants.ITEMS_POPULATION_TYPE, data.get(itemsPopulationConstants.ITEMS_POPULATION_TYPE))
    .setIn([itemsPopulationConstants.ITEMS_POPULATION, data.get(constants.TYPE), constants.CONDITIONS], data.get(constants.CONDITIONS));
}

export function itemsPopulationUpdateWholeCondition(conditionKey, operatorKey, conditionValue) {
  const shoppingCartConditions = getSchema().getIn([constants.CONDITIONS_PER_TRIGGERS, constants.SHOPPING_CART]);
  const conditionSchema = shoppingCartConditions.find((condition) => condition.get(constants.KEY) === conditionKey);
  const dataValueType = conditionSchema ? conditionSchema.get(constants.CONDITION_VALUE_DATA_TYPE) : '';
  return Map({
    [constants.CONDITION_KEY]: conditionKey,
    [constants.OPERATOR_KEY]: operatorKey,
    [constants.CONDITION_VALUE]: conditionValue,
    [constants.CONDITION_VALUE_DATA_TYPE]: dataValueType
  });
}

export function appendItemsGroup(group, itemGroupsConditionsList) {
  if (itemGroupsConditionsList.size) {
    const conditionValue = itemGroupsConditionsList.getIn([0, constants.CONDITION_VALUE]);
    return itemGroupsConditionsList.get(0)
      .set(constants.CONDITION_VALUE, conditionValue.concat(group[itemGroupsConstants.SERVER_ID]))
      .set(constants.CONDITION_VALUE_DATA_TYPE, schemaConstants.DATA_VALUE_TYPE_STRING_ARRAY);
  }
  return fromJS(defaults.emptyCondition)
    .set(constants.CONDITION_KEY, itemsPopulationConstants.ITEMS_POPULATION_TYPE_ITEM_GROUPS)
    .set(constants.OPERATOR_KEY, constants.IS_ONE_OF_CONDITION_OPERATOR)
    .set(constants.CONDITION_VALUE, List().push(group[itemGroupsConstants.SERVER_ID]))
    .set(constants.CONDITION_VALUE_DATA_TYPE, schemaConstants.DATA_VALUE_TYPE_STRING_ARRAY);
}

export function removeItemGroupFromList(itemsGroupsList, itemGroupServerId) {
  if (!itemsGroupsList) {
    return itemsGroupsList;
  }
  return itemsGroupsList.filter((groupId) => groupId !== itemGroupServerId);
}
